import { lowerThenCamelCase } from '@/util/string/stringUtils';
import { FormSelect } from 'react-bootstrap';
import {
  type DeterminationMethod,
  availableDeterminationMethods,
} from '../../../../../../business/neosModel';
import { FlowDatePicker } from '../../../../../share/datePicker';
import { ErrorHighlight } from '../../../../../share/errorHighlight';
import { CloseButton } from '../closeButton';
import type { ForwardStartModel } from './getForwardStartModel';

export interface OwnProps {
  rfqId: string;
  strategyId: string;
}

export interface StateProps {
  model: ForwardStartModel;
}

export interface DispatchProps {
  onForwardStartDateChanged(date: string | undefined): void;
  onDeterminationMethodChanged(determinationMethod: DeterminationMethod | undefined): void;
  onTenorChanged(legId: string, tenor: string | undefined): void;
}

type Props = OwnProps & StateProps & DispatchProps;

export function ForwardStartDateStyled({
  rfqId,
  strategyId,
  model,
  onForwardStartDateChanged,
  onTenorChanged,
  onDeterminationMethodChanged,
}: Props) {
  if (!model.isDisplayed) {
    return <div />;
  }
  const { forwardStartDate, determinationMethod, maturities, tenors, isEls, isReadonly } = model;
  return (
    <div className="d-flex mb-1 ms-1 border p-1 fit-content">
      <div className="d-grid w-100 gap-1" style={{ gridTemplateColumns: '80px 150px' }}>
        <span className="fw-bold">Fwd. Date</span>
        <span className="fw-bold">Det. Method</span>
        <ErrorHighlight errorField={'forwardStartDate'} related={{ rfqId, strategyId }}>
          <FlowDatePicker
            readOnly={isReadonly}
            date={forwardStartDate || ''}
            maturities={maturities}
            tenors={tenors}
            maturitiesCategory={isEls || maturities.length === 0 ? 'TENORS' : 'LISTED'}
            onChangeTenor={val => onTenorChanged(strategyId, val)}
            hideCalendarIcon
            onChange={onForwardStartDateChanged}
            data-e2e="neos-extra-features-forward-start-date-maturity"
          />
        </ErrorHighlight>
        <FormSelect
          readOnly={isReadonly}
          value={determinationMethod || ''}
          onChange={event =>
            onDeterminationMethodChanged(
              (event.target.value || undefined) as DeterminationMethod | undefined,
            )
          }
          data-e2e="neos-extra-features-forward-start-det-toggle"
        >
          <option value="">---</option>
          {availableDeterminationMethods.map(method => (
            <option value={method} key={method}>
              {method === 'VWA_PPRICE' ? 'Vwa Price' : lowerThenCamelCase(method)}
            </option>
          ))}
        </FormSelect>
      </div>
      <CloseButton strategyId={strategyId} type={'FORWARD_START'} />
    </div>
  );
}
