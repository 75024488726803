import type { BridgeAppConfig } from '@/util/config/config';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { UserInfoApi } from './userInfoEpics';
import { ALL_FEATURE_TOGGLES, type OnyxCurrentUser, type OnyxFeatureToggle } from './userInfoModel';

export const createUserInfoApi = (http: SgmeHttp): UserInfoApi => ({
  getCurrentUser() {
    const url = 'api/user/current';
    return http.get<OnyxCurrentUser>({ url });
  },
  getFeatureToggles() {
    const url = 'api/toggleFeaturesByNames';
    return http.post<OnyxFeatureToggle[]>({
      body: ALL_FEATURE_TOGGLES,
      url,
    });
  },
  getAdditionalConfig() {
    const url = 'api/config';
    return http.get<BridgeAppConfig>({ url });
  },
});
