import type { ActorsSetup } from './actorsSetupModel';

export type ActorsSetupState = Readonly<ActorsSetup>;

export const defaultPartialActorsSetup: Omit<ActorsSetupState, 'salesInitId' | 'salesValoId'> = {
  salesGroupId: undefined,
  counterpartId: undefined,
  name: undefined,
  eliotCode: undefined,
  mnemo: undefined,
  salesPhysicalLocation: undefined,
  salesInitCityLocation: undefined,
  contacts: [],
  salesDiffusionFeatureToggles: undefined,
};
