import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import type { OptionObservableType } from '@/neos/business/rfq/strategy/leg/product/productModel';
import { neosThunks } from '@/neos/business/thunks';
import { getObservableTypeModel } from '@/neos/components/rfq/strategies/strategy/strategyDefinition/ObservableType/getObservableTypeModel';
import { ErrorHighlight } from '@/neos/components/share/errorHighlight';
import { MultiToggleButton } from '@/neos/components/share/multiToggleButton/MultiToggleButton';
import { useDispatch } from 'react-redux';

export function ObservableType({ rfqId, strategyId }: { strategyId: string; rfqId: string }) {
  const dispatch = useDispatch<ExtendedDispatch>();
  const model = useAppSelector(state => getObservableTypeModel(state, strategyId, selectors));

  return (
    <section>
      {model.observableTypes.map(({ id: productId, value, isEditable }) => (
        <ErrorHighlight
          key={productId}
          errorField={'observableType'}
          related={{ rfqId, legId: productId, strategyId }}
        >
          <MultiToggleButton<OptionObservableType | undefined>
            readOnly={!isEditable}
            selectedValue={value}
            valueFormatter={valueToFormat => (valueToFormat ? valueToFormat.charAt(0) : '')}
            availableValues={model.availableObservableTypes}
            onValueChange={newObservableType =>
              dispatch(
                neosThunks.createUpdateProductAndResetDependenciesThunk(
                  rfqId,
                  strategyId,
                  productId,
                  'observableType',
                  newObservableType,
                ),
                neosActionCreators.createDefaultObservableTypeRequestedAction(rfqId),
              )
            }
            data-e2e="neos-strategy-definition-observableType"
            className="fw-bold form-control"
          />
        </ErrorHighlight>
      ))}
    </section>
  );
}
