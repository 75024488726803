import type { InternalMailOptions } from './internalOnyxPreconfirmationModel';
import type {
  InternalPreconfirmationPreviewAction,
  InternalPreconfirmationSendAction,
} from './internalPreconfirmationAction';
import { internalPreconfirmationDomain } from './internalPreconfirmationDomain';

const internalPreconfirmationCrudActions = internalPreconfirmationDomain.actions;

export const internalPreconfirmationActionCreators = {
  internalPreconfirmationCrudActions,
  internalPreconfirmationEmailSendAction,
  internalPreconfirmationPreviewAction,
};

function internalPreconfirmationEmailSendAction(
  rfqId: string,
  internalMailOptions: InternalMailOptions,
  ignoreFieldChanges = false,
): InternalPreconfirmationSendAction {
  return {
    type: 'INTERNAL_PRECONFIRMATION_EMAIL_SEND_REQUESTED',
    rfqId,
    internalMailOptions,
    ignoreFieldChanges,
  };
}

function internalPreconfirmationPreviewAction(rfqId: string): InternalPreconfirmationPreviewAction {
  return {
    type: 'INTERNAL_PRECONFIRMATION_EMAIL_PREVIEW_REQUESTED',
    rfqId,
  };
}
