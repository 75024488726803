import type { Thunk } from '@/bootstrap/thunks';
import type { ActionContainer } from '@/util/actionContainer';

export function createFairPricesWarningsResetThunk(rfqId: string): Thunk {
  return function fairPricesWarningsResetThunk(
    dispatch,
    getState,
    {
      selectors: { getRfqData },
      actionCreators: {
        neos: { strategyUiCrudActions, rfqUiCrudActions },
      },
    },
  ) {
    const appState = getState();

    const { strategyIds } = getRfqData(appState, rfqId);
    const actions: ActionContainer[] = [
      rfqUiCrudActions.patchOrInsert(rfqId, { reverseWeightsMessageDismissed: undefined }),
    ];
    actions.push(
      ...strategyIds.map(strategyId =>
        strategyUiCrudActions.update(strategyId, { legOverMessageDismissed: undefined }),
      ),
    );
    dispatch(actions);
  };
}
