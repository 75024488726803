import { isDefined } from '@/util/undefinedAndNull/isDefined';
import type { RowNode } from '@ag-grid-community/core';
import { blotterFilterDefaultUser } from '../eventBus/defaultFilterRequested';
import { filterDeletedBus } from '../eventBus/filterChanged';
import type { BlotterGridProps } from './BlotterGrid';
import type { GridStateStorageAdapter } from './gridStateStorageAdapter';

export class GridNotificationsHandler {
  constructor(private gridStateStorageAdapter: GridStateStorageAdapter) {}

  public handleEvents = () => {
    const eventResetFilter = (eventName: string) => {
      if (eventName === 'all') {
        const reloadAllFilters = () => this.gridStateStorageAdapter.gridApi?.setFilterModel(null);
        reloadAllFilters();
      }

      const filter = this.gridStateStorageAdapter.gridApi?.getFilterInstance(eventName);
      const reloadFilter = () => this.gridStateStorageAdapter.gridApi?.onFilterChanged();
      if (filter) {
        filter.setModel(null);
        reloadFilter();
      }
    };

    filterDeletedBus.removeAllListeners().on('filterDeleted', eventResetFilter);

    blotterFilterDefaultUser
      .removeAllListeners()
      .on('blotterFilterDefaultUser', model =>
        this.gridStateStorageAdapter.gridApi?.setFilterModel(model),
      );
  };

  public handleNotifications = (props: BlotterGridProps) => {
    const { isVisible, pendingRfqIds } = props;
    if (isVisible && pendingRfqIds.length) {
      if (!this.gridStateStorageAdapter.gridApi) {
        throw Error('gridApi should be defined');
      }
      this.flashNewRows(props);
      const rowIdsAfterFilter: (string | undefined)[] = [];
      this.gridStateStorageAdapter.gridApi.forEachNodeAfterFilter(f =>
        rowIdsAfterFilter.push(f.id),
      );

      const filteredOut = pendingRfqIds.some(id => !rowIdsAfterFilter.includes(id));
      if (filteredOut) {
        props.updateBlotterRfqFilteredOutNotificationLastDate();
      }
      props.emptyBlotter();
    }
  };

  private flashNewRows = (props: BlotterGridProps): void => {
    // TODO improve this ugly try / catch block
    // CLINEG-6251
    // the try catch is there because it happens that this code is throwing sometime, for unknown reason
    // yes it's ugly but nobody found why :(

    try {
      if (this.gridStateStorageAdapter.gridApi) {
        const rowNodes = this.getNewRowsToFlash(props);

        if (rowNodes.length > 0) {
          this.gridStateStorageAdapter.gridApi.flashCells({
            rowNodes,
          });

          // this setTimeout is here to redraw row after they have been flashed,
          // the timeout duration match the duration of the css transition that happen during the flashCells
          setTimeout(() => {
            this.gridStateStorageAdapter.gridApi?.redrawRows({ rowNodes });
          }, 1700);
        }
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        throw err;
      }
    }
  };

  private getNewRowsToFlash({ pendingRfqIds, rows }: BlotterGridProps): RowNode[] {
    if (this.gridStateStorageAdapter.gridApi) {
      const currentRfqIds = rows.map(row => row.uuid);
      const rfqIdsToFlash = currentRfqIds.filter(currentRfqId =>
        pendingRfqIds.includes(currentRfqId),
      );
      const rowNode = rfqIdsToFlash
        .map(rfqId => this.gridStateStorageAdapter.gridApi?.getRowNode(rfqId))
        .filter(isDefined);
      return rowNode as RowNode[];
    }

    return [];
  }
}
