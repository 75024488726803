import type { Status, SubStatus } from '../../../../neos/business/neosModel';

interface StatusAndSubStatus {
  status: Status;
  subStatus?: SubStatus;
}

type ClassNameArray = StatusAndSubStatus & { className: string };

const commonClassNamesArray: ClassNameArray[] = [
  { status: 'NEW', className: 'neos-new-rfq' },
  { status: 'CAPTURED', className: 'neos-captured-rfq' },
  { status: 'BEING_PRICED', className: 'neos-being-priced-rfq' },
  { status: 'PRICED', className: 'neos-priced-rfq' },
  { status: 'PRICE_PROPOSED', className: 'neos-price-proposed-rfq' },
  { status: 'ORDER_RECEIVED', className: 'neos-order-received-rfq' },
  { status: 'PENDING_DELTA_INSTRUCTIONS', className: 'neos-pending-delta-instructions-rfq' },
  { status: 'ORDER_CONFIRMED', className: 'neos-order-confirmed-rfq' },
  { status: 'ORDER_ACCEPTED', className: 'neos-order-accepted-rfq' },
  { status: 'DELTA_BEING_EXECUTED', className: 'neos-delta-being-executed-rfq' },
  { status: 'DELTA_MANUALLY_EXECUTED', className: 'neos-delta-manually-executed-rfq' },
  { status: 'ORDER_BEING_CROSSED', className: 'neos-delta-manually-executed-rfq' },
  { status: 'ORDER_MANUALLY_CROSSED', className: 'neos-delta-manually-executed-rfq' },
  { status: 'ORDER_CROSSED', className: 'neos-delta-manually-executed-rfq' },
  { status: 'DELTA_EXECUTED', className: 'neos-delta-executed-rfq' },
  { status: 'BOOKING_REQUESTED', className: 'neos-booking-requested-rfq' },
  { status: 'POSITION_BEING_BOOKED', className: 'neos-position-being-booked-rfq' },
  { status: 'TRADED', className: 'neos-traded-rfq' },
  { status: 'TRADE_BEING_COMPLETED', className: 'neos-traded-rfq' },
  { status: 'TRADE_TO_BE_COMPLETED', className: 'neos-traded-rfq' },
  { status: 'TRADE_COMPLETED', className: 'neos-traded-rfq' },
  { status: 'BEING_BOOKED', className: 'neos-being-booked-rfq' },
  { status: 'BOOKED', className: 'neos-booked-rfq' },
  { status: 'PRE_TRADE_BOOKING_REQUESTED', className: 'neos-temp-booking-requested-rfq' },
  { status: 'PRE_TRADE_BEING_BOOKED', className: 'neos-temp-being-booked-rfq' },
  { status: 'CANCELLED', subStatus: 'ABORTED', className: 'neos-aborted-rfq' },
  { status: 'CANCELLED', subStatus: 'DEAL_CANCEL', className: 'neos-deal-cancel-rfq' },
  { status: 'CANCELLED', subStatus: 'NO_DEAL', className: 'neos-no-deal-rfq' },
  { status: 'CANCELLED', subStatus: 'NO_DEAL_WITH_SG', className: 'neos-no-deal-with-sg-rfq' },
  { status: 'CANCELLED', subStatus: 'NO_QUOTE', className: 'neos-no-quote-rfq' },
];

const salesSpecificClassNamesArray: ClassNameArray[] = [
  { status: 'PRICE_REQUESTED', className: 'neos-requested-rfq' },
];

const traderSpecificClassNamesArray: ClassNameArray[] = [
  { status: 'PRICE_REQUESTED', className: 'neos-trader-requested-rfq' },
];

const separator = '#||#';

const salesClassNameByStatusAndSubStatus = buildClassNameByStatusAndSubStatus([
  ...commonClassNamesArray,
  ...salesSpecificClassNamesArray,
]);

const traderClassNameByStatusAndSubStatus = buildClassNameByStatusAndSubStatus([
  ...commonClassNamesArray,
  ...traderSpecificClassNamesArray,
]);

function buildClassNameByStatusAndSubStatus(classNameArray: ClassNameArray[]) {
  return classNameArray.reduce(
    (acc, { className, status, subStatus }) => ({
      ...acc,
      [getStatusSubStatusKey(status, subStatus)]: className,
    }),
    {} as Record<string, string>,
  );
}

function getStatusSubStatusKey(status: Status, subStatus: SubStatus | undefined) {
  return `${status}${separator}${subStatus ?? 'NONE'}`;
}

export function getClassNameByStatusAndSubStatus(
  status: Status,
  subStatus: SubStatus | undefined,
  isTrader: boolean,
): string | undefined {
  const key = getStatusSubStatusKey(status, subStatus);
  return isTrader
    ? traderClassNameByStatusAndSubStatus[key]
    : salesClassNameByStatusAndSubStatus[key];
}
