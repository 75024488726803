import type { SalesLocationsReceivedAction } from './salesLocationsActions';
import type { SalesLocations } from './salesLocationsModel';

export const salesLocationsActionCreators = { createSalesLocationReceivedAction };

export function createSalesLocationReceivedAction(
  salesLocations: SalesLocations[],
): SalesLocationsReceivedAction {
  return {
    type: 'SALES_LOCATIONS_RECEIVED',
    salesLocations,
  };
}
