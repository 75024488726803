import type { Thunk } from '@/bootstrap/thunks';
import type { OnyxQuoteType } from '../rfqOnyxModel';

export function createRfqChangeQuoteTypeThunk(rfqId: string): Thunk {
  return function rfqChangeQuoteTypeThunk(
    dispatch,
    getState,
    {
      selectors: { getRfqData },
      actionCreators: {
        neos: { rfqDataCrudActions },
      },
    },
  ) {
    const state = getState();
    const { quoteType: currentQuoteType } = getRfqData(state, rfqId);
    const quoteType: OnyxQuoteType = currentQuoteType === 'TRADABLE' ? 'INDICATIVE' : 'TRADABLE';

    dispatch(rfqDataCrudActions.update(rfqId, { quoteType }));
  };
}
