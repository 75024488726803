import type { Dispatchable, Thunk } from '@/bootstrap/thunks';

export function createChangeIsRfqInternalThunk(
  rfqId: string,
  oldInternal: boolean | undefined,
): Thunk {
  return function changeIsRfqInternalThunk(dispatch, getState, { selectors, actionCreators }) {
    const state = getState();
    const { strategyIds } = selectors.getRfqData(state, rfqId);

    const dispatchables: Dispatchable[] = [];
    dispatchables.push(
      actionCreators.neos.rfqDataCrudActions.update(rfqId, {
        internal: oldInternal ? undefined : true,
      }),
    );
    dispatchables.push(
      actionCreators.neos.actorsSetupCrudActions.update(rfqId, {
        counterpartId: undefined,
      }),
    );

    if (oldInternal === true) {
      strategyIds.forEach(strategyId => {
        dispatchables.push(
          actionCreators.neos.strategyDataCrudActions.update(strategyId, {
            mirrorPortfolioId: undefined,
          }),
        );
      });
    }

    dispatchables.push(actionCreators.neos.createNextUserActionsRequestedAction(rfqId));

    dispatch(dispatchables);
  };
}
