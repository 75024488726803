import type { Dispatchable, Thunk } from '@/bootstrap/thunks';
import type { Product } from '@/neos/business/neosModel';

export function createUpdateProductNoTaxCollectionThunk(
  strategyId: string,
  productId: string,
  newNoTaxCollection: Product['noTaxCollection'],
): Thunk {
  return function updateProductNoTaxCollectionThunk(
    dispatch,
    _,
    {
      thunks: {
        neos: { createUpdateProductThunk },
      },
    },
  ) {
    const dispatchables: Dispatchable[] = [
      createUpdateProductThunk(strategyId, productId, 'noTaxCollection', newNoTaxCollection),
    ];
    if (!newNoTaxCollection) {
      dispatchables.push(
        createUpdateProductThunk(strategyId, productId, 'clientTaxRate', undefined),
      );
    }

    dispatch(dispatchables);
  };
}
