import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { NeosApp } from '@/neos/components/NeosApp.container';
import type { FC } from 'react';
import { useSelector } from 'react-redux';
import { AppCrashError } from './AppCrashError';
import styles from './ApplicationSelector.module.scss';

export const ApplicationSelector: FC = () => {
  const isAppCrashed = useSelector((state: AppState) => selectors.isAppCriticallyCrashed(state));

  return isAppCrashed ? (
    <AppCrashError />
  ) : (
    <div className={styles['application']}>
      <NeosApp />
    </div>
  );
};
