import type {
  AppCrashedAction,
  AppError,
  CriticalAppCrashedAction,
  SessionLostAction,
} from './globalErrorActions';

export const globalErrorActionCreators = {
  createSessionExpiredAction,
  createAppCrashedAction,
  createAppCrashedActionWithUserfeedback,
  createCriticalAppCrashAction,
};

function createSessionExpiredAction(): SessionLostAction {
  return {
    type: 'SESSION_EXPIRED',
  };
}

function createAppCrashedAction(source: string, error: any): AppCrashedAction {
  return {
    type: 'APP_CRASHED',
    source,
    error: convertToError(error),
    rawError: error,
    userFeedback: false,
  };
}

function createAppCrashedActionWithUserfeedback(source: string, error: any): AppCrashedAction {
  return {
    type: 'APP_CRASHED',
    source,
    error: convertToError(error),
    rawError: error,
    userFeedback: true,
  };
}

function createCriticalAppCrashAction(source: string, error: any): CriticalAppCrashedAction {
  return {
    type: 'CRITICAL_APP_CRASHED',
    source,
    error: convertToError(error),
    rawError: error,
  };
}

function convertToError(err: any): AppError {
  if (
    Object.prototype.hasOwnProperty.call(err, 'stack') &&
    Object.prototype.hasOwnProperty.call(err, 'message')
  ) {
    const errorData: any = {};
    Object.getOwnPropertyNames(err)
      .filter(key => key !== 'stack' && key !== 'message')
      .forEach(key => {
        errorData[key] = err[key];
      });
    return { message: err.message, stack: err.stack, data: errorData };
  }
  return {
    message: 'An error has occured',
    stack: 'StackTrace Unavailable',
    data: err,
  };
}
