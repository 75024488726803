import { mdpSound } from '@/bootstrap/audios/mdpSound';
import type { Thunk } from '@/bootstrap/thunks';
import type { OnyxAlert } from '../../neosOnyxModel';

export function createPlayMdpSoundThunk(alert: OnyxAlert): Thunk {
  return function playMdpSoundThunk(_, getState, { selectors: { isAlertSoundPlayed } }) {
    const userPreferenceState = getState().ui.userPreferences;
    const shouldPlaySound = isAlertSoundPlayed(userPreferenceState);
    const isAlertMdp = !!alert.source && alert.source !== 'VOICE';

    if (shouldPlaySound && isAlertMdp) {
      mdpSound.play();
    }
  };
}
