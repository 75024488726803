import type {
  DefaultProductValuesForStrategy,
  Product,
  TotalReturnFuture,
} from '../../../../../../../../../neos/business/neosModel';
import {
  getCommonPart,
  getDerivativePart,
  getFutureNegotiationPart,
  getFutureStrikeDatePart,
} from './getDefaultedPartialProduct';

export function getDefaultedTotalReturnFutureProduct(
  currentProduct: Product,
  defaultValues: DefaultProductValuesForStrategy,
): TotalReturnFuture {
  const { pointValue: strategyPointValue, maturity, underlying, negotiation } = defaultValues;

  const { pointValue } =
    currentProduct.subFamily === 'TOTAL_RETURN_FUTURE' ? currentProduct : { pointValue: undefined };

  return {
    subFamily: 'TOTAL_RETURN_FUTURE',
    pointValue: strategyPointValue || pointValue,
    ...getCommonPart(currentProduct, defaultValues),
    ...getFutureNegotiationPart(currentProduct, negotiation),
    ...getDerivativePart(currentProduct, maturity, underlying),
    ...getFutureStrikeDatePart(currentProduct, defaultValues),
    hasCustomUnderlying: false,
  };
}
