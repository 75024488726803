import { Switch } from '@/neos/components/share/switch/Switch';
import { useDispatch, useSelector } from 'react-redux';

import { actionCreators } from '@/bootstrap/actions';
import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { ElsFeaturesContext } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures';
import { ElsBlocField } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/ElsBlocField';
import { useContext } from 'react';
import { isElsProduct } from '../../../../../../../../neos/business/neosModel';

export function SpecialDividends() {
  const { strategyId } = useContext(ElsFeaturesContext);
  const dispatch = useDispatch();
  const product = useSelector((state: AppState) =>
    selectors.getStrategyProduct(state, strategyId, selectors),
  );
  if (!isElsProduct(product)) {
    return null;
  }
  const { uuid, specialDividends } = product;
  const { update: updateProduct } = actionCreators.neos.productCrudActions;

  const onSpecialDividendsChange = () => {
    dispatch(updateProduct(uuid, { specialDividends: !specialDividends }));
  };

  return (
    <ElsBlocField
      label="Special Dividends"
      renderInput={readOnly => (
        <Switch
          disabled={readOnly}
          switchClassName="text-center"
          data-e2e="dividend-section-special-dividend"
          checked={!!specialDividends}
          onChange={() => onSpecialDividendsChange()}
        />
      )}
    />
  );
}
