import type { Thunk } from '@/bootstrap/thunks';
import type { PriceWithUnit } from '@/neos/business/neosOnyxModel';
import { getSecondaryInfos, isLegIdPartOfASecondaryStrategy } from '../secondaryInfosSelectors';

export function createUpdateNovationFeeThunk(
  allocationId: string,
  rfqId: string,
  legId: string,
  patch: Partial<PriceWithUnit>,
): Thunk {
  return function updateNovationFeeThunk(
    dispatch,
    getState,
    { selectors, actionCreators: { neos: neosActionCreators } },
  ) {
    const state = getState();
    if (!isLegIdPartOfASecondaryStrategy(legId, state, selectors)) {
      return;
    }
    const currentSecondaryInfo = getSecondaryInfos(legId, allocationId, rfqId, state, selectors);

    dispatch(
      neosActionCreators.otcAllocationCrudActions.update(
        { allocationId, rfqId, legId },
        {
          secondaryInfo: {
            ...currentSecondaryInfo,
            novationFee: {
              nominal: { ...currentSecondaryInfo?.novationFee?.nominal, ...patch },
              valueDate: currentSecondaryInfo?.novationFee?.valueDate,
            },
          },
        },
      ),
    );
  };
}
