type ClipboardResult =
  | {
      status: 'SUCCESS';
      data: string;
    }
  | {
      status: 'FAILED';
      message: string;
    };

export async function getDataFromClipboard(): Promise<ClipboardResult> {
  const permission = await navigator.permissions.query({
    name: 'clipboard-read' as PermissionName,
  });
  if (permission.state === 'denied') {
    return {
      status: 'FAILED',
      message:
        'Please allow clipboard reading in your browser settings by clicking on the lock next to the URL.',
    };
  }
  if (permission.state === 'prompt') {
    setTimeout(() => {
      navigator.clipboard
        .readText()
        .then(() => {
          return;
        })
        .catch(() => {
          return;
        });
    });
    return {
      status: 'FAILED',
      message: 'Please allow clipboard reading in your browser settings.',
    };
  }

  try {
    const clipboard = await navigator.clipboard.readText();
    return { status: 'SUCCESS', data: clipboard };
  } catch (e: any) {
    return {
      status: 'FAILED',
      message: e.message?.includes('focused') ? '' : e.message,
    };
  }
}
