import type { Thunk } from '@/bootstrap/thunks';

export function createResetSecondaryTypesOnLegsThunk(): Thunk {
  return function resetSecondaryTypesThunk(
    dispatch,
    getState,
    {
      selectors: { getSelectedTab },
      actionCreators: {
        neos: { rfqUiCrudActions, createResetSALinkManagement },
      },
    },
  ) {
    const state = getState();
    const selectedTabId = getSelectedTab(state.ui.tabManagement);
    dispatch(
      rfqUiCrudActions.patchOrInsert(selectedTabId, {
        isRfqWaitingForSecondarySelection: false,
        shouldDisplayStrategyLink: false,
      }),
      createResetSALinkManagement(),
    );
  };
}
