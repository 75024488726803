import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ColumnsDisplayToggle as ColumnsDisplayToggleType } from '@/neos/business/ui/userPreferences/userPreferencesUiModel';

export interface StrategyGridDisplayModel {
  salesPricesToggle: ColumnsDisplayToggleType;
  deltaToggle: ColumnsDisplayToggleType;
  fairPricesToggle: ColumnsDisplayToggleType;
  fairGreeksToggle: ColumnsDisplayToggleType;
  volBidAskToggle: ColumnsDisplayToggleType;
}

export function getStrategyGridDisplayModel(
  state: AppState,
  selectors: Selectors,
): StrategyGridDisplayModel {
  const userPreferencesState = state.ui.userPreferences;
  return {
    deltaToggle: selectors.getDeltaToggle(userPreferencesState),
    fairGreeksToggle: selectors.getFairGreeksToggle(userPreferencesState),
    fairPricesToggle: selectors.getFairPricesToggle(userPreferencesState),
    salesPricesToggle: selectors.getSalesPricesToggle(userPreferencesState),
    volBidAskToggle: selectors.getVolBidAskToggle(userPreferencesState),
  };
}
