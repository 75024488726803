import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { Observable } from 'rxjs';
import type { NextUserActionsApi } from '../epics/retrieveNextUserActionsEpic';
import type { OnyxNextUserActions } from '../neosOnyxModel';

export const createNextUserActionsApi = (http: SgmeHttp): NextUserActionsApi => ({
  retrieveNextUserActions(
    rfqId: string,
    internal: boolean | undefined,
    initiatedByTrader: boolean | undefined,
  ): Observable<OnyxNextUserActions> {
    const searchParams = new URLSearchParams();
    if (internal) {
      searchParams.append('internal', 'true');
    }
    if (initiatedByTrader) {
      searchParams.append('traderInitiated', 'true');
    }

    return http.get<OnyxNextUserActions>({
      url: `api/workflow-neos/${rfqId}?${searchParams}`, //${startQuery}${internalQuery}${querySeparator}${traderInitiatedQuery}`,
    });
  },
});
