import type { StatusColor } from '@/neos/business/bookingSteps/bookingStepOnyxModel';
import type { ReactElement } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import type { OverlayDelay } from 'react-bootstrap/esm/OverlayTrigger';
import type { Placement } from 'react-bootstrap/esm/types';

export type SimpleNeosTooltipProps = {
  children: ReactElement;
  message: string | undefined;
  type?: StatusColor | 'primary';
  id?: string;
  disable?: boolean;
  placement?: Placement;
  tooltipDataE2e?: string;
  delay?: OverlayDelay;
};

export const SimpleNeosTooltip = ({
  children,
  delay,
  type = 'primary',
  id,
  disable = false,
  placement = 'top',
  message,
  tooltipDataE2e,
}: SimpleNeosTooltipProps) => {
  if (disable || message === '' || message === undefined) {
    return children;
  }

  const tooltipClassName = type === 'primary' ? undefined : `react-bootstrap-${type}-tooltip`;

  return (
    <OverlayTrigger
      delay={delay ?? undefined}
      overlay={
        <Tooltip className={tooltipClassName} id={id} data-e2e={tooltipDataE2e}>
          {message}
        </Tooltip>
      }
      placement={placement}
    >
      {children}
    </OverlayTrigger>
  );
};
