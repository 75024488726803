import type { AppState } from '@/bootstrap/state.ts';
import type { Selectors } from '@/bootstrap/selectors.ts';

export function selectIsPreconfirmationSent(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): boolean {
  const externalEmailStatus = selectors.getExternalEmailStatus(state, rfqId);
  const internalEmailStatus = selectors.getInternalEmailStatus(state, rfqId);

  return ![externalEmailStatus, internalEmailStatus].some(status => status !== 'NOT_SENT');
}
