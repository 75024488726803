import type {
  DefaultProductValuesForStrategy,
  Product,
} from '../../../../../../../../neos/business/neosModel';
import { getDefaultedProductUsingDefaulters } from './getDefaultedProductUsingDefaulters';
import { strategyProductDefaulters } from './getSpecificDefaultedProduct';

export function getDefaultedProduct(
  currentProduct: Product,
  defaultValues: DefaultProductValuesForStrategy,
): Product {
  return getDefaultedProductUsingDefaulters(
    currentProduct,
    defaultValues,
    strategyProductDefaulters,
  );
}
