import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { Hedge } from './hedgeModel';
import type { OnyxHedge } from './hedgeOnyxModel';

export const toHedgeMappers = { mapToOnyxHedges };
export const fromHedgeMappers = { mapFromOnyxHedges };

function mapToOnyxHedges(
  state: AppState,
  rfqId: string,
  { getHedges }: Selectors,
): OnyxHedge[] | undefined {
  const hedges = getHedges(state, { rfqId });

  const removeDeltaMixedWithoutDeltaValue = (hedge: Hedge) =>
    hedges.filter(h => h.underlyingId === hedge.underlyingId).length < 2 ||
    hedge.delta !== undefined;

  const onyxHedges = hedges
    .filter(removeDeltaMixedWithoutDeltaValue)
    .map(
      ({
        underlyingId,
        underlying,
        lotSize,
        market,
        negotiatedSize,
        way,
        sgWay,
        deltaType,
        delta,
        deltaUnit,
        deltaSummary,
      }): OnyxHedge => {
        const greeks =
          delta !== undefined ? { delta: { value: delta, unit: deltaUnit ?? '%' } } : undefined;
        return {
          underlyingId,
          lotSize,
          market,
          negotiatedSize,
          way,
          sgWay,
          deltaSummary,
          underlying,
          deltaType,
          greeks,
        };
      },
    );
  return onyxHedges.length ? onyxHedges : undefined;
}

function mapFromOnyxHedges(onyxHedges: OnyxHedge[]): Hedge[] {
  return onyxHedges.map(
    ({
      underlyingId,
      underlying,
      lotSize,
      market,
      negotiatedSize,
      way,
      deltaType,
      greeks,
      deltaSummary,
      sgWay,
    }): Hedge => {
      return {
        underlyingId,
        underlying,
        lotSize,
        market,
        negotiatedSize,
        way,
        sgWay,
        deltaType,
        deltaSummary,
        delta: greeks?.delta?.value,
        deltaUnit: greeks?.delta?.unit,
      };
    },
  );
}
