import {
  type DetailedHTMLProps,
  forwardRef,
  type InputHTMLAttributes,
  useEffect,
  useState,
} from 'react';

export const NeosBlurInput = forwardRef<
  HTMLInputElement,
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    transform?: (value: string | undefined) => string | undefined;
  }
>(({ transform, ...props }, ref) => {
  const [state, setState] = useState(props.value);

  useEffect(() => {
    setState(props.value);
  }, [props.value]);

  return (
    <input
      ref={ref}
      {...props}
      className={`form-control ${props.className}`}
      value={state ?? ''}
      onChange={e => {
        const value = e?.target?.value;
        const newValue = transform ? transform(value) : value;
        setState(newValue);
      }}
      onBlur={e => props.onBlur?.(e)}
    />
  );
});
