import type { Tenor } from '../../../../neos/business/neosModel';

import styles from './FlowDatePicker.module.scss';

interface TenorsProps {
  isDisplayed: boolean;
  currentMaturity: string;
  tenors: Tenor[];
  onSelect: (tenor: string) => void;
}

export const Tenors = ({ tenors, onSelect, currentMaturity, isDisplayed }: TenorsProps) =>
  !isDisplayed ? null : (
    <div className={`${styles['tenors-container']} `}>
      {tenors.map((tenor, index) => {
        return (
          <span
            className={`${tenor.code === currentMaturity ? styles['tenor-selected'] : ''} `}
            key={index}
            onClick={() => onSelect(tenor.code)}
          >
            {tenor.label}
          </span>
        );
      })}
    </div>
  );
