import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { getStrategyEpics } from '../strategy/strategyEpics';
import { getDefaultRfqEpic } from './defaultRfqEpic';
import { getIbChatEpic } from './ibChatEpic';
import { getRecomputeRfqEpic } from './recomputeRfqEpic';

export function getRfqEpics(http: SgmeHttp) {
  return [
    getRecomputeRfqEpic(http),
    getDefaultRfqEpic(http),
    getIbChatEpic(http),
    ...getStrategyEpics(http),
  ];
}
