import type { EventEmitter } from 'events';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { commonActionCreators } from '@/common/business/commonActionCreators';
import {
  EVENT_AUTH_DISCARDED,
  EVENT_AUTH_EXPIRED,
  EVENT_AUTH_RENEW_ERROR,
  EVENT_AUTH_RENEW_SUCCESS,
} from '@sgwt/connect-core';

import { logger } from '@/util/logging/logger';

export const sgwtConnectSetup = {
  setup: (sgwtConnectEventEmitter: { on: EventEmitter['on'] }, dispatch: ExtendedDispatch) => {
    sgwtConnectEventEmitter.on(EVENT_AUTH_DISCARDED, () => {
      dispatch(
        commonActionCreators.createLogAction('Token is no longer available on the client side.'),
      );
    });

    sgwtConnectEventEmitter.on(EVENT_AUTH_EXPIRED, () => {
      const shouldDisplayPopin = sgmeConfiguration.sgconnect.enableSessionLostPopIn === 'true';
      dispatch(
        commonActionCreators.createLogAction(
          `Token is no longer valid. User ${
            shouldDisplayPopin ? 'will' : 'will not'
          } receive a pop-in`,
        ),
      );
      if (shouldDisplayPopin) {
        dispatch(commonActionCreators.createSessionExpiredAction());
        logger.scheduleStop();
      }
    });

    sgwtConnectEventEmitter.on(EVENT_AUTH_RENEW_ERROR, (error: any) => {
      dispatch(commonActionCreators.createLogAction(`Failed to renew the token due to ${error}`));
    });

    sgwtConnectEventEmitter.on(EVENT_AUTH_RENEW_SUCCESS, () => {
      dispatch(commonActionCreators.createLogAction('A fresh token has been acquired.'));
    });
  },
};
