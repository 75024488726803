import { useEffect, useState } from 'react';

type NeosTimeInputProps = {
  readOnly?: boolean;
  time: string | undefined;
  'data-e2e': string;
  onBlur: (newTime: string | undefined) => void;
};

export function NeosTimeInput({ onBlur, readOnly, time, 'data-e2e': dataE2e }: NeosTimeInputProps) {
  const [internalTime, setInternalTime] = useState<string | undefined>(time);
  useEffect(() => {
    setInternalTime(time);
  }, [time]);

  function handleTimeChange(value: string) {
    setInternalTime(value);
  }

  return (
    <input
      className="form-control"
      type="time"
      value={internalTime ?? ''}
      onChange={event => handleTimeChange(event.target.value)}
      onBlur={() => {
        onBlur(internalTime);
      }}
      disabled={readOnly}
      data-e2e={dataE2e}
    />
  );
}
