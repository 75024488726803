import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type {
  PredealCheck,
  PredealCheckLevel,
  PredealCheckType,
} from '../predealCheck/predealCheckModel';

export function getNeosPredealChecksByTypeAndLevel(
  state: AppState,
  rfqId: string,
  predealCheckType: PredealCheckType,
  predealCheckLevel: PredealCheckLevel,
  selectors: Selectors,
): PredealCheck[] {
  return selectors
    .getNeosPredealChecks(state, rfqId, selectors)
    .filter(
      predealCheck =>
        predealCheck.type === predealCheckType && predealCheck.level === predealCheckLevel,
    );
}
