import { isDefined } from '@/util/undefinedAndNull/isDefined';
import type { BlotterRowModel } from './blotterRowModel';
import { getClassNameByStatusAndSubStatus } from './statusColumnStyle';

interface Parameter<T extends Partial<Pick<BlotterRowModel, 'status' | 'subStatus'>>> {
  data?: T;
}

export function getStatusCellClass<
  T extends Partial<Pick<BlotterRowModel, 'status' | 'subStatus' | 'feedbackLevel'>>,
>(isTrader: boolean) {
  return ({ data }: Parameter<T>): string[] | string => {
    const { status, subStatus, feedbackLevel } = (data ?? {}) as Partial<
      Pick<BlotterRowModel, 'status' | 'subStatus' | 'feedbackLevel'>
    >;
    if (isDefined(feedbackLevel)) {
      return 'neos-error-highlight-rfq';
    }
    if (status) {
      return getClassNameByStatusAndSubStatus(status, subStatus, isTrader) ?? '';
    }
    return [];
  };
}
