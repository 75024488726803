import { BrokenPeriod } from '../BrokenPeriod';
import { ConventionDay } from '../ConventionDay';
import { FixedDay } from '../FixedDay';
import { GenerateFrom } from '../GenerateFrom';
import { RateReset } from '../RateReset';
import { RateResetMode } from '../RateResetMode';

interface ScheduleInputsProps {
  rfqId: string;
  strategyId: string;
}

export function CLSScheduleInputs({ rfqId, strategyId }: ScheduleInputsProps) {
  return (
    <>
      <div className="mb-4 d-grid gap-1" style={{ gridTemplateColumns: '1fr 1fr 150px 1fr 1fr' }}>
        <GenerateFrom rfqId={rfqId} strategyId={strategyId} />

        <FixedDay rfqId={rfqId} strategyId={strategyId} />
        <span></span>

        <RateReset rfqId={rfqId} strategyId={strategyId} />

        <ConventionDay rfqId={rfqId} strategyId={strategyId} />
        <span></span>
        <RateResetMode rfqId={rfqId} strategyId={strategyId} />

        <BrokenPeriod rfqId={rfqId} strategyId={strategyId} />
      </div>
    </>
  );
}
