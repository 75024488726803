import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import {
  ForwardDriftCellsComponent,
  type ForwardDriftCellsDispatchProps,
  type ForwardDriftCellsMapStateProps,
  type ForwardDriftCellsOwnProps,
} from './ForwardDriftCells';
import { getForwardDriftModel } from './getForwardDriftModel';

export const ForwardDriftCells = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForwardDriftCellsComponent);

function mapStateToProps(
  state: AppState,
  { rfqId, strategyId }: ForwardDriftCellsOwnProps,
): ForwardDriftCellsMapStateProps {
  return getForwardDriftModel(state, rfqId, strategyId, selectors);
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId, strategyId }: ForwardDriftCellsOwnProps,
): ForwardDriftCellsDispatchProps {
  return {
    onForwardDriftChanged(legId: string, forwardDrift: number | undefined) {
      dispatch(
        neosThunks.createUpdateProductAndResetDependenciesThunk(
          rfqId,
          strategyId,
          legId,
          'forwardDrift',
          forwardDrift,
        ),
      );
    },
  };
}
