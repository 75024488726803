import type { ActionContainer } from '@/util/actionContainer';
import { RfqRecomputeScope, type Way } from '../../neosModel';
import { rfqDataActionCreators } from '../rfqData/rfqActionCreators';
import { createActionWithRecompute } from './actionWithRecompute';

export function createClientWayChangedAction(rfqId: string, clientWay: Way): ActionContainer {
  return createActionWithRecompute(rfqId, RfqRecomputeScope.CHANGE_WAY, [
    rfqDataActionCreators.rfqDataCrudActions.update(rfqId, { clientWay }),
  ]);
}
