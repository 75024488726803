import type { LegData, Product } from '../../../../../../../neos/business/neosModel';
import type { Field } from './Field';

export function getFieldsArray<V extends Product | LegData, T extends keyof V>(
  legsOrProducts: V[],
  field: T,
  isSameApplicable?: boolean,
  isReadOnly?: boolean,
): Array<Field<V[T]>> {
  const isEditable = !isReadOnly;

  if (!legsOrProducts.length) {
    return [];
  }

  if (isSameApplicable) {
    const firstLegOrProduct = legsOrProducts[0];
    return [{ id: firstLegOrProduct.uuid, value: firstLegOrProduct[field], isEditable }];
  }

  return legsOrProducts.map(legOrProduct => ({
    id: legOrProduct.uuid,
    value: legOrProduct[field],
    isEditable,
  }));
}
