import type { OnyxRfq } from '@/neos/business/rfq/rfqOnyxModel';
import { formatNum } from '@/util/number/numberUtils';
import type { BlotterModelGetters } from '.';

export function getBlotterLegSizes(
  rfq: OnyxRfq,
  { getAllStrategiesExceptDeltaAdj, twoDimentionalArrayToString }: BlotterModelGetters,
) {
  const allStrategiesExceptDeltaAdj = getAllStrategiesExceptDeltaAdj(rfq);
  const sizes = allStrategiesExceptDeltaAdj.map(({ legs }) =>
    legs.map(leg => {
      const sizeType = leg?.negotiatedSize?.sizeType;
      const numberOfLots = leg.negotiatedSize?.numberOfLots;
      const notional = leg.negotiatedSize?.notional?.value;
      const notionalUnit = leg.negotiatedSize?.notional?.unit;

      if (sizeType === 'FIXED_QUANTITY' && numberOfLots !== undefined) {
        return { value: numberOfLots };
      }

      if (notional === undefined && notionalUnit === undefined) {
        return undefined;
      }

      return { unit: notionalUnit, value: notional };
    }),
  );
  return twoDimentionalArrayToString(
    sizes,
    v => (v.unit ? `${v.unit} ` : '') + (v.value === undefined ? 'N/A' : formatNum(v.value)),
  );
}
