import type { StateMap } from '@/util/collectionHelper';
import type { PriceUnitType, SecondaryEvent, Status } from '../../../neos/business/neosModel';
import type {
  BidAsk,
  ForexRate,
  OnyxFairPrice,
  OnyxGreeks,
  OnyxGroup,
  OnyxPeople,
  OnyxQuote,
  OnyxStrategy,
} from '../../../neos/business/neosOnyxModel';
import type { OnyxHedge } from './hedge/hedgeOnyxModel';

export * from './quotes/quoteOnyxModel';
export * from './strategy/strategyOnyxModel';
export * from './fairPrice/fairPriceOnyxModel';
export * from './forexRates/forexRatesOnyxModel';
export * from './reference/referenceOnyxModel';
export * from './hedge/hedgeOnyxModel';

export type OnyxQuoteType = 'INDICATIVE' | 'TRADABLE';
export type Workflow = 'NEOS' | 'ONYX' | 'COBALT' | 'SKIPPER';
type StrategyId = string;

export interface PriceWithUnit {
  unit?: string;
  value?: number;
  type?: PriceUnitType;
}

export interface PriceWithCurrencyUnit extends PriceWithUnit {
  type?: 'CCY';
}

export interface PriceWithPercentUnit extends PriceWithUnit {
  type?: 'REF_PERCENT';
}

export type Source = Mdp | VoiceLike | BiddingQis;

export type Mdp =
  | 'FIX_ALADDIN'
  | 'FIX_TRADEWEB'
  | 'FIX_RFQHUB'
  | 'FIX_BLOOMBERG'
  | 'BOT_SYMPHONY_BFAM';
export type VoiceLike = 'VOICE' | 'AMER_INSURANCE';
export type BiddingQis = 'BIDDING' | 'QIS';

export function isVoiceLikeSource(source: Source): source is VoiceLike {
  return source === 'AMER_INSURANCE' || source === 'VOICE';
}

export function isBiddingQisLikeSource(source: Source): source is BiddingQis {
  return source === 'BIDDING' || source === 'QIS';
}

export function mapMdpSourceToBadgeText(mdpSource: Mdp) {
  const mdpNames: Record<Mdp, string> = {
    FIX_ALADDIN: 'AL',
    FIX_TRADEWEB: 'TW',
    FIX_RFQHUB: 'RH',
    FIX_BLOOMBERG: 'BB',
    BOT_SYMPHONY_BFAM: 'BF',
  };
  return mdpNames[mdpSource] ?? 'MDP'; // in case conversion not supported, we default to "MDP"
}

export function mapSourceToBadgeText(source: Source): string {
  switch (source) {
    case 'AMER_INSURANCE':
      return 'AI';
    case 'BIDDING':
    case 'QIS':
      return 'Auct';
    case 'VOICE':
      return '';
    default:
      return mapMdpSourceToBadgeText(source);
  }
}

export function mapMdpSourceToName(mdpSource: Mdp) {
  const mdpNames: Record<Mdp, string> = {
    FIX_ALADDIN: 'Aladdin',
    FIX_TRADEWEB: 'TradeWeb',
    FIX_RFQHUB: 'Rfq-Hub',
    FIX_BLOOMBERG: 'Bloomberg MDP',
    BOT_SYMPHONY_BFAM: 'BFAM bot',
  };
  return mdpNames[mdpSource] ?? 'MDP'; // in case conversion not supported, we default to "MDP"
}

export type SpreadWidth = 'UNKNOWN' | 'TIGHT' | 'AVERAGE' | 'WIDE';

export type Axe = 'UNKNOWN' | 'BETTER_BID' | 'CENTERED' | 'BETTER_ASK';

export interface OnyxTradedAwayFeedback {
  price?: PriceWithUnit;
  spreadWidth?: SpreadWidth;
  axe?: Axe;
}

export type ActivityType = 'PRIMARY' | 'SECONDARY' | 'MIXED';
export type RfqWarningTypes = 'LEG_BIG_NOMINAL';

export type RfqWarnings = {
  [rfqWarningType in RfqWarningTypes]: {
    [id: string]: string;
  };
};

export interface OnyxRfq {
  bookingValidation?: { warnings: string };
  internal: boolean | undefined;
  source: Source;
  uuid: string;
  version: number;
  workflow: Workflow;
  tradeDate?: string;
  salesCounterparty?: OnyxCounterparty;
  strategies: OnyxStrategy[];
  salesDiffusion?: OnyxSalesDiffusion;
  quoteType?: OnyxQuoteType;
  lifecycle: OnyxLifecycle;
  forexRates?: ForexRate[];
  comment?: string;
  chatComment?: string;
  clientComment?: string;
  notional?: PriceWithUnit;
  notionalInSwapCurrency?: PriceWithUnit;
  pivotNotional?: PriceWithUnit;
  usdNotional?: PriceWithUnit;
  eurNotional?: PriceWithUnit;
  quoteRecap?: string;
  tradeRecap?: string;
  crossBorder?: boolean;
  fairPrice?: OnyxFairPrice;
  quote?: OnyxQuote;
  greeksAggregationByUnderlyingId?: StateMap<OnyxGreeks>;
  deltas?: OnyxStrategy[];
  totalSalesMarginAmount?: BidAsk;
  totalSalesMarginAmountEur?: BidAsk;
  totalPremiums?: BidAsk;
  aggregatedDataByPriceUnit?: StateMap<AggregatedDataByPriceUnit>;
  dataAggregationByUnderlyingId?: StateMap<DataAggregationByUnderlyingId>;
  pdcs?: string[];
  priceRequestUTCDate?: string | undefined;
  hedges?: OnyxHedge[];
  screenPrice?: BidAsk;
  orderReceivedExpirationUTCTimestamp?: string;
  rfqExpirationUTCTimestamp?: string;
  hasQuoteExpirationTime?: boolean;
  quoteExpiryUTCTime?: string;
  quoteValidityDuration?: number;
  hasToConfirmDelta?: boolean;
  tradedAwayFeedback?: OnyxTradedAwayFeedback;
  activity?: ActivityType;
  secondaryEventType?: SecondaryEvent;
  salesCreditBookingReference?: { application: 'XONE'; id: string };
  salesLink?: boolean;
  salesMarginAmountEurByAllocation?: Record<string, BidAsk>;
  quoteInBasis?: OnyxQuote;
  quoteInCurrency?: OnyxQuote;
  quoteInPercent?: OnyxQuote;
  legQuotes?: Record<string, UnitTypeQuotes>;
  strategyQuotes?: Record<StrategyId, UnitTypeQuotes>;
  refSpotInSwapCurrency?: Record<StrategyId, PriceWithUnit>;
  rfqPricingExpirationUTCTimestamp?: string;
  warnings?: RfqWarnings;
  manualSalesCredit?: PriceWithUnit;
  totalCommissionAmountEur?: number;
  initiatedByTrader?: boolean;
}

type SimpleQuote = { salesPrice: BidAsk; traderPrice: BidAsk };

export type UnitTypeQuotes = Record<PriceUnitType, SimpleQuote | undefined>;

export interface DataAggregationByUnderlyingId {
  vegaNotional?: PriceWithUnit;
  greeks: {
    delta?: PriceWithUnit;
  };
  fairGreeks?: {
    delta?: PriceWithUnit;
  };
}

export interface AggregatedDataByPriceUnit {
  quote: OnyxQuote;
  totalPremium: BidAsk;
  weight: number;
}

export interface OnyxLifecycle {
  status: Status;
  subStatus?: OnyxSubStatus;
  updater?: string;
  creationTime?: string;
  updateTime?: string;
  orderReceivedUTCTimestamp?: string;
}

export type OnyxStatus =
  | 'CAPTURED'
  | 'PRICE_REQUESTED'
  | 'BEING_PRICED'
  | 'PRICED'
  | 'PRICE_PROPOSED'
  | 'ORDER_RECEIVED'
  | 'ORDER_ACCEPTED'
  | 'CANCELLED'
  | 'BEING_BOOKED'
  | 'BOOKED';

export type OnyxSubStatus =
  | 'ABORTED'
  | 'NO_QUOTE'
  | 'NO_DEAL'
  | 'NO_DEAL_WITH_SG'
  | 'DEAL_CANCEL'
  | 'NONE'
  | 'TECHNICAL_ABORTED';

export type SalesDiffusionFeatureToggles =
  | {
      toggles: {
        [name: string]: boolean;
      };
    }
  | undefined;

export interface OnyxSalesDiffusion {
  salesInit?: OnyxPeople;
  salesValo?: OnyxPeople;
  salesGroup?: OnyxGroup;
  salesInitCityLocation?: string;
  featureToggles?: SalesDiffusionFeatureToggles;
}

export interface OnyxCounterparty {
  id: number;
  mnemo?: string;
  name?: string;
  eliotCode?: string;
}

export interface OnyxIbChatRequest {
  chat: string;
  type: 'CHAT';
  rfq: OnyxRfq;
}

export interface OnyxIbChatResponse {
  chat: string;
  rfq: OnyxRfq | undefined;
  messages: string[] | undefined;
  trustLevel: number | undefined;
}

export interface OnyxTraderOrSalesMailResponse {
  id: number;
  description: string;
  name: string;
  diffusionUsers: OnyxDiffusionUsers[];
  email: string;
}

export interface OnyxDiffusionUsers {
  id: string;
  email: string;
}
