import { forwardRef, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

export interface SwitchProps {
  checked: boolean;
  labelName?: string;
  switchClassName?: string;
  disabled?: boolean;
  e2eId?: string;
  onChange?: () => void;
}

export const Switch = forwardRef<HTMLDivElement, SwitchProps>(
  (
    { checked, e2eId, labelName, onChange, switchClassName = '', disabled = false, ...rest },
    ref,
  ) => {
    const id = useRef(uuidv4()).current;

    return (
      <div
        className={`${switchClassName} form-check mb-0 form-switch form-switch-colored align-items-center`}
        ref={ref}
        {...rest}
      >
        <input
          id={id}
          disabled={disabled}
          checked={checked}
          onChange={onChange}
          className={`form-check-input`}
          type="checkbox"
          role="switch"
          data-e2e={e2eId}
        />
        {labelName !== undefined && (
          <label className="form-check-label ms-2" htmlFor={id}>
            {labelName}
          </label>
        )}
      </div>
    );
  },
);
