import type { OnyxCounterparty } from '@/neos/business/rfq/rfqOnyxModel';
import type { Counterpart } from '../../../../../neos/business/neosModel';

export const fromCounterpartMappers = { mapFromOnyxCounterpart };

// this mapper exist only to discard unwanted value from server
function mapFromOnyxCounterpart({ id, eliotCode, mnemo, name }: OnyxCounterparty): Counterpart {
  return {
    id,
    eliotCode: eliotCode ?? '',
    mnemo: mnemo ?? '',
    name: name ?? '',
  };
}
