import type {
  DividendLegPeriodDates,
  EquityLegPeriodDates,
  RateLegPeriodDates,
} from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel';

export const equityLegPeriodDatesFields: EquityLegPeriodDates = {
  startDate: 'Start Date',
  endDate: 'End Date',
  paymentDate: 'Pay Date',
};

export const dividendLegPeriodDatesFields: DividendLegPeriodDates = {
  ...equityLegPeriodDatesFields,
  theoreticalPeriodPaymentDate: 'Theoretical Payment Date',
};

export const rateOverNightLegPeriodDatesFields: RateLegPeriodDates = {
  firstFixingDate: 'First Fixing Date',
  fixingDate: 'Last Fixing Date',
  ...equityLegPeriodDatesFields,
};

export const rateLegPeriodDatesFields: RateLegPeriodDates = {
  fixingDate: 'Fixing Date',
  ...equityLegPeriodDatesFields,
};
