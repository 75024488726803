import { createActionContainerMiddleware } from '@/util/actionContainer/actionContainerMiddleware';
import { logger } from '@/util/logging/logger';
import { isDefined } from '@/util/undefinedAndNull/isDefined';
import type { Middleware } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { createAnalyticMiddleware } from './analytics';
import type { ThunkEpicMiddleware } from './epics/epicsMiddleware';
import { createErrorHandlingMiddleware } from './errorHandling/errorHandlingMiddleware';
import { createLoggingMiddleware } from './logging/loggingMiddleware';
import { createThunkLoggerMiddleware } from './thunkLoggerMiddleware';
import { createThunkMiddleware } from './thunks/thunkMiddleware';

export function createMiddlewares() {
  const batchActionsMiddleware = createActionContainerMiddleware();
  const errorHandlingMiddleware = createErrorHandlingMiddleware();
  const thunkMiddlewareLogger = createThunkLoggerMiddleware();

  const thunkMiddleware = createThunkMiddleware();
  const epicMiddleware = createEpicMiddleware() as ThunkEpicMiddleware;
  const loggingMiddleware = createLoggingMiddleware(logger);
  const analyticMiddleware = createAnalyticMiddleware();

  const middlewares: Array<Middleware | undefined> = [
    batchActionsMiddleware,
    errorHandlingMiddleware,
    thunkMiddlewareLogger,
    thunkMiddleware,
    epicMiddleware,
    loggingMiddleware,
    analyticMiddleware,
  ];

  return { middlewares: middlewares.filter(isDefined), epicMiddleware };
}
