import type { Thunk } from '@/bootstrap/thunks';

export function createCopyCurrentTypeaheadInActiveTypeahead(): Thunk {
  return function copyCurrentTypeaheadInActiveTypeahead(
    dispatch,
    getState,
    {
      actionCreators: {
        neos: { changeBlotterActiveMultipleCriterias },
      },
      selectors: { getBlotterTypeaheadValue },
    },
  ) {
    const state = getState();
    const currentTypeaheadValues = getBlotterTypeaheadValue(state.blotter);
    dispatch(changeBlotterActiveMultipleCriterias(currentTypeaheadValues));
  };
}
