import { Switch } from '@/neos/components/share/switch/Switch';
import { useDispatch, useSelector } from 'react-redux';

import { actionCreators } from '@/bootstrap/actions';
import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { thunks } from '@/bootstrap/thunks';
import { ElsFeaturesContext } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures';
import { ElsBlocField } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/ElsBlocField';
import { useContext } from 'react';
import { isElsProduct } from '../../../../../../../business/neosModel';

export function ComponentSecurityIndexAnnex() {
  const { rfqId, strategyId } = useContext(ElsFeaturesContext);
  const dispatch = useDispatch();
  const product = useSelector((state: AppState) =>
    selectors.getStrategyProduct(state, strategyId, selectors),
  );
  if (!isElsProduct(product)) {
    return null;
  }
  const { uuid, componentSecurityIndexAnnex } = product;
  const { update: updateProduct } = actionCreators.neos.productCrudActions;
  const onComponentSecurityIndexAnnex = () => {
    dispatch(updateProduct(uuid, { componentSecurityIndexAnnex: !componentSecurityIndexAnnex }));
    dispatch(thunks.neos.createDefaultConfirmMediaRequested(rfqId));
  };

  return (
    <ElsBlocField
      label={{ type: 'tooltip', content: 'CSIA', tooltip: 'Component Security Index Annex' }}
      renderInput={readOnly => (
        <Switch
          disabled={readOnly}
          switchClassName="text-center"
          data-e2e="other-section-csia"
          checked={!!componentSecurityIndexAnnex}
          onChange={() => onComponentSecurityIndexAnnex()}
        />
      )}
    />
  );
}
