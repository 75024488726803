import type { Thunk } from '@/bootstrap/thunks';

export function createSetNegoOrPostNegoScreenThunk(rfqId: string): Thunk {
  return function setNegoOrPostNegoScreenThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { rfqUiCrudActions },
      },
    },
  ) {
    const state = getState();
    const isOtcRFQ = selectors.isMasterStrategyOtc(state, rfqId, selectors);
    if (
      !(
        (selectors.isFeatureToggleEnabled(state, 'neos.allocs.enabled') && !isOtcRFQ) ||
        (selectors.isFeatureToggleEnabled(state, 'neos.otc.allocs.enabled') && isOtcRFQ)
      )
    ) {
      return;
    }

    const { status } = selectors.getRfqData(state, rfqId);
    const statusWherePostNegoShouldBeDisplayed = [
      'TRADED',
      'ORDER_MANUALLY_CROSSED',
      'ORDER_CONFIRMED',
      'BOOKING_REQUESTED',
      'PRE_TRADE_BOOKING_REQUESTED',
    ];
    if (!statusWherePostNegoShouldBeDisplayed.includes(status)) {
      return;
    }

    const doNotForcePostNego = selectors.isPostNegoStepAlreadyAutomaticallySet(state.ui, rfqId);

    if (doNotForcePostNego) {
      return;
    }

    const listedStrategies = selectors.getListedExecutionStrategies(state, rfqId, selectors);
    const otcStrategies = selectors.getOtcExecutionStrategies(state, rfqId, selectors);
    const hasNoExecutionStrategies =
      !listedStrategies.strategyLegs.length &&
      !listedStrategies.deltaLegs.length &&
      !otcStrategies.strategyLegs.length &&
      !otcStrategies.deltaLegs.length;

    if (hasNoExecutionStrategies) {
      return;
    }

    dispatch(
      rfqUiCrudActions.patchOrInsert(rfqId, {
        isPostNegoStepAlreadyAutomaticallySet: true,
        selectedNegoStep: 'POST_NEGO',
      }),
    );
  };
}
