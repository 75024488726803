import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { PriceWithUnit } from '@/neos/business/rfq/rfqOnyxModel';
import type { PriceUnitType } from '../../../../neos/business/referenceData/referenceDataModel';
import type { ParentId } from '../models';
import type { FairPrice } from './fairPriceModel';
import type { OnyxFairPrice } from './fairPriceOnyxModel';

export const toFairPriceMappers = { mapToOnyxFairPrice };
export const fromFairPriceMappers = { mapFromOnyxFairPrice };
function mapToOnyxFairPrice(
  state: AppState,
  fairPriceId: string | undefined,
  selectors: Selectors,
): OnyxFairPrice | undefined {
  if (!fairPriceId) {
    return undefined;
  }

  const fairPrice = selectors.getFairPrice(state, fairPriceId);
  const price = getPriceWithUnit(fairPrice.mid, fairPrice.midUnit, fairPrice.midPriceType);
  const volPricing = fairPrice.volatility;
  const delta = getPriceWithUnit(fairPrice.delta, fairPrice.deltaUnit);
  const gamma = fairPrice.gamma;
  const vega = getPriceWithUnit(fairPrice.vega, fairPrice.vegaUnit);
  const theta = getPriceWithUnit(fairPrice.theta, fairPrice.thetaUnit);
  const spot = getPriceWithUnit(fairPrice.spot, fairPrice.spotUnit);
  const initialPrice = getPriceWithUnit(
    fairPrice.initialPrice,
    fairPrice.initialPriceUnit,
    fairPrice.initialPriceType,
  );

  if (!price && !volPricing && !delta && !gamma && !vega && !theta && !spot) {
    return undefined;
  }

  const onyxFairPrice: OnyxFairPrice = {
    price,
    volPricing,
    greeks:
      delta || gamma || vega || theta
        ? {
            delta,
            gamma,
            vega,
            theta,
          }
        : undefined,
    spot,
    initialPrice,
    premium: fairPrice.premium,
  };

  return onyxFairPrice;
}

function getPriceWithUnit(
  value: number | undefined,
  unit: string | undefined,
  unitType?: PriceUnitType,
): PriceWithUnit | undefined {
  if (value === undefined || !unit) {
    return undefined;
  }
  return { value, unit, type: unitType };
}

function mapFromOnyxFairPrice(parentId: ParentId, onyxFairPrice?: OnyxFairPrice): FairPrice {
  if (!onyxFairPrice) {
    return { parentId, midPriceType: undefined };
  }
  const { price, greeks, volPricing: volatility, spot, initialPrice } = onyxFairPrice;
  const { delta, gamma, theta, vega } = greeks
    ? greeks
    : { delta: undefined, gamma: undefined, theta: undefined, vega: undefined };
  return {
    parentId,
    mid: price?.value,
    midUnit: price?.unit,
    midPriceType: price?.type,
    delta: delta?.value,
    deltaUnit: delta?.unit,
    gamma,
    theta: theta?.value,
    thetaUnit: theta?.unit,
    vega: vega?.value,
    vegaUnit: vega?.unit,
    volatility,
    spot: spot?.value,
    spotUnit: spot?.unit,
    initialPrice: initialPrice?.value,
    initialPriceUnit: initialPrice?.unit,
    initialPriceType: initialPrice?.type,
    premium: onyxFairPrice.premium,
  };
}
