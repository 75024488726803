import type { Thunk } from '@/bootstrap/thunks';
import { internalGetErrorId } from '../../../../mappers/error/internal/errorIdGenerator';
import type { OnyxFieldError } from '../../../../mappers/error/onyxErrorModel';
import type { ForwardStartDateFeatureKey } from '../featureModel';

export function createChangeForwardStartDate(
  rfqId: string,
  featureKey: ForwardStartDateFeatureKey,
  forwardStartDate: string | undefined,
): Thunk {
  return function changeForwardStartDate(
    dispatch,
    getState,
    {
      selectors: { getRfqData, getStrategyData },
      actionCreators: {
        neos: { errorCrudActions },
      },
      thunks: {
        neos: {
          createDefaultForwardStartDateRequested,
          createChangeFeatureFieldThunk,
          createHandleErrorsThunk,
        },
      },
    },
  ) {
    const state = getState();
    const { strategyIds } = getRfqData(state, rfqId);
    const { legIds } = getStrategyData(state, featureKey.strategyId);
    const errorReset = legIds.map(legId =>
      errorCrudActions.delete(
        internalGetErrorId('forwardStartDate')({
          legId,
          strategyId: featureKey.strategyId,
        }),
      ),
    );
    const chainOptions = {
      success: {
        dispatchables: errorReset,
      },
      error: {
        dispatchables: [
          createHandleErrorsThunk(
            rfqId,
            getLegForwardDateFieldErrors(legIds, strategyIds, featureKey),
          ),
          createChangeFeatureFieldThunk(featureKey, { forwardStartDate: undefined }),
        ],
      },
    };

    dispatch(
      createChangeFeatureFieldThunk(featureKey, { forwardStartDate }),
      createDefaultForwardStartDateRequested(rfqId, chainOptions),
    );
  };
}

function getLegForwardDateFieldErrors(
  legIds: string[],
  strategyIds: string[],
  featureKey: ForwardStartDateFeatureKey,
): OnyxFieldError[] {
  return legIds.map((_, index) => ({
    field: `rfq.strategies[${strategyIds.indexOf(
      featureKey.strategyId,
    )}].legs[${index}].product.forwardStartDate`,
    message: 'Due to defaulting service error, forward start date has been reset',
  }));
}
