import type { OnyxElectronicMediaUser } from './electronicMediaUserModel';
import { electronicMediaUsersDomain } from './electronicMediaUsersDomain';

export interface ElectronicMediaUsersRequestedAction {
  type: 'ELECTRONIC_MEDIA_USERS_REQUESTED';
  counterpartId: number;
}

function createElectronicMediaUsersRequestedAction(
  counterpartId: number,
): ElectronicMediaUsersRequestedAction {
  return {
    type: 'ELECTRONIC_MEDIA_USERS_REQUESTED',
    counterpartId,
  };
}
export interface ElectronicMediaUsersReceivedAction {
  type: 'ELECTRONIC_MEDIA_USERS_RECEIVED';
  counterpartId: number;
  electronicMediaUsers: OnyxElectronicMediaUser[];
}

function createElectronicMediaUsersReceivedAction(
  counterpartId: string,
  electronicMediaUsers: OnyxElectronicMediaUser[],
) {
  return electronicMediaUsersDomain.actions.upsert(counterpartId, electronicMediaUsers);
}

export const electronicMediaUsersActionCreators = {
  ...electronicMediaUsersDomain.actions,
  createElectronicMediaUsersReceivedAction,
  createElectronicMediaUsersRequestedAction,
};
