import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ClientPosition } from '../../../../../../../neos/business/neosModel';
import type { Field } from '../utils/Field';
import { getIsStrategyDefinitionFieldEditable } from '../utils/fieldsEnablingConditions';
import { getFieldsArray } from '../utils/getFieldsArray';

export interface ClientPositionCellsModel {
  clientPositions: Array<Field<ClientPosition | undefined>>;
  availablePositions: Array<ClientPosition | undefined>;
}

export function getClientPositionCellsModel(
  appState: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): ClientPositionCellsModel {
  const { getStrategyData } = selectors;
  const { isUsListed, getStrategyDefinition } = selectors;

  const _isUsListed = isUsListed(appState, strategyId, selectors);

  if (!_isUsListed) {
    return {
      clientPositions: [],
      availablePositions: [],
    };
  }

  const { strategyType } = getStrategyData(appState, strategyId);
  const { readOnlyProductClientPosition } = getStrategyDefinition(
    appState.referenceData,
    strategyType,
  );

  const { legIds } = selectors.getStrategyData(appState, strategyId);

  const legsData = legIds.map(legId => selectors.getLegData(appState, legId));

  const ClientPositionsAreEditable = getIsStrategyDefinitionFieldEditable(
    appState,
    rfqId,
    strategyId,
    selectors,
    ['RFQ_SCOPE', 'RFQ_EDITABLE', 'STRATEGY_EDITABLE'],
  );

  const isReadonlyAtWorkflow = selectors.isReadOnlyAtCurrentWorkflow(
    appState,
    strategyId,
    selectors,
  );
  const isReadonly =
    !ClientPositionsAreEditable || readOnlyProductClientPosition || isReadonlyAtWorkflow;

  const clientPositions = getFieldsArray(legsData, 'clientPosition', false, isReadonly);

  return {
    clientPositions,
    availablePositions: ['OPEN', 'CLOSE', undefined],
  };
}
