import { actionCreators } from '@/bootstrap/actions';
import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { StrategyRecomputeScope } from '@/neos/business/rfq/models/recomputeModel';
import { NumericInput } from '@/neos/components/share/numericInput';
import { useDispatch, useSelector } from 'react-redux';
import { getPtmmPriceModel } from './getPtmmPriceModel';

interface PtmmPriceProps {
  rfqId: string;
  legId: string;
}

export function PtmmPrice({ rfqId, legId }: PtmmPriceProps) {
  const dispatch = useDispatch();

  const model = useSelector((state: AppState) => getPtmmPriceModel(state, legId, selectors));

  function onPreTradeMidMarketMarkPriceChanged(value: number | undefined) {
    if (!model) {
      return;
    }

    dispatch(
      actionCreators.neos.quoteCrudActions.update(model.quoteId, {
        preTradeMidMarketMarkPrice: value,
      }),
    );

    dispatch(
      actionCreators.neos.createRecomputeRfqRequestedAction(
        rfqId,
        StrategyRecomputeScope.CHANGE_PTMMM,
      ),
    );
  }

  if (!model) {
    return null;
  }

  return (
    <div>
      <label className="mb-0 me-3">PTMM</label>
      <NumericInput
        value={model.preTradeMidMarketMarkPrice}
        unit={model.preTradeMidMarketMarkPriceUnit}
        onBlur={onPreTradeMidMarketMarkPriceChanged}
        data-e2e="leg-ptmm-price"
      />
    </div>
  );
}
