import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import type { NegoStep } from '@/neos/business/ui/rfq/rfqUiModel';
import { useDispatch, useSelector } from 'react-redux';

export type ElsSwitcherProps = {
  rfqId: string;
};

export const ElsSwitcher = ({ rfqId }: ElsSwitcherProps) => {
  const dispatch = useDispatch<ExtendedDispatch>();
  const currentStep = useSelector((state: AppState) =>
    selectors.getSelectedNegoStep(state.ui, rfqId),
  );
  const targetStep: NegoStep =
    currentStep === 'NEGO' || currentStep === 'POST_NEGO' ? 'ELS_DETAILS' : 'NEGO';

  function onStepChanged(targetStep: NegoStep) {
    dispatch(
      createLogAnalyticsAction('NEOS RFQ', 'Switch to target step', targetStep),
      neosThunks.createNeosSwitchNegoStepThunk(rfqId, targetStep),
    );
  }

  return (
    <button
      data-e2e="neos-switch-els-step"
      className="btn btn-primary text-nowrap"
      onClick={() => onStepChanged(targetStep)}
    >
      {targetStep === 'NEGO' ? 'Negotiation' : 'ELS Details'}
    </button>
  );
};
