import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { StrategyTypeWithLabel } from '@/neos/business/referenceData/referenceDataSelectors';
import type { StrategyType } from '@/neos/business/referenceData/strategyDefinition/strategyDefinitionModel';
import type { Shortcut } from '@/neos/components/rfq/shortcuts/shared/ShortcutButton';

export interface StrategyPickerModel {
  strategiesList: Shortcut<StrategyType>[];
  availableStrategyTypes: StrategyTypeWithLabel[];
}

export function getStrategyPickerModel(state: AppState, selectors: Selectors): StrategyPickerModel {
  const userPreferencesState = state.ui.userPreferences;
  const strategiesList = selectors.getStrategiesShortcutsList(userPreferencesState);
  return {
    strategiesList,
    availableStrategyTypes: selectors
      .getAvailableStrategies(state.referenceData, 'RFQ', selectors)
      .filter(strategyType => !strategiesList.map(s => s.id).includes(strategyType.id)),
  };
}
