import { mapFromOnyxProductCommon } from './commonMapper';
import {
  fromCustomUnderlyingProductMappers,
  toCustomUnderlyingProductMappers,
} from './cutomUnderlyingProductMapper';
import {
  fromDerivativeProductMappers,
  toDerivativeProductMappers,
} from './derivativeProductMapper';
import { fromNegotiationMappers, toNegotiationMappers } from './negotiationMapper';
import { mapFromOnyxStrike, mapToOnyxStrike } from './strikeMapper';
import { mapFromOnyxUpperLowerStrike, mapToOnyxUpperLowerStrike } from './upperLowerStrikeMapper';

export const fromPartialProductMappers = {
  mapFromOnyxStrike,
  mapFromOnyxProductCommon,
  mapFromOnyxUpperLowerStrike,
  ...fromDerivativeProductMappers,
  ...fromNegotiationMappers,
  ...fromCustomUnderlyingProductMappers,
};
export const toPartialProductMappers = {
  mapToOnyxStrike,
  mapToOnyxUpperLowerStrike,
  ...toDerivativeProductMappers,
  ...toNegotiationMappers,
  ...toCustomUnderlyingProductMappers,
};

export type FromPartialProductMappers = typeof fromPartialProductMappers;
export type ToPartialProductMappers = typeof toPartialProductMappers;
