import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';

export function getIsSpotWarningDisplayed(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): boolean {
  const isStatusAtOrAfterOrderAccepted = selectors.isStatusAtOrAfterOrderAccepted(
    state,
    rfqId,
    selectors,
  );

  if (!isStatusAtOrAfterOrderAccepted) {
    return false;
  }

  const isElsSpotConfirmed = selectors.getIsElsSpotConfirmed(state, rfqId, selectors);

  return isElsSpotConfirmed === false;
}
