import type { Thunk } from '@/bootstrap/thunks';
import { orderOf } from '../services/statusOrder';

export function createAutoSelectDeltaAdjOrRefTabThunk(rfqId: string): Thunk {
  return function autoSelectDeltaAdjOrRefTab(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { rfqUiCrudActions },
      },
    },
  ) {
    const appState = getState();
    const { deltaHedgingStrategyIds, status } = selectors.getRfqData(appState, rfqId);

    const hasDeltaAdjusted = deltaHedgingStrategyIds.some(strategyId =>
      selectors.isDeltaHedgingStrategyAdjusted(appState, strategyId, selectors),
    );

    if (status === 'DELTA_MANUALLY_EXECUTED') {
      dispatch(rfqUiCrudActions.patchOrInsert(rfqId, { isDeltaAdjustedSelected: false }));
    } else if (hasDeltaAdjusted && orderOf(status).isAfterOrEqual('ORDER_ACCEPTED')) {
      dispatch(rfqUiCrudActions.patchOrInsert(rfqId, { isDeltaAdjustedSelected: true }));
    }
  };
}
