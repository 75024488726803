import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { connect } from 'react-redux';
import {
  ClientCommentComponent,
  type ClientCommentDispatchProps,
  type ClientCommentOwnPropsProps,
  type ClientCommentStateProps,
} from './ClientComment';

export const ClientComment = connect(mapStateToProps, mapDispatchToProps)(ClientCommentComponent);

function mapStateToProps(
  state: AppState,
  { rfqId }: ClientCommentOwnPropsProps,
): ClientCommentStateProps {
  const { clientComment } = selectors.getRfqData(state, rfqId);
  return {
    clientComment,
  };
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId }: ClientCommentOwnPropsProps,
): ClientCommentDispatchProps {
  return {
    onCommentChanged: (clientComment: string) => {
      dispatch(neosActionCreators.rfqDataCrudActions.update(rfqId, { clientComment }));
    },
  };
}
