import type { ChainOptions } from '../neosActions';
import type { UnderlyingInfoRequestedAction } from './underlyingInfoActions';
import { underlyingInfoDomain } from './underlyingInfoDomain';

export const underlyingInfoActionCreators = {
  underlyingInfoCrudActions: underlyingInfoDomain.actions,
  createUnderlyingInfoRequestedAction,
};

function createUnderlyingInfoRequestedAction(
  rfqIds: string[],
  underlyingIds: string[],
  chainOptions?: ChainOptions,
): UnderlyingInfoRequestedAction {
  return {
    type: 'UNDERLYING_INFO_REQUESTED',
    rfqIds,
    underlyingIds,
    chainOptions,
  };
}
