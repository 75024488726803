import type { Thunk } from '@/bootstrap/thunks';
import { DefaultingScope } from '../models';
import { LegRecomputeChangedScope, getLegRecomputeScope } from '../models/recomputeModel';
import { DefaultingOptions } from '../rfqActions';
import { type Product, isListedProduct } from '../strategy/leg/legModel';

export function createRfqChangeMarketLotSizeThunk(
  rfqId: string,
  strategyId: string,
  legId: string,
  lotSizeMarket: string | undefined,
): Thunk {
  return function rfqChangeMarketLotSizeThunk(
    dispatch,
    getState,
    {
      selectors: { getStrategyData, getLegData, getProduct },
      thunks: {
        neos: { createRecomputeRfqThunk, createUpdateProductLotSizeMarketThunk },
      },
      actionCreators: {
        neos: { createDefaultRfqRequestedAction, createRecomputeRfqRequestedAction },
      },
    },
  ) {
    const state = getState();
    const { isMasterStrategy, scope: deltaStategyScope } = getStrategyData(state, strategyId);
    const { isMaster: isMasterLeg, productId } = getLegData(state, legId);
    const product: Product = getProduct(state, productId);
    const currentLotSize: number | undefined = product.lotSize;
    const currentMarket = isListedProduct(product) ? product.marketExchangeId : undefined;

    const overrideScopes = [DefaultingScope.MARKET_CODE, DefaultingScope.AGGREGATED_FAIR_PRICES];
    const scope = LegRecomputeChangedScope.QUANTITY;

    const options = new DefaultingOptions({
      enrichScopes: [DefaultingScope.HEDGES],
      overrideScopes,
      overrideIfInvalidScopes: [DefaultingScope.OPTION_STYLE],
    });
    const isDeltaStrategy = deltaStategyScope !== 'RFQ';

    if ((currentLotSize === undefined && currentMarket === undefined) || isListedProduct(product)) {
      dispatch(
        createUpdateProductLotSizeMarketThunk(
          strategyId,
          legId,
          lotSizeMarket,
          createDefaultRfqRequestedAction(rfqId, options, {
            success: {
              dispatchables: [
                createRecomputeRfqRequestedAction(
                  rfqId,
                  getLegRecomputeScope({
                    scope,
                    isMasterStrategy,
                    isMasterLeg,
                    isDeltaStrategy,
                  }),
                ),
              ],
            },
          }),
        ),
      );
    } else {
      dispatch(
        createRecomputeRfqThunk(
          rfqId,
          getLegRecomputeScope({
            scope,
            isMasterStrategy,
            isMasterLeg,
            isDeltaStrategy,
          }),
          createUpdateProductLotSizeMarketThunk(strategyId, legId, lotSizeMarket),
        ),
      );
    }
  };
}
