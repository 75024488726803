import type { Thunk } from '@/bootstrap/thunks';

export function createNeosCloneStrategyThunk(rfqId: string, strategyId: string): Thunk {
  return function neosCloneStrategyThunk(
    dispatch,
    _,
    {
      thunks: {
        neos: { createRfqCloneStrategyThunk, createStrategyUiClonedThunk },
      },
      services: { idCreator },
    },
  ) {
    const newId = idCreator.createId();
    dispatch(
      createRfqCloneStrategyThunk(rfqId, strategyId, newId),
      createStrategyUiClonedThunk(strategyId, newId),
    );
  };
}
