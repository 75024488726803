import type { User } from '../referenceDataModel';
import type { SalesValosReceivedAction } from './salesValoActions';

export const salesValoActionCreators = {
  createSalesValosReceivedAction,
};

export function createSalesValosReceivedAction(salesValos: User[]): SalesValosReceivedAction {
  return {
    type: 'SALES_VALOS_RECEIVED',
    salesValos,
  };
}
