import { EventEmitter } from 'events';
import { sgwtConnect as connected } from '@/util/widgets/sgwtConnect';
import type { SGWTConnectCore } from '@sgwt/connect-core';
import type { ISGWTConnectIdTokenClaims } from '@sgwt/connect-core/dist/src/SGWTConnectIdTokenClaims';
import type { AsyncLogger } from '../../logging/asyncLogger';
import { mt } from '../../logging/messageTemplates';

const extractParamFromUrl = (url: string, paramKey: string): string => {
  const param = paramKey + '=';
  const paramIndex = url.indexOf(param);
  const trimedLeft = url.substring(paramIndex + param.length);
  const endIndex = trimedLeft.indexOf('&');
  if (endIndex === -1) {
    return trimedLeft;
  }

  return trimedLeft.substring(0, endIndex);
};

const setupFakeSGConnect = (): SGWTConnectCore => {
  const evt = new EventEmitter();
  const sgConnect = {
    getIdTokenClaims: () => ({ sub: 'fake.user@sgcib.com' }) as ISGWTConnectIdTokenClaims,
    isAuthorized: () => true,
    getAuthorizationError: () => null,
    requestAuthorization: () => sgConnect,
    getAuthorizationHeader: () => {
      return 'Fake ' + extractParamFromUrl(window.location.search, 'user');
    },
    renewAuthorization: () => sgConnect,
    on: (e, l) => {
      evt.on.bind(evt)(e, l);
      return sgConnect;
    },
    emit: evt.emit.bind(evt),
  } as SGWTConnectCore;

  return sgConnect;
};

export const sgwtConnect = import.meta.env.MODE === 'test' ? setupFakeSGConnect() : connected;

let errorHandler: () => void;
let logger: AsyncLogger;

export const getAuthHeader = () => {
  const sgConnectHeader = sgwtConnect.getAuthorizationHeader();
  if (!sgConnectHeader) {
    if (logger) {
      logger.info(mt.sgconnectLog, 'getAuthHeader: Got empty Authorization header');
      sgwtConnect.renewAuthorization(error => {
        if (error) {
          logger.error(mt.sgconnecterror, 'Failed to refresh the token.', error);
        } else {
          logger.info(mt.sgconnectLog, 'Your token has been refreshed.');
        }
      });
    }
    if (errorHandler) {
      errorHandler();
    }

    return null;
  }
  return sgConnectHeader;
};

export const enhanceErrorHandler = (_handlerWithStore: () => void, _logger: AsyncLogger) => {
  errorHandler = _handlerWithStore;
  logger = _logger;
};

if (import.meta.env.MODE !== 'test') {
  const widget = document.querySelector('sgwt-connect') as any;
  if (widget) {
    if (typeof widget.setSgwtConnectInstance === 'undefined') {
      const handleSgwtConnectReady = () => {
        widget.setSgwtConnectInstance(sgwtConnect);
        widget.removeEventListener('sgwt-connect--ready', handleSgwtConnectReady);
      };

      widget.addEventListener('sgwt-connect--ready', handleSgwtConnectReady);
    } else {
      widget.setSgwtConnectInstance(sgwtConnect);
    }
  }
}
