import type { DisplayNegotiatedSize } from '@/neos/business/ui/strategy/strategyUiModel';

interface NotionalUnits {
  notionalUnit: string;
  localNotionalUnit: string;
}
export function getSizeUnit(
  uiSizeType: DisplayNegotiatedSize,
  { notionalUnit, localNotionalUnit }: NotionalUnits,
): string {
  switch (uiSizeType) {
    case 'LOCAL_NOTIONAL':
      return localNotionalUnit;
    case 'NOTIONAL':
      return notionalUnit;
    case 'QUANTITY':
      return '#';
    case 'VAR_UNIT':
      return 'VU';
    case 'NUMBER_OF_LOTS':
      return '#';
  }
}
