import type { NeosBlotterRfq } from './blotterModel';
import type { BlotterState } from './blotterState';

export const blotterSelectors = {
  getBlotterRfqById,
  getBlotterRfqs,
  getBlotterRfqsIds,
  getBlotterFrom,
  getBlotterTo,
  getBlotterUiFrom,
  getBlotterUiTo,
  getBlotterWorkflows,
  getBlotterLastNotificationDate,
  getBlotterLastRequestTime,
  getBlotterRfqSubfilter,
  getBlotterTypeaheadValue,
  getBlotterActiveTypeaheadValue,
  getBlotterMaturityDateValue,
  getBlotterMaturityDateFromValue,
  getBlotterMaturityDateToValue,
};

function getBlotterRfqs(state: BlotterState) {
  return state.rfqs;
}

function getBlotterRfqsIds(state: BlotterState): string[] {
  return getBlotterRfqs(state).map(rfq => rfq.uuid);
}

function getBlotterRfqById(state: BlotterState, rfqId: string): NeosBlotterRfq | undefined {
  return state.rfqs.find(rfq => rfq.uuid === rfqId);
}

function getBlotterFrom(state: BlotterState) {
  return state.from;
}

function getBlotterTo(state: BlotterState) {
  return state.to;
}

function getBlotterUiFrom(state: BlotterState) {
  return state.uiFrom;
}

function getBlotterUiTo(state: BlotterState) {
  return state.uiTo;
}

function getBlotterWorkflows(state: BlotterState) {
  return state.workflows;
}

function getBlotterLastNotificationDate(state: BlotterState) {
  return state.lastFilteredOutNotificationDate;
}

function getBlotterLastRequestTime(state: BlotterState) {
  return state.lastRequestTime;
}

function getBlotterRfqSubfilter(state: BlotterState) {
  return state.subfilter;
}

function getBlotterTypeaheadValue(state: BlotterState) {
  return state.typeaheadValue;
}

function getBlotterActiveTypeaheadValue(state: BlotterState) {
  return state.activeTypeaheadValues;
}

function getBlotterMaturityDateValue(state: BlotterState) {
  return state.maturityDates;
}

function getBlotterMaturityDateFromValue(state: BlotterState): string {
  return state.maturityDateFrom;
}

function getBlotterMaturityDateToValue(state: BlotterState): string {
  return state.maturityDateTo;
}
