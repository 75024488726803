import { type Observable, of, zip } from 'rxjs';
import { catchError, map, mergeMap, retry, tap } from 'rxjs/operators';
import type { OnyxCurrentUser, OnyxFeatureToggle } from './userInfoModel';
import { ofType } from 'redux-observable';
import { userInfoActionCreators } from './userInfoActionCreators';
import { commonActionCreators } from '../commonActionCreators';
import type { BridgeAppConfig } from '@/util/config/config';
import type { ThunkEpic } from '@/bootstrap/epics';
import type { Thunks } from '@/bootstrap/thunks';
import { uiActionCreators } from '@/neos/business/ui/uiActionCreators';

export interface UserInfoApi {
  getCurrentUser: () => Observable<OnyxCurrentUser>;
  getFeatureToggles: () => Observable<OnyxFeatureToggle[]>;
  getAdditionalConfig: () => Observable<BridgeAppConfig>;
}

export function retrieveUserInfo(api: UserInfoApi, { createErrorToasterThunk }: Thunks): ThunkEpic {
  return action$ =>
    action$.pipe(
      ofType('USER_INFO_REQUESTED'),
      mergeMap(({ nextDispatchables }) => {
        return zip(
          api.getCurrentUser(),
          api.getFeatureToggles().pipe(
            map(onyxToggleFeatures => ({ isError: false, result: onyxToggleFeatures })),
            catchError(_ => of({ isError: true, result: [] })),
          ),
          api.getAdditionalConfig(),
        ).pipe(
          tap(([_, __, additionalConfig]) => {
            window.sgmeConfiguration = { ...sgmeConfiguration, ...additionalConfig };
          }),
          mergeMap(([currentUser, toggleFeatureResult]) => {
            const { isError, result: toggleFeatures } = toggleFeatureResult;

            const dispatchables = [
              userInfoActionCreators.createUserInfoReceivedAction({ currentUser, toggleFeatures }),
              ...nextDispatchables,
            ];

            if (isError) {
              dispatchables.push(uiActionCreators.setIsToggleFeatureErrorDisplayed());
              dispatchables.unshift(
                createErrorToasterThunk(
                  { message: 'Error while retrieving feature toggles.' },
                  undefined,
                ),
              );
            }

            return dispatchables;
          }),
          retry(2),
          catchError(err => [
            commonActionCreators.createCriticalAppCrashAction('retrieve-user-info', err),
          ]),
        );
      }),
    );
}
