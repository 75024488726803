import type { FromOnyxMappers } from '@/neos/business/mappers';
import { convertNullToUndefined } from '@/util/undefinedAndNull/convertNullToUndefined';
import type { OptionOnFuture } from '../../productModel';
import type { OnyxSingleUnderlyingProduct } from '../../productOnyxModel';

export function mapFromOnyxProductOptionOnFuture(
  legId: string,
  onyxProduct: OnyxSingleUnderlyingProduct,
  productId: string,
  mappers: FromOnyxMappers,
): OptionOnFuture {
  const { strike, strikeUnit } = mappers.mapFromOnyxStrike(onyxProduct);
  return {
    subFamily: 'OPTION_ON_FUTURE',
    strike,
    strikeUnit,
    style: convertNullToUndefined(onyxProduct.optionStyle),
    flex: onyxProduct.optionFlexType || undefined,
    type: onyxProduct.optionType || undefined,
    ...mappers.mapFromOnyxProductCommon(legId, onyxProduct, productId),
    ...mappers.mapFromOnyxNegotiation(onyxProduct.negotiation),
    ...mappers.mapFromSingleDerivativeOnyxProduct(onyxProduct, mappers),
    hasCustomUnderlying: false,
    futureMaturity: onyxProduct.futureMaturity || undefined,
  };
}
