import type { Thunk } from '@/bootstrap/thunks';
import type { BarriersFeatureKey } from '../neosModel';
import { DefaultingScope } from '../rfq/models';
import { DefaultingOptions } from '../rfq/rfqActions';

export function createNeosChangeBarrierUnderlyingThunk(
  rfqId: string,
  featureKey: BarriersFeatureKey,
  barrierIndex: number,
  underlyingIdOrName: string | undefined,
): Thunk {
  return function neosChangeBarrierUnderlyingThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { createUnderlyingInfoRequestedAction, createDefaultRfqRequestedAction },
      },
      thunks: {
        neos: { createUpdateBarrierOfFeatureThunk },
      },
    },
  ) {
    const appState = getState();
    const { isStrategyCustomUnderlying } = selectors;
    const { getUnderlyingInfo } = selectors;

    const isCustomUnderlying = isStrategyCustomUnderlying(
      appState,
      featureKey.strategyId,
      selectors,
    );

    const isDefinedUnderlyingId = !isCustomUnderlying && !!underlyingIdOrName;

    const underlyingInfo = isDefinedUnderlyingId
      ? getUnderlyingInfo(appState, underlyingIdOrName)
      : undefined;

    const dispatchables = [
      createUpdateBarrierOfFeatureThunk(
        featureKey,
        barrierIndex,
        isCustomUnderlying
          ? {
              underlyingType: 'CUSTOM',
              underlyingName: underlyingIdOrName,
            }
          : {
              underlyingType: 'NON_CUSTOM',
              underlyingId: underlyingIdOrName,
              limitStrikeUnit: undefined,
            },
      ),
      ...[
        createDefaultRfqRequestedAction(
          rfqId,
          new DefaultingOptions({
            overrideScopes: [DefaultingScope.REFERENCE_MATURITY],
            enrichScopes: [
              DefaultingScope.FOREX,
              DefaultingScope.REFERENCE,
              DefaultingScope.SPOT_NET,
              DefaultingScope.BARRIER_UNITS,
              DefaultingScope.UNDERLYING,
            ],
          }),
        ),
      ].filter(() => isDefinedUnderlyingId),
    ];

    if (isDefinedUnderlyingId && !underlyingInfo) {
      return dispatch(
        createUnderlyingInfoRequestedAction([rfqId], [underlyingIdOrName!], {
          success: { dispatchables },
        }),
      );
    }

    dispatch(dispatchables);
  };
}
