import type { Thunk } from '@/bootstrap/thunks';
import type { BlotterPreconfStatus, NeosBlotterRfq } from '../blotterModel';

export function createIntegrateReceivedBlotterPreconfThunk(
  blotterPreconfStatus: BlotterPreconfStatus[],
): Thunk {
  return function integrateReceivedBlotterRfqsThunk(
    dispatch,
    getState,
    {
      actionCreators: {
        neos: { createBlotterRfqsReceivedAction },
      },
      selectors: { getBlotterRfqs },
    },
  ) {
    const blotterState = getState().blotter;
    const blotterRfqs = getBlotterRfqs(blotterState);

    const blotterPreconfStatusMap = buildPreconfStatusMap(blotterPreconfStatus);

    const neosBlotterRfqs: NeosBlotterRfq[] = blotterRfqs.map(rfq => {
      const preconf = blotterPreconfStatusMap[rfq.uuid];
      return {
        ...rfq,
        externalMailStatus: preconf?.externalMailStatus ?? 'NOT_SENT',
        internalMailStatus: preconf?.internalMailStatus ?? 'NOT_SENT',
      };
    });

    dispatch(createBlotterRfqsReceivedAction(neosBlotterRfqs));
  };
}

function buildPreconfStatusMap(
  blotterPreconfStatus: BlotterPreconfStatus[],
): Record<string, BlotterPreconfStatus | undefined> {
  const preconfStatusMap: Record<string, BlotterPreconfStatus | undefined> = {};

  blotterPreconfStatus.forEach(preconfStatus => {
    preconfStatusMap[preconfStatus.rfqUuid] = preconfStatus;
  });

  return preconfStatusMap;
}
