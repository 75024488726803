import type { MailStatusType } from '@/neos/business/preconfirmation/shared/preconfirmationModel';
import { NeosTooltip } from '@/neos/components/share/tooltip/NeosTooltip';
import { Popover } from 'react-bootstrap';
import type { BlotterPreconfMailStatus } from './possibleBlotterRowModel';

interface PreconfStatusCellRendererProps {
  value: BlotterPreconfMailStatus;
}

type TextKind = 'success' | 'warning' | 'secondary text-opacity-70' | 'secondary text-opacity-50';

export const PreconfStatusCellRenderer = (props: PreconfStatusCellRendererProps) => {
  const preconfStatus = props.value;
  if (!preconfStatus) {
    return <span></span>;
  }

  const bulletColor = getBulletColor(preconfStatus);

  return (
    <NeosTooltip
      placement="top"
      overlay={
        <Popover id="">
          <div className="d-flex align-items-center p-2">
            <i
              className={`icon icon-sm ms-2 text-${bulletColor} lh-1`}
              data-e2e="preconfirmation-is-email-sent-icon"
            >
              circle
            </i>
            {renderStatus('External', preconfStatus.externalMailStatus)}
            {renderStatus('Internal', preconfStatus.internalMailStatus)}
          </div>
        </Popover>
      }
    >
      <i
        className={`icon icon-sm ms-2 text-${bulletColor} display-4`}
        data-e2e="preconfirmation-is-email-sent-icon"
      >
        circle
      </i>
    </NeosTooltip>
  );
};

function renderStatus(statusType: 'External' | 'Internal', status: MailStatusType) {
  const statusColor: TextKind =
    status === 'SENT'
      ? 'success'
      : status === 'MUST_BE_RESENT'
        ? 'warning'
        : 'secondary text-opacity-70';
  return <div className={`text-${statusColor} display-5 ms-2`}>{statusType}</div>;
}

function getBulletColor({
  externalMailStatus,
  internalMailStatus,
}: BlotterPreconfMailStatus): TextKind {
  const statusList = [externalMailStatus, internalMailStatus];

  if (statusList.every(status => status === 'NOT_SENT')) {
    return 'secondary text-opacity-50';
  }

  return statusList.filter(status => status !== 'NOT_SENT').every(status => status === 'SENT')
    ? 'success'
    : 'warning';
}
