import type { Thunk } from '@/bootstrap/thunks';

export function createRequestNeosPredealCheckRefreshThunk(rfqId: string): Thunk {
  return function requestNeosPredealCheckRefreshThunk(
    dispatch,
    getState,
    {
      selectors: { getRfqMasterStrategy },
      actionCreators: {
        neos: { createNeosPredealCheckRefreshRequestedAction, createStrategySelectedAction },
      },
    },
  ) {
    const state = getState();
    const { uuid: masterStrategyId } = getRfqMasterStrategy(state, rfqId);

    dispatch(
      createStrategySelectedAction(rfqId, masterStrategyId),
      createNeosPredealCheckRefreshRequestedAction(rfqId),
    );
  };
}
