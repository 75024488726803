import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';

export interface DisplayedSimilarActivitiesModel {
  areDisplayed: true;
  gridHeight: number;
}

interface HiddenSimilarActivitiesModel {
  areDisplayed: false;
  rowsNumber: number;
}

export type SimilarActivitiesModel = DisplayedSimilarActivitiesModel | HiddenSimilarActivitiesModel;

export function getSimilarActivitiesModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): SimilarActivitiesModel {
  const areDisplayed = selectors.areSimilarActivitiesDisplayed(state.ui, rfqId);

  if (!areDisplayed) {
    const similarActivities = selectors.getSimilarActivities(state, rfqId);
    return {
      areDisplayed,
      rowsNumber: similarActivities.length,
    };
  }

  return {
    areDisplayed,
    gridHeight: selectors.getRfqSimilarActivitiesGridHeight(state.ui, rfqId),
  };
}
