import { FormSelect, Modal } from 'react-bootstrap';

import type { RfqOutdatedModal } from '@/neos/business/ui/rfq/rfqUiModel';
import { useState } from 'react';

import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import styles from './Versionning.module.scss';

export interface VersionningOwnProps {
  rfqId: string;
}

export interface VersionningMapStateToProps {
  availableVersions: Array<KeyValue<number, string>>;
  currentVersion: number;
  rfqOutdatedModalState: RfqOutdatedModal;
  isReadonlyRfq: boolean;
}

export interface KeyValue<T, U> {
  id: T;
  value: U;
}

export interface VersionningDispatchProps {
  onVersionSelected: (version: number) => void;
  onSavedWithVersion: (version: number) => void;
  onOutdatedDismiss: () => void;
}

export type VersionningProps = VersionningOwnProps &
  VersionningMapStateToProps &
  VersionningDispatchProps;

export const VersionningComponent = (props: VersionningProps) => {
  const [loadModalShow, setLoadModalShow] = useState(false);
  const [loadModalSelectedVersionNumber, setLoadModalSelectedVersionNumber] = useState(-1);
  const [loadModalSelectedVersionName, setLoadModalSelectedVersionName] = useState<string | null>(
    null,
  );

  const {
    availableVersions,
    currentVersion,
    rfqOutdatedModalState,
    onOutdatedDismiss,
    onSavedWithVersion,
    onVersionSelected,
    isReadonlyRfq: isReadOnlyRfq,
  } = props;

  const handleReloadModalShow = () => {
    const selectedVersion = props.availableVersions.find(({ id }) => id === props.currentVersion);

    if (!selectedVersion) {
      return;
    }

    setLoadModalShow(true);
    setLoadModalSelectedVersionNumber(props.currentVersion);
    setLoadModalSelectedVersionName(selectedVersion.value);
  };

  const handleLoadModalShow = (target: EventTarget & HTMLSelectElement) => {
    const loadModalSelectedVersionNumber = parseInt(target.value.toString(), 10);
    setLoadModalShow(true);
    setLoadModalSelectedVersionNumber(loadModalSelectedVersionNumber);
    setLoadModalSelectedVersionName(target.options[target.options.selectedIndex].text);
  };

  const handleLoadModalClose = () => {
    setLoadModalShow(false);
  };

  const handleLoadModalOk = () => {
    setLoadModalShow(false);
    props.onVersionSelected(loadModalSelectedVersionNumber);
  };

  return (
    <section className={`${styles['versionning']}`}>
      <section className={`${styles['select-refresh-version']}`}>
        <FormSelect
          disabled={availableVersions.length === 0}
          value={currentVersion}
          onChange={event => handleLoadModalShow(event.target)}
          data-e2e="neos-rfq-versionning-available-versions"
        >
          {availableVersions.length === 0 ? (
            <option value="">Version History</option>
          ) : (
            availableVersions.map(({ id, value }, index) => (
              <option value={id} key={index}>
                {value}
              </option>
            ))
          )}
        </FormSelect>
        <SimpleNeosTooltip id="refresh-rfq" message={'Refresh RFQ'}>
          <button
            className="btn btn-sm btn-discreet-secondary"
            onClick={() => {
              (document.activeElement as any)?.blur?.();
              handleReloadModalShow();
            }}
            data-e2e="neos-rfq-versionning-refresh-button"
            disabled={availableVersions.length === 0 || isReadOnlyRfq}
          >
            <i className="icon icon-sm">cached</i>
          </button>
        </SimpleNeosTooltip>
      </section>
      <Modal
        show={loadModalShow}
        onHide={handleLoadModalClose}
        onKeyPress={(event: any) => event.charCode === 13 && handleLoadModalOk()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Loading version: "{loadModalSelectedVersionName}"...</Modal.Title>
        </Modal.Header>
        <Modal.Body>Any unsaved changes will be lost! Continue?</Modal.Body>
        <Modal.Footer>
          <button className="btn btn-lg btn-flat-secondary" onClick={handleLoadModalClose}>
            Cancel
          </button>
          <button className="btn btn-lg btn-primary ms-2" onClick={handleLoadModalOk}>
            Ok
          </button>
        </Modal.Footer>
      </Modal>
      {rfqOutdatedModalState.action === 'SHOW' && (
        <Modal show={rfqOutdatedModalState.action === 'SHOW'} onHide={onOutdatedDismiss}>
          <Modal.Header closeButton>
            <Modal.Title>Another person has edited this RFQ!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>We detected that a newer version of this RFQ was saved by another user!</p>
            <p>Choosing to load this newer version will result in loosing your current changes!</p>
          </Modal.Body>
          <Modal.Footer>
            <SimpleNeosTooltip
              id={'save-current-version'}
              message={'You can manually load any version later!'}
            >
              <button
                className="btn btn-lg btn-primary"
                onClick={() => onSavedWithVersion(rfqOutdatedModalState.latestVersion)}
              >
                Save the current version
              </button>
            </SimpleNeosTooltip>
            <SimpleNeosTooltip
              id={'load-new-version'}
              message={'All unsaved changes will be lost!'}
            >
              <button
                className="btn btn-lg btn-primary"
                onClick={() => onVersionSelected(rfqOutdatedModalState.latestVersion)}
              >
                Load the newer version ({rfqOutdatedModalState.latestVersion})
              </button>
            </SimpleNeosTooltip>
          </Modal.Footer>
        </Modal>
      )}
    </section>
  );
};
