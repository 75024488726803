import type { MultipleTypeaheadValue } from '@/neos/components/share/multipleAsyncTypeahead/MultipleAsyncTypeahead';
import { sortBy } from 'lodash';
import type { Underlying } from '../../../../../../../neos/business/neosModel';

export function mapUnderlyingsToTypeaheadValues(
  underlyings: Underlying[],
): MultipleTypeaheadValue[] {
  return sortBy(underlyings.map(mapUnderlyingToTypeaheadValue), u => u.label);
}

export function mapUnderlyingToTypeaheadValue({
  id,
  bloombergCode,
}: {
  id: string;
  bloombergCode: string;
}): MultipleTypeaheadValue {
  return {
    value: id,
    label: bloombergCode,
    origin: 'UNDERLYING',
  };
}
