import { FlowDatePicker } from '@/neos/components/share/datePicker';
import { getStartDateCellModel } from '@/neos/components/rfq/strategies/strategy/strategyDefinition/startDateCell/getStartDateCellModel.ts';
import { useAppSelector } from '@/bootstrap/hooks.ts';
import { selectors } from '@/bootstrap/selectors.ts';
import { useDispatch } from 'react-redux';
import { neosThunks } from '@/neos/business/thunks';
import { ErrorHighlight } from '@/neos/components/share/errorHighlight';

export interface StrategyStartDateCellProps {
  rfqId: string;
  strategyId: string;
  isReadOnlyRfq: boolean;
}

export const StartDateCell = ({ rfqId, strategyId, isReadOnlyRfq }: StrategyStartDateCellProps) => {
  const dispatch = useDispatch();
  const { startDatesProducts, isEditable } = useAppSelector(state =>
    getStartDateCellModel(state, rfqId, strategyId, selectors),
  );

  const onStartDateChange = (selectedDate: string, productId: string) => {
    dispatch(neosThunks.createUpdateProductThunk(strategyId, productId, 'startDate', selectedDate));
  };

  return (
    <section>
      {startDatesProducts.map(({ startDate, productId }) => (
        <ErrorHighlight
          key={productId}
          errorField={'startDate'}
          related={{ rfqId, strategyId, legId: productId }}
        >
          <FlowDatePicker
            key={productId}
            maturities={[]}
            readOnly={isReadOnlyRfq || !isEditable}
            onChange={e => onStartDateChange(e, productId)}
            date={startDate || ''}
            data-e2e={`neos-strategy-start-date-${productId}`}
            hideCalendarIcon
          />
        </ErrorHighlight>
      ))}
    </section>
  );
};
