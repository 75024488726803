import type { Preset } from '.';
import type { StrategyType } from '../../../../../neos/business/neosModel';

const STRATEGY_TYPE: Preset<StrategyType> = [
  {
    id: 'CALL',
    label: 'Call',
  },
  {
    id: 'PUT',
    label: 'Put',
  },
  {
    id: 'CALL_SPREAD',
    label: 'CS',
  },
  {
    id: 'PUT_SPREAD',
    label: 'PS',
  },
  {
    id: 'STRADDLE',
    label: 'Straddle',
  },
  {
    id: 'STRANGLE',
    label: 'Strangle',
  },
  {
    id: 'RISK_REVERSAL',
    label: 'R. R.',
    ['data-e2e']: 'neos-rfq-shortcuts-strategy-type-RISK_REVERSAL',
  },
  {
    id: 'SYNTHETIC',
    label: 'Synth.',
    ['data-e2e']: 'neos-rfq-shortcuts-strategy-type-SYNTHETIC',
  },
  {
    id: 'FUTURE',
    label: 'Fut.',
  },
  {
    id: 'FUTURE_ROLL',
    label: 'Fut. Roll',
  },
  {
    id: 'FORWARD',
    label: 'Fwd.',
  },
  {
    id: 'ELS',
    label: 'ELS',
  },
  {
    id: 'VAR_SWAP',
    label: 'Var.',
  },
  {
    id: 'VOL_SWAP',
    label: 'Vol.',
  },
];

const UNDERLYINGS: Preset<string> = [
  {
    id: 'SG_17360',
    label: 'SPX',
  },
  {
    id: 'SG_350457',
    label: 'EEM UP',
  },
  {
    id: 'SG_17370',
    label: 'NDX',
  },
  {
    id: 'SG_41613',
    label: 'EFA UP',
  },
  {
    id: 'SG_17359',
    label: 'RTY',
  },
  {
    id: 'SG_876680',
    label: 'GLD UP',
  },
  {
    id: 'SG_17405',
    label: 'SX5E',
  },
  {
    id: 'SG_2069855',
    label: 'HYG UP',
  },
  {
    id: 'SG_35908',
    label: 'VIX',
  },
  {
    id: 'SG_8006',
    label: 'QQQ UQ',
  },
];

export const AMER_PRESET = {
  STRATEGY_TYPE,
  UNDERLYINGS,
};
