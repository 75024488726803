import type { BlotterPosition } from '@/neos/business/ui/blotter/blotterUiModel';
import { ResizableBox, type ResizableBoxProps } from 'react-resizable';
import { Tabs } from './Tabs';

export interface NeosTabsStateProps {
  isDividedView: boolean;
  blotterPosition: BlotterPosition;
  tabId: string;
}

type NeosAppProps = NeosTabsStateProps;

export type TabId = 'BLOTTER' | { rfqId: string };

const Blotter = () => <Tabs kind="MAIN" tabId={'BLOTTER'} />;

export const NeosTabsUnconnected = ({ blotterPosition, tabId }: NeosAppProps) => {
  const Rfqs = () => <Tabs kind="SECONDARY" tabId={tabId} />;

  switch (blotterPosition) {
    case 'LEFT':
      return (
        <>
          <ResizableBox {...resizableOptions(blotterPosition)}>
            <Blotter />
          </ResizableBox>
          <Rfqs />
        </>
      );
    case 'RIGHT':
      return (
        <>
          <ResizableBox {...resizableOptions(blotterPosition)}>
            <Rfqs />
          </ResizableBox>
          <Blotter />
        </>
      );
    case 'TOP':
      return (
        <div className="flex flex-column w-100">
          <ResizableBox {...resizableOptions(blotterPosition)}>
            <Blotter />
          </ResizableBox>
          <Rfqs />
        </div>
      );
    case 'BOTTOM':
      return (
        <div className="flex flex-column w-100">
          <ResizableBox {...resizableOptions(blotterPosition)}>
            <Rfqs />
          </ResizableBox>
          <Blotter />
        </div>
      );
    case 'CENTER':
      return (
        <>
          <div className={`flex flex-column w-100 ${tabId !== 'BLOTTER' ? 'd-none' : ''}`}>
            <Tabs kind="MAIN" tabId={'BLOTTER'} showTabs={tabId === 'BLOTTER'} />
          </div>
          {tabId !== 'BLOTTER' && <Tabs kind="MAIN" tabId={tabId} />}
        </>
      );
  }
};

function resizableOptions(position: Omit<BlotterPosition, 'CENTER'>): ResizableBoxProps {
  switch (position) {
    case 'LEFT':
    case 'RIGHT':
      return {
        height: Infinity,
        width: window.innerWidth * 0.5,
        className: 'horizontal',
        resizeHandles: ['e'],
      };
    case 'TOP':
    case 'BOTTOM':
      return {
        height: window.innerHeight * 0.5,
        width: Infinity,
        className: 'vertical',
        resizeHandles: ['s'],
      };
    default:
      throw new Error('Position not managed');
  }
}
