import type { Action } from '@/bootstrap/actions.ts';
import type { Thunk } from '@/bootstrap/thunks.ts';
import type {
  LegPeriod,
  RateLegPeriodDates,
} from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel.ts';
import { isClsProduct, isElsProduct } from '../../../../../strategyModel.ts';

export function createUpdateRateSchedulePeriodThunk(
  strategyId: string,
  updatedDate: Partial<RateLegPeriodDates>,
  uuid: string,
): Thunk {
  return function updateRateSchedulePeriodThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { productCrudActions },
      },
    },
  ) {
    const appState = getState();

    const product = selectors.getStrategyProduct(appState, strategyId, selectors);

    if (!isElsProduct(product) && !isClsProduct(product)) {
      throw new Error('product is neither an els nor a cls');
    }

    const periods: LegPeriod<RateLegPeriodDates>[] = [...(product?.ratePeriods ?? [])];
    const periodToUpdateIndex = periods.findIndex(line => line.uuid === uuid);
    const oldPeriod = periods[periodToUpdateIndex];

    if (!oldPeriod) {
      throw new Error('Index of periods is out of bound');
    }

    const newDividendPeriod = {
      ...oldPeriod,
      dates: {
        ...oldPeriod.dates,
        ...updatedDate,
      },
    };
    periods[periodToUpdateIndex] = newDividendPeriod;

    const action: Action = productCrudActions.update(product.uuid, {
      ratePeriods: periods,
    });
    dispatch(action);
  };
}
