import type { Thunk } from '@/bootstrap/thunks';
import decodeUriComponent from 'decode-uri-component';

export function createStartAppThunk(urlHash: string): Thunk {
  return (dispatch, getState, { selectors: { getSesameId }, actionCreators, thunks }) => {
    const regex = /^#\/neos\/open\/(.*)\/?$/;
    const match = regex.exec(urlHash)?.[1];
    const rfqId = match ? decodeUriComponent(match) : undefined;

    if (getSesameId(getState())) {
      dispatch(thunks.neos.createSelectNeosApplicationThunk(rfqId));
    } else {
      dispatch(
        actionCreators.common.createUserInfoRequestedAction([
          thunks.neos.createSubscribeToNeosNotifications(),
          thunks.neos.createSelectNeosApplicationThunk(rfqId),
        ]),
      );
    }
  };
}
