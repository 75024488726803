import type { Thunk } from '@/bootstrap/thunks';
import type { SecondaryInfo } from '@/neos/business/neosOnyxModel';
import { getSecondaryInfos } from './secondaryInfosSelectors';

export function createUpdateSecondaryInfoThunk(
  allocationId: string,
  rfqId: string,
  legId: string,
  patch: Partial<SecondaryInfo>,
): Thunk {
  return function updateSecondaryInfoThunk(
    dispatch,
    getState,
    { selectors, actionCreators: { neos: neosActionCreators } },
  ) {
    const state = getState();

    const currentSecondaryInfo = getSecondaryInfos(legId, allocationId, rfqId, state, selectors);
    dispatch(
      neosActionCreators.otcAllocationCrudActions.update(
        { allocationId, rfqId, legId },
        {
          secondaryInfo: {
            ...currentSecondaryInfo,
            ...patch,
          },
        },
      ),
    );
  };
}
