import styles from '@/neos/components/share/datePicker/FlowDatePicker.module.scss';
import { useCustomDateOnKeyDownInput } from '@/neos/components/share/datePicker/useCustomDateOnKeyDownInput';
import { DATE_SHORT_LITTERAL_FORMAT } from '@/util/date/dateFormatHelper';
import { type FC, forwardRef, useRef } from 'react';
import DatePicker from 'react-datepicker';

interface SimpleDatePickerProps {
  date: Date | null;
  onChange: (date: Date) => void;
  clearable?: boolean;
  ['data-e2e']?: string;
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
  hideCalendarIcon?: boolean;
  maxDate?: Date;
  minDate?: Date;
}

export const SimpleDatePicker: FC<SimpleDatePickerProps> = forwardRef<
  HTMLDivElement,
  SimpleDatePickerProps
>((props, parentRef) => {
  const {
    date,
    onChange,
    maxDate,
    minDate,
    disabled = false,
    clearable,
    hideCalendarIcon,
    className,
    ...restProps
  } = props;

  const onChangeDate = (date: Date) => {
    onChange(date);
  };

  const datePickerRef = useRef<DatePicker>(null);

  const openCalendar = () => {
    return (datePickerRef?.current as any)?.setOpen(true);
  };
  const closeDatePicker = () => datePickerRef.current?.setOpen(false);

  const { customOnKeyDown } = useCustomDateOnKeyDownInput({
    inputValue: date,
    closeDatePicker,
  });
  return (
    <div ref={parentRef} className={`d-flex ${className}`} {...restProps}>
      <DatePicker
        ref={datePickerRef}
        className="form-control"
        selected={date}
        onChange={onChangeDate}
        startDate={date}
        locale={'en'}
        dateFormat={DATE_SHORT_LITTERAL_FORMAT}
        disabled={disabled}
        isClearable={clearable}
        peekNextMonth
        onKeyDown={customOnKeyDown}
        calendarClassName={`${styles.calendar} panel panel-default`}
        maxDate={maxDate}
        minDate={minDate}
      />

      <button
        className="btn btn-icon btn-discreet-primary"
        disabled={disabled}
        onClick={openCalendar}
      >
        {!hideCalendarIcon && (
          <i className="icon icon-xs" aria-hidden="true">
            calendar_today
          </i>
        )}
      </button>
    </div>
  );
});
