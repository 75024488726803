import { Component } from 'react';
import {
  Key,
  type KeyboardCallback,
  Modifier,
  type Subscription,
  reactToKeyCombination,
} from './keyboard';

interface Props {
  code: Key;
  modifier?: Modifier;
  propagation?: 'BUBBLING' | 'CAPTURE';
  onKeyPress(): void;
}

export class Keyboard extends Component<Props, {}> {
  private subscription: Subscription | undefined;

  constructor(props: Props) {
    super(props);
  }

  public componentDidMount() {
    const { code: key, modifier, onKeyPress } = this.props;
    const callback: KeyboardCallback = (event: KeyboardEvent) => {
      event.preventDefault();
      onKeyPress();
    };
    const useCapture = this.props.propagation === 'CAPTURE';
    this.subscription = reactToKeyCombination({ key, modifier }, callback, { useCapture });
  }

  public componentWillUnmount() {
    this.subscription!.unsubscribe();
  }

  public render() {
    return null;
  }
}

// Prevent Google Chrome Shortcut
reactToKeyCombination({ key: Key.e, modifier: Modifier.ctrl }, e => {
  e.preventDefault();
  return;
});
