import { transfereeContactsDomain } from './transfereeContactsDomain';

export const transfereeContactsCrudActions = {
  createTransfereeContactsRequestedAction,
  transfereeContactsCrudActions: transfereeContactsDomain.actions,
};

export interface TransfereeContactsRequestedAction {
  type: 'TRANSFEREE_CONTACT_LIST_REQUESTED';
  rfqId: string;
  counterpartId: number;
  salesValoEmail: string;
}

export function createTransfereeContactsRequestedAction(
  rfqId: string,
  counterpartId: number,
  salesValoEmail: string,
): TransfereeContactsRequestedAction {
  return {
    type: 'TRANSFEREE_CONTACT_LIST_REQUESTED',
    rfqId,
    counterpartId,
    salesValoEmail,
  };
}
