import type { Dispatchable, Thunk } from '@/bootstrap/thunks';

export function createChangeLegSecondaryTypeThunk({
  rfqId,
  strategyId,
  legId,
}: {
  rfqId: string;
  strategyId: string;
  legId: string;
}): Thunk {
  return function changeLegSecondaryTypeThunk(
    dispatch,
    getState,
    {
      selectors: { getSimilarActivitiesLinkManagement },
      actionCreators: {
        neos: { rfqUiCrudActions, legUiCrudActions, legDataCrudActions },
      },
      thunks,
    },
  ) {
    const appState = getState();

    const {
      currentPrimaryLegId,
      currentPrimaryRfqId: primaryRfqId,
      secondaryEventType: primaryRfqSecondaryEvent,
    } = getSimilarActivitiesLinkManagement(appState);

    const dispatchables: Dispatchable[] = [
      rfqUiCrudActions.patchOrInsert(rfqId, {
        isRfqWaitingForSecondarySelection: false,
      }),
      legUiCrudActions.patchOrInsert(legId, {
        secondaryType: primaryRfqSecondaryEvent,
      }),
    ];
    if (currentPrimaryLegId && primaryRfqId) {
      dispatchables.push(
        legDataCrudActions.update(legId, {
          eventType: primaryRfqSecondaryEvent,
          legReferenceUuids: [
            {
              rfqId: { application: 'XONE', id: primaryRfqId },
              legId: { application: 'XONE', id: currentPrimaryLegId },
            },
          ],
        }),
      );
      dispatchables.push(
        thunks.neos.createChangeRfqActivity(
          { rfqId, strategyId, legId, legIds: [] },
          {
            newActivity: 'SECONDARY',
            primaryRfqId,
            primaryRfqSecondaryEvent,
          },
        ),
      );
      dispatchables.push(
        legUiCrudActions.update(currentPrimaryLegId, {
          secondaryType: undefined,
        }),
      );
    }
    dispatch(dispatchables);
  };
}
