import type { Action, ActionCreators } from '@/bootstrap/actions';
import type { ActionsObservable, Epic } from 'redux-observable';
import { ofType } from 'redux-observable';
import type { Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import type { Contact } from './contactModel';

export interface ContactApi {
  getContacts: (salesValoEmail: string, counterpartId: number) => Observable<Contact[]>;
}

export function createRetrieveContactsEpic(
  api: ContactApi,
  { common: { createAppCrashedAction }, neos: { actorsSetupCrudActions } }: ActionCreators,
): Epic {
  return (action$: ActionsObservable<Action>) =>
    action$.pipe(
      ofType('CONTACT_LIST_REQUESTED'),
      mergeMap(({ rfqId, salesValoEmail, counterpartId }) => {
        return api.getContacts(salesValoEmail, counterpartId).pipe(
          map((contacts: Contact[]) => actorsSetupCrudActions.update(rfqId, { contacts })),
          catchError(error =>
            error && error.code === 400
              ? []
              : [createAppCrashedAction('contact-list-requested', error)],
          ),
        );
      }),
    );
}
