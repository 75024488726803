import { ResizableBox, type ResizableProps } from 'react-resizable';

export type NeosResizableProps = ResizableProps & {
  isResizable?: boolean;
};

export const NeosResizableBox = (props: NeosResizableProps) => {
  const { children, isResizable = true, ...restProps } = props;
  return isResizable ? (
    <ResizableBox
      className={`${props.className} position-relative`}
      handle={
        <div
          style={{
            position: 'absolute',
            cursor: 'ew-resize',
            right: '0',
            top: '0',
            width: '5px',
            height: '100%',
          }}
        >
          <i
            className="icon icon-sm"
            style={{
              position: 'absolute',
              cursor: 'ew-resize',
              color: 'grey',
              top: '50%',
              right: '1px',
              width: '5px',
              display: 'flex',
              justifyContent: 'center',
              transform: 'translateY(-50%)',
            }}
          >
            chevron_right
          </i>
        </div>
      }
      {...restProps}
    >
      {children}
    </ResizableBox>
  ) : (
    <div style={{ width: '100%' }}>{children}</div>
  );
};
