import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { useSelector } from 'react-redux';
import {
  FutureMaturityCellsComponent,
  type FutureMaturityCellsMapStateProps,
  type FutureMaturityCellsOwnProps,
} from './FutureMaturityCells';
import { getFutureMaturityCellsModel } from './getFutureMaturityCellsModel';

export const FutureMaturityCells = (ownProps: FutureMaturityCellsOwnProps) => {
  const stateProps = useSelector<AppState, FutureMaturityCellsMapStateProps>(state => {
    return getFutureMaturityCellsModel(state, ownProps.rfqId, ownProps.strategyId, selectors);
  });

  return <FutureMaturityCellsComponent {...ownProps} {...stateProps} />;
};
