import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { Product } from '../../../../../../../neos/business/neosModel';

export function getFieldRelevantProducts(
  state: AppState,
  strategyId: string,
  sameProductField: boolean,
  selectors: Selectors,
): Product[] {
  const hasACompositionLeg = selectors.hasACompositionLeg(state, strategyId, selectors);

  if (sameProductField && !hasACompositionLeg) {
    return [selectors.getStrategyMasterProduct(state, strategyId, selectors)];
  }

  const allProducts = selectors.getStrategyProducts(state, strategyId, selectors);

  return hasACompositionLeg ? [allProducts[allProducts.length - 1]] : allProducts;
}
