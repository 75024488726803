import { useAppSelector } from '@/bootstrap/hooks';
import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import type { BlotterDefaultTimerange } from '@/neos/business/ui/userPreferences/userPreferencesUiModel';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

export function BlotterPreferences() {
  const dispatch = useDispatch<ExtendedDispatch>();
  const changeBlotterDefaultTimerange = (blotterDefaultTimerange: BlotterDefaultTimerange) => {
    dispatch(
      createLogAnalyticsAction('NEOS USER PREFERENCES', 'Change blotter default time range'),
      neosActionCreators.createBlotterDefaultTimerangeChangedAction(blotterDefaultTimerange),
    );
  };

  const blotterDefaultTimerange = useAppSelector(state =>
    selectors.getBlotterDefaultTimerange(state.ui.userPreferences),
  );

  return (
    <div className="d-grid">
      <span className="me-2 mb-2">Blotter default time range</span>
      <ToggleButtonGroup
        name="columnDisplayTimeRange"
        className="errorable-bloc btn-group-toggle"
        type="radio"
        onChange={changeBlotterDefaultTimerange}
        value={blotterDefaultTimerange}
      >
        <ToggleButton variant="toggle-primary" value={of('LAST_3_DAYS')} id="LAST_3_DAYS">
          Last 3 days
        </ToggleButton>
        <ToggleButton variant="toggle-primary" value={of('TODAY')} id="TODAY">
          Today
        </ToggleButton>
        <ToggleButton variant="toggle-primary" value={of('YESTERDAY')} id="YESTERDAY">
          Yesterday
        </ToggleButton>
        <ToggleButton variant="toggle-primary" value={of('THIS_WEEK')} id="THIS_WEEK">
          This week
        </ToggleButton>
        <ToggleButton variant="toggle-primary" value={of('LAST_WEEK')} id="LAST_WEEK">
          Last week
        </ToggleButton>
        <ToggleButton variant="toggle-primary" value={of('THIS_MONTH')} id="THIS_MONTH">
          This month
        </ToggleButton>
        <ToggleButton variant="toggle-primary" value={of('LAST_MONTH')} id="LAST_MONTH">
          Last month
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}

function of(value: BlotterDefaultTimerange): BlotterDefaultTimerange {
  return value;
}
