import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import type { Product } from '@/neos/business/rfq/strategy/leg/product/productModel';
import { neosThunks } from '@/neos/business/thunks';
import { NumericInput } from '@/neos/components/share/numericInput';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import { useDispatch } from 'react-redux';
import { ErrorHighlight } from '../../../../../share/errorHighlight';
import { getClientTaxRateCellsModel } from './getClientTaxRateCellsModel';

type ClientTaxRateCellsProps = {
  rfqId: string;
  strategyId: string;
};

export const ClientTaxRateCells = ({ rfqId, strategyId }: ClientTaxRateCellsProps) => {
  const dispatch = useDispatch();

  function onClientTaxRateChange(
    oldClientTaxRate: Product['clientTaxRate'],
    newPriceValue: number | undefined,
    productId: string,
  ) {
    dispatch(
      neosThunks.createUpdateProductThunk(strategyId, productId, 'clientTaxRate', {
        ...oldClientTaxRate,
        unit: '%',
        value: newPriceValue,
      }),
    );
  }

  const { clientTaxRateFields } = useAppSelector(state =>
    getClientTaxRateCellsModel(state, rfqId, strategyId, selectors),
  );

  return (
    <section>
      {clientTaxRateFields.map(({ id, value: clientTaxRate, isEditable, isDisplayed }) =>
        isDisplayed ? (
          <SimpleNeosTooltip key={id} message="871m Client tax rate %">
            <span>
              <ErrorHighlight
                errorField={'clientTaxRate'}
                related={{ rfqId, legId: id, strategyId }}
              >
                <NumericInput
                  readOnly={!isEditable}
                  value={clientTaxRate?.value}
                  unit={'%'}
                  onBlur={value => onClientTaxRateChange(clientTaxRate, value, id)}
                  data-e2e={`client-tax-rate-${id}`}
                />
              </ErrorHighlight>
            </span>
          </SimpleNeosTooltip>
        ) : (
          <div key={id} style={{ height: '32px' }} />
        ),
      )}
    </section>
  );
};
