import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { OnyxQuote } from '../../../../neos/business/neosOnyxModel';
import type { ParentId, Quote } from './quoteModel';
import { getOnyxQuote } from './util';

export const toQuoteMappers = {
  mapToOnyxQuoteForLeg,
  mapToOnyxQuoteForStrategy,
  mapToOnyxQuoteForRfq,
};
export const fromQuoteMappers = { mapFromOnyxQuote };

function mapToOnyxQuoteForLeg(
  state: AppState,
  legId: string,
  selectors: Selectors,
): OnyxQuote | undefined {
  const { quoteId } = selectors.getLegData(state, legId);
  return mapPrivateToOnyxQuote(state, quoteId, selectors);
}

function mapToOnyxQuoteForStrategy(
  state: AppState,
  strategyId: string,
  selectors: Selectors,
): OnyxQuote | undefined {
  const { quoteId } = selectors.getStrategyData(state, strategyId);
  return mapPrivateToOnyxQuote(state, quoteId, selectors);
}

function mapToOnyxQuoteForRfq(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): OnyxQuote | undefined {
  const { quoteId } = selectors.getRfqData(state, rfqId);
  return mapPrivateToOnyxQuote(state, quoteId, selectors);
}

function mapFromOnyxQuote(parentId: ParentId, onyxQuote?: OnyxQuote): Quote {
  if (!onyxQuote) {
    return {
      parentId,
      deltaUnit: '%',
      unit: undefined,
      initialTraderPrice: undefined,
      initialSalesPrice: undefined,
    };
  }

  const {
    status,
    subStatus,
    traderPrice,
    salesPrice,
    greeks,
    volatility,
    extraSalesMargin,
    includedSalesMargin,
    initialSalesPrice,
    initialTraderPrice,
    initialPreTradeMidMarketMarkPrice,
    preTradeMidMarketMarkPrice,
    salesVolatility,
    traderVolatility,
    agreedTraderPrice,
  } = onyxQuote;
  const unit = traderPrice?.ask?.unit || traderPrice?.bid?.unit;

  return {
    parentId,
    status,
    subStatus,
    salesAsk: salesPrice?.ask?.value,
    salesAskUnit: salesPrice?.ask?.unit,
    salesBid: salesPrice?.bid?.value,
    traderAsk: traderPrice?.ask?.value,
    traderBid: traderPrice?.bid?.value,
    delta: greeks?.delta?.value,
    deltaUnit: '%',
    gamma: greeks && greeks.gamma,
    theta: greeks?.theta?.value,
    thetaUnit: greeks?.theta?.unit,
    vega: greeks?.vega?.value,
    vegaUnit: greeks?.vega?.unit,
    unit,
    volatilityAsk: volatility?.ask?.value,
    volatilityBid: volatility?.bid?.value,
    extraSalesMarginAsk: extraSalesMargin?.value?.ask?.value,
    extraSalesMarginBid: extraSalesMargin?.value?.bid?.value,
    extraSalesMarginSpread: extraSalesMargin?.value?.spread?.value,
    extraSalesMarginAskUnit: extraSalesMargin?.value?.ask?.unit,
    extraSalesMarginBidUnit: extraSalesMargin?.value?.bid?.unit,
    extraSalesMarginSpreadUnit: extraSalesMargin?.value?.spread?.unit,
    extraSalesMarginRule: extraSalesMargin?.rule,
    includedSalesMarginBid: includedSalesMargin?.value?.bid?.value,
    includedSalesMarginAsk: includedSalesMargin?.value?.ask?.value,
    includedSalesMarginSpread: includedSalesMargin?.value?.spread?.value,
    includedSalesMarginRule: includedSalesMargin?.rule,
    includedSalesMarginAskUnit: includedSalesMargin?.value?.ask?.unit,
    includedSalesMarginBidUnit: includedSalesMargin?.value?.bid?.unit,
    includedSalesMarginSpreadUnit: includedSalesMargin?.value?.spread?.unit,
    initialPreTradeMidMarketMarkPrice: initialPreTradeMidMarketMarkPrice?.value,
    initialPreTradeMidMarketMarkPriceUnit: initialPreTradeMidMarketMarkPrice?.unit,
    preTradeMidMarketMarkPrice: preTradeMidMarketMarkPrice?.value,
    preTradeMidMarketMarkPriceUnit: preTradeMidMarketMarkPrice?.unit,
    initialSalesPrice,
    initialTraderPrice,
    salesBidVolatility: salesVolatility?.bid?.value,
    salesBidVolatilityUnit: salesVolatility?.bid?.unit,
    salesAskVolatility: salesVolatility?.ask?.value,
    salesAskVolatilityUnit: salesVolatility?.ask?.unit,
    traderBidVolatility: traderVolatility?.bid?.value,
    traderBidVolatilityUnit: traderVolatility?.bid?.unit,
    traderAskVolatility: traderVolatility?.ask?.value,
    traderAskVolatilityUnit: traderVolatility?.ask?.unit,
    salesAskType: salesPrice?.ask?.type,
    salesBidType: salesPrice?.bid?.type,
    traderAskType: traderPrice?.ask?.type,
    traderBidType: traderPrice?.bid?.type,
    deltaType: greeks?.delta?.type,
    thetaType: greeks?.theta?.type,
    vegaType: greeks?.vega?.type,
    volatilityAskType: volatility?.ask?.type,
    extraSalesMarginAskType: extraSalesMargin?.value?.ask?.type,
    extraSalesMarginBidType: extraSalesMargin?.value?.bid?.type,
    volatilityBidType: volatility?.bid?.type,
    extraSalesMarginSpreadType: extraSalesMargin?.value?.spread?.type,
    includedSalesMarginBidType: includedSalesMargin?.value?.bid?.type,
    includedSalesMarginAskType: includedSalesMargin?.value?.ask?.type,
    includedSalesMarginSpreadType: includedSalesMargin?.value?.spread?.type,
    initialPreTradeMidMarketMarkPriceType: initialPreTradeMidMarketMarkPrice?.type,
    preTradeMidMarketMarkPriceType: preTradeMidMarketMarkPrice?.type,
    salesBidVolatilityType: salesVolatility?.bid?.type,
    salesAskVolatilityType: salesVolatility?.ask?.type,
    traderBidVolatilityType: traderVolatility?.bid?.type,
    traderAskVolatilityType: traderVolatility?.ask?.type,
    agreedTraderPrice,
  };
}

function mapPrivateToOnyxQuote(
  state: AppState,
  quoteId: string,
  selectors: Selectors,
): OnyxQuote | undefined {
  const quote = selectors.getQuote(state, quoteId);
  const quoteUnit = quote.unit;

  return getOnyxQuote(quoteUnit, quote);
}
