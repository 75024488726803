import type { Action } from '@/bootstrap/actions';
import type { Thunk } from '@/bootstrap/thunks';
import type { Barrier, BarriersFeature, BarriersFeatureKey } from '../featureModel';

export function createRemoveBarrierOfFeatureThunk(
  featureKey: BarriersFeatureKey,
  index: number,
): Thunk {
  return function removeBarrierOfFeature(
    dispatch,
    getState,
    {
      selectors: { getFeature },
      actionCreators: {
        neos: { featureCrudActions },
      },
    },
  ) {
    const appState = getState();
    const feature: BarriersFeature | undefined = getFeature(appState.featureState, featureKey);
    if (!feature) {
      throw new Error(`This feature is not defined`);
    }

    const barriers: Barrier[] = [...feature.barriers];
    const barrierToRemove: Barrier | undefined = barriers[index];
    if (!barrierToRemove) {
      throw new Error('Index of barrier is out of bound');
    }
    barriers.splice(index, 1);
    const action: Action = featureCrudActions.update(featureKey, { barriers });
    dispatch(action);
  };
}
