import { DATE_SHORT_KEBAB_FORMAT, DATE_SHORT_LITTERAL_FORMAT } from '@/util/date/dateFormatHelper';
import { format, parse } from 'date-fns';
import type { FC } from 'react';
import type { Tenor } from '../../../../neos/business/neosModel';

import styles from './FlowDatePicker.module.scss';

interface PredefinedDatesProps {
  isDisplayed: boolean;
  selectedDate: string;
  predefinedDates: string[];
  tenors: Tenor[] | undefined;
  onSelect: (date: string) => void;
}

export const PredefinedDates: FC<PredefinedDatesProps> = ({
  isDisplayed,
  predefinedDates,
  selectedDate,
  tenors,
  onSelect,
}: PredefinedDatesProps) => {
  if (!isDisplayed || !predefinedDates.length) {
    return null;
  }

  const selectDate = (date: string): void => {
    onSelect(date);
  };

  return (
    <div
      data-e2e="predefined-dates"
      className={`${
        tenors?.length ? styles['maturity-container'] : styles['maturity-ref-container']
      } `}
    >
      {predefinedDates.map((date, index) => (
        <span
          className={`${selectedDate === date ? styles['maturity-selected'] : ''} `}
          key={index}
          onClick={() => selectDate(date)}
        >
          {date === 'MORE' || date === '---'
            ? date
            : format(parse(date, DATE_SHORT_KEBAB_FORMAT, 0), DATE_SHORT_LITTERAL_FORMAT)}
        </span>
      ))}
    </div>
  );
};
