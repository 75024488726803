import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import {
  type UpdateJustification,
  type UpdateJustificationLabel,
  allUpdateJustificationOptions,
} from '@/neos/business/rfq/strategy/leg/otcAllocation/updateJustificationModel';

export function mapFromOnyxUpdateJustification(
  updateJustificationType: UpdateJustification | undefined,
): UpdateJustificationLabel | undefined {
  const option = allUpdateJustificationOptions.find(
    option => option.value === updateJustificationType,
  );
  return option?.label;
}

export function mapToOnyxUpdateJustification(
  state: AppState,
  rfqId: string,
  updateJustificationLabel: UpdateJustificationLabel | undefined,
  selectors: Selectors,
): UpdateJustification | undefined {
  if (updateJustificationLabel === undefined) {
    return undefined;
  }

  const isUserTrader = selectors.isTrader(state);
  const isStatusInAmendWorkflow = selectors.isRfqStatusInAmendWorkflow(state, rfqId, selectors);
  const isStatusInCancelWorkflow = selectors.isRfqStatusInCancelWorkflow(state, rfqId, selectors);

  if (!isStatusInCancelWorkflow && !isStatusInAmendWorkflow) {
    return undefined;
  }

  const firstCharacter = isStatusInAmendWorkflow ? 'F' : 'G';
  const secondCharacter = isUserTrader ? 'A' : 'B';
  const labelToNumber: Record<UpdateJustificationLabel, 1 | 2 | 3> = {
    'FO error': 1,
    'Third party error/request': 2,
    'Split/Allocation': 3,
  };
  const thirdCharacter = labelToNumber[updateJustificationLabel];

  return `${firstCharacter}${secondCharacter}${thirdCharacter}`;
}
