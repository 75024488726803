import { hedgeDomain } from './hedgeDomain';

import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { sumBy } from 'lodash';
import type { Hedge, HedgeKey } from './hedgeModel';

export const hedgeSelectors = {
  getHedge,
  getHedges,
  getRfqHedgesKeys,
  getDeltaSum,
};

function getHedge(state: AppState, key: HedgeKey): Hedge | undefined {
  return getHedges(state, key)[0] ?? undefined;
}

function getHedges(
  state: AppState,
  partialKey: HedgeKey | Pick<HedgeKey, 'rfqId'> | Pick<HedgeKey, 'underlyingId'>,
): Hedge[] {
  return hedgeDomain.selectors.selectObjects(state.hedge, partialKey);
}

function getRfqHedgesKeys(state: AppState, rfqId: string): HedgeKey[] {
  return hedgeDomain.selectors.selectKeys(state.hedge, { rfqId });
}

function getDeltaSum(
  state: AppState,
  rfqId: string,
  underlyingId: string,
  selectors: Selectors,
): number | undefined {
  const hedges = selectors.getHedges(state, { underlyingId, rfqId });
  const noDelta = hedges.every(({ delta }) => delta === undefined);
  const totalDelta = sumBy(hedges, ({ delta }) => delta ?? 0);
  return noDelta ? undefined : totalDelta;
}
