import type { PredealCheckUiColor } from '@/neos/business/predealCheck/predealCheckModel';
import type { BulletColor } from '../predealCheckBullet';

export function convertToBulletColor(color: PredealCheckUiColor): BulletColor {
  switch (color) {
    case 'GREEN':
      return 'green';
    case 'GREEN_BLUR':
      return 'green-blur';
    case 'AMBER':
      return 'amber';
    case 'AMBER_BLUR':
      return 'amber-blur';
    case 'RED':
      return 'red';
    case 'RED_BLUR':
      return 'red-blur';
    case 'RED_DEROGATED':
      return 'red-derogated';
    case 'UNKNOWN_BLUR':
      return 'grey-blur';
    default:
      return 'grey';
  }
}
