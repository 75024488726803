import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import type { EventType } from '@/neos/business/rfq/strategy/leg/legData/legDataModel';
import styles from '@/neos/components/blotter/createSecondaryFromXone/CreateSecondaryFromXone.module.scss';
import { availableEventTypes } from '@/neos/components/rfq/strategies/strategy/strategyDefinition/secondaryType/shared/SecondaryTypeCellsCommon';
import { SecondaryWidget } from '@/neos/components/rfq/strategies/strategy/strategyDefinition/secondaryType/shared/SecondaryWidget';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

export interface CreateSecondaryFromXoneModalProps {
  isModalShown: boolean;
  onHideModal: () => void;
}

export function CreateSecondaryFromXoneModal({
  isModalShown,
  onHideModal,
}: CreateSecondaryFromXoneModalProps) {
  const dispatch = useDispatch<ExtendedDispatch>();
  const [selectedEventType, setSelectedEventType] = useState<EventType | undefined>(undefined);
  const [xOneId, setXOneId] = useState<string>('');

  const clearAndClose = () => {
    setXOneId('');
    setSelectedEventType(undefined);
    onHideModal();
  };

  const handleCreate = () => {
    if (xOneId && selectedEventType) {
      dispatch(
        createLogAnalyticsAction('NEOS BLOTTER', 'Create Secondary RFQ from XOne ID'),
        neosActionCreators.requestCreateSecondaryFromXoneId(xOneId, selectedEventType),
      );
    }
    clearAndClose();
  };

  return (
    <Modal size="lg" show={isModalShown} onHide={clearAndClose}>
      <Modal.Header closeButton>
        <Modal.Title>Create a secondary event</Modal.Title>
      </Modal.Header>
      <Modal.Body className={`${styles['modal-body']}`}>
        <div
          className="d-grid gap-x-5 gap-y-3"
          style={{
            gridTemplateColumns: '250px 140px',
            justifyContent: 'center',
          }}
        >
          <label className="fs-4 text-nowrap text-end align-self-center">
            Choose a secondary event type
          </label>
          <SecondaryWidget
            handleClick={(_, __, eventType) => setSelectedEventType(eventType)}
            id="create-secondary-from-xone"
            eventTypes={availableEventTypes.filter(e => e != undefined)}
            selectedEventType={selectedEventType}
          />
          <label className="fs-4 text-nowrap text-end align-self-center">Enter a X-One ID</label>
          <input
            value={xOneId}
            onChange={event => setXOneId(event.target.value)}
            type="text"
            className={`form-control input-sm ${styles['modal-body-input']}`}
            placeholder="ELI-XXXXXX"
            data-e2e="neos-blotter-input-xone-id"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-lg btn-primary"
          onClick={handleCreate}
          disabled={!(xOneId && selectedEventType)}
        >
          Create
        </button>
      </Modal.Footer>
    </Modal>
  );
}
