import type { OnyxRfq } from '@/neos/business/rfq/rfqOnyxModel';
import { DATE_SHORT_LITTERAL_FORMAT } from '@/util/date/dateFormatHelper';
import { format, parseISO } from 'date-fns';
import type { BlotterModelGetters } from '.';

export function getLegMaturities(
  rfq: OnyxRfq,
  { getAllStrategiesExceptDeltaAdj, twoDimentionalArrayToString }: BlotterModelGetters,
) {
  const allStrategiesExceptDeltaAdj = getAllStrategiesExceptDeltaAdj(rfq);

  const toDisplayFormat = (dateString: string) =>
    format(parseISO(dateString), DATE_SHORT_LITTERAL_FORMAT);

  const legMaturities = allStrategiesExceptDeltaAdj.map(strat =>
    strat.legs.map(({ product }) => product?.maturity ?? undefined),
  );

  return twoDimentionalArrayToString(legMaturities, toDisplayFormat);
}
