import { selectors } from '@/bootstrap/selectors';
import { services } from '@/bootstrap/services';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { createInfoToasterThunk } from '@/bootstrap/toasterThunks/thunks';
import { getSortedDeltaSummaries } from '@/neos/business/rfq/selectors/getSortedDeltaSummaries';
import { connect } from 'react-redux';
import {
  type ReferenceDispatchProps,
  type ReferenceMapStateToProps,
  type ReferenceOwnProps,
  ReferenceStyled,
} from './Reference';

export const Reference = connect(mapStateToProps, mapDispatchToProps)(ReferenceStyled);

function mapStateToProps(state: AppState, { rfqId }: ReferenceOwnProps): ReferenceMapStateToProps {
  const { getRfqData } = selectors;
  const { isDeltaAdjustedSelected } = selectors;
  const isDeltaAdjustedPanelVisible = isDeltaAdjustedSelected(state.ui, rfqId);

  const { status } = getRfqData(state, rfqId);
  const isDeltaSummaryVisible =
    services.orderOf(status).isAfterOrEqual('PRICED') &&
    services.orderOf(status).isBefore('ORDER_ACCEPTED');
  const deltaSummaries = getSortedDeltaSummaries(state, rfqId, selectors);

  const isEls = selectors
    .getRfqData(state, rfqId)
    .strategyIds.some(id => selectors.isElsStrategy(state, id, selectors));

  return {
    isDeltaAdjustedPanelVisible,
    isDeltaSummaryVisible,
    deltaSummaries,
    isEls,
  };
}

function mapDispatchToProps(dispatch: ExtendedDispatch): ReferenceDispatchProps {
  return {
    onCopy: (text: string) => {
      services.copyToClipboard(text, {
        onSuccess: () =>
          dispatch(createInfoToasterThunk({ message: 'Copied!', dismissAfter: 1500 })),
      });
    },
  };
}
