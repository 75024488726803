import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { type CustomUnderlyingProduct, isCustomUnderlyingProduct } from '../../productModel';
import type { OnyxProductUnderlying, OnyxSingleUnderlyingProduct } from '../../productOnyxModel';

export const toCustomUnderlyingProductMappers = { mapToOnyxCustomUnderlyingProduct };

export const fromCustomUnderlyingProductMappers = { mapFromCustomUnderlyingOnyxProduct };

export interface OnyxUnderlyingProduct {
  underlying?: OnyxProductUnderlying;
}

function mapToOnyxCustomUnderlyingProduct(
  state: AppState,
  productId: string,
  selectors: Selectors,
): OnyxUnderlyingProduct {
  const product = selectors.getProduct(state, productId);
  if (!isCustomUnderlyingProduct(product)) {
    throw new Error(`Can't map non custom underlying products!`);
  }

  return {
    underlying: {
      type: 'CUSTOM',
      name: product.underlyingName,
      currency: product.currency,
    },
  };
}

function mapFromCustomUnderlyingOnyxProduct(
  onyxProduct: OnyxSingleUnderlyingProduct,
): CustomUnderlyingProduct {
  return {
    hasCustomUnderlying: true,
    maturity: onyxProduct.maturity ?? undefined,
    underlyingName: onyxProduct.underlying?.name,
    currency: onyxProduct.underlying?.currency,
  };
}
