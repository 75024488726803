import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { Observable } from 'rxjs';
import type { PrimeBroker } from './primeBrokerActions';

export const makePrimeBrokerApi = (http: SgmeHttp): PrimeBrokerApi => ({
  getPrimeBrokers(clientId: string): Observable<PrimeBroker[]> {
    return http.get<PrimeBroker[]>({
      url: `api/referential/primeBroker/${clientId}`,
    });
  },
});

export interface PrimeBrokerApi {
  getPrimeBrokers: (clientId: string) => Observable<PrimeBroker[]>;
}
