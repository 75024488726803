import type { Thunk } from '@/bootstrap/thunks';
import { defaultPartialActorsSetup } from '../../rfq/actorsSetup/actorsSetupState';
import { defaultVersions } from '../../rfq/versions/versionsModel';
import { getInitialRfqData } from '../models';

export function createInitializeRfqThunk(rfqId: string): Thunk {
  return function initializeRfqThunk(
    dispatch,
    getState,
    {
      selectors: { getSesameId, isTrader },
      thunks: {
        neos: { createAddFirstStrategyThunk, createEnsureCounterpartsLoadedThunk },
      },
      actionCreators: {
        neos: { actorsSetupCrudActions, rfqDataCrudActions, versionsCrudActions, quoteCrudActions },
      },
    },
  ) {
    const state = getState();
    const sesameId = getSesameId(state);
    const isTraderInitiated = isTrader(state);

    dispatch(
      actorsSetupCrudActions.insert(rfqId, {
        ...defaultPartialActorsSetup,
        salesInitId: sesameId,
        salesValoId: undefined,
      }),
      rfqDataCrudActions.insert(rfqId, getInitialRfqData(rfqId, isTraderInitiated)),
      versionsCrudActions.insert(rfqId, defaultVersions()),
      quoteCrudActions.insert(rfqId, {
        parentId: { type: 'Rfq', id: rfqId },
        deltaUnit: '%',
        unit: undefined,
        initialSalesPrice: undefined,
        initialTraderPrice: undefined,
      }),
      createEnsureCounterpartsLoadedThunk([
        {
          salesIds: [sesameId],
          rfqIds: [rfqId],
        },
      ]),
      createAddFirstStrategyThunk(rfqId),
    );
  };
}
