import type { Thunk } from '@/bootstrap/thunks';
import type { OnyxError } from '@/neos/business/mappers/error';
import { escape as lodashEscape, groupBy, map } from 'lodash';
import { type Notification, notify } from 'reapop';

type PartialNotification = Pick<
  Notification,
  | 'title'
  | 'message'
  | 'image'
  | 'dismissAfter'
  | 'dismissible'
  | 'onAdd'
  | 'onDismiss'
  | 'showDismissButton'
  | 'allowHTML'
>;

const toaster = (notification: OmitSafe<Notification, 'id' | 'position' | 'buttons'>) => {
  return notify(notification);
};

export const createSuccessToasterThunk = (option: PartialNotification) =>
  toaster({
    ...option,
    status: 'success',
  });

export const createInfoToasterThunk = (option: PartialNotification) =>
  toaster({
    ...option,
    status: 'info',
  });

export const createWarningToasterThunk = (option: PartialNotification) =>
  toaster({
    ...option,
    status: 'warning',
  });

export const createErrorToasterThunk = (
  { message: incompleteMessage, ...option }: PartialNotification,
  error: OnyxError | undefined,
  showTechnicalError: boolean = true,
): Thunk => {
  return function errorToasterThunk(
    dispatch,
    getState,
    {
      actionCreators: {
        common: { createLogAction },
      },
      selectors: { isFeatureToggleEnabled },
    },
  ) {
    const state = getState();

    const parts = [incompleteMessage];
    if (error) {
      if (error.fieldErrors?.length > 0) {
        error.fieldErrors.forEach(({ field, message }) => {
          const cleanField = field.replace(/\[\d\]/gi, ''); //remove all [x] occurrences
          if (!errorResources[cleanField]) {
            dispatch(createLogAction(`Missing error highlight for field: ${field}`, message, true));
          }
        });
        parts.push(getFormattedErrors(error));
      }
      if (
        showTechnicalError &&
        isFeatureToggleEnabled(state, 'neos.error.technical.details.enabled')
      ) {
        parts.push(
          escapeHtmlExceptBr(
            typeof error === 'string' ? error : JSON.stringify(error, null, '<br />'),
          ),
        );
      }
    }

    const message = parts.join('<br /> ---------- <br />');

    dispatch(
      toaster({
        ...option,
        allowHTML: true,
        message,
        status: 'error',
        dismissAfter: 20 * 1_000,
      }),
    );
  };
};

export function getFormattedErrors(error: OnyxError) {
  const allErrors = error.fieldErrors.map(({ field, message }) =>
    escapeHtmlExceptBr(`- ${translateField(field)} ${message}`),
  );
  return map(
    groupBy(allErrors),
    (items, value) => `${value}${items.length > 1 ? ` (${items.length} times)` : ''}<br />`,
  ).join('');
}

function translateField(field: string) {
  const cleanField = field.replace(/\[\d\]/gi, ''); //remove all [x] occurrences
  return errorResources[cleanField] ?? cleanField;
}

function escapeHtmlExceptBr(message: string): string {
  const messageWithoutBr = message.replace(/(<br \/>)+/gi, 'BACK_TO_LINE');
  const escapedHtmlMessage = lodashEscape(messageWithoutBr);
  return escapedHtmlMessage.replace(/BACK_TO_LINE/gi, '<br />');
}

const errorResources: Record<string, string> = {
  'rfq.notional.value': 'Notional field',
  'rfq.strategies.notional.value': 'Notional field',
  'rfq.strategies.legs.product.strike.value': 'Strike field',
  'rfq.strategies.legs.product.lotSize': 'LotSize field',
  'rfq.strategies.legs.product.optionStyle': 'Option Style (Stl) field',
  'rfq.strategies.legs.product.maturity': 'Maturity field',
  'rfq.strategies.legs.product.startDate': 'Start Date field',
  'rfq.strategies.legs.product.underlying': 'Underlying field',
  'rfq.strategies.legs.negotiatedSize.numberOfLots': 'Number of lots field',
  'rfq.strategies.legs.clientWay': 'Client',
  'rfq.strategies.preAllocs.counterparty': 'OTC Pre Alloc Counterparty',
  'rfq.strategies.preAllocs.independentAmount.valueDateNotRequiredOrNotNull': 'OTC Pre Alloc',
  'rfq.strategies.references.adjustedFutureLevel.value': 'Ref Adjusted field',
  'rfq.strategies.legs.product.features.down.observationConvention': 'Down field',
  'rfq.strategies.legs.product.features.up.observationConvention': 'Up field',
  'rfq.deltas.legs.clientWay': 'Client',
  'rfq.deltas.references.adjustedFutureLevel.value': 'Ref Adjusted field',
  'rfq.quote.traderPrice.ask.value': 'Trader ASK price',
  'rfq.quote.traderPrice.bid.value': 'Trader BID price',
  'rfq.quote.salesPrice.ask.value': 'Sales ASK price',
  'rfq.quote.salesPrice.bid.value': 'Sales BID price',
  'rfq.salesCounterparty': 'Client field',
  'rfq.hedges.delta': 'Delta field',
  'rfq.hedges.deltaType': 'Delta type field',
  'rfq.salesDiffusion.salesGroup': 'Sales group field',
  'external.clientContact': '',
};
