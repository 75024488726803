import type { Dispatchable, Thunk } from '@/bootstrap/thunks.ts';
import type { LegPeriodDates } from '../../elsProductOnyxModel.ts';
import { DefaultingOptions } from '@/neos/business/rfq/rfqActions.ts';
import { isClsProduct } from '@/neos/business/rfq/strategy/leg/product/productModel.ts';
import { DefaultingScope } from '@/neos/business/neosModel.ts';

export function createChangeScheduleRateLegStartOrEndDateThunk(
  rfqId: string,
  strategyId: string,
  date: Partial<LegPeriodDates>,
  dispatchable: Dispatchable,
): Thunk {
  return function changeScheduleRateLegStartOrEndDate(
    dispatch,
    getState,
    { actionCreators, selectors },
  ) {
    const state = getState();

    const dispatchables: Dispatchable[] = [dispatchable];

    const product = selectors.getStrategyMasterProduct(state, strategyId, selectors);

    if (isClsProduct(product)) {
      return dispatch(dispatchables);
    }

    const startDateHasChanged = date.startDate !== undefined;
    const endDateHasChanged = date.endDate !== undefined;

    if (startDateHasChanged || endDateHasChanged) {
      const options = new DefaultingOptions({});

      if (startDateHasChanged) {
        options.overrideScopes.push(DefaultingScope.SCHEDULE_END_DATE);
      }

      if (endDateHasChanged) {
        options.overrideScopes.push(DefaultingScope.SCHEDULE_START_DATE);
      }
      dispatchables.push(actionCreators.neos.createDefaultRfqRequestedAction(rfqId, options));
    }

    return dispatch(dispatchables);
  };
}
