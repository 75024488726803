import type { Selectors } from '@/bootstrap/selectors';
import type { Services } from '@/bootstrap/services';
import type { AppState } from '@/bootstrap/state';
import type { OnyxElectronicMediaUser } from '@/neos/business/electronicMediaUsers/electronicMediaUserModel';
import type { Counterpart, OtcPreAlloc, RfqData } from '@/neos/business/neosModel';
import type { DisplayNegotiatedSize } from '@/neos/business/ui/strategy/strategyUiModel';
import type { StateMap } from '@/util/collectionHelper';
import { isDefined } from '@/util/undefinedAndNull/isDefined';
import { sortBy, sum } from 'lodash';
import type { TooltipInfo } from '../../listedPreAlloc/getModel/getGenericListedPreAllocationsModel';

type OtcPreAllocationModel = OtcPreAlloc & {
  isRatioInvalid: boolean;
  allocationIndex: number;
};

export interface OtcPreAllocationsModel {
  ratiosTooltipInfo: TooltipInfo | undefined;
  allocations: OtcPreAllocationModel[];
  availableClients: Counterpart[];
  masterCcy: string | undefined;
  mediaUsers: StateMap<OnyxElectronicMediaUser[]>;
  isApplicable: boolean;
  uiSizeType?: DisplayNegotiatedSize;
}

export function getGenericOtcPreAllocationsModel(
  state: AppState,
  rfqId: string,
  property: keyof Pick<RfqData, 'otcPreAllocs' | 'deltaOtcPreAllocs'>,
  selectors: Selectors,
  services: Services,
): OtcPreAllocationsModel {
  const isApplicable = getIsApplicable();

  if (!isApplicable) {
    return {
      allocations: [],
      availableClients: [],
      ratiosTooltipInfo: undefined,
      masterCcy: undefined,
      uiSizeType: undefined,
      mediaUsers: {},
      isApplicable,
    };
  }

  const relatedPreAllocs = selectors.getRfqData(state, rfqId)[property];
  const allocations = mapToAllocations();
  const ratiosTooltipInfo = getTooltipInfo();

  const availableClients = sortBy(selectors.getCounterparts(state, rfqId) ?? [], c => c.name);

  const masterStrategy = selectors.getRfqMasterStrategy(state, rfqId);
  const uiSizeType = selectors.getDisplayNegotiatedSize(state.ui, masterStrategy.uuid);
  const masterCcy = selectors.getCurrencyByStrategyId(state, masterStrategy.uuid, selectors);
  const mediaUsers = selectors.getAllElectronicMediaUsers(state);

  return {
    allocations,
    ratiosTooltipInfo,
    uiSizeType,
    availableClients,
    masterCcy,
    mediaUsers,
    isApplicable,
  };

  function getIsApplicable(): boolean {
    if (!selectors.isFeatureToggleEnabled(state, 'neos.otc.allocs.enabled')) {
      return false;
    }
    const selector: keyof Pick<
      Selectors,
      'getOtcExecutionRfqStrategies' | 'getOtcExecutionDeltaStrategies'
    > =
      property === 'otcPreAllocs'
        ? 'getOtcExecutionRfqStrategies'
        : 'getOtcExecutionDeltaStrategies';

    const relatedStrategies = selectors[selector](state, rfqId, selectors);
    return !!relatedStrategies.length;
  }

  function mapToAllocations(): OtcPreAllocationModel[] {
    return relatedPreAllocs.map(
      (alloc, index): OtcPreAllocationModel => ({
        ...alloc,
        ratio: services.applyMultiplierWithPrecision(alloc.ratio, 100, 2),
        isRatioInvalid: alloc.ratio !== undefined && (alloc.ratio < 0 || alloc.ratio > 1),
        allocationIndex: index,
      }),
    );
  }

  function getTooltipInfo(): TooltipInfo | undefined {
    const allocationValidRatios = allocations
      .filter(({ isRatioInvalid }) => !isRatioInvalid)
      .map(({ ratio }) => ratio)
      .filter(isDefined);
    if (!allocationValidRatios.length) {
      return undefined;
    }

    const ratiosSum = sum(allocationValidRatios);

    if (ratiosSum === 100) {
      return {
        ratioStyleName: 'green-ratios',
        type: undefined,
        message: 'fully-pre-allocated',
      };
    }

    return ratiosSum > 100
      ? {
          ratioStyleName: 'red-ratios',
          type: 'danger',
          message: 'over-pre-allocated',
        }
      : {
          ratioStyleName: 'orange-ratios',
          type: 'warning',
          message: 'under-pre-allocated',
        };
  }
}
