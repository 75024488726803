import type { Selectors } from '@/bootstrap/selectors';
import { services } from '@/bootstrap/services';
import type { AppState } from '@/bootstrap/state';

export interface PostNegoModel {
  tradeRecap: string;
  tradeRecapLinesNumber: number;
  deltaSummaries: string[];
  isTotalSCDisplayed: boolean;
}

export function getPostNegoModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): PostNegoModel {
  const deltaSummaries = selectors.getSortedDeltaSummaries(state, rfqId, selectors);

  const { savedStatus, tradeRecap = '' } = selectors.getRfqData(state, rfqId);
  const tradeRecapLinesNumber = Math.min((tradeRecap.match(/\n/g) ?? []).length + 2, 6);

  const isTotalSCDisplayed =
    savedStatus !== undefined &&
    services.orderOf(savedStatus).isAfterOrEqual('PRICE_REQUESTED') &&
    !selectors.isRfqInitiatedByTrader(state, rfqId);

  return {
    deltaSummaries,
    tradeRecap,
    tradeRecapLinesNumber,
    isTotalSCDisplayed,
  };
}
