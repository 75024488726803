import type { ThunkEpic } from '@/bootstrap/epics';
import { type Selectors, selectors } from '@/bootstrap/selectors';
import { type Thunks, thunks } from '@/bootstrap/thunks';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { ofType } from 'redux-observable';
import type { Observable } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import type { OnyxError } from '../../mappers/error';
import type { BlotterPreconfStatus } from '../blotterModel';
import { createBlotterPreconApi } from './blotterPreconfApi';

export interface LoadBlotterPreconfApi {
  loadPreconfStatus(rfqIds: string[]): Observable<BlotterPreconfStatus[]>;
}

export function getLoadBlotterPreconEpic(http: SgmeHttp): ThunkEpic {
  const api = createBlotterPreconApi(http);
  return createLadBlotterPreconEpic(api, selectors, thunks);
}

export function createLadBlotterPreconEpic(
  api: LoadBlotterPreconfApi,
  { isFeatureToggleEnabled, getBlotterRfqsIds }: Selectors,
  { createErrorToasterThunk, neos: { createIntegrateReceivedBlotterPreconfThunk } }: Thunks,
): ThunkEpic {
  return (action$, state$) =>
    action$.pipe(
      ofType('REQUEST_ALL_BLOTTER_PRECONF'),
      filter(() => isFeatureToggleEnabled(state$.value, 'neos.preconfirmation.blotter.enabled')),
      switchMap(() => {
        const blotterState = state$.value.blotter;
        const rfqIds = getBlotterRfqsIds(blotterState);
        return api.loadPreconfStatus(rfqIds).pipe(
          map(blotterPreconfStatus => {
            return createIntegrateReceivedBlotterPreconfThunk(blotterPreconfStatus);
          }),
          catchError((error: { response: OnyxError | undefined }) => [
            createErrorToasterThunk(
              {
                message:
                  error?.response?.content ?? 'Error when loading all Blotter Preconf status',
              },
              error?.response,
            ),
          ]),
        );
      }),
    );
}
