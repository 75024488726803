import type { Thunk } from '@/bootstrap/thunks';

export function createChangeLegMidThunk(
  legId: string,
  mid: number | undefined,
  isPtmmm: boolean,
): Thunk {
  return function changeLegMidThunk(
    dispatch,
    getState,
    {
      selectors: { getLegData },
      actionCreators: {
        neos: { quoteCrudActions, fairPriceCrudActions },
      },
    },
  ) {
    const state = getState();
    const { fairPriceId, quoteId } = getLegData(state, legId);

    if (isPtmmm) {
      dispatch(quoteCrudActions.update(quoteId, { preTradeMidMarketMarkPrice: mid }));
      return;
    }

    if (fairPriceId) {
      dispatch(fairPriceCrudActions.update(fairPriceId, { mid }));
    }
  };
}
