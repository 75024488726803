import { exportDataToExcel } from '@/util/excel/excel.ts';
import type { Thunk } from '@/bootstrap/thunks.ts';
import { isElsBasketProduct } from '@/neos/business/rfq/strategy/leg/product/productModel.ts';

export function createExportElsBasketCompositionThunk(rfqId: string, productId: string): Thunk {
  return function exportElsBasketCompositionThunk(_, getState, { selectors }) {
    const state = getState();
    const product = selectors.getProduct(state, productId);
    if (!isElsBasketProduct(product)) {
      return;
    }

    const detailsWithUnderyingInfo = product.basketUnderlying.basketComposition
      .filter(compositionDetails => compositionDetails.underlyingId !== undefined)
      .map(compositionDetails => {
        const underlyingInfo = selectors.getUnderlyingInfo(state, compositionDetails.underlyingId);
        const underlyingReference = compositionDetails.underlyingId
          ? selectors.getReference(state, {
              rfqId,
              underlyingId: compositionDetails.underlyingId,
            })
          : undefined;

        return {
          ...compositionDetails,
          underlyingInfo,
          underlyingReference,
        };
      });

    const exportedData = detailsWithUnderyingInfo.map(underlyingDetails => {
      const line = [
        ['Bloomberg Code', underlyingDetails.underlyingInfo?.bloombergCode],
        ['Weight', underlyingDetails.weight],
        ['Quantity', underlyingDetails.quantity],
        ['Spot', underlyingDetails.underlyingReference?.refSpot],
        ['Spot Unit', underlyingDetails.underlyingReference?.refSpotUnit],
        ['Spot Net', underlyingDetails.underlyingReference?.refSpotNet],
        ['Spot Net Unit', underlyingDetails.underlyingReference?.refSpotNetUnit],
        ['Nominal', underlyingDetails.nominal?.value],
        ['Nominal Unit', underlyingDetails.nominal?.unit],
      ];

      if (
        product.basketUnderlying.execFees === 'IN' ||
        product.basketUnderlying.execFees === 'IN_AND_OUT'
      ) {
        line.push(['Exec Fees In', underlyingDetails.execFeesIn?.value]);
        line.push(['Exec Fees In Unit', underlyingDetails.execFeesIn?.unit]);
      }

      if (
        product.basketUnderlying.execFees === 'OUT' ||
        product.basketUnderlying.execFees === 'IN_AND_OUT'
      ) {
        line.push(['Exec Fees Out', underlyingDetails.execFeesOut?.value]);
        line.push(['Exec Fees Out Unit', underlyingDetails.execFeesOut?.unit]);
      }

      return Object.fromEntries(line);
    });

    exportDataToExcel(exportedData, 'Els Basket Composition');
  };
}
