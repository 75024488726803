import type { BlobWithMetadata } from '@/util/http/sgmeAjaxHttpRequest';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { OnyxTransaction } from '../../neosOnyxModel';

import type {
  InternalOnyxPreconfirmation,
  InternalPreconfirmationRequest,
} from './internalOnyxPreconfirmationModel';
import type { InternalPreconfirmationApi } from './internalPreconfirmationApiType';

export const createInternalPreconfirmationApi = (http: SgmeHttp): InternalPreconfirmationApi => ({
  sendInternalPreconfirmationEmail(
    uuid: string,
    internalPreconfirmationRequest: InternalPreconfirmationRequest,
    transaction: OnyxTransaction,
    ignoreFieldChanges,
  ) {
    const url: string = `api/workflow-neos/${uuid}/sendPreconfMail?mailType=INTERNAL`;
    return http.post<InternalOnyxPreconfirmation>({
      url,
      body: {
        ignoreFieldChanges,
        mailOptions: internalPreconfirmationRequest,
        mailType: 'INTERNAL',
        transaction,
      },
    });
  },

  getInternalPreconfirmationEmailPreview(
    uuid: string,
    internalPreconfirmationRequest: InternalPreconfirmationRequest,
  ) {
    const url: string = `api/preConfirmations/${uuid}/previewAttachment?type=INTERNAL`;
    return http.put<BlobWithMetadata>({
      url,
      body: internalPreconfirmationRequest,
      responseContentType: { contentType: 'BLOB' },
    });
  },
});
