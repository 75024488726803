import type { CurrenciesReceivedAction } from './currencyActions';
import type { Currency } from './currencyModel';

export const currencyActionCreators = {
  createCurrenciesReceivedAction,
};

export function createCurrenciesReceivedAction(currencies: Currency[]): CurrenciesReceivedAction {
  return {
    type: 'CURRENCIES_RECEIVED',
    currencies,
  };
}
