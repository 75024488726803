import type { Thunk } from '@/bootstrap/thunks';

export function createRfqRemoveObsoleteForexThunk(rfqId: string): Thunk {
  return function rfqRemoveObsoleteForexThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { forexRatesCrudActions },
      },
    },
  ) {
    const appState = getState();

    const newForexRates = selectors.getForexRates(appState, rfqId);

    dispatch(forexRatesCrudActions.upsert(rfqId, { forexRates: newForexRates }));
  };
}
