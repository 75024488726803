import type { Thunk } from '@/bootstrap/thunks';
import type { StrategyAllocationsModel } from '@/neos/components/rfq/postNego/allocs/getAllocationsModel';
import { isDefined } from '@/util/undefinedAndNull/isDefined';

export function createChangeListedOrdersMatchingThunk(
  rfqId: string,
  value: boolean,
  strategyLegs: StrategyAllocationsModel[],
): Thunk {
  return function changeListedOrdersMatchingThunk(
    dispatch,
    getState,
    { selectors, actionCreators },
  ) {
    const listedLegIds = strategyLegs.flatMap(strategy =>
      strategy.legsModel.filter(leg => !leg.isOtc).map(leg => leg.legId),
    );
    const state = getState();

    const listedLegOrders = listedLegIds.map(legId =>
      selectors.getOrderByLegId(state.orderData, rfqId, legId),
    );

    dispatch(
      listedLegOrders
        .filter(isDefined)
        .map(order =>
          actionCreators.neos.orderCrudActionCreators.update(
            { orderId: order.uuid, rfqId },
            { matching: value },
          ),
        ),
    );
  };
}
