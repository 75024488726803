import type { OnyxRfq } from '@/neos/business/rfq/rfqOnyxModel';
import { uniq } from 'lodash';
import type { NegotiationMode } from '../../../../../neos/business/neosModel';

export function getBlotterNegoTypes({ strategies }: OnyxRfq): string {
  const negotiationTypes = strategies.map(
    s => s.legs.find(l => l.master)?.product?.negotiation?.discriminator,
  );
  const uni = uniq(negotiationTypes);
  const formatNegoType = (nt: NegotiationMode | undefined) =>
    nt === 'LISTED' ? 'Listed' : (nt ?? 'N/A');

  if (uni.length === 1) {
    return formatNegoType(uni[0]);
  }

  return negotiationTypes.map(nt => '[' + formatNegoType(nt) + ']').join(', ');
}
