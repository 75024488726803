import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type {
  DerogationKey,
  PredealCheck,
  PredealCheckType,
  RiskPredealCheck,
} from '@/neos/business/predealCheck/predealCheckModel';
import { canDerogate } from '../../../util/canDerogate';
import {
  getDfaDetails,
  getDfaSecDetails,
  getEmirDetails,
  getMifidDetails,
  getRegulationDetails,
  getRiskDetails,
  getUMRDetails,
} from './util/details';
import { getKycDetails } from './util/details/getKycDetails';
import {
  type DerogationCommentsInfo,
  getPredealCheckDerogationComments,
} from './util/getPredealCheckDerogationComments';

export interface PredealCheckDetailsModel {
  predealCheckTitle: string;
  detail: PredealCheckDetail;
  canApplyAll: boolean;
  canDerogate: boolean;
  commentInfo: DerogationCommentsInfo;
  bdrId: number;
}

const detailsTitlesMap: { [k in PredealCheckType]: string } = {
  KYC: 'KYC',
  MIFID1: 'MIFID',
  CREDIT_CHECK_ASK: 'Risk',
  CREDIT_CHECK_BID: 'Risk',
  COST_AND_CHARGES: 'Cost & Charges',
  DFA: 'DFA CFTC',
  DFA_SEC: 'DFA SEC',
  EMIR: 'EMIR',
  FATCA: 'FATCA',
  CASS: 'CASS',
  EMBARGO: 'Embargo Ins.',
  HKMA: 'HKMA',
  PTT: 'PTT',
  HIRE_ACT: 'HIRE Act',
  IM_VM: 'IM VM',
  CROSS_BORDER: 'Cross Border',
  EMBARGO_CTPY: 'Embargo Ctp.',
  IM_SIMULATION_BID: 'UMR RFQ Bid',
  IM_SIMULATION_ASK: 'UMR RFQ Ask',
};

export function getPredealCheckDetailsModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): PredealCheckDetailsModel {
  const selectedPredealCheck = selectors.getNeosSelectedPredealCheck(state, rfqId, selectors);
  if (!selectedPredealCheck) {
    throw new Error('Selected predeal check not found');
  }
  const canApplyAll =
    selectors.getNeosPredealChecksByTypeAndLevel(
      state,
      rfqId,
      selectedPredealCheck.type,
      selectedPredealCheck.level,
      selectors,
    ).length > 1;

  const detail = {
    ...getMessagesDetails(selectedPredealCheck),
    ...getDerogationDetails(selectedPredealCheck),
  };

  const { status } = selectors.getRfqData(state, rfqId);

  return {
    predealCheckTitle: detailsTitlesMap[selectedPredealCheck.type],
    detail,
    commentInfo: getPredealCheckDerogationComments(state, selectedPredealCheck.type, selectors),
    canApplyAll,
    canDerogate: canDerogate(status),
    bdrId: selectedPredealCheck.counterpartyId,
  };
}

interface PredealCheckDetailMessage {
  label: string;
  message: string;
}

interface PredealCheckDetailFlag {
  label: string;
  value?: boolean;
}

export interface DerogationDetails {
  isDerogatable: boolean;
  selectedDerogationComment: DerogationKey | undefined;
  selectedIsDerogated: boolean;
  riskDetails: Pick<RiskPredealCheck, 'riskId' | 'derogationComment' | 'uuid'> | undefined;
}

interface MessagesDetails {
  messages: PredealCheckDetailMessage[];
  technicalMessages: PredealCheckDetailMessage[];
  flags: PredealCheckDetailFlag[];
}

interface PredealCheckDetail extends DerogationDetails, MessagesDetails {}

function getMessagesDetails(predealCheck: PredealCheck): MessagesDetails {
  switch (predealCheck.type) {
    case 'KYC':
      return getKycDetails(predealCheck);
    case 'MIFID1':
      return getMifidDetails(predealCheck);
    case 'CREDIT_CHECK_ASK':
    case 'CREDIT_CHECK_BID':
      return getRiskDetails(predealCheck);
    case 'DFA':
      return getDfaDetails(predealCheck);
    case 'DFA_SEC':
      return getDfaSecDetails(predealCheck);
    case 'EMIR':
      return getEmirDetails(predealCheck);
    case 'COST_AND_CHARGES':
    case 'FATCA':
    case 'CASS':
    case 'EMBARGO':
    case 'EMBARGO_CTPY':
    case 'HKMA':
    case 'HIRE_ACT':
    case 'CROSS_BORDER':
    case 'IM_VM':
      return getRegulationDetails(predealCheck);
    case 'IM_SIMULATION_BID':
    case 'IM_SIMULATION_ASK':
      return getUMRDetails(predealCheck);
    case 'PTT':
      throw Error('No details are available for PTT');
  }
}

function getDerogationDetails(predealCheck: PredealCheck): DerogationDetails {
  return {
    isDerogatable: predealCheck.isDerogable,
    selectedDerogationComment: predealCheck.pdcDerogation,
    selectedIsDerogated: predealCheck.status === 'DEROGATED',
    riskDetails: getDerogationRiskDetails(predealCheck),
  };
}

function getDerogationRiskDetails(predealCheck: PredealCheck): DerogationDetails['riskDetails'] {
  return predealCheck.status === 'DEROGATED' &&
    (predealCheck.type === 'CREDIT_CHECK_ASK' || predealCheck.type === 'CREDIT_CHECK_BID')
    ? {
        derogationComment: predealCheck.derogationComment,
        riskId: predealCheck.riskId,
        uuid: predealCheck.uuid,
      }
    : undefined;
}
