import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import type { NextUserAction } from '@/neos/business/nextUserActions/nextUserActionsModel';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import {
  DeltaAdjustedCancelButtonComponent,
  type DeltaAdjustedCancelButtonModel,
  type DeltaAdjustedCancelButtonOwnProps,
  type DeltaAdjustedCancelButtonStateProps,
} from './DeltaAdjustedCancelButton';

export const DeltaAdjustedCancelButton = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeltaAdjustedCancelButtonComponent);

function mapStateToProps(
  state: AppState,
  { rfqId }: DeltaAdjustedCancelButtonOwnProps,
): DeltaAdjustedCancelButtonStateProps {
  const { status } = selectors.getRfqData(state, rfqId);
  const { nextActions } = selectors.getNextUserActions(state, rfqId);

  const executeDeltaManualyNextAction = nextActions.find(
    ({ title }) => title === 'Execute Delta Manually',
  );

  const model: DeltaAdjustedCancelButtonModel = !(
    status === 'DELTA_BEING_EXECUTED' && executeDeltaManualyNextAction
  )
    ? {
        isDisabled: true,
      }
    : {
        isDisabled: false,
        executeDeltaManualyNextAction,
      };

  return {
    model,
  };
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId }: DeltaAdjustedCancelButtonOwnProps,
) {
  return {
    cancel: (nextUserAction: NextUserAction) => {
      dispatch(
        createLogAnalyticsAction('NEOS RFQ', 'Cancel order', nextUserAction.action),
        neosThunks.createApplyNextUserActionThunk(rfqId, nextUserAction),
      );
    },
  };
}
