import type { Thunk } from '@/bootstrap/thunks.ts';
import type { ElsBasket } from '@/neos/business/rfq/strategy/leg/product/productModel.ts';

export function createUpdateElsBasketCompositionUnderlyingThunk(
  product: ElsBasket,
  rfqId: string,
  underlyingId: string | undefined,
  index: number,
): Thunk {
  return function updateElsBasketCompositionUnderlying(dispatch, _, { thunks }) {
    if (underlyingId === undefined) {
      return;
    }

    dispatch(
      thunks.neos.createUpdateElsBasketCompositionThunk(
        product,
        index,
        'underlyingId',
        underlyingId,
      ),
    );

    dispatch(
      thunks.neos.createNeosLoadUnderlyingInfoAndChangeThunk(
        rfqId,
        underlyingId,
        thunks.neos.createDefaultBasketProductThunk(rfqId),
      ),
    );
  };
}
