import { type Way, clientWays, idbWays } from '@/neos/business/rfq/rfqData/rfqDataModel';
import { ErrorHighlight } from '@/neos/components/share/errorHighlight';
import type { FC } from 'react';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';

import styles from './ClientWay.module.scss';

export interface ClientWayOwnProps {
  rfqId: string;
}

export interface ClientWayMapStateToProps {
  internal: boolean | undefined;
  clientWay?: Way;
  isReadonly: boolean;
}

export interface ClientWayDispatchProps {
  onClientWayChanged(clientWay: Way | undefined): void;
}

export const ClientWayComponent: FC<
  ClientWayOwnProps & ClientWayMapStateToProps & ClientWayDispatchProps
> = props => (
  <section className={`${styles['client-way']} `}>
    <ErrorHighlight errorField="clientWay" related={{ rfqId: props.rfqId }}>
      <ToggleButtonGroup
        name="clientWay"
        className="errorable-bloc"
        type="radio"
        value={props.clientWay}
        onChange={(val: Way) => props.onClientWayChanged(val)}
      >
        <ToggleButton
          variant="outline-danger"
          disabled={props.isReadonly && props.clientWay !== 'SELL'}
          value={'SELL' as Way}
          data-e2e="neos-rfq-client-way-sell"
          id="neos-rfq-client-way-sell"
        >
          {props.internal ? idbWays['SELL'] : clientWays['SELL']}
        </ToggleButton>
        <ToggleButton
          variant="outline-primary"
          disabled={props.isReadonly && props.clientWay !== 'TWO_WAYS'}
          value={'TWO_WAYS' as Way}
          data-e2e="neos-rfq-client-way-two-ways"
          id="neos-rfq-client-way-two-ways"
        >
          {props.internal ? idbWays['TWO_WAYS'] : clientWays['TWO_WAYS']}
        </ToggleButton>
        <ToggleButton
          variant="outline-success"
          disabled={props.isReadonly && props.clientWay !== 'BUY'}
          value={'BUY' as Way}
          data-e2e="neos-rfq-client-way-buy"
          id="neos-rfq-client-way-buy"
        >
          {props.internal ? idbWays['BUY'] : clientWays['BUY']}
        </ToggleButton>
      </ToggleButtonGroup>
    </ErrorHighlight>
  </section>
);
