import type { ErrorableField } from '@/neos/business/mappers/error/errorHandlerData';
import { StrategyPtmmm } from '@/neos/components/rfq/strategies/strategy/prices/strategyPtmmm/StrategyPtmmm.container';
import { cn } from '@/util/classNames';
import { formatFromCamelToStartCase } from '@/util/format';
import { FormSelect } from 'react-bootstrap';
import type { MarginRules, PriceUnitType } from '../../../../../../business/neosModel';
import { ErrorHighlight } from '../../../../../share/errorHighlight';
import { ErrorHighlightWithTooltip } from '../../../../../share/errorHighlightWithTooltip/ErrorHighlightWithTooltip.container';
import { NumericInput } from '../../../../../share/numericInput';
import { MidPrices } from '../MidPrices/MidPrices.container';
import { type FieldInfo, GenericLegsQuotes } from './genericQuotes/GenericLegsQuotes';
import { getQuotesAndHeaderGridColumnTemplate } from './getQuotesAndHeaderGridColumnTemplate';
import {
  type FullQuotesModel,
  isFullQuotesModel,
  type QuoteModel,
  type StrategyAndLegsQuoteModel,
} from './getQuotesModel';

import { If } from '@/neos/components/share/if/if';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import styles from './Quotes.module.scss';

export interface QuotesMapStateProps {
  model: StrategyAndLegsQuoteModel;
}

export interface QuotesOwnProps {
  rfqId: string;
  strategyId: string;

  onFocus?(): void;
}

export interface WrapedQuotesDispatchProps {
  dispatchProps: QuotesDispatchProps;
}

type OnLegChanged = (quoteId: string, legId: string, value?: number) => void;
type OnStratChanged = (quoteId: string, value?: number) => void;

interface QuotesDispatchProps {
  onLegTraderBidChanged: OnLegChanged;
  onLegTraderAskChanged: OnLegChanged;
  onLegSalesBidChanged: OnLegChanged;
  onLegSalesAskChanged: OnLegChanged;
  onLegTraderDeltaChanged: OnLegChanged;
  onLegMarkupRuleChange: (quoteId: string, rules: MarginRules) => void;

  onStrategyTraderBidChanged: OnStratChanged;
  onStrategyTraderAskChanged: OnStratChanged;
  onStrategySalesBidChanged: OnStratChanged;
  onStrategySalesAskChanged: OnStratChanged;
  onStrategyTraderDeltaChanged: OnStratChanged;
  onStrategyMarkupRuleChange: (rules: MarginRules) => void;
  onStrategyQuoteUnitChanged: (unit: string | undefined) => void;
}

export type QuotesProps = QuotesOwnProps & QuotesMapStateProps & WrapedQuotesDispatchProps;

export function QuotesComponent({ rfqId, strategyId, model, onFocus, dispatchProps }: QuotesProps) {
  return (
    <section
      data-e2e="neos-quote-strategy-definition"
      className={`${styles['quotes']} ${styles['quotes-grid']}`}
      style={getQuotesAndHeaderGridColumnTemplate({
        areElsPtmmmDisplayed: model.areElsPtmmmDisplayed,
        areFairPricesDisplayed: model.areFairPricesDisplayed,
        areSalesPricesDisplayed: model.areSalesPricesDisplayed,
        isTraderDeltaDisplayed: model.isTraderDeltaDisplayed,
        isMarkupDisplayed: model.isMarkupDisplayed,
      })}
      onFocus={onFocus}
    >
      {isFullQuotesModel(model)
        ? FullQuotesComponent({ rfqId, strategyId, model, dispatchProps })
        : EmptyQuotesComponent()}
    </section>
  );
}

interface GenericQuoteModel {
  rfqId: string;
  strategyId: string;
  model: FullQuotesModel;
  onLegChanged: OnLegChanged;
  onStratChanged: OnStratChanged;
  onStrategyQuoteUnitChanged: (unit: string | undefined) => void;
}

function genericQuotesPrice(
  genericQuoteModel: GenericQuoteModel,
  legFieldInfo: FieldInfo,
  strategyErrorField: ErrorableField,
  isUnitDisplayed: boolean,
) {
  const { model, rfqId, strategyId, onLegChanged, onStratChanged, onStrategyQuoteUnitChanged } =
    genericQuoteModel;

  const {
    legsQuotes,
    strategyQuotes,
    areTraderPricesEnabled,
    isReadonly,
    displayEmptyFirstLeg,
    areUnitsDifferent,
  } = model;

  const { color, isTransparentKey, fieldName: displayField } = legFieldInfo;

  const { availablePriceUnitTypes, unit, isQuoteUnitEditable, quoteId } = strategyQuotes;

  const unitsToUse: string[] = getAvailablePriceUnits(unit, availablePriceUnitTypes);

  return (
    <section>
      <If condition={displayEmptyFirstLeg}>
        <NumericInput readOnly />
      </If>
      <GenericLegsQuotes
        rfqId={rfqId}
        strategyId={strategyId}
        fieldInfo={legFieldInfo}
        legsQuotes={legsQuotes}
        areTraderPricesEnabled={areTraderPricesEnabled}
        isUnitDisplayed={isUnitDisplayed}
        onLegChanged={onLegChanged}
      />
      <div
        className={styles['unit-selector-container']}
        data-e2e={`neos-quote-strategy-${displayField}-container`}
      >
        <If condition={isQuoteUnitEditable && isUnitDisplayed}>
          <FormSelect
            value={unit}
            onChange={ev => onStrategyQuoteUnitChanged(ev.target.value || undefined)}
            readOnly={isReadonly}
          >
            <option value="">---</option>
            {unitsToUse.map(unit => (
              <option key={unit} value={unit}>
                {unit}
              </option>
            ))}
          </FormSelect>
        </If>

        <ErrorHighlightWithTooltip
          errorField={strategyErrorField}
          related={{ rfqId, strategyId }}
          id={strategyId}
          isDisplayed={
            isAveragePriceTooltipDisplayed(displayField, strategyQuotes) || areUnitsDifferent
          }
          renderMessage={() => (
            <p>{areUnitsDifferent ? strategyQuotes.currencyUnit : strategyQuotes.averagePrice}</p>
          )}
          key={strategyId}
          componentClassName="warning-bloc field-warning"
          tooltipClassName="react-bootstrap-warning-tooltip"
        >
          {displayField === 'traderAsk' || displayField === 'traderBid' ? (
            <span>
              <SimpleNeosTooltip
                id="trader-warning"
                type={'warning'}
                message={strategyQuotes.traderWarning?.[displayField]}
              >
                <NumericInput
                  color={color}
                  className={cn(
                    { transparent: strategyQuotes[isTransparentKey] },
                    'errorable-bloc',
                    {
                      'field-warning': !!strategyQuotes.traderWarning?.[displayField],
                    },
                  )}
                  inputClassName="fw-bold"
                  unit={isUnitDisplayed && !isQuoteUnitEditable ? unit : undefined}
                  value={strategyQuotes[displayField]}
                  areUnitsDifferent={areUnitsDifferent}
                  onBlur={(val: number | undefined) => onStratChanged(quoteId, val)}
                  readOnly={isReadonly}
                  data-e2e={`neos-quote-strategy-${displayField}`}
                />
              </SimpleNeosTooltip>
            </span>
          ) : (
            <NumericInput
              color={color}
              className={cn({ transparent: strategyQuotes[isTransparentKey] }, 'errorable-bloc')}
              inputClassName="fw-bold"
              unit={isUnitDisplayed && !isQuoteUnitEditable ? unit : undefined}
              value={strategyQuotes[displayField]}
              areUnitsDifferent={areUnitsDifferent}
              onBlur={(val: number | undefined) => onStratChanged(quoteId, val)}
              readOnly={isReadonly}
              data-e2e={`neos-quote-strategy-${displayField}`}
            />
          )}
        </ErrorHighlightWithTooltip>
      </div>
    </section>
  );
}

function isAveragePriceTooltipDisplayed(
  displayField: 'salesBid' | 'salesAsk' | 'traderBid' | 'traderAsk',
  strategyQuotes: QuoteModel,
): boolean {
  return (
    ((displayField === 'salesAsk' && strategyQuotes.isAveragePriceTooltipSalesAskEnabled) ||
      (displayField === 'salesBid' && strategyQuotes.isAveragePriceTooltipSalesBidEnabled)) &&
    !!strategyQuotes.averagePrice
  );
}

function getSalesBidPricesComponent(model: GenericQuoteModel) {
  const legFieldInfo: FieldInfo = {
    color: 'BID',
    fieldName: 'salesBid',
    legErrorField: 'legSalesPriceBid',
    isTransparentKey: 'bidIsTransparent',
  };
  return genericQuotesPrice(model, legFieldInfo, 'strategySalesPriceBid', true);
}

function getTraderBidPricesComponent(model: GenericQuoteModel) {
  const legFieldInfo: FieldInfo = {
    color: 'BID',
    fieldName: 'traderBid',
    legErrorField: 'legTraderPriceBid',
    isTransparentKey: 'bidIsTransparent',
  };
  return genericQuotesPrice(
    model,
    legFieldInfo,
    'strategyTraderPriceBid',
    !model.model.areSalesPricesDisplayed,
  );
}

function getTraderAskPricesComponent(model: GenericQuoteModel) {
  const legFieldInfo: FieldInfo = {
    color: 'ASK',
    fieldName: 'traderAsk',
    legErrorField: 'legTraderPriceAsk',
    isTransparentKey: 'askIsTransparent',
  };
  return genericQuotesPrice(model, legFieldInfo, 'strategyTraderPriceAsk', false);
}

function getSalesAskPricesComponent(model: GenericQuoteModel) {
  const legFieldInfo: FieldInfo = {
    color: 'ASK',
    fieldName: 'salesAsk',
    legErrorField: 'legSalesPriceAsk',
    isTransparentKey: 'askIsTransparent',
  };
  return genericQuotesPrice(model, legFieldInfo, 'strategySalesPriceAsk', false);
}

interface FullQuotesComponentProps {
  rfqId: string;
  strategyId: string;
  model: FullQuotesModel;
  dispatchProps: QuotesDispatchProps;
}

function FullQuotesComponent({
  rfqId,
  strategyId,
  model,
  dispatchProps: {
    onLegTraderAskChanged,
    onLegTraderBidChanged,
    onLegTraderDeltaChanged,
    onLegMarkupRuleChange,

    onStrategyTraderAskChanged,
    onStrategyTraderBidChanged,
    onStrategyTraderDeltaChanged,
    onLegSalesBidChanged,
    onLegSalesAskChanged,
    onStrategySalesBidChanged,
    onStrategySalesAskChanged,
    onStrategyMarkupRuleChange,
    onStrategyQuoteUnitChanged,
  },
}: FullQuotesComponentProps) {
  const isReadOnly = model.isReadonly;
  return (
    <>
      <If condition={model.areSalesPricesDisplayed}>
        {getSalesBidPricesComponent({
          rfqId,
          model,
          strategyId,
          onLegChanged: onLegSalesBidChanged,
          onStratChanged: onStrategySalesBidChanged,
          onStrategyQuoteUnitChanged,
        })}
      </If>

      {getTraderBidPricesComponent({
        rfqId,
        model,
        strategyId,
        onLegChanged: onLegTraderBidChanged,
        onStratChanged: onStrategyTraderBidChanged,
        onStrategyQuoteUnitChanged,
      })}

      <If condition={model.areElsPtmmmDisplayed}>
        <StrategyPtmmm rfqId={rfqId} strategyId={strategyId} />
      </If>
      <If condition={model.areFairPricesDisplayed}>
        <MidPrices rfqId={rfqId} strategyId={strategyId} />
      </If>

      {getTraderAskPricesComponent({
        rfqId,
        model,
        strategyId,
        onLegChanged: onLegTraderAskChanged,
        onStratChanged: onStrategyTraderAskChanged,
        onStrategyQuoteUnitChanged,
      })}

      <If condition={model.areSalesPricesDisplayed}>
        {getSalesAskPricesComponent({
          rfqId,
          model,
          strategyId,
          onLegChanged: onLegSalesAskChanged,
          onStratChanged: onStrategySalesAskChanged,
          onStrategyQuoteUnitChanged,
        })}
        <section>
          {model.displayEmptyFirstLeg && <NumericInput readOnly />}
          {model.legsQuotes.map(quotes => (
            <FormSelect
              key={quotes.quoteId}
              data-e2e="neos-strategy-leg-markup-rule"
              value={quotes.salesMarginRule || ''}
              onChange={event =>
                onLegMarkupRuleChange(
                  quotes.quoteId,
                  (event.target.value as MarginRules) || undefined,
                )
              }
            >
              <option value="" />
              {model.availableMarginRules.map(rule => (
                <option value={rule} key={rule}>
                  {formatFromCamelToStartCase(rule)}
                </option>
              ))}
            </FormSelect>
          ))}

          <If condition={model.isMarkupDisplayed}>
            <FormSelect
              readOnly={isReadOnly}
              value={model.strategyQuotes.salesMarginRule || ''}
              data-e2e="neos-strategy-markup-rule"
              onChange={event =>
                onStrategyMarkupRuleChange((event.target.value as MarginRules) || undefined)
              }
            >
              <option value={''} />
              {model.availableMarginRules.map(rule => (
                <option value={rule} key={rule}>
                  {formatFromCamelToStartCase(rule)}
                </option>
              ))}
            </FormSelect>
          </If>
        </section>
      </If>

      {/* delta */}
      <If condition={model.isTraderDeltaDisplayed}>
        <section>
          {model.displayEmptyFirstLeg && <NumericInput readOnly />}
          {model.legsQuotes.map(quotes => (
            <ErrorHighlight
              key={quotes.quoteId}
              errorField={'legQuoteDelta'}
              related={{ rfqId, legId: quotes.legId, strategyId }}
            >
              <NumericInput
                unit="%"
                className="errorable-bloc"
                value={quotes.traderDeltaPercent}
                withMaximumNumberOfFloatingDigits={2}
                onBlur={(val: number | undefined) =>
                  onLegTraderDeltaChanged(quotes.quoteId, quotes.legId, val)
                }
                readOnly={!model.areTraderDeltaEnabled}
                data-e2e="neos-strategy-quote-delta"
              />
            </ErrorHighlight>
          ))}
          <ErrorHighlight errorField={'strategyQuoteDelta'} related={{ rfqId, strategyId }}>
            <NumericInput
              unit="%"
              inputClassName="fw-bold"
              className="errorable-bloc"
              value={model.strategyQuotes.traderDeltaPercent}
              withMaximumNumberOfFloatingDigits={2}
              onBlur={(val: number | undefined) =>
                onStrategyTraderDeltaChanged(model.strategyQuotes.quoteId, val)
              }
              readOnly={!model.areTraderDeltaEnabled}
              data-e2e="neos-strategy-quote-delta"
            />
          </ErrorHighlight>
        </section>
      </If>
    </>
  );
}

function EmptyQuotesComponent() {
  return (
    <>
      <section>{<NumericInput readOnly />}</section>
      <section>
        <NumericInput readOnly />
      </section>
      <section>
        <NumericInput readOnly />
      </section>
    </>
  );
}

function getAvailablePriceUnits(unit: string, availablePriceUnitTypes: PriceUnitType[]) {
  const currencies = ['AUD', 'CAD', 'CHF', 'EUR', 'GBP', 'HKD', 'JPY', 'USD'];

  const currenciesToUse: string[] = [];
  if (availablePriceUnitTypes.includes('CCY')) {
    if (!unit || currencies.includes(unit)) {
      currenciesToUse.push(...currencies);
    } else {
      currenciesToUse.push(...[...currencies, unit].sort());
    }
  }

  return currenciesToUse;
}
