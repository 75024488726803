import { createAlertReceivedThunk } from './alertReceivedThunk';
import { createEnableHandlingAlertClickThunk } from './enableHandlingAlertClickThunk';
import { createPlayMdpSoundThunk } from './playMdpSongThunk';
import { createRequestNotificationPermissionThunk } from './requestNotificationPermissionThunk';

export const alertThunks = {
  createEnableHandlingAlertClickThunk,
  createRequestNotificationPermissionThunk,
  createAlertReceivedThunk,
  createPlayMdpSoundThunk,
};
