import type {
  UserPreferencesCity,
  UserPreferencesCountry,
} from '@/neos/business/currentUserPreferences/currentUserPreferencesModel';
import type { SalesGroup } from '@/neos/business/referenceData/salesGroups/salesGroupsModel';
import type { TraderGroup } from '@/neos/business/referenceData/traderGroups/traderGroupsModel';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { Observable } from 'rxjs';

export interface CurrentUserPreferencesReferentialApis {
  getCities: () => Observable<UserPreferencesCity[]>;
  getCountries: () => Observable<UserPreferencesCountry[]>;
  getTraderGroups: () => Observable<TraderGroup[]>;
  getDesks: () => Observable<SalesGroup[]>;
}

export const createUserPreferencesReferentialApis = (
  http: SgmeHttp,
): CurrentUserPreferencesReferentialApis => {
  const BaseUrl: string = 'api/users/preferences';
  return {
    getCities() {
      return http.get<UserPreferencesCity[]>({ url: BaseUrl + '/cities' });
    },
    getCountries() {
      return http.get<UserPreferencesCountry[]>({ url: BaseUrl + '/countries' });
    },
    getTraderGroups() {
      return http.get<TraderGroup[]>({ url: BaseUrl + '/tradergroups' });
    },
    getDesks() {
      return http.get<SalesGroup[]>({ url: BaseUrl + '/desks' });
    },
  };
};
