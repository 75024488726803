import { NumericInput } from '@/neos/components/share/numericInput';
import type { TypeaheadValue } from '@/neos/components/share/typeahead';
import { useState } from 'react';
import { UnderlyingInput } from '../../../common';
import type { TopSectionModel } from './getTopSectionModel';

import styles from './TopSection.module.scss';

export interface TopSectionOwnProps {
  rfqId: string;
  strategyId: string;
}

export interface TopSectionMapStateProps {
  model: TopSectionModel;
}

export interface TopSectionDispatchProps {
  onTopChanged(value: number | undefined): void;
  onUnderlyingTopChanged(strategyId: string, underlyingId: string | undefined): void;
  onCompositionOpened(): void;
}

type TopSectionProps = TopSectionOwnProps & TopSectionMapStateProps & TopSectionDispatchProps;

export const TopSectionComponent = (props: TopSectionProps) => {
  const [underlyingFocusedOn, setUnderlyingFocusedOn] = useState(false);
  return props.model.displayTopSection ? (
    <div className={`mb-1 ${styles['top-section']}`}>
      <div onFocus={() => setUnderlyingFocusedOn(true)}>
        {underlyingFocusedOn || props.model.underlyingId ? (
          <UnderlyingInput
            componentId={`neos-underlying-${props.strategyId}`}
            underlyingId={props.model.underlyingId}
            bloombergCode={props.model.bloombergCode}
            readOnly={props.model.disabled}
            underlyingTypes={props.model.underlyingTypes}
            data-e2e="neos-strategy-definition-aggregatable-underlying"
            onChange={(val: TypeaheadValue | undefined) =>
              props.onUnderlyingTopChanged(props.strategyId, val && val.value)
            }
            autoFocus={underlyingFocusedOn}
            onBlur={() => setUnderlyingFocusedOn(false)}
          />
        ) : (
          <input defaultValue={'Custom'} className="input-sm form-control"></input>
        )}
      </div>
      <div className={styles['top-label-and-input']}>
        {!props.model.isCustomMode && <label>Top</label>}
        <NumericInput
          value={props.model.top}
          readOnly={props.model.disabled}
          onBlur={val => props.onTopChanged(val)}
          data-e2e="neos-strategy-definition-top"
          className={`${styles['top-input']} errorable-bloc`}
        />
      </div>
      <button
        className={`${
          props.model.displayErrorHighlight ? styles['error'] : ''
        } btn btn-discreet-secondary p-0`}
        data-e2e="neos-strategy-definition-composition"
        onClick={() => props.onCompositionOpened()}
      >
        <i className="icon icon-sm">more_horiz</i>
      </button>
    </div>
  ) : (
    <div />
  );
};
