import { DefaultingOptions } from '../rfqActions';

import type { ChainOptions, RetryOnErrorOptions } from '../../neosActions';
import { DefaultingScope } from '../models';
import { createDefaultRfqRequestedAction } from './defaultRfqRequestedAction';

export function createDefaultMcaRequestedAction(rfqId: string, chainOptions?: ChainOptions) {
  const options = new DefaultingOptions({
    overrideScopes: [DefaultingScope.MCA],
  });
  const retryOptions: RetryOnErrorOptions = {
    retries: 1,
  };
  return createDefaultRfqRequestedAction(rfqId, options, chainOptions, retryOptions);
}
