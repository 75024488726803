import type { Thunk } from '@/bootstrap/thunks.ts';
import type { Els } from '@/neos/business/rfq/strategy/leg/product/productModel.ts';

export function createUpdateElsStockLoanHedgeComponentUnderlyingThunk(
  product: Els,
  rfqId: string,
  underlyingId: string | undefined,
  index: number,
): Thunk {
  return function updateElsStockLoanHedgeComponentUnderlying(dispatch, _, { thunks }) {
    if (underlyingId === undefined) {
      return;
    }

    dispatch(
      thunks.neos.createUpdateElsStockLoanComponentThunk(
        product.uuid,
        index,
        'underlyingId',
        underlyingId,
      ),
    );

    dispatch(thunks.neos.createNeosLoadUnderlyingInfoAndChangeThunk(rfqId, underlyingId, []));
  };
}
