import { crudDomain } from '@/util/crudUtils';
import type { OtcAllocation, OtcAllocationKey } from './otcAllocationModel';

const compositeIdSeparator = ' | ';

function keyToString({ rfqId, legId, allocationId }: OtcAllocationKey): string {
  return `${rfqId}${compositeIdSeparator}${legId}${compositeIdSeparator}${allocationId}`;
}

function stringToKey(id: string): OtcAllocationKey {
  const [rfqId, legId, allocationId] = id.split(compositeIdSeparator);
  return { rfqId, legId, allocationId };
}

export const otcAllocationDomain = crudDomain<OtcAllocation, OtcAllocationKey>('otcAllocation', {
  keyToString,
  stringToKey,
});
