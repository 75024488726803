import { invert } from 'lodash';

export type LabelableSubStatus =
  | 'ABORTED'
  | 'DEAL_CANCEL'
  | 'NO_DEAL'
  | 'NO_DEAL_WITH_SG'
  | 'NO_QUOTE'
  | 'TECHNICAL_ABORTED';

export type SubStatus = LabelableSubStatus | 'NONE';

export const allSubStatusLabel = [
  'Aborted',
  'Deal Cancel',
  'Nothing Done',
  'Traded Away',
  'No Quote',
  'Technical Aborted',
] as const;

export type SubStatusLabel = (typeof allSubStatusLabel)[number];

export const labelSubStatuses: Record<LabelableSubStatus, SubStatusLabel> = {
  ABORTED: 'Aborted',
  DEAL_CANCEL: 'Deal Cancel',
  NO_DEAL: 'Nothing Done',
  NO_DEAL_WITH_SG: 'Traded Away',
  NO_QUOTE: 'No Quote',
  TECHNICAL_ABORTED: 'Technical Aborted',
};

export const labelSubStatusLabels = invert(labelSubStatuses) as Record<SubStatusLabel, SubStatus>;
