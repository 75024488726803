import type { ChainOptions } from '@/neos/business/neosActions';
import type { ActionContainer } from '@/util/actionContainer';
import type { RecomputeScope } from '../models/recomputeModel';
import { createRecomputeRfqRequestedAction } from './recomputeRfqRequestedAction';

export function createActionWithRecompute(
  rfqId: string,
  scope: RecomputeScope,
  actions: ActionContainer[],
  chainOptions?: ChainOptions,
): ActionContainer {
  return [...actions, createRecomputeRfqRequestedAction(rfqId, scope, chainOptions)];
}
