import type { HandledField } from '@/neos/business/services/fieldMatchers';
import type { DeltaId, LegId, RfqId, StrategyId } from './errorHandlerModel';

export interface ErrorableFieldsRelated {
  adjustedFutureLevel: DeltaId;
  clientWay: RfqId;
  salesCounterparty: RfqId;
  deltaType: StrategyId;
  legTraderPriceAsk: LegId;
  legTraderPriceBid: LegId;
  legSalesPriceAsk: LegId;
  legSalesPriceBid: LegId;
  legQuoteDelta: LegId;
  strategyType: StrategyId;
  strategyTraderPriceAsk: StrategyId;
  strategyTraderPriceBid: StrategyId;
  strategySalesPriceAsk: StrategyId;
  strategySalesPriceBid: StrategyId;
  strategyQuoteDelta: StrategyId;
  clientPosition: LegId;
  optionStyle: LegId;
  optionFlex: LegId;
  lotSize: LegId;
  underlying: LegId;
  strike: LegId;
  upperStrike: LegId;
  lowerStrike: LegId;
  maturity: LegId;
  effectiveDate: LegId;
  futureMaturity: LegId;
  description: StrategyId;
  expectedN: LegId;
  noTaxCollection: LegId;
  clientTaxRate: LegId;
  startDate: LegId;
  settlementDate: LegId;
  strikeDate: LegId;
  numberOfLots: LegId;
  deliveryType: StrategyId;
  rfqTraderPriceBid: RfqId;
  rfqTraderPriceAsk: RfqId;
  traderGroup: StrategyId;
  trader: StrategyId;
  market: LegId;
  rfqNotional: RfqId;
  up: StrategyId;
  down: StrategyId;
  observationConvention: StrategyId;
  forwardDrift: LegId;
  forwardInterestRate: LegId;
  forwardStartDate: StrategyId;
  equityBullet: StrategyId;
  rateBullet: StrategyId;
  rateOvernight: StrategyId;
  resetFrequency: StrategyId;
  accrual: LegId;
  clsType: LegId;
  execFees: StrategyId;
  swapCurrency: LegId;
  rateCurve: LegId;
  rateTenor: LegId;
  forexType: StrategyId;
  rfqPriceRequestDate: RfqId;
  otcPreAllocCounterparty: RfqId;
  otcPreAllocIAValueDate: RfqId;
  endOfObservation: StrategyId;
  preConfExternalClientContact: RfqId;
  observableType: LegId;
}

export interface ErrorableNonFieldRelated {
  strategy: StrategyId;
}

export type ErrorableAllUiRelated = ErrorableFieldsRelated & ErrorableNonFieldRelated;

export type ErrorableField = HandledField;
export type ErrorableAllUi = keyof ErrorableAllUiRelated;

type ErrorReceivedRelatedFieldFilterType = {
  [key in ErrorableField]: (t: ErrorableFieldsRelated[key]) => ErrorableFieldsRelated[key];
};

// Used to filter received errors on the corresponding property
export const errorReceivedRelatedFieldFilter: ErrorReceivedRelatedFieldFilterType = {
  adjustedFutureLevel: all => ({
    rfqId: all.rfqId,
    deltaId: all.deltaId,
  }),
  clientWay: all => ({ rfqId: all.rfqId }),
  deltaType: all => ({ rfqId: all.rfqId, strategyId: all.strategyId }),
  strategyType: all => ({ rfqId: all.rfqId, strategyId: all.strategyId }),
  legTraderPriceAsk: all => ({
    rfqId: all.rfqId,
    strategyId: all.strategyId,
    legId: all.legId,
  }),
  legTraderPriceBid: all => ({
    rfqId: all.rfqId,
    strategyId: all.strategyId,
    legId: all.legId,
  }),
  legSalesPriceAsk: all => ({
    rfqId: all.rfqId,
    strategyId: all.strategyId,
    legId: all.legId,
  }),
  legSalesPriceBid: all => ({
    rfqId: all.rfqId,
    strategyId: all.strategyId,
    legId: all.legId,
  }),
  legQuoteDelta: all => ({ rfqId: all.rfqId, strategyId: all.strategyId, legId: all.legId }),
  strategyTraderPriceAsk: all => ({ rfqId: all.rfqId, strategyId: all.strategyId }),
  strategyTraderPriceBid: all => ({ rfqId: all.rfqId, strategyId: all.strategyId }),
  strategySalesPriceAsk: all => ({ rfqId: all.rfqId, strategyId: all.strategyId }),
  strategySalesPriceBid: all => ({ rfqId: all.rfqId, strategyId: all.strategyId }),
  salesCounterparty: all => ({ rfqId: all.rfqId }),
  strategyQuoteDelta: all => ({ rfqId: all.rfqId, strategyId: all.strategyId }),
  optionStyle: all => ({ rfqId: all.rfqId, strategyId: all.strategyId, legId: all.legId }),
  lotSize: all => ({ rfqId: all.rfqId, strategyId: all.strategyId, legId: all.legId }),
  market: all => ({ rfqId: all.rfqId, strategyId: all.strategyId, legId: all.legId }),
  underlying: all => ({ rfqId: all.rfqId, strategyId: all.strategyId, legId: all.legId }),
  strike: all => ({ rfqId: all.rfqId, strategyId: all.strategyId, legId: all.legId }),
  upperStrike: all => ({ rfqId: all.rfqId, strategyId: all.strategyId, legId: all.legId }),
  lowerStrike: all => ({ rfqId: all.rfqId, strategyId: all.strategyId, legId: all.legId }),
  settlementDate: all => ({ rfqId: all.rfqId, strategyId: all.strategyId, legId: all.legId }),
  strikeDate: all => ({ rfqId: all.rfqId, strategyId: all.strategyId, legId: all.legId }),
  maturity: all => ({ rfqId: all.rfqId, strategyId: all.strategyId, legId: all.legId }),
  effectiveDate: all => ({ rfqId: all.rfqId, strategyId: all.strategyId, legId: all.legId }),
  startDate: all => ({ rfqId: all.rfqId, strategyId: all.strategyId, legId: all.legId }),
  description: all => ({ rfqId: all.rfqId, strategyId: all.strategyId }),
  numberOfLots: all => ({ rfqId: all.rfqId, strategyId: all.strategyId, legId: all.legId }),
  deliveryType: all => ({ rfqId: all.rfqId, strategyId: all.strategyId }),
  rfqTraderPriceBid: all => ({ rfqId: all.rfqId }),
  rfqTraderPriceAsk: all => ({ rfqId: all.rfqId }),
  traderGroup: all => ({ rfqId: all.rfqId, strategyId: all.strategyId }),
  trader: all => ({ rfqId: all.rfqId, strategyId: all.strategyId }),
  rfqNotional: all => ({ rfqId: all.rfqId }),
  up: all => ({ rfqId: all.rfqId, strategyId: all.strategyId }),
  down: all => ({ rfqId: all.rfqId, strategyId: all.strategyId }),
  observationConvention: all => ({ rfqId: all.rfqId, strategyId: all.strategyId }),
  forwardDrift: all => ({ rfqId: all.rfqId, strategyId: all.strategyId, legId: all.legId }),
  forwardInterestRate: all => ({
    rfqId: all.rfqId,
    strategyId: all.strategyId,
    legId: all.legId,
  }),
  forwardStartDate: all => ({ rfqId: all.rfqId, strategyId: all.strategyId }),
  equityBullet: all => ({ rfqId: all.rfqId, strategyId: all.strategyId }),
  rateBullet: all => ({ rfqId: all.rfqId, strategyId: all.strategyId }),
  rateOvernight: all => ({ rfqId: all.rfqId, strategyId: all.strategyId }),
  resetFrequency: all => ({ rfqId: all.rfqId, strategyId: all.strategyId }),
  accrual: all => ({ rfqId: all.rfqId, strategyId: all.strategyId, legId: all.legId }),
  clsType: all => ({ rfqId: all.rfqId, strategyId: all.strategyId, legId: all.legId }),
  execFees: all => ({ rfqId: all.rfqId, strategyId: all.strategyId }),
  swapCurrency: all => ({ rfqId: all.rfqId, strategyId: all.strategyId, legId: all.legId }),
  rateCurve: all => ({ rfqId: all.rfqId, strategyId: all.strategyId, legId: all.legId }),
  rateTenor: all => ({ rfqId: all.rfqId, strategyId: all.strategyId, legId: all.legId }),
  forexType: all => ({ rfqId: all.rfqId, strategyId: all.strategyId }),
  rfqPriceRequestDate: all => ({ rfqId: all.rfqId }),
  otcPreAllocCounterparty: all => ({ rfqId: all.rfqId }),
  otcPreAllocIAValueDate: all => ({ rfqId: all.rfqId }),
  endOfObservation: all => ({ rfqId: all.rfqId, strategyId: all.strategyId }),
  preConfExternalClientContact: all => ({ rfqId: all.rfqId }),
};
