import type { AppState } from '@/bootstrap/state';
import { orderBy } from 'lodash';

export function getUnderlyingStrikesByMaturity(
  appState: AppState,
  underlyingId: string,
  maturity: string,
): number[] {
  return appState.underlyingState[underlyingId] &&
    appState.underlyingState[underlyingId].option.maturityInfo[maturity]
    ? orderBy(
        appState.underlyingState[underlyingId].option.maturityInfo[maturity].strikes,
        strike => strike,
        ['asc'],
      )
    : [];
}

export function getUnderlyingStrikesByMaturityOnOptionOnFuture(
  appState: AppState,
  underlyingId: string,
  maturity: string,
): number[] {
  if (
    appState.underlyingState[underlyingId] &&
    appState.underlyingState[underlyingId].option.optionOnFutureMaturityInfo
  ) {
    const maturities = appState.underlyingState[underlyingId].option.optionOnFutureMaturityInfo;
    return orderBy(maturities?.[maturity]?.strikes, strike => strike, ['asc']);
  }
  return [];
}
