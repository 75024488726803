import { DefaultingOptions } from '../rfqActions';

import { DefaultingScope } from '../models';
import { createDefaultRfqRequestedAction } from './defaultRfqRequestedAction';

export function createDefaultNegotiationModeRequestedAction(rfqId: string) {
  const options = new DefaultingOptions({
    overrideScopes: [DefaultingScope.DETERMINATION_METHOD],
  });
  return createDefaultRfqRequestedAction(rfqId, options);
}
