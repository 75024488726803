import { useAppSelector } from '@/bootstrap/hooks';
import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import type { CustomizableQuickButtonToggle } from '@/neos/business/ui/userPreferences/userPreferencesUiModel';
import { Switch } from '@/neos/components/share/switch/Switch';
import { getRfqPreferencesModel } from '@/neos/components/userPreferences/rfq/getRfqPreferencesModel';
import { useDispatch } from 'react-redux';
import { LocationPreferenceToggle } from './LocationPreferenceToggle';
import { StrategyPicker } from './StrategyPicker';
import { UnderlyingPicker } from './UnderlyingPicker';

export function RfqPreferences() {
  const {
    isIbChatSectionShown,
    isQuickButtonsSectionShown,
    quickButtonStrategyToggle,
    quickButtonUnderlyingToggle,
    isIndicativeTradableDisplayed,
  } = useAppSelector(state => getRfqPreferencesModel(state, selectors));

  const dispatch = useDispatch<ExtendedDispatch>();
  const switchIbChatSectionToggle = (toggle: boolean) => {
    dispatch(
      createLogAnalyticsAction('NEOS USER PREFERENCES', 'Switch ib chat section toggle'),
      neosActionCreators.createIbChatSwitchedAction(toggle),
    );
  };
  const switchQuickButtonsSectionToggle = (toggle: boolean) => {
    dispatch(
      createLogAnalyticsAction('NEOS USER PREFERENCES', 'Switch quick buttons section toggle'),
      neosActionCreators.createQuickButtonsSwitchedAction(toggle),
    );
  };
  const onIndicativeTradableChanged = (isIndicativeTradableDisplayed: boolean) => {
    dispatch(
      neosActionCreators.createIndicativeTradableChangedAction(isIndicativeTradableDisplayed),
    );
  };

  const switchQuickButtonStrategyToggleModel = (toggle: CustomizableQuickButtonToggle) => {
    dispatch(
      createLogAnalyticsAction('NEOS USER PREFERENCES', 'Switch quick button strategy toggle'),
      neosActionCreators.createQuickButtonStrategyToggleAction(toggle),
    );
  };
  const switchQuickButtonUnderlyingToggleModel = (toggle: CustomizableQuickButtonToggle) => {
    dispatch(
      createLogAnalyticsAction('NEOS USER PREFERENCES', 'Switch quick button underlying toggle'),
      neosActionCreators.createQuickButtonUnderlyingToggleAction(toggle),
    );
  };

  return (
    <>
      <div>
        <Switch
          checked={isIbChatSectionShown}
          labelName="IB Chat section"
          onChange={() => switchIbChatSectionToggle(!isIbChatSectionShown)}
        />
        <Switch
          checked={isQuickButtonsSectionShown}
          labelName="Quick buttons section"
          onChange={() => switchQuickButtonsSectionToggle(!isQuickButtonsSectionShown)}
        />
        <Switch
          checked={isIndicativeTradableDisplayed}
          labelName="Indicative / Tradable"
          onChange={() => onIndicativeTradableChanged(!isIndicativeTradableDisplayed)}
        />
      </div>
      <div>
        <label className="py-2">Strategies buttons configuration</label>
        <div className="col d-grid" data-e2e="neos-user-preferences-menu-rfqtab-strategy-config">
          <LocationPreferenceToggle
            name="strategy-rfq-prefs"
            toggle={quickButtonStrategyToggle}
            switchToggle={switchQuickButtonStrategyToggleModel}
          />
        </div>
      </div>
      {quickButtonStrategyToggle === 'CUSTOM' && <StrategyPicker />}
      <div className="py-2">
        <label className="py-2">Underlying buttons configuration</label>
        <div className="col d-grid" data-e2e="neos-user-preferences-menu-rfqtab-underlying-config">
          <LocationPreferenceToggle
            name="underlying-rfq-prefs"
            toggle={quickButtonUnderlyingToggle}
            switchToggle={switchQuickButtonUnderlyingToggleModel}
          />
        </div>
      </div>
      {quickButtonUnderlyingToggle === 'CUSTOM' && <UnderlyingPicker />}
    </>
  );
}
