import { actionCreators } from '@/bootstrap/actions';
import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { RfqRecomputeScope } from '@/neos/business/rfq/models';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import {
  type RfqPremiumDispatchProps,
  type RfqPremiumOwnProps,
  type RfqPremiumPropsFromState,
  RfqPremiumStyled,
} from './RfqPremium';
import { type PricesType, getRfqPremiumModel } from './getRfqPremiumModel';

export const RfqPremium = connect(mapStateToProps, mapDispatchToProps)(RfqPremiumStyled);

function mapStateToProps(state: AppState, { rfqId }: RfqPremiumOwnProps): RfqPremiumPropsFromState {
  return getRfqPremiumModel(state, rfqId, selectors);
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId }: RfqPremiumOwnProps,
): RfqPremiumDispatchProps {
  return {
    onTotalBidChanged(value: number | undefined, priceType: PricesType) {
      dispatch(
        neosThunks.createRecomputeRfqThunk(
          rfqId,
          priceType === 'Sls'
            ? RfqRecomputeScope.CHANGE_SALES_PRICE_RFQ
            : RfqRecomputeScope.CHANGE_PRICE_RFQ,
          priceType === 'Sls'
            ? neosThunks.createUpdateTotalRfqQuoteThunk(rfqId, { salesBid: value })
            : neosThunks.createUpdateTotalRfqQuoteThunk(rfqId, { traderBid: value }),
        ),
      );
    },
    onTotalAskChanged(value: number | undefined, priceType: PricesType) {
      dispatch(
        neosThunks.createRecomputeRfqThunk(
          rfqId,
          priceType === 'Sls'
            ? RfqRecomputeScope.CHANGE_SALES_PRICE_RFQ
            : RfqRecomputeScope.CHANGE_PRICE_RFQ,
          priceType === 'Sls'
            ? neosThunks.createUpdateTotalRfqQuoteThunk(rfqId, { salesAsk: value })
            : neosThunks.createUpdateTotalRfqQuoteThunk(rfqId, { traderAsk: value }),
        ),
      );
    },
    onTotalPtmmmChanged: (value: number | undefined) =>
      dispatch(neosThunks.createChangeRfqMidThunk(rfqId, value, true)),
    onReverseYes: () =>
      dispatch(
        neosThunks.createRfqReverseStrategyWeightThunk(rfqId, {
          success: {
            dispatchables: [neosThunks.createRequestRfqFairPricesThunk(rfqId)],
          },
        }),
      ),
    onReverseNo: () =>
      dispatch(
        actionCreators.neos.rfqUiCrudActions.patchOrInsert(rfqId, {
          reverseWeightsMessageDismissed: true,
        }),
      ),
  };
}
