import { DATE_SHORT_KEBAB_FORMAT, formatNow } from '@/util/date/dateFormatHelper';
import {
  type Cls,
  type DefaultProductValuesForStrategy,
  type Product,
  isClsProduct,
} from '../../../../../../../../../neos/business/neosModel';
import {
  getCommonPart,
  getDerivativePart,
  getOtcNegotiationPart,
} from './getDefaultedPartialProduct';

export function getDefaultedClsProduct(
  currentProduct: Product,
  defaultValues: DefaultProductValuesForStrategy,
): Cls {
  const {
    maturity,
    underlying,
    strikeDate: strategyStrikeDate,
    accrual: strategyAccrualType,
    clsType: strategyClsType,
  } = defaultValues;
  const {
    strikeDate: currentStrikeDate,
    clsType: currentClsType,
    accrual: currentAccrualType,
  } = isClsProduct(currentProduct)
    ? currentProduct
    : {
        strikeDate: undefined,
        clsType: undefined,
        accrual: undefined,
      };

  return {
    subFamily: 'CLS',
    strikeDate: strategyStrikeDate ?? currentStrikeDate ?? formatNow(DATE_SHORT_KEBAB_FORMAT),
    strikeTenor: undefined,
    accrual: strategyAccrualType ?? currentAccrualType ?? undefined,
    clsType: strategyClsType ?? currentClsType,
    ...getCommonPart(currentProduct, defaultValues),
    ...getOtcNegotiationPart(),
    ...getDerivativePart(currentProduct, maturity, underlying),
    hasCustomUnderlying: false,
  };
}
