import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { PreconfirmationPrefsApi } from './apiModel';

export const createPreconfirmationPreferencesApi = (http: SgmeHttp): PreconfirmationPrefsApi => {
  const BaseUrl: string = `api/groups/preConfirmation`;
  return {
    getUserSavedGroups() {
      return http.get<string[]>({ url: BaseUrl });
    },
    addUserSavedGroup(groupKey: string) {
      const url = `${BaseUrl}/${groupKey}`;
      return http.post<void>({
        url,
        body: {},
      });
    },
    deleteUserSavedGroup(groupKey: string) {
      const url = `${BaseUrl}/${groupKey}`;
      return http.delete<void>({ url });
    },
  };
};
