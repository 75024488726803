import type { AppState } from '@/bootstrap/state';
import { listedAllocationDomain } from './listedAllocationDomain';
import type { ListedAllocation } from './listedAllocationModel';

export const listedAllocationSelectors = {
  listedAllocationSelectors: listedAllocationDomain.selectors,
  getListedAllocationsByRfqId,
};

export function getListedAllocationsByRfqId(state: AppState, rfqId: string): ListedAllocation[] {
  return listedAllocationDomain.selectors.selectObjects(state.listedAllocationState, { rfqId });
}
