import { Fragment } from 'react';
import type { PttPredealChecksModel } from './getPttPredealChecksModel';

import styles from './PttPredealChecks.module.scss';

export interface PttPredealChecksOwnProps {
  rfqId: string;
}

export type PttPredealChecksStateProps = PttPredealChecksModel;

export type PttPredealChecksProps = PttPredealChecksOwnProps & PttPredealChecksStateProps;
export const PttPredealChecksWithStyle = (props: PttPredealChecksProps) => (
  <section className="card card-bordered">
    <div className={`${styles['ptt-panel-body']} card-body`}>
      <span className={`${styles['ptt-label']} `}>MIFID2 PTT:</span>
      <table className={`${styles['ptt-table']} `}>
        <thead>
          <tr>
            <th>Leg #</th>
            <th>ISIN / TOKEN</th>
          </tr>
        </thead>
        <tbody>
          {props.legIdsByStrategyIds.map(({ strategyId, legIds }, startIndex) => (
            <Fragment key={strategyId}>
              <tr className={`${styles['strategy']} `} key={strategyId}>
                <td>Strat {startIndex + 1}</td>
                <td />
              </tr>
              {legIds.map((legId, legIndex) => (
                <tr key={legId}>
                  <td>Leg {legIndex + 1}</td>
                  <td>
                    {props.legs[legId].isinCode
                      ? props.legs[legId].isinCode
                      : props.legs[legId].token
                        ? props.legs[legId].token
                        : 'Not received yet'}
                  </td>
                </tr>
              ))}
            </Fragment>
          ))}
        </tbody>
      </table>
    </div>
  </section>
);
