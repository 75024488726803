import type { Thunk } from '@/bootstrap/thunks';
import { distinct } from '@/util/array/arrayUtils';
import { isDefined } from '@/util/undefinedAndNull/isDefined';
import { isEqual, uniq } from 'lodash';
import type { CounterpartRequest } from '../rfq/actorsSetup/counterpart/counterpartActions';

export function createEnsureUniqueCounterpartsLoadedThunk(rfqIds: string[]): Thunk {
  return function ensureUniqueCounterpartsLoadedThunk(
    dispatch,
    getState,
    {
      selectors,
      thunks: {
        neos: {
          createEnsureCounterpartsLoadedThunk,
          createEnsureAllocExtraCounterpartsLoadedThunk,
        },
      },
    },
  ) {
    const appState = getState();

    const requests: CounterpartRequest[] = [];
    const extraCounterpartRequests: { rfqId: string; counterpartId: number }[] = [];
    rfqIds.forEach(rfqId => {
      const { salesInitId, salesValoId } = selectors.getActorsSetup(appState, rfqId)!;
      const currSalesIds = uniq([salesInitId, salesValoId].filter(isDefined)).sort();
      const found = requests.find(({ salesIds }) => isEqual(salesIds, currSalesIds));
      if (found) {
        found.rfqIds.push(rfqId);
      } else {
        requests.push({ salesIds: currSalesIds, rfqIds: [rfqId] });
      }

      distinct(
        selectors
          .getOtcAllocationsByRfqId(appState, rfqId)
          .map(alloc => alloc.counterpartId)
          .filter(isDefined),
      ).forEach(counterpartId => extraCounterpartRequests.push({ rfqId, counterpartId }));
    });

    dispatch(
      extraCounterpartRequests.length
        ? createEnsureCounterpartsLoadedThunk(
            requests,
            createEnsureAllocExtraCounterpartsLoadedThunk(extraCounterpartRequests),
          )
        : createEnsureCounterpartsLoadedThunk(requests),
    );
  };
}
