import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import type { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  RequestPredealCheckCellComponent,
  type RequestPredealCheckCellDispatchProps,
  type RequestPredealCheckCellOwnProps,
  type RequestPredealCheckCellStateProps,
} from './RequestPredealCheckCell';
import { getRequestPredealCheckCellModel } from './getRequestPredealCheckCellModel';

export const RequestPredealCheckCell: FC<RequestPredealCheckCellOwnProps> = ownProps => {
  const stateProps = useSelector<AppState, RequestPredealCheckCellStateProps>(state => ({
    model: getRequestPredealCheckCellModel(
      state,
      ownProps.rfqId,
      ownProps.type,
      ownProps.bdrId,
      selectors,
    ),
  }));

  const dispatch = useDispatch<ExtendedDispatch>();
  const dispatchProps: RequestPredealCheckCellDispatchProps = mapDispatchProps(dispatch, ownProps);

  return <RequestPredealCheckCellComponent {...ownProps} {...stateProps} {...dispatchProps} />;
};

function mapDispatchProps(
  dispatch: ExtendedDispatch,
  { rfqId }: RequestPredealCheckCellOwnProps,
): RequestPredealCheckCellDispatchProps {
  return {
    onSelected(predealCheckId: string) {
      dispatch(neosActionCreators.createNeosPredealCheckSelected(rfqId, predealCheckId));
    },
  };
}
