import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { type NeosActionCreators, neosActionCreators } from '@/neos/business/neosActionCreators';
import { useDispatch, useSelector } from 'react-redux';
import {
  SecondaryTypeCellComponent,
  type SecondaryTypeCellDispatchProps,
  type SecondaryTypeCellMapStateProps,
  type SecondaryTypeCellOwnProps,
} from './SecondaryTypeCell';
import { getSecondaryTypeCellModel } from './getSecondaryTypeCellModel';

/**
 * Displayed at leg level in a secondary rfq
 * for display only not an action button
 * both in transaction and in Similar Activities
 */
export const SecondaryTypeCell = (ownProps: SecondaryTypeCellOwnProps) => {
  const stateProps = useSelector<AppState, SecondaryTypeCellMapStateProps>(state => {
    return getSecondaryTypeCellModel(state, ownProps.rfqId, ownProps.strategyId, selectors);
  });
  const dispatch = useDispatch<ExtendedDispatch>();
  const dispatchProps = getDispatchProps(dispatch, neosActionCreators);

  return <SecondaryTypeCellComponent {...ownProps} {...stateProps} {...dispatchProps} />;
};

function getDispatchProps(
  dispatch: ExtendedDispatch,
  neosActionCreators: NeosActionCreators,
): SecondaryTypeCellDispatchProps {
  return {
    loadLatestTransaction: (primaryRfqId: string) => {
      dispatch(
        createLogAnalyticsAction(
          'NEOS RFQ',
          'Open primary RFQ on secondary event click, on secondary RFQ',
        ),
        neosActionCreators.loadTransactionAtVersion({ rfqId: primaryRfqId, version: 'LATEST' }),
      );
    },
  };
}
