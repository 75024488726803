import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { Workflow } from '@/neos/business/rfq/rfqOnyxModel';
import type { MultipleTypeaheadValue } from '@/neos/components/share/multipleAsyncTypeahead/MultipleAsyncTypeahead';
import { sortBy } from 'lodash';
import { type Status, labelStatuses } from '../../../../neos/business/neosModel';

export interface BlotterSearchPropsFromState {
  from: string;
  to: string;
  workflows: Workflow[];
  underlyingsAndCounterparts: MultipleTypeaheadValue[];
  staticOptions: MultipleTypeaheadValue[];
  maturityDates: string;
  maturityDateFrom: string;
  maturityDateTo: string;
}

export function getBlotterSearchModel(
  state: AppState,
  selectors: Selectors,
): BlotterSearchPropsFromState {
  const blotterState = state.blotter;
  const typeaheadValueSelected = selectors.getBlotterTypeaheadValue(blotterState);
  const staticOptions = getStaticOptions(state, selectors);

  return {
    from: selectors.getBlotterUiFrom(blotterState),
    to: selectors.getBlotterUiTo(blotterState),
    workflows: selectors.getBlotterWorkflows(blotterState),
    underlyingsAndCounterparts: typeaheadValueSelected.map(val => ({
      label: val.label.substr(0, 8),
      value: val.value,
      origin: val.origin,
    })),
    staticOptions,
    maturityDates: selectors.getBlotterMaturityDateValue(blotterState),
    maturityDateFrom: selectors.getBlotterMaturityDateFromValue(blotterState),
    maturityDateTo: selectors.getBlotterMaturityDateToValue(blotterState),
  };
}

function getStaticOptions(state: AppState, selectors: Selectors) {
  const staticOptions = [];

  const statuses = Object.keys(labelStatuses).map((statusKey): MultipleTypeaheadValue => {
    const status = statusKey as Status;
    return {
      label: status === 'CANCELLED' ? 'Cancelled' : selectors.getLabelStatus(status),
      value: status,
      origin: 'STATUS',
    };
  });

  const strategiesDefinitions = selectors.getStrategyDefinitions(state.referenceData, 'RFQ');
  const strategyTypes = strategiesDefinitions.map(
    ({ id, name }): MultipleTypeaheadValue => ({ value: id, label: name, origin: 'STRATEGY_TYPE' }),
  );

  staticOptions.push(...sortBy(statuses, s => s.label), ...sortBy(strategyTypes, s => s.label));
  return staticOptions;
}
