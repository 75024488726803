import type { ThunkEpic } from '@/bootstrap/epics';
import { type Selectors, selectors } from '@/bootstrap/selectors';
import { type Thunks, thunks } from '@/bootstrap/thunks';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { ofType } from 'redux-observable';
import type { Observable } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { createNextUserActionsApi } from '../apis/retrieveNextUserActionsApi';
import type { OnyxError } from '../mappers/error';
import type { OnyxNextUserActions } from '../neosOnyxModel';

export interface NextUserActionsApi {
  retrieveNextUserActions: (
    rfqId: string,
    internal: boolean | undefined,
    initiatedByTrader: boolean | undefined,
  ) => Observable<OnyxNextUserActions>;
}

export function getNextUserActionsEpic(http: SgmeHttp): ThunkEpic {
  const api = createNextUserActionsApi(http);
  return createNextUserActionsEpic(api, thunks, selectors);
}

export function createNextUserActionsEpic(
  api: NextUserActionsApi,
  { createErrorToasterThunk, neos: { createIntegrateNextUserActions } }: Thunks,
  { getRfqData }: Selectors,
): ThunkEpic {
  return (action$, state$) =>
    action$.pipe(
      ofType('NEXT_USER_ACTIONS_REQUESTED'),
      mergeMap(({ rfqId }) => {
        const appState = state$.value;
        const { internal, initiatedByTrader } = getRfqData(appState, rfqId);

        return api.retrieveNextUserActions(rfqId, internal, initiatedByTrader).pipe(
          mergeMap(nextUserActions => [createIntegrateNextUserActions(rfqId, nextUserActions)]),
          catchError((error: OnyxError) => {
            return [
              createErrorToasterThunk(
                {
                  message: 'Error retrieving next user actions',
                },
                error,
              ),
            ];
          }),
        );
      }),
    );
}
