import { Children, type PropsWithChildren, cloneElement, isValidElement } from 'react';
import {
  OverlayTrigger,
  type OverlayTriggerProps,
  Tooltip as ReactBootstrapTooltip,
} from 'react-bootstrap';
import type { OverlayDelay } from 'react-bootstrap/esm/OverlayTrigger';
import type { Placement } from 'react-bootstrap/esm/types';

interface NeosTooltipPropsWithoutChildren
  extends PropsWithChildren<OmitSafe<OverlayTriggerProps, 'overlay'>> {
  overlay?: OverlayTriggerProps['overlay'];
  componentClassName?: string;
  id?: string;
  isDisplayed?: boolean;
  placement?: Placement;
  renderMessage?: () => JSX.Element;
  tooltipClassName?: string;
  tooltipDataE2e?: string;
  delay?: OverlayDelay;
}

export type NeosTooltipProps = PropsWithChildren<NeosTooltipPropsWithoutChildren>;

export const NeosTooltip = ({
  children,
  componentClassName = '',
  delay,
  id,
  isDisplayed = false,
  onToggle,
  overlay,
  placement = 'top',
  renderMessage,
  tooltipClassName = 'tooltip',
  tooltipDataE2e,
  trigger,
  ...rest
}: NeosTooltipProps) => {
  return Children.map(children, child => {
    if (!isValidElement(child)) {
      return null;
    }

    if (!isDisplayed && delay === undefined && trigger === undefined && overlay === undefined) {
      return child;
    }

    if (
      (!trigger && !isDisplayed) ||
      ((isDisplayed || trigger || delay) && (overlay !== undefined || renderMessage !== undefined))
    ) {
      return (
        <OverlayTrigger
          delay={delay ?? undefined}
          key={id ?? ''}
          onToggle={onToggle}
          overlay={
            overlay ?? (
              <ReactBootstrapTooltip className={tooltipClassName} id={id} data-e2e={tooltipDataE2e}>
                {renderMessage!()}
              </ReactBootstrapTooltip>
            )
          }
          placement={placement}
          trigger={trigger ?? undefined}
          {...rest}
        >
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */}
          {cloneElement(child, {
            ...child.props,
            className: `${componentClassName} ${child.props['className'] ?? ''}`,
          })}
        </OverlayTrigger>
      );
    }
  });
};
