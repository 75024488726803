import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ToOnyxMappers } from '@/neos/business/mappers';
import type { RfqDifference } from '@/neos/business/neosModel';
import { getDifferencesBetweenCurrentAndOnyxRfq } from '@/neos/business/neosSelectors/getDifferencesBetweenCurrentAndOnyxRfq';

export function getDifferencesBetweenCurrentAndLastTradeCompletedRfq(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
  mappers: ToOnyxMappers,
): RfqDifference[] {
  const lastTradeCompletedRfq = state.lastTradeCompletedRfq[rfqId];

  return getDifferencesBetweenCurrentAndOnyxRfq(
    state,
    rfqId,
    lastTradeCompletedRfq,
    selectors,
    mappers,
  );
}
