import type { Thunk } from '@/bootstrap/thunks';
import type { SecondaryEventTypeData } from '@/neos/business/rfq/strategy/leg/legData/legDataModel';

export function createCreateAllocationsSecondaryEventThunk(rfqId: string): Thunk {
  return function createAllocationsSecondaryEventThunk(
    dispatch,
    getState,
    { actionCreators, selectors },
  ) {
    const state = getState();
    const rfqOtcAllocations = selectors.getOtcAllocationsByRfqId(state, rfqId);

    const allocationSecondaryEvents: SecondaryEventTypeData[] = rfqOtcAllocations.map(
      allocation => {
        const eventType = selectors.getAllocationSecondaryEventCreation(state, {
          rfqId,
          allocationId: allocation.uuid,
        });

        return {
          eventType,
          primaryAllocUuid: allocation.uuid,
        };
      },
    );

    const allSecondaryEventCreationCrudActionsToReset = rfqOtcAllocations.map(allocation =>
      actionCreators.neos.allocSecondaryEventCreationCrudActions.delete({
        rfqId,
        allocationId: allocation.uuid,
      }),
    );

    dispatch(
      actionCreators.neos.createSecondaryEventAction(rfqId, allocationSecondaryEvents),
      allSecondaryEventCreationCrudActionsToReset,
    );
  };
}
