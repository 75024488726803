import type { Thunk } from '@/bootstrap/thunks';
import type { OnyxNextUserActions } from '../neosOnyxModel';

export function createIntegrateNextUserActions(
  rfqId: string,
  onyxNextUserActions: OnyxNextUserActions,
): Thunk {
  return function integrateNextUserActions(
    dispatch,
    _,
    {
      actionCreators: {
        neos: { nextUserActionsCrudActions },
      },
      fromMappers: { mapFromOnyxNextUserAction },
    },
  ) {
    const nextUserActions = mapFromOnyxNextUserAction(onyxNextUserActions);
    dispatch(nextUserActionsCrudActions.upsert(rfqId, nextUserActions));
  };
}
