import { actionCreators } from '@/bootstrap/actions';
import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { RfqDifferencesModal } from '@/neos/components/rfq/workflow/rfqDifferences/RfqDifferencesModal';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

interface RfqDifferencesProps {
  rfqId: string;
}

export const RfqDifferences = ({ rfqId }: RfqDifferencesProps) => {
  const dispatch = useDispatch<ExtendedDispatch>();

  const [isModalOpened, setIsModalOpened] = useState(false);
  const showRfqDiff = useAppSelector(state =>
    selectors.isRfqStatusInAmendWorkflow(state, rfqId, selectors),
  );
  const areVersionsLoaded = useAppSelector(state => selectors.getVersions(state, rfqId).length > 0);

  if (!showRfqDiff) {
    return null;
  }

  return (
    <>
      <Button
        data-e2e="neos-show-rfq-differences-button"
        disabled={!areVersionsLoaded}
        className="text-nowrap"
        onClick={() => {
          setIsModalOpened(true);
          dispatch(
            actionCreators.neos.lastTradeCompletedRfqActionCreators.createGetLastTradeCompletedRfqAction(
              rfqId,
            ),
          );
        }}
      >
        Show Diff
      </Button>
      {isModalOpened && (
        <RfqDifferencesModal rfqId={rfqId} onHide={() => setIsModalOpened(false)} />
      )}
    </>
  );
};
