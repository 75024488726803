import { actionCreators } from '@/bootstrap/actions';
import { useAppSelector } from '@/bootstrap/hooks';
import type { Broker } from '@/neos/business/referenceData/brokers/brokersModel';
import { debounce } from 'lodash';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';

type SearchInputProps = { searchedBrokers: Broker[] };

export function SearchBrokersInput({ searchedBrokers }: SearchInputProps) {
  const dispatch = useDispatch();
  const searchBrokerInputRef = useRef<HTMLInputElement>(null);

  const isSearchLoading = useAppSelector(
    state => state.ui.userPreferences.isSearchBrokersPreferenceLoading,
  );

  const areNoResultFound =
    searchedBrokers.length === 0 &&
    searchBrokerInputRef.current?.value.trim() !== '' &&
    searchBrokerInputRef.current?.value.trim() !== undefined &&
    !isSearchLoading;

  const onSearchChange = () => {
    const formattedSearchedValue = searchBrokerInputRef.current?.value.trim().toUpperCase();
    if (formattedSearchedValue === '' || !formattedSearchedValue) {
      dispatch(actionCreators.neos.createInsertSearchedBrokersAction([]));
      return;
    }
    dispatch(actionCreators.neos.createChangeUserPreferenceSearchBrokersLoadingAction(true));
    dispatch(actionCreators.neos.createSearchBrokersPreferencesAction(formattedSearchedValue));
  };

  const debouncedOnChange = debounce(onSearchChange, 500);

  return (
    <>
      <div className="form-group mt-2">
        <div className="input-group">
          <div className="input-icon-start">
            {isSearchLoading ? (
              <div className="spinner spinner-sm"></div>
            ) : (
              <i className="icon">search</i>
            )}
          </div>
          <input
            type="search"
            ref={searchBrokerInputRef}
            className="form-control"
            placeholder="Search for a broker"
            onChange={debouncedOnChange}
            data-e2e="user-preferences-brokers-search"
          />
        </div>
      </div>
      {areNoResultFound && <div className="mt-2">No result found</div>}
    </>
  );
}
