import type { ErrorableField } from '@/neos/business/mappers/error/errorHandlerData';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import { cn } from '@/util/classNames';
import { ErrorHighlightWithTooltip } from '../../../../../../share/errorHighlightWithTooltip/ErrorHighlightWithTooltip.container';
import { NumericInput } from '../../../../../../share/numericInput';
import type { FinancialColor } from '../../../../../../share/numericInput/withStyle/withStyle';
import type { LegQuoteModel, QuoteModel } from '../getQuotesModel';

interface BidFieldInfo {
  fieldName: keyof Pick<QuoteModel, 'salesBid' | 'traderBid'>;
  color: Extract<FinancialColor, 'BID'>;
  legErrorField: Extract<ErrorableField, 'legSalesPriceBid' | 'legTraderPriceBid'>;
  isTransparentKey: keyof Pick<QuoteModel, 'bidIsTransparent'>;
}

interface AskFieldInfo {
  fieldName: keyof Pick<QuoteModel, 'salesAsk' | 'traderAsk'>;
  color: Extract<FinancialColor, 'ASK'>;
  legErrorField: Extract<ErrorableField, 'legSalesPriceAsk' | 'legTraderPriceAsk'>;
  isTransparentKey: keyof Pick<QuoteModel, 'askIsTransparent'>;
}

export type FieldInfo = BidFieldInfo | AskFieldInfo;

type OnLegChanged = (quoteId: string, legId: string, value?: number) => void;

type CommonReducedLegQuote = Pick<LegQuoteModel, 'legId' | 'quoteId' | 'unit'> &
  Partial<
    Pick<
      LegQuoteModel,
      'salesAsk' | 'traderAsk' | 'salesBid' | 'traderBid' | 'bidIsTransparent' | 'askIsTransparent'
    >
  >;

type SalesAskLegQuoteModel = CommonReducedLegQuote &
  Pick<
    LegQuoteModel,
    'salesAsk' | 'askIsTransparent' | 'isAveragePriceTooltipSalesAskEnabled' | 'averagePrice'
  >;

type TraderAskLegQuoteModel = CommonReducedLegQuote &
  Pick<LegQuoteModel, 'traderAsk' | 'askIsTransparent' | 'traderWarning'>;

type SalesBidLegQuoteModel = CommonReducedLegQuote &
  Pick<
    LegQuoteModel,
    'salesBid' | 'bidIsTransparent' | 'isAveragePriceTooltipSalesBidEnabled' | 'averagePrice'
  >;

type TraderBidLegQuoteModel = CommonReducedLegQuote &
  Pick<LegQuoteModel, 'traderBid' | 'bidIsTransparent' | 'traderWarning'>;

type SalesLegQuoteModel = SalesAskLegQuoteModel | SalesBidLegQuoteModel;
type ReducedLegQuote = SalesLegQuoteModel | TraderAskLegQuoteModel | TraderBidLegQuoteModel;

function isSalesAskLegQuoteModel(legQuote: ReducedLegQuote): legQuote is SalesAskLegQuoteModel {
  return 'isAveragePriceTooltipSalesAskEnabled' in legQuote;
}

function isSalesBidLegQuoteModel(legQuote: ReducedLegQuote): legQuote is SalesBidLegQuoteModel {
  return 'isAveragePriceTooltipSalesBidEnabled' in legQuote;
}

function isTraderAskLegQuoteModel(legQuote: ReducedLegQuote): legQuote is TraderAskLegQuoteModel {
  return 'traderAsk' in legQuote;
}

function isTraderBidLegQuoteModel(legQuote: ReducedLegQuote): legQuote is TraderBidLegQuoteModel {
  return 'traderBid' in legQuote;
}

export function isSalesLegQuoteModel(legQuote: ReducedLegQuote): legQuote is SalesLegQuoteModel {
  return isSalesAskLegQuoteModel(legQuote) || isSalesBidLegQuoteModel(legQuote);
}

interface GenericLegsQuotesProps {
  rfqId: string;
  strategyId: string;
  fieldInfo: FieldInfo;
  legsQuotes: ReducedLegQuote[];
  areTraderPricesEnabled: boolean;
  isUnitDisplayed: boolean;
  onLegChanged: OnLegChanged;
}

export const GenericLegsQuotes = ({
  rfqId,
  strategyId,
  legsQuotes,
  fieldInfo,
  onLegChanged,
  areTraderPricesEnabled,
  isUnitDisplayed,
}: GenericLegsQuotesProps) => {
  const { fieldName, color, isTransparentKey, legErrorField } = fieldInfo;
  return (
    <>
      {legsQuotes.map((legQuote: ReducedLegQuote) => (
        <div key={legQuote.quoteId} data-e2e={`neos-quote-leg-${fieldName}-container`}>
          <ErrorHighlightWithTooltip
            errorField={legErrorField}
            related={{ rfqId, legId: legQuote.legId, strategyId }}
            id={legQuote.legId}
            isDisplayed={
              (isSalesAskLegQuoteModel(legQuote) &&
                legQuote.isAveragePriceTooltipSalesAskEnabled &&
                !!legQuote.averagePrice) ||
              (isSalesBidLegQuoteModel(legQuote) &&
                legQuote.isAveragePriceTooltipSalesBidEnabled &&
                !!legQuote.averagePrice)
            }
            renderMessage={() =>
              isSalesLegQuoteModel(legQuote) ? <p>{legQuote.averagePrice}</p> : <p />
            }
            key={legQuote.legId}
            componentClassName="warning-bloc border border-warning"
            tooltipClassName="react-bootstrap-warning-tooltip"
          >
            {(isTraderBidLegQuoteModel(legQuote) || isTraderAskLegQuoteModel(legQuote)) &&
            (fieldName === 'traderAsk' || fieldName === 'traderBid') ? (
              <SimpleNeosTooltip
                disable={!legQuote.traderWarning}
                type="warning"
                message={legQuote.traderWarning?.[fieldName]}
              >
                <NumericInput
                  inputClassName="fw-bold"
                  color={color}
                  className={cn(
                    { transparent: legQuote[isTransparentKey] },
                    {
                      'field-warning': !!(
                        legQuote.traderWarning && legQuote.traderWarning[fieldName]
                      ),
                    },
                  )}
                  unit={isUnitDisplayed ? legQuote.unit : undefined}
                  value={legQuote[fieldName]}
                  onBlur={(val: number | undefined) =>
                    onLegChanged(legQuote.quoteId, legQuote.legId, val)
                  }
                  readOnly={!areTraderPricesEnabled}
                  data-e2e={`neos-quote-leg-${fieldName}`}
                />
              </SimpleNeosTooltip>
            ) : (
              <NumericInput
                inputClassName="fw-bold"
                color={color}
                className={cn({ transparent: legQuote[isTransparentKey] })}
                unit={isUnitDisplayed ? legQuote.unit : undefined}
                value={legQuote[fieldName]}
                onBlur={(val: number | undefined) =>
                  onLegChanged(legQuote.quoteId, legQuote.legId, val)
                }
                readOnly={!areTraderPricesEnabled}
                data-e2e={`neos-quote-leg-${fieldName}`}
              />
            )}
          </ErrorHighlightWithTooltip>
        </div>
      ))}
    </>
  );
};
