import type { Curve } from '@/neos/business/referenceData/curvesList/curveModel';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { Observable } from 'rxjs';

const URL: string = 'api/referential/rates?currencyCode';

export interface CurvesListApi {
  fetchCurvesList: (currency: string, strategyType: 'ELS' | 'CLS') => Observable<Curve[]>;
}

export const makeCurvesListApi = (http: SgmeHttp): CurvesListApi => ({
  fetchCurvesList(currency: string, strategyType: 'ELS' | 'CLS') {
    const url = `${URL}=${currency}&strategyType=${strategyType}`;
    return http.get<Curve[]>({ url });
  },
});
