import type { ComponentProps } from 'react';
import { FormSelect } from 'react-bootstrap';

export type SelectOption<T = string> = { label: string; value: T };
export type SelectOptions<T extends string> = SelectOption<T>[] | Record<T, string>;

type NeosSelectProps<T extends string, U extends boolean | undefined> = Omit<
  ComponentProps<typeof FormSelect>,
  'onChange'
> & {
  options: SelectOptions<T>;
  onChange: (value: U extends true ? T | undefined : T) => void;
  value: T | undefined;
  style?: any;
  className?: string;
  addEmptyOption?: U;
  addNonSelectableEmptyOption?: boolean;
  readOnly?: boolean;
  'data-e2e'?: string;
  orientation?: 'left' | 'right';
  placeholder?: string;
};

export function NeosSelect<T extends string = string, U extends boolean = false>({
  options,
  addEmptyOption,
  addNonSelectableEmptyOption,
  className,
  onChange,
  value,
  style,
  orientation,
  readOnly,
  'data-e2e': dataE2e,
  placeholder,
}: NeosSelectProps<T, U>) {
  const displayedValue = value ?? (placeholder !== undefined ? 'placeholder' : '');

  return (
    <FormSelect
      value={displayedValue}
      onChange={event => {
        const selectedValue = escapeSelectedValue(event.target.value as T);
        onChange(selectedValue as Parameters<NeosSelectProps<T, U>['onChange']>[number]);
      }}
      className={className}
      style={{
        ...style,
        textAlign: orientation,
        color: placeholder && value === undefined ? 'grey' : style?.color,
      }}
      readOnly={readOnly}
      data-e2e={dataE2e}
      data-testid={dataE2e}
    >
      {placeholder && (
        <option value="placeholder" disabled hidden>
          {placeholder}
        </option>
      )}
      {addEmptyOption && <option value=""></option>}
      {addNonSelectableEmptyOption && <option value="" hidden></option>}
      {formatOptions(options).map(option => (
        <option
          key={option.value}
          value={option.value}
          className={placeholder || displayedValue ? 'text-primary' : ''}
        >
          {option.label}
        </option>
      ))}
    </FormSelect>
  );
}

function formatOptions<T extends string>(options: SelectOptions<T>): SelectOption<T>[] {
  if (areOptionsRecord(options)) {
    return Object.entries<string>(options).map(([value, label]) => ({ value: value as T, label }));
  }
  return options;
}

function areOptionsRecord<T extends string>(
  options: SelectOptions<T>,
): options is Record<T, string> {
  return !Array.isArray(options);
}

function escapeSelectedValue<T extends string>(value: T): T | undefined {
  if (value === 'placeholder' || value === '' || !value) {
    return undefined;
  }

  return value;
}
