import '../../../share/blotterGrid/BlotterRowStyles.css';
import { QuoteStatus } from '../quoteStatus/quoteStatus.container';
import styles from './Status.module.scss';

import { SaveButton } from './saveButton/SaveButton.container';

export interface StatusOwnProps {
  rfqId: string;
}

export interface StatusMapStateToProps {
  statusLabel: string;
  statusClassName: string;
}

export type StatusProps = StatusOwnProps & StatusMapStateToProps;

export const StatusComponent = ({ statusLabel, rfqId, statusClassName }: StatusProps) => {
  return (
    <>
      <div className={`${styles['status-select']} ${statusClassName}`} data-e2e="neos-rfq-status">
        {statusLabel}
      </div>
      <QuoteStatus rfqId={rfqId} />
      <SaveButton rfqId={rfqId} />
    </>
  );
};
