import type { Thunk } from '@/bootstrap/thunks';
import { type BarriersFeatureKey, DefaultingScope } from '../models';
import { DefaultingOptions } from '../rfqActions';

export function createRfqAddBarrierThunk(rfqId: string, featureKey: BarriersFeatureKey): Thunk {
  return function rfqAddBarrierThunk(
    dispatch,
    _,
    {
      thunks: {
        neos: { createAddBarrierToFeatureThunk },
      },
      actionCreators: {
        neos: { createDefaultRfqRequestedAction },
      },
    },
  ) {
    dispatch(
      createAddBarrierToFeatureThunk(featureKey),
      createDefaultRfqRequestedAction(
        rfqId,
        new DefaultingOptions({
          enrichScopes: [DefaultingScope.BARRIER_UNITS, DefaultingScope.UNDERLYING],
        }),
      ),
    );
  };
}
