import { NeosTooltip } from '@/neos/components/share/tooltip/NeosTooltip.tsx';
import { Tooltip } from 'react-bootstrap';

import { selectors } from '@/bootstrap/selectors.ts';
import type { AppState } from '@/bootstrap/state.ts';
import { thunks } from '@/bootstrap/thunks.ts';
import { useDispatch, useSelector } from 'react-redux';
import { getElsFeatureFields } from '../elsFeatures/RateBloc/getElsFeatureFields.ts';

import { actionCreators } from '@/bootstrap/actions.ts';
import { ResetFrequencySelect } from '../elsFeatures/RateBloc/ResetFrequencySelect.tsx';

export interface OwnProps {
  rfqId: string;
  strategyId: string;
}

export const ClsResetFrequency = ({ rfqId, strategyId }: OwnProps) => {
  const dispatch = useDispatch();
  const { resetFrequency, onResetFrequencyChanged, isEls } = useSelector((state: AppState) =>
    getElsFeatureFields(strategyId, state, selectors, thunks, actionCreators, dispatch),
  );

  if (!resetFrequency.isDisplayed || isEls) {
    return null;
  }
  return (
    <div className="d-flex mb-1 ms-1 border p-1 fit-content">
      {resetFrequency.isDisplayed && !isEls && (
        <div className="me-1">
          <NeosTooltip
            placement="top"
            overlay={<Tooltip id={'reset-frequency-label'}>{'Reset Frequency'}</Tooltip>}
          >
            <span className="fw-bold">Reset Frqcy</span>
          </NeosTooltip>
          <ResetFrequencySelect
            rfqId={rfqId}
            strategyId={strategyId}
            resetFrequency={resetFrequency.value}
            onChange={newResetFrequency => onResetFrequencyChanged(newResetFrequency, rfqId)}
          />
        </div>
      )}
    </div>
  );
};
