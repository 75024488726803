import type { Dispatchable, Thunk } from '@/bootstrap/thunks.ts';
import { DefaultingScope, initialEquityHedge, isElsProduct } from '@/neos/business/neosModel.ts';
import { DefaultingOptions } from '@/neos/business/rfq/rfqActions.ts';

export function createToggleElsBuyAndSellFeatureThunk(
  rfqId: string,
  productId: string,
  isReset: boolean,
): Thunk {
  return function toggleElsBuyAndSellFeatureThunk(
    dispatch,
    getState,
    { actionCreators, selectors },
  ) {
    const state = getState();
    const product = selectors.getProduct(state, productId);

    if (!isElsProduct(product)) {
      return;
    }

    const dispatchables: Dispatchable[] = [];

    if (product.equityHedge === undefined || isReset) {
      dispatchables.push(
        actionCreators.neos.productCrudActions.update(product.uuid, {
          equityHedge: initialEquityHedge,
        }),
        actionCreators.neos.createDefaultRfqRequestedAction(
          rfqId,
          new DefaultingOptions({
            overrideScopes: [DefaultingScope.EQUITY_HEDGE],
          }),
        ),
      );
    } else {
      dispatchables.push(
        actionCreators.neos.productCrudActions.update(product.uuid, {
          equityHedge: undefined,
        }),
      );
    }

    dispatch(dispatchables);
  };
}
