import type { DefaultProductValuesForStrategy } from '../../../../../../../../../neos/business/neosModel';
import {
  type AsianSpreadOption,
  type Product,
  isOptionLike,
  isProductWithAsianFields,
  isProductWithUpperLowerStrike,
} from '../../../../legModel';
import { getCommonPart, getDerivativePart, getNegotiationPart } from './getDefaultedPartialProduct';

export function getDefaultedAsianSpreadOptionProduct(
  currentProduct: Product,
  defaultValues: DefaultProductValuesForStrategy,
): AsianSpreadOption {
  const {
    underlying,
    negotiation,
    optionStyle,
    optionType,
    upperStrike: strategyUpperStrike,
    lowerStrike: strategyLowerStrike,
    maturity,
    period: strategyPeriod,
    strikeType: strategyStrikeType,
    averageDisruptionDate: strategyAverageDisruptionDate,
    businessDayConvention: strategyBusinessDayConvention,
  } = defaultValues;

  const { type, style } = isOptionLike(currentProduct)
    ? currentProduct
    : { type: undefined, style: undefined };
  const { upperStrike, lowerStrike, strikeUnit } = isProductWithUpperLowerStrike(currentProduct)
    ? currentProduct
    : { upperStrike: undefined, lowerStrike: undefined, strikeUnit: undefined };
  const { period, strikeType, averageDisruptionDate, businessDayConvention } =
    isProductWithAsianFields(currentProduct)
      ? currentProduct
      : {
          period: undefined,
          strikeType: undefined,
          averageDisruptionDate: undefined,
          businessDayConvention: undefined,
        };

  return {
    subFamily: 'ASIAN_SPREAD_OPTION',
    type: optionType ?? type,
    style: optionStyle ?? style,
    upperStrike: strategyUpperStrike ?? upperStrike,
    lowerStrike: strategyLowerStrike ?? lowerStrike,
    strikeUnit,
    ...getCommonPart(currentProduct, defaultValues),
    ...getNegotiationPart(currentProduct, negotiation),
    ...getDerivativePart(currentProduct, maturity, underlying),
    hasCustomUnderlying: false,
    period: strategyPeriod ?? period,
    strikeType: strategyStrikeType ?? strikeType,
    averageDisruptionDate: strategyAverageDisruptionDate ?? averageDisruptionDate,
    businessDayConvention: strategyBusinessDayConvention ?? businessDayConvention,
  };
}
