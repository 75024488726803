import type { FromOnyxMappers } from '@/neos/business/mappers';
import type { DigitalOptionProduct } from '../../../../../../../../neos/business/neosModel';
import type { OnyxSingleUnderlyingProduct } from '../../productOnyxModel';

export function mapFromOnyxDigitalOptionProduct(
  legId: string,
  onyxProduct: OnyxSingleUnderlyingProduct,
  productUuid: string,
  mappers: FromOnyxMappers,
): DigitalOptionProduct {
  return {
    subFamily: 'DIGITAL_OPTION',
    hasCustomUnderlying: false,
    ...mappers.mapFromOnyxProductCommon(legId, onyxProduct, productUuid),
    ...mappers.mapFromOnyxOtcNegotiation(),
    ...mappers.mapFromSingleDerivativeOnyxProduct(onyxProduct, mappers),
  };
}
