import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { isElsProduct } from '@/neos/business/rfq/strategy/leg/product/productModel';
import { ElsFeaturesContext } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures';
import { getElsRefSpotModel } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/EquityBloc/getElsRefSpotModel';
import { ElsBlocField } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/ElsBlocField';
import { NumericInput } from '@/neos/components/share/numericInput';
import { useContext } from 'react';

export function RefSpotInSwapCcy() {
  const { rfqId, strategyId } = useContext(ElsFeaturesContext);
  const product = useAppSelector(state =>
    selectors.getStrategyProduct(state, strategyId, selectors),
  );
  const refSpotModel = useAppSelector(state =>
    getElsRefSpotModel(state, rfqId, strategyId, selectors),
  );
  const refSpotInSwapCCY = useAppSelector(state =>
    selectors.getElsRefSpotInSwapCurrency(state, rfqId, strategyId),
  );

  const isNotDisplayed =
    !isElsProduct(product) ||
    !refSpotModel ||
    !refSpotInSwapCCY ||
    refSpotModel.refSpot.unit === refSpotInSwapCCY.unit;

  if (isNotDisplayed) {
    return null;
  }

  return (
    <ElsBlocField
      label="Ref Spot in Swap CCY"
      renderInput={() => (
        <NumericInput
          style={{ width: '130px' }}
          unit={refSpotInSwapCCY.unit}
          value={refSpotInSwapCCY.value}
          readOnly
          data-e2e="els-section-equity-ref-spot-in-swap-ccy"
        />
      )}
    />
  );
}
