import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { LegSecondaryEventCreation } from '@/neos/business/legSecondaryEventCreation/legSecondaryEventCreationModel';
import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { getEventTypesByProduct } from '../../shared/SecondaryTypeCellsCommon';
import { SecondaryWidget } from '../../shared/SecondaryWidget';
import type { SecondaryWidgetModel } from './getLegSecondaryTypeWidgetModel';

type LegSecondaryToggleButtonsProps = {
  secondaryTypeField: SecondaryWidgetModel;
  handleClick: ({
    isLongClick,
    legId,
    eventType,
  }: {
    isLongClick: boolean;
    legId: string;
    eventType: LegSecondaryEventCreation['eventType'];
  }) => void;
  isLoading: boolean;
};

export const LegSecondaryToggleButtons = forwardRef<HTMLDivElement, LegSecondaryToggleButtonsProps>(
  ({ secondaryTypeField, handleClick, isLoading }, ref) => {
    const product = useSelector((state: AppState) =>
      selectors.getProduct(state, secondaryTypeField.legId),
    );
    const eventTypes = getEventTypesByProduct(product);

    return (
      <SecondaryWidget
        ref={ref}
        eventTypes={eventTypes}
        isDisabled={!secondaryTypeField.isLegMaturityValid}
        isLoading={isLoading}
        id={secondaryTypeField.legId}
        selectedEventType={secondaryTypeField.secondaryType}
        handleClick={(isLongClick, legId, eventType) =>
          handleClick({
            isLongClick,
            legId,
            eventType,
          })
        }
      />
    );
  },
);
