import type { NextUserAction } from '@/neos/business/nextUserActions/nextUserActionsModel';
import { ConfirmModal } from '@/neos/components/share/confirmModal';
import { sentenceToConstant } from '@/util/string/stringUtils';
import { Fragment, useState } from 'react';
import { Dropdown, SplitButton } from 'react-bootstrap';

import type { ActionsModel, NextUserActionsModel } from './getNextUserActionsModel';

import styles from './NextUserActions.module.scss';

export interface OwnProps {
  rfqId: string;
}

export interface StateProps {
  model: NextUserActionsModel;
}

export interface DispatchProps {
  onSelectAction(nextUserAction?: NextUserAction): void;
}

export type Props = OwnProps & StateProps & DispatchProps;

export const NextUserActionsComponent = ({
  model: { nextUserActions, nextAction, isDisabled },
  onSelectAction,
}: Props) => {
  const [nextActionState, setNextActionState] = useState<ActionsModel | undefined>(undefined);

  const handleNextActionClick = (action: ActionsModel | undefined) => {
    setTimeout(() => document.body.click());
    return action?.isConfirmable ? setNextActionState(action) : onSelectAction(action?.action);
  };

  return (
    <div className={`${styles['next-user-actions']}`} data-e2e="neos-rfq-next-user-actions">
      {nextUserActions.length > 0 && (
        <SplitButton
          className={`text-nowrap btn-secondary ${
            !nextAction || nextAction.disabled ? 'disabled' : ''
          }`}
          title={nextAction?.action.title ?? '---'}
          id="next-user-actions-dropdown-btn"
          onClick={() => nextAction && !nextAction.disabled && handleNextActionClick(nextAction)}
          disabled={(nextAction && nextAction.disabled) || isDisabled}
        >
          {nextUserActions.map(nextUserAction =>
            nextUserAction ? (
              <Dropdown.Item
                data-e2e={sentenceToConstant(nextUserAction.action.title)}
                disabled={nextUserAction.disabled}
                className={`${
                  nextUserAction.action.action === 'next' ? styles['next-user-action-highligh'] : ''
                }`}
                onClick={() => !nextUserAction.disabled && handleNextActionClick(nextUserAction)}
                key={nextUserAction.action.action}
              >
                {nextUserAction.action.title}
              </Dropdown.Item>
            ) : (
              <div key={'divider'} className="dropdown-divider" />
            ),
          )}
        </SplitButton>
      )}
      <ConfirmModal
        title={nextActionState?.isConfirmable ? nextActionState.modalTitle : ''}
        isModalShown={!!nextActionState}
        onHide={() => setNextActionState(undefined)}
        onConfirm={() => {
          onSelectAction(nextActionState?.action);
        }}
      >
        <p>
          {nextActionState?.isConfirmable &&
            nextActionState.modalBody.map(body => (
              <Fragment key={body}>
                {body}
                <br />
              </Fragment>
            ))}
        </p>
      </ConfirmModal>
    </div>
  );
};
