import type { Thunk } from '@/bootstrap/thunks';

export function createCreateLegsSecondaryEventThunk(primaryRfqId: string): Thunk {
  return function createLegsSecondaryEventThunk(
    dispatch,
    getState,
    {
      actionCreators: {
        neos: { createSecondaryEventAction, legSecondaryEventCreationCrudActions },
      },
      selectors: { getLegSecondaryEventCreationLegIds, getLegSecondaryEventCreation },
    },
  ) {
    const appState = getState();
    const legIds = getLegSecondaryEventCreationLegIds(appState, primaryRfqId);

    const legEvents = legIds.map((legId: string) => {
      const eventType = getLegSecondaryEventCreation(appState, {
        rfqId: primaryRfqId,
        legId,
      });

      return {
        eventType,
        primaryLegUuid: legId,
      };
    });

    const allSecondaryEventCreationCrudActionsToReset = legIds.map(legId =>
      legSecondaryEventCreationCrudActions.delete({ rfqId: primaryRfqId, legId }),
    );

    dispatch(
      createSecondaryEventAction(primaryRfqId, legEvents),
      allSecondaryEventCreationCrudActionsToReset,
    );
  };
}
