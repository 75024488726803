import { useDispatch, useSelector } from 'react-redux';

import { actionCreators } from '@/bootstrap/actions';
import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { thunks } from '@/bootstrap/thunks';
import {
  type BrokenPeriodPosition,
  brokenPeriodPositions,
} from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel';
import { ElsFeaturesContext } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures';
import { ElsBlocField } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/ElsBlocField';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';
import { useContext } from 'react';
import { isElsProduct } from '../../../../../../../../neos/business/neosModel';

export function BrokenPeriod() {
  const { rfqId, strategyId } = useContext(ElsFeaturesContext);
  const dispatch = useDispatch();
  const product = useSelector((state: AppState) =>
    selectors.getStrategyProduct(state, strategyId, selectors),
  );
  if (!isElsProduct(product)) {
    return null;
  }
  const { uuid, brokenPeriodPosition } = product;
  const { update: updateProduct } = actionCreators.neos.productCrudActions;

  const onBrokenPeriodChange = (newBrokenPeriodPosition: BrokenPeriodPosition | undefined) => {
    dispatch(thunks.neos.changeElsIsScheduleObsoleteThunk(rfqId, uuid, true));
    dispatch(updateProduct(uuid, { brokenPeriodPosition: newBrokenPeriodPosition ?? undefined }));
  };

  return (
    <ElsBlocField
      label="Broken Period"
      renderInput={readOnly => (
        <NeosSelect
          readOnly={readOnly}
          value={brokenPeriodPosition}
          onChange={onBrokenPeriodChange}
          data-e2e="rate-convention-section-broken-period"
          addEmptyOption
          options={brokenPeriodPositions}
        />
      )}
    />
  );
}
