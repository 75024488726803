import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import type { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  PredealCheckCellComponent,
  type PredealCheckCellDispatchProps,
  type PredealCheckCellOwnProps,
  type PredealCheckCellStateProps,
} from './PredealCheckCell';
import { getPredealCheckCellModel } from './getPredealCheckCellModel';

export const PredealCheckCell: FC<PredealCheckCellOwnProps> = ownProps => {
  const dispatch = useDispatch<ExtendedDispatch>();
  const stateProps = useSelector<AppState, PredealCheckCellStateProps>(appState => ({
    model: getPredealCheckCellModel(appState, ownProps, selectors),
  }));

  const dispatchProps = mapDispatchProps(dispatch, ownProps);

  return <PredealCheckCellComponent {...ownProps} {...stateProps} {...dispatchProps} />;
};

function mapDispatchProps(
  dispatch: ExtendedDispatch,
  { rfqId }: PredealCheckCellOwnProps,
): PredealCheckCellDispatchProps {
  return {
    onSelected(predealCheckId: string) {
      dispatch(neosActionCreators.createNeosPredealCheckSelected(rfqId, predealCheckId));
    },
  };
}
