import type { ListedAllocation, OtcAllocation } from '@/neos/business/neosModel';
import type { DeltaStockListedAllocation } from '@/neos/business/rfq/strategy/leg/deltaStockListedAllocation/deltaStockListedAllocationModel';

export type CancelledAllocationsModel<
  Allocation extends OtcAllocation | DeltaStockListedAllocation | ListedAllocation,
> = {
  activeAllocations: Allocation[];
  cancelledAllocations: Allocation[];
};

export function getCancelledAllocationsModel<
  Allocation extends OtcAllocation | DeltaStockListedAllocation | ListedAllocation,
>(allocations: Allocation[]): CancelledAllocationsModel<Allocation> {
  const activeAllocations = allocations.filter(allocation => !allocation.isCancelled);
  const cancelledAllocations = allocations.filter(allocation => allocation.isCancelled);

  return {
    activeAllocations,
    cancelledAllocations,
  };
}
