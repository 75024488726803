import { createSlice } from '@reduxjs/toolkit';

export type TopLevelErrorState = {
  isSignalRConnexionErrorDisplayed: boolean;
  isToggleFeatureErrorDisplayed: boolean;
};

const initialState: TopLevelErrorState = {
  isSignalRConnexionErrorDisplayed: false,
  isToggleFeatureErrorDisplayed: false,
};

export const topLevelErrorSlice = createSlice({
  name: 'topLevelError',
  initialState,
  reducers: {
    setIsSignalRConnexionErrorDisplayed: state => {
      state.isSignalRConnexionErrorDisplayed = true;
    },
    setIsToggleFeatureErrorDisplayed: state => {
      state.isToggleFeatureErrorDisplayed = true;
    },
  },
});
