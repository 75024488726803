import { PredealCheckBullet } from '../predealCheckBullet';
import { neosThunks } from '@/neos/business/thunks';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@/bootstrap/hooks.ts';
import { getAggregatedPredealCheckModel } from '@/neos/components/rfq/predealChecks/aggregatedPredealCheck/getAggregatedPredealCheckModel.ts';
import { selectors } from '@/bootstrap/selectors.ts';

export type AggregatedPredealCheckProps = {
  rfqId: string;
};

export const AggregatedPredealCheck = ({ rfqId }: AggregatedPredealCheckProps) => {
  const dispatch = useDispatch();

  const { riskColor, othersColor, isRefreshEnabled } = useAppSelector(state =>
    getAggregatedPredealCheckModel(state, rfqId, selectors),
  );

  const showPredealChecksModal = () => {
    dispatch(neosThunks.createShowPredealChecksModalThunks(rfqId));
  };
  const refreshPredealChecks = () => {
    dispatch(neosThunks.createRequestNeosPredealCheckRefreshThunk(rfqId));
  };

  return (
    <section className="d-flex">
      <button
        className="btn btn-primary text-nowrap d-inline-flex gap-2"
        data-e2e="neos-rfq-show-pdc-button"
        onClick={showPredealChecksModal}
      >
        <span>Credit</span>
        <PredealCheckBullet
          color={riskColor}
          isElementCentered={false}
          isSelectable={false}
          isLoading={false}
          isSmaller={true}
        />

        <span className="ms-1">KYC/REG</span>
        <PredealCheckBullet
          color={othersColor}
          isElementCentered={false}
          isSelectable={false}
          isLoading={false}
          isSmaller={true}
        />
      </button>

      <button
        className="btn btn-primary px-2"
        data-e2e="neos-rfq-refresh-pdc-button"
        onClick={refreshPredealChecks}
        disabled={!isRefreshEnabled}
      >
        <i className="icon icon-sm">cached</i>
      </button>
    </section>
  );
};
