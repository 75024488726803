import type { Action } from '@/bootstrap/actions';
import type { Thunk } from '@/bootstrap/thunks';
import type {
  Barrier,
  BarriersFeature,
  BarriersFeatureKey,
  CustomBarrier,
  NonCustomBarrier,
} from '../featureModel';

export function createUpdateBarrierOfFeatureThunk(
  featureKey: BarriersFeatureKey,
  index: number,
  patch:
    | Partial<Omit<Barrier, 'underlyingType'>>
    | Pick<CustomBarrier, 'underlyingType' | 'underlyingName'>
    | Pick<NonCustomBarrier, 'underlyingType' | 'underlyingId' | 'limitStrikeUnit'>,
): Thunk {
  return function updateBarrierOfFeature(
    dispatch,
    getState,
    {
      selectors: { getFeature },
      actionCreators: {
        neos: { featureCrudActions },
      },
    },
  ) {
    const appState = getState();
    const feature: BarriersFeature | undefined = getFeature(appState.featureState, featureKey);

    if (!feature) {
      throw new Error(`This feature is not defined`);
    }
    const barriers: Barrier[] = [...feature.barriers];
    const barrierToModify: Barrier | undefined = barriers[index];
    if (!barrierToModify) {
      throw new Error('Index of barrier is out of bound');
    }
    barriers[index] = { ...barrierToModify, ...patch };
    const action: Action = featureCrudActions.update(featureKey, { barriers });
    dispatch(action);
  };
}
