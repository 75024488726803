import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import {
  type ButtonHTMLAttributes,
  type DetailedHTMLProps,
  type ReactNode,
  createRef,
} from 'react';
import styles from './MultiToggleButton.module.scss';

interface MultiToggleButtonProps<T>
  extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  selectedValue: T;
  availableValues: T[];
  readOnly?: boolean;
  onValueChange: (newValue: T) => void;
  valueFormatter?: (value: T) => string;
}

export function MultiToggleButton<T extends ReactNode>(props: MultiToggleButtonProps<T>) {
  const {
    className,
    selectedValue,
    availableValues,
    onValueChange,
    valueFormatter,
    onClick,
    onChange,
    disabled,
    readOnly,
    ...rest
  } = props;
  const ref = createRef<HTMLButtonElement>();

  return (
    <SimpleNeosTooltip
      id="multi-toggle-button"
      message={'Click to toggle'}
      disable={disabled || readOnly}
    >
      <button
        className={`${styles['multi-toggle']} ${className} btn ${
          !disabled && !readOnly ? 'btn-discreet-primary' : ''
        } ${props.readOnly ? 'btn-flat-primary border-bottom' : ''}`}
        disabled={disabled}
        data-readonly={props.readOnly ? true : undefined}
        ref={ref}
        onClick={e => {
          if (disabled) {
            return;
          }

          const availableStyles = [...availableValues];
          const nextStyle =
            availableStyles[(availableStyles.indexOf(selectedValue) + 1) % availableStyles.length];
          onValueChange(nextStyle);
          ref.current!.blur();
          if (onChange) {
            onChange(e);
          }
          if (onClick) {
            onClick(e);
          }
        }}
        type="button"
        {...rest}
      >
        {valueFormatter ? valueFormatter(selectedValue) : selectedValue}
      </button>
    </SimpleNeosTooltip>
  );
}
