import { actionCreators } from '@/bootstrap/actions';
import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { DefaultingScope } from '@/neos/business/neosModel';
import { DefaultingOptions } from '@/neos/business/rfq/rfqActions';
import { getSettlementCurrencyModel } from '@/neos/components/rfq/reference/SettlementCurrency/getSettlementCurrencyModel';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';
import { useDispatch } from 'react-redux';

type SettlementCurrencyProps = { rfqId: string };

export const SettlementCurrency = ({ rfqId }: SettlementCurrencyProps) => {
  const dispatch = useDispatch();
  const model = useAppSelector(state => getSettlementCurrencyModel(state, rfqId, selectors));

  if (!model) {
    return null;
  }

  const { rfqLegIds, displayedCurrency, currencies, isReadonly } = model;

  return (
    <section className="ms-3" style={{ width: '105px' }}>
      <div className="fw-bold">Settl. Currency</div>
      <NeosSelect
        data-e2e="reference-settlement-currency-select"
        readOnly={isReadonly}
        value={displayedCurrency}
        options={currencies}
        onChange={value => {
          const options = new DefaultingOptions({ enrichScopes: [DefaultingScope.FOREX] });

          rfqLegIds.forEach(legId => {
            dispatch(
              actionCreators.neos.legDataCrudActions.update(legId, {
                settlement: {
                  currency: value,
                },
              }),
            );
          });
          dispatch(actionCreators.neos.createDefaultRfqRequestedAction(rfqId, options));
        }}
      />
    </section>
  );
};
