import type { Thunk } from '@/bootstrap/thunks';

export function createDeleteStrategyDependenciesThunk(strategyId: string): Thunk {
  return function deleteStrategyDependenciesThunk(
    dispatch,
    getState,
    {
      selectors: { getStrategyData },
      actionCreators: {
        neos: { strategyDataCrudActions, featureCrudActions },
      },
      thunks: {
        neos: { createDeleteLegsThunk },
      },
    },
  ) {
    const state = getState();

    const { legIds } = getStrategyData(state, strategyId);

    dispatch(
      strategyDataCrudActions.delete(strategyId),
      featureCrudActions.deleteByPartialKey({ strategyId }),
      createDeleteLegsThunk(legIds),
    );
  };
}
