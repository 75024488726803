import { noop } from '@/util/noop';
import type { FC } from 'react';
import { FlowDatePicker } from '../../../../../share/datePicker';
import { ErrorHighlight } from '../../../../../share/errorHighlight';
import type { FutureMaturityCellsModel } from './getFutureMaturityCellsModel';

export interface FutureMaturityCellsOwnProps {
  rfqId: string;
  strategyId: string;
  isReadOnlyRfq: boolean;
}

export type FutureMaturityCellsMapStateProps = FutureMaturityCellsModel;

type FutureMaturityCellsProps = FutureMaturityCellsOwnProps & FutureMaturityCellsMapStateProps;

export const FutureMaturityCellsComponent: FC<FutureMaturityCellsProps> = props => {
  return (
    <section>
      {props.futureMaturities.map(({ id, value, isEditable }) => (
        <ErrorHighlight
          key={id}
          errorField={'futureMaturity'}
          related={{ rfqId: props.rfqId, legId: id, strategyId: props.strategyId }}
        >
          <FlowDatePicker
            disabled={!isEditable || props.isReadOnlyRfq}
            readOnly={!isEditable || props.isReadOnlyRfq}
            onChange={noop}
            onChangeTenor={noop}
            maturities={[]}
            date={value || ''}
            tenors={[]}
            maturitiesCategory={'LISTED'}
            data-e2e="neos-strategy-definition-future-maturity"
            hideCalendarIcon
          />
        </ErrorHighlight>
      ))}
    </section>
  );
};
