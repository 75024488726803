import type { AppState } from '@/bootstrap/state.ts';
import type { Selectors } from '@/bootstrap/selectors.ts';
import { isElsProduct } from '@/neos/business/rfq/strategy/leg/product/productModel.ts';
import type { EquityFinanceType } from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel.ts';

export type EquityFinanceModel = {
  isDisplayed: boolean;
  equityFinance: EquityFinanceType | undefined;
};

export function getEquityFinanceModel(
  state: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): EquityFinanceModel {
  const product = selectors.getStrategyProduct(state, strategyId, selectors);
  const feature = selectors.getFeature(state.featureState, {
    strategyId,
    type: 'EQUITY_FINANCE',
  });

  const isDisplayed =
    isElsProduct(product) &&
    !selectors.isRfqInitiatedByTrader(state, rfqId) &&
    product.dealType === 'EQUITY_FINANCE';

  return {
    isDisplayed,
    equityFinance: feature?.equityFinance,
  };
}
