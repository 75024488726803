import BlockUi from '@availity/block-ui';
import '@availity/block-ui/dist/index.css';
import styles from './BlockingContainer.module.scss';
export interface BlockingContainerProps {
  className?: string;
  blocking: boolean;
  loaderEnabled?: boolean;
  doNotRenderChildren?: boolean;
  overflowHidden?: boolean;
  children: any;
}

export const BlockingContainer = ({
  blocking,
  doNotRenderChildren,
  loaderEnabled,
  overflowHidden,
  children,
  className,
}: BlockingContainerProps) => {
  return blocking ? (
    <div
      className={`${styles['disable-container']} ${
        overflowHidden ? styles['overflow-hidden'] : ''
      }`}
    >
      <BlockUi
        tag="div"
        renderChildren={!doNotRenderChildren}
        blocking
        loader={loaderEnabled ? undefined : <></>}
        className={className}
      >
        {children}
      </BlockUi>
    </div>
  ) : (
    children
  );
};
