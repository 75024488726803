import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { isSecondaryEvent } from '@/neos/business/neosModel';
import { useSelector } from 'react-redux';
import { SecondaryFieldComponent } from './SecondaryFieldComponent';
import { getSecondaryFieldsToDisplay } from './secondaryFieldsByType';

export type SecondaryFieldProps = {
  allocationId: string;
  rfqId: string;
  legId: string;
  strategyId: string;
};

export function SecondaryAllocationsFields(props: SecondaryFieldProps) {
  const { eventType } = useSelector((state: AppState) => selectors.getLegData(state, props.legId));
  const product = useSelector((state: AppState) => selectors.getProduct(state, props.legId));

  if (!eventType || !isSecondaryEvent(eventType)) {
    return null;
  }

  return (
    <>
      {getSecondaryFieldsToDisplay(eventType, product).map(field => (
        <SecondaryFieldComponent key={field} field={field} forwardProps={props} />
      ))}
    </>
  );
}
