import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { Observable } from 'rxjs';
import type { NotificationUnsubscriptionApi } from '../epics/notificationUnsubscriptionEpic';
import type { NotificationUnsubscriptionRequest } from '../neosModel';

export const createNotificationUnsubscriptionApi = (
  http: SgmeHttp,
): NotificationUnsubscriptionApi => ({
  unsubscribeFromNotification(
    notificationUnsubscriptionRequest: NotificationUnsubscriptionRequest[],
  ): Observable<void> {
    return http.post({
      url: 'api/neos/notification/unsubscribe',
      body: notificationUnsubscriptionRequest,
    });
  },
});
