import type { FromOnyxMappers } from '@/neos/business/mappers';
import type { Stock } from '../../productModel';
import type { OnyxSingleUnderlyingProduct } from '../../productOnyxModel';

export function mapFromOnyxProductStock(
  legId: string,
  onyxProduct: OnyxSingleUnderlyingProduct,
  productUuid: string,
  mappers: FromOnyxMappers,
): Stock {
  return {
    subFamily: 'STOCK',
    ...mappers.mapFromOnyxProductCommon(legId, onyxProduct, productUuid),
    ...mappers.mapFromOnyxListedNegotiation(onyxProduct.negotiation),
    isDerivativeProduct: false,
    hasCustomUnderlying: false,
  };
}
