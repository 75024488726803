import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import type { OptionFlex } from '../../../../../../../neos/business/neosModel';
import {
  OptionFlexCells as Component,
  type OptionFlexCellsDispatchProps,
  type OptionFlexCellsMapStateProps,
  type OptionFlexCellsOwnProps,
} from './OptionFlexCells';
import { getOptionFlexCellsModel } from './getOptionFlexCellsModel';

export const OptionFlexCells = connect(mapStateToProps, mapDispatchToProps)(Component);

function mapStateToProps(
  state: AppState,
  { strategyId, rfqId }: OptionFlexCellsOwnProps,
): OptionFlexCellsMapStateProps {
  return getOptionFlexCellsModel(state, rfqId, strategyId, selectors);
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { strategyId, rfqId }: OptionFlexCellsOwnProps,
): OptionFlexCellsDispatchProps {
  return {
    onOptionFlexChanged(productId: string, optionFlex: OptionFlex | undefined) {
      dispatch(neosThunks.createOptionFlexChangedThunk(strategyId, productId, optionFlex));
    },
    activateStrategyFlexs() {
      dispatch(neosThunks.createActivateStrategyFlexThunk(rfqId, strategyId));
    },
  };
}
