import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { type ExtendedDispatch, thunks } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { connect } from 'react-redux';
import {
  TradeRecapButtonComponentWithCss,
  type TradeRecapButtonDispatchProps,
  type TradeRecapButtonOwnProps,
  type TradeRecapButtonPropsFromState,
} from './TradeRecapButton';
import { getTradeRecapButtonModel } from './getTradeRecapButtonModel';

export const TradeRecapButton = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TradeRecapButtonComponentWithCss);

function mapStateToProps(
  state: AppState,
  { rfqId }: TradeRecapButtonOwnProps,
): TradeRecapButtonPropsFromState {
  return {
    model: getTradeRecapButtonModel(state, rfqId, selectors),
  };
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId }: TradeRecapButtonOwnProps,
): TradeRecapButtonDispatchProps {
  return {
    onTradeRecapModalHidden: () => {
      dispatch(
        neosActionCreators.rfqUiCrudActions.patchOrInsert(rfqId, { showTradeRecapModal: false }),
      );
    },
    onTradeRecapModalShown: () =>
      dispatch(neosActionCreators.createTradeRecapRequestedAction(rfqId, true)),
    sendDrafTradeRecap: (comment: string) => {
      dispatch(neosActionCreators.createTradeRecapMailCCRecipientsAction(rfqId, comment));
    },
    onCopyToClipboard: (text: string) => {
      dispatch(thunks.createCopyToClipboardThunk(text));
    },
  };
}
