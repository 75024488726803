import type { Preset } from '.';
import type { StrategyType } from '../../../../../neos/business/neosModel';

const STRATEGY_TYPE: Preset<StrategyType> = [
  {
    id: 'CALL',
    label: 'Call',
    ['data-e2e']: 'neos-rfq-shortcuts-strategy-type-CALL',
  },
  {
    id: 'PUT',
    label: 'Put',
    ['data-e2e']: 'neos-rfq-shortcuts-strategy-type-PUT',
  },
  {
    id: 'CALL_SPREAD',
    label: 'CS',
    ['data-e2e']: 'neos-rfq-shortcuts-strategy-type-CALL_SPREAD',
  },
  {
    id: 'PUT_SPREAD',
    label: 'PS',
    ['data-e2e']: 'neos-rfq-shortcuts-strategy-type-PUT_SPREAD',
  },
  {
    id: 'STRADDLE',
    label: 'Straddle',
    ['data-e2e']: 'neos-rfq-shortcuts-strategy-type-STRADDLE',
  },
  {
    id: 'STRANGLE',
    label: 'Strangle',
    ['data-e2e']: 'neos-rfq-shortcuts-strategy-type-STRANGLE',
  },
  {
    id: 'RISK_REVERSAL',
    label: 'R. R.',
    ['data-e2e']: 'neos-rfq-shortcuts-strategy-type-RISK_REVERSAL',
  },
  {
    id: 'SYNTHETIC',
    label: 'Synth.',
    ['data-e2e']: 'neos-rfq-shortcuts-strategy-type-SYNTHETIC',
  },
  {
    id: 'FUTURE',
    label: 'Fut.',
    ['data-e2e']: 'neos-rfq-shortcuts-strategy-type-FUTURE',
  },
  {
    id: 'FUTURE_ROLL',
    label: 'Fut. Roll',
    ['data-e2e']: 'neos-rfq-shortcuts-strategy-type-FUTURE_ROLL',
  },
  {
    id: 'FORWARD',
    label: 'Fwd.',
    ['data-e2e']: 'neos-rfq-shortcuts-strategy-type-FORWARD',
  },
  {
    id: 'ELS',
    label: 'ELS',
    ['data-e2e']: 'neos-rfq-shortcuts-strategy-type-ELS',
  },
  {
    id: 'VAR_SWAP',
    label: 'Var.',
    ['data-e2e']: 'neos-rfq-shortcuts-strategy-type-VAR_SWAP',
  },
  {
    id: 'VOL_SWAP',
    label: 'Vol.',
    ['data-e2e']: 'neos-rfq-shortcuts-strategy-type-VOL_SWAP',
  },
];

const UNDERLYINGS: Preset<string> = [
  {
    id: 'SG_17360',
    label: 'SPX',
    ['data-e2e']: 'neos-rfq-shortcuts-underlying-SPX',
  },
  {
    id: 'SG_17405',
    label: 'SX5E',
    ['data-e2e']: 'neos-rfq-shortcuts-underlying-SX5E',
  },
  {
    id: 'SG_17344',
    label: 'NKY',
    ['data-e2e']: 'neos-rfq-shortcuts-underlying-NKY',
  },
  {
    id: 'SG_17357',
    label: 'TPX',
    ['data-e2e']: 'neos-rfq-shortcuts-underlying-TPX',
  },
  {
    id: 'SG_35828',
    label: 'HSCEI',
    ['data-e2e']: 'neos-rfq-shortcuts-underlying-HSCEI',
  },
  {
    id: 'SG_17354',
    label: 'HSI',
    ['data-e2e']: 'neos-rfq-shortcuts-underlying-HSI',
  },
  {
    id: 'SG_17362',
    label: 'KOSPI2',
    ['data-e2e']: 'neos-rfq-shortcuts-underlying-KOSPI2',
  },
  {
    id: 'SG_36591',
    label: 'TWSE',
    ['data-e2e']: 'neos-rfq-shortcuts-underlying-TWSE',
  },
  {
    id: 'SG_17460',
    label: 'AS51',
    ['data-e2e']: 'neos-rfq-shortcuts-underlying-AS51',
  },
  {
    id: 'SG_17476',
    label: 'NIFTY',
    ['data-e2e']: 'neos-rfq-shortcuts-underlying-NIFTY',
  },
];

export const ASIA_PRESET = {
  STRATEGY_TYPE,
  UNDERLYINGS,
};
