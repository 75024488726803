import { NeosTooltip } from '@/neos/components/share/tooltip/NeosTooltip';
import { Tooltip } from 'react-bootstrap';
import type { AddBarrierButtonModel } from './getAddBarrierButtonModel';

export interface AddBarrierButtonOwnProps {
  rfqId: string;
  strategyId: string;
}

export interface AddBarrierButtonStateProps {
  model: AddBarrierButtonModel;
}

export interface AddBarrierButtonDispatchProps {
  onBarrierAdded: () => void;
}

type AddBarrierButtonProps = AddBarrierButtonOwnProps &
  AddBarrierButtonStateProps &
  AddBarrierButtonDispatchProps;

export const AddBarrierButton = ({ model, onBarrierAdded }: AddBarrierButtonProps) => (
  <span data-e2e="neos-extra-features-add-barrier">
    {model.isEnabled ? (
      <button className="btn btn-icon btn-flat-primary" onClick={onBarrierAdded}>
        <i className="icon icon-md">add</i>
      </button>
    ) : (
      <NeosTooltip
        placement="top"
        overlay={
          <Tooltip id="add-barrier-tooltip">
            Fill all the fields before adding a new barrier
          </Tooltip>
        }
      >
        <span>
          <button className="btn btn-icon btn-flat-primary disabled">
            <i className="icon icon-md">add</i>
          </button>
        </span>
      </NeosTooltip>
    )}
  </span>
);
