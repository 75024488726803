import type { Thunk } from '@/bootstrap/thunks';
import type { TraderGroup } from '@/neos/business/neosModel';
import { DefaultingScope } from '../../models';
import { DefaultingOptions } from '../../rfqActions';

export function createChangeStrategyTraderGroup(
  rfqId: string,
  strategyId: string,
  traderGroupName: string | undefined,
): Thunk {
  return function changeStrategyTraderGroup(
    dispatch,
    getState,
    {
      actionCreators: {
        neos: { strategyDataCrudActions, createDefaultRfqRequestedAction },
      },
      selectors,
    },
  ) {
    const state = getState();
    const traderGroups: TraderGroup[] = selectors.getTraderGroups(state.referenceData);
    const selectedTraderGroup = traderGroups.find(
      traderGroup => traderGroup.value === traderGroupName,
    );
    dispatch(
      strategyDataCrudActions.update(strategyId, {
        traderGroupName,
        traderGroupId: selectedTraderGroup?.id,
      }),
    );

    const options = new DefaultingOptions({
      overrideScopes: [DefaultingScope.BOOKING_INFO],
    });
    dispatch(createDefaultRfqRequestedAction(rfqId, options));
  };
}
