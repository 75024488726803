import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import type { GenerateFromType } from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel';
import { NeosBlurInput } from '@/neos/components/share/blurComponent/NeosBlurInput';
import { getGenerateFromValues } from './getGenerateFromValues';

type GenerateFromResultProps = {
  generateFrom: GenerateFromType;
  strategyId: string;
};
export function GenerateFromResult({ generateFrom, strategyId }: GenerateFromResultProps) {
  const { strikeDate, effectiveDate, settlementDate, maturity } = useAppSelector(state =>
    getGenerateFromValues(strategyId, state, selectors),
  );
  let value = undefined;
  switch (generateFrom) {
    case 'EFFECTIVE_DATE':
      value = effectiveDate;
      break;
    case 'FINAL_SETTLEMENT_DATE':
      value = settlementDate;
      break;
    case 'FINAL_VALUATION_DATE':
      value = maturity;
      break;
    case 'STRIKE_DATE':
      value = strikeDate;
      break;
  }
  return <NeosBlurInput value={value} readOnly />;
}
