import type { Thunk } from '@/bootstrap/thunks';
import type { EventType } from '../legModel';

export function createSimilarActivitiesSecondaryTypesOnLegsThunk({
  rfqId,
  legId,
  eventType,
}: {
  rfqId: string;
  legId: string;
  eventType: EventType;
}): Thunk {
  return function createSimilarActivitiesSecondaryTypesThunk(
    dispatch,
    getState,
    {
      selectors: { getSelectedTab },
      actionCreators: {
        neos: { rfqUiCrudActions, createAddPrimaryLeg },
      },
    },
  ) {
    const state = getState();
    const selectedTabId = getSelectedTab(state.ui.tabManagement);

    dispatch(
      createAddPrimaryLeg({
        currentPrimaryLegId: legId,
        currentPrimaryRfqId: rfqId,
        secondaryEventType: eventType,
      }),
      rfqUiCrudActions.update(selectedTabId, {
        isRfqWaitingForSecondarySelection: true,
        shouldDisplayStrategyLink: true,
      }),
    );
  };
}
