import type { Way } from '@/neos/business/rfq/rfqData/rfqDataModel';
import type {
  ActivityType,
  BidAsk,
  BookingId,
  OnyxLegAssociation,
  OnyxLegReference,
  OnyxNegotiatedSize,
  PriceWithUnit,
  SizeType,
} from '../../../../../../neos/business/neosOnyxModel';
import type { ClientPosition, Product } from '../product/productModel';

type Pivot = 'PLUS' | 'MINUS';

export interface SizeData {
  numberOfLots?: number;
  notional?: number;
  notionalUnit?: string | undefined;
  localNotional?: number;
  localNotionalUnit?: string | undefined;
  quantity?: number;
  sizeType: SizeType | undefined;
  varUnit?: number;
}

export const brokerExecutionVenueTypes = {
  OFF_FACILITY: 'Off facility',
  SEF: 'SEF',
} as const;

export type BrokerExecutionVenueTypes = keyof typeof brokerExecutionVenueTypes;

interface BrokerData {
  brokerId?: string;
  brokerCommission?: number;
  brokerCommissionCcy?: string;
  brokerInfoVenue?: string;
  brokerInfoTradingVenueTransactionId?: string;
  brokerInfoTradingDateTime?: string;
  brokerInfoExecutionVenue?: BrokerExecutionVenueTypes;
}

export const SecondaryEventTypes = [
  'PARTIAL_UNWIND',
  'FULL_UNWIND',
  'STEPIN',
  'NOVATION_REMAINING',
  'INCREASE',
] as const;

export type SecondaryEvent = (typeof SecondaryEventTypes)[number];
export type PrimaryEvent = 'CREATE' | 'RESET_QUANTITY' | 'RESET_NOMINAL';

export type EventType = SecondaryEvent | PrimaryEvent;

export function isSecondaryEvent(eventType: EventType): eventType is SecondaryEvent {
  return !!SecondaryEventTypes.find(secondaryEvent => secondaryEvent === eventType);
}

export type SecondaryEventType = SecondaryEventTypeData | undefined;

export interface SecondaryEventTypeData {
  eventType: EventType | undefined;
  negotiatedSize?: OnyxNegotiatedSize;
  primaryLegUuid?: string;
  primaryAllocUuid?: string;
}

export interface LegData extends SizeData, BrokerData {
  uuid: string;
  strategyId: string;
  activity?: ActivityType;
  clientPosition?: ClientPosition;
  isMaster: boolean;
  productId: string;
  weight: number;
  initialWeight: number;
  clientWay?: Way;
  sgWay?: Way;
  pivot?: Pivot;
  settlement?: SettlementInfo;
  quoteId: string;
  fairPriceId?: string;
  screenPrice?: BidAsk;
  totalExecutedSize?: OnyxNegotiatedSize;
  averageExecutedPrice?: PriceWithUnit;
  secondaryEvent?: SecondaryEvent;
  legAssociations?: OnyxLegAssociation[];
  legReferenceUuids?: OnyxLegReference[];
  eventType?: EventType;
  taxCollection?: boolean;
  bookingId?: BookingId;
}

export interface LegMaturityWithValidity {
  value: string | undefined;
  isValid: boolean;
}

export type LegField = keyof Pick<
  LegData,
  | 'weight'
  | 'numberOfLots'
  | 'notional'
  | 'notionalUnit'
  | 'localNotional'
  | 'varUnit'
  | 'sizeType'
  | 'clientPosition'
  | 'brokerId'
  | 'brokerCommission'
  | 'brokerCommissionCcy'
  | 'brokerInfoVenue'
  | 'brokerInfoTradingVenueTransactionId'
  | 'brokerInfoTradingDateTime'
  | 'brokerInfoExecutionVenue'
>;

export interface SettlementInfo {
  currency?: string;
  date?: DateWithOffset;
  forexRate?: ForexRate;
}

export interface DateWithOffset {
  value?: string;
  offset?: number;
}

interface ForexRate {
  from: string;
  rate: number;
  to: string;
}

type RequiredGenericSize = Required<
  Pick<Product, 'negotiationMode' | 'lotSize'> & Pick<LegData, 'numberOfLots' | 'quantity'>
>;

export type GenericSizeWithQuantity = {
  [K in keyof RequiredGenericSize]: RequiredGenericSize[K] | undefined;
};
export type GenericSize = Omit<GenericSizeWithQuantity, 'quantity'>;

export const defaultLegData = (strategyId: string, uuid: string, isMaster?: boolean): LegData => ({
  uuid,
  strategyId,
  activity: 'PRIMARY',
  quoteId: uuid,
  productId: uuid,
  isMaster: isMaster || false,
  weight: 1,
  initialWeight: 1,
  sizeType: 'FIXED_QUANTITY',
});
