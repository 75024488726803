import type { Thunk } from '@/bootstrap/thunks';
import type { RfqData } from '../../../../neos/business/neosModel';

export function createRfqAddStrategyWhenOnlyOneExistThunk(rfqId: string): Thunk {
  return function rfqAddStrategyWhenOnlyOneExistThunk(
    dispatch,
    getState,
    {
      selectors,
      thunks: {
        neos: { createAddStrategyThunk },
      },
      actionCreators: {
        neos: { rfqDataCrudActions },
      },
    },
  ) {
    const state = getState();
    const { strategyIds } = selectors.getRfqData(state, rfqId);

    if (isThereOnlyOneStrategy(strategyIds)) {
      const rfqStratResetPatch = getRfqStratResetPatch();
      dispatch(rfqDataCrudActions.update(rfqId, rfqStratResetPatch), createAddStrategyThunk(rfqId));
    }
  };
}

function isThereOnlyOneStrategy(strategyIds: string[]): boolean {
  return strategyIds.length === 1;
}

type RfqDataPartialRequired = Required<
  Pick<
    RfqData,
    | 'notionalAmount'
    | 'notionalCurrency'
    | 'pivotNotionalAmount'
    | 'totalSalesMargin'
    | 'totalSalesMarginEur'
    | 'aggregatedDataByPriceUnit'
  >
>;
export type RfqStratResetPatch = { [K in keyof RfqDataPartialRequired]: undefined };

function getRfqStratResetPatch(): RfqStratResetPatch {
  return {
    aggregatedDataByPriceUnit: undefined,
    notionalAmount: undefined,
    notionalCurrency: undefined,
    totalSalesMargin: undefined,
    totalSalesMarginEur: undefined,
    pivotNotionalAmount: undefined,
  };
}
