import {
  type EquityHedge,
  isElsProduct,
} from '@/neos/business/rfq/strategy/leg/product/productModel.ts';
import type { Dispatchable, Thunk } from '@/bootstrap/thunks.ts';

export function createUpdateElsEquityHedgeBrokerThunk(
  productId: string,
  value: EquityHedge['broker'],
): Thunk {
  return function updateElsEquityHedgeBrokerThunk(dispatch, getState, { selectors, thunks }) {
    const state = getState();
    const product = selectors.getProduct(state, productId);

    if (!isElsProduct(product)) {
      return;
    }

    const dispatchables: Dispatchable[] = [];

    dispatchables.push(thunks.neos.createUpdateElsEquityHedgeThunk(product.uuid, 'broker', value));

    if (product.equityHedge?.equityType === 'WITH_BROKER') {
      product.equityHedge?.equityHedgeComponents.forEach((_, index) => {
        dispatchables.push(
          thunks.neos.createUpdateElsEquityComponentThunk(product.uuid, index, 'broker', value),
        );
      });
    }

    dispatch(dispatchables);
  };
}
