import type { Thunk } from '@/bootstrap/thunks';
import type { InternalOnyxPreconfirmationData } from './internalOnyxPreconfirmationModel';

export function createIntegrateInternalPreconfirmationThunk(
  rfqId: string,
  externalOnyxPreconfirmation: InternalOnyxPreconfirmationData,
): Thunk {
  return function integrateInternalPreconfirmationThunk(
    dispatch,
    _,
    {
      actionCreators: {
        neos: { internalPreconfirmationCrudActions },
      },
      fromMappers: { mapFromInternalOnyxPreconfirmation },
    },
  ) {
    const preconfirmation = mapFromInternalOnyxPreconfirmation(externalOnyxPreconfirmation);
    dispatch(internalPreconfirmationCrudActions.upsert(rfqId, preconfirmation));
  };
}
