import type { Thunk } from '@/bootstrap/thunks';

export function createLegFocusedThunk(rfqId: string, legId: string): Thunk {
  return function legFocusedThunks(
    dispatch,
    getState,
    {
      selectors: { getSelectedLegId },
      actionCreators: {
        neos: { rfqUiCrudActions },
      },
    },
  ) {
    const state = getState();
    const selectedLegId = getSelectedLegId(state.ui, rfqId);
    if (legId !== selectedLegId) {
      dispatch(
        rfqUiCrudActions.patchOrInsert(rfqId, {
          selectedLegId: legId,
        }),
      );
    }
  };
}

export function createLegBlurredThunk(rfqId: string, legId: string): Thunk {
  return function legBlurredThunks(
    dispatch,
    getState,
    {
      selectors: { getSelectedLegId },
      actionCreators: {
        neos: { rfqUiCrudActions },
      },
    },
  ) {
    setTimeout(() => {
      const state = getState();
      const selectedLegId = getSelectedLegId(state.ui, rfqId);
      if (legId === selectedLegId) {
        dispatch(
          rfqUiCrudActions.patchOrInsert(rfqId, {
            selectedLegId: undefined,
          }),
        );
      }
    }, 2000);
  };
}
