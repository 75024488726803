import type { EventType } from '@/neos/business/rfq/strategy/leg/legData/legDataModel';
import { crudDomain } from '@/util/crudUtils';

export interface AllocSecondaryEventCreation {
  eventType: EventType | undefined;
  primaryAllocUuid: string;
}

export interface AllocSecondaryEventCreationKey {
  rfqId: string;
  allocationId: string;
}

const compositeIdSeparator = ' | ';

function getAllocSecondaryEventCreationId({
  rfqId,
  allocationId,
}: AllocSecondaryEventCreationKey): string {
  return `${rfqId}${compositeIdSeparator}${allocationId}`;
}

function getAllocSecondaryEventCreationKey(id: string): AllocSecondaryEventCreationKey {
  const [rfqId, allocationId] = id.split(compositeIdSeparator);
  return { rfqId, allocationId };
}

export const allocSecondaryEventCreationDomain = crudDomain<
  AllocSecondaryEventCreation,
  AllocSecondaryEventCreationKey
>('alloc-secondary-event-creation', {
  keyToString: getAllocSecondaryEventCreationId,
  stringToKey: getAllocSecondaryEventCreationKey,
});
