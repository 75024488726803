import type { Thunk } from '@/bootstrap/thunks';

export function createObsoletePredealChecksRemovedThunk(
  rfqId: string,
  oldPredealCheckIds: string[],
): Thunk {
  return function obsoletePredealChecksRemovedThunk(
    dispatch,
    getState,
    {
      selectors: { getRfqData },
      actionCreators: {
        neos: { createPredealChecksRemovedAction },
      },
    },
  ) {
    const state = getState();
    const { predealCheckIds } = getRfqData(state, rfqId);
    const predealChecksToRemoveIds = oldPredealCheckIds.filter(
      oldId => !predealCheckIds.includes(oldId),
    );

    if (predealChecksToRemoveIds.length) {
      return dispatch(createPredealChecksRemovedAction(predealChecksToRemoveIds));
    }
  };
}
