import { CounterpartPopover } from '../../counterpartPopover';
import type { RequestPredealChecksModel } from './getRequestPredealChecksModel';
import { RequestPredealCheckCell } from './requestPredealCheckCell/RequestPredealCheckCell.container';

import styles from './RequestPredealChecks.module.scss';

export interface RequestPredealChecksOwnProps {
  rfqId: string;
}

export interface RequestPredealChecksStateProps {
  model: RequestPredealChecksModel;
}

type RequestPredealChecksProps = RequestPredealChecksOwnProps & RequestPredealChecksStateProps;

export const RequestPredealChecksComponent = ({ rfqId, model }: RequestPredealChecksProps) =>
  model.counterpart ? (
    <section className="card card-bordered">
      <div className={`${styles['request-predeal-checks-body']} card-body`}>
        <div className={`${styles['counterpart']} `}>
          <span className={`${styles['counterpart-label']} `}>Client:</span>
          <CounterpartPopover counterpart={model.counterpart}>
            <span
              className={`${styles['predeal-checks-request-counterpart']} `}
              data-e2e="predeal-checks-request-counterpart"
            >
              {model.counterpart.name}
            </span>
          </CounterpartPopover>
        </div>
        <div className={`${styles['predeal-checks']} `}>
          {model.typesToDisplay.map(type => (
            <RequestPredealCheckCell
              key={type}
              rfqId={rfqId}
              type={type}
              bdrId={model.counterpart.id}
            />
          ))}
        </div>
      </div>
    </section>
  ) : (
    <span className="label label-danger" data-e2e="request-no-counterpart-warning">
      You should select a counterpart!
    </span>
  );
