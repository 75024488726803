import type { BulkCancelReason } from '@/neos/business/blotter/blotterModel';
import type { DisplayTimeZone } from '@/neos/business/ui/userPreferences/userPreferencesUiModel';
import type { BlotterRowModel } from '@/neos/components/share/blotterGrid';
import { AgGridReact } from '@ag-grid-community/react';
import { Component, type KeyboardEvent, type Ref } from 'react';
import '../../share/blotterGrid/BlotterRowStyles.css';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import type { ExcelStyle } from '@ag-grid-community/core';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { SideBarModule } from '@ag-grid-enterprise/side-bar';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import styles from './BlotterGrid.module.scss';
import { createGridApiExtended } from './gridApiExtended';
import { GridNotificationsHandler } from './gridNotificationsHandler';
import { GridStateApiManager } from './gridStateApiManager';
import { getBlotterStateProps } from './gridStatePropsManager';
import { GridStateStorageAdapter } from './gridStateStorageAdapter';
import { GridStateStorageManager } from './gridStateStorageManager';
import { getGridStaticOptions } from './gridStaticOptions';

export interface BlotterGridModel {
  rows: BlotterRowModel[];
  pendingRfqIds: string[];
  isVisible: boolean;
  displayTimezone: DisplayTimeZone;
  lastRequestTime: string;
  isTrader: boolean;
  isPreconfStatusEnabled: boolean;
}

export type BlotterGridPropsFromState = BlotterGridModel;

export interface BlotterGridDispatchProps {
  loadLatestTransactions(rfqIds: string[]): void;

  displayNotManagedRfqError(data: BlotterRowModel): void;

  generateManualPricing(rfqIds: string[]): void;

  copyQuoteRecapsToClipboard(rfqIds: string[]): void;

  copyTradeRecapsToClipboard(rfqIds: string[]): void;

  copyRfqIdsToClipboard(rfqIds: string[]): void;

  onBulkCancelRfqs(rfqIds: string[], reason: BulkCancelReason): void;

  emptyBlotter(): void;

  resetBlotterRfqFilteredOutNotificationLastDate(): void;

  updateBlotterRfqFilteredOutNotificationLastDate(): void;

  requestAnalyticLogging(desc: string): void;
}

export type BlotterGridProps = BlotterGridDispatchProps & BlotterGridPropsFromState;

export class BlotterGridWithCss extends Component<BlotterGridProps> {
  private gridStateStorageAdapter: GridStateStorageAdapter;
  private gridNotificationHandler: GridNotificationsHandler;

  constructor(props: BlotterGridProps) {
    super(props);
    const storageManager = new GridStateStorageManager(window.localStorage);

    this.gridStateStorageAdapter = new GridStateStorageAdapter(
      storageManager,
      createGridApiExtended,
      api => new GridStateApiManager(api),
    );
    this.gridNotificationHandler = new GridNotificationsHandler(this.gridStateStorageAdapter);
    this.callbackResetFilter = this.callbackResetFilter.bind(this);
  }

  public componentDidMount() {
    this.gridNotificationHandler.handleEvents();
  }

  public componentDidUpdate(prevProps: BlotterGridProps) {
    setTimeout(() => {
      this.gridNotificationHandler.handleNotifications(this.props);
    }, 1500);

    const gridApi = this.gridStateStorageAdapter.gridApi;
    if (prevProps.displayTimezone !== this.props.displayTimezone) {
      if (gridApi) {
        const { columnDefs } = getGridStaticOptions(this.props);
        gridApi.setColumnDefs(columnDefs!);
      }
    }
  }

  public handleMultipleOpenRfq({ key }: KeyboardEvent<HTMLDivElement>) {
    if (key === 'Enter') {
      const selectedRows =
        this.gridStateStorageAdapter.gridApi?.getSelectedRows() as BlotterRowModel[];
      const selectedIds = selectedRows.map(node => node.uuid);
      this.props.loadLatestTransactions(selectedIds);
    }
  }

  public callbackResetFilter = (): void => {
    const gridApi = this.gridStateStorageAdapter.gridApi;
    if (gridApi) {
      gridApi.setFilterModel(null);
    }
  };

  private agGrid: Ref<AgGridReact> | null = null;

  public render() {
    const excelStyles: ExcelStyle[] = [
      {
        id: 'ExcelDateTime',
        dataType: 'DateTime',
        numberFormat: { format: 'yyyy-mm-dd hh:mm:ss;;;' },
      },
    ];

    return (
      <div
        className={`${styles['blotter-grid']} ag-theme-sg-bootstrap-condensed`}
        onKeyPress={e => this.handleMultipleOpenRfq(e)}
      >
        <AgGridReact
          ref={this.agGrid}
          gridOptions={getGridStaticOptions(this.props)}
          excelStyles={excelStyles}
          suppressColumnVirtualisation
          rowData={this.props.rows}
          {...getBlotterStateProps(this.gridStateStorageAdapter, this.props, {
            handleResetFilter: this.callbackResetFilter.bind(this),
          })}
          modules={[
            ExcelExportModule,
            ClientSideRowModelModule,
            RangeSelectionModule,
            SideBarModule,
            StatusBarModule,
            MenuModule,
            RowGroupingModule,
            SetFilterModule,
            MultiFilterModule,
          ]}
        />
      </div>
    );
  }
}
