import { HttpContext } from '@/bootstrap/HttpContext';
import type { UnderlyingType } from '@/neos/business/neosOnyxModel';
import { mapUnderlyingToTypeaheadValue } from '@/neos/components/rfq/strategies/strategy/common/underlyingInput/underlyingMappers';
import { Typeahead, type TypeaheadApi, type TypeaheadValue } from '../../../../../share/typeahead';
import { makeUnderlyingApi } from './searchUnderlyingApi';

interface UnderlyingInputProps {
  className?: string;
  inputClassName?: string;
  componentId: string;
  underlyingId: string | undefined;
  bloombergCode: string | undefined;
  disabled?: boolean;
  readOnly?: boolean;
  autoFocus?: boolean;
  positionFixed?: boolean;
  'data-e2e'?: string;
  onChange: (val: TypeaheadValue | undefined) => void;
  onFocus?: () => void;
  onBlur?: (val: TypeaheadValue | undefined) => void;
  shouldCleanInputAfterSelection?: boolean;
  exclude?: string[];
}

function UnderlyingInputWithHttpContext({
  componentId,
  underlyingId,
  bloombergCode,
  readOnly,
  disabled = false,
  'data-e2e': dataE2e,
  autoFocus = false,
  positionFixed = false,
  onChange,
  onFocus,
  onBlur,
  typeaheadApi,
  className = '',
  inputClassName = 'fw-bold',
  shouldCleanInputAfterSelection = false,
  exclude,
}: UnderlyingInputProps & {
  typeaheadApi: TypeaheadApi;
}) {
  const typeaheadValue =
    underlyingId && bloombergCode
      ? mapUnderlyingToTypeaheadValue({ id: underlyingId, bloombergCode })
      : undefined;

  return readOnly ? (
    <input
      className={'form-control ' + inputClassName}
      readOnly
      onFocus={onFocus}
      value={bloombergCode || ''}
    />
  ) : (
    <Typeahead
      id={componentId}
      api={typeaheadApi}
      inputClassName={inputClassName}
      className={'typeahead errorable-bloc ' + className}
      value={typeaheadValue}
      disabled={disabled}
      positionFixed={positionFixed}
      autoFocus={autoFocus}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      data-e2e={dataE2e}
      shouldCleanInputAfterSelection={shouldCleanInputAfterSelection}
      exclude={exclude}
    />
  );
}

type UnderlyingInputWithTypesProps = UnderlyingInputProps & {
  underlyingTypes?: UnderlyingType[];
};

export function UnderlyingInput(underlyingInputProps: UnderlyingInputWithTypesProps) {
  const { underlyingTypes = [], ...props } = underlyingInputProps;
  return (
    <HttpContext.Consumer>
      {({ httpClient }) => {
        const { fetchMUnderlyingMatchingOptions } = makeUnderlyingApi(httpClient!);
        const typeaheadApi: TypeaheadApi = {
          fetchMatchingOptions: (prefix: string) =>
            fetchMUnderlyingMatchingOptions(prefix, underlyingTypes),
        };
        return <UnderlyingInputWithHttpContext typeaheadApi={typeaheadApi} {...props} />;
      }}
    </HttpContext.Consumer>
  );
}
