import type { Thunk } from '@/bootstrap/thunks';
import type { Quote } from '../../../../neos/business/neosModel';

export function createUpdateTotalRfqQuoteThunk(rfqId: string, quotePatch: Partial<Quote>): Thunk {
  return function updateTotalRfqQuoteThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { quoteCrudActions },
      },
    },
  ) {
    const state = getState();
    const { quoteId: rfqQuoteId } = selectors.getRfqData(state, rfqId);
    const allRfqChildrensQuoteIds = selectors
      .getRfqQuoteIds(state, rfqId, selectors)
      .filter(quoteId => quoteId !== rfqQuoteId);

    const emptyQuotePatch: Partial<Quote> = makeResetStrategyPricesPatch(quotePatch);

    const legsQuotesActions = allRfqChildrensQuoteIds.map(quoteId =>
      quoteCrudActions.update(quoteId, emptyQuotePatch),
    );

    dispatch(quoteCrudActions.update(rfqQuoteId, quotePatch), ...legsQuotesActions);
  };
}

function makeResetStrategyPricesPatch({
  traderBid,
  traderAsk,
  delta,
}: Partial<Quote>): Partial<Quote> {
  if (traderBid !== undefined || traderAsk !== undefined) {
    return {
      traderBid: undefined,
      traderAsk: undefined,
    };
  }
  if (delta !== undefined) {
    return {
      delta: undefined,
    };
  }
  return {};
}
