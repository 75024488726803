import { last, max } from 'lodash';
import type { TabAction, TabAddedAction, TabClosedAction, TabSelectedAction } from './tabActions';
import { type TabManagerState, defaultTabManagerState } from './tabState';

export function tabReducer(
  state: TabManagerState = defaultTabManagerState,
  action: TabAction,
): TabManagerState {
  switch (action.type) {
    case 'TAB_ADDED':
      return addTab(state, action);
    case 'TAB_SELECTED':
      return selectTab(state, action);
    case 'TAB_CLOSED':
      return closeTab(state, action);
    default:
      return state;
  }
}

function addTab(state: TabManagerState, { tabId }: TabAddedAction): TabManagerState {
  const maxTabId = getNewTabNumber(state);
  return {
    ...state,
    tabs: [...state.tabs, { id: tabId, name: `#${maxTabId}` }],
    previouslySelectedTabIds: [...state.previouslySelectedTabIds, tabId],
    selectedTabId: tabId,
  };
}

function getNewTabNumber(state: TabManagerState) {
  const tabIds = state.tabs
    .filter(tab => tab.name.startsWith('#'))
    .map(tab => parseInt(tab.name.substr(1), 10));
  const maxTabId = (max(tabIds) || 0) + 1;
  return maxTabId;
}

function closeTab(state: TabManagerState, { tabId }: TabClosedAction): TabManagerState {
  const { previouslySelectedTabIds } = state;
  const newPreviouslySelectedTabIds = previouslySelectedTabIds.filter(p => p !== tabId);
  return {
    ...state,
    tabs: state.tabs.filter(currentTabId => currentTabId.id !== tabId),
    selectedTabId: last(newPreviouslySelectedTabIds)!,
    previouslySelectedTabIds: newPreviouslySelectedTabIds,
  };
}

function selectTab(state: TabManagerState, { selectedTabId }: TabSelectedAction): TabManagerState {
  const { previouslySelectedTabIds } = state;
  const newPreviouslySelectedTabIds = previouslySelectedTabIds.filter(p => p !== selectedTabId);
  newPreviouslySelectedTabIds.push(selectedTabId);

  return {
    ...state,
    previouslySelectedTabIds: newPreviouslySelectedTabIds,
    selectedTabId,
  };
}
