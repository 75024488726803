import type { BlobWithMetadata } from '@/util/http/sgmeAjaxHttpRequest';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { OnyxTransaction } from '../../neosOnyxModel';
import type {
  ExternalOnyxPreconfirmation,
  ExternalPreconfirmationRequest,
} from './externalOnyxPreconfirmationModel';
import type { ExternalPreconfirmationApi } from './externalPreconfirmationApiType';

export const createExternalPreconfirmationApi = (http: SgmeHttp): ExternalPreconfirmationApi => ({
  sendExternalPreconfirmationEmail(
    uuid: string,
    externalPreconfirmationRequest: ExternalPreconfirmationRequest,
    transaction: OnyxTransaction,
    ignoreFieldChanges,
  ) {
    const url: string = `api/workflow-neos/${uuid}/sendPreconfMail?mailType=EXTERNAL`;
    return http.post<ExternalOnyxPreconfirmation>({
      url,
      body: {
        ignoreFieldChanges,
        mailOptions: externalPreconfirmationRequest,
        mailType: 'EXTERNAL',
        transaction,
      },
    });
  },

  getExternalPreconfirmationEmailPreview(
    uuid: string,
    externalPreconfirmationRequest: ExternalPreconfirmationRequest,
  ) {
    const url: string = `api/preConfirmations/${uuid}/previewAttachment?type=EXTERNAL`;
    return http.put<BlobWithMetadata>({
      url,
      body: externalPreconfirmationRequest,
      responseContentType: { contentType: 'BLOB' },
    });
  },
});
