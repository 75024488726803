import type { Thunk } from '@/bootstrap/thunks';
import { isDefined } from '@/util/undefinedAndNull/isDefined';

export function createRequestBulkCopyQuoteRecapToClipboardThunk(rfqIds: string[]): Thunk {
  return function requestBulkCopyQuoteRecapToClipboardThunk(
    dispatch,
    getState,
    {
      selectors: { getBlotterRfqById },
      actionCreators: {
        analytics: { createLogAnalyticsAction },
      },
      thunks: { createCopyToClipboardThunk },
    },
  ) {
    const state = getState();
    const payload: string = rfqIds
      .map(rfqId => getBlotterRfqById(state.blotter, rfqId))
      .filter(isDefined)
      .map(({ quoteRecap }) => quoteRecap)
      .filter((quoteRecap: string | undefined) => quoteRecap)
      .join('\r\n\r\n');

    dispatch(
      createCopyToClipboardThunk(payload),
      createLogAnalyticsAction('NEOS BLOTTER', 'Copy Quote Recap'),
    );
  };
}
