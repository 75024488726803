import type { ChainOptions } from '../neosActions';
import { nextUserActionsDomain } from './nextUserActionsDomain';
import type { NextUserAction } from './nextUserActionsModel';
import type {
  ApplyNextUserActionRequestedAction,
  NextUserActionsRequestedAction,
} from './nextUserActionsReduxActions';

export const nextUserActionsReduxActionCreators = {
  nextUserActionsCrudActions: nextUserActionsDomain.actions,
  createNextUserActionsRequestedAction,
  createApplyNextUserActionRequestedAction,
};

function createNextUserActionsRequestedAction(rfqId: string): NextUserActionsRequestedAction {
  return {
    type: 'NEXT_USER_ACTIONS_REQUESTED',
    rfqId,
  };
}

function createApplyNextUserActionRequestedAction(
  rfqId: string,
  nextUserAction: NextUserAction,
  chainOptions?: ChainOptions,
): ApplyNextUserActionRequestedAction {
  return {
    type: 'APPLY_NEXT_USER_ACTION_REQUESTED_ACTION',
    rfqId,
    nextUserAction,
    chainOptions,
  };
}
