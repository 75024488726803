import { actionCreators } from '@/bootstrap/actions';
import { thunks } from '@/bootstrap/thunks';
import { isValidAllocationFileType } from '@/neos/business/services/isValidAllocationFileType';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';

export function UploadFile({ rfqId, allocId }: { rfqId: string; allocId: string }) {
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);

  function uploadFile(rfqId: string, allocId: string, selectedFile: File | undefined) {
    if (selectedFile) {
      if (isValidAllocationFileType(selectedFile.name)) {
        const formData = new FormData();
        formData.append('file', selectedFile);
        dispatch(actionCreators.neos.createUploadAllocationFileAction(rfqId, allocId, formData));
        if (inputRef && inputRef.current) {
          inputRef.current.value = '';
        }
      } else {
        dispatch(
          thunks.createErrorToasterThunk(
            {
              message:
                'Error when uploading file: the file type must be one of the following: xlsx, xls, csv, doc, docx or pdf',
            },
            undefined,
            false,
          ),
        );
      }
    }
  }

  return (
    <SimpleNeosTooltip id={`tooltip-upload-${allocId}`} message={'Upload file'}>
      <button
        className="btn btn-discreet-primary btn-icon"
        onClick={() => inputRef.current?.click()}
      >
        <i className="icon">upload</i>
        <input
          id={'otc_alloc_upload_' + allocId}
          ref={inputRef}
          className="d-none"
          type="file"
          accept=".xlsx,.xls,.csv,.doc,.docx,application/pdf"
          onChange={e => {
            uploadFile(rfqId, allocId, e?.target?.files?.[0]);
          }}
        />
      </button>
    </SimpleNeosTooltip>
  );
}
