import type { ChainOptions } from '@/neos/business/neosActions';
import type { ActionContainer } from '@/util/actionContainer';
import type { ReferenceKey } from '../models';
import { RfqRecomputeScope } from '../models/recomputeModel';
import { referenceActionCreators } from '../reference/referenceActionCreators';
import { createActionWithRecompute } from './actionWithRecompute';

export function createSpotChangedAction(
  referenceKey: ReferenceKey,
  refSpot: number | undefined,
  chainOptions?: ChainOptions,
): ActionContainer {
  return createActionWithRecompute(
    referenceKey.rfqId,
    RfqRecomputeScope.CHANGE_SPOT,
    [referenceActionCreators.referenceCrudActions.update(referenceKey, { refSpot })],
    chainOptions,
  );
}
