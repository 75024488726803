import { useTimerWithDuration } from '../../../../share/neosTimer/useNeosTimer';
import { CommonTimer } from '../commonTimer/CommonTimer';

export interface MdpRfqTimerOwnProps {
  rfqId: string;
}

export interface MdpRfqTimerStateProps {
  expiry: string;
  creationTime: string;
}

export type MdpRfqTimerProps = MdpRfqTimerOwnProps & MdpRfqTimerStateProps;

export const MdpRfqTimerComponent = ({ expiry, creationTime }: MdpRfqTimerProps) => {
  const { remainingTime, duration } = useTimerWithDuration(expiry, creationTime);
  return (
    <CommonTimer remainingTime={remainingTime} duration={duration} timerName={'Life cycle timer'} />
  );
};
