import type { User } from '../referenceDataModel';
import type { TradersReceivedAction } from './traderActions';

export const tradersActionCreators = {
  createTradersReceivedAction,
};

export function createTradersReceivedAction(traders: User[]): TradersReceivedAction {
  return {
    type: 'TRADERS_RECEIVED',
    traders,
  };
}
