import type { OnyxQuoteType } from '@/neos/business/rfq/rfqOnyxModel';
import { NeosTooltip } from '@/neos/components/share/tooltip/NeosTooltip';
import type { FC } from 'react';
import { ToggleButton, ToggleButtonGroup, Tooltip } from 'react-bootstrap';

export interface QuoteTypeOwnProps {
  rfqId: string;
}

export interface QuoteTypeMapStateToProps {
  quoteType: OnyxQuoteType;
  isReadonlyRfq: boolean;
}

export interface QuoteTypeDispatchProps {
  onQuoteTypeChanged(): void;
}

export const QuoteTypeComponent: FC<
  QuoteTypeOwnProps & QuoteTypeMapStateToProps & QuoteTypeDispatchProps
> = ({ quoteType, isReadonlyRfq, onQuoteTypeChanged }) => (
  <section>
    <NeosTooltip placement="top" overlay={<Tooltip id="quote-type">Quote Type</Tooltip>}>
      <ToggleButtonGroup
        name="quoteType"
        className="btn-group-toggle"
        type="radio"
        value={quoteType}
        onChange={onQuoteTypeChanged}
      >
        <ToggleButton
          disabled={isReadonlyRfq}
          variant="toggle-primary"
          value="INDICATIVE"
          id="indicative"
        >
          Indicative
        </ToggleButton>
        <ToggleButton
          disabled={isReadonlyRfq}
          variant="toggle-primary"
          value="TRADABLE"
          id="tradable"
        >
          Tradable
        </ToggleButton>
      </ToggleButtonGroup>
    </NeosTooltip>
  </section>
);
