import type { FromOnyxMappers } from '@/neos/business/mappers';
import { convertNullToUndefined } from '@/util/undefinedAndNull/convertNullToUndefined';
import type { VSwap } from '../../productModel';
import type { OnyxSingleUnderlyingProduct } from '../../productOnyxModel';

type VSwapFamilies = VSwap['subFamily'];

export function mapFromOnyxProductVSwap(
  legId: string,
  onyxProduct: OnyxSingleUnderlyingProduct,
  productUuid: string,
  subFamily: VSwapFamilies,
  mappers: FromOnyxMappers,
): VSwap {
  return {
    subFamily,
    ...mappers.mapFromOnyxProductCommon(legId, onyxProduct, productUuid),
    ...mappers.mapFromOnyxOtcNegotiation(),
    ...mappers.mapFromSingleDerivativeOnyxProduct(onyxProduct, mappers),
    expectedN: convertNullToUndefined(onyxProduct.expectedN),
    hasCustomUnderlying: false,
  };
}
