import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { type ExtendedDispatch, type NeosThunks, thunks } from '@/bootstrap/thunks';
import { useDispatch, useSelector } from 'react-redux';
import {
  LegLinkCellComponent,
  type LegLinkCellDispatchProps,
  type LegLinkCellMapStateProps,
  type LegLinkCellOwnProps,
} from './LegLinkCell';
import { getLegLinkCellModel } from './getLegLinkCellModel';

export const LegLinkCell = (ownProps: LegLinkCellOwnProps) => {
  const stateProps = useSelector<AppState, LegLinkCellMapStateProps>(state => {
    return getLegLinkCellModel(state, ownProps.rfqId, ownProps.strategyId, selectors);
  });
  const dispatch = useDispatch<ExtendedDispatch>();
  const dispatchProps = getDispatchProps(dispatch, ownProps, thunks.neos);
  return <LegLinkCellComponent {...ownProps} {...stateProps} {...dispatchProps} />;
};

function getDispatchProps(
  dispatch: ExtendedDispatch,
  { rfqId, strategyId, legId }: LegLinkCellOwnProps,
  { createChangeLegSecondaryTypeThunk, createResetSecondaryTypesOnLegsThunk }: NeosThunks,
): LegLinkCellDispatchProps {
  return {
    onLink: () => {
      dispatch([
        createLogAnalyticsAction('NEOS RFQ', `Rfq similar activities leg link requested`),
        createChangeLegSecondaryTypeThunk({ rfqId, strategyId, legId }),
        createResetSecondaryTypesOnLegsThunk(),
      ]);
    },
  };
}
