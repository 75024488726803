import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import {
  type AllocationOrDeltaPredealCheck,
  allocationOrDeltaPredealCheckWithLegsTypes,
  onlyListedAllocationOrDeltaPredealCheckWithLegsTypes,
} from '@/neos/business/predealCheck/predealCheckModel';
import {
  type PredealCheckAllocationOrDeltaLevel,
  predealChecksWithoutLegsTypes,
  type PredealCheckType,
} from '@/neos/business/predealCheck/predealCheckModel/common';

interface DisplayedPredealChecksTableModel {
  displayed: true;
  counterpartsNamesByIds: Array<{ id: number; name: string }>;
  legIdsByStrategyIds: Array<{ strategyId: string; legIds: string[] }>;
  orderedPredealCheckTypes: Array<{
    type: PredealCheckType;
    title: string;
    padding: 'pe-0' | 'pe-3' | '';
  }>;
  isCollapsed: boolean;
  levelTitle: 'Market' | 'Delta';
}

interface HiddenPredealChecksTableModel {
  displayed: false;
}
export type PredealChecksTableModel =
  | DisplayedPredealChecksTableModel
  | HiddenPredealChecksTableModel;

const predealCheckTypeTitlesMap: { [k in AllocationOrDeltaPredealCheck['type']]: string } = {
  KYC: 'KYC',
  MIFID1: 'MIFID',
  CREDIT_CHECK_BID: 'Credit Risk Bid',
  CREDIT_CHECK_ASK: 'Credit Risk Ask',
  COST_AND_CHARGES: 'MIFID 2 C&C',
  DFA: 'DFA CFTC',
  DFA_SEC: 'DFA SEC',
  EMIR: 'EMIR',
  FATCA: 'FATCA',
  HIRE_ACT: 'HIRE Act',
  CROSS_BORDER: 'X-Border',
  HKMA: 'HKMA',
  IM_VM: 'IM/VM',
  IM_SIMULATION_BID: 'UMR RFQ Bid',
  IM_SIMULATION_ASK: 'UMR RFQ Ask',
  CASS: 'CASS',
  EMBARGO: 'Embargo Ins.',
  EMBARGO_CTPY: 'Embargo Ctp.',
};

export function getPredealChecksTableModel(
  state: AppState,
  rfqId: string,
  level: PredealCheckAllocationOrDeltaLevel,
  appSelectors: Selectors,
): PredealChecksTableModel {
  const selectors = appSelectors;
  const salesCounterpart = selectors.getSalesCounterpart(state, rfqId, appSelectors);
  if (!salesCounterpart) {
    return { displayed: false };
  }

  const allocCounterparts = selectors.getOtcAllocationsCounterparts(state, rfqId, selectors);

  const counterpartsNamesByIds = [
    { id: salesCounterpart.id, name: salesCounterpart.name },
    ...allocCounterparts.filter(c => c.id !== salesCounterpart.id),
  ];

  const [legIdsByStrategyIdsSelector, collapsedSelector, levelTitle] =
    level === 'ALLOCATION'
      ? [
          selectors.getRfqLegIdsByStrategyIds,
          appSelectors.areMarketPredealChecksCollapsed,
          'Market' as const,
        ]
      : [
          selectors.getRfqLegIdsByDeltaStrategyIds,
          appSelectors.areDeltaPredealChecksCollapsed,
          'Delta' as const,
        ];
  const legIdsByStrategyIds = legIdsByStrategyIdsSelector(state, rfqId, appSelectors).map(
    ({ strategyId, legIds }) =>
      appSelectors.hasACompositionLeg(state, strategyId, appSelectors)
        ? { strategyId, legIds: [] }
        : { strategyId, legIds },
  );

  const hasAtListOTCStrategies = !!legIdsByStrategyIds.find(({ strategyId }) =>
    selectors
      .getStrategyProducts(state, strategyId, appSelectors)
      .find(({ negotiationMode }) => negotiationMode === 'OTC'),
  );

  const isUMRPdcEnabled = appSelectors.isFeatureToggleEnabled(state, 'neos.umr.pdc.enabled');

  const otcPdcs = isUMRPdcEnabled
    ? allocationOrDeltaPredealCheckWithLegsTypes
    : allocationOrDeltaPredealCheckWithLegsTypes.filter(
        check => check !== 'IM_SIMULATION_ASK' && check !== 'IM_SIMULATION_BID',
      );

  const orderedTypesToDisplay: Array<AllocationOrDeltaPredealCheck['type']> = [
    ...predealChecksWithoutLegsTypes,
    ...(hasAtListOTCStrategies ? otcPdcs : onlyListedAllocationOrDeltaPredealCheckWithLegsTypes),
  ];

  const getPadding = (type: PredealCheckType) => {
    switch (type) {
      case 'EMBARGO':
        return !hasAtListOTCStrategies ? 'pe-3' : '';
      case 'CREDIT_CHECK_ASK':
        return 'pe-0';
      default:
        return '';
    }
  };

  const orderedPredealCheckTypes = orderedTypesToDisplay.map(
    (type): DisplayedPredealChecksTableModel['orderedPredealCheckTypes'][number] => ({
      type,
      title: predealCheckTypeTitlesMap[type],
      padding: getPadding(type),
    }),
  );

  const isCollapsed = collapsedSelector(state.ui, rfqId);

  return {
    displayed: true,
    counterpartsNamesByIds,
    legIdsByStrategyIds,
    orderedPredealCheckTypes,
    isCollapsed,
    levelTitle,
  };
}
