import { hasDiscriminator } from '@/neos/business/rfq/strategy/feature/hasDiscriminator';
import { convertNullToUndefined } from '@/util/undefinedAndNull/convertNullToUndefined';
import type {
  OnyxPartialReset,
  OnyxProductFeaturesFields,
} from '../../../../../../neos/business/neosOnyxModel';
import type { PartialResetFeature } from '../featureModel';

export function mapFromOnyxFeatureOrExtraFeaturesPartialReset(
  { extraFeatures }: OnyxProductFeaturesFields,
  strategyId: string,
): PartialResetFeature | undefined {
  const partialResetFeature: OnyxPartialReset | undefined = extraFeatures?.find(
    hasDiscriminator<OnyxPartialReset>('PARTIAL_RESET'),
  );

  if (partialResetFeature) {
    return {
      type: 'PARTIAL_RESET',
      strategyId,
      partialReset: convertNullToUndefined(partialResetFeature.partialReset),
    };
  }

  return undefined;
}

export function mapToOnyxPartialReset(feature: PartialResetFeature): OnyxProductFeaturesFields {
  const { partialReset } = feature;

  return {
    extraFeatures: [
      {
        discriminator: 'PARTIAL_RESET',
        partialReset: partialReset === undefined ? null : partialReset,
      },
    ],
  };
}
