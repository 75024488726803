import { NeosTooltip } from '@/neos/components/share/tooltip/NeosTooltip';
import { Tooltip } from 'react-bootstrap';
import type { AllStrategyColumns, ColumnGridDefinition } from '../getDisplayedColumns';
import styles from './StrategyHeader.module.scss';

export interface StrategyHeaderOwnProps {
  columnGridDefinition: ColumnGridDefinition[];
  gridTemplateColumns: string;
}

export const StrategyHeader = (props: StrategyHeaderOwnProps) => (
  <section
    style={{
      gridTemplateColumns: props.gridTemplateColumns,
    }}
    className={`${styles['strategy-header']} ${styles['strategy-grid']}`}
  >
    {props.columnGridDefinition.map((columnGridDefinition, index) =>
      makeHeader(columnGridDefinition, index),
    )}
  </section>
);

function makeHeaderLabel(
  index: number,
  { label, tooltip, styleName }: ColumnHeaderDefinition,
): () => JSX.Element {
  const labelComponent = (
    <label
      key={index}
      data-e2e={'neos-rfq-strategies-header-label'}
      className={`${styleName ? styles[styleName] : ''}`}
    >
      {label}
    </label>
  );
  return () =>
    tooltip ? (
      <NeosTooltip
        key={index}
        placement="top"
        overlay={<Tooltip id={'header' + label}>{tooltip}</Tooltip>}
      >
        {labelComponent}
      </NeosTooltip>
    ) : (
      labelComponent
    );
}

interface ColumnHeaderDefinition {
  label: string;
  tooltip?: string;
  styleName?: string;
}

type ColumnHeadersDefinition = { [columnId in AllStrategyColumns]: ColumnHeaderDefinition };

function makeHeader({ id }: ColumnGridDefinition, index: number): JSX.Element {
  const columnHeaderDefinition: ColumnHeadersDefinition = {
    valueDate: { label: 'Value Date' },
    warnings: { label: '' },
    schedule: { label: '' },
    basketToggle: { label: 'Basket' },
    weight: { label: 'Wgt', tooltip: 'Strategy Weight' },
    size: { label: 'Size' },
    secondaryType: { label: 'Secondary Type' },
    secondaryTypeOnSecondaryRfq: { label: '' },
    legLink: { label: 'Leg Link', tooltip: 'Leg Link' },
    strategyLink: { label: 'Strat. Link', tooltip: 'Strategy Link' },
    strategyType: { label: 'Strategy Type' },
    negoType: { label: '' },
    elsType: { label: 'ELS T.', tooltip: 'ELS Type' },
    ratio: { label: 'Ratio', tooltip: 'Leg Ratio' },
    underlying: { label: 'Underlying' },
    effectiveDate: { label: 'Effective Date' },
    clientTaxRate: { label: 'Client Tax Rate' },
    noTaxCollection: { label: 'No Tax' },
    maturity: { label: 'Maturity' },
    startDate: { label: 'Start Date' },
    strikeDate: { label: 'Stk Date', tooltip: 'Strike Date' },
    settlementDate: { label: 'Settlement Date', tooltip: 'Settlement Date' },
    futureMaturity: { label: 'F. Maturity', tooltip: 'Future Maturity' },
    clientPosition: { label: 'CP', tooltip: 'Client Position' },
    expectedN: { label: 'Exp N' },
    optionStyle: { label: 'Stl', tooltip: 'Option Style' },
    observableType: { label: 'Obs', tooltip: 'Observable Type' },
    optionFlex: { label: 'Flex', tooltip: 'Option Flex' },
    optionType: { label: 'Ty', tooltip: 'Option Type' },
    strike: { label: 'Strike' },
    upperStrike: { label: 'Upper Strike' },
    lowerStrike: { label: 'Lower Strike' },
    swapCurrency: { label: 'Swap Ccy', tooltip: 'Swap Currency' },
    forwardInterestRate: {
      label: 'Fwd. IR',
      tooltip: 'Forward Interest Rate',
    },
    forwardDrift: { label: 'Fwd. Drift', tooltip: 'Forward Drif' },
    lotSize: { label: 'Lot size / Mkt' },
    isMasterLeg: { label: 'Mtr', tooltip: 'Master Leg' },
    clsType: { label: 'CLS T.', tooltip: 'CLS Type' },
    accrual: { label: 'Accrual' },
  };

  return makeHeaderLabel(index, columnHeaderDefinition[id])();
}
