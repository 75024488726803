import type { ActionCreators } from '@/bootstrap/actions';
import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { Dispatchable, Thunks } from '@/bootstrap/thunks';
import { DefaultingScope, type InterestRateFeatureKey } from '@/neos/business/rfq/models';
import { DefaultingOptions } from '@/neos/business/rfq/rfqActions';
import type { Dispatch } from 'react';

export function getInterestRateCurveModel(
  strategyId: string,
  rfqId: string,
  state: AppState,
  selectors: Selectors,
  actionCreators: ActionCreators,
  thunks: Thunks,
  dispatch: Dispatch<Dispatchable>,
) {
  const interestRateFeature = selectors.getFeature(state.featureState, {
    strategyId,
    type: 'INTEREST_RATE_INDEX_FEATURE',
  });

  const isInterestRateScopeEnabled = selectors.isFeatureToggleEnabled(
    state,
    'neos.els.scope.Interest.Rate.Index.enabled',
  );

  const featureKey: InterestRateFeatureKey = { strategyId, type: 'INTEREST_RATE_INDEX_FEATURE' };
  const products = selectors.getStrategyProducts(state, strategyId, selectors);

  return {
    rateCurve: interestRateFeature?.rateCurve,
    onRateCurveChanged: (newRateCurve: string | undefined) => {
      products.forEach(product => {
        dispatch(thunks.neos.changeElsIsScheduleObsoleteThunk(rfqId, product.uuid, true));
      });
      dispatch(thunks.neos.createChangeFeatureFieldThunk(featureKey, { rateCurve: newRateCurve }));
      const overrideScopes = [
        DefaultingScope.RATE_FIXING_OFFSET,
        DefaultingScope.CONFIRM_MEDIA,
        DefaultingScope.RELATED_EXCHANGE,
        DefaultingScope.SPECIAL_DIVIDENDS,
        DefaultingScope.LOCAL_TAXES,
      ];
      if (isInterestRateScopeEnabled) {
        overrideScopes.push(
          DefaultingScope.RATE_OVERNIGHT,
          DefaultingScope.RATE_TENOR,
          DefaultingScope.CALCULATION_METHOD,
        );
      }
      if (selectors.isElsStrategy(state, strategyId, selectors)) {
        overrideScopes.push(DefaultingScope.RESET_FREQUENCY, DefaultingScope.RATE_RESET);
      }
      if (selectors.isClsStrategy(state, strategyId, selectors)) {
        overrideScopes.push(DefaultingScope.SCHEDULE, DefaultingScope.UNDERLYING);
      }
      if (overrideScopes.length) {
        dispatch(
          actionCreators.neos.createDefaultRfqRequestedAction(
            rfqId,
            new DefaultingOptions({
              overrideScopes,
            }),
          ),
        );
      }
    },
  };
}
