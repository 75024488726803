import type { Thunk } from '@/bootstrap/thunks';
import type { BookingId } from '@/neos/business/neosOnyxModel';

type ThunkProps = {
  strategyId: string;
  legId: string;
  bookingId: BookingId;
};

export function createUpdateLegBookingIdThunk({ strategyId, legId, bookingId }: ThunkProps): Thunk {
  return function updateLegBookingIdThunk(dispatch, getState, { selectors, actionCreators }) {
    const state = getState();

    const isOtc = selectors.isOtcStrategy(state, strategyId, selectors);

    if (isOtc) {
      dispatch(
        actionCreators.neos.legDataCrudActions.update(legId, {
          bookingId,
        }),
      );
    }
  };
}
