import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { connect } from 'react-redux';
import { type MidPricesOwnProps, type MidPricesStateProps, MidPricesStyled } from './MidPrices';
import { getMidPriceModel } from './getMidPricesModel';

export const MidPrices = connect(mapStateToProps)(MidPricesStyled);

function mapStateToProps(
  state: AppState,
  { strategyId, rfqId }: MidPricesOwnProps,
): MidPricesStateProps {
  return getMidPriceModel(state, rfqId, strategyId, selectors);
}
