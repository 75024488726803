import type {
  RequestAllBlotterPreconfAction,
  RequestBlotterPreconfAction,
} from './blotterPreconfActions';

export function createRequestAllBlotterPreconfAction(): RequestAllBlotterPreconfAction {
  return {
    type: 'REQUEST_ALL_BLOTTER_PRECONF',
  };
}

export function createRequestBlotterPreconfAction(rfqId: string): RequestBlotterPreconfAction {
  return {
    type: 'REQUEST_BLOTTER_PRECONF',
    rfqId,
  };
}
