import type { Dispatchable, Thunk } from '@/bootstrap/thunks';
import type { ActionContainer } from '@/util/actionContainer';
import { flatMap } from 'lodash';
import { DefaultingScope } from '../neosModel';
import { DefaultingOptions } from '../rfq/rfqActions';

export function createFinalizeTransactionsLoadThunk(
  rfqIds: string[],
  shouldDisplayTab: boolean,
): Thunk {
  return function finalizeTransactionsLoadThunk(
    dispatch,
    getState,
    {
      selectors: { getActorsSetup, getRfqData, getSalesValo },
      actionCreators: {
        neos: {
          createLoadVersionsRequestedAction,
          createContactsRequestedAction,
          createRequestSimilarActivitiesAction,
          createDefaultRfqRequestedAction,
        },
      },
      thunks: {
        neos: {
          createErrorsRemovedThunk,
          createRequestMissingUnderlyingsThunk,
          createEnsureUniqueCounterpartsLoadedThunk,
          createAutoSelectDeltaAdjOrRefTabThunk,
          createKeepSavedGlobalDeltaThunk,
          createFetchCurvesListBasedOnCurrencyThunk,
        },
      },
    },
  ) {
    const state = getState();
    const postActions: ActionContainer[] = [];
    for (const rfqId of rfqIds) {
      const actorsSetup = getActorsSetup(state, rfqId)!;

      postActions.push(createLoadVersionsRequestedAction(rfqId));

      if (actorsSetup?.salesValoId && actorsSetup.counterpartId) {
        const salesUser = getSalesValo(state.referenceData, actorsSetup.salesValoId);

        if (salesUser) {
          postActions.push(
            createContactsRequestedAction(rfqId, actorsSetup.counterpartId, salesUser.email),
          );
        }
      }
      const { strategyIds } = getRfqData(state, rfqId);
      dispatch(
        createErrorsRemovedThunk(rfqId),
        createAutoSelectDeltaAdjOrRefTabThunk(rfqId),
        createFetchCurvesListBasedOnCurrencyThunk(rfqId, strategyIds),
      );
    }

    const chainOptions = {
      success: {
        dispatchables: flatMap(rfqIds, rfqId => {
          const dispatchables: Dispatchable[] = [createKeepSavedGlobalDeltaThunk(rfqId)];

          if (shouldDisplayTab) {
            dispatchables.push(createRequestSimilarActivitiesAction(rfqId));
          }

          dispatchables.push(
            createDefaultRfqRequestedAction(
              rfqId,
              new DefaultingOptions({
                enrichScopes: [DefaultingScope.TRADED_AWAY_FEEDBACK],
              }),
            ),
          );
          return dispatchables;
        }),
      },
    };

    dispatch(
      createEnsureUniqueCounterpartsLoadedThunk(rfqIds),
      createRequestMissingUnderlyingsThunk(rfqIds, chainOptions),
      postActions,
    );
  };
}
