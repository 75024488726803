import type { Thunk } from '@/bootstrap/thunks';
import { DefaultingScope } from '../../neosModel';
import { DefaultingOptions } from '../rfqActions';

export function createRfqCustomOptionsAddLegsThunk(rfqId: string, strategyId: string): Thunk {
  return function createRfqCustomOptionsAddLegsThunk(
    dispatch,
    _,
    {
      thunks: {
        neos: { createRfqAddLegsThunk },
      },
      actionCreators: {
        neos: { createDefaultRfqRequestedAction },
      },
    },
  ) {
    dispatch(createRfqAddLegsThunk(strategyId));

    const enrichScopes = [DefaultingScope.UNITS];
    const options = new DefaultingOptions({
      overrideScopes: [DefaultingScope.UNDERLYING],
      enrichScopes,
    });
    dispatch(createDefaultRfqRequestedAction(rfqId, options));
  };
}
