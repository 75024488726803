import { ScheduleSelectors } from '@/neos/business/rfq/strategy/feature/schedule/ScheduleSelectors';
import type { StateMap } from '@/util/collectionHelper';
import { featureDomain } from './featureDomain';
import type { Feature, FeatureKey } from './featureModel';

const crudSelectors = featureDomain.selectors;

export const featureSelectors = {
  getFeature,
  getStrategyFeatures,
  strategyHasFeature,
  ...ScheduleSelectors,
};

function getFeature<T extends FeatureKey>(
  state: StateMap<Feature>,
  featureKey: T,
): Extract<Feature, T> | undefined {
  const features = crudSelectors.selectObjects(state, featureKey);
  if (!features || !features.length) {
    return undefined;
  }
  const feature = features[0];
  if (feature.type !== featureKey.type) {
    throw Error(
      `feature { strategyId: '${feature.strategyId}', type: '${feature.type}'} found to have type: ${featureKey.type}`,
    );
  }

  return feature as Extract<Feature, T>;
}

function getStrategyFeatures(state: StateMap<Feature>, strategyId: string): Feature[] {
  return crudSelectors.selectObjects(state, { strategyId });
}

function strategyHasFeature(state: StateMap<Feature>, featureKey: FeatureKey): boolean {
  return getFeature(state, featureKey) !== undefined;
}
