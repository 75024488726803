import type { Dispatchable, Thunk } from '@/bootstrap/thunks.ts';
import {
  assertProductIsElsBasket,
  type BasketCompositionDetails,
  type BasketUnderlying,
} from '@/neos/business/neosModel.ts';
import type { ExecFees } from '@/neos/business/rfq/strategy/leg/product/productOnyxModel.ts';
import { getBiggestIndexOfBasketComposition } from '@/neos/business/rfq/strategy/leg/product/els/thunks/getBiggestIndexOfBasketComposition.ts';

export function createAddNewElsBasketCompositionItemThunk(productId: string): Thunk {
  return function addNewElsBasketCompositionItemThunk(
    dispatch,
    getState,
    { actionCreators, selectors },
  ) {
    const state = getState();
    const product = selectors.getProduct(state, productId);
    assertProductIsElsBasket(product);

    const { basketUnderlying } = product;

    const biggestIndex = getBiggestIndexOfBasketComposition(basketUnderlying.basketComposition);

    const newLine: BasketCompositionDetails = {
      containerIndex: biggestIndex + 1,
      nominal: undefined,
      quantity: undefined,
      underlyingId: undefined,
      weight: undefined,
      ...getDefaultExecFees(basketUnderlying),
    };

    const dispatchables: Dispatchable[] = [
      actionCreators.neos.productCrudActions.update(product.uuid, {
        basketUnderlying: {
          ...product.basketUnderlying,
          basketComposition: basketUnderlying.basketComposition.concat(newLine),
        },
      }),
    ];

    dispatch(dispatchables);
  };
}

function getDefaultExecFees(
  basketUnderlying: BasketUnderlying,
): Pick<BasketCompositionDetails, 'execFeesIn' | 'execFeesOut'> {
  const { execFees } = basketUnderlying;
  const firstItem = basketUnderlying.basketComposition.at(0);

  const defaultExecFeesIn: ExecFees = {
    value: undefined,
    type: firstItem?.execFeesIn?.type ?? 'BIPS',
    unit: firstItem?.execFeesIn?.unit ?? 'bp',
  };

  const defaultExecFeesOut: ExecFees = {
    value: undefined,
    type: firstItem?.execFeesOut?.type ?? 'BIPS',
    unit: firstItem?.execFeesOut?.unit ?? 'bp',
  };

  switch (execFees) {
    case 'NONE':
      return {
        execFeesIn: undefined,
        execFeesOut: undefined,
      };
    case 'IN':
      return {
        execFeesIn: defaultExecFeesIn,
        execFeesOut: undefined,
      };
    case 'OUT':
      return {
        execFeesIn: undefined,
        execFeesOut: defaultExecFeesOut,
      };
    case 'IN_AND_OUT':
      return {
        execFeesIn: defaultExecFeesIn,
        execFeesOut: defaultExecFeesOut,
      };
  }
}
