import type { FromOnyxMappers } from '@/neos/business/mappers';
import { mapFromOnyxPeriod } from '@/neos/business/rfq/strategy/leg/product/mappers/specificProductMappers/mapFromOnyxPeriod';
import { convertNullToUndefined } from '@/util/undefinedAndNull/convertNullToUndefined';
import type { AsianOption } from '../../productModel';
import type { OnyxSingleUnderlyingProduct } from '../../productOnyxModel';

export function mapFromOnyxProductAsianOption(
  legId: string,
  onyxProduct: OnyxSingleUnderlyingProduct,
  productId: string,
  mappers: FromOnyxMappers,
): AsianOption {
  const { strike, strikeUnit } = mappers.mapFromOnyxStrike(onyxProduct);
  return {
    subFamily: 'ASIAN_OPTION',
    strike,
    strikeUnit,
    style: convertNullToUndefined(onyxProduct.optionStyle),
    type: onyxProduct.optionType || undefined,
    flex: onyxProduct.optionFlexType || undefined,
    ...mappers.mapFromOnyxProductCommon(legId, onyxProduct, productId),
    ...mappers.mapFromOnyxNegotiation(onyxProduct.negotiation),
    ...mappers.mapFromSingleDerivativeOnyxProduct(onyxProduct, mappers),
    hasCustomUnderlying: false,
    period: mapFromOnyxPeriod(onyxProduct.period),
    strikeType: onyxProduct.strikeType ?? undefined,
    averageDisruptionDate: onyxProduct.averageDisruptionDate ?? undefined,
    businessDayConvention: onyxProduct.businessDayConvention ?? undefined,
  };
}
