import type { Thunk } from '@/bootstrap/thunks';

export function createRequestTransactionSaveWithVersionThunk(
  rfqId: string,
  version: number,
): Thunk {
  return function requestTransactionSaveWithVersionThunk(
    dispatch,
    _,
    {
      actionCreators: {
        neos: { rfqDataCrudActions, rfqUiCrudActions },
      },
      thunks: {
        neos: { createRequestTransactionSaveThunk },
      },
    },
  ) {
    dispatch(
      rfqDataCrudActions.update(rfqId, { version }),
      rfqUiCrudActions.update(rfqId, { rfqOutdatedModal: { action: 'HIDE' } }),
      createRequestTransactionSaveThunk(rfqId),
    );
  };
}
