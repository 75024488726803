import type { StatusSubStatusLabel } from '@/neos/business/referenceData/status/statusLabelModel';
import { getStatusAndSubStatusFromLabel } from '@/neos/business/referenceData/status/statusSelector';
import { getStatusCellClass } from './getStatusCellClass';

export const StatusFilterCellRender = (params: any, isTrader: boolean) => {
  if (params.value === '(Select All)') {
    return <span>(Select All)</span>;
  }
  const { status, subStatus } = getStatusAndSubStatusFromLabel(
    params.value as StatusSubStatusLabel,
  );
  const classNames = getStatusCellClass(isTrader)({
    data: { status, subStatus },
  });
  return (
    <div
      className={classNames as string}
      style={{
        height: '23px',
        width: '1000px',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '5px',
      }}
    >
      {params.value}
    </div>
  );
};
