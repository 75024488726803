import type { Thunk } from '@/bootstrap/thunks';
import { cloneDeep } from 'lodash';

export function createApplyRfqListedPreallocationsToDeltaThunk(rfqId: string): Thunk {
  return function applyRfqListedPreallocationsToDeltaThunk(
    dispatch,
    getState,
    {
      selectors: { getRfqData },
      actionCreators: {
        neos: { rfqDataCrudActions },
      },
    },
  ) {
    const state = getState();
    const { listedPreAllocs } = getRfqData(state, rfqId);
    return dispatch(
      rfqDataCrudActions.update(rfqId, { deltaListedPreAllocs: cloneDeep(listedPreAllocs) }),
    );
  };
}
