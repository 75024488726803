import type { Quote, RecomputeScope } from '@/neos/business/rfq/models';
import type { ActionContainer } from '@/util/actionContainer';
import { quoteActionCreators } from '../quotes/quoteActionCreator';
import { createActionWithRecompute } from './actionWithRecompute';

export function createRfqQuoteChangedAction(
  rfqId: string,
  scope: RecomputeScope,
  quotePatch: Partial<Quote>,
): ActionContainer {
  return createActionWithRecompute(rfqId, scope, [
    quoteActionCreators.quoteCrudActions.update(rfqId, quotePatch),
  ]);
}
