import type {
  DefaultProductValuesForStrategy,
  Product,
} from '../../../../../../../../neos/business/neosModel';
import type { StrategyProductDefaulters } from './getSpecificDefaultedProduct';

export function getDefaultedProductUsingDefaulters(
  currentProduct: Product,
  defaultValues: DefaultProductValuesForStrategy,
  defaulters: StrategyProductDefaulters,
): Product {
  switch (defaultValues.discriminator) {
    case 'OPTION':
      return defaulters.getDefaultedOptionProduct(currentProduct, defaultValues);
    case 'OPTION_ON_FUTURE':
      return defaulters.getDefaultedOptionOnFutureProduct(currentProduct, defaultValues);
    case 'ASIAN_OPTION':
      return defaulters.getDefaultedAsianOptionProduct(currentProduct, defaultValues);
    case 'ASIAN_SPREAD_OPTION':
      return defaulters.getDefaultedAsianSpreadOptionProduct(currentProduct, defaultValues);
    case 'EUROPEAN_SPREAD_OPTION':
      return defaulters.getDefaultedEuropeanOptionProduct(currentProduct, defaultValues);
    case 'FIXED_STRIKE_FVA':
      return defaulters.getDefaultedFvaFixedK(currentProduct, defaultValues);
    case 'FLOATING_STRIKE_FVA':
      return defaulters.getDefaultedFvaFloatingK(currentProduct, defaultValues);
    case 'FUTURE':
      return defaulters.getDefaultedFutureProduct(currentProduct, defaultValues);
    case 'TOTAL_RETURN_FUTURE':
      return defaulters.getDefaultedTotalReturnFutureProduct(currentProduct, defaultValues);
    case 'DIVIDEND_FUTURE':
      return defaulters.getDefaultedDividendFutureProduct(currentProduct, defaultValues);
    case 'VAR_SWAP':
    case 'VOL_SWAP':
    case 'CROSS_CORRIDOR_VAR_SWAP':
    case 'CROSS_CORRIDOR_VOL_SWAP':
    case 'MONO_CORRIDOR_VAR_SWAP':
    case 'MONO_CORRIDOR_VOL_SWAP':
      return defaulters.getDefaultedVSwapProduct(
        currentProduct,
        defaultValues,
        defaultValues.discriminator,
      );
    case 'ELS':
    case 'PRS':
    case 'TRS':
      return defaulters.getDefaultedElsProduct(
        currentProduct,
        defaultValues,
        defaultValues.discriminator,
      );
    case 'CLS':
      return defaulters.getDefaultedClsProduct(currentProduct, defaultValues);
    case 'STOCK':
      return defaulters.getDefaultedStockProduct(currentProduct, defaultValues);
    case 'DIV_SWAP':
      return defaulters.getDefaultedDivSwapProduct(currentProduct, defaultValues);
    case 'SWAPTION':
      return defaulters.getDefaultedSwaptionProduct(currentProduct, defaultValues);
    case 'CORREL_SWAP':
    case 'FX_OPTION':
    case 'FX_VOL_SWAP':
    case 'FX_VAR_SWAP':
    case 'BASKET_OPTION':
    case 'FX_FVA':
    case 'CUSTOM':
      return defaulters.getDefaultedCustomProduct(
        currentProduct,
        defaultValues,
        defaultValues.discriminator,
      );
    case 'FX_FORWARD':
      return defaulters.getDefaultedFxForward(currentProduct, defaultValues);
    case 'FX_DIGITAL_OPTION':
      return defaulters.getDefaultedFxDigitalOptionProduct(currentProduct, defaultValues);
    case 'DIGITAL_OPTION':
      return defaulters.getDefaultedDigitalOptionProduct(currentProduct, defaultValues);
    default:
      throw Error(`Unhandled product sub family found: ${defaultValues.discriminator}`);
  }
}
