import { NeosTooltip } from '@/neos/components/share/tooltip/NeosTooltip';
import type { FC } from 'react';
import { Tooltip } from 'react-bootstrap';

import styles from './QuoteStatus.module.scss';

export interface QuoteStatusOwnProps {
  rfqId: string;
}

export interface QuoteStatusMapStateToProps {
  color?: 'error' | 'warning';
  quoteSubStatus?: string;
  quoteStatus?: string;
}

export type QuoteStatusProps = QuoteStatusOwnProps & QuoteStatusMapStateToProps;

export const QuoteStatusComponent: FC<QuoteStatusProps> = ({
  color,
  quoteSubStatus,
  quoteStatus,
}) => {
  if (!quoteSubStatus) {
    return null;
  }

  return (
    <NeosTooltip
      placement="top"
      overlay={<Tooltip id="save-rfq">{`${quoteStatus} / ${quoteSubStatus}`}</Tooltip>}
    >
      <i className={`${color ? styles[color] : ''} icon`}>circle</i>
    </NeosTooltip>
  );
};
