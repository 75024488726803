import { DefaultingOptions } from '../rfqActions';

import type { ChainOptions, RetryOnErrorOptions } from '@/neos/business/neosActions';
import { DefaultingScope } from '../models';
import { createDefaultRfqRequestedAction } from './defaultRfqRequestedAction';

export function createDefaultConfirmationMediaRequestedAction(
  rfqId: string,
  chainOptions?: ChainOptions,
) {
  const options = new DefaultingOptions({
    enrichScopes: [
      DefaultingScope.CONFIRM_MEDIA,
      DefaultingScope.IA_VALUE_DATE,
      DefaultingScope.RELATED_EXCHANGE,
      DefaultingScope.SPECIAL_DIVIDENDS,
      DefaultingScope.LOCAL_TAXES,
    ],
  });
  const retryOptions: RetryOnErrorOptions = {
    retries: 1,
  };
  return createDefaultRfqRequestedAction(rfqId, options, chainOptions, retryOptions);
}
