import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import type { RfqUi } from '@/neos/business/ui/rfq/rfqUiModel';
import type { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  PredealChecksTableComponent,
  type PredealChecksTableDispatchProps,
  type PredealChecksTableOwnProps,
  type PredealChecksTableStateProps,
} from './PredealChecksTable';
import { getPredealChecksTableModel } from './getPredealChecksTableModel';

export const PredealChecksTable: FC<PredealChecksTableOwnProps> = ownProps => {
  const stateProps = useSelector<AppState, PredealChecksTableStateProps>(state => ({
    model: getPredealChecksTableModel(state, ownProps.rfqId, ownProps.level, selectors),
  }));
  const dispatch = useDispatch<ExtendedDispatch>();
  const dispatchProps = mapDispatchToProps(dispatch, ownProps);

  return <PredealChecksTableComponent {...ownProps} {...stateProps} {...dispatchProps} />;
};

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId, level }: PredealChecksTableOwnProps,
): PredealChecksTableDispatchProps {
  return {
    onExpandOrCollapse(isCollapsed: boolean) {
      const patchKey: keyof Pick<
        RfqUi,
        'areDeltaPredealChecksCollapsed' | 'areMarketPredealChecksCollapsed'
      > =
        level === 'ALLOCATION'
          ? 'areMarketPredealChecksCollapsed'
          : 'areDeltaPredealChecksCollapsed';
      dispatch(
        neosActionCreators.rfqUiCrudActions.update(rfqId, {
          [patchKey]: isCollapsed,
        }),
      );
    },
  };
}
