import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { services } from '@/bootstrap/services';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { getCancelledAllocationsModel } from '@/neos/components/rfq/postNego/allocs/getCancelledAllocationsModel';
import { useDispatch } from 'react-redux';
import { Portfolio } from '../../../strategyDetails/Portfolio/Portfolio';
import { AllocationLegSize } from '../allocInfo/AllocationLegSize';
import { ClientWay } from '../allocInfo/ClientWay';
import { Underlying } from '../allocInfo/Underlying';
import { DeltaStockAllocation } from './DeltaStockAllocation';
import styles from './DeltaStockAllocations.module.scss';
import { getDeltaStockQuantityStyleNameAndTooltipMessage } from './DeltaStockAllocations.utils';

export interface DeltaStockAllocationsOwnProps {
  rfqId: string;
  strategyId: string;
  legId: string;
}

export const DeltaStockAllocations = ({
  rfqId,
  strategyId,
  legId,
}: DeltaStockAllocationsOwnProps) => {
  const dispatch = useDispatch();

  const allocations = useAppSelector(state =>
    selectors.getDeltaStockListedAllocationsByLegId(state, legId),
  );
  const cancelledAllocationsModel = getCancelledAllocationsModel(allocations);

  function onDeltaStockAllocationAdded() {
    const allocationId = services.idCreator.createId();
    dispatch(
      neosActionCreators.deltaStockListedAllocationCrudActions.insert(
        {
          allocationId,
          legId,
          rfqId,
        },
        {
          legId,
          uuid: allocationId,
          counterpartId: undefined,
          comment: undefined,
          numberOfLots: undefined,
          broker: undefined,
          externalReferenceId: undefined,
          isCancelled: false,
        },
      ),
    );
  }

  const displayedTooltip = useAppSelector(state =>
    getDeltaStockQuantityStyleNameAndTooltipMessage(state, legId, selectors),
  );

  return (
    <div
      className={`${styles['leg-description-deltaStock']}`}
      data-e2e="neos-postNego-legDetails-deltaStock"
    >
      <div
        className={`${styles['leg-info']} mb-2 text-nowrap`}
        data-e2e="neos-postNego-leg-description-deltaStock"
      >
        <AllocationLegSize
          legId={legId}
          strategyId={strategyId}
          displayedTooltip={displayedTooltip}
        />
        <ClientWay rfqId={rfqId} strategyId={strategyId} legId={legId} />
        <Underlying rfqId={rfqId} strategyId={strategyId} legId={legId} />
        <div>
          <Portfolio rfqId={rfqId} strategyId={strategyId} isComponentInLegDescription={true} />
        </div>
      </div>

      <div className={`${styles['alloc-info']}`} style={{ marginBottom: '2px' }}>
        <button
          className="btn btn-icon btn-flat-primary"
          onClick={() => onDeltaStockAllocationAdded()}
          data-e2e="add-deltaStock-alloc"
        >
          <i className="icon icon-md">add</i>
        </button>
        <label>Counterparties</label>
        <label>Al. Size</label>
        <label>Price</label>
        <label>Booking Id</label>
        <label>Comment</label>
      </div>
      {cancelledAllocationsModel.activeAllocations.map(allocation => (
        <DeltaStockAllocation
          key={allocation.uuid}
          allocation={allocation}
          rfqId={rfqId}
          strategyId={strategyId}
          legId={legId}
        />
      ))}
      <div style={{ cursor: 'not-allowed' }}>
        <div className="opacity-50" style={{ pointerEvents: 'none' }}>
          {cancelledAllocationsModel.cancelledAllocations.map(allocation => (
            <DeltaStockAllocation
              key={allocation.uuid}
              allocation={allocation}
              rfqId={rfqId}
              strategyId={strategyId}
              legId={legId}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
