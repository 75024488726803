import type { Thunk } from '@/bootstrap/thunks';
import type { PreConfirmationStep } from './PreConfirmationStepModel';

export function createPreConfirmationNotificationReceivedThunk(
  rfqId: string,
  preconfirmationStep: PreConfirmationStep,
): Thunk {
  return function preConfirmationNotificationReceivedThunk(
    dispatch,
    getState,
    {
      actionCreators: {
        neos: { createPreConfirmationStepsReceivedAction },
      },
      selectors: { tabExists },
    },
  ) {
    const state = getState();
    const rfqIsOpen = tabExists(state.ui.tabManagement, rfqId);
    if (!rfqIsOpen) {
      return;
    }

    dispatch(createPreConfirmationStepsReceivedAction(rfqId, [preconfirmationStep]));
  };
}
