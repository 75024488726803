import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { connect } from 'react-redux';
import {
  type PttPredealChecksOwnProps,
  type PttPredealChecksStateProps,
  PttPredealChecksWithStyle,
} from './PttPredealChecks';
import { getPttPredealChecksModel } from './getPttPredealChecksModel';

export const PttPredealChecks = connect(mapStateToProps)(PttPredealChecksWithStyle);

function mapStateToProps(
  state: AppState,
  { rfqId }: PttPredealChecksOwnProps,
): PttPredealChecksStateProps {
  return { ...getPttPredealChecksModel(state, rfqId, selectors) };
}
