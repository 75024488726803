import { orderDomain } from './orderDataDomain';
import type { OrderData } from './orderDataModel';

export const orderCrudActionCreators = orderDomain.actions;
export const orderActionDataCreators = {
  createOrdersDataReceived,
  createOrdersDataDeleted,
  orderCrudActionCreators,
};

function createOrdersDataReceived(rfqId: string, orders: OrderData[]) {
  return orderCrudActionCreators.upsertMany(
    orders.map(o => ({
      id: { orderId: o.uuid, rfqId },
      value: o,
    })),
  );
}

function createOrdersDataDeleted(rfqId: string) {
  return orderCrudActionCreators.deleteByPartialKey({ rfqId });
}
