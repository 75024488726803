export enum Key {
  c = 'c',
  l = 'l',
  m = 'm',
  s = 's',
  e = 'e',
  u = 'u',
  delete = 'Delete',
  up = 'ArrowUp',
  right = 'ArrowRight',
  down = 'ArrowDown',
  left = 'ArrowLeft',
}

export enum Modifier {
  ctrl = 'ctrlKey',
}

export interface KeyCombination {
  key: Key;
  modifier?: Modifier;
}

export type KeyboardCallback = (event: KeyboardEvent) => void;

export interface Options {
  useCapture?: boolean;
}

const defaultOptions: Options = {
  useCapture: false,
};

// Parameters are valid, comprehensive options (merged with defaults)
type Parameters = Options;

export interface Subscription {
  unsubscribe(): void;
}

export function reactToKeyCombination(
  { key, modifier }: KeyCombination,
  callback: KeyboardCallback,
  options?: Options,
): Subscription {
  const { useCapture }: Parameters = mergeOptionsWithDefaults(defaultOptions, options);
  const conditionalCallback = (event: KeyboardEvent) => {
    if (expectationOnKeyIsMet(key, event) && expectationOnModifierIsMet(modifier, event)) {
      event.stopPropagation();
      callback(event);
    }
  };
  document.addEventListener('keydown', conditionalCallback, useCapture);
  return {
    unsubscribe: () => document.removeEventListener('keydown', conditionalCallback, useCapture),
  };
}

function expectationOnKeyIsMet(key: Key, event: KeyboardEvent): boolean {
  return event.key === key.toString();
}

function expectationOnModifierIsMet(modifier: Modifier | undefined, event: KeyboardEvent): boolean {
  return modifier ? event[modifier] : true;
}

function mergeOptionsWithDefaults<T extends Object>(defaults: T, t: T | undefined): T {
  const result = {} as T;
  Object.keys(defaults).forEach((key: string) => {
    (result as any)[key] = (t && (t as any)[key] !== undefined) || (defaults as any)[key];
  });
  return result;
}
