import type { FC } from 'react';
import { Shortcuts } from '../shortcuts';
import { Strategies } from '../strategies/Strategies.container';
import { Reference } from '../reference/Reference.container';
import { StrategyDetails } from '../strategyDetails/StrategyDetails.container';
import { getBlockingProps } from '../blockingProps';
import { Totals } from '../totals/Totals';
import { Summary } from '../summary/Summary.container';
import { IbChat } from '../ibChat/IbChat.container';
import { SimilarActivities } from '../similarActivities/SimilarActivities.container';
import type { NegoModel } from './getNegoModel';
import { RfqComment } from '../comments/rfqComment/RfqComment.container';
import { ClientComment } from '../comments/clientComment/ClientComment.container';
import { StepsCommonInfo } from '../stepsCommonInfo';
import { ClientFeedback } from '@/neos/components/rfq/comments/clientFeedback/ClientFeedback';

import styles from './Nego.module.scss';

export type NegoPropsFromState = NegoModel;

export interface NegoOwnProps {
  rfqId: string;
}

export const NegoWithStyle: FC<NegoOwnProps & NegoPropsFromState> = ({
  rfqId,
  selectedStrategyId,
  isLoading,
  isIbChatSectionShown,
  isQuickButtonsSectionShown,
  isReadonlyRfq,
  areSummaryAndTotalsShown,
  isClientCommentShown,
}) => {
  return (
    <div className={`${styles['neos-content']}`}>
      <div
        className={`${styles['neos-nego-content']}`}
        data-e2e="neos-rfq"
        {...getBlockingProps(isLoading)}
      >
        <StepsCommonInfo rfqId={rfqId} />
        <div className={`${styles['nego-body']}`}>
          {!isReadonlyRfq && (isIbChatSectionShown || isQuickButtonsSectionShown) && (
            <section className={`${styles['ib-chat-and-shortcuts']}`}>
              {isIbChatSectionShown && <IbChat rfqId={rfqId} />}
              {isQuickButtonsSectionShown && <Shortcuts rfqId={rfqId} />}
            </section>
          )}
          {areSummaryAndTotalsShown && (
            <>
              <Summary rfqId={rfqId} />
              <Totals rfqId={rfqId} />
            </>
          )}
          <Strategies rfqId={rfqId} />
          {selectedStrategyId !== undefined ? (
            <StrategyDetails rfqId={rfqId} strategyId={selectedStrategyId} />
          ) : undefined}
          <Reference rfqId={rfqId} />
          <section className={`${styles['comments']}`}>
            <ClientFeedback rfqId={rfqId} />
            <RfqComment rfqId={rfqId} />
            {isClientCommentShown && <ClientComment rfqId={rfqId} />}
          </section>
        </div>
      </div>
      <SimilarActivities rfqId={rfqId} />
    </div>
  );
};
