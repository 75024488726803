import type { PropsWithChildren } from 'react';

export const If = (props: PropsWithChildren<{ condition: boolean; fallback?: JSX.Element }>) => {
  if (props.children && props.condition) {
    return <>{props.children}</>;
  }
  if (props.fallback) {
    return <>{props.fallback}</>;
  }
  return null;
};
