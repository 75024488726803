import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { Observable } from 'rxjs';
import type { RetrieveUnderlyingOptionInfoApi } from './underlyingInfoEpics';
import type {
  OnyxUnderlyingDividendFutureInfo,
  OnyxUnderlyingFutureInfo,
  OnyxUnderlyingOptionInfo,
  OnyxUnderlyingTotalReturnFutureInfo,
} from './underlyingInfoOnyxModel';

export const createRetrieveUnderlyingOptionInfoApi = (
  http: SgmeHttp,
): RetrieveUnderlyingOptionInfoApi => ({
  retrieveUnderlyingOptionInfos(underlyingIds: string[]): Observable<OnyxUnderlyingOptionInfo[]> {
    const url: string = `api/referential/underlyings/optionInfos?ids=${underlyingIds}`;
    return http.get<OnyxUnderlyingOptionInfo[]>({ url });
  },
  retrieveUnderlyingFutureInfos(underlyingIds: string[]): Observable<OnyxUnderlyingFutureInfo[]> {
    const url: string = `api/referential/underlyings/futureInfos?ids=${underlyingIds}`;
    return http.get<OnyxUnderlyingFutureInfo[]>({ url });
  },
  retrieveUnderlyingDividendFutureInfos(
    underlyingIds: string[],
  ): Observable<OnyxUnderlyingDividendFutureInfo[]> {
    const url: string = `api/referential/underlyings/dividendFutureInfos?ids=${underlyingIds}`;
    return http.get<OnyxUnderlyingDividendFutureInfo[]>({ url });
  },
  retrieveUnderlyingTotalReturnFutureInfos(
    underlyingIds: string[],
  ): Observable<OnyxUnderlyingTotalReturnFutureInfo[]> {
    const url: string = `api/referential/underlyings/totalReturnFutureInfos?ids=${underlyingIds}`;
    return http.get<OnyxUnderlyingTotalReturnFutureInfo[]>({ url });
  },
  retrieveFutureRefMaturities(underlyingIds: string[]): Observable<Record<string, string[]>> {
    const url = `api/referential/underlyings/refFutureMaturities?ids=${underlyingIds}`;
    return http.get<Record<string, string[]>>({ url });
  },
});
