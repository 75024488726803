import type { PreconfState } from './prefonfirmationPreferencesReducer';

export const preconfirmationUserPrefSelectors = {
  getSavedPreconfGroups,
  getLoadingPreconfGroups,
};

function getSavedPreconfGroups(state: PreconfState): string[] {
  return state.preconfSavedGroups;
}

function getLoadingPreconfGroups(state: PreconfState): string[] {
  return state.loadingGroups;
}
