import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';

export function areUnitsDifferent(
  appState: AppState,
  productId: string,
  quoteId: string,
  selectors: Selectors,
): boolean {
  const { salesAskType: type, unit } = selectors.getQuote(appState, quoteId);
  const underlyingUnit = selectors.getUnderlyingUnit(appState, productId, selectors);
  const areValidUnits = underlyingUnit && unit;
  if (areValidUnits) {
    return (
      (type === 'CCY' || type === 'BASIS') && underlyingUnit?.toLowerCase() !== unit?.toLowerCase()
    );
  }
  return false;
}
