import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ToOnyxMappers } from '../mappers';
import { fromExecutionMappers, toExecutionMappers } from './execution/executionMapper';
import type { OrderData } from './orderModel';
import type { OnyxOrder } from './orderOnyxModel';

export const fromOrderMappers = {
  ...fromExecutionMappers,
  mapFromOnyxOrders,
};

export const toOrderMappers = {
  ...toExecutionMappers,
  mapToOnyxOrders,
};

function mapFromOnyxOrders(onyxOrders: OnyxOrder[]): OrderData[] {
  return onyxOrders.map((onyxOrder): OrderData => {
    const { executions, ...order } = onyxOrder;
    return order;
  });
}

function mapToOnyxOrders(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
  toMappers: ToOnyxMappers,
): OnyxOrder[] {
  const orders = selectors.getOrders(state.orderData, rfqId) ?? [];
  return orders.map(onyxOrder => {
    const executions = selectors.executionSelectors.selectObjects(state.execution, {
      orderId: onyxOrder.uuid,
    });
    return {
      ...onyxOrder,
      executions: toMappers.mapToOnyxExecutions(executions),
    };
  });
}
