import { crudDomain } from '@/util/crudUtils';
import type { Destination } from '../../neosModel';
import type {
  PreconfirmationEmailPreview,
  PreconfirmationEmailPreviewKey,
} from './preconfirmationEmailPreviewModel';

const compositeIdSeparator = ' | ';

function getPreconfirmationEmailPreviewId({
  rfqId,
  destination,
}: PreconfirmationEmailPreviewKey): string {
  return `${rfqId}${compositeIdSeparator}${destination}`;
}

function getPreconfirmationEmailPreviewKey(id: string): PreconfirmationEmailPreviewKey {
  const [rfqId, destination] = id.split(compositeIdSeparator);
  return { rfqId, destination: destination as Destination };
}

export const preconfirmationEmailPreviewDomain = crudDomain<
  PreconfirmationEmailPreview,
  PreconfirmationEmailPreviewKey
>('preconfirmationEmailPreview', {
  keyToString: getPreconfirmationEmailPreviewId,
  stringToKey: getPreconfirmationEmailPreviewKey,
});
