import type {
  DefaultProductValuesForStrategy,
  Product,
} from '../../../../../../../../../neos/business/neosModel';
import type { Swaption } from '../../../../product/productModel';
import {
  getCommonPart,
  getDerivativePart,
  getOtcNegotiationPart,
} from './getDefaultedPartialProduct';

export function getDefaultedSwaptionProduct(
  currentProduct: Product,
  defaultValues: DefaultProductValuesForStrategy,
): Swaption {
  return {
    subFamily: 'SWAPTION',
    hasCustomUnderlying: false,
    description: currentProduct.subFamily === 'SWAPTION' ? currentProduct.description : undefined,
    ...getCommonPart(currentProduct, defaultValues),
    ...getOtcNegotiationPart(),
    ...getDerivativePart(currentProduct, defaultValues.maturity, defaultValues.underlying),
  };
}
