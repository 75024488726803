import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { MapToOnyxCommonAllocationResult } from '@/neos/business/rfq/strategy/leg/legMapper';
import type {
  OnyxClearingInfo,
  OnyxCommissionInfo,
  OnyxListedAllocation,
} from '../../../../../../neos/business/neosOnyxModel';
import type { ListedAllocation } from './listedAllocationModel';

export const toListedAllocationMappers = {
  mapToOnyxLegListedAllocations,
};

export const fromListedAllocationMappers = {
  mapFromOnyxListedAllocation,
};

function mapToOnyxLegListedAllocations(
  state: AppState,
  rfqId: string,
  legId: string,
  selectors: Selectors,
): MapToOnyxCommonAllocationResult<OnyxListedAllocation> {
  const legListedAllocations = selectors.listedAllocationSelectors.selectObjects(
    state.listedAllocationState,
    { legId },
  );

  const counterpartId = selectors.getSelectedCounterpartId(state, rfqId);

  const activeAllocations = legListedAllocations.filter(allocation => !allocation.isCancelled);
  const cancelledAllocations = legListedAllocations.filter(allocation => allocation.isCancelled);

  return {
    activeOnyxAllocations: innerMapToOnyxLegListedAllocations(activeAllocations, counterpartId),
    cancelledOnyxAllocations: innerMapToOnyxLegListedAllocations(
      cancelledAllocations,
      counterpartId,
    ),
  };
}

function innerMapToOnyxLegListedAllocations(
  allocations: ListedAllocation[],
  counterpartId: number | undefined,
): OnyxListedAllocation[] {
  return allocations.map(
    ({
      uuid,
      executionId,
      numberOfLots,
      clearerAccount,
      commission,
      commissionType,
      broker,
      externalReferenceId,
    }) => {
      const clearingInfo: OnyxClearingInfo | undefined = clearerAccount
        ? { clearerAccount }
        : undefined;

      const commissionInfo: OnyxCommissionInfo | undefined =
        commission || commissionType
          ? {
              commission,
              commissionType,
            }
          : undefined;
      return {
        negotiationMode: 'LISTED',
        uuid,
        execUuid: executionId,
        counterparty: counterpartId ? { id: counterpartId } : undefined,
        clearingInfo,
        commissionInfo,
        negotiatedSize:
          numberOfLots !== undefined
            ? {
                numberOfLots,
                sizeType: 'FIXED_QUANTITY',
              }
            : undefined,
        broker,
        externalReferenceId,
      };
    },
  );
}

function mapFromOnyxListedAllocation(
  legId: string,
  {
    uuid,
    execUuid,
    clearingInfo,
    negotiatedSize,
    commissionInfo,
    broker,
    externalReferenceId,
  }: OnyxListedAllocation,
  isCancelled: 'active' | 'cancelled',
): ListedAllocation {
  return {
    uuid,
    isCancelled: isCancelled === 'cancelled',
    clearerAccount: clearingInfo?.clearerAccount,
    legId,
    executionId: execUuid,
    quantity: negotiatedSize?.quantity,
    numberOfLots: negotiatedSize?.numberOfLots,
    commission: commissionInfo?.commission,
    commissionType: commissionInfo?.commissionType,
    broker,
    externalReferenceId,
  };
}
