import type { OnyxRfq } from '@/neos/business/rfq/rfqOnyxModel';
import { isElsOnyxProduct } from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel';
import type { BlotterElsType } from '@/neos/components/share/blotterGrid/possibleBlotterRowModel';

export function getElsType({
  strategies,
  initiatedByTrader,
  internal,
}: OnyxRfq): BlotterElsType | undefined {
  const oneStrategyIsEls = strategies.some(strategy => {
    return strategy.legs.some(leg => {
      const product = leg.product;
      return isElsOnyxProduct(product);
    });
  });

  if (!oneStrategyIsEls) {
    return undefined;
  }

  if (initiatedByTrader && internal) {
    return 'ELS Internal';
  }

  if (initiatedByTrader && !internal) {
    return 'ELS IDB';
  }

  if (!initiatedByTrader && !internal) {
    return 'ELS Sales wf';
  }
}
