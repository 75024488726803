import { hasDiscriminator } from '@/neos/business/rfq/strategy/feature/hasDiscriminator';
import type { WithExtraProperty } from '@/neos/business/rfq/strategy/feature/withExtraProperty';
import type { ForexTypeFeature } from '../../../../../../neos/business/neosModel';
import type {
  OnyxForexType,
  OnyxProductFeaturesFields,
  OnyxSingleUnderlyingProduct,
} from '../../../../../../neos/business/neosOnyxModel';

export function mapFromOnyxFeatureOrExtraFeaturesToForexType(
  {
    extraFeatures,
    forexType: definitionForexType,
    initialFxConstat: definitionInitialFxConstat,
    finalFxConstat: definitionFinalFxConstat,
  }: OnyxProductFeaturesFields &
    Pick<OnyxSingleUnderlyingProduct, 'initialFxConstat' | 'finalFxConstat'>,
  strategyId: string,
): ForexTypeFeature | undefined {
  const mappedForexType: Pick<ForexTypeFeature, 'strategyId' | 'type'> = {
    strategyId,
    type: 'FOREX_TYPE',
  };

  let forexType = definitionForexType ?? undefined;

  if (forexType === undefined && extraFeatures) {
    const forexTypeFeature: OnyxForexType | undefined = extraFeatures.find(
      hasDiscriminator<OnyxForexType>('FOREX_TYPE'),
    );
    if (forexTypeFeature) {
      forexType = forexTypeFeature.forexType ?? undefined;
    }
  }

  const initialForexConstat = definitionInitialFxConstat ?? undefined;
  const finalForexConstat = definitionFinalFxConstat ?? undefined;

  if (
    forexType !== undefined ||
    initialForexConstat !== undefined ||
    finalForexConstat !== undefined
  ) {
    return { ...mappedForexType, forexType, initialForexConstat, finalForexConstat };
  }

  return undefined;
}

export function mapToOnyxForexType(
  feature: WithExtraProperty<ForexTypeFeature>,
): OnyxProductFeaturesFields &
  Pick<OnyxSingleUnderlyingProduct, 'initialFxConstat' | 'finalFxConstat'> {
  const onyxForexType = {
    initialFxConstat: feature.initialForexConstat,
    finalFxConstat: feature.finalForexConstat,
  };
  return feature.isExtra
    ? {
        ...onyxForexType,
        extraFeatures: [
          {
            discriminator: 'FOREX_TYPE',
            forexType: feature.forexType,
          },
        ],
      }
    : {
        ...onyxForexType,
        forexType: feature.forexType,
      };
}
