import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import { useDispatch } from 'react-redux';
import { Key, Keyboard, Modifier } from '../share/keyboardManager';

export function UserPreferencesMenu() {
  const dispatch = useDispatch();
  const showUserPreferencesModal = () =>
    dispatch(neosActionCreators.createUserPreferencesDisplayedBatchAction());

  return (
    <>
      <Keyboard modifier={Modifier.ctrl} code={Key.u} onKeyPress={showUserPreferencesModal} />
      <SimpleNeosTooltip placement="left" id="shortcut-tooltip" message="Settings [Ctrl+u]">
        <button
          className="btn btn-xl btn-flat-primary btn-icon"
          onClick={showUserPreferencesModal}
          data-e2e="neos-user-preferences-cog-button"
        >
          <i className="icon">settings</i>
        </button>
      </SimpleNeosTooltip>
    </>
  );
}
