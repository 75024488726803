import { actionCreators } from '@/bootstrap/actions';
import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { ElsFeaturesContext } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures';
import { ElsBlocField } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/ElsBlocField';
import { Switch } from '@/neos/components/share/switch/Switch';
import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isElsProduct } from '../../../../../../../business/neosModel';

export function LocalTaxes() {
  const { strategyId } = useContext(ElsFeaturesContext);
  const dispatch = useDispatch();
  const product = useSelector((state: AppState) =>
    selectors.getStrategyProduct(state, strategyId, selectors),
  );
  if (!isElsProduct(product)) {
    return null;
  }
  const { uuid, localTaxes } = product;
  const { update: updateProduct } = actionCreators.neos.productCrudActions;
  const onLocalTaxesChange = () => {
    dispatch(updateProduct(uuid, { localTaxes: !localTaxes }));
  };

  return (
    <ElsBlocField
      label="Local Taxes "
      renderInput={readOnly => (
        <Switch
          disabled={readOnly}
          switchClassName="text-center"
          data-e2e="other-section-local-taxes"
          checked={!!localTaxes}
          onChange={() => onLocalTaxesChange()}
        />
      )}
    />
  );
}
