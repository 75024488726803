import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { Way } from '@/neos/business/rfq/rfqData/rfqDataModel.ts';

export interface StrategyModel {
  isMasterStrategy: boolean;
  isSelectedStrategy: boolean;
  isDeltaHedgingStrategy: boolean;
  hasBookingSteps: boolean;
  isNotDeltaOrSyntheticDelta: boolean;
  isReadonlyRfq: boolean;
  isSecondaryRfq: boolean;
  clientWay: Way | undefined;
  isVarVolStrategy: boolean;
}

export function getStrategyModel(
  state: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): StrategyModel {
  const { clientWay } = selectors.getRfqData(state, rfqId);
  const { isMasterStrategy, scope } = selectors.getStrategyData(state, strategyId);
  const isDeltaHedgingStrategy = selectors.isDeltaHedgingStrategy(state, strategyId, selectors);
  const isSelectedStrategy =
    selectors.getSelectedStrategyId(state.ui.selectedStrategy, rfqId) === strategyId;
  const hasBookingSteps = selectors.hasRFQBookingSteps(state, rfqId);
  const isSyntheticDeltaStrategy = scope === 'DELTA_EXCHANGE_OTC';
  const isSecondaryRfq = selectors.isSecondaryRfq(state, rfqId);

  return {
    isReadonlyRfq: selectors.isReadOnlyRfq(state, rfqId),
    isMasterStrategy,
    isSelectedStrategy,
    isDeltaHedgingStrategy,
    hasBookingSteps,
    isNotDeltaOrSyntheticDelta: !isDeltaHedgingStrategy || isSyntheticDeltaStrategy,
    isSecondaryRfq,
    clientWay,
    isVarVolStrategy: selectors.isVarVolDispersionStrategy(state, strategyId, selectors),
  };
}
