import { type HTMLAttributes, useState } from 'react';

export function useLongClick(
  normalPressCallback = () => {
    return;
  },
  longClickCallback = () => {
    return;
  },
  ms = 500,
  isLoading = false,
): HTMLAttributes<unknown> {
  const [click, setClick] = useState<'NORMAL' | 'LONG' | 'NONE'>('NONE');
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [className, setClassName] = useState('');

  const startHandler = () => {
    setClick('NORMAL');
    setTimer(
      setTimeout(() => {
        setClick('LONG');
        if (!isLoading) {
          setClassName('btn-primary');
        }
      }, ms),
    );
  };

  const endHandler = () => {
    if (!isLoading) {
      if (click === 'LONG') {
        longClickCallback();
      } else if (click === 'NORMAL') {
        normalPressCallback();
      }
    }
    reset();
  };

  const reset = () => {
    clearTimeout(timer!);
    setClassName('');
    setClick('NONE');
  };
  return {
    onMouseDown: startHandler,
    onMouseUp: endHandler,
    onMouseLeave: reset,
    onTouchStart: startHandler,
    onTouchEnd: endHandler,
    onKeyDown: ({ key }) =>
      key === 'Escape' ? reset() : key === ' ' && click === 'NONE' ? startHandler() : null,
    onKeyUp: ({ key }) => (key === ' ' ? endHandler() : null),
    className,
  };
}
