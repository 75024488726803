import type { ActionContainer } from '@/util/actionContainer';
import type { FromOnyxMappers } from '../mappers';
import { allocationInstructionDomain } from './allocationInstructionDomain';
import type { OnyxAllocationInstruction } from './allocationInstructionOnyxModel';

export const allocationInstructionActionCreators = {
  allocationInstructionCrudActions: allocationInstructionDomain.actions,
  createAllocationInstructionsReceivedAction,
};

function createAllocationInstructionsReceivedAction(
  onyxInstructions: OnyxAllocationInstruction[],
  mappers: FromOnyxMappers,
): ActionContainer {
  return onyxInstructions.map(instruction =>
    allocationInstructionDomain.actions.upsert(
      { rfqId: instruction.rfqUuid, executionId: instruction.execUuid },
      mappers.mapFromOnyxAllocationInstruction(instruction),
    ),
  );
}
