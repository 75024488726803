import type { Thunk } from '@/bootstrap/thunks';
import { flatMap } from 'lodash';

export function createRfqProceedToLegsToStrategiesThunk(
  rfqId: string,
  strategyToSplitId: string,
): Thunk {
  return function rfqProceedToLegsToStrategiesThunk(
    dispatch,
    getState,
    {
      selectors: { getRfqData, getStrategyData },
      thunks: {
        neos: { createStrategyProceedToLegsToStrategiesThunk },
      },
      actionCreators: {
        neos: { rfqDataCrudActions },
      },
      services: { idCreator },
    },
  ) {
    const state = getState();
    const { strategyIds } = getRfqData(state, rfqId);
    const { legIds } = getStrategyData(state, strategyToSplitId);

    // Generate missing strategies ids for legs transformed to strategies
    const strategiesIdsToAdd = legIds
      .filter((_, index) => index !== 0)
      .map(_ => idCreator.createId());
    const updatedRfqStrategyIds = flatMap(strategyIds, strategyId =>
      strategyId === strategyToSplitId ? [strategyId, ...strategiesIdsToAdd] : [strategyId],
    );

    dispatch(createStrategyProceedToLegsToStrategiesThunk(strategyToSplitId, strategiesIdsToAdd));
    dispatch(rfqDataCrudActions.update(rfqId, { strategyIds: updatedRfqStrategyIds }));
  };
}
