import type { Thunk } from '@/bootstrap/thunks';
import { isDefined } from '@/util/undefinedAndNull/isDefined';
import type { OnyxRfq } from '../rfq/rfqOnyxModel';

export function createRequestBulkCopyTradeRecapToClipboardThunk(rfqIds: string[]): Thunk {
  return function requestBulkCopyTradeRecapToClipboardThunk(
    dispatch,
    getState,
    {
      selectors: { getBlotterRfqById },
      actionCreators: {
        analytics: { createLogAnalyticsAction },
      },
      thunks: { createCopyToClipboardThunk },
    },
  ) {
    const state = getState();
    const payload: string = rfqIds
      .map(rfqId => getBlotterRfqById(state.blotter, rfqId))
      .filter(isDefined)
      .map(({ tradeRecap }: OnyxRfq) => tradeRecap || 'N/A')
      .join('\r\n\r\n');

    dispatch(
      createCopyToClipboardThunk(payload),
      createLogAnalyticsAction('NEOS BLOTTER', 'Copy Trade Recap'),
    );
  };
}
