import type { ThunkEpic } from '@/bootstrap/epics';
import { selectors } from '@/bootstrap/selectors';
import { type Thunks, thunks } from '@/bootstrap/thunks';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { ofType } from 'redux-observable';
import type { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { cancelNegotiationApi } from '../apis/cancelNegotiationApi';
import { wrapInLoadingObservable } from '../epics/wrapInLoadingObservable';
import { type ToOnyxMappers, mappers } from '../mappers';
import type { OnyxError } from '../mappers/error';
import type { OnyxTransaction } from '../neosOnyxModel';

export interface CancelNegotiationApi {
  cancelNegotiation: (transaction: OnyxTransaction) => Observable<OnyxTransaction>;
}

export function getCancelNegotiationEpic(http: SgmeHttp): ThunkEpic {
  const api = cancelNegotiationApi(http);
  return cancelNegotiationEpic(api, mappers.toOnyxMappers, thunks);
}

export function cancelNegotiationEpic(
  api: CancelNegotiationApi,
  toOnyxMappers: ToOnyxMappers,
  { neos: { createNeosCancelRfqThunk, createHandleTransactionCancelErrorThunk } }: Thunks,
): ThunkEpic {
  return (action$, state$) =>
    action$.pipe(
      ofType('CANCEL_NEGOCIATION_REQUESTED'),
      mergeMap(({ rfqId }) => {
        const transaction = toOnyxMappers.mapToOnyxTransaction(
          state$.value,
          rfqId,
          selectors,
          toOnyxMappers,
        );
        return wrapInLoadingObservable({
          tabIds: [rfqId],
          apiObservable: api.cancelNegotiation(transaction),
          onSuccess: (onyxTransaction: OnyxTransaction) =>
            createNeosCancelRfqThunk(onyxTransaction.rfq),
          onError: (error: OnyxError) => createHandleTransactionCancelErrorThunk(error),
        });
      }),
    );
}
