import type { Thunk } from '@/bootstrap/thunks';
import type { OtcAllocation, RfqData } from '@/neos/business/neosModel';
import type { SizeType } from '@/neos/business/neosOnyxModel';
import { getDisplayedSizeTypeByStrategy } from '@/neos/business/neosSelectors/getDisplayedSizeTypeByStrategy';
import type { DisplayNegotiatedSize } from '@/neos/business/ui/strategy/strategyUiModel';
import { sum } from 'lodash';
import { getDisplayedValueAndLabelBaseOnSizeType } from '../allocInfo/AllocationLegSize';

export function createAddOtcAllocationThunk(
  legId: string,
  rfqId: string,
  firstAllocCcy: string | undefined,
  ccy?: string,
  sizeType?: SizeType,
): Thunk {
  return function addOtcAllocationThunk(
    dispatch,
    getState,
    { selectors, actionCreators, services },
  ) {
    const state = getState();
    const rfqData = selectors.getRfqData(state, rfqId);
    const strategyId = selectors.getStrategyIdByLegId(state, legId);

    const maxSize = getDisplayedValueAndLabelBaseOnSizeType(state, strategyId, legId, selectors);
    const allocations = selectors.getOtcAllocationsByLegId(state, legId);
    const stratSizeType = getDisplayedSizeTypeByStrategy(state, strategyId, selectors);
    const defaultIaType = getDefaultIaType();
    const allocationId = services.idCreator.createId();
    const isEls = selectors.isElsStrategy(state, strategyId, selectors);

    dispatch(
      actionCreators.neos.otcAllocationCrudActions.insert(
        {
          allocationId,
          legId,
          rfqId,
        },
        {
          legId,
          uuid: allocationId,
          counterpartId: undefined,
          independantAmountType: defaultIaType,
          independantAmountUnit: firstAllocCcy ?? ccy,
          independantAmountValue: undefined,
          independantAmountValueDate: undefined,
          independantAmountCurrencyValue: undefined,
          quantity:
            stratSizeType === 'QUANTITY'
              ? calculateNextAllocAmount(maxSize?.value, stratSizeType, allocations)
              : undefined,
          media: undefined,
          notional: {
            value: stratSizeType.includes('NOTIONAL')
              ? calculateNextAllocAmount(maxSize?.value, stratSizeType, allocations)
              : undefined,
            type: 'CCY',
            unit: isEls ? defaultElsCcy(rfqData) : ccy,
          },
          varUnit: undefined,
          sizeType,
          broker: undefined,
          secondaryInfo: undefined,
          markitWireEligible: undefined,
          isCancelled: false,
          updateJustificationLabel: undefined,
        },
      ),
    );

    function getDefaultIaType() {
      return allocations.length > 0 ? allocations[0].independantAmountType : 'CCY';
    }
  };
}

export function calculateNextAllocAmount(
  maxSize: number | undefined,
  sizeType: DisplayNegotiatedSize,
  allocations: Array<OtcAllocation>,
): number | undefined {
  const currentSum = sum(
    allocations.map(alloc =>
      sizeType.includes('NOTIONAL') ? (alloc.notional?.value ?? 0) : (alloc.quantity ?? 0),
    ),
  );
  return maxSize && maxSize > currentSum ? maxSize - currentSum : undefined;
}

function defaultElsCcy(rfqData: RfqData): string | undefined {
  return rfqData.notionalCurrency;
}
