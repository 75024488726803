import { getQuotesAndHeaderCommonModel } from './getQuotesAndHeaderCommonModel';
import { getQuotesAndHeaderGridColumnTemplate } from './getQuotesAndHeaderGridColumnTemplate';

import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import styles from './Quotes.module.scss';

export interface QuotesHeaderProps {
  rfqId: string;
}

export function QuotesHeader({ rfqId }: QuotesHeaderProps) {
  const {
    areSalesPricesDisplayed,
    areFairPricesDisplayed,
    areElsPtmmmDisplayed,
    isTraderDeltaDisplayed,
    isMarkupDisplayed,
  } = useAppSelector(state => getQuotesAndHeaderCommonModel(state, rfqId, selectors));

  return (
    <section
      className={`${styles['quotes-grid']}`}
      style={getQuotesAndHeaderGridColumnTemplate({
        areElsPtmmmDisplayed,
        areFairPricesDisplayed,
        areSalesPricesDisplayed,
        isTraderDeltaDisplayed,
        isMarkupDisplayed,
      })}
    >
      {areSalesPricesDisplayed && <label>Sls Bid</label>}
      <label>Trd Bid</label>
      {areElsPtmmmDisplayed && <label>PTMM</label>}
      {areFairPricesDisplayed && <label>Fair Price</label>}
      <label>Trd Ask</label>
      {areSalesPricesDisplayed && (
        <>
          <label>Sls Ask</label>
          {isMarkupDisplayed && <label>Mkp rule</label>}
        </>
      )}
      {isTraderDeltaDisplayed && <label>Trd Delta</label>}
    </section>
  );
}
