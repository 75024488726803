import type { NoSuccessToasterChainOptions } from '@/neos/business/neosActions';
import type { RecomputeScope } from '../models/recomputeModel';
import type { RecomputeRfqRequested } from '../rfqActions';

export function createRecomputeRfqRequestedAction(
  rfqId: string,
  scope: RecomputeScope,
  chainOptions?: NoSuccessToasterChainOptions,
): RecomputeRfqRequested {
  return {
    type: 'RECOMPUTE_RFQ_REQUESTED',
    rfqId,
    scope,
    chainOptions,
  };
}
