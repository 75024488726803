import { NeosTooltip } from '@/neos/components/share/tooltip/NeosTooltip';
import {
  Children,
  Component,
  type HTMLAttributes,
  type ReactElement,
  type ReactNode,
  cloneElement,
  isValidElement,
} from 'react';
import { Tooltip } from 'react-bootstrap';
import type { Counterpart } from '../../../../neos/business/neosModel';

interface PopoverOwnProps {
  counterpart: Counterpart | undefined | null;
  className?: string;
  children: any;
}

export class CounterpartPopover extends Component<PopoverOwnProps> {
  constructor(props: PopoverOwnProps) {
    super(props);
  }

  public render() {
    const { counterpart, className, children, ...rest } = this.props;
    const onlyChild: ReactElement = Children.only(children);
    if (!isValidElement<{ className?: string }>(onlyChild)) {
      throw new Error('child of CounterpartPopover must be a valid react element');
    }

    const onlyChildProps = onlyChild.props;
    const childClassName: string = (onlyChildProps && onlyChildProps.className) || '';
    const forwardedProps = {
      className: (className ? className + ' ' : '') + childClassName,
      ...rest,
    };

    return counterpart ? (
      <NeosTooltip
        id="tooltip-counterpart"
        placement="top"
        trigger={['hover', 'focus']}
        overlay={
          <Tooltip className="counterpart-info-tooltip" id="popover-trigger-hover-focus">
            <table>
              <tbody>
                <tr>
                  <td>Name:</td>
                  <td>{counterpart.name}</td>
                </tr>
                {counterpart.id !== null && (
                  <tr>
                    <td>Bdr Code:</td>
                    <td>{counterpart.id}</td>
                  </tr>
                )}
                {counterpart.mnemo !== null && (
                  <tr>
                    <td>Mnemo:</td>
                    <td>{counterpart.mnemo}</td>
                  </tr>
                )}
                {counterpart.eliotCode !== null && (
                  <tr>
                    <td>Eliot Code:</td>
                    <td>{counterpart.eliotCode}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </Tooltip>
        }
      >
        {forwardProps(onlyChild, forwardedProps) as ReactElement}
      </NeosTooltip>
    ) : (
      forwardProps(onlyChild, forwardedProps)
    );
  }
}

function forwardProps(
  onlyChild: ReactElement<any>,
  forwardedProps: HTMLAttributes<any>,
): ReactNode {
  return isValidElement(onlyChild) && cloneElement(onlyChild, forwardedProps);
}
