import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { BookingStep } from '@/neos/business/bookingSteps/BookingStepsSelectors';

export type MeteorBookingStepsModel = Record<string, BookingStep | undefined>;

export function getOtcAllocationsMeteorBookingStepsModel(
  appState: AppState,
  rfqId: string,
  legId: string,
  selectors: Selectors,
): MeteorBookingStepsModel {
  const allocations = selectors.getOtcAllocationsByLegId(appState, legId);

  return allocations.reduce((accumulator, allocation) => {
    const bookingSteps = selectors.selectBookingStepsByFilter(appState, {
      rfqId,
      referenceUuid: allocation.uuid,
      stepType: 'SKIPPER',
    });

    accumulator[allocation.uuid] = bookingSteps.at(0);
    return accumulator;
  }, {} as MeteorBookingStepsModel);
}
