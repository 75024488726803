import { useAppSelector } from '@/bootstrap/hooks';
import { thunks } from '@/bootstrap/thunks';
import { getFormattedErrors } from '@/bootstrap/toasterThunks/thunks';
import { uiActionCreators } from '@/neos/business/ui/uiActionCreators';
import { uiSelectors } from '@/neos/business/ui/uiSelectors';
import { type FC, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

type PreconfIgnoreChangesModalProps = {
  rfqId: string;
};

export const PreconfIgnoreChangesModal: FC<PreconfIgnoreChangesModalProps> = ({ rfqId }) => {
  const [areDetailsDisplayed, setAreDetailsDisplayed] = useState(false);

  const dispatch = useDispatch();
  const modalInfo = useAppSelector(state =>
    uiSelectors.getPreconfIgnoreChangesModalInfo(state.ui, rfqId),
  );

  if (!modalInfo) {
    return null;
  }

  const { isModalDisplayed, error, type } = modalInfo;

  function hideModal() {
    dispatch(
      uiActionCreators.rfqUiCrudActions.update(rfqId, {
        preconfIgnoreChangesModalInfo: {
          isModalDisplayed: false,
        },
      }),
    );
  }

  function onGenerateAnyway() {
    switch (type) {
      case 'internalPreview':
        dispatch(
          thunks.neos.createPreconfirmationEmailPreviewRequestedThunk(rfqId, 'INTERNAL', true),
        );
        break;
      case 'externalPreview':
        dispatch(
          thunks.neos.createPreconfirmationEmailPreviewRequestedThunk(rfqId, 'EXTERNAL', true),
        );
        break;
      case 'internalSend':
        dispatch(
          thunks.neos.createSendPreconfirmationEmailRequestedThunk(rfqId, false, true, true),
        );
        break;
      case 'externalSend':
        dispatch(
          thunks.neos.createSendPreconfirmationEmailRequestedThunk(rfqId, true, false, true),
        );
        break;
      case 'externalInternalSend':
        dispatch(thunks.neos.createSendPreconfirmationEmailRequestedThunk(rfqId, true, true, true));
        break;
    }
    hideModal();
  }

  return (
    <Modal show={isModalDisplayed} size="xl">
      <Modal.Header>
        <Modal.Title>Preconf generation failed</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Some changes were detected between your version and the server</p>
        <br />
        {error && (
          <>
            <button
              className={`btn btn-collapse btn-flat-primary ${
                !areDetailsDisplayed ? 'collapsed' : ''
              } collapse-icon-end`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseBorderExample"
              aria-expanded={areDetailsDisplayed}
              aria-controls="collapseBorderExample"
              onClick={() => setAreDetailsDisplayed(value => !value)}
            >
              See details
            </button>
            <div
              className={`collapse ${areDetailsDisplayed ? 'show' : ''}`}
              id="collapseBorderExample"
              dangerouslySetInnerHTML={{ __html: getFormattedErrors(error) }}
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-lg btn-flat-primary"
          onClick={() => hideModal()}
          data-e2e="preconfirmation-ignore-changes-cancel"
        >
          Cancel
        </button>
        <button
          className="btn btn-lg btn-primary"
          onClick={() => onGenerateAnyway()}
          data-e2e="preconfirmation-ignore-changes-generate-anyway"
        >
          Generate anyway
        </button>
      </Modal.Footer>
    </Modal>
  );
};
