import type { ZodObject } from 'zod';
import { z } from 'zod';
import type {
  DividendLegPeriodDates,
  EquityLegPeriodDates,
  RateLegPeriodDates,
} from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel.ts';

export const scheduleEquityDatesSchema = z.object({
  startDate: z.optional(z.string().date()),
  endDate: z.optional(z.string().date()),
  paymentDate: z.optional(z.string().date()),
});

export const scheduleRateDatesWtihoutOvernightSchema = z.object({
  fixingDate: z.optional(z.string().date()),
  startDate: z.optional(z.string().date()),
  endDate: z.optional(z.string().date()),
  paymentDate: z.optional(z.string().date()),
});

export const scheduleRateDatesSchema = z.object({
  firstFixingDate: z.optional(z.string().date()),
  fixingDate: z.optional(z.string().date()),
  startDate: z.optional(z.string().date()),
  endDate: z.optional(z.string().date()),
  paymentDate: z.optional(z.string().date()),
});

export const scheduleDividendDatesSchema = z.object({
  startDate: z.optional(z.string().date()),
  endDate: z.optional(z.string().date()),
  paymentDate: z.optional(z.string().date()),
  theoreticalPeriodPaymentDate: z.optional(z.string().date()),
});

export const validateScheduleSchema = (
  equityDates: Partial<EquityLegPeriodDates & DividendLegPeriodDates & RateLegPeriodDates>,
  scheduleSchema: ZodObject<any>,
) => {
  return scheduleSchema.safeParse(equityDates);
};
