import { type ActionCreators, actionCreators } from '@/bootstrap/actions';
import type { ThunkEpic } from '@/bootstrap/epics';
import { type Thunks, thunks } from '@/bootstrap/thunks';
import type {
  UserPreferencesCity,
  UserPreferencesCountry,
} from '@/neos/business/currentUserPreferences/currentUserPreferencesModel';
import {
  type CurrentUserPreferencesReferentialApis,
  createUserPreferencesReferentialApis,
} from '@/neos/business/currentUserPreferences/currentUserPreferencesReferentialApis';
import type { OnyxError } from '@/neos/business/mappers/error';
import type { SalesGroup } from '@/neos/business/referenceData/salesGroups/salesGroupsModel';
import type { TraderGroup } from '@/neos/business/referenceData/traderGroups/traderGroupsModel';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { ofType } from 'redux-observable';
import { forkJoin } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

export function getCurrentUserPreferencesReferentialEpic(http: SgmeHttp) {
  const api = createUserPreferencesReferentialApis(http);
  return createGetUserPreferencesReferentialEpic(api, thunks, actionCreators);
}

export function createGetUserPreferencesReferentialEpic(
  apis: CurrentUserPreferencesReferentialApis,
  { createErrorToasterThunk }: Thunks,
  { neos: { currentUserPreferencesActionCreators } }: ActionCreators,
): ThunkEpic {
  return (action$, state$) =>
    action$.pipe(
      ofType('CURRENT_USER_PREFERENCES_REFERENTIAL_REQUESTED'),
      mergeMap(() => {
        const isTrader = state$.value.userInfo.currentUser.categories.includes('TRADER');
        const safeFilteredApisByRole: CurrentUserPreferencesReferentialApis[] = Object.values(apis)
          .filter(api => {
            return !(
              (api.name === 'getDesks' && isTrader) ||
              (api.name === 'getTraderGroups' && !isTrader)
            );
          })
          .map(api => {
            return api().pipe(
              catchError((error: OnyxError) => [
                createErrorToasterThunk(
                  {
                    message: 'Error getting user preferences referential',
                  },
                  error,
                ),
              ]),
            );
          });

        return forkJoin(safeFilteredApisByRole).pipe(
          mergeMap(
            ([cities, countries, deskOrGroup]: [
              UserPreferencesCity[],
              UserPreferencesCountry[],
              SalesGroup[] | TraderGroup,
            ]) => {
              const userGroup = isTrader
                ? { tradergroups: deskOrGroup as TraderGroup[], desks: undefined }
                : { tradergroups: undefined, desks: deskOrGroup as SalesGroup[] };
              const userPrefReferential = {
                cities,
                countries,
                ...userGroup,
              };

              return [
                currentUserPreferencesActionCreators.referentialReceived(userPrefReferential),
              ];
            },
          ),
          catchError((error: OnyxError) => [
            createErrorToasterThunk(
              {
                message: 'Error getting cities for user preferences',
              },
              error,
            ),
          ]),
        );
      }),
    );
}
