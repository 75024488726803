import type { Thunk } from '@/bootstrap/thunks';

const currentPath = window.location.origin + window.location.pathname;
const cleanCurrentPath = currentPath.endsWith('/') ? currentPath.slice(0, -1) : currentPath;

export const mainClientIdLocalStorageKey = 'mainClientId';
const duplicateTabFilename = 'duplicate-tab.html';

export function createListenToAppStartEventsThunk(): Thunk {
  return (
    dispatch,
    _,
    {
      thunks: {
        common: { createStartAppThunk },
      },
      services: { serviceWorker, appStartChannel, titleBlinker },
    },
  ) => {
    appStartChannel.onmessage = e => {
      const urlHash = e.data as string;
      titleBlinker('NEOS Operation Performed!');
      serviceWorker.showNotification(
        'NEOS Operation Performed!\nClick here to show the app',
        {
          data: { type: 'APP_FOCUS', mainClientId: localStorage[mainClientIdLocalStorageKey] },
          path: `${cleanCurrentPath}/${urlHash.toString()}`,
        },
        { tag: 'APP_FOCUS' },
      );
      dispatch(createStartAppThunk(urlHash));
    };
    serviceWorker
      .getClients()
      .then(clients => {
        const eligibleClients = clients.filter(({ url }) => !url.endsWith(duplicateTabFilename));
        const weHaveAMain = eligibleClients.some(
          ({ id }) => id && id === localStorage[mainClientIdLocalStorageKey],
        );

        if (weHaveAMain) {
          appStartChannel.postMessage(document.location.hash);

          const w = window.open(cleanCurrentPath + '/' + duplicateTabFilename, '_self');
          // duplicateTab will be shown in case we can't close it
          if (w) {
            w.close();
          }
        } else {
          const thisClientId = eligibleClients.find(({ isCurrent }) => isCurrent)!.id;
          localStorage[mainClientIdLocalStorageKey] = thisClientId;
          dispatch(createStartAppThunk(document.location.hash));
        }
      })
      .catch(() => {
        dispatch(createStartAppThunk(document.location.hash));
      });
  };
}
