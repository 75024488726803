import { type Selectors, selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { FlowDatePicker } from '@/neos/components/share/datePicker';

import { useSelector } from 'react-redux';
import {
  isListedExecutionProduct,
  isOtcExecutionProduct,
} from '../../../../../../neos/business/neosModel';
import type { LegDescriptionComponentProps } from './AveragePrice';

export function getProductMaturityByLegId(state: AppState, legId: string, selectors: Selectors) {
  const { productId } = selectors.getLegData(state, legId);
  const product = selectors.getProduct(state, productId);

  if (isOtcExecutionProduct(product) || isListedExecutionProduct(product)) {
    return product.maturity;
  }

  return undefined;
}

export function getProductSubFamilyByLegId(state: AppState, legId: string, selectors: Selectors) {
  const { productId } = selectors.getLegData(state, legId);
  const product = selectors.getProduct(state, productId);

  return product.subFamily;
}

export function Maturity({ legId }: LegDescriptionComponentProps) {
  const maturity = useSelector((state: AppState) =>
    getProductMaturityByLegId(state, legId, selectors),
  );
  const productSubFamily = useSelector((state: AppState) =>
    getProductSubFamilyByLegId(state, legId, selectors),
  );
  if (productSubFamily === 'STOCK') {
    return null;
  }
  return (
    <div>
      <label className="mb-0 me-3">Maturity</label>
      <FlowDatePicker
        data-e2e="maturity"
        date={maturity ?? ''}
        maturities={[]}
        onChange={() => void 0}
        readOnly
        hideCalendarIcon
      />
    </div>
  );
}
