import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { Base64 } from 'js-base64';
import type { Observable } from 'rxjs';
import type { MailRequest } from './mailRequestModel';

export interface MailRequestApi {
  sendEmail: (mailRequest: MailRequest) => Observable<void>;
}

export const createMailRequestApi = (http: SgmeHttp): MailRequestApi => ({
  sendEmail(mailRequest: MailRequest): Observable<void> {
    const encodedMailRequest: MailRequest = {
      ...mailRequest,
      body: Base64.encode(mailRequest.body),
    };
    return http.post<void>({
      body: encodedMailRequest,
      url: 'api/mail/send',
    });
  },
});
