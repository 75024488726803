import { isJsonString } from '@/util/string/stringUtils';

type LocalStorageKey = 'blotter-workflows' | 'userPreferences' | 'lastUsedApp';

export function getLocalStorageItem<T>(key: LocalStorageKey): T | null {
  const item = window.localStorage.getItem(key);

  if (item === null || item === '' || item === undefined || item === 'undefined') {
    return null;
  }

  if (isJsonString(item)) {
    return JSON.parse(item) as T;
  }

  return item as any as T;
}

export function setLocalStorageItem(key: LocalStorageKey, value: string) {
  window.localStorage.setItem(key, value);
}
