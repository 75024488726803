import type { ThunkEpic } from '@/bootstrap/epics';
import { type Thunks, thunks } from '@/bootstrap/thunks';
import {
  type CreateSecondaryFromXoneIdApi,
  createCreateSecondaryFromXoneIdApi,
} from '@/neos/business/apis/createSecondaryFromXoneIdApi';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import type { OnyxError } from '../mappers/error';
import { type NeosActionCreators, neosActionCreators } from '../neosActionCreators';

export function getCreateCreateSecondaryFromXoneIdEpic(http: SgmeHttp): ThunkEpic {
  const api = createCreateSecondaryFromXoneIdApi(http);
  return createCreateSecondaryFromXoneIdEpic(api, thunks, neosActionCreators);
}

export function createCreateSecondaryFromXoneIdEpic(
  api: CreateSecondaryFromXoneIdApi,
  { createErrorToasterThunk, createSuccessToasterThunk }: Thunks,
  { loadTransactionAtVersion }: NeosActionCreators,
): ThunkEpic {
  return action$ =>
    action$.pipe(
      ofType('REQUEST_CREATE_SECONDARY_FROM_XONE_ID'),
      mergeMap(({ bookingId, eventType }) => {
        return api.createSecondaryFromXoneIdApi(bookingId, eventType).pipe(
          mergeMap(onyxCreateSecondaryEvent => [
            loadTransactionAtVersion({
              rfqId: onyxCreateSecondaryEvent.rfqUuid,
              version: 'LATEST',
            }),
            createSuccessToasterThunk({
              message: 'Successfully created a secondary from X-One ID',
            }),
          ]),
          catchError((error: OnyxError) =>
            of(
              createErrorToasterThunk(
                { message: 'Error when creating a secondary from X-One' },
                error,
              ),
            ),
          ),
        );
      }),
    );
}
