import type {
  BaseCustomProduct,
  CustomProduct,
  CustomProductFamily,
  DefaultProductValuesForStrategy,
  FxDigitalOptionProduct,
  FxForward,
  Product,
} from '../../../../../../../../../neos/business/neosModel';
import {
  getCommonPart,
  getCustomUnderlyingPart,
  getOtcNegotiationPart,
} from './getDefaultedPartialProduct';

export function getDefaultedCommonCustomProduct(
  currentProduct: Product,
  defaultValues: DefaultProductValuesForStrategy,
  productFamily: CustomProductFamily | 'FX_FORWARD',
): BaseCustomProduct {
  return {
    isDerivativeProduct: false,
    description:
      currentProduct.subFamily === productFamily ? currentProduct.description : undefined,
    ...getCommonPart(currentProduct, defaultValues),
    ...getOtcNegotiationPart(),
    ...getCustomUnderlyingPart(currentProduct),
  };
}

export function getDefaultedCustomProduct(
  currentProduct: Product,
  defaultValues: DefaultProductValuesForStrategy,
  productFamily: CustomProductFamily,
): CustomProduct {
  return {
    subFamily: productFamily,
    ...getDefaultedCommonCustomProduct(currentProduct, defaultValues, productFamily),
  };
}

export function getDefaultedFxForward(
  currentProduct: Product,
  defaultValues: DefaultProductValuesForStrategy,
): FxForward {
  return {
    subFamily: 'FX_FORWARD',
    ...getDefaultedCommonCustomProduct(currentProduct, defaultValues, 'FX_FORWARD'),
    pointValue: 1,
  };
}

export function getDefaultedFxDigitalOptionProduct(
  currentProduct: Product,
  defaultValues: DefaultProductValuesForStrategy,
): FxDigitalOptionProduct {
  return {
    subFamily: 'FX_DIGITAL_OPTION',
    isDerivativeProduct: false,
    ...getCommonPart(currentProduct, defaultValues),
    ...getOtcNegotiationPart(),
    ...getCustomUnderlyingPart(currentProduct),
  };
}
