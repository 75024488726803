import type { FromOnyxMappers } from '@/neos/business/mappers';
import { convertNullToUndefined } from '@/util/undefinedAndNull/convertNullToUndefined';
import type { FvaCommon, FvaFixedK, FvaFloatingK } from '../../productModel';
import type { OnyxSingleUnderlyingProduct } from '../../productOnyxModel';

export function mapFromOnyxFvaFixedK(
  legId: string,
  onyxProduct: OnyxSingleUnderlyingProduct,
  productId: string,
  mappers: FromOnyxMappers,
): FvaFixedK {
  return {
    subFamily: 'FIXED_STRIKE_FVA',
    forwardDrift: convertNullToUndefined(onyxProduct.forwardDrift),
    forwardInterestRate: onyxProduct.forwardInterestRate && onyxProduct.forwardInterestRate.value,
    ...mapFromOnyxFva(legId, onyxProduct, productId, mappers),
  };
}

export function mapFromOnyxFvaFloatingK(
  legId: string,
  onyxProduct: OnyxSingleUnderlyingProduct,
  productId: string,
  mappers: FromOnyxMappers,
): FvaFloatingK {
  return {
    subFamily: 'FLOATING_STRIKE_FVA',
    ...mapFromOnyxFva(legId, onyxProduct, productId, mappers),
  };
}

function mapFromOnyxFva(
  legId: string,
  onyxProduct: OnyxSingleUnderlyingProduct,
  productId: string,
  mappers: FromOnyxMappers,
): FvaCommon {
  const { strike, strikeUnit } = mappers.mapFromOnyxStrike(onyxProduct);

  return {
    strike,
    strikeUnit,
    style: convertNullToUndefined(onyxProduct.optionStyle),
    type: onyxProduct.optionType || undefined,
    flex: onyxProduct.optionFlexType || undefined,
    negotiationMode: 'OTC',
    ...mappers.mapFromOnyxProductCommon(legId, onyxProduct, productId),
    ...mappers.mapFromSingleDerivativeOnyxProduct(onyxProduct, mappers),
    hasCustomUnderlying: false,
  };
}
