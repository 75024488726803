import type { OnyxRfq } from '@/neos/business/rfq/rfqOnyxModel';
import { lowerCase, uniq, upperFirst } from 'lodash';
import type { BlotterModelGetters } from '.';

export function getSecondaryEventType(
  { strategies, activity }: OnyxRfq,
  { twoDimentionalArrayToString }: BlotterModelGetters,
): string | undefined {
  if (activity !== 'SECONDARY') {
    return undefined;
  }

  const strategiesLegsEventTypes = strategies.map(strategy =>
    uniq(strategy.legs.map(leg => leg.eventType)),
  );

  return twoDimentionalArrayToString(strategiesLegsEventTypes, v => upperFirst(lowerCase(v)));
}
