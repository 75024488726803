import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { SelectOption } from '@/neos/components/share/NeosSelect/NeosSelect';

export interface DefaultingPreferencesModel {
  cities: SelectOption[];
  countries: SelectOption[];
  groups: SelectOption[];
  isTrader: boolean;
}

export function getDefaultingPreferencesModel(
  state: AppState,
  selectors: Selectors,
): DefaultingPreferencesModel {
  const userPreferencesReferential =
    selectors.currentUserPreferencesSelectors.selectCurrentUserReferential(state);
  const isTrader = selectors.isTrader(state);
  const groups = isTrader
    ? userPreferencesReferential.tradergroups!.map(group => ({
        value: group.value,
        label: group.value,
      }))
    : userPreferencesReferential.desks!.map(desk => ({
        value: desk.value,
        label: desk.value,
      }));

  // NOTE: convert all identifiers to string for NormalSelect because event.target.value is typeof string | undefined
  const cities = userPreferencesReferential.cities.map(city => ({
    value: city.cityId?.toString(),
    label: city.name,
  }));
  const countries = userPreferencesReferential.countries.map(country => ({
    value: country.countryRefId.toString(),
    label: country.name,
  }));

  return {
    cities: cities.sort((a, b) => a.label.charCodeAt(0) - b.label.charCodeAt(0)),
    countries: countries.sort((a, b) => a.label.charCodeAt(0) - b.label.charCodeAt(0)),
    groups,
    isTrader,
  };
}
