import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { isFvaFixedKProduct } from '../../../../../../../neos/business/neosModel';
import type { Field } from '../utils/Field';
import { getIsStrategyDefinitionFieldEditable } from '../utils/fieldsEnablingConditions';
import { getFieldsArray } from '../utils/getFieldsArray';
import type { InterestRateCellsMapStateProps } from './InterestRateCells';

export interface InterestRateModel {
  forwardInterestRates: Array<Field<number | undefined>>;
}

export function getInterestRateModel(
  appState: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): InterestRateCellsMapStateProps {
  const products = selectors.getStrategyProducts(appState, strategyId, selectors);

  const fixedFvaProducts = products.filter(isFvaFixedKProduct);
  const { strategyType } = selectors.getStrategyData(appState, strategyId);
  const { sameProductForwardInterestRate } = selectors.getStrategyDefinition(
    appState.referenceData,
    strategyType,
  );
  const isReadonlyAtWorkflow = selectors.isReadOnlyAtCurrentWorkflow(
    appState,
    strategyId,
    selectors,
  );

  const isReadonly =
    !getIsStrategyDefinitionFieldEditable(appState, rfqId, strategyId, selectors, [
      'RFQ_EDITABLE',
      'STRATEGY_EDITABLE',
      'UNDERLYING_SET',
    ]) || isReadonlyAtWorkflow;

  const forwardInterestRates = getFieldsArray(
    fixedFvaProducts,
    'forwardInterestRate',
    sameProductForwardInterestRate,
    isReadonly,
  );

  return { model: { forwardInterestRates } };
}
