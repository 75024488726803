import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { isBasketDerivativeProduct } from '@/neos/business/rfq/strategy/leg/product/productModel';
import type { ShortcutsStateProps } from './Shortcuts';
import { AMER_PRESET, ASIA_PRESET, EUROPE_PRESET, type Preset } from './presets';

export function getShortcutsModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): ShortcutsStateProps {
  const { strategyIds } = selectors.getRfqData(state, rfqId);
  const { standaloneStrategies } = selectors.getStrategyConfiguration(state.referenceData);

  const strategyToggle = selectors.getQuickButtonStrategyToggle(state.ui.userPreferences);
  const underlyingToggle = selectors.getQuickButtonUnderlyingToggle(state.ui.userPreferences);

  const isTrader = selectors.isTrader(state);

  const rfqProducts = selectors.getRfqProducts(state, rfqId, selectors);

  const isThereAtLeastOneBasketUnderlying = rfqProducts.some(product =>
    isBasketDerivativeProduct(product),
  );

  return {
    strategyTypeShortcuts: getStrategyTypeShortcuts().map(shortcut => {
      const isDisabledForStandaloneStrategies =
        strategyIds.length > 1 && standaloneStrategies.includes(shortcut.id);

      return {
        ...shortcut,
        isDisabled: isTrader || isDisabledForStandaloneStrategies,
      };
    }),
    underlyingShortcuts: getUnderlyingShortcuts(isThereAtLeastOneBasketUnderlying),
  };

  function getStrategyTypeShortcuts() {
    switch (strategyToggle) {
      case 'AMER':
        return AMER_PRESET.STRATEGY_TYPE;
      case 'ASIA':
        return ASIA_PRESET.STRATEGY_TYPE;
      case 'EUROPE':
        return EUROPE_PRESET.STRATEGY_TYPES;
      case 'CUSTOM':
        return selectors.getStrategiesShortcutsList(state.ui.userPreferences);
    }
  }

  function getUnderlyingShortcuts(isDisabled: boolean) {
    let shortcutList: Preset<string>;

    switch (underlyingToggle) {
      case 'AMER':
        shortcutList = AMER_PRESET.UNDERLYINGS;
        break;
      case 'ASIA':
        shortcutList = ASIA_PRESET.UNDERLYINGS;
        break;
      case 'EUROPE':
        shortcutList = EUROPE_PRESET.UNDERLYINGS;
        break;
      case 'CUSTOM':
        shortcutList = selectors.getUnderlyingsShortcutsList(state.ui.userPreferences);
    }
    return isDisabled
      ? shortcutList.map(shortcut => ({ ...shortcut, isDisabled: true }))
      : shortcutList;
  }
}
