import type {
  PriceWithCurrencyUnit,
  PriceWithPercentUnit,
  PriceWithUnit,
  UnderlyingType,
} from '@/neos/business/rfq/rfqOnyxModel';
import type {
  AsianPeriodDate,
  AverageDisruptionDate,
  DeterminationMethod,
  OptionObservableType,
  StrikeType,
  Way,
} from '../../../../../../neos/business/rfq/models';
import type {
  BusinessDayConvention,
  DividendLegPeriodDates,
  DividendPaymentDate,
  DividendPeriod,
  EquityFinanceType,
  OnyxProductEls,
} from './elsProductOnyxModel';
import type {
  ClientPosition,
  ClsType,
  DeliveryType,
  ExecFeesValue,
  ForexConstat,
  ForexType,
  OptionFlex,
  OptionStyle,
  OptionType,
  ProductSubFamily,
} from './productModel';

export type OnyxProductCommon = OnyxProductFeaturesFields & {
  clsType?: ClsType;
  deliveryType: Nullable<DeliveryType> | undefined;
  discriminator: ProductSubFamily;
  expectedN?: Nullable<number>;
  finalFxConstat?: ForexConstat;
  forwardDrift?: number;
  forwardInterestRate?: PriceWithUnit;
  futureMaturity?: Nullable<string>;
  initialFxConstat?: ForexConstat;
  lotSize?: Nullable<number>;
  lowerStrike?: PriceWithUnit;
  maturity?: Nullable<string>;
  maturityTenor?: string;
  negotiation?: OnyxNegotiation;
  optionFlexType: Nullable<OptionFlex> | undefined;
  optionStyle?: OptionStyle;
  optionType: Nullable<OptionType> | undefined;
  pointValue?: Nullable<number>;
  rateCurve?: Nullable<string>;
  rateTenor?: Nullable<string>;
  rateValue?: Nullable<number>;
  strike?: PriceWithUnit;
  startDate?: string;
  strikeDate?: string;
  strikeTenor?: string;
  swapCurrency?: Nullable<string>;
  upperStrike?: PriceWithUnit;
  observableType?: OptionObservableType;
  isScheduleObsolete?: boolean;
  noTaxCollection?: Nullable<boolean>;
  clientTaxRate?: Nullable<PriceWithUnit>;
  strikeType?: StrikeType | null;
  period?: OnyxAsianPeriod | null;
  averageDisruptionDate?: AverageDisruptionDate | null;
  businessDayConvention?: BusinessDayConvention | null;
} & OnyxProductEls;

export type OnyxAsianPeriod = {
  startDate: string;
  endDate: string;
  dates: OmitSafe<AsianPeriodDate, 'uuid'>[];
  frequency: 'P1Y' | 'P1M' | 'P1D' | undefined;
  includeEndDate: boolean | undefined;
};

export type OnyxSingleUnderlyingProduct = OnyxProductCommon & {
  underlying: Nullable<OnyxProductUnderlying> | undefined;
};

export type OnyxBasketProduct = OnyxProductCommon & {
  underlying: Nullable<OnyxBasketUnderlying> | undefined;
};

export type OnyxProduct = OnyxSingleUnderlyingProduct | OnyxBasketProduct;

export function isOnyxBasketUnderlying(
  underlying: OnyxProduct['underlying'],
): underlying is OnyxBasketUnderlying {
  return !!underlying && underlying.discriminator === 'BASKET_UNDERLYING';
}

export function assertIsOnyxProductUnderlying(
  onyxProductUnderlying: OnyxProduct['underlying'],
): asserts onyxProductUnderlying is OnyxProductUnderlying {
  if (isOnyxBasketUnderlying(onyxProductUnderlying)) {
    throw new Error('Product is not Single Underlying Derivative');
  }
}
export function isOnyxBasketProduct(onyxProduct: OnyxProduct): onyxProduct is OnyxBasketProduct {
  return isOnyxBasketUnderlying(onyxProduct.underlying);
}

export interface OnyxBasketUnderlying {
  discriminator?: 'BASKET_UNDERLYING';
  type?: 'BASKET';
  currency?: Nullable<string>;
  basketType?: Nullable<'BEST_OF' | 'WORST_OF' | 'WEIGHTED_PERF' | 'WEIGHTED'>;
  basketMarket?: Nullable<{
    galaxyCode?: string;
    micCode?: string;
  }>;
  basketTypology?: Nullable<'MULTI_UNDERLYING'>;
  basketMultiplier?: Nullable<number>;
  basketDivisor?: Nullable<number>;
  isAutoRepo?: Nullable<boolean>;
  isAutoDiv?: Nullable<boolean>;
  basketEliotID?: Nullable<string>;
  repoSource?: Nullable<string>;
  basketComposition?: Nullable<OnyxBasketCompositionDetails[]>;
  forexType?: Nullable<ForexType>;
  execFees?: Nullable<ExecFeesValue>;
}

export interface OnyxBasketCompositionDetails {
  containerIndex?: Nullable<number>;
  underlying?: Nullable<OnyxProductUnderlying>;
  nominal?: Nullable<{
    value: number | undefined;
    unit: string | undefined;
    type: string | undefined;
  }>;
  execFeesIn?: Nullable<ExecFees>;
  execFeesOut?: Nullable<ExecFees>;
  quantity?: Nullable<number>;
  weight?: Nullable<number>;
}

export type ExecFeesUnitType = 'BIPS' | 'REF_PERCENT' | 'CENTS';
export type ExecFeesUnit = 'bp' | '%' | 'Cts';

export interface ExecFees {
  value?: number;
  unit?: 'bp' | '%' | 'Cts';
  type?: ExecFeesUnitType;
}

export const valuationFrequencies = {
  DAILY: 'Daily',
  MONTHLY: 'Monthly',
  NONE: 'None',
} as const;

export type ValuationFrequency = keyof typeof valuationFrequencies;

export const partialResetTypes = {
  NONE: 'None',
  FIFO: 'Fifo',
  WAC: 'WAC',
} as const;

export type PartialResetType = keyof typeof partialResetTypes;

export type OnyxBarrierActivationType = 'DOWN' | 'UP';
export type OnyxBarrierType = 'KNOCK_IN' | 'KNOCK_OUT';
export type OnyxLimitObservationType = 'EUROPEAN' | 'CLOSE' | 'EXTREMUM';
export type OnyxObservationConvention = 'T_MINUS_1' | 'T_MINUS_1_AND_T';
export const newRateTenors = [
  '1D',
  '3D',
  '1W',
  '2W',
  '3W',
  '1M',
  '2M',
  '3M',
  '4M',
  '5M',
  '6M',
  '7M',
  '8M',
  '9M',
  '1Y',
  '2Y',
  '3Y',
  '4Y',
  '5Y',
  '6Y',
  '7Y',
  '8Y',
  '9Y',
] as const;

export type NewRateTenors = (typeof newRateTenors)[number];

export type OnyxBarrier = {
  underlying: OnyxProductUnderlying | undefined;
  type?: OnyxBarrierType;
  activationType?: OnyxBarrierActivationType;
  limitStrike?: PriceWithUnit;
  maturity?: string;
  limitObservationType: OnyxLimitObservationType | undefined;
  allMustHit: boolean;
  strictLimit: boolean;
  maturityTenor?: string;
  gap?: number;
  rebate?: number;
  limitWindowDate?: { startDate: string; endDate: string };
};

export interface OnyxBarriers {
  discriminator: 'BARRIER_CONTAINER';
  barriers: OnyxBarrier[] | null;
}

export type OnyxForwardStart = {
  discriminator: 'FORWARD_START';
  forwardStartDate: string | undefined | null;
  forwardStartTenor?: string;
  determinationMethod?: DeterminationMethod;
};

interface UpDownProperty {
  underlying: OnyxProductUnderlying | undefined;
  strike: PriceWithUnit | null;
  observationConvention: OnyxObservationConvention | undefined | null;
}

export interface OnyxUpStrike {
  discriminator: 'UP_CONTAINER';
  up: UpDownProperty;
}

export interface OnyxDownStrike {
  discriminator: 'DOWN_CONTAINER';
  down: UpDownProperty;
}

interface UnderlyingAndPrice {
  underlying: OnyxProductUnderlying | undefined;
  price: PriceWithUnit | null;
}

export interface OnyxCap {
  discriminator: 'CAP_CONTAINER';
  cap: UnderlyingAndPrice;
}

export interface OnyxFloor {
  discriminator: 'FLOOR_CONTAINER';
  floor: UnderlyingAndPrice;
}

export interface OnyxResetFrequency {
  discriminator: 'RESET_FREQUENCY';
  resetFrequency: 0 | 1 | 2 | 3 | 6 | 12 | null;
}

export interface OnyxInterestRate {
  discriminator: 'INTEREST_RATE_INDEX';
  rateCurve: string | null | undefined;
  rateTenor: string | null | undefined;
  newRateTenor: NewRateTenors | null | undefined;
}

export interface OnyxEquityBullet {
  discriminator: 'EQUITY_BULLET';
  equityBullet: boolean | null;
}

export interface OnyxRateBullet {
  discriminator: 'RATE_BULLET';
  rateBullet: boolean | null;
}

export interface OnyxRateOvernight {
  discriminator: 'RATE_OVERNIGHT';
  rateOvernight: boolean | null;
}

export interface OnyxExecFees {
  discriminator: 'EXEC_FEES';
  execFees: ExecFeesValue | null;
  execFeesIn: ExecFees | null;
  execFeesOut: ExecFees | null;
}

export interface OnyxSwapCurrency {
  discriminator: 'SWAP_CURRENCY';
  swapCurrency: string | null;
}

export interface OnyxValuationFrequency {
  discriminator: 'VALUATION_FREQUENCY';
  valuationFrequency: ValuationFrequency | null;
}

export interface OnyxPartialReset {
  discriminator: 'PARTIAL_RESET';
  partialReset: PartialResetType | null;
}

export interface OnyxEquityFinance {
  discriminator: 'EQUITY_FINANCE';
  equityFinance: EquityFinanceType | null;
}

export const DividendTypeValues = {
  DECLARED_BY_ISSUER: 'Declared by the Issuer',
  RECEIVED_BY_CTP: 'Received by the Counterpart',
  CUSTOM: 'Custom',
};

export type DividendType = keyof typeof DividendTypeValues;

export type DividendFeature = Omit<
  OnyxDividendFeature,
  'discriminator' | 'dividendSchedulePeriods'
> & {
  type: 'DIVIDEND_COMPONENT';
  strategyId: string;
  dividendSchedulePeriods?: Array<DividendPeriod>;
};

export type DividendFxFixingDate = 'EX_DATE' | 'PAYMENT_DATE' | 'RESET_DATE' | 'END_DATE';

export interface OnyxDividendFeature {
  discriminator: 'DIVIDEND_COMPONENT';
  dividendWay?: Way;
  dividendType?: DividendType;
  dividendPaymentDateType?: DividendPaymentDate;
  dividendPaymentDateOffset?: number;
  dividendPaymentCurrency?: string;
  dividendFxFixingDate?: DividendFxFixingDate;
  dividendRequirement?: PriceWithPercentUnit;
  dividendAmount?: PriceWithCurrencyUnit;
  specialDividends?: boolean;
  divRequirementDetails?: boolean;
  traderDividendRequirement?: string;
  salesDividendRequirement?: string;
  divValuationDate?: string;
  dividendSchedulePeriods?: Array<DividendLegPeriodDates>;
  dividendPreconfComment?: string;
}

export interface OnyxForexType {
  discriminator: 'FOREX_TYPE';
  forexType: ForexType | null | undefined;
}

export interface OnyxDescription {
  discriminator: 'DESCRIPTION';
  description: string | undefined;
}

export interface OnyxEndOfObservation {
  discriminator: 'END_OF_OBSERVATION';
  endOfObservation: string | undefined;
}

type ExtraFeaturesContainer = {
  extraFeatures?: OnyxFeature[];
};

interface FeatureFields
  extends ExtraFeaturesContainer,
    Omit<OnyxBarriers, 'discriminator'>,
    Omit<OnyxUpStrike, 'discriminator'>,
    Omit<OnyxDownStrike, 'discriminator'>,
    Omit<OnyxForwardStart, 'discriminator'>,
    Omit<OnyxResetFrequency, 'discriminator'>,
    Omit<OnyxInterestRate, 'discriminator'>,
    Omit<OnyxEquityBullet, 'discriminator'>,
    Omit<OnyxRateBullet, 'discriminator'>,
    Omit<OnyxRateOvernight, 'discriminator'>,
    Omit<OnyxForexType, 'discriminator'>,
    Omit<OnyxDescription, 'discriminator'>,
    Omit<OnyxDividendFeature, 'discriminator'>,
    Omit<OnyxSwapCurrency, 'discriminator'>,
    Omit<OnyxEndOfObservation, 'discriminator'>,
    Omit<OnyxEquityFinance, 'discriminator'>,
    Omit<OnyxPartialReset, 'discriminator'> {}

export type OnyxProductFeaturesFields = Partial<FeatureFields>;

export type OnyxFeature =
  | OnyxBarriers
  | OnyxForwardStart
  | OnyxUpStrike
  | OnyxDownStrike
  | OnyxCap
  | OnyxFloor
  | OnyxResetFrequency
  | OnyxInterestRate
  | OnyxEquityBullet
  | OnyxRateBullet
  | OnyxRateOvernight
  | OnyxExecFees
  | OnyxForexType
  | OnyxDescription
  | OnyxDividendFeature
  | OnyxSwapCurrency
  | OnyxValuationFrequency
  | OnyxPartialReset
  | OnyxEndOfObservation
  | OnyxEquityFinance;

export type OnyxFeatureDiscriminator = OnyxFeature['discriminator'];

export interface OnyxOtcNegotiation {
  discriminator: 'OTC';
}

export interface OnyxListedNegotiation {
  discriminator: 'LISTED';
  productRef: OnyxProductRef | undefined;
  market: OnyxMarket | undefined;
  clientPosition?: ClientPosition;
}

export type OnyxNegotiation = OnyxListedNegotiation | OnyxOtcNegotiation;

export interface OnyxProductRef {
  id: string | undefined;
  backend: OnyxProductBackend;
  sameProductIds?: string;
}

export interface OnyxMarket {
  galaxyCode: string;
  micCode: string | undefined;
}

export type OnyxProductBackend = 'GALAXY';

export interface OnyxProductUnderlying {
  id?: string;
  type: UnderlyingType;
  currency?: string;
  bloombergCode?: string;
  name?: string;
  discriminator?: string;
}

export type CurrencyCode = (typeof currencyCodes)[number];

export const currencyCodes = [
  'AED',
  'AFN',
  'ALL',
  'AMD',
  'ANG',
  'AOA',
  'ARS',
  'AUD',
  'AWG',
  'AZN',
  'BAM',
  'BBD',
  'BDT',
  'BGN',
  'BHD',
  'BIF',
  'BMD',
  'BND',
  'BOB',
  'BOV',
  'BRL',
  'BSD',
  'BTN',
  'BWP',
  'BYN',
  'BYR',
  'BZD',
  'CAD',
  'CDF',
  'CHE',
  'CHF',
  'CHW',
  'CLF',
  'CLP',
  'CNY',
  'CNH',
  'COP',
  'COU',
  'CRC',
  'CUC',
  'CUP',
  'CVE',
  'CZK',
  'DJF',
  'DKK',
  'DOP',
  'DZD',
  'EGP',
  'ERN',
  'ETB',
  'EUR',
  'FJD',
  'FKP',
  'GBP',
  'GBp',
  'GEL',
  'GHS',
  'GIP',
  'GMD',
  'GNF',
  'GTQ',
  'GYD',
  'HKD',
  'HNL',
  'HRK',
  'HTG',
  'HUF',
  'IDR',
  'ILS',
  'INR',
  'IQD',
  'IRR',
  'ISK',
  'JMD',
  'JOD',
  'JPY',
  'KES',
  'KGS',
  'KHR',
  'KMF',
  'KPW',
  'KRW',
  'KWD',
  'KYD',
  'KZT',
  'LAK',
  'LBP',
  'LKR',
  'LRD',
  'LSL',
  'LTL',
  'LYD',
  'MAD',
  'MDL',
  'MGA',
  'MKD',
  'MMK',
  'MNT',
  'MOP',
  'MRO',
  'MUR',
  'MVR',
  'MWK',
  'MXN',
  'MXV',
  'MYR',
  'MZN',
  'NAD',
  'NGN',
  'NIO',
  'NOK',
  'NPR',
  'NZD',
  'OMR',
  'PAB',
  'PEN',
  'PGK',
  'PHP',
  'PKR',
  'PLN',
  'PYG',
  'QAR',
  'RON',
  'RSD',
  'RUB',
  'RUR',
  'RWF',
  'SAR',
  'SBD',
  'SCR',
  'SDG',
  'SEK',
  'SGD',
  'SHP',
  'SLL',
  'SOS',
  'SRD',
  'SSP',
  'STD',
  'SVC',
  'SYP',
  'SZL',
  'THB',
  'TJS',
  'TMT',
  'TND',
  'TOP',
  'TRY',
  'TTD',
  'TWD',
  'TZS',
  'UAH',
  'UGX',
  'USD',
  'USN',
  'USS',
  'UYI',
  'UYU',
  'UZS',
  'VEF',
  'VND',
  'VUV',
  'WST',
  'XAF',
  'XAG',
  'XAU',
  'XBA',
  'XBB',
  'XBC',
  'XBD',
  'XCD',
  'XDR',
  'XOF',
  'XPD',
  'XPF',
  'XPT',
  'XSU',
  'XTS',
  'XUA',
  'XXX',
  'YER',
  'ZAR',
  'ZMW',
  'ZWL',
] as const;
