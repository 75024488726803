import type { Dispatchable, Thunk } from '@/bootstrap/thunks';

export function createChangeSalesCounterpartyThunk(
  rfqId: string,
  counterpartId: number | undefined,
): Thunk {
  return function changeSalesCounterpartyThunk(
    dispatch,
    getState,
    {
      selectors: { getSalesValo, getSelectedSalesValoId, getSalesInitId, isTrader, getTraderById },
      thunks: {
        neos: { createDefaultSalesCounterpartyThunk, createGetPrimeBrokerListThunk },
      },
      actionCreators: {
        analytics: { createLogAnalyticsAction },
        neos: { createContactsRequestedAction, actorsSetupCrudActions },
      },
    },
  ) {
    const state = getState();
    const actions: Dispatchable[] = [
      createLogAnalyticsAction('NEOS RFQ', 'Select client', counterpartId?.toString()),
    ];
    const isUserTrader = isTrader(state);

    if (counterpartId !== undefined) {
      const salesInit = getSalesInitId(state, rfqId);
      const selectedSalesValo = getSelectedSalesValoId(state, rfqId);

      if (isUserTrader && !selectedSalesValo) {
        const trader = getTraderById(state.referenceData, salesInit);

        if (!trader) {
          throw Error(`Trader not found for ${salesInit}`);
        }
        actions.push(createContactsRequestedAction(rfqId, counterpartId, trader.email));
      } else {
        const salesId = selectedSalesValo || salesInit;
        const sales = getSalesValo(state.referenceData, salesId);

        if (!sales) {
          throw Error(`Sales not found for sales id ${salesId}`);
        }
        actions.push(createContactsRequestedAction(rfqId, counterpartId, sales.email));
      }
      actions.push(createGetPrimeBrokerListThunk(counterpartId.toString()));
    }

    return dispatch(
      ...actions,
      actorsSetupCrudActions.update(rfqId, { counterpartId }),
      createDefaultSalesCounterpartyThunk(rfqId),
    );
  };
}
