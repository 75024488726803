import type { ActionCreators } from '@/bootstrap/actions';
import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { Dispatchable, Thunks } from '@/bootstrap/thunks';
import { DefaultingScope, type FeatureKey, isElsProduct } from '@/neos/business/rfq/models';
import { DefaultingOptions } from '@/neos/business/rfq/rfqActions';
import type {
  BrokenPeriodType,
  BusinessDayConvention,
  DividendPaymentDate,
  FixedDayType,
  GenerateFromType,
  ResetType,
} from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel';
import type { Dispatch } from 'react';

export function getScheduleInputsFields(
  rfqId: string,
  strategyId: string,
  state: AppState,
  selectors: Selectors,
  actionCreators: ActionCreators,
  thunks: Thunks,
  dispatch: Dispatch<Dispatchable>,
) {
  const product = selectors.getStrategyProduct(state, strategyId, selectors);
  if (!isElsProduct(product)) {
    throw new Error('product is not an els');
  }
  const {
    generateFrom,
    equityResetType,
    rateReset,
    brokenPeriod,
    wRateResetOnEach,
    conventionDay,
    rateFixingOffset,
    uuid: productId,
    derogateRateFixingOffset,
  } = product;

  const feature = selectors.getFeature(state.featureState, {
    strategyId,
    type: 'DIVIDEND_COMPONENT',
  });
  const dividendPaymentDate = feature?.dividendPaymentDateType;

  function onGenerateFromChanged(value: GenerateFromType | undefined) {
    const options = new DefaultingOptions({
      overrideScopes: [DefaultingScope.FIXED_DAY_TYPE],
    });
    // call defaulter after the update product crud action
    const Dispatchables = [
      actionCreators.neos.productCrudActions.update(productId, { generateFrom: value }),
      actionCreators.neos.createDefaultRfqRequestedAction(rfqId, options),
    ];
    dispatch(Dispatchables);
  }

  function onStrikeDateChanged(value: string | undefined) {
    dispatch(actionCreators.neos.productCrudActions.update(productId, { strikeDate: value }));
  }

  function onWRateResetOnEachChanged(value: FixedDayType | undefined) {
    dispatch(actionCreators.neos.productCrudActions.update(productId, { wRateResetOnEach: value }));
    dispatch(thunks.neos.changeElsIsScheduleObsoleteThunk(rfqId, productId, true));
  }

  function onEquityResetTypeChanged(value: ResetType | undefined) {
    dispatch(actionCreators.neos.productCrudActions.update(productId, { equityResetType: value }));
    dispatch(thunks.neos.changeElsIsScheduleObsoleteThunk(rfqId, productId, true));
  }

  function onRateResetTypeChanged(value: ResetType | undefined) {
    dispatch(actionCreators.neos.productCrudActions.update(productId, { rateReset: value }));
  }

  function onBrokenPeriodTypeChanged(value: BrokenPeriodType | undefined) {
    dispatch(actionCreators.neos.productCrudActions.update(productId, { brokenPeriod: value }));
  }

  function onBusinessDayConventionChanged(value: BusinessDayConvention | undefined) {
    dispatch(actionCreators.neos.productCrudActions.update(productId, { conventionDay: value }));
  }

  function onRateFixingOffsetChanged(value: number | undefined) {
    dispatch(actionCreators.neos.productCrudActions.update(productId, { rateFixingOffset: value }));
  }

  function onDerogateRateFixingOffsetChanged(value: boolean) {
    dispatch(
      actionCreators.neos.productCrudActions.update(productId, {
        derogateRateFixingOffset: value,
      }),
    );
    dispatch(thunks.neos.changeElsIsScheduleObsoleteThunk(rfqId, productId, true));
  }

  function onDividendPaymentDateChanged(value: DividendPaymentDate | undefined) {
    const featureKey: FeatureKey = { strategyId, type: 'DIVIDEND_COMPONENT' };
    dispatch(
      thunks.neos.createChangeFeatureFieldThunk(featureKey, { dividendPaymentDateType: value }),
    );
    dispatch(thunks.neos.createDefaultConfirmMediaRequested(rfqId));
  }

  return {
    generateFrom,
    wRateResetOnEach,
    equityResetType,
    conventionDay,
    rateReset,
    brokenPeriod,
    dividendPaymentDate,
    rateFixingOffset,
    derogateRateFixingOffset,
    onDerogateRateFixingOffsetChanged,
    onGenerateFromChanged,
    onStrikeDateChanged,
    onEquityResetTypeChanged,
    onRateResetTypeChanged,
    onBrokenPeriodTypeChanged,
    onWRateResetOnEachChanged,
    onBusinessDayConventionChanged,
    onDividendPaymentDateChanged,
    onRateFixingOffsetChanged,
  };
}
