import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { Hedge } from '@/neos/business/rfq/hedge/hedgeModel';

export function isDeltaHedgingButtonEnabled(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): boolean {
  const { getHedges } = selectors;

  const hedges = getHedges(state, { rfqId });

  return isThereAtLeastOneDeltaPerUdlSet(hedges);
}

function isThereAtLeastOneDeltaPerUdlSet(hedges: Hedge[]): boolean {
  return hedges.some(isHedgesDeltaDefined);
}

function isHedgesDeltaDefined(hedge: Hedge): boolean {
  return Boolean(hedge.delta && hedge.deltaType);
}
