import type { Thunk } from '@/bootstrap/thunks';

export function createDeleteTransactionThunk(rfqId: string): Thunk {
  return function deleteTransactionThunk(
    dispatch,
    getState,
    {
      thunks: { neos },
      actionCreators: {
        neos: {
          createPredealChecksRemovedAction,
          createOrdersDeleted,
          nextUserActionsCrudActions,
          similarActivitiesCrudActions,
          externalPreconfirmationCrudActions,
          internalPreconfirmationCrudActions,
        },
      },
      selectors: { getRfqData },
    },
  ) {
    const state = getState();
    const { predealCheckIds } = getRfqData(state, rfqId);

    dispatch(
      neos.createDeleteRfqThunk(rfqId),
      externalPreconfirmationCrudActions.delete(rfqId),
      internalPreconfirmationCrudActions.delete(rfqId),
      nextUserActionsCrudActions.delete(rfqId),
    );

    if (predealCheckIds && predealCheckIds.length) {
      dispatch(createPredealChecksRemovedAction(predealCheckIds));
    }

    dispatch(createOrdersDeleted(rfqId), similarActivitiesCrudActions.delete(rfqId));
  };
}
