import type { Thunk } from '@/bootstrap/thunks';
import type { BlotterPreconf } from '@/neos/business/notification';

import type { NeosBlotterRfq } from '../blotterModel';

export function createIntegrateBlotterPreconfNotificationThunk(
  rfqId: string,
  { externalMailStatus, internalMailStatus }: BlotterPreconf,
): Thunk {
  return function integrateBlotterPreconfNotificationThunk(
    dispatch,
    getState,
    { actionCreators: { neos }, selectors },
  ) {
    const appState = getState();
    const blotterRfq = selectors.getBlotterRfqById(appState.blotter, rfqId);

    if (blotterRfq) {
      const newExternalMailStatus = externalMailStatus ? { externalMailStatus } : {};
      const newInternalMailStatus = internalMailStatus ? { internalMailStatus } : {};

      const blotterRfqUpdated: NeosBlotterRfq = {
        ...blotterRfq,
        ...newExternalMailStatus,
        ...newInternalMailStatus,
      };

      dispatch(neos.updateBlotterRfq(blotterRfqUpdated));
    }
  };
}
