import type { Thunk } from '@/bootstrap/thunks';

import { flatMap } from 'lodash';

export function createDeleteLegsThunk(legIds: string[]): Thunk {
  return function deleteLegsThunk(
    dispatch,
    getState,
    {
      selectors: { getLegData },
      actionCreators: {
        neos: { legDataCrudActions, productCrudActions },
      },
    },
  ) {
    if (!legIds.length) {
      return;
    }

    const state = getState();

    dispatch(
      flatMap(legIds, legId => {
        const { productId } = getLegData(state, legId);
        return [legDataCrudActions.delete(legId), productCrudActions.delete(productId)];
      }),
    );
  };
}
