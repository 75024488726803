import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import {
  ExpectedNCellsComponent,
  type ExpectedNCellsDispatchProps,
  type ExpectedNCellsMapStateProps,
  type ExpectedNCellsOwnProps,
} from './ExpectedNCells';
import { getExpectedNModel } from './getExpectedNModel';

export const ExpectedNCells = connect(mapStateToProps, mapDispatchToProps)(ExpectedNCellsComponent);

function mapStateToProps(
  state: AppState,
  { strategyId }: ExpectedNCellsOwnProps,
): ExpectedNCellsMapStateProps {
  return { model: getExpectedNModel(state, strategyId, selectors) };
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { strategyId }: ExpectedNCellsOwnProps,
): ExpectedNCellsDispatchProps {
  return {
    onExpectedNChanged(productId: string, expectedN: number | undefined) {
      dispatch(neosThunks.createUpdateProductThunk(strategyId, productId, 'expectedN', expectedN));
    },
  };
}
