import { DefaultingOptions } from '../rfqActions';

import { DefaultingScope } from '../models';
import { createDefaultRfqRequestedAction } from './defaultRfqRequestedAction';

export function createDefaultNotionalRequestedAction(
  rfqId: string,
  withDeterminationMethod: boolean = false,
) {
  const overrideScopes = [
    DefaultingScope.UNDERLYING,
    DefaultingScope.MARKET_CODE,
    DefaultingScope.NEGOTIATED_SIZE,
    DefaultingScope.NOTIONAL,
    DefaultingScope.CAP,
    DefaultingScope.OBSERVABLE_TYPE,
  ];
  const enrichScopes = [DefaultingScope.OPTION_STYLE];
  if (withDeterminationMethod) {
    overrideScopes.push(DefaultingScope.DETERMINATION_METHOD);
  }
  const options = new DefaultingOptions({
    overrideScopes,
    enrichScopes,
  });
  return createDefaultRfqRequestedAction(rfqId, options);
}
