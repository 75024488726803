import { FlowDatePicker } from '@/neos/components/share/datePicker';
import { NeosTooltip } from '@/neos/components/share/tooltip/NeosTooltip';
import { Tooltip } from 'react-bootstrap';

import type { RefMaturityCellModel } from './getRefMaturityCellModel';

export interface RefMaturityCellOwnProps {
  rfqId: string;
  underlyingId: string;
}

export type RefMaturityCellMapStateToProps = RefMaturityCellModel;

export interface RefMaturityCellDispatchProps {
  onRefMaturityChanged(underlyingId: string, value: string | undefined): void;
}

export type RefMaturityCellProps = RefMaturityCellOwnProps &
  RefMaturityCellMapStateToProps &
  RefMaturityCellDispatchProps;

export const RefMaturityCellUnconnected = ({
  underlyingId,
  refMaturity,
  refMaturities,
  refMaturityWarning,
  isReadonly,
  onRefMaturityChanged,
}: RefMaturityCellProps) => (
  <>
    {refMaturityWarning.isRefMaturityWarningDisplayed ? (
      <NeosTooltip
        placement="top"
        key={underlyingId}
        overlay={
          <Tooltip className="react-bootstrap-warning-tooltip" id={underlyingId}>
            {refMaturityWarning.refMaturityWarningText}
          </Tooltip>
        }
      >
        <FlowDatePicker
          readOnly={isReadonly}
          key={underlyingId}
          onChange={val => {
            onRefMaturityChanged(underlyingId, val);
          }}
          maturities={refMaturities}
          date={refMaturity}
          className="warning-bloc field-warning"
        />
      </NeosTooltip>
    ) : (
      <FlowDatePicker
        readOnly={isReadonly}
        key={underlyingId}
        onChange={val => {
          onRefMaturityChanged(underlyingId, val);
        }}
        maturities={refMaturities}
        date={refMaturity}
      />
    )}
  </>
);
