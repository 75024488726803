import { type FC, useEffect, useRef, useState } from 'react';
import { ResizableBox } from 'react-resizable';
import type { SimilarActivitiesModel } from './getSimilarActivitiesModel';
import { SimilarActivitiesGrid } from './grid/SimilarActivitiesGrid.container';
import 'react-resizable/css/styles.css';

import styles from './SimilarActivities.module.scss';

export interface SimilarActivitiesOwnProps {
  rfqId: string;
}

export interface SimilarActivitiesStateProps {
  model: SimilarActivitiesModel;
  isRfqWaitingForSecondarySelection: boolean;
}

export interface SimilarActivitiesDispatchProps {
  requestSimilarActivities(): void;
  changeDisplayedStatus(areDisplayed: boolean): void;
  updateGridHeight(newHeight: number): void;
  onCloseBackDrop: () => void;
}

type SimilarActivitiesProps = SimilarActivitiesOwnProps &
  SimilarActivitiesStateProps &
  SimilarActivitiesDispatchProps;

export interface SimilarActivitiesRef {
  handleSimilarActivitiesResize: () => void;
}

function useWindowSize() {
  const [size, setSize] = useState(0);
  useEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export const SimilarActivitiesWithStyle: FC<SimilarActivitiesProps> = ({
  rfqId,
  model,
  isRfqWaitingForSecondarySelection,
  onCloseBackDrop,
  changeDisplayedStatus,
  requestSimilarActivities,
  updateGridHeight,
}) => {
  const ref = useRef<SimilarActivitiesRef>(null);

  // CLINEG-6349 little hack to make the grid buttons stick to the right side of the window when resized
  const screenWidth = useWindowSize();

  return (
    <div className={`${styles['similar-activities']} `}>
      {model.areDisplayed ? (
        <ResizableBox
          height={model.gridHeight}
          width={Infinity}
          axis="y"
          minConstraints={[0, 225]}
          resizeHandles={['n']}
          onResizeStop={(_, data) => {
            updateGridHeight(data.size.height);
            if (ref.current) {
              ref.current.handleSimilarActivitiesResize();
            }
          }}
        >
          <div className={`${styles['grid']} `}>
            <div className={`${styles['grid-buttons']}`} style={{ marginLeft: screenWidth - 80 }}>
              <button
                className="btn btn-icon btn-flat-primary"
                onClick={() => requestSimilarActivities()}
              >
                <i className="icon">cached</i>
              </button>
              <button
                className="btn btn-icon btn-flat-primary"
                onClick={() => changeDisplayedStatus(false)}
              >
                <i className="icon">remove</i>
              </button>
            </div>
            {isRfqWaitingForSecondarySelection && (
              <div className={`${styles['backdrop']} `}>
                <div className="text-center text-xxlarge">
                  <div className="bg-lvl1 p-3 d-inline">
                    Please select a secondary event in the current RFQ
                  </div>
                </div>
                <button
                  className="btn btn-icon btn-lg icon btn-flat-primary position-absolute end-0 top-0"
                  onClick={onCloseBackDrop}
                >
                  close
                </button>
              </div>
            )}
            <div className={`${styles['grid-rows']} ag-bootstrap`}>
              <SimilarActivitiesGrid ref={ref} rfqId={rfqId} />
            </div>
          </div>
        </ResizableBox>
      ) : (
        <div className={`${styles['display-button']} `}>
          <button
            className="btn btn-discreet-secondary"
            onClick={() => changeDisplayedStatus(true)}
            data-e2e="similar-activities-extend-icon"
          >
            {`Show Similar Activities (${model.rowsNumber})`}
          </button>
        </div>
      )}
    </div>
  );
};
