import { actionCreators } from '@/bootstrap/actions';
import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { thunks } from '@/bootstrap/thunks';
import { resetTypeValues } from '@/neos/business/rfq/strategy/feature/elsSections/elsSectionsModel';
import type { ResetType } from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel';
import { isElsProduct } from '@/neos/business/rfq/strategy/leg/product/productModel';
import { ElsFeaturesContext } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures';
import { ElsBlocField } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/ElsBlocField';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';

export function EquityReset() {
  const { rfqId, strategyId } = useContext(ElsFeaturesContext);
  const dispatch = useDispatch();
  const product = useAppSelector(state =>
    selectors.getStrategyProduct(state, strategyId, selectors),
  );

  if (!isElsProduct(product)) {
    return null;
  }

  const { uuid, equityResetType } = product;
  const { update: updateProduct } = actionCreators.neos.productCrudActions;

  function onEquityResetTypeChanged(equityResetType: ResetType | undefined) {
    dispatch(thunks.neos.changeElsIsScheduleObsoleteThunk(rfqId, uuid, true));
    dispatch(updateProduct(uuid, { equityResetType }));
  }

  return (
    <ElsBlocField
      label="Equity Reset"
      renderInput={readOnly => (
        <NeosSelect
          readOnly={readOnly}
          value={equityResetType}
          onChange={onEquityResetTypeChanged}
          data-e2e="els-section-equity-equityResetType"
          addEmptyOption
          options={Object.entries(resetTypeValues).map(([value, label]) => ({
            value: value as ResetType,
            label,
          }))}
        />
      )}
    />
  );
}
