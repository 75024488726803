import type { Thunk } from '@/bootstrap/thunks';
import { identity } from 'lodash';
import type { StrategiesFilterer } from '../rfqActions';
import type { OnyxRfq } from '../rfqOnyxModel';
import type { StrategyAndOutdatedLegs } from './rfqDeleteObsoleteLegsThunk';

export function createLoadRfqThunk(rfq: OnyxRfq): Thunk {
  return function loadRfqThunk(dispatch, __, { thunks: { neos } }) {
    dispatch(neos.createLoadPartialRfqThunk(rfq, identity));
  };
}

export function createLoadPartialRfqThunk(
  rfq: OnyxRfq,
  strategiesFilterer: StrategiesFilterer,
): Thunk {
  return function loadPartialRfqThunk(
    dispatch,
    getState,
    {
      actionCreators: { neos: actionCreators },
      fromMappers,
      selectors,
      thunks: {
        neos: {
          createLoadStrategiesThunk,
          createRfqDeleteObsoleteUnderlyingDependenciesThunk,
          createHedgesReceivedThunk,
          createRfqDeleteObsoleteLegsThunk,
          createGetPrimeBrokerListThunk,
        },
      },
      services,
    },
  ) {
    const rfqId = rfq.uuid;
    const { mapFromOnyxRfq } = fromMappers;

    const {
      actorsSetupCrudActions,
      rfqDataCrudActions,
      forexRatesCrudActions,
      createFairPricesLoadedAction,
      createQuotesLoadedAction,
      rfqUiCrudActions,
      electronicMediaUsersActionCreators,
    } = actionCreators;

    const state = getState();
    const sesameId = selectors.getSesameId(state);
    const {
      actorSetup,
      actorSetup: { counterpartId },
      rfqData: newRfqData,
    } = mapFromOnyxRfq(rfq, sesameId, fromMappers);
    const currentActorsSetup = selectors.getActorsSetup(state, rfqId);
    const oldRfqData = selectors.getRfqData(state, rfqId);
    const strategyAndOutdatedLegIds: StrategyAndOutdatedLegs[] =
      oldRfqData.strategyIds?.map(strategyId => {
        const { legIds: outdatedLegIds } = selectors.getStrategyData(state, strategyId);
        return { strategyId, outdatedLegIds };
      }) || [];
    const { contacts } = currentActorsSetup ? currentActorsSetup : { contacts: [] };

    const hasDeltaAdjusted = rfq.deltas?.some(
      ({ discriminator }) => discriminator === 'DELTA_ADJUSTED',
    );

    dispatch(
      createFairPricesLoadedAction(rfq, fromMappers),
      createQuotesLoadedAction(rfq, fromMappers),
      rfqDataCrudActions.upsert(rfqId, newRfqData),
      actorsSetupCrudActions.upsert(rfqId, {
        ...actorSetup,
        contacts,
      }),
      forexRatesCrudActions.upsert(rfqId, {
        forexRates: rfq.forexRates ? rfq.forexRates : [],
      }),
    );

    if (counterpartId) {
      dispatch(createGetPrimeBrokerListThunk(counterpartId.toString()));
    }

    if (!hasDeltaAdjusted) {
      dispatch(rfqUiCrudActions.patchOrInsert(rfqId, { isDeltaAdjustedSelected: false }));
    }

    if (rfq.source === 'VOICE') {
      const status = rfq.lifecycle.status;
      if (services.orderOf(status).isAfterOrEqual('PRICE_PROPOSED')) {
        dispatch(rfqUiCrudActions.patchOrInsert(rfqId, { isQuoteTimerEditionEnable: false }));
      } else {
        dispatch(rfqUiCrudActions.patchOrInsert(rfqId, { isQuoteTimerEditionEnable: true }));
      }
    }

    dispatch(createLoadStrategiesThunk(rfqId, strategiesFilterer(rfq.strategies)));
    if (rfq.deltas) {
      dispatch(createLoadStrategiesThunk(rfqId, rfq.deltas));
    }

    dispatch(
      createRfqDeleteObsoleteLegsThunk(strategyAndOutdatedLegIds),
      createHedgesReceivedThunk(rfqId, rfq.hedges),
      createRfqDeleteObsoleteUnderlyingDependenciesThunk(rfqId),
    );

    if (newRfqData.otcPreAllocs) {
      newRfqData.otcPreAllocs.forEach((preAlloc, i) => {
        if (preAlloc.counterpartId) {
          dispatch(
            electronicMediaUsersActionCreators.createElectronicMediaUsersRequestedAction(
              preAlloc.counterpartId,
            ),
          );
        }

        const oldPreAlloc = oldRfqData.otcPreAllocs?.[i];
        if (oldPreAlloc && oldPreAlloc.counterpartId === preAlloc.counterpartId) {
          if (!oldPreAlloc.eligibleMedia && !oldPreAlloc.media) {
            dispatch(
              rfqDataCrudActions.arrayPatch(rfqId, {
                index: i,
                property: 'otcPreAllocs',
                value: { eligibleMedia: preAlloc.media || preAlloc.eligibleMedia },
              }),
            );
          } else {
            dispatch(
              rfqDataCrudActions.arrayPatch(rfqId, {
                index: i,
                property: 'otcPreAllocs',
                value: { eligibleMedia: oldPreAlloc.eligibleMedia },
              }),
            );
          }
        }
      });
    }
  };
}
