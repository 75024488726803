import type { Thunk } from '@/bootstrap/thunks';

export function createRequestTransfereesContactsThunk(rfqId: string): Thunk {
  return function requestTransfereesContacts(dispatch, getState, { selectors, actionCreators }) {
    const state = getState();
    const transfereesIds = selectors.getOtcAllocationsNovationRemainingTransferees(
      state,
      rfqId,
      selectors,
    );
    const salesValoId = selectors.getSelectedSalesValoId(state, rfqId);

    if (!salesValoId) {
      return;
    }

    const salesValoEmail = selectors.getSalesValo(state.referenceData, salesValoId)?.email;

    if (!salesValoEmail) {
      return;
    }

    transfereesIds.forEach(id =>
      dispatch(
        actionCreators.neos.createTransfereeContactsRequestedAction(rfqId, id, salesValoEmail),
      ),
    );
  };
}
