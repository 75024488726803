import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { Status } from '@/neos/business/neosModel';
import type { PriceWithUnit } from '@/neos/business/neosOnyxModel';

export type ManualSalesCreditModel = {
  manualSalesCredit: PriceWithUnit | undefined;
  isManuelSalesCreditReadOnly: boolean;
};

const manualSalesCreditReadOnlyStatus: Status[] = [
  'TRADE_BEING_COMPLETED',
  'TRADE_COMPLETED',
  'TRADE_TO_BE_COMPLETED',
];

export function getManualSalesCredit(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): ManualSalesCreditModel {
  const { manualSalesCredit, status } = selectors.getRfqData(state, rfqId);
  const isManuelSalesCreditReadOnly = manualSalesCreditReadOnlyStatus.includes(status);

  return { manualSalesCredit, isManuelSalesCreditReadOnly };
}
