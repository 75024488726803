import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { isFvaFixedKProduct } from '../../../../../../../neos/business/neosModel';
import type { Field } from '../utils/Field';
import { getIsStrategyDefinitionFieldEditable } from '../utils/fieldsEnablingConditions';
import { getFieldsArray } from '../utils/getFieldsArray';
import type { ForwardDriftCellsMapStateProps } from './ForwardDriftCells';

export interface ForwardDriftModel {
  forwardDrifts: Array<Field<number | undefined>>;
}

export function getForwardDriftModel(
  appState: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): ForwardDriftCellsMapStateProps {
  const products = selectors.getStrategyProducts(appState, strategyId, selectors);
  const fixedFvaProducts = products.filter(isFvaFixedKProduct);
  const { strategyType } = selectors.getStrategyData(appState, strategyId);
  const { sameProductForwardDrift } = selectors.getStrategyDefinition(
    appState.referenceData,
    strategyType,
  );

  const isReadonlyAtWorkflow = selectors.isReadOnlyAtCurrentWorkflow(
    appState,
    strategyId,
    selectors,
  );
  const isReadonly =
    !getIsStrategyDefinitionFieldEditable(appState, rfqId, strategyId, selectors, [
      'RFQ_EDITABLE',
      'STRATEGY_EDITABLE',
      'UNDERLYING_SET',
    ]) || isReadonlyAtWorkflow;

  const forwardDrifts = getFieldsArray(
    fixedFvaProducts,
    'forwardDrift',
    sameProductForwardDrift,
    isReadonly,
  );

  return { model: { forwardDrifts } };
}
