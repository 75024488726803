import { BaseNumericInput } from './base/BaseNumericInput';
import { withAccelerator } from './withAccelerator/withAccelerator';
import { withConstraintApplication } from './withConstraintApplication/withConstraintApplication';
import { withConstraintEvaluation } from './withConstraintEvaluation/withConstraintEvaluation';
import { withFormat } from './withFormat/withFormat';
import { withReactToFocusChange } from './withReactToFocusChange/withReactToFocusChange';
import { withStyle } from './withStyle/withStyle';
import { withUnit } from './withUnit/withUnit';

const WithAcceleratorNumericInput = withAccelerator(BaseNumericInput);
const WithFormatNumericInput = withFormat(WithAcceleratorNumericInput);
const WithUnitNumericInput = withUnit(WithFormatNumericInput);
const WithConstraintEvaluationNumericInput = withConstraintEvaluation(WithUnitNumericInput);
const WithConstraintApplicationNumericInput = withConstraintApplication(
  WithConstraintEvaluationNumericInput,
);
const WithStyleNumericInput = withStyle(WithConstraintApplicationNumericInput);
const ReactiveToFocusChangeNumericInput = withReactToFocusChange(WithStyleNumericInput);

export const NumericInput = ReactiveToFocusChangeNumericInput;
