import type { Thunk } from '@/bootstrap/thunks.ts';
import {
  type EquityHedge,
  type EquityHedgeComponent,
  initialEquityHedge,
  isElsProduct,
} from '@/neos/business/rfq/strategy/leg/product/productModel.ts';
import { updateInArray } from '@/util/array/arrayUtils.ts';

export function createUpdateElsEquityComponentThunk<K extends keyof EquityHedgeComponent>(
  productId: string,
  index: number,
  key: K,
  value: EquityHedgeComponent[K],
): Thunk {
  return function updateElsEquityComponentThunk(dispatch, getState, { actionCreators, selectors }) {
    const state = getState();
    const product = selectors.getProduct(state, productId);

    if (!isElsProduct(product)) {
      return;
    }

    const equityComponent = product.equityHedge?.equityHedgeComponents?.at(index);

    if (equityComponent === undefined) {
      return;
    }

    const newEquityHedgeComponent: EquityHedgeComponent = {
      ...equityComponent,
      [key]: value,
    };

    const oldEquityHedge: EquityHedge = product.equityHedge ?? initialEquityHedge;

    dispatch(
      actionCreators.neos.productCrudActions.update(product.uuid, {
        equityHedge: {
          ...oldEquityHedge,
          equityHedgeComponents: updateInArray(
            oldEquityHedge.equityHedgeComponents,
            index,
            newEquityHedgeComponent,
          ),
        },
      }),
    );
  };
}
