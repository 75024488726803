import type { Thunk } from '@/bootstrap/thunks';
import type { ActionContainer } from '@/util/actionContainer';
import { LegRecomputeScope } from '../models';
import { type DeltaType, isRfqStrategyData } from '../strategy/strategyModel';

export function createRfqChangeStrategyDeltaTypeThunk(
  rfqId: string,
  strategyId: string,
  deltaType: DeltaType,
): Thunk {
  return function rfqChangeStrategyDeltaTypeThunk(
    dispatch,
    getState,
    {
      selectors: { getRfqData, getStrategyData },
      actionCreators: {
        neos: { strategyDataCrudActions, createActionWithRecompute },
      },
      thunks: { createInfoToasterThunk },
    },
  ) {
    const state = getState();
    const { strategyIds } = getRfqData(state, rfqId);
    const { isMasterStrategy } = getStrategyData(state, strategyId);

    const actions = getDeltaTypePatchActions();

    dispatch(createActionWithRecompute(rfqId, LegRecomputeScope.CHANGE_DELTA, actions));

    if (actions.length > 1) {
      const message = `Delta Type has been applied to ${actions.length} strategies`;
      dispatch(
        createInfoToasterThunk({
          message,
          allowHTML: true,
        }),
      );
    }

    return;

    function getDeltaTypePatchActions(): ActionContainer[] {
      const strategyToPatchIds = isMasterStrategy
        ? strategyIds.filter(currentStrategyId => {
            const strategyData = getStrategyData(state, currentStrategyId);
            return (
              isRfqStrategyData(strategyData) &&
              (currentStrategyId === strategyId || strategyData.deltaType === undefined)
            );
          })
        : [strategyId];

      return strategyToPatchIds.map(currentStrategyId =>
        strategyDataCrudActions.update(currentStrategyId, { deltaType }),
      );
    }
  };
}
