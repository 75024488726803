import type { Thunk } from '@/bootstrap/thunks';

export function createRfqRemoveObsoleteReferencesThunk(rfqId: string): Thunk {
  return function rfqRemoveObsoleteReferencesThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { referenceCrudActions },
      },
    },
  ) {
    const appState = getState();

    const rfqCurrentUnderlyingIds = selectors.getRfqAndDeltaStrategiesUnderlyingIds(
      appState,
      rfqId,
      selectors,
    );

    const rfqObsoleteReferencesKeys = selectors
      .getRfqReferencesKeys(appState, rfqId)
      .filter(
        ({ underlyingId }) =>
          !rfqCurrentUnderlyingIds.find(existingUnderlying => underlyingId === existingUnderlying),
      );

    dispatch(rfqObsoleteReferencesKeys.map(k => referenceCrudActions.delete(k)));
  };
}
