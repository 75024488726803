interface McaEligibilityProps {
  isMcaEligible: boolean | undefined;
}

export function McaEligibility({ isMcaEligible }: McaEligibilityProps) {
  if (isMcaEligible === undefined) {
    return <div></div>;
  }

  return (
    <div className="justify-content-center d-flex align-items-center" style={{ height: '32px' }}>
      {isMcaEligible ? (
        <i data-testid="eligible" className="icon icon-sm text-success">
          done
        </i>
      ) : (
        <i data-testid="not-eligible" className="icon icon-sm">
          block
        </i>
      )}
    </div>
  );
}
