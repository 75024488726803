import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { Observable } from 'rxjs';
import type { ContactApi } from './contactEpics';
import type { Contact } from './contactModel';

const buildUrl = (salesValoEmail: string, counterpartId: number) =>
  `api/referential/contacts/byCtp?ctpId=${counterpartId}&userEmail=${salesValoEmail}`;

export const makeContactApi = (http: SgmeHttp): ContactApi => ({
  getContacts(salesValoEmail: string, counterpartId: number): Observable<Contact[]> {
    const url: string = buildUrl(salesValoEmail, counterpartId);
    return http.get<Contact[]>({
      url,
    });
  },
});
