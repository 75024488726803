import type { OnyxRfq } from '@/neos/business/rfq/rfqOnyxModel';
import { logger } from '@/util/logging/logger';
import { mt } from '@/util/logging/messageTemplates';
import type { BlotterModelGetters } from '.';

export function getMasterLeg(rfq: OnyxRfq, { getMasterStrategy }: BlotterModelGetters) {
  const masterStrategy = getMasterStrategy(rfq);

  const masterLeg = masterStrategy?.legs.find(leg => leg.master);
  if (!masterLeg && masterStrategy) {
    logger.error(mt.blotterNoMasterLeg, rfq.uuid);
    return undefined;
  }
  return masterLeg;
}
