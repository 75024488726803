import type { OnyxRfq } from '@/neos/business/rfq/rfqOnyxModel';

export function getMasterStrategy(rfq: OnyxRfq) {
  const masterStrategy = rfq.strategies.find(strat => strat.master);
  if (!masterStrategy && rfq.strategies.length > 0) {
    throw new Error('We should have a master strategy!');
  }

  return masterStrategy;
}
