import { isDefined } from '@/util/undefinedAndNull/isDefined';
import { Component, type ComponentType } from 'react';
import { type ConstraintFunction, constraintFunctions } from './constraintFunctions';

interface Props {
  value?: number;
  reset?: number;
  onBlur?(value: number | undefined): void;
}

interface ConstraintEvaluationProps {
  onlyPositiveNumbers?: boolean;
  onlyNegativeNumbers?: boolean;
}

interface State {
  propsValue?: number;
  value?: number;
  reset: number;
}

export function withConstraintEvaluation<P extends Props>(
  CustomComponent: ComponentType<P>,
): ComponentType<Omit<P, 'reset'> & ConstraintEvaluationProps> {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return class ComponentWithConstraintEvaluation extends Component<
    Omit<P, 'reset'> & ConstraintEvaluationProps,
    State
  > {
    public static getDerivedStateFromProps = (
      props: Omit<P, 'reset'> & ConstraintEvaluationProps,
      state: State,
    ): State | null => {
      if (props.value !== state.propsValue) {
        return {
          ...state,
          propsValue: props.value,
          value: props.value,
        };
      }
      return null;
    };

    constructor(props: P & ConstraintEvaluationProps) {
      super(props);
      const allConstraintsAreMet: boolean = this.applyConstraints(props.value);
      if (!allConstraintsAreMet) {
        this.state = {
          propsValue: props.value,
          reset: 0,
        };
      } else {
        this.state = {
          propsValue: props.value,
          value: props.value,
          reset: 0,
        };
      }
    }

    private onWrappedComponentBlur = (value: number) => {
      const allConstraintsAreMet: boolean = this.applyConstraints(value);
      if (!allConstraintsAreMet) {
        this.setState({
          ...this.state,
          reset: this.state.reset + 1,
        });
      } else {
        if (this.props.onBlur) {
          this.props.onBlur(value);
        }
      }
    };

    private applyConstraints = (value: number | undefined): boolean => {
      if (value === undefined) {
        return true;
      }
      const { value: _, onBlur, ...rest } = this.props;
      const applicableConstraints: ConstraintFunction[] = this.getApplicableConstraints(rest);
      return applicableConstraints.reduce(this.combineConstraintResults(value), true);
    };

    private getApplicableConstraints = (props: ConstraintEvaluationProps): ConstraintFunction[] => {
      return (Object.keys(constraintFunctions) as Array<keyof ConstraintEvaluationProps>)
        .map((key: keyof ConstraintEvaluationProps) => {
          return props[key] === true ? constraintFunctions[key] : undefined;
        })
        .filter(isDefined);
    };

    private combineConstraintResults =
      (value: number) =>
      (accumulatedResult: boolean, constraintFunction: ConstraintFunction): boolean => {
        return accumulatedResult && constraintFunction(value);
      };

    public render() {
      const { value, onBlur, onlyPositiveNumbers, onlyNegativeNumbers, ...restProps } = this.props;
      return (
        <CustomComponent
          {...(restProps as P)}
          value={this.state.value}
          reset={this.state.reset}
          onBlur={this.onWrappedComponentBlur}
        />
      );
    }
  };
}
