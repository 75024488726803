import type { Selectors } from '@/bootstrap/selectors';
import { services } from '@/bootstrap/services';
import type { AppState } from '@/bootstrap/state';
import type { Way } from '../../../../neos/business/neosModel';

export function getLegWay(state: AppState, legId: string, selectors: Selectors): Way | undefined {
  const { weight: legWeight, strategyId } = selectors.getLegData(state, legId);
  const { weight: strategyWeight, rfqId } = selectors.getStrategyData(state, strategyId);
  const { clientWay } = selectors.getRfqData(state, rfqId);
  return services.computeLegWay(strategyWeight, legWeight, clientWay);
}
