import { NeosTooltip } from '@/neos/components/share/tooltip/NeosTooltip';
import { Tooltip } from 'react-bootstrap';

export function LabelWithHelp({
  labelClassNames,
  helpText,
  labelText,
}: {
  labelClassNames?: string;
  helpText: string;
  labelText: string;
}) {
  return (
    <div className="d-flex justify-content-between align-items-center">
      <label className={`${labelClassNames} me-3`}>{labelText}</label>
      <NeosTooltip overlay={<Tooltip id="help-tooltip">{helpText}</Tooltip>}>
        <i className="icon icon-xs" style={{ lineHeight: 'normal' }}>
          help
        </i>
      </NeosTooltip>
    </div>
  );
}
