import type { TabAddedAction, TabClosedAction, TabSelectedAction } from './tabActions';

export const tabActionCreators = {
  createTabAddedAction,
  createTabClosedAction,
  createTabSelectedAction,
};

export function createTabAddedAction(tabId: string): TabAddedAction {
  return {
    type: 'TAB_ADDED',
    tabId,
  };
}

export function createTabClosedAction(tabId: string): TabClosedAction {
  return {
    type: 'TAB_CLOSED',
    tabId,
  };
}

export function createTabSelectedAction(selectedTabId: 'BLOTTER' | string): TabSelectedAction {
  return {
    type: 'TAB_SELECTED',
    selectedTabId,
  };
}
