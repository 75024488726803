import type { Thunk } from '@/bootstrap/thunks';
import { isDefined } from '@/util/undefinedAndNull/isDefined';

export function createDeleteRfqThunk(rfqId: string): Thunk {
  return function deleteRfqThunk(
    dispatch,
    getState,
    {
      selectors: { getRfqData },
      thunks: {
        neos: { createRfqDeleteStrategyAndDependenciesThunk },
      },
      actionCreators: {
        neos: {
          rfqDataCrudActions,
          actorsSetupCrudActions,
          quoteCrudActions,
          versionsCrudActions,
          fairPriceCrudActions,
          referenceCrudActions,
          hedgeCrudActions,
          removeOnyxBookingSteps,
          forexRatesCrudActions,
          otcAllocationCrudActions,
          listedAllocationCrudActions,
          deltaStockListedAllocationCrudActions,
        },
      },
    },
  ) {
    const appState = getState();
    const { strategyIds, deltaHedgingStrategyIds, quoteId, fairPriceId } = getRfqData(
      appState,
      rfqId,
    );

    dispatch([
      [...(strategyIds || []), ...(deltaHedgingStrategyIds || [])].map(strategyId =>
        createRfqDeleteStrategyAndDependenciesThunk(strategyId),
      ),
      rfqDataCrudActions.delete(rfqId),
      actorsSetupCrudActions.delete(rfqId),
      versionsCrudActions.delete(rfqId),
      forexRatesCrudActions.delete(rfqId),
      quoteCrudActions.delete(quoteId),
      referenceCrudActions.deleteByPartialKey({ rfqId }),
      hedgeCrudActions.deleteByPartialKey({ rfqId }),
      removeOnyxBookingSteps(rfqId),
      [fairPriceId].filter(isDefined).map(fpId => fairPriceCrudActions.delete(fpId)),
      otcAllocationCrudActions.deleteByPartialKey({ rfqId }),
      listedAllocationCrudActions.deleteByPartialKey({ rfqId }),
      deltaStockListedAllocationCrudActions.deleteByPartialKey({ rfqId }),
    ]);
  };
}
