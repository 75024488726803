export interface ManualPricingButtonOwnProps {
  rfqId: string;
}

export interface ManualPricingButtonMapStateToProps {
  isButtonEnabled: boolean;
}

export interface ManualPricingButtonDispatchProps {
  onManualPricingRequested(): void;
}

export type ManualPricingButtonProps = ManualPricingButtonOwnProps &
  ManualPricingButtonMapStateToProps &
  ManualPricingButtonDispatchProps;

export const ManualPricingButtonComponent = (props: ManualPricingButtonProps) => (
  <button
    className="btn btn-primary"
    onClick={() => props.onManualPricingRequested()}
    data-e2e="neos-rfq-manual-pricing-button"
    disabled={!props.isButtonEnabled}
  >
    Manual Pricing
  </button>
);
