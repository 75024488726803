import { DefaultingOptions } from '../rfqActions';

import type { ChainOptions, RetryOnErrorOptions } from '@/neos/business/neosActions';
import { DefaultingScope } from '../models';
import { createDefaultRfqRequestedAction } from './defaultRfqRequestedAction';

export function createDefaultTradeRecapRequestedAction(rfqId: string, chainOptions?: ChainOptions) {
  const options = new DefaultingOptions({
    overrideScopes: [DefaultingScope.TRADE_RECAP],
  });
  const retryOptions: RetryOnErrorOptions = {
    retries: 1,
  };
  return createDefaultRfqRequestedAction(rfqId, options, chainOptions, retryOptions);
}
