import type { FromOnyxMappers } from '@/neos/business/mappers';
import type { FxDigitalOptionProduct } from '../../../../../../../../neos/business/neosModel';
import type { OnyxSingleUnderlyingProduct } from '../../../../../../../../neos/business/neosOnyxModel';

export function mapFromOnyxFxDigitalOptionProduct(
  legId: string,
  onyxProduct: OnyxSingleUnderlyingProduct,
  productUuid: string,
  mappers: FromOnyxMappers,
): FxDigitalOptionProduct {
  return {
    subFamily: 'FX_DIGITAL_OPTION',
    isDerivativeProduct: false,
    ...mappers.mapFromOnyxProductCommon(legId, onyxProduct, productUuid),
    ...mappers.mapFromOnyxOtcNegotiation(),
    ...mappers.mapFromCustomUnderlyingOnyxProduct(onyxProduct),
  };
}
