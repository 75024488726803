import type { Thunk } from '@/bootstrap/thunks';
import type { ChainOptions } from '@/neos/business/neosActions';
import type { NextUserAction } from '../nextUserActions/nextUserActionsModel';

export function createApplyNextUserActionThunk(
  rfqId: string,
  nextUserAction: NextUserAction,
  chainOptionsParam?: ChainOptions,
): Thunk {
  return function applyNextUserActionThunk(
    dispatch,
    getState,
    {
      actionCreators: {
        neos: {
          createStrategySelectedAction,
          createApplyNextUserActionRequestedAction,
          nestedRfqsCrudActions,
          createRequestSubscriptionAction,
        },
      },
      thunks: {
        neos: { createErrorsRemovedThunk },
        createCopyToClipboardThunk,
      },
      selectors: { isRfqSubscribedToNotification, getRfqData, getRfqMasterStrategy },
    },
  ) {
    const state = getState();

    if (nextUserAction.title === 'Propose Price') {
      const { quoteRecap } = getRfqData(state, rfqId);
      if (quoteRecap) {
        dispatch(createCopyToClipboardThunk(quoteRecap, 'Quote recap copied to clipboard!'));
      }
    }

    const isSubscribedToNotification = isRfqSubscribedToNotification(state.ui, rfqId);
    const { uuid: masterStrategyId } = getRfqMasterStrategy(state, rfqId);
    dispatch(
      nestedRfqsCrudActions.update(rfqId, { isSavingDone: false }),
      createErrorsRemovedThunk(rfqId),
    );
    if (isSubscribedToNotification) {
      dispatch(createApplyNextUserActionRequestedAction(rfqId, nextUserAction, chainOptionsParam));
    } else {
      const chainOptions = {
        success: {
          dispatchables: [
            createRequestSubscriptionAction([
              {
                notificationTopic: 'TRANSACTION',
                dataGroupNames: [rfqId],
              },
            ]),
            ...(chainOptionsParam?.success?.dispatchables ?? []),
          ],
          toaster: chainOptionsParam?.success?.toaster,
        },
        error: {
          ...chainOptionsParam?.error,
        },
      };
      dispatch(createApplyNextUserActionRequestedAction(rfqId, nextUserAction, chainOptions));
    }

    dispatch(createStrategySelectedAction(rfqId, masterStrategyId));
  };
}
