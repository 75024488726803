import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { Observable } from 'rxjs';
import type { AlertApi } from './alertEpic';
import type { OnyxAlert } from './alertOnyxModel';

export const createAlertApi = (http: SgmeHttp): AlertApi => ({
  retrieveAlerts(): Observable<OnyxAlert[]> {
    return http.get<OnyxAlert[]>({
      url: 'api/alert',
    });
  },
});
