import type {
  DefaultProductValuesForStrategy,
  Product,
  VSwap,
  VSwapProductFamily,
} from '../../../../../../../../../neos/business/neosModel';
import {
  getCommonPart,
  getDerivativePart,
  getOtcNegotiationPart,
} from './getDefaultedPartialProduct';

export function getDefaultedVSwapProduct(
  currentProduct: Product,
  defaultValues: DefaultProductValuesForStrategy,
  discriminator: VSwapProductFamily,
): VSwap {
  const { maturity, underlying } = defaultValues;

  return {
    subFamily: discriminator,
    ...getCommonPart(currentProduct, defaultValues),
    ...getOtcNegotiationPart(),
    ...getDerivativePart(currentProduct, maturity, underlying),
    expectedN: undefined,
    hasCustomUnderlying: false,
  };
}
