import type { Thunk } from '@/bootstrap/thunks';
import { batch } from 'react-redux';
import { DefaultingScope } from '../neosModel';
import { defaultNestedRfqs } from '../nestedRfqs/nestedRfqsModel';
import { DefaultingOptions } from '../rfq/rfqActions';

export function createAddTabThunk(): Thunk {
  return function addTabThunk(
    dispatch,
    getState,
    {
      thunks: {
        neos: { createInitializeRfqThunk, createUpsertReferencedRfqThunk },
      },
      actionCreators: {
        neos: {
          createTabAddedAction,
          nestedRfqsCrudActions,
          pendingPredealChecksCrudActions,
          createNextUserActionsRequestedAction,
          createDefaultRfqRequestedAction,
        },
      },
      selectors,
      services: { idCreator },
    },
  ) {
    batch(() => {
      const state = getState();
      const rfqId: string = idCreator.createId();
      const isTrader = selectors.isTrader(state);
      const traderOverrideScopes = [
        DefaultingScope.TRADER_DIFFUSION,
        DefaultingScope.DELIVERY_TYPE,
        DefaultingScope.DETERMINATION_METHOD,
        DefaultingScope.UNDERLYING,
        DefaultingScope.OPTION_STYLE,
        DefaultingScope.CAP,
        DefaultingScope.NEGOTIATED_SIZE,
        DefaultingScope.NOTIONAL,
        DefaultingScope.DELTAS,
        DefaultingScope.UNITS,
        DefaultingScope.EXPECTED_N,
        DefaultingScope.SWAP_CURRENCY,
        DefaultingScope.TRADED_AWAY_FEEDBACK,
      ];
      const traderEnrichScopes = [
        DefaultingScope.FOREX,
        DefaultingScope.SALES_DIFFUSION,
        DefaultingScope.TRADE_DATE,
        DefaultingScope.PIVOT_CURRENCY,
      ];
      const salesEnrichScopes = [
        DefaultingScope.SALES_DIFFUSION,
        DefaultingScope.TRADE_DATE,
        DefaultingScope.PIVOT_CURRENCY,
      ];

      dispatch(
        createInitializeRfqThunk(rfqId),
        nestedRfqsCrudActions.insert(rfqId, defaultNestedRfqs),
        pendingPredealChecksCrudActions.insert(rfqId, { pending: [] }),
        createTabAddedAction(rfqId),
        createNextUserActionsRequestedAction(rfqId),
        createDefaultRfqRequestedAction(
          rfqId,
          new DefaultingOptions({
            enrichScopes: isTrader ? traderEnrichScopes : salesEnrichScopes,
            overrideScopes: isTrader ? traderOverrideScopes : [],
          }),
        ),
        createUpsertReferencedRfqThunk(rfqId, rfqId),
      );
    });
  };
}
