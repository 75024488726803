import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { isDefined } from '@/util/undefinedAndNull/isDefined';
import { differenceInDays, startOfDay } from 'date-fns';
import {
  type Product,
  isElsProduct,
  isSingleUnderlyingDerivativeProduct,
} from '../../../../../business/neosModel';
import { isUnderlyingIndexType } from '../../../../../business/neosOnyxModel';

interface RefMaturityCellWarning {
  isRefMaturityWarningDisplayed: boolean;
  refMaturityWarningText: string | undefined;
}

export interface RefMaturityCellModel {
  underlyingId: string;
  refMaturity: string;
  refMaturities: string[];
  refMaturityWarning: RefMaturityCellWarning;
  isReadonly: boolean;
}

export function getRefMaturityCellModel(
  state: AppState,
  rfqId: string,
  underlyingId: string,
  selectors: Selectors,
): RefMaturityCellModel {
  const neosSelectors = selectors;

  const isRfqReadonly = neosSelectors.isReadOnlyRfq(state, rfqId);
  const isRfqReadOnlyAtCurrentWorkflow = selectors.isRfqReadOnlyAtCurrentWorkflow(
    state,
    rfqId,
    selectors,
  );

  const { strategyIds } = neosSelectors.getRfqData(state, rfqId);

  const reference = neosSelectors.getReference(state, { rfqId, underlyingId });
  if (!reference) {
    throw new Error(`Reference for ${underlyingId} should exist`);
  }

  const underlyingInfo = selectors.getUnderlyingInfo(state, reference.underlyingId);

  if (!underlyingInfo) {
    return {
      underlyingId: reference.underlyingId,
      refMaturities: [],
      refMaturity: '',
      refMaturityWarning: {
        isRefMaturityWarningDisplayed: false,
        refMaturityWarningText: undefined,
      },
      isReadonly: isRfqReadonly || isRfqReadOnlyAtCurrentWorkflow,
    };
  }

  const products: Array<Product | undefined> = strategyIds.map(strategyId => {
    const product = neosSelectors.getStrategyMasterProduct(state, strategyId, selectors);

    if (isSingleUnderlyingDerivativeProduct(product)) {
      return product.underlyingId === reference.underlyingId ? product : undefined;
    }
    return undefined;
  });

  const hasOnlyElsProduct =
    products.filter(isDefined).filter(product => !isElsProduct(product)).length === 0;

  return {
    underlyingId: underlyingInfo.id,
    refMaturities: isUnderlyingIndexType(underlyingInfo.type)
      ? underlyingInfo.futureRefMaturities.sort()
      : [],
    refMaturity: reference.refMaturity || '',
    refMaturityWarning: hasOnlyElsProduct
      ? {
          isRefMaturityWarningDisplayed: false,
          refMaturityWarningText: undefined,
        }
      : getRefMaturityWarning(reference.refMaturity),
    isReadonly:
      isRfqReadonly ||
      isRfqReadOnlyAtCurrentWorkflow ||
      !isUnderlyingIndexType(underlyingInfo.type),
  };
}

function getRefMaturityWarning(refMaturity: string | undefined): RefMaturityCellWarning {
  if (refMaturity) {
    const numberOfDaysUpToMaturity: number = computeNumberOfDays(new Date())(refMaturity);
    if (numberOfDaysUpToMaturity <= 7) {
      return {
        isRefMaturityWarningDisplayed: true,
        refMaturityWarningText: `The selected Ref Maturity is in ${numberOfDaysUpToMaturity} day(s)!`,
      };
    }
  }

  return {
    isRefMaturityWarningDisplayed: false,
    refMaturityWarningText: undefined,
  };
}

function parseToDate(date: string /* YYYY-MM-DD */): Date {
  const [year, month, day] = date.split('-').map(s => parseInt(s, 10));
  return new Date(year, month - 1, day);
}

export function computeNumberOfDays(from: Date) {
  const startOfDayFrom = startOfDay(from);
  return (to: string): number => {
    const toDate = parseToDate(to);
    return Math.round(differenceInDays(toDate, startOfDayFrom));
  };
}
