import type { Thunk } from '@/bootstrap/thunks';

export function changeClsIsScheduleObsoleteThunk(
  rfqId: string,
  productId: string,
  isScheduleObsolete: boolean,
): Thunk {
  return function changeClsIsScheduleObsolete(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { productCrudActions },
      },
    },
  ) {
    const state = getState();
    const { uuid } = selectors.getRfqMasterStrategy(state, rfqId);

    const isClsStrategy = selectors.isClsStrategy(state, uuid, selectors);
    const areSomeClsScheduleDates = selectors.areSomeClsScheduleDates(state, uuid, selectors);
    const isStrategyPrimary = selectors.isPrimaryStrategy(state, uuid, selectors);
    const canClsScheduleBeGenerated = selectors.canClsScheduleBeGenerated(state, uuid, selectors);

    if (
      isClsStrategy &&
      (isScheduleObsolete ? areSomeClsScheduleDates : true) &&
      isStrategyPrimary &&
      canClsScheduleBeGenerated
    ) {
      dispatch(
        productCrudActions.update(productId, {
          isScheduleObsolete,
        }),
      );
    }
  };
}
