import type { SalesLocations } from '@/neos/business/referenceData/salesLocation/salesLocationsModel';
import { formatFromCamelToStartCase } from '@/util/format';
import { orderBy } from 'lodash';
import { FormSelect } from 'react-bootstrap';

export interface SalesInitLocationOwnProps {
  rfqId: string;
}

export interface SalesInitLocationMapStateToProps {
  salesInitCityLocation: string | undefined;
  salesLocations: SalesLocations[];
  isReadonlyRfq: boolean;
}

export interface SalesInitLocationDispatchProps {
  onChangeSalesLocation: (location: string) => void;
}

export type SalesParametersProps = SalesInitLocationOwnProps &
  SalesInitLocationMapStateToProps &
  SalesInitLocationDispatchProps;

export const SalesInitLocationUnconnected = (props: SalesParametersProps) => (
  <div>
    <label>Location</label>
    <FormSelect
      value={props.salesInitCityLocation}
      onChange={e => props.onChangeSalesLocation(e.target.value)}
      readOnly={props.isReadonlyRfq}
    >
      <option key="void" />
      {orderBy(props.salesLocations, ['name']).map(location => (
        <option key={`${location.name}-${location.countryCode}`} value={location.name}>
          {formatFromCamelToStartCase(location.name)}
        </option>
      ))}
    </FormSelect>
  </div>
);
