import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { type NeosActionCreators, neosActionCreators } from '@/neos/business/neosActionCreators';
import { neosThunks } from '@/neos/business/thunks';
import { useDispatch, useSelector } from 'react-redux';
import {
  MaturityCellsComponent,
  type MaturityCellsDispatchProps,
  type MaturityCellsMapStateProps,
  type MaturityCellsOwnProps,
} from './MaturityCells';
import { getMaturityCellsModel } from './getMaturityCellsModel';
import { maturityCellsModelGetters } from './modelGetters';
import { isLegMaturityUpToDate } from './modelGetters/getMaturitiesFieldsArray';

export const MaturityCells = (ownProps: MaturityCellsOwnProps) => {
  const stateProps = useSelector<AppState, MaturityCellsMapStateProps>(state => {
    return getMaturityCellsModel(
      state,
      ownProps.rfqId,
      ownProps.strategyId,
      selectors,
      maturityCellsModelGetters,
    );
  });

  const dispatch = useDispatch<ExtendedDispatch>();
  const dispatchProps = getDispatchProps(dispatch, neosActionCreators, ownProps);
  return <MaturityCellsComponent {...ownProps} {...stateProps} {...dispatchProps} />;
};

function getDispatchProps(
  dispatch: ExtendedDispatch,
  { legUiCrudActions }: NeosActionCreators,
  { rfqId, strategyId }: MaturityCellsOwnProps,
): MaturityCellsDispatchProps {
  return {
    onMaturityChanged(legId: string, maturity: string | undefined) {
      dispatch(
        legUiCrudActions.patchOrInsert(legId, {
          legMaturityWithValidity: { value: maturity, isValid: isLegMaturityUpToDate(maturity) },
        }),
        neosThunks.createRfqUpdateLegMaturityThunk(rfqId, strategyId, legId, maturity),
        createLogAnalyticsAction('NEOS RFQ', 'Select Maturity', maturity),
      );
    },
    onTenorChanged(legId: string, tenor: string | undefined) {
      dispatch(
        neosThunks.createRfqDefaultTenorMaturityThunk(rfqId, strategyId, legId, tenor, 'MATURITY'),
        createLogAnalyticsAction('NEOS RFQ', 'Select Tenor', tenor),
      );
    },
  };
}
