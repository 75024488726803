import { ErrorHighlight } from '@/neos/components/share/errorHighlight';
import { MultiToggleButton } from '@/neos/components/share/multiToggleButton/MultiToggleButton';
import type { FC } from 'react';
import type { ElsType } from '../../../../../../../neos/business/neosOnyxModel';
import type { ElsTypeCellsModel } from './getElsTypeCellsModel';

export interface ElsTypeCellsOwnProps {
  rfqId: string;
  strategyId: string;
}

export type ElsTypeCellsMapStateProps = ElsTypeCellsModel;

export interface ElsTypeCellsDispatchProps {
  onElsTypeChanged(productId: string, elsType: ElsType | undefined): void;
}

type ElsTypeCellsProps = ElsTypeCellsOwnProps &
  ElsTypeCellsMapStateProps &
  ElsTypeCellsDispatchProps;

export const ElsTypeCells: FC<ElsTypeCellsProps> = props => {
  return (
    <section>
      {props.elsTypes.map(({ id, value, isEditable }) => (
        <ErrorHighlight
          key={id}
          errorField={'optionStyle'}
          related={{ rfqId: props.rfqId, legId: id, strategyId: props.strategyId }}
        >
          <MultiToggleButton<ElsType | undefined>
            className="form-control"
            readOnly={!isEditable}
            selectedValue={value}
            valueFormatter={v => (v ? v : '')}
            availableValues={props.availableElsTypes}
            onValueChange={newElsType => props.onElsTypeChanged(id, newElsType)}
            data-e2e="neos-strategy-els-type-cell"
          />
        </ErrorHighlight>
      ))}
    </section>
  );
};
