import type { Thunk } from '@/bootstrap/thunks';
import type { DisplayNegotiatedSize } from '@/neos/business/ui/strategy/strategyUiModel';
import type { SizeType } from '../../../../../neos/business/referenceData/referenceDataOnyxModel';
import type { SizeField } from '../leg/legSelectors';
import type { RfqStrategyData } from '../strategyData/strategyDataModel';

interface StrategySizeUpdateParameters {
  strategyId: string;
  value: number | undefined;
  isStrategyUpdate: true;
}

interface LegSizeUpdateParameters {
  strategyId: string;
  legId: string;
  value: number | undefined;
  isStrategyUpdate: false;
}

export type SizeUpdateParameters = StrategySizeUpdateParameters | LegSizeUpdateParameters;

export function createUpdateStrategyOrLegSizeThunk(parameters: SizeUpdateParameters): Thunk {
  return function updateStrategyOrLegSizeThunk(
    dispatch,
    getState,
    {
      selectors,
      thunks: {
        neos: { createUpdateLegThunk },
      },
      actionCreators: {
        neos: { strategyDataCrudActions },
      },
    },
  ) {
    const { strategyId, value } = parameters;
    const appState = getState();
    const displayedSizeField: SizeField = selectors.getLegSizeField(
      appState,
      strategyId,
      selectors,
    );
    const sizeFieldToModify: SizeField =
      displayedSizeField === 'localNotional' ? 'notional' : displayedSizeField;

    const { strategyType, legIds } = selectors.getStrategyData(appState, strategyId);
    const legId = parameters.isStrategyUpdate ? legIds[0] : parameters.legId;

    dispatchSizeAndSizeTypeUpdate();

    const { availableSizeTypes } = selectors.getStrategyDefinition(
      appState.referenceData,
      strategyType,
    ).legs[0];

    const uiSizeType = selectors.getDisplayNegotiatedSize(appState.ui, strategyId);
    const newSizeType = getSizeType(uiSizeType, availableSizeTypes);

    return dispatch(createUpdateLegThunk(strategyId, legId, 'sizeType', newSizeType));

    function dispatchSizeAndSizeTypeUpdate() {
      const localNotionalUnit =
        displayedSizeField === 'localNotional'
          ? selectors.getLegData(appState, legIds[0]).localNotionalUnit
          : undefined;

      if (parameters.isStrategyUpdate) {
        const patch: Pick<RfqStrategyData, 'notionalUnit'> = localNotionalUnit
          ? { notionalUnit: localNotionalUnit }
          : {};

        return dispatch(
          strategyDataCrudActions.update(strategyId, {
            [sizeFieldToModify]: value,
            ...patch,
          }),
        );
      }

      dispatch(createUpdateLegThunk(strategyId, legId, sizeFieldToModify, value));
      if (localNotionalUnit) {
        dispatch(createUpdateLegThunk(strategyId, legId, 'notionalUnit', localNotionalUnit));
      }
    }
  };
}

function getSizeType(
  displayNegotiatedSize: DisplayNegotiatedSize,
  availableSizeTypes: SizeType[],
): SizeType {
  switch (displayNegotiatedSize) {
    case 'NOTIONAL':
    case 'LOCAL_NOTIONAL':
      return availableSizeTypes.includes('FIXED_VEGA_NOTIONAL')
        ? 'FIXED_VEGA_NOTIONAL'
        : availableSizeTypes.includes('FIXED_DIGITAL_NOTIONAL')
          ? 'FIXED_DIGITAL_NOTIONAL'
          : 'FIXED_NOTIONAL';
    case 'VAR_UNIT':
      return 'FIXED_VAR_UNIT';
    case 'QUANTITY':
      return 'FIXED_QUANTITY';
    case 'NUMBER_OF_LOTS':
      return 'FIXED_QUANTITY';
  }
}
