import type { ActionCreators } from '@/bootstrap/actions';
import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { Dispatchable, Thunks } from '@/bootstrap/thunks';
import type { Dispatch } from 'react';
import { DefaultingScope, isElsProduct } from '@/neos/business/rfq/models';
import type { CalculationMethodType } from './CalculationMethodModel';
import { DefaultingOptions } from '@/neos/business/rfq/rfqActions.ts';

export function getElsProductFields(
  rfqId: string,
  strategyId: string,
  state: AppState,
  selectors: Selectors,
  actionCreators: ActionCreators,
  thunks: Thunks,
  dispatch: Dispatch<Dispatchable>,
) {
  const product = selectors.getStrategyProduct(state, strategyId, selectors);
  if (!isElsProduct(product)) {
    throw new Error('product is not an els');
  }
  const { observationShift, lookbackPeriod, paymentDelay, calculationMethod, lockout, uuid } =
    product;

  function onObservationShiftChanged(newObservationShift: number | undefined) {
    dispatch(thunks.neos.changeElsIsScheduleObsoleteThunk(rfqId, uuid, true));
    dispatch(
      actionCreators.neos.productCrudActions.update(uuid, {
        observationShift: newObservationShift,
      }),
    );
  }

  function onLookbackPeriodChanged(newLookbackPeriod: number | undefined) {
    dispatch(thunks.neos.changeElsIsScheduleObsoleteThunk(rfqId, uuid, true));
    dispatch(
      actionCreators.neos.productCrudActions.update(uuid, { lookbackPeriod: newLookbackPeriod }),
    );
  }

  function onPaymentDelayChanged(newPaymentDelay: number | undefined) {
    dispatch(thunks.neos.changeElsIsScheduleObsoleteThunk(rfqId, uuid, true));
    dispatch(
      actionCreators.neos.productCrudActions.update(uuid, { paymentDelay: newPaymentDelay }),
    );
  }

  function onCalculationMethodChanged(newCalculationMethod: CalculationMethodType | undefined) {
    dispatch(thunks.neos.changeElsIsScheduleObsoleteThunk(rfqId, uuid, true));
    dispatch(
      actionCreators.neos.productCrudActions.update(uuid, {
        calculationMethod: newCalculationMethod,
      }),
    );
    dispatch(
      actionCreators.neos.createDefaultRfqRequestedAction(
        rfqId,
        new DefaultingOptions({
          overrideScopes: [DefaultingScope.RESET_FREQUENCY, DefaultingScope.RATE_RESET],
        }),
      ),
    );
  }

  function onLockoutChanged(newLockout: number | undefined) {
    dispatch(thunks.neos.changeElsIsScheduleObsoleteThunk(rfqId, uuid, true));
    dispatch(actionCreators.neos.productCrudActions.update(uuid, { lockout: newLockout }));
  }

  return {
    observationShift,
    lookbackPeriod,
    paymentDelay,
    calculationMethod,
    lockout,
    onObservationShiftChanged,
    onLookbackPeriodChanged,
    onPaymentDelayChanged,
    onCalculationMethodChanged,
    onLockoutChanged,
  };
}
