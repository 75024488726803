import type { Thunk } from '@/bootstrap/thunks';
import { flatMap } from 'lodash';

export function createErrorsRemovedThunk(rfqId: string): Thunk {
  return function errorsRemovedThunk(
    dispatch,
    getState,
    {
      selectors: { getRfqData, getStrategyData, getNeosErrorIds },
      actionCreators: {
        neos: { errorCrudActions },
      },
    },
  ) {
    const appState = getState();
    const { ui } = appState;
    const strategyIds = getRfqData(appState, rfqId).strategyIds;
    const legIds = flatMap(strategyIds, strategyId => getStrategyData(appState, strategyId).legIds);

    const potentialErrorIds = [rfqId, ...strategyIds, ...legIds];
    const errorsIds = getNeosErrorIds(ui);
    const errorsIdsToDelete = errorsIds.filter(errorId =>
      potentialErrorIds.some(potentialErrorId => errorId.includes(potentialErrorId)),
    );
    dispatch(errorsIdsToDelete.map(errorCrudActions.delete));
  };
}
