import type { Tenor } from './tenorModel';
import type { TenorAction } from './tenorsActions';

export const tenorActionCreators = {
  createTenorReceivedAction,
};

export function createTenorReceivedAction(tenors: Tenor[]): TenorAction {
  return {
    type: 'TENORS_RECEIVED',
    tenors,
  };
}
