import type { Dispatchable } from '@/bootstrap/thunks';
import type { UserInfoReceivedAction, UserInfoRequestedAction } from './userInfoActions';
import type { UserInfo } from './userInfoModel';

export const userInfoActionCreators = {
  createUserInfoReceivedAction,
  createUserInfoRequestedAction,
};

function createUserInfoReceivedAction(userInfo: UserInfo): UserInfoReceivedAction {
  return {
    type: 'USER_INFO_RECEIVED',
    userInfo,
  };
}

function createUserInfoRequestedAction(nextDispatchables: Dispatchable[]): UserInfoRequestedAction {
  return {
    type: 'USER_INFO_REQUESTED',
    nextDispatchables,
  };
}
