import type { OnyxStrategy } from '../../../neos/business/neosOnyxModel';
import type { Way } from '../neosModel';

export function computeClientWay(onyxStrategy: OnyxStrategy): Way {
  const firstLeg = onyxStrategy.legs[0];
  if (onyxStrategy.weight! * firstLeg.weight > 0 && firstLeg.clientWay) {
    return firstLeg.clientWay;
  }

  return getReverseClientWay(firstLeg.clientWay);
}
export function getReverseClientWay(clientWay: Way | undefined): Way {
  switch (clientWay) {
    case 'BUY':
      return 'SELL';
    case 'SELL':
      return 'BUY';
    default:
      return 'TWO_WAYS';
  }
}

export function computeLegWay(
  strategyWeight: number,
  weight: number,
  clientWay: Way | undefined,
): Way | undefined {
  return strategyWeight * weight > 0 ? clientWay : getReverseClientWay(clientWay);
}
