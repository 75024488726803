/* eslint-disable no-console */
import {
  AppServiceWorkerRegistration,
  type BaseServiceWorkerRegistration,
} from './appServiceWorker';

const notSupportedServiceWorkerMessage = 'Service worker is not supported';

export const serviceWorker: BaseServiceWorkerRegistration = navigator.serviceWorker
  ? new AppServiceWorkerRegistration(navigator.serviceWorker)
  : {
      showNotification: () => Promise.resolve(console.log(notSupportedServiceWorkerMessage)),
      hideNotification: () => Promise.resolve(console.log(notSupportedServiceWorkerMessage)),
      addEventListener: () => console.log(notSupportedServiceWorkerMessage),
      postMessage: () => console.log(notSupportedServiceWorkerMessage),
      hideAllNotifications: () => Promise.resolve(console.log(notSupportedServiceWorkerMessage)),
      getClients: () => Promise.resolve(console.log(notSupportedServiceWorkerMessage)) as any,
    };
