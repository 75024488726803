import type { ActionCreators } from '@/bootstrap/actions';
import type { ThunkEpic } from '@/bootstrap/epics';
import { selectors } from '@/bootstrap/selectors';
import type { Dispatchable, Thunks } from '@/bootstrap/thunks';
import { ofType } from 'redux-observable';
import { type Observable, forkJoin } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import type { FromOnyxMappers } from '../../../mappers';
import type { OnyxCounterparty } from '../../../neosOnyxModel';

export interface CounterpartApi {
  getCounterparts: (users: string[]) => Observable<OnyxCounterparty[]>;
  getCounterpart: (bdrId: number) => Observable<OnyxCounterparty>;
}

export function createRetrieveCounterpartsEpic(
  api: CounterpartApi,
  { common: { createAppCrashedAction } }: ActionCreators,
  { neos: { createIntegrateReceivedCounterPartThunk } }: Thunks,
): ThunkEpic {
  return action$ =>
    action$.pipe(
      ofType('COUNTERPART_LIST_REQUESTED'),
      mergeMap(({ requests, nextDispatchable }) => {
        const responses$: Observable<Dispatchable>[] = requests.map(({ salesIds, rfqIds }) => {
          return api.getCounterparts(salesIds).pipe(
            mergeMap((counterpartList: OnyxCounterparty[]) => [
              createIntegrateReceivedCounterPartThunk(rfqIds, salesIds, counterpartList),
            ]),
            catchError(error => [createAppCrashedAction('counterpart-list-requested', error)]),
          );
        });
        const allResponses$ = forkJoin(responses$).pipe(
          mergeMap((a: Dispatchable[]) => {
            if (nextDispatchable) {
              return [...a, nextDispatchable];
            }
            return a;
          }),
        );
        return allResponses$;
      }),
    );
}

export function createRetrieveCounterpartEpic(
  api: CounterpartApi,
  { common: { createAppCrashedAction }, neos: { extraCounterpartsCrudActions } }: ActionCreators,
  _: Thunks,
  { mapFromOnyxCounterpart }: FromOnyxMappers,
): ThunkEpic {
  return (action$, state$) =>
    action$.pipe(
      ofType('COUNTERPART_REQUESTED'),
      mergeMap(({ bdrId, rfqId }) => {
        return api.getCounterpart(bdrId).pipe(
          mergeMap((counterpart: OnyxCounterparty) => {
            const hasExtraCtp = !!selectors.getAllocExtraCounterparts(state$.value, rfqId);
            return [
              hasExtraCtp
                ? extraCounterpartsCrudActions.arrayAdd(rfqId, {
                    property: 'ALLOCATIONS',
                    value: mapFromOnyxCounterpart(counterpart),
                  })
                : extraCounterpartsCrudActions.patchOrInsert(rfqId, {
                    ALLOCATIONS: [mapFromOnyxCounterpart(counterpart)],
                  }),
            ];
          }),
          catchError(error => [createAppCrashedAction('counterpart-requested', error)]),
        );
      }),
    );
}
