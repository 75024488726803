import type { Thunk } from '@/bootstrap/thunks';
import type { StepInInfo } from '@/neos/business/neosOnyxModel';
import { getStepInInfos } from './stepInInfosSelectors';

export function createUpdateStepInInfoThunk(
  allocationId: string,
  rfqId: string,
  legId: string,
  patch: Partial<StepInInfo>,
): Thunk {
  return function updateStepInInfoThunk(
    dispatch,
    getState,
    { selectors, actionCreators: { neos: neosActionCreators } },
  ) {
    const state = getState();

    const currentStepInInfos = getStepInInfos(legId, allocationId, rfqId, state, selectors);
    dispatch(
      neosActionCreators.otcAllocationCrudActions.update(
        { allocationId, rfqId, legId },
        {
          stepInInfo: {
            ...currentStepInInfos,
            ...patch,
          },
        },
      ),
    );
  };
}
