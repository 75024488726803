import type { BlotterPosition } from '@/neos/business/ui/blotter/blotterUiModel';

export interface BlotterPinButtonsStateProps {
  position: BlotterPosition;
}
export interface BlotterPinButtonsDispatchProps {
  onChange: (position: BlotterPosition) => void;
}

export type BlotterPinButtonsProps = BlotterPinButtonsDispatchProps & BlotterPinButtonsStateProps;

const icons: Record<BlotterPosition, string> = {
  TOP: 'expand_less',
  BOTTOM: 'expand_more',
  RIGHT: 'chevron_right',
  LEFT: 'chevron_left',
  CENTER: 'lens',
};

export function BlotterPinButtonsComponent({ position, onChange }: BlotterPinButtonsProps) {
  const PinButton = ({ type }: { type: BlotterPosition }) => (
    <button
      type="button"
      title={`Pin to ${type.toLocaleLowerCase()}`}
      className={`btn btn-primary-alt px-1 py-0 icon icon-sm ${
        position === type ? 'text-secondary disabled' : ''
      }`}
      style={{ height: '30px', width: '30px' }}
      disabled={position === type}
      onClick={e => {
        onChange(type);
        e.stopPropagation();
      }}
      data-e2e={'neos-pin-button-' + type.toLocaleLowerCase()}
    >
      {icons[type]}
    </button>
  );

  return (
    <div>
      <div className="text-center">
        <PinButton type="TOP" />
      </div>
      <div>
        <PinButton type="LEFT" />
        <button
          type="button"
          title="Unpin"
          data-e2e="neos-unpin-button"
          className={`btn btn-primary-alt px-2 py-1 icon icon-xs ${
            position === 'CENTER' ? 'text-secondary disabled' : ''
          }`}
          style={{ height: '30px', width: '30px' }}
          disabled={position === 'CENTER'}
          onClick={e => {
            onChange('CENTER');
            e.stopPropagation();
          }}
        >
          lens
        </button>
        <PinButton type="RIGHT" />
      </div>
      <div className="text-center">
        <PinButton type="BOTTOM" />
      </div>
    </div>
  );
}
