import { NeosTooltip } from '@/neos/components/share/tooltip/NeosTooltip';
import { Dropdown, Tooltip } from 'react-bootstrap';

import type { DeltaStrategyScope } from '../../../../../../business/neosModel';
import { ErrorHighlight } from '../../../../../share/errorHighlight';
import { Key, Keyboard, Modifier } from '../../../../../share/keyboardManager';
import type { StrategyActionsButtonModel } from './getStrategyActionsButtonModel';

import { If } from '@/neos/components/share/if/if';
import styles from './StrategyActionsButton.module.scss';

export interface StrategyActionsButtonOwnProps {
  rfqId: string;
  strategyId: string;
  isMasterStrategy: boolean;
  isSelectedStrategy: boolean;
  onFocus: () => void;
}

export interface StrategyActionsButtonMapStateProps {
  model: StrategyActionsButtonModel;
}

export interface StrategyActionsButtonDispatchProps {
  onDeleteMenuSelected: () => void;
  onDeleteShortcutSelected: () => void;

  onCloneMenuSelected: () => void;

  onSetAsMasterMenuSelected: () => void;
  onSetAsMasterShortcutSelected: () => void;

  onStratToLegsMenuSelected: () => void;

  onToggleShowLegPricesMenuSelected: () => void;
  onToggleShowLegPricesShortcutSelected: () => void;

  onManageExtraFeaturesMenuSelected: () => void;
  onManageExtraFeaturesShortcutSelected: () => void;

  onManageElsSectionsMenuSelected: () => void;

  onLegRemoved: (legId: string) => void;
  onLegAdded: () => void;
}

type StrategyActionsButtonProps = StrategyActionsButtonOwnProps &
  StrategyActionsButtonMapStateProps &
  StrategyActionsButtonDispatchProps;

function StrategyDropdown(props: StrategyActionsButtonProps) {
  const {
    strategyId,
    isMasterStrategy,
    model,
    onDeleteMenuSelected,
    onCloneMenuSelected,
    onSetAsMasterMenuSelected,
    onStratToLegsMenuSelected,
    onToggleShowLegPricesMenuSelected,
    onManageExtraFeaturesMenuSelected,
    onManageElsSectionsMenuSelected,
  } = props;

  return (
    <Dropdown id={`strategy-options-${strategyId}`} onSelect={() => null}>
      <Dropdown.Toggle
        disabled={model.isReadonly}
        variant="discreet-primary"
        className="form-control"
        data-e2e="strategy-action-button"
      >
        <i className="icon icon-xs">settings</i>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          disabled={model.isReadonly}
          onClick={onDeleteMenuSelected}
          data-e2e="strategy-action-button-delete"
        >
          Delete Strategy [Ctrl+Del]
        </Dropdown.Item>
        {!model.isElsMenuEnabled && (
          <Dropdown.Item
            disabled={model.isReadonly}
            onClick={onCloneMenuSelected}
            data-e2e="strategy-action-button-clone"
          >
            Clone Strategy
          </Dropdown.Item>
        )}
        {isMasterStrategy ? null : (
          <Dropdown.Item
            disabled={model.isReadonly}
            onClick={onSetAsMasterMenuSelected}
            data-e2e="strategy-action-button-set-as-master"
          >
            Set as Master [Ctrl+M]
          </Dropdown.Item>
        )}
        {model.isSplittable && (
          <Dropdown.Item
            disabled={model.isReadonly}
            onClick={onStratToLegsMenuSelected}
            data-e2e="strategy-action-button-legs-to-strat"
          >
            Legs to Strats
          </Dropdown.Item>
        )}
        {model.isToggleLegPricesVisible && (
          <Dropdown.Item
            onClick={onToggleShowLegPricesMenuSelected}
            data-e2e="strategy-action-button-toggle-leg-prices"
          >
            Toggle Leg Prices [Ctrl+L]
          </Dropdown.Item>
        )}

        <Dropdown.Item
          onClick={onManageExtraFeaturesMenuSelected}
          disabled={!model.isExtraFeatureEnabled || model.isReadonly}
          data-e2e="strategy-action-button-manage-extra-features"
        >
          Manage Extra Features [Ctrl+E]
        </Dropdown.Item>

        {model.isElsMenuEnabled && (
          <Dropdown.Item
            onClick={onManageElsSectionsMenuSelected}
            data-e2e="strategy-action-button-manage-els-sections"
          >
            Manage ELS Sections
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export function StrategyActionsButtonComponent(props: StrategyActionsButtonProps) {
  const {
    rfqId,
    strategyId,
    isSelectedStrategy,
    model,
    onFocus,
    onDeleteShortcutSelected,
    onSetAsMasterShortcutSelected,
    onToggleShowLegPricesShortcutSelected,
    onManageExtraFeaturesShortcutSelected,
    onLegRemoved,
    onLegAdded,
  } = props;

  return (
    <section className={`${styles['settings-grid']}`} onFocus={onFocus} onClick={onFocus}>
      <If condition={isSelectedStrategy}>
        <>
          {model.isExtraFeatureEnabled && (
            <Keyboard
              code={Key.e}
              modifier={Modifier.ctrl}
              onKeyPress={onManageExtraFeaturesShortcutSelected}
            />
          )}
          <Keyboard
            code={Key.m}
            modifier={Modifier.ctrl}
            onKeyPress={onSetAsMasterShortcutSelected}
          />
          <Keyboard
            code={Key.l}
            modifier={Modifier.ctrl}
            onKeyPress={onToggleShowLegPricesShortcutSelected}
          />
          <Keyboard
            code={Key.delete}
            modifier={Modifier.ctrl}
            onKeyPress={onDeleteShortcutSelected}
          />
        </>
      </If>
      <div data-e2e="neos-strategy-strategy-actions">
        <ErrorHighlight
          disableError={isSelectedStrategy}
          errorField={'strategy'}
          related={{ rfqId, strategyId }}
        >
          {model.scope === 'RFQ' ? (
            <StrategyDropdown {...props} />
          ) : (
            <NeosTooltip
              placement="top"
              overlay={<Tooltip id="strategies-delta-label">Delta Strategy</Tooltip>}
            >
              <span className={`${styles['delta-label']}`}>
                Δ {deltaShortcutMapper(model.scope)}
              </span>
            </NeosTooltip>
          )}
        </ErrorHighlight>
      </div>
      {!model.readOnlyNumberOfLegs &&
        model.removableLegIds.map(legId => (
          <button
            key={legId}
            onClick={() => onLegRemoved(legId)}
            data-e2e="neos-strategy-remove-leg"
            className="btn btn-flat-primary btn-icon w-100"
          >
            <i className="icon icon-md">delete_forever</i>
          </button>
        ))}
      {!model.readOnlyNumberOfLegs && (
        <button
          onClick={() => onLegAdded()}
          data-e2e="neos-strategy-add-leg"
          className="btn btn-flat-primary btn-icon w-100"
        >
          <i className="icon icon-md">add</i>
        </button>
      )}
    </section>
  );
}

function deltaShortcutMapper(deltaType: DeltaStrategyScope): string {
  switch (deltaType) {
    case 'DELTA_ADJUSTED':
      return 'Adj';
    case 'DELTA_EXCHANGE':
    case 'DELTA_EXCHANGE_OTC':
      return 'Exc';
  }
}
