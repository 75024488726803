import type { Thunk } from '@/bootstrap/thunks';
import { flatMap } from 'lodash';

export function createEnsureValidPreAllocCounterpartsThunk(rfqIds: string[]): Thunk {
  return function ensureValidPreAllocCounterpartsThunk(
    dispatch,
    getState,
    {
      selectors: { getRfqData, getCounterparts },
      actionCreators: {
        neos: { rfqDataCrudActions },
      },
    },
  ) {
    const state = getState();
    const actions = flatMap(rfqIds, rfqId => {
      const counterparts = getCounterparts(state, rfqId)!;
      const { otcPreAllocs } = getRfqData(state, rfqId);

      return otcPreAllocs
        .map(({ counterpartId }, index) => ({ counterpartId, index }))
        .filter(({ counterpartId }) => !counterparts.some(({ id }) => id === counterpartId))
        .map(({ index }) =>
          rfqDataCrudActions.arrayPatch(rfqId, {
            property: 'otcPreAllocs',
            index,
            value: { counterpartId: undefined },
          }),
        );
    });

    if (actions.length > 0) {
      dispatch(actions);
    }
  };
}
