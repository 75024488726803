import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { CounterpartPopover } from '@/neos/components/rfq/predealChecks/counterpartPopover';
import styles from '@/neos/components/rfq/predealChecks/predealChecksScreen/requestPredealChecks/RequestPredealChecks.module.scss';
import {
  type GetRequestPDCBrokerModel,
  getRequestPDCBrokerModel,
} from '@/neos/components/rfq/predealChecks/predealChecksScreen/requestPredealChecks/getRequestPDCBrokerModel';
import { RequestPredealCheckCell } from '@/neos/components/rfq/predealChecks/predealChecksScreen/requestPredealChecks/requestPredealCheckCell/RequestPredealCheckCell.container';

interface RequestPDCBrokerProps {
  rfqId: string;
}

export const RequestPDCBrokerComponent = ({ rfqId }: RequestPDCBrokerProps) => {
  const model: GetRequestPDCBrokerModel | null = useAppSelector(state =>
    getRequestPDCBrokerModel(state, rfqId, selectors),
  );
  if (!model) {
    return null;
  }

  return (
    <section className="card card-bordered">
      {model.broker ? (
        <div className={`${styles['request-predeal-checks-body']} card-body`}>
          <div className={`${styles['counterpart']} `}>
            <span className={`${styles['counterpart-label']} `}>Broker:</span>
            <CounterpartPopover counterpart={model.broker}>
              <span
                className={`${styles['predeal-checks-request-counterpart']} `}
                data-e2e="predeal-checks-request-broker"
              >
                {model.broker?.name}
              </span>
            </CounterpartPopover>
          </div>
          <div className={`${styles['predeal-checks']} `}>
            {model.bulletToDisplay.map(type => (
              <RequestPredealCheckCell
                key={type}
                rfqId={rfqId}
                type={type}
                bdrId={model.broker?.id}
              />
            ))}
          </div>
        </div>
      ) : (
        <span className="label label-danger p-3" data-e2e="request-no-broker-warning">
          No broker selected
        </span>
      )}
    </section>
  );
};
