import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { MdpQuoteTimerEditionStateProps } from './MdpQuoteTimerEdition';

export function getQuoteTimerEditionModel(
  state: AppState,
  rfqId: string,
  { isQuoteTimerEditable, getRfqData }: Selectors,
): MdpQuoteTimerEditionStateProps {
  const isEditable = isQuoteTimerEditable(state.ui, rfqId);
  const { quoteValidityDuration } = getRfqData(state, rfqId);
  return { isEditable, quoteValidityDuration };
}
