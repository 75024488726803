import { type ActionCreators, actionCreators } from '@/bootstrap/actions';
import { type Thunks, thunks } from '@/bootstrap/thunks';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { flatMap } from 'lodash';
import { type Epic, ofType } from 'redux-observable';
import { type Observable, combineLatest } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { wrapInChainableLoadingObservable } from '../../epics/wrapInChainableLoadingObservable';
import type { OnyxError } from '../../mappers/error';
import type { Underlying } from '../../neosModel';
import { createRetrieveUnderlyingApi } from './underlyingApi';

export interface RetrieveUnderlyingApi {
  retrieveUnderlyingByBloombergCode: (bloombergCode: string) => Observable<Underlying[]>;
  bulkRetrieveUnderlyingByBloombergCodes: (bloombergCodes: string[]) => Observable<Underlying[]>;
}

export function getUnderlyingEpic(http: SgmeHttp): Epic {
  const api = createRetrieveUnderlyingApi(http);
  return createUnderlyingEpic(api, actionCreators, thunks);
}

export function createUnderlyingEpic(
  api: RetrieveUnderlyingApi,
  { common: { createAppCrashedAction }, neos: { productCrudActions } }: ActionCreators,
  { createErrorToasterThunk }: Thunks,
): Epic {
  return action$ =>
    action$.pipe(
      ofType('UNDERLYING_IDS_REQUESTED'),
      mergeMap(({ rfqIds, bloombergCodesByProductId: bloombergCodesByLegId, chainOptions }) => {
        const underlyings$ = combineLatest(
          bloombergCodesByLegId.map(bloombergCodeByLegId =>
            api.retrieveUnderlyingByBloombergCode(bloombergCodeByLegId.bloombergCode),
          ),
        );

        return wrapInChainableLoadingObservable({
          tabIds: rfqIds,
          apiObservable: underlyings$,
          observableOptions: {
            success: {
              on: underlyings => {
                return flatMap(underlyings).map(underlying =>
                  productCrudActions.update(findProductId(bloombergCodesByLegId, underlying), {
                    underlyingId: underlying.id,
                  }),
                );
              },
            },
            error: {
              on: (error: OnyxError) => [
                createAppCrashedAction('underlyings-requested', error),
                createErrorToasterThunk(
                  {
                    message: 'Error when retrieving underlyings',
                  },
                  error,
                ),
              ],
            },
          },
          chainOptions,
        });
      }),
    );

  function findProductId(
    bloombergCodesByLegId: { productId: string; bloombergCode: string }[],
    underlying: Underlying,
  ): string {
    return bloombergCodesByLegId.filter(
      bloombergCodeByLegId => bloombergCodeByLegId.bloombergCode === underlying.bloombergCode,
    )[0].productId;
  }
}
