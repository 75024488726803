import type { Dispatchable, Thunk } from '@/bootstrap/thunks';
import type { ChainOptions } from '@/neos/business/neosActions';
import { flatMap } from 'lodash';
import type { ReferenceKey } from '../models';

export function createChangeReferenceSpotThunk(
  referenceKey: ReferenceKey,
  refSpot: number | undefined,
): Thunk {
  return function changeReferenceSpotThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { createSpotChangedAction, rfqUiCrudActions },
      },
      thunks: {
        neos: { createRfqResetQuotesWithGivenUnderlyingThunk, createRequestRfqFairPricesThunk },
      },
    },
  ) {
    const state = getState();
    const { strategyIds } = selectors.getRfqData(state, referenceKey.rfqId);
    const rfqStrategies = strategyIds.map(strategyId =>
      selectors.getStrategyData(state, strategyId),
    );
    const priceUnitTypes = flatMap(
      rfqStrategies.map(({ quoteId }) => {
        const quote = selectors.getQuote(state, quoteId);
        return quote.traderAskType || quote.traderBidType;
      }),
    );
    const dispatchables: Dispatchable[] = [];

    if (priceUnitTypes.includes('CCY')) {
      dispatchables.push(createRfqResetQuotesWithGivenUnderlyingThunk(referenceKey));
    }
    const chainOptions: ChainOptions = {
      success: {
        dispatchables: [createRequestRfqFairPricesThunk(referenceKey.rfqId)],
      },
    };

    const isTrader = selectors.isTrader(state);
    if (isTrader) {
      const backEndRefSpotValue = selectors.getOldRefSpotValue(state.ui, referenceKey.rfqId);
      if (backEndRefSpotValue === undefined || backEndRefSpotValue !== refSpot) {
        dispatchables.push(
          rfqUiCrudActions.update(referenceKey.rfqId, { isRefSpotChangedByTrader: true }),
        );
      } else if (
        backEndRefSpotValue !== undefined &&
        refSpot !== undefined &&
        backEndRefSpotValue === refSpot
      ) {
        dispatchables.push(
          rfqUiCrudActions.update(referenceKey.rfqId, { isRefSpotChangedByTrader: false }),
        );
      }
      // save the original backend value only once
      if (backEndRefSpotValue === undefined) {
        const currentRefSpot = selectors.getReference(state, referenceKey)?.refSpot;
        dispatchables.push(
          rfqUiCrudActions.update(referenceKey.rfqId, { oldRefSpotValue: currentRefSpot }),
        );
      }
    }

    const hasAlreadyFairPricesInRfq = selectors.hasRfqFairPrices(
      state,
      referenceKey.rfqId,
      selectors,
    );
    dispatchables.push(
      createSpotChangedAction(
        referenceKey,
        refSpot,
        hasAlreadyFairPricesInRfq ? chainOptions : undefined,
      ),
    );

    dispatch(dispatchables);
  };
}
