import { importFromExcelClipboardData } from '@/util/excel/excel';
import { neosThunks } from '@/neos/business/thunks';
import { useDispatch } from 'react-redux';
import type { ElsBasket } from '@/neos/business/rfq/strategy/leg/product/productModel';
import { thunks } from '@/bootstrap/thunks.ts';

type ElsBasketClipboardPasteProps = {
  rfqId: string;
  product: ElsBasket;
};

export const ElsBasketClipboardPaste = ({ product, rfqId }: ElsBasketClipboardPasteProps) => {
  const dispatch = useDispatch();

  function handleError(error: string) {
    dispatch(thunks.createErrorToasterThunk({ message: error }, undefined));
  }

  function handleClipboardData(importedData: unknown[]) {
    dispatch(neosThunks.createImportBasketCompositionThunk(rfqId, product.uuid, importedData));
  }

  return (
    <button
      onClick={() => {
        importFromExcelClipboardData(handleClipboardData, handleError);
      }}
      className="btn btn-icon btn-discreet-primary"
    >
      <i className="icon">content_paste</i>
    </button>
  );
};
