import type { FromOnyxMappers } from '@/neos/business/mappers';
import { convertNullToUndefined } from '@/util/undefinedAndNull/convertNullToUndefined';
import type { Future, StrikeDateTenor } from '../../productModel';
import type { OnyxProduct, OnyxSingleUnderlyingProduct } from '../../productOnyxModel';

export function mapFromOnyxProductFuture(
  legId: string,
  onyxProduct: OnyxSingleUnderlyingProduct,
  productUuid: string,
  mappers: FromOnyxMappers,
): Future {
  return {
    subFamily: 'FUTURE',
    pointValue: convertNullToUndefined(onyxProduct.pointValue),
    ...mappers.mapFromOnyxProductCommon(legId, onyxProduct, productUuid),
    ...mappers.mapFromOnyxFutureNegotiation(onyxProduct.negotiation),
    ...mappers.mapFromOnyxFutureStrikeDate(onyxProduct),
    ...mappers.mapFromSingleDerivativeOnyxProduct(onyxProduct, mappers),
    hasCustomUnderlying: false,
  };
}

export function mapFromOnyxFutureStrikeDate(onyxProduct: OnyxProduct): StrikeDateTenor {
  return {
    strikeDate: onyxProduct.strikeDate ?? undefined,
    strikeTenor: onyxProduct.strikeTenor ?? undefined,
  };
}
