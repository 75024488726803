import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { sortBy } from 'lodash';
import {
  type Barrier,
  type LimitObservationType,
  type NeosBarrierType,
} from '../../../../../../../neos/business/neosModel';
import type { FeatureComponentModel } from '../sharedFeatureComponentModel';

interface CommonBarrierModel {
  barrierIndex: number;
  hasCustomUnderlying: boolean;
  neosBarrierType: NeosBarrierType | undefined;
  maturity: string | undefined;
  limitStrikeValue: number | undefined;
  limitStrikeUnit: string | undefined;
  limitObservationType: LimitObservationType | undefined;
  gap?: number;
  rebate?: number;
  limitWindowDate?: { startDate: string; endDate: string };
}

interface CustomBarrierModel extends CommonBarrierModel {
  hasCustomUnderlying: true;
  underlyingName: string | undefined;
}

interface NonCustomBarrierModel extends CommonBarrierModel {
  hasCustomUnderlying: false;
  underlyingId: string | undefined;
  bloombergCode: string | undefined;
}

export type BarrierModel = CustomBarrierModel | NonCustomBarrierModel;
export type BarriersModel = FeatureComponentModel<{
  barriers: BarrierModel[];
  availableLimitObservationTypes: Array<LimitObservationType | undefined>;
  isReadonly: boolean;
}>;

export function getBarriersModel(
  appState: AppState,
  strategyId: string,
  selectors: Selectors,
): BarriersModel {
  const isDisplayed = selectors.isStrategyFeatureMandatoryOrDefined(
    appState,
    { strategyId, type: 'BARRIERS' },
    selectors,
  );

  if (!isDisplayed) {
    return { isDisplayed };
  }

  const feature = selectors.getFeature(appState.featureState, {
    strategyId,
    type: 'BARRIERS',
  });

  const { barriers: stateBarriers } = feature ? feature : { barriers: [] as Barrier[] };

  const barrierFieldNamesSortArray: Array<keyof CustomBarrierModel | keyof NonCustomBarrierModel> =
    [
      'neosBarrierType',
      'bloombergCode',
      'underlyingName',
      'maturity',
      'limitStrikeValue',
      'limitObservationType',
    ];
  const barriers = sortBy(
    stateBarriers.map((barrier, index): BarrierModel => {
      const commonBarrier: Omit<CommonBarrierModel, 'hasCustomUnderlying'> = {
        barrierIndex: index,
        neosBarrierType: barrier.neosBarrierType,
        limitStrikeUnit: barrier.limitStrikeUnit,
        limitStrikeValue: barrier.limitStrikeValue,
        maturity: barrier.maturity,
        limitObservationType: barrier.limitObservationType,
        gap: barrier.gap,
        rebate: barrier.rebate,
        limitWindowDate: barrier.limitWindowDate,
      };
      return barrier.underlyingType === 'CUSTOM'
        ? { hasCustomUnderlying: true, underlyingName: barrier.underlyingName, ...commonBarrier }
        : {
            hasCustomUnderlying: false,
            underlyingId: barrier.underlyingId,
            bloombergCode: selectors.getBloombergCode(appState, barrier.underlyingId, selectors),
            ...commonBarrier,
          };
    }),
    barrierFieldNamesSortArray,
  );

  const isReadonly = selectors.isReadOnlyAtCurrentWorkflow(appState, strategyId, selectors);
  return {
    isDisplayed,
    barriers,
    availableLimitObservationTypes: [undefined, 'EUROPEAN', 'CLOSE', 'EXTREMUM'],
    isReadonly,
  };
}
