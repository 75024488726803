import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { useDispatch, useSelector } from 'react-redux';
import {
  PredealChecksModalComponent,
  type PredealChecksModalDispatchProps,
  type PredealChecksModalOwnProps,
  type PredealChecksModalStateProps,
} from './PredealChecksModal';
import { getPredealChecksModalModel } from './getPredealChecksModalModel';

import { selectors } from '@/bootstrap/selectors';
import { neosThunks } from '@/neos/business/thunks';
import type { FC } from 'react';

export const PredealChecksModal: FC<PredealChecksModalOwnProps> = ownProps => {
  const stateProps = useSelector<AppState, PredealChecksModalStateProps>(appState =>
    getPredealChecksModalModel(appState, ownProps.rfqId, selectors),
  );
  const dispatch = useDispatch<ExtendedDispatch>();
  const dispatchProps = mapDispatchToProps(dispatch, ownProps);
  return <PredealChecksModalComponent {...ownProps} {...stateProps} {...dispatchProps} />;
};

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId }: PredealChecksModalOwnProps,
): PredealChecksModalDispatchProps {
  return {
    hideModal: () => {
      dispatch(neosThunks.createDerogatePredealChecksOnModalCloseThunk(rfqId));
      dispatch(
        neosActionCreators.rfqUiCrudActions.update(rfqId, { showPredealChecksModal: false }),
      );
    },
  };
}
