import { type Selectors, selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { isOptionLike } from '../../../../../../neos/business/neosModel';

import { constantToSentence } from '@/util/string/stringUtils';
import { useSelector } from 'react-redux';
import type { LegDescriptionComponentProps } from './AveragePrice';

export function getProductType(state: AppState, legId: string, selectors: Selectors) {
  const { productId } = selectors.getLegData(state, legId);
  const product = selectors.getProduct(state, productId);
  return isOptionLike(product) ? product.type : product.subFamily;
}

export function ProductType({ legId }: LegDescriptionComponentProps) {
  const productType = useSelector((state: AppState) => getProductType(state, legId, selectors));
  return (
    <div>
      <label className="mb-0 me-3">Type</label>
      <input
        className="form-control"
        data-e2e="product-type"
        value={constantToSentence(productType ?? '')}
        readOnly
      />
    </div>
  );
}
