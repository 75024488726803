import { type ActionCreators, actionCreators } from '@/bootstrap/actions';
import type { ThunkEpic } from '@/bootstrap/epics';
import { type Services, services } from '@/bootstrap/services';
import { type Thunks, thunks } from '@/bootstrap/thunks';
import { wrapInLoadingObservable } from '@/neos/business/epics/wrapInLoadingObservable';
import type { OnyxError } from '@/neos/business/mappers/error';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import {
  type HandleAllocationFileApi,
  makeHandleAllocationFileApi,
} from './handleAllocationFileApi';

export function getUploadAllocationFileEpic(http: SgmeHttp) {
  const api = makeHandleAllocationFileApi(http);
  return createUploadAllocationFileEpic(api, thunks);
}

export function getDownloadAllocationFileEpic(http: SgmeHttp) {
  const api = makeHandleAllocationFileApi(http);
  return createDownloadAllocationFileEpic(api, actionCreators, thunks, services);
}

export function createUploadAllocationFileEpic(
  api: HandleAllocationFileApi,
  { createErrorToasterThunk, createSuccessToasterThunk }: Thunks,
): ThunkEpic {
  return action$ =>
    action$.pipe(
      ofType('UPLOAD_ALLOCATION_FILE'),
      mergeMap(({ rfqId, allocId, file }) => {
        return wrapInLoadingObservable({
          tabIds: [rfqId],
          apiObservable: api.uploadFile(rfqId, allocId, file),
          onSuccess: () => createSuccessToasterThunk({ message: 'File successfully uploaded' }),
          onError: (error: OnyxError) => [
            createErrorToasterThunk(
              {
                message: 'Error when uploading file',
              },
              error,
            ),
          ],
        });
      }),
    );
}

export function createDownloadAllocationFileEpic(
  api: HandleAllocationFileApi,
  { neos: { createDownloadSuccessAction } }: ActionCreators,
  { createErrorToasterThunk }: Thunks,
  { downloadBlob }: Services,
): ThunkEpic {
  return action$ =>
    action$.pipe(
      ofType('DOWNLOAD_ALLOCATION_FILE'),
      mergeMap(({ rfqId, allocId }) => {
        return api.downloadFile(rfqId, allocId).pipe(
          map(({ blob, metadata }) => {
            downloadBlob(blob, metadata.fileName);
            return createDownloadSuccessAction();
          }),
          catchError((error: OnyxError) => {
            return of(
              createErrorToasterThunk(
                {
                  message: 'Error when downloading file',
                },
                error,
              ),
            );
          }),
        );
      }),
    );
}
