import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import {
  type RatioCellsDispatchProps,
  type RatioCellsMapStateProps,
  type RatioCellsOwnProps,
  RatioCells as RatioCellsUnconnected,
} from './RatioCells';
import { getRatioCellsModel } from './getRatioCellsModel';

export const RatioCells = connect(mapStateToProps, mapDispatchToProps)(RatioCellsUnconnected);

function mapStateToProps(
  state: AppState,
  { strategyId, rfqId }: RatioCellsOwnProps,
): RatioCellsMapStateProps {
  return getRatioCellsModel(state, rfqId, strategyId, selectors);
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId, strategyId }: RatioCellsOwnProps,
): RatioCellsDispatchProps {
  return {
    onRatioChanged(legId: string, weight: number | undefined) {
      dispatch(neosThunks.createRfqChangeLegWeightThunk(rfqId, strategyId, legId, weight));
    },
    onLegOverYes() {
      dispatch(neosThunks.createProceedToStrategyLegOverThunk(rfqId, strategyId));
    },

    onLegOverNo() {
      dispatch(
        neosActionCreators.strategyUiCrudActions.update(strategyId, {
          legOverMessageDismissed: true,
        }),
      );
    },
  };
}
