import type { Action } from '@/bootstrap/actions.ts';
import type { Thunk } from '@/bootstrap/thunks.ts';
import { assertProductIsEls } from '../../../../../strategyModel.ts';

export function createRemoveBreakFeePeriodThunk(strategyId: string, id: string): Thunk {
  return function removeBreakFeePeriodThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { productCrudActions },
      },
    },
  ) {
    const appState = getState();

    const product = selectors.getStrategyProduct(appState, strategyId, selectors);
    assertProductIsEls(product);

    const periods = product?.breakFeePeriods ?? [];

    const action: Action = productCrudActions.update(product.uuid, {
      breakFeePeriods: periods.filter(line => line.id !== id),
    });
    dispatch(action);
  };
}
