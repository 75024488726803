import type { Thunk } from '@/bootstrap/thunks';
import type { OnyxCounterparty } from '@/neos/business/rfq/rfqOnyxModel';

export function createIntegrateReceivedCounterPartThunk(
  rfqIds: string[],
  salesIds: string[],
  counterparts: OnyxCounterparty[],
): Thunk {
  return function integrateReceivedCounterPartThunk(
    dispatch,
    getState,
    {
      actionCreators: {
        neos: { counterpartsCrudActions },
      },
      thunks: {
        neos: { createAddExtraCounterPartThunk },
      },
      fromMappers: { mapFromOnyxCounterpart },
      selectors: { getCounterpartsBySales },
    },
  ) {
    const appState = getState();
    if (getCounterpartsBySales(appState, salesIds) === undefined) {
      dispatch(counterpartsCrudActions.insert(salesIds, counterparts.map(mapFromOnyxCounterpart)));
    }

    dispatch(createAddExtraCounterPartThunk(rfqIds, salesIds));
  };
}
