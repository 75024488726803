import {
  isElsProduct,
  type StockLoanComponent,
} from '@/neos/business/rfq/strategy/leg/product/productModel.ts';
import type { PortfolioWay } from '@/neos/business/rfq/strategy/leg/product/els/selectors/getElsStockLoanHedgePortfolioWay.ts';
import type { Dispatchable, Thunk } from '@/bootstrap/thunks.ts';

export function createUpdateElsStockLoanHedgeComponentPortfolioThunk(
  productId: string,
  value: StockLoanComponent[PortfolioWay],
  portfolioSource: PortfolioWay,
): Thunk {
  return function updateElsStockLoanHedgeComponentPortfolioThunk(
    dispatch,
    getState,
    { selectors, thunks },
  ) {
    const state = getState();
    const product = selectors.getProduct(state, productId);

    if (!isElsProduct(product)) {
      return;
    }

    const dispatchables: Dispatchable[] = [];

    product.stockLoanHedge?.stockLoanComponents.forEach((_, index) => {
      dispatchables.push(
        thunks.neos.createUpdateElsStockLoanComponentThunk(
          product.uuid,
          index,
          portfolioSource,
          value,
        ),
      );
    });

    const portfolioWay = selectors.getElsStockLoanHedgePortfolioWay(
      state,
      product.legId,
      selectors,
    );

    if (portfolioWay === portfolioSource) {
      dispatchables.push(
        thunks.neos.createUpdateElsStockLoanHedgeThunk(product.uuid, 'portfolio', value),
      );
    }

    dispatch(dispatchables);
  };
}
