import type { OnyxRfq } from '@/neos/business/rfq/rfqOnyxModel';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

const initialState: Record<string, OnyxRfq> = {};

export const lastOnyxTradeCompletedRfqSlice = createSlice({
  name: 'lastOnyxTradeCompletedRfq',
  initialState,
  reducers: {
    rfqReceived: (state, action: PayloadAction<OnyxRfq>) => {
      const { uuid } = action.payload;
      state[uuid] = action.payload;
    },
    deleteRfq: (state, action: PayloadAction<string>) => {
      delete state[action.payload];
    },
  },
});

export type GetLastTradeCompletedRfqAction = {
  type: 'GET_LAST_TRADE_COMPLETED_RFQ';
  rfqId: string;
};

export function createGetLastTradeCompletedRfqAction(
  rfqId: string,
): GetLastTradeCompletedRfqAction {
  return {
    type: 'GET_LAST_TRADE_COMPLETED_RFQ',
    rfqId,
  };
}

export const lastTradeCompletedRfqActionCreators = {
  ...lastOnyxTradeCompletedRfqSlice.actions,
  createGetLastTradeCompletedRfqAction,
};
