import type { SearchBrokerNotInPreferencesAction } from '@/neos/business/rfq/rfqActionCreators/searchBrokerNotInPreferencesActions';
import { uniq } from 'lodash';
import type { NoSuccessToasterChainableAction, RetryOnErrorAction } from '../neosActions';
import type { ActorsSetupAction } from './actorsSetup/actorSetupActions';
import { DefaultingScope, type OtcPreAlloc, type RecomputeScope } from './models';
import type { PreconfirmationEmailPreviewAction } from './preconfirmationEmailPreview/preconfirmationEmailPreviewAction';
import type { OnyxStrategy } from './rfqOnyxModel';
import type { StrategyAction } from './strategy/strategyActions';
import type { VersionsAction } from './versions/versionsActions';

export type RfqAction =
  | StrategyAction
  | ActorsSetupAction
  | VersionsAction
  | DefaultRfqRequested
  | DefaultRfqPreAllocRequested
  | RecomputeRfqRequested
  | IbChatRequested
  | PreconfirmationEmailPreviewAction
  | SearchBrokerNotInPreferencesAction;

export type StrategiesFilterer = (strategies: OnyxStrategy[]) => OnyxStrategy[];

export interface DefaultingOptionsParameters {
  overrideScopes?: DefaultingScope[];
  overrideIfInvalidScopes?: DefaultingScope[];
  enrichScopes?: DefaultingScope[];
  isSettlementOtcOverride?: boolean;
}

export const MANDATORY_OVERRIDE_SCOPES: DefaultingScope[] = [
  DefaultingScope.ROUNDING_STRATEGY,
  DefaultingScope.SETTLEMENT_LISTED,
];

export class DefaultingOptions {
  public get allScopes(): DefaultingScope[] {
    return [...this.overrideScopes, ...this.enrichScopes];
  }

  public strategiesFilterer?: StrategiesFilterer;

  public overrideScopes: DefaultingScope[];
  public overrideIfInvalidScopes: DefaultingScope[];
  public enrichScopes: DefaultingScope[];

  constructor(param: DefaultingOptionsParameters) {
    this.overrideScopes = [...MANDATORY_OVERRIDE_SCOPES, ...(param.overrideScopes || [])];

    if (param.isSettlementOtcOverride) {
      this.overrideScopes.push(DefaultingScope.SETTLEMENT_OTC);
    }
    this.overrideScopes = uniq(this.overrideScopes);

    this.overrideIfInvalidScopes = [...(param.overrideIfInvalidScopes || [])];

    this.enrichScopes = [...(param.enrichScopes || [])];

    if (!param.isSettlementOtcOverride) {
      this.enrichScopes.push(DefaultingScope.SETTLEMENT_OTC);
    }
    this.enrichScopes = uniq(this.enrichScopes);
  }
}

export interface DefaultRfqRequested extends NoSuccessToasterChainableAction, RetryOnErrorAction {
  type: 'DEFAULT_RFQ_REQUESTED';
  rfqId: string;
  options: DefaultingOptions;
}

export interface DefaultRfqPreAllocRequested {
  type: 'DEFAULT_RFQ_PRE_ALLOC_REQUESTED';
  rfqId: string;
  options: DefaultingOptions;
  preAllocBeforeDefaultingRequested: OtcPreAlloc[];
}

export interface RecomputeRfqRequested extends NoSuccessToasterChainableAction {
  type: 'RECOMPUTE_RFQ_REQUESTED';
  rfqId: string;
  scope: RecomputeScope;
}

export interface IbChatRequested {
  type: 'IBCHAT_REQUESTED';
  rfqId: string;
}
