import { AddStrategyButton } from './addStrategyButton/AddStrategyButton';
import { Cap } from './cap/Cap.container';
import { ClientWay } from './clientWay/ClientWay.container';
import { FairPriceButton } from './fairPriceButton/FairPriceButton.container';
import { getGlobalActionsModel } from './getGlobalActionsModel';
import { LegDetailsButton } from './legDetails/LegDetailsButton.container';
import { ManualPricingButton } from './manualPricing/ManualPricing.container';
import { PricesUnitButton } from './pricesUnit/PricesUnitButton';
import { QuoteType } from './quoteType/QuoteType.container';
import { TradeRecapButton } from './tradeRecap/TradeRecapButton.container';

import { RfqInternalSwitcher } from '@/neos/components/rfq/strategies/globalActions/RfqInternalSwitcher';
import { RfqSecondaryWidgetSection } from '@/neos/components/rfq/strategies/globalActions/secondaryEventWidget/RfqSecondaryWidgetSection';
import styles from './GlobalActions.module.scss';
import { StepIn } from './stepIn/StepIn';
import type { ExtendedDispatch } from '@/bootstrap/thunks.ts';
import { neosThunks } from '@/neos/business/thunks';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@/bootstrap/hooks.ts';
import { getIsPreConfWarningDisplayed } from '@/neos/components/rfq/strategies/globalActions/getIsPreConfWarningDisplayed.ts';
import { selectors } from '@/bootstrap/selectors.ts';
import { getIsSpotWarningDisplayed } from '@/neos/components/rfq/strategies/globalActions/getIsSpotWarningDisplayed.ts';
import { isEffectiveSettlementDatesOffsetEqual as checkIfEffectiveSettlementDatesOffsetEqual } from '@/neos/components/rfq/strategies/globalActions/isEffectiveSettlementDatesOffsetEqual.ts';

export interface GlobalActionsProps {
  rfqId: string;
}

function AlertWarningIcon() {
  return <i className="icon icon-sm me-2">warning</i>;
}

export function GlobalActions({ rfqId }: GlobalActionsProps) {
  const dispatch = useDispatch<ExtendedDispatch>();

  const {
    hasBookingSteps,
    isMidOutOfTraderPrices,
    areTraderPricesOutOfSalesPrices,
    doesSalesPricesMismatchExecutedPrices,
    isReadonlyRfq,
    isTraderDeltaFarFromFairDelta,
    isQuoteTypeDisplayed,
    isMixedRfq,
    isSecondaryRfq,
    areSecondaryFeatureTogglesEnabled,
    isEls,
    isSalesLinkDisplayed,
    isTrader,
    disableAddStrategyButton,
  } = useAppSelector(state => getGlobalActionsModel(state, rfqId, selectors));

  const isPreConfWarningDisplayed = useAppSelector(state =>
    getIsPreConfWarningDisplayed(state, rfqId, selectors),
  );
  const isSpotWarningDisplayed = useAppSelector(state =>
    getIsSpotWarningDisplayed(state, rfqId, selectors),
  );
  const isEffectiveSettlementDatesOffsetEqual = useAppSelector(state =>
    checkIfEffectiveSettlementDatesOffsetEqual(state, rfqId, selectors),
  );

  function addStrategies(number: number) {
    dispatch(neosThunks.createManyStrategiesAddedThunk(rfqId, number));
  }

  return (
    <section className={`${styles['globalActions']} d-flex mb-2 mt-1 text-nowrap`}>
      {!hasBookingSteps && (
        <AddStrategyButton
          tooltipText="Add strategy"
          availableValues={[1, 2, 3, 4, 5, 10]}
          onClick={() => addStrategies(1)}
          onSelect={addStrategies}
          isDisabled={isReadonlyRfq || disableAddStrategyButton}
        />
      )}
      <ClientWay rfqId={rfqId} />
      {isQuoteTypeDisplayed && <QuoteType rfqId={rfqId} />}
      <PricesUnitButton rfqId={rfqId} />

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(8, auto)',
          gridGap: '12px',
        }}
      >
        {isEls && isTrader && <RfqInternalSwitcher rfqId={rfqId} />}
        <FairPriceButton rfqId={rfqId} />
        <TradeRecapButton rfqId={rfqId} />
        <ManualPricingButton rfqId={rfqId} />
        <LegDetailsButton rfqId={rfqId} />
        <RfqSecondaryWidgetSection rfqId={rfqId} />
        <StepIn rfqId={rfqId} />
        <Cap rfqId={rfqId} />
      </div>

      {areSecondaryFeatureTogglesEnabled && isSecondaryRfq && (
        <div role="alert" className="alert alert-sm alert-outline-warning">
          <AlertWarningIcon />
          This RFQ is a Secondary event
        </div>
      )}
      {areSecondaryFeatureTogglesEnabled && isMixedRfq && (
        <div role="alert" className="alert alert-sm alert-outline-warning">
          <AlertWarningIcon />
          This RFQ contains at least one Secondary event
        </div>
      )}
      {areTraderPricesOutOfSalesPrices && (
        <div role="alert" className="alert alert-sm alert-warning">
          <AlertWarningIcon />
          Trader prices are not between sales prices.
        </div>
      )}
      {doesSalesPricesMismatchExecutedPrices && (
        <div role="alert" className="alert alert-sm alert-warning">
          <AlertWarningIcon />
          Negotiated prices mismatch Executed prices
        </div>
      )}
      {isMidOutOfTraderPrices && (
        <div role="alert" className="alert alert-sm alert-warning">
          <AlertWarningIcon />
          Fair mid is not between trader prices.
        </div>
      )}
      {isTraderDeltaFarFromFairDelta && (
        <div role="alert" className="alert alert-sm alert-warning">
          <AlertWarningIcon />
          Trader delta is not within a ±5% range around fair delta.
        </div>
      )}
      {isSalesLinkDisplayed && (
        <div role="alert" className="alert alert-sm alert-warning">
          <AlertWarningIcon />
          Sales coverage is not yet done.
        </div>
      )}
      {isPreConfWarningDisplayed && (
        <div role="alert" className="alert alert-sm alert-warning">
          <AlertWarningIcon />
          Pre Confirmation must be sent.
        </div>
      )}
      {isSpotWarningDisplayed && (
        <div role="alert" className="alert alert-sm alert-warning">
          <AlertWarningIcon />
          Spot is not confirmed.
        </div>
      )}
      {isEffectiveSettlementDatesOffsetEqual === false && (
        <div role="alert" className="alert alert-sm alert-warning">
          <AlertWarningIcon />
          Effective date offset is different from Settlement date offset.
        </div>
      )}
    </section>
  );
}
