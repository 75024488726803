import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { getDifferencesBetweenCurrentAndOnyxRfq } from '@/neos/business/neosSelectors/getDifferencesBetweenCurrentAndOnyxRfq';
import type { ToOnyxMappers } from '../mappers';
import type { RfqDifference } from '../neosModel';

export function getDifferencesBetweenCurrentAndNotificationRfq(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
  mappers: ToOnyxMappers,
): RfqDifference[] {
  const notificationRfq = selectors.getPendingNotificationRfq(state, rfqId);

  return getDifferencesBetweenCurrentAndOnyxRfq(state, rfqId, notificationRfq, selectors, mappers);
}
