import type { FromOnyxMappers } from '@/neos/business/mappers';
import type { DivSwap, Swaption } from '../../productModel';
import type { OnyxSingleUnderlyingProduct } from '../../productOnyxModel';

export function mapFromOnyxProductDivSwapSwaption(
  legId: string,
  subFamily: 'DIV_SWAP' | 'SWAPTION',
  onyxProduct: OnyxSingleUnderlyingProduct,
  productUuid: string,
  mappers: FromOnyxMappers,
): DivSwap | Swaption {
  return {
    subFamily,
    hasCustomUnderlying: false,
    ...mappers.mapFromOnyxProductCommon(legId, onyxProduct, productUuid),
    ...mappers.mapFromOnyxOtcNegotiation(),
    ...mappers.mapFromSingleDerivativeOnyxProduct(onyxProduct, mappers),
    description: onyxProduct.description ?? undefined,
    startDate: onyxProduct.startDate ?? undefined,
  };
}
