import type { ChainOptions, RetryOnErrorOptions } from '@/neos/business/neosActions';
import { DefaultingScope } from '../models';
import { DefaultingOptions } from '../rfqActions';
import { createDefaultRfqRequestedAction } from './defaultRfqRequestedAction';

export function createDefaultAllocationsRequestedAction(
  rfqId: string,
  chainOptions?: ChainOptions,
) {
  const options = new DefaultingOptions({
    overrideScopes: [
      DefaultingScope.LISTED_ALLOCATIONS,
      DefaultingScope.ALLOCATIONS,
      DefaultingScope.TRADE_RECAP,
    ],
  });
  const retryOptions: RetryOnErrorOptions = {
    retries: 1,
  };
  return createDefaultRfqRequestedAction(rfqId, options, chainOptions, retryOptions);
}
