import { actionCreators } from '@/bootstrap/actions';
import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { thunks } from '@/bootstrap/thunks';
import { useDispatch, useSelector } from 'react-redux';
import { calculationMethodValues } from './CalculationMethodModel';
import { getElsProductFields } from './getElsProductFields';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect.tsx';

type CalculationMethodProps = {
  rfqId: string;
  parentClassNames?: string;
  labelClassNames?: string;
  strategyId: string;
};

export function CalculationMethod({
  parentClassNames,
  labelClassNames,
  strategyId,
  rfqId,
}: CalculationMethodProps) {
  const dispatch = useDispatch();
  const { calculationMethod, onCalculationMethodChanged } = useSelector((state: AppState) =>
    getElsProductFields(rfqId, strategyId, state, selectors, actionCreators, thunks, dispatch),
  );
  const isReadonlyAtCurrentWorkflow = useAppSelector(state =>
    selectors.isReadOnlyAtCurrentWorkflow(state, strategyId, selectors),
  );

  return (
    <div className={parentClassNames ?? '' + ' els-field'} onMouseDown={e => e.stopPropagation()}>
      <label className={labelClassNames ?? ''}>Calculation Method</label>
      <NeosSelect
        readOnly={isReadonlyAtCurrentWorkflow}
        style={{ minWidth: '150px' }}
        value={calculationMethod}
        onChange={onCalculationMethodChanged}
        data-e2e="rate-block-calculation-method"
        addEmptyOption
        options={calculationMethodValues}
      />
    </div>
  );
}
