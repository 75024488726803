import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { NegotiationMode } from '../../../../neos/business/neosModel';

export interface IbChatModel {
  ibChat: string;
  ibChatNegotiationMode: NegotiationMode;
  availableNegotiationModes: NegotiationMode[];
  ibChatTrustLevel: number | undefined;
  ibChatTrustLevelStyleName: 'red' | 'orange' | 'green' | undefined;
}

export function getIbChatModel(state: AppState, rfqId: string, selectors: Selectors): IbChatModel {
  const ibChat = selectors.getIbChat(state.ui, rfqId) ?? '';
  const ibChatTrustLevel = selectors.getIbChatTrustLevel(state.ui, rfqId);
  const ibChatNegotiationMode = selectors.getIbChatNegotiationMode(state.ui, rfqId);
  const availableNegotiationModes: NegotiationMode[] = ['LISTED', 'OTC'];
  if (ibChatTrustLevel === undefined) {
    return {
      ibChat,
      ibChatNegotiationMode,
      availableNegotiationModes,
      ibChatTrustLevel: undefined,
      ibChatTrustLevelStyleName: undefined,
    };
  }
  const ibChatTrustLevelStyleName =
    ibChatTrustLevel <= 1 ? 'red' : ibChatTrustLevel <= 3 ? 'orange' : 'green';

  return {
    ibChat,
    ibChatNegotiationMode,
    availableNegotiationModes,
    ibChatTrustLevel,
    ibChatTrustLevelStyleName,
  };
}
