import type { Thunk } from '@/bootstrap/thunks';
import {
  type LegRecomputeChangedScope,
  LegRecomputeScope,
  type Quote,
  getLegRecomputeScope,
} from '../models';

interface LegQuoteUpdateThunkParamModel {
  rfqId: string;
  strategyId: string;
  legId: string;
  quoteId: string;
  quotePatch: Partial<Quote>;
  scope:
    | LegRecomputeChangedScope.TRADER_PRICE
    | LegRecomputeChangedScope.SALES_PRICE
    | LegRecomputeScope.CHANGE_DELTA;
}

export function createRfqUpdateLegQuoteThunk({
  rfqId,
  strategyId,
  legId,
  quoteId,
  quotePatch,
  scope,
}: LegQuoteUpdateThunkParamModel): Thunk {
  return function rfqUpdateLegQuoteThunk(
    dispatch,
    getState,
    {
      actionCreators: {
        neos: { quoteCrudActions, createActionWithRecompute },
      },
      thunks: {
        neos: { createDispatchIfBeforeOrderAcceptedThunk },
      },
      selectors: { getLegData, getStrategyData },
    },
  ) {
    const state = getState();
    const { isMasterStrategy } = getStrategyData(state, strategyId);
    const { isMaster: isMasterLeg } = getLegData(state, legId);

    const patchAction = quoteCrudActions.update(quoteId, quotePatch);

    const recomputeScope = getRecomputeScope(scope, isMasterStrategy, isMasterLeg);
    const withRecomputeAction = createActionWithRecompute(rfqId, recomputeScope, [patchAction]);

    dispatch(createDispatchIfBeforeOrderAcceptedThunk(rfqId, withRecomputeAction, patchAction));
  };
}
function getRecomputeScope(
  scope:
    | LegRecomputeChangedScope.TRADER_PRICE
    | LegRecomputeChangedScope.SALES_PRICE
    | LegRecomputeScope.CHANGE_DELTA,
  isMasterStrategy: boolean,
  isMasterLeg: boolean,
) {
  return scope === LegRecomputeScope.CHANGE_DELTA
    ? LegRecomputeScope.CHANGE_DELTA
    : getLegRecomputeScope({ scope, isMasterStrategy, isMasterLeg });
}
