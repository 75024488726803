import type { TraderGroupsReceivedAction } from './traderGroupsActions';
import type { TraderGroup } from './traderGroupsModel';

export const traderGroupsActionCreators = {
  traderGroupsReceived,
};
export function traderGroupsReceived(traderGroupList: TraderGroup[]): TraderGroupsReceivedAction {
  return {
    type: 'TRADER_GROUPS_RECEIVED',
    traderGroupList,
  };
}
