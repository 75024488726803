import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import type { RfqDifference } from '@/neos/business/neosModel';
import { getRfqDifferencesModalModel } from '@/neos/components/rfq/workflow/rfqDifferences/getRfqDifferencesModalModel';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import type { ColDef } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { Modal } from 'react-bootstrap';

const defaultColDef: ColDef<RfqDifference> = {
  resizable: true,
  wrapText: true,
  autoHeight: true,
  minWidth: 230,
  flex: 1,
};

const highlightedColumnDefs: ColDef<RfqDifference>[] = [
  {
    field: 'path',
    headerName: 'Field',
    flex: 2,
  },
  {
    field: 'currentValue',
    headerName: 'Current value',
  },
  {
    field: 'newValue',
    headerName: 'Last Trade Completed value',
  },
];
const detailsColumnDefs: ColDef<RfqDifference>[] = [
  {
    field: 'currentValue',
    headerName: 'Current value',
  },
  {
    field: 'newValue',
    headerName: 'Last Trade Completed value',
  },
];

interface RfqDifferencesModalProps {
  rfqId: string;
  onHide: () => void;
}

export const RfqDifferencesModal = ({ rfqId, onHide }: RfqDifferencesModalProps) => {
  const isDiffLoading = useAppSelector(state => selectors.isRfqDifferencesLoading(state.ui, rfqId));

  const { otherDifferences, highlightedDifferences } = useAppSelector(state =>
    getRfqDifferencesModalModel(state, rfqId, selectors),
  );

  return (
    <Modal size="xl" show={true} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Differences with latest Trade Completed RFQ</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isDiffLoading ? (
          <div className="text-center mb-4 mt-4">
            <div className="spinner" />
          </div>
        ) : (
          <div>
            <div
              style={{ minHeight: 300, maxHeight: 500 }}
              className="ag-theme-sg-bootstrap ag-theme-sg-bootstrap-condensed flex-grow-1 w-100 position-relative"
            >
              <AgGridReact<RfqDifference>
                suppressMovableColumns
                containerStyle={{ width: '100%', position: 'absolute' }}
                rowData={highlightedDifferences}
                columnDefs={highlightedColumnDefs}
                modules={[ClientSideRowModelModule]}
                defaultColDef={defaultColDef}
                overlayNoRowsTemplate="No differences to show"
              />
            </div>
            <h5 className="mt-3 mb-1">Other Changes</h5>
            <div
              style={{ minHeight: 300, maxHeight: 500 }}
              className="ag-theme-sg-bootstrap ag-theme-sg-bootstrap-condensed flex-grow-1 w-100 position-relative"
            >
              <AgGridReact<RfqDifference>
                suppressMovableColumns
                treeData
                containerStyle={{ width: '100%', position: 'absolute' }}
                rowData={otherDifferences}
                columnDefs={detailsColumnDefs}
                modules={[ClientSideRowModelModule, RowGroupingModule]}
                getDataPath={data => data.path.split('.').slice(1)} // Slice(1) to remove rfq level from the diff
                defaultColDef={defaultColDef}
                gridOptions={{
                  autoGroupColumnDef: {
                    headerName: 'Field',
                    flex: 2,
                    cellRendererParams: {
                      suppressCount: true,
                    },
                  },
                }}
                groupDefaultExpanded={0}
                overlayNoRowsTemplate="No other differences to show"
              />
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
