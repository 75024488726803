import { commonActionCreators } from '@/common/business/commonActionCreators';
import type { CommonAction } from '@/common/business/commonActions';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import type { NeosAction } from '@/neos/business/neosActions';
import { type LogAnalyticsAction, createLogAnalyticsAction } from './middlewares/analytics/actions';
import type { Dispatchable } from './thunks';

export interface NextAction {
  additionalNextAction?: Dispatchable;
}
export type Action = CommonAction | NeosAction | LogAnalyticsAction;

export const actionCreators = {
  common: commonActionCreators,
  neos: neosActionCreators,
  analytics: { createLogAnalyticsAction },
};

export type ActionCreators = typeof actionCreators;
