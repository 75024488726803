import type {
  AsianPeriod,
  AsianPeriodDate,
  AsianProduct,
} from '@/neos/business/rfq/strategy/leg/product/productModel';
import type {
  OnyxAsianPeriod,
  OnyxProduct,
} from '@/neos/business/rfq/strategy/leg/product/productOnyxModel';
import { idCreator } from '@/util/id';

export function mapFromOnyxPeriod(period: OnyxProduct['period']): AsianProduct['period'] {
  if (!period) {
    return undefined;
  }

  return {
    endDate: period.endDate,
    startDate: period.startDate,
    dates: period.dates
      .map(date => ({ ...date, uuid: idCreator.createId() }))
      .sort(compareAsianPeriodDates),
    frequency: mapFromOnyxPeriodFrequency(period.frequency),
    includeEndDate: period.includeEndDate,
  };
}

function mapFromOnyxPeriodFrequency(
  frequency: OnyxAsianPeriod['frequency'],
): AsianPeriod['frequency'] {
  if (!frequency) {
    return undefined;
  }

  switch (frequency) {
    case 'P1Y':
      return 'YEARLY';
    case 'P1M':
      return 'MONTHLY';
    case 'P1D':
      return 'DAILY';
    default:
      return undefined;
  }
}

export function compareAsianPeriodDates(a: AsianPeriodDate, b: AsianPeriodDate) {
  if (!a.date || !b.date) {
    return 0;
  }

  const dateA = new Date(a.date);
  const dateB = new Date(b.date);
  return dateA < dateB ? -1 : 1;
}
