import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';

export interface ResetAllocationsButtonModel {
  isDisabled: boolean;
}

export function getResetAllocationsButtonModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): ResetAllocationsButtonModel {
  const { strategyLegs, deltaLegs } = selectors.getAllExecutionStrategies(state, rfqId, selectors);
  return {
    isDisabled: !strategyLegs.length && !deltaLegs.length,
  };
}
