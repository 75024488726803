import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { allFieldsAreDefined } from '@/util/undefinedAndNull/allFieldsAreDefined';
import type { Barrier } from '../../../../../../../business/neosModel';

export interface AddBarrierButtonModel {
  isEnabled: boolean;
}

export function getAddBarrierButtonModel(
  appState: AppState,
  strategyId: string,
  selectors: Selectors,
): AddBarrierButtonModel {
  const feature = selectors.getFeature(appState.featureState, {
    strategyId,
    type: 'BARRIERS',
  });

  const barriers = feature?.barriers ?? [];

  const isEnabled =
    barriers.every(barrier => allMandatoryFieldsAreDefined(barrier)) ||
    selectors.isReadOnlyAtCurrentWorkflow(appState, strategyId, selectors);
  return {
    isEnabled,
  };
}

function allMandatoryFieldsAreDefined(barrier: Barrier): boolean {
  const underlyingField =
    barrier.underlyingType === 'NON_CUSTOM' ? barrier.underlyingId : barrier.underlyingName;
  const { neosBarrierType, maturity, limitStrikeValue, limitObservationType } = barrier;
  return allFieldsAreDefined({
    neosBarrierType,
    maturity,
    limitStrikeValue,
    limitObservationType,
    underlyingField,
  });
}
