import { useAppSelector } from '@/bootstrap/hooks';
import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { UnderlyingInput } from '@/neos/components/rfq/strategies/strategy/common';
import { useDispatch } from 'react-redux';
import type { Shortcut } from '../../rfq/shortcuts/shared/ShortcutButton';
import type { TypeaheadValue } from '../../share/typeahead';

export function UnderlyingPicker() {
  const underlyingList = useAppSelector(state =>
    selectors.getUnderlyingsShortcutsList(state.ui.userPreferences),
  );
  const dispatch = useDispatch<ExtendedDispatch>();
  const updateUnderlyingShortcutsList = (underlyingsList: Shortcut<string>[]) => {
    dispatch(
      createLogAnalyticsAction('NEOS USER PREFERENCES', 'Update Underlying Shortcuts List'),
      neosActionCreators.createUpdateUnderlyingShortcutsListAction(underlyingsList),
    );
  };

  return (
    <div className="row py-2">
      <div className="col-5">
        <label>Search by underlying</label>
        <UnderlyingInput
          inputClassName="fw-regular"
          componentId="neos-user-preferences-underlying-searchbox"
          data-e2e="neos-user-preferences-underlying-searchbox"
          underlyingId=""
          bloombergCode=""
          onChange={(selected: TypeaheadValue | undefined) => {
            if (
              selected &&
              underlyingList.length < 14 &&
              underlyingList.find(list => list.id === selected.value) === undefined
            ) {
              updateUnderlyingShortcutsList([
                ...underlyingList,
                {
                  id: selected.value,
                  label: selected.label,
                  'data-e2e': `neos-user-preferences-underlying-shortcut-${selected?.value}`,
                },
              ]);
            }
          }}
          shouldCleanInputAfterSelection
        />
        <button
          onClick={() => {
            updateUnderlyingShortcutsList([]);
          }}
          className="btn btn-link ps-0"
        >
          <u>Clear All</u>
        </button>
      </div>
      <div className="col-7 py-2 d-flex flex-wrap  align-items-center">
        {underlyingList.map(underlying => (
          <span
            key={underlying.id}
            data-e2e={`neos-user-preferences-underlying-tag-${underlying.id}`}
            onClick={() => {
              updateUnderlyingShortcutsList(underlyingList.filter(u => u.id !== underlying.id));
            }}
            className="badge c-pointer bg-secondary badge-dismissible mb-2 me-2"
          >
            <em className="c-pointer icon icon-xs pe-1">close</em>
            {underlying.label}
          </span>
        ))}
      </div>
    </div>
  );
}
