import { actionCreators } from '@/bootstrap/actions';
import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { isElsProduct } from '@/neos/business/rfq/strategy/leg/product/productModel';
import { ElsFeaturesContext } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures';
import { ElsBlocField } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/ElsBlocField';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';
import { moveElemInObjectArray } from '@/util/array/arrayUtils';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';

export function RelatedExchange() {
  const { strategyId } = useContext(ElsFeaturesContext);
  const dispatch = useDispatch();
  const product = useAppSelector(state =>
    selectors.getStrategyProduct(state, strategyId, selectors),
  );
  const relatedExchangeFields = useAppSelector(state =>
    selectors.getRelatedExchangeFields(state.referenceData),
  );

  if (!isElsProduct(product)) {
    return null;
  }

  const { uuid, relatedExchange } = product;
  const { update: updateProduct } = actionCreators.neos.productCrudActions;
  const relatedExchangeFieldsSelect = relatedExchangeFields
    .map(elem => {
      return {
        label: elem.id,
        value: elem.id,
      };
    })
    .sort((a, b) => a.value.localeCompare(b.value));

  //NOTE: to put None, Not Applicable, All Exchanges at the top of the list
  const indexOfNone = relatedExchangeFieldsSelect.map(e => e.value).indexOf('None');
  moveElemInObjectArray(relatedExchangeFieldsSelect, indexOfNone, 0);

  const indexOfNotApplicable = relatedExchangeFieldsSelect
    .map(e => e.value)
    .indexOf('Not Applicable');
  moveElemInObjectArray(relatedExchangeFieldsSelect, indexOfNotApplicable, 1);

  const indexOfAllExchanges = relatedExchangeFieldsSelect
    .map(e => e.value)
    .indexOf('All Exchanges');
  moveElemInObjectArray(relatedExchangeFieldsSelect, indexOfAllExchanges, 2);

  return (
    <ElsBlocField
      label="Related Exchange"
      renderInput={readOnly => (
        <NeosSelect
          readOnly={readOnly}
          addEmptyOption
          options={relatedExchangeFieldsSelect}
          onChange={value => dispatch(updateProduct(uuid, { relatedExchange: value }))}
          value={relatedExchange}
          data-e2e="other-section-relatedExchange"
        />
      )}
    />
  );
}
