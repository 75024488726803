import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { dateStringToIsoString, formatDateInTimeZone } from '@/util/date/dateFormatHelper';

export function useGetStringToLocalDateConvertor(): {
  stringToLocalDateConvertor: (date: string) => string;
} {
  const displayTimeZone = useAppSelector(state =>
    selectors.getDisplayTimeZone(state.ui.userPreferences),
  );

  return {
    stringToLocalDateConvertor: date =>
      formatDateInTimeZone(displayTimeZone, dateStringToIsoString(date)),
  };
}
