import { type ActionCreators, actionCreators } from '@/bootstrap/actions';
import type { ThunkEpic } from '@/bootstrap/epics';
import { type Selectors, selectors } from '@/bootstrap/selectors';
import { type Services, services } from '@/bootstrap/services';
import { type Thunks, thunks } from '@/bootstrap/thunks';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { ofType } from 'redux-observable';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { type ToOnyxMappers, mappers } from '../../mappers';
import type { OnyxError } from '../../mappers/error';
import { createInternalPreconfirmationApi } from './internalPreconfirmationApi';
import type { InternalPreconfirmationApi } from './internalPreconfirmationApiType';

export function getInternalPreconfirmationPreviewEpic(http: SgmeHttp) {
  const api = createInternalPreconfirmationApi(http);
  return createInternalPreconfirmationPreviewEpic(
    api,
    thunks,
    selectors,
    actionCreators,
    services,
    mappers.toOnyxMappers,
  );
}

export function createInternalPreconfirmationPreviewEpic(
  api: InternalPreconfirmationApi,
  { createErrorToasterThunk }: Thunks,
  { getInternalMailOptions }: Selectors,
  { neos: { rfqUiCrudActions } }: ActionCreators,
  { downloadBlob }: Services,
  toOnyxMappers: ToOnyxMappers,
): ThunkEpic {
  return (action$, state$) =>
    action$.pipe(
      ofType('INTERNAL_PRECONFIRMATION_EMAIL_PREVIEW_REQUESTED'),
      mergeMap(({ rfqId }) => {
        const appState = state$.value;
        const mailOptions = getInternalMailOptions(appState, rfqId);
        const enabledSendButtonAction = rfqUiCrudActions.patchOrInsert(rfqId, {
          isPreconfirmationButtonsDisabled: false,
        });

        const transaction = toOnyxMappers.mapToOnyxTransaction(
          state$.value,
          rfqId,
          selectors,
          toOnyxMappers,
        );

        return api
          .getInternalPreconfirmationEmailPreview(rfqId, { internal: mailOptions }, transaction)
          .pipe(
            map(({ blob, metadata }) => {
              downloadBlob(blob, metadata.fileName);
              return enabledSendButtonAction;
            }),
            catchError((error: OnyxError) => {
              return [
                createErrorToasterThunk(
                  {
                    message: 'Error downloading preview',
                  },
                  error,
                ),
                enabledSendButtonAction,
              ];
            }),
          );
      }),
    );
}
