import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { MdpRfqTimerStateProps } from '../MdpRfqTimer';

export interface RfqTimerModelBase {
  isMdpRfqTimerDisplay: boolean;
}

export interface TradeWebRfqTimerModel extends RfqTimerModelBase, MdpRfqTimerStateProps {
  isMdpRfqTimerDisplay: true;
}

export interface RegularRfqTimerModel extends RfqTimerModelBase {
  isMdpRfqTimerDisplay: false;
}

export type RfqTimerModel = TradeWebRfqTimerModel | RegularRfqTimerModel;

export function getMdpRfqOrderReceivedTimerModel(
  state: AppState,
  rfqId: string,
  neosSelectors: Selectors,
): RfqTimerModel {
  const {
    orderReceivedExpirationUTCTimestamp: expiry,
    orderReceivedUTCTimestamp: creationTime,
    status,
  } = neosSelectors.getRfqData(state, rfqId);

  const isDisplayForCurrentStatus = status === 'ORDER_RECEIVED';
  if (isDisplayForCurrentStatus && expiry && creationTime) {
    return {
      isMdpRfqTimerDisplay: true,
      expiry,
      creationTime,
    };
  }
  return {
    isMdpRfqTimerDisplay: false,
  };
}
