import type {
  AddRfqIdToPendingBlotterRfqNotificationsAction,
  ChangeBlotterPositionAction,
  EmptyPendingRfqIdsAction,
} from './blotterUiActions';
import type { BlotterPosition } from './blotterUiModel';

export const blotterUiActionCreators = {
  createAddRfqIdToPendingBlotterRfqNotificationsAction,
  createEmptyPendingRfqIdsAction,
  createChangeBlotterPositionAction,
};

function createAddRfqIdToPendingBlotterRfqNotificationsAction(
  pendingRfqId: string,
): AddRfqIdToPendingBlotterRfqNotificationsAction {
  return {
    type: 'ADD_RFQ_ID_TO_PENDING_BLOTTER_RFQ_NOTIFICATIONS_ACTION',
    pendingRfqId,
  };
}

function createEmptyPendingRfqIdsAction(): EmptyPendingRfqIdsAction {
  return {
    type: 'EMPTY_PENDING_RFQ_IDS_ACTION',
  };
}

function createChangeBlotterPositionAction(position: BlotterPosition): ChangeBlotterPositionAction {
  return {
    type: 'CHANGE_BLOTTER_POSITION',
    position,
  };
}
