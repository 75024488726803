import { createEmptyPendingBlotterRfqsThunk } from '.././blotter/thunks/emptyPendingBlotterRfqsThunk';
import { createToggleBlotterPinThunk } from '../blotter/thunks/createToggleBlotterPinThunk';
import { createPreconfirmationPreferencesGroupUpdatedThunk } from '../prefonfirmationPreferences/preconfirmationPreferencesGroupUpdatedThunk';
import {
  createRemoveReferencedRfqThunk,
  createUpsertReferencedRfqThunk,
} from '../referencedRfq/referencedRfqThunk';
import { createErrorsRemovedThunk } from './errorsRemovedThunk';
import { createFairPricesWarningsResetThunk } from './fairPricesWarningsResetThunk';
import { createIntegrateNotificationSubscriptionResultsThunk } from './integrateNotificationSubscriptionResultsThunk';
import { createKeepSavedGlobalDeltaThunk } from './keepSavedGlobalDelta';
import { createLegBlurredThunk, createLegFocusedThunk } from './legFocusChangedThunks';
import { createLegSizesUnitChangedThunk } from './legSizesUnitChangedThunk';
import { createNotificationGroupUpdatedThunk } from './notificationGroupUpdatedThunk';
import { createPreconfModalDisplayChangeThunk } from './preconfModalDisplayChangeThunk';
import { createSetNegoOrPostNegoScreenThunk } from './setNegoOrPostNegoScreenThunk';
import { createStrategyUiClonedThunk } from './strategyUiClonedThunk';
import { createUiRfqClonedThunk } from './uiRfqClonedThunks';

export const neosUiThunks = {
  createErrorsRemovedThunk,
  createFairPricesWarningsResetThunk,
  createStrategyUiClonedThunk,
  createUiRfqClonedThunk,
  createLegSizesUnitChangedThunk,
  createLegFocusedThunk,
  createLegBlurredThunk,
  createEmptyPendingBlotterRfqsThunk,
  createToggleBlotterPinThunk,
  createIntegrateNotificationSubscriptionResultsThunk,
  createNotificationGroupUpdatedThunk,
  createKeepSavedGlobalDeltaThunk,
  createSetNegoOrPostNegoScreenThunk,
  createUpsertReferencedRfqThunk,
  createRemoveReferencedRfqThunk,
  createPreconfirmationPreferencesGroupUpdatedThunk,
  createPreconfModalDisplayChangeThunk,
};
