import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { connect } from 'react-redux';
import {
  RfqCommentComponent,
  type RfqCommentDispatchProps,
  type RfqCommentOwnPropsProps,
  type RfqCommentStateProps,
} from './RfqComment';

export const RfqComment = connect(mapStateToProps, mapDispatchToProps)(RfqCommentComponent);

function mapStateToProps(
  state: AppState,
  { rfqId }: RfqCommentOwnPropsProps,
): RfqCommentStateProps {
  const { comment } = selectors.getRfqData(state, rfqId);
  const isReadonlyRfq = selectors.isReadOnlyRfq(state, rfqId);
  return {
    isReadonlyRfq,
    salesComment: comment,
  };
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId }: RfqCommentOwnPropsProps,
): RfqCommentDispatchProps {
  return {
    onCommentChanged: (comment: string) => {
      dispatch(neosActionCreators.rfqDataCrudActions.update(rfqId, { comment }));
    },
  };
}
