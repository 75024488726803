export interface DeltaHedgingButtonOwnProps {
  rfqId: string;
}

export interface DeltaHedgingButtonPropsFromState {
  isEnabled: boolean;
}

export interface DeltaHedgingButtonDispatchProps {
  onDeltaHedgingRequested(): void;
}

export type DeltaHedgingButtonProps = DeltaHedgingButtonOwnProps &
  DeltaHedgingButtonPropsFromState &
  DeltaHedgingButtonDispatchProps;

export const DeltaHedgingButtonComponent = (props: DeltaHedgingButtonProps) => (
  <button
    className="btn btn-primary"
    onClick={() => props.onDeltaHedgingRequested()}
    data-e2e="neos-rfq-delta-hedge-button"
    disabled={!props.isEnabled}
  >
    Delta Hedge
  </button>
);
