import { ErrorHighlight } from '@/neos/components/share/errorHighlight';
import { MultiToggleButton } from '@/neos/components/share/multiToggleButton/MultiToggleButton';
import type { FC } from 'react';
import type { ClientPosition } from '../../../../../../../neos/business/neosModel';
import type { ClientPositionCellsModel } from './getClientPositionCellsModel';

export interface ClientPositionCellsOwnProps {
  rfqId: string;
  strategyId: string;
}

export type ClientPositionCellsMapStateProps = ClientPositionCellsModel;

export interface ClientPositionCellsDispatchProps {
  onClientPositionChanged(legId: string, ClientPosition: ClientPosition | undefined): void;
}

type ClientPositionCellsProps = ClientPositionCellsOwnProps &
  ClientPositionCellsMapStateProps &
  ClientPositionCellsDispatchProps;

export const ClientPositionCellsComponent: FC<ClientPositionCellsProps> = props => {
  return (
    <section>
      {props.clientPositions.map(({ id, value, isEditable }) => (
        <ErrorHighlight
          key={id}
          errorField={'clientPosition'}
          related={{ rfqId: props.rfqId, legId: id, strategyId: props.strategyId }}
        >
          <MultiToggleButton<ClientPosition | undefined>
            className="form-control fw-bold"
            readOnly={!isEditable}
            selectedValue={value}
            valueFormatter={v => (v ? v.charAt(0) : '')}
            availableValues={props.availablePositions}
            onValueChange={newPosition => {
              props.onClientPositionChanged(id, newPosition);
            }}
            data-e2e="neos-strategy-definition-client-position"
          />
        </ErrorHighlight>
      ))}
    </section>
  );
};
