import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { Observable } from 'rxjs';

export interface TradeRecapMailCCRecipientsApi {
  getTradeRecapMailCCRecipients: (rfqId: string) => Observable<string[]>;
}

export const createTradeRecapMailCCRecipientsApi = (
  http: SgmeHttp,
): TradeRecapMailCCRecipientsApi => ({
  getTradeRecapMailCCRecipients(uuid: string) {
    const url: string = `api/preConfirmations/${uuid}/mailCCRecipients?type=INTERNAL`;
    return http.get<string[]>({
      url,
    });
  },
});
