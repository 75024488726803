import { crudDomain } from '@/util/crudUtils';
import type { PreConfirmationStep, PreConfirmationStepKey } from './PreConfirmationStepModel';

const compositeIdSeparator = ' | ';

function getPreConfirmationStepId({ rfqId, uuid }: PreConfirmationStepKey): string {
  return `${rfqId}${compositeIdSeparator}${uuid}`;
}

function getPreConfirmationStepKey(id: string): PreConfirmationStepKey {
  const [rfqId, uuid] = id.split(compositeIdSeparator);
  return { rfqId, uuid };
}

export const preConfirmationStepDomain = crudDomain<PreConfirmationStep, PreConfirmationStepKey>(
  'neos-pre-confirmation-step',
  {
    keyToString: getPreConfirmationStepId,
    stringToKey: getPreConfirmationStepKey,
  },
);

export const preConfirmationStepReducer = preConfirmationStepDomain.reducer;

export const preConfirmationStepSelectors = preConfirmationStepDomain.selectors;
