import { HubConnectionBuilder, type IRetryPolicy, LogLevel } from '@microsoft/signalr';
import { type ConnectableObservable, Observable, type Observer } from 'rxjs';
import { publish } from 'rxjs/operators';
import { sgwtConnect } from '../api/connect/connect';
import { logger } from '../logging/logger';
import { mt } from '../logging/messageTemplates';

export function createSessionStream(
  config: SessionStreamConfiguration,
): ConnectableObservable<SessionStreamEvent> {
  const bearer = () => sgwtConnect.getAuthorizationHeader()!.replace('Bearer ', '') || ' ';
  const retryPolicy: IRetryPolicy = {
    nextRetryDelayInMilliseconds: () => 4000,
  };
  const connection = new HubConnectionBuilder()
    .withAutomaticReconnect(retryPolicy)
    .configureLogging({
      log: (level, content) => {
        switch (level) {
          case LogLevel.Information:
            logger.info(mt.signalrLog, content);
            break;
          case LogLevel.Warning:
            logger.warn(mt.signalrLog, content);
            break;
          case LogLevel.Error:
          case LogLevel.Critical:
            logger.error(mt.signalrError, content);
            break;
        }
      },
    })
    .withUrl(config.hubUrl, {
      accessTokenFactory: bearer,
    })
    .build();

  const sessionStream: Observable<SessionStreamEvent> = new Observable(
    (observer: Observer<SessionStreamEvent>) => {
      // Register listeners on connection
      connection.on('ReceiveMessage', (messageType, data, _, __) => {
        observer.next({
          type: messageType,
          data,
        });
      });

      connection.onreconnected(connectionId => {
        logger.info(mt.sgconnectLog, `Connection reconnected with Id ${connectionId ?? 'N/A'}`);
        observer.next({ type: 'RECONNECT', data: connectionId });
      });

      // Start the connection
      connection
        .start()
        .then(() => {
          logger.info(
            mt.sgconnectLog,
            `Connection started with Id ${connection.connectionId ?? 'N/A'}`,
          );
        })
        .catch(err => {
          logger.error(mt.signalrError, err);
          observer.next({
            data: '',
            type: 'STARTERROR',
          });
        });

      return () => {
        connection.stop();
      };
    },
  );
  return sessionStream.pipe(publish()) as ConnectableObservable<SessionStreamEvent>;
}

export type DataStreamEvent = DataStreamData | DataStreamError;

export interface DataStreamData {
  type: 'DATA';
  dataType: string;
  requestId?: string;
  data: any;
}

export interface DataStreamError {
  type: 'ERROR';
  error: any;
}

export function isData(event: DataStreamEvent): event is DataStreamData {
  return event.type === 'DATA';
}

export function isError(event: DataStreamEvent): event is DataStreamError {
  return event.type === 'ERROR';
}

export function toDataStreamEvent(event: SessionStreamEvent): DataStreamEvent {
  try {
    return {
      type: 'DATA',
      dataType: 'undefined',
      requestId: undefined,
      data: event.data,
    };
  } catch (error) {
    logger.error(mt.signalrError, error);
    return {
      type: 'ERROR',
      error,
    };
  }
}

export interface SessionStreamEvent {
  type:
    | 'STARTERROR'
    | 'ERROR'
    | 'SESSIONERROR'
    | 'START'
    | 'DRIVER_CHANGE'
    | 'DISCONNECT'
    | 'RECONNECT'
    | 'DATA';
  data?: any;
}

export interface SessionStreamConfiguration {
  hubUrl: string;
  username?: string;
  drivers?: string[];
  applicationName?: string;
  sessionName?: string;
  openDriverTimeout?: number;
}
