import { TopLevelErrorToaster } from '@/neos/components/TopLevelErrorToaster';
import { If } from '@/neos/components/share/if/if';
import { useAppSelector } from '@/bootstrap/hooks';

export function TopLevelErrorMessage() {
  const isSignalRConnexionErrorDisplayed = useAppSelector(
    state => state.ui.topLevelError.isSignalRConnexionErrorDisplayed,
  );
  const isToggleFeatureErrorDisplayed = useAppSelector(
    state => state.ui.topLevelError.isToggleFeatureErrorDisplayed,
  );
  const isEliotMarketsErrorDisplayed =
    useAppSelector(state => state.referenceData.marketsEliot) === 'DEGRADED_FEATURE';

  const firstErrorPosition = 5;
  const featureToggleErrorPositionPixel = isSignalRConnexionErrorDisplayed
    ? firstErrorPosition + 30
    : firstErrorPosition;
  const eliotMarketsErrorPositionPixel = isToggleFeatureErrorDisplayed
    ? featureToggleErrorPositionPixel + 30
    : featureToggleErrorPositionPixel;

  return (
    <>
      <If condition={isSignalRConnexionErrorDisplayed}>
        <TopLevelErrorToaster
          title="Notifications are currently not working"
          message="The SignalR notification service is not working, please contact your support"
          position={firstErrorPosition}
        />
      </If>
      <If condition={isToggleFeatureErrorDisplayed}>
        <TopLevelErrorToaster
          title="Feature toggles could not be retrieved"
          message="Could not retrieve your enabled feature toggles, please contact your support"
          position={featureToggleErrorPositionPixel}
        />
      </If>
      <If condition={isEliotMarketsErrorDisplayed}>
        <TopLevelErrorToaster
          title="Eliot markets could not be retrieved"
          message="Eliot market is not working, please contact your support"
          position={eliotMarketsErrorPositionPixel}
        />
      </If>
    </>
  );
}
