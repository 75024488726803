import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { neosThunks } from '@/neos/business/thunks';
import { FlowDatePickerWithOffset } from '@/neos/components/share/datePicker/FlowDatePickerWithOffset';
import { ErrorHighlight } from '@/neos/components/share/errorHighlight';
import { useDispatch, useSelector } from 'react-redux';
import {
  type EffectiveDateCellsModel,
  getEffectiveDateCellsModel,
} from './getEffectiveDateCellsModel';
import {
  type IsEffectiveDateCellsModel,
  getIsEffectiveDateCellsEditableModel,
} from './getIsEffectiveDateCellsEditableModel';

export interface EffectiveDateCellsOwnProps {
  rfqId: string;
  strategyId: string;
  isReadOnlyRfq: boolean;
}

type EffectiveDateCellsProps = EffectiveDateCellsOwnProps;

export function EffectiveDateCells({ rfqId, strategyId, isReadOnlyRfq }: EffectiveDateCellsProps) {
  const dispatch = useDispatch();

  function onEffectiveDateChange(selectedDate: string) {
    dispatch(neosThunks.createUpdateProductEffectiveDateThunk(rfqId, strategyId, selectedDate));
  }

  function onEffectiveOffsetChange(selectedOffset: number) {
    dispatch(
      neosThunks.createUpdateProductEffectiveDateOffsetThunk(rfqId, strategyId, selectedOffset),
    );
  }

  const legId: string = useSelector(
    (state: AppState) => selectors.getStrategyMasterLeg(state, strategyId, selectors).uuid,
  );

  const { effectiveDate, effectiveDateOffset, isDisplayed }: EffectiveDateCellsModel = useSelector(
    (state: AppState) => getEffectiveDateCellsModel(state, strategyId, selectors),
  );

  const { isEditable }: IsEffectiveDateCellsModel = useSelector((state: AppState) =>
    getIsEffectiveDateCellsEditableModel(state, { strategyId, rfqId }, selectors),
  );

  if (!isDisplayed) {
    return null;
  }

  return (
    <section>
      <ErrorHighlight errorField={'effectiveDate'} related={{ rfqId, strategyId, legId }}>
        <FlowDatePickerWithOffset
          data-e2e="effective-date"
          date={effectiveDate ?? ''}
          onChange={onEffectiveDateChange}
          onOffsetChange={onEffectiveOffsetChange}
          currentOffset={effectiveDateOffset}
          readOnly={isReadOnlyRfq || !isEditable}
          hideCalendarIcon
        />
      </ErrorHighlight>
    </section>
  );
}
