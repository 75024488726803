import { hasDiscriminator } from '@/neos/business/rfq/strategy/feature/hasDiscriminator';
import type { WithExtraProperty } from '@/neos/business/rfq/strategy/feature/withExtraProperty';
import type { UpDownFeature } from '../../../../../../neos/business/neosModel';
import type {
  OnyxDownStrike,
  OnyxFeature,
  OnyxProductFeaturesFields,
  OnyxProductUnderlying,
  OnyxUpStrike,
} from '../../../../../../neos/business/neosOnyxModel';

export function mapFromOnyxExtraFeaturesToUpDown(
  extraFeatures: OnyxFeature[],
  strategyId: string,
): UpDownFeature | undefined {
  const upFeature: OnyxUpStrike | undefined = extraFeatures.find(
    hasDiscriminator<OnyxUpStrike>('UP_CONTAINER'),
  );
  const downFeature: OnyxDownStrike | undefined = extraFeatures.find(
    hasDiscriminator('DOWN_CONTAINER'),
  );
  if (upFeature || downFeature) {
    return {
      strategyId,
      type: 'UP_DOWN',
      upStrikeUnit: upFeature?.up?.strike?.unit,
      upStrikeValue: upFeature?.up?.strike?.value,
      upObservationConvention: upFeature?.up?.observationConvention ?? undefined,
      downStrikeUnit: downFeature?.down?.strike?.unit,
      downStrikeValue: downFeature?.down?.strike?.value,
      downObservationConvention: downFeature?.down?.observationConvention ?? undefined,
    };
  }
  return undefined;
}

export function mapFromOnyxFeatureToUpDown(
  productFields: OnyxProductFeaturesFields,
  strategyId: string,
): UpDownFeature | undefined {
  const { up, down } = productFields;
  if (!up && !down) {
    return undefined;
  }
  return {
    strategyId,
    type: 'UP_DOWN',
    upStrikeUnit: up?.strike?.unit,
    upStrikeValue: up?.strike?.value,
    upObservationConvention: up?.observationConvention ?? undefined,
    downStrikeUnit: down?.strike?.unit,
    downStrikeValue: down?.strike?.value,
    downObservationConvention: down?.observationConvention ?? undefined,
  };
}

export function mapFromOnyxFeatureOrExtraFeatureToUpDown(
  productFields: OnyxProductFeaturesFields,
  strategyId: string,
): UpDownFeature | undefined {
  const { extraFeatures } = productFields;
  const featureFromOnyxFeatures = mapFromOnyxFeatureToUpDown(productFields, strategyId);

  if (featureFromOnyxFeatures) {
    return featureFromOnyxFeatures;
  }
  if (extraFeatures) {
    return mapFromOnyxExtraFeaturesToUpDown(extraFeatures, strategyId);
  }
  return undefined;
}

export function mapToOnyxUpDown(
  feature: WithExtraProperty<UpDownFeature>,
  underlying: OnyxProductUnderlying | undefined,
): OnyxProductFeaturesFields {
  const {
    upStrikeUnit,
    upStrikeValue,
    upObservationConvention,
    downStrikeUnit,
    downStrikeValue,
    downObservationConvention,
  } = feature;

  const up: OnyxUpStrike = {
    discriminator: 'UP_CONTAINER',
    up: {
      strike: {
        unit: upStrikeUnit,
        value: upStrikeValue,
      },
      observationConvention: upObservationConvention || downObservationConvention,
      underlying,
    },
  };

  const down: OnyxDownStrike = {
    discriminator: 'DOWN_CONTAINER',
    down: {
      strike: {
        unit: downStrikeUnit,
        value: downStrikeValue,
      },
      observationConvention: downObservationConvention || upObservationConvention,
      underlying,
    },
  };

  if (feature.isExtra) {
    const extraFeatures: OnyxFeature[] = [];
    if (up?.up?.strike?.value !== undefined || down?.down?.strike?.value === undefined) {
      extraFeatures.push(up);
    }
    if (down?.down?.strike?.value !== undefined || up?.up?.strike?.value === undefined) {
      extraFeatures.push(down);
    }

    return {
      extraFeatures,
    };
  }
  return {
    up: up.up,
    down: down.down,
    extraFeatures: [],
  };
}
