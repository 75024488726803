import { referenceActionCreators } from '@/neos/business/rfq/reference/referenceActionCreators';
import { rfqActionCreators } from '@/neos/business/rfq/rfqActionCreators';
import { NumericInput } from '@/neos/components/share/numericInput';

import { useDispatch } from 'react-redux';
import type { ExecutedSpotModel } from './getExecutedSpotModel';

interface ExecutedSpotProps {
  underlyingsExecutedSpotModel: ExecutedSpotModel[];
  isDisplayed: boolean;
  rfqId: string;
}

export function ExecutedSpot({
  underlyingsExecutedSpotModel,
  isDisplayed,
  rfqId,
}: ExecutedSpotProps) {
  const dispatch = useDispatch();

  if (!isDisplayed) {
    return null;
  }

  return (
    <section>
      <div>Executed Spot</div>
      {underlyingsExecutedSpotModel.map(({ underlyingId, executedSpot, isReadonly }) => (
        <NumericInput
          key={underlyingId}
          value={executedSpot}
          readOnly={isReadonly}
          onBlur={value =>
            dispatch([
              referenceActionCreators.referenceCrudActions.update(
                { rfqId, underlyingId },
                { executedSpot: value },
              ),
              rfqActionCreators.createExecutedSpotChangedAction({ rfqId, underlyingId }, value),
            ])
          }
          data-e2e="neos-rfq-reference-executedSpot"
        />
      ))}
    </section>
  );
}
