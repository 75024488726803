import type { LoadVersionsRequestedAction } from './versionsActions';
import { versionsDomain } from './versionsDomain';

export const versionsActionCreators = {
  versionsCrudActions: versionsDomain.actions,
  createLoadVersionsRequestedAction,
};

function createLoadVersionsRequestedAction(rfqId: string): LoadVersionsRequestedAction {
  return {
    type: 'LOAD_VERSIONS_REQUESTED',
    rfqId,
  };
}
