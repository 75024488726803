import type {
  BrokenPeriodPosition,
  BrokenPeriodType,
  BusinessDayConvention,
  DividendPaymentDate,
  FixedDayType,
  GenerateFromType,
} from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel';

export const GenerateFromValues: Record<GenerateFromType, string> = {
  STRIKE_DATE: 'Strike Date',
  EFFECTIVE_DATE: 'Effective Date',
  FINAL_VALUATION_DATE: 'Maturity',
  FINAL_SETTLEMENT_DATE: 'Final Settlement Date',
} as const;

export const BusinessDayConventionValues: Record<BusinessDayConvention, string> = {
  NONE: 'None',
  PRECEDING: 'Preceding',
  FOLLOWING: 'Following',
  MODIFIED_FOLLOWING: 'Modified Following',
  CLOSED: 'Closed',
};

export const FixedDayValues: Record<FixedDayType, string> = {
  MON1: '1st Monday',
  TUE1: '1st Tuesday',
  WED1: '1st Wednesday',
  THU1: '1st Thursday',
  FRI1: '1st Friday',
  MON2: '2nd Monday',
  TUE2: '2nd Tuesday',
  WED2: '2nd Wednesday',
  THU2: '2nd Thursday',
  FRI2: '2nd Friday',
  MON3: '3rd Monday',
  TUE3: '3rd Tuesday',
  WED3: '3rd Wednesday',
  THU3: '3rd Thursday',
  FRI3: '3rd Friday',
  MON4: '4th Monday',
  TUE4: '4th Tuesday',
  WED4: '4th Wednesday',
  THU4: '4th Thursday',
  FRI4: '4th Friday',
  LAST: 'Last day of month',
  DAY1: '1st day',
  DAY2: '2nd day',
  DAY3: '3rd day',
  DAY4: '4th day',
  DAY5: '5th day',
  DAY6: '6th day',
  DAY7: '7th day',
  DAY8: '8th day',
  DAY9: '9th day',
  DAY10: '10th day',
  DAY11: '11th day',
  DAY12: '12th day',
  DAY13: '13th day',
  DAY14: '14th day',
  DAY15: '15th day',
  DAY16: '16th day',
  DAY17: '17th day',
  DAY18: '18th day',
  DAY19: '19th day',
  DAY20: '20th day',
  DAY21: '21st day',
  DAY22: '22nd day',
  DAY23: '23th day',
  DAY24: '24th day',
  DAY25: '25th day',
  DAY26: '26th day',
  DAY27: '27th day',
  DAY28: '28th day',
  DAY29: '29th day',
  DAY30: '30th day',
} as const;

export const BrokenPeriodPositionValues: Record<BrokenPeriodPosition, string> = {
  NONE: 'None',
  FIRST_PERIOD: 'First period',
  LAST_PERIOD: 'Last period',
  FIRST_AND_LAST_PERIODS: 'First and last periods',
};

export const BrokenPeriodTypeValues: Record<BrokenPeriodType, string> = {
  LONG_LONG: 'Long Long',
  SHORT_SHORT: 'Short Short',
  SHORT_LONG: 'Short Long',
  LONG_SHORT: 'Long Short',
};

export const DividendPaymentDateValues: Record<DividendPaymentDate, string> = {
  MATURITY_WITH_COMPOUND_RATE: 'At maturity with compound rate',
  DIVIDEND_PAYMENT_DATE: 'At dividend payment date',
  NEXT_RESET_PAYMENT_DATE: 'Next reset payment date',
  MATURITY: 'At maturity',
  EACH_EXECUTION_DATE: 'At each execution date',
};
