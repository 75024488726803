import { actionCreators } from '@/bootstrap/actions';
import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { thunks } from '@/bootstrap/thunks';
import { DefaultingScope, isClsProduct, isElsProduct } from '@/neos/business/neosModel';
import { DefaultingOptions } from '@/neos/business/rfq/rfqActions';
import type { BrokenPeriodType } from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';
import { useDispatch, useSelector } from 'react-redux';
import { BrokenPeriodTypeValues } from './ScheduleModel';

export function BrokenPeriod({ strategyId, rfqId }: { rfqId: string; strategyId: string }) {
  const dispatch = useDispatch();
  const isReadonly = useAppSelector(appState =>
    selectors.isReadOnlyAtCurrentWorkflow(appState, strategyId, selectors),
  );
  const product = useSelector((state: AppState) =>
    selectors.getStrategyProduct(state, strategyId, selectors),
  );
  if (!isElsProduct(product) && !isClsProduct(product)) {
    throw new Error('product is neither an els nor a cls');
  }

  function onBrokenPeriodTypeChanged(value: BrokenPeriodType | undefined) {
    dispatch(thunks.neos.changeElsIsScheduleObsoleteThunk(rfqId, product.uuid, true));
    dispatch(actionCreators.neos.productCrudActions.update(uuid, { brokenPeriod: value }));
    if (isClsProduct(product)) {
      const overrideScopes = [DefaultingScope.UNDERLYING];
      const defaultingOptions = new DefaultingOptions({ overrideScopes });
      dispatch(actionCreators.neos.createDefaultRfqRequestedAction(rfqId, defaultingOptions));
    }
  }

  const { brokenPeriod, uuid } = product;
  return (
    <>
      <label className="p-1">Broken Period Type</label>

      <NeosSelect
        options={Object.entries(BrokenPeriodTypeValues).map(([key, value]) => ({
          label: value,
          value: key as BrokenPeriodType,
        }))}
        readOnly={isReadonly}
        addEmptyOption
        onChange={onBrokenPeriodTypeChanged}
        value={brokenPeriod}
        data-e2e="schedule-broken-period-type"
      />
    </>
  );
}
