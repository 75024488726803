import { MiniAlert } from '../../../../../share/miniAlert/miniAlert';
import { NumericInput } from '../../../../../share/numericInput';
import type { RatioCellsModel } from './getRatioCellsModel';

import styles from './RatioCells.module.scss';

export interface RatioCellsOwnProps {
  rfqId: string;
  strategyId: string;
  isReadOnlyRfq: boolean;
}

export type RatioCellsMapStateProps = RatioCellsModel;

export interface RatioCellsDispatchProps {
  onRatioChanged(legId: string, ratio: number | undefined): void;
  onLegOverYes: () => void;
  onLegOverNo: () => void;
}

type RatioCellsProps = RatioCellsOwnProps & RatioCellsMapStateProps & RatioCellsDispatchProps;

export function RatioCells(props: RatioCellsProps) {
  return (
    <section>
      {props.ratios.map(({ id, value, isEditable, sign }) => {
        const warning: boolean = value === undefined ? false : value <= 0;
        const isReadOnly = !isEditable || props.isReadOnlyRfq;
        return (
          <div className={`${styles['ratio']}`} key={id}>
            <NumericInput
              value={value}
              warning={warning}
              onBlur={val => props.onRatioChanged(id, val)}
              readOnly={isReadOnly}
              onlyPositiveNumbers={sign === 'POSITIVE'}
              onlyNegativeNumbers={sign === 'NEGATIVE'}
              data-e2e="neos-strategy-definition-ratio"
            />
          </div>
        );
      })}
      {props.isLegOverEnabled ? (
        props.isMdpRfq ? (
          <MiniAlert lines={<span>Negative mid price</span>} />
        ) : (
          <MiniAlert
            lines={
              <span>
                Negative mid price -
                <br /> do you want to reverse Leg Over?
              </span>
            }
            fn={{ onYes: props.onLegOverYes, onNo: props.onLegOverNo }}
          />
        )
      ) : (
        <></>
      )}
    </section>
  );
}
