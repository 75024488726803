import type { Thunk } from '@/bootstrap/thunks';
import type { Counterpart } from '../../models';

import { isDefined } from '@/util/undefinedAndNull/isDefined';
import { differenceWith } from 'lodash';

export function createAddExtraCounterPartThunk(rfqIds: string[], salesIds: string[]): Thunk {
  return function integrateReceivedCounterPartThunk(
    dispatch,
    getState,
    {
      actionCreators: {
        neos: { counterpartsCrudActions },
      },
      thunks: {
        neos: { createEnsureValidPreAllocCounterpartsThunk },
      },
      selectors: { getSelectedCounterpart, getCounterpartsBySales },
    },
  ) {
    const appState = getState();
    const counterparts = getCounterpartsBySales(appState, salesIds) ?? [];

    const counterpartsFromRfq = rfqIds
      .map(rfqId => getSelectedCounterpart(appState, rfqId))
      .filter(isDefined);

    const predicat = (left: Counterpart, right: Counterpart) => left.id === right.id;

    const extraCounterparts = differenceWith(counterpartsFromRfq, counterparts, predicat);
    if (extraCounterparts.length > 0) {
      dispatch(
        counterpartsCrudActions.upsert(salesIds, [...counterparts, ...extraCounterparts]),
        createEnsureValidPreAllocCounterpartsThunk(rfqIds),
      );
    }
  };
}
