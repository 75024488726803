import type { Thunk } from '@/bootstrap/thunks';

export function createRfqChangePriceRequestTimeThunk(
  rfqId: string,
  newTime: string | undefined,
): Thunk {
  return function rfqChangePriceRequestTime(
    dispatch,
    getState,
    {
      selectors: { getDisplayTimeZone, getRfqData },
      actionCreators: {
        neos: { rfqDataCrudActions },
      },
    },
  ) {
    if (!newTime) {
      return dispatch(rfqDataCrudActions.update(rfqId, { priceRequestUTCDateAndTime: undefined }));
    }
    const appState = getState();
    const {
      ui: { userPreferences },
    } = getState();
    const displayTimeZone = getDisplayTimeZone(userPreferences);
    const { priceRequestUTCDateAndTime } = getRfqData(appState, rfqId);
    const [hours, minutes] = newTime.split(':');
    const priceRequestDate = priceRequestUTCDateAndTime
      ? new Date(priceRequestUTCDateAndTime)
      : new Date();
    if (displayTimeZone === 'LOCAL') {
      priceRequestDate.setHours(Number(hours), Number(minutes), 0, 0);
    } else {
      priceRequestDate.setUTCHours(Number(hours), Number(minutes), 0, 0);
    }

    return dispatch(
      rfqDataCrudActions.update(rfqId, {
        priceRequestUTCDateAndTime: priceRequestDate.toISOString(),
      }),
    );
  };
}
