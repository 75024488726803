import type { Thunk } from '@/bootstrap/thunks';
import type { ActionContainer } from '@/util/actionContainer';
import { type Quote, RfqRecomputeScope } from '../../neosModel';

export function createRfqChangeSalesCreditAmountThunk(
  rfqId: string,
  quotePatch: Partial<Quote>,
): Thunk {
  return function rfqChangeSalesCreditAmountThunk(
    dispatch,
    getState,
    {
      selectors: { getRfqData },
      actionCreators: {
        neos: { createRfqQuoteChangedAction, quoteCrudActions },
      },
    },
  ) {
    const state = getState();

    const { strategyIds } = getRfqData(state, rfqId);
    const actions: ActionContainer[] = [];
    strategyIds.forEach(strategyId => {
      return actions.push(quoteCrudActions.update(strategyId, quotePatch));
    });

    dispatch(actions);

    dispatch(
      createRfqQuoteChangedAction(rfqId, RfqRecomputeScope.CHANGE_RFQ_MARKUP_VALUE, quotePatch),
    );
  };
}
