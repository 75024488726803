import type { Thunk } from '@/bootstrap/thunks';
import type { MailRequest } from '@/common/business/mail/mailRequestModel';
import { isDefined } from '@/util/undefinedAndNull/isDefined';
import { uniq } from 'lodash';
import { type OnyxRfq, isOnyxBasketProduct } from '../rfq/rfqOnyxModel';

export function createRequestBulkManualPricingThunk(rfqIds: string[]): Thunk {
  return function requestBulkManualPricingThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { createMailRequestedAction },
        analytics: { createLogAnalyticsAction },
      },
    },
  ) {
    const { getCurrentUser, getBlotterRfqById } = selectors;
    const state = getState();
    const { email: mail } = getCurrentUser(state);
    const blotterRfqs = rfqIds
      .map(rfqId => getBlotterRfqById(state.blotter, rfqId))
      .filter(isDefined);
    const manualPricingRfqs: ManualPricingRfq[] = blotterRfqs.map(
      (blotterRfq): ManualPricingRfq => {
        const underlyingStratMaster = getUnderlyingStratMaster(blotterRfq);
        return {
          notionalCcy: blotterRfq.notional?.unit,
          notionalValue: blotterRfq.notional?.value,
          pivotNotionalCcy: blotterRfq.pivotNotional?.unit,
          pivotNotionalValue: blotterRfq.pivotNotional?.value,
          quoteRecap: blotterRfq.quoteRecap,
          uuid: blotterRfq.uuid,
          salesCounterpartyName: blotterRfq.salesCounterparty?.name ?? '',
          underlyingStratMaster,
          rfqComment: blotterRfq.comment,
          strategyComments: blotterRfq.strategies.map(({ comment }) => comment),
          deltaStrategyComments: (blotterRfq.deltas ?? []).map(({ comment }) => comment),
        };
      },
    );

    const mailSubject = getBulkMailSubject(manualPricingRfqs);
    const body = getMailBody(manualPricingRfqs);

    const mailRequest: MailRequest = {
      body,
      from: mail,
      to: [mail],
      cc: [],
      isBodyHtml: true,
      subject: mailSubject,
    };

    dispatch(
      createMailRequestedAction(
        mailRequest,
        'Manual pricing successfully sent!',
        'Error sending manual pricing',
      ),
      createLogAnalyticsAction('NEOS BLOTTER', 'Manual Pricing'),
    );
  };
}

function getUnderlyingStratMaster({ strategies }: OnyxRfq) {
  return (
    strategies
      .find(s => s.master)
      ?.legs.map(leg => {
        if (isOnyxBasketProduct(leg.product)) {
          return 'BASKET';
        }
        return leg?.product?.underlying?.bloombergCode ?? leg?.product?.underlying?.name;
      })
      ?.toString() ?? ''
  );
}

export function createRequestManualPricingFromRfqThunk(rfqId: string): Thunk {
  return function requestManualPricingFromRfqThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { createMailRequestedAction },
        analytics: { createLogAnalyticsAction },
      },
    },
  ) {
    const { getCurrentUser } = selectors;
    const state = getState();
    const { email: mail } = getCurrentUser(state);
    const {
      notionalCurrency,
      notionalAmount,
      pivotNotionalCurrency,
      pivotNotionalAmount,
      quoteRecap,
      comment,
      strategyIds,
      deltaHedgingStrategyIds,
    } = selectors.getRfqData(state, rfqId);
    const salesCounterpart = selectors.getSalesCounterpart(state, rfqId, selectors);
    const masterStrategy = selectors.getRfqMasterStrategy(state, rfqId);
    const underlyingLabel = selectors.getStrategyMasterUnderlyingLabel(
      state,
      masterStrategy.uuid,
      selectors,
    );
    const manualPricingRfq: ManualPricingRfq = {
      notionalCcy: notionalCurrency,
      notionalValue: notionalAmount,
      pivotNotionalCcy: pivotNotionalCurrency,
      pivotNotionalValue: pivotNotionalAmount,
      quoteRecap,
      uuid: rfqId,
      salesCounterpartyName: salesCounterpart ? salesCounterpart!.name : '',
      underlyingStratMaster: underlyingLabel ?? '',
      rfqComment: comment,
      strategyComments: strategyIds.map(sId => selectors.getStrategyData(state, sId).comment),
      deltaStrategyComments: deltaHedgingStrategyIds.map(
        sId => selectors.getStrategyData(state, sId).comment,
      ),
    };
    const mailSubject = getMailSubject(manualPricingRfq);
    const body = getMailBody([manualPricingRfq]);

    const mailRequest: MailRequest = {
      body,
      from: mail,
      to: [mail],
      cc: [],
      isBodyHtml: true,
      subject: mailSubject,
    };
    dispatch(
      createMailRequestedAction(
        mailRequest,
        'Manual pricing successfully sent!',
        'Error sending manual pricing',
      ),
      createLogAnalyticsAction('NEOS RFQ', 'Manual Pricing'),
    );
  };
}

export interface ManualPricingRfq {
  uuid: string;
  salesCounterpartyName: string;
  notionalCcy: string | undefined;
  notionalValue: number | undefined;
  pivotNotionalCcy: string | undefined;
  pivotNotionalValue: number | undefined;
  quoteRecap: string | undefined;
  underlyingStratMaster: string | undefined;
  strategyComments: (string | undefined)[];
  deltaStrategyComments: (string | undefined)[];
  rfqComment: string | undefined;
}

function getBulkMailSubject(manualPricingRfqs: ManualPricingRfq[]) {
  const selectedRequestsCount = manualPricingRfqs.length;
  const salesCounterpartyNames = uniq(
    manualPricingRfqs.map(manualPricingRfq => manualPricingRfq.salesCounterpartyName),
  );

  let result = `[NEOS] ${selectedRequestsCount} price request${
    selectedRequestsCount > 1 ? 's' : ''
  } on ${uniq(manualPricingRfqs.map(manualPricingRfq => manualPricingRfq.underlyingStratMaster))
    .map(underlyingStratMaster => `[${underlyingStratMaster || 'N/A'}]`)
    .join(', ')}`;

  if (salesCounterpartyNames.length === 1 && salesCounterpartyNames[0] !== '') {
    result += ` for ${truncateToNextWhiteSpace(salesCounterpartyNames[0], 25)}`;
  }

  return result;
}

function getMailSubject({ salesCounterpartyName, underlyingStratMaster }: ManualPricingRfq) {
  return `[NEOS] 1 price request on ${underlyingStratMaster}${
    salesCounterpartyName ? ' for ' + truncateToNextWhiteSpace(salesCounterpartyName, 25) : ''
  }`;
}

function truncateToNextWhiteSpace(sentence: string, index: number) {
  const whiteSpaceIndex = sentence.indexOf(' ', index);
  const endIndex = whiteSpaceIndex < 0 ? index : whiteSpaceIndex;
  return sentence.substr(0, endIndex);
}

function getMailBody(manualPricingRfqs: ManualPricingRfq[]) {
  return manualPricingRfqs
    .map(request => {
      const { uuid, salesCounterpartyName, quoteRecap } = request;
      const counterpartString = `Counterpart: ${salesCounterpartyName || ''}`;
      const quoteRecapString = `Quote recap: ${quoteRecap || ''}`;
      const notionalsString = getNotionalsString(request);
      const commentsString = getCommentsString(request);
      const rfqIdString = `(NEOS RFQ ID: ${uuid})`;

      return [counterpartString, quoteRecapString, notionalsString, commentsString, rfqIdString]
        .filter(str => str.length)
        .join('<br /><br />');
    })
    .join('<br /><hr /><br />');
}

function getNotionalsString({
  notionalCcy,
  notionalValue,
  pivotNotionalCcy,
  pivotNotionalValue,
}: ManualPricingRfq): string {
  const getNotionalString = (
    ccy: string | undefined,
    value: number | undefined,
    notionalPrefix = '',
  ): string => `${notionalPrefix}Notional: ${ccy ?? ''} ${value ?? ''}`;

  const notionalString = getNotionalString(notionalCcy, notionalValue);
  const pivotNotionalString =
    notionalCcy !== pivotNotionalCcy
      ? getNotionalString(pivotNotionalCcy, pivotNotionalValue, 'Pivot ')
      : undefined;

  return [notionalString, pivotNotionalString].filter(n => !!n).join('<br />');
}

function getCommentsString({
  strategyComments,
  deltaStrategyComments,
  rfqComment,
}: ManualPricingRfq) {
  const rfqCommentString = rfqComment ? `RFQ Comment: ${rfqComment}<br />` : '';
  const strategyCommentsString = strategyComments
    .map((comm, i) => ({ comm, i, prefix: 'Strategy' }))
    .concat(deltaStrategyComments.map((comm, i) => ({ comm, i, prefix: 'Delta Strategy' })))
    .filter(({ comm }) => comm)
    .map(({ prefix, i, comm }) => `${prefix} ${i + 1} comment: ${comm}<br />`)
    .join('')
    .slice(0, -6);

  return rfqCommentString + strategyCommentsString;
}
