import { type ActionCreators, actionCreators } from '@/bootstrap/actions';
import type { ThunkEpic } from '@/bootstrap/epics';
import { type Selectors, selectors } from '@/bootstrap/selectors';
import { type Thunks, thunks } from '@/bootstrap/thunks';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { ofType } from 'redux-observable';
import type { Observable } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { type ToOnyxMappers, mappers } from '../../mappers';
import type { OnyxError } from '../../mappers/error';
import type { OnyxTransaction } from '../../neosOnyxModel';
import { createPredealChecksRefreshApi } from '../apis';
import type { OnyxPredealCheckKey } from '../predealCheckOnyxModel';

export interface PredealChecksRefreshApi {
  refreshPredealChecks: (
    rfqId: string,
    transaction: OnyxTransaction,
  ) => Observable<OnyxPredealCheckKey[]>;
}

export function getRefreshPredealChecksEpic(http: SgmeHttp): ThunkEpic {
  const api = createPredealChecksRefreshApi(http);
  return createRefreshPredealChecksEpic(
    api,
    selectors,
    actionCreators,
    thunks,
    mappers.toOnyxMappers,
  );
}

export function createRefreshPredealChecksEpic(
  api: PredealChecksRefreshApi,
  selectorsParameter: Selectors,
  { common: { createAppCrashedAction } }: ActionCreators,
  { createErrorToasterThunk, neos: { createIntegratePredealChecksRefreshThunk } }: Thunks,
  toOnyxMappers: ToOnyxMappers,
): ThunkEpic {
  return (action$, state$) =>
    action$.pipe(
      ofType('NEOS_PREDEAL_CHECK_REFRESH_REQUESTED'),
      mergeMap(({ rfqId }) => {
        return api
          .refreshPredealChecks(
            rfqId,
            toOnyxMappers.mapToOnyxTransaction(
              state$.value,
              rfqId,
              selectorsParameter,
              toOnyxMappers,
            ),
          )
          .pipe(
            mergeMap(predealChecks => [
              createIntegratePredealChecksRefreshThunk(rfqId, predealChecks),
            ]),
            catchError((error: OnyxError) => {
              return [
                createErrorToasterThunk({ message: 'An error occured when refreshing pdc' }, error),
              ];
            }),
          );
      }),
      catchError(error => [createAppCrashedAction('neos-refresh-predeal-checks', error)]),
    );
}
