import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { useSelector } from 'react-redux';
import { AllocationsComponent, type AllocationsOwnProps } from './Allocations';
import { type AllocationsModel, getAllocationsModel } from './getAllocationsModel';

export const Allocations = (ownProps: AllocationsOwnProps) => {
  const stateProps = useSelector<AppState, AllocationsModel>(state =>
    getAllocationsModel(state, ownProps.rfqId, selectors),
  );

  return <AllocationsComponent {...ownProps} {...stateProps} />;
};
