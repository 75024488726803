import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { rfqUiActionCreators } from '@/neos/business/ui/rfq/rfqUiActionCreators';
import type { ActionContainer } from '@/util/actionContainer';
import type { IbChatRequested } from '../rfqActions';
import { createIbChatRequestedAction } from './ibChatRequestedAction';

export function createIbChatWithClearLevelRequestedAction(rfqId: string): ActionContainer {
  const ibChatRequestedAction: IbChatRequested = createIbChatRequestedAction(rfqId);
  return [
    createLogAnalyticsAction('NEOS RFQ', `Rfq ib chat requested`),
    ibChatRequestedAction,
    rfqUiActionCreators.rfqUiCrudActions.patchOrInsert(rfqId, { ibChatTrustLevel: undefined }),
  ];
}
