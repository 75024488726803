import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { Broker } from '@/neos/business/referenceData/brokers/brokersModel';
import { referenceSelectors } from '@/neos/business/rfq/reference/referenceSelectors';
import type { StateMap } from '@/util/collectionHelper';
import { isDateInToday } from '@/util/date/dateFormatHelper';
import { isDefined } from '@/util/undefinedAndNull/isDefined';
import { flatMap, flatten, max, uniq } from 'lodash';
import type { UnderlyingInfo } from '../../neosModel';
import type { OrderData } from '../../order/orderModel';
import { counterpartsSelectors } from '../counterparts/counterpartsSelectors';
import { extraCounterpartsSelectors } from '../extraCounterparts/extraCounterpartsSelectors';
import type { ForexRate } from '../forexRates/forexRatesOnyxModel';
import { hedgeSelectors } from '../hedge/hedgeSelector';
import {
  type ActorsSetup,
  type Contact,
  type Counterpart,
  type EventType,
  type FairPrice,
  type GenericSizeWithQuantity,
  isRfqStrategyData,
  type LegData,
  type LegIdsByStrategyId,
  type NegotiationMode,
  type Product,
  type Quote,
  type RfqData,
  type SecondaryEvent,
  type StrategyData,
} from '../models';
import { preconfirmationEmailPreviewSelectors } from '../preconfirmationEmailPreview/preconfirmationEmailPreviewSelectors';
import { quoteSelectors } from '../quotes/quote.selectors';
import type { DataAggregationByUnderlyingId, SalesDiffusionFeatureToggles } from '../rfqOnyxModel';
import { strategySelectors } from '../strategy/strategySelectors';
import type { Version, Versions } from '../versions/versionsModel';
import { getLegWay } from './getLegWay';
import { getNotAggregatedReferences } from './getNotAggregatedReferences';
import { getRfqProductNearestStrike } from './getRfqProductNearestStrike';
import { getSortedDeltaSummaries } from './getSortedDeltaSummaries';
import { getSortedHedges } from './getSortedHedges';
import { getRfqAndDeltaStrategiesUnderlyingIds } from './rfqAndDeltaStrategiesUnderlyingIds';

export const rfqSelectors = {
  ...strategySelectors,
  ...referenceSelectors,
  ...quoteSelectors,
  ...counterpartsSelectors,
  ...extraCounterpartsSelectors,
  ...hedgeSelectors,
  ...preconfirmationEmailPreviewSelectors,
  getAllExecutionStrategies,
  getAllExecutionDeltaStrategies,
  getListedExecutionStrategies,
  getOtcExecutionStrategies,
  getRfqSecondaryEvent,
  getListedExecutionRfqStrategies,
  getListedExecutionDeltaStrategies,
  getOtcExecutionRfqStrategies,
  getOtcExecutionDeltaStrategies,
  getRfqProductNearestStrike,
  getActorsSetup,
  isValidStrategyForImplicitUnwind,
  getRfqData,
  doesRfqExist,
  getIsMdpRfq,
  getQuote,
  isSecondaryRfq,
  isSecondaryOrMixedRfq,
  isMixedRfq,
  getSelectedSalesValoId,
  getSelectedSalesCounterpartId,
  getSelectedCounterpartId,
  getSelectedCounterpart,
  getElsRfqBrokerInfo,
  getVersions,
  getVersion,
  getStrategiesFairPriceQuoteIds,
  getRfqQuoteIds,
  getStrategiesQuoteIds,
  getRfqFairPriceIds,
  getStrategiesFairPriceIds,
  hasRfqFairPrices,
  getFairPrice,
  getLatestVersion,
  getForexRates,
  getRfqMasterStrategy,
  getNotAggregatedReferences,
  isDeltaTypeEditableForStrategy,
  getRfqAndDeltaStrategiesUnderlyingIds,
  getSalesInitId,
  getSalesDiffusionFeatureToggles,
  getSalesPhysicalLocation,
  getContacts,
  getSalesCounterpart,
  deltaStrategyExists,
  getRfqProducts,
  getRfqLegIds,
  getRfqMasterProduct,
  isReadOnlyRfq,
  getRfqLegIdsByStrategyIds,
  getRfqLegIdsByDeltaStrategyIds,
  getRfqHasFairPrices,
  getSizeOfMasterLegOfMasterStrategy,
  getMasterUnderlyingInfo,
  getDataAggregationByUnderlyingId,
  getSalesInitCityLocation,
  isRfqCustomUnderlying,
  getLegWay,
  areNotificationDifferencesDisplayed,
  getSortedHedges,
  getSortedDeltaSummaries,
  isTradeRecapEnabled,
  getRfqAndDeltaProducts,
  getStrategyAveragePrice,
  isThereAtLeastOneStratListed,
  isRfqInternalEls,
  isInternalElsRfqInitiatedByTrader,
  isRfqMasterStrategyEls,
  getSalesLink,
  getRfqEventType,
  isTradeDateToday,
  isRfqEls,
  isRfqInitiatedByTrader,
};

function deltaStrategyExists(state: AppState, rfqId: string, selectors: Selectors): boolean {
  const { deltaHedgingStrategyIds } = selectors.getRfqData(state, rfqId);
  return deltaHedgingStrategyIds.length > 0;
}

function getActorsSetup(state: AppState, rfqId: string): ActorsSetup | undefined {
  return state.actorsSetup[rfqId];
}

function isValidStrategyForImplicitUnwind(
  state: AppState,
  rfqId: string,
  strategyId: string,
  negotiationMode: NegotiationMode,
): boolean {
  return (
    (state.rfqDataState[rfqId].strategyIds.includes(strategyId) && negotiationMode === 'OTC') ||
    false
  );
}

function getRfqData(state: AppState, rfqId: string): RfqData {
  return state.rfqDataState[rfqId] || {};
}

function doesRfqExist(state: AppState, rfqId: string): boolean {
  return !!state.rfqDataState[rfqId];
}

function getIsMdpRfq(state: AppState, rfqId: string): boolean {
  const { source } = getRfqData(state, rfqId);
  return source !== undefined && source !== 'VOICE';
}

function getDataAggregationByUnderlyingId(
  state: AppState,
  rfqId: string,
  underlyingId: string,
): DataAggregationByUnderlyingId | undefined {
  const { dataAggregationByUnderlyingId } = getRfqData(state, rfqId);
  return (
    dataAggregationByUnderlyingId &&
    dataAggregationByUnderlyingId[underlyingId] &&
    dataAggregationByUnderlyingId[underlyingId]
  );
}

export function isTradeDateToday(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): boolean | undefined {
  const { tradeDate } = selectors.getRfqData(state, rfqId);

  if (!tradeDate) {
    return undefined;
  }

  return isDateInToday(tradeDate);
}

function getQuote(state: AppState, quoteId: string): Quote {
  return state.quotesState[quoteId];
}

function isMixedRfq(state: AppState, rfqId: string): boolean {
  return state.rfqDataState[rfqId]?.activity === 'MIXED';
}

function getSalesLink(state: AppState, rfqId: string): boolean | undefined {
  return state.rfqDataState[rfqId]?.salesLink;
}

function isSecondaryRfq(state: AppState, rfqId: string): boolean {
  return state.rfqDataState[rfqId]?.activity === 'SECONDARY';
}

function isRfqInitiatedByTrader(state: AppState, rfqId: string): boolean {
  return !!state.rfqDataState[rfqId]?.initiatedByTrader;
}

function isSecondaryOrMixedRfq(state: AppState, rfqId: string): boolean {
  return isSecondaryRfq(state, rfqId) || isMixedRfq(state, rfqId);
}

function getSelectedSalesValoId(state: AppState, rfqId: string): string | undefined {
  return state.actorsSetup[rfqId] && state.actorsSetup[rfqId].salesValoId;
}

function getSalesPhysicalLocation(state: AppState, rfqId: string): string | undefined {
  return state.actorsSetup[rfqId] && state.actorsSetup[rfqId].salesPhysicalLocation;
}

function getSalesInitCityLocation(state: AppState, rfqId: string): string | undefined {
  return state.actorsSetup[rfqId] && state.actorsSetup[rfqId].salesInitCityLocation;
}

function getSalesInitId(state: AppState, rfqId: string): string {
  return state.actorsSetup[rfqId].salesInitId;
}

function getSalesDiffusionFeatureToggles(
  state: AppState,
  rfqId: string,
): SalesDiffusionFeatureToggles {
  return state.actorsSetup[rfqId].salesDiffusionFeatureToggles;
}

function getSelectedSalesCounterpartId(state: AppState, rfqId: string): number | undefined {
  return state.actorsSetup[rfqId] && state.actorsSetup[rfqId].counterpartId;
}

function getContacts(state: AppState, rfqId: string): Contact[] {
  return state.actorsSetup[rfqId].contacts;
}

function getSelectedCounterpart(state: AppState, rfqId: string): Counterpart | undefined {
  const actorsSetup = state.actorsSetup[rfqId];
  if (actorsSetup) {
    const { counterpartId, mnemo, name, eliotCode } = actorsSetup;
    return counterpartId && mnemo && name && eliotCode
      ? {
          id: counterpartId,
          mnemo,
          name,
          eliotCode,
        }
      : undefined;
  }
  return undefined;
}

function getElsRfqBrokerInfo(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): Broker | undefined {
  const { legIds } = selectors.getRfqMasterStrategy(state, rfqId);
  const { brokerId } = selectors.getLegData(state, legIds[0]);

  return brokerId
    ? (selectors.getPreferenceBrokerById(state.referenceData, brokerId) ??
        selectors.getRfqData(state, rfqId).brokerNotInPreferences)
    : undefined;
}

function getSelectedCounterpartId(state: AppState, rfqId: string): number | undefined {
  return state.actorsSetup[rfqId] && state.actorsSetup[rfqId].counterpartId;
}

function getSalesCounterpart(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): Counterpart | undefined {
  const selectedCounterpartId = getSelectedCounterpartId(state, rfqId);
  const counterparts = selectors.getCounterparts(state, rfqId);
  return counterparts?.find(counterpart => counterpart.id === selectedCounterpartId);
}

function getVersion(state: AppState, rfqId: string, versionNumber: number): Version | undefined {
  const versions: Versions = state.versionsState[rfqId];
  if (versions === undefined) {
    return undefined;
  }
  return versions.versions.find(({ version: v }: Version) => v === versionNumber);
}

function getVersions(state: AppState, rfqId: string): Version[] {
  const versionState = state.versionsState[rfqId];
  return versionState ? versionState.versions : [];
}

function getForexRates(state: AppState, rfqId: string): ForexRate[] {
  const forexRatesState = state.forexRatesState[rfqId];
  return forexRatesState ? forexRatesState.forexRates : [];
}

function getLatestVersion(state: AppState, rfqId: string): number {
  const versions = getVersions(state, rfqId);
  return max(versions.map(version => version.version)) || 0;
}

function getStrategiesQuoteIds(state: AppState, rfqId: string, selectors: Selectors): string[] {
  const { strategyIds } = selectors.getRfqData(state, rfqId);
  return strategyIds.reduce<string[]>(
    (prev, strategyId) => [...prev, ...selectors.getStrategyQuoteIds(state, strategyId)],
    [],
  );
}

function getRfqQuoteIds(state: AppState, rfqId: string, selectors: Selectors): string[] {
  const { quoteId } = selectors.getRfqData(state, rfqId);
  return [...selectors.getStrategiesQuoteIds(state, rfqId, selectors), quoteId];
}

export interface QuoteFairPriceId {
  quoteId: string;
  fairPriceId: string | undefined;
}

function getStrategiesFairPriceQuoteIds(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): QuoteFairPriceId[] {
  const { strategyIds } = selectors.getRfqData(state, rfqId);
  return strategyIds.reduce<QuoteFairPriceId[]>(
    (prev, strategyId) => [...prev, ...selectors.getStrategyFairPriceQuoteIds(state, strategyId)],
    [],
  );
}

function getRfqSecondaryEvent(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): SecondaryEvent | undefined {
  const { strategyIds } = selectors.getRfqData(state, rfqId);
  const secondaryEvents = uniq(
    flatten(
      strategyIds.map(strategyId =>
        selectors.getStrategyLegsSecondaryEvent(state, strategyId, selectors),
      ),
    ),
  );
  return secondaryEvents.length === 1 ? secondaryEvents[0] : undefined;
}

function getRfqEventType(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): EventType | undefined {
  const { strategyIds } = selectors.getRfqData(state, rfqId);
  const eventTypes = uniq(
    flatten(
      strategyIds.map(strategyId =>
        selectors.getStrategyLegsEventTypes(state, strategyId, selectors),
      ),
    ),
  );
  return eventTypes.at(0);
}

function getStrategiesFairPriceIds(state: AppState, rfqId: string, selectors: Selectors): string[] {
  const { strategyIds } = selectors.getRfqData(state, rfqId);
  return strategyIds.reduce<string[]>(
    (prev, strategyId) => [...prev, ...selectors.getStrategyFairPriceIds(state, strategyId)],
    [],
  );
}

function getRfqFairPriceIds(state: AppState, rfqId: string, selectors: Selectors): string[] {
  const { fairPriceId } = selectors.getRfqData(state, rfqId);
  const strategiesFairPriceIds = selectors.getStrategiesFairPriceIds(state, rfqId, selectors);
  return [fairPriceId, ...strategiesFairPriceIds].filter(isDefined);
}

function hasRfqFairPrices(state: AppState, rfqId: string, selectors: Selectors): boolean {
  const fairPricesIds = selectors.getRfqFairPriceIds(state, rfqId, selectors);
  return fairPricesIds.some(fairPriceId => getFairPrice(state, fairPriceId).mid !== undefined);
}

function getFairPrice(state: AppState, fairPriceId: string): FairPrice {
  return state.fairPricesState[fairPriceId];
}

export function isDeltaTypeEditableForStrategy(
  strategyId: string,
  state: AppState,
  selectors: Selectors,
): boolean {
  const { getStrategyData, getLegData } = selectors;
  const strategyData = getStrategyData(state, strategyId);
  if (!isRfqStrategyData(strategyData)) {
    return false;
  }
  const { legIds, quoteId, deltaType } = strategyData;
  const legDataArray = legIds.map(legId => getLegData(state, legId));
  const areDeltaDefined =
    getIsQuoteDeltaDefined(state, quoteId, selectors) ||
    areAllLegsDeltaDefined(state, legDataArray, selectors);

  return (
    !!deltaType &&
    areDeltaDefined &&
    areAllLegsSingleUnderlyingIdDefined(state, legIds, selectors) &&
    areAllLegsQuantityDefined(legDataArray)
  );
}

function areAllLegsSingleUnderlyingIdDefined(
  state: AppState,
  legIds: string[],
  selectors: Selectors,
): boolean {
  return legIds.every(legId =>
    selectors.getUnderlyingIdOrNameOrRefIdOfLeg(state, legId, selectors),
  );
}

export function areAllLegsQuantityDefined(legDataArray: LegData[]): boolean {
  return legDataArray.every(legData => !!(legData.numberOfLots || legData.notional));
}

function areAllLegsDeltaDefined(
  state: AppState,
  legDataArray: LegData[],
  selectors: Selectors,
): boolean {
  return legDataArray.every(({ quoteId }) => getIsQuoteDeltaDefined(state, quoteId, selectors));
}

function getIsQuoteDeltaDefined(appState: AppState, quoteId: string, selectors: Selectors) {
  const { delta } = selectors.getQuote(appState, quoteId);
  return delta !== undefined;
}

export function getRfqMasterStrategy(state: AppState, rfqId: string): StrategyData {
  const rfqStrategyIds: string[] = state.rfqDataState[rfqId].strategyIds;
  const strategyDataState: StateMap<StrategyData> = state.strategyDataState;
  const isMasterStrategy: (strategyId: string) => boolean = isMasterStrategyOfRfq(
    rfqStrategyIds,
    strategyDataState,
  );
  const masterStrategyId: string | undefined =
    Object.keys(strategyDataState).find(isMasterStrategy);
  if (!masterStrategyId) {
    throw new Error('Error: there should be a master strategy');
  }
  return strategyDataState[masterStrategyId];
}

function getMasterUnderlyingInfo(
  rfqId: string,
  state: AppState,
  selectors: Selectors,
): UnderlyingInfo | undefined {
  const { uuid: masterStrategyId } = selectors.getRfqMasterStrategy(state, rfqId);
  const masterProduct = selectors.getStrategyMasterProduct(state, masterStrategyId, selectors);
  const masterUnderlyingId = selectors.getUnderlyingId(masterProduct);
  return masterUnderlyingId ? selectors.getUnderlyingInfo(state, masterUnderlyingId) : undefined;
}

export function isRfqInternalEls(state: AppState, rfqId: string, selectors: Selectors) {
  const isEls = selectors.isRfqEls(state, rfqId, selectors);
  const { internal } = selectors.getRfqData(state, rfqId);
  return isEls && !!internal;
}

export function isInternalElsRfqInitiatedByTrader(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): boolean {
  const rfqInitiatedByTrader = selectors.isRfqInitiatedByTrader(state, rfqId);
  const rfqInternalElsAndToggled = selectors.isRfqInternalEls(state, rfqId, selectors);
  return rfqInitiatedByTrader && rfqInternalElsAndToggled;
}

export function isRfqEls(state: AppState, rfqId: string, selectors: Selectors) {
  const { uuid } = selectors.getRfqMasterStrategy(state, rfqId);
  return selectors.isElsStrategy(state, uuid, selectors);
}

export function isRfqMasterStrategyEls(state: AppState, rfqId: string, selectors: Selectors) {
  const { uuid } = selectors.getRfqMasterStrategy(state, rfqId);
  return selectors.isElsStrategy(state, uuid, selectors);
}

function isMasterStrategyOfRfq(
  rfqStrategyIds: string[],
  strategyDataState: StateMap<StrategyData>,
) {
  return (strategyId: string) =>
    rfqStrategyIds.includes(strategyId) && strategyDataState[strategyId].isMasterStrategy;
}

function getRfqProducts(state: AppState, rfqId: string, selectors: Selectors): Product[] {
  return flatten(
    selectors
      .getRfqData(state, rfqId)
      .strategyIds.map(strategyId => selectors.getStrategyProducts(state, strategyId, selectors)),
  );
}

function getRfqAndDeltaProducts(state: AppState, rfqId: string, selectors: Selectors): Product[] {
  const { strategyIds, deltaHedgingStrategyIds } = selectors.getRfqData(state, rfqId);
  return flatten(
    [...strategyIds, ...deltaHedgingStrategyIds].map(strategyId =>
      selectors.getStrategyProducts(state, strategyId, selectors),
    ),
  );
}

function getRfqMasterProduct(state: AppState, rfqId: string, selectors: Selectors): Product {
  return selectors.getStrategyMasterProduct(
    state,
    selectors.getRfqMasterStrategy(state, rfqId).uuid,
    selectors,
  );
}

function isReadOnlyRfq(state: AppState, rfqId: string) {
  const rfqData = state.rfqDataState[rfqId];
  if (rfqData) {
    const strategyIds = rfqData.strategyIds;
    const containsStrategyType = strategyIds.some(strategyId => {
      const { strategyType } = strategySelectors.getStrategyData(state, strategyId);
      return state.referenceData.strategyConfiguration.viewableStrategies.includes(strategyType);
    });
    return containsStrategyType || rfqData.workflow !== 'NEOS';
  }
  return false;
}

function isTradeRecapEnabled(state: AppState, rfqId: string, selectors: Selectors): boolean {
  if (selectors.isReadOnlyRfq(state, rfqId)) {
    return false;
  }

  const { clientWay } = selectors.getRfqData(state, rfqId);
  return clientWay === 'BUY' || clientWay === 'SELL';
}

function getRfqLegIdsByStrategyIds(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): LegIdsByStrategyId[] {
  const { strategyIds } = selectors.getRfqData(state, rfqId);
  return strategyIds.map(strategyId => ({
    strategyId,
    legIds: selectors.getStrategyData(state, strategyId).legIds,
  }));
}

function getRfqLegIds(state: AppState, rfqId: string, selectors: Selectors): string[] {
  const { strategyIds } = selectors.getRfqData(state, rfqId);
  return flatMap(strategyIds, strategyId => selectors.getStrategyData(state, strategyId).legIds);
}

export interface ExecutionStrategies {
  strategyLegs: LegIdsByStrategyId[];
  deltaLegs: LegIdsByStrategyId[];
}

function getAllExecutionStrategies(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): ExecutionStrategies {
  return {
    strategyLegs: [
      ...selectors.getListedExecutionRfqStrategies(state, rfqId, selectors),
      ...selectors.getOtcExecutionRfqStrategies(state, rfqId, selectors),
    ],
    deltaLegs: [
      ...selectors.getListedExecutionDeltaStrategies(state, rfqId, selectors),
      ...selectors.getOtcExecutionDeltaStrategies(state, rfqId, selectors),
    ],
  };
}

function getAllExecutionDeltaStrategies(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): LegIdsByStrategyId[] {
  return [
    ...selectors.getListedExecutionDeltaStrategies(state, rfqId, selectors),
    ...selectors.getOtcExecutionDeltaStrategies(state, rfqId, selectors),
  ];
}

function getListedExecutionStrategies(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): ExecutionStrategies {
  return {
    strategyLegs: selectors.getListedExecutionRfqStrategies(state, rfqId, selectors),
    deltaLegs: selectors.getListedExecutionDeltaStrategies(state, rfqId, selectors),
  };
}

function getOtcExecutionStrategies(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): ExecutionStrategies {
  return {
    strategyLegs: selectors.getOtcExecutionRfqStrategies(state, rfqId, selectors),
    deltaLegs: selectors.getOtcExecutionDeltaStrategies(state, rfqId, selectors),
  };
}

function getListedExecutionRfqStrategies(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): LegIdsByStrategyId[] {
  return selectors
    .getRfqLegIdsByStrategyIds(state, rfqId, selectors)
    .filter(({ strategyId }) => selectors.isListedExecutionStrategy(state, strategyId, selectors));
}

function getListedExecutionDeltaStrategies(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): LegIdsByStrategyId[] {
  return selectors
    .getRfqLegIdsByDeltaStrategyIds(state, rfqId, selectors)
    .filter(({ strategyId }) => selectors.isListedExecutionStrategy(state, strategyId, selectors));
}

function getOtcExecutionRfqStrategies(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): LegIdsByStrategyId[] {
  return selectors
    .getRfqLegIdsByStrategyIds(state, rfqId, selectors)
    .filter(({ strategyId }) => selectors.isOtcExecutionStrategy(state, strategyId, selectors));
}

function getOtcExecutionDeltaStrategies(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): LegIdsByStrategyId[] {
  return selectors
    .getRfqLegIdsByDeltaStrategyIds(state, rfqId, selectors)
    .filter(({ strategyId }) => selectors.isOtcExecutionStrategy(state, strategyId, selectors));
}

function getRfqLegIdsByDeltaStrategyIds(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): LegIdsByStrategyId[] {
  const { deltaHedgingStrategyIds } = selectors.getRfqData(state, rfqId);
  return deltaHedgingStrategyIds.map(strategyId => ({
    strategyId,
    legIds: selectors.getStrategyData(state, strategyId).legIds,
  }));
}

function getRfqHasFairPrices(state: AppState, rfqId: string) {
  const { fairPriceId } = getRfqData(state, rfqId);
  return !!fairPriceId && getFairPrice(state, fairPriceId).mid !== undefined;
}

export function getSizeOfMasterLegOfMasterStrategy(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): GenericSizeWithQuantity {
  const { uuid } = selectors.getRfqMasterStrategy(state, rfqId);
  const { numberOfLots, productId, quantity } = selectors.getStrategyMasterLeg(
    state,
    uuid,
    selectors,
  );
  const { negotiationMode, lotSize } = selectors.getProduct(state, productId);
  const genericLotSize: number = lotSize ? lotSize : 1;
  return {
    negotiationMode,
    lotSize: genericLotSize,
    numberOfLots,
    quantity,
  };
}

function isRfqCustomUnderlying(state: AppState, rfqId: string, selectors: Selectors): boolean {
  const { strategyType } = selectors.getRfqMasterStrategy(state, rfqId);
  return selectors.isStrategyTypeCustomUnderlying(state.referenceData, strategyType, selectors);
}

function areNotificationDifferencesDisplayed(state: AppState, selectors: Selectors) {
  return selectors.isFeatureToggleEnabled(state, 'neos.rfq.notification.diff.enabled');
}

export function getStrategyAveragePrice(
  state: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): OrderData | undefined {
  const { legIds } = selectors.getStrategyData(state, strategyId);
  if (legIds.length !== 1) {
    return undefined;
  }
  const { uuid } = selectors.getStrategyMasterLeg(state, strategyId, selectors);
  return selectors.getOrderByLegId(state.orderData, rfqId, uuid);
}

function isThereAtLeastOneStratListed(state: AppState, rfqId: string, selectors: Selectors) {
  const { strategyIds } = selectors.getRfqData(state, rfqId);
  return strategyIds.some(strategyId => selectors.isListedStrategy(state, strategyId, selectors));
}
