import { crudDomain } from '@/util/crudUtils';
import type { AllocationInstruction, AllocationInstructionKey } from './allocationInstructionModel';

const compositeIdSeparator = ' | ';

function getAllocationInstructionId({ executionId, rfqId }: AllocationInstructionKey): string {
  return `${rfqId}${compositeIdSeparator}${executionId}`;
}

function getAllocationInstructionKey(id: string): AllocationInstructionKey {
  const [rfqId, executionId] = id.split(compositeIdSeparator);
  return { rfqId, executionId };
}
export const allocationInstructionDomain = crudDomain<
  AllocationInstruction,
  AllocationInstructionKey
>('allocation-instruction', {
  keyToString: getAllocationInstructionId,
  stringToKey: getAllocationInstructionKey,
});
