import { useDispatch, useSelector } from 'react-redux';

import { actionCreators } from '@/bootstrap/actions';
import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { SpreadAdjustmentTypeValues } from '@/neos/business/rfq/strategy/feature/elsSections/elsSectionsModel';
import type { SpreadAdjustmentType } from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel';
import { ElsFeaturesContext } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures';
import { ElsBlocField } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/ElsBlocField';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';
import { useContext } from 'react';
import { isElsProduct } from '../../../../../../../../neos/business/neosModel';

export function DividentReqAdjustment() {
  const { strategyId } = useContext(ElsFeaturesContext);
  const dispatch = useDispatch();
  const product = useSelector((state: AppState) =>
    selectors.getStrategyProduct(state, strategyId, selectors),
  );
  if (!isElsProduct(product)) {
    return null;
  }
  const { uuid, dividendSpreadAdjustment } = product;
  const { update: updateProduct } = actionCreators.neos.productCrudActions;

  const onDividendSpreadAdjustmentChange = (
    newDividendSpreadAdjustment: SpreadAdjustmentType | undefined,
  ) => {
    dispatch(updateProduct(uuid, { dividendSpreadAdjustment: newDividendSpreadAdjustment }));
  };

  return (
    <ElsBlocField
      minWidth={180}
      label="Dividend Req. Adjustment"
      renderInput={readOnly => (
        <NeosSelect
          readOnly={readOnly}
          value={dividendSpreadAdjustment}
          onChange={onDividendSpreadAdjustmentChange}
          data-e2e="early-termination-section-dividend-spread-adjustment"
          addEmptyOption
          options={SpreadAdjustmentTypeValues}
        />
      )}
    />
  );
}
