import { logger } from '@/util/logging/logger';
import { mt } from '@/util/logging/messageTemplates';
import type { ColumnState } from '@ag-grid-community/core';
import type { GridApiExtended } from './gridApiExtended';

export interface GridState {
  columnState: ColumnState[] | undefined;
}

export class GridStateApiManager {
  constructor(private gridApi: GridApiExtended) {}

  public getGridState = (): GridState => {
    const columnState = this.gridApi.getColumnState();

    return {
      columnState,
    };
  };

  public applyGridState = ({ columnState }: GridState) => {
    if (this.gridApi) {
      this.setIfDefined(this.gridApi.setColumnState, { state: columnState, applyOrder: true });
    } else {
      logger.error(mt.debug, 'gridApi in undefined', this.gridApi);
    }
  };

  private setIfDefined<T>(setFunction: (value: T) => void, value: T | undefined) {
    if (value) {
      setFunction(value);
    }
  }
}
