import { invert } from 'lodash';

export type LabelableStatus =
  | 'NEW'
  | 'CAPTURED'
  | 'TO_BE_REQUESTED'
  | 'PRICE_REQUESTED'
  | 'BEING_PRICED'
  | 'PRICED'
  | 'PRICE_PROPOSED'
  | 'ORDER_RECEIVED'
  | 'PENDING_DELTA_INSTRUCTIONS'
  | 'ORDER_CONFIRMED'
  | 'ORDER_ACCEPTED'
  | 'PLACED'
  | 'DELTA_BEING_EXECUTED'
  | 'DELTA_EXECUTED'
  | 'DELTA_MANUALLY_EXECUTED'
  | 'ORDER_BEING_CROSSED'
  | 'ORDER_CROSSED'
  | 'ORDER_MANUALLY_CROSSED'
  | 'BOOKING_REQUESTED'
  | 'POSITION_BEING_BOOKED'
  | 'POSITION_BOOKED'
  | 'TRADED'
  | 'TRADED_EXTERNALLY'
  | 'ORDER_BEING_EXECUTED'
  | 'ORDER_EXECUTED'
  | 'BEING_BOOKED'
  | 'BOOKED'
  | 'TRADE_BEING_COMPLETED'
  | 'TRADE_TO_BE_COMPLETED'
  | 'TRADE_COMPLETED'
  | 'INDICATIVE_COMPLETED'
  | 'PRE_TRADE_BOOKING_REQUESTED'
  | 'AMEND_REQUESTED'
  | 'TRADE_BEING_CANCELLED'
  | 'TRADE_TO_BE_CANCELLED'
  | 'TRADE_CANCELLED'
  | 'BEING_AMENDED'
  | 'DEAL_AMENDED'
  | 'AMEND_BEING_COMPLETED'
  | 'AMEND_COMPLETED'
  | 'EXPIRED'
  | 'PRE_TRADE_BEING_BOOKED'
  | 'PRE_TRADE_POSITION_BOOKED';

export type Status = LabelableStatus | 'CANCELLED';

export const allStatusLabels = [
  'New',
  'Captured',
  'Price Requested',
  'To Be Requested',
  'Being Priced',
  'Priced',
  'Price Proposed',
  'Order Accepted',
  'Order Received',
  'Pending Delta Instructions',
  'Placed',
  'Delta Being Executed',
  'Delta Executed',
  'Delta Manually Executed',
  'Order Being Crossed',
  'Order Crossed',
  'Order Manually Crossed',
  'Traded',
  'Traded Externally',
  'Order Being Executed',
  'Order Executed',
  'Trade Completed',
  'Being Booked',
  'Booked',
  'Trade being completed',
  'Trade to be completed',
  'Indicative Completed',
  'Booking Requested',
  'Order Confirmed',
  'Position Being Booked',
  'Position Booked',
  'Pre Trade Being Booked',
  'Pre Trade Booking Requested',
  'Pre Trade Position Booked',
  'Amend Requested',
  'Being Amended',
  'Deal Amended',
  'Amend being completed',
  'Amend Completed',
  'Expired',
  'Trade Being Cancelled',
  'Trade To Be Cancelled',
  'Trade Cancelled',
] as const;

export type StatusLabel = (typeof allStatusLabels)[number];

export const labelStatuses: { [key in LabelableStatus]: StatusLabel } = {
  NEW: 'New',
  CAPTURED: 'Captured',
  PRICE_REQUESTED: 'Price Requested',
  TO_BE_REQUESTED: 'To Be Requested',
  BEING_PRICED: 'Being Priced',
  PRICED: 'Priced',
  PRICE_PROPOSED: 'Price Proposed',
  ORDER_ACCEPTED: 'Order Accepted',
  ORDER_RECEIVED: 'Order Received',
  PENDING_DELTA_INSTRUCTIONS: 'Pending Delta Instructions',
  PLACED: 'Placed',
  DELTA_BEING_EXECUTED: 'Delta Being Executed',
  DELTA_EXECUTED: 'Delta Executed',
  DELTA_MANUALLY_EXECUTED: 'Delta Manually Executed',
  ORDER_BEING_CROSSED: 'Order Being Crossed',
  ORDER_CROSSED: 'Order Crossed',
  ORDER_MANUALLY_CROSSED: 'Order Manually Crossed',
  POSITION_BOOKED: 'Position Booked',
  TRADED: 'Traded',
  TRADED_EXTERNALLY: 'Traded Externally',
  ORDER_BEING_EXECUTED: 'Order Being Executed',
  ORDER_EXECUTED: 'Order Executed',
  TRADE_COMPLETED: 'Trade Completed',
  BEING_BOOKED: 'Being Booked',
  BOOKED: 'Booked',
  TRADE_BEING_COMPLETED: 'Trade being completed',
  TRADE_TO_BE_COMPLETED: 'Trade to be completed',
  INDICATIVE_COMPLETED: 'Indicative Completed',
  BOOKING_REQUESTED: 'Booking Requested',
  ORDER_CONFIRMED: 'Order Confirmed',
  POSITION_BEING_BOOKED: 'Position Being Booked',
  PRE_TRADE_BEING_BOOKED: 'Pre Trade Being Booked',
  PRE_TRADE_BOOKING_REQUESTED: 'Pre Trade Booking Requested',
  PRE_TRADE_POSITION_BOOKED: 'Pre Trade Position Booked',
  AMEND_REQUESTED: 'Amend Requested',
  BEING_AMENDED: 'Being Amended',
  DEAL_AMENDED: 'Deal Amended',
  AMEND_BEING_COMPLETED: 'Amend being completed',
  AMEND_COMPLETED: 'Amend Completed',
  TRADE_BEING_CANCELLED: 'Trade Being Cancelled',
  TRADE_TO_BE_CANCELLED: 'Trade To Be Cancelled',
  TRADE_CANCELLED: 'Trade Cancelled',
  EXPIRED: 'Expired',
};

export const labelStatusLabels = invert(labelStatuses) as Record<StatusLabel, Status>;
