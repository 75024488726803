import { hasDiscriminator } from '@/neos/business/rfq/strategy/feature/hasDiscriminator';
import type { WithExtraProperty } from '@/neos/business/rfq/strategy/feature/withExtraProperty';
import { convertNullToUndefined } from '@/util/undefinedAndNull/convertNullToUndefined';
import type { ExecFeesFeature } from '../../../../neosModel';
import type { OnyxExecFees, OnyxProductFeaturesFields } from '../../../../neosOnyxModel';

export function mapFromOnyxExtraFeaturesToExecFeesFeature(
  { extraFeatures }: OnyxProductFeaturesFields,
  strategyId: string,
): ExecFeesFeature | undefined {
  const mappedEls: Pick<ExecFeesFeature, 'strategyId' | 'type'> = { strategyId, type: 'EXEC_FEES' };

  if (extraFeatures) {
    const execFeesFeature = extraFeatures.find(hasDiscriminator<OnyxExecFees>('EXEC_FEES'));

    if (execFeesFeature) {
      return {
        ...mappedEls,
        execFees: (execFeesFeature && execFeesFeature.execFees) ?? 'NONE',
        execFeesIn: convertNullToUndefined(execFeesFeature.execFeesIn),
        execFeesOut: convertNullToUndefined(execFeesFeature.execFeesOut),
      };
    }
  }

  return undefined;
}

export function mapToOnyxExecFeesFeature(
  feature: WithExtraProperty<ExecFeesFeature>,
): OnyxProductFeaturesFields {
  const { execFees, execFeesIn, execFeesOut } = feature;
  const isExecFeesInMapped =
    (execFees === 'IN' || execFees === 'IN_AND_OUT') && execFeesIn !== undefined;
  const isExecFeesOutMapped =
    (execFees === 'OUT' || execFees === 'IN_AND_OUT') && execFeesOut !== undefined;

  const onyxExecFees: OnyxExecFees = {
    discriminator: 'EXEC_FEES',
    execFees,
    execFeesIn: isExecFeesInMapped ? execFeesIn : null,
    execFeesOut: isExecFeesOutMapped ? execFeesOut : null,
  };

  return {
    extraFeatures: feature.isExtra ? [onyxExecFees] : [],
  };
}
