import { FlowDatePicker } from '@/neos/components/share/datePicker';
import type { ExecutionDateTime } from '../getLegExecutionModel';

export function ExecutionDateAndTime({
  isManual,
  executionDateTime,
  onExecutionDateChanged,
  onExecutionTimeChanged,
  isReadOnly,
}: {
  isManual: boolean;
  onExecutionTimeChanged: (newTime: string | undefined) => void;
  onExecutionDateChanged: (newDate: string | undefined) => void;
  executionDateTime: ExecutionDateTime;
  isReadOnly: boolean;
}) {
  if (!isManual) {
    return null;
  }
  return (
    <section className="d-flex">
      <FlowDatePicker
        onChange={val => {
          onExecutionDateChanged(val);
        }}
        maturities={[]}
        date={executionDateTime.date ?? ''}
        hideCalendarIcon
        className="errorable-bloc"
        inputClassName="form-control"
        readOnly={isReadOnly}
        data-e2e="leg-execution-date"
      />

      <input
        className="form-control errorable-bloc"
        type="time"
        data-e2e="leg-execution-time"
        value={executionDateTime.time ?? ''}
        onChange={event => {
          onExecutionTimeChanged(event.target.value || undefined);
        }}
        readOnly={isReadOnly}
        step="1"
      />
    </section>
  );
}
