import type { FromOnyxMappers } from '@/neos/business/mappers';
import type { ActionContainer } from '@/util/actionContainer';
import { hedgeDomain } from './hedgeDomain';
import type { OnyxHedge } from './hedgeOnyxModel';

export const hedgeActionCreators = {
  hedgeCrudActions: hedgeDomain.actions,
  createUpdateHedgesAction,
};

function createUpdateHedgesAction(
  rfqId: string,
  hedges: OnyxHedge[],
  { mapFromOnyxHedges }: FromOnyxMappers,
): ActionContainer {
  return mapFromOnyxHedges(hedges).map(hedge =>
    hedgeDomain.actions.upsert(
      { rfqId, underlyingId: hedge.underlyingId, deltaType: hedge.deltaType },
      hedge,
    ),
  );
}
