/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
  type PossibleBlotterColumnFields,
  getColumnHeaderName,
} from '@/neos/components/share/blotterGrid';
import { Component } from 'react';
import { BlotterSubfilter } from '../blotterSubfilter/BlotterSubfilter.container';
import { blotterFilterDefaultUser } from '../eventBus/defaultFilterRequested';
import { filterChangedBus, filterDeletedBus } from '../eventBus/filterChanged';
import { BlotterLastNotification } from '../lastNotification/BlotterLastNotification.container';
import styles from './BlotterPanelInformation.module.scss';
import type { BlotterPanelInformationModel } from './getBlotterPanelInformationModel';

export interface BlotterPanelInformationPropsFromState {
  model: BlotterPanelInformationModel;
}

export interface BlotterPanelInformationDispatchProps {
  requestBlotterRfqs: () => void;
}

export type BlotterPanelInformationProps = BlotterPanelInformationPropsFromState &
  BlotterPanelInformationDispatchProps;

interface BlotterFilter {
  name: PossibleBlotterColumnFields;
  value: string[];
}

interface OwnState {
  blotterFilters: BlotterFilter[];
}

export class BlotterPanelInformationWithCss extends Component<
  BlotterPanelInformationProps,
  OwnState
> {
  _isMounted = false;
  constructor(props: BlotterPanelInformationProps) {
    super(props);
    this.state = { blotterFilters: [] };
  }

  private dispatchDeleteFilterEvent(name: string) {
    filterDeletedBus.emit('filterDeleted', name);
  }

  private getFilterValue(blotterFilters: any, key: string): string[] {
    if (Object.keys(blotterFilters[key]).some(k => k === 'filter')) {
      return [blotterFilters[key].filter];
    }

    if (Object.keys(blotterFilters[key]).some(k => k === 'values')) {
      return blotterFilters[key].values as string[];
    }

    if (Object.keys(blotterFilters[key]).some(k => k.startsWith('condition'))) {
      const temp = Object.keys(blotterFilters[key]).filter(k => k.startsWith('condition'));
      return temp.map(conditionNodeKey => blotterFilters[key][conditionNodeKey].filter);
    }

    return [];
  }

  private getHeaderFilterName(name: PossibleBlotterColumnFields): string {
    return getColumnHeaderName(name) ?? 'N/A';
  }

  private parseBlotterFilters(blotterFilters: any): BlotterFilter[] {
    const result: BlotterFilter[] = [];
    if (blotterFilters) {
      Object.keys(blotterFilters).forEach(key => {
        result.push({
          name: key as PossibleBlotterColumnFields,
          value: this.getFilterValue(blotterFilters, key),
        });
      });
    }
    return result;
  }

  private addBlotterOnFilterChangedHandler() {
    filterChangedBus.removeAllListeners();
    filterChangedBus.on('blotterFilterChanged', ev => {
      if (this._isMounted) {
        const blotterFilters = this.parseBlotterFilters(ev.filterModel);
        this.setState({
          blotterFilters,
        });
      }
    });
  }

  public componentDidMount() {
    this._isMounted = true;
    this.addBlotterOnFilterChangedHandler();
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  public dispatchRequestTodoList() {
    blotterFilterDefaultUser.emit(
      'blotterFilterDefaultUser',
      this.props.model.defaultFiltersByUser,
    );
  }

  public render() {
    return (
      <div
        className={`${styles['blotter-panel-information']} d-flex flex-column align-items-end text-nowrap`}
      >
        <div className="d-flex justify-content-between w-100 align-items-start">
          <div>
            <h5 className="ms-1">Search results</h5>
          </div>
          <BlotterLastNotification />
          <div className="d-flex flex-nowrap h-0">
            <BlotterSubfilter />
            <button
              className={`${styles['btn-todo-list']} btn btn-discreet-primary ms-1`}
              onClick={() => this.dispatchRequestTodoList()}
              type="button"
              disabled={
                (this.props.model.defaultFiltersByUser.statusLabel.values?.length ?? 0) === 0
              }
            >
              My Todo List
            </button>
          </div>
        </div>
        <div>
          {this.state.blotterFilters.length > 0 && (
            <div className={`${styles['blotter-filters']} mt-1`}>
              {this.state.blotterFilters.map(({ name, value }: BlotterFilter) => (
                <span className="badge badge-info badge-dismissible" key={name}>
                  <button className="btn" onClick={() => this.dispatchDeleteFilterEvent(name)}>
                    <em className="icon">close</em>
                  </button>
                  {`${this.getHeaderFilterName(name)} : ${
                    value.length ? value.join(' | ') : '[Nothing]'
                  }`}
                </span>
              ))}
              {this.state.blotterFilters.length > 1 && (
                <button
                  className="btn btn-discreet-primary badge"
                  onClick={() => this.dispatchDeleteFilterEvent('all')}
                >
                  Clean all filters
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}
