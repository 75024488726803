import type { ThunkEpic } from '@/bootstrap/epics';
import { type Thunks, thunks } from '@/bootstrap/thunks';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { type Epic, ofType } from 'redux-observable';
import { catchError, mergeMap } from 'rxjs/operators';
import {
  createStorageRequestApi,
  type StorageRequestApi,
} from '@/common/business/helpRequest/stateStorage/storageRequestApi';

export function createSaveStateToStorageEpic(http: SgmeHttp): Epic {
  return saveStateToStorageEpic(createStorageRequestApi(http), thunks);
}

export function saveStateToStorageEpic(
  api: StorageRequestApi,
  { createErrorToasterThunk }: Thunks,
): ThunkEpic {
  return (action$, _) =>
    action$.pipe(
      ofType('HELP_REQUEST_SAVE_STATE'),
      mergeMap(({ storageRequest }) => {
        return api.storeState(storageRequest).pipe(
          mergeMap(() => []),
          catchError(() => [
            createErrorToasterThunk(
              {
                message: 'Could not save state to storage',
              },
              undefined,
              false,
            ),
          ]),
        );
      }),
    );
}
