import { Blotter } from '../blotter/Blotter.container';
import { Rfq } from '../rfq/Rfq.container';
import { NavTabs } from './navTabs/NavTabs.container';
import './Tabs.module.scss';

interface TabsProps {
  kind: 'MAIN' | 'SECONDARY';
  tabId: string;
  showTabs?: boolean;
}

export const Tabs = ({ kind, tabId, showTabs = true }: TabsProps) => (
  <div
    className="tabs border"
    data-e2e={kind.toLocaleLowerCase() + '-tabs' + (showTabs ? '' : '-invisible')}
  >
    {showTabs && <NavTabs kind={kind} />}
    <div className="neosTab">{tabId === 'BLOTTER' ? <Blotter /> : <Rfq rfqId={tabId} />}</div>
  </div>
);
