import { createDefaultConfirmMediaRequested } from '@/neos/business/rfq/strategy/feature/thunks/defaultConfirmMediaRequested';
import { createUpdateElsSectionHeightThunk } from '../elsSections/updateElsSectionHeightThunk';
import { createAddBarrierToFeatureThunk } from './addBarrierToFeature';
import { createChangeFeatureFieldThunk } from './changeFeatureFieldThunk';
import { createChangeForwardStartDate } from './changeForwardStartDate';
import { createDefaultForwardStartDateRequested } from './defaultForwardStartDateRequested';
import { createAddDividendSchedulePeriodThunk } from './dividend/addDividendSchedulePeriodThunk';
import { createRemoveAllDividendSchedulePeriodThunk } from './dividend/removeAllDividendSchedulePeriodThunk';
import { createRemoveDividendSchedulePeriodThunk } from './dividend/removeDividendSchedulePeriodThunk';
import { createUpdateDividendSchedulePeriodThunk } from './dividend/updateDividendSchedulePeriodThunk';
import { createRemoveBarrierOfFeatureThunk } from './removeBarrierOfFeature';
import { createUpdateBarrierOfFeatureThunk } from './updateBarrierOfFeature';

export const featureThunks = {
  createAddBarrierToFeatureThunk,
  createUpdateBarrierOfFeatureThunk,
  createRemoveBarrierOfFeatureThunk,
  createChangeFeatureFieldThunk,
  createChangeForwardStartDate,
  createDefaultForwardStartDateRequested,
  createAddDividendSchedulePeriodThunk,
  createRemoveDividendSchedulePeriodThunk,
  createUpdateDividendSchedulePeriodThunk,
  createRemoveAllDividendSchedulePeriodThunk,
  createUpdateElsSectionHeightThunk,
  createDefaultConfirmMediaRequested,
};
