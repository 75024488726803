import { useCustomDateOnKeyDownInput } from '@/neos/components/share/datePicker/useCustomDateOnKeyDownInput';
import { DATE_SHORT_LITTERAL_FORMAT } from '@/util/date/dateFormatHelper';
import { format } from 'date-fns';
import { type ForwardedRef, forwardRef, useState } from 'react';

interface CustomInputProps {
  styleName?: string;
  disabled: boolean;
  readOnly: boolean;
  hideCalendarIcon?: boolean;
  convertedDate: Date | null;
  className: string;
  closeDatePicker: () => void | undefined;
  onClick?: () => void;
  onCustomBlur: (inputValue: string | null) => void;
  onBlur?: (ev: any) => void;
  onKeyDown?: (ev: any) => void;
}

const CustomDateInputWithRef = (
  {
    styleName,
    disabled,
    hideCalendarIcon,
    convertedDate,
    className,
    readOnly,
    closeDatePicker,
    onCustomBlur,
    onBlur,
    onKeyDown,
    ...restProps
  }: CustomInputProps,
  ref: ForwardedRef<HTMLInputElement>,
) => {
  const [inputValue, setInputValue] = useState<string | null>(null);
  const [denyNextBlur, setDenyNextBlur] = useState(false);

  const customValue =
    inputValue === null && convertedDate
      ? format(convertedDate, DATE_SHORT_LITTERAL_FORMAT)
      : (inputValue ?? '');

  const resetInput = () => setInputValue(null);

  const onInputBlur = (): void => {
    resetInput();
    if (!denyNextBlur) {
      onCustomBlur(inputValue);
    }
    setDenyNextBlur(false);
  };

  const { customOnKeyDown } = useCustomDateOnKeyDownInput({
    inputValue,
    closeDatePicker,
    onCustomBlur,
    resetInput,
  });

  return (
    <div className="maturity-container">
      <input
        ref={ref}
        type="text"
        {...restProps}
        value={customValue || ''}
        onChange={({ target }) => {
          setInputValue(target.value);
        }}
        onBlur={onInputBlur}
        onKeyDown={customOnKeyDown}
        disabled={disabled}
        readOnly={readOnly}
        data-should-not-focus-on-loading
        className={`input-sm form-control input ${className}`}
      />
      {!hideCalendarIcon && (
        <button
          className="btn btn-icon btn-discreet-primary"
          disabled={disabled || readOnly}
          onClick={restProps.onClick}
        >
          <i className="icon icon-xs" aria-hidden="true">
            calendar_today
          </i>
        </button>
      )}
    </div>
  );
};

export const CustomDateInput = forwardRef(CustomDateInputWithRef);
