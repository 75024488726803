import type { Thunk } from '@/bootstrap/thunks.ts';
import type { ElsSection } from '@/neos/business/rfq/strategy/feature/elsSections/elsSectionsModel.ts';

export function createEnableElsSectionThunk(sectionName: ElsSection): Thunk {
  return function enableElsSectionThunk(
    dispatch,
    getState,
    {
      selectors: { getElsSections },
      actionCreators: {
        neos: { upsertElsSections },
      },
    },
  ) {
    const elsSections = getElsSections(getState());

    dispatch(
      upsertElsSections(
        elsSections.map(elsSection => {
          if (elsSection.i === sectionName) {
            elsSection.isDisplayed = true;
          }
          return elsSection;
        }),
      ),
    );
  };
}
