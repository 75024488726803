import type { Thunk } from '@/bootstrap/thunks';
import type { NeosActionCreators } from '@/neos/business/neosActionCreators';
import type { ActionContainer } from '@/util/actionContainer';
import { flatMap } from 'lodash';
import {
  type MarketLotSize,
  type MarketLotSizePointValue,
  isSingleUnderlyingDerivativeProduct,
} from '../../../../../../neos/business/neosModel';
import { isListedProduct, isOptionProduct } from '../legModel';
import {
  type CommonProduct,
  type Future,
  type Listed,
  type ListedNegotiation,
  type Product,
  isCustomUnderlyingProduct,
  isFutureLikeProduct,
  isFutureOptionProduct,
  isVSwapProduct,
} from '../product/productModel';

export function createDefaultProductLotSizeMarketThunk(productIds: string[]): Thunk {
  return function defaultProductLotSizeMarketThunk(
    dispatch,
    getState,
    {
      selectors: { getUnderlyingInfo, getProduct },
      actionCreators: {
        neos: { productCrudActions },
      },
    },
  ) {
    const appState = getState();
    const actions: ActionContainer[] = flatMap(productIds, productId => {
      const product = getProduct(appState, productId);

      if (!isListedProduct(product)) {
        return [];
      }

      const underlyingOrRefId = isCustomUnderlyingProduct(product)
        ? undefined
        : isSingleUnderlyingDerivativeProduct(product)
          ? product.underlyingId
          : product.refId;

      if (!underlyingOrRefId) {
        return [];
      }

      const underlyingInfo = getUnderlyingInfo(appState, underlyingOrRefId);
      if (!underlyingInfo) {
        throw new Error(`UnderlyingInfo not found for ${underlyingOrRefId}`);
      }

      return [
        ...getFutureProductPatchActions(
          productId,
          product,
          underlyingInfo.future.marketLotSizes,
          productCrudActions,
        ),
        ...getOptionOrVSwapProductPatchActions(
          productId,
          product,
          underlyingInfo.option.marketLotSizes,
          productCrudActions,
        ),
        ...getOptionOnFutureProductPatchActions(
          productId,
          product,
          underlyingInfo.option.optionOnFutureMarketLotSizes,
          productCrudActions,
        ),
      ];
    });
    if (actions.length) {
      return dispatch(actions);
    }
  };
}

function getFutureProductPatchActions(
  productId: string,
  product: Listed<Product>,
  marketLotSizePointValues: {
    [id: string]: MarketLotSizePointValue;
  },
  actionCreator: NeosActionCreators['productCrudActions'],
): ActionContainer[] {
  if (!isFutureLikeProduct(product)) {
    return [];
  }

  const selectedKey = Object.keys(marketLotSizePointValues).find(
    key =>
      marketLotSizePointValues[key].lotSize === product.lotSize &&
      marketLotSizePointValues[key].market.galaxyCode === product.marketExchangeId,
  );

  if (selectedKey) {
    return [
      actionCreator.update(productId, {
        pointValue: marketLotSizePointValues[selectedKey].pointValue,
      }),
    ];
  }

  const { lotSize, marketExchangeId, marketMicCode, pointValue } =
    getDefaultMarketLotSizePointValue(marketLotSizePointValues);
  return [
    actionCreator.update(productId, {
      lotSize,
      marketExchangeId,
      marketMicCode,
      pointValue,
    }),
  ];
}

function getOptionOrVSwapProductPatchActions(
  productId: string,
  product: Listed<Product>,
  marketLotSizes: {
    [id: string]: MarketLotSize;
  },
  actionCreator: NeosActionCreators['productCrudActions'],
): ActionContainer[] {
  if (!isOptionProduct(product) && !isVSwapProduct(product)) {
    return [];
  }
  const selectedKey = Object.keys(marketLotSizes).find(
    key =>
      marketLotSizes[key].lotSize === product.lotSize &&
      marketLotSizes[key].market.galaxyCode === product.marketExchangeId,
  );

  if (selectedKey) {
    return [];
  }

  const { lotSize, marketExchangeId, marketMicCode } = getDefaultMarketLotSize(
    marketLotSizes,
    product.lotSize,
  );
  return [
    actionCreator.update(productId, {
      lotSize,
      marketExchangeId,
      marketMicCode,
    }),
  ];
}

function getOptionOnFutureProductPatchActions(
  productId: string,
  product: Listed<Product>,
  optionOnFutureMarketLotSizes:
    | {
        [id: string]: MarketLotSize;
      }
    | undefined,
  productActions: NeosActionCreators['productCrudActions'],
): ActionContainer[] {
  if (!isFutureOptionProduct(product) || !optionOnFutureMarketLotSizes) {
    return [];
  }
  const selectedKey = Object.keys(optionOnFutureMarketLotSizes).find(
    key =>
      optionOnFutureMarketLotSizes[key].lotSize === product.lotSize &&
      optionOnFutureMarketLotSizes[key].market.galaxyCode === product.marketExchangeId,
  );

  if (selectedKey) {
    return [];
  }

  const { lotSize, marketExchangeId, marketMicCode } = getDefaultMarketLotSize(
    optionOnFutureMarketLotSizes,
    product.lotSize,
  );
  return [
    productActions.update(productId, {
      lotSize,
      marketExchangeId,
      marketMicCode,
    }),
  ];
}

function getDefaultMarketLotSizePointValue(marketLotSizes: {
  [id: string]: MarketLotSizePointValue;
}): Pick<Listed<Future>, 'lotSize' | 'marketExchangeId' | 'marketMicCode' | 'pointValue'> {
  const availableMarketLotSizeKeys = Object.keys(marketLotSizes);
  if (availableMarketLotSizeKeys.length === 1) {
    const { lotSize, market, pointValue } = marketLotSizes[availableMarketLotSizeKeys[0]];
    return {
      lotSize,
      marketExchangeId: market.galaxyCode,
      marketMicCode: market.micCode,
      pointValue,
    };
  }
  return {
    lotSize: undefined,
    marketExchangeId: undefined,
    marketMicCode: undefined,
    pointValue: undefined,
  };
}

export function getDefaultMarketLotSize(
  marketLotSizes: {
    [id: string]: MarketLotSize;
  },
  currentLotSize: number | undefined,
): Pick<CommonProduct & ListedNegotiation, 'lotSize' | 'marketMicCode' | 'marketExchangeId'> {
  const availableMarketLotSizeKeys = Object.keys(marketLotSizes);
  if (availableMarketLotSizeKeys.length === 1) {
    const { lotSize, market } = marketLotSizes[availableMarketLotSizeKeys[0]];
    return { lotSize, marketExchangeId: market.galaxyCode, marketMicCode: market.micCode };
  }

  if (availableMarketLotSizeKeys.length > 1) {
    const matchingMarketLotSize = availableMarketLotSizeKeys.filter(key => {
      const { lotSize } = marketLotSizes[key];
      return lotSize === currentLotSize;
    });

    if (matchingMarketLotSize.length === 1) {
      const { lotSize, market } = marketLotSizes[matchingMarketLotSize[0]];
      return { lotSize, marketExchangeId: market.galaxyCode, marketMicCode: market.micCode };
    }
  }

  return { lotSize: undefined, marketExchangeId: undefined, marketMicCode: undefined };
}
