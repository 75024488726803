import { distinct } from '@/util/array/arrayUtils';
import { keyBy } from 'lodash';
import type { Market, OnyxMarket, OnyxMarketEliot } from './markets/marketsModel';
import type { ReferenceDataAction } from './referenceDataActions';
import { defaultReferenceDataState, type ReferenceDataState } from './referenceDataState';

export const referenceDataReducer = (
  state: ReferenceDataState = defaultReferenceDataState,
  action: ReferenceDataAction,
): ReferenceDataState => {
  switch (action.type) {
    case 'SALES_VALOS_RECEIVED':
      return {
        ...state,
        salesValos: keyBy(action.salesValos, a => a.id),
      };
    case 'PRIME_BROKERS_RECEIVED':
      return {
        ...state,
        primeBrokers: {
          ...state.primeBrokers,
          [action.counterpartId]: action.primeBrokers,
        },
      };
    case 'TRADERS_RECEIVED':
      return {
        ...state,
        traders: keyBy(action.traders, a => a.id),
      };
    case 'CURRENCIES_RECEIVED':
      return {
        ...state,
        currencies: action.currencies,
      };
    case 'SALES_GROUPS_RECEIVED':
      return {
        ...state,
        salesGroups: action.salesGroupList,
      };
    case 'TRADER_GROUPS_RECEIVED':
      return {
        ...state,
        traderGroups: action.traderGroupList,
      };
    case 'SALES_LOCATIONS_RECEIVED':
      return {
        ...state,
        salesLocations: action.salesLocations,
      };
    case 'PREDEAL_CHECKS_DEROGATIONS_RECEIVED':
      return {
        ...state,
        predealChecksDerogations: action.predealChecksDerogations,
      };
    case 'STRATEGY_CONFIGURATION_RECEIVED':
      return {
        ...state,
        strategyConfiguration: action.strategyConfiguration,
      };
    case 'STRATEGIES_DEFINITIONS_RECEIVED': {
      return {
        ...state,
        strategyDefinitions: keyBy(action.strategiesDefinitions, s => s.id),
      };
    }
    case 'TENORS_RECEIVED':
      return {
        ...state,
        tenors: action.tenors,
      };
    case 'COUNTERPART_RECEIVED':
      return {
        ...state,
        counterparts: action.counterparts,
      };
    case 'CURRENCY_CURVES_LIST_RECEIVED': {
      return {
        ...state,
        currencyCurvesList: { ...state.currencyCurvesList, [action.currency]: action.curves },
      };
    }
    case 'MARKETS_RECEIVED':
      return {
        ...state,
        markets: distinct(
          action.markets.map(m => ({ galaxyCode: m.galaxyCode, micCode: m.micCode })),
        ),
        usMarkets: distinct(
          ((action.markets || []) as OnyxMarket[])
            .filter(m => m.marketCountry === 'USA')
            .map(m => ({ galaxyCode: m.galaxyCode, micCode: m.micCode })),
        ),
      };
    case 'MARKETS_ELIOT_RECEIVED':
      return {
        ...state,
        marketsEliot:
          action.markets !== 'DEGRADED_FEATURE'
            ? action.markets
                .map((mkt: OnyxMarketEliot) => ({ galaxyCode: mkt.marketId, micCode: mkt.boCode }))
                .sort((a: Market, b: Market) =>
                  //NOTE: because galaxyCode can be null
                  a.galaxyCode && b.galaxyCode && a.galaxyCode > b.galaxyCode ? 1 : -1,
                )
            : 'DEGRADED_FEATURE',
      };
    case 'REFERENCE_DATA_RECEIVED_ACTION':
      return {
        ...state,
        areReferenceDataReceived: true,
      };
    case 'RELATED_EXCHANGE_FIELDS_RECEIVED':
      return {
        ...state,
        relatedExchangeFields: action.payload,
      };
    case 'BROKERS_RECEIVED':
      return {
        ...state,
        brokers: action.brokers,
      };

    default:
      return state;
  }
};
