import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { Curve } from '@/neos/business/referenceData/curvesList/curveModel';

export function getCurvesList(
  state: AppState,
  selectors: Selectors,
  swapCurrency: string | undefined,
): Curve[] {
  return swapCurrency
    ? selectors
        .getCurrencyCurvesList(state.referenceData, swapCurrency)
        .filter(curve => curve.active)
    : [];
}
