import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { parserIbChat } from '@/util/parser/parserIbChat';
import { connect } from 'react-redux';
import type { NegotiationMode } from '../../../business/neosModel';
import {
  type IbChatDispatchProps,
  type IbChatOwnProps,
  type IbChatPropsFromState,
  IbChatStyled,
} from './IbChat';
import { getIbChatModel } from './getIbChatModel';

export const IbChat = connect(mapStateToProps, mapDispatchToProps)(IbChatStyled);

function mapStateToProps(state: AppState, { rfqId }: IbChatOwnProps): IbChatPropsFromState {
  return getIbChatModel(state, rfqId, selectors);
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId }: IbChatOwnProps,
): IbChatDispatchProps {
  return {
    onIbChatChanged(ibChatUnparse) {
      const ibChat = parserIbChat(ibChatUnparse);
      dispatch(neosActionCreators.rfqUiCrudActions.patchOrInsert(rfqId, { ibChat }));
    },
    onIbChatNegotiationModeChanged(ibChatNegotiationMode: NegotiationMode) {
      dispatch(neosActionCreators.rfqUiCrudActions.patchOrInsert(rfqId, { ibChatNegotiationMode }));
    },
    onIbChatRequested() {
      dispatch(neosActionCreators.createIbChatWithClearLevelRequestedAction(rfqId));
    },
  };
}
