import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { UnderlyingType } from '@/neos/business/rfq/strategy/leg/legOnyxModel';
import { type Product, isElsBasketProduct } from '../../../../../../../neos/business/neosModel';
import type { UnderlyingField } from './getUnderlyingCellsModel';

export function getUnderlyingFieldsArray(
  appState: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): UnderlyingField[] {
  const { strategyType } = selectors.getStrategyData(appState, strategyId);
  const { sameProductUnderlying, hasACompositionLeg, legs } = selectors.getStrategyDefinition(
    appState.referenceData,
    strategyType,
  );
  const legsData = selectors.getStrategyLegsData(appState, strategyId, selectors);
  const lastLegData = legsData[legsData.length - 1];
  const product = selectors.getProduct(appState, lastLegData.productId);

  const isLastLegOtcOptionInAmendWorkflow = selectors.isOtcOptionLikeLegInAmendWorkflowStatus(
    appState,
    rfqId,
    lastLegData.uuid,
    selectors,
  );
  const isElsStrategyInAmendWorkflow = selectors.isElsStrategyInAmendWorkflowStatus(
    appState,
    rfqId,
    strategyId,
    selectors,
  );
  const isLastLegVarOrVolSwapInAmendWorkflow = selectors.isVSwapProductInAmendWorkflowStatus(
    appState,
    rfqId,
    product,
    selectors,
  );

  if (isElsBasketProduct(product)) {
    return [
      {
        type: 'BASKET',
        legId: product.legId,
      },
    ];
  }

  if ((sameProductUnderlying || hasACompositionLeg) && !product.hasCustomUnderlying) {
    const underlyingId = selectors.getUnderlyingId(product);
    return [
      {
        type: 'NON_CUSTOM',
        legId: lastLegData.uuid,
        underlyingId,
        bloombergCode: selectors.getBloombergCode(appState, underlyingId, selectors),
        underlyingTypes: legs[legs.length - 1].availableProductUnderlyingTypes ?? [],
        isDisabled:
          isLastLegOtcOptionInAmendWorkflow ||
          isElsStrategyInAmendWorkflow ||
          isLastLegVarOrVolSwapInAmendWorkflow,
      },
    ];
  }

  return legsData.map((legData, index): UnderlyingField => {
    const product: Product = selectors.getProduct(appState, legData.productId);
    const isOtcOptionLegInAmendWorkflow = selectors.isOtcOptionLikeLegInAmendWorkflowStatus(
      appState,
      rfqId,
      legData.uuid,
      selectors,
    );

    const isVarVolProductInAmendWorkflow = selectors.isVSwapProductInAmendWorkflowStatus(
      appState,
      rfqId,
      product,
      selectors,
    );

    if (product.hasCustomUnderlying) {
      return {
        type: 'CUSTOM',
        legId: legData.uuid,
        name: product.underlyingName,
        isDisabled:
          isOtcOptionLegInAmendWorkflow ||
          isElsStrategyInAmendWorkflow ||
          isVarVolProductInAmendWorkflow,
      };
    }

    const underlyingId = selectors.getUnderlyingId(product);

    return {
      type: 'NON_CUSTOM',
      legId: legData.uuid,
      underlyingId,
      bloombergCode: selectors.getBloombergCode(appState, underlyingId, selectors),
      underlyingTypes: getUnderlyingTypesByLegIndexOrLastLeg() ?? [],
      isDisabled:
        isOtcOptionLegInAmendWorkflow ||
        isElsStrategyInAmendWorkflow ||
        isVarVolProductInAmendWorkflow,
    };

    function getUnderlyingTypesByLegIndexOrLastLeg(): UnderlyingType[] | undefined {
      return legs.length > index
        ? legs[index].availableProductUnderlyingTypes
        : legs[legs.length - 1].availableProductUnderlyingTypes;
    }
  });
}
