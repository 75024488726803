import { actionCreators } from '@/bootstrap/actions';
import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { isClsProduct } from '@/neos/business/neosModel';
import { ResetModeValues } from '@/neos/business/rfq/strategy/feature/elsSections/elsSectionsModel';
import type { ResetMode } from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';
import { useDispatch, useSelector } from 'react-redux';

export function RateResetMode({ strategyId }: { rfqId: string; strategyId: string }) {
  const dispatch = useDispatch();

  const product = useSelector((state: AppState) =>
    selectors.getStrategyProduct(state, strategyId, selectors),
  );

  if (!isClsProduct(product)) {
    return null;
  }

  function onRateResetModeChanged(value: ResetMode | undefined) {
    if (isClsProduct(product)) {
      dispatch(actionCreators.neos.productCrudActions.update(uuid, { resetMode: value }));
    }
  }

  const { uuid } = product;
  const resetMode = isClsProduct(product) ? product.resetMode : undefined;
  return (
    <>
      <label className="p-1">Reset Mode</label>

      <NeosSelect
        options={Object.entries(ResetModeValues).map(([key, value]) => ({
          label: value,
          value: key as ResetMode,
        }))}
        addEmptyOption
        onChange={x => onRateResetModeChanged(x)}
        value={resetMode}
        data-e2e="schedule-broken-period-reset-mode"
      />
    </>
  );
}
