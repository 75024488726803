import type { OtcAllocation, PriceUnitType } from '@/neos/business/neosModel';
import type { DisplayNegotiatedSize } from '@/neos/business/ui/strategy/strategyUiModel';
import { IACurrency } from './IACurrency';
import { IAValue } from './IAValue';

export function IAToBeBooked({
  onIAToBeBookedCcyChanged,
  alloc,
  uiSizeType,
  displayIAToBeBookedColumn,
}: {
  alloc: OtcAllocation;
  uiSizeType?: DisplayNegotiatedSize;
  displayIAToBeBookedColumn: boolean;
  onIAToBeBookedCcyChanged: (
    allocationId: string,
    currency?: string,
    IaType?: PriceUnitType,
  ) => void;
}) {
  if (displayIAToBeBookedColumn && alloc.independantAmountType === 'CCY') {
    return <span></span>;
  }

  if (!displayIAToBeBookedColumn || alloc.independantAmountType === 'CCY') {
    return null;
  }

  return (
    <div className="d-flex">
      <IACurrency
        independantAmountValue={alloc.independantAmountCurrencyValue}
        independantAmountUnit={alloc.independantAmountCurrencyUnit}
        iaTobeBookedMode={true}
        independantAmountType={alloc.independantAmountType}
        uuid={alloc.uuid}
        onIACcyChanged={onIAToBeBookedCcyChanged}
        dataE2e="otc-alloc-ia-ccy"
      />

      <IAValue
        dataE2e="otc-alloc-ia-value"
        independantAmountValue={alloc.independantAmountCurrencyValue}
        independantAmountUnit={alloc.independantAmountCurrencyUnit}
        uiSizeType={uiSizeType}
        disabled={true}
        uuid={alloc.uuid}
      />
    </div>
  );
}
