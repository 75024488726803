import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { EventType, Status } from '../../../../../../../neos/business/neosModel';

export interface StrategyLinkCellModel {
  primaryRfqId: string;
  primaryLegId: string;
  primaryStrategyId: string;
  primaryRfqSecondaryEvent: EventType;
  status: Status;
  legIds: string[];
  disabled: boolean;
}

export function getStrategyLinkCellModel(
  appState: AppState,
  strategyId: string,
  rfqId: string,
  selectors: Selectors,
): StrategyLinkCellModel {
  const { currentPrimaryLegId, currentPrimaryStrategyId, currentPrimaryRfqId, secondaryEventType } =
    selectors.getSimilarActivitiesLinkManagement(appState);
  const isLoading = selectors.isRfqLoading(appState.ui, rfqId);
  const { status } = selectors.getRfqData(appState, rfqId);
  const { legIds } = selectors.getStrategyData(appState, strategyId);
  const { negotiationMode } = selectors.getStrategyProduct(appState, strategyId, selectors);

  const isValidStrategy = selectors.isValidStrategyForImplicitUnwind(
    appState,
    rfqId,
    strategyId,
    negotiationMode,
  );
  const isReadonlyAtWorkflow = selectors.isReadOnlyAtCurrentWorkflow(
    appState,
    strategyId,
    selectors,
  );

  const disabled = isLoading || !isValidStrategy || isReadonlyAtWorkflow;

  return {
    primaryRfqId: currentPrimaryRfqId,
    primaryLegId: currentPrimaryLegId,
    primaryStrategyId: currentPrimaryStrategyId,
    primaryRfqSecondaryEvent: secondaryEventType,
    status,
    legIds,
    disabled,
  };
}
