import type { Broker } from '@/neos/business/referenceData/brokers/brokersModel';

export type BrokersAction = BrokersReceivedAction;

export const brokersActionCreators = {
  createBrokersReceivedAction,
};

export interface BrokersReceivedAction {
  type: 'BROKERS_RECEIVED';
  brokers: Broker[];
}

export function createBrokersReceivedAction(brokers: Broker[]): BrokersReceivedAction {
  return {
    type: 'BROKERS_RECEIVED',
    brokers,
  };
}
