import type { HeaderMenuAction } from './headerMenuActions';
import { type HeaderMenuState, defaultHeaderMenuState } from './headerMenuState';

export function headerMenuReducer(
  state: HeaderMenuState = defaultHeaderMenuState,
  action: HeaderMenuAction,
): HeaderMenuState {
  switch (action.type) {
    case 'ALERT_MENU_HIDDEN':
      return {
        ...state,
        isModalShown: false,
      };
    case 'ALERT_MENU_DISPLAYED':
      return {
        ...state,
        isModalShown: true,
      };
    default:
      return state;
  }
}
