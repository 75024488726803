import { LegSecondaryTypeWidget } from './LegSecondaryTypeWidget/LegSecondaryTypeWidget.container';
import { SimilarActivitiesSecondaryTypeWidget } from './SimilarActivitiesSecondaryTypeWidget/SimilarActivitiesSecondaries.container';
interface SecondaryTypeWidgetCellsOwnProps {
  rfqId: string;
  strategyId: string;
  isSimilarActivityMode: boolean;
}

export const SecondaryTypeWidgetCells = ({
  isSimilarActivityMode,
  rfqId,
  strategyId,
}: SecondaryTypeWidgetCellsOwnProps) =>
  isSimilarActivityMode ? (
    <SimilarActivitiesSecondaryTypeWidget rfqId={rfqId} strategyId={strategyId} />
  ) : (
    <LegSecondaryTypeWidget rfqId={rfqId} strategyId={strategyId} />
  );
