import { getDefaultedAsianOptionProduct } from './getDefaultedAsianOptionProduct';
import { getDefaultedAsianSpreadOptionProduct } from './getDefaultedAsianSpreadOptionProduct';
import { getDefaultedClsProduct } from './getDefaultedClsProduct';
import {
  getDefaultedCustomProduct,
  getDefaultedFxDigitalOptionProduct,
  getDefaultedFxForward,
} from './getDefaultedCustomProduct';
import { getDefaultedDigitalOptionProduct } from './getDefaultedDigitalOptionProduct';
import { getDefaultedDivSwapProduct } from './getDefaultedDivSwapProduct';
import { getDefaultedDividendFutureProduct } from './getDefaultedDividendFutureProduct';
import { getDefaultedElsProduct } from './getDefaultedElsProduct';
import { getDefaultedEuropeanOptionProduct } from './getDefaultedEuropeanOptionProduct';
import { getDefaultedFutureProduct } from './getDefaultedFutureProduct';
import { getDefaultedFvaFixedK, getDefaultedFvaFloatingK } from './getDefaultedFvaProduct';
import { getDefaultedOptionOnFutureProduct } from './getDefaultedOptionOnFutureProduct';
import { getDefaultedOptionProduct } from './getDefaultedOptionProduct';
import { getDefaultedStockProduct } from './getDefaultedStockProduct';
import { getDefaultedSwaptionProduct } from './getDefaultedSwaptionProduct';
import { getDefaultedTotalReturnFutureProduct } from './getDefaultedTotalReturnFutureProduct';
import { getDefaultedVSwapProduct } from './getDefaultedVSwapProduct';

export const strategyProductDefaulters = {
  getDefaultedOptionProduct,
  getDefaultedOptionOnFutureProduct,
  getDefaultedAsianOptionProduct,
  getDefaultedAsianSpreadOptionProduct,
  getDefaultedFutureProduct,
  getDefaultedTotalReturnFutureProduct,
  getDefaultedDividendFutureProduct,
  getDefaultedStockProduct,
  getDefaultedVSwapProduct,
  getDefaultedFvaFixedK,
  getDefaultedFvaFloatingK,
  getDefaultedElsProduct,
  getDefaultedClsProduct,
  getDefaultedDivSwapProduct,
  getDefaultedSwaptionProduct,
  getDefaultedCustomProduct,
  getDefaultedFxForward,
  getDefaultedFxDigitalOptionProduct,
  getDefaultedDigitalOptionProduct,
  getDefaultedEuropeanOptionProduct,
};

export type StrategyProductDefaulters = typeof strategyProductDefaulters;
