import type { ThunkEpic } from '@/bootstrap/epics';
import { type Thunks, thunks } from '@/bootstrap/thunks';
import type { OnyxError } from '@/neos/business/mappers/error';
import {
  type TradeRecapMailCCRecipientsApi,
  createTradeRecapMailCCRecipientsApi,
} from '@/neos/business/rfq/tradeRecapMail/tradeRecapMailCCRecipientsApi';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

export function TradeRecapMailCCRecipientsEpic(http: SgmeHttp) {
  const api = createTradeRecapMailCCRecipientsApi(http);
  return createGetInternalPreconfirmationMailCCRecipientsEpic(api, thunks);
}

export function createGetInternalPreconfirmationMailCCRecipientsEpic(
  api: TradeRecapMailCCRecipientsApi,
  { createErrorToasterThunk, neos: { createRequestTradeRecapMailThunk } }: Thunks,
): ThunkEpic {
  return action$ =>
    action$.pipe(
      ofType('TRADE_RECAP_MAIL_CC_RECIPIENTS_REQUESTED'),
      mergeMap(({ rfqId, comment }) => {
        return api.getTradeRecapMailCCRecipients(rfqId).pipe(
          mergeMap(mailCCRecipients =>
            of(createRequestTradeRecapMailThunk(rfqId, comment, mailCCRecipients)),
          ),
          catchError((error: OnyxError) => {
            return [
              createErrorToasterThunk(
                {
                  message: 'Error getting preconfirmation email CC recipients',
                },
                error,
              ),
            ];
          }),
        );
      }),
    );
}
