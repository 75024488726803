import type { SalesGroupsReceivedAction } from './salesGroupsAction';
import type { SalesGroup } from './salesGroupsModel';

export const salesGroupsActionCreators = {
  salesGroupsReceived,
};
export function salesGroupsReceived(salesGroupList: SalesGroup[]): SalesGroupsReceivedAction {
  return {
    type: 'SALES_GROUPS_RECEIVED',
    salesGroupList,
  };
}
