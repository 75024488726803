import type { Thunk } from '@/bootstrap/thunks';

export function createPreconfModalDisplayChangeThunk(
  rfqId: string,
  isPreconfirmationModalOpened: boolean,
): Thunk {
  return function preconfModalDisplayChangeThunk(
    dispatch,
    _,
    {
      actionCreators: {
        neos: { rfqUiCrudActions, preconfirmationEmailPreviewCrudAction },
      },
    },
  ) {
    dispatch(
      rfqUiCrudActions.patchOrInsert(rfqId, {
        isPreconfirmationModalOpened,
      }),
    );

    if (!isPreconfirmationModalOpened) {
      dispatch(
        preconfirmationEmailPreviewCrudAction.delete({ rfqId, destination: 'EXTERNAL' }),
        preconfirmationEmailPreviewCrudAction.delete({ rfqId, destination: 'INTERNAL' }),
      );
    }
  };
}
