import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { PredealCheckType } from '@/neos/business/predealCheck/predealCheckModel';
import { sortBy } from 'lodash';
import type { PredealCheckAvailableDerogation } from '../../../../../../../../neos/business/neosModel';

interface NoDerogationComments {
  noDerogationComment: true;
}

export interface WithDerogationComments {
  noDerogationComment: false;
  derogationComments: PredealCheckAvailableDerogation[];
}

export type DerogationCommentsInfo = NoDerogationComments | WithDerogationComments;

export function getPredealCheckDerogationComments(
  state: AppState,
  predealCheckType: PredealCheckType,
  selectors: Selectors,
): DerogationCommentsInfo {
  const predealCheckDerogations = sortBy(
    selectors.getPredealCheckDerogations(state.referenceData, predealCheckType),
    a => a.message,
  );
  if (predealCheckDerogations.find(pdc => pdc.message === '') === undefined) {
    predealCheckDerogations.unshift({
      type: 'DEFAULT',
      message: '',
    });
  }

  if (noAvailableDerogationComments(predealCheckDerogations)) {
    return {
      noDerogationComment: true,
    };
  }

  return {
    noDerogationComment: false,
    derogationComments: predealCheckDerogations,
  };
}

function noAvailableDerogationComments(
  predealChecksDerogations: PredealCheckAvailableDerogation[],
): boolean {
  return (
    !predealChecksDerogations.length ||
    (predealChecksDerogations.length === 1 && predealChecksDerogations[0].message === '')
  );
}
