import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { type ExtendedDispatch, thunks } from '@/bootstrap/thunks';
import { commonSelectors } from '@/common/business/commonSelectors';
import type { BulkCancelReason } from '@/neos/business/blotter/blotterModel';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { neosThunks } from '@/neos/business/thunks';
import { MAX_BLOTTER_RFQS_TO_OPEN } from '@/neos/components/blotter/grid/gridStaticOptions';
import { type BlotterRowModel, blotterModelGetters } from '@/neos/components/share/blotterGrid';
import { connect } from 'react-redux';
import {
  type BlotterGridDispatchProps,
  type BlotterGridPropsFromState,
  BlotterGridWithCss,
} from './BlotterGrid';
import { getBlotterModel } from './getBlotterModel';

const { bulkLoadTransactions } = neosActionCreators;

const {
  createRequestBulkManualPricingThunk,
  createRequestBulkCopyQuoteRecapToClipboardThunk,
  createRequestBulkCopyTradeRecapToClipboardThunk,
} = neosThunks;

const { createRequestBulkCancelRfqsAction } = neosActionCreators;

function mapStateToProps(state: AppState): BlotterGridPropsFromState {
  return getBlotterModel(state, selectors, blotterModelGetters, commonSelectors);
}

function mapDispatchToProps(dispatch: ExtendedDispatch): BlotterGridDispatchProps {
  return {
    loadLatestTransactions: (rfqIds: string[]) => {
      if (rfqIds.length > MAX_BLOTTER_RFQS_TO_OPEN) {
        dispatch(
          thunks.createWarningToasterThunk({
            message: `Too many RFQs selected at once. Please open no more than ${MAX_BLOTTER_RFQS_TO_OPEN}.`,
          }),
          createLogAnalyticsAction(
            'NEOS BLOTTER',
            `Try to open more than ${MAX_BLOTTER_RFQS_TO_OPEN} RFQs`,
          ),
        );
      } else {
        dispatch(
          bulkLoadTransactions(rfqIds),
          createLogAnalyticsAction('NEOS BLOTTER', 'Open RFQ'),
        );
      }
    },
    displayNotManagedRfqError: (data: BlotterRowModel) =>
      dispatch(
        thunks.createErrorToasterThunk(
          {
            message: !data.hasMasterLeg
              ? 'RFQ badly formed, impossible to open it'
              : 'Strategy type not yet supported by SGME',
          },
          undefined,
          false,
        ),
      ),
    generateManualPricing: (rfqIds: string[]) =>
      dispatch(createRequestBulkManualPricingThunk(rfqIds)),
    copyQuoteRecapsToClipboard: (rfqIds: string[]) =>
      dispatch(createRequestBulkCopyQuoteRecapToClipboardThunk(rfqIds)),
    copyTradeRecapsToClipboard: (rfqIds: string[]) =>
      dispatch(createRequestBulkCopyTradeRecapToClipboardThunk(rfqIds)),
    copyRfqIdsToClipboard: (rfqIds: string[]) =>
      dispatch(thunks.createCopyToClipboardThunk(rfqIds.join('\n'))),
    emptyBlotter: () => dispatch(neosThunks.createEmptyPendingBlotterRfqsThunk()),
    resetBlotterRfqFilteredOutNotificationLastDate: () =>
      dispatch(neosActionCreators.resetBlotterRfqFilteredOutNotificationLastDate()),
    updateBlotterRfqFilteredOutNotificationLastDate: () =>
      dispatch(neosActionCreators.updateBlotterRfqFilteredOutNotificationLastDate()),
    requestAnalyticLogging: (desc: string) =>
      dispatch(createLogAnalyticsAction('NEOS BLOTTER', desc)),
    onBulkCancelRfqs: (rfqIds: string[], reason: BulkCancelReason) => {
      dispatch(
        createRequestBulkCancelRfqsAction(rfqIds, reason),
        createLogAnalyticsAction('NEOS BLOTTER', 'Quick Action', reason),
      );
    },
  };
}

export const BlotterGrid = connect(mapStateToProps, mapDispatchToProps)(BlotterGridWithCss);
