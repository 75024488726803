import { useAppSelector } from '@/bootstrap/hooks';
import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import type { DisplayTimeZone } from '@/neos/business/ui/userPreferences/userPreferencesUiModel';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

export function GeneralPreferences() {
  const dispatch = useDispatch<ExtendedDispatch>();
  const changeDisplayTimeZone = (displayTimeZone: DisplayTimeZone) => {
    dispatch(
      createLogAnalyticsAction('NEOS USER PREFERENCES', 'Change display timezone'),
      neosActionCreators.createDisplayTimeZoneChangedAction(displayTimeZone),
    );
  };

  const displayTimeZone = useAppSelector(state =>
    selectors.getDisplayTimeZone(state.ui.userPreferences),
  );

  return (
    <div className="d-grid mb-4">
      <span className="me-2 mb-2">Display time zone</span>
      <ToggleButtonGroup
        name="columnDisplayTimeZone"
        className="errorable-bloc btn-group-toggle"
        type="radio"
        onChange={changeDisplayTimeZone}
        value={displayTimeZone}
      >
        <ToggleButton variant="toggle-primary" value={'LOCAL'} id="LOCAL">
          Local
        </ToggleButton>
        <ToggleButton variant="toggle-primary" value={'GMT'} id="GMT">
          GMT
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}
