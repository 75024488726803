import type { Thunk } from '@/bootstrap/thunks';
import type { ActorsSetup } from '../../models';

export function createTrySelectCounterpart(
  rfqId: string,
  counterpartId: number | undefined,
): Thunk {
  return function trySelectCounterpart(
    dispatch,
    getState,
    {
      selectors: { getCounterparts },
      actionCreators: {
        neos: { actorsSetupCrudActions },
      },
    },
  ) {
    const state = getState();
    const counterparts = getCounterparts(state, rfqId)!;
    const shouldUpdateCounterpartId = counterparts?.some(({ id }) => id === counterpartId);

    const actorsSetupPatch: Partial<ActorsSetup> = {
      counterpartId: shouldUpdateCounterpartId ? counterpartId : undefined,
    };

    dispatch(actorsSetupCrudActions.update(rfqId, actorsSetupPatch));
  };
}
