import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ErrorableFieldHandler } from '../mappers/error/internal/errorIdGenerator';

export function getStrategyIndicesWithError(
  state: AppState,
  rfqId: string,
  {
    selectors,
    getStrategyErrorId,
  }: {
    selectors: Selectors;
    getStrategyErrorId: ErrorableFieldHandler['strategy'];
  },
): number[] {
  const { strategyIds } = selectors.getRfqData(state, rfqId);
  return strategyIds
    .map((strategyId, index) => ({ strategyId, index }))
    .filter(
      ({ strategyId }) =>
        selectors.getNeosError(state.ui, getStrategyErrorId({ rfqId, strategyId })).isError,
    )
    .map(({ index }) => index);
}
