import type { Thunk } from '@/bootstrap/thunks';
import { type OptionFlex, isListedProduct } from '../legModel';

export function createOptionFlexChangedThunk(
  strategyId: string,
  productId: string,
  optionFlex: OptionFlex | undefined,
): Thunk {
  return function optionFlexChangedThunk(
    dispatch,
    getState,
    { selectors, thunks, actionCreators },
  ) {
    const state = getState();
    const product = selectors.getProduct(state, productId);

    const onlyOneStrategyProductFlexOptions = selectors.onlyOneStrategyProductFlexOptions(
      state,
      strategyId,
      selectors,
    );

    const isCurrentProductMarketIflo =
      isListedProduct(product) && product.marketExchangeId === 'IFLO';

    const { strategyType } = selectors.getStrategyData(state, strategyId);
    const { sameProductLotSize } = selectors.getStrategyDefinition(
      state.referenceData,
      strategyType,
    );

    if (optionFlex !== 'FLEX' && onlyOneStrategyProductFlexOptions && isCurrentProductMarketIflo) {
      if (sameProductLotSize) {
        const productIds = selectors.getStrategyProductIds(state, strategyId, selectors);
        const productActions = productIds.map(id =>
          actionCreators.neos.productCrudActions.update(id, {
            lotSize: undefined,
            marketExchangeId: undefined,
          }),
        );
        dispatch(productActions);
      } else {
        dispatch(
          actionCreators.neos.productCrudActions.update(productId, {
            lotSize: undefined,
            marketExchangeId: undefined,
          }),
        );
      }
    }

    dispatch(thunks.neos.createUpdateProductThunk(strategyId, productId, 'flex', optionFlex));
  };
}
