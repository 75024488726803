export type StateMap<T> = Record<string, T>;

export function find<T>(map: StateMap<T>, matches: (obj: T) => boolean): T | undefined {
  const arrayOfT: T[] = Object.values(map);
  return arrayOfT.find(matches);
}

export function insert<T>(map: StateMap<T>, key: string, val: T): StateMap<T> {
  if (map[key] !== undefined) {
    throw new Error(
      `addKey cannot overwrite existing key ${key} for inserting ${JSON.stringify(val)}`,
    );
  }
  return { ...map, [key]: val };
}

export function insertOrUpdate<T>(map: StateMap<T>, key: string, val: T): StateMap<T> {
  return { ...map, [key]: val };
}

export function remove(map: StateMap<any>, key: string) {
  const { [key]: val, ...rest } = map;
  return val !== undefined ? rest : map;
}

export function update<T>(
  map: StateMap<T>,
  key: string,
  getPatch: (keyState: T) => Partial<T> | null,
): StateMap<T> {
  if (map[key] === undefined) {
    return map;
  }
  const patch = getPatch(map[key]);
  return patch !== null ? { ...map, [key]: { ...(map[key] as any), ...(patch as any) } } : map;
}
