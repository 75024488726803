import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import type { OptionStyle } from '../../../../../../../neos/business/neosModel';
import {
  OptionStyleCells as Component,
  type OptionStyleCellsDispatchProps,
  type OptionStyleCellsMapStateProps,
  type OptionStyleCellsOwnProps,
} from './OptionStyleCells';
import { getOptionStyleCellsModel } from './getOptionStyleCellsModel';

export const OptionStyleCells = connect(mapStateToProps, mapDispatchToProps)(Component);

function mapStateToProps(
  state: AppState,
  { strategyId, rfqId }: OptionStyleCellsOwnProps,
): OptionStyleCellsMapStateProps {
  return getOptionStyleCellsModel(state, rfqId, strategyId, selectors);
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId, strategyId }: OptionStyleCellsOwnProps,
): OptionStyleCellsDispatchProps {
  return {
    onOptionStyleChanged(productId: string, optionStyle: OptionStyle | undefined) {
      dispatch(
        neosThunks.createUpdateProductAndResetDependenciesThunk(
          rfqId,
          strategyId,
          productId,
          'style',
          optionStyle,
        ),
      );
    },
  };
}
