import type { Thunk } from '@/bootstrap/thunks';

export function createSendPreconfirmationEmailRequestedThunk(
  rfqId: string,
  isExternalSelected: boolean,
  isInternalSelected: boolean,
  ignoreFieldChanges = false,
): Thunk {
  return function sendPreconfirmationEmailRequestedThunk(
    dispatch,
    getState,
    {
      thunks: {
        neos: {
          createExternalPreconfirmationEmailSendThunk,
          createInternalPreconfirmationEmailSendThunk,
        },
      },
      actionCreators,
      selectors: { getInternalMailOptions, getExternalMailOptions },
    },
  ) {
    const state = getState();
    const internalMailOptions = getInternalMailOptions(state, rfqId);
    const externalMailOptions = getExternalMailOptions(state, rfqId);
    const dispatchables = [];
    if (isExternalSelected && externalMailOptions) {
      dispatchables.push(
        createExternalPreconfirmationEmailSendThunk(rfqId, externalMailOptions, ignoreFieldChanges),
      );
      dispatchables.push(
        actionCreators.neos.externalPreconfirmationCrudActions.patchOrInsert(rfqId, {
          ...externalMailOptions,
        }),
      );
    }
    if (isInternalSelected && internalMailOptions) {
      dispatchables.push(
        createInternalPreconfirmationEmailSendThunk(rfqId, internalMailOptions, ignoreFieldChanges),
      );
      dispatchables.push(
        actionCreators.neos.internalPreconfirmationCrudActions.patchOrInsert(rfqId, {
          ...internalMailOptions,
        }),
      );
    }
    dispatch(dispatchables);
  };
}
