import type { AppState } from '@/bootstrap/state';
import type { Thunk } from '@/bootstrap/thunks';
import { createDefaultFeature } from '../../../neosModel';
import type {
  FeatureDefinition,
  FeatureType,
} from '../../../referenceData/strategyDefinition/strategyDefinitionModel';

export function createAddExtraFeatureThunk(strategyId: string, featureType: FeatureType): Thunk {
  return function addExtraFeature(
    dispatch,
    getState,
    {
      actionCreators: {
        neos: { featureCrudActions },
      },
      selectors,
    },
  ) {
    // Get feature definitions
    const state: AppState = getState();
    const referenceDataState = state.referenceData;
    const { strategyType } = selectors.getStrategyData(state, strategyId);
    const { legs } = selectors.getStrategyDefinition(referenceDataState, strategyType);
    const { features: featureDefinitions } = legs[0];

    // Check that this feature type is defined for this strategy
    const featureDefinition: FeatureDefinition | undefined = featureDefinitions.find(
      ({ type }: FeatureDefinition) => type === featureType,
    );
    if (!featureDefinition) {
      throw new Error(`No ${featureType} is defined for this strategy type`);
    }
    if (!featureDefinition.isExtra) {
      throw new Error(
        `The ${featureType} feature is not defined as an extra feature for this strategy type`,
      );
    }

    const currency = selectors.getCurrencyByStrategyId(state, strategyId, selectors);
    const feature = createDefaultFeature({ strategyId, type: featureType }, currency);

    dispatch(
      featureCrudActions.upsert({ strategyId: feature.strategyId, type: feature.type }, feature),
    );
  };
}
