import type { Thunk } from '@/bootstrap/thunks';
import type { ExecutionKey } from '../executionModel';

export function createChangeExecutionNumberOfLotsThunk(
  executionKey: ExecutionKey,
  numberOfLots: number | undefined,
): Thunk {
  return function changeExecutionQuantityThunk(
    dispatch,
    getState,
    { selectors, actionCreators: { neos: as }, thunks: { neos: nt } },
  ) {
    const state = getState();
    const order = selectors.getOrder(state.orderData, executionKey.orderId);
    if (!order) {
      return;
    }
    const lotSize = order.lotSize;

    const quantity = numberOfLots && lotSize ? numberOfLots * lotSize : undefined;

    dispatch(
      as.executionCrudActions.update(executionKey, {
        size: {
          quantity,
          numberOfLots,
          sizeType: 'FIXED_QUANTITY',
        },
      }),
      nt.createChangeOrderUpdateExecSizeThunk({
        orderId: executionKey.orderId,
        rfqId: executionKey.rfqId,
      }),
      as.createDefaultTradeRecapRequestedAction(executionKey.rfqId, {
        success: {
          dispatchables: [
            nt.createChangeOrderUpdateAveragePriceThunk({
              orderId: executionKey.orderId,
              rfqId: executionKey.rfqId,
            }),
          ],
        },
      }),
    );
  };
}
