import type { Thunk } from '@/bootstrap/thunks';
import { notionalSizeTypes } from '../../../../../neosOnyxModel';
import { getDefaultedProduct } from './util';

export function createDefaultLegsAsPerStrategyDefinitionThunk(strategyId: string): Thunk {
  return function defaultLegsAsPerStrategyDefinitionThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { productCrudActions, legDataCrudActions },
      },
      thunks: {
        neos: { createDefaultProductLotSizeMarketThunk },
      },
    },
  ) {
    const appState = getState();
    const { referenceData } = appState;
    const { strategyType, legIds } = selectors.getStrategyData(appState, strategyId);

    const strategyDefinition = selectors.getStrategyDefinition(referenceData, strategyType);

    const firstLegSizeType = selectors.getLegData(appState, legIds[0]).sizeType;
    const firstLegDefinition = strategyDefinition.legs[0];
    const newSizeType =
      firstLegSizeType && firstLegDefinition.availableSizeTypes.includes(firstLegSizeType)
        ? firstLegSizeType
        : firstLegDefinition.sizeType;

    const legDefaultingActions = strategyDefinition.legs.map((leg, index) => {
      const legId: string = legIds[index];
      const { numberOfLots, varUnit, notional, localNotional } = selectors.getLegData(
        appState,
        legId,
      );

      const sizeData = {
        sizeType: newSizeType,
        numberOfLots: newSizeType === 'FIXED_QUANTITY' ? numberOfLots : undefined,
        quantity: undefined,
        varUnit: newSizeType === 'FIXED_VAR_UNIT' ? varUnit : undefined,
        notional: notionalSizeTypes.includes(newSizeType) ? notional : undefined,
        localNotional: notionalSizeTypes.includes(newSizeType) ? localNotional : undefined,
      };

      return legDataCrudActions.update(legId, {
        weight: leg.weight,
        taxCollection: leg.taxCollection,
        isMaster: leg.master,
        ...sizeData,
      });
    });

    const productDefaultingActions = strategyDefinition.legs.map(
      ({ product: strategyProductDefaultValues }, index) => {
        const { productId } = selectors.getLegData(appState, legIds[index]);
        const currentProduct = selectors.getProduct(appState, productId);

        const modifiedProduct = strategyDefinition.hasACompositionLeg
          ? { ...currentProduct, underlyingId: undefined }
          : currentProduct;

        const defaultedProduct = getDefaultedProduct(modifiedProduct, {
          ...strategyProductDefaultValues,
          underlying: undefined,
        });
        return productCrudActions.upsert(productId, defaultedProduct);
      },
    );

    dispatch(
      ...legDefaultingActions,
      ...productDefaultingActions,
      createDefaultProductLotSizeMarketThunk(
        selectors.getStrategyProductIds(appState, strategyId, selectors),
      ),
    );
  };
}
