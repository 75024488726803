import type { OnyxRfq } from '@/neos/business/rfq/rfqOnyxModel';
import { flatMap } from 'lodash';
import type { BlotterModelGetters } from '.';
import { formatPriceWithUnit } from '../priceFormatter';

export function getLegStrikes(
  rfq: OnyxRfq,
  { getAllStrategiesExceptDeltaAdj, twoDimentionalArrayToString }: BlotterModelGetters,
) {
  const strategies = getAllStrategiesExceptDeltaAdj(rfq);
  const legStrikes = strategies.map(strat =>
    flatMap(strat.legs, ({ product }) =>
      product?.lowerStrike || product?.upperStrike
        ? [product?.lowerStrike, product?.upperStrike]
        : [product?.strike],
    ),
  );

  return twoDimentionalArrayToString(legStrikes, formatPriceWithUnit);
}
