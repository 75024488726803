import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { FC } from 'react';
import { useSelector } from 'react-redux';
import { MdpRfqTimerComponent, type MdpRfqTimerOwnProps } from '../MdpRfqTimer';
import {
  type RfqTimerModel,
  getMdpRfqOrderReceivedTimerModel,
} from './getMdpRfqOrderReceivedTimerModel';

export const MdpRfqOrderReceivedTimer: FC<MdpRfqTimerOwnProps> = ownProps => {
  const model = useSelector<AppState, RfqTimerModel>(state => {
    return getMdpRfqOrderReceivedTimerModel(state, ownProps.rfqId, selectors);
  });

  if (model.isMdpRfqTimerDisplay) {
    const { isMdpRfqTimerDisplay, ...rest } = model;
    return <MdpRfqTimerComponent {...rest} {...ownProps} />;
  }
  return null;
};
