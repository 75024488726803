import { PredealCheckBullet } from '../../../predealCheckBullet';
import type { CellKey, PredealCheckCellModel } from './getPredealCheckCellModel';

export interface PredealCheckCellStateProps {
  model: PredealCheckCellModel;
}

export interface PredealCheckCellDispatchProps {
  onSelected: (predealCheckId: string) => void;
}

export type PredealCheckCellOwnProps = CellKey;

type Props = PredealCheckCellOwnProps & PredealCheckCellStateProps & PredealCheckCellDispatchProps;
export const PredealCheckCellComponent = (props: Props) => {
  if (!props.model.isDisplayed) {
    return null;
  }

  const bulletProps = props.model.bullet.isSelectable
    ? { ...props.model.bullet, onSelected: props.onSelected }
    : props.model.bullet;

  return <PredealCheckBullet {...bulletProps} isElementCentered />;
};
