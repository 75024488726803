import { NeosTooltip } from '@/neos/components/share/tooltip/NeosTooltip';
import { useState } from 'react';
import { Modal, Tooltip as ReactBootstrapTooltip } from 'react-bootstrap';
import type { DeltaAdjustedBookingModel } from './getDeltaAdjustedBookingModel';

import { NumericInput } from '@/neos/components/share/numericInput';

export interface DeltaAdjustedBookingOwnProps {
  rfqId: string;
}

export type DeltaAdjustedBookingMapStateToProps = DeltaAdjustedBookingModel;

export type DeltaAdjustedBookingProps = DeltaAdjustedBookingOwnProps &
  DeltaAdjustedBookingMapStateToProps;

export const DeltaAdjustedBookingComponent = ({ bookingStatuses }: DeltaAdjustedBookingProps) => {
  const [showModalFor, setShowModalFor] = useState<(typeof bookingStatuses)[number] | undefined>(
    undefined,
  );

  return (
    <>
      {bookingStatuses.map((bs, i) => (
        <div key={`${bs.underlyingLabel ?? 'N/A'}${i}`}>
          <NeosTooltip
            placement="top"
            trigger={['hover', 'focus']}
            overlay={
              <ReactBootstrapTooltip id="bs_tooltip">
                <strong>Click here to open booking details</strong>
              </ReactBootstrapTooltip>
            }
          >
            <button
              data-e2e="neos-delta-adjusted-booking-status"
              className={`badge ${bs.aggregatedBadge.className}`}
              onClick={() => setShowModalFor(bs)}
            >
              {bs.aggregatedBadge.name}
            </button>
          </NeosTooltip>
        </div>
      ))}
      <Modal
        restoreFocus={false}
        size="lg"
        centered
        show={!!showModalFor}
        onHide={() => setShowModalFor(undefined)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delta Adjusted Booking for {showModalFor?.underlyingLabel}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-sm table-borderless">
            <thead>
              <tr>
                <th scope="col" className="text-end">
                  <div className="me-1">Qty.</div>
                </th>
                <th scope="col" className="text-end">
                  <div className="me-1">Price</div>
                </th>
                <th scope="col">Booking Id</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {showModalFor?.executionsBookingStep.map(ex => (
                <tr key={ex.execution.uuid}>
                  <td>
                    <NumericInput value={ex.execution.size?.quantity} readOnly />
                  </td>
                  <td>
                    <NumericInput
                      value={ex.execution.lastPrice?.value}
                      unit={ex.execution.lastPrice?.unit}
                      readOnly
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      readOnly
                      value={ex.bookingStep?.bookingId ?? ''}
                    />
                  </td>
                  <td>
                    <span
                      data-e2e="neos-delta-adjusted-booking-step-status"
                      className={`badge ${ex.uiBookingStep?.className ?? ''}`}
                    >
                      {ex.uiBookingStep?.name}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    </>
  );
};
