import { capitalize } from 'lodash';

import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import { useSelector } from 'react-redux';
import styles from './BookingStepStatusLabel.module.scss';
import { getBookingStepStatusLabelModel } from './getBookingStepStatusLabelModel';

export interface BookingStepStatusLabelProps {
  rfqId: string;
  strategyId: string;
  onFocus: () => void;
}

export const BookingStepStatusLabel = ({
  onFocus,
  rfqId,
  strategyId,
}: BookingStepStatusLabelProps) => {
  const uiBookingStatus = useSelector((state: AppState) =>
    getBookingStepStatusLabelModel(state, { rfqId, strategyId }, selectors),
  );
  return (
    <SimpleNeosTooltip
      disable={!uiBookingStatus.tooltip}
      type={'danger'}
      message={capitalize(uiBookingStatus.tooltip)}
    >
      <section className={styles['settings-grid']} onFocus={onFocus}>
        <div>
          <span
            data-e2e="neos-strategy-booking-status"
            className={`badge ${uiBookingStatus.className}`}
          >
            {uiBookingStatus.name}
          </span>
        </div>
      </section>
    </SimpleNeosTooltip>
  );
};
