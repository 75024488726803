import { Component } from 'react';
import { Modal } from 'react-bootstrap';
import type { SwitcherFeatureType } from '../../../../../../../neos/business/neosModel';
import { FeatureSwitcher } from './FeatureSwitcher';

import styles from './ExtraFeaturesModal.module.scss';

export interface OwnProps {
  strategyId: string;
}

export interface MapStateToProps {
  modalState: 'SHOW' | 'HIDE';
  eligibleFeatureTypes: SwitcherFeatureType[];
}

export interface DispatchProps {
  onModalHide: () => void;
}

export type Props = OwnProps & MapStateToProps & DispatchProps;

export class ExtraFeaturesModalStyled extends Component<Props> {
  public render() {
    const { strategyId, modalState, onModalHide, eligibleFeatureTypes } = this.props;
    return (
      modalState === 'SHOW' && (
        <Modal size="sm" show={modalState === 'SHOW'} onHide={onModalHide}>
          <Modal.Header closeButton>
            <Modal.Title>Extra Features</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={`${styles['extra-features-switchers-container']}`}>
              <div className={`${styles['extra-features-switchers']}`}>
                {eligibleFeatureTypes.map(type => (
                  <FeatureSwitcher key={type} strategyId={strategyId} type={type} />
                ))}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )
    );
  }
}
