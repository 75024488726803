import type { Dispatchable, Thunk } from '@/bootstrap/thunks';
import type { CounterpartRequest } from '../counterpart/counterpartActions';

export function createEnsureCounterpartsLoadedThunk(
  requests: CounterpartRequest[],
  nextDispatchable?: Dispatchable,
): Thunk {
  return function ensureCounterpartsLoadedThunk(
    dispatch,
    getState,
    {
      selectors: { getCounterpartsBySales },
      actionCreators: {
        neos: { createCounterpartsRequestedAction },
      },
      thunks: {
        neos: { createAddExtraCounterPartThunk },
      },
    },
  ) {
    const state = getState();
    const requestsTofetch = requests.filter(r => !getCounterpartsBySales(state, r.salesIds));
    const requestsAlreadyFetched = requests.filter(r => getCounterpartsBySales(state, r.salesIds));

    requestsAlreadyFetched.forEach(({ rfqIds, salesIds }) =>
      dispatch(createAddExtraCounterPartThunk(rfqIds, salesIds)),
    );

    if (requestsTofetch.length > 0) {
      dispatch(createCounterpartsRequestedAction(requestsTofetch, nextDispatchable));
    } else if (nextDispatchable) {
      dispatch(nextDispatchable);
    }
  };
}
