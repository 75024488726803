import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import { PredealCheckBullet } from '../../../../predealChecks/predealCheckBullet';
import { convertToBulletColor, getAggregationColor } from '../../../../predealChecks/util';
import { neosActionCreators } from '@/neos/business/neosActionCreators.ts';
import { useDispatch } from 'react-redux';
import type { ExtendedDispatch } from '@/bootstrap/thunks.ts';
import { selectors } from '@/bootstrap/selectors.ts';
import { useAppSelector } from '@/bootstrap/hooks.ts';
import { isRiskPdc } from '@/neos/business/predealCheck/predealCheckSelectors.ts';

export interface OtcAllocPdcProps {
  counterpartId?: number;
  rfqId: string;
}

export const OtcAllocPdc = ({ rfqId, counterpartId }: OtcAllocPdcProps) => {
  const dispatch = useDispatch<ExtendedDispatch>();
  const { riskPdc, othersPdc } = useAppSelector(state => {
    const allocPdc = selectors.getAllNeosAllocationPredealChecks(state, rfqId, selectors);

    const riskPdc = allocPdc.filter(pdc => pdc.counterpartyId === counterpartId && isRiskPdc(pdc));
    const othersPdc = allocPdc.filter(
      pdc => pdc.counterpartyId === counterpartId && !isRiskPdc(pdc),
    );

    return { riskPdc, othersPdc };
  });

  if (!counterpartId) {
    return <div />;
  }

  const riskColor = getAggregationColor(riskPdc.map(pdc => convertToBulletColor(pdc.uiColor)));
  const othersColor = getAggregationColor(othersPdc.map(pdc => convertToBulletColor(pdc.uiColor)));

  const onClick = () => {
    dispatch(
      neosActionCreators.rfqUiCrudActions.update(rfqId, {
        showPredealChecksModal: true,
      }),
    );
  };

  const isLoading =
    (riskPdc.length > 0 && riskPdc.some(pdc => pdc.status === 'COMPUTING')) ||
    (othersPdc.length > 0 && othersPdc.some(pdc => pdc.status === 'COMPUTING'));

  return (
    <div className="d-flex gap-1 m-auto">
      <SimpleNeosTooltip message="Credit Check">
        <div onClick={onClick}>
          <PredealCheckBullet
            isSelectable={false}
            color={riskColor}
            isElementCentered={false}
            isLoading={isLoading}
          />
        </div>
      </SimpleNeosTooltip>
      <SimpleNeosTooltip message="Regulation">
        <div onClick={onClick}>
          <PredealCheckBullet
            isSelectable={false}
            color={othersColor}
            isElementCentered={false}
            isLoading={isLoading}
          />
        </div>
      </SimpleNeosTooltip>
    </div>
  );
};
