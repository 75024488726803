import type { EventType } from '@/neos/business/rfq/strategy/leg/legData/legDataModel';
import type { OnyxCreatedSecondaryEvent } from '@/neos/components/rfq/strategies/globalActions/secondaryEventWidget/createSecondary/OnyxCreateSecondaryModel';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { Observable } from 'rxjs';

export interface CreateSecondaryFromXoneIdApi {
  createSecondaryFromXoneIdApi: (
    bookingId: string,
    eventType: EventType,
  ) => Observable<OnyxCreatedSecondaryEvent>;
}

export const createCreateSecondaryFromXoneIdApi = (
  http: SgmeHttp,
): CreateSecondaryFromXoneIdApi => ({
  createSecondaryFromXoneIdApi(bookingId, eventType) {
    const url: string = `api/post-trade/secondary/external`;
    return http.put<OnyxCreatedSecondaryEvent>({
      url,
      body: {
        bookingId: {
          id: bookingId,
          application: 'XONE',
        },
        eventType,
      },
    });
  },
});
