import { thunks } from '@/bootstrap/thunks.ts';
import { useDispatch } from 'react-redux';
import { isElsProduct } from '@/neos/business/rfq/strategy/leg/product/productModel.ts';
import { useContext } from 'react';
import {
  commonElsBlocClasses,
  ElsFeaturesContext,
} from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures.tsx';
import { useUpdateSectionHeight } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/useUpdateSectionHeight.ts';
import { useAppSelector } from '@/bootstrap/hooks.ts';
import { selectors } from '@/bootstrap/selectors.ts';
import { DealTypeComponent } from './DealType';
import { EquityFinanceTypeComponent } from './EquityFinanceTypeComponent';
import { PartialReset } from './PartialReset';
import { ValuationFrequencyComponent } from './ValuationFrequency';
import { ValuationTypeComponent } from './ValuationType';

interface ProductDetailsProps {
  canBeHidden: boolean;
}

export function ProductDetailsBloc({ canBeHidden }: ProductDetailsProps) {
  const dispatch = useDispatch();
  const { strategyId } = useContext(ElsFeaturesContext);
  const { ref } = useUpdateSectionHeight(strategyId, 'Product Details');
  const product = useAppSelector(state =>
    selectors.getStrategyMasterProduct(state, strategyId, selectors),
  );

  function hideSection() {
    dispatch(thunks.neos.createToggleElsSectionThunk('Product Details', false));
  }

  if (!isElsProduct(product)) {
    return null;
  }

  return (
    <div className={commonElsBlocClasses} style={{ cursor: 'grab' }}>
      <div ref={ref}>
        <div className="d-flex justify-content-between">
          <h5 className="d-inline-block p-1 m-0 mb-1">Product Details</h5>
          {canBeHidden && (
            <button className="btn btn-icon btn-flat-primary" onClick={hideSection}>
              <i className="icon">clear</i>
            </button>
          )}
        </div>
        <div className="d-flex flex-wrap">
          <ValuationTypeComponent />
          <ValuationFrequencyComponent />
          <PartialReset />
          <DealTypeComponent />
          <EquityFinanceTypeComponent />
        </div>
      </div>
    </div>
  );
}
