import { useState } from 'react';
import type { StrategyLinkCellModel } from './getStrategyLinkCellModel';

export interface StrategyLinkCellOwnProps {
  rfqId: string;
  strategyId: string;
}

export type StrategyLinkCellMapStateProps = StrategyLinkCellModel;

export interface StrategyLinkCellDispatchProps {
  onLink: (isPrimaryRfq: boolean) => void;
}

export type StrategyLinkCellProps = StrategyLinkCellOwnProps &
  StrategyLinkCellMapStateProps &
  StrategyLinkCellDispatchProps;

export const StrategyLinkCellComponent = (props: StrategyLinkCellProps) => {
  const [toggleLink, setToggleLink] = useState(false);
  const [isPrimaryRfq, setIsPrimaryRfq] = useState(true);
  function handleClick() {
    setToggleLink(!toggleLink);
    setIsPrimaryRfq(!isPrimaryRfq);
    props.onLink(isPrimaryRfq);
  }

  return (
    <button
      className={`text-center btn text-large p-0 btn-primary`}
      disabled={props.disabled}
      onClick={() => handleClick()}
    >
      <span className="icon icon-sm">link</span>
    </button>
  );
};
