import { type ActionCreators, actionCreators } from '@/bootstrap/actions';
import { type Selectors, selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { type Thunks, thunks } from '@/bootstrap/thunks';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { type Epic, ofType } from 'redux-observable';
import { type Observable, of, zip } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { wrapInChainableLoadingObservable } from '../epics/wrapInChainableLoadingObservable';
import { mappers } from '../mappers';
import type { OnyxError } from '../mappers/error';
import { createRetrieveUnderlyingOptionInfoApi } from './underlyingInfoApi';
import type {
  OnyxUnderlyingDividendFutureInfo,
  OnyxUnderlyingFutureInfo,
  OnyxUnderlyingOptionInfo,
  OnyxUnderlyingTotalReturnFutureInfo,
} from './underlyingInfoOnyxModel';

export interface RetrieveUnderlyingOptionInfoApi {
  retrieveUnderlyingOptionInfos: (
    underlyingIds: string[],
  ) => Observable<OnyxUnderlyingOptionInfo[]>;
  retrieveUnderlyingFutureInfos: (
    underlyingIds: string[],
  ) => Observable<OnyxUnderlyingFutureInfo[]>;
  retrieveUnderlyingDividendFutureInfos: (
    underlyingIds: string[],
  ) => Observable<OnyxUnderlyingDividendFutureInfo[]>;
  retrieveUnderlyingTotalReturnFutureInfos: (
    underlyingIds: string[],
  ) => Observable<OnyxUnderlyingTotalReturnFutureInfo[]>;
  retrieveFutureRefMaturities: (underlyingIds: string[]) => Observable<Record<string, string[]>>;
}

export function getUnderlyingOptionInfoEpic(http: SgmeHttp): Epic {
  const api = createRetrieveUnderlyingOptionInfoApi(http);
  return createUnderlyingOptionInfoEpic(api, actionCreators, thunks, selectors);
}

export function createUnderlyingOptionInfoEpic(
  api: RetrieveUnderlyingOptionInfoApi,
  {
    common: { createAppCrashedAction },
    neos: { createUnderlyingsInfosReceivedAction },
  }: ActionCreators,
  { createErrorToasterThunk }: Thunks,
  { isFeatureToggleEnabled }: Selectors,
): Epic {
  return (action$, state$) =>
    action$.pipe(
      ofType('UNDERLYING_INFO_REQUESTED'),
      mergeMap(({ rfqIds, underlyingIds, chainOptions }) => {
        const appState = state$.value;
        const underlyingOptionInfo$ = zip(
          api.retrieveUnderlyingOptionInfos(underlyingIds),
          api.retrieveUnderlyingFutureInfos(underlyingIds),
          api.retrieveFutureRefMaturities(underlyingIds),
          api.retrieveUnderlyingDividendFutureInfos(underlyingIds),
          isFeatureToggleEnabled(appState as AppState, 'neos.rfq.total.return.future.enabled')
            ? api.retrieveUnderlyingTotalReturnFutureInfos(underlyingIds)
            : of([]),
        );

        return wrapInChainableLoadingObservable({
          tabIds: rfqIds,
          apiObservable: underlyingOptionInfo$,
          observableOptions: {
            success: {
              on: underlyingInfos => {
                return [
                  createUnderlyingsInfosReceivedAction(underlyingInfos, mappers.fromOnyxMappers),
                ];
              },
            },
            error: {
              on: (error: OnyxError) => [
                createAppCrashedAction('underlying-option-info-requested', error),
                createErrorToasterThunk(
                  {
                    message: 'Error when retrieving the underlying details',
                  },
                  error,
                ),
              ],
            },
          },
          chainOptions,
        });
      }),
    );
}
