import type { FromOnyxMappers } from '@/neos/business/mappers';
import { convertNullToUndefined } from '@/util/undefinedAndNull/convertNullToUndefined';
import type { AsianSpreadOption } from '../../productModel';
import type { OnyxSingleUnderlyingProduct } from '../../productOnyxModel';

import { mapFromOnyxPeriod } from '@/neos/business/rfq/strategy/leg/product/mappers/specificProductMappers/mapFromOnyxPeriod';

export function mapFromOnyxProductAsianSpreadOption(
  legId: string,
  onyxProduct: OnyxSingleUnderlyingProduct,
  productId: string,
  mappers: FromOnyxMappers,
): AsianSpreadOption {
  const { lowerStrike, upperStrike, strikeUnit } = mappers.mapFromOnyxUpperLowerStrike(onyxProduct);
  return {
    subFamily: 'ASIAN_SPREAD_OPTION',
    lowerStrike,
    upperStrike,
    strikeUnit,
    style: convertNullToUndefined(onyxProduct.optionStyle),
    type: onyxProduct.optionType || undefined,
    ...mappers.mapFromOnyxProductCommon(legId, onyxProduct, productId),
    ...mappers.mapFromOnyxNegotiation(onyxProduct.negotiation),
    ...mappers.mapFromSingleDerivativeOnyxProduct(onyxProduct, mappers),
    hasCustomUnderlying: false,
    period: mapFromOnyxPeriod(onyxProduct.period),
    strikeType: onyxProduct.strikeType ?? undefined,
    averageDisruptionDate: onyxProduct.averageDisruptionDate ?? undefined,
    businessDayConvention: onyxProduct.businessDayConvention ?? undefined,
  };
}
