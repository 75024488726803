import type { ThunkEpic } from '@/bootstrap/epics';
import { type Thunks, thunks } from '@/bootstrap/thunks';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { ofType } from 'redux-observable';
import type { Observable } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import type { OnyxError } from '../../mappers/error';
import { createCancelRfqsApi } from '../blotterApi';
import type { BulkCancelReason } from '../blotterModel';

export interface CancelTransactionApiModel {
  transactions: { rfq: { uuid: string } }[];
}

export interface CancelRfqsApi {
  cancelRfqs: (
    transactions: CancelTransactionApiModel,
    cancelReason: BulkCancelReason,
  ) => Observable<void>;
}

export function getCancelRfqsEpic(http: SgmeHttp): ThunkEpic {
  const api = createCancelRfqsApi(http);
  return createCancelRfqsEpic(api, thunks);
}

export function createCancelRfqsEpic(
  api: CancelRfqsApi,
  { createInfoToasterThunk, createErrorToasterThunk }: Thunks,
): ThunkEpic {
  return action$ =>
    action$.pipe(
      ofType('REQUEST_BULK_CANCEL_RFQS_ACTION'),
      mergeMap(({ rfqIds, reason }) => {
        return api
          .cancelRfqs({ transactions: rfqIds.map(uuid => ({ rfq: { uuid } })) }, reason)
          .pipe(
            mergeMap(() => [
              createInfoToasterThunk({
                title: 'Bulk Cancellation Request',
                message: 'Request taken into account',
              }),
            ]),
            catchError((error: OnyxError) => [
              createErrorToasterThunk({ message: 'Error on bulk cancellation request' }, error),
            ]),
          );
      }),
    );
}
