import type { ThunkEpic } from '@/bootstrap/epics';
import { type Thunks, thunks } from '@/bootstrap/thunks';
import {
  type CurrentUserPreferencesApi,
  createCurrentUserPreferencesApis,
} from '@/neos/business/apis/currentUserPreferencesApis';
import type { OnyxError } from '@/neos/business/mappers/error';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { ofType } from 'redux-observable';
import { catchError, map, mergeMap } from 'rxjs/operators';

export function getCurrentUserPreferencesEpic(http: SgmeHttp): ThunkEpic {
  const api = createCurrentUserPreferencesApis(http);
  return createGetCurrentUserPreferencesEpic(api, thunks);
}

export function postCurrentUserPreferencesEpic(http: SgmeHttp): ThunkEpic {
  const api = createCurrentUserPreferencesApis(http);
  return createPostCurrentUserPreferencesEpic(api, thunks);
}

export function createGetCurrentUserPreferencesEpic(
  api: CurrentUserPreferencesApi,
  { createErrorToasterThunk, neos: { createIntegrateCurrentUserPreferencesThunk } }: Thunks,
): ThunkEpic {
  return action$ => {
    return action$.pipe(
      ofType('GET_CURRENT_USER_PREFERENCES'),
      mergeMap(() =>
        api.getCurrentUserPreferences().pipe(
          map(preferences => createIntegrateCurrentUserPreferencesThunk(preferences)),
          catchError((error: OnyxError) => [
            createErrorToasterThunk({ message: 'Error getting current user preferences' }, error),
          ]),
        ),
      ),
    );
  };
}

export function createPostCurrentUserPreferencesEpic(
  api: CurrentUserPreferencesApi,
  { createErrorToasterThunk }: Thunks,
): ThunkEpic {
  return action$ => {
    return action$.pipe(
      ofType('UPDATE_CURRENT_USER_PREFERENCES'),
      mergeMap(({ currentUserNewPreferences }) =>
        api.postCurrentUserPreferences(currentUserNewPreferences).pipe(
          map(() => []),
          catchError((error: OnyxError) => [
            createErrorToasterThunk({ message: 'Error post current user new preferences' }, error),
          ]),
        ),
      ),
    );
  };
}
