import { crudDomain } from '@/util/crudUtils';
import type { OrderData, OrderKey } from './orderDataModel';

const compositeIdSeparator = ' | ';

function getOrderId({ rfqId, orderId }: OrderKey): string {
  return `${rfqId}${compositeIdSeparator}${orderId}`;
}

function getOrderKey(id: string): OrderKey {
  const [rfqId, orderId] = id.split(compositeIdSeparator);
  return { rfqId, orderId };
}

export const orderDomain = crudDomain<OrderData, OrderKey>('order', {
  keyToString: getOrderId,
  stringToKey: getOrderKey,
});
