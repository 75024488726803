import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import type { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ReplicateInDeltaButtonComponent,
  type ReplicateInDeltaButtonDispatchProps,
  type ReplicateInDeltaButtonOwnProps,
  type ReplicateInDeltaButtonStateProps,
} from './ReplicateInDeltaButton';
import { getReplicateInDeltaButtonModel } from './getReplicateInDeltaButtonModel';

export const ReplicateInDeltaButton: FC<ReplicateInDeltaButtonOwnProps> = ownProps => {
  const dispatch = useDispatch<ExtendedDispatch>();
  const stateProps = useSelector<AppState, ReplicateInDeltaButtonStateProps>(appState => ({
    model: getReplicateInDeltaButtonModel(appState, ownProps.rfqId, selectors),
  }));
  const dispatchProps = mapDispatchProps(dispatch, ownProps);

  return <ReplicateInDeltaButtonComponent {...ownProps} {...stateProps} {...dispatchProps} />;
};

function mapDispatchProps(
  dispatch: ExtendedDispatch,
  { rfqId }: ReplicateInDeltaButtonOwnProps,
): ReplicateInDeltaButtonDispatchProps {
  return {
    onButtonClicked: () => {
      dispatch(
        createLogAnalyticsAction('NEOS RFQ', 'Replicate Strategy Pre Allocations in Delta', rfqId),
        neosThunks.createApplyRfqListedPreallocationsToDeltaThunk(rfqId),
      );
    },
  };
}
