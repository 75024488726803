import type { Curve } from './curveModel';
import type { CurvesAction } from './curvesListActions';

export const curvesListActionCreators = {
  createCurvesListRequestedAction,
  createAddToCurrencyCurvesListAction,
};

function createCurvesListRequestedAction(
  swapCurrency: string,
  rfqId: string,
  strategyType: 'CLS' | 'ELS',
): CurvesAction {
  return {
    type: 'LOAD_CURVES_REQUESTED',
    strategyData: { currency: swapCurrency, rfqId },
    strategyType,
  };
}

function createAddToCurrencyCurvesListAction(currency: string, curves: Array<Curve>): CurvesAction {
  return {
    type: 'CURRENCY_CURVES_LIST_RECEIVED',
    currency,
    curves,
  };
}
