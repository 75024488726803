import { selectors } from '@/bootstrap/selectors';
import { services } from '@/bootstrap/services';
import type { AppState } from '@/bootstrap/state';
import { partial } from 'lodash';
import { useSelector } from 'react-redux';
import type { StrategyData } from '../../../../neos/business/neosModel';
import {
  type StrategiesMapStateToProps,
  type StrategiesOwnProps,
  StrategiesStyled,
} from './Strategies';
import {
  type DisplayedColumnsForLegsByStrategyId,
  getColumnGridDefinitions,
} from './getDisplayedColumns';
import { getStrategiesModel } from './getStrategiesModel';

export const Strategies = (ownProps: StrategiesOwnProps) => {
  const stateProps = useSelector<AppState, StrategiesMapStateToProps>(state => {
    const { rfqId, isSimilarActivityMode, areSimilarActivitiesStrategies } = ownProps;
    const rfqStrategiesData = getRfqStrategiesData(state, rfqId);
    const allDisplayedColumnsForStrategies = getAllDisplayedColumnsForStrategies(
      rfqStrategiesData,
      state,
    );

    const mainRfqId = selectors.getSelectedTab(state.ui.tabManagement);
    const isWaitingForSecondarySelection = selectors.isRfqWaitingForSecondarySelection(
      state.ui,
      mainRfqId,
    );
    const legIds = selectors.getRfqLegIds(state, rfqId, selectors);
    const hasOtc = legIds.some(lId => selectors.isOtcLeg(state, lId, selectors));

    const areSecondaryFeatureTogglesEnabled =
      selectors.areEveryRfqProductsSecondaryFeatureTogglesEnabled(state, rfqId, selectors);
    const selectedTabId = state.ui.tabManagement?.selectedTabId;
    const { status: currentSecondaryRfqStatus } = selectors.getRfqData(state, selectedTabId);
    const currentSecondaryRfqStatusOrder = services.orderOf(currentSecondaryRfqStatus);
    const isValidStatusForImplicitUnwindMode =
      currentSecondaryRfqStatusOrder.isBeforeOrEqual('ORDER_ACCEPTED');

    const shouldDisplaySASecondaryType =
      !isWaitingForSecondarySelection &&
      isSimilarActivityMode &&
      isValidStatusForImplicitUnwindMode &&
      hasOtc &&
      areSecondaryFeatureTogglesEnabled;

    const shouldDisplayStrategyLink = selectors.getUiShouldDisplayStratLink(
      state.ui,
      selectedTabId,
    );

    const isRfqWaitingForSecondaryIntegration = state.ui.rfq[ownProps.rfqId].isLoading;

    const columnGridDefinition = getColumnGridDefinitions(
      rfqId,
      state,
      selectors,
      allDisplayedColumnsForStrategies,
      {
        isRfqWaitingForSecondaryIntegration,
        shouldDisplayStrategyLink,
        shouldDisplaySASecondaryType,
        areSimilarActivitiesStrategies,
      },
    );

    return {
      model: getStrategiesModel(state, rfqId, selectors),
      columnGridDefinition,
      selectedTabId,
    };
  });

  return <StrategiesStyled {...ownProps} {...stateProps} />;
};

function getRfqStrategiesData(state: AppState, rfqId: string) {
  const { strategyIds, deltaHedgingStrategyIds } = selectors.getRfqData(state, rfqId);
  const allStrategyIds = [...strategyIds, ...deltaHedgingStrategyIds];
  return allStrategyIds.map(strategyId => selectors.getStrategyData(state, strategyId));
}

function getAllDisplayedColumnsForStrategies(
  strategyDatas: StrategyData[],
  state: AppState,
): DisplayedColumnsForLegsByStrategyId {
  return strategyDatas.reduce(partial(addEntryToStrategyDisplayedColumnsDictionary, state), {});
}

function addEntryToStrategyDisplayedColumnsDictionary(
  state: AppState,
  displayedColumnsForLegsByStrategyId: DisplayedColumnsForLegsByStrategyId,
  { strategyType, uuid }: StrategyData,
): DisplayedColumnsForLegsByStrategyId {
  const strategyDefinition = selectors.getStrategyDefinition(state.referenceData, strategyType);
  if (!strategyDefinition) {
    return displayedColumnsForLegsByStrategyId;
  }
  return {
    ...displayedColumnsForLegsByStrategyId,
    [uuid]: strategyDefinition.legs.map(leg => leg.displayedColumnsForLeg),
  };
}
