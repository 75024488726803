import type { UserPrefPreconf } from './preconfirmationPreferencesActions';

export interface PreconfState {
  preconfSavedGroups: string[];
  loadingGroups: string[];
}

export const defaultSelectedMailGroups: PreconfState = {
  preconfSavedGroups: [],
  loadingGroups: [],
};

export const prefonfirmationPreferencesReducer = (
  state: PreconfState = defaultSelectedMailGroups,
  action: UserPrefPreconf,
): PreconfState => {
  switch (action.type) {
    case 'USER_PREF_GROUPS_RECEIVED':
      return {
        preconfSavedGroups: action.groupKeys,
        loadingGroups: [],
      };

    case 'ADD_USER_PREF_GROUP':
      return {
        ...state,
        preconfSavedGroups: [...state.preconfSavedGroups, action.groupKey],
      };

    case 'DELETE_USER_PREF_GROUP': {
      const preconfSavedGroups = [...state.preconfSavedGroups].filter(g => g !== action.groupKey);
      return {
        ...state,
        preconfSavedGroups,
      };
    }

    case 'ADD_PRECONF_PREF_GROUP_TO_LOADING_GROUP': {
      return {
        ...state,
        loadingGroups: [...state.loadingGroups, action.groupKey],
      };
    }

    case 'REMOVE_PRECONF_PREF_GROUP_FROM_LOADING_GROUP': {
      return {
        ...state,
        loadingGroups: state.loadingGroups.filter(gk => gk !== action.groupKey),
      };
    }

    default:
      return state;
  }
};
