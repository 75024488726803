import { selectors } from '@/bootstrap/selectors';
import { neosThunks } from '@/neos/business/thunks';
import { useAppSelector } from '@/bootstrap/hooks';
import { useDispatch } from 'react-redux';
import {
  SwapCurrencyCells as Component,
  type SwapCurrencyCellsOwnProps,
} from './SwapCurrencyCells';
import { getSwapCurrencyCellsModel } from './getSwapCurrencyCellsModel';

export function SwapCurrencyCells(props: SwapCurrencyCellsOwnProps) {
  const dispatch = useDispatch();
  const model = useAppSelector(state =>
    getSwapCurrencyCellsModel(state, props.rfqId, props.strategyId, selectors),
  );

  return (
    <Component
      onSwapCurrencyChanged={(productId: string, swapCurrency: string) => {
        dispatch(
          neosThunks.createSwapCurrencyChangeThunk(
            props.rfqId,
            props.strategyId,
            productId,
            swapCurrency,
          ),
        );
      }}
      {...props}
      {...model}
    />
  );
}
