import type { FC } from 'react';
import { FormSelect } from 'react-bootstrap';
import { ErrorHighlight } from '../../../../../share/errorHighlight';
import type { SwapCurrencyCellsModel } from './getSwapCurrencyCellsModel';

export interface SwapCurrencyCellsOwnProps {
  rfqId: string;
  strategyId: string;
}

export type SwapCurrencyCellsMapStateProps = SwapCurrencyCellsModel;

export interface SwapCurrencyCellsDispatchProps {
  onSwapCurrencyChanged(productId: string, swapCurrency: string | undefined): void;
}

type SwapCurrencyCellsProps = SwapCurrencyCellsOwnProps &
  SwapCurrencyCellsMapStateProps &
  SwapCurrencyCellsDispatchProps;

export const SwapCurrencyCells: FC<SwapCurrencyCellsProps> = ({
  rfqId,
  strategyId,
  swapCurrencies,
  availableCurrencies,
  onSwapCurrencyChanged,
}) => {
  return (
    <section>
      {swapCurrencies.map(({ id, value, isEditable }) => (
        <ErrorHighlight
          key={id}
          errorField={'swapCurrency'}
          related={{ rfqId, strategyId, legId: id }}
        >
          <FormSelect
            value={value || ''}
            readOnly={!isEditable}
            onChange={event => onSwapCurrencyChanged(id, event.target.value)}
            data-e2e="neos-strategy-definition-swap-currency"
          >
            {(!isEditable || value === undefined) && <option value=""></option>}
            {availableCurrencies.map(currency => (
              <option key={currency} value={currency}>
                {currency}
              </option>
            ))}
          </FormSelect>
        </ErrorHighlight>
      ))}
    </section>
  );
};
