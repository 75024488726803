import { crudDomain } from '@/util/crudUtils';
import type { ReferenceUi, ReferenceUiKey } from './referenceUiModel';

const compositeIdSeparator = ' | ';

function getReferenceId({ rfqId, underlyingId }: ReferenceUiKey): string {
  return `${rfqId}${compositeIdSeparator}${underlyingId}`;
}

function getReferenceKey(id: string): ReferenceUiKey {
  const [rfqId, underlyingId] = id.split(compositeIdSeparator);
  return { rfqId, underlyingId };
}

export const referenceUiDomain = crudDomain<ReferenceUi, ReferenceUiKey>('reference-ui', {
  keyToString: getReferenceId,
  stringToKey: getReferenceKey,
});
