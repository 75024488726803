import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { logger } from '@/util/logging/logger';
import { mt } from '@/util/logging/messageTemplates';
import { NeosError } from '@/util/logging/neosError';
import { Component, type ErrorInfo } from 'react';
import { connect } from 'react-redux';
import { helpRequestActionCreators } from '../../business/helpRequest/helpRequestActionCreators';

export interface ErrorBoundaryOwnProps {
  location?: string;
  children?: any;
}

interface ErrorBoundaryDispatchProps {
  showHelpRequestModal: (location?: string, error?: string) => void;
}

interface ErrorBoundaryStateProps {
  hasError: boolean;
  error?: string;
}

class ErrorBoundaryComponent extends Component<
  ErrorBoundaryOwnProps & ErrorBoundaryDispatchProps,
  ErrorBoundaryStateProps
> {
  constructor(props: ErrorBoundaryOwnProps & ErrorBoundaryDispatchProps) {
    super(props);
    this.state = { hasError: false };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (error instanceof NeosError) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      logger.error(error.messageTemplate, ...error.properties);
    } else {
      logger.error(mt.errorBoundary, error.message, {
        error,
        componentStack: errorInfo.componentStack,
      });
    }
    this.setState({ hasError: true, error: (error.stack || '') + '\n' + errorInfo.componentStack });
  }

  public render() {
    return this.state.hasError ? (
      <div className="error-boundary-alert">
        <div
          role="alert"
          className=" alert-danger d-flex justify-content-between align-items-center p-4"
        >
          <span>An unexpected error has occured.</span>
          <button
            className="btn btn-primary"
            onClick={() => this.props.showHelpRequestModal(this.props.location, this.state.error)}
          >
            Send help request
          </button>
        </div>
      </div>
    ) : (
      this.props.children
    );
  }
}

const mapDispatchToProps = (dispatch: ExtendedDispatch): ErrorBoundaryDispatchProps => ({
  showHelpRequestModal: (location?: string, error?: string) => {
    dispatch(
      helpRequestActionCreators.createHelpRequestModalDisplayedAction(true, location, error),
    );
  },
});

export const ErrorBoundary = connect<{}, ErrorBoundaryDispatchProps, ErrorBoundaryOwnProps>(
  undefined,
  mapDispatchToProps,
)(ErrorBoundaryComponent);
