import type { FC } from 'react';
import { ComboBox } from '../../../../../share/comboBox';
import { ErrorHighlight } from '../../../../../share/errorHighlight';
import { NumericInput } from '../../../../../share/numericInput';
import type { StrikeCellsModel } from './getStrikeCellsModel';

export interface StrikeCellsOwnProps {
  rfqId: string;
  strategyId: string;
}

export interface StrikeCellsMapStateProps {
  model: StrikeCellsModel;
}

export interface StrikeCellsDispatchProps {
  onStrikeChanged(productId: string, strike: number | undefined): void;
}

type StrikeCellsProps = StrikeCellsOwnProps & StrikeCellsMapStateProps & StrikeCellsDispatchProps;

export const StrikeCells: FC<StrikeCellsProps> = ({
  rfqId,
  strategyId,
  model,
  onStrikeChanged,
}) => {
  return (
    <section>
      {model.strikes.map(({ id, value, isEditable }, index) => (
        <ErrorHighlight key={id} errorField={'strike'} related={{ rfqId, strategyId, legId: id }}>
          {model.showAvailableStrikes ? (
            <ComboBox
              disabled={!isEditable}
              className="errorable-bloc"
              onChange={val => onStrikeChanged(id, val)}
              value={value?.value}
              recommendedValue={value?.recommendedValue}
              unit={value?.unit}
              options={model.availableStrikes[index] ? model.availableStrikes[index].strikes : []}
              data-e2e="neos-strategy-definition-strike"
            />
          ) : (
            <NumericInput
              readOnly={!isEditable}
              className="errorable-bloc"
              value={value ? value.value : value}
              unit={value ? value.unit : value}
              onBlur={val => onStrikeChanged(id, val)}
              data-e2e="neos-strategy-definition-strike"
            />
          )}
        </ErrorHighlight>
      ))}
    </section>
  );
};
