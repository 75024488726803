import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import { getTwoDaysAgo } from '@/util/date/dateFormatHelper';
import { connect } from 'react-redux';
import {
  type BlotterPanelInformationDispatchProps,
  type BlotterPanelInformationPropsFromState,
  BlotterPanelInformationWithCss,
} from './BlotterPanelInformation';
import { getBlotterPanelInformationModel } from './getBlotterPanelInformationModel';

function mapStateToProps(appState: AppState): BlotterPanelInformationPropsFromState {
  return {
    model: getBlotterPanelInformationModel(appState, selectors),
  };
}

function mapDispatchToProps(dispatch: ExtendedDispatch): BlotterPanelInformationDispatchProps {
  return {
    requestBlotterRfqs: () =>
      dispatch(neosThunks.createRequestBlotterThunk({ from: getTwoDaysAgo() })),
  };
}

export const BlotterPanelInformation = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BlotterPanelInformationWithCss);
