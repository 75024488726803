import type { DefaultProductValuesForStrategy } from '../../../../../../../../../neos/business/neosModel';
import {
  type AsianOption,
  type Product,
  isOptionLike,
  isProductWithAsianFields,
  isProductWithStrike,
} from '../../../../legModel';
import { getCommonPart, getDerivativePart, getNegotiationPart } from './getDefaultedPartialProduct';

export function getDefaultedAsianOptionProduct(
  currentProduct: Product,
  defaultValues: DefaultProductValuesForStrategy,
): AsianOption {
  const {
    underlying,
    negotiation,
    optionStyle,
    optionType,
    strike: strategyStrike,
    maturity,
    period: strategyPeriod,
    strikeType: strategyStrikeType,
    averageDisruptionDate: strategyAverageDisruptionDate,
    businessDayConvention: strategyBusinessDayConvention,
  } = defaultValues;

  const { type, style } = isOptionLike(currentProduct)
    ? currentProduct
    : { type: undefined, style: undefined };
  const { strike, strikeUnit } = isProductWithStrike(currentProduct)
    ? currentProduct
    : { strike: undefined, strikeUnit: undefined };
  const { period, strikeType, averageDisruptionDate, businessDayConvention } =
    isProductWithAsianFields(currentProduct)
      ? currentProduct
      : {
          period: undefined,
          strikeType: undefined,
          averageDisruptionDate: undefined,
          businessDayConvention: undefined,
        };

  return {
    subFamily: 'ASIAN_OPTION',
    type: optionType ?? type,
    style: optionStyle ?? style,
    strike: strategyStrike ?? strike,
    strikeUnit,
    ...getCommonPart(currentProduct, defaultValues),
    ...getNegotiationPart(currentProduct, negotiation),
    ...getDerivativePart(currentProduct, maturity, underlying),
    hasCustomUnderlying: false,
    period: strategyPeriod ?? period,
    strikeType: strategyStrikeType ?? strikeType,
    averageDisruptionDate: strategyAverageDisruptionDate ?? averageDisruptionDate,
    businessDayConvention: strategyBusinessDayConvention ?? businessDayConvention,
  };
}
