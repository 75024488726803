import type { Thunk } from '@/bootstrap/thunks';
import type { OnyxHedge } from '@/neos/business/rfq/hedge/hedgeOnyxModel';

export function createHedgesReceivedThunk(
  rfqId: string,
  onyxHedges: OnyxHedge[] | undefined,
): Thunk {
  return function hedgesReceivedThunk(
    dispatch,
    _,
    {
      actionCreators: {
        neos: { hedgeCrudActions, createUpdateHedgesAction },
      },
      fromMappers,
    },
  ) {
    const deleteAction = hedgeCrudActions.deleteByPartialKey({ rfqId });

    const insertionActions = onyxHedges
      ? [createUpdateHedgesAction(rfqId, onyxHedges, fromMappers)]
      : [];

    dispatch(deleteAction, ...insertionActions);
  };
}
