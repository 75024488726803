import { DefaultingOptions } from '../rfqActions';

import type { ChainOptions, RetryOnErrorOptions } from '@/neos/business/neosActions';
import { DefaultingScope } from '../models';
import { createDefaultRfqRequestedAction } from './defaultRfqRequestedAction';

export function createDefaultUnderlyingRequestedAction(
  rfqId: string,
  strategyIds: string[],
  defaultDeltas: boolean,
  featureToggles: {
    isElsProductWithInterestRateScopeEnabled: boolean;
    isDispersionEnabled: boolean;
  },
  additionalStratInfos: {
    isElsProduct: boolean;
    isCls: boolean;
    isTrader: boolean;
    isElsIdb: boolean;
  },
  chainOptions?: ChainOptions,
) {
  const overrideScopes = [
    DefaultingScope.TRADER_DIFFUSION,
    DefaultingScope.DELIVERY_TYPE,
    DefaultingScope.DETERMINATION_METHOD,
    DefaultingScope.UNDERLYING,
    DefaultingScope.OPTION_STYLE,
    DefaultingScope.CAP,
    DefaultingScope.NEGOTIATED_SIZE,
    DefaultingScope.NOTIONAL,
    DefaultingScope.PIVOT_CURRENCY,
    DefaultingScope.UNITS,
    DefaultingScope.EXPECTED_N,
    DefaultingScope.SWAP_CURRENCY,
    DefaultingScope.REFERENCE_MATURITY,
    DefaultingScope.LOT_SIZE,
    DefaultingScope.MARKET,
    DefaultingScope.TRADED_AWAY_FEEDBACK,
    DefaultingScope.BOOKING_INFO,
    DefaultingScope.OBSERVABLE_TYPE,
    DefaultingScope.NEGOTIATION,
  ];

  if (defaultDeltas) {
    overrideScopes.push(DefaultingScope.DELTAS);
  }

  if (additionalStratInfos.isCls) {
    overrideScopes.push(DefaultingScope.SCHEDULE);
  }

  if (featureToggles.isDispersionEnabled) {
    overrideScopes.push(DefaultingScope.DISPERSION_LEGS);
  }

  if (additionalStratInfos.isElsProduct) {
    overrideScopes.push(DefaultingScope.STRIKE_DATE);
    overrideScopes.push(DefaultingScope.MATURITY_DATE);
    overrideScopes.push(DefaultingScope.RESET_FREQUENCY);
    overrideScopes.push(DefaultingScope.RATE_RESET);
    overrideScopes.push(DefaultingScope.ROLE_DEFINITION);
    overrideScopes.push(DefaultingScope.BREAK_FEE_ELECTION);
    overrideScopes.push(DefaultingScope.COMPONENT_SECURITY_INDEX_ANNEX);
    overrideScopes.push(DefaultingScope.DIVIDEND_PAYMENT_DATE);
    overrideScopes.push(DefaultingScope.SETTLEMENT_METHOD_ELECTION);
    overrideScopes.push(DefaultingScope.EFFECTIVE_DATE);
  }

  if (featureToggles.isElsProductWithInterestRateScopeEnabled) {
    overrideScopes.push(DefaultingScope.INTEREST_RATE_INDEX);
    overrideScopes.push(DefaultingScope.CALCULATION_METHOD);
  }

  if (additionalStratInfos.isElsIdb) {
    overrideScopes.push(DefaultingScope.BROKER_COMMISSION_CURRENCY);
  }

  const enrichScopes = [
    DefaultingScope.FOREX,
    DefaultingScope.REFERENCE,
    DefaultingScope.SPOT_NET,
    DefaultingScope.SALES_DIFFUSION,
    DefaultingScope.TRADE_DATE,
    DefaultingScope.HEDGES,
  ];
  const options = new DefaultingOptions({
    overrideScopes,
    enrichScopes,
    isSettlementOtcOverride: true,
  });

  options.strategiesFilterer = strategies => strategies.filter(s => strategyIds.includes(s.uuid));

  const retryOptions: RetryOnErrorOptions = {
    retries: 1,
  };

  return createDefaultRfqRequestedAction(rfqId, options, chainOptions, retryOptions);
}
