import { type ActionCreators, actionCreators } from '@/bootstrap/actions';
import type { ThunkEpic } from '@/bootstrap/epics';
import { type Thunks, thunks } from '@/bootstrap/thunks';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { ofType } from 'redux-observable';
import type { Observable } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import type { OnyxError } from '../../mappers/error';
import { createPredealChecksDerogationApi } from '../apis';
import type { Derogation } from '../predealCheckModel';

export interface PredealChecksDerogationApi {
  derogatePredealChecks: (rfqId: string, derogation: Derogation) => Observable<void>;
}

export function getDerogatePredealChecksEpic(http: SgmeHttp): ThunkEpic {
  const api = createPredealChecksDerogationApi(http);
  return createDerogatePredealChecksEpic(api, actionCreators, thunks);
}

export function createDerogatePredealChecksEpic(
  api: PredealChecksDerogationApi,
  {
    neos: { createPredealCheckDerogationDoneAction },
    common: { createAppCrashedAction },
  }: ActionCreators,
  { createErrorToasterThunk }: Thunks,
): ThunkEpic {
  return action$ =>
    action$.pipe(
      ofType('NEOS_PREDEAL_CHECK_DEROGATION_REQUESTED'),
      mergeMap(({ rfqId, derogation }) => {
        return api.derogatePredealChecks(rfqId, derogation).pipe(
          mergeMap(() => [createPredealCheckDerogationDoneAction([derogation])]),
          catchError((error: OnyxError) => [
            createErrorToasterThunk(
              {
                message: `An error occured when derogating pdc ${error?.response?.content ?? ''}`,
              },
              error,
            ),
          ]),
        );
      }),
      catchError(error => [createAppCrashedAction('neos-derogate-predeal-checks', error)]),
    );
}
