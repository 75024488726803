import type { ColumnState } from '@ag-grid-community/core';
import type { GridState } from './gridStateApiManager';

export const columnStateStorageKey = 'blotter-column-state';
export const expandedRowGroupsStorageKey = 'blotter-expanded-row-groups';
export const filterModelStorageKey = 'blotter-filter-model';

interface StorageManager {
  getItem: (key: string) => string | null;
  setItem: (key: string, value: string) => void;
  removeItem: (key: string) => void;
}

export class GridStateStorageManager {
  constructor(private storageManager: StorageManager) {}

  public saveGridState({ columnState }: GridState) {
    this.storageManager.setItem(columnStateStorageKey, JSON.stringify(columnState));
  }

  public getGridState = (): GridState => {
    return {
      columnState: this.parse<ColumnState[]>(columnStateStorageKey),
    };
  };

  private parse = <T>(key: string) => {
    const item = this.storageManager.getItem(key);
    return item === null ? undefined : (JSON.parse(item) as T);
  };
}
