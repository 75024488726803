import type { AppState } from '@/bootstrap/state.ts';
import type { Selectors } from '@/bootstrap/selectors.ts';
import {
  isElsBasketProduct,
  isElsProduct,
} from '@/neos/business/rfq/strategy/leg/product/productModel.ts';

export function getIsElsSpotConfirmed(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): boolean | null {
  const product = selectors.getRfqProducts(state, rfqId, selectors).at(0);

  if (product === undefined || !isElsProduct(product)) {
    return null;
  }

  if (isElsBasketProduct(product)) {
    return selectors
      .selectElsBasketReferences(state, rfqId, product)
      .every(reference => reference.isSpotConfirmed);
  }

  if (!product.underlyingId) {
    return null;
  }

  const isSpotConfirmed = selectors.getReference(state, {
    rfqId,
    underlyingId: product.underlyingId,
  })?.isSpotConfirmed;

  return !!isSpotConfirmed;
}
