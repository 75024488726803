import type { ExecutionKey } from '@/neos/business/order/orderModel';
import type { OnyxCounterparty } from '@/neos/business/rfq/rfqOnyxModel';

export interface ListedAllocationKey extends Omit<ExecutionKey, 'orderId'> {
  allocationId: string;
}

export type CommissionType = 'NOTIONAL' | 'PREMIUM' | 'FIXED_PER_LOTS' | 'FIXED';

export const CommissionTypeValues: Record<CommissionType, string> = {
  NOTIONAL: 'Notional (bps)',
  PREMIUM: 'Premium (bps)',
  FIXED_PER_LOTS: 'Fixed per lots',
  FIXED: 'Fixed',
};

export interface ListedAllocation {
  uuid: string;
  isCancelled: boolean;
  legId: string;
  executionId: string;
  clearerAccount: string | undefined;
  quantity: number | undefined;
  numberOfLots: number | undefined;
  commission: number | undefined;
  commissionType: CommissionType | undefined;
  broker: OnyxCounterparty | undefined;
  externalReferenceId?: {
    application: 'XONE';
    id: string | undefined;
  };
}
