import type { Thunk } from '@/bootstrap/thunks';
import type { BloombergCodeByProductId } from '@/neos/business/referenceData/underlying/underlyingActionCreators';
import { flatMap, remove } from 'lodash';
import { getQuoteToPatchByClientWay } from '@/neos/components/rfq/strategies/strategy/compositionLegsModal/quoteByClientWayHelpers.ts';

export interface ImportedCompositionLegsData {
  underlying?: string;
  type?: string;
  weight?: number;
  expectedN?: number;
  volStrike?: number;
}

export function createRfqApplyImportedCompositionLegsThunk(
  rfqId: string,
  strategyId: string,
  importedData: ImportedCompositionLegsData[],
): Thunk {
  return function rfqApplyImportedCompositionLegs(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: {
          createUnderlyingIdsRequestedAction,
          createDefaultCompositionLegsRequestedAction,
          legDataCrudActions,
          productCrudActions,
          quoteCrudActions,
          strategyUiCrudActions,
        },
      },
      thunks: {
        neos: {
          createRequestMissingUnderlyingsThunk,
          createResetTopAndDispatchThunk,
          createRfqChangeStrategyTopThunk,
        },
      },
    },
  ) {
    const indexUdl = importedData.filter(id => id.type?.toLocaleLowerCase() === 'i');
    if (indexUdl.length > 1) {
      dispatch(
        strategyUiCrudActions.update(strategyId, {
          displayCompoImportHasMultipleIndicesWarning: true,
        }),
      );
      indexUdl.length = 0;
      remove(importedData, id => id.type?.toLocaleLowerCase() === 'i');
    }

    const compoUdls = importedData.filter(id => id.type?.toLocaleLowerCase() !== 'i');
    const { getStrategyMasterLeg, getStrategyData, getLegData, getRfqData } = selectors;
    const state = getState();
    const { clientWay } = getRfqData(state, rfqId);
    const { uuid: masterLegUuid } = getStrategyMasterLeg(state, strategyId, selectors);
    const sortedLegs = [...compoUdls, ...(indexUdl.length ? [indexUdl[0]] : [])];

    const compoCount = indexUdl.length ? importedData.length - 1 : importedData.length;

    dispatch(
      createResetTopAndDispatchThunk(masterLegUuid),
      createRfqChangeStrategyTopThunk(rfqId, strategyId, compoCount),
    );

    const updatedState = getState();
    const { legIds } = getStrategyData(updatedState, strategyId);
    const compositionLegs = legIds.slice(0, sortedLegs.length).map(legId => {
      return getLegData(updatedState, legId);
    });

    const bloombergCodesByProductId: BloombergCodeByProductId[] = compositionLegs
      .map(({ productId }, index) => ({
        productId,
        bloombergCode: sortedLegs[index].underlying,
      }))
      .filter(
        (bloombergCodeByProductId): bloombergCodeByProductId is BloombergCodeByProductId =>
          bloombergCodeByProductId.bloombergCode !== undefined,
      );

    if (bloombergCodesByProductId.length) {
      dispatch(
        createUnderlyingIdsRequestedAction([rfqId], bloombergCodesByProductId, {
          success: {
            dispatchables: [
              createRequestMissingUnderlyingsThunk([rfqId], {
                success: {
                  dispatchables: [createDefaultCompositionLegsRequestedAction(rfqId, strategyId)],
                },
              }),
            ],
          },
        }),
      );
    }

    const productAndlegActions = flatMap(compositionLegs, ({ uuid, quoteId, productId }, index) => {
      const leg = sortedLegs[index];
      const importedWeight = leg.weight;
      const weight = importedWeight ? importedWeight / 100 : importedWeight;
      return [
        legDataCrudActions.update(uuid, {
          weight,
          initialWeight: weight,
        }),
        productCrudActions.update(productId, {
          expectedN: leg.expectedN,
        }),
        quoteCrudActions.update(quoteId, {
          ...getQuoteToPatchByClientWay(clientWay, leg.volStrike),
          unit: 'VOL',
        }),
      ];
    });

    const strategyUiActions = [
      strategyUiCrudActions.update(strategyId, {
        displayMissingCompositionDataWarning: importedDataHasMissingInfo(importedData),
        compoLastImportTime: new Date().getTime(),
        compoLastImportRowCount: sortedLegs.length,
      }),
    ];

    dispatch(...productAndlegActions, ...strategyUiActions);
  };
}

function importedDataHasMissingInfo(data: ImportedCompositionLegsData[]): boolean {
  return data.some(({ underlying, weight }) => !underlying || !weight);
}
