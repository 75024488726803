import type { Thunk } from '@/bootstrap/thunks';

export function createNeosToggleStrategyShowLegPricesThunk(strategyId: string): Thunk {
  return function neosToggleStrategyShowLegPricesThunk(
    dispatch,
    getState,
    {
      selectors: { isShowLegPrices },
      actionCreators: {
        neos: { strategyUiCrudActions },
      },
    },
  ) {
    const state = getState();
    const newShowLegPrices = !isShowLegPrices(state.ui, strategyId);
    dispatch(strategyUiCrudActions.update(strategyId, { showLegPrices: newShowLegPrices }));
  };
}
