import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { PredealChecksDerogationApi } from '../epics/derogatePredealChecksEpic';
import type { Derogation } from '../predealCheckModel';

export const createPredealChecksDerogationApi = (http: SgmeHttp): PredealChecksDerogationApi => ({
  derogatePredealChecks(rfqId: string, derogation: Derogation) {
    return http.post<void>({
      url: `api/pdc/${rfqId}/derogate`,
      body: derogation,
    });
  },
});
