import type {
  OnyxBarrierActivationType,
  OnyxBarrierType,
} from '../../../../../neos/business/neosOnyxModel';
import type { NeosBarrierType } from './featureModel';

interface BarrierTypeMapping {
  type: OnyxBarrierType | undefined;
  activationType: OnyxBarrierActivationType | undefined;
  label: NeosBarrierType;
}

const barrierTypeValues: BarrierTypeMapping[] = [
  {
    type: 'KNOCK_IN',
    activationType: 'DOWN',
    label: 'KI Down',
  },
  {
    type: 'KNOCK_IN',
    activationType: 'UP',
    label: 'KI Up',
  },
  {
    type: 'KNOCK_OUT',
    activationType: 'DOWN',
    label: 'KO Down',
  },
  {
    type: 'KNOCK_OUT',
    activationType: 'UP',
    label: 'KO Up',
  },
];

export function getNeosBarrierTypeByProperties(
  type: OnyxBarrierType | undefined,
  activationType: OnyxBarrierActivationType | undefined,
): NeosBarrierType | undefined {
  if (!type && !activationType) {
    return undefined;
  }

  const result = barrierTypeValues.find(
    elem => elem.type === type && elem.activationType === activationType,
  );
  if (!result) {
    throw new Error(
      `Cannot find label associated with barrierType: ${type} & activationType: ${activationType}`,
    );
  }
  return result.label;
}

export function getBarrierPropertiesByNeosBarrierType(barrierLabel: NeosBarrierType | undefined) {
  if (!barrierLabel) {
    return {
      type: undefined,
      activationType: undefined,
    };
  }

  const result = barrierTypeValues.find(elem => elem.label === barrierLabel);
  if (!result) {
    throw new Error(`Cannot find barrier properties for label: ${barrierLabel}`);
  }
  return {
    type: result.type,
    activationType: result.activationType,
  };
}
