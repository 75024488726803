import { NeosBlurTextarea } from '@/neos/components/share/blurComponent/NeosBlurTextarea';

import styles from '../Comment.module.scss';

export interface RfqCommentOwnPropsProps {
  rfqId: string;
}

export interface RfqCommentStateProps {
  salesComment: string | undefined;
  isReadonlyRfq: boolean;
}

export interface RfqCommentDispatchProps {
  onCommentChanged(comment: string): void;
}

export type RfqCommentProps = RfqCommentOwnPropsProps &
  RfqCommentStateProps &
  RfqCommentDispatchProps;

export const RfqCommentComponent = ({
  salesComment,
  onCommentChanged,
  isReadonlyRfq,
}: RfqCommentProps) => (
  <div className={`${styles['comment']}`}>
    <label className="fw-bold m-1">RFQ Comment</label>
    <NeosBlurTextarea
      className={`${styles['rfq-comment-textarea']} form-control`}
      data-e2e="neos-rfq-comment"
      value={salesComment || ''}
      rows={4}
      onChange={event => onCommentChanged(event.target.value)}
      disabled={isReadonlyRfq}
    />
  </div>
);
