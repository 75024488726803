import type { Thunk } from '@/bootstrap/thunks';
import type { ListedAllocation } from '@/neos/business/rfq/strategy/leg/listedAllocation/listedAllocationModel';
import { isDefined } from '@/util/undefinedAndNull/isDefined';
import { sum } from 'lodash';
import type { ExecutionKey } from '../executionModel';

export function createAddAllocationThunk(executionKey: ExecutionKey): Thunk {
  return function addAllocationThunk(dispatch, getState, { selectors, actionCreators, services }) {
    const state = getState();

    const execution = selectors.executionSelectors.find(state.execution, executionKey);
    const maxSize = execution?.size?.numberOfLots;
    const allocations = selectors.listedAllocationSelectors.selectObjects(
      state.listedAllocationState,
      {
        executionId: executionKey.executionId,
        legId: executionKey.legId,
        rfqId: executionKey.rfqId,
      },
    );

    dispatch(
      actionCreators.analytics.createLogAnalyticsAction(
        'NEOS RFQ',
        `Add allocation to execution`,
        executionKey.executionId,
      ),
    );
    const allocationId = services.idCreator.createId();
    dispatch(
      actionCreators.neos.listedAllocationCrudActions.insert(
        {
          rfqId: executionKey.rfqId,
          legId: executionKey.legId,
          executionId: executionKey.executionId,
          allocationId,
        },
        {
          uuid: allocationId,
          executionId: executionKey.executionId,
          legId: executionKey.legId,
          quantity: undefined,
          numberOfLots: calculateNextAllocSize(maxSize, allocations),
          clearerAccount: undefined,
          commission: undefined,
          commissionType: 'FIXED_PER_LOTS',
          broker: undefined,
          externalReferenceId: undefined,
          isCancelled: false,
        },
      ),
    );
  };
}

export function calculateNextAllocSize(
  maxSize: number | undefined,
  allocations: Array<ListedAllocation>,
): number | undefined {
  const currentSum = sum(allocations.map(alloc => alloc?.numberOfLots ?? 0));
  return isDefined(maxSize) && maxSize > currentSum ? maxSize - currentSum : undefined;
}
