import { useEffect } from 'react';
import { Strategies } from '../../strategies/Strategies.container';

export interface RfqDetailsOwnProps {
  rfqId: string;
  parentRfqId: string;
}

export interface RfqDetailsStateProps {
  rfqExists: boolean;
  isSimilarActivityMode: boolean;
}

export interface RfqDetailsDispatchProps {
  onOpen: (shouldLoad: boolean) => void;
  onClose: () => void;
}

export type RfqDetailsProps = RfqDetailsOwnProps & RfqDetailsStateProps & RfqDetailsDispatchProps;

export const RfqDetailsComponent = ({
  rfqId,
  rfqExists,
  isSimilarActivityMode,
  onClose,
  onOpen,
}: RfqDetailsProps) => {
  useEffect(() => {
    onOpen(!rfqExists);

    return onClose;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {rfqExists ? (
        <Strategies
          rfqId={rfqId}
          isSimilarActivityMode={isSimilarActivityMode}
          areSimilarActivitiesStrategies
        />
      ) : (
        <div className="p-4 spinner-grow w-100 bg-lvl1" role="status">
          Loading...
        </div>
      )}
    </div>
  );
};
