import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch, NeosThunks } from '@/bootstrap/thunks';
import { type NeosActionCreators, neosActionCreators } from '@/neos/business/neosActionCreators';
import { neosThunks } from '@/neos/business/thunks';

import { selectors } from '@/bootstrap/selectors';
import { useDispatch, useSelector } from 'react-redux';
import {
  LegSecondaryTypeWidgetComponent,
  type LegSecondaryTypeWidgetDispatchProps,
  type LegSecondaryTypeWidgetOwnProps,
  type LegSecondaryTypeWidgetStateProps,
} from './LegSecondaryTypeWidget';
import { getLegSecondaryTypeWidgetModel } from './getLegSecondaryTypeWidgetModel';

export const LegSecondaryTypeWidget = (ownProps: LegSecondaryTypeWidgetOwnProps) => {
  const stateProps = useSelector<AppState, LegSecondaryTypeWidgetStateProps>(state =>
    getLegSecondaryTypeWidgetModel(state, ownProps, selectors),
  );
  const dispatch = useDispatch<ExtendedDispatch>();
  const dispatchProps = getDispatchProps(dispatch, ownProps, neosActionCreators, neosThunks);
  return <LegSecondaryTypeWidgetComponent {...ownProps} {...stateProps} {...dispatchProps} />;
};

function getDispatchProps(
  dispatch: ExtendedDispatch,
  { rfqId, strategyId }: LegSecondaryTypeWidgetOwnProps,
  { legSecondaryEventCreationCrudActions }: NeosActionCreators,
  { createSecondaryTypesOnLegsThunk }: NeosThunks,
): LegSecondaryTypeWidgetDispatchProps {
  return {
    onLegSecondaryTypeChanged(legId, eventType) {
      dispatch(
        legSecondaryEventCreationCrudActions.upsert(
          { rfqId, legId },
          { eventType, primaryLegUuid: legId },
        ),
      );
    },
    onLegSecondaryTypeLongClicked(eventType) {
      dispatch(createSecondaryTypesOnLegsThunk({ rfqId, strategyId, eventType }));
    },
  };
}
