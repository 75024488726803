import { useAppSelector } from '@/bootstrap/hooks';
import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { neosThunks } from '@/neos/business/thunks';
import { Switch } from '@/neos/components/share/switch/Switch';
import { getNotificationGroupsModel } from '@/neos/components/userPreferences/notifications/getNotificationGroupsModel';
import { useDispatch } from 'react-redux';

export function NotificationsGroups() {
  const { isAlertSoundPlayed, notificationGroups } = useAppSelector(state =>
    getNotificationGroupsModel(state, selectors),
  );

  const dispatch = useDispatch<ExtendedDispatch>();
  const onNotificationGroupChanged = (notificationGroup: number, enabled: boolean) =>
    dispatch(
      createLogAnalyticsAction('NEOS USER PREFERENCES', 'Change notification group'),
      neosThunks.createNotificationGroupUpdatedThunk(notificationGroup, enabled),
    );

  const switchAlertSoundToggle = (toggle: boolean) => {
    dispatch(
      createLogAnalyticsAction(
        'NEOS USER PREFERENCES',
        `Switch alert played sound toggle to ${toggle}`,
      ),
      neosActionCreators.createAlertSoundSwitchedAction(toggle),
    );
  };

  return (
    <>
      <div className="mb-2">Select the groups on which you want to receive popup alerts</div>
      {notificationGroups.map(group => (
        <Switch
          key={group.id}
          checked={group.enabled}
          labelName={group.label}
          switchClassName="ms-2"
          onChange={() => onNotificationGroupChanged(group.id, !group.enabled)}
        />
      ))}
      <div>
        <label className="py-2">Activate Mdp sound</label>
        <Switch
          checked={isAlertSoundPlayed}
          labelName="Alert sound"
          switchClassName="ms-2"
          onChange={() => switchAlertSoundToggle(!isAlertSoundPlayed)}
        />
      </div>
    </>
  );
}
