import type { Thunk } from '@/bootstrap/thunks';
import { LegRecomputeChangedScope } from '../models';

interface RfqUpdateLegQuoteAccordingToAveragePriceThunkParamModel {
  rfqId: string;
  legId: string;
  averagePriceValue: number;
}

export function createRfqUpdateLegQuoteAccordingToAveragePriceThunk({
  rfqId,
  legId,
  averagePriceValue,
}: RfqUpdateLegQuoteAccordingToAveragePriceThunkParamModel): Thunk {
  return function rfqUpdateLegQuoteAccordingToAveragePriceThunk(
    dispatch,
    getState,
    {
      thunks: {
        neos: { createRfqUpdateLegQuoteThunk },
      },
      selectors,
    },
  ) {
    const state = getState();

    const { quoteId, clientWay } = selectors.getLegData(state, legId);
    const strategyId = selectors.getStrategyIdByLegId(state, legId);
    const { status } = selectors.getRfqData(state, rfqId);
    const isStatusOrderManuallyCrossed = status === 'ORDER_MANUALLY_CROSSED';

    if (
      clientWay !== 'TWO_WAYS' &&
      selectors.isStrategyFutureRoll(state, strategyId, selectors) &&
      isStatusOrderManuallyCrossed
    ) {
      dispatch(
        createRfqUpdateLegQuoteThunk({
          rfqId,
          strategyId,
          quoteId,
          legId,
          quotePatch:
            clientWay === 'BUY'
              ? { traderAsk: averagePriceValue }
              : { traderBid: averagePriceValue },
          scope: LegRecomputeChangedScope.TRADER_PRICE,
        }),
      );
    }
  };
}
