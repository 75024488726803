import { tabActionCreators } from './tab/tabActionCreators';
import { selectedStrategyActionCreators } from './selectedStrategy/selectedStrategyActionCreators';
import { errorActionCreators } from './error/errorActionCreators';
import { rfqUiActionCreators } from './rfq/rfqUiActionCreators';
import { strategyUiActionCreators } from './strategy/strategyUiActionCreators';
import { userPreferencesUiActionCreators } from './userPreferences/userPreferencesUiActionCreators';
import { blotterUiActionCreators } from './blotter/blotterUiActionCreators';
import { headerMenuActionCreators } from './headerMenu/headerMenuActionCreators';
import { underlyingUiActionCreators } from './underlying/underlyingUiActionCreators';
import { legUiActionCreators } from './leg/legUiActionCreators';
import { referencedRfqActionCreators } from './referencedRfq/referencedRfqActionCreators';
import { allocationUiActionCreators } from './allocation/allocationUiActionCreators';
import { topLevelErrorSlice } from '@/neos/business/ui/topLevelError/topLevelErrorSlice';

export const uiActionCreators = {
  ...blotterUiActionCreators,
  ...tabActionCreators,
  ...selectedStrategyActionCreators,
  ...errorActionCreators,
  ...rfqUiActionCreators,
  ...strategyUiActionCreators,
  ...legUiActionCreators,
  ...userPreferencesUiActionCreators,
  ...headerMenuActionCreators,
  ...underlyingUiActionCreators,
  ...referencedRfqActionCreators,
  ...allocationUiActionCreators,
  ...topLevelErrorSlice.actions,
};
