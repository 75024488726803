import type { ThunkEpic } from '@/bootstrap/epics';
import { type Thunks, thunks } from '@/bootstrap/thunks';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { retryWithDelay } from '@/util/rxFunctionsAndOperators/retryWithDelay';
import type { Epic } from 'redux-observable';
import { ofType } from 'redux-observable';
import type { Observable, SchedulerLike } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { createNotificationSubscriptionApi } from '../apis/notificationSubscriptionApi';
import type {
  NotificationSubscriptionRequest,
  NotificationSubscriptionResponse,
} from '../neosModel';

export interface NotificationSubscriptionApi {
  subscribeToNotification: (
    notificationSubscriptionRequests: NotificationSubscriptionRequest[],
  ) => Observable<NotificationSubscriptionResponse[]>;
}

export function getNotificationSubscriptionEpic(http: SgmeHttp): Epic {
  return notificationSubscriptionEpic(createNotificationSubscriptionApi(http), thunks, 5000);
}

export function notificationSubscriptionEpic(
  api: NotificationSubscriptionApi,
  { neos: { createIntegrateNotificationSubscriptionResultsThunk } }: Thunks,
  retrialDelay: number,
  scheduler?: SchedulerLike,
): ThunkEpic {
  return action$ =>
    action$.pipe(
      ofType('REQUEST_SUBSCRIPTION_ACTION'),
      mergeMap(({ notificationSubscriptionRequests }) =>
        api
          .subscribeToNotification(
            notificationSubscriptionRequests.filter(isDataGroupNamesNotEmpty),
          )
          .pipe(
            mergeMap(response =>
              response
                .filter(({ notificationTopic }) => notificationTopic === 'TRANSACTION')
                .map(({ subscriptionResults }) =>
                  createIntegrateNotificationSubscriptionResultsThunk(subscriptionResults),
                ),
            ),
            retryWithDelay(5, retrialDelay, scheduler),
            catchError(() => []),
          ),
      ),
    );
}

function isDataGroupNamesNotEmpty(
  notificationSubscriptionRequest: NotificationSubscriptionRequest,
): boolean {
  return notificationSubscriptionRequest.dataGroupNames.length > 0;
}
