import { actionCreators } from '@/bootstrap/actions';
import type { ThunkEpic } from '@/bootstrap/epics';
import { type Thunks, thunks } from '@/bootstrap/thunks';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { ofType } from 'redux-observable';
import type { Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import type { OnyxError } from '../mappers/error';
import type { NeosActionCreators } from '../neosActionCreators';
import { createAlertApi } from './alertApi';
import type { OnyxAlert } from './alertOnyxModel';

export interface AlertApi {
  retrieveAlerts: () => Observable<OnyxAlert[]>;
}

export function getRetreiveAlertEpic(http: SgmeHttp): ThunkEpic {
  const api = createAlertApi(http);
  return createRetreiveAlertEpic(api, actionCreators.neos, thunks);
}

export function createRetreiveAlertEpic(
  api: AlertApi,
  { createAlertLoadedBatchAction, createAlertCrudAction: alertCrudAction }: NeosActionCreators,
  { createErrorToasterThunk }: Thunks,
): ThunkEpic {
  return action$ =>
    action$.pipe(
      ofType('ALERTS_REQUESTED_ACTION'),
      mergeMap(() =>
        api.retrieveAlerts().pipe(
          map(onyxAlerts => createAlertLoadedBatchAction(alertCrudAction, onyxAlerts)),
          catchError((error: OnyxError) => [
            createErrorToasterThunk({ message: 'An error occured while loading alerts' }, error),
          ]),
        ),
      ),
    );
}
