import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { dateStringToIsoString, formatDateInTimeZone } from '@/util/date/dateFormatHelper';
import { connect } from 'react-redux';
import {
  type TradeDateMapStateToProps,
  type TradeDateOwnProps,
  TradeDateStyled,
} from './TradeDate';

const { getDisplayTimeZone } = selectors;
const { getRfqData } = selectors;

export const TradeDate = connect(mapStateToProps)(TradeDateStyled);

function mapStateToProps(state: AppState, { rfqId }: TradeDateOwnProps): TradeDateMapStateToProps {
  const { tradeDate } = getRfqData(state, rfqId);
  const displayTimezone = getDisplayTimeZone(state.ui.userPreferences);
  return {
    tradeDate: tradeDate
      ? formatDateInTimeZone(displayTimezone, dateStringToIsoString(tradeDate))
      : 'N/A',
  };
}
