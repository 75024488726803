import type { Thunk } from '@/bootstrap/thunks';
import { createDefaultFeature, type Feature, type FeatureKey } from '../featureModel';

export function createChangeFeatureFieldThunk<T extends Feature>(
  featureKey: FeatureKey,
  patch: Partial<T>,
): Thunk {
  return function changeFeatureFieldThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { featureCrudActions },
      },
    },
  ) {
    const appState = getState();

    const feature: Feature =
      selectors.getFeature(appState.featureState, featureKey) ||
      createDefaultFeature(
        featureKey,
        selectors.getCurrencyByStrategyId(appState, featureKey.strategyId, selectors),
      );

    dispatch(
      featureCrudActions.patchOrInsert(featureKey, {
        ...feature,
        ...patch,
      }),
    );
  };
}
