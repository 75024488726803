import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import {
  ListedExecutionsComponent,
  type ListedExecutionsOwnProps,
  type ListedExecutionsStateProps,
} from './ListedExecutions';
import { getListedExecutionsModel } from './getListedExecutionsModel';
import { getListedExecutionsWarningDisplayedModel } from './getListedExecutionsWarningDisplayedModel';

export const ListedExecutions = (ownProps: ListedExecutionsOwnProps) => {
  const stateProps: ListedExecutionsStateProps = useAppSelector(state => ({
    model: getListedExecutionsModel(
      state,
      ownProps.rfqId,
      ownProps.strategyId,
      ownProps.legId,
      selectors,
    ),
    isWarningDisplayed: getListedExecutionsWarningDisplayedModel(
      state,
      ownProps.rfqId,
      ownProps.strategyId,
      ownProps.legId,
      selectors,
    ),
    isCommFlowIdDisplayed: selectors.isFeatureToggleEnabled(state, 'neos.commission.enabled'),
  }));

  return <ListedExecutionsComponent {...ownProps} {...stateProps} />;
};
