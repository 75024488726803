import { importExcelFileContent } from '@/util/excel/excel';
import { neosThunks } from '@/neos/business/thunks';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@/bootstrap/hooks.ts';
import { selectors } from '@/bootstrap/selectors.ts';

type ScheduleImportProps = {
  strategyId: string;
};

export const CLSScheduleImport = ({ strategyId }: ScheduleImportProps) => {
  const dispatch = useDispatch();
  const excelDateFormat = useAppSelector(state =>
    selectors.selectExcelDateFormat(state.ui.userPreferences),
  );

  function onImport(importedData: unknown[]) {
    dispatch(neosThunks.createClsImportScheduleDataThunk(strategyId, importedData, 'file'));
  }

  const fileInputRef = useRef<HTMLInputElement>(null);

  return (
    <button
      onClick={() => {
        fileInputRef.current?.click();
      }}
      className="btn btn-discreet-primary"
    >
      Import <i className="icon icon-sm ms-2">file_upload</i>
      <input
        ref={fileInputRef}
        data-e2e="cls-schedule-import"
        data-testid="cls-schedule-import"
        type="file"
        accept=".xlsx"
        className="d-none"
        onChange={e => {
          importExcelFileContent(e, onImport, { dateFormat: excelDateFormat });
        }}
      />
    </button>
  );
};
