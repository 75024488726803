import { CreateAllocSecondaryButton } from '@/neos/components/rfq/postNego/allocs/otcAllocations/otcAllocationFields/createAllocSecondaryEvent/CreateAllocSecondaryButton';
import { If } from '@/neos/components/share/if/if';
import { NeosResizableBox } from '@/neos/components/share/neosResizableBox/NeosResizableBox';
import { Fragment } from 'react';
import { type CommonResizableProps, addBorder } from '../PostNego';
import { ReGeneratePreconfsButton } from './ReGeneratePreconfsButton/ReGeneratePreconfsButton';
import { TriggerMatchingButton } from './TriggerMatchingButton/TriggerMatchingButton';
import { DeltaStockAllocations } from './deltaStockAllocations/DeltaStockAllocations';
import type { AllocationsModel } from './getAllocationsModel';
import { ListedExecutions } from './listedExecutions/ListedExecutions.container';
import { MatchingOrInsertToggle } from './matchingOrInsertToggle/MatchingOrInsertToggle';
import { OtcAllocations } from './otcAllocations/OtcAllocations.container';
import { ImportOtcAllocations } from './otcAllocations/otcAllocationFields/ImportOtcAllocations/ImportOtcAllocations.container';

export type AllocationsOwnProps = CommonResizableProps;
export type AllocationsStateProps = AllocationsModel;
type AllocationsProps = AllocationsOwnProps & AllocationsStateProps;

export const AllocationsComponent = ({
  rfqId,
  strategyLegs,
  deltaLegs,
  width,
  onResize,
  isDeltaPresent,
}: AllocationsProps) => {
  const hasOtc = strategyLegs.some(s => s.legsModel.some(l => l.isOtc));
  const legs = {
    strategyLegs,
    deltaLegs,
  };

  return (
    <div className="d-flex">
      <NeosResizableBox
        width={width}
        height={Infinity}
        resizeHandles={['e']}
        axis="x"
        minConstraints={[window.innerWidth * 0.25, Infinity]}
        maxConstraints={[window.innerWidth * 0.75, Infinity]}
        onResize={(_, d) => onResize(d.size.width)}
        isResizable={isDeltaPresent}
      >
        <div className={`${addBorder(isDeltaPresent)} px-1 h-100`}>
          <div className="d-flex justify-content-between mb-1">
            <label>Strategy Legs Allocations</label>
            <MatchingOrInsertToggle rfqId={rfqId} legs={legs} />
            <div className="d-flex gap-1">
              <CreateAllocSecondaryButton rfqId={rfqId} />
              <ReGeneratePreconfsButton rfqId={rfqId} />
              {hasOtc && <ImportOtcAllocations rfqId={rfqId} />}
              <TriggerMatchingButton rfqId={rfqId} legs={legs} />
            </div>
          </div>
          {strategyLegs.map(({ strategyId, legsModel }) => (
            <div className="border p-1 mb-3 overflow-auto" key={strategyId}>
              {legsModel.map(({ legId, isOtc }) => (
                <Fragment key={legId}>
                  {isOtc ? (
                    <OtcAllocations rfqId={rfqId} strategyId={strategyId} legId={legId} />
                  ) : (
                    <ListedExecutions rfqId={rfqId} strategyId={strategyId} legId={legId} />
                  )}
                </Fragment>
              ))}
            </div>
          ))}
        </div>
      </NeosResizableBox>
      <If condition={isDeltaPresent}>
        <div
          className="px-1 overflow-auto"
          style={{ maxWidth: `calc(100vw - ${width}px - 24px)`, width: '100%', minWidth: '600px' }}
        >
          <div className="d-flex justify-content-between mb-1">
            <label>Delta Legs Allocations</label>
            <MatchingOrInsertToggle rfqId={rfqId} legs={legs} isDelta />
            <TriggerMatchingButton rfqId={rfqId} legs={legs} isDelta />
          </div>
          {deltaLegs.map(({ strategyId, legsModel }) => (
            <div className="border p-1 mb-3 overflow-auto" key={strategyId}>
              {legsModel.map(({ legId, isOtc, isUnderlyingTypeDelta }) => (
                <Fragment key={legId}>
                  {isOtc ? (
                    <OtcAllocations
                      key={legId}
                      rfqId={rfqId}
                      strategyId={strategyId}
                      legId={legId}
                    />
                  ) : (
                    <>
                      {isUnderlyingTypeDelta ? (
                        <DeltaStockAllocations
                          rfqId={rfqId}
                          strategyId={strategyId}
                          legId={legId}
                        />
                      ) : (
                        <ListedExecutions rfqId={rfqId} strategyId={strategyId} legId={legId} />
                      )}
                    </>
                  )}
                </Fragment>
              ))}
            </div>
          ))}
        </div>
      </If>
    </div>
  );
};
