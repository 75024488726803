import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { thunks } from '@/bootstrap/thunks';
import { Switch } from '@/neos/components/share/switch/Switch';
import { useDispatch, useSelector } from 'react-redux';

export function ObserveNewRateFixing({
  allocationId,
  rfqId,
  legId,
}: {
  allocationId: string;
  rfqId: string;
  legId: string;
}) {
  const dispatch = useDispatch();

  const currentAllocation = useSelector((state: AppState) =>
    selectors.getOtcAllocationsByAllocId(state, rfqId, legId, allocationId),
  );
  const ObserveNewRateFixing = currentAllocation?.secondaryInfo?.observeNewRateFixing;

  const onObserveNewRateFixing = (value: boolean) => {
    dispatch(
      thunks.neos.createUpdateSecondaryInfoThunk(allocationId, rfqId, legId, {
        observeNewRateFixing: value,
      }),
    );
  };

  return (
    <Switch
      switchClassName="text-center"
      data-e2e="observe-new-rate-fixing"
      checked={!!ObserveNewRateFixing}
      onChange={() => onObserveNewRateFixing(!ObserveNewRateFixing)}
    />
  );
}
