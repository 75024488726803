import type { AppReadyAction } from './appReadyActions';

export const appReadyActionCreators = {
  createAppReadyAction,
};

function createAppReadyAction(): AppReadyAction {
  return {
    type: 'APP_READY',
  };
}
