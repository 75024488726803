import type { LogAction } from './logActions';

export const logActionCreators = {
  createLogAction,
  createErrorAction,
};

function createLogAction<T>(
  message: string,
  data?: T,
  showLogInConsoleAndKibana = false,
): LogAction<T> {
  return {
    type: 'DEBUG_ACTION',
    showLogInConsoleAndKibana,
    message,
    data,
  };
}

function createErrorAction<T>(message: string, data?: T): LogAction<T> {
  return {
    type: 'ERROR_ACTION',
    message,
    data,
  };
}
