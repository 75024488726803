import {
  type BreakFeePeriod,
  EBreakFeeElection,
  type EBreakFeeElectionType,
  EBreakFeePeriodTypes,
} from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel';
import { FlowDatePicker } from '@/neos/components/share/datePicker';
import { NumericInput } from '@/neos/components/share/numericInput';
import { useDispatch } from 'react-redux';

import { actionCreators } from '@/bootstrap/actions';
import { selectors } from '@/bootstrap/selectors';
import { thunks } from '@/bootstrap/thunks';
import { ElsBlocField } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/ElsBlocField';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';
import { If } from '@/neos/components/share/if/if';
import { Switch } from '@/neos/components/share/switch/Switch';
import { useContext } from 'react';
import {
  availableExecFeesValues,
  type ExecFeesValue,
  isElsProduct,
  isSingleUnderlyingDerivativeProduct,
} from '../../../../../../../../neos/business/neosModel';
import { commonElsBlocClasses, ElsFeaturesContext } from '../ElsFeatures';
import { getElsFeatureFields } from '../RateBloc/getElsFeatureFields';
import { useUpdateSectionHeight } from '../useUpdateSectionHeight';
import { ExecFeesIn } from './ExecFeesIn';
import { ExecFeesOut } from './ExecFeesOut';
import { useAppSelector } from '@/bootstrap/hooks.ts';

interface FeesBlocProps {
  canBeHidden: boolean;
}

export function FeesBloc({ canBeHidden }: FeesBlocProps) {
  const { rfqId, strategyId, isReadonlyAtCurrentWorkflow } = useContext(ElsFeaturesContext);
  const dispatch = useDispatch();

  const execFeesValueFormatter: { [k in ExecFeesValue]: string } = {
    NONE: 'None',
    IN: 'In',
    OUT: 'Out',
    IN_AND_OUT: 'In/Out',
  };

  function hideSection() {
    dispatch(thunks.neos.createToggleElsSectionThunk('Fees', false));
  }

  const { execFees, onExecFeesTypeChanged } = useAppSelector(state =>
    getElsFeatureFields(strategyId, state, selectors, thunks, actionCreators, dispatch),
  );

  const product = useAppSelector(state =>
    selectors.getStrategyProduct(state, strategyId, selectors),
  );

  const shouldBeEditable = useAppSelector(state =>
    selectors.isSecondaryEditable(state, rfqId, selectors),
  );

  const { ref } = useUpdateSectionHeight(strategyId, 'Fees');

  if (!isElsProduct(product)) {
    return null;
  }

  const { update: updateProduct } = actionCreators.neos.productCrudActions;

  const {
    dailyMinSize,
    dailyMaxSize,
    breakFeeElection,
    breakFeePeriods,
    uuid,
    secondaryMarketAllowed,
  } = product;

  const onDailyMinSizeChange = (newDailyMinSize: number) => {
    dispatch(updateProduct(uuid, { dailyMinSize: newDailyMinSize }));
  };
  const onDailyMaxSizeChange = (newDailyMaxSize: number) => {
    dispatch(updateProduct(uuid, { dailyMaxSize: newDailyMaxSize }));
  };

  const onBreakFeeElectionChange = (newBreakFeeElection: EBreakFeeElectionType | undefined) => {
    // newBreakFeeElection can be empty string in html
    if (!newBreakFeeElection) {
      newBreakFeeElection = undefined;
    }
    dispatch(thunks.neos.createUpdateBreakFeeElectionThunk(strategyId, rfqId, newBreakFeeElection));
  };

  const addBreakFeePeriod = () => {
    dispatch(thunks.neos.createAddBreakFeePeriodThunk(strategyId));
  };
  const removeBreakFeePeriod = (id: string) => {
    dispatch(thunks.neos.createRemoveBreakFeePeriodThunk(strategyId, id));
  };
  const updateBreakFeePeriod = (id: string, partialUpdate: Partial<BreakFeePeriod>) => {
    dispatch(thunks.neos.createUpdateBreakFeePeriodThunk(strategyId, id, partialUpdate));
  };
  const onSecondaryMarketAllowedChange = (newSecondaryMarketAllowed: boolean) => {
    dispatch(updateProduct(uuid, { secondaryMarketAllowed: newSecondaryMarketAllowed }));
  };

  const isExecFeesBlockDisplayed = isSingleUnderlyingDerivativeProduct(product);
  return (
    <div className={commonElsBlocClasses} style={{ cursor: 'grab' }}>
      <div className="fees-bloc p-1" ref={ref}>
        <div className="d-flex justify-content-between">
          <h5 className="d-inline-block p-1 m-0 mb-1">Fees</h5>
          {canBeHidden && (
            <button className="btn btn-icon btn-flat-primary" onClick={hideSection}>
              <i className="icon">clear</i>
            </button>
          )}
        </div>

        <If condition={isExecFeesBlockDisplayed}>
          <div className="d-flex flex-wrap">
            {execFees.isDisplayed && (
              <ElsBlocField
                minWidth={160}
                label="Exec Fees Type"
                renderInput={readOnly => (
                  <NeosSelect<ExecFeesValue>
                    readOnly={readOnly && !shouldBeEditable}
                    id="exec-fees-type"
                    value={execFees.value}
                    onChange={value => onExecFeesTypeChanged(value, rfqId)}
                    data-e2e="fees-block-exec-fees"
                    options={availableExecFeesValues.map(value => ({
                      value,
                      label: execFeesValueFormatter[value],
                    }))}
                  />
                )}
              />
            )}
            {execFees.isDisplayed && execFees.value.toUpperCase().includes('IN') && <ExecFeesIn />}
            {execFees.isDisplayed && execFees.value.toUpperCase().includes('OUT') && (
              <ExecFeesOut />
            )}
          </div>
        </If>
        <div className={`${isExecFeesBlockDisplayed ? 'border-top  pt-2 mt-2' : ''}`}>
          <div className="d-flex flex-wrap mb-2">
            <ElsBlocField
              minWidth={160}
              label="Break Fee Election"
              renderInput={readOnly => (
                <NeosSelect
                  readOnly={readOnly}
                  value={breakFeeElection}
                  onChange={onBreakFeeElectionChange}
                  data-e2e="fees-block-break-fee-election"
                  addEmptyOption
                  options={EBreakFeeElection}
                />
              )}
            />

            {breakFeeElection !== undefined && breakFeeElection !== 'NONE' && (
              <>
                <ElsBlocField
                  label={{ type: 'tooltip', content: 'SMA', tooltip: 'Secondary Market Allowed' }}
                  renderInput={readOnly => (
                    <Switch
                      disabled={readOnly}
                      switchClassName="text-center"
                      data-e2e="fees-block-secondaryMarketAllowed"
                      checked={!!secondaryMarketAllowed}
                      onChange={() => onSecondaryMarketAllowedChange(!secondaryMarketAllowed)}
                    />
                  )}
                />

                <ElsBlocField
                  label=" Daily Min Size (Nominal)"
                  renderInput={readOnly => (
                    <NumericInput
                      readOnly={readOnly}
                      value={dailyMinSize}
                      onBlur={onDailyMinSizeChange}
                      data-e2e="fees-block-daily-min-size"
                    />
                  )}
                />

                <ElsBlocField
                  label=" Daily Max Size (Nominal)"
                  renderInput={readOnly => (
                    <NumericInput
                      readOnly={readOnly}
                      value={dailyMaxSize}
                      onBlur={onDailyMaxSizeChange}
                      data-e2e="fees-block-dailyMaxSize"
                    />
                  )}
                />
              </>
            )}
          </div>
          {breakFeeElection !== undefined && breakFeeElection !== 'NONE' && (
            <div className="overflow-auto">
              <table
                className="table table-sm table-bordered mb-1"
                onMouseDown={e => e.stopPropagation()}
                style={{ cursor: 'default' }}
              >
                <thead>
                  <tr>
                    <th className="p-0" style={{ width: '32px' }}>
                      <button className="btn btn-flat-primary btn-icon" onClick={addBreakFeePeriod}>
                        <i className="icon">add</i>
                      </button>
                    </th>
                    <th className="minWidth85px">Type</th>
                    <th className="minWidth85px">Start Date</th>
                    <th className="minWidth85px">End Date</th>
                    <th className="minWidth85px">Nominal Min</th>
                    <th className="minWidth85px">Nominal Max</th>
                    <th className="minWidth85px">Fees (bps)</th>
                  </tr>
                </thead>
                <tbody>
                  {breakFeePeriods?.map(period => (
                    <tr key={period.id}>
                      <td className="p-0" style={{ width: '32px' }}>
                        <button
                          disabled={isReadonlyAtCurrentWorkflow}
                          className="btn btn-flat-primary btn-icon"
                          onClick={() => removeBreakFeePeriod(period.id)}
                        >
                          <i className="icon">delete_forever</i>
                        </button>
                      </td>
                      <td className="p-0 minWidth85px">
                        <NeosSelect
                          readOnly={isReadonlyAtCurrentWorkflow}
                          value={period.type}
                          onChange={value =>
                            updateBreakFeePeriod(period.id, {
                              type: value,
                            })
                          }
                          data-e2e="fees-block-break-fee-period-type"
                          addEmptyOption
                          options={EBreakFeePeriodTypes}
                        />
                      </td>
                      <td className="p-0 minWidth85px">
                        <FlowDatePicker
                          readOnly={isReadonlyAtCurrentWorkflow}
                          onChange={e =>
                            updateBreakFeePeriod(period.id, {
                              startDate: e,
                            })
                          }
                          maturities={[]}
                          date={period.startDate ?? ''}
                          hideCalendarIcon
                          className="errorable-bloc fixed-position"
                          inputClassName="form-control"
                          data-e2e="fees-block-break-fee-period-startDate"
                          popperPlacement="right"
                        />
                      </td>
                      <td className="p-0 minWidth85px">
                        <FlowDatePicker
                          readOnly={isReadonlyAtCurrentWorkflow}
                          onChange={e =>
                            updateBreakFeePeriod(period.id, {
                              endDate: e,
                            })
                          }
                          maturities={[]}
                          date={period.endDate ?? ''}
                          hideCalendarIcon
                          className="errorable-bloc fixed-position"
                          inputClassName="form-control"
                          data-e2e="fees-block-break-fee-period-endDate"
                          popperPlacement="right"
                        />
                      </td>
                      <td className="p-0 minWidth85px">
                        <NumericInput
                          readOnly={isReadonlyAtCurrentWorkflow}
                          value={period.nominalMin}
                          onBlur={e =>
                            updateBreakFeePeriod(period.id, {
                              nominalMin: e,
                            })
                          }
                          data-e2e="fees-block-break-fee-period-nominalMin"
                        />
                      </td>
                      <td className="p-0 minWidth85px">
                        <NumericInput
                          readOnly={isReadonlyAtCurrentWorkflow}
                          value={period.nominalMax}
                          onBlur={e =>
                            updateBreakFeePeriod(period.id, {
                              nominalMax: e,
                            })
                          }
                          data-e2e="fees-block-break-fee-period-nominalMax"
                        />
                      </td>
                      <td className="p-0 minWidth85px">
                        <NumericInput
                          readOnly={isReadonlyAtCurrentWorkflow}
                          value={period.fee}
                          onBlur={e =>
                            updateBreakFeePeriod(period.id, {
                              fee: e,
                            })
                          }
                          data-e2e="fees-block-break-fee-period-fee"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
