import type { AllocationOrDeltaPredealCheckWithLegsType } from './predealCheckWithLegs';

export interface CommonPredealCheck {
  uuid: string;
  rfqUuid: string;
  type: PredealCheckType;
  level: PredealCheckLevel;
  counterpartyId: number;
  status: PredealCheckStatus;
  mandatory: boolean;
  uiColor: PredealCheckUiColor;
  isDerogable: boolean;
  message: string;
  pdcDerogation?: DerogationKey;
  strategyUuid: string;
}

export const predealChecksWithoutLegsTypes = [
  'CREDIT_CHECK_BID',
  'CREDIT_CHECK_ASK',
  'KYC',
  'MIFID1',
] as const;

export type PredealCheckWithoutLegsType = (typeof predealChecksWithoutLegsTypes)[number];

type PttPredealCheckType = 'PTT';

export type PredealCheckType =
  | AllocationOrDeltaPredealCheckWithLegsType
  | PredealCheckWithoutLegsType
  | PttPredealCheckType;

export type PredealCheckRequestLevel = 'REQUEST';

export type PredealCheckAllocationLevel = 'ALLOCATION';
export type PredealCheckDeltaLevel = 'DELTA';

export type PredealCheckAllocationOrDeltaLevel =
  | PredealCheckAllocationLevel
  | PredealCheckDeltaLevel;

export type PredealCheckLevel = PredealCheckRequestLevel | PredealCheckAllocationOrDeltaLevel;

type PredealCheckStatus =
  | 'NEW'
  | 'COMPUTING'
  | 'UNDEFINED'
  | 'OK'
  | 'DEROGATED'
  | 'KO'
  | 'WARNING'
  | 'ERROR';

export type DerogationKey =
  | 'INCORRECT_RECEIVED_STATUS'
  | 'DEFAULT'
  | 'TECHNICAL_ERROR'
  | 'CBO_CLL'
  | 'CBO_DME'
  | 'CBO_OTH'
  | 'CBO_SAL'
  | 'DFA_AMA'
  | 'DFA_HEF'
  | 'DFA_INS'
  | 'DFA_OTH'
  | 'HRA_CLE'
  | 'HRA_CSM'
  | 'HRA_GRD'
  | 'HRA_OTH'
  | 'HRA_TIW'
  | 'IMV_CCL'
  | 'IMV_CCO'
  | 'IMV_CCS'
  | 'KYC_ECE'
  | 'KYC_OTH'
  | 'KYC_PTA'
  | 'KYC_RDP'
  | 'KYC_WDR'
  | 'KYC_WDS'
  | 'MFD_CAT'
  | 'MFD_ISL'
  | 'MFD_OTH'
  | 'MFD_PNC';

export type PredealCheckUiColor =
  | 'GREEN'
  | 'GREEN_BLUR'
  | 'AMBER'
  | 'AMBER_BLUR'
  | 'RED'
  | 'RED_DEROGATED'
  | 'RED_BLUR'
  | 'UNKNOWN_BLUR'
  | 'UNKNOWN';
