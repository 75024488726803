import type { AllocationInstruction } from './allocationInstructionModel';
import type { OnyxAllocationInstruction } from './allocationInstructionOnyxModel';

export const fromAllocationInstructionMappers = {
  mapFromOnyxAllocationInstruction,
};

function mapFromOnyxAllocationInstruction({
  uuid,
  rfqUuid: rfqId,
  execUuid: executionId,
  status,
  fixAllocId,
}: OnyxAllocationInstruction): AllocationInstruction {
  return { uuid, rfqId, executionId, status, fixAllocId };
}
