import type { Dispatchable, Thunk } from '@/bootstrap/thunks.ts';
import {
  type EquityHedge,
  type EquityHedgeComponent,
  initialEquityHedge,
  isElsProduct,
} from '@/neos/business/neosModel.ts';
import { idCreator } from '@/util/id';

export function createAddNewElsEquityHedgeComponentThunk(rfqId: string, productId: string): Thunk {
  return function addNewElsEquityHedgeComponentThunk(
    dispatch,
    getState,
    { actionCreators, selectors },
  ) {
    const state = getState();
    const product = selectors.getProduct(state, productId);

    if (!isElsProduct(product)) {
      return;
    }

    const newLine: EquityHedgeComponent = {
      uuid: idCreator.createId(),
      counterparty: undefined,
      portfolio: undefined,
      quantity: undefined,
      underlyingId: undefined,
      bookingApplication: undefined,
      bookingId: undefined,
      broker: undefined,
      equityType: undefined,
      internalPortfolio: undefined,
      spot: undefined,
      spotNet: undefined,
      way: undefined,
      nominal: undefined,
    };

    const oldEquityHedge: EquityHedge = product.equityHedge ?? initialEquityHedge;

    const dispatchables: Dispatchable[] = [
      actionCreators.neos.productCrudActions.update(product.uuid, {
        equityHedge: {
          ...oldEquityHedge,
          equityHedgeComponents: oldEquityHedge.equityHedgeComponents.concat(newLine),
        },
      }),
    ];

    dispatch(dispatchables);
  };
}
