import type { FC, ReactNode } from 'react';
import type { PreconfTabModel } from '../preconfTabmodel';

export interface PreconfTabOwnProps {
  rfqId: string;
  children: ReactNode;
}

export interface PreconfTabStateProps {
  model: PreconfTabModel;
}

export interface PreconfTabDispatchProps {
  onPreconfTabPreviewClicked: (rfqId: string) => void;
  onPreconfTabPreviewAttachmentClicked: (rfqId: string) => void;
}

type PreconfTabProps = PreconfTabOwnProps & PreconfTabStateProps & PreconfTabDispatchProps;

export const PreconfTabComponent: FC<PreconfTabProps> = ({
  rfqId,
  children,
  model,
  onPreconfTabPreviewClicked,
  onPreconfTabPreviewAttachmentClicked,
}) => {
  return (
    <div className="mt-2">
      {model.isEmailStatusDisplay && (
        <div className="row">
          <div className="col-12">
            <h3
              className={`text-${model.color} d-flex flex-row-reverse`}
              data-e2e="preconfirmation-is-email-sent-message"
            >
              {model.text}
            </h3>
          </div>
        </div>
      )}
      {children}
      <div className="d-flex justify-content-end">
        <div className="d-flex justify-content-end w-50">
          <button
            className="btn btn-primary me-2 w-25"
            disabled={model.isPreconfirmationButtonsDisabled}
            onClick={() => onPreconfTabPreviewClicked(rfqId)}
            data-e2e="preconfirmation-preview"
          >
            Preview
          </button>
          {model.isSaveButtonDisplayed && (
            <button
              className="btn btn-primary w-25"
              disabled={model.isPreconfirmationButtonsDisabled}
              onClick={() => onPreconfTabPreviewAttachmentClicked(rfqId)}
              data-e2e="preconfirmation-save"
            >
              <em className="icon">save_alt</em>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
