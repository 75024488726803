import { hasDiscriminator } from '@/neos/business/rfq/strategy/feature/hasDiscriminator';
import type { WithExtraProperty } from '@/neos/business/rfq/strategy/feature/withExtraProperty';
import { convertNullToUndefined } from '@/util/undefinedAndNull/convertNullToUndefined';
import type { EquityBulletFeature } from '../../../../neosModel';
import type { OnyxEquityBullet, OnyxProductFeaturesFields } from '../../../../neosOnyxModel';

export function mapFromOnyxFeatureOrExtraFeaturesToEquityBullet(
  { extraFeatures, equityBullet: definitionEquityBullet }: OnyxProductFeaturesFields,
  strategyId: string,
): EquityBulletFeature | undefined {
  const mappedEquityBullet: Pick<EquityBulletFeature, 'strategyId' | 'type'> = {
    strategyId,
    type: 'EQUITY_BULLET',
  };
  const equityBullet = convertNullToUndefined(definitionEquityBullet);

  if (equityBullet !== undefined) {
    return {
      ...mappedEquityBullet,
      equityBullet: !!equityBullet,
    };
  }

  if (extraFeatures) {
    const equityBulletFeature = extraFeatures.find(
      hasDiscriminator<OnyxEquityBullet>('EQUITY_BULLET'),
    );

    if (equityBulletFeature) {
      return {
        ...mappedEquityBullet,
        equityBullet: !!(equityBulletFeature && equityBulletFeature.equityBullet),
      };
    }
  }
  return undefined;
}

export function mapToOnyxEquityBulletFeature(
  feature: WithExtraProperty<EquityBulletFeature>,
): OnyxProductFeaturesFields {
  const { equityBullet } = feature;

  if (!feature.isExtra) {
    return {
      equityBullet,
      extraFeatures: [],
    };
  }
  const onyxEquityBullet: OnyxEquityBullet = { discriminator: 'EQUITY_BULLET', equityBullet };
  return {
    extraFeatures: [onyxEquityBullet],
  };
}
