import type { FC } from 'react';
import { useSelector } from 'react-redux';

import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import {
  RequestPredealChecksComponent,
  type RequestPredealChecksOwnProps,
  type RequestPredealChecksStateProps,
} from './RequestPredealChecks';
import { getRequestPredealChecksModel } from './getRequestPredealChecksModel';

export const RequestPredealChecks: FC<RequestPredealChecksOwnProps> = ownProps => {
  const stateProps = useSelector<AppState, RequestPredealChecksStateProps>(state => ({
    model: getRequestPredealChecksModel(state, ownProps.rfqId, selectors),
  }));
  return <RequestPredealChecksComponent {...ownProps} {...stateProps} />;
};
