import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import type { ElsClsFlowModeType } from '@/neos/business/neosOnyxModel';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';

export function FlowMode({
  value,
  onFlowChange,
  dataE2e,
  legId,
}: {
  value: ElsClsFlowModeType | undefined;
  onFlowChange: (value: ElsClsFlowModeType | undefined) => void;
  dataE2e: string;
  legId: string;
}) {
  const { eventType } = useAppSelector(state => selectors.getLegData(state, legId));
  const strategyId = useAppSelector(state => selectors.getStrategyIdByLegId(state, legId));
  const { strategyType } = useAppSelector(state => selectors.getStrategyData(state, strategyId));

  const options: ElsClsFlowModeType[] = ['Split Flow and Pay at Value Date', 'Do Not Split'];

  if (strategyType === 'CLS' || eventType === 'FULL_UNWIND') {
    options.push('Split Flow and Keep Payment Date');
  }

  return (
    <NeosSelect
      value={value}
      onChange={onFlowChange}
      addEmptyOption
      options={options.map(value => ({ value, label: value }))}
      data-e2e={dataE2e}
    />
  );
}
