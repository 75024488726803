import type {
  ExternalOnyxPreconfirmation,
  ExternalOnyxPreconfirmationData,
} from './externalOnyxPreconfirmationModel';
import type { ExternalPreconfirmation } from './externalPreconfirmationModel';

export function mapFromExternalOnyxPreconfirmation(
  externalOnyxPreconfirmation: ExternalOnyxPreconfirmationData,
): ExternalPreconfirmation {
  const { mailOptions, mailStatus } = externalOnyxPreconfirmation;

  const externalPreconfirmation: ExternalPreconfirmation = {
    ...mailOptions,
    mailStatus,
  };

  return externalPreconfirmation;
}

export function mapToExternalOnyxPreconfirmation(externalPreconfirmation: ExternalPreconfirmation) {
  const { mailStatus, ...mailOptions } = externalPreconfirmation;

  const externalOnyxPreconfirmation: ExternalOnyxPreconfirmation = {
    external: {
      mailOptions,
      mailStatus,
    },
  };
  return externalOnyxPreconfirmation;
}
