import type { Thunk } from '@/bootstrap/thunks';

export function createGetPrimeBrokerListThunk(counterpartId: string): Thunk {
  return function getPrimeBrokerListThunk(
    dispatch,
    getState,
    {
      actionCreators: {
        neos: { createPrimeBrokersRequestedAction },
      },
      selectors: { getPrimeBrokersByCounterpartId, isFeatureToggleEnabled },
    },
  ) {
    const state = getState();
    const primeBrokers = getPrimeBrokersByCounterpartId(state.referenceData, counterpartId);
    const clearerFieldToggleEnabled = isFeatureToggleEnabled(
      state,
      'neos.allocs.clearerfield.enabled',
    );

    if ((!primeBrokers || !primeBrokers.length) && clearerFieldToggleEnabled) {
      dispatch(createPrimeBrokersRequestedAction(counterpartId));
    }
  };
}
