import { camelCase, capitalize, startCase } from 'lodash';

export function lowerThenCamelCase(str: string | undefined) {
  return startCase(camelCase(str));
}

export function constantToSentence(str: string) {
  return capitalize(str).replace(/_/g, ' ');
}

export function sentenceToConstant(str: string) {
  return str.trim().toUpperCase().replace(/ /g, '_');
}

export function sentenceToKebabCase(str: string) {
  return str.trim().toLowerCase().replace(/ /g, '-');
}

export function isJsonString(str: string): boolean {
  try {
    JSON.parse(str);
  } catch (_e) {
    return false;
  }
  return true;
}

export function isValidEmail(str: string): boolean {
  return (
    str.includes('.') &&
    str.includes('@') &&
    str.indexOf('@') === str.lastIndexOf('@') &&
    !str.includes(',')
  );
}

export function camelCaseToTitleCase(str: string) {
  const result = str.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export function toUpperCase(value: string | undefined): string | undefined {
  return value?.toUpperCase();
}
