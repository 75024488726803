import { selectors } from '@/bootstrap/selectors';
import { services } from '@/bootstrap/services';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import type { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CommonOtcPreAllocationsComponent,
  type CommonOtcPreAllocationsOwnProps,
  type CommonOtcPreAllocationsStateProps,
} from '../CommonOtcPreAllocations';
import { getGenericOtcPreAllocationsModel } from '../getModel/getGenericOtcPreAllocationsModel';
import { genericMapDispatchToProps } from './genericMapDispatchToProps';

export const DeltaOtcPreAllocations: FC<CommonOtcPreAllocationsOwnProps> = ownProps => {
  const dispatch = useDispatch<ExtendedDispatch>();
  const stateProps = useSelector<AppState, CommonOtcPreAllocationsStateProps>(state => ({
    model: getGenericOtcPreAllocationsModel(
      state,
      ownProps.rfqId,
      'deltaOtcPreAllocs',
      selectors,
      services,
    ),
  }));
  const dispatchProps = genericMapDispatchToProps(dispatch, ownProps, 'DELTA');

  return (
    <CommonOtcPreAllocationsComponent
      scope="DELTA"
      {...ownProps}
      {...stateProps}
      {...dispatchProps}
    />
  );
};
