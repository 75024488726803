import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { Counterpart } from '@/neos/business/neosModel';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import { sortBy } from 'lodash';
import { useSelector } from 'react-redux';
import { InvalidTooltip } from '../../../InvalidTooltip';

interface CounterPartPropsType {
  allocationId: string;
  rfqId: string;
  legId: string;
  counterpart: Counterpart | undefined;
  onChange: (value: Counterpart | undefined) => void;
}

export function CounterPart({
  allocationId,
  counterpart,
  rfqId,
  legId,
  onChange,
}: CounterPartPropsType) {
  const rfqClients =
    useSelector((state: AppState) => selectors.getCounterparts(state, rfqId)) ?? [];
  const availableClients = sortBy(rfqClients, c => c.name);
  const extraClients =
    useSelector((state: AppState) => selectors.getAllocExtraCounterparts(state, rfqId)) ?? [];

  const allClients = [...availableClients, ...extraClients];
  const options = allClients.map(({ id, name, eliotCode, mnemo }) => ({
    value: id.toString(),
    label: name,
    data: [eliotCode, mnemo],
  }));
  const currentAllocation = useSelector((state: AppState) =>
    selectors.getOtcAllocationsByAllocId(state, rfqId, legId, allocationId),
  );

  const allocError = useSelector((state: AppState) =>
    selectors.getAllocErrors(state.ui, allocationId),
  );
  const error =
    currentAllocation?.counterpartId !== undefined &&
    (allocError?.Counterparty || !allClients.find(c => c.id === currentAllocation?.counterpartId));
  const warning = !!extraClients.find(c => c.id === currentAllocation?.counterpartId);

  return (
    <SimpleNeosTooltip
      disable={!warning}
      type={'warning'}
      id={`extra-client-tooltip`}
      message={"This counterparty is not in your client's portfolio"}
    >
      <div>
        <InvalidTooltip show={error}>
          <NeosSelect
            options={[...options, { value: '', label: '', data: [] }]}
            value={counterpart?.id?.toString()}
            onChange={selected => {
              const selectedCounterPartId = selected ? parseInt(selected, 10) : undefined;
              const selectedCounterPart = allClients.find(c => c.id === selectedCounterPartId);
              onChange(selectedCounterPart);
            }}
          />
        </InvalidTooltip>
      </div>
    </SimpleNeosTooltip>
  );
}
