import type { ThunkEpic } from '@/bootstrap/epics';
import { type Dispatchable, type Thunks, thunks } from '@/bootstrap/thunks';
import { DATE_CURRENT_TIME_FORMAT, formatNow } from '@/util/date/dateFormatHelper';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { ofType } from 'redux-observable';
import type { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { wrapInLoadingObservable } from '../../epics/wrapInLoadingObservable';
import type { OnyxError } from '../../mappers/error';
import { type NeosActionCreators, neosActionCreators } from '../../neosActionCreators';
import type { OnyxRfq, Workflow } from '../../neosOnyxModel';
import { createLoadRfqsApi } from '../blotterApi';

export interface LoadRfqsApi {
  loadRfqs: (
    from: string,
    to: string,
    workflows: Workflow[],
    underlyingIds: string[],
    counterpartIds: string[],
    statuses: string[],
    strategyTypes: string[],
    maturityDates: string, // FIXME: to delete when toggle feature is enabled in PROD
    maturityDateFrom: string,
    maturityDateTo: string,
  ) => Observable<OnyxRfq[]>;
}

export function getLoadRfqsEpic(http: SgmeHttp): ThunkEpic {
  const api = createLoadRfqsApi(http);
  return createLoadRfqsEpic(api, thunks, neosActionCreators, () =>
    formatNow(DATE_CURRENT_TIME_FORMAT),
  );
}

export function createLoadRfqsEpic(
  api: LoadRfqsApi,
  {
    createErrorToasterThunk,
    neos: { createCopyCurrentTypeaheadInActiveTypeahead, createIntegrateReceivedBlotterRfqsThunk },
  }: Thunks,
  { changeBlotterRequestTime }: NeosActionCreators,
  getCurrentTimeService: () => string,
): ThunkEpic {
  return action$ =>
    action$.pipe(
      ofType('REQUEST_BLOTTER_RFQS'),
      switchMap(
        ({
          from,
          to,
          workflows,
          underlyingIds,
          counterpartIds,
          statuses,
          strategyTypes,
          maturityDates,
          maturityDateFrom,
          maturityDateTo,
        }) => {
          const time = getCurrentTimeService();

          const apiObservable$ = api.loadRfqs(
            from,
            to,
            workflows,
            underlyingIds,
            counterpartIds,
            statuses,
            strategyTypes,
            maturityDates,
            maturityDateFrom,
            maturityDateTo,
          );

          return wrapInLoadingObservable({
            tabIds: ['BLOTTER'],
            apiObservable: apiObservable$,
            onSuccess: (rfqs): Dispatchable[] => [
              changeBlotterRequestTime(time),
              createIntegrateReceivedBlotterRfqsThunk(rfqs),
              createCopyCurrentTypeaheadInActiveTypeahead(),
            ],
            onError: (error: { response: OnyxError | undefined }) => {
              return [
                createErrorToasterThunk(
                  {
                    message: error?.response?.content ?? 'Error when loading Blotter',
                  },
                  error?.response,
                ),
              ];
            },
          });
        },
      ),
    );
}
