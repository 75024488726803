import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import {
  type CurrentUserPreferences,
  tradingBusinessTypes,
} from '@/neos/business/currentUserPreferences/currentUserPreferencesModel';
import { currentUserPreferencesActionCreators } from '@/neos/business/currentUserPreferences/currentUserPreferencesSlice';
import { partialResetTypes } from '@/neos/business/rfq/strategy/leg/product/productOnyxModel';
import { neosThunks } from '@/neos/business/thunks';
import { swapsWireSgTraderAccountTypeOptions } from '@/neos/components/rfq/postNego/confirmationDetails/SwapsWireAccountType';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';
import { NeosBlurInput } from '@/neos/components/share/blurComponent/NeosBlurInput';
import { getDefaultingPreferencesModel } from '@/neos/components/userPreferences/defaulting/getDefaultingPreferencesModel';
import { formatGridTemplateColumns } from '@/util/format/formatGridTemplateColumns';
import { useDispatch } from 'react-redux';

export function DefaultingPreferences() {
  const dispatch = useDispatch<ExtendedDispatch>();
  const { cities, countries, groups, isTrader } = useAppSelector(state =>
    getDefaultingPreferencesModel(state, selectors),
  );
  const currentDefaultingUserPreferences = useAppSelector(state =>
    selectors.currentUserPreferencesSelectors.selectCurrentUserPreferences(state),
  );

  const onChange = <K extends keyof CurrentUserPreferences>(
    value: CurrentUserPreferences[K] | undefined,
    fieldName: K,
  ) => {
    if (value !== undefined) {
      dispatch(
        neosThunks.createUpdateAndPostUserPreferencesThunk([
          currentUserPreferencesActionCreators.updateCurrentPreferencesByField({
            field: fieldName,
            newValue: value,
          }),
        ]),
      );
    }
  };

  return (
    <div
      className="d-grid gap-4"
      style={{ gridTemplateColumns: formatGridTemplateColumns(['0.5fr', [isTrader, '0.5fr']]) }}
    >
      <div className="d-grid gap-1 align-items-center" style={{ gridTemplateColumns: '1fr 1fr' }}>
        <label>My current city</label>
        <NeosSelect
          data-e2e="user-preferences-defaulting-cities"
          value={currentDefaultingUserPreferences.city?.cityId.toString()}
          onChange={selectedId => {
            if (selectedId !== undefined) {
              dispatch(neosThunks.createUpdateUserPreferencesCityThunk(Number(selectedId)));
            }
          }}
          options={cities}
        />
        <label>My current country</label>
        <NeosSelect
          data-e2e="user-preferences-defaulting-countries"
          value={currentDefaultingUserPreferences.country?.countryRefId.toString()}
          onChange={selectedId => {
            if (selectedId !== undefined) {
              dispatch(neosThunks.createUpdateUserPreferencesCountryThunk(Number(selectedId)));
            }
          }}
          options={countries}
        />
        <label>My default mail</label>
        <NeosBlurInput
          type="text"
          value={currentDefaultingUserPreferences.userEmail}
          onBlur={event => onChange(event.target.value, 'userEmail')}
          data-e2e="user-preferences-defaulting-mail"
        />
        <label>{isTrader ? 'Default group' : 'My current desk'}</label>
        <NeosSelect
          data-e2e="user-preferences-defaulting-group"
          value={currentDefaultingUserPreferences.defaultDeskId}
          onChange={selected => onChange(selected, 'defaultDeskId')}
          options={groups}
        />
        <label>Trading Business</label>
        <NeosSelect
          data-e2e="user-preferences-defaulting-trading-business"
          value={currentDefaultingUserPreferences.tradingBusiness}
          onChange={selected => onChange(selected, 'tradingBusiness')}
          options={tradingBusinessTypes}
        />
        <label>Partial Reset</label>
        <NeosSelect
          data-e2e="user-preferences-defaulting-partial-reset"
          value={currentDefaultingUserPreferences.partialReset}
          onChange={selected => onChange(selected, 'partialReset')}
          options={partialResetTypes}
        />
      </div>
      {isTrader && (
        <div
          className="d-grid gap-1 align-items-center"
          style={{ gridTemplateColumns: '1fr 1fr', height: 'fit-content' }}
        >
          <h6 style={{ height: '32px' }}>SwapsWire</h6>
          <span></span>
          <label>Full Name</label>
          <NeosBlurInput
            type="text"
            value={currentDefaultingUserPreferences.swapswireAccount}
            onBlur={event => onChange(event.target.value, 'swapswireAccount')}
            data-e2e="user-preferences-defaulting-trader-fullname"
          />
          <label>Profile</label>
          <NeosSelect
            data-e2e="user-preferences-defaulting-profile"
            value={currentDefaultingUserPreferences.swapswireAccountType}
            onChange={selected => onChange(selected, 'swapswireAccountType')}
            options={swapsWireSgTraderAccountTypeOptions}
          />
          <label>Accounting Center</label>
          <NeosBlurInput
            type="text"
            value={currentDefaultingUserPreferences.swapswireAccountingCenter}
            onBlur={event => onChange(event.target.value, 'swapswireAccountingCenter')}
            data-e2e="user-preferences-defaulting-trader-swapswire-accounting-center"
          />
        </div>
      )}
    </div>
  );
}
