import { forwardRef, type Ref } from 'react';
import Select from 'react-select';

export interface Option<T> {
  value: string;
  label: string;
  data?: string[];
  tag?: T;
}

interface NeosSelectWithAutocompleteProps<T> {
  value: string | undefined;
  options: Option<T>[];
  maxDisplayedItems?: number;
  classes?: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  addEmptyOption?: boolean;
  isLabelBold?: boolean;
  'data-e2e'?: string;
  onChange: (tag: Option<T> | null) => void;
}

// react-select is used because sg-bootstrap SgPicker is not working properly as of today.
// The style has been customized to better suit SG theme

export const NeosSelectWithAutocomplete = forwardRef(function <T>(
  {
    value,
    options,
    onChange,
    classes,
    isReadOnly,
    isDisabled,
    addEmptyOption,
    isLabelBold,
    'data-e2e': dataE2e = '',
  }: NeosSelectWithAutocompleteProps<T>,
  ref: Ref<HTMLDivElement>,
) {
  const displayedOptions: Option<T>[] = options.map(option => ({
    ...option,
    data: option.data ?? [],
  }));

  if (addEmptyOption) {
    displayedOptions.unshift({
      value: '',
      label: '',
      data: [],
      tag: undefined,
    });
  }

  const displayedValue = displayedOptions.find(option => option.value === value);
  return (
    <div ref={ref}>
      <Select<Option<T>>
        options={displayedOptions}
        value={displayedValue}
        id={dataE2e}
        menuPortalTarget={document.body} // fixes menu being hidden under other elements
        filterOption={(opt, rawInput) => {
          const searchable = [
            opt.value,
            ...opt.label.trim().split(/\s+/),
            ...(opt.data.data ?? []),
          ];
          return rawInput
            .trim()
            .split(/\s+/)
            .every(part =>
              searchable.some(s => s.toLocaleLowerCase().includes(part.toLocaleLowerCase())),
            );
        }}
        components={{
          IndicatorSeparator: () => null, // Hide react-select component
          DropdownIndicator: () => null, // Hide react-select component
          Placeholder: () => null, // Hide react-select component
        }}
        className={`w-100 ${classes ?? ''}`}
        classNames={{
          input: () => ` form-control form-select ${isDisabled ? 'readonly' : ''}`,
          option: () => `${dataE2e}-select-option`, //this is used in the e2e tests
          valueContainer: () => 'm-0 p-0',
          singleValue: () => `position-absolute ms-1 pe-32px ${isLabelBold ? 'fw-bold' : ''}`,
        }}
        theme={theme => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            neutral0: 'var(--bs-bg-lvl2)',
            primary: 'var(--bs-primary)',
            primary25: 'var(--bs-bg-lvl3)',
            primary50: 'var(--bs-bg-lvl1)',
            neutral80: 'var(--bs-body-font-color)',
          },
        })}
        styles={{
          input: () => ({
            backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%236e777a'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");`,
            backgroundSize: '1.125rem',
            padding: '5px',
          }), // Update the style to have the same as sg bootstrap
          singleValue: baseStyles => ({
            ...baseStyles,
            color: undefined,
          }), // Remove react-select singleValue styling to display disabled text color
          control: () => ({}), // Remove react-select control styling
          menu: baseStyles => ({
            ...baseStyles,
            width: 'fit-content',
            minWidth: '100%',
            marginTop: '1px',
          }),
          menuPortal: baseStyles => ({
            ...baseStyles,
            zIndex: '1520',
          }),
          option: baseStyles => ({
            ...baseStyles,
            whiteSpace: 'nowrap',
            height: '36px',
          }),
        }}
        isDisabled={isDisabled || isReadOnly}
        onChange={option => onChange(option)}
        data-e2e={dataE2e}
      />
    </div>
  );
});
