import { selectors } from '@/bootstrap/selectors';
import { services } from '@/bootstrap/services';
import type { AppState } from '@/bootstrap/state';
import { connect } from 'react-redux';
import { type NegoOwnProps, type NegoPropsFromState, NegoWithStyle } from './Nego';
import { getNegoModel } from './getNegoModel';

function mapStateToProps(state: AppState, { rfqId }: NegoOwnProps): NegoPropsFromState {
  return getNegoModel(state, rfqId, selectors, services);
}
export const Nego = connect(mapStateToProps)(NegoWithStyle);
