import { services } from '@/bootstrap/services';
import type { OnyxRfq } from '@/neos/business/rfq/rfqOnyxModel';
import type { BlotterModelGetters } from '.';

export function getTotalSalesMarginEur(rfq: OnyxRfq, { getBlotterClientWay }: BlotterModelGetters) {
  const clientWay = getBlotterClientWay(rfq, services);

  return rfq.manualSalesCredit?.value
    ? rfq.manualSalesCredit.value
    : clientWay === 'SELL'
      ? rfq.totalSalesMarginAmountEur?.bid?.value
      : rfq.totalSalesMarginAmountEur?.ask?.value;
}
