import type { Action } from '@/bootstrap/actions';
import type { Thunk } from '@/bootstrap/thunks';
import type { Feature, FeatureKey } from '../feature/featureModel';

export function createRemoveExtraFeatureThunk(featureKey: FeatureKey): Thunk {
  return function removeExtraFeature(
    dispatch,
    getState,
    {
      thunks: {
        neos: { createDefaultForwardStartDateRequested },
      },
      actionCreators: {
        neos: { featureCrudActions },
      },
      selectors,
    },
  ) {
    const { getFeature, isStrategyFeatureMandatory } = selectors;
    const state = getState();
    const feature: Feature | undefined = getFeature(state.featureState, featureKey);

    if (!feature) {
      throw new Error('The feature to remove is not defined');
    }

    const isMandatory = isStrategyFeatureMandatory(state, featureKey, selectors);
    if (isMandatory) {
      throw new Error('Cannot remove a mandatory feature');
    }
    const deleteFeatureAction: Action = featureCrudActions.delete(featureKey);

    dispatch(deleteFeatureAction);

    if (featureKey.type === 'FORWARD_START') {
      const { rfqId } = selectors.getStrategyData(state, feature.strategyId);
      dispatch(createDefaultForwardStartDateRequested(rfqId, undefined));
    }
  };
}
