import type {
  AddRfqIdToPendingBlotterRfqNotificationsAction,
  BlotterUiActions,
} from './blotterUiActions';
import {
  type BlotterUiState,
  blotterPositionStateLocalStorageKey,
  defaultBlotterUiState,
} from './blotterUiState';

export function blotterUiReducer(
  state: BlotterUiState = defaultBlotterUiState,
  action: BlotterUiActions,
): BlotterUiState {
  switch (action.type) {
    case 'ADD_RFQ_ID_TO_PENDING_BLOTTER_RFQ_NOTIFICATIONS_ACTION':
      return addRfqIdToPendingRfqIds(state, action);
    case 'EMPTY_PENDING_RFQ_IDS_ACTION':
      return emptyPendingRfqIds(state);
    case 'CHANGE_BLOTTER_POSITION':
      localStorage[blotterPositionStateLocalStorageKey] = action.position;
      return {
        ...state,
        position: action.position,
      };
    default:
      return state;
  }
}

function addRfqIdToPendingRfqIds(
  state: BlotterUiState,
  { pendingRfqId }: AddRfqIdToPendingBlotterRfqNotificationsAction,
): BlotterUiState {
  return { ...state, pendingRfqIds: [...state.pendingRfqIds, pendingRfqId] };
}

function emptyPendingRfqIds(state: BlotterUiState): BlotterUiState {
  return { ...state, pendingRfqIds: [] };
}
