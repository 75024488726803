import { type SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import type { FilterByCallback } from 'react-bootstrap-typeahead/types/types';
import type { Observable } from 'rxjs';

export interface TypeaheadValue {
  label: string;
  value: string;
}

export interface TypeaheadApi {
  fetchMatchingOptions: (prefix: string) => Observable<TypeaheadValue[]>;
}

export interface TypeaheadProps {
  id: string;
  api: TypeaheadApi;
  value?: TypeaheadValue;
  disabled?: boolean;
  inputClassName?: string;
  positionFixed: boolean;
  className: string;
  autoFocus: boolean;
  onChange: (option: TypeaheadValue | undefined) => void;
  onFocus?: (event: SyntheticEvent<HTMLInputElement>) => void;
  onBlur?: (option: TypeaheadValue | undefined) => void;
  ['data-e2e']?: string;
  children?: any;
  shouldCleanInputAfterSelection?: boolean;
  exclude?: string[];
}

export function Typeahead(props: TypeaheadProps) {
  const initialOptions: TypeaheadValue[] = [];
  const [options, setOptions] = useState(initialOptions);
  const [forceCloseMenu, setForceCloseMenu] = useState(false);
  const [actualValue, setActualValue] = useState(props.value);
  const [inputValue, setInputValue] = useState(props.value?.label);

  const typeaheadRef = useRef<HTMLDivElement>(null);
  const typeaheadPreviousPropValueRef = useRef(props.value);

  const onSearch = useCallback(
    (userInput: string) =>
      props.api.fetchMatchingOptions(userInput).subscribe((options: TypeaheadValue[]) => {
        setOptions(options);
      }),
    [props.api],
  );

  const onChange = (values: TypeaheadValue[]) => {
    const valueHasNotChanged = values[0] === actualValue;
    if (valueHasNotChanged) {
      return;
    }

    props.onChange(values[0]);

    const selectedValueLabelOrPreviousInputValue = values[0] ? values[0].label : inputValue;

    if (props.shouldCleanInputAfterSelection) {
      setActualValue(undefined);
      setInputValue(undefined);
    } else {
      setActualValue(values[0]);
      setInputValue(selectedValueLabelOrPreviousInputValue);
    }
  };

  const onInputChange = (value: string) => {
    setInputValue(value);
  };

  const onBlur = (event: any) => {
    if (!props.onBlur || actualValue?.label === event.target.value) {
      return;
    }

    setInputValue(undefined);
    props.onBlur(undefined);
  };

  useEffect(() => {
    handleBlurAndFocusEventListeners('addEventListener');

    const hasValueChanged =
      actualValue?.value !== props.value?.value &&
      typeaheadPreviousPropValueRef?.current?.value !== props.value?.value;

    if (hasValueChanged) {
      setActualValue(props.value);
      setInputValue(props.value?.label);
    }

    typeaheadPreviousPropValueRef.current = props.value;
    return () => {
      handleBlurAndFocusEventListeners('removeEventListener');
    };

    function handleBlurAndFocusEventListeners(
      action: Extract<keyof Element, 'removeEventListener' | 'addEventListener'>,
    ) {
      const ref = typeaheadRef?.current;
      if (ref) {
        const input = ref.querySelector('.rbt');
        if (input) {
          input[action]('blur', forceCloseMenuTrue, { capture: true });
          input[action]('focus', forceCloseMenuFalse, { capture: true });
        }
      }
    }

    function forceCloseMenuTrue(e: Event) {
      const ev = e as FocusEvent;
      if (!ev.relatedTarget || (ev.relatedTarget as HTMLDivElement).className !== 'rbt') {
        if (!forceCloseMenu) {
          setForceCloseMenu(true);
        }
      }
    }

    function forceCloseMenuFalse() {
      if (forceCloseMenu) {
        setForceCloseMenu(false);
      }
    }
  }, [actualValue, props.value, forceCloseMenu]);

  const getSelectedValue = (): TypeaheadValue[] => {
    const selectedWhenEmptyInputValue = [{ label: '', value: '' }];
    const selectedWhenEmptyActualValue = inputValue ? [] : selectedWhenEmptyInputValue;
    const selectedFromState = [{ label: inputValue ?? '', value: actualValue?.value ?? '' }];

    return actualValue ? selectedFromState : selectedWhenEmptyActualValue;
  };

  const {
    id,
    api,
    children,
    className,
    disabled,
    inputClassName,
    onChange: propsOnChange,
    onFocus,
    onBlur: propsOnBlur,
    value,
    autoFocus,
    positionFixed,
    shouldCleanInputAfterSelection,
    exclude,
    ...restProps
  } = props;

  const filterByCallback: FilterByCallback = option => {
    if (typeof option === 'string' || exclude === undefined) {
      return true;
    }
    const { value } = option;

    return !exclude.includes(value as string);
  };

  return (
    <div ref={typeaheadRef} className={className} {...restProps}>
      <AsyncTypeahead
        inputProps={{ className: inputClassName }}
        id={id}
        isLoading={false}
        delay={500}
        filterBy={filterByCallback}
        minLength={1}
        options={options}
        multiple={false}
        onSearch={onSearch}
        onChange={selected => onChange(selected as TypeaheadValue[])}
        onInputChange={onInputChange}
        onFocus={onFocus}
        onBlur={onBlur}
        selected={getSelectedValue()}
        disabled={disabled}
        open={forceCloseMenu ? false : undefined} // undefined => manager by the component
        autoFocus={autoFocus}
        positionFixed={positionFixed}
      />
    </div>
  );
}
