import type { FC } from 'react';
import { Modal } from 'react-bootstrap';
import type { PredealChecksModalModel } from './getPredealChecksModalModel';
import { PredealChecksScreen } from './predealChecksScreen';

import styles from './PredealChecksModal.module.scss';
export interface PredealChecksModalOwnProps {
  rfqId: string;
}

export type PredealChecksModalStateProps = PredealChecksModalModel;

export interface PredealChecksModalDispatchProps {
  hideModal(): void;
}

export type PredealChecksModalProps = PredealChecksModalOwnProps &
  PredealChecksModalStateProps &
  PredealChecksModalDispatchProps;

export const PredealChecksModalComponent: FC<PredealChecksModalProps> = ({
  rfqId,
  showModal,
  modalSize,
  hideModal,
}: PredealChecksModalProps) => (
  <Modal
    size={modalSize}
    show={showModal}
    onHide={() => hideModal()}
    onKeyPress={(event: any) => event.charCode === 13 && hideModal()}
  >
    <Modal.Header closeButton>
      <Modal.Title>Predeal Checks</Modal.Title>
    </Modal.Header>
    <Modal.Body className={`${styles['modal-body']}`}>
      <PredealChecksScreen rfqId={rfqId} />
    </Modal.Body>
    <Modal.Footer>
      <button className="btn btn-lg btn-flat-secondary" onClick={() => hideModal()}>
        Hide
      </button>
    </Modal.Footer>
  </Modal>
);
