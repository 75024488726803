import type { PredealCheckWithoutLegsType } from '@/neos/business/predealCheck/predealCheckModel';
import type { FC } from 'react';
import { PredealCheckBullet } from '../../../predealCheckBullet';
import type { RequestPredealCheckCellModel } from './getRequestPredealCheckCellModel';

import styles from './RequestPredealCheckCell.module.scss';

export interface RequestPredealCheckCellOwnProps {
  rfqId: string;
  type: PredealCheckWithoutLegsType;
  bdrId: number | undefined;
}

export interface RequestPredealCheckCellStateProps {
  model: RequestPredealCheckCellModel;
}

export interface RequestPredealCheckCellDispatchProps {
  onSelected(predealCheckId: string): void;
}

type RequestPredealCheckCellProps = RequestPredealCheckCellOwnProps &
  RequestPredealCheckCellStateProps &
  RequestPredealCheckCellDispatchProps;

export const RequestPredealCheckCellComponent: FC<RequestPredealCheckCellProps> = ({
  model,
  onSelected,
}) => (
  <div
    className={`${styles['request-predeal-check']} ${styles[model.selectableStyle]} ${
      styles[model.selectedStyle]
    } `}
    onClick={() => !model.isUnknown && model.isSelectable && onSelected(model.predealCheckId)}
    data-e2e={`request-${model.title}`}
  >
    <span>{model.title}</span>
    <div>
      <PredealCheckBullet
        isSelectable={false}
        isElementCentered={false}
        color={model.color}
        isLoading={model.isLoading}
      />
    </div>
  </div>
);
