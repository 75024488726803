import { type ActionCreators, actionCreators } from '@/bootstrap/actions';
import type { ThunkEpic } from '@/bootstrap/epics';
import { type Selectors, selectors } from '@/bootstrap/selectors';
import { createErrorToasterThunk } from '@/bootstrap/toasterThunks/thunks';
import type { OnyxError } from '@/neos/business/mappers/error';
import type { Version } from '@/neos/business/rfq/versions/versionsModel';
import { uiActionCreators } from '@/neos/business/ui/uiActionCreators';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { orderBy } from 'lodash';
import { ofType } from 'redux-observable';
import { concat, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { type LoadTransactionApi, createLoadTransactionApi } from '../apis/loadTransactionApi';

export function getGetLastTradeCompletedRfqEpic(http: SgmeHttp): ThunkEpic {
  const api = createLoadTransactionApi(http);
  return createGetLastTradeCompletedRfqEpic(api, actionCreators, selectors);
}

function orderVersionsByTradeDateDesc(versions: Version[]) {
  return orderBy(versions, (version: Version) => version.version, ['desc']);
}

export function createGetLastTradeCompletedRfqEpic(
  api: LoadTransactionApi,
  {
    neos: {
      lastTradeCompletedRfqActionCreators: { rfqReceived },
    },
  }: ActionCreators,
  { getVersions }: Selectors,
): ThunkEpic {
  return (action$, state$) => {
    return action$.pipe(
      ofType('GET_LAST_TRADE_COMPLETED_RFQ'),
      mergeMap(({ rfqId }) => {
        const appState = state$.value;
        const versions = getVersions(appState, rfqId);
        const orderedVersions = orderVersionsByTradeDateDesc(versions);
        const lastTradeCompletedVersion = orderedVersions.find(
          version => version.lifecycle.status === 'TRADE_COMPLETED',
        )?.version;

        if (!lastTradeCompletedVersion) {
          return [];
        }
        return concat(
          of(
            uiActionCreators.rfqUiCrudActions.patchOrInsert(rfqId, {
              isRfqDifferencesLoading: true,
            }),
          ),
          api.loadTransaction(rfqId, lastTradeCompletedVersion).pipe(
            map(transaction => {
              if (transaction?.rfq) {
                return [
                  uiActionCreators.rfqUiCrudActions.patchOrInsert(rfqId, {
                    isRfqDifferencesLoading: false,
                  }),
                  rfqReceived(transaction.rfq),
                ];
              }
              throw new Error('Transaction received is not defined');
            }),
            catchError((error: OnyxError) => [
              uiActionCreators.rfqUiCrudActions.patchOrInsert(rfqId, {
                isRfqDifferencesLoading: false,
              }),
              createErrorToasterThunk(
                { message: 'An error occurred when retrieving last trade completed RFQ' },
                error,
              ),
            ]),
          ),
        );
      }),
    );
  };
}
