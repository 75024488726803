import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { FC } from 'react';
import { useSelector } from 'react-redux';
import { EndOfObservationComponent, type OwnProps } from './EndOfObservation';
import { type EndOfObservationModel, getEndOfObservationModel } from './getEndOfObservationModel';

export const EndOfObservation: FC<OwnProps> = ({ rfqId, strategyId }) => {
  const model = useSelector<AppState, EndOfObservationModel>(appState =>
    getEndOfObservationModel(appState, strategyId, selectors),
  );
  return <EndOfObservationComponent {...{ rfqId, strategyId, model }} />;
};
