import type { Shortcut } from '@/neos/components/rfq/shortcuts/shared/ShortcutButton';
import type {
  ExcelDateFormat,
  ExcelLanguage,
  StrategyType,
} from '../../../../neos/business/neosModel';
import type { CustomizableQuickButtonToggle, NotificationGroup } from './userPreferencesUiModel';
import type { UserPreferencesState } from './userPreferencesUiState';

export const userPreferencesSelectors = {
  selectExcelLanguage,
  selectExcelDateFormat,
  isUserPreferencesModalShown,
  isQuickButtonsSectionShown,
  isIbChatSectionShown,
  getDisplayTimeZone,
  getBlotterDefaultTimerange,
  getDeltaToggle,
  getFairGreeksToggle,
  getFairPricesToggle,
  getSalesPricesToggle,
  getNotificationGroups,
  getIsIndicativeTradableDisplayed,
  getVolBidAskToggle,
  isAlertSoundPlayed,
  getQuickButtonStrategyToggle,
  getQuickButtonUnderlyingToggle,
  getUnderlyingsShortcutsList,
  getStrategiesShortcutsList,
};

function getUnderlyingsShortcutsList(state: UserPreferencesState): Shortcut<string>[] {
  return state.underlyingsShortcutsList;
}

function getStrategiesShortcutsList(state: UserPreferencesState): Shortcut<StrategyType>[] {
  return state.strategiesShortcutsList;
}

function getQuickButtonUnderlyingToggle(
  state: UserPreferencesState,
): CustomizableQuickButtonToggle {
  return state.quickButtonUnderlyingToggle;
}

function getQuickButtonStrategyToggle(state: UserPreferencesState): CustomizableQuickButtonToggle {
  return state.quickButtonStrategyToggle;
}

function isUserPreferencesModalShown(state: UserPreferencesState): boolean {
  return state.showUserPreferencesModal;
}

function isQuickButtonsSectionShown(state: UserPreferencesState): boolean {
  return state.showQuickButtonsSection;
}

function isIbChatSectionShown(state: UserPreferencesState): boolean {
  return state.showIbChatSection;
}
function getDisplayTimeZone(state: UserPreferencesState) {
  return state.displayTimeZone;
}

function getBlotterDefaultTimerange(state: UserPreferencesState) {
  return state.blotterDefaultTimerange;
}

function getDeltaToggle(state: UserPreferencesState) {
  return state.deltaToggle;
}

function isAlertSoundPlayed(state: UserPreferencesState): boolean {
  return state.isAlertSoundPlayed;
}

function selectExcelLanguage(state: UserPreferencesState): ExcelLanguage {
  if (state.excelLanguage === 'auto') {
    return navigator.language.includes('en') ? 'EN' : 'FR';
  }
  return state.excelLanguage;
}

function selectExcelDateFormat(state: UserPreferencesState): ExcelDateFormat {
  if (state.excelDateFormat === 'auto') {
    return navigator.language.includes('en-us') ? 'EN-US' : 'ISO';
  }
  return state.excelDateFormat;
}

function getFairGreeksToggle(state: UserPreferencesState) {
  return state.fairGreeksToggle;
}

function getFairPricesToggle(state: UserPreferencesState) {
  return state.fairPricesToggle;
}

function getSalesPricesToggle(state: UserPreferencesState) {
  return state.salesPricesToggle;
}

function getNotificationGroups(state: UserPreferencesState): NotificationGroup[] {
  return state.notificationGroups;
}

function getIsIndicativeTradableDisplayed(state: UserPreferencesState): boolean {
  return state.isIndicativeTradableDisplayed;
}

function getVolBidAskToggle(state: UserPreferencesState) {
  return state.volBidAskToggle;
}
