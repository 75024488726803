import type { Thunk } from '@/bootstrap/thunks';
import { RfqRecomputeScope } from '../../neosModel';

export function createRfqCloneStrategyThunk(
  rfqId: string,
  strategySourceId: string,
  strategyTargetId: string,
): Thunk {
  return function rfqCloneStrategyThunk(
    dispatch,
    getState,
    {
      selectors: { getRfqData, getQuote },
      thunks: {
        neos: { createCloneStrategyThunk, createRequestRfqDeltaHedgingAndFairPricesThunk },
      },
      actionCreators: {
        neos: {
          rfqDataCrudActions,
          quoteCrudActions,
          createStrategySelectedAction,
          createRecomputeRfqRequestedAction,
        },
      },
    },
  ) {
    const state = getState();

    dispatch(createCloneStrategyThunk(rfqId, strategySourceId, strategyTargetId));

    const rfqData = getRfqData(state, rfqId);
    const { unit } = getQuote(state, rfqData.quoteId);
    const newStrategyIds = rfqData.strategyIds;
    newStrategyIds.push(strategyTargetId);

    const quoteTargetId = strategyTargetId;
    dispatch(
      rfqDataCrudActions.update(rfqId, { strategyIds: newStrategyIds }),
      quoteCrudActions.insert(quoteTargetId, {
        parentId: { type: 'Strategy', id: strategyTargetId },
        deltaUnit: '%',
        unit,
        initialSalesPrice: undefined,
        initialTraderPrice: undefined,
      }),
      createStrategySelectedAction(rfqId, strategyTargetId),
      createRecomputeRfqRequestedAction(rfqId, RfqRecomputeScope.CHANGE_PRICE, {
        success: { dispatchables: [createRequestRfqDeltaHedgingAndFairPricesThunk(rfqId)] },
      }),
    );
  };
}
