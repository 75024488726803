import { createDerogatePredealChecksOnModalCloseThunk } from './derogatePredealChecksOnModalCloseThunk';
import { createObsoletePredealChecksRemovedThunk } from './obsoletePredealChecksRemovedThunk';
import { createPredealCheckDerogatedThunk } from './predealCheckDerogatedThunk';
import { createPredealChecksReceivedThunk } from './predealChecksReceivedThunk';
import { createRequestNeosPredealCheckRefreshThunk } from './requestNeosPredealCheckRefresh';
import { createShowPredealChecksModalThunks } from './showPredealChecksModalThunks';

export const predealChecksThunks = {
  createPredealCheckDerogatedThunk,
  createObsoletePredealChecksRemovedThunk,
  createPredealChecksReceivedThunk,
  createRequestNeosPredealCheckRefreshThunk,
  createShowPredealChecksModalThunks,
  createDerogatePredealChecksOnModalCloseThunk,
};
