import { NeosTooltip } from '@/neos/components/share/tooltip/NeosTooltip';
import { cn } from '@/util/classNames';
import { useEffect, useState } from 'react';
import { Popover } from 'react-bootstrap';
import { ajax } from 'rxjs/ajax';
import { timeout } from 'rxjs/operators';

async function getVersion() {
  try {
    const json = (await ajax.getJSON('version.json').pipe(timeout(5_000)).toPromise()) as {
      version: string;
    };
    return json?.version as string;
  } catch (_e) {
    return 'N/A';
  }
}

const current: { version: string } = { version: 'N/A' };
getVersion().then(v => {
  current.version = v;
});

export const EnrichedSgLogo = () => {
  const [deployedVersion, setDeployedVersion] = useState('');
  const [versionShown, setVersionShown] = useState(false);

  useEffect(() => {
    if (current.version !== 'N/A' && versionShown) {
      getVersion().then(v => setDeployedVersion(v));
      const c = setInterval(() => {
        getVersion().then(v => setDeployedVersion(v));
      }, 3000);
      return () => clearInterval(c);
    }
  }, [versionShown]);

  return (
    <NeosTooltip
      id="eqd-logo-tooltip"
      placement="right"
      trigger="click"
      onToggle={nextShow => {
        if (nextShow) {
          setDeployedVersion('');
        }
        setVersionShown(nextShow);
      }}
      overlay={
        <Popover id="version-tooltip">
          <Popover.Body>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, fit-content(100%))',
                columnGap: '7px',
              }}
            >
              <span>Current version: </span>
              <span className="px-1">{current.version}</span>
              <span>Deployed version:</span>
              <span
                className={cn('px-1', {
                  ['text-info fw-bold']: deployedVersion && deployedVersion !== current.version,
                })}
              >
                {current.version !== 'N/A'
                  ? deployedVersion || <div className="spinner spinner-sm" />
                  : 'N/A'}
              </span>
            </div>
          </Popover.Body>
        </Popover>
      }
      rootClose
    >
      <div className="navbar-title-link">
        <div className="navbar-logo">
          <img
            src="assets/images/sg_logo_glyph.svg"
            alt="SG logo Glyph"
            data-e2e="application-selection-neos"
          />
        </div>
        <div className="navbar-title-divider"></div>
        <div className="navbar-service-name">NEOS</div>
      </div>
    </NeosTooltip>
  );
};
