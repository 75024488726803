import type { ActionContainer } from '@/util/actionContainer';
import { legActionCreators } from './leg/legActionCreators';
import { strategyDataActionCreators } from './strategyData/strategyDataActionCreators';

import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { featureActionCreators } from './feature';
import { elsSectionsActionCreators } from './feature/elsSections/elsSectionsActionCreators';
import {
  getDefaultStrategyData,
  getDefaultTraderStrategyData,
} from './strategyData/strategyDataModel';
export const strategyActionCreators = {
  ...strategyDataActionCreators,
  ...legActionCreators,
  ...featureActionCreators,
  ...elsSectionsActionCreators,
  strategyInitialized,
};

function strategyInitialized(
  rfqId: string,
  strategyId: string,
  legId: string,
  state: AppState,
): ActionContainer {
  const legIds = [legId];
  const isTrader = selectors.isTrader(state);
  return [
    strategyDataActionCreators.strategyDataCrudActions.insert(
      strategyId,
      isTrader
        ? getDefaultTraderStrategyData(rfqId, strategyId, legIds)
        : getDefaultStrategyData(rfqId, strategyId, legIds),
    ),
    legActionCreators.createLegsInitializedAction(strategyId, legIds),
  ];
}
