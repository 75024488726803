import type { Thunk } from '@/bootstrap/thunks';
import { DefaultingScope, type RfqData } from '../../models';
import { DefaultingOptions } from '../../rfqActions';

export function createOnIACurrencyChangedThunk(
  rfqId: string,
  index: number,
  property: keyof Pick<RfqData, 'otcPreAllocs' | 'deltaOtcPreAllocs'>,
  independantAmountUnit: string | undefined,
): Thunk {
  return function onIACurrencyChangedThunk(
    dispatch,
    _,
    {
      actionCreators: {
        neos: { rfqDataCrudActions, createDefaultRfqRequestedAction },
      },
    },
  ) {
    dispatch(
      rfqDataCrudActions.arrayPatch(rfqId, {
        index,
        property,
        value: { independantAmountUnit },
      }),
    );

    const overrideScopes = [
      DefaultingScope.IA_CURRENCY,
      DefaultingScope.PRE_ALLOCATIONS_VALUE_DATE,
    ];
    const enrichScopes = [DefaultingScope.FOREX];
    const options = new DefaultingOptions({ enrichScopes, overrideScopes });

    dispatch(createDefaultRfqRequestedAction(rfqId, options));
  };
}
