import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import {
  type BookingStepFilter,
  filterBookingSteps,
  isSplitOnyxBookingStep,
  mapFromSplitOnyxBookingSteps,
  transformToBookingStepsByStepType,
} from '@/neos/business/bookingSteps/BookingStepsMappers';
import {
  availableBookingApplications,
  type BookingId,
  type BookingStepApplication,
  type BookingStepReferenceType,
  type BookingStepStatus,
  type BookingStepStepType,
  type OnyxBookingStep,
} from './bookingStepOnyxModel';
import { getWorkflowHasAtLeastOneMatchedBookingId } from './getWorkflowHasAtLeastOneMatchedBookingId';

export type BookingStep = {
  status: BookingStepStatus;
  message?: string;
  stepType: BookingStepStepType;
  bookingId?: string;
  nettingBookingId?: string;
  novationBookingId?: string;
  novationFeeId?: string;
  type: BookingStepReferenceType;
  application?: BookingStepApplication;
  referenceUuid: string;
  parentBookingUuid: string;
  isReference: boolean;
  salesCreditBooked?: boolean;
  brokenLink?: boolean;
  lastUpdateTime: string | undefined;
  meteorTradeId?: BookingId;
  meteorExGenId?: BookingId;
};

// TODO Corriger les types steptype non utilise !
export const selectBookingStepsFromSplitByFilter = (state: AppState, filter: BookingStepFilter) =>
  selectBookingStepsByFilter(state, filter, {
    mapper: (onyxBookingSteps: OnyxBookingStep[]) =>
      mapFromSplitOnyxBookingSteps(onyxBookingSteps.filter(isSplitOnyxBookingStep)),
  });

export function selectBookingStepsByFilter(
  state: AppState,
  filter: BookingStepFilter,
  options: {
    skipLegs?: boolean;
    mapper?: (onyxBookingStep: OnyxBookingStep[]) => BookingStep[];
  } = { skipLegs: false },
): BookingStep[] {
  const { rfqId, stepType } = filter;
  const onyxBookingSteps = state.bookingSteps[rfqId];
  if (onyxBookingSteps === undefined || onyxBookingSteps.length === 0) {
    return [];
  }
  const bookingSteps: BookingStep[] = options.mapper
    ? options.mapper(onyxBookingSteps)
    : transformToBookingStepsByStepType(onyxBookingSteps, stepType, options.skipLegs);

  return filterBookingSteps(bookingSteps, filter);
}

function selectAllBookingSteps(state: AppState, filter: BookingStepFilter): BookingStep[] {
  // All BookingSteps
  const bookingSteps = selectBookingStepsByFilter(state, filter);
  // Extracted BookingSteps from legs ( stepType is a split)
  const splitBookingSteps = selectBookingStepsFromSplitByFilter(state, filter);

  return [...bookingSteps, ...splitBookingSteps];
}

const getXOneSplitAllocationBookingStep = (state: AppState, rfqId: string, allocationId: string) =>
  selectBookingStepsByFilter(state, {
    rfqId,
    stepType: 'XONE_SPLIT',
    referenceUuid: allocationId,
  }).at(0);

// SPLIT Data but taken directly with no mapping
function getXOneSplitRFQBookingSteps(
  state: AppState,
  rfqId: string,
  strategyId: string,
): BookingStep[] {
  const legIds = state.strategyDataState[strategyId].legIds;
  return legIds.flatMap(legId =>
    selectBookingStepsByFilter(
      state,
      {
        rfqId,
        referenceUuid: legId,
        stepType: 'XONE_SPLIT',
      },
      { skipLegs: true },
    ).filter(bs => bs.status),
  );
}

function getXOneLegRFQBookingSteps(
  state: AppState,
  rfqId: string,
  strategyId: string,
): BookingStep[] {
  const legIds = state.strategyDataState[strategyId].legIds;
  return legIds.flatMap(legId => {
    return selectBookingStepsByFilter(state, {
      rfqId,
      referenceUuid: legId,
      stepType: 'XONE_LEG',
    }).filter(bookingStep => bookingStep.status);
  });
}

// ======

function hasAnyFailedXOneSplitBookingSteps(
  state: AppState,
  rfqId: string,
  strategyId: string,
): boolean {
  const bookingSteps = getXOneSplitRFQBookingSteps(state, rfqId, strategyId);
  return bookingSteps.some(booking => booking.status === 'FAILED');
}

function areAllXOneSplitBookingStepsBooked(
  state: AppState,
  rfqId: string,
  strategyId: string,
): boolean {
  const xOneSplitRFQBookingSteps = getXOneSplitRFQBookingSteps(state, rfqId, strategyId);
  return (
    !!xOneSplitRFQBookingSteps.length &&
    xOneSplitRFQBookingSteps.every(bookingStep =>
      ['BOOKED', 'MANUALLY_BOOKED'].includes(bookingStep.status),
    )
  );
}

function hasAnyFailedXOneLegBookingStep(
  state: AppState,
  rfqId: string,
  strategyId: string,
): boolean {
  const definedXoneLegBookingSteps = getXOneLegRFQBookingSteps(state, rfqId, strategyId);
  return definedXoneLegBookingSteps.some(bookingStep => bookingStep.status === 'FAILED');
}

function hasAnyBrokenLinkXOneLegBookingStep(
  state: AppState,
  rfqId: string,
  strategyId: string,
): boolean {
  const definedXoneLegBookingSteps = getXOneLegRFQBookingSteps(state, rfqId, strategyId);
  return definedXoneLegBookingSteps.some(bookingStep => bookingStep.brokenLink);
}

function areAllXOneLegBookingStepsBooked(
  state: AppState,
  rfqId: string,
  strategyId: string,
): boolean {
  const definedXoneLegBookingSteps = getXOneLegRFQBookingSteps(state, rfqId, strategyId);
  return (
    !!definedXoneLegBookingSteps.length &&
    definedXoneLegBookingSteps.every(bookingStep =>
      ['BOOKED', 'MANUALLY_BOOKED'].includes(bookingStep.status),
    )
  );
}

function getMonoLegBookingStep(
  state: AppState,
  rfqId: string,
  legId: string,
): BookingStep | undefined {
  const bookingSteps = selectBookingStepsByFilter(state, { rfqId, referenceUuid: legId });
  if (bookingSteps.length > 1) {
    return undefined;
  }
  if (
    bookingSteps.length === 1 &&
    bookingSteps[0].application &&
    availableBookingApplications.includes(bookingSteps[0].application)
  ) {
    return bookingSteps[0];
  }
}

// TODO Boilerplate for nothing -  Create a wrapper generator for these getters - Their only purpose is to be contained in the tests for mocking
const getDeltaExecutionBookingStep = (state: AppState, rfqId: string, executionId: string) => {
  return selectBookingStepsByFilter(state, { rfqId, referenceUuid: executionId }).at(0);
};

const getMarkitWireBookingStep = (state: AppState, rfqId: string, allocationId: string) => {
  return selectBookingStepsByFilter(state, {
    rfqId,
    referenceUuid: allocationId,
    stepType: 'MARKITWIRE',
  }).at(0);
};

const getLegBookingStep = (state: AppState, rfqId: string, legId: string) => {
  return selectBookingStepsByFilter(state, {
    rfqId,
    referenceUuid: legId,
    stepType: 'XONE_LEG',
  }).at(0);
};

const getExecutionBookingStep = (state: AppState, rfqId: string, executionId: string) => {
  return selectAllBookingSteps(state, { rfqId, referenceUuid: executionId }).at(0);
};

const getEliotCashFlowBookingStep = (state: AppState, rfqId: string, executionId: string) => {
  return selectAllBookingSteps(state, {
    rfqId,
    referenceUuid: executionId,
    stepType: 'ELIOT_CASH_FLOW',
  }).at(0);
};

const getDeltaAllocBookingStepByStrategyId = (
  state: AppState,
  rfqId: string,
  strategyId: string,
) => {
  return selectAllBookingSteps(state, { rfqId, referenceUuid: strategyId, type: 'DELTA' }).at(0);
};

const getAllocationBookingStep = (state: AppState, rfqId: string, allocationId: string) => {
  return selectBookingStepsByFilter(state, { rfqId, referenceUuid: allocationId }).at(0);
};

const getAllocationEliotStockSplitBookingStep = (
  state: AppState,
  rfqId: string,
  allocationId: string,
) => {
  return selectBookingStepsByFilter(state, {
    rfqId,
    stepType: 'ELIOT_STOCK_SPLIT',
    referenceUuid: allocationId,
  }).at(0);
};

const getOtcAllocationSalesCreditBookingStep = (
  state: AppState,
  rfqId: string,
  allocationId: string,
) => {
  return selectBookingStepsByFilter(state, {
    rfqId,
    referenceUuid: allocationId,
    type: 'SALES_CREDIT',
  }).at(0);
};
const getRfqAsiaSalesCreditBookingStep = (state: AppState, rfqId: string) => {
  return selectBookingStepsByFilter(state, { rfqId, type: 'SALES_CREDIT' }).at(0);
};
const getBookingStepByStrategyId = (state: AppState, rfqId: string, strategyId: string) =>
  selectBookingStepsByFilter(state, { rfqId, referenceUuid: strategyId }).at(0);

//  ========================

function hasRFQBookingSteps(state: AppState, rfqId: string): boolean {
  return state.bookingSteps[rfqId]?.length > 0;
}

function getOtcAllocationBookingStep(
  state: AppState,
  rfqId: string,
  referenceUuid: string,
): BookingStep | undefined {
  const mainFilter = { rfqId, referenceUuid };
  const xoneAllocationBookingStep = selectBookingStepsByFilter(state, {
    ...mainFilter,
    stepType: 'XONE_ALLOCATION',
  });

  if (xoneAllocationBookingStep.length > 0) {
    return xoneAllocationBookingStep[0];
  }

  const xoneSplitAllocationBookingStep = selectBookingStepsByFilter(state, {
    ...mainFilter,
    stepType: 'XONE_SPLIT',
  });

  if (xoneSplitAllocationBookingStep.length > 0) {
    return xoneSplitAllocationBookingStep[0];
  }

  const xoneStepInBookingStep = selectBookingStepsByFilter(state, {
    ...mainFilter,
    stepType: 'XONE_STEPIN',
  });

  if (xoneStepInBookingStep.length > 0) {
    return xoneStepInBookingStep[0];
  }

  const skipperBookingStep = selectBookingStepsByFilter(state, {
    ...mainFilter,
    stepType: 'SKIPPER',
  });

  if (skipperBookingStep.length > 0) {
    return skipperBookingStep[0];
  }
}

function getRfqEuropeSalesCreditBookingStep(
  state: AppState,
  rfqId: string,
): BookingStep | undefined {
  return selectBookingStepsByFilter(state, { rfqId }).find(booking => booking.salesCreditBooked);
}

function getRFQAggregatedSalesCreditBookingStep(
  state: AppState,
  rfqId: string,
): BookingStepStatus | undefined {
  const bookingSteps = selectBookingStepsByFilter(state, { rfqId });
  const salesCreditBookingStatuses = bookingSteps
    .filter(booking => booking.type === 'SALES_CREDIT')
    .map(b => b.status);
  if (
    salesCreditBookingStatuses.length &&
    salesCreditBookingStatuses.every(status => status === 'BOOKED')
  ) {
    return 'BOOKED';
  }
  if (
    salesCreditBookingStatuses.length &&
    salesCreditBookingStatuses.some(status => status === 'FAILED')
  ) {
    return 'FAILED';
  }
  return undefined;
}

function getBookingStepByStrategyIdOrByLegId(
  state: AppState,
  rfqId: string,
  strategyId: string,
  legId: string,
): BookingStep | undefined {
  const bookingStep = getBookingStepByStrategyId(state, rfqId, strategyId);
  if (bookingStep) {
    return bookingStep;
  }
  return getMonoLegBookingStep(state, rfqId, legId);
}

function getExecBookingStepForSingleExecSingleLegSingleStrat(
  state: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): BookingStep | undefined {
  const multiStrat = selectors.getRfqData(state, rfqId).strategyIds.length > 1;
  if (multiStrat) {
    return undefined;
  }
  const legIds = selectors.getStrategyData(state, strategyId).legIds;
  if (legIds.length > 1) {
    return undefined;
  }
  const executions = selectors.executionSelectors.selectObjects(state.execution, {
    rfqId,
    legId: legIds[0],
  });
  if (executions.length > 1 || executions.length === 0) {
    return undefined;
  }
  return getExecutionBookingStep(state, rfqId, executions[0].uuid);
}

export const bookingStepsSelectors = {
  selectAllBookingSteps,
  getXOneSplitAllocationBookingStep,
  getLegBookingStep,
  getDeltaAllocBookingStepByStrategyId,
  getExecutionBookingStep,
  getAllocationBookingStep,
  getAllocationEliotStockSplitBookingStep,
  getOtcAllocationBookingStep,
  getOtcAllocationSalesCreditBookingStep,
  getBookingStepByStrategyId,
  getDeltaExecutionBookingStep,
  hasAnyFailedXOneSplitBookingSteps,
  hasAnyFailedXOneLegBookingStep,
  areAllXOneLegBookingStepsBooked,
  areAllXOneSplitBookingStepsBooked,
  getRfqAsiaSalesCreditBookingStep,
  getRfqEuropeSalesCreditBookingStep,
  getRFQAggregatedSalesCreditBookingStep,
  hasRFQBookingSteps,
  getBookingStepByStrategyIdOrByLegId,
  getEliotCashFlowBookingStep,
  getExecBookingStepForSingleExecSingleLegSingleStrat,
  getMarkitWireBookingStep,
  getWorkflowHasAtLeastOneMatchedBookingId,
  selectBookingStepsByFilter,
  hasAnyBrokenLinkXOneLegBookingStep,
};
