import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { OnyxSingleUnderlyingProduct } from '../../../../../../../../neos/business/neosOnyxModel';
import { type UpperLowerStrikeProduct, isProductWithUpperLowerStrike } from '../../productModel';

export function mapToOnyxUpperLowerStrike(
  state: AppState,
  productId: string,
  selectors: Selectors,
): Pick<OnyxSingleUnderlyingProduct, 'upperStrike' | 'lowerStrike'> {
  const product = selectors.getProduct(state, productId);
  if (!isProductWithUpperLowerStrike(product)) {
    return {};
  }
  const { upperStrike, lowerStrike, strikeUnit } = product;
  const onyxUpperStrike: Pick<OnyxSingleUnderlyingProduct, 'upperStrike'> =
    upperStrike || strikeUnit ? { upperStrike: { value: upperStrike, unit: strikeUnit } } : {};
  const onyxLowerStrike: Pick<OnyxSingleUnderlyingProduct, 'lowerStrike'> =
    lowerStrike || strikeUnit ? { lowerStrike: { value: lowerStrike, unit: strikeUnit } } : {};

  return { ...onyxUpperStrike, ...onyxLowerStrike };
}

export function mapFromOnyxUpperLowerStrike(
  onyxProduct: OnyxSingleUnderlyingProduct,
): UpperLowerStrikeProduct {
  return {
    lowerStrike: onyxProduct?.lowerStrike?.value,
    upperStrike: onyxProduct?.upperStrike?.value,
    strikeUnit: onyxProduct?.upperStrike?.unit,
  };
}
