import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { uniqBy } from 'lodash';
import {
  isDividendFutureProduct,
  isFutureLikeProduct,
  isFutureOptionProduct,
  isFutureProduct,
  isOptionProduct,
} from '../../../../../../../../neos/business/neosModel';
import {
  DefaultValueForOTCLotSize,
  type LotSizeMarket,
} from './getStrategyDefinitionSpecificProduct';

export function getAvailableLotSizesMarketsForRfqStrategies(
  state: AppState,
  strategyId: string,
  underlyingId: string | undefined,
  defaultValues: string[],
  selectors: Selectors,
): LotSizeMarket[] {
  const underlyingInfo = selectors.getUnderlyingInfo(state, underlyingId);

  if (!underlyingInfo) {
    return [];
  }

  const masterProduct = selectors.getStrategyMasterProduct(state, strategyId, selectors);
  const isOptionMasterProduct = isOptionProduct(masterProduct);
  const isFutureLikeMasterProduct = isFutureLikeProduct(masterProduct);
  const isOptionOnFutureProduct = isFutureOptionProduct(masterProduct);

  const isListed = selectors.isListedStrategy(state, strategyId, selectors);

  if (!isFutureLikeMasterProduct && !isOptionMasterProduct && !isOptionOnFutureProduct) {
    return [{ id: defaultValues[0], value: defaultValues[0] }];
  }

  const isSomeProductsFLexOptions = selectors.isSomeStrategyProductFlexOptions(
    state,
    strategyId,
    selectors,
  );

  const futureMarketLotSizes = isFutureProduct(masterProduct)
    ? underlyingInfo.future.marketLotSizes
    : isDividendFutureProduct(masterProduct)
      ? underlyingInfo.dividendFuture.marketLotSizes
      : (underlyingInfo.totalReturnFuture?.marketLotSizes ?? {});

  const optionOnFutureMarketLotSizes = underlyingInfo.option.optionOnFutureMarketLotSizes;

  if (isOptionOnFutureProduct && !optionOnFutureMarketLotSizes) {
    return [];
  }

  const optionMarketLotSizes = isSomeProductsFLexOptions
    ? underlyingInfo.option.marketLotSizesFlex
    : isOptionOnFutureProduct
      ? optionOnFutureMarketLotSizes! //FIXME remove the exclamatory point for type check below
      : underlyingInfo.option.marketLotSizes;

  const marketLotSizes = isFutureLikeMasterProduct ? futureMarketLotSizes : optionMarketLotSizes;

  const lotsizeMarketsFormated: LotSizeMarket[] = Object.keys(marketLotSizes).map(key => ({
    id: key,
    value: `${isListed ? key : marketLotSizes[key].lotSize}`,
  }));

  if (isListed) {
    return lotsizeMarketsFormated;
  }

  const defaultValOtc: LotSizeMarket = {
    id: DefaultValueForOTCLotSize.ID,
    value: DefaultValueForOTCLotSize.ID,
  };
  return uniqBy([defaultValOtc, ...lotsizeMarketsFormated], data => data.value);
}
