import type { OnyxCurrentUserPreferences } from '@/neos/business/currentUserPreferences/currentUserPreferencesMappers';
import type { CurrentUserPreferences } from '@/neos/business/currentUserPreferences/currentUserPreferencesModel';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { Observable } from 'rxjs';

export interface CurrentUserPreferencesApi {
  getCurrentUserPreferences: () => Observable<OnyxCurrentUserPreferences>;
  postCurrentUserPreferences: (currentUserNewPref: CurrentUserPreferences) => Observable<void>;
}

export const createCurrentUserPreferencesApis = (http: SgmeHttp): CurrentUserPreferencesApi => {
  const BaseUrl: string = 'api/users/current/preferences';
  return {
    getCurrentUserPreferences() {
      return http.get<OnyxCurrentUserPreferences>({ url: BaseUrl });
    },
    postCurrentUserPreferences(currentUserNewPref: CurrentUserPreferences) {
      return http.post({
        url: BaseUrl,
        body: currentUserNewPref,
      });
    },
  };
};
