import { selectors } from '@/bootstrap/selectors';
import { type NewRateTenors, newRateTenors } from '../../../../../../business/neosOnyxModel';
import { NeosBlurInput } from '../../../../../share/blurComponent/NeosBlurInput';

import { useAppSelector } from '@/bootstrap/hooks';
import { thunks } from '@/bootstrap/thunks';
import { isClsProduct, isElsProduct } from '@/neos/business/neosModel';
import { getInterestRateTenorModel } from '@/neos/components/rfq/strategies/strategy/features/interestRate/getInterestRateTenorModel';
import { NeosTooltip } from '@/neos/components/share/tooltip/NeosTooltip';
import { FormSelect, Tooltip } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { actionCreators } from '@/bootstrap/actions.ts';

type CurveRateTenorProps = {
  strategyId: string;
  labelClassNames: string;
  parentClassNames: string;
};

export function RateTenor({ strategyId, labelClassNames, parentClassNames }: CurveRateTenorProps) {
  const dispatch = useDispatch();
  const { newRateTenor, rateTenor, onNewRateTenorChanged, onRateTenorChanged } = useAppSelector(
    state =>
      getInterestRateTenorModel(strategyId, state, actionCreators, selectors, thunks, dispatch),
  );
  const product = useAppSelector(state =>
    selectors.getStrategyProduct(state, strategyId, selectors),
  );

  const isEls = isElsProduct(product);

  const isCls = isClsProduct(product);

  const isReadonlyAtCurrentWorkflow = useAppSelector(state =>
    selectors.isReadOnlyAtCurrentWorkflow(state, strategyId, selectors),
  );

  return (
    <div className={parentClassNames + ' els-field'} onMouseDown={e => e.stopPropagation()}>
      <NeosTooltip placement="top" overlay={<Tooltip id={'rate-tenor-label'}>Rate Tenor</Tooltip>}>
        <label className={labelClassNames}>Tenor</label>
      </NeosTooltip>
      {isEls || isCls ? (
        <FormSelect
          readOnly={isReadonlyAtCurrentWorkflow}
          value={newRateTenor ?? ''}
          onChange={event =>
            onNewRateTenorChanged((event.target.value as NewRateTenors) || undefined)
          }
          data-e2e="interest-rate-new-rate-tenor"
        >
          <option value=""></option>
          {newRateTenors.map(tenor => (
            <option key={tenor} value={tenor}>
              {tenor}
            </option>
          ))}
        </FormSelect>
      ) : (
        <NeosBlurInput
          readOnly={isReadonlyAtCurrentWorkflow}
          type="text"
          value={rateTenor ?? ''}
          onBlur={event => onRateTenorChanged(event.target.value || undefined)}
          data-e2e="interest-rate-rate-tenor"
        />
      )}
    </div>
  );
}
