import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import {
  type RefMaturityCellDispatchProps,
  type RefMaturityCellMapStateToProps,
  type RefMaturityCellOwnProps,
  RefMaturityCellUnconnected,
} from './RefMaturityCell';
import { getRefMaturityCellModel } from './getRefMaturityCellModel';

export const RefMaturityCell = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RefMaturityCellUnconnected);

function mapStateToProps(
  state: AppState,
  { rfqId, underlyingId }: RefMaturityCellOwnProps,
): RefMaturityCellMapStateToProps {
  return getRefMaturityCellModel(state, rfqId, underlyingId, selectors);
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId }: RefMaturityCellOwnProps,
): RefMaturityCellDispatchProps {
  return {
    onRefMaturityChanged: (underlyingId: string, refMaturity: string | undefined) => {
      dispatch(neosThunks.createChangeReferenceMaturityThunk({ rfqId, underlyingId }, refMaturity));
    },
  };
}
