import { BookingStatusBorderColor, type BookingStepStatus } from '@/neos/business/neosOnyxModel';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';

export function BookingStatusWarningIcon({
  status,
  message,
}: {
  status: BookingStepStatus | undefined;
  message: string | undefined;
}) {
  const tooltipType =
    status && ['FAILED', 'SKIPPED', 'ABORTED'].includes(status)
      ? BookingStatusBorderColor[status]
      : message
        ? 'warning'
        : undefined;

  if (!message) {
    return <div></div>;
  }

  return (
    <div className="d-flex align-items-center" style={{ height: '32px' }}>
      <SimpleNeosTooltip message={message} type={tooltipType} id="status-error-message">
        <i
          data-e2e="leg-execution-eliot-id-error-icon"
          className={`icon icon-sm text-${tooltipType}`}
        >
          warning
        </i>
      </SimpleNeosTooltip>
    </div>
  );
}
