import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { isClsProduct } from '@/neos/business/neosModel';

export interface ClsModel {
  isDisplayed: boolean;
  isRateDisplayed: boolean;
}

export function getClsModel(state: AppState, strategyId: string, selectors: Selectors): ClsModel {
  const { strategyType } = selectors.getStrategyData(state, strategyId);

  const resetFrequencyDefinition = selectors.getSingleStrategyDefinitionFeature(
    state.referenceData,
    strategyType,
    'RESET_FREQUENCY_FEATURE',
  );

  const interestRateDefinition = selectors.getSingleStrategyDefinitionFeature(
    state.referenceData,
    strategyType,
    'INTEREST_RATE_INDEX_FEATURE',
  );

  const interestRateFeature = selectors.getFeature(state.featureState, {
    strategyId,
    type: 'INTEREST_RATE_INDEX_FEATURE',
  });

  const product = selectors.getStrategyMasterProduct(state, strategyId, selectors);
  const isERI = isClsProduct(product) && product.clsType === 'ERI';

  return {
    isDisplayed: !isERI && !!resetFrequencyDefinition && !!interestRateDefinition,
    isRateDisplayed: interestRateFeature?.rateCurve === 'Fixed',
  };
}
