import type { UserInfoReceivedAction } from './userInfoActions';
import { type UserInfoState, defaultUserInfoState } from './userInfoState';

export const userInfoReducer = (
  state: UserInfoState = defaultUserInfoState,
  action: UserInfoReceivedAction,
): UserInfoState => {
  if (action.type === 'USER_INFO_RECEIVED') {
    const { currentUser, toggleFeatures } = action.userInfo;
    return {
      ...state,
      currentUser: {
        email: currentUser.email,
        diffusionGroups: currentUser.diffusionGroups,
        firstname: currentUser.firstname,
        groupId: currentUser.groupId,
        lastname: currentUser.lastname,
        login: currentUser.login,
        sesameId: currentUser.sesameId,
        categories: currentUser.categories,
      },
      toggleFeatures,
    };
  }
  return state;
};
