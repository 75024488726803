import { type Selectors, selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { commonSelectors } from '@/common/business/commonSelectors';
import { connect } from 'react-redux';
import { RfqComponent, type RfqOwnProps, type RfqPropsFromState } from './Rfq';

const { getSelectedNegoStep } = selectors;
const { isFeatureToggleEnabled } = commonSelectors;

function mapStateToProps(state: AppState, { rfqId }: RfqOwnProps): RfqPropsFromState {
  const selectedStep = getSelectedNegoStep(state.ui, rfqId);
  return {
    selectedStep,
    isRfqFullyLoaded: isRfqFullyLoaded(state, rfqId, selectors),
    isPostNegoEnabled:
      isFeatureToggleEnabled(state, 'neos.allocs.enabled') ||
      isFeatureToggleEnabled(state, 'neos.otc.allocs.enabled'),
  };
}

function isRfqFullyLoaded(state: AppState, rfqId: string, selectors: Selectors) {
  const rfqData = selectors.getRfqData(state, rfqId);
  return (
    rfqData?.strategyIds !== undefined &&
    rfqData.strategyIds.length > 0 &&
    rfqData.strategyIds.every(id => {
      const strategyData = selectors.getStrategyData(state, id);
      return strategyData !== undefined && Object.keys(strategyData).length > 0;
    })
  );
}

export const Rfq = connect(mapStateToProps)(RfqComponent);
