import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { Observable } from 'rxjs';

export interface StorageRequest {
  name: string;
  data: string;
  isAttachmentEncoded: boolean;
}

export interface StorageRequestApi {
  storeState: (mailRequest: StorageRequest) => Observable<void>;
}

export const createStorageRequestApi = (http: SgmeHttp): StorageRequestApi => ({
  storeState(storageRequest: StorageRequest): Observable<void> {
    return http.post<void>({
      body: storageRequest,
      url: 'api/storage/save',
    });
  },
});
