import type { Services } from '@/bootstrap/services';
import type { FromOnyxMappers } from '@/neos/business/mappers';
import { convertNullToUndefined } from '@/util/undefinedAndNull/convertNullToUndefined';
import type { Cls } from '../../productModel';
import type { OnyxSingleUnderlyingProduct } from '../../productOnyxModel';
import { mapFromOnyxLegPeriod } from './elsMapper';

export function mapFromOnyxProductCls(
  legId: string,
  onyxProduct: OnyxSingleUnderlyingProduct,
  productId: string,
  mappers: FromOnyxMappers,
  services: Services,
): Cls {
  return {
    subFamily: 'CLS',
    strikeDate: convertNullToUndefined(onyxProduct.strikeDate),
    strikeTenor: convertNullToUndefined(onyxProduct.strikeTenor),
    generateFrom: convertNullToUndefined(onyxProduct.generateFrom),
    fixedDay: convertNullToUndefined(onyxProduct.fixedDay),
    rateReset: convertNullToUndefined(onyxProduct.rateReset),
    resetMode: convertNullToUndefined(onyxProduct.resetMode),
    effectiveDate: convertNullToUndefined(onyxProduct.effectiveDate),
    ratePeriods: mapFromOnyxLegPeriod(onyxProduct.rateSchedulePeriods, services),
    brokenPeriod: convertNullToUndefined(onyxProduct.brokenPeriod),
    conventionDay: convertNullToUndefined(onyxProduct.conventionDay),
    clsType: convertNullToUndefined(onyxProduct.clsType),
    accrual: convertNullToUndefined(onyxProduct.accrual),
    ...mappers.mapFromOnyxProductCommon(legId, onyxProduct, productId),
    ...mappers.mapFromOnyxOtcNegotiation(),
    ...mappers.mapFromSingleDerivativeOnyxProduct(onyxProduct, mappers),
    hasCustomUnderlying: false,
    isScheduleObsolete: convertNullToUndefined(onyxProduct.isScheduleObsolete),
  };
}
