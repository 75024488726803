import { actionCreators } from '@/bootstrap/actions';
import { useAppSelector } from '@/bootstrap/hooks';
import type { Counterpart } from '@/neos/business/rfq/actorsSetup/counterpart/counterpartModel';
import { debounce } from 'lodash';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';

type SearchInputProps = { searchedCounterparts: Counterpart[] };

export function SearchInput({ searchedCounterparts }: SearchInputProps) {
  const dispatch = useDispatch();
  const searchInputRef = useRef<HTMLInputElement>(null);

  const isSearchLoading = useAppSelector(
    state => state.ui.userPreferences.isSearchCounterpartPreferenceLoading,
  );

  const areNoResultFound =
    searchedCounterparts.length === 0 &&
    searchInputRef.current?.value.trim() !== '' &&
    searchInputRef.current?.value.trim() !== undefined &&
    !isSearchLoading;

  const onSearchChange = () => {
    const formattedSearchedValue = searchInputRef.current?.value.trim();
    if (formattedSearchedValue === '' || !formattedSearchedValue) {
      dispatch(actionCreators.neos.createInsertSearchedCounterpartsAction([]));
      return;
    }
    dispatch(actionCreators.neos.createChangeUserPreferenceSearchCounterpartsLoadingAction(true));
    dispatch(actionCreators.neos.createSearchCounterpartPreferencesAction(formattedSearchedValue));
  };

  const debouncedOnChange = debounce(onSearchChange, 500);

  return (
    <>
      <div className="form-group mt-2">
        <div className="input-group">
          <div className="input-icon-start">
            {isSearchLoading ? (
              <div className="spinner spinner-sm"></div>
            ) : (
              <i className="icon">search</i>
            )}
          </div>
          <input
            type="search"
            ref={searchInputRef}
            className="form-control"
            placeholder="Search for a counterparty"
            onChange={debouncedOnChange}
            data-e2e="user-preferences-counterparts-search"
          />
        </div>
      </div>
      {areNoResultFound && <div className="mt-2">No result found</div>}
    </>
  );
}
