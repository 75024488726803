import type { Thunk } from '@/bootstrap/thunks';

export function createRfqAddLegWithUnderlyingThunk(
  rfqId: string,
  strategyId: string,
  underlyingId: string,
): Thunk {
  return function RfqAddLegsWithUnderlyingThunk(
    dispatch,
    getState,
    {
      selectors: { getStrategyData, getLegData },
      thunks: {
        neos: { createRfqAddLegsThunk },
      },
      actionCreators: {
        neos: { createUnderlyingInfoRequestedAction, productCrudActions },
      },
    },
  ) {
    dispatch(createRfqAddLegsThunk(strategyId, 1, 'BEFORE_LAST'));

    const state = getState();
    const { legIds } = getStrategyData(state, strategyId);
    const addedLegId = legIds[legIds.length - 2];
    const { productId } = getLegData(state, addedLegId);

    dispatch(
      createUnderlyingInfoRequestedAction([rfqId], [underlyingId], {
        success: {
          dispatchables: [productCrudActions.update(productId, { underlyingId })],
        },
      }),
    );
  };
}
