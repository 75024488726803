import { createDefaultObservableTypeRequestedAction } from '@/neos/business/rfq/rfqActionCreators/defaultObservableTypeRequestedAction';
import { createSearchBrokerNotInPreferencesAction } from '@/neos/business/rfq/rfqActionCreators/searchBrokerNotInPreferencesActions';
import { createTradeRecapMailCCRecipientsAction } from '@/neos/business/rfq/tradeRecapMail/TradeRecapMailCCRecipientsActionCreator';
import { transfereeContactsCrudActions } from '@/neos/business/rfq/transfereeContacts/transfereeContactsActionCreators';
import { actorsSetupActionCreators } from '../actorsSetup/actorSetupActionCreators';
import { counterpartsActionCreators } from '../counterparts/counterpartsActionCreators';
import { extraCounterpartsActionCreators } from '../extraCounterparts/extraCounterpartsActionCreators';
import { fairPriceActionCreators } from '../fairPrice/fairPriceActionCreator';
import { forexRatesActionCreators } from '../forexRates/forexRatesActionCreator';
import { hedgeActionCreators } from '../hedge/hedgeActionCreators';
import { preconfirmationEmailPreviewActionsCreators } from '../preconfirmationEmailPreview/preconfirmationEmailPreviewActionCreators';
import { quoteActionCreators } from '../quotes/quoteActionCreator';
import { referenceActionCreators } from '../reference/referenceActionCreators';
import { rfqDataActionCreators } from '../rfqData/rfqActionCreators';
import { strategyActionCreators } from '../strategy/strategyActionCreators';
import { versionsActionCreators } from '../versions/versionsActionCreators';
import { createActionWithRecompute } from './actionWithRecompute';
import { createBasisChangedAction } from './basisChangedAction';
import { createDefaultAllocationsRequestedAction } from './defaultAllocationsRequestedAction';
import { createDefaultCompositionLegsRequestedAction } from './defaultCompositionLegsRequestedAction';
import { createDefaultConfirmationMediaAndMcaRequestedAction } from './defaultConfirmationMediaAndMcaRequestedAction';
import { createDefaultConfirmationMediaRequestedAction } from './defaultConfirmationMediaRequestedAction';
import { createDefaultElsTypeRequestedAction } from './defaultElsTypeRequestedAction';
import { createDefaultMcaRequestedAction } from './defaultMcaRequestedAction';
import { createDefaultNegotiationModeRequestedAction } from './defaultNegotiationModeRequestedAction';
import { createDefaultNotionalRequestedAction } from './defaultNotionalRequestedAction';
import { createDefaultRfqPreAllocRequestedAction } from './defaultRfqPreAllocRequestedAction';
import { createDefaultRfqRequestedAction } from './defaultRfqRequestedAction';
import { createDefaultTopRequestedAction } from './defaultTopRequestedAction';
import { createDefaultTradeRecapRequestedAction } from './defaultTradeRecapRequestAction';
import { createDefaultUnderlyingRequestedAction } from './defaultUnderlyingRequestedAction';
import { createExecutedSpotChangedAction } from './executedSpotChangedAction';
import { createIbChatRequestedAction } from './ibChatRequestedAction';
import { createIbChatWithClearLevelRequestedAction } from './ibChatWithClearLevelRequestedAction';
import { createLevelChangedAction } from './levelChangedAction';
import { createRecomputeRfqRequestedAction } from './recomputeRfqRequestedAction';
import { createRefAdjustedChangedAction } from './refAdjustedChangedAction';
import { createClientWayChangedAction } from './rfqClientWayChangedAction';
import { createRfqDeltaHedgingAndFairPricesRequestedAction } from './rfqDeltaHedgingAndFairPricesRequestedAction';
import { createRfqDeltaHedgingRequestedAction } from './rfqDeltaHedgingRequestedAction';
import { createRfqFairPricesRequestedAction } from './rfqFairPricesRequestedAction';
import { createRfqQuoteChangedAction } from './rfqMarkupChangedAction';
import {
  createRfqNotionalChangedAction,
  createRfqNotionalCurrencyChangedAction,
  createRfqPivotNotionalChangedAction,
} from './rfqNotionalChangedAction';
import { createRfqStrategyWeightChangedAction } from './rfqStrategyWeightChangedAction';
import { createSpotChangedAction } from './spotChangedAction';

export const rfqActionCreators = {
  ...actorsSetupActionCreators,
  ...rfqDataActionCreators,
  ...strategyActionCreators,
  ...quoteActionCreators,
  ...versionsActionCreators,
  ...fairPriceActionCreators,
  ...forexRatesActionCreators,
  ...referenceActionCreators,
  ...hedgeActionCreators,
  ...counterpartsActionCreators,
  ...extraCounterpartsActionCreators,
  ...preconfirmationEmailPreviewActionsCreators,
  ...transfereeContactsCrudActions,
  createDefaultRfqRequestedAction,
  createIbChatRequestedAction,
  createIbChatWithClearLevelRequestedAction,
  createRfqFairPricesRequestedAction,
  createRfqDeltaHedgingRequestedAction,
  createDefaultUnderlyingRequestedAction,
  createRecomputeRfqRequestedAction,
  createRfqNotionalChangedAction,
  createRfqPivotNotionalChangedAction,
  createRfqStrategyWeightChangedAction,
  createSpotChangedAction,
  createExecutedSpotChangedAction,
  createLevelChangedAction,
  createBasisChangedAction,
  createDefaultNotionalRequestedAction,
  createRfqDeltaHedgingAndFairPricesRequestedAction,
  createClientWayChangedAction,
  createActionWithRecompute,
  createRfqQuoteChangedAction,
  createDefaultRfqPreAllocRequestedAction,
  createRefAdjustedChangedAction,
  createDefaultTopRequestedAction,
  createDefaultElsTypeRequestedAction,
  createRfqNotionalCurrencyChangedAction,
  createDefaultCompositionLegsRequestedAction,
  createDefaultNegotiationModeRequestedAction,
  createDefaultAllocationsRequestedAction,
  createDefaultConfirmationMediaRequestedAction,
  createDefaultConfirmationMediaAndMcaRequestedAction,
  createDefaultMcaRequestedAction,
  createDefaultTradeRecapRequestedAction,
  createTradeRecapMailCCRecipientsAction,
  createDefaultObservableTypeRequestedAction,
  createSearchBrokerNotInPreferencesAction,
};
