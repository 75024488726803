import {
  type CommonProduct,
  type DefaultProductValuesForStrategy,
  type ListedNegotiation,
  type NegotiationMode,
  type OtcNegotiation,
  type Product,
  type SingleUnderlyingDerivative,
  type StrikeDateTenor,
  isDerivativeProduct,
  isFutureLikeProduct,
  isListedProduct,
  isSingleUnderlyingDerivativeProduct,
} from '../../../../../../../../neosModel';

import type { OnyxUnderlying } from '../../../../../../../../neosOnyxModel';
import {
  type CustomUnderlyingProduct,
  isCustomUnderlyingProduct,
} from '../../../../../../../models';
import type { OtcNegotiationFutureProductPart } from '../../../../../product/mappers/partialProductMappers/negotiationMapper';

export function getNegotiationPart(
  currentProduct: Product,
  strategyDefaultNegotiationMode: NegotiationMode | undefined,
): ListedNegotiation | OtcNegotiation {
  return expectedNegotiationIsOtc(currentProduct, strategyDefaultNegotiationMode)
    ? getOtcNegotiationPart()
    : getListedNegotiationPart(currentProduct);
}

export function getFutureNegotiationPart(
  currentProduct: Product,
  strategyDefaultNegotiationMode: NegotiationMode | undefined,
): ListedNegotiation | OtcNegotiationFutureProductPart {
  return expectedNegotiationIsOtc(currentProduct, strategyDefaultNegotiationMode)
    ? { ...getOtcNegotiationPart(), pointValue: 1 }
    : getListedNegotiationPart(currentProduct);
}

function expectedNegotiationIsOtc(
  currentProduct: Product,
  strategyDefaultNegotiationMode: NegotiationMode | undefined,
): boolean {
  const strategyModeIsOtc =
    strategyDefaultNegotiationMode && strategyDefaultNegotiationMode === 'OTC';
  const noStrategyModeAndProductIsOtc =
    !strategyDefaultNegotiationMode && !isListedProduct(currentProduct);

  return strategyModeIsOtc || noStrategyModeAndProductIsOtc;
}

export function getListedNegotiationPart(currentProduct: Product): ListedNegotiation {
  return isListedProduct(currentProduct)
    ? {
        negotiationMode: 'LISTED',
        marketExchangeId: currentProduct.marketExchangeId,
        marketMicCode: currentProduct.marketMicCode,
        refId: currentProduct.refId,
      }
    : {
        negotiationMode: 'LISTED',
        marketExchangeId: undefined,
        refId: undefined,
        marketMicCode: undefined,
      };
}

export function getOtcNegotiationPart(): OtcNegotiation {
  return { negotiationMode: 'OTC' };
}

export function getFutureStrikeDatePart(
  currentProduct: Product,
  {
    strikeDate: defaultStrikeDate,
    strikeTenor: defaultStrikeTenor,
  }: DefaultProductValuesForStrategy,
): StrikeDateTenor {
  const { strikeDate, strikeTenor } = isFutureLikeProduct(currentProduct)
    ? currentProduct
    : { strikeTenor: undefined, strikeDate: undefined };

  return {
    strikeDate: defaultStrikeDate ?? strikeDate,
    strikeTenor: defaultStrikeTenor ?? strikeTenor,
  };
}

export function getCommonPart(
  currentProduct: Product,
  {
    lotSize: defaultLotSize,
    deliveryType: defaultDeliveryType,
    clientTaxRate: defaultClientTaxRate,
    noTaxCollection: defaultNoTaxCollection,
  }: DefaultProductValuesForStrategy,
): CommonProduct {
  const { uuid, legId, lotSize, deliveryType, clientTaxRate, noTaxCollection } = currentProduct;
  return {
    uuid,
    legId,
    lotSize: defaultLotSize ?? lotSize,
    deliveryType: defaultDeliveryType ?? deliveryType,
    noTaxCollection: defaultNoTaxCollection ?? noTaxCollection,
    clientTaxRate: defaultClientTaxRate ?? clientTaxRate,
  };
}

export function getDerivativePart(
  currentProduct: Product,
  strategyDefaultMaturity: string | undefined,
  strategyDefaultUnderlying: OnyxUnderlying | undefined,
): SingleUnderlyingDerivative {
  const { underlyingId, maturity, maturityTenor } = getMaturityAndUnderlyingId(currentProduct);

  return {
    underlyingKind: 'SINGLE',
    isDerivativeProduct: true,
    maturity: strategyDefaultMaturity || maturity,
    underlyingId: strategyDefaultUnderlying ? strategyDefaultUnderlying.id : underlyingId,
    maturityTenor,
  };
}

export function getCustomUnderlyingPart(currentProduct: Product): CustomUnderlyingProduct {
  const { underlyingName, maturity, currency } = getMaturityAndUnderlyingName(currentProduct);

  return {
    hasCustomUnderlying: true,
    maturity,
    underlyingName,
    maturityTenor: undefined,
    currency,
  };
}

function getMaturityAndUnderlyingName(currentProduct: Product) {
  const underlyingName = isCustomUnderlyingProduct(currentProduct)
    ? currentProduct.underlyingName
    : undefined;
  const currency = isCustomUnderlyingProduct(currentProduct) ? currentProduct.currency : undefined;

  const maturity = isCustomUnderlyingProduct(currentProduct) ? currentProduct.maturity : undefined;

  return {
    underlyingName,
    maturity,
    currency,
  };
}

function getMaturityAndUnderlyingId(currentProduct: Product) {
  const underlyingId = isSingleUnderlyingDerivativeProduct(currentProduct)
    ? currentProduct.underlyingId
    : undefined;
  const maturity =
    isDerivativeProduct(currentProduct) || isCustomUnderlyingProduct(currentProduct)
      ? currentProduct.maturity
      : undefined;

  const maturityTenor =
    isDerivativeProduct(currentProduct) || isCustomUnderlyingProduct(currentProduct)
      ? currentProduct.maturityTenor
      : undefined;

  return {
    underlyingId,
    maturity,
    maturityTenor,
  };
}
