import type { OnyxRfq } from '@/neos/business/rfq/rfqOnyxModel';
import type { BlotterModelGetters } from '@/neos/components/share/blotterGrid';
import { lowerCase, uniq, upperFirst } from 'lodash';

export function getObservableType(
  { strategies }: OnyxRfq,
  { twoDimentionalArrayToString }: BlotterModelGetters,
): string | undefined {
  const strategiesLegsProductObservableTypes = strategies.map(strategy =>
    uniq(strategy.legs.map(leg => leg.product?.observableType)),
  );

  return twoDimentionalArrayToString(strategiesLegsProductObservableTypes, v =>
    upperFirst(lowerCase(v)),
  );
}
