import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { PredealCheckWithoutLegsType } from '@/neos/business/predealCheck/predealCheckModel';
import { predealChecksWithoutLegsTypes } from '@/neos/business/predealCheck/predealCheckModel/common';
import type { Broker } from '@/neos/business/referenceData/brokers/brokersModel';

export interface GetRequestPDCBrokerModel {
  broker: Broker | undefined;
  bulletToDisplay: PredealCheckWithoutLegsType[];
}

export function getRequestPDCBrokerModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): Nullable<GetRequestPDCBrokerModel> {
  const broker = selectors.getElsRfqBrokerInfo(state, rfqId, selectors);
  const isElsIdb = selectors.isRfqInitiatedByTrader(state, rfqId);

  if (!isElsIdb) {
    return null;
  }

  const bulletToDisplay = predealChecksWithoutLegsTypes.filter(pdcType => pdcType === 'KYC');

  return {
    broker,
    bulletToDisplay,
  };
}
