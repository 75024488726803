import type { StateMap } from '@/util/collectionHelper';
import type { ErrorableAllUi, ErrorableAllUiRelated, ErrorableField } from '../errorHandlerData';

export const internalGetErrorId = (field: ErrorableAllUi) => (related: StateMap<any>) => {
  return `${field} => ${Object.keys(related)
    .sort()
    .map(relatedKey => `${relatedKey} = ${related[relatedKey]}`)
    .join(' & ')}`;
};

export type ErrorableFieldHandler = {
  [key in ErrorableAllUi]: (relatedTo: ErrorableAllUiRelated[key]) => string;
};

export const getErrorId = new Proxy(
  {},
  {
    get: (_, name) => {
      return internalGetErrorId(name as ErrorableField);
    },
    set: () => {
      throw new Error(`getErrorId values are read only`);
    },
  },
) as ErrorableFieldHandler;
