import { actionCreators } from '@/bootstrap/actions';
import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { type ExtendedDispatch, thunks } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import type { FromFieldUnderlyingChangedParameters } from '@/neos/business/underlyingInfo/underlyingInfoActions';
import { connect } from 'react-redux';
import {
  UnderlyingCellsComponent,
  type UnderlyingCellsDispatchProps,
  type UnderlyingCellsMapStateProps,
  type UnderlyingCellsOwnProps,
} from './UnderlyingCells';
import { getUnderlyingCellsModel } from './getUnderlyingCellsModel';

export const UnderlyingCells = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnderlyingCellsComponent);

function mapStateToProps(
  state: AppState,
  { strategyId, rfqId }: UnderlyingCellsOwnProps,
): UnderlyingCellsMapStateProps {
  return { model: getUnderlyingCellsModel(state, rfqId, strategyId, selectors) };
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId, strategyId }: UnderlyingCellsOwnProps,
): UnderlyingCellsDispatchProps {
  const partialParameter: Pick<FromFieldUnderlyingChangedParameters, 'type' | 'strategyId'> = {
    type: 'FROM_UNDERLYING_FIELD',
    strategyId,
  };
  return {
    onUnderlyingChanged(legId: string, underlyingId: string | undefined) {
      const underlyingChangedParameter: FromFieldUnderlyingChangedParameters = {
        ...partialParameter,
        legId,
        underlyingId,
        ignoreUndefined: true,
      };
      dispatch(
        createLogAnalyticsAction('NEOS RFQ', 'Select underlying', underlyingId),
        neosThunks.createNeosChangeRfqUnderlyingThunk(rfqId, underlyingChangedParameter),
      );
    },
    onUnderlyingNameChanged(legId: string, underlyingName: string | undefined) {
      dispatch(
        createLogAnalyticsAction('NEOS RFQ', 'Select underlying name', underlyingName),
        actionCreators.neos.productCrudActions.update(legId, { underlyingName }),
      );
    },
    onUnderlyingFieldFocused(legId: string) {
      dispatch(neosThunks.createLegFocusedThunk(rfqId, legId));
    },

    onUnderlyingFieldBlurred(legId: string, underlyingId: string | undefined) {
      const underlyingChangedParameter: FromFieldUnderlyingChangedParameters = {
        ...partialParameter,
        legId,
        underlyingId,
        ignoreUndefined: false,
      };
      dispatch(thunks.neos.createNeosBlurProductUnderlyingThunk(rfqId, underlyingChangedParameter));
    },
  };
}
