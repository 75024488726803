import { services } from '@/bootstrap/services';
import type { RfqDifference } from '@/neos/business/neosModel';
import { isPathHiddenFromDifference } from '@/neos/business/services/hiddenFieldInDifferenceService';
import { type Diff, type DiffArray, type DiffDeleted, type DiffEdit, diff } from 'deep-diff';
import { isEqual } from 'lodash';

export function getDifferencesExceptForIrrelevantFields<T>(
  withoutIrrelevant: T,
  withIrrelevant: T,
): Array<DiffDeleted<T> | DiffArray<T> | DiffEdit<T>> {
  const differences = diff(withoutIrrelevant, withIrrelevant) || [];
  return differences.filter(
    (difference): difference is DiffDeleted<T> | DiffArray<T> | DiffEdit<T> => {
      return (
        difference.kind !== 'N' && filterDiffDeleted(difference) && filterDiffEdited(difference)
      );
    },
  );
}

function filterDiffDeleted(difference: Diff<any>): boolean {
  return !(
    difference.kind === 'D' &&
    (difference.lhs === undefined ||
      isEqual(difference.lhs, {}) ||
      isEqual(difference.lhs, []) ||
      difference.lhs === '')
  );
}

function filterDiffEdited(difference: Diff<any>): boolean {
  return !(
    difference.kind === 'E' &&
    difference.lhs === undefined &&
    (isEqual(difference.rhs, {}) || isEqual(difference.rhs, []) || difference.rhs === '')
  );
}

type ClassifiedDifferences = {
  highlightedDifferences: RfqDifference[];
  otherDifferences: RfqDifference[];
};

export function classifyDifferences(differences: RfqDifference[]): ClassifiedDifferences {
  const differencesResult: ClassifiedDifferences = {
    otherDifferences: [],
    highlightedDifferences: [],
  };

  differences.forEach(difference => {
    const fieldMatch = services.getKnownFieldMatch({ field: difference.path });
    if (fieldMatch) {
      difference.path = fieldMatch.description;
      differencesResult.highlightedDifferences.push(difference);
    } else {
      differencesResult.otherDifferences.push(difference);
    }
  });

  return differencesResult;
}

export function filterHiddenDifferences(differences: RfqDifference[]): RfqDifference[] {
  return differences.filter(difference => !isPathHiddenFromDifference(difference.path));
}
