import type { Thunk } from '@/bootstrap/thunks';

export function createRemoveStrategyFeaturesThunk(strategyId: string): Thunk {
  return function removeStrategyFeatures(
    dispatch,
    _,
    {
      actionCreators: {
        neos: { featureCrudActions },
      },
    },
  ) {
    dispatch(featureCrudActions.deleteByPartialKey({ strategyId }));
  };
}
