import type { Thunk } from '@/bootstrap/thunks';
import type { OnyxBookingStep } from './bookingStepOnyxModel';

export function createIntegrateReceivedBookingStepThunk(
  rfqId: string,
  receivedBookingStep: OnyxBookingStep,
): Thunk {
  return function integrateReceivedBookingStepThunk(
    dispatch,
    getState,
    {
      selectors: { tabExists },
      actionCreators: {
        neos: { onyxBookingStepsReceived },
      },
    },
  ) {
    const state = getState();
    const rfqIsOpen = tabExists(state.ui.tabManagement, rfqId);
    if (!rfqIsOpen) {
      return;
    }

    dispatch(onyxBookingStepsReceived({ rfqId, bookingSteps: [receivedBookingStep] }));
  };
}
