export interface MailStatus {
  mailStatus?: MailStatusType;
}
export type MailStatusType = 'SENT' | 'NOT_SENT' | 'MUST_BE_RESENT';

export type PreconfColor = 'warning' | 'success' | 'secondary';

export const mailStatusToColor: Record<MailStatusType, PreconfColor> = {
  SENT: 'success',
  MUST_BE_RESENT: 'warning',
  NOT_SENT: 'secondary',
};
