import type { Thunk } from '@/bootstrap/thunks';
import type { OnyxTransaction } from '../neosOnyxModel';

export function createHandleAutoCloseTabThunk(transaction: OnyxTransaction): Thunk {
  return function handleAutoCloseTabThunk(
    dispatch,
    _,
    {
      thunks: {
        neos: { createCloseTabThunk },
      },
    },
  ) {
    const {
      lifecycle: { status },
      uuid,
    } = transaction.rfq;

    if (status === 'CANCELLED') {
      setTimeout(() => {
        dispatch(createCloseTabThunk(uuid));
      }, 3000);
    }
  };
}
