import type { StrategiesDefinitionsReceivedAction } from './strategyDefinitionActions';
import type { StrategyDefinition } from './strategyDefinitionModel';

export const strategyActionCreators = {
  createStrategiesDefinitionsReceivedAction,
};

export function createStrategiesDefinitionsReceivedAction(
  strategiesDefinitions: StrategyDefinition[],
): StrategiesDefinitionsReceivedAction {
  return {
    type: 'STRATEGIES_DEFINITIONS_RECEIVED',
    strategiesDefinitions,
  };
}
