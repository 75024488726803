import { actionCreators } from '@/bootstrap/actions';
import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { thunks } from '@/bootstrap/thunks';
import { NormalSelectWithUnit } from '@/neos/components/share/NormalSelectWithUnit/NormalSelectWithUnit';
import { LabelWithHelp } from '@/neos/components/share/labelWithHelp/LabelWithHelp';
import { useDispatch } from 'react-redux';
import { getElsProductFields } from './getElsProductFields';

export function LookBackPeriod({
  parentClassNames,
  labelClassNames,
  strategyId,
  rfqId,
}: {
  parentClassNames?: string;
  labelClassNames?: string;
  strategyId: string;
  rfqId: string;
}) {
  const dispatch = useDispatch();
  const { lookbackPeriod, onLookbackPeriodChanged } = useAppSelector(state =>
    getElsProductFields(rfqId, strategyId, state, selectors, actionCreators, thunks, dispatch),
  );
  const isReadonlyAtCurrentWorkflow = useAppSelector(state =>
    selectors.isReadOnlyAtCurrentWorkflow(state, strategyId, selectors),
  );

  return (
    <div className={(parentClassNames ?? '') + ' els-field'} onMouseDown={e => e.stopPropagation()}>
      <LabelWithHelp
        labelClassNames={labelClassNames}
        labelText="Look Back Period"
        helpText="The overnight rate fixing dates are shifted backward, by the specified number of
              business days"
      />

      <NormalSelectWithUnit
        readOnly={isReadonlyAtCurrentWorkflow}
        addEmptyValue
        style={{ minWidth: '130px' }}
        options={[...Array(11).keys()].map(i => ({ label: `${i}`, value: `${i}` }))}
        value={lookbackPeriod?.toString()}
        onChange={n => onLookbackPeriodChanged(n ? Number.parseInt(n) : undefined)}
        data-e2e="rate-bloc-look-back-period"
        unit="BD"
        unitTooltip="Business Days"
      />
    </div>
  );
}
