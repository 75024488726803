import styles from './TruncatedLabel.module.scss';

export interface TruncatedLabelProps {
  label: string;
  className?: string;
}

export const TruncatedLabel = ({ label, className = '' }: TruncatedLabelProps) => (
  <label title={label} className={`${styles['truncated-label']} ${className} `}>
    {label}
  </label>
);
