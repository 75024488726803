import type { FC } from 'react';
import { Crossborder } from '../crossBorder/Crossborder.container';
import { SalesCredit } from './SalesCredit';

export const TotalSalesMargin: FC<{ rfqId: string }> = props => (
  <section
    className="d-grid gap-1 text-nowrap px-2"
    style={{ gridTemplateColumns: '60px fit-content(100%)' }}
  >
    <Crossborder rfqId={props.rfqId} />
    <SalesCredit rfqId={props.rfqId} />
  </section>
);
