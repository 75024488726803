import type { Thunk } from '@/bootstrap/thunks';
import type { OnyxRfq } from '@/neos/business/rfq/rfqOnyxModel';

export function createNeosSaveRfqThunk(rfq: OnyxRfq): Thunk {
  return function neosSaveRfqThunk(
    dispatch,
    getState,
    {
      thunks: {
        createSuccessToasterThunk,
        neos: { createNeosLoadRfqThunk },
      },
      actionCreators: {
        analytics: { createLogAnalyticsAction },
        neos: { createLoadVersionsRequestedAction },
      },
      selectors: { getPendingNotificationRfq },
    },
  ) {
    const state = getState();
    const pendingNotificationRfq = getPendingNotificationRfq(state, rfq.uuid);

    const rfqToLoad: OnyxRfq = pendingNotificationRfq ? pendingNotificationRfq : rfq;

    dispatch(
      createLogAnalyticsAction('NEOS RFQ', 'Rfq save requested'),
      createNeosLoadRfqThunk(rfqToLoad),
      createLoadVersionsRequestedAction(rfqToLoad.uuid),
      createSuccessToasterThunk({
        message: `RFQ saved successfully! Version: ${rfqToLoad.version}`,
      }),
    );
  };
}
