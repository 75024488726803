declare global {
  type BusEventCallback<T> = (payload: T | undefined) => void;
  type SubscriptionHandle = any;

  interface IWidgetBus {
    dangerouslyGetCurrentValue<T>(topicName: string): T | undefined;
    subscribe<T>(topicName: string, cb: BusEventCallback<T>): SubscriptionHandle;
    unsubscribe(handle: SubscriptionHandle): void;
  }
}

export const BUS_SG_DESIGN_SYSTEM_THEME = 'sg-design-system.theme';

export type SG_DESIGN_SYSTEM_THEME = 'standard' | 'dark';

export function getWidgetBus(): IWidgetBus {
  const widgetConfiguration = (window as any).SGWTWidgetConfiguration;
  return widgetConfiguration && widgetConfiguration.bus ? widgetConfiguration.bus : null;
}
