import { sortBy } from 'lodash';

import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import type {
  DeltaStockListedAllocation,
  DeltaStockListedAllocationKey,
} from '@/neos/business/rfq/strategy/leg/deltaStockListedAllocation/deltaStockListedAllocationModel';
import { neosThunks } from '@/neos/business/thunks';
import { NeosBookingId } from '@/neos/components/share/NeosBookingId/NeosBookingId';
import { NeosBlurInput } from '@/neos/components/share/blurComponent/NeosBlurInput';
import { NeosSelectWithAutocomplete } from '@/neos/components/share/neosSelectWithAutocomplete/NeosSelectWithAutocomplete';
import { NumericInput } from '@/neos/components/share/numericInput';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import { useDispatch } from 'react-redux';
import { DeltaStockAllocationPrice } from './DeltaStockAllocationPrice';
import styles from './DeltaStockAllocations.module.scss';
import { getDeltaStockAllocationModel } from './getDeltaStockAllocationModel';

export interface DeltaStockAllocationProps {
  rfqId: string;
  legId: string;
  strategyId: string;
  allocation: DeltaStockListedAllocation;
}

export const DeltaStockAllocation = ({
  rfqId,
  legId,
  allocation,
  strategyId,
}: DeltaStockAllocationProps) => {
  const dispatch = useDispatch();
  const availableClients = useAppSelector(state =>
    sortBy(selectors.getCounterparts(state, rfqId) ?? [], c => c.name),
  );
  const extraClients = useAppSelector(
    state => selectors.getAllocExtraCounterparts(state, rfqId) ?? [],
  );

  const { allocationBookingId, defaultAllocationBookingId, extractedStatus } = useAppSelector(
    state =>
      getDeltaStockAllocationModel(state, { rfqId, strategyId, legId, allocation }, selectors),
  );

  const clientWarning = !!extraClients.find(client => client.id === allocation.counterpartId);
  const allClients = [...availableClients, ...extraClients];

  const deltaStockListedAllocationKey: DeltaStockListedAllocationKey = {
    allocationId: allocation.uuid,
    legId,
    rfqId,
  };

  function onAllocationCancel() {
    dispatch(
      neosThunks.createDeleteDeltaStockListedAllocationThunk(
        deltaStockListedAllocationKey,
        allocationBookingId,
      ),
    );
  }

  function onCounterpartChanged(value: string | undefined) {
    const counterpartId = value ? parseInt(value, 10) : undefined;
    dispatch(
      neosActionCreators.deltaStockListedAllocationCrudActions.update(
        deltaStockListedAllocationKey,
        {
          legId,
          uuid: allocation.uuid,
          counterpartId,
        },
      ),
    );
  }

  function onAllocatedSizeChanged(numberOfLots: number | undefined) {
    dispatch(
      neosActionCreators.deltaStockListedAllocationCrudActions.update(
        deltaStockListedAllocationKey,
        {
          legId,
          uuid: allocation.uuid,
          numberOfLots,
        },
      ),
    );
  }

  function onCommentChanged(comment: string) {
    dispatch(
      neosActionCreators.deltaStockListedAllocationCrudActions.update(
        deltaStockListedAllocationKey,
        {
          legId,
          uuid: allocation.uuid,
          comment,
        },
      ),
    );
  }

  function onDeltaStockAllocationBookingIdChanged(bookingId: string | undefined) {
    dispatch(
      neosActionCreators.deltaStockListedAllocationCrudActions.update(
        deltaStockListedAllocationKey,
        {
          legId,
          uuid: allocation.uuid,
          externalReferenceId: bookingId ? { id: bookingId, application: 'XONE' } : undefined,
        },
      ),
    );
  }

  return (
    <div className={`${styles['alloc-info']}`} key={allocation.uuid}>
      <button
        className="btn btn-icon btn-flat-primary"
        onClick={onAllocationCancel}
        data-e2e="remove-deltaStock-alloc"
      >
        <i className="icon icon-md">{allocation.isCancelled ? 'clear' : 'delete_forever'}</i>
      </button>

      <SimpleNeosTooltip
        disable={!clientWarning}
        type="warning"
        message="This counterparty is not in your client's portfolio"
        id="extra-client-tooltip"
      >
        <div>
          <NeosSelectWithAutocomplete
            classes={`${clientWarning ? styles['client-warning'] : ''}`}
            isLabelBold
            data-e2e="deltaStock-alloc-counterpart"
            value={allocation.counterpartId?.toString()}
            onChange={selected => onCounterpartChanged(selected?.value)}
            addEmptyOption
            options={allClients.map(({ id, name, eliotCode, mnemo }) => ({
              value: id.toString(),
              label: name,
              data: [eliotCode, mnemo],
            }))}
          />
        </div>
      </SimpleNeosTooltip>
      <SimpleNeosTooltip id={`alloc-status-tooltip`} message="Allocated Size">
        <NumericInput
          value={allocation.numberOfLots}
          onBlur={onAllocatedSizeChanged}
          disableAccelerators
          onlyPositiveNumbers
          data-e2e={`deltaStock-alloc-qty`}
        />
      </SimpleNeosTooltip>
      <DeltaStockAllocationPrice legId={legId} />
      <NeosBookingId
        defaultBookingId={defaultAllocationBookingId}
        bookingId={allocationBookingId}
        bookingStatus={extractedStatus}
        dataE2e="deltaStock-alloc-booking-id"
        onBookingIdChanged={onDeltaStockAllocationBookingIdChanged}
      />
      <SimpleNeosTooltip message="Comment">
        <NeosBlurInput
          data-e2e="deltaStock-alloc-comment"
          value={allocation.comment}
          onBlur={e => onCommentChanged(e.target.value)}
        />
      </SimpleNeosTooltip>
    </div>
  );
};
