import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { createRfqDefaultTenorMaturityThunk } from '@/neos/business/rfq/thunks/rfqDefaultTenorMaturity';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import type {
  DeterminationMethod,
  ForwardStartDateFeatureKey,
} from '../../../../../../business/neosModel';
import {
  type DispatchProps,
  ForwardStartDateStyled,
  type OwnProps,
  type StateProps,
} from './ForwardStart';
import { getForwardStartModel } from './getForwardStartModel';

function mapStateToProps(appState: AppState, { strategyId }: OwnProps): StateProps {
  return { model: getForwardStartModel(appState, strategyId, selectors) };
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId, strategyId }: OwnProps,
): DispatchProps {
  const featureKey: ForwardStartDateFeatureKey = { strategyId, type: 'FORWARD_START' };

  return {
    onForwardStartDateChanged: (forwardStartDate: string | undefined) => {
      dispatch(neosThunks.createChangeForwardStartDate(rfqId, featureKey, forwardStartDate));
    },
    onDeterminationMethodChanged: (determinationMethod: DeterminationMethod | undefined) => {
      dispatch(neosThunks.createChangeFeatureFieldThunk(featureKey, { determinationMethod }));
    },
    onTenorChanged(legId: string, tenor: string | undefined) {
      dispatch(
        createRfqDefaultTenorMaturityThunk(rfqId, strategyId, legId, tenor, 'FORWARD_START_DATE'),
      );
      dispatch(createLogAnalyticsAction('NEOS RFQ', 'Select Tenor', tenor));
    },
  };
}

export const ForwardStart = connect(mapStateToProps, mapDispatchToProps)(ForwardStartDateStyled);
