import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { OnyxRfq } from '../../neosOnyxModel';
import type { RequestSimilarActivitiesApi } from '../epics/requestSimilarActivitiesEpic';

export const createRequestSimilarActivitiesApi = (http: SgmeHttp): RequestSimilarActivitiesApi => ({
  requestSimilarActivities(rfq: OnyxRfq) {
    return http.post<OnyxRfq[]>({
      url: `api/rfq/similar/search`,
      body: rfq,
    });
  },
});
