import { actionCreators } from '@/bootstrap/actions';
import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

type ReGeneratePreconfsButtonProps = {
  rfqId: string;
};

export const ReGeneratePreconfsButton: FC<ReGeneratePreconfsButtonProps> = ({ rfqId }) => {
  const dispatch = useDispatch();

  const rfqPreConfSteps = useSelector((state: AppState) =>
    selectors.preConfirmationStepSelectors.selectObjects(state.preConfirmationStep, {
      rfqId,
    }),
  );

  const isDisplayed = rfqPreConfSteps.length;

  if (!isDisplayed) {
    return null;
  }

  return (
    <button
      className="btn btn-primary"
      data-testid="regenerate-preconfs-button"
      onClick={() => dispatch(actionCreators.neos.createGeneratePreconfsAction(rfqId))}
    >
      Regenerate Pre Conf
      <i className="icon icon-sm ms-2">cached</i>
    </button>
  );
};
