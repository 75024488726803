import { hasDiscriminator } from '@/neos/business/rfq/strategy/feature/hasDiscriminator';
import type { WithExtraProperty } from '@/neos/business/rfq/strategy/feature/withExtraProperty';
import type { ForwardStartFeature } from '../../../../../../neos/business/neosModel';
import type {
  OnyxForwardStart,
  OnyxProductFeaturesFields,
} from '../../../../../../neos/business/neosOnyxModel';

export function mapFromOnyxFeatureOrExtraFeaturesToForwardStart(
  { extraFeatures, forwardStartDate, determinationMethod }: OnyxProductFeaturesFields,
  strategyId: string,
): ForwardStartFeature | undefined {
  const mappedForwardStart: Pick<ForwardStartFeature, 'strategyId' | 'type'> = {
    strategyId,
    type: 'FORWARD_START',
  };
  if (forwardStartDate) {
    return {
      ...mappedForwardStart,
      forwardStartDate: forwardStartDate ?? undefined,
      determinationMethod: determinationMethod ?? undefined,
    };
  }

  if (extraFeatures) {
    const forwardStartFeature: OnyxForwardStart | undefined = extraFeatures.find(
      hasDiscriminator<OnyxForwardStart>('FORWARD_START'),
    );
    if (forwardStartFeature) {
      return {
        ...mappedForwardStart,
        forwardStartDate: forwardStartFeature.forwardStartDate ?? undefined,
        determinationMethod: forwardStartFeature.determinationMethod ?? undefined,
      };
    }
  }

  return undefined;
}

export function mapToOnyxForwardStart(
  feature: WithExtraProperty<ForwardStartFeature>,
): OnyxProductFeaturesFields {
  return feature.isExtra
    ? {
        extraFeatures: [
          {
            discriminator: 'FORWARD_START',
            forwardStartDate: feature.forwardStartDate,
            forwardStartTenor: feature.forwardStartTenor,
            determinationMethod: feature.determinationMethod,
          },
        ],
      }
    : {
        forwardStartDate: feature.forwardStartDate,
        forwardStartTenor: feature.forwardStartTenor,
        determinationMethod: feature.determinationMethod,
      };
}
