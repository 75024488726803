import type { Dispatchable, Thunk } from '@/bootstrap/thunks';
import type { RateLegPeriodDates } from '../rfq/strategy/leg/product/elsProductOnyxModel';
import { scheduleRateDatesWtihoutOvernightSchema } from '@/neos/business/thunks/scheduleImportedKeysValidation.ts';
import { fromError } from 'zod-validation-error';
import { createErrorToasterThunk } from '@/bootstrap/toasterThunks/thunks.ts';
import { mapFromScheduleImportedData } from '@/neos/business/thunks/mapFromScheduleImportedData.ts';
import { RawImportedDataSchema } from '@/util/excel/excel.ts';

export function createClsImportScheduleDataThunk(
  strategyId: string,
  importedData: unknown[],
  from: 'clipboard' | 'file',
): Thunk {
  return function clsImportScheduleDataThunk(
    dispatch,
    getState,
    { selectors, thunks, actionCreators },
  ) {
    const state = getState();
    const excelDateFormat = selectors.selectExcelDateFormat(state.ui.userPreferences);
    const dispatchables: Dispatchable[] = [];

    dispatch(
      actionCreators.neos.strategyUiCrudActions.update(strategyId, {
        showImportScheduleError: false,
      }),
    );
    dispatchables.push(thunks.neos.createRemoveAllRateSchedulePeriodThunk(strategyId));

    const rawParsingResult = RawImportedDataSchema.safeParse(importedData);

    if (!rawParsingResult.success) {
      const zodError = fromError(rawParsingResult.error).toString();
      // eslint-disable-next-line no-console
      console.error(zodError);
      dispatch(
        createErrorToasterThunk(
          {
            message: zodError,
          },
          undefined,
        ),
      );
      return;
    }

    const neosMappedImportedData = mapFromScheduleImportedData(
      rawParsingResult.data,
      excelDateFormat,
      from,
    );
    try {
      neosMappedImportedData.forEach((line, index) => {
        const rateDates: Partial<RateLegPeriodDates> = {
          fixingDate: line.rateFixingDate,
          startDate: line.rateStartDate,
          endDate: line.rateEndDate,
          paymentDate: line.ratePayDate,
        };

        const rateDatesValidationResult =
          scheduleRateDatesWtihoutOvernightSchema.safeParse(rateDates);

        if (!rateDatesValidationResult.success) {
          const zodError = fromError(rateDatesValidationResult.error, {
            prefix: `Error trying to import rate dates at line ${index + 1}, make sure format match your user preferences`,
          }).toString();
          throw new Error(zodError);
        }

        const rateDatesSchedule: RateLegPeriodDates = {
          startDate: rateDatesValidationResult.data.startDate ?? '',
          endDate: rateDatesValidationResult.data.endDate ?? '',
          paymentDate: rateDatesValidationResult.data.paymentDate ?? '',
          fixingDate: rateDatesValidationResult.data.fixingDate ?? '',
        };

        dispatchables.push(
          thunks.neos.createAddRateSchedulePeriodThunk(strategyId, rateDatesSchedule),
        );
      });
      dispatch(dispatchables);
    } catch (e: unknown) {
      if (e instanceof Error) {
        // eslint-disable-next-line no-console
        console.error(e.message);
        dispatch(
          thunks.createErrorToasterThunk(
            {
              message: e.message,
            },
            undefined,
          ),
        );
      }
    }
  };
}
