import { ErrorHighlight } from '@/neos/components/share/errorHighlight';
import { NumericInput } from '@/neos/components/share/numericInput';
import { times } from 'lodash';
import { Fragment } from 'react';
import styles from './RfqPremium.module.scss';
import type { PricesType, RfqPremiumModel } from './getRfqPremiumModel';

export interface RfqPremiumOwnProps {
  rfqId: string;
}

export type RfqPremiumPropsFromState = RfqPremiumModel;

export interface RfqPremiumDispatchProps {
  onTotalBidChanged: (bid: number | undefined, priceType: PricesType) => void;
  onTotalAskChanged: (ask: number | undefined, priceType: PricesType) => void;
  onTotalPtmmmChanged: (ptmmm: number | undefined) => void;
  onReverseYes: () => void;
  onReverseNo: () => void;
}

type RfqPremiumProps = RfqPremiumOwnProps & RfqPremiumPropsFromState & RfqPremiumDispatchProps;

export const RfqPremiumStyled = (props: RfqPremiumProps) => {
  const midColumnsCount =
    (props.arePtmmmDisplayed ? 1 : 0) + (props.areFairPricesDisplayed ? 1 : 0);
  const spacer = times(midColumnsCount, i => <div key={i} />);
  const isTotalDisplayed = props.total.unit && props.aggBidAsk.length > 1;

  return (
    <div className="d-flex flex-column align-items-center pe-1">
      <label className="fw-bold">RFQ Premium</label>
      <div
        style={{
          gridTemplateColumns: `${isTotalDisplayed ? '40px' : ''} repeat(${
            2 + midColumnsCount
          }, minmax(110px, 250px))`,
        }}
        className={`${styles['rfq-premium']} gap-1 align-items-center`}
      >
        {isTotalDisplayed && <div></div>}
        <label>{props.pricesType} Bid</label>
        {props.arePtmmmDisplayed && <label>PTMM</label>}
        {props.areFairPricesDisplayed && <label>Fair Price</label>}
        <label>{props.pricesType} Ask</label>

        {isTotalDisplayed && <label>Total</label>}

        <ErrorHighlight errorField={'rfqTraderPriceBid'} related={{ rfqId: props.rfqId }}>
          <NumericInput
            color="BID"
            className="errorable-bloc"
            inputClassName="fw-bold"
            unit={props.total.unit}
            value={props.total.bid}
            onBlur={val => props.onTotalBidChanged(val, props.pricesType)}
            readOnly={props.hasMultipleCurrencies}
            data-e2e="neos-rfq-premium-bid"
          />
        </ErrorHighlight>
        {props.arePtmmmDisplayed && (
          <NumericInput
            value={props.total.mid?.ptmmm}
            withMaximumNumberOfFloatingDigits={4}
            readOnly={false}
            onBlur={value => props.onTotalPtmmmChanged(value)}
            data-e2e="neos-rfq-premium-ptmmm"
          />
        )}
        {props.areFairPricesDisplayed && (
          <NumericInput
            value={props.total.mid?.mid}
            withMaximumNumberOfFloatingDigits={2}
            readOnly={true}
            data-e2e="neos-rfq-premium-fair-price"
          />
        )}
        <ErrorHighlight errorField={'rfqTraderPriceAsk'} related={{ rfqId: props.rfqId }}>
          <NumericInput
            color="ASK"
            inputClassName="fw-bold"
            className="errorable-bloc"
            value={props.total.ask}
            onBlur={val => props.onTotalAskChanged(val, props.pricesType)}
            readOnly={props.hasMultipleCurrencies}
            data-e2e="neos-rfq-premium-ask"
          />
        </ErrorHighlight>

        {props.hasMultipleCurrencies &&
          props.aggBidAsk.map(aggBidAsk => (
            <Fragment key={aggBidAsk.unit}>
              {isTotalDisplayed && <div></div>}
              <NumericInput
                className={`${aggBidAsk.bidClassName}`}
                unit={aggBidAsk.unit}
                value={aggBidAsk.bid}
                readOnly
              />
              {spacer}
              <NumericInput
                className={`${aggBidAsk.askClassName}`}
                value={aggBidAsk.ask}
                readOnly
              />
            </Fragment>
          ))}

        {props.isReverseEnabled && (
          <div className={`${styles['alert-container']}`} data-e2e="neos-alert-reverse">
            {props.isMdpRfq ? (
              <div role="alert" className="alert alert-warning d-flex p-1 align-items-center fs-6">
                The global RFQ mid price is negative
              </div>
            ) : (
              <div role="alert" className="alert alert-warning d-flex p-1 align-items-center fs-6">
                The global RFQ mid price is negative, do you want to reverse it?
                <div className="d-flex ms-2 gap-1">
                  <button
                    className="btn btn-sm btn-outline-primary-alt"
                    onClick={props.onReverseYes}
                  >
                    Yes
                  </button>
                  <button
                    className="btn btn-sm btn-outline-primary-alt"
                    onClick={props.onReverseNo}
                  >
                    No
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
