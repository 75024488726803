import { HelpRequestButton } from '@/common/components/helpRequest/helpRequestButton';
import { AlertMenu } from '@/neos/components/alert/AlertsMenu.container';
import { BrowserNotificationCloseIcon } from '@/neos/components/browserNotificationCloseIcon';
import { UserPreferencesMenu } from '@/neos/components/userPreferences/UserPreferencesMenu';
import {
  BUS_SG_DESIGN_SYSTEM_THEME,
  getWidgetBus,
  type SG_DESIGN_SYSTEM_THEME,
} from '@/util/widget-bus';
import { useEffect } from 'react';
import { AccountCenter } from '../accountCenter/AccountCenter';
import { EnrichedSgLogo } from './EnrichedSgLogo';

export const NavHeader = () => {
  useEffect(() => {
    // Subscribe to Bus events to synchronize the theme.
    const bus = getWidgetBus();
    const themeSubscription = bus.subscribe<SG_DESIGN_SYSTEM_THEME>(
      BUS_SG_DESIGN_SYSTEM_THEME,
      (theme: SG_DESIGN_SYSTEM_THEME | undefined) => {
        if (theme) {
          const from = theme === 'dark' ? 'standard' : 'dark';
          const bootstrapLink = document.querySelector(
            `link[href*="core/css/sg-bootstrap-${from}.min.css"]`,
          );
          const agGridLink = document.querySelector(
            `link[href*="ag-grid-theme/29-${from}-condensed.min.css"]`,
          );
          if (bootstrapLink) {
            const href = bootstrapLink.getAttribute('href');
            if (href) {
              bootstrapLink.setAttribute('href', href.replace(from, theme));
            }
          }
          if (agGridLink) {
            const href = agGridLink.getAttribute('href');
            if (href) {
              agGridLink.setAttribute('href', href.replace(from, theme));
            }
          }
        }
      },
    );

    return () => {
      // Unsubscribe to Bus events.
      bus.unsubscribe(themeSubscription);
    };
  }, []);

  return (
    <nav className="navbar navbar-sm border-top">
      <div className="navbar-title">
        <EnrichedSgLogo />
      </div>
      <div className="navbar-content"></div>
      <div className="navbar-toolbar">
        <AlertMenu />
        <BrowserNotificationCloseIcon />
        <HelpRequestButton />
        <UserPreferencesMenu />
        <AccountCenter />
      </div>
    </nav>
  );
};
