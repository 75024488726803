import { hasDiscriminator } from '@/neos/business/rfq/strategy/feature/hasDiscriminator';
import type { WithExtraProperty } from '@/neos/business/rfq/strategy/feature/withExtraProperty';
import { convertNullToUndefined } from '@/util/undefinedAndNull/convertNullToUndefined';
import type { RateBulletFeature } from '../../../../neosModel';
import type { OnyxProductFeaturesFields, OnyxRateBullet } from '../../../../neosOnyxModel';

export function mapFromOnyxFeatureOrExtraFeaturesToRateBulletFeature(
  { extraFeatures, rateBullet: definitionRateBullet }: OnyxProductFeaturesFields,
  strategyId: string,
): RateBulletFeature | undefined {
  const mappedEls: Pick<RateBulletFeature, 'strategyId' | 'type'> = {
    strategyId,
    type: 'RATE_BULLET',
  };
  const rateBullet = convertNullToUndefined(definitionRateBullet);
  if (rateBullet !== undefined) {
    return {
      ...mappedEls,
      rateBullet: !!rateBullet,
    };
  }

  if (extraFeatures) {
    const rateBulletFeature = extraFeatures.find(hasDiscriminator<OnyxRateBullet>('RATE_BULLET'));

    if (rateBulletFeature) {
      return {
        ...mappedEls,
        rateBullet: !!(rateBulletFeature && rateBulletFeature.rateBullet),
      };
    }
  }

  return undefined;
}

export function mapToOnyxRateBulletFeature(
  feature: WithExtraProperty<RateBulletFeature>,
): OnyxProductFeaturesFields {
  const { rateBullet } = feature;

  if (!feature.isExtra) {
    return {
      rateBullet,
      extraFeatures: [],
    };
  }
  const onyxRateBullet: OnyxRateBullet = { discriminator: 'RATE_BULLET', rateBullet };
  return {
    extraFeatures: [onyxRateBullet],
  };
}
