import type { Action } from '@/bootstrap/actions';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import type { Middleware } from 'redux';
import { LogAnalytic } from './logger';

export function createAnalyticMiddleware() {
  if (analyticMiddlewareMightBeCreated()) {
    return analyticMiddlewareBuilder(new LogAnalytic(sgmeConfiguration.piwik));
  }
  return undefined;
}

function analyticMiddlewareMightBeCreated() {
  return (
    sgmeConfiguration.piwik.piwikRootUrl &&
    !sgmeConfiguration.piwik.piwikRootUrl.startsWith('{{') &&
    sgmeConfiguration.piwik.siteId
  );
}

export function analyticMiddlewareBuilder(logger: LogAnalytic): Middleware {
  const logRecursively = logRecursivelyFactory(logger);
  return () => (next: ExtendedDispatch) => (action: Action) => {
    logRecursively(action);
    return next(action);
  };
}

export function logRecursivelyFactory(logger: LogAnalytic) {
  return function logRecursively(action: Action): void {
    switch (action.type) {
      case 'USER_INFO_RECEIVED': {
        logger.initialize(action.userInfo.currentUser.login);
        break;
      }
      case 'ANALYTICS_LOGGING_REQUESTED': {
        const { category, name } = action;
        logger.log(category, name);
        break;
      }
      default:
        break;
    }
  };
}
