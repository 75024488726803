import type { Selectors } from '@/bootstrap/selectors';
import type { Services } from '@/bootstrap/services';
import type { AppState } from '@/bootstrap/state';
import type { TimersStateProps } from './Timers';

export function getTimersModel(
  state: AppState,
  rfqId: string,
  { getRfqData }: Selectors,
  { orderOf }: Services,
): TimersStateProps {
  const { hasQuoteExpirationTime, status } = getRfqData(state, rfqId);
  const isMdpQuoteTimerDisplayForStatus = orderOf(status).isBetween(
    'PRICE_REQUESTED',
    'ORDER_RECEIVED',
  );

  const isMdpQuoteTimerDisplay = !!hasQuoteExpirationTime && isMdpQuoteTimerDisplayForStatus;
  return {
    isMdpQuoteTimerDisplay,
  };
}
