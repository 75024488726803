import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import type { ExternalMailOptions } from '@/neos/business/preconfirmation/external/externalOnyxPreconfirmationModel';
import { ErrorHighlight } from '@/neos/components/share/errorHighlight';
import { useFeatureToggle } from '@/neos/components/share/featureToggle/useFeatureToggle';
import {
  NeosSelectWithAutocomplete,
  type Option,
} from '@/neos/components/share/neosSelectWithAutocomplete/NeosSelectWithAutocomplete';
import { cn } from '@/util/classNames';
import type { Contact } from '../../../../../../business/neosModel';
import { NeosBlurInput } from '../../../../../share/blurComponent/NeosBlurInput';
import { NeosBlurTextarea } from '../../../../../share/blurComponent/NeosBlurTextarea';

export interface ExternalPreconfirmationBookingOptionsOwnProps {
  rfqId: string;
}

export interface PreconfirmationBookingOptionsStateProps {
  bookingMailOptions: ExternalMailOptions | undefined;
  availableContacts: Option<Contact>[];
  otherFieldIsInvalid: boolean;
}

export interface ExternalPreconfirmationBookingOptionsDispatchProps {
  onContactSelectOption(contact: Contact | undefined): void;

  onMiddleSelectOption(contact: Contact | undefined): void;

  onBackSelectOption(contact: Contact | undefined): void;

  onOtherValueChange(email: string | undefined): void;

  onCommentSelected(comment: string | undefined): void;
}

export type ExternalPreconfirmationBookingOptionsProps = PreconfirmationBookingOptionsStateProps &
  ExternalPreconfirmationBookingOptionsOwnProps &
  ExternalPreconfirmationBookingOptionsDispatchProps;

export const ExternalPreconfirmationBookingOptionsComponent = ({
  bookingMailOptions: mailOptions,
  availableContacts,
  otherFieldIsInvalid,
  rfqId,
  onContactSelectOption,
  onMiddleSelectOption,
  onBackSelectOption,
  onOtherValueChange,
  onCommentSelected,
}: ExternalPreconfirmationBookingOptionsProps) => {
  const labelClass = 'my-auto col-sm-3 col-lg-4 fw-bold';
  const inputClass = 'col-sm-9 col-lg-8';
  const isOtcPreconfEnabled = useFeatureToggle('neos.otc.preconf.enabled');
  const isMasterStrategyOtc = useAppSelector(state =>
    selectors.isMasterStrategyOtc(state, rfqId, selectors),
  );
  const isOtcAndEnabled = isMasterStrategyOtc && isOtcPreconfEnabled;

  return (
    <>
      <h5 className="mb-3">Client Addresses</h5>
      <div className="container">
        <div className="row mb-2">
          <div className={labelClass}>Contact</div>
          <div className={inputClass}>
            <ErrorHighlight errorField="preConfExternalClientContact" related={{ rfqId }}>
              <NeosSelectWithAutocomplete<Contact>
                addEmptyOption
                options={availableContacts}
                value={mailOptions?.clientContact?.id ?? ''}
                data-e2e="preconfirmation-contact"
                onChange={selectedOption => onContactSelectOption(selectedOption?.tag || undefined)}
              />
            </ErrorHighlight>
          </div>
        </div>
        <div className="row mb-2">
          <div className={labelClass}>Middle</div>
          <div className={inputClass}>
            <NeosSelectWithAutocomplete<Contact>
              addEmptyOption
              options={availableContacts}
              value={mailOptions?.clientMiddle?.id ?? ''}
              data-e2e="preconfirmation-middle"
              onChange={selectedOption => onMiddleSelectOption(selectedOption?.tag || undefined)}
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className={labelClass}>Back</div>
          <div className={inputClass}>
            <NeosSelectWithAutocomplete<Contact>
              addEmptyOption
              options={availableContacts}
              value={mailOptions?.clientBack?.id ?? ''}
              data-e2e="preconfirmation-back"
              onChange={selectedOption => onBackSelectOption(selectedOption?.tag || undefined)}
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className={labelClass}>Other</div>
          <div className={inputClass}>
            <NeosBlurInput
              className={cn({ 'is-invalid': otherFieldIsInvalid })}
              value={mailOptions?.clientOther ?? ''}
              data-e2e="preconfirmation-other"
              onBlur={e => onOtherValueChange(e.target.value || undefined)}
            />
            {otherFieldIsInvalid && (
              <span className="invalid-feedback">
                <em className="icon">close</em> Invalid mail(s)
              </span>
            )}
          </div>
        </div>
        <div className="row mt-4">
          {!isOtcAndEnabled && (
            <div className="w-100 fst-italic text-danger fw-medium">
              Under MIFID || Article 59 and when commissions are charged to clients, all
              pre-confirmations on listed derivatives transactions must be sent to clients by the
              end of T+1 with all the relevant informations set in NEOS.
            </div>
          )}
        </div>
        <div className="row mt-4">
          <div className="w-100 fw-bold">Comment</div>
        </div>
        <div className="row">
          <div className="w-100">
            <NeosBlurTextarea
              className="form-control"
              rows={6}
              value={mailOptions?.emailComment ?? ''}
              data-e2e="preconfirmation-comment"
              onChange={e => onCommentSelected(e.target.value || undefined)}
            />
          </div>
        </div>
      </div>
    </>
  );
};
