import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { isElsProduct } from '../../../../../../business/neosModel';

export type SettlementDateCellsModel = {
  settlementDate: string | undefined;
  settlementDateOffset: number | undefined;
  isDisplayed: boolean;
  legId: string;
};

export function getSettlementDateCellsModel(
  state: AppState,
  strategyId: string,
  selectors: Selectors,
): SettlementDateCellsModel {
  const isFeatureEnabled = selectors.isFeatureToggleEnabled(
    state,
    'neos.cls.settlement.value.date.enabled',
  );

  const legId = selectors.getStrategyMasterLeg(state, strategyId, selectors).uuid;
  const settlementDate = selectors.getLegData(state, legId)?.settlement?.date;

  const product = selectors.getStrategyMasterProduct(state, strategyId, selectors);
  const isDisplayed = isElsProduct(product) && isFeatureEnabled;

  return {
    settlementDate: isDisplayed ? settlementDate?.value : undefined,
    settlementDateOffset: isDisplayed ? settlementDate?.offset : undefined,
    isDisplayed,
    legId,
  };
}
