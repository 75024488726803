import { isDefined } from '@/util/undefinedAndNull/isDefined';
import { Component, type ComponentType } from 'react';
import { type ConstraintFunction, constraintFunctions } from './constraintFunctions';

interface Props {
  value?: number;
  onBlur?: (value: number | undefined) => void;
}

interface ConstraintApplicationProps {
  withMaximumNumberOfFloatingDigits?: number;
}

interface State {
  propsValue?: number;
  value?: number;
}

export function withConstraintApplication<P extends Props>(
  CustomComponent: ComponentType<P>,
): ComponentType<P & ConstraintApplicationProps> {
  return class ComponentWithConstraintApplication extends Component<
    P & ConstraintApplicationProps,
    State
  > {
    public static getDerivedStateFromProps = (
      props: P & ConstraintApplicationProps,
      state: State,
    ): State | null => {
      if (props.value !== state.propsValue) {
        const { value: _, onBlur, ...restProps } = props;
        const result: number | undefined =
          props.value === undefined ? props.value : applyConstraints(props.value, restProps);

        return {
          ...state,
          propsValue: props.value,
          value: result,
        };
      }
      return null;
    };

    constructor(props: P & ConstraintApplicationProps) {
      super(props);
      const { value: _, onBlur, ...restProps } = this.props;
      const result: number | undefined =
        props.value === undefined ? props.value : applyConstraints(props.value, restProps);
      this.state = {
        propsValue: props.value,
        value: result,
      };
    }

    private onWrappedComponentBlur = (value: number) => {
      const { value: _, onBlur, ...restProps } = this.props;
      const result: number = applyConstraints(value, restProps);
      this.setState({
        ...this.state,
        value: result,
      });
      if (this.props.onBlur) {
        this.props.onBlur(result);
      }
    };

    public render() {
      const { value, onBlur, withMaximumNumberOfFloatingDigits, ...restProps } = this.props;
      return (
        <CustomComponent
          {...(restProps as P)}
          value={this.state.value}
          onBlur={this.onWrappedComponentBlur}
        />
      );
    }
  };
}

function applyConstraints(value: number, props: ConstraintApplicationProps): number {
  const applicableConstraints: ConstraintFunction[] = getApplicableConstraints(props);
  return applicableConstraints.reduce(combineConstraintResults, value);
}

function getApplicableConstraints(props: ConstraintApplicationProps): ConstraintFunction[] {
  return (Object.keys(constraintFunctions) as Array<keyof ConstraintApplicationProps>)
    .map((key: keyof ConstraintApplicationProps) => {
      return props[key] !== undefined ? constraintFunctions[key](props[key]) : undefined;
    })
    .filter(isDefined);
}

function combineConstraintResults(
  accumulatedResult: number,
  constraintFunction: ConstraintFunction,
): number {
  return constraintFunction(accumulatedResult);
}
