import type { Thunk } from '@/bootstrap/thunks';
import type { MarginRules } from '../../../../neos/business/neosModel';
import { LegRecomputeScope } from '../models/recomputeModel';

export function createChangeLegQuoteMarkupRuleThunk(
  rfqId: string,
  quoteId: string,
  rule: MarginRules | undefined,
): Thunk {
  return function changeLegMarkupRuleThunk(
    dispatch,
    _,
    {
      actionCreators: {
        neos: { quoteCrudActions, createActionWithRecompute },
      },
    },
  ) {
    const patchAction = quoteCrudActions.update(quoteId, { extraSalesMarginRule: rule });

    const withRecomputeAction = createActionWithRecompute(
      rfqId,
      LegRecomputeScope.CHANGE_LEG_MARKUP,
      [patchAction],
    );

    dispatch(withRecomputeAction);
  };
}
