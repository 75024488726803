import type { Thunk } from '@/bootstrap/thunks';
import type { ExecutionKey } from '@/neos/business/order/orderModel';

export function createDeleteListedAllocationThunk(
  executionKey: ExecutionKey,
  allocationId: string,
  allocationBookingId: string | undefined,
): Thunk {
  return function deleteListedlocationThunk(dispatch, _, { actionCreators }) {
    const hasAllocationBookingId = allocationBookingId !== undefined;

    dispatch(
      actionCreators.analytics.createLogAnalyticsAction(
        'NEOS RFQ',
        'Remove allocation from execution',
      ),
    );
    if (hasAllocationBookingId) {
      dispatch(
        actionCreators.neos.listedAllocationCrudActions.update(
          { ...executionKey, allocationId },
          { isCancelled: true },
        ),
      );
    } else {
      dispatch(
        actionCreators.neos.listedAllocationCrudActions.delete({ ...executionKey, allocationId }),
      );
    }
  };
}
