import type { Action } from '@/bootstrap/actions.ts';
import type { Thunk } from '@/bootstrap/thunks.ts';
import type {
  LegPeriod,
  RateLegPeriodDates,
} from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel.ts';
import { isClsProduct, isElsProduct } from '../../../../../../../neosModel.ts';

export function createRemoveRateSchedulePeriodThunk(strategyId: string, uuid: string): Thunk {
  return function removeRateSchedulePeriodThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { productCrudActions },
      },
    },
  ) {
    const appState = getState();

    const product = selectors.getStrategyProduct(appState, strategyId, selectors);

    if (!isElsProduct(product) && !isClsProduct(product)) {
      throw new Error('product is neither an els nor a cls');
    }
    const periods: LegPeriod<RateLegPeriodDates>[] = product?.ratePeriods ?? [];

    const action: Action = productCrudActions.update(product.uuid, {
      ratePeriods: periods.filter(line => line.uuid !== uuid),
    });
    dispatch(action);
  };
}
