import { convertNullToUndefined } from '@/util/undefinedAndNull/convertNullToUndefined';
import type { CommonProduct } from '../../productModel';
import type { OnyxProduct } from '../../productOnyxModel';

export function mapFromOnyxProductCommon(
  legId: string,
  product: OnyxProduct,
  productUuid: string,
): CommonProduct {
  return {
    uuid: productUuid,
    legId,
    deliveryType: convertNullToUndefined(product.deliveryType),
    lotSize: convertNullToUndefined(product.lotSize),
    clientTaxRate: convertNullToUndefined(product.clientTaxRate),
    noTaxCollection: convertNullToUndefined(product.noTaxCollection),
  };
}
