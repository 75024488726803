import type { Thunk } from '@/bootstrap/thunks';

interface Parameters {
  isDeltaHedgeUpdateMandatory: boolean;
  checkForFairPricesUpdate: boolean;
}

export function createRequestRfqDeltaHedgingThunk(rfqId: string): Thunk {
  return function requestRfqDeltaHedgingThunk(dispatch, _) {
    dispatch(
      createRfqDeltaHedgingAndFairPricesEventuallyRequestedThunk(rfqId, {
        isDeltaHedgeUpdateMandatory: true,
        checkForFairPricesUpdate: false,
      }),
    );
  };
}

export function createRequestRfqDeltaHedgingAndFairPricesThunk(rfqId: string): Thunk {
  return function requestRfqDeltaHedgingAndFairPricesThunk(dispatch, _) {
    dispatch(
      createRfqDeltaHedgingAndFairPricesEventuallyRequestedThunk(rfqId, {
        isDeltaHedgeUpdateMandatory: false,
        checkForFairPricesUpdate: true,
      }),
    );
  };
}

function createRfqDeltaHedgingAndFairPricesEventuallyRequestedThunk(
  rfqId: string,
  requestedElements: Parameters,
): Thunk {
  return function rfqDeltaHedgingAndFairPricesEventuallyRequestedThunk(
    dispatch,
    getState,
    {
      selectors: { getRfqHasFairPrices, getRfqData, getRfqMasterStrategy },
      thunks: {
        neos: { createRfqDeleteStrategyAndDependenciesThunk, createRequestRfqFairPricesThunk },
      },
      actionCreators: {
        neos: {
          rfqDataCrudActions,
          createRfqDeltaHedgingRequestedAction,
          createRfqDeltaHedgingAndFairPricesRequestedAction,
          createStrategySelectedAction,
        },
      },
    },
  ) {
    const state = getState();
    const { deltaHedgingStrategyIds } = getRfqData(state, rfqId);
    const rfqHasFairPrices = getRfqHasFairPrices(state, rfqId);
    const { uuid: masterStrategyId } = getRfqMasterStrategy(state, rfqId);

    if (isDeltaHedgingUpdateNeeded(deltaHedgingStrategyIds, requestedElements)) {
      const rfqDefaultingAction = isFairPricesUpdateNeeded(requestedElements, rfqHasFairPrices)
        ? createRfqDeltaHedgingAndFairPricesRequestedAction
        : createRfqDeltaHedgingRequestedAction;

      dispatch(
        createStrategySelectedAction(rfqId, masterStrategyId),
        rfqDataCrudActions.update(rfqId, { deltaHedgingStrategyIds: [] }),
        rfqDefaultingAction(rfqId),
        deltaHedgingStrategyIds.map(deltaHedgingStrategyId =>
          createRfqDeleteStrategyAndDependenciesThunk(deltaHedgingStrategyId),
        ),
      );
      return;
    }

    if (isFairPricesUpdateNeeded(requestedElements, rfqHasFairPrices)) {
      dispatch(createRequestRfqFairPricesThunk(rfqId));
    }
  };
}

function isFairPricesUpdateNeeded(requestedElements: Parameters, rfqHasFairPrices: boolean) {
  return requestedElements.checkForFairPricesUpdate && rfqHasFairPrices;
}

function isDeltaHedgingUpdateNeeded(
  deltaHedgingStrategyIds: string[],
  requestedElements: Parameters,
) {
  return deltaHedgingStrategyIds.length || requestedElements.isDeltaHedgeUpdateMandatory;
}
