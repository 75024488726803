import type { FromOnyxMappers } from '@/neos/business/mappers';
import { convertNullToUndefined } from '@/util/undefinedAndNull/convertNullToUndefined';
import type { TotalReturnFuture } from '../../productModel';
import type { OnyxSingleUnderlyingProduct } from '../../productOnyxModel';

export function mapFromOnyxProductTotalReturnFuture(
  legId: string,
  onyxProduct: OnyxSingleUnderlyingProduct,
  productUuid: string,
  mappers: FromOnyxMappers,
): TotalReturnFuture {
  return {
    subFamily: 'TOTAL_RETURN_FUTURE',
    pointValue: convertNullToUndefined(onyxProduct.pointValue),
    ...mappers.mapFromOnyxProductCommon(legId, onyxProduct, productUuid),
    ...mappers.mapFromOnyxFutureNegotiation(onyxProduct.negotiation),
    ...mappers.mapFromSingleDerivativeOnyxProduct(onyxProduct, mappers),
    ...mappers.mapFromOnyxFutureStrikeDate(onyxProduct),
    hasCustomUnderlying: false,
  };
}
