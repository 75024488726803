import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { Counterpart } from '@/neos/business/rfq/models';
import { isDefined } from '@/util/undefinedAndNull/isDefined';
import { deltaStockListedAllocationDomain } from './deltaStockListedAllocationDomain';
import type { DeltaStockListedAllocation } from './deltaStockListedAllocationModel';

export const deltaStockListedAllocationSelectors = {
  getDeltaStockListedAllocationsByLegId,
  getDeltaStockListedAllocationsByRfqId,
  getDeltaStockListedAllocationCounterparts,
};

export function getDeltaStockListedAllocationsByLegId(
  state: AppState,
  legId: string,
): DeltaStockListedAllocation[] {
  return deltaStockListedAllocationDomain.selectors.selectObjects(
    state.deltaStockListedAllocationState,
    {
      legId,
    },
  );
}

export function getDeltaStockListedAllocationCounterparts(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): Counterpart[] {
  const counterparts = selectors.getCounterparts(state, rfqId);

  return getDeltaStockListedAllocationsByRfqId(state, rfqId)
    .map(alloc => counterparts?.find(c => alloc.counterpartId === c.id))
    .filter(isDefined)
    .filter((alloc, index, array) => array.indexOf(alloc) === index);
}

export function getDeltaStockListedAllocationsByRfqId(
  state: AppState,
  rfqId: string,
): DeltaStockListedAllocation[] {
  return deltaStockListedAllocationDomain.selectors.selectObjects(
    state.deltaStockListedAllocationState,
    {
      rfqId,
    },
  );
}
