import type { BlotterUi } from './blotterUiModel';

export type BlotterUiState = Readonly<BlotterUi>;

export const blotterPositionStateLocalStorageKey = 'blotterPositionnState';

export const defaultBlotterUiState: BlotterUiState = {
  pendingRfqIds: [],
  position: localStorage[blotterPositionStateLocalStorageKey] || 'CENTER',
};
