import { actionCreators } from '@/bootstrap/actions';
import { thunks } from '@/bootstrap/thunks';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { combineEpics } from 'redux-observable';
import { mappers } from '../../mappers';
import { makeContactApi } from './contact/contactApi';
import { type ContactApi, createRetrieveContactsEpic } from './contact/contactEpics';
import { makeCounterpartApi } from './counterpart/counterpartApi';
import {
  type CounterpartApi,
  createRetrieveCounterpartEpic,
  createRetrieveCounterpartsEpic,
} from './counterpart/counterpartEpics';

export function getActorsSetupRootEpic(http: SgmeHttp) {
  const counterpartApi: CounterpartApi = makeCounterpartApi(http);
  const contactApi: ContactApi = makeContactApi(http);
  return combineEpics(
    createRetrieveCounterpartsEpic(counterpartApi, actionCreators, thunks),
    createRetrieveCounterpartEpic(counterpartApi, actionCreators, thunks, mappers.fromOnyxMappers),
    createRetrieveContactsEpic(contactApi, actionCreators),
  );
}
