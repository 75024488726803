import { useDispatch, useSelector } from 'react-redux';

import { actionCreators } from '@/bootstrap/actions';
import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { thunks } from '@/bootstrap/thunks';
import { CalculationPartyValues } from '@/neos/business/rfq/strategy/feature/elsSections/elsSectionsModel';
import type { CalculationParty } from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel';
import { ElsFeaturesContext } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures';
import { ElsBlocField } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/ElsBlocField';
import { useContext } from 'react';
import { FormSelect } from 'react-bootstrap';
import { isElsProduct } from '../../../../../../../business/neosModel';

export function CalculationAgent() {
  const { rfqId, strategyId } = useContext(ElsFeaturesContext);
  const dispatch = useDispatch();
  const product = useSelector((state: AppState) =>
    selectors.getStrategyProduct(state, strategyId, selectors),
  );
  if (!isElsProduct(product)) {
    return null;
  }
  const { uuid, calculationAgent } = product;
  const { update: updateProduct } = actionCreators.neos.productCrudActions;
  const onCalculationAgentChange = (newCalculationAgent: CalculationParty) => {
    dispatch(updateProduct(uuid, { calculationAgent: newCalculationAgent }));
    dispatch(thunks.neos.createDefaultConfirmMediaRequested(rfqId));
  };

  return (
    <ElsBlocField
      label="Calculation Agent"
      renderInput={readOnly => (
        <FormSelect
          readOnly={readOnly}
          value={calculationAgent ?? ''}
          onChange={event =>
            onCalculationAgentChange((event.target.value as CalculationParty) || undefined)
          }
          data-e2e="other-section-calculation-agent"
        >
          <option value=""></option>
          {Object.entries(CalculationPartyValues).map(([key, value]) => (
            <option key={key} value={key}>
              {value}
            </option>
          ))}
        </FormSelect>
      )}
    />
  );
}
