import {
  BookingStatusBorderColor,
  BookingStatusTooltip,
  getBookingStatusTooltipClassName,
} from '@/neos/business/neosOnyxModel';
import { useGetStringToLocalDateConvertor } from '@/neos/components/share/NeosBookingId/useGetStringToLocalDateConvertor';
import { If } from '@/neos/components/share/if/if';
import { NumericInput } from '@/neos/components/share/numericInput';
import { NeosTooltip } from '@/neos/components/share/tooltip/NeosTooltip';
import type { FC } from 'react';
import { Tooltip } from 'react-bootstrap';
import type { SalesMarginAmountInfo } from '../OtcAllocation';

type OtcAllocSalesMarginProps = {
  isSalesMarginDisplayed: boolean;
  salesMarginValue: SalesMarginAmountInfo | undefined;
};

export const OtcAllocSalesMargin: FC<OtcAllocSalesMarginProps> = ({
  isSalesMarginDisplayed,
  salesMarginValue,
}) => {
  const statusTooltip = salesMarginValue?.status
    ? BookingStatusTooltip[salesMarginValue?.status]
    : '';
  const { stringToLocalDateConvertor } = useGetStringToLocalDateConvertor();

  const lastUpdateTime = salesMarginValue?.lastUpdateTime;
  const timeStampMessage =
    lastUpdateTime !== undefined ? ` on ${stringToLocalDateConvertor(lastUpdateTime)}` : '';

  return (
    <If condition={isSalesMarginDisplayed}>
      <NeosTooltip
        placement="top"
        overlay={
          <Tooltip
            className={getBookingStatusTooltipClassName(salesMarginValue?.status)}
            id="booking-status-tooltip"
          >
            <b>{statusTooltip}</b> {timeStampMessage}
          </Tooltip>
        }
      >
        <NumericInput
          data-e2e="sales-margin"
          inputClassName="fw-bold shadow-none"
          unit={salesMarginValue?.unit}
          value={salesMarginValue?.value}
          withMaximumNumberOfFloatingDigits={0}
          readOnly
          borderColor={
            salesMarginValue?.status ? BookingStatusBorderColor[salesMarginValue.status] : undefined
          }
        />
      </NeosTooltip>
    </If>
  );
};
