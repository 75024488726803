import { camelCase } from 'lodash';

export function elsBasketImportApproximateImportedKeys(
  importedDataWithCamelCaseKeys: Record<string, string>[],
): Record<string, string>[] {
  return importedDataWithCamelCaseKeys.map(line => {
    return Object.fromEntries(
      Object.entries(line).map(([key, value]) => {
        const column = sanitizeColumnName(key);
        if (
          sanitizeArrayOfColumnNames(BasketColumnNameConversions.BLOOMBERG_CODE).includes(column)
        ) {
          return ['bloombergCode', value];
        }
        if (sanitizeArrayOfColumnNames(BasketColumnNameConversions.QUANTITY).includes(column)) {
          return ['quantity', value];
        }
        if (sanitizeArrayOfColumnNames(BasketColumnNameConversions.SPOT).includes(column)) {
          return ['spot', value];
        }

        return [key, value];
      }),
    );
  });
}

// These aliases are NOT exhaustive because they are evaluated case-insensitive and space-insensitive in sanitizeColumnName()
const bloombergCodeColumnAliases = [
  'underlyingName',
  'Bloombergcode',
  'Ticker',
  'Tickers',
  'Underlying',
  'Underlying name',
  'underlyingName',
  'name',
  'bbgName',
  'bbg name',
  'bbg code',
  'bbgCode',
  'bbg',
  'bloom',
  'Basket Underlying',
];

const quantityColumnAliases = ['quantity', 'quantité', 'qty', 'Basket Quantity'];

const spotColumnAliases = [
  'spot',
  'price',
  'openprice',
  'open price',
  'closeprice',
  'close price',
  'px',
  'prix',
  'Basket Spot Gross',
];

export const BasketColumnNameConversions = {
  BLOOMBERG_CODE: bloombergCodeColumnAliases,
  QUANTITY: quantityColumnAliases,
  SPOT: spotColumnAliases,
};

const sanitizeColumnName = (column: string) => camelCase(column).toLowerCase();

const sanitizeArrayOfColumnNames = (columns: string[]) =>
  columns.map(column => sanitizeColumnName(column));
