import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { BlotterModelGetters, BlotterRowModel } from '@/neos/components/share/blotterGrid';
import type { DisplayTimeZone } from '../../../../../neos/business/neosModel';

export interface SimilarActivitiesGridModel {
  rows: BlotterRowModel[];
  isTrader: boolean;
  displayTimezone: DisplayTimeZone;
}

export function getSimilarActivitiesGridModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
  getters: BlotterModelGetters,
): SimilarActivitiesGridModel {
  const similarActivities = selectors.getSimilarActivities(state, rfqId);
  const displayTimezone = selectors.getDisplayTimeZone(state.ui.userPreferences);
  const isTrader = selectors.isTrader(state);

  return {
    rows: similarActivities.map(similarActivity =>
      getters.getBlotterRow(state, similarActivity, selectors, getters),
    ),
    displayTimezone,
    isTrader,
  };
}
