import type { UnderlyingType } from '@/neos/business/rfq/strategy/leg/legOnyxModel';
import type { MultipleTypeaheadValue } from '@/neos/components/share/multipleAsyncTypeahead/MultipleAsyncTypeahead';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import type { Underlying } from '../../../../../../../neos/business/neosModel';
import { mapUnderlyingsToTypeaheadValues } from './underlyingMappers';

const URL: string = 'api/referential/underlyings?bloombergCodePrefix';

export interface UnderlyingApi {
  fetchMUnderlyingMatchingOptions: (
    prefix: string,
    underlyingTypes: UnderlyingType[],
  ) => Observable<MultipleTypeaheadValue[]>;
}

export const makeUnderlyingApi = (http: SgmeHttp): UnderlyingApi => ({
  fetchMUnderlyingMatchingOptions(prefix: string, underlyingTypes: UnderlyingType[]) {
    const url = `${URL}=${prefix}${underlyingTypes.map(udlType => `&types=${udlType}`).join('')}`;
    return http
      .get<Underlying[]>({
        url,
        timeout: 10_000,
      })
      .pipe(map(mapUnderlyingsToTypeaheadValues));
  },
});
