import type { Thunk } from '@/bootstrap/thunks';

export function createUpdateLegMaturityThunk(
  rfqId: string,
  strategyId: string,
  legId: string,
  maturity: string | undefined,
): Thunk {
  return function updateLegMaturityThunk(
    dispatch,
    getState,
    {
      selectors,
      thunks: {
        neos: {
          createRfqResetStrategyQuoteAndFairPricesThunk,
          createUpdateProductAndResetDependenciesThunk,
        },
      },
    },
  ) {
    const appState = getState();
    dispatch(createRfqResetStrategyQuoteAndFairPricesThunk(rfqId, strategyId));

    const { productId } = selectors.getLegData(appState, legId);
    dispatch(
      createUpdateProductAndResetDependenciesThunk(
        rfqId,
        strategyId,
        productId,
        'maturity',
        maturity,
      ),
    );
  };
}
