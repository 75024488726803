import type { Thunk } from '@/bootstrap/thunks';
import type { ExecutionKey } from '../executionModel';

export function createChangeExecutionDateThunk(
  executionKey: ExecutionKey,
  newDate: string | undefined,
): Thunk {
  return function changeExecutionDateThunk(
    dispatch,
    getState,
    { selectors, actionCreators: { neos: as } },
  ) {
    const state = getState();
    const execution = selectors.executionSelectors.find(state.execution, executionKey);
    const displayTimeZone = selectors.getDisplayTimeZone(state.ui.userPreferences);
    if (!execution) {
      return;
    }
    if (!newDate) {
      return dispatch(as.executionCrudActions.update(executionKey, { executionTime: undefined }));
    }

    const { executionTime } = execution;

    const [year, month, days] = newDate.split('-');
    const executionDateTime = cleanPriceRequestDate(
      executionTime?.substring(
        0,
        executionTime?.indexOf('[') > 0 ? executionTime?.indexOf('[') : executionTime.length,
      ),
    );

    if (displayTimeZone === 'LOCAL') {
      executionDateTime.setFullYear(Number(year));
      executionDateTime.setMonth(Number(month) - 1);
      executionDateTime.setDate(Number(days));
    } else {
      executionDateTime.setUTCFullYear(Number(year));
      executionDateTime.setUTCMonth(Number(month) - 1);
      executionDateTime.setUTCDate(Number(days));
    }

    dispatch(
      as.executionCrudActions.update(executionKey, {
        executionTime: executionDateTime.toISOString(),
      }),
    );
  };
}

function cleanPriceRequestDate(dateTime: string | undefined) {
  if (dateTime) {
    return new Date(dateTime);
  }
  const prd = new Date();
  prd.setMilliseconds(0);
  return prd;
}
