import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { Thunk } from '@/bootstrap/thunks';
import type { NeosActionCreators } from '@/neos/business/neosActionCreators';
import type { ActionContainer } from '@/util/actionContainer';
import { flatMap } from 'lodash';

type FairPriceStartLevel = 'STRATEGY' | 'LEG';

export function createRfqResetStrategyFairPriceThunk(
  rfqId: string,
  strategyId: string,
  startLevel: FairPriceStartLevel = 'LEG',
): Thunk {
  return function rfqResetStrategyFairPriceThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { fairPriceCrudActions },
      },
    },
  ) {
    const state = getState();

    dispatch(
      getResetFairPricesBatchAction(
        state,
        rfqId,
        strategyId,
        startLevel,
        fairPriceCrudActions,
        selectors,
      ),
    );
  };
}

function getResetFairPricesBatchAction(
  state: AppState,
  rfqId: string,
  strategyId: string,
  startLevel: FairPriceStartLevel,
  fairPriceCrudAction: NeosActionCreators['fairPriceCrudActions'],
  selectors: Selectors,
): ActionContainer {
  const { getRfqData, getStrategyData, getLegData } = selectors;

  // Rfq fair price
  const { fairPriceId: rfqFairPriceId } = getRfqData(state, rfqId);
  const upsertRfqFairPriceActions = rfqFairPriceId
    ? [
        fairPriceCrudAction.upsert(rfqFairPriceId, {
          parentId: { type: 'Rfq', id: rfqId },
          midPriceType: undefined,
        }),
      ]
    : [];

  // Strategy fair price
  const { fairPriceId: strategyFairPriceId, legIds } = getStrategyData(state, strategyId);
  const upsertStrategyFairPriceActions = strategyFairPriceId
    ? [
        fairPriceCrudAction.upsert(strategyFairPriceId, {
          parentId: { type: 'Strategy', id: strategyId },
          midPriceType: undefined,
        }),
      ]
    : [];

  // Legs fair prices
  const legIdsToMap = startLevel !== 'STRATEGY' ? legIds : [];
  const upsertLegFairPriceActions = flatMap(legIdsToMap, legId => {
    const { fairPriceId: legFairPriceId } = getLegData(state, legId);
    return legFairPriceId
      ? [
          fairPriceCrudAction.upsert(legFairPriceId, {
            parentId: { type: 'Leg', id: legId },
            midPriceType: undefined,
          }),
        ]
      : [];
  });

  return [
    ...upsertRfqFairPriceActions,
    ...upsertStrategyFairPriceActions,
    ...upsertLegFairPriceActions,
  ];
}
