import { type ActionCreators, actionCreators } from '@/bootstrap/actions';
import type { ThunkEpic } from '@/bootstrap/epics';
import { type Thunks, thunks } from '@/bootstrap/thunks';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { ofType } from 'redux-observable';
import { catchError, map, mergeMap } from 'rxjs/operators';
import type { OnyxError } from '../../mappers/error';
import { type PrimeBrokerApi, makePrimeBrokerApi } from './primeBrokerApi';

export function getRetrievePrimeBrokersEpic(http: SgmeHttp): ThunkEpic {
  const primeBrokerApi: PrimeBrokerApi = makePrimeBrokerApi(http);
  return createRetrievePrimeBrokersEpic(primeBrokerApi, actionCreators, thunks);
}

export function createRetrievePrimeBrokersEpic(
  api: PrimeBrokerApi,
  { neos: { createPrimeBrokersReceivedAction } }: ActionCreators,
  { createErrorToasterThunk }: Thunks,
): ThunkEpic {
  return action$ =>
    action$.pipe(
      ofType('PRIME_BROKERS_REQUESTED'),
      mergeMap(({ counterpartId }) => {
        return api.getPrimeBrokers(counterpartId).pipe(
          map(primeBrokers => createPrimeBrokersReceivedAction(primeBrokers, counterpartId)),
          catchError((error: OnyxError) => [
            createErrorToasterThunk(
              { message: 'Error while retrieving broker list' },
              error,
              false,
            ),
          ]),
        );
      }),
    );
}
