import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { connect } from 'react-redux';
import {
  FairPricesComponentWithCss,
  type FairPricesMapStateProps,
  type FairPricesOwnProps,
} from './FairPrices';
import { getFairPricesModel } from './getFairPricesModel';

export const FairPrices = connect(mapStateToProps)(FairPricesComponentWithCss);

function mapStateToProps(
  state: AppState,
  { rfqId, strategyId }: FairPricesOwnProps,
): FairPricesMapStateProps {
  return { model: getFairPricesModel(state, rfqId, strategyId, selectors) };
}
