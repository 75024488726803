import type { BusinessDayConvention } from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel';
import type {
  AverageDisruptionDate,
  ClsType,
  DateWithOffset,
  DeliveryType,
  OptionObservableType,
  OptionStyle,
  OptionType,
  PriceUnitType,
  ProductSubFamily,
  StrategyScope,
  StrategyType,
  StrikeType,
} from '../../../../neos/business/neosModel';
import type {
  OnyxAsianPeriod,
  OnyxNegotiation,
  OnyxObservationConvention,
  OnyxUnderlying,
  PriceWithUnit,
  UnderlyingType,
} from '../../../../neos/business/neosOnyxModel';
import type { AccrualType, ClientPosition, OptionFlex } from '../../../../neos/business/rfq/models';

export interface OnyxStrategyDefinition {
  alias: string;
  name: string;
  id: StrategyType;
  availableStrategyScopes: StrategyScope[];
  readOnlyMaster?: boolean;
  readOnlyWeight?: boolean;
  readOnlyProductLotSize?: boolean;
  sameProductDiscriminator?: boolean;
  samePriceUnitType?: boolean;
  sameProductExtraFeatures?: boolean;
  sameProductDown?: boolean;
  sameProductUp?: boolean;
  sameNotionalCurrency?: boolean;
  priceUnitType?: PriceUnitType;
  readOnlyNumberOfLegs?: boolean;
  readOnlyProductNegotiationMode?: boolean;
  readOnlyProductClientPosition?: boolean;
  readOnlyProductOptionStyle?: boolean;
  readOnlyProductOptionType?: boolean;
  readOnlyProductSwapCurrency?: boolean;
  readOnlyProductSettlementDate?: boolean;
  readOnlyProductStrikeDate?: boolean;
  readOnlyProductRateCurve?: boolean;
  readOnlyProductRateTenor?: boolean;
  readOnlyProductExpectedN?: boolean;
  readOnlyProductElsType?: boolean;
  readOnlyProductClsType?: boolean;
  readOnlyProductAccrualType?: boolean;
  top?: number | null;
  legs: OnyxStrategyDefinitionLeg[];
  sameNegotiatedSize?: boolean;
  sameProductDeliveryType?: boolean;
  sameProductLotSize?: boolean;
  sameProductMaturity?: boolean;
  sameProductClientPosition?: boolean;
  sameProductOptionStyle?: boolean;
  sameProductOptionType?: boolean;
  sameProductOptionFlex?: boolean;
  sameProductStrike?: boolean;
  sameProductUnderlying?: boolean;
  sameProductNegotiationMode?: boolean;
  sameProductSwapCurrency?: boolean;
  sameProductSettlementDate?: boolean;
  sameProductStrikeDate?: boolean;
  sameProductExpectedN?: boolean;
  sameProductForwardDrift?: boolean;
  sameProductForwardInterestRate?: boolean;
  sameProductRateTenor?: boolean;
  sameProductRateCurve?: boolean;
  sameProductElsType?: boolean;
  sameProductClsType?: boolean;
  sameProductEndOfObservationType?: boolean;
  sameProductAccrualType?: boolean;
  sameWeight?: boolean;
  sameFixedDay?: boolean;
  splittable?: boolean;
  ratio?: boolean;
  sameFactorWeights?: boolean;
  availablePriceUnitTypes?: PriceUnitType[];
  aggregatableLegs?: boolean | null;
  sameGenerateFrom?: boolean;
  sameEquityResetType?: boolean;
  sameRateReset?: boolean;
  sameResetMode?: boolean;
  sameBrokenPeriodType?: boolean;
  sameWRateResetOnEach?: boolean;
  sameConventionDay?: boolean;
  sameEffectiveDate?: boolean;
  sameEffectiveDateOffset?: boolean;
  sameRatePeriods?: boolean;
  sameEquityPeriods?: boolean;
  sameRoleDefinition?: boolean;
  sameCalculationAgent?: boolean;
  sameDeterminingParty?: boolean;
  sameHedgingParty?: boolean;
  sameRateSpreadAdjustment?: boolean;
  sameDividendSpreadAdjustment?: boolean;
  sameDividendPriceType?: boolean;
  sameLinearInterpolation?: boolean;
  sameLookthroughDR?: boolean;
  sameRightToSubstituteScope?: boolean;
  sameRightToSubstituteConditions?: boolean;
  sameRelatedExchange?: boolean;
  sameTermNotice?: boolean;
  sameClientTermNotice?: boolean;
  sameDailyMinSize?: boolean;
  sameDailyMaxSize?: boolean;
  sameSecondaryMarketAllowed?: boolean;
  sameComponentSecurityIndexAnnex?: boolean;
  sameBreakFeePeriods?: boolean;
  sameBreakFeeElection?: boolean;
  sameSettlementMethodElection?: boolean;
  sameTerminationType?: boolean;
  sameTerminationRights?: boolean;
  sameTerminationConditions?: boolean;
  sameBasisType?: boolean;
  sameCompoundRate?: boolean;
  sameElectionFee?: boolean;
  sameElectionDate?: boolean;
  sameValuationType?: boolean;
  sameDealType?: boolean;
  sameDeclaredCashDiv?: boolean;
  sameLocalTaxes?: boolean;
  sameSpecialDividends?: boolean;
  sameRateFixingOffset?: boolean;
  sameBrokenPeriodPosition?: boolean;
  sameExecFeesIn?: boolean;
  sameExecFeesOut?: boolean;
  sameDerogateRateFixingOffset?: boolean;
  readOnlyObservableType?: boolean;
  sameIsScheduleObsolete?: boolean;
  availableObservableTypes?: OptionObservableType[];
  readOnlyProductNoTaxCollection?: boolean;
  readOnlyProductClientTaxRate?: boolean;
  sameProductNoTaxCollection?: boolean;
  sameProductClientTaxRate?: boolean;
}

export type SizeType =
  | 'FIXED_QUANTITY'
  | 'FIXED_VEGA_NOTIONAL'
  | 'FIXED_DIGITAL_NOTIONAL'
  | 'FIXED_NOTIONAL'
  | 'FIXED_VAR_UNIT';

export const notionalSizeTypes: SizeType[] = [
  'FIXED_NOTIONAL',
  'FIXED_VEGA_NOTIONAL',
  'FIXED_DIGITAL_NOTIONAL',
];

export interface OnyxStrategyDefinitionLeg {
  master: boolean;
  product: OnyxStrategyDefinitionProduct;
  weight: number;
  sizeType: SizeType;
  availableObservationConvention?: OnyxObservationConvention[] | null;
  upObservationConvention?: OnyxObservationConvention | null;
  downObservationConvention?: OnyxObservationConvention | null;
  availableSizeTypes: SizeType[];
  readOnlySizeType?: boolean;
  availablePriceUnitTypes?: unknown;
  priceUnitType?: unknown;
  availableStrikeUnitTypes?: unknown;
  strikeUnitType?: unknown;
  availableProductUnderlyingTypes?: UnderlyingType[];
  readOnlyFutureMaturity?: boolean;
  taxCollection?: boolean;
  strikeDate?: boolean;
}

export type ElsType = 'TRS' | 'PRS';

type PartialWithNull<T> = { [P in keyof T]?: T[P] | null | undefined };

export interface OnyxStrategyDefinitionProduct {
  valueDate?: DateWithOffset | null;
  discriminator: ProductSubFamily;
  deliveryType?: DeliveryType | null;
  optionType?: OptionType | null;
  optionStyle?: OptionStyle | null;
  clientPosition?: ClientPosition | null;
  optionFlexType?: OptionFlex | null;
  underlying: OnyxUnderlying | null | undefined;
  maturity?: string | null;
  effectiveDate?: string | null;
  maturityTenor?: string | null;
  futureMaturity?: string | null;
  expectedN?: number | null;
  strike?: number | null;
  swapCurrency?: string | null;
  settlementDate?: string | null;
  startDate?: string | null;
  strikeDate?: string | null;
  strikeTenor?: null;
  description?: string | null;
  lotSize: number | null | undefined;
  negotiation: PartialWithNull<OnyxNegotiation> | null;
  lowerStrike?: null;
  upperStrike?: null;
  strikeType?: StrikeType | null;
  period?: OnyxAsianPeriod | null;
  averageDisruptionDate?: AverageDisruptionDate | null;
  pointValue?: number | null;
  extraFeatures?: OnyxExtraFeaturesFields[];
  barriers?: null | unknown[];
  up?: null;
  down?: null;
  forwardStartDate?: null;
  forwardStartTenor?: null;
  forwardDrift?: unknown;
  forwardInterestRate?: unknown;
  resetFrequency?: unknown;
  equityBullet?: unknown;
  rateBullet?: unknown;
  rateOvernight?: unknown;
  forexType?: unknown;
  rateCurve?: string | null;
  rateTenor?: string | null;
  elsType?: ElsType | null;
  clsType?: ClsType | null;
  accrual?: AccrualType | null;
  endOfObservation?: string | null;
  noTaxCollection: boolean | null;
  clientTaxRate: PriceWithUnit | null;
  businessDayConvention?: BusinessDayConvention | null;
}

export type OnyxExtraFeatureDiscriminator =
  | 'FORWARD_START'
  | 'BARRIER_CONTAINER'
  | 'CAP_CONTAINER'
  | 'FLOOR_CONTAINER'
  | 'UP_CONTAINER'
  | 'DOWN_CONTAINER'
  | 'RESET_FREQUENCY'
  | 'INTEREST_RATE_INDEX'
  | 'EQUITY_BULLET'
  | 'RATE_BULLET'
  | 'RATE_OVERNIGHT'
  | 'FOREX_TYPE'
  | 'EXEC_FEES';

export interface OnyxExtraFeaturesFields {
  discriminator: OnyxExtraFeatureDiscriminator;
  forwardStartDate?: boolean | null;
  forwardStartTenor?: null;
  barriers?: boolean | null;
  allMustHit?: boolean | null;
  cap?: boolean | null;
  floor?: boolean | null;
  up?: boolean | null;
  down?: boolean | null;
  resetFrequency?: number | null;
  equityBullet?: boolean | null;
  forexType?: null;
  rateBullet?: boolean | null;
  rateOvernight?: boolean | null;
  execFees?: unknown;
}
