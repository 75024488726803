import { errorReducer } from '@/common/business/globalError/globalErrorReducer';
import { helpRequestReducer } from '@/common/business/helpRequest/helpRequestReducer';
import { userInfoReducer } from '@/common/business/userInfo/userInfoReducer';
import { alertReducer } from '@/neos/business/alert/alertReducer';
import { allocSecondaryEventCreationReducer } from '@/neos/business/allocSecondaryEventCreation/allocSecondaryEventCreationReducer';
import { allocationInstructionReducer } from '@/neos/business/allocationInstruction/allocationInstructionReducer';
import { blotterReducer } from '@/neos/business/blotter/blotterReducer';
import { bookingStepsReducer } from '@/neos/business/bookingSteps/BookingStepsSlice';
import { currentUserPreferencesSliceReducer } from '@/neos/business/currentUserPreferences/currentUserPreferencesSlice';
import { electronicMediaUsersReducer } from '@/neos/business/electronicMediaUsers/electronicMediaUsersReducer';
import { lastOnyxTradeCompletedRfqSlice } from '@/neos/business/lastOnyxTradeCompletedRfq/lastOnyxTradeCompletedRfqSlice';
import { legSecondaryEventCreationReducer } from '@/neos/business/legSecondaryEventCreation/legSecondaryEventCreationReducer';
import { nestedRfqsReducer } from '@/neos/business/nestedRfqs/nestedRfqsReducer';
import { nextUserActionsReducer } from '@/neos/business/nextUserActions/nextUserActionsReducer';
import { executionDomain } from '@/neos/business/order/execution/executionDomain';
import { orderDomain } from '@/neos/business/order/orderData/orderDataDomain';
import { pendingPredealChecksReducer } from '@/neos/business/pendingPredealChecks/pendingPredealChecksReducer';
import { preConfirmationStepReducer } from '@/neos/business/preConfirmationStep/preConfirmationStepDomain';
import { preconfirmationReducer } from '@/neos/business/preconfirmation/preconfirmationReducer';
import { predealCheckReducer } from '@/neos/business/predealCheck/predealCheckReducer';
import { referenceDataReducer } from '@/neos/business/referenceData/referenceDataReducer';
import { actorsSetupReducer } from '@/neos/business/rfq/actorsSetup/actorsSetupReducer';
import { counterpartsReducer } from '@/neos/business/rfq/counterparts/counterpartsReducer';
import { extraCounterpartsReducer } from '@/neos/business/rfq/extraCounterparts/extraCounterpartsReducer';
import { fairPriceReducer } from '@/neos/business/rfq/fairPrice/fairPriceReducer';
import { forexRatesReducer } from '@/neos/business/rfq/forexRates/forexRatesReducer';
import { hedgeReducer } from '@/neos/business/rfq/hedge/hedgeReducer';
import { preconfirmationEmailPreviewReducer } from '@/neos/business/rfq/preconfirmationEmailPreview/preconfirmationEmailPreviewReducer';
import { quoteReducer } from '@/neos/business/rfq/quotes/quoteReducer';
import { referenceReducer } from '@/neos/business/rfq/reference/referenceReducer';
import { rfqDataReducer } from '@/neos/business/rfq/rfqData/rfqDataReducer';
import { featureReducer } from '@/neos/business/rfq/strategy/feature/featureReducer';
import { deltaStockListedAllocationReducer } from '@/neos/business/rfq/strategy/leg/deltaStockListedAllocation/deltaStockListedAllocationReducer';
import { legDataReducer } from '@/neos/business/rfq/strategy/leg/legData/legDataReducer';
import { listedAllocationReducer } from '@/neos/business/rfq/strategy/leg/listedAllocation/listedAllocationReducer';
import { otcAllocationReducer } from '@/neos/business/rfq/strategy/leg/otcAllocation/otcAllocationReducer';
import { productReducer } from '@/neos/business/rfq/strategy/leg/product/productReducer';
import { strategyDataReducer } from '@/neos/business/rfq/strategy/strategyData/strategyDataReducer';
import { transfereeContactsReducer } from '@/neos/business/rfq/transfereeContacts/transfereeContactsReducer';
import { versionsReducer } from '@/neos/business/rfq/versions/versionsReducer';
import { similarActivitiesReducer } from '@/neos/business/similarActivities/similarActivitiesReducer';
import { similarActivitiesLinkManagementReducer } from '@/neos/business/similarActivitiesLinkManagement/similarActivitiesLinkManagementReducer';
import { neosUiReducer } from '@/neos/business/ui/uiReducer';
import { underlyingInfoReducer } from '@/neos/business/underlyingInfo/underlyingInfoReducer';
import { warningsReducer } from '@/neos/business/warnings/warningReducer';
import { reducer as notificationsReducer } from 'reapop';
import { combineReducers } from 'redux';
import type { AppState } from './state';

export const rootReducer = combineReducers<AppState>({
  currentUserPreferences: currentUserPreferencesSliceReducer,
  lastTradeCompletedRfq: lastOnyxTradeCompletedRfqSlice.reducer,
  predealCheck: predealCheckReducer,
  pendingPredealChecks: pendingPredealChecksReducer,
  alerts: alertReducer,
  nestedRfqs: nestedRfqsReducer,
  actorsSetup: actorsSetupReducer,
  transfereeContacts: transfereeContactsReducer,
  quotesState: quoteReducer,
  versionsState: versionsReducer,
  fairPricesState: fairPriceReducer,
  forexRatesState: forexRatesReducer,
  reference: referenceReducer,
  hedge: hedgeReducer,
  counterparts: counterpartsReducer,
  extraCounterparts: extraCounterpartsReducer,
  preconfirmationEmail: preconfirmationEmailPreviewReducer,
  featureState: featureReducer,
  productState: productReducer,
  legDataState: legDataReducer,
  notifications: notificationsReducer(),
  strategyDataState: strategyDataReducer,
  rfqDataState: rfqDataReducer,
  orderData: orderDomain.reducer,
  execution: executionDomain.reducer,
  underlyingState: underlyingInfoReducer,
  allocationInstruction: allocationInstructionReducer,
  listedAllocationState: listedAllocationReducer,
  otcAllocationState: otcAllocationReducer,
  deltaStockListedAllocationState: deltaStockListedAllocationReducer,
  referenceData: referenceDataReducer,
  ui: neosUiReducer,
  blotter: blotterReducer,
  similarActivitiesLinkManagement: similarActivitiesLinkManagementReducer,
  nextUserActions: nextUserActionsReducer,
  similarActivities: similarActivitiesReducer,
  preconfirmation: preconfirmationReducer,
  electronicMediaUsersState: electronicMediaUsersReducer,
  legSecondaryEventCreation: legSecondaryEventCreationReducer,
  allocSecondaryEventCreation: allocSecondaryEventCreationReducer,
  warnings: warningsReducer,
  preConfirmationStep: preConfirmationStepReducer,
  userInfo: userInfoReducer,
  globalErrors: errorReducer,
  helpRequestState: helpRequestReducer,
  bookingSteps: bookingStepsReducer,
});
