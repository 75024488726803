import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import type { AllocationsModel } from '@/neos/components/rfq/postNego/allocs/getAllocationsModel';
import { useDispatch } from 'react-redux';
import { getTriggerMatchingButtonModel } from './getTriggerMatchingButtonModel';

type TriggerMatchingButtonProps = {
  rfqId: string;
  isDelta?: boolean;
  legs: Partial<AllocationsModel>;
};

export function TriggerMatchingButton({
  rfqId,
  isDelta = false,
  legs,
}: TriggerMatchingButtonProps) {
  const dispatch = useDispatch();

  const { isDisplayed } = useAppSelector(state => {
    return getTriggerMatchingButtonModel(state, rfqId, legs, isDelta, selectors);
  });

  const onRetriggerMatchingClick = () => {
    dispatch(neosActionCreators.createTriggerMatchingAction(rfqId));
  };

  if (!isDisplayed) {
    return isDelta ? <div /> : null;
  }

  return (
    <button className="btn btn-primary" onClick={() => onRetriggerMatchingClick()}>
      Trigger matching
    </button>
  );
}
