import { type ActionCreators, actionCreators } from '@/bootstrap/actions';
import type { ThunkEpic } from '@/bootstrap/epics';
import { type Selectors, selectors } from '@/bootstrap/selectors';
import { type Thunks, thunks } from '@/bootstrap/thunks';
import type { OnyxError } from '@/neos/business/mappers/error';
import type { Broker } from '@/neos/business/referenceData/brokers/brokersModel';
import {
  type BrokersPreferencesApis,
  brokersPreferencesApis,
} from '@/neos/business/ui/userPreferences/brokers/brokersPreferencesApis';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { ofType } from 'redux-observable';
import { catchError, mergeMap } from 'rxjs/operators';

export function getGetCurrentUserBrokersEpic(http: SgmeHttp): ThunkEpic {
  const api = brokersPreferencesApis(http);
  return createGetCurrentUserBrokersEpic(api, actionCreators, thunks, selectors);
}

export function getSearchBrokersEpic(http: SgmeHttp): ThunkEpic {
  const api = brokersPreferencesApis(http);
  return createSearchBrokersEpic(api, actionCreators, thunks);
}

export function getAddBrokerEpic(http: SgmeHttp): ThunkEpic {
  const api = brokersPreferencesApis(http);
  return createAddBrokerPreferencesEpic(api, actionCreators, thunks);
}

export function getDeleteBrokerEpic(http: SgmeHttp): ThunkEpic {
  const api = brokersPreferencesApis(http);
  return createDeleteBrokerPreferencesEpic(api, actionCreators, thunks);
}

export function createSearchBrokersEpic(
  api: BrokersPreferencesApis,
  actionCreators: ActionCreators,
  { createErrorToasterThunk }: Thunks,
): ThunkEpic {
  return action$ =>
    action$.pipe(
      ofType('USER_PREFERENCES_SEARCH_BROKERS'),
      mergeMap(({ entry }) => {
        return api.searchBrokers(entry).pipe(
          mergeMap(searchedBrokers => {
            return [
              actionCreators.neos.createInsertSearchedBrokersAction(searchedBrokers),
              actionCreators.neos.createChangeUserPreferenceSearchBrokersLoadingAction(false),
            ];
          }),
          catchError((error: OnyxError) => [
            actionCreators.neos.createChangeUserPreferenceSearchBrokersLoadingAction(false),
            createErrorToasterThunk(
              { message: 'Error when searching for brokers in user preferences' },
              error,
            ),
          ]),
        );
      }),
    );
}

export function createAddBrokerPreferencesEpic(
  api: BrokersPreferencesApis,
  actionCreators: ActionCreators,
  { createErrorToasterThunk }: Thunks,
): ThunkEpic {
  return action$ =>
    action$.pipe(
      ofType('USER_PREFERENCES_ADD_BROKER'),
      mergeMap(({ brokerId }) => {
        return api.addBroker(brokerId).pipe(
          mergeMap(() => [
            actionCreators.neos.createGetCurrentUserBrokersAction(),
            actionCreators.neos.createChangeUserPreferenceAddBrokersLoadingAction(false),
          ]),
          catchError((error: OnyxError) => [
            actionCreators.neos.createChangeUserPreferenceAddBrokersLoadingAction(false),
            createErrorToasterThunk(
              { message: 'Error when adding a broker in user preferences' },
              error,
            ),
          ]),
        );
      }),
    );
}

export function createDeleteBrokerPreferencesEpic(
  api: BrokersPreferencesApis,
  actionCreators: ActionCreators,
  { createErrorToasterThunk }: Thunks,
): ThunkEpic {
  return action$ =>
    action$.pipe(
      ofType('USER_PREFERENCES_DELETE_BROKER'),
      mergeMap(({ brokerId }) => {
        return api.deleteBroker(brokerId).pipe(
          mergeMap(() => [
            actionCreators.neos.createGetCurrentUserBrokersAction(),
            actionCreators.neos.createChangeUserPreferenceDeleteBrokersLoadingAction(false),
          ]),
          catchError((error: OnyxError) => [
            actionCreators.neos.createChangeUserPreferenceDeleteBrokersLoadingAction(false),
            createErrorToasterThunk(
              { message: 'Error when deleting a broker in user preferences' },
              error,
            ),
          ]),
        );
      }),
    );
}

export function createGetCurrentUserBrokersEpic(
  api: BrokersPreferencesApis,
  actionCreators: ActionCreators,
  { createErrorToasterThunk }: Thunks,
  { getSesameId }: Selectors,
): ThunkEpic {
  return (action$, state$) =>
    action$.pipe(
      ofType('GET_CURRENT_USER_BROKERS'),
      mergeMap(() => {
        const sesameId = getSesameId(state$.value);
        return api.getCurrentUserBrokers(sesameId).pipe(
          mergeMap(brokers => {
            const reformattedBrokers: Broker[] = brokers.map(broker => {
              return {
                name: broker.name,
                eliotCode: broker.eliotCode,
                mnemo: broker.mnemo,
                id: broker.id,
              };
            });
            return [actionCreators.neos.createBrokersReceivedAction(reformattedBrokers)];
          }),
          catchError((error: OnyxError) => [
            createErrorToasterThunk(
              { message: 'Error when getting current user brokers for user preferences' },
              error,
            ),
          ]),
        );
      }),
    );
}
