import type { ThunkEpic } from '@/bootstrap/epics';
import { type Thunks, thunks } from '@/bootstrap/thunks';
import type { OnyxCreatedSecondaryEvent } from '@/neos/components/rfq/strategies/globalActions/secondaryEventWidget/createSecondary/OnyxCreateSecondaryModel';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { ofType } from 'redux-observable';
import { type Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { createCreateSecondaryEventApi } from '../apis/createSecondaryEventApi';
import type { OnyxError } from '../mappers/error';
import { type NeosActionCreators, neosActionCreators } from '../neosActionCreators';
import type { SecondaryEventType } from '../rfq/strategy/leg/legData/legDataModel';

export interface CreateSecondaryEventApi {
  createSecondaryEvent: (
    rfqId: string,
    secondaryEvents: SecondaryEventType[],
  ) => Observable<OnyxCreatedSecondaryEvent>;
}

export function getCreateCreateSecondaryEventEpic(http: SgmeHttp): ThunkEpic {
  const api = createCreateSecondaryEventApi(http);
  return createCreateSecondaryEventEpic(api, thunks, neosActionCreators);
}

export function createCreateSecondaryEventEpic(
  api: CreateSecondaryEventApi,
  { createErrorToasterThunk }: Thunks,
  { loadTransactionAtVersion }: NeosActionCreators,
): ThunkEpic {
  return action$ =>
    action$.pipe(
      ofType('CREATE_SECONDARY_EVENT_REQUESTED'),
      mergeMap(({ secondaryEventCreationRequest: { primaryRfqId, secondaryEvents } }) => {
        const filteredOutLegEvents = secondaryEvents.filter(
          secondaryEvent => secondaryEvent?.eventType,
        );
        return api.createSecondaryEvent(primaryRfqId, filteredOutLegEvents).pipe(
          map(({ rfqUuid }) => {
            return loadTransactionAtVersion({ rfqId: rfqUuid, version: 'LATEST' });
          }),
          catchError((error: OnyxError) =>
            of(
              createErrorToasterThunk(
                { message: 'Error when creating the secondary event' },
                error,
              ),
            ),
          ),
        );
      }),
    );
}
