import type { Thunk } from '@/bootstrap/thunks';
import type { ChainOptions } from '@/neos/business/neosActions';
import type { ReferenceKey } from '../models';

export function createChangeReferenceBasisThunk(
  referenceKey: ReferenceKey,
  refBasis: number | undefined,
): Thunk {
  return function changeReferenceBasisThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { createBasisChangedAction },
      },
      thunks: {
        neos: { createRfqResetQuotesWithGivenUnderlyingThunk, createRequestRfqFairPricesThunk },
      },
    },
  ) {
    dispatch(createRfqResetQuotesWithGivenUnderlyingThunk(referenceKey));
    const state = getState();

    const chainOptions: ChainOptions = {
      success: {
        dispatchables: [createRequestRfqFairPricesThunk(referenceKey.rfqId)],
      },
    };

    const hasAlreadyFairPricesInRfq = selectors.hasRfqFairPrices(
      state,
      referenceKey.rfqId,
      selectors,
    );

    dispatch(
      createBasisChangedAction(
        referenceKey,
        refBasis,
        hasAlreadyFairPricesInRfq ? chainOptions : undefined,
      ),
    );
  };
}
