import type { DisplayTimeZone } from '@/neos/business/ui/userPreferences/userPreferencesUiModel';
import type { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { getAlertBlotterColumnDefinition } from './AlertBlotterColumnDefinitions';

import type { OnyxAlert } from '@/neos/business/alert/alertOnyxModel';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import type { GridOptions, RowDoubleClickedEvent } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import styles from './AlertMenuModal.module.scss';

export interface AlertMenuModalStateProps {
  isModalShown: boolean;
  pendingAlerts: OnyxAlert[];
  displayTimezone: DisplayTimeZone;
}

export interface AlertBlotterRowDoubleClickedEvent extends RowDoubleClickedEvent {
  data: OnyxAlert;
}

export interface AlertMenuModalDispatchProps {
  hideModal: () => void;
  openRfq: (event: AlertBlotterRowDoubleClickedEvent) => void;
}

export type AlertModalProps = AlertMenuModalDispatchProps & AlertMenuModalStateProps;

export const AlertMenuModalComponent: FC<AlertModalProps> = ({
  isModalShown,
  hideModal,
  pendingAlerts,
  openRfq,
  displayTimezone,
}) => {
  const gridOptions: GridOptions = {
    columnDefs: getAlertBlotterColumnDefinition(displayTimezone),
    sideBar: false,
    rowHeight: 32,
    onRowDoubleClicked: openRfq,
    defaultColDef: {
      resizable: true,
    },
  };

  return (
    <Modal show={isModalShown} onHide={() => hideModal()} dialogClassName="modal-550w" size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Pendings alerts</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          className={`${styles['grid']} ag-theme-sg-bootstrap ag-theme-sg-bootstrap-condensed`}
          data-e2e="alert-blotter"
        >
          <AgGridReact
            rowData={pendingAlerts}
            gridOptions={gridOptions}
            modules={[ClientSideRowModelModule]}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
