import type { Thunk } from '@/bootstrap/thunks';
import type { ExecutionKey } from '../../order/orderModel';

export function createRfqChangeIsRfqContainingInvalidListedExecStrategyThunk(
  rfqId: string,
  executionKey: ExecutionKey,
): Thunk {
  return function rfqChangeIsRfqContainingInvalidListedExecStrategyThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { rfqUiCrudActions, legUiCrudActions },
      },
    },
  ) {
    const appState = getState();

    const order = selectors.getOrderByLegId(
      appState.orderData,
      executionKey.rfqId,
      executionKey.legId,
    );

    const legAllocation = selectors.listedAllocationSelectors
      .selectObjects(appState.listedAllocationState, {
        legId: executionKey.legId,
      })
      .find(legAllocation => legAllocation.legId === executionKey.legId);
    const execSize = order?.execSize?.numberOfLots;
    const allocationExecSize = legAllocation?.numberOfLots;

    const isValidExecListedExecStrategy =
      order?.manual && Number.isInteger(execSize) && Number.isInteger(allocationExecSize);
    const isRfqContainingInvalidExecListedStrategySize = !isValidExecListedExecStrategy;

    return dispatch([
      rfqUiCrudActions.patchOrInsert(rfqId, {
        isRfqContainingInvalidExecListedStrategySize,
      }),
      legUiCrudActions.upsert(executionKey.legId, { isValidExecListedExecStrategy }),
    ]);
  };
}
