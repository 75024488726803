import type { FC } from 'react';
import type { GenerateAllocationsButtonModel } from './getGenerateAllocationsButtonModel';

export interface GenerateAllocationsButtonOwnProps {
  rfqId: string;
}

export interface GenerateAllocationsButtonStateProps {
  model: GenerateAllocationsButtonModel;
}

export interface GenerateAllocationsButtonDispatchProps {
  onButtonClicked: () => void;
}

type GenerateAllocationsButtonProps = GenerateAllocationsButtonOwnProps &
  GenerateAllocationsButtonStateProps &
  GenerateAllocationsButtonDispatchProps;

export const GenerateAllocationsButtonComponent: FC<GenerateAllocationsButtonProps> = ({
  model: { isDisabled },
  onButtonClicked,
}) => {
  return (
    <button
      className="btn btn-primary"
      data-e2e="apply-pre-allocations-button"
      onClick={() => onButtonClicked()}
      disabled={isDisabled}
    >
      Generate Allocations
    </button>
  );
};
