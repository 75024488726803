import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { isOtcProduct } from '@/neos/business/neosModel';
import { isDefined } from '@/util/undefinedAndNull/isDefined';
import { sum } from 'lodash';
import type { AllocationTooltip } from '../allocInfo/AllocationLegSize';

export function getDeltaStockQuantityStyleNameAndTooltipMessage(
  state: AppState,
  legId: string,
  selectors: Selectors,
): AllocationTooltip | undefined {
  const { numberOfLots, productId } = selectors.getLegData(state, legId);

  const product = selectors.getProduct(state, productId);

  if (numberOfLots === undefined || isOtcProduct(product)) {
    return undefined;
  }

  const activeDeltaStockListedAllocations = selectors
    .getDeltaStockListedAllocationsByLegId(state, legId)
    .filter(allocation => !allocation.isCancelled);

  if (
    !activeDeltaStockListedAllocations.length ||
    activeDeltaStockListedAllocations.every(({ numberOfLots }) => numberOfLots === undefined)
  ) {
    return undefined;
  }

  const deltaStockListedAllocationQuantitiesSum = sum(
    activeDeltaStockListedAllocations.map(({ numberOfLots }) => numberOfLots).filter(isDefined),
  );

  if (deltaStockListedAllocationQuantitiesSum < numberOfLots) {
    return { quantityStyleName: 'not-fully-crossed', tooltipMessage: 'Not Fully Crossed' };
  }
  if (deltaStockListedAllocationQuantitiesSum > numberOfLots) {
    return { quantityStyleName: 'over-crossed', tooltipMessage: 'Over Crossed' };
  }
  return undefined;
}
