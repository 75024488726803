import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import { Fragment } from 'react';

interface BrowserNotificationCloseIconDispatchProps {
  closeAllBrowserNotifications: () => void;
}

export type BrowserNotificationCloseIconProps = BrowserNotificationCloseIconDispatchProps;

export const BrowserNotificationCloseIconComponent = ({
  closeAllBrowserNotifications,
}: BrowserNotificationCloseIconProps) => (
  <Fragment>
    <SimpleNeosTooltip placement="left" id="shortcut-tooltip" message="Close all popup alerts">
      <button
        className="btn btn-xl btn-flat-primary btn-icon"
        onClick={closeAllBrowserNotifications}
        data-e2e="close-all-browser-notifications-icon"
      >
        <i className="icon">playlist_remove</i>
      </button>
    </SimpleNeosTooltip>
  </Fragment>
);
