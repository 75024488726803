import { createAddExecutionThunk } from './addExecutionThunk';
import { createChangeExecutionDateThunk } from './changeExecutionDateThunk';
import { createChangeExecutionTimeThunk } from './changeExecutionTimeThunk';
import { createChangeExecutionNumberOfLotsThunk } from './createChangeExecutionNumberOfLotsThunk';

export const executionThunks = {
  createAddExecutionThunk,
  createChangeExecutionTimeThunk,
  createChangeExecutionDateThunk,
  createChangeExecutionNumberOfLotsThunk,
};
