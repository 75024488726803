import type { RiskPredealCheck } from '@/neos/business/predealCheck/predealCheckModel';

export function getRiskDetails(risk: RiskPredealCheck) {
  const riskDirection = risk.type === 'CREDIT_CHECK_BID' ? 'Bid' : 'Ask';

  return {
    flags: risk.csa === undefined ? [] : [{ label: 'CSA', value: risk.csa }],
    messages: [
      { label: riskDirection, message: risk.message },
      { label: 'Url', message: risk.url },
    ],
    technicalMessages: [
      {
        label: `${riskDirection} iFlow ID`,
        message: risk.riskId,
      },
    ],
  };
}
