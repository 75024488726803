import { thunks } from '@/bootstrap/thunks';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { combineEpics } from 'redux-observable';
import { createUserInfoApi } from './userInfo/userInfoApis';
import { type UserInfoApi, retrieveUserInfo } from './userInfo/userInfoEpics';
import { createSaveStateToStorageEpic } from '@/common/business/helpRequest/saveStateToStorageEpic';

export function getCommonRootEpic(http: SgmeHttp) {
  const userInfoApi: UserInfoApi = createUserInfoApi(http);
  return combineEpics(retrieveUserInfo(userInfoApi, thunks), createSaveStateToStorageEpic(http));
}
