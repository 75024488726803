import type { AppCrashedAction, CriticalAppCrashedAction } from '../globalError/globalErrorActions';
import type { HelpRequestActions } from './helpRequestActions';
import { type HelpRequestState, defaultHelpRequestState } from './helpRequestState';

export const helpRequestReducer = (
  state: HelpRequestState = defaultHelpRequestState,
  action: HelpRequestActions | AppCrashedAction | CriticalAppCrashedAction,
): HelpRequestState => {
  switch (action.type) {
    case 'HELP_REQUEST_MODAL_DISPLAYED_ACTION':
      return {
        ...state,
        displayHelpRequestModal: action.isDisplayed,
        errorLocation: action.errorLocation,
        error: action.error,
      };
    case 'APP_CRASHED':
      return {
        ...state,
        displayHelpRequestModal: true,
        errorLocation: action.source,
        error: JSON.stringify(action.error),
      };
    default:
      return state;
  }
};
