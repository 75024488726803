import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { isVSwapProduct } from '../../../../../../../neos/business/neosModel';
import { type Field, getFieldRelevantProducts } from '../utils';

export interface ExpectedNModel {
  displayCompositionEmptyCell: boolean;
  expectedNs: Array<Field<number | undefined>>;
}

export function getExpectedNModel(
  state: AppState,
  strategyId: string,
  selectors: Selectors,
): ExpectedNModel {
  const { strategyType } = selectors.getStrategyData(state, strategyId);

  const { sameProductExpectedN, readOnlyProductExpectedN } = selectors.getStrategyDefinition(
    state.referenceData,
    strategyType,
  );

  const hasACompositionLeg = selectors.hasACompositionLeg(state, strategyId, selectors);

  const products = getFieldRelevantProducts(state, strategyId, sameProductExpectedN, selectors);

  const vSwapProducts = products.filter(isVSwapProduct);

  const expectedNs = vSwapProducts.map(({ uuid, underlyingId, maturity, expectedN }) => ({
    id: uuid,
    value: expectedN,
    isEditable: !readOnlyProductExpectedN && !!underlyingId && !!maturity,
  }));

  const displayCompositionEmptyCell =
    hasACompositionLeg && !sameProductExpectedN && !!expectedNs.length;

  return { expectedNs, displayCompositionEmptyCell };
}
