import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { Observable } from 'rxjs';
import type { ApplyWorkflowActionApi } from '../epics/applyNextUserActionEpic';
import type { OnyxTransaction } from '../neosOnyxModel';
import type { NextUserAction } from '../nextUserActions/nextUserActionsModel';

export const createApplyWorkflowActionApi = (http: SgmeHttp): ApplyWorkflowActionApi => ({
  applyWorkflowAction(
    nextUserAction: NextUserAction,
    onyxTransaction: OnyxTransaction,
  ): Observable<OnyxTransaction> {
    return http.post<OnyxTransaction>({
      url: nextUserAction.href,
      body: onyxTransaction,
    });
  },
});
