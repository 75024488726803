import { type FC, useEffect } from 'react';

import styles from './AccountCenter.module.scss';

export const AccountCenter: FC = () => {
  useEffect(() => {
    const myServicesLink = document.querySelector(
      '.sgwt-account-center-my-services',
    ) as HTMLAnchorElement | null;
    if (myServicesLink && myServicesLink.target !== '_blank') {
      myServicesLink.target = '_blank';
      myServicesLink.rel = 'noopener noreferrer';
    }
  });

  return (
    <div className={styles['account-center']}>
      <sgwt-account-center
        theme-switcher="true"
        mode="sg-markets"
        available-languages="en"
        authentication="sg-connect-v2"
        language="en"
        producer-code="sgm_eqd_flow"
      />
    </div>
  );
};
