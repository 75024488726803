import type { Selectors } from '@/bootstrap/selectors';
import { services } from '@/bootstrap/services';
import type { AppState } from '@/bootstrap/state';
import { flatten } from 'lodash';
import type { FromOnyxMappers, ToOnyxMappers } from '../../../mappers';
import type { StrategyScope } from '../../../neosModel';
import type {
  OnyxDeltaStockListedAllocation,
  OnyxLeg,
  OnyxListedAllocation,
  OnyxOtcAllocation,
} from '../../../neosOnyxModel';
import type { Feature } from '../feature/featureModel';
import {
  fromDeltaStockListedAllocationMappers,
  toDeltaStockListedAllocationMappers,
} from './deltaStockListedAllocation/deltaStockListedAllocationMapper';
import type { DeltaStockListedAllocation } from './deltaStockListedAllocation/deltaStockListedAllocationModel';
import type { LegData, ListedAllocation, OtcAllocation, Product } from './legModel';
import {
  fromListedAllocationMappers,
  toListedAllocationMappers,
} from './listedAllocation/listedAllocationMapper';
import {
  fromOtcAllocationMappers,
  toOtcAllocationMappers,
} from './otcAllocation/otcAllocationMapper';
import { fromProductMappers, toProductMappers } from './product/mappers';

export const toLegMappers = {
  mapToOnyxLeg,
  ...toProductMappers,
  ...toListedAllocationMappers,
  ...toOtcAllocationMappers,
  ...toDeltaStockListedAllocationMappers,
};
export const fromLegMappers = {
  mapFromOnyxLeg,
  ...fromProductMappers,
  ...fromListedAllocationMappers,
  ...fromOtcAllocationMappers,
  ...fromDeltaStockListedAllocationMappers,
};

export type MapToOnyxCommonAllocationResult<
  A extends OnyxListedAllocation | OnyxDeltaStockListedAllocation | OnyxOtcAllocation,
> = {
  activeOnyxAllocations: A[];
  cancelledOnyxAllocations: A[];
};

export interface MapFromOnyxLegResult {
  legData: LegData;
  product: Product;
  features: Feature[];
  listedAllocations: ListedAllocation[];
  otcAllocations: OtcAllocation[];
  deltaStockListedAllocations: DeltaStockListedAllocation[];
}

function mapFromOnyxLeg(
  onyxLeg: OnyxLeg,
  strategyId: string,
  mappers: FromOnyxMappers,
  strategyScope: StrategyScope,
): MapFromOnyxLegResult {
  const legData: LegData = mapFromOnyxLegToLegData(strategyId, onyxLeg);
  const { uuid: productId, product } = onyxLeg;
  const udlType = product.underlying?.type;
  const activeAllocations = onyxLeg.allocations ?? [];
  const cancelledAllocations = onyxLeg.cancelledAllocations ?? [];

  function getOtcActiveAndCancelledAllocations(): OtcAllocation[] {
    return [
      ...activeAllocations
        .filter(
          (allocation): allocation is OnyxOtcAllocation => allocation?.negotiationMode === 'OTC',
        )
        .map(onyxOtcAllocation =>
          mappers.mapFromOnyxOtcAllocation(onyxLeg.uuid, onyxOtcAllocation, 'active'),
        ),
      ...cancelledAllocations
        .filter(
          (allocation): allocation is OnyxOtcAllocation => allocation?.negotiationMode === 'OTC',
        )
        .map(onyxOtcAllocation =>
          mappers.mapFromOnyxOtcAllocation(onyxLeg.uuid, onyxOtcAllocation, 'cancelled'),
        ),
    ];
  }

  const otcAllocations = getOtcActiveAndCancelledAllocations();

  // delta index
  // rfq index
  // rfq stock
  function getListedActiveAndCancelledAllocations(): ListedAllocation[] {
    return [
      ...activeAllocations
        .filter(
          (allocation): allocation is OnyxListedAllocation =>
            allocation?.negotiationMode === 'LISTED',
        )
        .map(onyxListedAllocation =>
          mappers.mapFromOnyxListedAllocation(onyxLeg.uuid, onyxListedAllocation, 'active'),
        ),
      ...cancelledAllocations
        .filter(
          (allocation): allocation is OnyxListedAllocation =>
            allocation?.negotiationMode === 'LISTED',
        )
        .map(onyxListedAllocation =>
          mappers.mapFromOnyxListedAllocation(onyxLeg.uuid, onyxListedAllocation, 'cancelled'),
        ),
    ];
  }

  const isUnderlyingTypeStockLike =
    udlType === 'STOCK' || udlType === 'PREFERENCE_SHARE' || udlType === 'ETF' || udlType === 'ADR';

  const listedAllocations =
    udlType === 'INDEX' || (isUnderlyingTypeStockLike && strategyScope === 'RFQ')
      ? getListedActiveAndCancelledAllocations()
      : [];

  // delta stock
  function getDeltaStockListedActiveAndCancelledAllocations(): DeltaStockListedAllocation[] {
    return [
      ...activeAllocations
        .filter(
          (allocation): allocation is OnyxDeltaStockListedAllocation =>
            allocation?.negotiationMode === 'LISTED',
        )
        .map(onyxDeltaStockListedAllocations =>
          mappers.mapFromDeltaStockListedAllocations(
            onyxLeg.uuid,
            onyxDeltaStockListedAllocations,
            'active',
          ),
        ),
      ...cancelledAllocations
        .filter(
          (allocation): allocation is OnyxDeltaStockListedAllocation =>
            allocation?.negotiationMode === 'LISTED',
        )
        .map(onyxDeltaStockListedAllocations =>
          mappers.mapFromDeltaStockListedAllocations(
            onyxLeg.uuid,
            onyxDeltaStockListedAllocations,
            'cancelled',
          ),
        ),
    ];
  }

  const deltaStockListedAllocations =
    isUnderlyingTypeStockLike &&
    (strategyScope === 'DELTA_EXCHANGE' || strategyScope === 'DELTA_EXCHANGE_OTC')
      ? getDeltaStockListedActiveAndCancelledAllocations()
      : [];

  return {
    legData,
    ...mappers.mapFromOnyxProduct({
      onyxProduct: product,
      strategyId,
      legId: legData.uuid,
      productId,
      mappers,
    }),
    listedAllocations,
    otcAllocations,
    deltaStockListedAllocations,
  };
}

function mapFromOnyxLegToLegData(strategyId: string, onyxLeg: OnyxLeg): LegData {
  const notional = onyxLeg.negotiatedSize?.notional;
  const localNotional = onyxLeg.negotiatedSize?.localNotional ?? notional;
  return {
    uuid: onyxLeg.uuid,
    clientPosition: onyxLeg.clientPosition,
    activity: onyxLeg.activity,
    strategyId,
    isMaster: onyxLeg.master,
    clientWay: onyxLeg.clientWay,
    sgWay: onyxLeg.sgWay,
    productId: onyxLeg.uuid,
    notional: notional?.value,
    notionalUnit: notional?.unit,
    localNotional: localNotional?.value,
    localNotionalUnit: localNotional?.unit,
    numberOfLots: onyxLeg.negotiatedSize?.numberOfLots,
    quantity: onyxLeg.negotiatedSize.quantity,
    quoteId: onyxLeg.uuid,
    weight: onyxLeg.weight,
    initialWeight: onyxLeg.weight,
    fairPriceId: onyxLeg.uuid,
    sizeType: onyxLeg.negotiatedSize?.sizeType,
    varUnit: onyxLeg.negotiatedSize?.varUnit?.value,
    screenPrice: onyxLeg.screenPrice,
    totalExecutedSize: onyxLeg.totalExecutedSize,
    averageExecutedPrice: onyxLeg.averageExecutedPrice,
    secondaryEvent:
      onyxLeg.eventType === 'FULL_UNWIND' || onyxLeg.eventType === 'PARTIAL_UNWIND'
        ? onyxLeg.eventType
        : undefined,
    eventType: onyxLeg.eventType,
    legReferenceUuids: onyxLeg.legReferenceUuids,
    settlement: onyxLeg.settlement,
    taxCollection: onyxLeg.taxCollection,
    bookingId: onyxLeg.bookingId,
    brokerId: onyxLeg.broker?.id?.toString(),
    brokerCommission: onyxLeg.brokerCommission?.value,
    brokerCommissionCcy: onyxLeg.brokerCommission?.unit,
    brokerInfoVenue: onyxLeg.brokerInfo?.venue,
    brokerInfoTradingVenueTransactionId: onyxLeg.brokerInfo?.tradingVenueTransactionId,
    brokerInfoExecutionVenue: onyxLeg.brokerInfo?.executionVenue,
    brokerInfoTradingDateTime: onyxLeg.brokerInfo?.tradingDateTime,
  };
}

interface MapToOnyxLegParameters {
  state: AppState;
  rfqId: string;
  strategyId: string;
  legId: string;
  selectors: Selectors;
  mappers: ToOnyxMappers;
}

function mapToOnyxLeg({
  state,
  rfqId,
  strategyId,
  legId,
  selectors,
  mappers,
}: MapToOnyxLegParameters): OnyxLeg {
  const legData = selectors.getLegData(state, legId);
  const orders = selectors.getOrdersByLegId(state.orderData, rfqId, legId);
  const executionDetails = flatten(
    orders
      .filter(order => order.crossWay === 'AS_DEFINED')
      .map(order =>
        selectors.executionSelectors
          .selectObjects(state.execution, {
            orderId: order.uuid,
          })
          .map(execution => ({
            lastPrice: execution.lastPrice,
            numberOfLots: execution.size?.numberOfLots,
            execUuid: execution.uuid,
          })),
      ),
  );
  const {
    numberOfLots,
    quantity,
    uuid,
    productId,
    clientPosition,
    isMaster: isMasterLeg,
    weight,
    fairPriceId,
    notional,
    notionalUnit,
    sizeType,
    varUnit,
    screenPrice,
    totalExecutedSize,
    averageExecutedPrice,
    activity,
    eventType,
    secondaryEvent,
    legAssociations,
    legReferenceUuids,
    settlement,
    taxCollection,
    bookingId,
    brokerId,
    brokerCommission,
    brokerCommissionCcy,
    brokerInfoVenue,
    brokerInfoTradingVenueTransactionId,
    brokerInfoTradingDateTime,
    brokerInfoExecutionVenue,
  } = legData;
  const { clientWay } = selectors.getRfqData(state, rfqId);
  const { weight: strategyWeight } = selectors.getStrategyData(state, strategyId);
  const onyxQuote = mappers.mapToOnyxQuoteForLeg(state, legId, selectors);

  const onyxListedAllocs = mappers.mapToOnyxLegListedAllocations(state, rfqId, legId, selectors);

  const OnyxDeltaStockListedAllocation = mappers.mapToDeltaStockListedAllocations(
    state,
    legId,
    selectors,
  );

  const onyxOtcAllocs = mappers.mapToOnyxLegOtcAllocations(state, rfqId, legId, selectors);

  const activeOnyxAllocations = [
    ...onyxListedAllocs.activeOnyxAllocations,
    ...onyxOtcAllocs.activeOnyxAllocations,
    ...OnyxDeltaStockListedAllocation.activeOnyxAllocations,
  ];
  const cancelledOnyxAllocations = [
    ...onyxListedAllocs.cancelledOnyxAllocations,
    ...onyxOtcAllocs.cancelledOnyxAllocations,
    ...OnyxDeltaStockListedAllocation.cancelledOnyxAllocations,
  ];

  return {
    uuid,
    activity,
    clientPosition,
    master: isMasterLeg,
    clientWay: services.computeLegWay(strategyWeight, weight, clientWay),
    negotiatedSize: {
      numberOfLots,
      notional: notionalUnit || notional ? { unit: notionalUnit, value: notional } : undefined,
      sizeType,
      quantity,
      varUnit: varUnit ? { value: varUnit, unit: 'VU' } : undefined,
    },
    weight,
    quote: onyxQuote,
    product: mappers.mapToOnyxProduct(state, strategyId, productId, selectors, mappers),
    fairPrice: mappers.mapToOnyxFairPrice(state, fairPriceId, selectors),
    screenPrice,
    totalExecutedSize,
    averageExecutedPrice,
    legAssociations,
    legReferenceUuids,
    settlement,
    allocations: activeOnyxAllocations,
    cancelledAllocations: cancelledOnyxAllocations,
    // mapped to secondaryEvent to quickly fix prod issue, this may need to be changed later
    eventType: secondaryEvent ? secondaryEvent : eventType,
    executionDetails,
    taxCollection,
    bookingId,
    broker: brokerId ? { id: parseFloat(brokerId) } : undefined,
    brokerCommission:
      brokerCommission || brokerCommissionCcy
        ? {
            value: brokerCommission,
            unit: brokerCommissionCcy,
          }
        : undefined,
    brokerInfo:
      brokerInfoExecutionVenue ||
      brokerInfoTradingDateTime ||
      brokerInfoVenue ||
      brokerInfoTradingVenueTransactionId
        ? {
            executionVenue: brokerInfoExecutionVenue,
            tradingDateTime: brokerInfoTradingDateTime,
            venue: brokerInfoVenue,
            tradingVenueTransactionId: brokerInfoTradingVenueTransactionId,
          }
        : undefined,
  };
}
