import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import type { PrimeBroker } from '@/neos/business/referenceData/primeBroker/primeBrokerActions';
import { neosThunks } from '@/neos/business/thunks';
import type { FC } from 'react';
import { useDispatch } from 'react-redux';
import type { CommissionType } from '../../../../../../../../neos/business/neosModel';
import { getCancelledAllocationsModel } from '../../../getCancelledAllocationsModel';
import {
  ExecutionAllocationsComponent,
  type ExecutionAllocationsDispatchProps,
  type ExecutionAllocationsOwnProps,
  type ExecutionAllocationsStateProps,
} from './ExecutionAllocations';
import { getExecutionAllocationsModel } from './getExecutionAllocationsModel';

export const ExecutionAllocations: FC<ExecutionAllocationsOwnProps> = ownProps => {
  const dispatch = useDispatch<ExtendedDispatch>();
  const executionAllocationsModel = useAppSelector(state => ({
    model: getExecutionAllocationsModel(state, ownProps.executionKey, selectors),
  }));
  const cancelledExecutionAllocationsModel = getCancelledAllocationsModel(
    executionAllocationsModel.model.allocations,
  );

  const displayedAllocations = [
    ...cancelledExecutionAllocationsModel.activeAllocations,
    ...cancelledExecutionAllocationsModel.cancelledAllocations,
  ];

  const stateProps: ExecutionAllocationsStateProps = {
    ...executionAllocationsModel,
    displayedAllocations,
  };

  const dispatchProps = mapDispatchProps(dispatch, ownProps);

  return <ExecutionAllocationsComponent {...ownProps} {...stateProps} {...dispatchProps} />;
};

function mapDispatchProps(
  dispatch: ExtendedDispatch,
  { executionKey }: ExecutionAllocationsOwnProps,
): ExecutionAllocationsDispatchProps {
  return {
    onAllocationNumberOfLotsChanged: (allocationId: string, numberOfLots: number | undefined) => {
      dispatch([
        neosActionCreators.listedAllocationCrudActions.update(
          { ...executionKey, allocationId },
          { numberOfLots },
        ),
        neosThunks.createRfqChangeIsRfqContainingInvalidListedExecStrategyThunk(
          executionKey.rfqId,
          executionKey,
        ),
      ]);
    },
    onAllocationCommissionChanged: (allocationId: string, commission: number | undefined) => {
      dispatch(
        neosActionCreators.listedAllocationCrudActions.update(
          { ...executionKey, allocationId },
          { commission },
        ),
      );
    },
    onAllocationCommissionTypeChanged: (
      allocationId: string,
      commissionType: CommissionType | undefined,
    ) => {
      dispatch(
        neosActionCreators.listedAllocationCrudActions.update(
          { ...executionKey, allocationId },
          { commissionType },
        ),
      );
    },
    onAllocationBrokerChanged: (allocationId: string, broker: PrimeBroker | undefined) => {
      dispatch(
        neosActionCreators.listedAllocationCrudActions.update(
          { ...executionKey, allocationId },
          { broker },
        ),
      );
    },
    onAllocationClearerAccountChanged: (
      allocationId: string,
      clearerAccount: string | undefined,
    ) => {
      dispatch(
        neosActionCreators.listedAllocationCrudActions.update(
          { ...executionKey, allocationId },
          { clearerAccount },
        ),
      );
    },
    onAllocationRemoved: (allocationId, allocationBookingId) => {
      dispatch(
        neosThunks.createDeleteListedAllocationThunk(
          executionKey,
          allocationId,
          allocationBookingId,
        ),
      );
    },
    onBookingIdChanged: (allocationId, bookingId) =>
      dispatch([
        neosActionCreators.listedAllocationCrudActions.update(
          { ...executionKey, allocationId },
          {
            externalReferenceId: {
              application: 'XONE',
              id: bookingId,
            },
          },
        ),
      ]),
  };
}
