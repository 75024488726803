import type { Thunk } from '@/bootstrap/thunks';

export function createInternalPreconfirmationPreviewAttachmentThunks(rfqId: string): Thunk {
  return function preconfirmationPreviewAttachmentThunks(
    dispatch,
    _,
    {
      actionCreators: {
        neos: { internalPreconfirmationPreviewAction, rfqUiCrudActions },
      },
    },
  ) {
    dispatch(
      rfqUiCrudActions.patchOrInsert(rfqId, { isPreconfirmationButtonsDisabled: true }),
      internalPreconfirmationPreviewAction(rfqId),
    );
  };
}
