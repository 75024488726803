import type { AppState } from '@/bootstrap/state';
import { useSelector } from 'react-redux';
import { StepsCommonInfo } from '../stepsCommonInfo';
import { ElsFeatures } from '../strategies/strategy/features/elsFeatures/ElsFeatures';

import { selectors } from '@/bootstrap/selectors';
import styles from './ElsDetails.module.scss';

type ElsDetailsProps = {
  rfqId: string;
};

export const ElsDetails = ({ rfqId }: ElsDetailsProps) => {
  const strategy = useSelector((state: AppState) => selectors.getRfqMasterStrategy(state, rfqId));

  return (
    <div className={`${styles['neos-content']}`}>
      <div className={`${styles['neos-nego-content']}`} data-e2e="neos-els-details">
        <StepsCommonInfo rfqId={rfqId} />
        <div className={`${styles['nego-body']}`}>
          <ElsFeatures rfqId={rfqId} strategyId={strategy.uuid} isElsDetails={true} />
        </div>
      </div>
    </div>
  );
};
