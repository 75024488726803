import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { connect } from 'react-redux';
import {
  LegDetailsButtonComponentWithCss,
  type LegDetailsButtonDispatchProps,
  type LegDetailsButtonOwnProps,
  type LegDetailsButtonPropsFromState,
} from './LegDetailsButton';

export const LegDetailsButton = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LegDetailsButtonComponentWithCss);

function mapStateToProps(
  state: AppState,
  { rfqId }: LegDetailsButtonOwnProps,
): LegDetailsButtonPropsFromState {
  return { showLegDetails: selectors.isLegDetailsToggleOn(state.ui, rfqId) };
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId }: LegDetailsButtonOwnProps,
): LegDetailsButtonDispatchProps {
  return {
    onToggleLegDetails: (showLegDetails: boolean) => {
      dispatch(
        createLogAnalyticsAction('NEOS RFQ', 'Leg details button hit'),
        neosActionCreators.rfqUiCrudActions.patchOrInsert(rfqId, { showLegDetails }),
      );
    },
  };
}
