import type { AppState } from '@/bootstrap/state';
import { useDispatch, useSelector } from 'react-redux';
import { getPreconfirmationModalModel } from './getPreconfirmationModalModel';

import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import {
  PreconfirmationModalComponent,
  type PreconfirmationModalDispatchProps,
  type PreconfirmationModalOwnProps,
  type PreconfirmationModalStateProps,
} from './PreconfirmationModal';

export const PreconfirmationModal = (ownProps: PreconfirmationModalOwnProps) => {
  const stateProps = useSelector<AppState, PreconfirmationModalStateProps>(state => ({
    model: getPreconfirmationModalModel(state, ownProps.rfqId, selectors),
  }));
  const dispatch = useDispatch<ExtendedDispatch>();
  const dispatchProps = getDispatchProps(dispatch);
  return <PreconfirmationModalComponent {...ownProps} {...stateProps} {...dispatchProps} />;
};

export function getDispatchProps(dispatch: ExtendedDispatch): PreconfirmationModalDispatchProps {
  return {
    onSendClicked: (rfqId: string, isExternalSelected: boolean, isInternalSelected: boolean) => {
      dispatch(
        neosThunks.createSendPreconfirmationEmailRequestedThunk(
          rfqId,
          isExternalSelected,
          isInternalSelected,
        ),
      );
    },
  };
}
