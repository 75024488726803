import type { Thunk } from '@/bootstrap/thunks';
import { defaultNestedRfqs } from '../nestedRfqs/nestedRfqsModel';

export function createNeosCloneRfqThunk(rfqSourceId: string): Thunk {
  return function neosCloneRfqThunk(
    dispatch,
    _,
    {
      thunks: {
        neos: { createCloneRfqThunk, createUiRfqClonedThunk, createUpsertReferencedRfqThunk },
      },
      actionCreators: {
        neos: { nestedRfqsCrudActions, pendingPredealChecksCrudActions },
      },
      services: { idCreator },
    },
  ) {
    const rfqTargetId: string = idCreator.createId();
    dispatch(
      nestedRfqsCrudActions.insert(rfqTargetId, defaultNestedRfqs),
      pendingPredealChecksCrudActions.insert(rfqTargetId, { pending: [] }),
      createCloneRfqThunk({ rfqTargetId, rfqSourceId }),
      createUiRfqClonedThunk({ rfqTargetId, rfqSourceId }),
      createUpsertReferencedRfqThunk(rfqTargetId, rfqTargetId),
    );
  };
}
