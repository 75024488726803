import { selectors } from '@/bootstrap/selectors';
import { type CommissionType, CommissionTypeValues } from '@/neos/business/neosModel';
import type { PrimeBroker } from '@/neos/business/referenceData/primeBroker/primeBrokerActions';
import { NeosBlurInput } from '@/neos/components/share/blurComponent/NeosBlurInput';
import { ErrorHighlightWithTooltip } from '@/neos/components/share/errorHighlightWithTooltip/ErrorHighlightWithTooltip.container';
import { NumericInput } from '@/neos/components/share/numericInput';

import { useAppSelector } from '@/bootstrap/hooks';
import type { ExecutionKey } from '@/neos/business/order/orderModel';
import { NeosBookingId } from '@/neos/components/share/NeosBookingId/NeosBookingId';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';
import { FormSelect } from 'react-bootstrap';
import styles from './ExecutionAllocations.module.scss';
import { TotalCommissionAmount } from './TotalCommissionAmount';
import { getCommissionsModel } from './getCommissionsModel';
import type {
  ExecutionAllocationsModel,
  ListedAllocationWithBookingInfo,
} from './getExecutionAllocationsModel';
import { getPrimeBrokersModel } from './getPrimeBrokersModel';

export interface ExecutionAllocationsOwnProps {
  executionKey: ExecutionKey;
  rfqId: string;
}

export type ExecutionAllocationsStateProps = {
  model: ExecutionAllocationsModel;
  displayedAllocations: ListedAllocationWithBookingInfo[];
};

export interface ExecutionAllocationsDispatchProps {
  onAllocationClearerAccountChanged: (allocationId: string, value: string | undefined) => void;
  onAllocationBrokerChanged: (allocationId: string, broker: PrimeBroker | undefined) => void;
  onAllocationNumberOfLotsChanged: (allocationId: string, value: number | undefined) => void;
  onAllocationCommissionChanged: (allocationId: string, value: number | undefined) => void;
  onAllocationCommissionTypeChanged: (
    allocationId: string,
    value: CommissionType | undefined,
  ) => void;
  onAllocationRemoved: (allocationId: string, allocationBookingId: string | undefined) => void;
  onBookingIdChanged: (uuid: string, value?: string) => void;
}

type ExecutionAllocationsProps = ExecutionAllocationsOwnProps &
  ExecutionAllocationsStateProps &
  ExecutionAllocationsDispatchProps;

export const ExecutionAllocationsComponent = ({
  model: { quantitiesStyleName, isValidExecStrategySize },
  displayedAllocations,
  executionKey,
  rfqId,
  onAllocationClearerAccountChanged,
  onAllocationBrokerChanged,
  onAllocationNumberOfLotsChanged,
  onAllocationCommissionChanged,
  onAllocationCommissionTypeChanged,
  onAllocationRemoved,
  onBookingIdChanged,
}: ExecutionAllocationsProps) => {
  const areCommissionFieldsDisplayed = useAppSelector(state =>
    getCommissionsModel(state, executionKey.rfqId, selectors),
  );
  const { primeBrokers, arePrimeBrokersDisplayed } = useAppSelector(state =>
    getPrimeBrokersModel(state, executionKey.rfqId, selectors),
  );
  const isTrader = useAppSelector(state => selectors.isTrader(state));

  return displayedAllocations.length ? (
    <>
      <section className="d-flex flex-column">
        {displayedAllocations.map(({ uuid: allocationId, isCancelled, bookingId }) => (
          <button
            className="btn btn-icon btn-flat-primary"
            key={allocationId}
            onClick={() => onAllocationRemoved(allocationId, bookingId)}
            data-e2e="execution-remove-allocation"
            disabled={isTrader || isCancelled}
          >
            <i className="icon icon-md">{isCancelled ? 'clear' : 'delete_forever'}</i>
          </button>
        ))}
      </section>
      {arePrimeBrokersDisplayed && primeBrokers && (
        <section>
          {displayedAllocations.map(({ uuid: allocationId, broker, isCancelled }) => (
            <FormSelect
              key={allocationId}
              value={broker?.id ?? ''}
              readOnly={isTrader || isCancelled}
              onChange={event =>
                onAllocationBrokerChanged(
                  allocationId,
                  primeBrokers.find(b => b.id === +event.target.value),
                )
              }
              data-e2e="allocation-clearer"
            >
              <option value=""></option>
              {primeBrokers.map(broker => (
                <option key={broker.id} value={broker.id}>
                  {broker.name}
                </option>
              ))}
            </FormSelect>
          ))}
        </section>
      )}
      <section>
        {displayedAllocations.map(({ uuid: allocationId, clearerAccount, isCancelled }) => (
          <div key={allocationId}>
            <NeosBlurInput
              readOnly={isTrader || isCancelled}
              type="text"
              value={clearerAccount ?? ''}
              onBlur={event =>
                onAllocationClearerAccountChanged(allocationId, event.target.value ?? undefined)
              }
              data-e2e="allocation-clearer-account"
            />
          </div>
        ))}
      </section>
      <section>
        <div className={`${quantitiesStyleName ? styles[quantitiesStyleName] : ''}`}>
          {displayedAllocations
            .filter(allocation => !allocation.isCancelled)
            .map(({ uuid: allocationId, numberOfLots: allocationNumberOfLots }) => (
              <ErrorHighlightWithTooltip
                key={allocationId}
                errorField={'numberOfLots'}
                related={{ rfqId: executionKey.rfqId, legId: executionKey.legId, strategyId: '' }}
                id={executionKey.executionId}
                isDisplayed={!isValidExecStrategySize}
                renderMessage={() => <p>Must be integer</p>}
                componentClassName="warning-bloc field-error"
                tooltipClassName={'react-bootstrap-danger-tooltip'}
              >
                <NumericInput
                  readOnly={isTrader}
                  key={allocationId}
                  value={allocationNumberOfLots}
                  onBlur={value => onAllocationNumberOfLotsChanged(allocationId, value)}
                  data-e2e="allocation-quantity"
                />
              </ErrorHighlightWithTooltip>
            ))}
        </div>
        <div>
          {displayedAllocations
            .filter(allocation => allocation.isCancelled)
            .map(({ uuid: allocationId, numberOfLots: allocationNumberOfLots }) => (
              <NumericInput
                readOnly
                key={allocationId}
                value={allocationNumberOfLots}
                data-e2e="allocation-quantity"
              />
            ))}
        </div>
      </section>
      <section>
        {displayedAllocations.map(
          ({ bookingId, defaultBookingId, bookingStatus, uuid, isCancelled }) => (
            <NeosBookingId
              key={uuid}
              bookingId={bookingId}
              bookingStatus={bookingStatus}
              onBookingIdChanged={value => onBookingIdChanged(uuid, value)}
              dataE2e="execution-booking-id"
              isReadOnly={isTrader || isCancelled}
              overlayTriggerPlacement="top"
              defaultBookingId={defaultBookingId}
            />
          ),
        )}
      </section>
      {areCommissionFieldsDisplayed && (
        <>
          <section>
            {displayedAllocations.map(({ uuid: allocationId, commission, isCancelled }) => (
              <NumericInput
                readOnly={isTrader || isCancelled}
                key={allocationId}
                value={commission}
                onBlur={value => onAllocationCommissionChanged(allocationId, value)}
                data-e2e="allocation-commission"
              />
            ))}
          </section>
          <section>
            {displayedAllocations.map(({ uuid: allocationId, commissionType, isCancelled }) => (
              <NeosSelect
                readOnly={isTrader || isCancelled}
                key={allocationId}
                value={commissionType}
                onChange={value => onAllocationCommissionTypeChanged(allocationId, value)}
                data-e2e="allocation-commission-type"
                addEmptyOption
                options={CommissionTypeValues}
              />
            ))}
          </section>
          <section>
            {displayedAllocations.map(allocation => (
              <TotalCommissionAmount key={allocation.uuid} rfqId={rfqId} allocation={allocation} />
            ))}
          </section>
        </>
      )}
    </>
  ) : null;
};
