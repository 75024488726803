import type { Action } from '@/bootstrap/actions';
import type { Thunk } from '@/bootstrap/thunks';
import type { DividendFeature } from '../../../strategyOnyxModel';
import type { DividendComponentFeatureKey } from '../../featureModel';
import type { DividendPeriod } from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel.ts';

export function createRemoveDividendSchedulePeriodThunk(
  featureKey: DividendComponentFeatureKey,
  uuid: string,
): Thunk {
  return function removeDividendSchedulePeriodThunk(
    dispatch,
    getState,
    {
      selectors: { getFeature },
      actionCreators: {
        neos: { featureCrudActions },
      },
    },
  ) {
    const appState = getState();
    const feature: DividendFeature | undefined = getFeature(appState.featureState, featureKey);

    if (!feature) {
      throw new Error(`This feature is not defined`);
    }

    const periods: DividendPeriod[] = feature?.dividendSchedulePeriods ?? [];

    const action: Action = featureCrudActions.update(featureKey, {
      dividendSchedulePeriods: periods.filter(line => line.uuid !== uuid),
    });
    dispatch(action);
  };
}
