import type { Thunk } from '@/bootstrap/thunks';
import type { ExternalMailOptions } from './externalOnyxPreconfirmationModel';

export function createExternalPreconfirmationEmailSendThunk(
  rfqId: string,
  externalMailOptions: ExternalMailOptions,
  ignoreFieldChanges: boolean,
): Thunk {
  return function internalPreconfirmationEmailSendThunk(
    dispatch,
    _,
    {
      actionCreators: {
        neos: { externalPreconfirmationEmailSendAction, rfqUiCrudActions },
      },
    },
  ) {
    dispatch(
      rfqUiCrudActions.patchOrInsert(rfqId, { isPreconfirmationButtonsDisabled: true }),
      externalPreconfirmationEmailSendAction(rfqId, externalMailOptions, ignoreFieldChanges),
    );
  };
}
