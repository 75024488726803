import type { Thunk } from '@/bootstrap/thunks';
import type { DeltaStockListedAllocationKey } from './deltaStockListedAllocationModel';

export function createDeleteDeltaStockListedAllocationThunk(
  deltaStockListedAllocationKey: DeltaStockListedAllocationKey,
  allocationBookingId: string | undefined,
): Thunk {
  return function deleteDeltaStockListedlocationThunk(dispatch, _, { actionCreators }) {
    const hasAllocationBookingId = allocationBookingId !== undefined;

    if (hasAllocationBookingId) {
      dispatch(
        actionCreators.neos.deltaStockListedAllocationCrudActions.update(
          deltaStockListedAllocationKey,
          {
            isCancelled: true,
          },
        ),
      );
    } else {
      dispatch(
        actionCreators.neos.deltaStockListedAllocationCrudActions.delete(
          deltaStockListedAllocationKey,
        ),
      );
    }
  };
}
