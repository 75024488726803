export interface QuoteNotificationChangesKey {
  rfqId: string;
  id: string;
}

const compositeIdSeparator = ' | ';

export function getQuoteNotificationChangesId({ rfqId, id }: QuoteNotificationChangesKey): string {
  return `${rfqId}${compositeIdSeparator}${id}`;
}

export function getQuoteNotificationChangesKey(stringId: string): QuoteNotificationChangesKey {
  const [rfqId, id] = stringId.split(compositeIdSeparator);

  return {
    rfqId,
    id,
  };
}
