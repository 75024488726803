import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { createChangeFeatureFieldThunk } from '@/neos/business/rfq/strategy/feature/thunks/changeFeatureFieldThunk';
import { createAddExtraFeatureThunk } from '@/neos/business/rfq/strategy/thunks/addExtraFeature';
import { connect } from 'react-redux';
import {
  CapComponent,
  type CapDispatchProps,
  type CapMapStateToProps,
  type CapOwnProps,
} from './Cap';
import { getCapModel } from './getCapModel';

export const Cap = connect(mapStateToProps, mapDispatchToProps)(CapComponent);

function mapStateToProps(state: AppState, { rfqId }: CapOwnProps): CapMapStateToProps {
  return {
    eligibleStrategyIds: getCapModel(state, rfqId, selectors),
  };
}

function mapDispatchToProps(dispatch: ExtendedDispatch): CapDispatchProps {
  return {
    onClick: (eligibleStrategyIds: string[]) => {
      eligibleStrategyIds.forEach(strategyId => {
        dispatch(
          createAddExtraFeatureThunk(strategyId, 'CAP_FLOOR'),
          createChangeFeatureFieldThunk({ strategyId, type: 'CAP_FLOOR' }, { capValue: 2.5 }),
        );
      });
    },
  };
}
