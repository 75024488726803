import type { Way } from '../../../../neos/business/neosModel';

export function getAskHighlight(clientWay: Way | undefined, weight: number): string {
  if (clientWay === 'BUY') {
    if (weight === -1) {
      return 'transparent';
    }
    return '';
  }

  if (clientWay === 'SELL') {
    if (weight === 1) {
      return 'transparent';
    }
    return '';
  }

  return '';
}

export function getBidHighlight(clientWay: Way | undefined, weight: number): string {
  if (clientWay === 'BUY') {
    if (weight === 1) {
      return 'transparent';
    }
    return '';
  }

  if (clientWay === 'SELL') {
    if (weight === -1) {
      return 'transparent';
    }
    return '';
  }

  return '';
}

export function getAskColor(weight: number) {
  return weight === 1 ? 'ask-color' : 'bid-color';
}

export function getBidColor(weight: number) {
  return weight === 1 ? 'bid-color' : 'ask-color';
}

export function getBidClassName(clientWay: Way | undefined, weight: number) {
  return `${getBidColor(weight)} ${getBidHighlight(clientWay, weight)}`;
}

export function getAskClassName(clientWay: Way | undefined, weight: number) {
  return `${getAskColor(weight)} ${getAskHighlight(clientWay, weight)}`;
}
