import type { ContactsRequestedAction } from './contactActions';

export const contactActioncreators = {
  createContactsRequestedAction,
};

export function createContactsRequestedAction(
  rfqId: string,
  counterpartId: number,
  salesValoEmail: string,
): ContactsRequestedAction {
  return {
    type: 'CONTACT_LIST_REQUESTED',
    rfqId,
    counterpartId,
    salesValoEmail,
  };
}
