import { formatMSToHumanTime } from '@/util/date/formatMSToHumanTime';
import { type FC, useState } from 'react';
import styles from './CommonTimer.module.scss';

export type TimerState = 'STARTED' | 'ENDED' | 'BEFORE_ENDED';
export interface CommonTimerProps {
  remainingTime: number;
  duration: number;
  timerName: string;
  onTimerStateChanged?: (status: TimerState) => void;
  beforeEndedThresholdInMinutes?: number;
}

export const CommonTimer: FC<CommonTimerProps> = ({
  remainingTime,
  duration,
  timerName,
  onTimerStateChanged,
  beforeEndedThresholdInMinutes,
}) => {
  const [isStarted, setIsStarted] = useState(false);
  const [isEnded, setIsEnded] = useState(false);
  const [isBeforeEnded, setIsBeforeEnded] = useState<boolean>(false);
  const isRemainingAboveThreshold = remainingTime >= (beforeEndedThresholdInMinutes ?? 1) * 60000;
  if (onTimerStateChanged) {
    if (!isStarted && remainingTime > 0) {
      onTimerStateChanged('STARTED');
      setIsStarted(true);
    }

    if (isStarted && !isBeforeEnded && !isRemainingAboveThreshold) {
      onTimerStateChanged('BEFORE_ENDED');
      setIsBeforeEnded(true);
    }

    if (isStarted && !isEnded && remainingTime === 0) {
      onTimerStateChanged('ENDED');
      setIsEnded(true);
    }
  }

  const currentProgress = duration === 0 ? 0 : Math.max((remainingTime / duration) * 100, 0);
  const color = isRemainingAboveThreshold ? 'success' : remainingTime > 0 ? 'warning' : 'danger';
  const displayedTime = formatMSToHumanTime(remainingTime);

  return (
    <div className="d-flex mb-0 me-2 align-items-center">
      <label className="mb-0 me-1">{timerName}</label>
      <div
        className={`${styles['mdpRfqTimer-container']} progress progress-lg`}
        role="progressbar"
        aria-label={displayedTime}
        aria-valuenow={currentProgress}
        aria-valuemin={0}
        aria-valuemax={100}
      >
        <div style={{ width: `${currentProgress}%` }} className={`progress-bar bg-${color}`}>
          <span className="progress-bar-label text-primary fw-bold">
            <div
              className="text-primary fw-bold ps-1 text-start"
              data-testid="common-timer-displayed-time"
              data-e2e="common-timer-displayed-time"
            >
              {displayedTime}
            </div>
          </span>
        </div>
      </div>
    </div>
  );
};
