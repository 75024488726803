import { Fragment, useState } from 'react';
import { ConfirmModal } from '../../../../share/confirmModal';
import type { SaveButtonModel } from './getSaveButtonModel';

export interface SaveButtonOwnProps {
  rfqId: string;
}

export interface SaveButtonMapStateToProps {
  model: SaveButtonModel;
}

export interface SaveButtonDispatchProps {
  onSave(): void;
}

export type SaveButtonProps = SaveButtonOwnProps &
  SaveButtonMapStateToProps &
  SaveButtonDispatchProps;

export const SaveButtonComponent = ({ model, onSave }: SaveButtonProps) => {
  const [isModalShown, setIsModalShown] = useState<boolean>(false);
  if (!model.canSaveStatus) {
    return null;
  }
  return (
    <>
      <button
        key="button"
        data-e2e="neos-rfq-save"
        className="btn btn-primary"
        onClick={_ => {
          if (model.isConfirmable) {
            setIsModalShown(true);
          } else {
            onSave();
          }
        }}
        disabled={model.isDisabled}
      >
        <i className="icon icon-sm">save</i>
      </button>
      {model.isConfirmable && (
        <ConfirmModal
          key="modal"
          title={model.modalTitle}
          isModalShown={isModalShown}
          onHide={() => setIsModalShown(false)}
          onConfirm={() => {
            onSave();
          }}
        >
          <p>
            {model.modalBody.map((b, i) => (
              <Fragment key={i}>
                {b}
                <br />
              </Fragment>
            ))}
          </p>
        </ConfirmModal>
      )}
    </>
  );
};
