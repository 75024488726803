import type { Thunk } from '@/bootstrap/thunks';
import type { PriceWithUnit } from '@/neos/business/neosOnyxModel';
import { getSecondaryInfos, isLegIdPartOfASecondaryStrategy } from './secondaryInfosSelectors';

export function createUpdateSecondaryAmountDueThunk(
  allocationId: string,
  rfqId: string,
  legId: string,
  patch: Partial<PriceWithUnit>,
): Thunk {
  return function updateSecondaryAmountDueThunk(
    dispatch,
    getState,
    { selectors, actionCreators: { neos: neosActionCreators } },
  ) {
    const state = getState();
    if (!isLegIdPartOfASecondaryStrategy(legId, state, selectors)) {
      return;
    }
    const currentSecondaryInfo = getSecondaryInfos(legId, allocationId, rfqId, state, selectors);
    dispatch(
      neosActionCreators.otcAllocationCrudActions.update(
        { allocationId, rfqId, legId },
        {
          secondaryInfo: {
            ...currentSecondaryInfo,
            amountDue: {
              nominal: { ...currentSecondaryInfo?.amountDue?.nominal, ...patch },
              valueDate: currentSecondaryInfo?.amountDue?.valueDate,
            },
          },
        },
      ),
    );
  };
}
