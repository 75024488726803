import type { Thunk } from '@/bootstrap/thunks';
import type { UnderlyingChangedParameters } from '@/neos/business/underlyingInfo/underlyingInfoActions';
import { createUpdateStepInInfoThunk } from '@/neos/components/rfq/postNego/allocs/otcAllocations/secondaryAllocations/updateStepInInfoThunk';
import { getOtcAllocationsByLegId } from '../rfq/strategy/leg/otcAllocation/otcAllocationSelector';

export function createNeosChangeRfqUnderlyingThunk(
  rfqId: string,
  parameters: UnderlyingChangedParameters,
): Thunk {
  return function neosChangeRfqUnderlyingThunk(
    dispatch,
    getState,
    {
      selectors,
      thunks: {
        neos: {
          createChangeRfqUnderlyingThunk,
          createNeosLoadUnderlyingInfoAndChangeThunk,
          changeElsIsScheduleObsoleteThunk,
          changeClsIsScheduleObsoleteThunk,
        },
      },
    },
  ) {
    const { underlyingId, ignoreUndefined } = parameters;

    if (ignoreUndefined && !underlyingId) {
      return;
    }

    const appState = getState();

    const changeData = selectors.getUnderlyingChangeData(appState, rfqId, parameters, selectors);

    const areSecondaryFeatureTogglesEnabled =
      selectors.areEveryRfqProductsSecondaryFeatureTogglesEnabled(appState, rfqId, selectors);
    if (areSecondaryFeatureTogglesEnabled) {
      const legId =
        parameters.type === 'FROM_UNDERLYING_FIELD'
          ? parameters.legId
          : (parameters.selectedLegId ??
            selectors.getRfqLegIds(appState, rfqId, selectors)?.[0] ??
            '');
      const novationStepInSelected =
        selectors.isOtcLeg(appState, legId, selectors) &&
        selectors.getLegData(appState, legId).eventType === 'STEPIN';
      if (novationStepInSelected) {
        const allocations = getOtcAllocationsByLegId(appState, legId);
        allocations.forEach(allocation => {
          if (allocation.stepInInfo?.initialStrike) {
            dispatch(
              createUpdateStepInInfoThunk(allocation.uuid, rfqId, legId, {
                initialStrike: undefined,
              }),
            );
          }
        });
      }
    }

    if (parameters.type === 'FROM_UNDERLYING_FIELD') {
      const leg = selectors.getLegData(appState, parameters.legId);
      const product = selectors.getProduct(appState, leg.productId);
      dispatch(
        changeElsIsScheduleObsoleteThunk(rfqId, product.uuid, true),
        changeClsIsScheduleObsoleteThunk(rfqId, product.uuid, true),
      );
    } else if (parameters.isApplyToAll) {
      const products = selectors.getRfqProducts(appState, rfqId, selectors);
      products.forEach(product => {
        dispatch(
          changeElsIsScheduleObsoleteThunk(rfqId, product.uuid, true),
          changeClsIsScheduleObsoleteThunk(rfqId, product.uuid, true),
        );
      });
    } else if (parameters.selectedLegId !== undefined) {
      const leg = selectors.getLegData(appState, parameters.selectedLegId);
      const product = selectors.getProduct(appState, leg.productId);
      dispatch(
        changeElsIsScheduleObsoleteThunk(rfqId, product.uuid, true),
        changeClsIsScheduleObsoleteThunk(rfqId, product.uuid, true),
      );
    }

    dispatch(
      createNeosLoadUnderlyingInfoAndChangeThunk(
        rfqId,
        underlyingId,
        createChangeRfqUnderlyingThunk(rfqId, changeData, underlyingId),
      ),
    );
  };
}
