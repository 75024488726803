import { commonThunks } from '@/common/business/commonThunks';
import type { FromOnyxMappers, ToOnyxMappers } from '@/neos/business/mappers';
import { neosThunks } from '@/neos/business/thunks';
import type { ActionContainer } from '@/util/actionContainer';
import type { ActionCreators } from './actions';
import type { Selectors } from './selectors';
import type { Services } from './services';
import type { AppState } from './state';
import { toasterThunks } from './toasterThunks';

export interface ThunkExtraArgument {
  selectors: Selectors;
  actionCreators: ActionCreators;
  services: Services;
  thunks: Thunks;
  toMappers: ToOnyxMappers;
  fromMappers: FromOnyxMappers;
}

export interface ExtendedDispatch {
  (...action: Dispatchable[]): unknown;
  (action: ActionContainer): unknown; // hack for react-reduct connect
}

export type Thunk = (
  dispatch: ExtendedDispatch,
  getState: () => AppState,
  extraArgument: ThunkExtraArgument,
) => any;

export const thunks = {
  ...toasterThunks,
  neos: neosThunks,
  common: commonThunks,
};

export type Thunks = typeof thunks;
export type NeosThunks = typeof neosThunks;

export type Dispatchable = ActionContainer | Thunk | Dispatchable[];
