import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { PredealCheck } from '../predealCheck/predealCheckModel';

export function getNeosSelectedPredealCheck(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): PredealCheck | undefined {
  const selectedPredealCheckId = selectors.getUiSelectedPredealCheckId(state.ui, rfqId);
  return selectedPredealCheckId
    ? selectors.getPredealCheck(state, selectedPredealCheckId)
    : undefined;
}
