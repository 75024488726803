import { useAppSelector } from '@/bootstrap/hooks';
import { CreateSecondaryButton } from '@/neos/components/rfq/strategies/globalActions/secondaryEventWidget/createSecondary/CreateSecondaryButton';
import { RfqSecondaryWidgetToggle } from '@/neos/components/rfq/strategies/globalActions/secondaryEventWidget/toggle/RfqSecondaryWidgetToggle';
import { RfqSecondaryTypeWidget } from '@/neos/components/rfq/strategies/globalActions/secondaryEventWidget/widget/RfqSecondaryTypeWidget';
import { selectors } from '@/bootstrap/selectors';

export type RfqSecondaryWidgetSectionProps = {
  rfqId: string;
};

export const RfqSecondaryWidgetSection = ({ rfqId }: RfqSecondaryWidgetSectionProps) => {
  const elsSecondaryToggle = useAppSelector(state =>
    selectors.isSecondaryFeatureToggleEnabled(state),
  );

  if (!elsSecondaryToggle) {
    return null;
  }

  return (
    <div className="d-flex gap-1">
      <RfqSecondaryWidgetToggle rfqId={rfqId} />
      <RfqSecondaryTypeWidget rfqId={rfqId} />
      <CreateSecondaryButton rfqId={rfqId} />
    </div>
  );
};
