import type {
  DfaPredealCheck,
  DfaSecPredealCheck,
} from '@/neos/business/predealCheck/predealCheckModel';

export function getDfaDetails(dfa: DfaPredealCheck) {
  return {
    flags: [
      { label: 'Eligible', value: dfa.eligible },
      { label: 'PT MMM required', value: dfa.preTradeMidMarketMarkRequired },
      { label: 'US Business', value: dfa.usBusiness },
      { label: 'SG Reporting Party', value: dfa.sgReportingParty },
    ],
    messages: [{ label: '', message: dfa.message }],
    technicalMessages: dfa.redKey
      ? [
          { label: 'RED ID', message: dfa.redKey.uuid },
          { label: 'VERSION ID', message: dfa.redKey.version },
          { label: 'Regulator', message: dfa.regulator },
          { label: 'Namespace', message: dfa.namespace },
          { label: 'Prior Namespace', message: dfa.priorNamespace },
          { label: 'USI', message: dfa.usi },
          { label: 'Prior USI', message: dfa.priorUsi },
        ]
      : [],
  };
}

export function getDfaSecDetails(dfa: DfaSecPredealCheck) {
  return {
    flags: [
      { label: 'Eligible', value: dfa.eligible },
      { label: 'US Business', value: dfa.usBusiness },
      { label: 'SG Reporting Party', value: dfa.sgReportingParty },
    ],
    messages: [{ label: '', message: dfa.message }],
    technicalMessages: dfa.redKey
      ? [
          { label: 'RED ID', message: dfa.redKey.uuid },
          { label: 'VERSION ID', message: dfa.redKey.version },
          { label: 'Regulator', message: dfa.regulator },
          { label: 'Namespace', message: dfa.namespace },
          { label: 'Prior Namespace', message: dfa.priorNamespace },
          { label: 'USI', message: dfa.usi },
          { label: 'Prior USI', message: dfa.priorUsi },
        ]
      : [],
  };
}
