import type { Preset } from '.';
import type { StrategyType } from '../../../../../neos/business/neosModel';

const STRATEGY_TYPES: Preset<StrategyType> = [
  {
    id: 'CALL',
    label: 'Call',
  },
  {
    id: 'PUT',
    label: 'Put',
  },
  {
    id: 'CALL_SPREAD',
    label: 'CS',
  },
  {
    id: 'PUT_SPREAD',
    label: 'PS',
  },
  {
    id: 'CALL_CALENDAR',
    label: 'CC',
  },
  {
    id: 'PUT_CALENDAR',
    label: 'PC',
  },
  {
    id: 'STRADDLE',
    label: 'Straddle',
  },
  {
    id: 'STRANGLE',
    label: 'Strangle',
  },
  {
    id: 'FUTURE',
    label: 'Fut',
  },
  {
    id: 'FORWARD',
    label: 'Fwd.',
  },
  {
    id: 'FUTURE_ROLL',
    label: 'Fut. Roll',
  },
  {
    id: 'ELS',
    label: 'ELS',
  },
];

const UNDERLYINGS: Preset<string> = [
  {
    id: 'SG_17405',
    label: 'SX5E',
  },
  {
    id: 'SG_17386',
    label: 'CAC',
  },
  {
    id: 'SG_17456',
    label: 'SX7E',
  },
  {
    id: 'SG_44110',
    label: 'FTSEMIB',
  },
  {
    id: 'SG_17486',
    label: 'SXAP',
  },
  {
    id: 'SG_17343',
    label: 'UKX',
  },
  {
    id: 'SG_17455',
    label: 'SXDP',
  },
  {
    id: 'SG_17387',
    label: 'DAX',
  },
  {
    id: 'SG_17485',
    label: 'SXPP',
  },
  {
    id: 'SG_17392',
    label: 'IBEX',
  },
];

export const EUROPE_PRESET = {
  STRATEGY_TYPES,
  UNDERLYINGS,
};
