import type { KeyboardEvent } from 'react';

interface CustomDateOnKeyDownInputProps {
  inputValue: string | null | Date;
  closeDatePicker: () => void | undefined;
  resetInput?: () => void;
  onCustomBlur?: (inputValue: string | null) => void;
  endRangeValue?: Date | null;
  onCustomRangeBlur?: (dates: [Date, Date | null]) => void | undefined;
}

export const useCustomDateOnKeyDownInput = ({
  inputValue,
  closeDatePicker,
  resetInput,
  onCustomBlur,
  endRangeValue,
  onCustomRangeBlur,
}: CustomDateOnKeyDownInputProps) => {
  const customOnKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Tab':
        closeDatePicker();
        break;
      case 'Enter':
        if (endRangeValue && onCustomRangeBlur) {
          onCustomRangeBlur([inputValue as Date, endRangeValue]);
        } else if (onCustomBlur) {
          onCustomBlur(inputValue as string);
          closeDatePicker();
        }
        break;
      case 'Escape':
        if (resetInput) {
          resetInput();
        }
        closeDatePicker();
        break;
    }
  };

  return { customOnKeyDown };
};
