import type { Thunk } from '@/bootstrap/thunks';
import type { UnderlyingChangeData } from '@/neos/business/neosSelectors/underlyingChangeData';

export function createChangeProductUnderlyingThunk(
  rfqId: string,
  { affectedStrategyId, affectedLegIds, affectedProductIds }: UnderlyingChangeData,
  underlyingId: string | undefined,
): Thunk {
  return function changeProductUnderlyingThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { productCrudActions },
      },
      thunks: {
        neos: {
          createUpdateProductAndResetDependenciesThunk,
          createDefaultProductLotSizeMarketThunk,
        },
      },
    },
  ) {
    affectedProductIds.forEach(productId => {
      dispatch(
        createUpdateProductAndResetDependenciesThunk(
          rfqId,
          affectedStrategyId,
          productId,
          'underlyingId',
          underlyingId,
        ),
      );
    });
    const state = getState();
    const isMasterAffected = affectedLegIds.some(
      legId => selectors.getLegData(state, legId).isMaster,
    );

    if (isMasterAffected) {
      const allProductIds = selectors.getStrategyProductIds(state, affectedStrategyId, selectors);
      dispatch(
        allProductIds.map(productId =>
          productCrudActions.update(productId, {
            maturity: undefined,
            strike: undefined,
            lotSize: undefined,
            marketExchangeId: undefined,
            marketMicCode: undefined,
          }),
        ),
      );
    }

    dispatch(createDefaultProductLotSizeMarketThunk(affectedProductIds));
  };
}
