import { type ActionCreators, actionCreators } from '@/bootstrap/actions';
import type { ThunkEpic } from '@/bootstrap/epics';
import { type Thunks, thunks } from '@/bootstrap/thunks';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { ofType } from 'redux-observable';
import type { Observable } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { type Selectors, selectors as allSelectors } from '../../../../bootstrap/selectors';
import { wrapInChainableLoadingObservable } from '../../epics/wrapInChainableLoadingObservable';
import { type ToOnyxMappers, mappers } from '../../mappers';
import { createRecomputeRfqApi } from '../apis/recomputeRfqApi';
import type { RecomputeScope } from '../models/recomputeModel';
import type { OnyxRfq } from '../rfqOnyxModel';

export interface RecomputeRfqApi {
  recomputeRfq: (rfq: OnyxRfq, scope: RecomputeScope) => Observable<OnyxRfq>;
}

export function getRecomputeRfqEpic(http: SgmeHttp): ThunkEpic {
  const api = createRecomputeRfqApi(http);
  return createRecomputeRfqEpic(api, allSelectors, actionCreators, thunks, mappers.toOnyxMappers);
}

export function createRecomputeRfqEpic(
  api: RecomputeRfqApi,
  selectors: Selectors,
  { common: { createAppCrashedAction } }: ActionCreators,
  { createErrorToasterThunk, neos: { createLoadRfqThunk } }: Thunks,
  toOnyxMappers: ToOnyxMappers,
): ThunkEpic {
  return (action$, state$) =>
    action$.pipe(
      ofType('RECOMPUTE_RFQ_REQUESTED'),
      mergeMap(({ rfqId, scope, chainOptions }) => {
        const rfq = toOnyxMappers.mapToOnyxRfqWithCurrentLocalVersion(
          state$.value,
          rfqId,
          selectors,
          toOnyxMappers,
        );

        return wrapInChainableLoadingObservable({
          tabIds: [rfqId],
          apiObservable: api.recomputeRfq(rfq, scope),
          observableOptions: {
            success: {
              on: recomputedRfq => [createLoadRfqThunk(recomputedRfq)],
            },
            error: {
              toaster: {
                creator: (message, error) => createErrorToasterThunk({ message }, error),
                message: 'Error during RFQ recompute',
              },
            },
          },
          chainOptions,
        });
      }),
      catchError(error => [createAppCrashedAction('recompute-rfq-epic', error)]),
    );
}
