import {
  isElsProduct,
  type StockLoanHedge,
} from '@/neos/business/rfq/strategy/leg/product/productModel.ts';
import type { Thunk } from '@/bootstrap/thunks.ts';

export function createUpdateElsStockLoanHedgePortfolioThunk(
  productId: string,
  value: StockLoanHedge['portfolio'],
): Thunk {
  return function updateElsStockLoanHedgePortfolioThunk(dispatch, getState, { selectors, thunks }) {
    const state = getState();
    const product = selectors.getProduct(state, productId);

    if (!isElsProduct(product)) {
      return;
    }

    const dispatchables = [
      thunks.neos.createUpdateElsStockLoanHedgeThunk(product.uuid, 'portfolio', value),
    ];

    const portfolioWay = selectors.getElsStockLoanHedgePortfolioWay(
      state,
      product.legId,
      selectors,
    );

    if (portfolioWay !== null) {
      product.stockLoanHedge?.stockLoanComponents.forEach((_, index) => {
        dispatchables.push(
          thunks.neos.createUpdateElsStockLoanComponentThunk(
            product.uuid,
            index,
            portfolioWay,
            value,
          ),
        );
      });
    }

    dispatch(dispatchables);
  };
}
