import type { RelatedExchangeAction } from '@/neos/business/referenceData/relatedExchange/relatedExchangeAction';
import type { RelatedExchange } from '@/neos/business/referenceData/relatedExchange/relatedExchangeModel';

export const relatedExchangeActionCreator = {
  createRelatedExchangeFieldsAction,
};

export function createRelatedExchangeFieldsAction(
  fields: RelatedExchange[],
): RelatedExchangeAction {
  return {
    type: 'RELATED_EXCHANGE_FIELDS_RECEIVED',
    payload: fields,
  };
}
