import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import type { FeatureKey } from '../../../../../../../neos/business/neosModel';
import {
  CapFloor as Component,
  type DispatchProps,
  type OwnProps,
  type StateProps,
} from './CapFloor';
import { getCapFloorModel } from './getCapFloorModel';

function mapStateToProps(state: AppState, { strategyId }: OwnProps): StateProps {
  return {
    model: getCapFloorModel(state, strategyId, selectors),
  };
}

function mapDispatchToProps(dispatch: ExtendedDispatch, { strategyId }: OwnProps): DispatchProps {
  const featureKey: FeatureKey = { strategyId, type: 'CAP_FLOOR' };
  return {
    onFloorChanged: (floorValue: number | undefined): void => {
      dispatch(neosThunks.createChangeFeatureFieldThunk(featureKey, { floorValue }));
    },
    onCapChanged: (capValue: number | undefined): void => {
      dispatch(neosThunks.createChangeFeatureFieldThunk(featureKey, { capValue }));
    },
  };
}

export const CapFloor = connect(mapStateToProps, mapDispatchToProps)(Component);
