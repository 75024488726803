import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { isDefined } from '@/util/undefinedAndNull/isDefined';

export function getSortedDeltaSummaries(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): string[] {
  return selectors
    .getSortedHedges(state, rfqId, selectors)
    .map(({ deltaSummary }) => deltaSummary)
    .filter(isDefined);
}
