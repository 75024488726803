import type { OnyxFieldError } from '../onyxErrorModel';

import { services } from '@/bootstrap/services';
import type { ErrorFieldMatcherResult } from './errorMapperModel';

export function getMatcherResults(fieldErrors: OnyxFieldError[]): ErrorFieldMatcherResult[] {
  const result: ErrorFieldMatcherResult[] = [];
  for (const fieldError of fieldErrors) {
    const knownFieldMatch = services.getKnownFieldMatch(fieldError);
    if (knownFieldMatch) {
      const { fieldName, groupResults } = knownFieldMatch;
      result.push({
        fieldName,
        groupResults,
        errorMessage: messageTranslator.get(fieldError.message) || fieldError.message,
      });
    }
  }
  return result;
}

const messageTranslator = new Map<string, string>([
  ['must not be null', 'This field is required!'],
  [
    'Delta type must be one of [DELTA_EXCHANGE, DELTA_EXCHANGE_OTC, DELTA_ADJUSTED, RISK]',
    'This field is required!',
  ],
]);
