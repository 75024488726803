export interface CapOwnProps {
  rfqId: string;
}

export interface CapMapStateToProps {
  eligibleStrategyIds: string[];
}

export interface CapDispatchProps {
  onClick: (eligibleStrategyIds: string[]) => void;
}

type CapProps = CapOwnProps & CapMapStateToProps & CapDispatchProps;

export const CapComponent = ({ eligibleStrategyIds, onClick }: CapProps) =>
  eligibleStrategyIds.length > 0 ? (
    <button
      className="btn btn-primary"
      data-e2e="cap-btn"
      onClick={() => onClick(eligibleStrategyIds)}
    >
      Cap
    </button>
  ) : null;
