import type { Thunk } from '@/bootstrap/thunks';
import { DefaultingScope, type PriceUnitType } from '@/neos/business/neosModel';
import { DefaultingOptions } from '../../rfqActions';

export function onAllocationIAToBeBookedCurrencyChangeThunk(
  legId: string,
  rfqId: string,
  allocationId: string,
  currency?: string,
  iaType?: PriceUnitType,
): Thunk {
  return function onAllocationIAToBeBookedCurrencyChange(dispatch, _, { actionCreators }) {
    dispatch(
      actionCreators.neos.otcAllocationCrudActions.update(
        { allocationId, rfqId, legId },
        {
          independantAmountCurrencyUnit: currency,
        },
      ),
    );

    if (iaType === 'REF_PERCENT' || iaType === 'MULTIPLIER') {
      const overrideScopes = [DefaultingScope.IA_VALUE_DATE, DefaultingScope.IA_CURRENCY];
      const enrichScopes = [DefaultingScope.FOREX];
      const options = new DefaultingOptions({ overrideScopes, enrichScopes });
      dispatch(actionCreators.neos.createDefaultRfqRequestedAction(rfqId, options));
    }
  };
}
