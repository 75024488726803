import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import styles from './AlertMenu.module.scss';

export interface AlertMenuDispatchProps {
  showAlertModal: () => void;
}

export interface AlertMenuStateProps {
  pendingAlertNumber: number;
  isAlertBlotterEnabled: boolean;
}

export type AlertMenuProps = AlertMenuDispatchProps & AlertMenuStateProps;

export const AlertMenuComponent = ({
  showAlertModal,
  pendingAlertNumber,
  isAlertBlotterEnabled,
}: AlertMenuProps) => (
  <>
    {isAlertBlotterEnabled && (
      <SimpleNeosTooltip id="alert-blotter-tooltip" placement="left" message="Pending alert(s)">
        <button
          className="btn btn-xl btn-flat-primary btn-icon"
          onClick={showAlertModal}
          data-e2e="alert-blotter-button"
        >
          {pendingAlertNumber !== 0 && (
            <span className={`${styles['badge']} badge bg-warning`}>{pendingAlertNumber}</span>
          )}
          <i className="icon">warning</i>
        </button>
      </SimpleNeosTooltip>
    )}
  </>
);
