import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type {
  ObservationConvention,
  UpDownFeatureKey,
} from '../../../../../../../neos/business/neosModel';
import type { FeatureComponentModel } from '../sharedFeatureComponentModel';

export type UpDownModel = FeatureComponentModel<{
  unit: string | undefined;
  upStrikeValue: number | undefined;
  downStrikeValue: number | undefined;
  availableObservationConventions: ObservationConvention[];
  observationConvention: ObservationConvention | undefined;
}>;

export function getUpDownModel(
  state: AppState,
  strategyId: string,
  selectors: Selectors,
): UpDownModel {
  const featureKey: UpDownFeatureKey = { strategyId, type: 'UP_DOWN' };
  const isDisplayed = selectors.isStrategyFeatureMandatoryOrDefined(state, featureKey, selectors);

  if (!isDisplayed) {
    return { isDisplayed };
  }

  const { strategyType } = selectors.getStrategyData(state, strategyId);
  const { legs } = selectors.getStrategyDefinition(state.referenceData, strategyType);
  const { availableObservationConventions } = legs[0];
  const feature = selectors.getFeature(state.featureState, featureKey);
  const {
    upStrikeValue,
    downStrikeValue,
    upStrikeUnit,
    downStrikeUnit,
    upObservationConvention,
    downObservationConvention,
  } = feature
    ? feature
    : {
        upStrikeValue: undefined,
        downStrikeValue: undefined,
        upStrikeUnit: undefined,
        downStrikeUnit: undefined,
        upObservationConvention: undefined,
        downObservationConvention: undefined,
      };
  const unit: string | undefined = upStrikeUnit || downStrikeUnit;
  const observationConvention: ObservationConvention | undefined =
    upObservationConvention || downObservationConvention;

  return {
    upStrikeValue,
    downStrikeValue,
    isDisplayed,
    availableObservationConventions,
    unit,
    observationConvention,
  };
}
