import type { Dispatchable } from '@/bootstrap/thunks';
import type {
  CounterpartRequest,
  CounterpartRequestedAction,
  CounterpartsRequestedAction,
} from './counterpartActions';

export const counterpartActioncreators = {
  createCounterpartsRequestedAction,
  createCounterpartRequestedAction,
};

export function createCounterpartsRequestedAction(
  requests: CounterpartRequest[],
  nextDispatchable?: Dispatchable,
): CounterpartsRequestedAction {
  return {
    type: 'COUNTERPART_LIST_REQUESTED',
    requests,
    nextDispatchable,
  };
}

export function createCounterpartRequestedAction(
  rfqId: string,
  bdrId: number,
  nextDispatchable?: Dispatchable,
): CounterpartRequestedAction {
  return {
    type: 'COUNTERPART_REQUESTED',
    bdrId,
    rfqId,
    nextDispatchable,
  };
}
