import type { FC } from 'react';

import { actionCreators } from '@/bootstrap/actions';
import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import type { AllocSecondaryEventCreation } from '@/neos/business/allocSecondaryEventCreation/allocSecondaryEventCreationDomain';
import { getOtcAllocationSecondaryTypeWidgetModel } from '@/neos/components/rfq/postNego/allocs/otcAllocations/otcAllocationFields/OtcAllocationSecondaryTypeWidget/getOtcAllocationSecondaryTypeWidgetModel';
import { InitNotEligibleIcon } from '@/neos/components/rfq/strategies/strategy/strategyDefinition/secondaryType/shared/SecondaryTypeCellsCommon';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import { useDispatch } from 'react-redux';
import { OtcAllocationSecondaryToggleButtons } from './OtcAllocationSecondaryToggleButtons';

type OtcAllocationSecondaryTypeWidgetProps = {
  rfqId: string;
  legId: string;
  allocationId: string;
};

export const OtcAllocationSecondaryTypeWidget: FC<OtcAllocationSecondaryTypeWidgetProps> = ({
  rfqId,
  legId,
  allocationId,
}) => {
  const dispatch = useDispatch();
  const model = useAppSelector(state =>
    getOtcAllocationSecondaryTypeWidgetModel(state, rfqId, legId, allocationId, selectors),
  );

  function onOtcAllocationSecondaryTypeChanged(
    eventType: AllocSecondaryEventCreation['eventType'],
  ) {
    dispatch(
      actionCreators.neos.allocSecondaryEventCreationCrudActions.upsert(
        { rfqId, allocationId },
        { eventType, primaryAllocUuid: allocationId },
      ),
    );
  }

  if (!model.isDisplayed) {
    return null;
  }

  if (!model.isEligible) {
    return <InitNotEligibleIcon legId={legId} key={`ini-not-eligible-icon-${legId}`} />;
  }

  return (
    <div className="d-flex">
      <SimpleNeosTooltip
        disable={model.isLegMaturityValid}
        id="secondary-type-button-group"
        message={'Impossible to unwind a leg with Maturity in the past'}
      >
        {model.isElsBasket ? (
          <div>Els Basket secondary events</div>
        ) : (
          <OtcAllocationSecondaryToggleButtons
            legId={legId}
            isLoading={model.isLoading}
            onEventTypeClick={eventType => onOtcAllocationSecondaryTypeChanged(eventType)}
            secondaryType={model.secondaryType}
            isLegMaturityValid={model.isLegMaturityValid}
          />
        )}
      </SimpleNeosTooltip>
    </div>
  );
};
