import type {
  InternalOnyxPreconfirmation,
  InternalOnyxPreconfirmationData,
} from './internalOnyxPreconfirmationModel';
import type { InternalPreconfirmation } from './internalPreconfirmationModel';

export function mapFromInternalOnyxPreconfirmation(
  internalOnyxPreconfirmation: InternalOnyxPreconfirmationData,
): InternalPreconfirmation {
  const { mailOptions, mailStatus } = internalOnyxPreconfirmation;

  const internalPreconfirmation: InternalPreconfirmation = {
    ...mailOptions,
    mailStatus,
  };

  return internalPreconfirmation;
}

export function mapToInternalOnyxPreconfirmation(internalPreconfirmation: InternalPreconfirmation) {
  const { mailStatus, emailComment, internalOther } = internalPreconfirmation;

  const internalOnyxPreconfirmation: InternalOnyxPreconfirmation = {
    internal: {
      mailStatus,
      mailOptions: {
        internalOther,
        emailComment,
      },
    },
  };
  return internalOnyxPreconfirmation;
}
