import type {
  CurrentUserPreferences,
  TradingBusinessType,
} from '@/neos/business/currentUserPreferences/currentUserPreferencesModel';
import type { PartialResetType } from '@/neos/business/rfq/strategy/leg/product/productOnyxModel';
import type { SwapsWireSgTraderAccountType } from '@/neos/business/rfq/strategy/strategyData/strategyDataModel';
import { convertNullToUndefined } from '@/util/undefinedAndNull/convertNullToUndefined';

export interface OnyxCurrentUserPreferences {
  defaultDeskId: Nullable<string>;
  swapswireAccountType: Nullable<SwapsWireSgTraderAccountType>;
  partialReset: Nullable<PartialResetType>;
  tradingBusiness: Nullable<TradingBusinessType>;
  swapswireAccount: Nullable<string>;
  swapswireAccountingCenter: Nullable<string>;
  city: Nullable<{
    cityId: number;
    name: string;
  }>;
  country: Nullable<{
    countryRefId: number;
    refId: string;
    name: string;
  }>;
  userEmail: Nullable<string>;
}

export function mapFromOnyxCurrentUserPreferences(
  userPreferences: OnyxCurrentUserPreferences,
): CurrentUserPreferences {
  return {
    swapswireAccountType: convertNullToUndefined(userPreferences.swapswireAccountType),
    country: convertNullToUndefined(userPreferences.country),
    partialReset: convertNullToUndefined(userPreferences.partialReset),
    tradingBusiness: convertNullToUndefined(userPreferences.tradingBusiness),
    defaultDeskId: convertNullToUndefined(userPreferences.defaultDeskId),
    swapswireAccount: convertNullToUndefined(userPreferences.swapswireAccount),
    city: convertNullToUndefined(userPreferences.city),
    swapswireAccountingCenter: convertNullToUndefined(userPreferences.swapswireAccountingCenter),
    userEmail: convertNullToUndefined(userPreferences.userEmail),
  };
}
