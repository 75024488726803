import type { Thunk } from '@/bootstrap/thunks';
import { DefaultingScope } from '@/neos/business/neosModel';
import { DefaultingOptions } from '../../rfqActions';

export function createOnAllocationIACurrencyChangedThunk(
  legId: string,
  rfqId: string,
  allocationId: string,
  newValue?: string,
): Thunk {
  return function onAllocationIACurrencyChangedThunk(dispatch, _, { actionCreators }) {
    dispatch(
      actionCreators.neos.otcAllocationCrudActions.update(
        { allocationId, rfqId, legId },
        {
          independantAmountUnit: newValue,
        },
      ),
    );
    const overrideScopes = [DefaultingScope.IA_CURRENCY, DefaultingScope.IA_VALUE_DATE];
    const enrichScopes = [DefaultingScope.FOREX];
    const options = new DefaultingOptions({ overrideScopes, enrichScopes });
    dispatch(actionCreators.neos.createDefaultRfqRequestedAction(rfqId, options));
    dispatch(
      actionCreators.neos.allocationUiCrudActions.patchOrInsert(allocationId, {
        errors: { IaType: false },
      }),
    );
  };
}
