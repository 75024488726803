import type {
  AddPreconfPrefGroupToLoadingGroupAction,
  AddUserPrefPreconfGroupAction,
  AddUserPrefPreconfGroupRequestedAction,
  DeleteUserPrefPreconfGroupAction,
  DeleteUserPrefPreconfGroupRequestedAction,
  RemovePreconfPrefGroupToLoadingGroupAction,
  UserPrefPreconfGroupsReceivedAction,
  UserPrefPreconfGroupsRequestedAction,
} from './preconfirmationPreferencesActions';

export const prefonfirmationPreferencesActionCreators = {
  loadUserPrefPreconfGroupsActionCreator,
  receivedUserPrefPreconfGroupsActionCreator,
  addUserPrefPreconfGroupsActionCreator,
  deleteUserPrefPreconfGroupsActionCreator,
  addUserPrefPreconfGroupsRequestedActionCreator,
  deleteUserPrefPreconfGroupsRequestedActionCreator,
  addPreconfPrefGroupToLoadingGroupAction,
  removePreconfPrefGroupFromLoadingGroupAction,
};

function loadUserPrefPreconfGroupsActionCreator(): UserPrefPreconfGroupsRequestedAction {
  return {
    type: 'USER_PREF_GROUPS_REQUESTED',
  };
}

function receivedUserPrefPreconfGroupsActionCreator(
  groupKeys: string[],
): UserPrefPreconfGroupsReceivedAction {
  return {
    type: 'USER_PREF_GROUPS_RECEIVED',
    groupKeys,
  };
}
function addUserPrefPreconfGroupsRequestedActionCreator(
  groupeKey: string,
): AddUserPrefPreconfGroupRequestedAction {
  return {
    type: 'ADD_USER_PREF_GROUP_REQUESTED',
    groupKey: groupeKey,
  };
}

function addUserPrefPreconfGroupsActionCreator(groupeKey: string): AddUserPrefPreconfGroupAction {
  return {
    type: 'ADD_USER_PREF_GROUP',
    groupKey: groupeKey,
  };
}

function deleteUserPrefPreconfGroupsRequestedActionCreator(
  groupKey: string,
): DeleteUserPrefPreconfGroupRequestedAction {
  return {
    type: 'DELETE_USER_PREF_GROUP_REQUESTED',
    groupKey,
  };
}

function deleteUserPrefPreconfGroupsActionCreator(
  groupKey: string,
): DeleteUserPrefPreconfGroupAction {
  return {
    type: 'DELETE_USER_PREF_GROUP',
    groupKey,
  };
}
function addPreconfPrefGroupToLoadingGroupAction(
  groupKey: string,
): AddPreconfPrefGroupToLoadingGroupAction {
  return {
    type: 'ADD_PRECONF_PREF_GROUP_TO_LOADING_GROUP',
    groupKey,
  };
}
function removePreconfPrefGroupFromLoadingGroupAction(
  groupKey: string,
): RemovePreconfPrefGroupToLoadingGroupAction {
  return {
    type: 'REMOVE_PRECONF_PREF_GROUP_FROM_LOADING_GROUP',
    groupKey,
  };
}
