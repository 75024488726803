import {
  type DetailedHTMLProps,
  type InputHTMLAttributes,
  forwardRef,
  useEffect,
  useState,
} from 'react';

export const NeosBlurInputWithDefaultValue = forwardRef<
  HTMLInputElement,
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    defaultValue: string | undefined;
  }
>((props, inputRef) => {
  const { defaultValue, ...restProps } = props;
  const [inputValue, setInputValue] = useState(props.value);

  useEffect(() => {
    setInputValue(props.value);
  }, [props.value]);

  return (
    <input
      ref={inputRef}
      {...restProps}
      value={inputValue ?? ''}
      onChange={e => {
        setInputValue(e?.target?.value);
      }}
      onBlur={e => {
        if (e.target.value === '' && props.defaultValue) {
          setInputValue(props.defaultValue);
        }
        props.onBlur?.(e);
      }}
    />
  );
});
