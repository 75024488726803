import type { ErrorableAllUi } from '@/neos/business/mappers/error/errorHandlerData';
import { omit } from 'lodash';
import type { ErrorHighlightOwnProps as ErrorHighlightComponentProps } from '../errorHighlight/ErrorHighlight';
import { ErrorHighlight } from '../errorHighlight/ErrorHighlightTyped';
import { NeosTooltip, type NeosTooltipProps } from '../tooltip/NeosTooltip';

type ErrorType = { discriminator: 'TOOLTIP' };
type TooltipType = {
  discriminator: 'ERROR';
};
export type TooltipProps = NeosTooltipProps & ErrorType;
export type ErrorHighlightProps<T extends ErrorableAllUi> = ErrorHighlightComponentProps<T> &
  TooltipType;

export type ErrorHighlightWithTooltipProps<T extends ErrorableAllUi> =
  | TooltipProps
  | ErrorHighlightProps<T>;

export function ErrorHighlightWithTooltipComponent<T extends ErrorableAllUi>(
  props: ErrorHighlightWithTooltipProps<T>,
) {
  if (props.discriminator === 'TOOLTIP') {
    const { children, ...restProps } = omit(props, 'discriminator');
    return <NeosTooltip {...restProps}>{children}</NeosTooltip>;
  }
  if (props.discriminator === 'ERROR') {
    const { children, ...restProps }: ErrorHighlightComponentProps<T> = omit(
      props,
      'discriminator',
    );
    return <ErrorHighlight {...restProps}>{children}</ErrorHighlight>;
  }
  return null;
}
