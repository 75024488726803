import type { AppState } from '@/bootstrap/state';
import { round } from 'lodash';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import type { DeltaType } from '../../../../../business/neosModel';
import { formatDeltaType, roundUndefined } from '../../../../share/formatters';
import { NumericInput } from '../../../../share/numericInput';
import type { HedgeModel, MixedDeltaTypes, UiDeltaType } from './getHedgeModel';

import { selectors } from '@/bootstrap/selectors';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import { FormSelect } from 'react-bootstrap';
import styles from './Hedge.module.scss';

export interface HedgeOwnProps {
  rfqId: string;
}

export type HedgeMapStateToProps = HedgeModel;

export interface HedgeDispatchProps {
  onDeltaChanged(
    underlyingId: string,
    deltaType: DeltaType | undefined,
    delta: number | undefined,
  ): void;

  onDeltaTypeChanged(underlyingId: string, deltaType: UiDeltaType): void;
}

export type HedgeProps = HedgeOwnProps & HedgeMapStateToProps & HedgeDispatchProps;

const mixedDeltaTypes: Record<MixedDeltaTypes, string> = {
  DELTA_EXCHANGE: 'Exc. Listed',
  DELTA_EXCHANGE_OTC: 'Exc. OTC',
  DELTA_ADJUSTED: 'Adj.',
};

export const HedgeComponent = (props: HedgeProps) => {
  const atLeastOneIsMixed = props.underlyingsModel.some(
    ({ deltaData: { deltaType } }) => deltaType === 'DELTA_MIXED',
  );
  const atLeastOneFairIsPresent = props.underlyingsModel.some(
    ({ fairDelta }) => fairDelta !== undefined,
  );

  const areRfqStrategiesFutureLike = useSelector((state: AppState) =>
    selectors.areRfqStrategiesFutureLike(state, props.rfqId, selectors),
  );

  return (
    <section
      className={`${styles['hedge']} `}
      style={{
        gridTemplateColumns: `${
          !areRfqStrategiesFutureLike ? 'minmax(65px, 100px) minmax(20px, 30px)' : ''
        }  ${atLeastOneFairIsPresent ? 'minmax(60px, 95px)' : ''}  minmax(60px, 95px) 150px ${
          atLeastOneIsMixed ? 'repeat(3, minmax(56px, 65px))' : ''
        }`,
      }}
    >
      {!areRfqStrategiesFutureLike && (
        <>
          <section>
            <div>Vega</div>
            {props.underlyingsModel.map(({ underlyingId, vega, vegaUnit }) => (
              <NumericInput
                unit={vegaUnit}
                key={underlyingId}
                value={vega ? round(vega) : undefined}
                readOnly
              />
            ))}
          </section>
          <section></section>
        </>
      )}
      {atLeastOneFairIsPresent ? (
        <section>
          <div>Fair Delta</div>
          {props.underlyingsModel.map(({ underlyingId, fairDelta }) => (
            <NumericInput
              data-e2e="underlying-reference-fair-delta"
              key={underlyingId}
              value={roundUndefined(fairDelta, 2)}
              unit={'%'}
              readOnly
            />
          ))}
        </section>
      ) : (
        <></>
      )}
      <section>
        <div>Delta</div>
        {props.underlyingsModel.map(
          ({ underlyingId, deltaSum, deltaData, warningModel, isDeltaDisabled }) => (
            <SimpleNeosTooltip
              id={'delta-changed-warning-' + underlyingId}
              disable={!warningModel.isWarningDisplayed}
              message={warningModel.isWarningDisplayed ? warningModel.warningMessage : undefined}
              key={underlyingId}
              type="warning"
              tooltipDataE2e="delta-changed-warning"
            >
              <NumericInput
                className={
                  warningModel.isWarningDisplayed ? 'warning-bloc field-warning' : undefined
                }
                data-e2e="underlying-reference-delta"
                key={underlyingId}
                value={roundUndefined(deltaSum, 2)}
                unit={'%'}
                onBlur={val =>
                  props.onDeltaChanged(
                    underlyingId,
                    deltaData.deltaType as DeltaType | undefined,
                    val,
                  )
                }
                readOnly={deltaData.deltaType === 'DELTA_MIXED' || isDeltaDisabled}
              />
            </SimpleNeosTooltip>
          ),
        )}
      </section>
      <section>
        <div>Delta Type</div>
        {props.underlyingsModel.map(({ underlyingId, deltaData }) => (
          <Fragment key={underlyingId}>
            {deltaData.deltaType === 'DELTA_MIXED' && !props.isDeltaMixedEnabled ? (
              <input value={formatDeltaType('DELTA_MIXED')} className="form-control" readOnly />
            ) : (
              <FormSelect
                data-e2e="underlying-reference-delta-type"
                value={deltaData.deltaType || ''}
                readOnly={props.isDeltaTypeDisabled}
                onChange={event =>
                  props.onDeltaTypeChanged(
                    underlyingId,
                    (event.target.value || undefined) as UiDeltaType,
                  )
                }
              >
                <option value="">---</option>
                {props.availableDeltaTypes.map(deltaType => (
                  <option value={deltaType} key={deltaType}>
                    {formatDeltaType(deltaType)}
                  </option>
                ))}
                {deltaData.isDeltaExchangeOtcAvailable && (
                  <option value="DELTA_EXCHANGE_OTC">
                    {formatDeltaType('DELTA_EXCHANGE_OTC')}
                  </option>
                )}
              </FormSelect>
            )}
          </Fragment>
        ))}
      </section>
      {atLeastOneIsMixed && (
        <>
          {Object.keys(mixedDeltaTypes).map(deltaType => (
            <section key={deltaType}>
              <div>{mixedDeltaTypes[deltaType as MixedDeltaTypes]}</div>
              {props.underlyingsModel.map(({ underlyingId, deltaData, isDeltaDisabled }) =>
                deltaData.deltaType === 'DELTA_MIXED' ? (
                  <NumericInput
                    readOnly={isDeltaDisabled}
                    data-e2e="underlying-reference-delta"
                    key={underlyingId}
                    value={roundUndefined(deltaData.values[deltaType as MixedDeltaTypes], 2)}
                    unit="%"
                    onBlur={val =>
                      props.onDeltaChanged(underlyingId, deltaType as MixedDeltaTypes, val)
                    }
                  />
                ) : (
                  <div key={underlyingId} />
                ),
              )}
            </section>
          ))}
        </>
      )}
    </section>
  );
};
