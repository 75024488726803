import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { BookingStepApplication, OnyxBookingStep } from '../neosOnyxModel';

export type OnyxBookingStepState = Record<string, OnyxBookingStep[]>;

const initialState: OnyxBookingStepState = {};

export const onyxBookingStepSlice = createSlice({
  name: 'onyxBookingSteps',
  initialState,
  reducers: {
    onyxBookingStepsReceived: (
      state,
      action: PayloadAction<{ rfqId: string; bookingSteps: Array<OnyxBookingStep> }>,
    ) => {
      const { rfqId, bookingSteps } = action.payload;
      const stateBookingSteps = state[rfqId];

      if (!stateBookingSteps) {
        state[rfqId] = bookingSteps;
      } else {
        bookingSteps.forEach(element => {
          const index = stateBookingSteps.findIndex(
            bookingStep => bookingStep.uuid === element.uuid,
          );

          if (index === -1) {
            stateBookingSteps.push(element);
          } else {
            stateBookingSteps.splice(index, 1, element);
          }
        });
      }
    },

    removeOnyxBookingSteps: (state, action: PayloadAction<string>) => {
      delete state[action.payload];
    },

    updateTopLevelBookingIdStrategyOrLegBookingStep(
      state,
      action: PayloadAction<{
        rfqId: string;
        parentBookingUuid: string;
        bookingId: string | undefined;
      }>,
    ) {
      const bookingSteps = state[action.payload.rfqId];
      const bookingStep = bookingSteps.find(
        bookingStep => bookingStep.uuid === action.payload.parentBookingUuid,
      );
      if (bookingStep) {
        bookingStep.reference.bookingId = {
          ...bookingStep.reference.bookingId,
          id: action.payload.bookingId,
        };
      }
    },

    updateTopLevelBookingApplicationStrategyOrLegBookingStep(
      state,
      action: PayloadAction<{
        rfqId: string;
        parentBookingUuid: string;
        bookingApplication: BookingStepApplication | undefined;
      }>,
    ) {
      const bookingSteps = state[action.payload.rfqId];
      const bookingStep = bookingSteps.find(
        bookingStep => bookingStep.uuid === action.payload.parentBookingUuid,
      );
      if (bookingStep) {
        bookingStep.reference.bookingId = {
          ...bookingStep.reference.bookingId,
          application: action.payload.bookingApplication,
        };
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  onyxBookingStepsReceived,
  removeOnyxBookingSteps,
  updateTopLevelBookingIdStrategyOrLegBookingStep,
  updateTopLevelBookingApplicationStrategyOrLegBookingStep,
} = onyxBookingStepSlice.actions;

export const bookingStepsReducer = onyxBookingStepSlice.reducer;
