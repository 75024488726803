import type { Thunk } from '@/bootstrap/thunks';
import type { PredealCheck } from '../predealCheckModel';

export function createPredealChecksReceivedThunk(
  rfqId: string,
  predealChecks: PredealCheck[],
): Thunk {
  return function predealChecksReceivedThunk(
    dispatch,
    getState,
    {
      selectors: { tabExists, getRfqData },
      actionCreators: {
        neos: { createPredealChecksReceivedAction, pendingPredealChecksCrudActions },
      },
    },
  ) {
    const state = getState();
    const rfqIsOpen = tabExists(state.ui.tabManagement, rfqId);

    if (!rfqIsOpen) {
      return;
    }

    const { predealCheckIds } = getRfqData(state, rfqId);

    const predealChecksToUpdate = predealChecks.filter(predealCheck =>
      predealCheckIds.includes(predealCheck.uuid),
    );

    const predealCheckToStore = predealChecks.filter(
      predealCheck => !predealCheckIds.includes(predealCheck.uuid),
    );

    const pendingPredealChecksCrudActionsList = predealCheckToStore.map(pdc =>
      pendingPredealChecksCrudActions.arrayAdd(rfqId, {
        property: 'pending',
        value: pdc,
      }),
    );

    dispatch(
      pendingPredealChecksCrudActionsList,
      createPredealChecksReceivedAction(predealChecksToUpdate),
    );
  };
}
