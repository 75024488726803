import type { OnyxIbChatRequest, OnyxIbChatResponse } from '@/neos/business/rfq/rfqOnyxModel';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { Observable } from 'rxjs';
import type { IbChatApi } from '../epics/ibChatEpic';

export const createIbChatApi = (http: SgmeHttp): IbChatApi => ({
  requestIbChat(request: OnyxIbChatRequest): Observable<OnyxIbChatResponse> {
    const url: string = `api/rfq/chatparser`;
    return http.post<OnyxIbChatResponse>({
      url,
      body: request,
    });
  },
});
