import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { thunks } from '@/bootstrap/thunks';
import type { Counterpart } from '@/neos/business/neosModel';
import { useDispatch, useSelector } from 'react-redux';
import { CounterPart } from './CounterPart';

export function Transferor({
  allocationId,
  rfqId,
  legId,
}: {
  allocationId: string;
  rfqId: string;
  legId: string;
}) {
  //can be mapped to either secondary info or step in info depending on the event type
  const dispatch = useDispatch();
  const currentAllocation = useSelector((state: AppState) =>
    selectors.getOtcAllocationsByAllocId(state, rfqId, legId, allocationId),
  );
  const { eventType } = useSelector((state: AppState) => selectors.getLegData(state, legId));

  const counterpart =
    eventType === 'STEPIN'
      ? currentAllocation?.stepInInfo?.transferor
      : currentAllocation?.secondaryInfo?.transferor;

  const onChange = (value: Counterpart | undefined) => {
    if (eventType === 'STEPIN') {
      dispatch(
        thunks.neos.createUpdateStepInInfoThunk(allocationId, rfqId, legId, {
          transferor: value,
        }),
      );
    } else {
      dispatch(
        thunks.neos.createUpdateSecondaryInfoThunk(allocationId, rfqId, legId, {
          transferor: value,
        }),
      );
    }
  };

  return (
    <CounterPart
      allocationId={allocationId}
      counterpart={counterpart}
      onChange={onChange}
      rfqId={rfqId}
      legId={legId}
    />
  );
}
