import type { Thunk } from '@/bootstrap/thunks';
import { formatFromCamelToStartCase } from '@/util/format';
import { escape as lodashEscape } from 'lodash';
import { type OnyxError, getErrorId } from '../mappers/error';

export function createHandleTransactionSaveFailureThunk(
  rfqId: string,
  error: OnyxError | undefined,
): Thunk {
  return function handleTransactionSaveFailureThunk(
    dispatch,
    getState,
    {
      selectors,
      thunks: {
        createErrorToasterThunk,
        neos: { createHandleErrorsThunk },
      },
      actionCreators: {
        neos: { rfqUiCrudActions, nestedRfqsCrudActions },
      },
    },
  ) {
    dispatch(nestedRfqsCrudActions.update(rfqId, { isSavingDone: true }));
    const errorMessagesLines: string[] = ['Error when saving RFQ'];
    if (error && error.content) {
      const { content } = error;
      const match = content.match(/Outdated Rfq data: expecting version : (\d+) but found \d+/);
      if (match) {
        const latestVersion = parseInt(match[1], 10);
        dispatch(
          rfqUiCrudActions.patchOrInsert(rfqId, {
            rfqOutdatedModal: { action: 'SHOW', latestVersion },
          }),
        );
        return;
      }

      errorMessagesLines.push(cleanErrorMessage(content));
    }

    dispatch(createHandleErrorsThunk(rfqId, error?.fieldErrors ?? []));
    const appState = getState();
    const indices = selectors.getStrategyIndicesWithError(appState, rfqId, {
      selectors,
      getStrategyErrorId: getErrorId.strategy,
    });

    const errorsOnStrategiesMessages = indices.map(
      i => `Some fields are in error on strategy ${i + 1}`,
    );
    errorMessagesLines.push(...errorsOnStrategiesMessages);
    dispatch(
      createErrorToasterThunk(
        {
          message: errorMessagesLines.filter(message => message.length).join('<br />'),
          allowHTML: true,
        },
        error,
      ),
    );
  };
}

function cleanConstantCase(status: string) {
  return formatFromCamelToStartCase(status).replace(/ /g, '&nbsp;');
}

function cleanErrorMessage(message: string) {
  if (message.toLocaleLowerCase().includes('exception') || message.length > 150) {
    return '';
  }

  message = lodashEscape(message);
  message = message.replace(
    /Illegal Transition from &#39;(.+?)&#39; to &#39;(.+?)&#39;/,
    (_, from: string, to: string) =>
      `Illegal Transition from '${cleanConstantCase(from)}' to '${cleanConstantCase(to)}'`,
  );
  return `<samp>${message}</samp>`;
}
