import type { FieldMatchers, StrategyType } from './filedMatchersModel';

export const knownFieldMatchers: FieldMatchers = {
  adjustedFutureLevel: {
    regex: /^rfq\.deltas\[(\d*)\]\.references\[\d*\]\.adjustedFutureLevel.value$/,
    groupIds: ['deltaIndex'],
    fieldDescriptor: ({ deltaIndex }) => `Adjusted future level ${getDeltaInformation(deltaIndex)}`,
  },
  deltaType: {
    regex: /^rfq\.strategies\[(\d*)\]\.deltaType$/,
    groupIds: ['strategyIndex'],
    fieldDescriptor: ({ strategyIndex }) => `Delta Type ${getStrategyInformation(strategyIndex)}`,
  },
  clientWay: {
    regex: /^rfq\.strategies\[(\d*)\]\.legs\[(\d*)\]\.clientWay$/,
    groupIds: ['strategyIndex', 'legIndex'],
    fieldDescriptor: ({ strategyIndex, legIndex }) =>
      `Client Way ${getLegInformation(strategyIndex, legIndex)}`,
  },
  legTraderPriceAsk: {
    regex: /^rfq\.strategies\[(\d*)\]\.legs\[(\d*)\]\.quote.traderPrice.ask/,
    groupIds: ['strategyIndex', 'legIndex'],
    fieldDescriptor: ({ strategyIndex, legIndex }) =>
      `Trader Ask ${getLegInformation(strategyIndex, legIndex)}`,
  },
  legTraderPriceBid: {
    regex: /^rfq\.strategies\[(\d*)\]\.legs\[(\d*)\]\.quote.traderPrice.bid/,
    groupIds: ['strategyIndex', 'legIndex'],
    fieldDescriptor: ({ strategyIndex, legIndex }) =>
      `Trader Bid ${getLegInformation(strategyIndex, legIndex)}`,
  },
  legSalesPriceAsk: {
    regex: /^rfq\.strategies\[(\d*)\]\.legs\[(\d*)\]\.quote.salesPrice.ask/,
    groupIds: ['strategyIndex', 'legIndex'],
    fieldDescriptor: ({ strategyIndex, legIndex }) =>
      `Sales Ask ${getLegInformation(strategyIndex, legIndex)}`,
  },
  legSalesPriceBid: {
    regex: /^rfq\.strategies\[(\d*)\]\.legs\[(\d*)\]\.quote.salesPrice.bid/,
    groupIds: ['strategyIndex', 'legIndex'],
    fieldDescriptor: ({ strategyIndex, legIndex }) =>
      `Sales Bid ${getLegInformation(strategyIndex, legIndex)}`,
  },
  legQuoteDelta: {
    regex: /^rfq\.strategies\[(\d*)\]\.legs\[(\d*)\]\.quote.greeks.delta/,
    groupIds: ['strategyIndex', 'legIndex'],
    fieldDescriptor: ({ strategyIndex, legIndex }) =>
      `Trader Delta ${getLegInformation(strategyIndex, legIndex)}`,
  },
  strategyType: {
    regex: /^rfq\.strategies\[(\d*)\]\.strategyType/,
    groupIds: ['strategyIndex'],
    fieldDescriptor: ({ strategyIndex }) =>
      `Strategy Type ${getStrategyInformation(strategyIndex)}`,
  },
  strategyTraderPriceAsk: {
    regex: /^rfq\.strategies\[(\d*)\]\.quote.traderPrice.ask/,
    groupIds: ['strategyIndex'],
    fieldDescriptor: ({ strategyIndex }) => `Trader Ask ${getStrategyInformation(strategyIndex)}`,
  },
  strategyTraderPriceBid: {
    regex: /^rfq\.strategies\[(\d*)\]\.quote.traderPrice.bid/,
    groupIds: ['strategyIndex'],
    fieldDescriptor: ({ strategyIndex }) => `Trader Bid ${getStrategyInformation(strategyIndex)}`,
  },
  strategySalesPriceAsk: {
    regex: /^rfq\.strategies\[(\d*)\]\.quote.salesPrice.ask/,
    groupIds: ['strategyIndex'],
    fieldDescriptor: ({ strategyIndex }) => `Sales Ask ${getStrategyInformation(strategyIndex)}`,
  },
  strategySalesPriceBid: {
    regex: /^rfq\.strategies\[(\d*)\]\.quote.salesPrice.bid/,
    groupIds: ['strategyIndex'],
    fieldDescriptor: ({ strategyIndex }) => `Sales Bid ${getStrategyInformation(strategyIndex)}`,
  },
  strategyQuoteDelta: {
    regex: /^rfq\.strategies\[(\d*)\]\.quote.greeks.delta/,
    groupIds: ['strategyIndex'],
    fieldDescriptor: ({ strategyIndex }) => `Trader Delta ${getStrategyInformation(strategyIndex)}`,
  },
  traderGroup: {
    regex: /^rfq\.strategies\[(\d*)\]\.traderDiffusion.traderGroup/,
    groupIds: ['strategyIndex'],
    fieldDescriptor: ({ strategyIndex }) => `Trader Group ${getStrategyInformation(strategyIndex)}`,
  },
  trader: {
    regex: /^rfq\.strategies\[(\d*)\]\.traderDiffusion.trader/,
    groupIds: ['strategyIndex'],
    fieldDescriptor: ({ strategyIndex }) => `Trader ${getStrategyInformation(strategyIndex)}`,
  },
  salesCounterparty: {
    regex: /^rfq\.salesCounterparty$/,
    groupIds: [],
    fieldDescriptor: () => 'Client (Rfq)',
  },
  optionStyle: {
    regex: /^rfq\.strategies\[(\d*)\]\.legs\[(\d*)\]\.product\.optionStyle/,
    groupIds: ['strategyIndex', 'legIndex'],
    fieldDescriptor: ({ strategyIndex, legIndex }) =>
      `Option Style ${getLegInformation(strategyIndex, legIndex)}`,
  },
  accrual: {
    regex: /^rfq\.strategies\[(\d*)\]\.legs\[(\d*)\]\.product\.accrual/,
    groupIds: ['strategyIndex', 'legIndex'],
    fieldDescriptor: ({ strategyIndex, legIndex }) =>
      `Option Style ${getLegInformation(strategyIndex, legIndex)}`,
  },
  clsType: {
    regex: /^rfq\.strategies\[(\d*)\]\.legs\[(\d*)\]\.product\.clsType/,
    groupIds: ['strategyIndex', 'legIndex'],
    fieldDescriptor: ({ strategyIndex, legIndex }) =>
      `Option Style ${getLegInformation(strategyIndex, legIndex)}`,
  },
  lotSize: {
    regex: /^rfq\.(strategies|deltas)\[(\d*)\]\.legs\[(\d*)\]\.product\.lotSize/,
    groupIds: ['strategyType', 'strategyIndex', 'legIndex'],
    fieldDescriptor: ({ strategyIndex, legIndex, strategyType }) =>
      `Lot Size ${getLegInformation(strategyIndex, legIndex, strategyType)}`,
  },
  market: {
    regex: /^rfq\.(strategies|deltas)\[(\d*)\]\.legs\[(\d*)\]\.product\.negotiation\.market/,
    groupIds: ['strategyType', 'strategyIndex', 'legIndex'],
    fieldDescriptor: ({ strategyIndex, legIndex, strategyType }) =>
      `Market ${getLegInformation(strategyIndex, legIndex, strategyType)}`,
  },
  underlying: {
    regex: /^rfq\.strategies\[(\d*)\]\.legs\[(\d*)\]\.product\.(?:features\[\]\.)?underlying/,
    groupIds: ['strategyIndex', 'legIndex'],
    fieldDescriptor: ({ strategyIndex, legIndex }) =>
      `Underlying ${getLegInformation(strategyIndex, legIndex)}`,
  },
  strike: {
    regex: /^rfq\.strategies\[(\d*)\]\.legs\[(\d*)\]\.product\.strike(?:\.|$)/,
    groupIds: ['strategyIndex', 'legIndex'],
    fieldDescriptor: ({ strategyIndex, legIndex }) =>
      `Strike ${getLegInformation(strategyIndex, legIndex)}`,
  },
  upperStrike: {
    regex: /^rfq\.strategies\[(\d*)\]\.legs\[(\d*)\]\.product\.upperStrike(?:\.|$)/,
    groupIds: ['strategyIndex', 'legIndex'],
    fieldDescriptor: ({ strategyIndex, legIndex }) =>
      `Upper Strike ${getLegInformation(strategyIndex, legIndex)}`,
  },
  lowerStrike: {
    regex: /^rfq\.strategies\[(\d*)\]\.legs\[(\d*)\]\.product\.lowerStrike(?:\.|$)/,
    groupIds: ['strategyIndex', 'legIndex'],
    fieldDescriptor: ({ strategyIndex, legIndex }) =>
      `Lower Strike ${getLegInformation(strategyIndex, legIndex)}`,
  },
  settlementDate: {
    regex: /^rfq\.strategies\[(\d*)\]\.legs\[(\d*)\]\.settlement\.date\.value/,
    groupIds: ['strategyIndex', 'legIndex'],
    fieldDescriptor: ({ strategyIndex, legIndex }) =>
      `Settlement Date ${getLegInformation(strategyIndex, legIndex)}`,
  },
  startDate: {
    regex: /^rfq\.strategies\[(\d*)\]\.legs\[(\d*)\]\.product\.startDate/,
    groupIds: ['strategyIndex', 'legIndex'],
    fieldDescriptor: ({ strategyIndex, legIndex }) =>
      `Start Date ${getLegInformation(strategyIndex, legIndex)}`,
  },
  strikeDate: {
    regex: /^rfq\.strategies\[(\d*)\]\.legs\[(\d*)\]\.product\.strikeDate/,
    groupIds: ['strategyIndex', 'legIndex'],
    fieldDescriptor: ({ strategyIndex, legIndex }) =>
      `Strike Date ${getLegInformation(strategyIndex, legIndex)}`,
  },
  swapCurrency: {
    regex: /^rfq\.strategies\[(\d*)\]\.legs\[(\d*)\]\.product\.swapCurrency/,
    groupIds: ['strategyIndex', 'legIndex'],
    fieldDescriptor: ({ strategyIndex, legIndex }) =>
      `Swap Currency ${getLegInformation(strategyIndex, legIndex)}`,
  },
  rateCurve: {
    regex: /^rfq\.strategies\[(\d*)\]\.legs\[(\d*)\]\.product\.rateCurve/,
    groupIds: ['strategyIndex', 'legIndex'],
    fieldDescriptor: ({ strategyIndex, legIndex }) =>
      `Rate Curve ${getLegInformation(strategyIndex, legIndex)}`,
  },
  rateTenor: {
    regex: /^rfq\.strategies\[(\d*)\]\.legs\[(\d*)\]\.product\.rateTenor/,
    groupIds: ['strategyIndex', 'legIndex'],
    fieldDescriptor: ({ strategyIndex, legIndex }) =>
      `Rate Tenor ${getLegInformation(strategyIndex, legIndex)}`,
  },
  maturity: {
    regex: /^rfq\.strategies\[(\d*)\]\.legs\[(\d*)\]\.product\.maturity/,
    groupIds: ['strategyIndex', 'legIndex'],
    fieldDescriptor: ({ strategyIndex, legIndex }) =>
      `Maturity ${getLegInformation(strategyIndex, legIndex)}`,
  },
  effectiveDate: {
    regex: /^rfq\.strategies\[(\d*)\]\.legs\[(\d*)\]\.product\.effectiveDate/,
    groupIds: ['strategyIndex', 'legIndex'],
    fieldDescriptor: ({ strategyIndex, legIndex }) =>
      `Effective Date ${getLegInformation(strategyIndex, legIndex)}`,
  },
  description: {
    regex: /^rfq\.strategies\[(\d*)\]\.legs\[\d*\]\.product\.(?:features\[\d*\].)?description/,
    groupIds: ['strategyIndex'],
    fieldDescriptor: ({ strategyIndex }) => `Description ${getStrategyInformation(strategyIndex)}`,
  },
  numberOfLots: {
    regex: /^rfq\.(strategies|deltas)\[(\d*)\]\.legs\[(\d*)\]\.negotiatedSize\.numberOfLots/,
    groupIds: ['strategyType', 'strategyIndex', 'legIndex'],
    fieldDescriptor: ({ strategyIndex, legIndex, strategyType }) =>
      `Size ${getLegInformation(strategyIndex, legIndex, strategyType)}`,
  },
  deliveryType: {
    regex: /^rfq\.strategies\[(\d*)\]\.legs\[(\d*)\]\.product\.deliveryType/,
    groupIds: ['strategyIndex', 'legIndex'],
    fieldDescriptor: ({ strategyIndex, legIndex }) =>
      `Delivery Type ${getLegInformation(strategyIndex, legIndex)}`,
  },
  rfqTraderPriceBid: {
    regex: /^rfq\.quote\.traderPrice\.bid\.value/,
    groupIds: [],
    fieldDescriptor: () => 'Trader Bid (Rfq)',
  },
  rfqTraderPriceAsk: {
    regex: /^rfq\.quote\.traderPrice\.ask\.value/,
    groupIds: [],
    fieldDescriptor: () => 'Trader Ask (Rfq)',
  },
  rfqNotional: {
    regex: /^rfq.notional.value/,
    groupIds: [],
    fieldDescriptor: () => `Notional`,
  },
  rfqPriceRequestDate: {
    regex: /^rfq.priceRequestUTCDate/,
    groupIds: [],
    fieldDescriptor: () => `Price Request Date`,
  },
  //product.features[].up.strike.value
  up: {
    regex:
      /^rfq\.strategies\[(\d*)\]\.legs\[\d*\]\.product(?:\.features\[\d*\])?\.up\.strike\.value/,
    groupIds: ['strategyIndex'],
    fieldDescriptor: ({ strategyIndex }) => `Up ${getStrategyInformation(strategyIndex)}`,
  },
  down: {
    regex:
      /^rfq\.strategies\[(\d*)\]\.legs\[\d*\]\.product(?:\.features\[\d*\])?\.down\.strike\.value/,
    groupIds: ['strategyIndex'],
    fieldDescriptor: ({ strategyIndex }) => `Up ${getStrategyInformation(strategyIndex)}`,
  },
  observationConvention: {
    regex:
      /^rfq\.strategies\[(\d*)\]\.legs\[\d*\]\.product(?:\.features\[\d*\])?\.(?:up|down)\.observationConvention/,
    groupIds: ['strategyIndex'],
    fieldDescriptor: ({ strategyIndex }) => `Up ${getStrategyInformation(strategyIndex)}`,
  },
  forwardStartDate: {
    regex:
      /^rfq\.strategies\[(\d*)\]\.legs\[\d*\]\.product(?:\.features\[\d*\])?\.forwardStartDate/,
    groupIds: ['strategyIndex'],
    fieldDescriptor: ({ strategyIndex }) =>
      `Forward Start Date ${getStrategyInformation(strategyIndex)}`,
  },
  endOfObservation: {
    regex:
      /^rfq\.strategies\[(\d*)\]\.legs\[\d*\]\.product(?:\.features\[\d*\])?\.endOfObservation/,
    groupIds: ['strategyIndex'],
    fieldDescriptor: ({ strategyIndex }) =>
      `End Of Observation ${getStrategyInformation(strategyIndex)}`,
  },
  equityBullet: {
    regex: /^rfq\.strategies\[(\d*)\]\.legs\[\d*\]\.product(?:\.features\[\d*\])?\.equityBullet/,
    groupIds: ['strategyIndex'],
    fieldDescriptor: ({ strategyIndex }) =>
      `Equity Bullet ${getStrategyInformation(strategyIndex)}`,
  },
  rateBullet: {
    regex: /^rfq\.strategies\[(\d*)\]\.legs\[\d*\]\.product(?:\.features\[\d*\])?\.rateBullet/,
    groupIds: ['strategyIndex'],
    fieldDescriptor: ({ strategyIndex }) => `Rate Bullet ${getStrategyInformation(strategyIndex)}`,
  },
  rateOvernight: {
    regex: /^rfq\.strategies\[(\d*)\]\.legs\[\d*\]\.product(?:\.features\[\d*\])?\.rateOvernight/,
    groupIds: ['strategyIndex'],
    fieldDescriptor: ({ strategyIndex }) =>
      `Rate Overnight ${getStrategyInformation(strategyIndex)}`,
  },
  resetFrequency: {
    regex: /^rfq\.strategies\[(\d*)\]\.legs\[\d*\]\.product(?:\.features\[\d*\])?\.resetFrequency/,
    groupIds: ['strategyIndex'],
    fieldDescriptor: ({ strategyIndex }) =>
      `Reset Frequency ${getStrategyInformation(strategyIndex)}`,
  },
  execFees: {
    regex: /^rfq\.strategies\[(\d*)\]\.legs\[\d*\]\.product.features\[\d*\]\.execFees/,
    groupIds: ['strategyIndex'],
    fieldDescriptor: ({ strategyIndex }) => `Exec Fees ${getStrategyInformation(strategyIndex)}`,
  },
  forexType: {
    regex: /^rfq\.strategies\[(\d*)\]\.legs\[\d*\]\.product(?:\.features\[\d*\])?\.forexType/,
    groupIds: ['strategyIndex'],
    fieldDescriptor: ({ strategyIndex }) => `Forex Type ${getStrategyInformation(strategyIndex)}`,
  },
  forwardDrift: {
    regex: /^rfq\.strategies\[(\d*)\]\.legs\[(\d*)\]\.product\.forwardDrift/,
    groupIds: ['strategyIndex', 'legIndex'],
    fieldDescriptor: ({ strategyIndex, legIndex }) =>
      `Froward Drift ${getLegInformation(strategyIndex, legIndex)}`,
  },
  forwardInterestRate: {
    regex: /^rfq\.strategies\[(\d*)\]\.legs\[(\d*)\]\.product\.forwardInterestRate/,
    groupIds: ['strategyIndex', 'legIndex'],
    fieldDescriptor: ({ strategyIndex, legIndex }) =>
      `Froward Interest Rate ${getLegInformation(strategyIndex, legIndex)}`,
  },
  otcPreAllocCounterparty: {
    regex: /^rfq.strategies\[\d*\]\.preAllocs\[\d*\]\.counterparty/,
    groupIds: [],
    fieldDescriptor: () => `OTC Pre Alloc Counterparty`,
  },
  otcPreAllocIAValueDate: {
    regex:
      /^rfq.strategies\[\d*\]\.preAllocs\[\d*\]\.independentAmount.valueDateNotRequiredOrNotNull/,
    groupIds: [],
    fieldDescriptor: () => `OTC Pre Alloc IA Value date`,
  },
  preConfExternalClientContact: {
    regex: /^external.clientContact/,
    groupIds: [],
    fieldDescriptor: () => `Pre Confirmation External Client Contact`,
  },
};

function getLegInformation(
  strategyIndex: string | 'ALL',
  legIndex: string | 'ALL',
  strategyType?: StrategyType,
): string {
  return `(${getStrategyTypeInformation(strategyType)} ${incrementIndex(
    strategyIndex,
  )} - Leg ${incrementIndex(legIndex)})`;
}

function getStrategyInformation(
  strategyIndex: string | 'ALL',
  strategyType?: StrategyType,
): string {
  return `(${getStrategyTypeInformation(strategyType)} ${incrementIndex(strategyIndex)})`;
}

function getDeltaInformation(deltaIndex: string | 'ALL'): string {
  return `(${incrementIndex(deltaIndex)})`;
}

function getStrategyTypeInformation(strategyType?: StrategyType): string {
  return strategyType === 'deltas' ? 'Delta Strat' : 'Strat';
}

function incrementIndex(index: string | 'ALL'): string {
  if (index === 'ALL') {
    return index;
  }
  const numberIndex = Number(index);
  return `${numberIndex + 1}`;
}
