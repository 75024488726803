import { hasDiscriminator } from '@/neos/business/rfq/strategy/feature/hasDiscriminator';
import { convertNullToUndefined } from '@/util/undefinedAndNull/convertNullToUndefined';
import type { OnyxProductFeaturesFields } from '../../../../../../neos/business/neosOnyxModel';
import type { OnyxValuationFrequency } from '../../strategyOnyxModel';
import type { ValuationFrequencyFeature } from '../featureModel';

export function mapFromOnyxFeatureOrExtraFeaturesValuationFrequency(
  { extraFeatures }: OnyxProductFeaturesFields,
  strategyId: string,
): ValuationFrequencyFeature | undefined {
  const valuationFrequencyFeature: OnyxValuationFrequency | undefined = extraFeatures?.find(
    hasDiscriminator<OnyxValuationFrequency>('VALUATION_FREQUENCY'),
  );

  if (valuationFrequencyFeature) {
    return {
      type: 'VALUATION_FREQUENCY',
      strategyId,
      valuationFrequency: convertNullToUndefined(valuationFrequencyFeature.valuationFrequency),
    };
  }

  return undefined;
}

export function mapToOnyxValuationFrequency(
  feature: ValuationFrequencyFeature,
): OnyxProductFeaturesFields {
  const { valuationFrequency } = feature;

  return {
    extraFeatures: [
      {
        discriminator: 'VALUATION_FREQUENCY',
        valuationFrequency: valuationFrequency === undefined ? null : valuationFrequency,
      },
    ],
  };
}
