import type { Thunk } from '@/bootstrap/thunks';
import type { OnyxRfq } from '@/neos/business/rfq/rfqOnyxModel';

export function createFinalizeRfqSavingDoneThunk(lastSavedRfq: OnyxRfq): Thunk {
  return function finalizeRfqSavingDoneThunk(
    dispatch,
    getState,
    {
      selectors: { getPendingPredealChecks },
      actionCreators: {
        neos: { pendingPredealChecksCrudActions, nestedRfqsCrudActions },
      },
      thunks: {
        neos: { createPredealChecksReceivedThunk },
      },
    },
  ) {
    const state = getState();
    const rfqId = lastSavedRfq.uuid;
    const pendingNotifications = getPendingPredealChecks(state, rfqId);

    dispatch(
      nestedRfqsCrudActions.upsert(rfqId, {
        isSavingDone: true,
        lastSavedRfq,
        notificationRfq: { isThereNotificationRfq: false },
      }),
      pendingPredealChecksCrudActions.upsert(rfqId, { pending: [] }),
      createPredealChecksReceivedThunk(rfqId, pendingNotifications),
    );
  };
}
