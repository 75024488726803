import type { Thunk } from '@/bootstrap/thunks';
import { DefaultingScope, type IATypeValuesType, type RfqData } from '../../models';
import { DefaultingOptions } from '../../rfqActions';
import { unitDefaultValues } from '../otcAllocation/onAllocationIATypeChangedThunk';

export function createOnIATypeChangedThunk(
  rfqId: string,
  index: number,
  property: keyof Pick<RfqData, 'otcPreAllocs' | 'deltaOtcPreAllocs'>,
  independantAmountType: IATypeValuesType | undefined,
): Thunk {
  return function onIATypeChangedThunk(
    dispatch,
    _,
    {
      actionCreators: {
        neos: { rfqDataCrudActions, createDefaultRfqRequestedAction },
      },
    },
  ) {
    dispatch(
      rfqDataCrudActions.arrayPatch(rfqId, {
        index,
        property,
        value: {
          independantAmountType,
          independantAmountValue: undefined,
          independantAmountUnit: independantAmountType
            ? unitDefaultValues[independantAmountType]
            : undefined,
        },
      }),
    );

    const overrideScopes = [
      DefaultingScope.IA_CURRENCY,
      DefaultingScope.PRE_ALLOCATIONS_VALUE_DATE,
    ];
    const enrichScopes = [DefaultingScope.FOREX];
    const options = new DefaultingOptions({ enrichScopes, overrideScopes });

    dispatch(createDefaultRfqRequestedAction(rfqId, options));
  };
}
