import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';

export function isLegOverWarningVisible(
  state: AppState,
  strategyId: string,
  selectors: Selectors,
): boolean {
  const { fairPriceId, quoteId, scope, strategyType } = selectors.getStrategyData(
    state,
    strategyId,
  );
  const isStrategyDeltaSynthetic = scope === 'DELTA_EXCHANGE_OTC' && strategyType === 'SYNTHETIC';
  const legOverWarningDismissed = selectors.isLegOverWarningDismissed(state.ui, strategyId);
  const isInconsistentForStrategy = selectors.isStrategyInconsistentForLegOverReverse(
    state,
    strategyId,
    selectors,
  );

  if (isStrategyDeltaSynthetic || legOverWarningDismissed || isInconsistentForStrategy) {
    return false;
  }

  const fairPrice = fairPriceId ? selectors.getFairPrice(state, fairPriceId) : undefined;
  const quote = selectors.getQuote(state, quoteId);

  const priceTypeIsBasis =
    fairPrice?.midPriceType === 'BASIS' ||
    quote.traderAskType === 'BASIS' ||
    quote.traderBidType === 'BASIS';

  if (priceTypeIsBasis) {
    return false;
  }

  const isMidFairPriceNegative = fairPrice?.mid && fairPrice.mid < 0;
  const quoteBidAndAskAreNegative =
    quote.traderAsk && quote.traderAsk < 0 && quote.traderBid && quote.traderBid < 0;

  const isLegOverEnabled = isMidFairPriceNegative || quoteBidAndAskAreNegative;
  return !!isLegOverEnabled;
}
