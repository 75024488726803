import { type OnyxRfq, isOnyxBasketUnderlying } from '@/neos/business/rfq/rfqOnyxModel';
import type { BlotterModelGetters } from '.';

export function getMasterRefLevel(rfq: OnyxRfq, g: BlotterModelGetters): number | undefined {
  const masterStrategy = g.getMasterStrategy(rfq);
  if (!masterStrategy || !masterStrategy.references || masterStrategy.references.length === 0) {
    return undefined;
  }
  const { references } = masterStrategy;
  const masterLeg = g.getMasterLeg(rfq, g);

  const masterUnderlyingId =
    masterLeg?.product?.discriminator === 'STOCK'
      ? masterLeg?.product?.negotiation?.discriminator === 'LISTED'
        ? masterLeg?.product?.negotiation?.productRef?.id
        : undefined
      : isOnyxBasketUnderlying(masterLeg?.product?.underlying)
        ? undefined
        : masterLeg?.product?.underlying?.id;

  const masterReference = masterUnderlyingId
    ? references.find(({ underlyingId }) => underlyingId === masterUnderlyingId)
    : undefined;

  return masterReference
    ? masterReference.discriminator === 'FUTURE'
      ? masterReference.futureLevel?.value
      : masterReference.spot?.value
    : undefined;
}
