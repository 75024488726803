import type { FC } from 'react';
import { ComboBox } from '../../../../../share/comboBox';
import { ErrorHighlight } from '../../../../../share/errorHighlight';
import { NumericInput } from '../../../../../share/numericInput';
import type { UpperStrikeCellsModel } from './getUpperStrikeCellsModel';

export interface UpperStrikeCellsOwnProps {
  rfqId: string;
  strategyId: string;
}

export interface UpperStrikeCellsMapStateProps {
  model: UpperStrikeCellsModel;
}

export interface UpperStrikeCellsDispatchProps {
  onUpperStrikeChanged(productId: string, upperStrike: number | undefined): void;
}

type UpperStrikeCellsProps = UpperStrikeCellsOwnProps &
  UpperStrikeCellsMapStateProps &
  UpperStrikeCellsDispatchProps;

export const UpperStrikeCells: FC<UpperStrikeCellsProps> = ({
  rfqId,
  strategyId,
  model,
  onUpperStrikeChanged,
}) => {
  return (
    <section>
      {model.upperStrikes.map(({ id, value, isEditable }, index) => (
        <ErrorHighlight
          key={id}
          errorField={'upperStrike'}
          related={{ rfqId, strategyId, legId: id }}
        >
          {model.showAvailableStrikes ? (
            <ComboBox
              disabled={!isEditable}
              className="errorable-bloc"
              onChange={val => onUpperStrikeChanged(id, val)}
              value={value?.value}
              unit={value?.unit}
              options={model.availableStrikes[index] ? model.availableStrikes[index].strikes : []}
              data-e2e="neos-strategy-definition-upper-strike"
            />
          ) : (
            <NumericInput
              readOnly={!isEditable}
              className="errorable-bloc"
              value={value ? value.value : value}
              unit={value ? value.unit : value}
              onBlur={val => onUpperStrikeChanged(id, val)}
              data-e2e="neos-strategy-definition-upper-strike"
            />
          )}
        </ErrorHighlight>
      ))}
    </section>
  );
};
