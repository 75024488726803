import { crudDomain } from '@/util/crudUtils';
import type {
  DeltaStockListedAllocation,
  DeltaStockListedAllocationKey,
} from './deltaStockListedAllocationModel';

const compositeIdSeparator = ' | ';

function keyToString({ rfqId, legId, allocationId }: DeltaStockListedAllocationKey): string {
  return `${rfqId}${compositeIdSeparator}${legId}${compositeIdSeparator}${allocationId}`;
}

function stringToKey(id: string): DeltaStockListedAllocationKey {
  const [rfqId, legId, allocationId] = id.split(compositeIdSeparator);
  return { rfqId, legId, allocationId };
}

export const deltaStockListedAllocationDomain = crudDomain<
  DeltaStockListedAllocation,
  DeltaStockListedAllocationKey
>('deltaStockListedAllocation', {
  keyToString,
  stringToKey,
});
