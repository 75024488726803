import type { NextUserActions } from './nextUserActionsModel';
import type { OnyxNextUserActions } from './nextUserActionsOnyxModel';

export function mapFromOnyxNextUserAction(
  onyxNextUserActions: OnyxNextUserActions,
): NextUserActions {
  return {
    nextActions: Object.entries(onyxNextUserActions._links).map(
      ([action, { href, title, type }]) => ({
        action,
        href: href.startsWith('/') ? href.substr(1) : href,
        title,
        type,
      }),
    ),
  };
}
