import type { Thunk } from '@/bootstrap/thunks';
import type { ActionContainer } from '@/util/actionContainer';

export function createPreconfirmationPreferencesGroupUpdatedThunk(
  groupKey: string,
  enabled: boolean,
): Thunk {
  return function preconfirmationPreferencesGroupUpdatedThunk(
    dispatch,
    _getState,
    {
      actionCreators: {
        neos: {
          addPreconfPrefGroupToLoadingGroupAction,
          addUserPrefPreconfGroupsRequestedActionCreator,
          deleteUserPrefPreconfGroupsRequestedActionCreator,
        },
      },
    },
  ) {
    const actions: ActionContainer[] = [
      addPreconfPrefGroupToLoadingGroupAction(groupKey),
      enabled
        ? addUserPrefPreconfGroupsRequestedActionCreator(groupKey)
        : deleteUserPrefPreconfGroupsRequestedActionCreator(groupKey),
    ];

    dispatch(actions);
  };
}
