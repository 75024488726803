import type { ChainOptions } from '@/neos/business/neosActions';
import type {
  BasketUnderlyingIdsRequestedAction,
  UnderlyingIdsRequestedAction,
} from './underlyingActions';
import type { ValidImportedBasketData } from '@/neos/business/rfq/strategy/leg/product/els/thunks/importBasketCompositionThunk.ts';

export const underlyingActionCreators = {
  createUnderlyingIdsRequestedAction,
  createBasketUnderlyingIdsRequestedAction,
};

export interface BloombergCodeByProductId {
  productId: string;
  bloombergCode: string;
}

function createUnderlyingIdsRequestedAction(
  rfqIds: string[],
  bloombergCodesByProductId: BloombergCodeByProductId[],
  chainOptions?: ChainOptions,
): UnderlyingIdsRequestedAction {
  return {
    type: 'UNDERLYING_IDS_REQUESTED',
    rfqIds,
    bloombergCodesByProductId,
    chainOptions,
  };
}

function createBasketUnderlyingIdsRequestedAction(
  rfqId: string,
  productId: string,
  validatedData: ValidImportedBasketData,
  chainOptions?: ChainOptions,
): BasketUnderlyingIdsRequestedAction {
  return {
    type: 'BASKET_UNDERLYING_IDS_REQUESTED',
    rfqId,
    productId,
    validatedData,
    chainOptions,
  };
}
