import { type FC, useState } from 'react';
import type { OptionFlex } from '../../../../../../business/neosModel';
import { ErrorHighlight } from '../../../../../share/errorHighlight';
import { MiniAlert } from '../../../../../share/miniAlert/miniAlert';
import type { OptionFlexCellsModel } from './getOptionFlexCellsModel';

export interface OptionFlexCellsOwnProps {
  rfqId: string;
  strategyId: string;
}

export type OptionFlexCellsMapStateProps = OptionFlexCellsModel;

export interface OptionFlexCellsDispatchProps {
  onOptionFlexChanged(productId: string, optionFlex: OptionFlex | undefined): void;
  activateStrategyFlexs(): void;
}

type OptionFlexCellsProps = OptionFlexCellsOwnProps &
  OptionFlexCellsMapStateProps &
  OptionFlexCellsDispatchProps;

export const OptionFlexCells: FC<OptionFlexCellsProps> = props => {
  const [hideAlert, setHideAlert] = useState(false);
  return (
    <section>
      {props.optionFlexs.map(({ id, value, isEditable }) => (
        <ErrorHighlight
          key={id}
          errorField={'optionFlex'}
          related={{ rfqId: props.rfqId, legId: id, strategyId: props.strategyId }}
        >
          <div className="form-check">
            <input
              type="checkbox"
              id={id}
              className="form-check-input"
              data-e2e={`flex-checkbox-${id}`}
              readOnly={!isEditable}
              disabled={!isEditable}
              checked={value === 'FLEX'}
              onChange={_ => props.onOptionFlexChanged(id, value === 'FLEX' ? 'NON_FLEX' : 'FLEX')}
            />
            <label className="form-check-label" htmlFor={id} />
          </div>
        </ErrorHighlight>
      ))}
      {props.showFlexWarning && !hideAlert && (
        <MiniAlert
          className="px-2"
          lines={<span>Do you want to switch to flex?</span>}
          fn={{
            onYes: () => {
              props.activateStrategyFlexs();
            },
            onNo: () => {
              setHideAlert(true);
            },
          }}
        />
      )}
    </section>
  );
};
