import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { thunks } from '@/bootstrap/thunks';

export function AppCrashError() {
  const dispatch = useDispatch();

  useEffect(() => {
    function handleSendHelpRequestClick() {
      dispatch(thunks.common.createHelpRequestSentThunk());
    }

    const sgErrorPage = document.querySelector('sg-error-page');

    sgErrorPage?.addEventListener('actionButtonClicked', handleSendHelpRequestClick);
    return () => {
      sgErrorPage?.removeEventListener('actionButtonClicked', handleSendHelpRequestClick);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="h-100">
      <sg-error-page
        code={500}
        action-button-label="Send help request"
        fullscreen={true}
        description-label="We're unable to complete your request at the moment."
        title-label="Something's wrong."
      />
    </div>
  );
}
