import type { OnyxCreatedSecondaryEvent } from '@/neos/components/rfq/strategies/globalActions/secondaryEventWidget/createSecondary/OnyxCreateSecondaryModel';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { Observable } from 'rxjs';
import type { CreateSecondaryEventApi } from '../epics/createSecondaryEventEpic';
import type { SecondaryEventType } from '../rfq/strategy/leg/legData/legDataModel';

export const createCreateSecondaryEventApi = (http: SgmeHttp): CreateSecondaryEventApi => ({
  createSecondaryEvent(
    rfqId: string,
    secondaryEvents: SecondaryEventType[],
  ): Observable<OnyxCreatedSecondaryEvent> {
    const url: string = `api/post-trade/secondary/${rfqId}`;
    return http.put<OnyxCreatedSecondaryEvent>({
      url,
      body: {
        secondaryEvents,
      },
    });
  },
});
