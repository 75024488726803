import type { Thunk } from '@/bootstrap/thunks';

type QuoteRemoveStartLevel = 'STRATEGY' | 'LEG';

export function createRfqResetStrategyQuoteAndFairPricesThunk(
  rfqId: string,
  strategyId: string,
  startLevel: QuoteRemoveStartLevel = 'LEG',
): Thunk {
  return function rfqResetStrategyQuoteAndFairPricesThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { rfqDataCrudActions },
      },
      thunks: {
        neos: { createRfqResetStrategyQuoteThunk, createRfqResetStrategyFairPriceThunk },
      },
    },
  ) {
    const state = getState();

    if (selectors.isOtcStrategyAndAmendWorkflowStatus(state, rfqId, strategyId, selectors)) {
      return;
    }

    const emptyPremiumAction = rfqDataCrudActions.update(rfqId, { totalPremiums: undefined });
    dispatch(
      emptyPremiumAction,
      createRfqResetStrategyQuoteThunk(rfqId, strategyId, startLevel),
      createRfqResetStrategyFairPriceThunk(rfqId, strategyId, startLevel),
    );
  };
}
