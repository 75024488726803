import type { Thunk } from '@/bootstrap/thunks';
import { mt } from '@/util/logging/messageTemplates';

export function createEnableHandlingAlertClickThunk(): Thunk {
  return function enableHandlingAlertClickThunk(
    dispatch,
    _,
    {
      thunks: {
        createInfoToasterThunk,
        createWarningToasterThunk,
        neos: { createSelectNeosApplicationThunk },
      },
      services: { serviceWorker, copyToClipboard, logger },
    },
  ) {
    serviceWorker.addEventListener('message', event => {
      if (event.data.type !== 'NEOS_ALERT_CLICK') {
        return;
      }

      const alert = event.data.value;
      const { rfqUuid } = alert;

      copyToClipboard(rfqUuid, {
        onSuccess: source => {
          dispatch(createInfoToasterThunk({ message: `RFQ ID ${rfqUuid} copied to clipboard` }));
          logger.info(mt.alertClipboard, source, rfqUuid);
        },
        onFail: (source, error) => {
          dispatch(
            createWarningToasterThunk({
              message: `Cannot copy RFQ ID ${rfqUuid} to clipboard!! Please copy it manually!`,
            }),
          );
          logger.error(mt.alertErrorClipboard, source, rfqUuid, error?.message, error);
        },
      });
    });

    serviceWorker.addEventListener('message', event => {
      if (event.data.type !== 'NEOS_ALERT_CLICK') {
        return;
      }

      const alert = event.data.value;
      const { rfqUuid } = alert;

      dispatch(createSelectNeosApplicationThunk(rfqUuid));
    });
  };
}
