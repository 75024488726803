import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { services } from '@/bootstrap/services';
import { type ExtendedDispatch, thunks } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { DefaultingScope, type OtcPreAlloc, type RfqData } from '@/neos/business/neosModel';
import { DefaultingOptions } from '@/neos/business/rfq/rfqActions';
import type { ObjectArrayPropertyPatch } from '@/util/crudUtils';
import type { ScopeType } from '../../scopeModel';
import type {
  CommonOtcPreAllocationsDispatchProps,
  CommonOtcPreAllocationsOwnProps,
} from '../CommonOtcPreAllocations';

type ArrayPatchOtc = ObjectArrayPropertyPatch<Pick<RfqData, 'otcPreAllocs' | 'deltaOtcPreAllocs'>>;

export function genericMapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId }: CommonOtcPreAllocationsOwnProps,
  scope: ScopeType,
): CommonOtcPreAllocationsDispatchProps {
  const property: keyof Pick<RfqData, 'otcPreAllocs' | 'deltaOtcPreAllocs'> =
    scope === 'RFQ' ? 'otcPreAllocs' : 'deltaOtcPreAllocs';
  return {
    onCounterpartChanged: (index, counterpartId) => {
      dispatch(
        neosActionCreators.rfqDataCrudActions.arrayPatch(rfqId, {
          index,
          property,
          value: {
            counterpartId,
            eligibleMedia: undefined,
            eligibilityReason: undefined,
            media: undefined,
            markitWireMode: undefined,
            electronicMediaUser: undefined,
            forcedReason: undefined,
          },
        } as ObjectArrayPropertyPatch<Pick<RfqData, 'otcPreAllocs'>>),
      );
      if (counterpartId) {
        dispatch(
          neosActionCreators.electronicMediaUsersActionCreators.createElectronicMediaUsersRequestedAction(
            counterpartId,
          ),
          neosActionCreators.createDefaultConfirmationMediaAndMcaRequestedAction(rfqId),
        );
      }
    },
    onIAChanged: (index, independantAmountValue) => {
      dispatch(
        neosActionCreators.rfqDataCrudActions.arrayPatch(rfqId, {
          index,
          property,
          value: { independantAmountValue },
        }),
      );

      const overrideScopes = [DefaultingScope.IA_CURRENCY];
      const enrichScopes = [DefaultingScope.FOREX, DefaultingScope.PRE_ALLOCATIONS_VALUE_DATE];
      const options = new DefaultingOptions({ enrichScopes, overrideScopes });
      dispatch(neosActionCreators.createDefaultRfqRequestedAction(rfqId, options));
    },
    onIACcyChanged: (index, independantAmountUnit) => {
      dispatch(
        thunks.neos.createOnIACurrencyChangedThunk(rfqId, index, property, independantAmountUnit),
      );
    },
    onIATypeChanged: (index, independantAmountType) => {
      dispatch(
        thunks.neos.createOnIATypeChangedThunk(rfqId, index, property, independantAmountType),
      );
    },

    onPreAllocationAdded: (firstpreAllocCcy, masterCcy) => {
      dispatch(
        createLogAnalyticsAction(
          'NEOS RFQ',
          `Add ${scope === 'DELTA' ? 'delta' : ''} strategies Otc pre alloc`,
        ),
      );
      dispatch(
        neosActionCreators.rfqDataCrudActions.arrayAdd(rfqId, {
          property,
          value: {
            counterpartId: undefined,
            ratio: undefined,
            independantAmountType: 'CCY',
            independantAmountUnit: firstpreAllocCcy ?? masterCcy,
            independantAmountValue: undefined,
          } as OtcPreAlloc,
        }),
      );
      dispatch(neosActionCreators.createDefaultMcaRequestedAction(rfqId));
    },
    onPreAllocationRemoved: index => {
      dispatch(
        createLogAnalyticsAction(
          'NEOS RFQ',
          `Remove ${scope === 'DELTA' ? 'delta' : ''} strategies Otc pre alloc`,
        ),
      );
      dispatch(
        neosActionCreators.rfqDataCrudActions.arrayRemoveIndex(rfqId, {
          index,
          property,
        }),
      );
    },
    onRatioChanged: (index, ratio) =>
      dispatch(
        neosActionCreators.rfqDataCrudActions.arrayPatch(rfqId, {
          index,
          property,
          value: { ratio: services.applyMultiplierWithPrecision(ratio, 0.01, 4) },
        }),
      ),
    onMediaChanged: (index, media) =>
      dispatch(
        neosActionCreators.rfqDataCrudActions.arrayPatch(rfqId, {
          index,
          property,
          value: { media },
        } as ArrayPatchOtc),
      ),
    onForcedReasonChanged: (index, forcedReason) =>
      dispatch(
        neosActionCreators.rfqDataCrudActions.arrayPatch(rfqId, {
          index,
          property,
          value: { forcedReason },
        } as ArrayPatchOtc),
      ),
    onMarkitWireModeChanged: (index, markitWireMode) =>
      dispatch(
        neosActionCreators.rfqDataCrudActions.arrayPatch(rfqId, {
          index,
          property,
          value: { markitWireMode },
        } as ArrayPatchOtc),
      ),
    onMediaUserChanged: (index, electronicMediaUser) =>
      dispatch(
        neosActionCreators.rfqDataCrudActions.arrayPatch(rfqId, {
          index,
          property,
          value: { electronicMediaUser },
        } as ArrayPatchOtc),
      ),
    onPreAllocAIValueDateChanged: (index, independantAmountValueDate) =>
      dispatch(
        neosActionCreators.rfqDataCrudActions.arrayPatch(rfqId, {
          index,
          property,
          value: { independantAmountValueDate },
        } as ArrayPatchOtc),
      ),
  };
}
