import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import styles from './Summary.module.scss';

export interface SummaryOwnProps {
  rfqId: string;
}

export interface SummaryPropsFromState {
  summary?: string;
  isLoading: boolean;
}

export interface SummaryDispatchProps {
  onCopySummaryToClipboard: (text: string) => void;
}

export type SummaryProps = SummaryOwnProps & SummaryPropsFromState & SummaryDispatchProps;

export const SummaryUnconnected = ({
  summary,
  isLoading,
  onCopySummaryToClipboard,
}: SummaryProps) => {
  return (
    <section className={`${styles['summary']}`}>
      {summary ? (
        <div>
          {isLoading ? (
            <i className="icon icon-xs mx-2">hourglass_bottom</i>
          ) : (
            <SimpleNeosTooltip id="copy-summary" message={'Copy summary'}>
              <button
                className="btn btn-sm btn-icon btn-link d-inline mx-2"
                onClick={() => {
                  onCopySummaryToClipboard(summary || '');
                }}
                data-e2e="copy-rfq-summary"
              >
                <i className="icon icon-xs">content_copy</i>
              </button>
            </SimpleNeosTooltip>
          )}
          {summary}
        </div>
      ) : (
        <div className={`${styles['quote-recap-empty']}`}>
          <label>Quote Recap: </label>
          <span>N/A</span>
        </div>
      )}
    </section>
  );
};
