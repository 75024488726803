import type { PrimeBroker } from '@/neos/business/referenceData/primeBroker/primeBrokerActions';
import type { OnyxBrokerId } from '@/neos/business/ui/userPreferences/brokers/brokersPreferencesApis';

export interface SearchUserBrokersAction {
  type: 'USER_PREFERENCES_SEARCH_BROKERS';
  entry: string;
}

export function createSearchBrokersPreferencesAction(entry: string): SearchUserBrokersAction {
  return {
    type: 'USER_PREFERENCES_SEARCH_BROKERS',
    entry,
  };
}

export interface AddBrokerPreferencesAction {
  type: 'USER_PREFERENCES_ADD_BROKER';
  brokerId: OnyxBrokerId;
}

export function createAddBrokerPreferencesAction(
  brokerId: OnyxBrokerId,
): AddBrokerPreferencesAction {
  return {
    type: 'USER_PREFERENCES_ADD_BROKER',
    brokerId,
  };
}

export interface DeleteBrokerPreferencesAction {
  type: 'USER_PREFERENCES_DELETE_BROKER';
  brokerId: OnyxBrokerId;
}

export function createDeleteBrokerPreferencesAction(
  brokerId: OnyxBrokerId,
): DeleteBrokerPreferencesAction {
  return {
    type: 'USER_PREFERENCES_DELETE_BROKER',
    brokerId,
  };
}

export interface GetCurrentUserBrokersAction {
  type: 'GET_CURRENT_USER_BROKERS';
}

export function createGetCurrentUserBrokersAction(): GetCurrentUserBrokersAction {
  return {
    type: 'GET_CURRENT_USER_BROKERS',
  };
}

export interface ChangeUserPreferenceSearchBrokersLoadingAction {
  type: 'USER_PREFERENCES_SEARCH_BROKERS_LOADING';
  value: boolean;
}

export function createChangeUserPreferenceSearchBrokersLoadingAction(
  value: boolean,
): ChangeUserPreferenceSearchBrokersLoadingAction {
  return {
    type: 'USER_PREFERENCES_SEARCH_BROKERS_LOADING',
    value,
  };
}

export interface ChangeUserPreferenceAddBrokersLoadingAction {
  type: 'USER_PREFERENCES_ADD_BROKERS_LOADING';
  value: boolean;
}

export function createChangeUserPreferenceAddBrokersLoadingAction(
  value: boolean,
): ChangeUserPreferenceAddBrokersLoadingAction {
  return {
    type: 'USER_PREFERENCES_ADD_BROKERS_LOADING',
    value,
  };
}

export interface ChangeUserPreferenceDeleteBrokersLoadingAction {
  type: 'USER_PREFERENCES_DELETE_BROKERS_LOADING';
  value: boolean;
}

export function createChangeUserPreferenceDeleteBrokersLoadingAction(
  value: boolean,
): ChangeUserPreferenceDeleteBrokersLoadingAction {
  return {
    type: 'USER_PREFERENCES_DELETE_BROKERS_LOADING',
    value,
  };
}

export interface InsertSearchedBrokersAction {
  type: 'USER_PREFERENCES_INSERT_SEARCHED_BROKERS';
  brokers: PrimeBroker[];
}

export function createInsertSearchedBrokersAction(
  brokers: PrimeBroker[],
): InsertSearchedBrokersAction {
  return {
    type: 'USER_PREFERENCES_INSERT_SEARCHED_BROKERS',
    brokers,
  };
}

export type UserPreferencesBrokersAction =
  | GetCurrentUserBrokersAction
  | SearchUserBrokersAction
  | AddBrokerPreferencesAction
  | DeleteBrokerPreferencesAction
  | ChangeUserPreferenceAddBrokersLoadingAction
  | ChangeUserPreferenceDeleteBrokersLoadingAction
  | ChangeUserPreferenceSearchBrokersLoadingAction
  | InsertSearchedBrokersAction;

export const brokersPreferencesActionCreators = {
  createGetCurrentUserBrokersAction,
  createSearchBrokersPreferencesAction,
  createAddBrokerPreferencesAction,
  createDeleteBrokerPreferencesAction,
  createChangeUserPreferenceSearchBrokersLoadingAction,
  createChangeUserPreferenceAddBrokersLoadingAction,
  createChangeUserPreferenceDeleteBrokersLoadingAction,
  createInsertSearchedBrokersAction,
};
