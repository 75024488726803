import type { Thunk } from '@/bootstrap/thunks';
import { DefaultingScope, type IATypeValuesType } from '@/neos/business/neosModel';
import { DefaultingOptions } from '../../rfqActions';

export const unitDefaultValues: Record<IATypeValuesType, string | undefined> = {
  CCY: undefined,
  MULTIPLIER: 'x',
  REF_PERCENT: '%',
};
export function createOnAllocationIATypeChangedThunk(
  legId: string,
  rfqId: string,
  allocationId: string,
  newValue?: IATypeValuesType,
): Thunk {
  return function onAllocationIATypeChangedThunk(dispatch, _, { actionCreators }) {
    dispatch(
      actionCreators.neos.otcAllocationCrudActions.update(
        { allocationId, rfqId, legId },
        {
          independantAmountType: newValue,
          independantAmountValue: undefined,
          independantAmountUnit: newValue ? unitDefaultValues[newValue] : undefined,
        },
      ),
    );
    const overrideScopes = [DefaultingScope.IA_CURRENCY, DefaultingScope.IA_VALUE_DATE];
    const enrichScopes = [DefaultingScope.FOREX];
    const options = new DefaultingOptions({ overrideScopes, enrichScopes });
    dispatch(actionCreators.neos.createDefaultRfqRequestedAction(rfqId, options));
    dispatch(
      actionCreators.neos.allocationUiCrudActions.patchOrInsert(allocationId, {
        errors: { IaType: false },
      }),
    );
  };
}
