import { globalErrorSelectors } from './globalError/globalErrorSelectors';
import { helpRequestSelectors } from './helpRequest/helpRequestSelectors';
import { userInfoSelectors } from './userInfo/userInfoSelectors';

export const commonSelectors = {
  ...userInfoSelectors,
  ...helpRequestSelectors,
  ...globalErrorSelectors,
};

export type CommonSelectors = typeof commonSelectors;
