import type { DisplayNegotiatedSize } from '@/neos/business/ui/strategy/strategyUiModel';
import { NumericInput } from '@/neos/components/share/numericInput';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import { noop } from '@/util/noop';

type IaValueProps = {
  showError?: boolean;
  independantAmountValue: number | undefined;
  onIAChanged?: (allocationId: any, independantAmountValue?: number) => void;
  uiSizeType?: DisplayNegotiatedSize;
  dataE2e: string;
  uuid: string | number;
  independantAmountUnit: string | undefined;
  style?: any;
  disabled?: boolean;
};

export function IAValue({
  showError,
  independantAmountValue,
  uiSizeType,
  onIAChanged,
  dataE2e,
  uuid,
  style,
  independantAmountUnit,
  disabled = false,
}: IaValueProps) {
  const showWarning =
    independantAmountUnit === '%' && uiSizeType !== 'NOTIONAL' && uiSizeType !== 'LOCAL_NOTIONAL';
  return (
    <SimpleNeosTooltip
      disable={!(showWarning || showError)}
      type={showError ? 'danger' : 'warning'}
      id={`ia-percent-tooltip`}
      message={showError ? 'Invalid field' : 'Beware, % of the Allocated amount'}
    >
      <NumericInput
        inputClassName={`${showError ? ' field-error errorable-bloc ' : ''} ${
          showWarning ? 'border border-warning' : ''
        }`}
        className="w-100"
        style={style}
        value={independantAmountValue}
        onBlur={value => (onIAChanged ? onIAChanged(uuid, value) : noop)}
        disableAccelerators
        onlyPositiveNumbers
        data-e2e={dataE2e}
        readOnly={disabled}
      />
    </SimpleNeosTooltip>
  );
}
