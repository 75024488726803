import { useDispatch, useSelector } from 'react-redux';

import { type Action, actionCreators } from '@/bootstrap/actions';
import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { ElsFeaturesContext } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures';
import { ElsBlocField } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/ElsBlocField';
import { useContext } from 'react';
import { FormSelect } from 'react-bootstrap';
import {
  type PartialResetFeature,
  isElsProduct,
} from '../../../../../../../../neos/business/neosModel';
import {
  type PartialResetType,
  partialResetTypes,
} from '../../../../../../../../neos/business/neosOnyxModel';

export function PartialReset() {
  const { strategyId } = useContext(ElsFeaturesContext);
  const dispatch = useDispatch();
  const product = useSelector((state: AppState) =>
    selectors.getStrategyProduct(state, strategyId, selectors),
  );
  const feature: PartialResetFeature | undefined = useSelector((appState: AppState) =>
    selectors.getFeature(appState.featureState, {
      strategyId,
      type: 'PARTIAL_RESET',
    }),
  );
  if (!isElsProduct(product)) {
    return null;
  }

  const onPartialResetChange = (newPartialReset: PartialResetType | undefined) => {
    const action: Action = actionCreators.neos.featureCrudActions.upsert(
      {
        strategyId,
        type: 'PARTIAL_RESET',
      },
      {
        type: 'PARTIAL_RESET',
        strategyId,
        partialReset: newPartialReset,
      },
    );

    dispatch(action);
  };

  return (
    <ElsBlocField
      label="Partial Reset"
      renderInput={readOnly => (
        <FormSelect
          readOnly={readOnly}
          value={feature?.partialReset ?? ''}
          onChange={event =>
            onPartialResetChange((event.target.value as PartialResetType) || undefined)
          }
          data-e2e="rate-convention-section-computed-rate-way"
        >
          <option value=""></option>
          {Object.entries(partialResetTypes).map(([key, value]) => (
            <option key={key} value={key}>
              {value}
            </option>
          ))}
        </FormSelect>
      )}
    />
  );
}
