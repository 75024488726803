import { crudDomain } from '@/util/crudUtils';
import type { UnderlyingUi } from './underlyingUiModel';

interface UiUnderlyingKey {
  rfqId: string;
  underlyingId: string;
}

const compositeIdSeparator = ' | ';

function getUnderlyingDomainId({ rfqId, underlyingId }: UiUnderlyingKey): string {
  return `${rfqId}${compositeIdSeparator}${underlyingId}`;
}

function getUnderlyingDomainKey(id: string): UiUnderlyingKey {
  const [rfqId, underlyingId] = id.split(compositeIdSeparator);
  return {
    rfqId,
    underlyingId,
  };
}

export const underlyingUiDomain = crudDomain<UnderlyingUi, UiUnderlyingKey>(
  'underlying-ui-domain',
  {
    keyToString: getUnderlyingDomainId,
    stringToKey: getUnderlyingDomainKey,
  },
);
