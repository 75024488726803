import type { LogTemplates } from './messageTemplates';

export class NeosError extends Error {
  /**
   *
   */
  public messageTemplate: LogTemplates;
  public properties: any[];
  public isNeosError: boolean = true;
  constructor(messageTemplate: LogTemplates, ...properties: any[]) {
    super();
    this.messageTemplate = messageTemplate;
    this.properties = properties;
    Object.setPrototypeOf(this, NeosError.prototype);
  }
}
