import { createAddNewAsianOptionPeriodDateThunk } from '@/neos/business/rfq/strategy/leg/product/thunks/createAddNewAsianOptionPeriodDateThunk';
import { createDeleteAsianOptionPeriodDateThunk } from '@/neos/business/rfq/strategy/leg/product/thunks/createDeleteAsianOptionPeriodDateThunk';
import { createUpdateAsianOptionPeriodDateThunk } from '@/neos/business/rfq/strategy/leg/product/thunks/createUpdateAsianOptionPeriodDateThunk';
import { elsProductThunks } from '@/neos/business/rfq/strategy/leg/product/els/thunks';

export const productThunks = {
  ...elsProductThunks,
  createAddNewAsianOptionPeriodDateThunk,
  createUpdateAsianOptionPeriodDateThunk,
  createDeleteAsianOptionPeriodDateThunk,
};
