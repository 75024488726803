import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { type OptionType, isOptionLike } from '../../../../../../../neos/business/neosModel';
import type { Field } from '../utils/Field';
import { getIsStrategyDefinitionFieldEditable } from '../utils/fieldsEnablingConditions';
import { getFieldsArray } from '../utils/getFieldsArray';

export interface OptionTypeCellsModel {
  optionTypes: Array<Field<OptionType | undefined>>;
  availableTypes: Array<OptionType | undefined>;
}

export function getOptionTypeCellsModel(
  appState: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): OptionTypeCellsModel {
  const { getStrategyData, getStrategyProducts } = selectors;
  const { getStrategyDefinition } = selectors;

  const { strategyType } = getStrategyData(appState, strategyId);
  const { sameProductOptionType, readOnlyProductOptionType } = getStrategyDefinition(
    appState.referenceData,
    strategyType,
  );

  const products = getStrategyProducts(appState, strategyId, selectors).filter(isOptionLike);

  const optionTypesAreEditable = getIsStrategyDefinitionFieldEditable(
    appState,
    rfqId,
    strategyId,
    selectors,
    ['RFQ_SCOPE', 'RFQ_EDITABLE', 'UNDERLYING_SET', 'STRATEGY_EDITABLE'],
  );
  const isReadonlyAtWorkflow = selectors.isReadOnlyAtCurrentWorkflow(
    appState,
    strategyId,
    selectors,
  );

  const isReadonly = readOnlyProductOptionType || !optionTypesAreEditable || isReadonlyAtWorkflow;
  const optionTypes = getFieldsArray(products, 'type', sameProductOptionType, isReadonly);

  return {
    optionTypes,
    availableTypes: ['CALL', 'PUT', undefined],
  };
}
