import type { Thunk } from '@/bootstrap/thunks';
import type { ExecutionKey } from '../executionModel';

export function createChangeExecutionTimeThunk(
  executionKey: ExecutionKey,
  newTime: string | undefined,
): Thunk {
  return function changeExecutionTimeThunk(
    dispatch,
    getState,
    { selectors, actionCreators: { neos: as } },
  ) {
    const state = getState();
    const execution = selectors.executionSelectors.find(state.execution, executionKey);
    const displayTimeZone = selectors.getDisplayTimeZone(state.ui.userPreferences);
    if (!execution) {
      return;
    }
    if (!newTime) {
      return dispatch(as.executionCrudActions.update(executionKey, { executionTime: undefined }));
    }

    const { executionTime } = execution;
    const [hours, minutes, seconds] = newTime.split(':');
    const executionTimeDate = executionTime
      ? new Date(
          executionTime?.substring(
            0,
            executionTime?.indexOf('[') > 0 ? executionTime?.indexOf('[') : executionTime.length,
          ),
        )
      : new Date();

    if (displayTimeZone === 'LOCAL') {
      executionTimeDate.setHours(Number(hours), Number(minutes), Number(seconds), 0);
    } else {
      executionTimeDate.setUTCHours(Number(hours), Number(minutes), Number(seconds), 0);
    }

    dispatch(
      as.executionCrudActions.update(executionKey, {
        executionTime: executionTimeDate.toISOString(),
      }),
    );
  };
}
