import type { Selectors } from '@/bootstrap/selectors';
import type { Services } from '@/bootstrap/services';
import type { AppState } from '@/bootstrap/state';
import type { MdpRfqTimerStateProps } from '../MdpRfqTimer';

export interface RfqTimerModelBase {
  isMdpRfqTimerDisplay: boolean;
}

export interface TradeWebRfqTimerModel extends RfqTimerModelBase, MdpRfqTimerStateProps {
  isMdpRfqTimerDisplay: true;
}

export interface RegularRfqTimerModel extends RfqTimerModelBase {
  isMdpRfqTimerDisplay: false;
}

export type RfqTimerModel = TradeWebRfqTimerModel | RegularRfqTimerModel;

export function getMdpRfqValidityTimerModel(
  state: AppState,
  rfqId: string,
  neosSelectors: Selectors,
  { orderOf }: Services,
): RfqTimerModel {
  const {
    rfqExpirationUTCTimestamp: expiry,
    creationTime,
    status,
  } = neosSelectors.getRfqData(state, rfqId);

  const isDisplayForCurrentStatus = orderOf(status).isBefore('ORDER_RECEIVED');

  if (isDisplayForCurrentStatus && expiry && creationTime) {
    return {
      isMdpRfqTimerDisplay: true,
      expiry,
      creationTime,
    };
  }
  return {
    isMdpRfqTimerDisplay: false,
  };
}
