import { NeosTooltip } from '@/neos/components/share/tooltip/NeosTooltip';
import { Tooltip } from 'react-bootstrap';

export interface LegDetailsButtonOwnProps {
  rfqId: string;
}

export interface LegDetailsButtonPropsFromState {
  showLegDetails: boolean;
}

export interface LegDetailsButtonDispatchProps {
  onToggleLegDetails(showLegDetails: boolean): void;
}

export type LegDetailsButtonProps = LegDetailsButtonOwnProps &
  LegDetailsButtonPropsFromState &
  LegDetailsButtonDispatchProps;

export const LegDetailsButtonComponentWithCss = (props: LegDetailsButtonProps) => (
  <NeosTooltip
    placement="top"
    overlay={<Tooltip id="show-leg-details">Toggle Leg Details</Tooltip>}
  >
    <button
      className="btn btn-primary btn-icon"
      data-e2e="toggle-legs-button"
      onClick={() => props.onToggleLegDetails(!props.showLegDetails)}
    >
      <i className="icon icon-sm">list</i>
    </button>
  </NeosTooltip>
);
