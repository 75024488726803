import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { NumericInput } from '@/neos/components/share/numericInput';
import type { ListedAllocationWithBookingInfo } from './getExecutionAllocationsModel';

export function TotalCommissionAmount({
  rfqId,
  allocation,
}: {
  rfqId: string;
  allocation: ListedAllocationWithBookingInfo;
}) {
  const totalCommissionAmount = useAppSelector(state =>
    selectors.getAllocationTotalCommission(state, rfqId, allocation, selectors),
  );

  return (
    <NumericInput
      key={allocation.uuid}
      value={totalCommissionAmount}
      readOnly={true}
      data-e2e="allocation-total-commission-amount"
    />
  );
}
