import { Modal } from 'react-bootstrap';
import { thunks } from '@/bootstrap/thunks';
import { useDispatch } from 'react-redux';
import { commonSelectors } from '@/common/business/commonSelectors';
import { useAppSelector } from '@/bootstrap/hooks';
import { commonActionCreators } from '@/common/business/commonActionCreators';

export const AppCrashModal = () => {
  const dispatch = useDispatch();
  const showModal = useAppSelector(state => commonSelectors.isHelpRequestModalDisplayed(state));

  function hideModal() {
    dispatch(commonActionCreators.createHelpRequestModalDisplayedAction(false));
  }

  function handleSendHelpRequestClick() {
    hideModal();
    dispatch(thunks.common.createHelpRequestSentThunk());
  }

  return (
    <Modal show={showModal} size="xl" onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>Help Request</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>
          An unexpected error has occured.
          <br /> You can contact us to provide a comment to help fix this issue and improve our
          service
        </span>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-lg btn-primary" onClick={handleSendHelpRequestClick}>
          Send help request
        </button>
      </Modal.Footer>
    </Modal>
  );
};
