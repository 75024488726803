import type { Thunk } from '@/bootstrap/thunks';
import type { OnyxFieldError } from '../mappers/error/onyxErrorModel';

export function createHandleErrorsThunk(rfqId: string, fieldErrors: OnyxFieldError[]): Thunk {
  return function handleErrorsThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators,

      fromMappers: { mapFromOnyxErrors },
    },
  ) {
    const state = getState();
    if (fieldErrors.length === 0) {
      return;
    }
    try {
      const neosErrors = mapFromOnyxErrors(state, rfqId, fieldErrors, selectors);
      const actions = neosErrors.map(({ errorId, isError, errorMessages }) =>
        actionCreators.neos.errorCrudActions.upsert(errorId, {
          isError,
          errorMessages,
        }),
      );
      dispatch(actions);
    } catch (e) {
      dispatch(actionCreators.common.createErrorAction('WRONG_BACKEND_ERROR_MESSAGE', e));
    }
  };
}
