import { hasDiscriminator } from '@/neos/business/rfq/strategy/feature/hasDiscriminator';
import type { WithExtraProperty } from '@/neos/business/rfq/strategy/feature/withExtraProperty';
import type { InterestRateFeature } from '../../../../../../neos/business/neosModel';
import type {
  OnyxInterestRate,
  OnyxProductFeaturesFields,
} from '../../../../../../neos/business/neosOnyxModel';

export function mapFromOnyxFeatureOrExtraFeaturesCurveTenor(
  { extraFeatures, rateCurve, newRateTenor }: OnyxProductFeaturesFields,
  strategyId: string,
): InterestRateFeature | undefined {
  const mappedInterestRate: Pick<InterestRateFeature, 'strategyId' | 'type'> = {
    strategyId,
    type: 'INTEREST_RATE_INDEX_FEATURE',
  };

  if (rateCurve !== undefined || newRateTenor !== undefined) {
    return {
      ...mappedInterestRate,
      rateCurve: rateCurve ?? undefined,
      rateTenor: undefined,
      newRateTenor: newRateTenor ?? undefined,
    };
  }

  if (extraFeatures) {
    const interestRateFeature: OnyxInterestRate | undefined = extraFeatures.find(
      hasDiscriminator<OnyxInterestRate>('INTEREST_RATE_INDEX'),
    );
    if (interestRateFeature) {
      return {
        ...mappedInterestRate,
        rateCurve: interestRateFeature.rateCurve ?? undefined,
        rateTenor: interestRateFeature.rateTenor ?? undefined,
        newRateTenor: interestRateFeature.newRateTenor ?? undefined,
      };
    }
  }

  return undefined;
}

export function mapToOnyxInterestRateFeature(
  feature: WithExtraProperty<InterestRateFeature>,
): OnyxProductFeaturesFields {
  const { rateTenor, rateCurve, newRateTenor } = feature;

  if (!feature.isExtra) {
    return {
      rateTenor,
      newRateTenor,
      rateCurve,
      extraFeatures: [],
    };
  }

  return {
    extraFeatures: [
      {
        discriminator: 'INTEREST_RATE_INDEX',
        rateTenor,
        rateCurve,
        newRateTenor,
      },
    ],
  };
}
