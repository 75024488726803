import { type ActionCreators, actionCreators } from '@/bootstrap/actions';
import type { ThunkEpic } from '@/bootstrap/epics';
import { type Thunks, thunks } from '@/bootstrap/thunks';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { ofType } from 'redux-observable';
import type { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { wrapInLoadingObservable } from '../../epics/wrapInLoadingObservable';
import type { OnyxError } from '../../mappers/error';
import { createLoadVersionsApi } from './versionsApi';
import type { Version } from './versionsModel';

export interface LoadVersionsApi {
  loadVersions: (uuid: string) => Observable<Version[]>;
}

export function getVersionsEpic(http: SgmeHttp) {
  const api = createLoadVersionsApi(http);
  return createLoadVersionsEpic(api, actionCreators, thunks);
}

export function createLoadVersionsEpic(
  api: LoadVersionsApi,
  { common: { createAppCrashedAction }, neos: { versionsCrudActions } }: ActionCreators,
  { createErrorToasterThunk }: Thunks,
): ThunkEpic {
  return action$ =>
    action$.pipe(
      ofType('LOAD_VERSIONS_REQUESTED'),
      mergeMap(({ rfqId }) => {
        return wrapInLoadingObservable({
          tabIds: [rfqId],
          apiObservable: api.loadVersions(rfqId),
          onSuccess: versions =>
            versionsCrudActions.upsert(rfqId, {
              versions: versions.map(v => ({
                lifecycle: v.lifecycle,
                tradeDate: v.tradeDate,
                version: v.version,
              })),
            }),
          onError: (error: OnyxError) => [
            createErrorToasterThunk(
              {
                message: 'Error when loading version list',
              },
              error,
            ),
            createAppCrashedAction('load-versions-requested', error),
          ],
        });
      }),
    );
}
