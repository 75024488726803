import type { HandleAllocationFileAction } from './handleAllocationFileActions';

export const handleAllocationFileActionCreators = {
  createDownloadAllocationFileAction,
  createUploadAllocationFileAction,
  createDownloadSuccessAction,
};

function createDownloadAllocationFileAction(
  rfqId: string,
  allocId: string,
): HandleAllocationFileAction {
  return {
    type: 'DOWNLOAD_ALLOCATION_FILE',
    rfqId,
    allocId,
  };
}

function createUploadAllocationFileAction(
  rfqId: string,
  allocId: string,
  file: FormData,
): HandleAllocationFileAction {
  return {
    type: 'UPLOAD_ALLOCATION_FILE',
    rfqId,
    allocId,
    file,
  };
}

function createDownloadSuccessAction(): HandleAllocationFileAction {
  return {
    type: 'ALLOCATION_DOWNLOAD_SUCCESS',
  };
}
