import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import type { ReactElement } from 'react';

export function InvalidTooltip({ show, children }: { show?: boolean; children: ReactElement }) {
  return (
    <SimpleNeosTooltip disable={!show} type={'danger'} message={'Invalid field'}>
      {children}
    </SimpleNeosTooltip>
  );
}
