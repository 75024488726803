import type { Selectors } from '@/bootstrap/selectors';
import { services } from '@/bootstrap/services';
import type { AppState } from '@/bootstrap/state';
import { isOptionLike } from '@/neos/business/rfq/strategy/leg/product/productModel';
import { has } from 'lodash';
import { cancelledWithoutReasonLabel, type StatusSubStatusLabel } from './statusLabelModel';
import {
  type LabelableStatus,
  labelStatuses,
  labelStatusLabels,
  type Status,
  type StatusLabel,
} from './statusModel';
import {
  type LabelableSubStatus,
  labelSubStatuses,
  labelSubStatusLabels,
  type SubStatus,
  type SubStatusLabel,
} from './subStatus/subStatusModel';

export const statusSelectors = {
  getStatusLabel,
  getLabelStatus,
  isStatusStrictlyBeforeOrderAccepted,
  isStatusAfterOrderBeingCrossed,
  isStatusAfterPricedIncluded,
  isStatusBeforePriceProposed,
  isStatusOrderConfirmedOrAfter,
  isOtcStrategyAndAmendWorkflowStatus,
  isOtcOptionLikeLegInAmendWorkflowStatus,
  isAmendWorkflowWithInvalidTradeDate,
  isRfqStatusInAmendWorkflow,
  isRfqStatusInCancelWorkflow,
  isElsStrategyInAmendWorkflowStatus,
  isStatusAtOrAfterOrderAccepted,
  isStatusAfterBookingRequestedIncluded,
};

function getLabelStatus(status: LabelableStatus): StatusLabel {
  return labelStatuses[status];
}

function getLabelSubStatus(status: LabelableSubStatus): SubStatusLabel {
  return labelSubStatuses[status];
}

export function getStatusAndSubStatusFromLabel(statusLabel: StatusSubStatusLabel): {
  status: Status;
  subStatus: SubStatus;
} {
  return has(labelStatusLabels, statusLabel)
    ? { status: labelStatusLabels[statusLabel as StatusLabel], subStatus: 'NONE' }
    : {
        status: 'CANCELLED',
        subStatus:
          statusLabel === cancelledWithoutReasonLabel
            ? 'NONE'
            : labelSubStatusLabels[statusLabel as SubStatusLabel],
      };
}

export function getStatusLabel(
  status: Status,
  subStatus: SubStatus | undefined,
): StatusSubStatusLabel {
  return status === 'CANCELLED'
    ? subStatus !== 'NONE' && subStatus
      ? getLabelSubStatus(subStatus)
      : cancelledWithoutReasonLabel
    : getLabelStatus(status);
}

export function isStatusStrictlyBeforeOrderAccepted(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): boolean {
  const { status } = selectors.getRfqData(state, rfqId);
  return services.orderOf(status).isBefore('ORDER_ACCEPTED');
}

export function isStatusAtOrAfterOrderAccepted(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): boolean {
  const { status } = selectors.getRfqData(state, rfqId);
  return services.orderOf(status).isAfterOrEqual('ORDER_ACCEPTED');
}

export function isStatusAfterBookingRequestedIncluded(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): boolean {
  const { status } = selectors.getRfqData(state, rfqId);
  return services.orderOf(status).isAfterOrEqual('BOOKING_REQUESTED');
}

function isAmendWorkflowStatus(status: Status): boolean {
  const amendWorkflowStatuses: Status[] = [
    'TRADE_COMPLETED',
    'AMEND_REQUESTED',
    'BEING_AMENDED',
    'DEAL_AMENDED',
    'AMEND_BEING_COMPLETED',
    'AMEND_COMPLETED',
  ];
  return amendWorkflowStatuses.includes(status);
}

export function isAmendWorkflowWithInvalidTradeDate(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): boolean {
  const isInAmendWorkflow = selectors.isRfqStatusInAmendWorkflow(state, rfqId, selectors);
  const tradeDateToday = selectors.isTradeDateToday(state, rfqId, selectors);
  return isInAmendWorkflow && !tradeDateToday;
}

export function isOtcOptionLikeLegInAmendWorkflowStatus(
  state: AppState,
  rfqId: string,
  legId: string,
  selectors: Selectors,
): boolean {
  const isOtcLeg = selectors.isOtcLeg(state, legId, selectors);
  const product = selectors.getLegProduct(state, legId, selectors);
  const isOptionLikeProduct = isOptionLike(product);
  const isInAmendWorkflow = selectors.isRfqStatusInAmendWorkflow(state, rfqId, selectors);

  return isOtcLeg && isInAmendWorkflow && isOptionLikeProduct;
}

export function isOtcStrategyAndAmendWorkflowStatus(
  state: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): boolean {
  const isOtcStrategy = selectors.isOtcStrategy(state, strategyId, selectors);
  const isInAmendWorkflow = selectors.isRfqStatusInAmendWorkflow(state, rfqId, selectors);
  return isOtcStrategy && isInAmendWorkflow;
}

export function isElsStrategyInAmendWorkflowStatus(
  state: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): boolean {
  const isElsStrategy = selectors.isElsStrategy(state, strategyId, selectors);
  const isInAmendWorkflow = selectors.isRfqStatusInAmendWorkflow(state, rfqId, selectors);
  return isElsStrategy && isInAmendWorkflow;
}

export function isRfqStatusInCancelWorkflow(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): boolean {
  const { status } = selectors.getRfqData(state, rfqId);
  const cancelWorkflowStatuses: Status[] = [
    'TRADE_COMPLETED',
    'TRADE_CANCELLED',
    'TRADE_TO_BE_CANCELLED',
    'TRADE_BEING_CANCELLED',
  ];
  return cancelWorkflowStatuses.includes(status);
}

export function isRfqStatusInAmendWorkflow(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): boolean {
  const { status } = selectors.getRfqData(state, rfqId);
  return isAmendWorkflowStatus(status);
}

export function isStatusAfterTradedIncluded(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): boolean {
  const { status } = selectors.getRfqData(state, rfqId);
  return services.orderOf(status).isAfterOrEqual('TRADED');
}

export function isStatusAfterPriceRequestedIncluded(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): boolean {
  const { status } = selectors.getRfqData(state, rfqId);
  return services.orderOf(status).isAfterOrEqual('PRICE_REQUESTED');
}

export function isStatusAfterPricedIncluded(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): boolean {
  const { status } = selectors.getRfqData(state, rfqId);
  return services.orderOf(status).isAfterOrEqual('PRICED');
}

export function isStatusBeforePriceProposed(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): boolean {
  const { status } = selectors.getRfqData(state, rfqId);
  return services.orderOf(status).isBefore('PRICE_PROPOSED');
}

export function isStatusOrderConfirmedOrAfter(
  state: AppState,
  rfqId: string,
  appSelectors: Selectors,
): boolean {
  const { status } = appSelectors.getRfqData(state, rfqId);
  return services.orderOf(status).isAfterOrEqual('ORDER_CONFIRMED');
}

export function isStatusAfterOrderBeingCrossed(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): boolean {
  const { status } = selectors.getRfqData(state, rfqId);
  return services.orderOf(status).isAfter('ORDER_BEING_CROSSED');
}
