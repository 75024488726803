import type { AjaxResponse } from 'rxjs/ajax';

type JsonResponseContentType = { contentType: 'JSON' };
type BlobResponseContentType = { contentType: 'BLOB' };

export type ResponseContentType = JsonResponseContentType | BlobResponseContentType;

export function downloadBlob(blob: Blob, fileName: string) {
  const url = window.URL.createObjectURL(blob);
  const link: HTMLAnchorElement = window.document.createElement('a');
  link.setAttribute('href', url);

  link.setAttribute('download', fileName);

  window.document.body.appendChild(link);

  if (typeof link.click === 'function') {
    link.click();
  }
  window.document.body.removeChild(link);
}

export function getResponse(
  { response, xhr }: AjaxResponse,
  responseContentType: ResponseContentType,
) {
  if (!response) {
    return undefined;
  }

  if (responseContentType.contentType === 'BLOB') {
    const fileType = xhr.getResponseHeader('Content-Type') ?? undefined;
    const blob = response instanceof Blob ? response : new Blob([response], { type: fileType });

    const contentDispositionHeader = xhr.getResponseHeader('Content-Disposition');

    if (contentDispositionHeader) {
      const [_, fileName] = contentDispositionHeader.split('"');

      return { blob, metadata: { fileName } };
    }
  }

  return JSON.parse(response as string);
}
