import { ClsCurve } from '@/neos/components/rfq/strategies/strategy/features/interestRate/ClsCurve';
import { ClsSwapCurrency } from '@/neos/components/rfq/strategies/strategy/features/interestRate/ClsSwapCurrency';
import { RateTenor } from '../interestRate/RateTenor';
import type { ClsModel } from './getClsModel';

export interface OwnProps {
  rfqId: string;
  strategyId: string;
}

export type StateProps = ClsModel;

type Props = OwnProps & StateProps;

const labelClassNames = 'd-block mb-0 fw-bold';
const parentClassNames = 'py-1 ps-1';

export const Cls = ({ strategyId, rfqId, isDisplayed }: Props) => {
  if (!isDisplayed) {
    return null;
  }

  return (
    <div className="d-flex border mb-1 pe-1 els-fields">
      <ClsCurve
        rfqId={rfqId}
        strategyId={strategyId}
        labelClassNames={labelClassNames}
        parentClassNames={parentClassNames}
      />
      <RateTenor
        strategyId={strategyId}
        labelClassNames={labelClassNames}
        parentClassNames={parentClassNames}
      />
      <ClsSwapCurrency
        rfqId={rfqId}
        strategyId={strategyId}
        labelClassNames={labelClassNames}
        parentClassNames={parentClassNames}
      />
    </div>
  );
};
