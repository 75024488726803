import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { getIsStrategyDefinitionFieldEditable } from '../utils/fieldsEnablingConditions';

export type IsSettlementDateCellsEditableModel = boolean;

export function getIsSettlementDateCellsEditableModel(
  state: AppState,
  { strategyId, rfqId }: { strategyId: string; rfqId: string },
  selectors: Selectors,
): IsSettlementDateCellsEditableModel {
  const isEditable = getIsStrategyDefinitionFieldEditable(state, rfqId, strategyId, selectors, [
    'RFQ_SCOPE',
    'RFQ_EDITABLE',
    'UNDERLYING_SET',
    'STRATEGY_EDITABLE',
  ]);

  return isEditable;
}
