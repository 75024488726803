import { ErrorHighlight } from '@/neos/components/share/errorHighlight';
import { MultiToggleButton } from '@/neos/components/share/multiToggleButton/MultiToggleButton';
import type { FC } from 'react';
import type { ClsType } from '../../../../../../../neos/business/neosModel';
import type { ClsTypeCellsModel } from './getClsTypeCellsModel';

export interface ClsTypeCellsOwnProps {
  rfqId: string;
  strategyId: string;
}

export type ClsTypeCellsMapStateProps = ClsTypeCellsModel;

export interface ClsTypeCellsDispatchProps {
  onClsTypeChanged(productId: string, clsType: ClsType | undefined): void;
}

type ClsTypeCellsProps = ClsTypeCellsOwnProps &
  ClsTypeCellsMapStateProps &
  ClsTypeCellsDispatchProps;

export const ClsTypeCells: FC<ClsTypeCellsProps> = props => {
  return (
    <section>
      {props.clsTypes.map(({ id, value, isEditable }) => (
        <ErrorHighlight
          key={id}
          errorField={'clsType'}
          related={{ rfqId: props.rfqId, legId: id, strategyId: props.strategyId }}
        >
          <MultiToggleButton<ClsType | undefined>
            className="form-control"
            readOnly={!isEditable}
            selectedValue={value}
            valueFormatter={v => (v ? v : '')}
            availableValues={props.availableClsTypes}
            onValueChange={newClsType => props.onClsTypeChanged(id, newClsType)}
            data-e2e="neos-strategy-cls-type-cell"
          />
        </ErrorHighlight>
      ))}
    </section>
  );
};
