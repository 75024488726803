import type { Thunk } from '@/bootstrap/thunks';
import type { OnyxOrder } from '../orderOnyxModel';

export function createIntegrateOrderNotificationThunk(rfqId: string, onyxOrder: OnyxOrder): Thunk {
  return function integrateOrderNotificationThunk(
    dispatch,
    getState,
    {
      selectors: { tabExists },
      thunks: {
        neos: { createIntegrateOrderExecutionPricesDifferenceNotificationThunk },
      },
      actionCreators: {
        neos: { createOrdersReceived },
      },
      fromMappers,
    },
  ) {
    const state = getState();
    const rfqIsOpen = tabExists(state.ui.tabManagement, rfqId);
    if (!rfqIsOpen) {
      return;
    }

    dispatch(
      createIntegrateOrderExecutionPricesDifferenceNotificationThunk(rfqId, onyxOrder),
      createOrdersReceived(rfqId, [onyxOrder], fromMappers),
    );
  };
}
