import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { PrimeBroker } from '@/neos/business/referenceData/primeBroker/primeBrokerActions';

export interface PrimeBrokersModel {
  primeBrokers: PrimeBroker[] | undefined;
  arePrimeBrokersDisplayed: boolean;
}

export function getPrimeBrokersModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): PrimeBrokersModel {
  const primeBrokers = selectors.getPrimeBrokersByRfqId(state, rfqId, selectors);
  const arePrimeBrokersDisplayed = !!(primeBrokers && primeBrokers.length);

  return {
    primeBrokers,
    arePrimeBrokersDisplayed,
  };
}
