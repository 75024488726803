import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';

export type CreateAllocSecondaryButtonModel = {
  isDisplayed: boolean;
  isDisabled: boolean;
};

export function getCreateAllocSecondaryButtonModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): CreateAllocSecondaryButtonModel {
  const areSomeAllocSecondaryEventTypesSelectedForTheRfq =
    selectors.areSomeAllocSecondaryEventTypesSelectedForTheRfq(state, rfqId);
  const isAllocSecondaryEventCreationColumnDisplayed =
    selectors.isOtcAllocSecondaryWidgetColumnDisplayed(state, rfqId, selectors);
  return {
    isDisabled: !areSomeAllocSecondaryEventTypesSelectedForTheRfq,
    isDisplayed: isAllocSecondaryEventCreationColumnDisplayed,
  };
}
