import {
  BookingStatusTooltip,
  type BookingStepStatus,
  bookingStepStatusClassName,
  getBookingStatusTooltipClassName,
} from '@/neos/business/neosOnyxModel';
import { useGetStringToLocalDateConvertor } from '@/neos/components/share/NeosBookingId/useGetStringToLocalDateConvertor';
import { If } from '@/neos/components/share/if/if';
import { NeosTooltip } from '@/neos/components/share/tooltip/NeosTooltip';
import { type OverlayTriggerProps, Tooltip } from 'react-bootstrap';
import { NeosBlurInputWithDefaultValue } from '../blurComponent/NeosBlurInputWithDefaultValue';

type NeosBookingIdBase = {
  bookingId: string | undefined;
  bookingStatus?: BookingStepStatus;
  message?: string;
  dataE2e: string;
  overlayTriggerPlacement?: OverlayTriggerProps['placement'];
  isReadOnly?: boolean;
  bookingTimeStamp?: string;
};

type NeosBookingIdReadonly = NeosBookingIdBase & {
  onBookingIdChanged: null;
};
type NeosBookingIdEditable = NeosBookingIdBase & {
  onBookingIdChanged: (bookingId: string | undefined) => void;
  defaultBookingId: string | undefined;
};

type NeosBookingIdProps = NeosBookingIdEditable | NeosBookingIdReadonly;

export function NeosBookingId(props: NeosBookingIdProps) {
  const {
    bookingId,
    bookingStatus,
    dataE2e,
    onBookingIdChanged,
    overlayTriggerPlacement,
    isReadOnly = false,
    bookingTimeStamp,
    message,
  } = props;
  const bookingStatusTooltip = bookingStatus ? BookingStatusTooltip[bookingStatus] : '';
  const { stringToLocalDateConvertor } = useGetStringToLocalDateConvertor();

  const bookingTimeStampMessage =
    bookingTimeStamp !== undefined ? ` on ${stringToLocalDateConvertor(bookingTimeStamp)}` : '';

  const inputClassName = bookingStatus ? bookingStepStatusClassName[bookingStatus] : '';

  function handleBookingIdChange(value: string) {
    const newBookingId = value || undefined;
    if (newBookingId !== bookingId && onBookingIdChanged) {
      onBookingIdChanged(newBookingId);
    }
  }

  function getIsBookingIdEditable(props: NeosBookingIdProps): props is NeosBookingIdEditable {
    return !!props.onBookingIdChanged;
  }

  const isBookingIdEditable = getIsBookingIdEditable(props);

  return (
    <NeosTooltip
      placement={overlayTriggerPlacement}
      overlay={
        <Tooltip className={getBookingStatusTooltipClassName(bookingStatus)} id={'tooltip-id'}>
          <b>{bookingStatusTooltip}</b> {bookingTimeStampMessage}
          <If condition={message !== undefined}>
            <div>{message}</div>
          </If>
        </Tooltip>
      }
    >
      <NeosBlurInputWithDefaultValue
        className={`input form-control ${inputClassName}`}
        data-e2e={dataE2e}
        data-testid={dataE2e}
        value={bookingId ?? ''}
        readOnly={isReadOnly}
        onBlur={event => handleBookingIdChange(event.target.value)}
        defaultValue={isBookingIdEditable ? props.defaultBookingId : undefined}
      />
    </NeosTooltip>
  );
}
