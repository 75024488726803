import type { DOMAttributes, SyntheticEvent } from 'react';

export const getBlockingProps: (
  blockCondition: boolean,
) => Partial<DOMAttributes<Element>> = blockCondition =>
  blockCondition
    ? {
        onFocusCapture: e => {
          const shouldNotFocus = e.target.getAttribute('data-should-not-focus-on-loading');
          if (shouldNotFocus) {
            stopPropagation(e);
          }
        },
        onClickCapture: stopPropagation,
        onMouseDownCapture: stopPropagation,
        onDoubleClickCapture: stopPropagation,
        onChangeCapture: stopPropagation,
        onInputCapture: stopPropagation,
        onSelectCapture: stopPropagation,
        // Allow tab (9) & shift (16)
        onKeyDownCapture: e => e.keyCode !== 9 && e.keyCode !== 16 && stopPropagation(e),
        onKeyPressCapture: e => e.keyCode !== 9 && e.keyCode !== 16 && stopPropagation(e),
        onKeyUpCapture: e => e.keyCode !== 9 && e.keyCode !== 16 && stopPropagation(e),
      }
    : {};

function stopPropagation(e: SyntheticEvent<Element>) {
  e.preventDefault();
  e.stopPropagation();
  e.nativeEvent.stopImmediatePropagation();
}
