import type { FC } from 'react';
import { FlowDatePicker } from '../../../../../share/datePicker';
import { ErrorHighlight } from '../../../../../share/errorHighlight';
import type { EndOfObservationModel } from './getEndOfObservationModel';

export interface OwnProps {
  rfqId: string;
  strategyId: string;
}

export interface StateProps {
  model: EndOfObservationModel;
}

type Props = OwnProps & StateProps;

export const EndOfObservationComponent: FC<Props> = ({ rfqId, strategyId, model }) => {
  if (!model.isDisplayed) {
    return null;
  }
  const { endOfObservation } = model;
  return (
    <div className="d-grid gap-1 mb-1 p-1 border fit-content">
      <span className="fw-bold">End Of Observation</span>
      <ErrorHighlight errorField="endOfObservation" related={{ rfqId, strategyId }}>
        <FlowDatePicker
          data-e2e="neos-extra-features-enOfObservation"
          readOnly
          date={endOfObservation ?? ''}
          maturities={[]}
          onChange={() => null}
          hideCalendarIcon
        />
      </ErrorHighlight>
    </div>
  );
};
