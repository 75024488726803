import type { Dispatchable, Thunk } from '@/bootstrap/thunks';
import { flatten } from 'lodash';
import { type NegotiationMode, isOptionLike } from '../strategyModel';

export function createChangeStrategiesNegotiationModeThunk(
  rfqId: string,
  strategyIds: string[],
  negotiationMode: NegotiationMode,
): Thunk {
  return function changeStrategiesNegotiationModeThunk(
    dispatch,
    getState,
    {
      selectors,
      thunks: {
        neos: { createChangeStrategyNegotiationModeThunk },
      },
      actionCreators: {
        neos: {
          createDefaultNegotiationModeRequestedAction,
          createDefaultNotionalRequestedAction,
          productCrudActions,
        },
      },
    },
  ) {
    const state = getState();
    const { getRfqMasterStrategy, getStrategyProducts } = selectors;
    const { getStrategyDefinition } = selectors;
    const masterStrategy = getRfqMasterStrategy(state, rfqId);
    const masterStrategyDefinition = getStrategyDefinition(
      state.referenceData,
      masterStrategy.strategyType,
    );
    const masterStrategyNegotiationModeIsReadOnly =
      masterStrategyDefinition.readOnlyProductNegotiationMode;

    const containsMasterStrategy = strategyIds.some(
      strategyId => strategyId === masterStrategy.uuid,
    );
    const willNegotiationModeOfMasterStrategyChange = getStrategyProducts(
      state,
      masterStrategy.uuid,
      selectors,
    ).every(({ negotiationMode: current }) => current !== negotiationMode);

    const shouldRequestNotionalDefaulting =
      containsMasterStrategy &&
      willNegotiationModeOfMasterStrategyChange &&
      !masterStrategyNegotiationModeIsReadOnly;

    const defaultingAction = shouldRequestNotionalDefaulting
      ? createDefaultNotionalRequestedAction(rfqId, true)
      : createDefaultNegotiationModeRequestedAction(rfqId);

    dispatch(
      strategyIds.map(strategyId =>
        createChangeStrategyNegotiationModeThunk(strategyId, negotiationMode),
      ),
    );

    dispatch(defaultingAction);
    if (negotiationMode === 'OTC') {
      const products = flatten(
        strategyIds.map(id =>
          getStrategyProducts(state, id, selectors).filter(p => isOptionLike(p) && p.flex),
        ),
      );

      const resetFlexActions: Dispatchable = products.map(p =>
        productCrudActions.update(p.uuid, { flex: undefined }),
      );

      dispatch(resetFlexActions);
    }
  };
}
