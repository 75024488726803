import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { constantToSentence } from '@/util/string/stringUtils';
import { connect } from 'react-redux';
import {
  QuoteStatusComponent,
  type QuoteStatusMapStateToProps,
  type QuoteStatusOwnProps,
} from './QuoteStatus';

export const QuoteStatus = connect(mapStateToProps)(QuoteStatusComponent);

function mapStateToProps(
  state: AppState,
  { rfqId }: QuoteStatusOwnProps,
): QuoteStatusMapStateToProps {
  const { quoteId } = selectors.getRfqData(state, rfqId);
  const { status, subStatus } = selectors.getQuote(state, quoteId);
  const warningOrErrorSubStatuses = [
    'NOT_IN_SCOPE',
    'TIMEOUT',
    'TO_BE_REPRICED',
    'AUTOMATIC_PRICER_FAILED_TO_PRICE',
    'INDICATIVE',
  ];

  if (status && subStatus && warningOrErrorSubStatuses.includes(subStatus)) {
    if (subStatus === 'INDICATIVE') {
      return {
        color: 'warning',
        quoteSubStatus: constantToSentence(subStatus),
        quoteStatus: constantToSentence(status),
      };
    }

    return {
      color: 'error',
      quoteSubStatus: constantToSentence(subStatus),
      quoteStatus: constantToSentence(status),
    };
  }

  return {};
}
