import type { BlotterRowModel } from '@/neos/components/share/blotterGrid';
import type { GetContextMenuItemsParams } from '@ag-grid-community/core';

export function updateAndReturnSelectedRows(params: GetContextMenuItemsParams): BlotterRowModel[] {
  const lastClickedNode = params.node;
  const { api } = params;
  if (!api) {
    return [];
  }

  if (lastClickedNode?.data) {
    const lastClickedNodeData = lastClickedNode.data as BlotterRowModel;
    const selectedRows = api.getSelectedRows() as BlotterRowModel[];

    const lastClickedIsSelected = !!selectedRows.find(
      ({ uuid }) => lastClickedNodeData.uuid === uuid,
    );

    if (lastClickedIsSelected) {
      return selectedRows;
    }

    api.deselectAll();
    api.setNodesSelected({ nodes: [lastClickedNode], newValue: true });

    return [lastClickedNodeData];
  }

  const groupNodes = params?.node?.allLeafChildren ?? [];
  api.deselectAll();

  return groupNodes?.map(node => {
    api.setNodesSelected({ nodes: [node], newValue: true });
    const nodeData = node.data as BlotterRowModel;
    return nodeData;
  });
}
