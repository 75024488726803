import { type DebounceSettings, type DebouncedFunc, debounce } from 'lodash';
import { type DependencyList, useCallback } from 'react';

export function useDebounce<F extends (...args: any[]) => any>(
  callback: F,
  deps: DependencyList = [],
  options: DebounceSettings & { wait?: number } = { leading: true, trailing: false },
): DebouncedFunc<F> {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    debounce(callback, options.wait, { leading: true, trailing: false, ...options }),
    deps,
  );
}
