import type { ReactNode } from 'react';
import styles from './ShortcutsTable.module.scss';

export interface ShortcutTableProps {
  children: ReactNode;
  'data-e2e'?: string;
}

export const ShortcutsTable = (props: ShortcutTableProps) => (
  <div
    className={`${styles['shortcuts-table']} shortcuts-table btn-group btn-group-toggle`}
    data-e2e={props['data-e2e']}
  >
    {props.children}
  </div>
);
