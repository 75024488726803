import { type Selectors, selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';

import { NumericInput } from '@/neos/components/share/numericInput';
import { useSelector } from 'react-redux';
import { isProductWithStrike } from '../../../../../../neos/business/neosModel';
import type { LegDescriptionComponentProps } from './AveragePrice';

export function getProductStrike(state: AppState, legId: string, selectors: Selectors) {
  const { productId } = selectors.getLegData(state, legId);
  const product = selectors.getProduct(state, productId);
  return isProductWithStrike(product)
    ? { isVisible: true, value: product.strike, unit: product.strikeUnit }
    : { isVisible: false };
}

export function Strike({ legId }: LegDescriptionComponentProps) {
  const strike = useSelector((state: AppState) => getProductStrike(state, legId, selectors));
  if (!strike.isVisible) {
    return null;
  }
  return (
    <div>
      <label className="mb-0 me-3">Strike</label>
      <NumericInput data-e2e="strike" value={strike.value} unit={strike.unit} readOnly />
    </div>
  );
}
