import type { StatusColor } from '@/neos/business/neosOnyxModel';
import { cn } from '@/util/classNames';
import { type ComponentType, type FC, createRef } from 'react';
import styles from './withUnit.module.scss';

interface Props {
  value?: number;
  disabled?: boolean;
  ['data-e2e']?: string;
}

interface WithUnitProps {
  unit?: string;
  readOnly?: boolean;
  areUnitsDifferent?: boolean;
  onUnitClick?: () => void;
  borderColor?: StatusColor;
}

export function withUnit<P extends Props>(
  CustomComponent: ComponentType<P>,
): ComponentType<P & WithUnitProps> {
  const ComponentWithUnit: FC<P & WithUnitProps> = (props: P & WithUnitProps) => {
    const {
      value,
      unit,
      onUnitClick,
      'data-e2e': dataE2e,
      areUnitsDifferent,
      borderColor,
      ...restProps
    } = props;

    const ref = createRef<HTMLButtonElement>();
    const fieldIsDisabled = props.disabled;
    const unitIsClickable = !props.disabled && !props.readOnly && !!onUnitClick;
    const unitIsReadOnlyButFieldIsChangeable =
      !unitIsClickable && !props.readOnly && !fieldIsDisabled;

    return (
      <div
        className={
          borderColor
            ? `${styles['with-unit']} ${styles['coloredBorder']} input-border-${borderColor}`
            : styles['with-unit']
        }
      >
        {unit && (
          <button
            data-e2e={dataE2e ? `${dataE2e}-unit` : undefined}
            data-testid="unit-button"
            className={`${cn('btn btn-sm', {
              'btn-discreet-primary': unitIsClickable,
              'btn-flat-primary': props.readOnly,
              'border-bottom': props.readOnly && !borderColor,
              'btn-discreet': fieldIsDisabled,
              'btn-light': unitIsReadOnlyButFieldIsChangeable,
              'btn-outline-warning': areUnitsDifferent,
            })}`}
            ref={ref}
            onClick={() => {
              if (onUnitClick && unitIsClickable) {
                onUnitClick();
              }
              if (ref.current) {
                ref.current.blur();
              }
            }}
            disabled={!unitIsClickable}
          >
            {unit}
          </button>
        )}
        <CustomComponent data-e2e={dataE2e} {...(restProps as P)} value={value} />
      </div>
    );
  };
  return ComponentWithUnit;
}
