import type { Thunk } from '@/bootstrap/thunks';

export function createRfqHideCompositionLegsModalThunk(rfqId: string, strategyId: string): Thunk {
  return function rfqHideCompositionLegsModalThunk(
    dispatch,
    getState,
    {
      actionCreators: {
        neos: { strategyDataCrudActions, createDefaultCompositionLegsRequestedAction },
      },
      selectors,
    },
  ) {
    const state = getState();
    const compositionLegsNumber = selectors.getStrategyCompositionLegsData(
      state,
      strategyId,
      selectors,
    ).length;

    dispatch(
      strategyDataCrudActions.update(strategyId, {
        top: compositionLegsNumber,
      }),
      createDefaultCompositionLegsRequestedAction(rfqId, strategyId),
    );
  };
}
