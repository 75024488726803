/**
 * Format the input columns for the gridTemplateColumns depending on the displayed condition
 * @param {([isDisplayed: boolean, columnSize: string] | string)[]} columns - Array of css sizes or array of [isDisplayed, columnSize] tuple.
 * @returns the joined columns of the given columns if they are displayed
 * @example
 * // returns '10px 20px 30px 40px'
 * formatGridTemplateColumns([
    '10px 20px',
    [true, '30px'],
    '40px',
    [false, '50px'],
  ]);
 */
export function formatGridTemplateColumns(
  columns: ([isDisplayed: boolean, columnSize: string] | string)[],
): string {
  return columns
    .map(column => {
      if (typeof column === 'string') {
        return column;
      }
      const [isColumnDisplayed, columnSize] = column;
      return isColumnDisplayed ? columnSize : '';
    })
    .filter(doesColumnExist => doesColumnExist)
    .join(' ');
}
