import { createChangeOrderUpdateAveragePriceThunk } from './changeOrderUpdateAveragePriceThunk';
import { createChangeOrderUpdateExecSizeThunk } from './changeOrderUpdateExecSizeThunk';
import { createChangeListedOrdersMatchingThunk } from './createChangeListedOrdersMatchingThunk';
import { createIntegrateOrderExecutionPricesDifferenceNotificationThunk } from './integrateOrderExecutionPricesDifferenceNotificationThunk';
import { createIntegrateOrderNotificationThunk } from './integrateOrderNotificationThunk';

export const orderThunks = {
  createIntegrateOrderNotificationThunk,
  createChangeOrderUpdateAveragePriceThunk,
  createChangeOrderUpdateExecSizeThunk,
  createIntegrateOrderExecutionPricesDifferenceNotificationThunk,
  createChangeListedOrdersMatchingThunk,
};
