import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { IATypeValuesType } from '@/neos/business/neosModel';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';
import { useSelector } from 'react-redux';
import { InvalidTooltip } from '../../../InvalidTooltip';

export function IACurrency({
  showError,
  independantAmountUnit,
  independantAmountType,
  iaTobeBookedMode = false,
  uuid,
  onIACcyChanged,
  dataE2e,
}: {
  uuid: string | number;
  independantAmountValue?: number;
  showError?: boolean;
  independantAmountUnit: string | undefined;
  independantAmountType: IATypeValuesType | undefined;
  iaTobeBookedMode?: boolean;
  onIACcyChanged: any;
  dataE2e: string;
}) {
  const currencies = useSelector((state: AppState) =>
    selectors
      .getCurrencyList(state.referenceData)
      .map(currency => ({ value: currency.refId, label: currency.refId })),
  );
  currencies.unshift({ value: '', label: '' });

  if (independantAmountType !== 'CCY' && !iaTobeBookedMode) {
    return null;
  }
  return (
    <InvalidTooltip show={showError}>
      <NeosSelect
        className={`${showError ? ' field-error errorable-bloc-select-override ' : ''} fit-content`}
        data-e2e={dataE2e}
        value={independantAmountUnit}
        onChange={selected => onIACcyChanged(uuid, selected, independantAmountType)}
        options={currencies}
      />
    </InvalidTooltip>
  );
}
