import {
  getAddBrokerEpic,
  getDeleteBrokerEpic,
  getGetCurrentUserBrokersEpic,
  getSearchBrokersEpic,
} from '@/neos/business/ui/userPreferences/brokers/brokersPreferencesEpics';
import {
  getAddCounterpartiesEpic,
  getDeleteCounterpartiesEpic,
  getGetCurrentUserCounterpartsEpic,
  getSearchCounterpartiesEpic,
} from '@/neos/business/ui/userPreferences/counterparties/counterpartPreferencesEpics';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { combineEpics } from 'redux-observable';
import { getPreconfirmationPreferenceAddGroupEpic } from './prefonfirmationPreferences/epics/preconfirmationPreferencesAddGroupEpic';
import { getPreconfirmationPreferenceDeleteGroupEpic } from './prefonfirmationPreferences/epics/preconfirmationPreferencesDeleteGroupEpic';
import { getPreconfirmationPreferenceLoadGroupsEpic } from './prefonfirmationPreferences/epics/preconfirmationPreferencesLoadGroupsEpic';

export function getUiRootEpic(http: SgmeHttp) {
  return combineEpics(
    getPreconfirmationPreferenceAddGroupEpic(http),
    getPreconfirmationPreferenceDeleteGroupEpic(http),
    getPreconfirmationPreferenceLoadGroupsEpic(http),
    getGetCurrentUserCounterpartsEpic(http),
    getSearchCounterpartiesEpic(http),
    getAddCounterpartiesEpic(http),
    getDeleteCounterpartiesEpic(http),
    getGetCurrentUserBrokersEpic(http),
    getSearchBrokersEpic(http),
    getAddBrokerEpic(http),
    getDeleteBrokerEpic(http),
  );
}
