import { crudDomain } from '@/util/crudUtils';
import type { FieldChange } from '../changesNotificationModel';
import {
  type QuoteNotificationChangesKey,
  getQuoteNotificationChangesId,
  getQuoteNotificationChangesKey,
} from '../quoteNotificationChanges/quoteNotificationChangesDomain';

export type TraderAskNotificationChangesKey = QuoteNotificationChangesKey;

const getTraderAskNotificationChangesId = getQuoteNotificationChangesId;

const getTraderAskNotificationChangesKey = getQuoteNotificationChangesKey;

export const traderAskNotificationChangesDomain = crudDomain<
  FieldChange,
  TraderAskNotificationChangesKey
>('traderAskNotificationChanges', {
  keyToString: getTraderAskNotificationChangesId,
  stringToKey: getTraderAskNotificationChangesKey,
});
