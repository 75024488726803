export interface TradeDateOwnProps {
  rfqId: string;
}

export interface TradeDateMapStateToProps {
  tradeDate: string | undefined;
}

export type TradeDateProps = TradeDateOwnProps & TradeDateMapStateToProps;

export const TradeDateStyled = ({ tradeDate }: TradeDateProps) => (
  <div className={`d-flex flex-nowrap align-items-center`}>
    <label className="fw-bold me-2">Trade Date</label>
    <span className="text-nowrap">{tradeDate}</span>
  </div>
);
