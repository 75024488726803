import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { isElsProduct } from '@/neos/business/rfq/strategy/leg/product/productModel';
import { AsianOptionsFeatures } from '@/neos/components/rfq/strategies/strategy/features/asianOptionsFeatures/AsianOptionsFeatures';
import { Description } from '@/neos/components/rfq/strategies/strategy/features/description/Description.container';
import { ClsResetFrequency } from '@/neos/components/rfq/strategies/strategy/features/cls/ClsResetFrequency.tsx';
import { ElsBasket } from '@/neos/components/rfq/strategies/strategy/features/elsBasket/ElsBasket';
import { ElsClsObsoleteScheduleWarning } from '@/neos/components/rfq/strategies/strategy/features/elsClsSchedule/ElsClsObsoleteScheduleWarning';
import { ClsSchedule } from '@/neos/components/rfq/strategies/strategy/features/elsClsSchedule/clsSchedule/ClsSchedule';
import { ElsSchedule } from '@/neos/components/rfq/strategies/strategy/features/elsClsSchedule/elsSchedule/ElsSchedule';
import { If } from '@/neos/components/share/if/if';
import { findIndex, sum } from 'lodash';
import type { ColumnGridDefinition } from '../../../getDisplayedColumns';
import { Barriers } from '../barriers';
import { CapFloor } from '../capFloor';
import { Cls } from '../cls';
import { ElsFeatures } from '../elsFeatures/ElsFeatures';
import { EndOfObservation } from '../endOfObservation/EndOfObservation.container';
import { ForexType } from '../forexType';
import { ForwardStart } from '../forwardStart';
import { UpDown } from '../upDown';
import styles from './FeaturesList.module.scss';

export interface FeaturesListOwnProps {
  rfqId: string;
  strategyId: string;
  columnGridDefinition: ColumnGridDefinition[];

  onFocus?: () => void;
}

export function FeaturesList(props: FeaturesListOwnProps) {
  const { rfqId, strategyId, onFocus } = props;
  const isScheduleUiToggleEnabled = useAppSelector(state =>
    selectors.isScheduleUiToggleEnabled(state, strategyId, selectors),
  );

  const product = useAppSelector(state =>
    selectors.getRfqMasterProduct(state, props.rfqId, selectors),
  );

  const isEls = isElsProduct(product);

  const indexOfMaturity = findIndex(props.columnGridDefinition, c => c.id === 'maturity');
  const columnsAfterMaturity = props.columnGridDefinition.slice(indexOfMaturity + 1);
  const widthsAfterForwardStart = columnsAfterMaturity.map(({ gridTemplateColumns }) =>
    gridTemplateToFixed(gridTemplateColumns),
  );
  const widthAfterForwardStart = Math.max(sum(widthsAfterForwardStart) - 48, 0);
  const gridTemplateColumnsAfterForwardDate = widthAfterForwardStart
    ? `${widthAfterForwardStart}px`
    : undefined;

  return (
    <>
      <Description rfqId={rfqId} strategyId={strategyId} />
      <div className={`${styles['left-features']}`} onFocus={onFocus} onClick={onFocus}>
        <UpDown rfqId={rfqId} strategyId={strategyId} />
        <EndOfObservation rfqId={rfqId} strategyId={strategyId} />
        <ForwardStart rfqId={rfqId} strategyId={strategyId} />
        <div
          className={`${styles['after-forward-start']}`}
          style={{
            gridTemplateColumns: gridTemplateColumnsAfterForwardDate,
          }}
        />
        <Barriers rfqId={rfqId} strategyId={strategyId} />
      </div>
      <div className={`${styles['features-list-container']}`} onFocus={onFocus} onClick={onFocus}>
        <If condition={!isScheduleUiToggleEnabled}>
          <ElsClsObsoleteScheduleWarning rfqId={rfqId} strategyId={strategyId} />
        </If>

        <CapFloor strategyId={strategyId} />
        <ClsResetFrequency rfqId={rfqId} strategyId={strategyId} />
        <If condition={!isEls}>
          <Cls rfqId={rfqId} strategyId={strategyId} />
          <ForexType rfqId={rfqId} strategyId={strategyId} />
        </If>
      </div>
      <div></div>
      <AsianOptionsFeatures strategyId={strategyId} rfqId={rfqId} />
      <ElsBasket strategyId={strategyId} rfqId={rfqId} />
      <ElsSchedule strategyId={strategyId} rfqId={rfqId} />
      <ClsSchedule strategyId={strategyId} rfqId={rfqId} />
      <ElsFeatures rfqId={rfqId} strategyId={strategyId} />
    </>
  );
}

function gridTemplateToFixed(gridTemplate: string): number {
  const match = gridTemplate.match(/(\d{1,5})px/);
  if (match) {
    return parseInt(match[1]);
  }
  return 25;
}
