import type { Thunk } from '@/bootstrap/thunks';
import type { OnyxRfq } from '@/neos/business/rfq/rfqOnyxModel';
import type { NeosBlotterRfq } from '../blotterModel';

export function createIntegrateReceivedBlotterRfqsThunk(rfqs: OnyxRfq[]): Thunk {
  return function integrateReceivedBlotterRfqsThunk(
    dispatch,
    _,
    {
      actionCreators: {
        neos: { createBlotterRfqsReceivedAction, createRequestAllBlotterPreconfAction },
      },
    },
  ) {
    const neosBlotterRfqs: NeosBlotterRfq[] = rfqs.map(rfq => ({
      ...rfq,
      externalMailStatus: 'NOT_SENT',
      internalMailStatus: 'NOT_SENT',
    }));

    dispatch(createBlotterRfqsReceivedAction(neosBlotterRfqs));
    dispatch(createRequestAllBlotterPreconfAction());
  };
}
