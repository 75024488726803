import { IATypeLabels, IATypeValues, type IATypeValuesType } from '@/neos/business/neosModel';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';

export function IAType({
  independantAmountType,
  uuid,
  onIATypeChanged,
  dataE2e,
}: {
  uuid: string | number;
  independantAmountType: IATypeValuesType | undefined;
  onIATypeChanged: any;
  dataE2e: string;
}) {
  return (
    <NeosSelect
      data-e2e={dataE2e}
      value={independantAmountType}
      onChange={selected => onIATypeChanged(uuid, selected)}
      addEmptyOption
      options={IATypeValues.map(type => ({
        value: type,
        label: IATypeLabels[type],
      }))}
    />
  );
}
