import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { isValidSecondaryWidgetStatus } from '@/neos/business/secondaryWidget/isValidSecondaryWidgetStatus';

export type RfqSecondaryWidgetToggleModel = {
  isEnabled: boolean;
  isRfqSecondaryWidgetToggleOn: boolean;
  isSecondary: boolean;
} | null;

export function getRfqSecondaryWidgetToggleModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): RfqSecondaryWidgetToggleModel {
  const { status } = selectors.getRfqData(state, rfqId);
  const legIds = selectors.getRfqLegIds(state, rfqId, selectors);
  const hasOtcLeg = legIds.some(legId => selectors.isOtcLeg(state, legId, selectors));

  const isValidStatus = isValidSecondaryWidgetStatus(status);
  const areSecondaryFeatureTogglesEnabled =
    selectors.areEveryRfqProductsSecondaryFeatureTogglesEnabled(state, rfqId, selectors);

  const isDisplayed = hasOtcLeg && areSecondaryFeatureTogglesEnabled && isValidStatus;

  if (!isDisplayed) {
    return null;
  }

  const rfqLegIdsByStrategyId = selectors.getRfqLegIdsByStrategyIds(state, rfqId, selectors);

  const isEnabled = rfqLegIdsByStrategyId.some(({ strategyId, legIds }) => {
    const hasACompositionLeg = selectors.hasACompositionLeg(state, strategyId, selectors);
    const hasPrimaryAndOtcLeg = legIds.some(legId => {
      const eventType = selectors.getLegData(state, legId).eventType;
      const isLegOtc = selectors.isOtcLeg(state, legId, selectors);

      return isLegOtc && (eventType === 'CREATE' || eventType === 'STEPIN');
    });
    return !hasACompositionLeg && hasPrimaryAndOtcLeg;
  });

  const isSecondary = selectors.isSecondaryRfq(state, rfqId);

  return {
    isEnabled,
    isRfqSecondaryWidgetToggleOn: selectors.isRfqSecondaryWidgetToggleOn(state.ui, rfqId),
    isSecondary,
  };
}
