import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { concat, fromEvent, interval, merge, timer } from 'rxjs';
import { catchError, concatMap, filter, mapTo, throttleTime } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

const pingerUrl = 'api/neos/ping';

const warmupDelay = 10_000;
const firstAfterWarmupDelay = 5_000;
const throttle = 20_000;
const pingInterval = 60_000;

export function startPinger(http: SgmeHttp) {
  const ping = (shouldNotLog = false) =>
    http
      .head({
        url: pingerUrl,
        headers: { 'x-correlation-id': uuidv4() },
        shouldNotLog,
      })
      .pipe(catchError(() => []));

  const warmup$ = timer(warmupDelay).pipe(mapTo(true));
  const first$ = timer(firstAfterWarmupDelay).pipe(mapTo(false));
  const focus$ = fromEvent(window, 'focus').pipe(mapTo(false));
  const timer$ = interval(pingInterval).pipe(mapTo(false));

  const triggers$ = concat(
    warmup$,
    first$,
    merge(focus$, timer$).pipe(
      filter(() => document.hasFocus() && navigator.onLine),
      throttleTime(throttle),
    ),
  ).pipe(concatMap(ping));

  triggers$.subscribe();
}
