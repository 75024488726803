import type { Thunk } from '@/bootstrap/thunks';
import type { ActionContainer } from '@/util/actionContainer';
import { DefaultingScope, type HedgeKey, RfqRecomputeScope } from '../models';
import { DefaultingOptions } from '../rfqActions';

export function createRfqUpdateDeltaValueThunk(key: HedgeKey, delta: number | undefined): Thunk {
  return function rfqUpdateDeltaValueThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: {
          hedgeCrudActions,
          strategyDataCrudActions,
          createDefaultRfqRequestedAction,
          createRecomputeRfqRequestedAction,
        },
      },
    },
  ) {
    const { getHedges, getRfqData } = selectors;
    const appState = getState();

    const { underlyingId, rfqId, deltaType } = key;
    const { strategyIds } = getRfqData(appState, rfqId);
    const hedges = getHedges(appState, { rfqId, underlyingId });
    const isDeltaMixedMode = selectors.isDeltaMixed(appState, rfqId, underlyingId, selectors);

    const actions: ActionContainer[] = [
      hedgeCrudActions.patchOrInsert(key, {
        underlyingId,
        delta,
        deltaUnit: '%',
        deltaType,
        deltaSummary: undefined,
      }),
    ];
    const recomputeAction = createRecomputeRfqRequestedAction(
      rfqId,
      RfqRecomputeScope.CHANGE_DELTA_RFQ,
    );
    if (!isDeltaMixedMode || hedges.some(h => h.deltaType === deltaType)) {
      actions.push(recomputeAction);
    } else {
      actions.push(
        ...strategyIds.map(sId => strategyDataCrudActions.update(sId, { deltaType: undefined })),
        createDefaultRfqRequestedAction(
          rfqId,
          new DefaultingOptions({
            overrideScopes: [DefaultingScope.TRADE_RECAP],
            enrichScopes: [DefaultingScope.HEDGES],
          }),
          {
            success: { dispatchables: [recomputeAction] },
          },
        ),
      );
    }

    dispatch(actions);
  };
}
