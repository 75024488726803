import type { Thunk } from '@/bootstrap/thunks';
import { dismissNotifications } from 'reapop';
import { createCopyToClipboardThunk } from './copyToClipboardThunk';
import {
  createErrorToasterThunk,
  createInfoToasterThunk,
  createSuccessToasterThunk,
  createWarningToasterThunk,
} from './thunks';

export const toasterThunks = {
  createCopyToClipboardThunk,
  createSuccessToasterThunk,
  createInfoToasterThunk,
  createWarningToasterThunk,
  createErrorToasterThunk,
  createRemoveNotificationsThunk: dismissNotifications as unknown as () => Thunk,
};
