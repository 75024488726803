import { NeosTooltip } from '@/neos/components/share/tooltip/NeosTooltip';
import { Component } from 'react';
import { Tooltip } from 'react-bootstrap';

import type { Counterpart } from '../../../../../neos/business/neosModel';

import styles from './CounterpartPopover.module.scss';

interface PopoverOwnProps {
  counterpart: Counterpart | undefined | null;
  children: any;
}

export class CounterpartPopover extends Component<PopoverOwnProps> {
  constructor(props: PopoverOwnProps) {
    super(props);
  }

  public render() {
    const { counterpart } = this.props;
    return counterpart ? (
      <NeosTooltip
        placement="top"
        overlay={
          <Tooltip className={styles['counterpart-popover']} id="popover-trigger-hover-top">
            <table>
              <tbody>
                <tr>
                  <td>Bdr Id:</td>
                  <td>{counterpart.id}</td>
                </tr>
                <tr>
                  <td>Mnemo BDR:</td>
                  <td>{counterpart.mnemo}</td>
                </tr>
                <tr>
                  <td>Eliot Code:</td>
                  <td>{counterpart.eliotCode}</td>
                </tr>
              </tbody>
            </table>
          </Tooltip>
        }
      >
        <span>{this.props.children}</span>
      </NeosTooltip>
    ) : (
      this.props.children
    );
  }
}
