import { crudDomain } from '@/util/crudUtils';
import { getDomainId, getDomainKey } from '../../sharedDomainUtils/sharedDomain';
import type { Hedge, HedgeKey } from './hedgeModel';

const getHedgeId = getDomainId;
const getHedgeKey = getDomainKey;

export const hedgeDomain = crudDomain<Hedge, HedgeKey>('hedge', {
  keyToString: getHedgeId,
  stringToKey: getHedgeKey,
});
