import type { Execution } from './executionModel';
import type { OnyxExecution } from './executionOnyxModel';

export const fromExecutionMappers = {
  mapFromOnyxExecutions,
};

export const toExecutionMappers = {
  mapToOnyxExecutions,
};

function mapFromOnyxExecutions(onyxExecutions: OnyxExecution[]): Execution[] {
  return [...onyxExecutions];
}

function mapToOnyxExecutions(executions: Execution[]): OnyxExecution[] {
  return [...executions];
}
