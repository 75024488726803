import { services } from '@/bootstrap/services';
import type { OnyxProductFeaturesFields } from '../../../../neosOnyxModel';
import type { DividendFeature, OnyxDividendFeature } from '../../strategyOnyxModel';

import { hasDiscriminator } from '@/neos/business/rfq/strategy/feature/hasDiscriminator';
import { mapToOnyxLegPeriod } from '@/neos/business/rfq/strategy/leg/product/mappers/productMapper.ts';

export function mapFromOnyxFeatureOrExtraFeaturesDividend(
  { extraFeatures }: OnyxProductFeaturesFields,
  strategyId: string,
): DividendFeature | undefined {
  const dividendFeature: OnyxDividendFeature | undefined = extraFeatures?.find(
    hasDiscriminator<OnyxDividendFeature>('DIVIDEND_COMPONENT'),
  );

  if (dividendFeature) {
    return {
      type: 'DIVIDEND_COMPONENT',
      strategyId,
      dividendPaymentDateType: dividendFeature.dividendPaymentDateType ?? undefined,
      dividendSchedulePeriods:
        dividendFeature.dividendSchedulePeriods?.map(period => ({
          uuid: services.idCreator.createId(),
          dates: period,
        })) ?? undefined,
      dividendType: dividendFeature.dividendType ?? undefined,
      dividendPaymentDateOffset: dividendFeature.dividendPaymentDateOffset ?? undefined,
      dividendFxFixingDate: dividendFeature.dividendFxFixingDate ?? undefined,
      dividendPaymentCurrency: dividendFeature.dividendPaymentCurrency ?? undefined,
      dividendRequirement: dividendFeature.dividendRequirement ?? undefined,
      dividendAmount: dividendFeature.dividendAmount ?? undefined,
      dividendPreconfComment: dividendFeature.dividendPreconfComment ?? undefined,
    };
  }

  return undefined;
}

export function mapToOnyxDividendFeature(feature: DividendFeature): OnyxProductFeaturesFields {
  const {
    dividendPaymentDateType,
    dividendSchedulePeriods,
    dividendType,
    dividendPaymentDateOffset,
    dividendFxFixingDate,
    dividendPaymentCurrency,
    dividendRequirement,
    dividendAmount,
    dividendPreconfComment,
  } = feature;

  return {
    extraFeatures: [
      {
        discriminator: 'DIVIDEND_COMPONENT',
        dividendPaymentDateType,
        dividendSchedulePeriods: mapToOnyxLegPeriod(dividendSchedulePeriods),
        dividendType,
        dividendPaymentDateOffset,
        dividendFxFixingDate,
        dividendPaymentCurrency,
        dividendRequirement,
        dividendAmount,
        dividendPreconfComment,
      },
    ],
  };
}
