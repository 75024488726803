import type { AppState } from '@/bootstrap/state';
import { thunks } from '@/bootstrap/thunks';
import {
  type ForexConstat,
  ForexConstatValues,
  type ForexType,
  type ForexTypeFeatureKey,
} from '../../../../../../../business/neosModel';

import { selectors } from '@/bootstrap/selectors';
import { availableForexTypes } from '@/neos/business/rfq/strategy/feature/elsSections/elsSectionsModel';
import { neosThunks } from '@/neos/business/thunks';
import { ElsBlocField } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/ElsBlocField';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';
import { Switch } from '@/neos/components/share/switch/Switch';
import { formatFromCamelToStartCase } from '@/util/format';
import { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ElsFeaturesContext, commonElsBlocClasses } from '../ElsFeatures';
import { useUpdateSectionHeight } from '../useUpdateSectionHeight';

interface ForexTypeBlocProps {
  canBeHidden: boolean;
}

export function ForexTypeBloc({ canBeHidden }: ForexTypeBlocProps) {
  const { strategyId } = useContext(ElsFeaturesContext);
  const dispatch = useDispatch();

  const feature = useSelector((state: AppState) =>
    selectors.getFeature(state.featureState, {
      strategyId,
      type: 'FOREX_TYPE',
    }),
  );

  const forexType = feature?.forexType;
  const initialFxConstat = feature?.initialForexConstat;
  const finalFxConstat = feature?.finalForexConstat;

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(initialFxConstat === finalFxConstat);

  const { ref } = useUpdateSectionHeight(strategyId, 'Forex Constat / Type');

  function onForexTypeChanged(newForexType: ForexType | undefined) {
    const featureKey: ForexTypeFeatureKey = { strategyId, type: 'FOREX_TYPE' };
    dispatch(neosThunks.createChangeFeatureFieldThunk(featureKey, { forexType: newForexType }));
  }

  function onInitialForexConstatChanged(newInitialForexConstat: ForexConstat | undefined) {
    const featureKey: ForexTypeFeatureKey = { strategyId, type: 'FOREX_TYPE' };
    dispatch(
      neosThunks.createChangeFeatureFieldThunk(featureKey, {
        initialForexConstat: newInitialForexConstat,
      }),
    );
    if (isCheckboxChecked) {
      onFinalForexConstatChanged(newInitialForexConstat);
    }
  }

  function onFinalForexConstatChanged(newfinalForexConstat: ForexConstat | undefined) {
    const featureKey: ForexTypeFeatureKey = { strategyId, type: 'FOREX_TYPE' };
    dispatch(
      neosThunks.createChangeFeatureFieldThunk(featureKey, {
        finalForexConstat: newfinalForexConstat,
      }),
    );
  }

  function onCheckboxChanged(isCheckboxChecked: boolean) {
    setIsCheckboxChecked(!isCheckboxChecked);
    if (initialFxConstat) {
      onFinalForexConstatChanged(initialFxConstat);
    }
  }

  function hideSection() {
    dispatch(thunks.neos.createToggleElsSectionThunk('Forex Constat / Type', false));
  }

  return (
    <div className={commonElsBlocClasses} style={{ cursor: 'grab' }}>
      <div ref={ref}>
        <div className="d-flex justify-content-between">
          <h5 className="d-inline-block p-1 m-0 mb-1">Forex Constat / Type</h5>
          {canBeHidden && (
            <button className="btn btn-icon btn-flat-primary" onClick={hideSection}>
              <i className="icon">clear</i>
            </button>
          )}
        </div>

        <div className="d-flex flex-wrap">
          <ElsBlocField
            minWidth={120}
            label={`${!isCheckboxChecked ? 'Initial' : ''} Forex Constat`}
            renderInput={readOnly => (
              <NeosSelect
                readOnly={readOnly}
                value={initialFxConstat}
                onChange={onInitialForexConstatChanged}
                data-e2e="feature-forex-initial-constat"
                addEmptyOption
                options={ForexConstatValues}
              />
            )}
          />

          {!isCheckboxChecked && (
            <ElsBlocField
              minWidth={120}
              label="Final Forex Constat"
              renderInput={readOnly => (
                <NeosSelect
                  readOnly={readOnly}
                  value={finalFxConstat}
                  onChange={onFinalForexConstatChanged}
                  data-e2e="feature-forex-final-constat"
                  addEmptyOption
                  options={ForexConstatValues}
                />
              )}
            />
          )}

          <ElsBlocField
            minWidth={120}
            label="Same Init/Final"
            renderInput={readOnly => (
              <Switch
                disabled={readOnly}
                checked={isCheckboxChecked}
                onChange={() => onCheckboxChanged(isCheckboxChecked)}
              />
            )}
          />

          <ElsBlocField
            minWidth={120}
            label="Forex Type from label"
            renderInput={readOnly => (
              <NeosSelect
                readOnly={readOnly}
                value={forexType}
                onChange={onForexTypeChanged}
                data-e2e="els-section-forex-type"
                addEmptyOption
                options={availableForexTypes.map(forexType => ({
                  value: forexType,
                  label: forexType ? formatFromCamelToStartCase(forexType) : '',
                }))}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}
