import type { Thunk } from '@/bootstrap/thunks';

export function createResetTopAndDispatchThunk(legId: string): Thunk {
  return function resetTopAndDispatchThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { strategyDataCrudActions },
      },
    },
  ) {
    const state = getState();
    const { strategyId } = selectors.getLegData(state, legId);
    const { strategyType, legIds } = selectors.getStrategyData(state, strategyId);
    const isLastLeg = legIds[legIds.length - 1] === legId;
    const { hasACompositionLeg } = selectors.getStrategyDefinition(
      state.referenceData,
      strategyType,
    );
    if (!isLastLeg && hasACompositionLeg) {
      dispatch(strategyDataCrudActions.update(strategyId, { underlyingId: undefined }));
    }
  };
}
