import { Switch } from '@/neos/components/share/switch/Switch';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import type { FC } from 'react';

export interface CrossborderOwnProps {
  rfqId: string;
}

export interface CrossborderPropsFromState {
  isCrossBorder: boolean;
  isReadonlyRfq: boolean;
}

export interface CrossborderDispatchProps {
  onCrossBorderToggled(crossBorder: boolean): void;
}

type CrossborderProps = CrossborderOwnProps & CrossborderPropsFromState & CrossborderDispatchProps;

export const CrossborderComponent: FC<CrossborderProps> = props => (
  <SimpleNeosTooltip message="Cross Border">
    <div className="d-flex flex-column">
      <label className="fw-bold mb-1" htmlFor={`XB-${props.rfqId}`}>
        XB
      </label>
      <Switch
        checked={props.isCrossBorder}
        switchClassName="d-flex"
        disabled={props.isReadonlyRfq}
        onChange={() => props.onCrossBorderToggled(!props.isCrossBorder)}
      />
    </div>
  </SimpleNeosTooltip>
);
