import type { FC } from 'react';
import { Modal } from 'react-bootstrap';

export interface ConfirmModalModel {
  isModalShown: boolean;
  title?: string;
  size?: 'sm' | 'lg';
  onHide: () => void;
  onConfirm: () => void;
  children?: any;
}
export const ConfirmModal: FC<ConfirmModalModel> = ({
  isModalShown,
  onHide,
  onConfirm,
  title,
  size,
  children,
}) => (
  <Modal
    show={isModalShown}
    onHide={onHide}
    onKeyPress={(event: any) => event.charCode === 13 && onHide()}
    size={size}
    animation={false}
  >
    <Modal.Header closeButton>
      <Modal.Title>{title ?? 'Confirm'}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div>{children}</div>
    </Modal.Body>
    <Modal.Footer>
      <button
        data-e2e="confim-modal-refuse"
        className="btn btn-lg btn-flat-secondary"
        onClick={() => onHide()}
      >
        No
      </button>
      <button
        data-e2e="confim-modal-accept"
        type="button"
        className="btn btn-lg btn-primary"
        onClick={() => {
          onHide();
          requestAnimationFrame(onConfirm);
        }}
      >
        Yes
      </button>
    </Modal.Footer>
  </Modal>
);
