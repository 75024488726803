import type { EventType } from '@/neos/business/neosModel';
import { useLongClick } from '@/neos/components/share/longClick/longClickHook';
import { forwardRef, useState } from 'react';
import { ToggleButton } from 'react-bootstrap';

import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import { EventTypeIcon, getButtonData } from '../shared/SecondaryTypeCellsCommon';

type SecondaryWidgetProps = {
  handleClick: (isLongClick: boolean, id: string, eventType: EventType | undefined) => void;
  id: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  eventTypes: (EventType | undefined)[];
  selectedEventType?: EventType | undefined;
};

export const SecondaryWidget = forwardRef<HTMLDivElement, SecondaryWidgetProps>(
  ({ handleClick, id, isLoading, isDisabled, eventTypes, selectedEventType }, ref) => {
    const [isLongClick, setIsLongClick] = useState(false);
    const longClick = useLongClick(
      () => setIsLongClick(false),
      () => setIsLongClick(true),
      500,
      isLoading,
    );

    const { className, ...rest } = longClick;
    return (
      <div className="d-block" {...rest} ref={ref}>
        {eventTypes.map(eventType => {
          const { color, label, iconName, dataE2e } = getButtonData(eventType);
          const keyPrefix = isDisabled ? 'invalid-maturity' : 'valid-maturity';

          return (
            <SimpleNeosTooltip
              key={`${keyPrefix}-widget-${id}-${eventType}`}
              disable={isDisabled}
              id={`${label}-button-tooltip`}
              message={label}
            >
              <ToggleButton
                id={`${keyPrefix}-widget-${id}-${eventType}-toggleButton`}
                value={eventType ?? ''}
                disabled={isDisabled}
                variant={`outline-${color}`}
                className="px-2"
                type="checkbox"
                checked={!!selectedEventType && selectedEventType === eventType}
                onClick={event => {
                  event.preventDefault();
                  handleClick(isLongClick, id, eventType);
                }}
              >
                <EventTypeIcon eventType={eventType} iconName={iconName} dataE2e={dataE2e} />
              </ToggleButton>
            </SimpleNeosTooltip>
          );
        })}
      </div>
    );
  },
);
