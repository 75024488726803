import { getNeosBarrierTypeByProperties } from '@/neos/business/rfq/strategy/feature/barrierMapper';
import { hasDiscriminator } from '@/neos/business/rfq/strategy/feature/hasDiscriminator';
import { DATE_SHORT_LITTERAL_FORMAT, formatDateInTimeZone } from '@/util/date/dateFormatHelper';
import { isDefined } from '@/util/undefinedAndNull/isDefined';
import { identity, isEmpty } from 'lodash';
import type { BlotterModelGetters } from '.';
import type {
  OnyxCap,
  OnyxFeature,
  OnyxFloor,
  OnyxProduct,
  OnyxRfq,
} from '../../../../business/neosOnyxModel';

export function getBlotterExtraFeatures(
  { strategies }: OnyxRfq,
  { twoDimentionalArrayToString }: BlotterModelGetters,
): string {
  const allFeatures = strategies.map(s =>
    stringifyProductFeatures(s.legs.find(l => l.master)?.product),
  );
  return twoDimentionalArrayToString(allFeatures, identity);
}

function stringifyProductFeatures(product: OnyxProduct | undefined) {
  return [
    stringifyResetFrequency(product),
    stringifyInterestRate(product),
    stringifyELS(product),
    stringifyBarriers(product),
    stringifyCapFloor(product),
    stringifyForexType(product),
    stringifyForwarStart(product),
    stringifyUpDown(product),
    stringifyDescription(product),
    stringifyEndOfObservation(product),
  ].filter(s => !isEmpty(s));
}

function stringifyResetFrequency(product: OnyxProduct | undefined): string {
  const resetFrequency = getFeatureOrExtraFeature(product, 'RESET_FREQUENCY', 'resetFrequency');
  return resetFrequency ? `Rst fqy=${resetFrequency}` : '';
}

function stringifyInterestRate(product: OnyxProduct | undefined): string {
  const rateCurve = getFeatureOrExtraFeature(product, 'INTEREST_RATE_INDEX', 'rateCurve');
  const rateTenor = getFeatureOrExtraFeature(product, 'INTEREST_RATE_INDEX', 'rateTenor');

  const rateTenorValue = rateTenor ? `Rt t.=${rateTenor}` : '';
  const rateCurveValue = rateCurve ? `Rt c.=${rateCurve}` : '';
  return [rateTenorValue, rateCurveValue].filter(str => !isEmpty(str)).join(', ');
}

function stringifyELS(product: OnyxProduct | undefined): string {
  const equityBullet = getFeatureOrExtraFeature(product, 'EQUITY_BULLET', 'equityBullet');
  const rateBullet = getFeatureOrExtraFeature(product, 'RATE_BULLET', 'rateBullet');

  const rateOvernight = getFeatureOrExtraFeature(product, 'RATE_OVERNIGHT', 'rateOvernight');
  const execFeesFeature = getExtraFeature(product, 'EXEC_FEES');

  const equityBulletValue = equityBullet ? 'Eqd b.' : '';
  const rateBulletValue = rateBullet ? 'Rt b.' : '';
  const rateOvernightValue = rateOvernight ? 'Rt Ovn' : '';
  const execFeesValue = execFeesFeature?.execFees ? `Ex fees=${execFeesFeature.execFees}` : '';

  const ElsFeatureStringWithSeparator = [
    equityBulletValue,
    rateBulletValue,
    rateOvernightValue,
    execFeesValue,
  ]
    .filter(str => !isEmpty(str))
    .join(', ');

  return ElsFeatureStringWithSeparator;
}

function stringifyBarriers(product: OnyxProduct | undefined): string {
  const barriersFeature = getFeatureOrExtraFeature(product, 'BARRIER_CONTAINER', 'barriers') ?? [];

  const barriers = barriersFeature
    .map(({ activationType, type }) => getNeosBarrierTypeByProperties(type, activationType))
    .filter(isDefined);

  const { length } = barriers;
  const separator = length > 1 ? ', ' : '';
  return barriers.join(separator);
}

function stringifyCapFloor(product: OnyxProduct | undefined): string {
  const capExtraFeature = product?.extraFeatures?.find(hasDiscriminator<OnyxCap>('CAP_CONTAINER'));
  const floorExtraFeature = product?.extraFeatures?.find(
    hasDiscriminator<OnyxFloor>('FLOOR_CONTAINER'),
  );
  const capValue = capExtraFeature?.cap?.price?.value;
  const capUnit = capExtraFeature?.cap?.price?.unit ?? '';
  const floorValue = floorExtraFeature?.floor?.price?.value;
  const floorUnit = floorExtraFeature?.floor?.price?.unit ?? '';
  const cap = capValue && `Cap=${capValue}${capUnit}`;
  const floor = floorValue && `Floor=${floorValue}${floorUnit}`;
  const separator = cap && floor ? ', ' : '';
  return `${cap ? cap : ''}${separator}${floor ? floor : ''}`;
}

function stringifyForexType(product: OnyxProduct | undefined): string {
  const forexType = getFeatureOrExtraFeature(product, 'FOREX_TYPE', 'forexType');
  return forexType ? `Fx Ty=${forexType}` : '';
}

function stringifyForwarStart(product: OnyxProduct | undefined): string {
  const forwardStartDate = getFeatureOrExtraFeature(product, 'FORWARD_START', 'forwardStartDate');

  if (!forwardStartDate) {
    return '';
  }

  const forwardStartDateFormated = formatDateInTimeZone(
    'GMT',
    forwardStartDate,
    DATE_SHORT_LITTERAL_FORMAT,
  );
  return `Fwd=${forwardStartDateFormated}`;
}

function stringifyUpDown(product: OnyxProduct | undefined): string {
  const upFeature = getFeatureOrExtraFeature(product, 'UP_CONTAINER', 'up')?.strike;
  const downFeature = getFeatureOrExtraFeature(product, 'DOWN_CONTAINER', 'down')?.strike;

  const upStrikeValue = upFeature?.value;
  const upStrikeUnit = upFeature?.unit ?? '';
  const downStrikeValue = downFeature?.value;
  const downStrikeUnit = downFeature?.unit ?? '';
  const up = upStrikeValue && `Up=${upStrikeUnit}${upStrikeValue}`;
  const down = downStrikeValue && `Down=${downStrikeUnit}${downStrikeValue}`;
  const separator = up && down ? ', ' : '';

  return `${up ? up : ''}${separator}${down ? down : ''}`;
}

function stringifyDescription(product: OnyxProduct | undefined) {
  return getFeatureOrExtraFeature(product, 'DESCRIPTION', 'description') ?? '';
}

function stringifyEndOfObservation(product: OnyxProduct | undefined) {
  return getFeatureOrExtraFeature(product, 'END_OF_OBSERVATION', 'endOfObservation');
}

function getFeatureOrExtraFeature<
  Discriminator extends OnyxFeature['discriminator'],
  DiscriminatedFeature extends Extract<OnyxFeature, { discriminator: Discriminator }>,
  FeatureName extends Extract<
    keyof OnyxProduct,
    Exclude<keyof DiscriminatedFeature, 'discriminator'>
  >,
>(product: OnyxProduct | undefined, discriminatorToMatch: Discriminator, featureName: FeatureName) {
  const feature = product?.extraFeatures?.find(
    hasDiscriminator<DiscriminatedFeature>(discriminatorToMatch),
  );
  return feature && feature![featureName] ? feature![featureName] : product?.[featureName];
}

function getExtraFeature<
  Discriminator extends OnyxFeature['discriminator'],
  DiscriminatedFeature extends Extract<OnyxFeature, { discriminator: Discriminator }>,
>(
  product: OnyxProduct | undefined,
  discriminatorToMatch: Discriminator,
): DiscriminatedFeature | undefined {
  return product?.extraFeatures?.find(hasDiscriminator<DiscriminatedFeature>(discriminatorToMatch));
}
