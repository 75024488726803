import { upperFirst } from 'lodash';
import type { User } from '../../../../neos/business/neosModel';

export interface DisplayUser {
  id: string;
  fullName: string;
}

export function toDisplayUser({ id, lastname, firstname }: User): DisplayUser {
  return {
    id,
    fullName: `${lastname.toUpperCase()} ${firstname}`,
  };
}

export function userIdToFullName(userId: string): string {
  const splitted = userId.split('.');
  if (splitted.length === 2) {
    return splitted[1].toLocaleUpperCase() + ' ' + upperFirst(splitted[0]);
  }
  return userId;
}

export function tryGetUserFullName(
  user: Record<string, User>,
  userId: string | undefined,
  defaultValue: string,
): string {
  return userId && user[userId]
    ? toDisplayUser(user[userId]).fullName
    : userId
      ? userIdToFullName(userId)
      : defaultValue;
}
