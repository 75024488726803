import type { Thunk } from '@/bootstrap/thunks';

export function createEnsureAllocExtraCounterpartsLoadedThunk(
  requests: { rfqId: string; counterpartId: number }[],
): Thunk {
  return function ensureAllocExtraCounterpartsLoadedThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { createCounterpartRequestedAction },
      },
    },
  ) {
    const appState = getState();

    requests.forEach(({ rfqId, counterpartId }) => {
      const clients = selectors.getCounterparts(appState, rfqId)?.map(c => c.id) ?? [];
      if (!clients.includes(counterpartId)) {
        dispatch(createCounterpartRequestedAction(rfqId, counterpartId));
      }
    });
  };
}
