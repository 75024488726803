import type { FromOnyxMappers } from '@/neos/business/mappers';
import { convertNullToUndefined } from '@/util/undefinedAndNull/convertNullToUndefined';
import type { DividendFuture } from '../../productModel';
import type { OnyxSingleUnderlyingProduct } from '../../productOnyxModel';

export function mapFromOnyxProductDividendFuture(
  legId: string,
  onyxProduct: OnyxSingleUnderlyingProduct,
  productId: string,
  mappers: FromOnyxMappers,
): DividendFuture {
  return {
    subFamily: 'DIVIDEND_FUTURE',
    pointValue: convertNullToUndefined(onyxProduct.pointValue),
    ...mappers.mapFromOnyxProductCommon(legId, onyxProduct, productId),
    ...mappers.mapFromOnyxFutureNegotiation(onyxProduct.negotiation),
    ...mappers.mapFromSingleDerivativeOnyxProduct(onyxProduct, mappers),
    ...mappers.mapFromOnyxFutureStrikeDate(onyxProduct),
    hasCustomUnderlying: false,
  };
}
