export interface FairPriceButtonOwnProps {
  rfqId: string;
}

export interface FairPriceButtonPropsFromState {
  isEnabled: boolean;
}

export interface FairPriceButtonDispatchProps {
  onFairPricesRequested(): void;
}

export type FairPriceButtonProps = FairPriceButtonOwnProps &
  FairPriceButtonPropsFromState &
  FairPriceButtonDispatchProps;

export const FairPriceButtonComponent = (props: FairPriceButtonProps) => (
  <button
    className="btn btn-primary"
    onClick={() => props.onFairPricesRequested()}
    data-e2e="neos-rfq-fair-price-button"
    disabled={!props.isEnabled}
  >
    Get Fair Prices
  </button>
);
