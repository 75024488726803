import { actionCreators } from '@/bootstrap/actions';
import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { thunks } from '@/bootstrap/thunks';
import { ElsFeaturesContext } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures';
import { ElsBlocField } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/ElsBlocField';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';
import { NeosBlurInput } from '@/neos/components/share/blurComponent/NeosBlurInput';
import { uniqWith } from 'lodash';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { getCurvesList } from '../../interestRate/getCurvesList';
import { getInterestRateCurveModel } from '../../interestRate/getInterestRateCurveModel';

export function ElsCurve() {
  const { rfqId, strategyId, product } = useContext(ElsFeaturesContext);
  const dispatch = useDispatch();

  const { rateCurve, onRateCurveChanged } = useAppSelector(state =>
    getInterestRateCurveModel(
      strategyId,
      rfqId,
      state,
      selectors,
      actionCreators,
      thunks,
      dispatch,
    ),
  );

  const curves = useAppSelector(state =>
    uniqWith(
      getCurvesList(state, selectors, product.swapCurrency),
      (curveA, curveB) => curveA.eliotId === curveB.eliotId,
    ),
  );

  const noRateCurveInCurves = curves.find(curve => curve.eliotId === rateCurve) === undefined;
  const isCurveReadonly = rateCurve !== undefined && noRateCurveInCurves;

  return (
    <ElsBlocField
      label={{ type: 'tooltip', tooltip: 'Rate Curve', content: 'Curve' }}
      renderInput={readOnly =>
        isCurveReadonly ? (
          <NeosBlurInput
            type="text"
            value={rateCurve}
            readOnly
            data-e2e="rate-convention-rate-curve"
          />
        ) : (
          <NeosSelect
            readOnly={readOnly}
            value={rateCurve}
            onChange={onRateCurveChanged}
            options={curves.map(curve => ({ label: curve.rateName, value: curve.eliotId }))}
            addEmptyOption
            data-e2e="rate-convention-rate-curve"
          />
        )
      }
    />
  );
}
