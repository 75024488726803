import type {
  DeltaStrategyScope,
  PriceUnitType,
  RfqStrategyScope,
  StrategyType,
} from '../../../../../neos/business/neosModel';
import type {
  BidAsk,
  BookingStepApplication,
  OrderType,
} from '../../../../../neos/business/neosOnyxModel';
import type { DateWithOffset, SizeData } from '../../models';
import type { ActivityType, ExecutionType } from '../../rfqOnyxModel';

export type DeltaType = 'DELTA_ADJUSTED' | 'DELTA_EXCHANGE' | 'DELTA_EXCHANGE_OTC' | 'RISK';

export const availableDeterminationMethods = [
  'CLOSING_PRICE',
  'EDSP',
  'EXCHANGE_LOOK_ALIKE',
  'HEDGE_EXECUTION',
  'MORNING_CLOSE',
  'NONE',
  'OPENING_PRICE',
  'OTHER',
  'PER_MASTER_CONFIRM',
  'VWA_PPRICE',
] as const;

export type DeterminationMethod = (typeof availableDeterminationMethods)[number];

export interface CommonStrategyData {
  uuid: string;
  rfqId: string;
  activityType?: ActivityType;
  strategyType: StrategyType;
  pricingCurrency?: string;
  weight: number;
  initialWeight: number;
  determinationMethod?: DeterminationMethod;
  initialPriceDetermination?: DeterminationMethod;
  comment?: string;
  mirrorPortfolioId: string | undefined;
  traderGroupName: string | undefined;
  traderGroupId: number | undefined;
  traderId: string | undefined;
  legIds: string[];
  isMasterStrategy: boolean;
  quoteId: string;
  fairPriceId?: string;
  valueDate?: DateWithOffset;
  top: number | undefined;
  underlyingId: string | undefined;
  screenPrice?: BidAsk;
  portfolioId?: string;
  operatingCenterId?: string;
  priceUnitType: PriceUnitType;
  etdDeskCity: string | undefined;
  executor?: string;
  crossPartially: boolean | undefined;
  broker: string | undefined;
  executionType?: ExecutionType | undefined;
  hasBeenLoadedWithEmptyReferenceFields: boolean;
  swapsWireSgTraderAccount: string | undefined;
  swapsWireSgTraderAccountType: SwapsWireSgTraderAccountType | undefined;
  tradingBusiness: unknown;
}

type StrategySizeData = { [k in keyof SizeData]: SizeData[k] | undefined };

export interface RfqStrategyData extends CommonStrategyData, StrategySizeData {
  scope: RfqStrategyScope;
  deltaType?: DeltaType;
}

export interface DeltaStrategyData extends CommonStrategyData {
  scope: DeltaStrategyScope;
  handlingInstruction: string | undefined;
  type: OrderType | undefined;
  timeInForce: string | undefined;
  limitPriceValue: number | undefined;
  limitPriceUnit: string | undefined;
  bookingId: string | undefined;
  bookingApplication: BookingStepApplication | undefined;
}

export type StrategyData = DeltaStrategyData | RfqStrategyData;

export function isRfqStrategyData(strategyData: StrategyData): strategyData is RfqStrategyData {
  return strategyData.scope === 'RFQ';
}

export const getDefaultStrategyData = (
  rfqId: string,
  uuid: string,
  legIds: string[],
): StrategyData => ({
  uuid,
  rfqId,
  legIds,
  strategyType: 'CALL',
  priceUnitType: 'CCY',
  scope: 'RFQ',
  traderGroupName: undefined,
  traderGroupId: undefined,
  traderId: undefined,
  weight: 1,
  initialWeight: 1,
  isMasterStrategy: false,
  quoteId: uuid,
  top: undefined,
  underlyingId: undefined,
  sizeType: undefined,
  etdDeskCity: undefined,
  executor: undefined,
  crossPartially: undefined,
  broker: undefined,
  hasBeenLoadedWithEmptyReferenceFields: false,
  swapsWireSgTraderAccount: undefined,
  swapsWireSgTraderAccountType: undefined,
  mirrorPortfolioId: undefined,
  tradingBusiness: undefined,
});

export const getDefaultTraderStrategyData = (
  rfqId: string,
  uuid: string,
  legIds: string[],
): StrategyData => ({
  ...getDefaultStrategyData(rfqId, uuid, legIds),
  strategyType: 'ELS',
  priceUnitType: 'REF_PERCENT',
});

export type SwapsWireSgTraderAccountType = 'USER' | 'GROUP';
