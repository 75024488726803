import type { Thunk } from '@/bootstrap/thunks';
import {
  type AsianPeriod,
  type AsianPeriodDate,
  isProductWithAsianFields,
} from '@/neos/business/neosModel';
import { compareAsianPeriodDates } from '@/neos/business/rfq/strategy/leg/product/mappers/specificProductMappers/mapFromOnyxPeriod';
import { updateInArray } from '@/util/array/arrayUtils';

export function createUpdateAsianOptionPeriodDateThunk<K extends keyof AsianPeriodDate>(
  productId: string,
  dateIndex: number,
  key: K,
  value: AsianPeriodDate[K],
): Thunk {
  return function updateAsianOptionPeriodDateThunk(
    dispatch,
    getState,
    { actionCreators, selectors },
  ) {
    const state = getState();
    const product = selectors.getProduct(state, productId);

    if (!isProductWithAsianFields(product)) {
      return;
    }

    const oldPeriod: AsianPeriod = product.period ?? {
      dates: [],
      endDate: '',
      frequency: undefined,
      startDate: '',
      includeEndDate: undefined,
    };

    const newDate: AsianPeriodDate = {
      ...oldPeriod.dates[dateIndex],
      [key]: value,
    };

    const newDates = updateInArray(oldPeriod.dates, dateIndex, newDate).sort(
      compareAsianPeriodDates,
    );

    dispatch(
      actionCreators.neos.productCrudActions.update(product.uuid, {
        period: {
          ...oldPeriod,
          dates: newDates,
        },
      }),
    );
  };
}
