import type { FC } from 'react';

import { NeosSelectWithAutocomplete } from '@/neos/components/share/neosSelectWithAutocomplete/NeosSelectWithAutocomplete';
import { NumericInput } from '@/neos/components/share/numericInput';
import {
  type IATypeValuesType,
  type MarkitConfirmationMedia,
  type OtcAllocation,
  type OtcPreAlloc,
  type PaperConfirmationMedia,
  otcAllocForcedReasonOptions,
  otcAllocMediaOptions,
  shouldShowForcedReason,
  otcAllocationsIaCurrencies,
} from '../../../../../../neos/business/neosModel';
import type { DeltaScopeModel, RfqScopeModel, ScopeType } from '../scopeModel';
import type { OtcPreAllocationsModel } from './getModel/getGenericOtcPreAllocationsModel';

import { ElectronicUser } from '@/neos/components/rfq/postNego/allocs/otcAllocations/otcAllocationFields/markitWire/ElectronicUser';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import { formatGridTemplateColumns } from '@/util/format/formatGridTemplateColumns';
import { IACurrency } from '../../allocs/otcAllocations/otcAllocationFields/IndependentAmount/IACurrency';
import { IAType } from '../../allocs/otcAllocations/otcAllocationFields/IndependentAmount/IAType';
import { IAValue } from '../../allocs/otcAllocations/otcAllocationFields/IndependentAmount/IAValue';
import { McaEligibility } from '../../allocs/otcAllocations/otcAllocationFields/McaEligibility';
import styles from './CommonOtcPreAllocations.module.scss';
import { PreAllocIAValueDate } from './PreAllocIAValueDate';

export interface CommonOtcPreAllocationsOwnProps {
  rfqId: string;
}

export interface CommonOtcPreAllocationsStateProps {
  model: OtcPreAllocationsModel;
}

export interface CommonOtcPreAllocationsDispatchProps {
  onCounterpartChanged: (index: number, counterpartId: number | undefined) => void;
  onIAChanged: (index: number, ia: number | undefined) => void;
  onIACcyChanged: (index: number, currency: string | undefined) => void;
  onIATypeChanged: (index: number, iaType: IATypeValuesType | undefined) => void;
  onRatioChanged: (index: number, ratio: number | undefined) => void;
  onPreAllocationRemoved: (index: number) => void;
  onPreAllocationAdded: (
    firstPreAllocCcy: string | undefined,
    masterCcy: string | undefined,
  ) => void;
  onMediaChanged: (index: number, value: OtcAllocation['media']) => void;
  onMarkitWireModeChanged: (
    index: number,
    value?: MarkitConfirmationMedia['markitWireMode'],
  ) => void;
  onMediaUserChanged: (
    index: number,
    value?: MarkitConfirmationMedia['electronicMediaUser'],
  ) => void;
  onForcedReasonChanged: (index: number, value?: PaperConfirmationMedia['forcedReason']) => void;
  onPreAllocAIValueDateChanged: (
    index: number,
    value?: OtcPreAlloc['independantAmountValueDate'],
  ) => void;
}

type OtcPreAllocationsProps = CommonOtcPreAllocationsOwnProps &
  CommonOtcPreAllocationsStateProps &
  CommonOtcPreAllocationsDispatchProps;

export type RfqOtcPreAllocationsProps = OtcPreAllocationsProps & RfqScopeModel;

export type DeltaOtcPreAllocationsProps = OtcPreAllocationsProps & DeltaScopeModel;

type CommonOtcPreAllocationsProps = RfqOtcPreAllocationsProps | DeltaOtcPreAllocationsProps;

const Ratios = ({
  scope,
  model: { allocations, ratiosTooltipInfo },
  onRatioChanged,
}: {
  scope: ScopeType;
  model: OtcPreAllocationsModel;
  onRatioChanged: CommonOtcPreAllocationsDispatchProps['onRatioChanged'];
}) => {
  return (
    <SimpleNeosTooltip
      disable={!ratiosTooltipInfo}
      type={ratiosTooltipInfo?.type}
      id={`Otc-pre-allocations-ratios`}
      message={ratiosTooltipInfo?.message}
    >
      <div
        className={`${
          ratiosTooltipInfo?.ratioStyleName ? styles[ratiosTooltipInfo?.ratioStyleName] : ''
        }`}
      >
        {allocations.map(({ ratio, counterpartId, isRatioInvalid, allocationIndex }) => (
          <NumericInput
            key={`${counterpartId}_${allocationIndex}`}
            value={ratio}
            unit="%"
            onBlur={val => onRatioChanged(allocationIndex, val)}
            disableAccelerators
            onlyPositiveNumbers
            className={isRatioInvalid ? 'errorable-bloc field-error' : undefined}
            data-e2e={`Otc-${scope.toLowerCase()}-prealloc-ratio`}
          />
        ))}
      </div>
    </SimpleNeosTooltip>
  );
};

export const CommonOtcPreAllocationsComponent: FC<CommonOtcPreAllocationsProps> = props => {
  const {
    scope,
    onCounterpartChanged,
    onIAChanged,
    onIACcyChanged,
    onRatioChanged,
    onIATypeChanged,
    onPreAllocationRemoved,
    onPreAllocationAdded,
    onForcedReasonChanged,
    onMarkitWireModeChanged,
    onMediaChanged,
    onMediaUserChanged,
    onPreAllocAIValueDateChanged,
    model,
  } = props;

  const hasMarkitPreAlloc = model.allocations.some(alloc => alloc.media === 'MARKITWIRE');
  const hasPaperPreAlloc = model.allocations.some(
    alloc => alloc.media === 'LONG_FORM' || alloc.media === 'SHORT_FORM',
  );
  const shouldShowForcedReasonColumn = model.allocations.some(shouldShowForcedReason);

  const iaCurrencies = Array.from(otcAllocationsIaCurrencies);
  if (model.masterCcy && !iaCurrencies.includes(model.masterCcy)) {
    iaCurrencies.splice(1, 0, model.masterCcy);
  }

  if (!props.model.isApplicable) {
    return null;
  }

  const gridTemplateColumns = formatGridTemplateColumns([
    '32px minmax(300px, 350px) 80px 90px 170px 110px 120px',
    [hasMarkitPreAlloc, '110px 120px'],
    [hasPaperPreAlloc && shouldShowForcedReasonColumn, '100px'],
    '50px',
  ]);

  return (
    <div className={`${styles['pre-allocations-content']} my-2 overflow-auto`}>
      <div className={`${styles['allocation-row']}`} style={{ gridTemplateColumns }}>
        <section>
          <button
            className="btn btn-icon btn-flat-primary"
            onClick={() =>
              onPreAllocationAdded(model.allocations[0]?.independantAmountUnit, model.masterCcy)
            }
            data-e2e={`add-Otc-${scope.toLowerCase()}-prealloc`}
          >
            <i className="icon icon-md">add</i>
          </button>
          {model.allocations.map(({ counterpartId, allocationIndex }) => (
            <button
              key={`${counterpartId}_${allocationIndex}`}
              className="btn btn-icon btn-flat-primary"
              onClick={() => onPreAllocationRemoved(allocationIndex)}
              data-e2e={`remove-Otc-${scope.toLowerCase()}-prealloc`}
            >
              <i className="icon icon-md">delete_forever</i>
            </button>
          ))}
        </section>
        <section>
          <label className="mb-2 mt-1">Counterparties for OTC strategies</label>
          {model.allocations.map(({ counterpartId, allocationIndex }) => (
            <NeosSelectWithAutocomplete
              key={allocationIndex}
              isLabelBold
              data-e2e="pre-alloc-counterpart"
              isReadOnly={model.availableClients.length <= 1}
              value={counterpartId?.toString()}
              onChange={selected => {
                onCounterpartChanged(
                  allocationIndex,
                  selected?.value ? parseInt(selected?.value, 10) : undefined,
                );
              }}
              addEmptyOption
              options={model.availableClients.map(({ id, name, eliotCode, mnemo }) => ({
                value: id.toString(),
                label: name,
                data: [eliotCode, mnemo],
              }))}
            />
          ))}
        </section>
        <section>
          <label className="mb-2 mt-1">Ratio</label>
          <Ratios scope={scope} model={model} onRatioChanged={onRatioChanged} />
        </section>
        <section>
          <label className="mb-2 mt-1">IA Type</label>
          {model.allocations.map(({ allocationIndex, independantAmountType }) => (
            <IAType
              key={allocationIndex}
              independantAmountType={independantAmountType}
              uuid={allocationIndex}
              onIATypeChanged={onIATypeChanged}
              dataE2e="otc-pre-alloc-ia-type"
            />
          ))}
        </section>
        <section>
          <label className="mb-2 mt-1">IA</label>

          {model.allocations.map(
            ({
              allocationIndex,
              independantAmountValue,
              independantAmountType,
              independantAmountUnit,
            }) => (
              <div className="d-flex" key={allocationIndex}>
                <IACurrency
                  dataE2e={`Otc-${scope.toLowerCase()}-prealloc-ia-ccy`}
                  independantAmountValue={independantAmountValue}
                  independantAmountUnit={independantAmountUnit}
                  independantAmountType={independantAmountType}
                  onIACcyChanged={onIACcyChanged}
                  uuid={allocationIndex}
                />
                <IAValue
                  dataE2e={`Otc-${scope.toLowerCase()}-prealloc-ia`}
                  independantAmountValue={independantAmountValue}
                  independantAmountUnit={independantAmountUnit}
                  onIAChanged={onIAChanged}
                  uiSizeType={model.uiSizeType}
                  uuid={allocationIndex}
                />
              </div>
            ),
          )}
        </section>

        <section>
          <label className="mb-2 mt-1">IA Value date</label>
          {model.allocations.map((alloc, index) => (
            <PreAllocIAValueDate
              key={index}
              preAlloc={alloc}
              rfqId={props.rfqId}
              onPreAllocAIValueDateChanged={date => onPreAllocAIValueDateChanged(index, date)}
            />
          ))}
        </section>

        <section>
          <label className="mb-2 mt-1">Media</label>
          {model.allocations.map((alloc, index) => (
            <NeosSelectWithAutocomplete
              key={index}
              data-e2e="otc-pre-alloc-media"
              value={alloc.media}
              onChange={selected =>
                onMediaChanged(index, selected?.value as OtcAllocation['media'])
              }
              addEmptyOption
              options={mapToOptions(otcAllocMediaOptions).filter(x => x.value !== 'UNKNOWN')}
            />
          ))}
        </section>
        {hasMarkitPreAlloc && (
          <>
            <section>
              <label className="mb-2 mt-1">Mode</label>
              {model.allocations.map((alloc, index) => (
                <NeosSelectWithAutocomplete
                  key={index}
                  data-e2e="otc-pre-alloc-mode"
                  isDisabled={alloc.media !== 'MARKITWIRE'}
                  value={alloc.media === 'MARKITWIRE' ? alloc.markitWireMode : undefined}
                  onChange={selected => {
                    onMarkitWireModeChanged(
                      index,
                      selected?.value as MarkitConfirmationMedia['markitWireMode'],
                    );
                  }}
                  addEmptyOption
                  options={['AUTO', 'MANUAL'].map(option => ({
                    value: option,
                    label: option,
                  }))}
                />
              ))}
            </section>
            <section>
              <label className="mb-2 mt-1">Electronic user</label>
              {model.allocations.map((alloc, index) => (
                <ElectronicUser
                  key={index}
                  data-e2e="otc-pre-alloc-media-user"
                  allocation={alloc}
                  onMediaUserChanged={user => onMediaUserChanged(index, user)}
                />
              ))}
            </section>
          </>
        )}
        {hasPaperPreAlloc && shouldShowForcedReasonColumn && (
          <section>
            <label className="mb-2 mt-1">Forced reason</label>
            {model.allocations.map((alloc, index) => (
              <NeosSelectWithAutocomplete
                key={index}
                data-e2e="otc-pre-alloc-forced-reason"
                isReadOnly={!shouldShowForcedReason(alloc)}
                value={
                  alloc.media === 'LONG_FORM' || alloc.media === 'SHORT_FORM'
                    ? alloc.forcedReason
                    : undefined
                }
                onChange={selected => {
                  onForcedReasonChanged(
                    index,
                    selected?.value as PaperConfirmationMedia['forcedReason'],
                  );
                }}
                addEmptyOption
                options={mapToOptions(otcAllocForcedReasonOptions)}
              />
            ))}
          </section>
        )}

        <section>
          <label className="mb-2 mt-1">MCA</label>
          {model.allocations.map((alloc, index) => (
            <McaEligibility key={index} isMcaEligible={alloc.mcaEligible} />
          ))}
        </section>
      </div>
    </div>
  );
};

const mapToOptions = (map: Record<string, string>) =>
  Object.entries(map).map(option => ({
    value: option[0],
    label: option[1],
  }));
