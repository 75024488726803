import type { Thunk } from '@/bootstrap/thunks';
import type { MarginRules } from '../../../../neos/business/neosModel';
import { LegRecomputeScope } from '../models/recomputeModel';

export function createChangeStrategyMarkupRuleThunk(
  rfqId: string,
  strategyId: string,
  rule: MarginRules | undefined,
): Thunk {
  return function changeLStrategyMarkupRuleThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { quoteCrudActions, createRecomputeRfqRequestedAction },
      },
    },
  ) {
    const state = getState();
    const quoteIds = selectors.getLegQuoteIdsFromStrategy(state, strategyId, selectors);
    const { quoteId: strategyQuoteId } = selectors.getStrategyData(state, strategyId);
    quoteIds.push(strategyQuoteId);

    const quotePatchActions = quoteIds.map(quoteId => {
      return quoteCrudActions.update(quoteId, { extraSalesMarginRule: rule });
    });

    dispatch(
      quotePatchActions,
      createRecomputeRfqRequestedAction(rfqId, LegRecomputeScope.CHANGE_LEG_MARKUP),
    );
  };
}
