import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { services } from '@/bootstrap/services';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { isOptionProduct, isVarSwapProduct, isVolSwapProduct } from '@/neos/business/neosModel';
import { legDataActionCreators } from '@/neos/business/rfq/strategy/leg/legData/legDataActionCreators';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import { useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

export function StepIn({ rfqId }: { rfqId: string }) {
  const dispatch = useDispatch<ExtendedDispatch>();
  const rfqProducts = useAppSelector(state => selectors.getRfqProducts(state, rfqId, selectors));
  const { status } = useAppSelector(state => selectors.getRfqData(state, rfqId));
  const isStatusBeforeBooked = services.orderOf(status).isBefore('BOOKED');

  const areEveryProductsEligible = rfqProducts.every(
    p => isOptionProduct(p) || isVarSwapProduct(p) || isVolSwapProduct(p),
  );
  const legIds = useAppSelector(state => selectors.getRfqLegIds(state, rfqId, selectors));
  const isOtcRfq = useAppSelector(state =>
    legIds.every(legId => selectors.isOtcLeg(state, legId, selectors)),
  );

  const areSecondaryFeatureTogglesEnabled = useAppSelector(state =>
    selectors.areEveryRfqProductsSecondaryFeatureTogglesEnabled(state, rfqId, selectors),
  );

  // Step in are primary rfq, not secondary
  const isSecondaryRfq = useAppSelector(
    state => selectors.getRfqData(state, rfqId).activity === 'SECONDARY',
  );

  const novationStepInSelected = useAppSelector(state =>
    legIds.every(legId => {
      return (
        selectors.isOtcLeg(state, legId, selectors) &&
        selectors.getLegData(state, legId).eventType === 'STEPIN'
      );
    }),
  );

  const [showButtons, setShowButtons] = useState(novationStepInSelected);

  function updateEventType(val: 'PRIMARY' | 'STEPIN') {
    const dispatchables = legIds.map(legId =>
      legDataActionCreators.legDataCrudActions.update(legId, {
        eventType: val !== 'PRIMARY' ? val : undefined,
      }),
    );
    dispatch(dispatchables);
  }

  const isDisplayed =
    areEveryProductsEligible &&
    isOtcRfq &&
    !isSecondaryRfq &&
    (novationStepInSelected || (areSecondaryFeatureTogglesEnabled && isStatusBeforeBooked));

  if (!isDisplayed) {
    return null;
  }

  return (
    <div className="d-flex gap-1">
      <SimpleNeosTooltip id="unwind-button-tooltip" message={'Toggle Secondary Step In'}>
        <button
          className="btn btn-primary btn-icon"
          data-e2e="unwind-button-toggle"
          onClick={() => setShowButtons(value => !value)}
        >
          {showButtons || novationStepInSelected ? (
            <i className="icon">chevron_left</i>
          ) : (
            <i className="icon">chevron_right</i>
          )}
        </button>
      </SimpleNeosTooltip>
      {(showButtons || novationStepInSelected) && (
        <ToggleButtonGroup
          name="legEventOverall"
          value={novationStepInSelected ? 'STEPIN' : 'PRIMARY'}
          onChange={(val: 'PRIMARY' | 'STEPIN') => updateEventType(val)}
        >
          <ToggleButton value="STEPIN" variant="outline-info" id="stepin">
            <SimpleNeosTooltip id="step-in-button-tooltip" message={'Step In'}>
              <span>S</span>
            </SimpleNeosTooltip>
          </ToggleButton>
          <ToggleButton
            value="PRIMARY"
            disabled={!areSecondaryFeatureTogglesEnabled || !isStatusBeforeBooked}
            variant="outline-primary"
            id="primary"
          >
            <SimpleNeosTooltip id="primary-button-tooltip" message={'Primary'}>
              <span>N/A</span>
            </SimpleNeosTooltip>
          </ToggleButton>
        </ToggleButtonGroup>
      )}
    </div>
  );
}
