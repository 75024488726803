import type { FC } from 'react';

export const Footer: FC<{}> = () => (
  <sgwt-mini-footer
    container="container-fluid"
    mode="sg-markets"
    type="micro"
    contact-us-by-help-center="#flow-help-center"
    accessibility-compliance="none"
    accessibility-link="https://shared.sgmarkets.com/accessibility/statement/en.html?redirect_uri=https://eqdflow.sgmarkets.com"
  />
);
