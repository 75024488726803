import { NumericInput } from '@/neos/components/share/numericInput';

import { ErrorHighlight } from '@/neos/components/share/errorHighlight';
import type { RefAdjustedModel } from './getRefAdjustedModel';

import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import styles from './RefAdjusted.module.scss';

export interface RefAdjustedOwnProps {
  rfqId: string;
}

export type RefAdjustedMapStateToProps = RefAdjustedModel;

export interface RefAdjustedDispatchProps {
  onRefAdjustedChanged(underlyingId: string, refAdjusted: number | undefined): void;
}

export type RefAdjustedProps = RefAdjustedOwnProps &
  RefAdjustedMapStateToProps &
  RefAdjustedDispatchProps;

export const RefAdjustedComponent = (props: RefAdjustedProps) => (
  <section className={`${styles['ref-adjusted']} `}>
    {props.refAdjustedModels.some(
      udlreference => udlreference.refAdjustedField.isRefAdjustedDisplayed,
    ) && (
      <section>
        <div>Ref Adjusted</div>
        {props.refAdjustedModels.map(
          ({ underlyingId, refAdjustedField, isReadonly, refAdjustedWarning, deltaId }) =>
            refAdjustedField.isRefAdjustedDisplayed ? (
              !refAdjustedWarning.isWarningDisplayed ? (
                <ErrorHighlight
                  key={underlyingId}
                  errorField={'adjustedFutureLevel'}
                  related={{ rfqId: props.rfqId, deltaId: deltaId ?? '' }}
                >
                  <NumericInput
                    className="errorable-bloc"
                    readOnly={isReadonly}
                    key={underlyingId}
                    value={refAdjustedField.refAdjusted}
                    onBlur={val => props.onRefAdjustedChanged(underlyingId, val)}
                    data-e2e="neos-rfq-reference-ref-adjusted"
                  />
                </ErrorHighlight>
              ) : (
                <SimpleNeosTooltip
                  key={underlyingId}
                  type={'warning'}
                  id={underlyingId}
                  message={refAdjustedWarning.warningText}
                >
                  <NumericInput
                    className={
                      refAdjustedWarning.warningText ? 'warning-bloc field-warning' : undefined
                    }
                    readOnly={isReadonly}
                    key={underlyingId}
                    value={refAdjustedField.refAdjusted}
                    onBlur={val => props.onRefAdjustedChanged(underlyingId, val)}
                    data-e2e="neos-rfq-reference-ref-adjusted"
                  />
                </SimpleNeosTooltip>
              )
            ) : (
              <div key={underlyingId} className={`${styles['empty-ref-adjusted']} `} />
            ),
        )}
      </section>
    )}
  </section>
);
