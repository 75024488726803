import { NumericInput } from '@/neos/components/share/numericInput';

import type { StrategyPtmmmModel } from '@/neos/components/rfq/strategies/strategy/prices/strategyPtmmm/getStrategyPtmmmModel';
import { If } from '@/neos/components/share/if/if';
import styles from './StrategyPtmmm.module.scss';

export interface PtmmmOwnProps {
  rfqId: string;
  strategyId: string;
}

export interface PtmmmDispatchProps {
  onStrategyPtmmmChange: (value: number | undefined) => void;
  onLegPtmmmChange: (legId: string, value: number | undefined) => void;
}

export type PtmmmStateProps = StrategyPtmmmModel;

export type PtmmmProps = PtmmmOwnProps & PtmmmStateProps & PtmmmDispatchProps;

export function StrategyPtmmmStyled(ptmmmProps: PtmmmProps) {
  const {
    legsPtmmms,
    strategyPtmmm,
    displayEmptyFirstLeg,
    onStrategyPtmmmChange,
    onLegPtmmmChange,
    isReadonly,
  } = ptmmmProps;

  return (
    <section>
      <If condition={displayEmptyFirstLeg}>
        <NumericInput readOnly />
      </If>
      {legsPtmmms.map(legsPtmmm => (
        <NumericInput
          readOnly={isReadonly}
          key={legsPtmmm.key}
          value={legsPtmmm.price}
          withMaximumNumberOfFloatingDigits={4}
          onBlur={value => onLegPtmmmChange(legsPtmmm.key, value)}
          data-e2e="neos-leg-ptmmmm"
        />
      ))}
      <NumericInput
        readOnly={isReadonly}
        value={strategyPtmmm.price}
        className={`${styles['strategy-price']}`}
        data-e2e="neos-strategy-ptmmmm"
        withMaximumNumberOfFloatingDigits={4}
        onBlur={value => onStrategyPtmmmChange(value)}
      />
    </section>
  );
}
