import type { AppState } from '@/bootstrap/state';
import type {
  OnyxFeatureToggle,
  OnyxFeatureToggleKey,
  OnyxUserCategory,
  SalesOrAssociatedUser,
} from './userInfoModel';
import type { Selectors } from '@/bootstrap/selectors';
import { getSavedUserPreferences } from '@/neos/business/ui/userPreferences/userPreferencesUiServices';
import {
  isClsProduct,
  isElsBasketProduct,
  isElsProduct,
  isOptionLike,
  isVSwapProduct,
  type Product,
} from '@/neos/business/rfq/strategy/leg/product/productModel';

export const userInfoSelectors = {
  isFeatureToggleEnabled,
  isSecondaryFeatureToggleEnabled,
  getCurrentUser,
  getSesameId,
  getUserName,
  getCategoriesForUser,
  getFilteredNotificationGroupsByPreferences,
  isTrader,
  isUserSalesOrAssociated,
  isUserForbiddenToSaveRfq,
  isProductSecondaryFeatureToggleEnabled,
  areEveryRfqProductsSecondaryFeatureTogglesEnabled,
};

export function isSecondaryFeatureToggleEnabled(state: AppState) {
  return isFeatureToggleEnabled(state, 'neos.secondary.enabled');
}

export function areEveryRfqProductsSecondaryFeatureTogglesEnabled(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): boolean {
  const products = selectors.getRfqProducts(state, rfqId, selectors);
  return products.every(product =>
    selectors.isProductSecondaryFeatureToggleEnabled(state, rfqId, product, selectors),
  );
}

export function isProductSecondaryFeatureToggleEnabled(
  state: AppState,
  rfqId: string,
  product: Product,
  selectors: Selectors,
): boolean {
  function getProductSecondaryFeatureToggle(product: Product): OnyxFeatureToggleKey | null {
    if (isElsProduct(product)) {
      const isRfqInitiatedByTrader = selectors.isRfqInitiatedByTrader(state, rfqId);
      if (isRfqInitiatedByTrader) {
        return null;
      }

      if (isElsBasketProduct(product)) {
        return 'neos.els.basket.secondary';
      }

      return 'neos.secondary.product.els';
    }
    if (isClsProduct(product)) {
      return 'neos.secondary.product.cls';
    }
    if (isOptionLike(product)) {
      return 'neos.secondary.product.options';
    }
    if (isVSwapProduct(product)) {
      return 'neos.secondary.product.varvolswaps';
    }
    return null;
  }

  const productSecondaryFeatureToggle = getProductSecondaryFeatureToggle(product);
  if (productSecondaryFeatureToggle === null) {
    return false;
  }
  return isFeatureToggleEnabled(state, productSecondaryFeatureToggle);
}

export function isFeatureToggleEnabled(state: AppState, featureToggle: OnyxFeatureToggleKey) {
  if (state.userInfo === undefined) {
    return false;
  }

  const found: OnyxFeatureToggle | undefined = state.userInfo.toggleFeatures.find(
    ft => ft.name === featureToggle,
  );
  if (!found) {
    return false;
  }

  return found.enable;
}

export function getCurrentUser(state: AppState) {
  return state.userInfo.currentUser;
}

export function getUserName(state: AppState) {
  const { lastname: lastName, firstname: firstName } = state.userInfo.currentUser;
  return `${lastName} ${firstName}`;
}

export function getSesameId(state: AppState) {
  return getCurrentUser(state).sesameId;
}

function getCategoriesForUser(state: AppState): OnyxUserCategory[] {
  return state.userInfo.currentUser.categories;
}

function isTrader(state: AppState): boolean {
  const currentUserRole = getCategoriesForUser(state);
  return currentUserRole.includes('TRADER');
}

function isUserSalesOrAssociated(state: AppState): boolean {
  const currentUserRole = getCategoriesForUser(state);
  const salesOrAssociated: SalesOrAssociatedUser[] = ['ADMIN', 'SALES', 'SALES_MANAGER'];
  return salesOrAssociated.some(role => currentUserRole.includes(role));
}

function isUserForbiddenToSaveRfq(state: AppState): boolean {
  const currentUserRole = getCategoriesForUser(state);
  return currentUserRole[0] === 'MIDDLE_OP' || currentUserRole[0] === 'MIDDLE_SA';
}

function getFilteredNotificationGroupsByPreferences(
  state: AppState,
  selectors: Selectors,
): number[] {
  const { diffusionGroups } = selectors.getCurrentUser(state);
  const userPreferencesFromLocalStorage = getSavedUserPreferences();

  return diffusionGroups.filter(
    diffusionGroup =>
      !userPreferencesFromLocalStorage?.notificationGroups
        .map(ng => ng.id)
        .includes(diffusionGroup),
  );
}
