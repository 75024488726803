import type { Thunk } from '@/bootstrap/thunks';
import { sumBy } from 'lodash';
import type { OrderKey } from '../orderData/orderDataModel';

export function createChangeOrderUpdateAveragePriceThunk(orderKey: OrderKey): Thunk {
  return function changeOrderUpdateAveragePriceThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: { neos: as },
      thunks: {
        neos: { createRfqUpdateLegQuoteAccordingToAveragePriceThunk },
      },
    },
  ) {
    const state = getState();
    const order = selectors.getOrder(state.orderData, orderKey.orderId);
    if (!order) {
      return;
    }

    const {
      uuid: orderId,
      price: { unit, type },
    } = order;

    const executions = selectors.executionSelectors.selectObjects(state.execution, { orderId });
    const executionQuantitySum = sumBy(executions, e => e.size?.numberOfLots ?? 0);
    const averagePriceValue =
      sumBy(executions, e => (e.lastPrice?.value ?? 0) * (e.size?.numberOfLots ?? 0)) /
      (executionQuantitySum ? executionQuantitySum : 1);
    const legId = order.clientOrderUuid;

    dispatch(
      as.orderCrudActionCreators.update(orderKey, {
        averagePrice: {
          unit: unit!,
          type: type!,
          value: averagePriceValue,
        },
      }),
      createRfqUpdateLegQuoteAccordingToAveragePriceThunk({
        legId,
        averagePriceValue,
        rfqId: order.rfqUuid,
      }),
    );
  };
}
