import type { OnyxRfq } from '@/neos/business/rfq/rfqOnyxModel';
import type { BlotterModelGetters } from '.';

export function getBlotterMasterSize(rfq: OnyxRfq, g: BlotterModelGetters) {
  const masterLeg = g.getMasterLeg(rfq, g);
  const sizeType = masterLeg?.negotiatedSize?.sizeType;
  const numberOfLots = masterLeg?.negotiatedSize?.numberOfLots;
  const masterNotional = masterLeg?.negotiatedSize?.notional?.value;

  return sizeType === 'FIXED_QUANTITY' ? numberOfLots : masterNotional;
}
