import type { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import type { OtcAllocation } from '@/neos/business/neosModel';
import type { DisplayNegotiatedSize } from '@/neos/business/ui/strategy/strategyUiModel';
import { NumericInput } from '@/neos/components/share/numericInput';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import { InvalidTooltip } from '../../InvalidTooltip';
import { thunks } from '@/bootstrap/thunks.ts';
import {
  getOtcAllocSizeModel,
  getPriceWithUnitSizeInAlloc,
} from '@/neos/components/rfq/postNego/allocs/otcAllocations/otcAllocationFields/OtcAllocSizeModel.ts';

type OtcAllocSizeProps = {
  allocationIndex: number;
  allocation: OtcAllocation;
  legId: string;
  strategyId: string;
  uiSizeType: DisplayNegotiatedSize;
  isErrorSize: boolean | undefined;
};

export const OtcAllocSize: FC<OtcAllocSizeProps> = ({
  allocation,
  legId,
  strategyId,
  uiSizeType,
  allocationIndex,
  isErrorSize,
}) => {
  const dispatch = useDispatch();

  const { sizeInfos } = useAppSelector(state =>
    getOtcAllocSizeModel(
      state,
      legId,
      uiSizeType,
      allocationIndex,
      allocation.isCancelled,
      selectors,
    ),
  );
  const priceWithUnitSizeInAlloc = getPriceWithUnitSizeInAlloc(allocation, uiSizeType);

  return (
    <SimpleNeosTooltip
      disable={!sizeInfos.tooltip}
      type={sizeInfos.tooltip?.type}
      id={`alloc-status-tooltip`}
      message={sizeInfos.tooltip?.msg}
    >
      <div>
        <InvalidTooltip show={isErrorSize}>
          <NumericInput
            value={priceWithUnitSizeInAlloc?.value}
            unit={priceWithUnitSizeInAlloc?.unit}
            onBlur={value =>
              dispatch(
                thunks.neos.createUpdateOtcAllocSizeThunk(
                  strategyId,
                  allocation,
                  uiSizeType,
                  value,
                ),
              )
            }
            data-e2e={`otc-alloc-qty`}
            className={`${sizeInfos.className} ${
              isErrorSize ? ' field-error errorable-bloc ' : ''
            }`}
          />
        </InvalidTooltip>
      </div>
    </SimpleNeosTooltip>
  );
};
