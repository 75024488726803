import type { FromOnyxMappers } from '@/neos/business/mappers';
import { convertNullToUndefined } from '@/util/undefinedAndNull/convertNullToUndefined';
import type { Option } from '../../productModel';
import type { OnyxSingleUnderlyingProduct } from '../../productOnyxModel';

export function mapFromOnyxProductOption(
  legId: string,
  onyxProduct: OnyxSingleUnderlyingProduct,
  productId: string,
  mappers: FromOnyxMappers,
): Option {
  const { strike, strikeUnit } = mappers.mapFromOnyxStrike(onyxProduct);
  return {
    subFamily: 'OPTION',
    strike,
    strikeUnit,
    style: convertNullToUndefined(onyxProduct.optionStyle),
    flex: onyxProduct.optionFlexType || undefined,
    type: onyxProduct.optionType || undefined,
    ...mappers.mapFromOnyxProductCommon(legId, onyxProduct, productId),
    ...mappers.mapFromOnyxNegotiation(onyxProduct.negotiation),
    ...mappers.mapFromSingleDerivativeOnyxProduct(onyxProduct, mappers),
    hasCustomUnderlying: false,
    observableType: convertNullToUndefined(onyxProduct.observableType),
  };
}
