import type { PossibleBlotterColumnFields } from './gridColumnDefinition';

export const blotterHeaderNames: Record<PossibleBlotterColumnFields, string> = {
  source: '',
  tradeDate: `Trade Date`,
  salesValo: 'Sales Valo',
  salesCounterpartyName: 'Counterpart',
  statusLabel: 'Status',
  masterQuantity: 'Quantity',
  masterSize: 'Size',
  masterLotSize: 'Lot Size',
  legSizes: 'Leg Sizes',
  underlyingBloombergCodes: 'Leg Udl',
  allStrategyTypes: 'Strat Types',
  negotiationTypes: 'Nego Types',
  legMaturities: 'Leg Maturity',
  legStrikes: 'Leg Strike',
  traderGroups: 'Trader Groups',
  traders: 'Traders',
  rfqBid: 'Bid',
  rfqMid: 'Fair Value',
  rfqAsk: 'Ask',
  totalSalesMarginEur: 'SC (EUR)',
  totalCommissionAmountEur: 'Total Com.',
  crossBorder: 'Cross Border',
  masterRefLevel: 'Ref. Level',
  eurNotionalValue: 'EUR Notional',
  usdNotionalValue: 'USD Notional',
  clientWay: 'Quote Way',
  extraFeatures: 'Additional Information',
  comment: 'Comment',
  quoteRecap: 'Description',
  workflow: 'Workflow',
  bookingInfo: 'Booking',
  creationTime: `Created`,
  updateTime: `Last Updated`,
  isQuotePercent: 'Is Quote Pct',
  uuid: 'Id',
  optionStyle: 'Styles',
  activity: 'Event Type',
  secondaryEventType: 'Secondary Event Type',
  preconfStatus: 'Preconf Status',
  feedbackLevel: 'Feedback level',
  elsType: 'ELS Type',
  observableType: 'Observable Type',
};
