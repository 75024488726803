import type { Thunk } from '@/bootstrap/thunks';
import type { ActionContainer } from '@/util/actionContainer';
import type { CrudInsertAction } from '@/util/crudUtils';
import { flatMap } from 'lodash';
import { buildParameterWithParentIdAsQuoteId } from '../../../../rfq/quotes/quoteActionCreator';
import type { SourceTarget } from '../../../../rfq/thunks/cloneRfqStrategies';
import type { LegData } from '../legModel';
import {
  isClsProduct,
  isElsProduct,
  isListedProduct,
  isVSwapProduct,
  type Product,
} from '../product/productModel';

interface Parameters {
  strategyTargetId: string;
  legSourceTargets: SourceTarget[];
}

export function createCloneLegRfqThunk(parameters: Parameters[]): Thunk {
  return function cloneLegRfqThunk(
    dispatch,
    getState,
    {
      selectors: { getLegData, getProduct, getQuote },
      actionCreators: {
        neos: { productCrudActions, legDataCrudActions, createDefaultQuotesAddedAction },
      },
    },
  ) {
    const appState = getState();

    const actions: ActionContainer[] = flatMap(
      parameters,
      ({ strategyTargetId, legSourceTargets }) => {
        function createQuotesInsertActions() {
          const arrayOfParentIdsAndQuoteIds = legSourceTargets.map(legSourceTarget => {
            const { quoteId } = getLegData(appState, legSourceTarget.sourceId);
            const { unit, salesAskType } = getQuote(appState, quoteId);
            return buildParameterWithParentIdAsQuoteId(
              'Leg',
              legSourceTarget.targetId,
              unit,
              salesAskType,
            );
          });

          return createDefaultQuotesAddedAction(arrayOfParentIdsAndQuoteIds);
        }

        function createInsertLegAndProductActions() {
          return flatMap(legSourceTargets, legSourceTarget => {
            const {
              productId: productSourceId,
              quoteId,
              fairPriceId,
              uuid,
              bookingId,
              brokerInfoTradingDateTime,
              ...legDataToClone
            } = getLegData(appState, legSourceTarget.sourceId);

            const sourceProduct = getProduct(appState, productSourceId);

            const insertedProduct: Product = {
              ...sourceProduct,
              ...(isListedProduct(sourceProduct)
                ? { refId: undefined, sameProductIds: undefined }
                : {}),
              ...(isElsProduct(sourceProduct)
                ? {
                    ratePeriods: undefined,
                    equityPeriods: undefined,
                    effectiveDate: undefined,
                    equityHedge: undefined,
                    stockLoanHedge: undefined,
                    hedgeComment: undefined,
                  }
                : {}),
              ...(isElsProduct(sourceProduct) || isClsProduct(sourceProduct)
                ? { isScheduleObsolete: undefined }
                : {}),
              uuid: legSourceTarget.targetId,
              legId: legSourceTarget.targetId,
            };

            const insertProductAction = productCrudActions.insert(
              legSourceTarget.targetId,
              insertedProduct,
            );

            let insertLegAction: CrudInsertAction<LegData>;
            if (isVSwapProduct(sourceProduct)) {
              insertLegAction = legDataCrudActions.insert(legSourceTarget.targetId, {
                ...legDataToClone,
                notional:
                  legDataToClone.sizeType === 'FIXED_VEGA_NOTIONAL'
                    ? legDataToClone.notional
                    : undefined,
                varUnit:
                  legDataToClone.sizeType === 'FIXED_VAR_UNIT' ? legDataToClone.varUnit : undefined,
                uuid: legSourceTarget.targetId,
                strategyId: strategyTargetId,
                productId: legSourceTarget.targetId,
                quoteId: legSourceTarget.targetId,
              });
            } else {
              insertLegAction = legDataCrudActions.insert(legSourceTarget.targetId, {
                ...legDataToClone,
                uuid: legSourceTarget.targetId,
                strategyId: strategyTargetId,
                productId: legSourceTarget.targetId,
                quoteId: legSourceTarget.targetId,
                ...(isElsProduct(sourceProduct)
                  ? {
                      settlement: {
                        ...legDataToClone.settlement,
                        date: {
                          ...legDataToClone.settlement?.date,
                          value: undefined,
                        },
                      },
                    }
                  : {}),
              });
            }

            return [insertProductAction, insertLegAction];
          });
        }

        return [createQuotesInsertActions(), ...createInsertLegAndProductActions()];
      },
    );

    dispatch(actions);
  };
}
