import { NeosTooltip } from '@/neos/components/share/tooltip/NeosTooltip';
import { Tooltip } from 'react-bootstrap';

import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import type { ProductWarnings } from '@/neos/business/rfq/strategy/strategySelectors';
import styles from './WarningCells.module.scss';

export interface WarningCellsProps {
  rfqId: string;
  strategyId: string;
}

export function WarningCells({ rfqId, strategyId }: WarningCellsProps) {
  const warnings: ProductWarnings = useAppSelector(state =>
    selectors.getStrategyWarnings(state, rfqId, strategyId, selectors),
  );

  return (
    <section>
      {warnings.map(w => (
        <div
          key={w.productId}
          className={`${styles['warning']}`}
          data-e2e={`strategy-grid-warnings-container`}
        >
          {w.warning && (
            <NeosTooltip
              placement="top"
              overlay={
                <Tooltip
                  className="react-bootstrap-warning-tooltip"
                  id={`${w.productId}-strat-grid-warning`}
                >
                  {w.warning}
                </Tooltip>
              }
            >
              <i data-e2e="strategy-grid-warning" className="icon icon-sm text-warning">
                warning
              </i>
            </NeosTooltip>
          )}
        </div>
      ))}
    </section>
  );
}
