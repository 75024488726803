import { serviceWorker } from '@/appServiceWorker';
import { computeClientWay, computeLegWay } from '@/neos/business/services/clientWayComputer';
import { getKnownFieldMatch } from '@/neos/business/services/fieldMatchers';
import { applyMultiplierWithPrecision, applyPrecision } from '@/neos/business/services/rounding';
import { orderOf } from '@/neos/business/services/statusOrder';
import { copyToClipboard } from '@/util/clipboard/copyToClipboard';
import { idCreator } from '@/util/id';
import { logger } from '@/util/logging/logger';
import * as localStorage from './localStorage/localStorage';
import { titleBlinker } from './titleBlinker/titleBlinker';

import { downloadBlob } from '@/util/http/httpUtils';

export const services = {
  idCreator,
  serviceWorker,
  copyToClipboard,
  logger,
  localStorage,
  titleBlinker,
  appStartChannel:
    import.meta.env.MODE === 'test'
      ? (undefined as unknown as BroadcastChannel)
      : new BroadcastChannel(
          'app-start-channel-' + document.location.origin + document.location.pathname,
        ),
  orderOf,
  computeClientWay,
  computeLegWay,
  getKnownFieldMatch,
  applyPrecision,
  applyMultiplierWithPrecision,
  downloadBlob,
};

export type Services = typeof services;
