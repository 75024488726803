import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { neosThunks } from '@/neos/business/thunks';
import { Switch } from '@/neos/components/share/switch/Switch';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import { useDispatch } from 'react-redux';
import { ErrorHighlight } from '../../../../../share/errorHighlight';
import { getNoTaxCollectionCellsModel } from './getNoTaxCollectionCellsModel';

type NoTaxCollectionCellsProps = {
  rfqId: string;
  strategyId: string;
};

export function NoTaxCollectionCells({ rfqId, strategyId }: NoTaxCollectionCellsProps) {
  const dispatch = useDispatch();

  function onNoTaxCollectionChange(newValue: boolean, productId: string) {
    dispatch(neosThunks.createUpdateProductNoTaxCollectionThunk(strategyId, productId, newValue));
  }

  const { noTaxCollectionFields } = useAppSelector(state =>
    getNoTaxCollectionCellsModel(state, rfqId, strategyId, selectors),
  );

  return (
    <section>
      {noTaxCollectionFields.map(({ id, value, isEditable, isDisplayed }) =>
        isDisplayed ? (
          <SimpleNeosTooltip key={id} message="871m eligible & No Tax Collection">
            <span>
              <ErrorHighlight
                errorField={'noTaxCollection'}
                related={{ rfqId, legId: id, strategyId }}
              >
                <Switch
                  disabled={!isEditable}
                  checked={!!value}
                  onChange={() => onNoTaxCollectionChange(!value, id)}
                  e2eId={`no-tax-collection-${id}`}
                />
              </ErrorHighlight>
            </span>
          </SimpleNeosTooltip>
        ) : (
          <div key={id} style={{ height: '32px' }} />
        ),
      )}
    </section>
  );
}
