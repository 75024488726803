import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import {
  type IsMasterLegCellsDispatchProps,
  type IsMasterLegCellsMapStateProps,
  type IsMasterLegCellsOwnProps,
  IsMasterLegCellsStyled,
} from './IsMasterLegCells';
import { getIsMasterLegCellsModel } from './getIsMasterLegCellsModel';

export const IsMasterLegCells = connect(
  mapStateToProps,
  mapDispatchToProps,
)(IsMasterLegCellsStyled);

function mapStateToProps(
  state: AppState,
  { rfqId, strategyId }: IsMasterLegCellsOwnProps,
): IsMasterLegCellsMapStateProps {
  return getIsMasterLegCellsModel(state, strategyId, rfqId, selectors);
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { strategyId }: IsMasterLegCellsOwnProps,
): IsMasterLegCellsDispatchProps {
  return {
    onMasterLegChanged(legId: string) {
      dispatch(neosThunks.createChangeMasterLegThunk(strategyId, legId));
    },
  };
}
