import { http } from './apis';
import { enhanceErrorHandler, sgwtConnect } from '@/util/api/connect/connect';
import { sgwtConnectSetup } from '@/util/api/connect/setupConnect';
import { logger } from '@/util/logging/logger';
import { setUnhandledExceptionHandler } from '@/util/logging/unhandledExceptions';
import { renderReactApp } from './main';
import { listenToAllNotifications } from './notifications';
import signalRService from '../util/notifications/signalRService';
import { thunks } from './thunks';
import { commonActionCreators } from '@/common/business/commonActionCreators';
import { createMiddlewares } from './middlewares';
import type { Middleware } from 'redux';
import { rootReducer } from './reducer';
import { debounce, snakeCase } from 'lodash';
import { batchedSubscribe } from 'redux-batched-subscribe';
import { bootstrapEpicMiddleware } from './middlewares/epics/epicsMiddleware';
import { isCrudAction } from '@/util/crudUtils';
import { type Action, actionCreators } from './actions';
import type { AppState } from './state';
import { configureStore, type DevToolsEnhancerOptions } from '@reduxjs/toolkit'; // const initialStateJson = JSON.parse(decodeURI(atob(storeEncoded.state)));

// const initialStateJson = JSON.parse(decodeURI(atob(storeEncoded.state)));

export function bootstrap(bootstrapOptions?: { isSignalRError: boolean }) {
  const { store, epicMiddleware } = getFlowStore();
  bootstrapEpicMiddleware(http, epicMiddleware);

  if (bootstrapOptions?.isSignalRError) {
    store.dispatch(actionCreators.neos.setIsSignalRConnexionErrorDisplayed());
  }

  setUnhandledExceptionHandler(logger, store);
  enhanceErrorHandler(() => {
    store.dispatch(commonActionCreators.createLogAction('Got empty Authorization header'));
    store.dispatch(commonActionCreators.createSessionExpiredAction());
    logger.scheduleStop();
  }, logger);

  initApp();

  function initApp() {
    renderReactApp(store);
    store.dispatch(thunks.common.createListenToAppStartEventsThunk() as any);
    if (
      (!window.location.search?.length ||
        !window.location.search?.includes('random=') ||
        window.location.origin === 'https://eqdflow.sgmarkets.com') &&
      signalRService.connected
    ) {
      listenToAllNotifications(
        store.getState.bind(store),
        store.dispatch.bind(store),
        thunks,
        signalRService.datastream$,
        signalRService.reconnect$,
      );
    }

    // Setup SGWT Listener and link them to store.
    sgwtConnectSetup.setup(sgwtConnect, store.dispatch);
  }
}

export function getFlowStore() {
  const { middlewares, epicMiddleware } = createMiddlewares();
  const store = neosConfigureStore(middlewares);
  return {
    store,
    epicMiddleware,
  };
}

export function neosConfigureStore(middleWares: Middleware[]) {
  const debounceNotify = debounce(notify => notify());

  return configureStore({
    reducer: rootReducer,
    middleware: middleWares,
    devTools: getDevToolsOptions(),
    enhancers: [batchedSubscribe(debounceNotify)],
  });
  // use in case of debug need with an initial state
  // return configureStore({
  //   reducer: rootReducer,
  //   middleware: middleWares,
  //   devTools: getDevToolsOptions(),
  //   preloadedState:initialStateJson,
  //   enhancers: [batchedSubscribe(debounceNotify)],
  // });
}

function getSanitizeWarningMessage<T>(field: string): T {
  return `THE ${field} VALUES ARE SANITIZED, comment ${field} in the stateSanitizer in bootstrap.ts to see the actual values.` as unknown as T;
}

function getDevToolsOptions(): DevToolsEnhancerOptions {
  return {
    actionSanitizer: (a: Action): any => {
      if (a.type === 'BLOTTER_RFQS_RECEIVED' && a.rfqs?.length) {
        return {
          ...a,
          rfqs: 'THE blotter VALUES ARE SANITIZED, comment the BLOTTER_RFQS_RECEIVED condition in actionSanitizer in bootstrap.ts to see the actual values.',
        };
      }

      if (a.type === 'MARKETS_RECEIVED' && a.markets) {
        return {
          ...a,
          markets:
            'THE markets VALUES ARE SANITIZED, comment the MARKETS_RECEIVED condition in actionSanitizer in bootstrap.ts to see the actual values.',
        };
      }

      if (a.type === 'MARKETS_ELIOT_RECEIVED' && a.markets) {
        return {
          ...a,
          marketsEliot:
            'THE markets eliot VALUES ARE SANITIZED, comment the MARKETS_ELIOT_RECEIVED condition in actionSanitizer in bootstrap.ts to see the actual values.',
        };
      }

      if (a.type === 'STRATEGIES_DEFINITIONS_RECEIVED' && a.strategiesDefinitions) {
        return {
          ...a,
          strategiesDefinitions:
            'THE strategiesDefinitions VALUES ARE SANITIZED, comment the STRATEGIES_DEFINITIONS_RECEIVED condition in actionSanitizer in bootstrap.ts to see the actual values.',
        };
      }

      if (a.type === 'SALES_VALOS_RECEIVED' && a.salesValos) {
        return {
          ...a,
          salesValos:
            'THE salesValos VALUES ARE SANITIZED, comment the SALES_VALOS_RECEIVED condition in actionSanitizer in bootstrap.ts to see the actual values.',
        };
      }

      if (a.type === 'TRADER_GROUPS_RECEIVED' && a.traderGroupList) {
        return {
          ...a,
          traderGroupList:
            'THE traderGroupList VALUES ARE SANITIZED, comment the TRADER_GROUPS_RECEIVED condition in actionSanitizer in bootstrap.ts to see the actual values.',
        };
      }

      if (a.type === 'TRADERS_RECEIVED' && a.traders) {
        return {
          ...a,
          traders:
            'THE traders VALUES ARE SANITIZED, comment the TRADERS_RECEIVED condition in actionSanitizer in bootstrap.ts to see the actual values.',
        };
      }

      if (a.type === 'SALES_GROUPS_RECEIVED' && a.salesGroupList) {
        return {
          ...a,
          salesGroupList:
            'THE salesGroupList VALUES ARE SANITIZED, comment the SALES_GROUPS_RECEIVED condition in actionSanitizer in bootstrap.ts to see the actual values.',
        };
      }

      if (a.type === 'INSERT_OR_UPDATE' && a.domain === 'alert' && a.items?.length) {
        return {
          ...a,
          type: `$${snakeCase(a.domain).toLocaleUpperCase()}.${a.type}`,
          $originalType: a.type,
          items:
            'THE ALERT VALUES ARE SANITIZED, comment the INSERT_OR_UPDATE condition in actionSanitizer in bootstrap.ts to see the actual values.',
        };
      }
      return {
        ...a,
        type: isCrudAction(a)
          ? `$${snakeCase(a.domain).toLocaleUpperCase()}.${a.type}`
          : `${a.type}`,
        $originalType: a.type,
      };
    },
    stateSanitizer: s => {
      const state = s as unknown as AppState;
      const unorderedState = {
        ...state,
        referenceData: {
          ...state.referenceData,
          areReferenceDataReceived: getSanitizeWarningMessage('areReferenceDataReceived'),
          counterparts: getSanitizeWarningMessage('counterparts'),
          currencies: getSanitizeWarningMessage('currencies'),
          currencyCurvesList: getSanitizeWarningMessage('currencyCurvesList'),
          marginRules: getSanitizeWarningMessage('marginRules'),
          predealChecksDerogations: getSanitizeWarningMessage('predealChecksDerogations'),
          salesGroups: getSanitizeWarningMessage('salesGroups'),
          salesLocations: getSanitizeWarningMessage('salesLocations'),
          salesValos: getSanitizeWarningMessage('salesValos'),
          strategyConfiguration: state.referenceData.strategyConfiguration,
          strategyDefinitions: getSanitizeWarningMessage('strategyDefinitions'),
          tenors: getSanitizeWarningMessage('tenors'),
          traderGroups: getSanitizeWarningMessage('traderGroups'),
          traders: getSanitizeWarningMessage('traders'),
          usMarkets: getSanitizeWarningMessage('usMarkets'),
          markets: getSanitizeWarningMessage('markets'),
          relatedExchangeFields: getSanitizeWarningMessage('relatedExchangeFields'),
          marketsEliot: getSanitizeWarningMessage('marketsEliot'),
        },
        blotter: {
          ...state.blotter,
          rfqs: getSanitizeWarningMessage('blotter'),
        },
        alerts: getSanitizeWarningMessage('alerts'),
      } as AppState;
      const orderedState = (Object.keys(unorderedState) as Array<keyof typeof unorderedState>)
        .sort()
        .reduce((obj, key) => {
          obj[key] = unorderedState[key];
          return obj;
        }, {} as AppState);
      return orderedState as any;
    },
  };
}
