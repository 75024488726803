import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { getBookingStatus } from './legExecution/getBookingStatus';

export function getListedExecutionsWarningDisplayedModel(
  state: AppState,
  rfqId: string,
  strategyId: string,
  legId: string,
  selectors: Selectors,
): boolean {
  const order = selectors.getOrderByLegIdAndCrossWay(
    state,
    { rfqId, strategyId, legId },
    selectors,
  );

  const executionKeys = selectors.executionSelectors.selectKeys(state.execution, {
    orderId: order?.uuid,
  });

  const isThereAWarningDisplayed = executionKeys.some(executionKey => {
    const bookingStep = selectors.getExecutionBookingStep(
      state,
      executionKey.rfqId,
      executionKey.executionId,
    );
    const { bookingStatus } = getBookingStatus(bookingStep);
    return bookingStatus.message;
  });

  return isThereAWarningDisplayed;
}
