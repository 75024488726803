import { type Selectors, selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';

import { lowerThenCamelCase } from '@/util/string/stringUtils';
import { useSelector } from 'react-redux';
import type { LegDescriptionComponentProps } from './AveragePrice';

export function getClientWayByLegId(state: AppState, legId: string, selectors: Selectors) {
  return selectors.getLegData(state, legId).clientWay;
}

export function ClientWay({ legId }: LegDescriptionComponentProps) {
  const clientWay = useSelector((state: AppState) => getClientWayByLegId(state, legId, selectors));
  return (
    <div>
      <label className="mb-0 me-3">C. Way</label>
      <input
        className="form-control"
        data-e2e="client-way"
        value={lowerThenCamelCase(clientWay ?? '').replace('Two', '2')}
        readOnly
      />
    </div>
  );
}
