import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { Thunk } from '@/bootstrap/thunks';
import type { MailRequest } from '@/common/business/mail/mailRequestModel';
import { isDefined } from '@/util/undefinedAndNull/isDefined';
import { flatMap } from 'lodash';
import type { Counterpart, RfqData } from '../../neosModel';
import type { Version } from '../versions/versionsModel';

export function createRequestTradeRecapMailThunk(
  rfqId: string,
  comment: string,
  mailCCRecipients: string[],
): Thunk {
  return function requestTradeRecapMailThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { createMailRequestedAction },
      },
    },
  ) {
    const state = getState();
    const rfqData = selectors.getRfqData(state, rfqId);
    const counterpartName = getRfqCounterpartName(state, rfqId, selectors);
    const version = getRfqVersion(state, rfqId, rfqData.version, selectors);
    const body = getMailBody({ counterpartName, version, rfqId, comment }, rfqData);

    const underlyingInfoBloombergCodes = getUnderlyingInfoBloombergCodes(state, rfqId, selectors);
    const mailSubject = makeSubjectField(underlyingInfoBloombergCodes, counterpartName);

    const { email: currentUserEmail } = selectors.getCurrentUser(state);
    const { strategyIds } = rfqData;

    const emailRecipients = selectors.isFeatureToggleEnabled(
      state,
      'neos.rfq.tradeRecap.groupMailing.enabled',
    )
      ? {
          to: flatMap(strategyIds, sId => {
            const { traderGroupName } = selectors.getStrategyData(state, sId);
            return traderGroupName
              ? (selectors
                  .getTraderGroup(state.referenceData, traderGroupName)
                  ?.diffusionUsers?.map(user => user.email)
                  .filter(isDefined) ?? [])
              : [];
          }),
          cc: mailCCRecipients,
        }
      : { to: [sgmeConfiguration.tradeRecap.to], cc: [sgmeConfiguration.tradeRecap.cc] };

    const mailRequest: MailRequest = {
      body,
      from: currentUserEmail,
      ...emailRecipients,
      isBodyHtml: true,
      subject: mailSubject,
    };

    dispatch(
      createMailRequestedAction(
        mailRequest,
        'Trade recap email successfully sent!',
        'Error sending trade recap email!',
      ),
    );
  };
}

function getMailBody(
  {
    rfqId,
    counterpartName,
    version,
    comment,
  }: {
    rfqId: string;
    counterpartName: string;
    version: string;
    comment: string;
  },
  { tradeRecap, quoteRecap }: RfqData,
) {
  const commentPart = comment
    ? `<div class="title">Comment</div>
    <div class="content">${comment.trim().replace(/(?:\r\n|\r|\n)/g, '<br>')}</div>`
    : '';
  const tradeRecapPart = tradeRecap ? tradeRecap.trim().replace(/(?:\r\n|\r|\n)/g, '<br>') : '';
  return `
  <html>
  <head>

  <style type="text/css">
    .title {
      font-size: 1.2em;
      font-weight: bold;
    }
    .content {
      margin-top: 0.2em;
      margin-left: 1.4em;
      margin-bottom: 0.8em;
    }
  </style>
  </head>
  <body>
  <div class="title">Counterpart</div>
  <div class="content">${counterpartName}</div>
  <div class="title">Quote Recap</div>
  <div class="content">
    ${quoteRecap}
  </div>
  <div class="title">Trade Recap</div>
  <div class="content">
    ${tradeRecapPart}
  </div>

  <div class="title">Version</div>
  <div class="content">
    ${version}
  </div>

  <div class="title">RFQ ID</div>
  <div class="content">${rfqId}</div>
  ${commentPart}
  </body>
  </html>`;
}

export function getUnderlyingInfoBloombergCodes(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): string[] {
  const rfqReferences = selectors.getReferences(state, { rfqId });
  const underlyingInfoBloombergCodes = rfqReferences.map(reference => {
    const { underlyingId } = reference;
    const underlyingInfo = selectors.getUnderlyingInfo(state, underlyingId);
    return !underlyingInfo ? '---' : underlyingInfo.bloombergCode;
  });

  return underlyingInfoBloombergCodes;
}

export function getRfqCounterpartName(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): string {
  const counterpartId = selectors.getSelectedCounterpartId(state, rfqId);
  const counterparts = selectors.getCounterparts(state, rfqId) ?? [];
  const counterpart = counterparts.find(({ id }: Counterpart) => id === counterpartId);

  return counterpart ? counterpart.name : '---';
}

export function getRfqVersion(
  state: AppState,
  rfqId: string,
  versionNumber: number,
  selectors: Selectors,
): string {
  const version: Version | undefined = selectors.getVersion(state, rfqId, versionNumber);

  if (version === undefined) {
    return '---';
  }

  const {
    tradeDate,
    lifecycle: { status, updater },
  } = version;
  const versionDisplay: string = `${versionNumber} | ${tradeDate} | ${status} | ${updater}`;

  return versionDisplay;
}

function makeSubjectField(underlyingBloombergCodes: string[], counterpartName: string): string {
  const spacePos = counterpartName.indexOf(' ', 9);
  const truncatedCounterpartName =
    spacePos === -1 ? counterpartName : counterpartName.substr(0, spacePos);
  return `[SGME NEOS] - ${truncatedCounterpartName} - ${underlyingBloombergCodes.join(', ')} DONE`;
}
