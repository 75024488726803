import { getKeys } from '@/util/getKeys/getKeys';
import type { PriceUnitType } from '../../../neosModel';
import type {
  BidAsk,
  BidAskVolatility,
  NeosMarkup,
  OnyxGreeks,
  OnyxQuote,
  PriceWithUnit,
} from '../../../neosOnyxModel';
import type { Quote } from '../quoteModel';

export function getOnyxQuote(unit: string | '%' | undefined, quote: Quote): OnyxQuote | undefined {
  const traderPrice: BidAsk | undefined = getTraderPrice(quote, unit);
  const salesPrice: BidAsk | undefined = getSalesPrice(quote, unit);

  const includedSalesMargin: NeosMarkup | undefined = getIncludedSalesMargin(quote);
  const extraSalesMargin: NeosMarkup | undefined = getExtraSalesMargin(quote);
  const greeks: OnyxGreeks | undefined = getGreeks(quote);
  const initialPreTradeMidMarketMarkPrice: PriceWithUnit | undefined = getInitialPtmmPrice(quote);
  const preTradeMidMarketMarkPrice: PriceWithUnit | undefined = getPtmmPrice(quote, unit);
  const salesVolatility: BidAsk | undefined = getSalesVolatility(quote);
  const traderVolatility: BidAsk | undefined = getTraderVolatility(quote);

  const { initialSalesPrice, initialTraderPrice, status, subStatus, agreedTraderPrice } = quote;
  return traderPrice || greeks || salesPrice || includedSalesMargin || extraSalesMargin
    ? {
        salesPrice,
        traderPrice,
        greeks,
        includedSalesMargin,
        extraSalesMargin,
        initialSalesPrice,
        initialTraderPrice,
        status,
        subStatus,
        initialPreTradeMidMarketMarkPrice,
        preTradeMidMarketMarkPrice,
        salesVolatility,
        traderVolatility,
        agreedTraderPrice,
      }
    : undefined;
}

function getSalesVolatility(quote: Quote): BidAskVolatility | undefined {
  return quote.salesAskVolatility !== undefined ||
    quote.salesBidVolatility !== undefined ||
    quote.salesAskVolatilityUnit !== undefined ||
    quote.salesBidVolatilityUnit !== undefined
    ? {
        ask: getPriceWithUnit(
          quote.salesAskVolatility,
          quote.salesAskVolatilityUnit,
          quote.salesAskVolatilityType,
        ),
        bid: getPriceWithUnit(
          quote.salesBidVolatility,
          quote.salesBidVolatilityUnit,
          quote.salesBidVolatilityType,
        ),
      }
    : undefined;
}

function getTraderVolatility(quote: Quote): BidAskVolatility | undefined {
  return quote.traderAskVolatility !== undefined ||
    quote.traderBidVolatility !== undefined ||
    quote.traderAskVolatilityUnit !== undefined ||
    quote.traderBidVolatilityUnit !== undefined
    ? {
        ask: getPriceWithUnit(
          quote.traderAskVolatility,
          quote.traderAskVolatilityUnit,
          quote.traderAskVolatilityType,
        ),
        bid: getPriceWithUnit(
          quote.traderBidVolatility,
          quote.traderBidVolatilityUnit,
          quote.traderBidVolatilityType,
        ),
      }
    : undefined;
}

function getTraderPrice(quote: Quote, unit: string | undefined): BidAsk | undefined {
  return unit !== undefined || quote.traderAsk !== undefined || quote.traderBid !== undefined
    ? {
        ask: getPriceWithUnit(quote.traderAsk, unit, quote.traderAskType),
        bid: getPriceWithUnit(quote.traderBid, unit, quote.traderBidType),
      }
    : undefined;
}

function getInitialPtmmPrice(quote: Quote): PriceWithUnit | undefined {
  return quote.initialPreTradeMidMarketMarkPriceUnit !== undefined ||
    quote.initialPreTradeMidMarketMarkPrice !== undefined
    ? {
        value: quote.initialPreTradeMidMarketMarkPrice,
        unit: quote.initialPreTradeMidMarketMarkPriceUnit,
      }
    : undefined;
}

function getPtmmPrice(quote: Quote, unit: string | undefined): PriceWithUnit | undefined {
  return unit !== undefined || quote.preTradeMidMarketMarkPrice !== undefined
    ? { value: quote.preTradeMidMarketMarkPrice, unit, type: quote.preTradeMidMarketMarkPriceType }
    : undefined;
}

function getSalesPrice(quote: Quote, unit: string | undefined): BidAsk | undefined {
  return unit !== undefined || quote.salesAsk !== undefined || quote.salesBid !== undefined
    ? {
        ask: getPriceWithUnit(quote.salesAsk, unit, quote.salesAskType),
        bid: getPriceWithUnit(quote.salesBid, unit, quote.salesBidType),
      }
    : undefined;
}

function getGreeks(quote: Quote): OnyxGreeks | undefined {
  const { delta, deltaType, vega, vegaType, gamma, theta, thetaType, vegaUnit, thetaUnit } = quote;
  const greeksValues = { delta, vega, gamma, theta };

  return getKeys(greeksValues).find(key => greeksValues[key] !== undefined)
    ? {
        delta: delta !== undefined ? getPriceWithUnit(delta, '%', deltaType) : undefined,
        vega: getPriceWithUnit(vega, vegaUnit, vegaType),
        gamma,
        theta: getPriceWithUnit(theta, thetaUnit, thetaType),
      }
    : undefined;
}

function getExtraSalesMargin(quote: Quote): NeosMarkup | undefined {
  return quote.extraSalesMarginRule ||
    quote.extraSalesMarginAsk !== undefined ||
    quote.extraSalesMarginBid !== undefined
    ? {
        rule: quote.extraSalesMarginRule,
        value:
          quote.extraSalesMarginAsk !== undefined || quote.extraSalesMarginBid !== undefined
            ? {
                ask: getPriceWithUnit(
                  quote.extraSalesMarginAsk,
                  quote.extraSalesMarginAskUnit,
                  quote.extraSalesMarginAskType,
                ),
                bid: getPriceWithUnit(
                  quote.extraSalesMarginBid,
                  quote.extraSalesMarginBidUnit,
                  quote.extraSalesMarginBidType,
                ),
                spread: getPriceWithUnit(
                  quote.extraSalesMarginSpread,
                  quote.extraSalesMarginSpreadUnit,
                  quote.extraSalesMarginSpreadType,
                ),
              }
            : undefined,
      }
    : undefined;
}

function getIncludedSalesMargin(quote: Quote): NeosMarkup | undefined {
  return quote.includedSalesMarginRule ||
    quote.includedSalesMarginAsk !== undefined ||
    quote.includedSalesMarginBid !== undefined
    ? {
        rule: quote.includedSalesMarginRule,
        value:
          quote.includedSalesMarginAsk !== undefined || quote.includedSalesMarginBid !== undefined
            ? {
                ask: getPriceWithUnit(
                  quote.includedSalesMarginAsk,
                  quote.includedSalesMarginAskUnit,
                  quote.includedSalesMarginAskType,
                ),
                bid: getPriceWithUnit(
                  quote.includedSalesMarginBid,
                  quote.includedSalesMarginBidUnit,
                  quote.includedSalesMarginBidType,
                ),
                spread: getPriceWithUnit(
                  quote.includedSalesMarginSpread,
                  quote.includedSalesMarginSpreadUnit,
                  quote.includedSalesMarginSpreadType,
                ),
              }
            : undefined,
      }
    : undefined;
}

function getPriceWithUnit(
  price: number | undefined,
  unit: string | undefined,
  type: PriceUnitType | undefined,
): PriceWithUnit | undefined {
  return price !== undefined || unit !== undefined ? { value: price, unit, type } : undefined;
}
