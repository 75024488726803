import type { Thunk } from '@/bootstrap/thunks';

export function createRequestNotificationPermissionThunk(): Thunk {
  return function requestNotificationPermissionThunk(
    dispatch,
    _,
    {
      thunks: {
        createWarningToasterThunk,
        neos: { createEnableHandlingAlertClickThunk },
      },
    },
  ) {
    if (Notification.permission !== 'granted') {
      Notification.requestPermission(status => {
        if (status !== 'granted') {
          dispatch(
            createWarningToasterThunk({
              message: 'You should allow notifications to be able to receive alerts!',
            }),
          );
        } else {
          dispatch(createEnableHandlingAlertClickThunk());
        }
      });
    } else {
      dispatch(createEnableHandlingAlertClickThunk());
    }
  };
}
