import { services } from '@/bootstrap/services';
import type { Dispatchable, Thunk } from '@/bootstrap/thunks';
import type { CrudUpdate } from '@/util/crudUtils';
import type { ChainOptions } from '../../neosActions';
import type { EventType, LegData, RfqData, StrategyData } from '../../neosModel';
import type { ActivityType } from '../../neosOnyxModel';

export function createChangeRfqActivity(
  {
    rfqId,
    strategyId,
    legId,
    legIds,
  }: { rfqId: string; strategyId: string; legId: string; legIds: string[] },
  {
    newActivity,
    primaryRfqSecondaryEvent,
  }: {
    newActivity: ActivityType;
    primaryRfqId: string | undefined;
    primaryRfqSecondaryEvent: EventType | undefined;
  },
): Thunk {
  return function changeRfqActivity(
    dispatch,
    getState,
    {
      selectors: { getRfqData, getStrategyData, getLegData },
      actionCreators: {
        neos: { rfqDataCrudActions, rfqUiCrudActions, strategyDataCrudActions, legDataCrudActions },
      },
      thunks,
    },
  ) {
    const dispatchables: Dispatchable[] = [];
    const appState = getState();

    const { activity: rfqActivity, status, ...rfqRest } = getRfqData(appState, rfqId);
    const newRfq: RfqData = { activity: newActivity, status, ...rfqRest };
    const newStrategy: StrategyData = {
      ...getStrategyData(appState, rfqId),
      activityType: newActivity,
    };

    dispatchables.push([
      rfqDataCrudActions.upsert(rfqId, newRfq),
      strategyDataCrudActions.update(strategyId, newStrategy),
    ]);
    const statusOrder = services.orderOf(status);
    const isValidStatus = statusOrder.isBeforeOrEqual('BOOKED');
    if (isValidStatus && legId) {
      const newLeg: Partial<LegData> = {
        ...getLegData(appState, legId),
        activity: newActivity,
        eventType: primaryRfqSecondaryEvent,
        secondaryEvent: undefined,
      };
      dispatchables.push(legDataCrudActions.update(legId, newLeg));
    }

    if (isValidStatus && legIds.length) {
      const legsActions: CrudUpdate<LegData, string>[] = legIds.map(legId =>
        legDataCrudActions.update(legId, {
          ...getLegData(appState, legId),
          activity: newActivity,
          eventType: primaryRfqSecondaryEvent,
          secondaryEvent: undefined,
        }),
      );
      if (legsActions.every(legAction => !!legAction)) {
        dispatchables.push(legsActions);
      }
    }

    const chainOptions: ChainOptions = {
      success: {
        dispatchables: [
          rfqUiCrudActions.patchOrInsert(rfqId, {
            shouldDisplayStrategyLink: false,
          }),
        ],
      },
      error: {
        dispatchables: [
          rfqDataCrudActions.update(rfqId, { activity: 'PRIMARY' }),
          thunks.neos.createResetSecondaryTypesOnLegsThunk(),
        ],
      },
    };
    dispatchables.push(thunks.neos.createRequestTransactionSaveThunk(rfqId, chainOptions));

    dispatch(dispatchables);
  };
}
