import type { FC } from 'react';
import styles from './StrategyBorder.module.scss';

export interface StrategyBorderOwnProps {
  isSelectedStrategy: boolean;
  isMasterStrategy: boolean;
  onClick: () => void;
  children: any;
  strategyIndex: number;
}

const initialGridLineIndex = 3;
const gridLineCountPerStrategy = 5;

export const StrategyBorder: FC<StrategyBorderOwnProps> = ({
  children,
  isMasterStrategy,
  isSelectedStrategy,
  strategyIndex,
  onClick,
}) => {
  const styleName = ` ${styles['strategy-border']} ${
    isMasterStrategy ? styles['master'] : isSelectedStrategy ? styles['selected'] : ''
  }`;
  return (
    <>
      <div
        className={`${styles['top']} ${styleName}`}
        onClick={onClick}
        data-e2e="neos-strategy-border-top"
      />
      <div className={`${styles['left']} ${styleName}`} onClick={onClick} />
      {children}
      <div
        className={`${styles['right']}  ${styleName}`}
        onClick={onClick}
        style={{
          gridRowStart: `${strategyIndex * gridLineCountPerStrategy + initialGridLineIndex}`,
        }}
      />
      <div className={`${styles['bottom']} ${styleName}`} onClick={onClick} />
    </>
  );
};
