import type {
  StockLoanType,
  TradingBusinessType,
} from '@/neos/business/currentUserPreferences/currentUserPreferencesModel';
import type {
  ElsType,
  ExecFees,
  PriceWithUnit,
  UnderlyingType,
} from '@/neos/business/neosOnyxModel';
import type { CalculationMethodType } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/CalculationMethodModel';
import type {
  BasisType,
  BreakFeePeriod,
  BrokenPeriodPosition,
  BrokenPeriodType,
  BusinessDayConvention,
  CalculationParty,
  DealType,
  DividendPriceType,
  EBreakFeeElectionType,
  EquityHedgeType,
  EquityLegPeriodDates,
  FixedDayType,
  GenerateFromType,
  HedgingParty,
  LegPeriod,
  LinearInterpolation,
  LookthroughDR,
  RateLegPeriodDates,
  ResetMode,
  ResetType,
  RightToSubstitute,
  RightToSubstituteScope,
  RoleDefinition,
  SpreadAdjustmentType,
  TerminationConditionType,
  TerminationRightType,
  ValuationType,
} from './elsProductOnyxModel';
import type { Way } from '@/neos/business/rfq/rfqData/rfqDataModel.ts';
import type { Counterpart } from '@/neos/business/rfq/actorsSetup/counterpart/counterpartModel.ts';

export type CustomProductFamily =
  | 'CORREL_SWAP'
  | 'FX_OPTION'
  | 'FX_VOL_SWAP'
  | 'FX_VAR_SWAP'
  | 'BASKET_OPTION'
  | 'FX_FVA'
  | 'CUSTOM';

export type ProductSubFamily =
  | 'OPTION'
  | 'ASIAN_OPTION'
  | 'ASIAN_SPREAD_OPTION'
  | 'EUROPEAN_SPREAD_OPTION'
  | 'FUTURE'
  | 'DIVIDEND_FUTURE'
  | 'TOTAL_RETURN_FUTURE'
  | VSwapProductFamily
  | 'STOCK'
  | 'FLOATING_STRIKE_FVA'
  | 'FIXED_STRIKE_FVA'
  | ElsProductFamily
  | 'CLS'
  | 'DIV_SWAP'
  | 'SWAPTION'
  | 'FX_FORWARD'
  | CustomProductFamily
  | 'FX_DIGITAL_OPTION'
  | 'DIGITAL_OPTION'
  | 'OPTION_ON_FUTURE';

export type VSwapProductFamily =
  | 'VOL_SWAP'
  | 'VAR_SWAP'
  | 'CROSS_CORRIDOR_VAR_SWAP'
  | 'CROSS_CORRIDOR_VOL_SWAP'
  | 'MONO_CORRIDOR_VOL_SWAP'
  | 'MONO_CORRIDOR_VAR_SWAP';

export type ElsProductFamily = 'ELS' | 'TRS' | 'PRS';

export type NegotiationMode = 'LISTED' | 'OTC';

export const ForexConstatValues = {
  BANK_OF_CANADA_NOON_FIXING: 'Bank of Canada Noon Fixing',
  BCE_FIXING: 'BCE Fixing',
  BEST_EFFORT: 'Best Effort',
  NONE: 'None',
  WM_COMPANY: 'WM Company',
} as const;

export type ForexConstat = keyof typeof ForexConstatValues;

export type ForexType = 'QUANTO' | 'COMPO';

export const availableExecFeesValues = ['NONE', 'IN', 'OUT', 'IN_AND_OUT'] as const;
export type ExecFeesValue = (typeof availableExecFeesValues)[number];

type SubFamilyType<T extends ProductSubFamily> = {
  subFamily: T;
};

export interface CommonProduct {
  uuid: string;
  legId: string;
  lotSize?: number;
  deliveryType?: DeliveryType;
  noTaxCollection?: boolean;
  clientTaxRate?: PriceWithUnit;
}

export interface ListedNegotiation {
  negotiationMode: 'LISTED';
  refId: string | undefined;
  marketExchangeId: string | undefined;
  marketMicCode: string | undefined;
  clientPosition?: ClientPosition;
  sameProductIds?: string;
}

export interface OtcNegotiation {
  negotiationMode: 'OTC';
}

export type Negotiation = ListedNegotiation | OtcNegotiation;

interface CommonDerivativeProduct {
  isDerivativeProduct: true;
  maturity: string | undefined;
  maturityTenor?: string;
}

export type BasketUnderlyingDerivative = CommonDerivativeProduct & {
  underlyingKind: 'BASKET';
  basketUnderlying: BasketUnderlying;
};

export type SingleUnderlyingDerivative = CommonDerivativeProduct & {
  underlyingKind: 'SINGLE';
  underlyingId: string | undefined;
};

export type Derivative = SingleUnderlyingDerivative | BasketUnderlyingDerivative;

interface NonDerivative {
  isDerivativeProduct: false;
}

export interface CustomUnderlyingProduct {
  hasCustomUnderlying: true;
  underlyingName: string | undefined;
  currency: string | undefined;
  maturity: string | undefined;
  maturityTenor?: string;
}

interface NonCustomUnderlyingProduct {
  hasCustomUnderlying: false;
}

interface OptionTypeProduct {
  type: OptionType | undefined;
}

interface OptionStyleProduct {
  style?: OptionStyle;
}

interface OptionFlexProduct {
  flex?: OptionFlex;
}

export interface StrikeProduct {
  strike: number | undefined;
  strikeUnit: string | undefined;
}

export interface StrikeDateTenor {
  strikeDate: string | undefined;
  strikeTenor: string | undefined;
}

export interface UpperLowerStrikeProduct {
  lowerStrike: number | undefined;
  upperStrike: number | undefined;
  strikeUnit: string | undefined;
}

export interface OptionFutureProduct {
  futureMaturity?: string;
}

export interface OptionObservableTypeProductField {
  observableType: OptionObservableType | undefined;
}

type CommonOptionProduct = CommonProduct &
  SingleUnderlyingDerivative &
  NonCustomUnderlyingProduct &
  Negotiation &
  OptionTypeProduct &
  OptionStyleProduct &
  OptionFlexProduct;

export type Option = SubFamilyType<'OPTION'> &
  CommonOptionProduct &
  StrikeProduct &
  OptionObservableTypeProductField;

export type OptionOnFuture = SubFamilyType<'OPTION_ON_FUTURE'> &
  CommonOptionProduct &
  StrikeProduct &
  OptionFutureProduct;

export type EuropeanOption = SubFamilyType<'EUROPEAN_SPREAD_OPTION'> &
  CommonOptionProduct &
  UpperLowerStrikeProduct;

export const averageDisruptionDateValues = {
  MODIFIED_POSTPONEMENT: 'Modified Postponement',
  POSTPONEMENT: 'Postponement',
  OMISSION: 'Omission',
} as const;
export type AverageDisruptionDate = keyof typeof averageDisruptionDateValues;

export const periodFrequencies = {
  YEARLY: 'Yearly',
  MONTHLY: 'Monthly',
  DAILY: 'Daily',
} as const;
export type PeriodFrequencies = keyof typeof periodFrequencies;

export const strikeTypeValues = {
  FIXED_STRIKE: 'Fixed Strike',
  FLOATING_STRIKE: 'Floating Strike',
} as const;
export type StrikeType = keyof typeof strikeTypeValues;

export type AsianPeriodDate = {
  weight: number | undefined;
  date: string | undefined;
  uuid: string;
};

export type AsianPeriod = {
  startDate: string;
  endDate: string;
  dates: AsianPeriodDate[];
  frequency: PeriodFrequencies | undefined;
  includeEndDate: boolean | undefined;
};

export interface AsianProduct {
  strikeType: StrikeType | undefined;
  period: AsianPeriod | undefined;
  averageDisruptionDate: AverageDisruptionDate | undefined;
  businessDayConvention: BusinessDayConvention | undefined;
}

export type AsianOption = SubFamilyType<'ASIAN_OPTION'> &
  CommonOptionProduct &
  StrikeProduct &
  AsianProduct;

export type AsianSpreadOption = SubFamilyType<'ASIAN_SPREAD_OPTION'> &
  CommonOptionProduct &
  UpperLowerStrikeProduct &
  AsianProduct;

export type OptionLike =
  | Option
  | Fva
  | EuropeanOption
  | AsianOption
  | AsianSpreadOption
  | OptionOnFuture;

interface OtcNegotiationPointValue extends OtcNegotiation {
  pointValue: 1;
}

interface ListedNegotiationPointValue extends ListedNegotiation {
  pointValue: number | undefined;
}

type NegotiationPointValue = OtcNegotiationPointValue | ListedNegotiationPointValue;

type CommonFuture = NonCustomUnderlyingProduct &
  CommonProduct &
  StrikeDateTenor &
  SingleUnderlyingDerivative &
  NegotiationPointValue;

export type Future = SubFamilyType<'FUTURE'> & CommonFuture;
export type TotalReturnFuture = SubFamilyType<'TOTAL_RETURN_FUTURE'> & CommonFuture;

export type DividendFuture = SubFamilyType<'DIVIDEND_FUTURE'> & CommonFuture;

export type Stock = SubFamilyType<'STOCK'> &
  NonCustomUnderlyingProduct &
  CommonProduct &
  NonDerivative &
  ListedNegotiation;

type ExpectedNProduct = {
  expectedN: number | undefined;
};

export type CommonVSwapProduct = CommonProduct &
  SingleUnderlyingDerivative &
  NonCustomUnderlyingProduct &
  OtcNegotiation &
  ExpectedNProduct;

export interface VolSwap extends CommonVSwapProduct {
  subFamily: 'VOL_SWAP';
}

export interface VarSwap extends CommonVSwapProduct {
  subFamily: 'VAR_SWAP';
}

export interface CrossCorridorVarSwap extends CommonVSwapProduct {
  subFamily: 'CROSS_CORRIDOR_VAR_SWAP';
}

export interface CrossCorridorVolSwap extends CommonVSwapProduct {
  subFamily: 'CROSS_CORRIDOR_VOL_SWAP';
}

export interface MonoCorridorVarSwap extends CommonVSwapProduct {
  subFamily: 'MONO_CORRIDOR_VAR_SWAP';
}

export interface MonoCorridorVolSwap extends CommonVSwapProduct {
  subFamily: 'MONO_CORRIDOR_VOL_SWAP';
}

type VarSwapProduct = VarSwap | CrossCorridorVarSwap | MonoCorridorVarSwap;
type VolSwapProduct = VolSwap | CrossCorridorVolSwap | MonoCorridorVolSwap;

export type VSwap = VarSwapProduct | VolSwapProduct;

export type FvaCommon = CommonProduct &
  NonCustomUnderlyingProduct &
  SingleUnderlyingDerivative &
  OtcNegotiation &
  OptionTypeProduct &
  OptionStyleProduct &
  OptionFlexProduct &
  StrikeProduct;

export interface FvaFixedK extends FvaCommon {
  subFamily: 'FIXED_STRIKE_FVA';
  forwardDrift: number | undefined;
  forwardInterestRate: number | undefined;
}

export interface FvaFloatingK extends FvaCommon {
  subFamily: 'FLOATING_STRIKE_FVA';
}

export type Fva = FvaFixedK | FvaFloatingK;

export type FutureLike = Future | DividendFuture | TotalReturnFuture;

export type ClsType = 'ERI' | 'TRI' | 'TFR';
export type AccrualType = 'ACT360' | 'ACT365';

export type Cls = CommonProduct &
  NonCustomUnderlyingProduct &
  SingleUnderlyingDerivative &
  OtcNegotiation &
  StrikeDateTenor & {
    subFamily: 'CLS';
    clsType: ClsType | undefined;
    accrual: AccrualType | undefined;
    generateFrom?: GenerateFromType;
    fixedDay?: FixedDayType;
    conventionDay?: BusinessDayConvention;
    brokenPeriod?: BrokenPeriodType;
    resetMode?: ResetMode;
    rateReset?: ResetType;
    ratePeriods?: LegPeriod<RateLegPeriodDates>[];
    effectiveDate?: string;
    isScheduleObsolete?: boolean;
  };

export type Els = ElsNonBasket | ElsBasket;

export type ElsNonBasket = CommonEls & SingleUnderlyingDerivative;
export type ElsBasket = CommonEls & BasketUnderlyingDerivative;

export interface BasketUnderlying {
  discriminator: 'BASKET_UNDERLYING';
  type: 'BASKET';
  currency?: string | undefined;
  basketType: 'BEST_OF' | 'WORST_OF' | 'WEIGHTED_PERF' | 'WEIGHTED' | undefined;
  basketMarket:
    | {
        galaxyCode: string | undefined;
        micCode: string | undefined;
      }
    | undefined;
  basketTypology: 'MULTI_UNDERLYING' | undefined;
  basketMultiplier: number | undefined;
  basketDivisor: number | undefined;
  isAutoRepo: boolean | undefined;
  isAutoDiv: boolean | undefined;
  basketEliotID: string | undefined;
  repoSource: string | undefined;
  basketComposition: BasketCompositionDetails[];
  forexType: ForexType | undefined;
  execFees: ExecFeesValue;
}

export interface BasketCompositionDetails {
  containerIndex: number;
  underlyingId: string | undefined;
  nominal:
    | {
        value: number | undefined;
        unit: string | undefined;
        type: string | undefined;
      }
    | undefined;
  execFeesIn: ExecFees | undefined;
  execFeesOut: ExecFees | undefined;
  quantity: number | undefined;
  weight: number | undefined;
}

export type CommonEls = CommonProduct &
  NonCustomUnderlyingProduct &
  OtcNegotiation &
  StrikeDateTenor & {
    // TODO remove TRS and PRS because there are not valid strategytype
    subFamily: 'ELS' | 'TRS' | 'PRS';
    swapCurrency: string | undefined;
    elsType: ElsType | undefined;
    observationShift?: number;
    lockout?: number;
    lookbackPeriod?: number;
    paymentDelay?: number;
    calculationMethod?: CalculationMethodType;
    effectiveDate?: string;
    effectiveDateOffset?: number;
    terminationType?: ValuationType;
    valuationType?: ValuationType;
    terminationRights?: TerminationRightType;
    terminationConditions?: TerminationConditionType;
    basisType?: BasisType;
    compoundRate?: boolean;
    dealType?: DealType;
    localTaxes?: boolean;
    specialDividends?: boolean;
    declaredCashDiv?: number;
    rateFixingOffset?: number;
    isScheduleObsolete?: boolean;
  } & ScheduleEls &
  ElsSections;

export type ScheduleEls = {
  generateFrom?: GenerateFromType;
  equityResetType?: ResetType;
  rateReset?: ResetType;
  brokenPeriod?: BrokenPeriodType;
  wRateResetOnEach?: FixedDayType;
  conventionDay?: BusinessDayConvention;
  derogateRateFixingOffset?: boolean;
  ratePeriods?: LegPeriod<RateLegPeriodDates>[];
  equityPeriods?: LegPeriod<EquityLegPeriodDates>[];
};

export interface StockLoanComponent {
  uuid: string | undefined;
  allIn: PriceWithUnit | undefined;
  borrowPortfolio: string | undefined;
  dividendRequirement: PriceWithUnit | undefined;
  fees: PriceWithUnit | undefined;
  lendPortfolio: string | undefined;
  quantity: number | undefined;
  underlyingId: string | undefined;
}

export type StockLoanHedge = {
  maturity: string | undefined;
  portfolio: string | undefined;
  stockLoanComponents: StockLoanComponent[];
  stockLoanType: StockLoanType | undefined;
  tradingBusiness: TradingBusinessType | undefined;
  bookingApplication: 'XONE' | undefined;
  bookingId: string | undefined;
};

export interface EquityHedgeComponent {
  counterparty: Counterpart | undefined;
  uuid: string | undefined;
  underlyingId: string | undefined;
  bookingId: string | undefined;
  bookingApplication: 'XONE' | undefined;
  way: Way | undefined;
  spot: PriceWithUnit | undefined;
  spotNet: PriceWithUnit | undefined;
  quantity: number | undefined;
  nominal: PriceWithUnit | undefined;
  broker: string | undefined;
  portfolio: string | undefined;
  internalPortfolio: string | undefined;
  equityType: EquityHedgeType | undefined;
}

export type EquityHedge = {
  broker: string | undefined;
  equityType: EquityHedgeType | undefined;
  currency: string | undefined;
  way: Way | undefined;
  bookedManually: boolean | undefined;
  counterparty: Counterpart | undefined;
  equityHedgeComponents: EquityHedgeComponent[];
};

export const initialStockLoanHedge: StockLoanHedge = Object.freeze({
  stockLoanComponents: [],
  portfolio: undefined,
  maturity: undefined,
  tradingBusiness: undefined,
  stockLoanType: undefined,
  bookingId: undefined,
  bookingApplication: undefined,
});

export const initialEquityHedge: EquityHedge = Object.freeze({
  broker: undefined,
  equityType: undefined,
  currency: undefined,
  way: undefined,
  bookedManually: undefined,
  counterparty: undefined,
  equityHedgeComponents: [],
});

export type ElsSections = {
  // Rate bloc
  brokenPeriodPosition?: BrokenPeriodPosition;
  ratePreconfComment?: string;
  // Early Termination bloc
  rateSpreadAdjustment?: SpreadAdjustmentType;
  dividendSpreadAdjustment?: SpreadAdjustmentType;
  termNotice?: number;
  clientTermNotice?: number;
  terminationPreconfComment?: string;
  // Other bloc
  roleDefinition?: RoleDefinition;
  calculationAgent?: CalculationParty;
  determiningParty?: CalculationParty;
  hedgingParty?: HedgingParty;
  linearInterpolation?: LinearInterpolation;
  lookthroughDR?: LookthroughDR;
  relatedExchange?: string;
  componentSecurityIndexAnnex?: boolean;
  dividendPriceType?: DividendPriceType;
  // Right To Substitute bloc
  rightToSubstituteScope?: RightToSubstituteScope;
  rightToSubstituteConditions?: RightToSubstitute;
  // Equity bloc
  electionDate?: string;
  electionFee?: number;
  settlementMethodElection?: boolean;
  equityPreconfComment?: string;
  // Fees bloc
  secondaryMarketAllowed?: boolean;
  breakFeeElection?: EBreakFeeElectionType;
  breakFeePeriods?: Array<BreakFeePeriod>;
  dailyMinSize?: number;
  dailyMaxSize?: number;
  // Lend and Borrow
  hedgeComment?: string;
  stockLoanHedge?: StockLoanHedge;
  // Buy and Sell
  equityHedge?: EquityHedge;
};

interface Description {
  description: string | undefined;
}

interface StartDateProduct {
  startDate: string | undefined;
}

export type DivSwap = SubFamilyType<'DIV_SWAP'> &
  CommonProduct &
  OtcNegotiation &
  SingleUnderlyingDerivative &
  NonCustomUnderlyingProduct &
  Description &
  StartDateProduct;

export type Swaption = SubFamilyType<'SWAPTION'> &
  CommonProduct &
  OtcNegotiation &
  SingleUnderlyingDerivative &
  NonCustomUnderlyingProduct &
  Description;

export type BaseCustomProduct = CommonProduct &
  OtcNegotiation &
  NonDerivative &
  CustomUnderlyingProduct &
  Description;

type CorrelSwap = SubFamilyType<'CORREL_SWAP'> & BaseCustomProduct;
type FxOption = SubFamilyType<'FX_OPTION'> & BaseCustomProduct;
type FxFva = SubFamilyType<'FX_FVA'> & BaseCustomProduct;
type FxVarSwap = SubFamilyType<'FX_VAR_SWAP'> & BaseCustomProduct;
type FxVolSwap = SubFamilyType<'FX_VOL_SWAP'> & BaseCustomProduct;
type BasketOption = SubFamilyType<'BASKET_OPTION'> & BaseCustomProduct;
type Custom = SubFamilyType<'CUSTOM'> & BaseCustomProduct;
export type FxForward = SubFamilyType<'FX_FORWARD'> & OtcNegotiationPointValue & BaseCustomProduct;

export type CustomProduct =
  | CorrelSwap
  | FxOption
  | FxFva
  | FxVarSwap
  | FxVolSwap
  | BasketOption
  | Custom;

export type FxDigitalOptionProduct = SubFamilyType<'FX_DIGITAL_OPTION'> &
  CommonProduct &
  OtcNegotiation &
  NonDerivative &
  CustomUnderlyingProduct;

export type DigitalOptionProduct = SubFamilyType<'DIGITAL_OPTION'> &
  CommonProduct &
  OtcNegotiation &
  SingleUnderlyingDerivative &
  NonCustomUnderlyingProduct;

export type Product =
  | OptionLike
  | Stock
  | FutureLike
  | VSwap
  | Els
  | Cls
  | DivSwap
  | Swaption
  | FxForward
  | CustomProduct
  | FxDigitalOptionProduct
  | DigitalOptionProduct;

/* HELPER TYPES */
export type Listed<T> = Extract<T, ListedNegotiation>;
export type Otc<T> = Extract<T, OtcNegotiation>;
export type DerivativeProduct<T extends Product> = Extract<T, Derivative>;
export type SingleUnderlyingDerivativeProduct<T extends Product> = Extract<
  T,
  SingleUnderlyingDerivative
>;
export type BasketDerivativeProduct<T extends Product> = Extract<T, BasketUnderlyingDerivative>;

export function isOptionProduct(product: Product): product is Option {
  return product.subFamily === 'OPTION';
}

function isEuropeanOptionProduct(product: Product): product is EuropeanOption {
  return product.subFamily === 'EUROPEAN_SPREAD_OPTION';
}

function isAsianOptionProduct(product: Product): product is AsianOption {
  return product.subFamily === 'ASIAN_OPTION';
}

function isAsianSpreadOptionProduct(product: Product): product is AsianSpreadOption {
  return product.subFamily === 'ASIAN_SPREAD_OPTION';
}

export function isProductWithStrikeDateTenor(
  product: Product,
): product is Extract<Product, StrikeDateTenor> {
  return isElsProduct(product) || isClsProduct(product) || isFutureLikeProduct(product);
}

export function isProductWithUpperLowerStrike(
  product: Product,
): product is Extract<Product, UpperLowerStrikeProduct> {
  return isEuropeanOptionProduct(product) || isAsianSpreadOptionProduct(product);
}

export function isProductWithAsianFields(
  product: Product,
): product is Extract<Product, AsianProduct> {
  return isAsianOptionProduct(product) || isAsianSpreadOptionProduct(product);
}

export function isOptionLike(product: Product): product is OptionLike {
  return (
    isOptionProduct(product) ||
    isFvaProduct(product) ||
    isEuropeanOptionProduct(product) ||
    isAsianOptionProduct(product) ||
    isAsianSpreadOptionProduct(product) ||
    isFutureOptionProduct(product)
  );
}

export function isProductWithStrike(product: Product): product is Extract<Product, StrikeProduct> {
  return (
    isOptionProduct(product) ||
    isFvaProduct(product) ||
    isAsianOptionProduct(product) ||
    isFutureOptionProduct(product)
  );
}

export function isBasketDerivativeProduct(
  product: Product,
): product is BasketDerivativeProduct<Product> {
  return product.isDerivativeProduct && product.underlyingKind === 'BASKET';
}

export function isSingleUnderlyingDerivativeProduct(
  product: Product,
): product is SingleUnderlyingDerivativeProduct<Product> {
  return product.isDerivativeProduct && product.underlyingKind === 'SINGLE';
}

export function isDerivativeProduct(product: Product): product is DerivativeProduct<Product> {
  return product.isDerivativeProduct;
}

export function hasMaturity(
  p: Product,
): p is Extract<Product, SingleUnderlyingDerivative | CustomUnderlyingProduct> {
  return isDerivativeProduct(p) || isCustomUnderlyingProduct(p);
}

export function hasFutureMaturity(p: Product): p is Extract<Product, OptionOnFuture> {
  return p.subFamily === 'OPTION_ON_FUTURE';
}

export function isListedProduct(product: Product): product is Listed<Product> {
  return product.negotiationMode === 'LISTED';
}

export function isOtcProduct(product: Product): product is Otc<Product> {
  return product.negotiationMode === 'OTC';
}

export function isFutureOptionProduct(product: Product): product is Future {
  return product.subFamily === 'OPTION_ON_FUTURE';
}

export function isFutureProduct(product: Product): product is Future {
  return product.subFamily === 'FUTURE';
}

export function isDividendFutureProduct(product: Product): product is DividendFuture {
  return product.subFamily === 'DIVIDEND_FUTURE';
}

export function isTotalReturnFutureProduct(product: Product): product is TotalReturnFuture {
  return product.subFamily === 'TOTAL_RETURN_FUTURE';
}

export function isFutureLikeProduct(product: Product): product is FutureLike {
  return (
    isFutureProduct(product) ||
    isDividendFutureProduct(product) ||
    isTotalReturnFutureProduct(product)
  );
}

export function isStockProduct(product: Product): product is Stock {
  return product.subFamily === 'STOCK';
}

export function isVarSwapProduct(product: Product): product is VarSwapProduct {
  const varSwapFamilies: Product['subFamily'][] = [
    'VAR_SWAP',
    'CROSS_CORRIDOR_VAR_SWAP',
    'MONO_CORRIDOR_VAR_SWAP',
  ];
  return varSwapFamilies.includes(product.subFamily);
}

export function isCrossCorridorVarSwapProduct(product: Product): product is CrossCorridorVarSwap {
  return product.subFamily === 'CROSS_CORRIDOR_VAR_SWAP';
}

export function isCrossCorridorVolSwapProduct(product: Product): product is CrossCorridorVolSwap {
  return product.subFamily === 'CROSS_CORRIDOR_VOL_SWAP';
}

export function isCrossCorridorVSwapProduct(
  product: Product,
): product is CrossCorridorVolSwap | CrossCorridorVarSwap {
  return isCrossCorridorVarSwapProduct(product) || isCrossCorridorVolSwapProduct(product);
}

export function isVolSwapProduct(product: Product): product is VolSwapProduct {
  const volSwapFamilies: Product['subFamily'][] = [
    'VOL_SWAP',
    'CROSS_CORRIDOR_VOL_SWAP',
    'MONO_CORRIDOR_VOL_SWAP',
  ];
  return volSwapFamilies.includes(product.subFamily);
}

export function isMonoCorridorVarSwapProduct(product: Product): product is MonoCorridorVarSwap {
  return product.subFamily === 'MONO_CORRIDOR_VAR_SWAP';
}

export function isMonoCorridorVolSwapProduct(product: Product): product is MonoCorridorVarSwap {
  return product.subFamily === 'MONO_CORRIDOR_VOL_SWAP';
}

export function isVSwapProduct(product: Product): product is VSwap {
  return isVolSwapProduct(product) || isVarSwapProduct(product);
}

export function isFvaFixedKProduct(product: Product): product is FvaFixedK {
  return product.subFamily === 'FIXED_STRIKE_FVA';
}

export function isFvaFloatingProduct(product: Product): product is FvaFloatingK {
  return product.subFamily === 'FLOATING_STRIKE_FVA';
}

export function isFvaProduct(product: Product): product is Fva {
  return isFvaFixedKProduct(product) || isFvaFloatingProduct(product);
}

export function isDivSwapProduct(product: Product): product is DivSwap {
  return product.subFamily === 'DIV_SWAP';
}

export function assertProductIsEls(product: Product): asserts product is Els {
  if (!isElsProduct(product)) {
    throw new Error('Product is not ELS');
  }
}
export function assertProductIsElsBasket(product: Product): asserts product is ElsBasket {
  if (!isElsBasketProduct(product)) {
    throw new Error('Product is not ELS BASKET');
  }
}

export function isElsProduct(product: Product): product is Els {
  return product.subFamily === 'ELS' || product.subFamily === 'PRS' || product.subFamily === 'TRS';
}

export function isElsBasketProduct(product: Product): product is ElsBasket {
  return isElsProduct(product) && product.underlyingKind === 'BASKET';
}

export function isElsTrsProduct(product: Els): product is Els {
  return product.elsType === 'TRS';
}

export function isClsProduct(product: Product): product is Cls {
  return product.subFamily === 'CLS';
}

export function isCustomUnderlyingProduct(
  product: Product,
): product is Extract<Product, CustomUnderlyingProduct> {
  return product.hasCustomUnderlying;
}

export function isListedExecutionProduct(
  product: Product,
): product is SingleUnderlyingDerivative & Listed<Product> {
  return isDerivativeProduct(product) && isListedProduct(product);
}

export function isOtcExecutionProduct(
  product: Product,
): product is SingleUnderlyingDerivative & Otc<Product> {
  return isDerivativeProduct(product) && isOtcProduct(product);
}

export interface Underlying {
  id: string;
  bloombergCode: string;
  currency: string;
  type: UnderlyingType;
}

export type DeliveryType = 'CASH' | 'PHYSICAL' | 'NONE';
export type OptionType = 'CALL' | 'PUT';
export type OptionStyle = 'AMERICAN' | 'EUROPEAN';
export type OptionFlex = 'FLEX' | 'NON_FLEX';
export type OptionObservableType = 'DIVIDEND' | 'SPOT';
export type ClientPosition = 'OPEN' | 'CLOSE';

interface EditableProduct
  extends OptionTypeProduct,
    AsianProduct,
    OptionStyleProduct,
    OptionFlexProduct,
    OptionObservableTypeProductField,
    StrikeProduct,
    UpperLowerStrikeProduct,
    ExpectedNProduct,
    OptionFutureProduct,
    SingleUnderlyingDerivative,
    StartDateProduct,
    Omit<ElsNonBasket, 'subFamily'>,
    Omit<Cls, 'subFamily'>,
    Omit<FvaFixedK, 'subFamily'> {}

export type FieldEditableProduct = OmitSafe<
  EditableProduct,
  | 'isDerivativeProduct'
  | 'uuid'
  | 'legId'
  | 'negotiationMode'
  | 'lotSize'
  | 'strikeUnit'
  | 'deliveryType'
  | 'hasCustomUnderlying'
  | 'strikeTenor'
  | 'underlyingKind'
>;

export type ProductField = keyof FieldEditableProduct;

export const defaultProduct = (legId: string, uuid: string): Product => ({
  uuid,
  legId,
  subFamily: 'OPTION',
  type: 'CALL',
  negotiationMode: 'LISTED',
  marketExchangeId: undefined,
  refId: undefined,
  underlyingId: undefined,
  isDerivativeProduct: true,
  maturity: undefined,
  maturityTenor: undefined,
  hasCustomUnderlying: false,
  strike: undefined,
  strikeUnit: undefined,
  marketMicCode: undefined,
  observableType: undefined,
  clientTaxRate: undefined,
  noTaxCollection: undefined,
  underlyingKind: 'SINGLE',
});

export const defaultBasketUnderlying: BasketUnderlying = {
  discriminator: 'BASKET_UNDERLYING',
  type: 'BASKET',
  basketDivisor: undefined,
  basketEliotID: undefined,
  basketMarket: undefined,
  basketMultiplier: undefined,
  basketType: undefined,
  basketTypology: undefined,
  currency: undefined,
  execFees: 'NONE',
  forexType: undefined,
  isAutoDiv: undefined,
  isAutoRepo: undefined,
  repoSource: undefined,
  basketComposition: [],
};
