import { type Selectors, selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { isOptionLike } from '../../../../../../neos/business/neosModel';

import { useSelector } from 'react-redux';
import type { LegDescriptionComponentProps } from './AveragePrice';

export function getProductOptionStyle(state: AppState, legId: string, selectors: Selectors) {
  const { productId } = selectors.getLegData(state, legId);
  const product = selectors.getProduct(state, productId);
  return isOptionLike(product) ? { isVisible: true, value: product.style } : { isVisible: false };
}
export function OptionStyle({ legId }: LegDescriptionComponentProps) {
  const optionStyle = useSelector((state: AppState) =>
    getProductOptionStyle(state, legId, selectors),
  );
  if (!optionStyle.isVisible) {
    return null;
  }
  return (
    <div>
      <label className="mb-0 me-3">Style</label>
      <input
        data-e2e="style"
        className="form-control"
        value={optionStyle.value?.charAt(0) ?? ''}
        readOnly
      />
    </div>
  );
}
