import deselectCurrent from 'toggle-selection';

type SuccessSource = 'EXEC_COMMAND' | 'NATIVE_API';
type FailSource = 'EXEC_COMMAND_FAIL_AND_NO_NATIVE_API_AVAILABLE' | 'EXEC_COMMAND_AND_NATIVE_FAIL';

export interface CopyOptions {
  onSuccess?: (source: SuccessSource) => void;
  onFail?: (source: FailSource, error?: any) => void;
}

export function copyToClipboard(text: string, options?: CopyOptions) {
  const success = copyEx(text);
  const onSuccess = (source: SuccessSource) => options?.onSuccess?.(source);
  const onFail = (source: FailSource, error?: any) => options?.onFail?.(source, error);

  if (success) {
    onSuccess('EXEC_COMMAND');
  } else if (navigator.clipboard?.writeText) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        onSuccess('NATIVE_API');
      })
      .catch(e => {
        onFail('EXEC_COMMAND_AND_NATIVE_FAIL', e);
      });
  } else {
    onFail('EXEC_COMMAND_FAIL_AND_NO_NATIVE_API_AVAILABLE');
  }
}

function copyEx(text: string) {
  let reselectPrevious,
    range,
    selection,
    mark,
    success = false;
  try {
    reselectPrevious = deselectCurrent();

    range = document.createRange();
    selection = document.getSelection();

    mark = document.createElement('span');
    mark.textContent = text;
    // reset user styles for span element
    // prevents scrolling to the end of the page
    mark.style.position = 'fixed';
    mark.style.top = '0';
    mark.style.clip = 'rect(0, 0, 0, 0)';
    // used to preserve spaces and line breaks
    mark.style.whiteSpace = 'pre';
    // do not inherit user-select (it may be `none`)
    mark.style.webkitUserSelect = 'text';
    mark.style.userSelect = 'text';
    mark.addEventListener('copy', function (e) {
      e.stopPropagation();
    });

    document.body.appendChild(mark);

    range.selectNodeContents(mark);
    selection!.addRange(range);

    success = document.execCommand('copy');
  } catch (_) {
    return;
  } finally {
    if (selection) {
      if (typeof selection.removeRange == 'function') {
        if (range) {
          selection.removeRange(range);
        }
      } else {
        selection.removeAllRanges();
      }
    }

    if (mark) {
      document.body.removeChild(mark);
    }
    reselectPrevious();
  }

  return success;
}
