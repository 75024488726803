import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { getIsStrategyDefinitionFieldEditable } from '../utils/fieldsEnablingConditions';

export interface WeightCellModel {
  weight: number | undefined;
  warning: boolean;
  disabled: boolean;
}

export function getWeightCellModel(
  appState: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): WeightCellModel {
  const { weight } = selectors.getStrategyData(appState, strategyId);

  const isOtcAndAmendWorkflowStatus = selectors.isOtcStrategyAndAmendWorkflowStatus(
    appState,
    rfqId,
    strategyId,
    selectors,
  );

  const warning = weight !== undefined && weight <= 0;

  const disabled =
    !getIsStrategyDefinitionFieldEditable(appState, rfqId, strategyId, selectors, [
      'RFQ_SCOPE',
      'RFQ_EDITABLE',
      'UNDERLYING_SET',
      'STRATEGY_EDITABLE',
    ]) || isOtcAndAmendWorkflowStatus;

  return {
    weight,
    warning,
    disabled,
  };
}
