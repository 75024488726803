import type { Thunk } from '@/bootstrap/thunks';

export function createNeosReplaceCurrentByNotificationRfqThunk(rfqId: string): Thunk {
  return function neosReplaceCurrentByNotificationRfqThunk(
    dispatch,
    getState,
    {
      thunks: {
        neos: { createNeosLoadNotificationRfqThunk },
      },
      selectors,
    },
  ) {
    const state = getState();
    const { getPendingNotificationRfq } = selectors;

    const notificationRfq = getPendingNotificationRfq(state, rfqId);
    if (!notificationRfq) {
      return;
    }

    dispatch(createNeosLoadNotificationRfqThunk(rfqId, notificationRfq));
  };
}
