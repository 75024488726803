import type { Thunk } from '@/bootstrap/thunks';
import type { ElsType } from '../../../../../../neos/business/neosOnyxModel';

export function createUpdateProductElsTypeThunk(
  rfqId: string,
  strategyId: string,
  legId: string,
  elsType: ElsType | undefined,
): Thunk {
  return function updateProductElsTypeThunk(
    dispatch,
    getState,
    {
      selectors: { getLegData },
      actionCreators: {
        neos: { createDefaultElsTypeRequestedAction },
      },
      thunks: {
        neos: { createUpdateProductThunk, changeElsIsScheduleObsoleteThunk },
      },
    },
  ) {
    const appState = getState();
    const { productId } = getLegData(appState, legId);
    dispatch(
      createUpdateProductThunk(strategyId, productId, 'elsType', elsType),
      changeElsIsScheduleObsoleteThunk(rfqId, productId, true),
      createDefaultElsTypeRequestedAction(rfqId),
    );
  };
}
