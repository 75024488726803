import { DefaultingOptions, type StrategiesFilterer } from '../rfqActions';

import type { ChainOptions, RetryOnErrorOptions } from '@/neos/business/neosActions';
import { DefaultingScope } from '../models';
import { createDefaultRfqRequestedAction } from './defaultRfqRequestedAction';

export function createDefaultCompositionLegsRequestedAction(
  rfqId: string,
  strategyId: string,
  chainOptions?: ChainOptions,
) {
  const strategiesFilterer: StrategiesFilterer = strategies =>
    strategies.filter(s => strategyId === s.uuid);
  const overrideScopes = [
    DefaultingScope.TRADER_DIFFUSION,
    DefaultingScope.DELIVERY_TYPE,
    DefaultingScope.DETERMINATION_METHOD,
    DefaultingScope.UNDERLYING,
    DefaultingScope.NEGOTIATED_SIZE,
    DefaultingScope.NOTIONAL,
    DefaultingScope.PIVOT_CURRENCY,
    DefaultingScope.REFERENCE_MATURITY,
    DefaultingScope.CAP,
  ];

  const enrichScopes = [
    DefaultingScope.EXPECTED_N,
    DefaultingScope.FOREX,
    DefaultingScope.REFERENCE,
    DefaultingScope.SPOT_NET,
    DefaultingScope.SALES_DIFFUSION,
    DefaultingScope.TRADE_DATE,
    DefaultingScope.UNITS,
  ];
  const options = new DefaultingOptions({ overrideScopes, enrichScopes });
  options.strategiesFilterer = strategiesFilterer;
  const retryOptions: RetryOnErrorOptions = {
    retries: 1,
  };
  return createDefaultRfqRequestedAction(rfqId, options, chainOptions, retryOptions);
}
