import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { Destination } from '../../neosModel';
import type { OnyxTransaction } from '../../neosOnyxModel';
import type { ExternalPreconfirmationRequest } from '../../preconfirmation/external/externalOnyxPreconfirmationModel';
import type { InternalPreconfirmationRequest } from '../../preconfirmation/internal/internalOnyxPreconfirmationModel';
import type { LoadPreconfirmationEmailPreviewApi } from './preconfirmationEmailPreviewEpic';
import type { PreconfirmationEmailPreview } from './preconfirmationEmailPreviewModel';

export const createLoadPreconfirmationEmailPreviewApi = (
  http: SgmeHttp,
): LoadPreconfirmationEmailPreviewApi => ({
  loadPreconfirmationEmail(
    uuid: string,
    mailOptions: ExternalPreconfirmationRequest | InternalPreconfirmationRequest,
    destination: Destination,
    transaction: OnyxTransaction,
    ignoreFieldChanges: boolean,
  ) {
    const url: string = `api/workflow-neos/${uuid}/previewPreconfMail?mailType=${destination}`;
    return http.post<PreconfirmationEmailPreview>({
      url,
      body: {
        ignoreFieldChanges,
        mailOptions,
        mailType: destination,
        transaction,
      },
    });
  },
});
