import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { Observable } from 'rxjs';
import type { BlotterPreconfStatus } from '../blotterModel';
import type { LoadBlotterPreconfApi } from './getLoadBlotterPreconfEpic';

export const createBlotterPreconApi = (http: SgmeHttp): LoadBlotterPreconfApi => ({
  loadPreconfStatus(rfqIds: string[]): Observable<BlotterPreconfStatus[]> {
    return http.put<BlotterPreconfStatus[]>({
      url: 'api/booking/blotter',
      body: rfqIds,
    });
  },
});
