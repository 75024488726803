import type { Thunk } from '@/bootstrap/thunks';

export function createRfqChangeIsRfqContainingInvalidListedStrategyThunk(rfqId: string): Thunk {
  return function rfqChangeIsRfqContainingInvalidListedStrategyThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { rfqUiCrudActions },
      },
    },
  ) {
    const appState = getState();

    const { strategyIds } = selectors.getRfqData(appState, rfqId);
    const areValidStrategySizes = strategyIds
      .map(stratId => selectors.isValidStrategySize(appState, stratId, selectors))
      .every(isValidStrategySize => isValidStrategySize);
    const isRfqContainingInvalidListedStrategySize = !areValidStrategySizes;
    return dispatch(
      rfqUiCrudActions.patchOrInsert(rfqId, {
        isRfqContainingInvalidListedStrategySize,
      }),
    );
  };
}
