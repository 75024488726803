import type { Thunk } from '@/bootstrap/thunks';
import { flatMap, uniq } from 'lodash';
import type { PriceUnitType } from '../../../../neos/business/referenceData/referenceDataModel';
import { DefaultingScope } from '../models';
import { RfqUnitRecomputeType, getRfqRecomputeScope } from '../models/recomputeModel';
import { DefaultingOptions } from '../rfqActions';

export function createRfqChangePricesUnitThunk(
  rfqId: string,
  newPriceUnitType: PriceUnitType,
): Thunk {
  return function rfqChangePricesUnitThunk(
    dispatch,
    getState,
    {
      selectors: { getRfqData, getStrategyData, getStrategyDefinition },
      actionCreators: {
        neos: { createRecomputeRfqRequestedAction, createDefaultRfqRequestedAction },
      },
    },
  ) {
    const state = getState();
    const { strategyIds } = getRfqData(state, rfqId);
    const strategies = strategyIds.map(strategyId => getStrategyData(state, strategyId));

    const availablePriceUnitTypes = uniq(
      flatMap(
        strategies.map(
          ({ strategyType }) =>
            getStrategyDefinition(state.referenceData, strategyType).availablePriceUnitTypes,
        ),
      ),
    );

    if (
      availablePriceUnitTypes.length < 2 ||
      strategies.every(strategy => strategy.priceUnitType === newPriceUnitType)
    ) {
      return;
    }

    const recomputePriceUnitScope = getRfqRecomputeScope({
      scope: RfqUnitRecomputeType.UNIT,
      to: newPriceUnitType,
    });

    if (recomputePriceUnitScope !== undefined) {
      dispatch(
        createRecomputeRfqRequestedAction(rfqId, recomputePriceUnitScope, {
          success: {
            dispatchables: [
              createDefaultRfqRequestedAction(
                rfqId,
                new DefaultingOptions({
                  overrideScopes: [DefaultingScope.TRADED_AWAY_FEEDBACK],
                }),
              ),
            ],
          },
        }),
      );
    }
  };
}
