import type { OnyxLifecycle } from '@/neos/business/rfq/rfqOnyxModel';

export interface Version {
  version: number;
  tradeDate: string;
  lifecycle: OnyxLifecycle;
}

export interface Versions {
  versions: Version[];
}

export const defaultVersions = (): Versions => ({
  versions: [],
});
