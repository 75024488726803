import { DefaultingOptions } from '../rfqActions';

import { DefaultingScope } from '../models';
import { createDefaultRfqRequestedAction } from './defaultRfqRequestedAction';

export function createDefaultObservableTypeRequestedAction(rfqId: string) {
  const options = new DefaultingOptions({
    overrideScopes: [DefaultingScope.TRADER_DIFFUSION],
  });
  return createDefaultRfqRequestedAction(rfqId, options);
}
