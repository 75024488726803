import type { Action } from '@/bootstrap/actions.ts';
import type { Thunk } from '@/bootstrap/thunks.ts';
import type {
  LegPeriod,
  RateLegPeriodDates,
} from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel.ts';
import { isClsProduct, isElsProduct } from '../../../../../../../neosModel.ts';

export function createAddRateSchedulePeriodThunk(
  strategyId: string,
  datesUpdate: RateLegPeriodDates = {
    endDate: '',
    paymentDate: '',
    startDate: '',
    fixingDate: '',
  },
): Thunk {
  return function addRateSchedulePeriodThunk(
    dispatch,
    getState,
    {
      selectors,
      services: { idCreator },
      actionCreators: {
        neos: { productCrudActions },
      },
    },
  ) {
    const appState = getState();
    const product = selectors.getStrategyProduct(appState, strategyId, selectors);
    const hasRateOvernightFeature = selectors.getFeature(appState.featureState, {
      strategyId,
      type: 'RATE_OVERNIGHT',
    })?.rateOvernight;

    if (!isElsProduct(product) && !isClsProduct(product)) {
      throw new Error('product is neither an els nor a cls');
    }

    const newPeriod: LegPeriod<RateLegPeriodDates> = {
      uuid: idCreator.createId(),
      dates:
        isElsProduct(product) && datesUpdate.startDate == '' && hasRateOvernightFeature
          ? {
              ...datesUpdate,
              firstFixingDate: '',
            }
          : datesUpdate,
    };

    const action: Action = productCrudActions.update(product.uuid, {
      ratePeriods: [...(product.ratePeriods ?? []), newPeriod],
    });

    dispatch(action);
  };
}
