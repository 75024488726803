import { type ActionCreators, actionCreators } from '@/bootstrap/actions';
import type { ThunkEpic } from '@/bootstrap/epics';
import { type Thunks, thunks } from '@/bootstrap/thunks';
import type { OnyxError } from '@/neos/business/mappers/error';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { ofType } from 'redux-observable';
import { catchError, map, mergeMap } from 'rxjs/operators';
import type { PreconfirmationPrefsApi } from '../apiModel';
import { createPreconfirmationPreferencesApi } from '../preconfirmationPreferencesApi';

export function getPreconfirmationPreferenceLoadGroupsEpic(http: SgmeHttp) {
  const api = createPreconfirmationPreferencesApi(http);
  return createPreconfirmationPreferenceLoadGroupsEpic(api, thunks, actionCreators);
}

export function createPreconfirmationPreferenceLoadGroupsEpic(
  api: PreconfirmationPrefsApi,
  { createErrorToasterThunk }: Thunks,
  { neos: { receivedUserPrefPreconfGroupsActionCreator } }: ActionCreators,
): ThunkEpic {
  return action$ =>
    action$.pipe(
      ofType('USER_PREF_GROUPS_REQUESTED'),
      mergeMap(() =>
        api.getUserSavedGroups().pipe(
          map(groupKeys => receivedUserPrefPreconfGroupsActionCreator(groupKeys)),
          catchError((error: OnyxError) => [
            createErrorToasterThunk(
              {
                message: 'Error getting preconfirmation preference group',
              },
              error,
            ),
          ]),
        ),
      ),
    );
}
