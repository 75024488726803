import type { ChainOptions, RetryOnErrorOptions } from '@/neos/business/neosActions';
import type { DefaultRfqRequested, DefaultingOptions } from '../rfqActions';

export function createDefaultRfqRequestedAction(
  rfqId: string,
  options: DefaultingOptions,
  chainOptions?: ChainOptions,
  retryOnErrorOptions?: RetryOnErrorOptions,
): DefaultRfqRequested {
  return {
    type: 'DEFAULT_RFQ_REQUESTED',
    rfqId,
    options,
    chainOptions,
    retryOnErrorOptions,
  };
}
