import type { AppCrashedAction, GlobalErrorAction } from './globalErrorActions';
import type { GlobalErrors } from './globalErrorModel';

const TECHNICAL_ERROR: string = 'An error occured. Please contact support';

export function errorReducer(
  state: GlobalErrors = { sessionActive: true },
  action: GlobalErrorAction,
): GlobalErrors {
  if (action.type === 'APP_CRASHED') {
    return onAppCrashed(state, action);
  }
  if (action.type === 'CRITICAL_APP_CRASHED') {
    return {
      ...state,
      isCriticalAppCrash: true,
    };
  }
  if (action.type === 'SESSION_EXPIRED') {
    return {
      ...state,
      sessionActive: false,
    };
  }
  return state;
}

function onAppCrashed(state: GlobalErrors, appCrash: AppCrashedAction): GlobalErrors {
  if (!appCrash.userFeedback) {
    return state;
  }

  const isUnauthorized = appCrash.error?.data?.status === 401;
  if (isUnauthorized) {
    window.location.href = sgmeConfiguration.authorization.unauthorizedRedirectUrl;
    return state;
  }

  return {
    ...state,
    errorValue: TECHNICAL_ERROR,
  };
}
