import { convertNullToUndefined } from '@/util/undefinedAndNull/convertNullToUndefined';
import type { DescriptionFeature } from '../../../../neosModel';
import type { OnyxDescription, OnyxProductFeaturesFields } from '../../../../neosOnyxModel';

import { hasDiscriminator } from '@/neos/business/rfq/strategy/feature/hasDiscriminator';

export function mapFromOnyxFeatureOrExtraFeaturesToDescription(
  { extraFeatures, description }: OnyxProductFeaturesFields,
  strategyId: string,
): DescriptionFeature | undefined {
  const mappedDescription: Pick<DescriptionFeature, 'strategyId' | 'type'> = {
    strategyId,
    type: 'DESCRIPTION',
  };
  const desc = convertNullToUndefined(description);
  if (desc !== undefined) {
    return { ...mappedDescription, description };
  }

  if (extraFeatures) {
    const descriptionFeature: OnyxDescription | undefined = extraFeatures.find(
      hasDiscriminator<OnyxDescription>('DESCRIPTION'),
    );
    if (descriptionFeature) {
      return {
        ...mappedDescription,
        description: convertNullToUndefined(descriptionFeature.description),
      };
    }
  }
}
