import type { Thunk } from '@/bootstrap/thunks';
import type { ActionContainer } from '@/util/actionContainer';

export function createRfqSetStrategyAsMasterThunk(rfqId: string, strategyId: string): Thunk {
  return function rfqSetStrategyAsMasterThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { strategyDataCrudActions, rfqDataCrudActions },
      },
    },
  ) {
    const result: ActionContainer[] = [];
    const appState = getState();
    const masterStrategyId = selectors.getRfqMasterStrategy(appState, rfqId).uuid;

    if (strategyId === masterStrategyId) {
      return;
    }

    const notionalCurrency = selectors.getCurrencyByStrategyId(appState, strategyId, selectors);
    //TODO, will delete, should be done by onyx recomputer
    result.push(
      rfqDataCrudActions.update(rfqId, {
        notionalCurrency,
        notionalAmount: undefined,
        pivotNotionalAmount: undefined,
      }),
    );

    result.push(strategyDataCrudActions.update(strategyId, { isMasterStrategy: true }));
    result.push(strategyDataCrudActions.update(masterStrategyId, { isMasterStrategy: false }));
    dispatch(result);
  };
}
