export type Kinds =
  | 'SESSION'
  | 'SGCONNECT'
  | 'ERROR_BOUNDARY'
  | 'CLIPBOARD'
  | 'REQUEST'
  | 'REDUX_ACTION'
  | 'SIGNAL_R'
  | 'FRONT_ERROR'
  | 'REQUEST_STATUS'
  | 'DEBUG'
  | 'BACK_ERROR_MESSAGE'
  | 'BLOTTER'
  | 'CONFIG';

export interface LogTemplates {
  kind: Kinds;
  msgtemplate: string;
}

const TemplateKeysValue = [
  'sgconnectLog',
  'sgconnecterror',
  'errorBoundary',
  'alertClipboard',
  'alertErrorClipboard',
  'reduxAction',
  'reduxErrorAction',
  'unhandledException',
  'signalrLog',
  'signalrError',
  'ajaxLog',
  'session',
  'blotterNoMasterLeg',
  'debug',
  'backEndErrorMessage',
  'configSuccess',
  'configError',
] as const;

export type TemplateKeys = (typeof TemplateKeysValue)[number];

export const mt: { [k in TemplateKeys]: LogTemplates } = {
  session: { kind: 'SESSION', msgtemplate: 'Chrome version: {ChromeVersion}' },
  debug: { kind: 'DEBUG', msgtemplate: '{VariableName}: {VariableValue}' },
  sgconnectLog: { kind: 'SGCONNECT', msgtemplate: `SgConnect Log Message: {SgConnectMessage}` },
  sgconnecterror: {
    kind: 'SGCONNECT',
    msgtemplate: `Error SgConnect Log\nError Message: {ErrorMessage}\nError Object: {ErrorObject}`,
  },
  errorBoundary: {
    kind: 'ERROR_BOUNDARY',
    msgtemplate:
      'Unexpected error catched by Error Boundary\nError Message: {ErrorMessage}\nError Object: {ErrorObject}`',
  },
  alertClipboard: {
    kind: 'CLIPBOARD',
    msgtemplate: 'Alert Click. Source: {ClipbaordActionSource}\nRFQ ID: {RfqId}',
  },
  alertErrorClipboard: {
    kind: 'CLIPBOARD',
    msgtemplate:
      'Error Alert Click. Source: {ClipbaordActionSource}\nRFQ ID: {RfqId}\nError Message: {ErrorMessage}\nError Object: {ErrorObject}',
  },
  reduxAction: {
    kind: 'REDUX_ACTION',
    msgtemplate: 'Redux Action {ReduxActionName} dispatched.\nContent: {ReduxAction}',
  },
  reduxErrorAction: {
    kind: 'REDUX_ACTION',
    msgtemplate:
      'Error catched by dispatching APP_CRASHED\nError Message: {ErrorMessage}\nError Object: {ErrorObject}\nSource: {ErrorSource}',
  },
  unhandledException: {
    kind: 'FRONT_ERROR',
    msgtemplate:
      'UnhandledException happened \nError Message: {ErrorMessage}\nError Object: {ErrorObject}',
  },
  backEndErrorMessage: {
    kind: 'BACK_ERROR_MESSAGE',
    msgtemplate:
      'BackEnd Error Message  \nError Message: {ErrorMessage}\nError Object: {ErrorObject}',
  },
  signalrLog: { kind: 'SIGNAL_R', msgtemplate: 'Signalr Log Message: {SignalrLogMessage}' },
  signalrError: { kind: 'SIGNAL_R', msgtemplate: 'Signalr error: {ErrorMessage}' },
  ajaxLog: {
    kind: 'REQUEST_STATUS',
    msgtemplate: `ajax log:
  \nPath: {NeosRoutePath}
  \nIsTimeout: {IsTimeout}
  \nmethod: {AjaxMethod}
  \nDuration {AjaxDuration}
  \nRequestLength {AjaxRequestLength}
  \nResponseLength: {AjaxResponseLength}
  \nStatus: {Status}
  \nOnyxBridgeDuration: {OnyxBridgeDuration}
  \nFrontWebApiDuration: {FrontWebApiDuration}
  \nWebApiBridgeDuration: {WebApiBridgeDuration}`,
  },
  blotterNoMasterLeg: {
    kind: 'BLOTTER',
    msgtemplate: 'We should have a master leg! RFQ ID: {RfqId}',
  },
  configSuccess: {
    kind: 'CONFIG',
    msgtemplate: 'Successfully load configuration from server : {additionalConfig}',
  },
  configError: {
    kind: 'CONFIG',
    msgtemplate: 'Error loading configuration {error} correlationId: {correlationId}',
  },
};
