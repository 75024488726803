import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { OnyxCurrentUser } from '@/common/business/userInfo/userInfoModel';
import type { SubfilterType } from '@/neos/business/blotter/blotterModel';
import type { OnyxRfq } from '@/neos/business/rfq/rfqOnyxModel';
import type { BlotterModelGetters } from '.';

export function filterBlotterRows(
  state: AppState,
  selectors: Selectors,
  blotterModelGetters: BlotterModelGetters,
) {
  const allblotterRfqs = selectors.getBlotterRfqs(state.blotter);

  const userInfo = selectors.getCurrentUser(state);
  const subfilter = selectors.getBlotterRfqSubfilter(state.blotter);
  const subfilterPredicat = getSubfilterPredicat(userInfo, subfilter, blotterModelGetters);

  const rfqWithSubfilterApplied = subfilterPredicat
    ? allblotterRfqs.filter(subfilterPredicat)
    : allblotterRfqs;

  return rfqWithSubfilterApplied;
}

function getSubfilterPredicat(
  { sesameId, groupId }: OnyxCurrentUser,
  subfilter: SubfilterType,
  g: BlotterModelGetters,
): undefined | ((element: OnyxRfq) => boolean) {
  switch (subfilter) {
    case 'ALL':
      return undefined;
    case 'GROUP':
      return rfq => {
        const salesGroupId = rfq?.salesDiffusion?.salesGroup?.id;
        const traderGroupIds = g
          .getAllStrategiesExceptDeltaAdj(rfq)
          .map(({ traderDiffusion }) => traderDiffusion?.traderGroup?.id);
        return (
          groupId !== undefined &&
          (salesGroupId === groupId ||
            traderGroupIds.some(traderGroupId => traderGroupId === groupId))
        );
      };
    case 'OWN':
      return rfq => {
        const traderIds = g.getTraderIds(rfq, g);
        const salesValoId = rfq.salesDiffusion?.salesValo?.id;
        const salesInitId = rfq.salesDiffusion?.salesInit?.id;
        return (
          salesInitId === sesameId ||
          salesValoId === sesameId ||
          traderIds.some(traderId => traderId === sesameId)
        );
      };
  }
}
