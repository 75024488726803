import type { ThunkEpic } from '@/bootstrap/epics';
import { type Selectors, selectors } from '@/bootstrap/selectors';
import { type Thunks, thunks } from '@/bootstrap/thunks';
import { type ToOnyxMappers, mappers } from '@/neos/business/mappers';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, mapTo, mergeMap } from 'rxjs/operators';
import type { OnyxError } from '../mappers/error';
import { type TriggerMatchingApi, createTriggerMatchingApi } from './triggerMatchingApi';

export function getTriggerMatchingEpic(http: SgmeHttp) {
  const api = createTriggerMatchingApi(http);
  return createTriggerMatchingEpic(api, mappers.toOnyxMappers, thunks, selectors);
}

export function createTriggerMatchingEpic(
  api: TriggerMatchingApi,
  toOnyxMappers: ToOnyxMappers,
  { createSuccessToasterThunk, createErrorToasterThunk }: Thunks,
  selectorsParameters: Selectors,
): ThunkEpic {
  return (action$, state$) =>
    action$.pipe(
      ofType('TRIGGER_MATCHING'),
      mergeMap(({ rfqId }) => {
        const transaction = toOnyxMappers.mapToOnyxTransaction(
          state$.value,
          rfqId,
          selectorsParameters,
          toOnyxMappers,
        );
        return api.triggerMatching(rfqId, transaction).pipe(
          mapTo(
            createSuccessToasterThunk({
              message: 'Successfully triggered matching for strategy and/or delta.',
            }),
          ),
          catchError((error: OnyxError) =>
            of(
              createErrorToasterThunk(
                {
                  message: 'Error when triggering matching for strategy and/or delta.',
                },
                error,
              ),
            ),
          ),
        );
      }),
    );
}
