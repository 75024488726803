import { type ActionCreators, actionCreators } from '@/bootstrap/actions';
import type { ThunkEpic } from '@/bootstrap/epics';
import { type Thunks, thunks } from '@/bootstrap/thunks';
import { wrapInLoadingObservable } from '@/neos/business/epics/wrapInLoadingObservable';
import type { OnyxError } from '@/neos/business/mappers/error';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { type CurvesListApi, makeCurvesListApi } from './curvesListApi';

export function getCurvesListEpic(http: SgmeHttp) {
  const api = makeCurvesListApi(http);
  return createLoadCurvesListEpic(api, actionCreators, thunks);
}

export function createLoadCurvesListEpic(
  api: CurvesListApi,
  { neos: { createAddToCurrencyCurvesListAction } }: ActionCreators,
  { createErrorToasterThunk }: Thunks,
): ThunkEpic {
  return action$ =>
    action$.pipe(
      ofType('LOAD_CURVES_REQUESTED'),
      mergeMap(({ strategyData, strategyType }) => {
        return wrapInLoadingObservable({
          tabIds: [strategyData.rfqId],
          apiObservable: api.fetchCurvesList(strategyData.currency, strategyType),
          onSuccess: currencyCurvesList =>
            createAddToCurrencyCurvesListAction(strategyData.currency, currencyCurvesList),
          onError: (error: OnyxError) => [
            createErrorToasterThunk(
              {
                message: 'Error when loading curves list',
              },
              error,
            ),
          ],
        });
      }),
    );
}
