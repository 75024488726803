import type { OnyxAlert } from '@/neos/business/alert/alertOnyxModel';
import type { DisplayTimeZone } from '@/neos/business/ui/userPreferences/userPreferencesUiModel';
import { formatDateInTimeZone } from '@/util/date/dateFormatHelper';
import type { ColDef } from '@ag-grid-community/core';

interface AlertColDef extends ColDef {
  field: keyof OnyxAlert;
}

export function getAlertBlotterColumnDefinition(
  displayTimezone: DisplayTimeZone,
): Array<AlertColDef> {
  return [
    {
      headerName: 'Warning date time',
      field: 'alertDate',
      cellRenderer: (param: { value: string | undefined }) =>
        formatDate(param.value, displayTimezone),
      width: 170,
      sort: 'asc',
    },
    { headerName: 'Warning category', field: 'level', width: 150 },
    { headerName: 'Alert message', field: 'message', width: 528 },
  ];
}

function formatDate(date: string | undefined, displayTimezone: DisplayTimeZone) {
  return date ? formatDateInTimeZone(displayTimezone, date) : '';
}
