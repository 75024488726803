import type {
  DefaultProductValuesForStrategy,
  DivSwap,
  Product,
} from '../../../../../../../../../neos/business/neosModel';
import {
  getCommonPart,
  getDerivativePart,
  getOtcNegotiationPart,
} from './getDefaultedPartialProduct';

export function getDefaultedDivSwapProduct(
  currentProduct: Product,
  defaultValues: DefaultProductValuesForStrategy,
): DivSwap {
  return {
    subFamily: 'DIV_SWAP',
    hasCustomUnderlying: false,
    description: currentProduct.subFamily === 'DIV_SWAP' ? currentProduct.description : undefined,
    startDate: currentProduct.subFamily === 'DIV_SWAP' ? currentProduct.startDate : undefined,
    ...getCommonPart(currentProduct, defaultValues),
    ...getOtcNegotiationPart(),
    ...getDerivativePart(currentProduct, defaultValues.maturity, defaultValues.underlying),
  };
}
