import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import {
  QuoteTypeComponent,
  type QuoteTypeDispatchProps,
  type QuoteTypeMapStateToProps,
  type QuoteTypeOwnProps,
} from './QuoteType';
const { getRfqData, isReadOnlyRfq } = selectors;

export const QuoteType = connect(mapStateToProps, mapDispatchToProps)(QuoteTypeComponent);

function mapStateToProps(state: AppState, { rfqId }: QuoteTypeOwnProps): QuoteTypeMapStateToProps {
  const { quoteType } = getRfqData(state, rfqId);
  return { quoteType, isReadonlyRfq: isReadOnlyRfq(state, rfqId) };
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId }: QuoteTypeOwnProps,
): QuoteTypeDispatchProps {
  return {
    onQuoteTypeChanged() {
      dispatch(neosThunks.createRfqChangeQuoteTypeThunk(rfqId));
    },
  };
}
