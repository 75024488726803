import {
  type BlotterColDef,
  GridColumnDefinition,
  type GridColumnDefinitionParams,
  getGridColumnDefinitionWithHeaderNames,
} from '../../../share/blotterGrid';

export const getBlotterColDefs = (colDefParams: GridColumnDefinitionParams): BlotterColDef[] => {
  const { columns: c } = new GridColumnDefinition(colDefParams);
  const resultWithoutHeaderNames: BlotterColDef[] = [
    c.statusLabel(true),
    c.tradeDate(),
    c.salesCounterpartyName(),
    c.clientWay(),
    c.masterQuantity(),
    c.underlyingBloombergCodes(),
    c.allStrategyTypes(),
    c.legMaturities(),
    c.legStrikes(),
    c.rfqBid(),
    c.rfqAsk(),
    c.comment(),
    c.bookingInfo(),
  ];

  return getGridColumnDefinitionWithHeaderNames(resultWithoutHeaderNames);
};
