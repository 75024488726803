import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import {
  type ExecutionType,
  type UnderlyingType,
  isUnderlyingIndexType,
} from '@/neos/business/neosOnyxModel';
import type { HighlightedValue } from '@/neos/business/ui/reference/referenceUiModel';

interface HighlightedValueModel {
  warningMessage: string | undefined;
  value: number | undefined;
}

export interface UnderlyingModel {
  underlyingId: string;
  underlyingBloombergCode: string | undefined;
  refSpot: HighlightedValueModel;
  refLevel: HighlightedValueModel;
  basis: HighlightedValueModel;
  refCurrency: string;
  refType: UnderlyingType | undefined;
  isReadonly: boolean;
  isRfqLevelLightFontEnabled: boolean | undefined;
  executionType: ExecutionType;
}

type UnderlyingModelRefProps = Pick<UnderlyingModel, 'basis' | 'refSpot' | 'refLevel'>;

export interface UnderlyingReferenceModel {
  underlyingsModel: UnderlyingModel[];
  hasRefSpotBeenChanged: boolean;
  hasLevelBeenChanged: boolean;
  hasBasisBeenChanged: boolean;
  hasMasterStrategyBeenLoadedWithEmptyReferenceFields: boolean;
}

export function getUnderlyingReferenceModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): UnderlyingReferenceModel {
  const references = selectors.getNotAggregatedReferences(state, rfqId, selectors);
  const isReadonlyRfq = selectors.isReadOnlyRfq(state, rfqId);
  const isReadOnlyAtCurrentWorkflow = selectors.isRfqReadOnlyAtCurrentWorkflow(
    state,
    rfqId,
    selectors,
  );
  const masterProduct = selectors.getRfqMasterProduct(state, rfqId, selectors);
  const {
    executionType,
    hasBeenLoadedWithEmptyReferenceFields: hasMasterStrategyBeenLoadedWithEmptyReferenceFields,
  } = selectors.getRfqMasterStrategy(state, rfqId);

  const underlyingsModel = references.map((reference): UnderlyingModel => {
    const underlyingInfo = selectors.getUnderlyingInfo(state, reference.underlyingId);
    const referenceUi = selectors.getReferenceUiByRfqIdAndUnderlyingId(
      state.ui,
      rfqId,
      reference.underlyingId,
    );

    const refValues: UnderlyingModelRefProps = {
      basis: mapToHighlightedValueModel(reference.refBasis, referenceUi?.refBasis, 'basis'),
      refLevel: mapToHighlightedValueModel(reference.refLevel, referenceUi?.refLevel, 'level'),
      refSpot: mapToHighlightedValueModel(reference.refSpot, referenceUi?.refSpot, 'spot'),
    };

    const isRfqLevelLightFontEnabled = masterProduct.subFamily === 'DIVIDEND_FUTURE';

    const isReadonly = isReadonlyRfq || isReadOnlyAtCurrentWorkflow;

    if (!underlyingInfo) {
      return {
        isRfqLevelLightFontEnabled,
        isReadonly,
        refType: undefined,
        underlyingId: reference.underlyingId,
        underlyingBloombergCode: undefined,
        refCurrency: '---',
        executionType,
        ...refValues,
      };
    }

    return {
      isRfqLevelLightFontEnabled,
      isReadonly,
      refType: underlyingInfo.type,
      underlyingId: underlyingInfo.id,
      underlyingBloombergCode: underlyingInfo.bloombergCode,
      refCurrency: underlyingInfo.tradingPence ? 'GBp' : underlyingInfo.currency,
      executionType,
      ...refValues,
    };
  });

  const hasRefSpotBeenChanged = underlyingsModel.some(model => !!model.refSpot.warningMessage);
  const hasLevelBeenChanged = underlyingsModel.some(model =>
    model.refType && !isUnderlyingIndexType(model.refType)
      ? !!model.refSpot.warningMessage
      : !!model.refLevel.warningMessage,
  );
  const hasBasisBeenChanged = underlyingsModel.some(model => !!model.basis.warningMessage);

  return {
    underlyingsModel,
    hasRefSpotBeenChanged,
    hasLevelBeenChanged,
    hasBasisBeenChanged,
    hasMasterStrategyBeenLoadedWithEmptyReferenceFields,
  };
}

function mapToHighlightedValueModel(
  currentRefValue: number | undefined,
  notificationRefValue: HighlightedValue | undefined,
  refProp: 'spot' | 'level' | 'basis',
): HighlightedValueModel {
  if (!notificationRefValue) {
    return {
      value: currentRefValue,
      warningMessage: undefined,
    };
  }
  const { isWarningDisplayed, newValue, oldValue } = notificationRefValue;
  return {
    value: currentRefValue,
    warningMessage: isWarningDisplayed
      ? `Ref ${refProp} has been changed ! ${
          currentRefValue === oldValue ? `New value: ${newValue}` : `Old value: ${oldValue}`
        }`
      : undefined,
  };
}
