import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ForexConstat, ForexType } from '../../../../../../../neos/business/neosModel';
import type { FeatureComponentModel } from '../sharedFeatureComponentModel';

export interface ForexTypeFields {
  initialFxConstat: ForexConstat | undefined;
  finalFxConstat: ForexConstat | undefined;
  availableForexConstats: Array<ForexConstat | undefined>;
  forexType: ForexType | undefined;
  availableForexTypes: Array<ForexType | undefined>;
}

export type ForexTypeModel = FeatureComponentModel<ForexTypeFields>;

export function getForexTypeModel(
  state: AppState,
  strategyId: string,
  selectors: Selectors,
): ForexTypeModel {
  const { strategyType } = selectors.getStrategyData(state, strategyId);
  const featureDefinition = selectors.getSingleStrategyDefinitionFeature(
    state.referenceData,
    strategyType,
    'FOREX_TYPE',
  );

  if (!featureDefinition) {
    return { isDisplayed: false };
  }

  const feature = selectors.getFeature(state.featureState, {
    strategyId,
    type: 'FOREX_TYPE',
  });

  const forexType = feature?.forexType;
  const initialFxConstat = feature?.initialForexConstat;
  const finalFxConstat = feature?.finalForexConstat;
  const availableForexConstats = getAvailableForexConstats();

  return {
    isDisplayed: true,
    initialFxConstat,
    finalFxConstat,
    availableForexConstats,
    forexType,
    availableForexTypes: getAvailableForexTypes(),
  };
}

function getAvailableForexTypes(): Array<ForexType | undefined> {
  return [undefined, 'QUANTO', 'COMPO'];
}

function getAvailableForexConstats(): Array<ForexConstat | undefined> {
  return [
    undefined,
    'BANK_OF_CANADA_NOON_FIXING',
    'BCE_FIXING',
    'BEST_EFFORT',
    'NONE',
    'WM_COMPANY',
  ];
}
