import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import type { Thunk } from '@/bootstrap/thunks';

export function createChangeSalesLocationThunk(rfqId: string, location: string): Thunk {
  return function changeSalesLocationThunk(
    dispatch,
    getState,
    {
      selectors: { getSalesLocations, getSalesPhysicalLocation },
      actionCreators: {
        neos: { actorsSetupCrudActions },
      },
    },
  ) {
    const appState = getState();
    const salesLocations = getSalesLocations(appState.referenceData);
    const newSalesLocation = salesLocations.find(salesLocation => salesLocation.name === location);
    const oldPhysicalLocation = getSalesPhysicalLocation(appState, rfqId);

    return dispatch(
      createLogAnalyticsAction('NEOS RFQ', `Add Rfq sales location`),
      actorsSetupCrudActions.update(rfqId, {
        salesInitCityLocation: location,
        salesPhysicalLocation: newSalesLocation?.countryCode
          ? newSalesLocation.countryCode
          : oldPhysicalLocation,
      }),
    );
  };
}
