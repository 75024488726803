import type { FromOnyxMappers } from '@/neos/business/mappers';
import { convertNullToUndefined } from '@/util/undefinedAndNull/convertNullToUndefined';
import type { EuropeanOption } from '../../productModel';
import type { OnyxSingleUnderlyingProduct } from '../../productOnyxModel';

export function mapFromOnyxProductEuropeanOption(
  legId: string,
  onyxProduct: OnyxSingleUnderlyingProduct,
  productId: string,
  mappers: FromOnyxMappers,
): EuropeanOption {
  const { upperStrike, lowerStrike, strikeUnit } = mappers.mapFromOnyxUpperLowerStrike(onyxProduct);
  return {
    subFamily: 'EUROPEAN_SPREAD_OPTION',
    upperStrike,
    lowerStrike,
    strikeUnit,
    style: convertNullToUndefined(onyxProduct.optionStyle),
    type: onyxProduct.optionType || undefined,
    flex: onyxProduct.optionFlexType || undefined,
    ...mappers.mapFromOnyxProductCommon(legId, onyxProduct, productId),
    ...mappers.mapFromOnyxNegotiation(onyxProduct.negotiation),
    ...mappers.mapFromSingleDerivativeOnyxProduct(onyxProduct, mappers),
    hasCustomUnderlying: false,
  };
}
