import type { Thunk } from '@/bootstrap/thunks';
import { flatMap } from 'lodash';
import type { ReferenceKey } from '../models';

export function createRfqResetQuotesWithGivenUnderlyingThunk({
  rfqId,
  underlyingId,
}: ReferenceKey): Thunk {
  return function rfqResetQuotesWithGivenUnderlyingThunk(
    dispatch,
    getState,
    {
      selectors,
      thunks: {
        neos: { createRfqResetStrategyQuoteThunk },
      },
    },
  ) {
    const appState = getState();
    const { strategyIds } = selectors.getRfqData(appState, rfqId);
    const toDeleteQuoteStrategyIds = flatMap(strategyIds, strategyId => {
      return selectors
        .getAllDistinctUnderlyingAndRefIdsOfStrategy(appState, strategyId, selectors)
        .filter(udlId => udlId === underlyingId)
        .map(_ => strategyId);
    });

    toDeleteQuoteStrategyIds.forEach(strategyId =>
      dispatch(createRfqResetStrategyQuoteThunk(rfqId, strategyId)),
    );
  };
}
