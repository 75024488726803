import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ElsSection } from '@/neos/business/rfq/strategy/feature/elsSections/elsSectionsModel';
import { createUpdateElsSectionHeightThunk } from '@/neos/business/rfq/strategy/feature/elsSections/updateElsSectionHeightThunk';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { gridUnitHeight } from './ElsFeatures';

export const useUpdateSectionHeight = (strategyId: string, sectionName: ElsSection) => {
  const dispatch = useDispatch();
  const [height, setHeight] = useState<number>();
  const ref = useRef<HTMLDivElement>(null);

  const resize = (height: number) => {
    const newHeight = Math.floor(height / (gridUnitHeight + 5)) + 2;
    dispatch(createUpdateElsSectionHeightThunk(newHeight, strategyId, sectionName));
  };

  const section = useSelector(
    (state: AppState) =>
      selectors
        .getFeatureToggledElsSections(state, strategyId, selectors)
        .find(s => s.i === sectionName),
    (a, b) => {
      if (a && b) {
        return a.h === b.h && a.w === b.w;
      }
      return false;
    },
  );

  useEffect(() => {
    if (ref.current?.clientHeight) {
      resize(ref.current?.clientHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (ref.current?.clientHeight !== height && ref.current?.clientHeight) {
      setHeight(ref.current?.clientHeight);
      resize(ref.current?.clientHeight);
    }
  });

  return { ref, resize };
};
