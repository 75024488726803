import { NeosBlurInput } from '@/neos/components/share/blurComponent/NeosBlurInput';
import { NumericInput } from '@/neos/components/share/numericInput';
import { FormSelect } from 'react-bootstrap';
import type { DeltaScopeModel, RfqScopeModel, ScopeType } from '../scopeModel';

import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { PrimeBroker } from '@/neos/business/referenceData/primeBroker/primeBrokerActions';
import {
  type CommissionType,
  CommissionTypeValues,
} from '@/neos/business/rfq/strategy/leg/listedAllocation/listedAllocationModel';
import { useSelector } from 'react-redux';
import { getCommissionsModel } from '../../allocs/listedExecutions/legExecution/executionAllocations/getCommissionsModel';
import { getPrimeBrokersModel } from '../../allocs/listedExecutions/legExecution/executionAllocations/getPrimeBrokersModel';
import type { ListedPreAllocationsModel } from './getModel/getGenericListedPreAllocationsModel';

import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import styles from './CommonListedPreAllocations.module.scss';

export interface CommonListedPreAllocationsOwnProps {
  rfqId: string;
}

export interface CommonListedPreAllocationsStateProps {
  model: ListedPreAllocationsModel;
}

export interface CommonListedPreAllocationsDispatchProps {
  onClearerAccountChanged: (index: number, clearerAccount: string | undefined) => void;
  onRatioChanged: (index: number, ratio: number | undefined) => void;
  onPreAllocationRemoved: (index: number) => void;
  onPreAllocationAdded: () => void;
  onBrokerChanged: (index: number, broker: PrimeBroker | undefined) => void;
  onCommissionChanged: (index: number, value: number | undefined) => void;
  onCommissionTypeChanged: (index: number, value: CommissionType | undefined) => void;
}

type ListedPreAllocationsProps = CommonListedPreAllocationsOwnProps &
  CommonListedPreAllocationsStateProps &
  CommonListedPreAllocationsDispatchProps;

export type RfqListedPreAllocationsProps = ListedPreAllocationsProps & RfqScopeModel;

export type DeltaListedPreAllocationsProps = ListedPreAllocationsProps & DeltaScopeModel;

type CommonListedPreAllocationsProps =
  | RfqListedPreAllocationsProps
  | DeltaListedPreAllocationsProps;

const Ratios = ({
  scope,
  model: { allocations, ratiosTooltipInfo },
  onRatioChanged,
}: {
  scope: ScopeType;
  model: ListedPreAllocationsModel;
  onRatioChanged: CommonListedPreAllocationsDispatchProps['onRatioChanged'];
}) => {
  return (
    <SimpleNeosTooltip
      disable={!ratiosTooltipInfo}
      type={ratiosTooltipInfo?.type}
      id="listed-pre-allocations-ratios"
      message={ratiosTooltipInfo?.message}
    >
      <div
        className={`${
          ratiosTooltipInfo?.ratioStyleName ? styles[ratiosTooltipInfo?.ratioStyleName] : ''
        }`}
      >
        {allocations.map(({ ratio, clearerAccount, isRatioInvalid, allocationIndex }) => (
          <NumericInput
            key={`${clearerAccount}_${allocationIndex}`}
            value={ratio}
            unit="%"
            onBlur={val => onRatioChanged(allocationIndex, val)}
            disableAccelerators
            onlyPositiveNumbers
            className={isRatioInvalid ? 'errorable-bloc field-error' : undefined}
            data-e2e={`listed-${scope.toLowerCase()}-prealloc-ratio`}
          />
        ))}
      </div>
    </SimpleNeosTooltip>
  );
};

export const CommonListedPreAllocationsComponent = ({
  model,
  scope,
  rfqId,
  onClearerAccountChanged,
  onRatioChanged,
  onPreAllocationRemoved,
  onPreAllocationAdded,
  onBrokerChanged,
  onCommissionChanged,
  onCommissionTypeChanged,
}: CommonListedPreAllocationsProps) => {
  const areCommissionFieldsDisplayed = useSelector((state: AppState) =>
    getCommissionsModel(state, rfqId, selectors),
  );
  const { primeBrokers, arePrimeBrokersDisplayed } = useSelector((state: AppState) =>
    getPrimeBrokersModel(state, rfqId, selectors),
  );

  if (!model.isApplicable) {
    return null;
  }

  return (
    <div className={`${styles['pre-allocations-content']} my-2 overflow-auto`}>
      <div
        className={`${styles['allocation-row']}`}
        style={{
          gridTemplateColumns: `32px ${
            arePrimeBrokersDisplayed ? 'minmax(300px, 350px)' : ''
          } 200px 65px 150px 150px`,
        }}
      >
        <section>
          <button
            className="btn btn-icon btn-flat-primary"
            onClick={() => onPreAllocationAdded()}
            data-e2e={`add-listed-${scope.toLowerCase()}-prealloc`}
          >
            <i className="icon icon-md">add</i>
          </button>
          {model.allocations.map(({ clearerAccount, allocationIndex }) => (
            <button
              key={`${clearerAccount}_${allocationIndex}`}
              className="btn btn-icon btn-flat-primary"
              onClick={() => onPreAllocationRemoved(allocationIndex)}
              data-e2e={`remove-listed-${scope.toLowerCase()}-prealloc`}
            >
              <i className="icon icon-md">delete_forever</i>
            </button>
          ))}
        </section>

        {arePrimeBrokersDisplayed && primeBrokers && (
          <section>
            <label className="mb-2 mt-1">Clearer for Listed</label>
            {model.allocations.map(({ broker, allocationIndex }) => (
              <FormSelect
                key={allocationIndex}
                value={broker?.id ?? ''}
                onChange={event =>
                  onBrokerChanged(
                    allocationIndex,
                    primeBrokers.find(b => b.id === +event.target.value),
                  )
                }
                data-e2e="pre-allocation-clearer"
              >
                <option value=""></option>
                {primeBrokers.map(availableBroker => (
                  <option key={availableBroker.id} value={availableBroker.id}>
                    {availableBroker.name}
                  </option>
                ))}
              </FormSelect>
            ))}
          </section>
        )}

        <section>
          <label className="mb-2 mt-1">Clearer Account for Listed</label>
          {model.allocations.map(({ clearerAccount, allocationIndex }) => (
            <NeosBlurInput
              key={`${clearerAccount}_${allocationIndex}`}
              type="text"
              value={clearerAccount ?? ''}
              onBlur={event =>
                onClearerAccountChanged(allocationIndex, event.target.value || undefined)
              }
              data-e2e={`listed-${scope.toLowerCase()}-prealloc-clearer-account`}
            />
          ))}
        </section>

        <section>
          <label className="mb-2 mt-1">Ratio</label>
          <Ratios scope={scope} model={model} onRatioChanged={onRatioChanged} />
        </section>

        {areCommissionFieldsDisplayed && (
          <>
            <section>
              <label className="mb-2 mt-1">Com.</label>
              {model.allocations.map(({ commission, allocationIndex }) => (
                <NumericInput
                  key={allocationIndex}
                  value={commission}
                  onBlur={value => onCommissionChanged(allocationIndex, value)}
                  data-e2e="pre-allocation-commission"
                />
              ))}
            </section>
            <section>
              <label className="mb-2 mt-1">Com. Type</label>
              {model.allocations.map(({ commissionType, allocationIndex }) => (
                <NeosSelect
                  key={allocationIndex}
                  value={commissionType}
                  onChange={value => onCommissionTypeChanged(allocationIndex, value)}
                  data-e2e="pre-allocation-commission-type"
                  addEmptyOption
                  options={CommissionTypeValues}
                />
              ))}
            </section>
          </>
        )}
      </div>
    </div>
  );
};
