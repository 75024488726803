import type { Action } from '@/bootstrap/actions';
import { LogLevel } from '@microsoft/signalr';
import { type Observable, type SchedulerLike, Subject, type Subscription } from 'rxjs';
import { bufferTime, catchError, concatMap, delay, filter, mergeMap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import type { SupportedVerb } from '../http/sgmeAjaxHttpRequest';
import type { SgmeHttp } from '../http/sgmeHttpBase';
import { retryWithDelay } from '../rxFunctionsAndOperators/retryWithDelay';
import type { LogTemplates } from './messageTemplates';

export { LogLevel };

export interface AsyncLoggerConfig {
  logUrl: string;
  pushTimeout: number;
  maxLogEntrySize: number;
  maxLogBufferSize: number;
  bufferTimeSpan: number;
  maxRetryCount: number;
  retryDelay: number;
  logDelay: number;
}

export type Domain = 'NEOS';

export type LogForPost = Omit<LogParameters, 'content'> & {
  index: number;
  timestamp: string;
  message: string | undefined;
  userAgent: string;
  chromeVersion: string | undefined;
};
export interface StructuredLog {
  '@timestamp': string;
  level: string;
  messageTemplate: string;
  parameters: (string | number | boolean | undefined)[];
  loggingSessionId: string;
  index: number;
}

const loggingSessionId = uuidv4();
export class AsyncLogger {
  public logObservable$: Observable<number[]>;
  private logSubject: Subject<StructuredLog>;
  private subscription: Subscription;
  private counter: number = 0;

  constructor(
    sgmeHttp: SgmeHttp,
    private config: AsyncLoggerConfig,
    scheduler?: SchedulerLike,
  ) {
    this.logSubject = new Subject<StructuredLog>();
    const {
      logUrl,
      bufferTimeSpan,
      maxLogBufferSize,
      pushTimeout,
      maxRetryCount,
      retryDelay,
      logDelay,
    } = config;

    this.logObservable$ = this.logSubject.asObservable().pipe(
      bufferTime(bufferTimeSpan, null, maxLogBufferSize, scheduler),
      filter(buffer => buffer.length > 0),
      concatMap(logs => {
        return sgmeHttp
          .post<unknown>({
            url: logUrl,
            body: logs,
            headers: { 'x-correlation-id': uuidv4() },
            timeout: pushTimeout,
            shouldNotLog: true,
          })
          .pipe(
            mergeMap(() => [logs.map(log => log.index)]),
            retryWithDelay(maxRetryCount, retryDelay, scheduler),
            catchError(() => []),
            delay(logDelay, scheduler),
          );
      }),
    );

    this.subscription = this.logObservable$.subscribe();
  }

  public scheduleStop() {
    this.subscription.unsubscribe();
  }

  debug = (
    logTemplates: LogTemplates,
    showLogInConsoleAndKibana: boolean,
    ...parameters: any[]
  ) => {
    if (showLogInConsoleAndKibana) {
      // eslint-disable-next-line no-console
      console.log('DEBUG_MESSAGE', parameters);
      return this.log({
        level: 'Information',
        logTemplates,
        parameters,
      });
    }
    return this.log({
      level: 'Debug',
      logTemplates,
      parameters,
    });
  };

  info = (logTemplates: LogTemplates, ...parameters: any[]) => {
    return this.log({
      level: 'Information',
      logTemplates,
      parameters,
    });
  };
  warn = (logTemplates: LogTemplates, ...parameters: any[]) => {
    return this.log({
      level: 'Warning',
      logTemplates,
      parameters,
    });
  };
  error = (logTemplates: LogTemplates, ...parameters: Array<any>) => {
    // eslint-disable-next-line no-console
    console.error(
      `[logger.error] ${logTemplates.kind}:\n${logTemplates.msgtemplate}\n`,
      parameters,
    );

    return this.log({
      level: 'Error',
      logTemplates,
      parameters,
    });
  };

  private log = (logParams: LogParametersEx) => {
    const { level = 'Information', logTemplates, parameters } = logParams;

    const sanitizedParameters = parameters.map(param => {
      if ((param ?? undefined) === undefined) {
        return undefined;
      }

      const sanitized =
        typeof param === 'object'
          ? param instanceof Error
            ? JSON.stringify({ name: param.name, message: param.message, stack: param.stack })
            : JSON.stringify(param)
          : param;

      if (typeof sanitized === 'string' && sanitized.length >= this.config.maxLogEntrySize) {
        return sanitized.slice(0, this.config.maxLogEntrySize) + '... [LogEntry Tuncated]';
      }
      return sanitized;
    });

    const kind = logTemplates.kind;
    const messageTemplate = `Kind: {Kind}\n${logTemplates.msgtemplate}`;

    const stuctured: StructuredLog = {
      '@timestamp': new Date().toISOString(),
      level,
      loggingSessionId,
      messageTemplate,
      parameters: [kind, ...sanitizedParameters],
      index: ++this.counter,
    };
    if (!this.logSubject.isStopped) {
      this.logSubject.next(stuctured);
    }
  };
}

export type LogLevels = 'Debug' | 'Information' | 'Warning' | 'Error';
interface LogParametersEx {
  logTemplates: LogTemplates;
  parameters: any[];
  level?: LogLevels;
}

interface BaseLogParameters {
  content: unknown;
  level?: LogLevel;
}

interface ReduxLogParameters extends BaseLogParameters {
  kind: 'REDUX_ACTION';
  reduxActionType: Action['type'];
}

interface OtherLogParameters extends BaseLogParameters {
  kind: 'SGCONNECT' | 'FRONT_ERROR' | 'ERROR_BOUNDARY' | 'SIGNAL_R' | 'CLIPBOARD';
}

export interface BaseRequestStatusLogParameters {
  method: SupportedVerb;
  path: string;
  duration: number;
  requestLength: number | undefined;
}

interface TimeoutRequestStatusLogParameters extends BaseRequestStatusLogParameters {
  isTimeout: true;
}

export interface NonTimeoutRequestStatusLogParameters extends BaseRequestStatusLogParameters {
  isTimeout: false;
  responseLength: number;
  status: number | undefined;
  onyxBridgeDuration: number | undefined;
  frontWebApiDuration: number | undefined;
}

export type RequestStatusLogParameters =
  | TimeoutRequestStatusLogParameters
  | NonTimeoutRequestStatusLogParameters;

export type LogParameters = ReduxLogParameters | OtherLogParameters | RequestStatusLogParameters;
