import type { ActionContainer } from '@/util/actionContainer';
import { flatMap } from 'lodash';
import { deltaStockListedAllocationActionCreators } from './deltaStockListedAllocation/deltaStockListedAllocationActionCreators';
import type { ReferenceMaturitiesRequested } from './legActions';
import { legDataActionCreators } from './legData/legDataActionCreators';
import { defaultLegData } from './legModel';
import { listedAllocationActionCreators } from './listedAllocation/listedAllocationActionCreators';
import { otcAllocationActionCreators } from './otcAllocation/otcAllocationActionCreators';
import { productActionCreators } from './product/productActionCreators';
import {
  type DeliveryType,
  type Listed,
  type NegotiationMode,
  type Otc,
  type Product,
  defaultProduct,
} from './product/productModel';

export const legActionCreators = {
  ...legDataActionCreators,
  ...productActionCreators,
  ...listedAllocationActionCreators,
  ...deltaStockListedAllocationActionCreators,
  ...otcAllocationActionCreators,
  createLegsInitializedAction,
  createProductLoadedAction,
  createDeliveryTypeChangedAction,
  createLegLegsToStrategiesAction,
  createLegProductsNegotiationModeUpdatedAction,
  createReferenceMaturitiesRequestedAction,
};

function createLegsInitializedAction(strategyId: string, legsIds: string[]): ActionContainer {
  return flatMap(legsIds, legId => [
    legDataActionCreators.legDataCrudActions.insert(
      legId,
      defaultLegData(strategyId, legId, legId === legsIds[0]),
    ),
    productActionCreators.productCrudActions.insert(legId, defaultProduct(legId, legId)),
  ]);
}

function createProductLoadedAction(product: Product): ActionContainer {
  return productActionCreators.productCrudActions.upsert(product.uuid, product);
}

function createDeliveryTypeChangedAction(
  legsIds: string[],
  deliveryType: DeliveryType | undefined,
): ActionContainer {
  return legsIds.map(legId =>
    productActionCreators.productCrudActions.update(legId, { deliveryType }),
  );
}

function createLegLegsToStrategiesAction(legsIds: string[]): ActionContainer {
  return legsIds.map(legId =>
    legDataActionCreators.legDataCrudActions.update(legId, { weight: 1, isMaster: true }),
  );
}

function createLegProductsNegotiationModeUpdatedAction(
  productIds: string[],
  negotiationMode: NegotiationMode,
): ActionContainer {
  const productPatch: Partial<Listed<Product>> | Partial<Otc<Product>> =
    negotiationMode === 'LISTED' ? { negotiationMode } : { negotiationMode, lotSize: 1 };
  return productIds.map(productId =>
    productActionCreators.productCrudActions.update(productId, productPatch),
  );
}

function createReferenceMaturitiesRequestedAction(
  rfqId: string,
  strategyIds: string[],
  underlyingId: string,
): ReferenceMaturitiesRequested {
  return {
    type: 'REFERENCE_MATURITIES_REQUESTED',
    rfqId,
    strategyIds,
    underlyingId,
  };
}
