import { type ChangeEvent, Component, type RefObject, createRef } from 'react';

export interface Props {
  value?: number;
  hasFocus?: boolean;
  disabled?: boolean;
  inputClassName?: string;
  style?: any;
  readOnly?: boolean;
  reset?: number;
  ['data-e2e']?: string;
  onClick?: () => void;
  onFocus: () => void;
  isInteger?: boolean;

  onChange(value: string | undefined): void;

  onBlur?(value: number | undefined): void;
}

interface State {
  propsValue?: number;
  inputValue?: string;
  reset?: number;
}

const decimalOrAcceleratorRegex = new RegExp('^-?(\\d*[.]?\\d*)?[kKmM]?');
const integerOrAcceleratorRegex = new RegExp('^-?(\\d*)[kKmM]?');

const convertNumericalValueToString = (value: number | undefined): string => {
  return value === undefined ? '' : value.toString();
};

export class BaseNumericInput extends Component<Props, State> {
  private inputRef: RefObject<HTMLInputElement>;

  public static getDerivedStateFromProps = (props: Props, state: State): State | null => {
    if (props.value !== state.propsValue || props.reset !== state.reset) {
      return {
        ...state,
        propsValue: props.value,
        inputValue: convertNumericalValueToString(props.value),
        reset: props.reset,
      };
    }
    return null;
  };

  constructor(props: Props) {
    super(props);
    this.inputRef = createRef();
    this.state = {
      propsValue: props.value,
      inputValue: convertNumericalValueToString(props.value),
      reset: props.reset,
    };
  }

  public componentDidMount = (): void => {
    if (this.inputRef.current && this.props.hasFocus) {
      this.inputRef.current.focus();
      this.inputRef.current.select();
    }
  };

  private onInputClick = (): void => {
    if (this.inputRef.current) {
      this.inputRef.current.select();
    }
    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  private onInputFocus = (): void => {
    this.props.onFocus();
  };

  private onInputValueChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const inputValue: string = this.extractDecimalOrAcceleratorCharacters(event.target.value || '');

    this.setState(
      {
        ...this.state,
        inputValue,
      },
      () => this.props.onChange(inputValue),
    );
  };

  private onInputBlur = (): void => {
    if (this.props.onBlur) {
      this.props.onBlur(this.convertStringValueToNumber(this.state.inputValue || ''));
    }
  };

  private extractDecimalOrAcceleratorCharacters = (value: string | number): string => {
    if (!value) {
      return '';
    }
    const result: RegExpMatchArray | null = value
      .toString()
      .match(this.props.isInteger ? integerOrAcceleratorRegex : decimalOrAcceleratorRegex);
    if (result === null || result.length === 0) {
      return '';
    }
    return result[0];
  };

  private convertStringValueToNumber = (value: string): number | undefined => {
    if (value === '') {
      return undefined;
    }
    const parsedValue: number = parseFloat(value);
    return isNaN(parsedValue) ? undefined : parsedValue;
  };

  public render() {
    const {
      value,
      hasFocus,
      onFocus,
      onClick,
      onChange,
      onBlur,
      inputClassName,
      isInteger,
      ...rest
    } = this.props;
    return (
      <input
        {...rest}
        ref={this.inputRef}
        type="text"
        data-testid="numeric-input"
        className={'form-control ' + (inputClassName ?? '')}
        value={this.state.inputValue !== undefined ? this.state.inputValue : ''}
        onClick={this.onInputClick}
        onFocus={this.onInputFocus}
        onChange={this.onInputValueChange}
        onBlur={this.onInputBlur}
        onDragStart={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      />
    );
  }
}
