import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { useSelector } from 'react-redux';
import {
  NegotiationModeShortcutsComponent,
  type NegotiationModeShortcutsOwnProps,
  type NegotiationModeShortcutsStateProps,
} from './NegotiationMode';
import { getNegotiationModeModel } from './getNegotiationModeModel';

export const NegotiationModeShortcuts = (ownProps: NegotiationModeShortcutsOwnProps) => {
  const stateProps = useSelector<AppState, NegotiationModeShortcutsStateProps>(state =>
    getNegotiationModeModel(state, ownProps.rfqId, selectors),
  );
  return <NegotiationModeShortcutsComponent {...ownProps} {...stateProps} />;
};
