export function distinct<T>(array: T[]): T[] {
  return Array.from(new Set(array));
}

export const valueBy = <T extends keyof any, R>(array: Array<T>, mapFn: (v: T) => R) =>
  array.reduce((acc, value: T) => ({ ...acc, [value]: mapFn(value) }), {} as Record<T, R>);

export const mapRecordEntriesToSelectOptions = (map: Record<string, string>) =>
  Object.entries(map).map(option => ({
    value: option[0],
    label: option[1],
  }));

export const moveElemInObjectArray = <ObjectType>(
  array: Array<ObjectType>,
  from: number,
  to: number,
) => {
  const elemToAdd = array.splice(from, 1)[0];
  array.splice(to, 0, elemToAdd);
};

export function updateInArray<T>(array: T[], index: number, element: T) {
  return [...array.slice(0, index), element, ...array.slice(index + 1)];
}
