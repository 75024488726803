import type {
  PreviousStrategySelectedAction,
  StrategySelectedAction,
} from './selectedStrategyActions';

export const selectedStrategyActionCreators = {
  createStrategySelectedAction,
  createPreviousStrategySelectedAction,
};

export function createStrategySelectedAction(
  rfqId: string,
  selectedStrategyId: string,
): StrategySelectedAction {
  return {
    type: 'STRATEGY_SELECTED',
    rfqId,
    selectedStrategyId,
  };
}

export function createPreviousStrategySelectedAction(
  rfqId: string,
): PreviousStrategySelectedAction {
  return {
    type: 'PREVIOUS_STRATEGY_SELECTED',
    rfqId,
  };
}
