import type { Thunk } from '@/bootstrap/thunks';

import type { UpdateJustificationLabel } from '@/neos/business/rfq/strategy/leg/otcAllocation/updateJustificationModel';

export function createChangeJustificationLabelThunk(
  rfqId: string,
  newUpdateJustificationLabel: UpdateJustificationLabel | undefined,
): Thunk {
  return function changeJustificationThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { otcAllocationCrudActions },
      },
    },
  ) {
    const state = getState();
    const rfqOtcAllocations = selectors.getOtcAllocationsByRfqId(state, rfqId);

    const actions = rfqOtcAllocations.map(otcAllocation =>
      otcAllocationCrudActions.update(
        { rfqId, legId: otcAllocation.legId, allocationId: otcAllocation.uuid },
        { updateJustificationLabel: newUpdateJustificationLabel },
      ),
    );

    dispatch(actions);
  };
}
