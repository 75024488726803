import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';

export function areTraderPricesEnabled(
  state: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): boolean {
  const isReadonlyRfq = selectors.isReadOnlyRfq(state, rfqId);
  const isUnderlyingSelected = selectors.isStrategyUnderlyingsSet(state, strategyId, selectors);

  const atLeastOneLegSizeIsSet = selectors
    .getStrategyLegsData(state, strategyId, selectors)
    .some(
      ({ numberOfLots, notional, varUnit }) =>
        numberOfLots !== undefined || notional !== undefined || varUnit !== undefined,
    );

  return !isReadonlyRfq && atLeastOneLegSizeIsSet && isUnderlyingSelected;
}
