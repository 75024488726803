import { getCommonRootEpic } from '@/common/business/commonEpics';
import { getNeosRootEpic } from '@/neos/business/epics';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { Middleware } from 'redux';
import { type Epic, combineEpics } from 'redux-observable';
import type { Action } from '../../actions';
import type { ThunkEpic } from '../../epics';
import type { AppState } from '../../state';
import type { Dispatchable } from '../../thunks';

export interface ThunkEpicMiddleware<
  Input extends Action = Action,
  Output extends Dispatchable = Dispatchable,
  State = AppState,
  Dependencies = any,
> extends Middleware {
  run(rootEpic: ThunkEpic<Input, Output, State, Dependencies>): void;
}

export function bootstrapEpicMiddleware(http: SgmeHttp, epicMiddleware: ThunkEpicMiddleware) {
  const commonRootEpic = getCommonRootEpic(http);
  const neosRootEpic = getNeosRootEpic(http);
  const rootEpic: Epic = combineEpics(commonRootEpic, neosRootEpic);
  epicMiddleware.run(rootEpic);
}
