import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { thunks } from '@/bootstrap/thunks';
import type { PriceWithUnit } from '@/neos/business/neosOnyxModel';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';
import { NumericInput } from '@/neos/components/share/numericInput';
import { useDispatch, useSelector } from 'react-redux';

export function NovationFee({
  allocationId,
  rfqId,
  legId,
}: {
  allocationId: string;
  rfqId: string;
  legId: string;
}) {
  const dispatch = useDispatch();
  const currentAllocation = useSelector((state: AppState) =>
    selectors.getOtcAllocationsByAllocId(state, rfqId, legId, allocationId),
  );
  const currency = currentAllocation?.secondaryInfo?.novationFee?.nominal.unit;
  const novation = currentAllocation?.secondaryInfo?.novationFee?.nominal.value;

  let availableCurrencies = ['%', 'EUR', 'USD', 'GBP'];
  if (currency && !availableCurrencies.includes(currency)) {
    availableCurrencies = [currency, ...availableCurrencies];
  }
  const currencies = availableCurrencies.map(ccy => ({
    value: ccy,
    label: ccy,
  }));

  const onValueChange = (value: number | undefined) => {
    dispatch(
      thunks.neos.createUpdateNovationFeeThunk(allocationId, rfqId, legId, {
        value,
      }),
    );
  };
  const onCurrencyChange = (value: string | undefined) => {
    const patch: Partial<PriceWithUnit> =
      value === '%'
        ? { unit: value, type: 'REF_PERCENT' }
        : { unit: value ?? undefined, type: value ? 'CCY' : undefined };
    dispatch(thunks.neos.createUpdateNovationFeeThunk(allocationId, rfqId, legId, patch));
  };

  return (
    <div className="d-flex">
      <NumericInput
        value={novation}
        onBlur={value => onValueChange(value)}
        disableAccelerators
        onlyPositiveNumbers
        data-e2e={`otc-alloc-novation-fee-value`}
      />
      <NeosSelect
        style={{ width: '100px' }}
        value={currency}
        onChange={selected => onCurrencyChange(selected)}
        addEmptyOption
        options={currencies}
        data-e2e={`otc-alloc-novation-fee-ccy`}
      />
    </div>
  );
}
