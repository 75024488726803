import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';

interface IdsOfLegsWithDisplayedPrices {
  displayReadonlyCellsForCompositionLeg: boolean;
  displayedPricesLegIds: string[];
}
export function getIdsOfLegsWithDisplayedPrices(
  state: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): IdsOfLegsWithDisplayedPrices {
  const isShowLegPrices = selectors.isNeosShowLegPrices(state, rfqId, strategyId, selectors);
  if (!isShowLegPrices) {
    return { displayReadonlyCellsForCompositionLeg: false, displayedPricesLegIds: [] };
  }
  const hasCompo = selectors.hasACompositionLeg(state, strategyId, selectors);
  const { legIds } = selectors.getStrategyData(state, strategyId);

  return hasCompo
    ? {
        displayReadonlyCellsForCompositionLeg: true,
        displayedPricesLegIds: [legIds[legIds.length - 1]],
      }
    : {
        displayReadonlyCellsForCompositionLeg: false,
        displayedPricesLegIds: [...legIds],
      };
}
