import { commonSelectors } from '@/common/business/commonSelectors';
import { alertSelectors } from '@/neos/business/alert/alertSelectors';
import { allocSecondaryEventCreationSelectors } from '@/neos/business/allocSecondaryEventCreation/allocSecondaryEventCreationSelectors';
import { allocationInstructionSelectors } from '@/neos/business/allocationInstruction/allocationInstructionSelectors';
import { blotterSelectors } from '@/neos/business/blotter/blotterSelectors';
import { bookingStepsSelectors } from '@/neos/business/bookingSteps/BookingStepsSelectors';
import { currentUserPreferencesSelectors } from '@/neos/business/currentUserPreferences/currentUserPreferencesSlice';
import { getAllElectronicMediaUsers } from '@/neos/business/electronicMediaUsers/electronicMediaUsersSelectors';
import { legSecondaryEventCreationSelectors } from '@/neos/business/legSecondaryEventCreation/legSecondaryEventCreationSelectors';
import { areTraderPricesEnabled } from '@/neos/business/neosSelectors/areTraderPricesEnabled';
import { areUnitsDifferent } from '@/neos/business/neosSelectors/areUnitsDifferent';
import { getAllNeosAllocationPredealChecks } from '@/neos/business/neosSelectors/getAllNeosAllocationPredealChecks';
import { getDifferencesBetweenCurrentAndLastTradeCompletedRfq } from '@/neos/business/neosSelectors/getDifferencesBetweenCurrentAndLastTradeCompletedRfq';
import { getDifferencesBetweenCurrentAndNotificationRfq } from '@/neos/business/neosSelectors/getDifferencesBetweenCurrentAndNotificationRfq';
import { getDisplayedSizeTypeByStrategy } from '@/neos/business/neosSelectors/getDisplayedSizeTypeByStrategy';
import { getIdsOfLegsWithDisplayedPrices } from '@/neos/business/neosSelectors/getIdsOfLegsWithDisplayedPrices';
import { getNeosDeltaPredealChecks } from '@/neos/business/neosSelectors/getNeosDeltaPredealChecks';
import { getNeosPredealChecks } from '@/neos/business/neosSelectors/getNeosPredealChecks';
import { getNeosPredealChecksByTypeAndLevel } from '@/neos/business/neosSelectors/getNeosPredealChecksByTypeAndLevel';
import { getNeosPttPredealChecks } from '@/neos/business/neosSelectors/getNeosPttPredealChecks';
import { getNeosSelectedPredealCheck } from '@/neos/business/neosSelectors/getNeosSelectedPredealCheck';
import { getOpenedRfqIds } from '@/neos/business/neosSelectors/getOpenedRfqIds';
import { getRequestExceptPttPredealChecksByBdrId } from '@/neos/business/neosSelectors/getRequestExceptPttPredealChecksByBdrId';
import { getStrategyCompositionLegsData } from '@/neos/business/neosSelectors/getStrategyCompositionLegsData';
import { getCurrencyByStrategyId } from '@/neos/business/neosSelectors/getStrategyCurrency';
import {
  getStrategyIdsForShortcut,
  getUnderlyingIdsForShortcut,
} from '@/neos/business/neosSelectors/getStrategyIdsForShortcut';
import { getStrategyIndicesWithError } from '@/neos/business/neosSelectors/getStrategyIndicesWithError';
import { getUnderlyingDividendFutureMaturities } from '@/neos/business/neosSelectors/getUnderlyingDividendFutureMaturities';
import { getUnderlyingFutureMaturities } from '@/neos/business/neosSelectors/getUnderlyingFutureMaturities';
import { getUnderlyingInfo } from '@/neos/business/neosSelectors/getUnderlyingInfo';
import {
  getUnderlyingOptionFutureMaturities,
  getUnderlyingOptionMaturities,
} from '@/neos/business/neosSelectors/getUnderlyingOptionMaturities';
import {
  getUnderlyingStrikesByMaturity,
  getUnderlyingStrikesByMaturityOnOptionOnFuture,
} from '@/neos/business/neosSelectors/getUnderlyingStrikesByMaturity';
import { getUnderlyingTotalReturnFutureMaturities } from '@/neos/business/neosSelectors/getUnderlyingTotalReturnFutureMaturities';
import { getUnderlyingUnit } from '@/neos/business/neosSelectors/getUnderlyingUnit';
import { getUnderlyingsIdsWithoutInfo } from '@/neos/business/neosSelectors/getUnderlyingsIdsWithoutInfo';
import {
  hasACompositionLeg,
  rfqHasCompositionLeg,
} from '@/neos/business/neosSelectors/hasACompositionLeg';
import { isDeltaMixed } from '@/neos/business/neosSelectors/isDeltaMixed';
import { isLegOverWarningVisible } from '@/neos/business/neosSelectors/isLegOverWarningVisible';
import { isNeosShowLegPrices } from '@/neos/business/neosSelectors/isNeosShowLegPrices';
import { isReverseWeightsWarningVisible } from '@/neos/business/neosSelectors/isReverseWeightsWarningVisible';
import { isUsListed } from '@/neos/business/neosSelectors/isUsListed';
import { changeUnderlyingSelectors } from '@/neos/business/neosSelectors/underlyingChangeData';
import { nestedRfqsSelectors } from '@/neos/business/nestedRfqs/nestedRfqsSelectors';
import { nextUserActionsSelectors } from '@/neos/business/nextUserActions/nextUserActionsSelectors';
import { orderSelectors } from '@/neos/business/order/orderSelectors';
import { pendingPredealChecksSelectors } from '@/neos/business/pendingPredealChecks/pendingPredealChecksSelectors';
import { preConfirmationStepSelectors } from '@/neos/business/preConfirmationStep/preConfirmationStepDomain';
import { externalPreconfirmationSelectors } from '@/neos/business/preconfirmation/external/externalPreconfirmationSelectors';
import { internalPreconfirmationSelectors } from '@/neos/business/preconfirmation/internal/internalPreconfirmationSelectors';
import { predealCheckSelectors } from '@/neos/business/predealCheck/predealCheckSelectors';
import { referenceDataSelectors } from '@/neos/business/referenceData/referenceDataSelectors';
import { rfqSelectors } from '@/neos/business/rfq/selectors';
import { transfereeContactsSelectors } from '@/neos/business/rfq/transfereeContacts/transfereeContactsSelector';
import { similarActivitiesSelectors } from '@/neos/business/similarActivities/similarActivitiesSelectors';
import { similarActivitiesLinkManagementSelectors } from '@/neos/business/similarActivitiesLinkManagement/similarActivitiesLinkManagementSelectors';
import { uiSelectors } from '@/neos/business/ui/uiSelectors';
import { deltaNotificationChangesSelectors } from '@/neos/business/warnings/deltaNotificationChanges/deltaNotificationChangesSelectors';
import { executionPriceNotificationChangesSelectors } from '@/neos/business/warnings/executionPriceNotificationChanges/executionPriceNotificationChangesSelectors';
import { traderAskNotificationChangesSelectors } from '@/neos/business/warnings/traderAskNotificationChanges/traderAskNotificationChangesSelectors';
import { traderBidNotificationChangesSelectors } from '@/neos/business/warnings/traderBidNotificationChanges/traderBidNotificationChangesSelectors';
import { selectIsPreconfirmationSent } from '@/neos/business/preconfirmation/preconfirmationSelectors.ts';

export const selectors = {
  ...nextUserActionsSelectors,
  ...alertSelectors,
  ...referenceDataSelectors,
  ...commonSelectors,
  ...rfqSelectors,
  ...uiSelectors,
  getDifferencesBetweenCurrentAndNotificationRfq,
  getDifferencesBetweenCurrentAndLastTradeCompletedRfq,
  getCurrencyByStrategyId,
  getOpenedRfqIds,
  hasACompositionLeg,
  rfqHasCompositionLeg,
  getStrategyCompositionLegsData,
  areTraderPricesEnabled,
  areUnitsDifferent,
  getIdsOfLegsWithDisplayedPrices,
  getAllElectronicMediaUsers,
  isUsListed,
  getDisplayedSizeTypeByStrategy,
  isDeltaMixed,
  getStrategyIdsForShortcut,
  getUnderlyingIdsForShortcut,
  getUnderlyingInfo,
  getUnderlyingUnit,
  getUnderlyingOptionMaturities,
  getUnderlyingOptionFutureMaturities,
  getUnderlyingFutureMaturities,
  getUnderlyingDividendFutureMaturities,
  getUnderlyingTotalReturnFutureMaturities,
  getUnderlyingStrikesByMaturity,
  getUnderlyingStrikesByMaturityOnOptionOnFuture,
  getUnderlyingsIdsWithoutInfo,
  getNeosPredealChecksByTypeAndLevel,
  isReverseWeightsWarningVisible,
  isLegOverWarningVisible,
  isNeosShowLegPrices,
  getStrategyIndicesWithError,
  getNeosPredealChecks,
  getNeosDeltaPredealChecks,
  getAllNeosAllocationPredealChecks,
  getNeosPttPredealChecks,
  getRequestExceptPttPredealChecksByBdrId,
  getNeosSelectedPredealCheck,
  ...pendingPredealChecksSelectors,
  ...externalPreconfirmationSelectors,
  ...internalPreconfirmationSelectors,
  selectIsPreconfirmationSent,
  ...legSecondaryEventCreationSelectors,
  ...allocSecondaryEventCreationSelectors,
  ...similarActivitiesLinkManagementSelectors,
  ...deltaNotificationChangesSelectors,
  ...executionPriceNotificationChangesSelectors,
  ...traderAskNotificationChangesSelectors,
  ...traderBidNotificationChangesSelectors,
  allocationInstructionSelectors,
  preConfirmationStepSelectors,
  ...blotterSelectors,
  ...predealCheckSelectors,
  ...nestedRfqsSelectors,
  ...similarActivitiesSelectors,
  ...changeUnderlyingSelectors,
  ...orderSelectors,
  ...bookingStepsSelectors,
  ...transfereeContactsSelectors,
  currentUserPreferencesSelectors,
};

export type Selectors = typeof selectors;
