import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { connect } from 'react-redux';
import type { Way as ClientWayT } from '../../../../../../neos/business/neosModel';
import {
  ClientWayComponent,
  type ClientWayDispatchProps,
  type ClientWayMapStateToProps,
  type ClientWayOwnProps,
} from './ClientWay';

import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';

const { getRfqData, isReadOnlyRfq } = selectors;

export const ClientWay = connect(mapStateToProps, mapDispatchToProps)(ClientWayComponent);

function mapStateToProps(state: AppState, { rfqId }: ClientWayOwnProps): ClientWayMapStateToProps {
  const { clientWay, internal } = getRfqData(state, rfqId);
  const isAmendWorkflowWithInvalidTradeDate = selectors.isAmendWorkflowWithInvalidTradeDate(
    state,
    rfqId,
    selectors,
  );
  return {
    clientWay,
    internal,
    isReadonly: isReadOnlyRfq(state, rfqId) || isAmendWorkflowWithInvalidTradeDate,
  };
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId }: ClientWayOwnProps,
): ClientWayDispatchProps {
  return {
    onClientWayChanged(clientWay: ClientWayT) {
      const { createClientWayChangedAction } = neosActionCreators;
      dispatch(
        createClientWayChangedAction(rfqId, clientWay),
        createLogAnalyticsAction('NEOS RFQ', 'Select client side', clientWay),
      );
    },
  };
}
