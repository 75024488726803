import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { isElsProduct } from '../../../../../../../neos/business/neosModel';
import type { Field } from '../utils/Field';
import { getIsStrategyDefinitionFieldEditable } from '../utils/fieldsEnablingConditions';
import { getFieldsArray } from '../utils/getFieldsArray';

export interface SwapCurrencyCellsModel {
  swapCurrencies: Array<Field<string | undefined>>;
  availableCurrencies: string[];
}

export function getSwapCurrencyCellsModel(
  appState: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): SwapCurrencyCellsModel {
  const { getStrategyData, getStrategyProducts } = selectors;
  const { getStrategyDefinition, getCurrencyList } = selectors;

  const { strategyType } = getStrategyData(appState, strategyId);
  const { sameProductSwapCurrency, readOnlyProductSwapCurrency } = getStrategyDefinition(
    appState.referenceData,
    strategyType,
  );

  const elsProducts = getStrategyProducts(appState, strategyId, selectors).filter(isElsProduct);

  const swapCurrenciesAreEditable = getIsStrategyDefinitionFieldEditable(
    appState,
    rfqId,
    strategyId,
    selectors,
    ['RFQ_SCOPE', 'RFQ_EDITABLE', 'UNDERLYING_SET', 'STRATEGY_EDITABLE'],
  );

  const availableCurrencies = getCurrencyList(appState.referenceData).map(({ refId }) => refId);

  const isReadonlyAtWorkflow = selectors.isReadOnlyAtCurrentWorkflow(
    appState,
    strategyId,
    selectors,
  );
  const isReadonly =
    readOnlyProductSwapCurrency || !swapCurrenciesAreEditable || isReadonlyAtWorkflow;
  const swapCurrencies = getFieldsArray(
    elsProducts,
    'swapCurrency',
    sameProductSwapCurrency,
    isReadonly,
  );

  return {
    swapCurrencies,
    availableCurrencies,
  };
}
