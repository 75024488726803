import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { NextUserAction } from '@/neos/business/nextUserActions/nextUserActionsModel';
import {
  isBiddingQisLikeSource,
  isVoiceLikeSource,
  mapMdpSourceToName,
  type Source,
} from '@/neos/business/rfq/rfqOnyxModel';
import { REFERENTIAL_MISSING_CONTRACT_WARNING } from '@/neos/business/rfq/strategy/referentialWarning';
import { AreValidAllocExecSizes } from './AreValidExecutionsSizesSelectors';

interface CommonAction {
  action: NextUserAction;
  shouldCopyQuoteRecap: boolean;
}

interface ConfirmableAction extends CommonAction {
  isConfirmable: true;
  modalTitle: string;
  modalBody: string[];
  disabled: boolean;
}

interface NonConfirmableAction extends CommonAction {
  isConfirmable: false;
  action: NextUserAction;
  disabled: boolean;
}

export type ActionsModel = ConfirmableAction | NonConfirmableAction;

export interface NextUserActionsModel {
  nextUserActions: (ActionsModel | undefined)[];
  nextAction: ActionsModel | undefined;
  quoteRecap: string | undefined;
  isDisabled: boolean;
}

export function getNextUserActionModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): NextUserActionsModel {
  const { status, quoteRecap, source } = selectors.getRfqData(state, rfqId);
  const { nextActions } = selectors.getNextUserActions(state, rfqId);
  const isNextDefined = !!nextActions.find(({ action }) => action === 'next');

  const isSalesNovice = selectors.isFeatureToggleEnabled(
    state,
    'neos.workflow.confirm.delta.execution.enabled',
  );

  const { strategyIds } = selectors.getRfqData(state, rfqId);

  const areValidStrategySizes = strategyIds
    .map(stratId => selectors.isValidStrategySize(state, stratId, selectors))
    .every(isValidStrategySize => isValidStrategySize);

  const areValidExecStrategySizes = strategyIds
    .filter(stratId => selectors.isListedStrategy(state, stratId, selectors))
    .map(stratId => selectors.isValidListedExecStrategySize(state, rfqId, stratId, selectors))
    .every(isValidExecStrategySize => isValidExecStrategySize);

  const areValidAllocExecSizes = AreValidAllocExecSizes(strategyIds, rfqId, state, selectors);

  const shouldBeDisabledOnNegoSizesValidation = !areValidStrategySizes;
  const shouldBeDisabledOnPostNegoSizesValidation = !(
    areValidAllocExecSizes && areValidExecStrategySizes
  );

  const isDisabled =
    shouldBeDisabledOnNegoSizesValidation ||
    shouldBeDisabledOnPostNegoSizesValidation ||
    selectors.isUserForbiddenToSaveRfq(state);

  const isRefSpotChangedByTrader = selectors.isRefSpotChangedByTrader(state.ui, rfqId);
  const isTrader = selectors.isTrader(state);
  const isMasterStrategyOtc = selectors.isMasterStrategyOtc(state, rfqId, selectors);
  const isRfqInitiatedByTrader = selectors.isRfqInitiatedByTrader(state, rfqId);

  const nextActionModels = nextActions.map((action): ActionsModel => {
    const title = action.title;
    const disabledTitlesForSalesInOtc: string[] = ['Amend', 'Cancel Trade'];

    const isDisabledForSalesInOtc =
      disabledTitlesForSalesInOtc.includes(title) && isMasterStrategyOtc;

    const disabledTitlesForSales: string[] = [
      'Book Position',
      'Book Pre Trade Position',
      'Book Delta And Validate',
      'Validate Deal',
    ];
    const mustBeDisabledForSales =
      !isTrader &&
      (((status === 'BOOKING_REQUESTED' || status === 'POSITION_BEING_BOOKED') &&
        (title === 'Trade' || title === 'Book Position')) ||
        disabledTitlesForSales.includes(title) ||
        isDisabledForSalesInOtc);

    const mustBeDisabledForTrader =
      isTrader &&
      !isRfqInitiatedByTrader &&
      ((status === 'TRADED' && title === 'Save And Book') || title === 'Complete Trade');

    const mustBeDisabled = mustBeDisabledForSales || mustBeDisabledForTrader;

    const productWarnings = strategyIds.flatMap(strategyId =>
      selectors.getStrategyWarnings(state, rfqId, strategyId, selectors),
    );
    const isReferentialWarningDisplayed =
      productWarnings.length > 0 &&
      productWarnings.some(({ warning }) => warning === REFERENTIAL_MISSING_CONTRACT_WARNING);

    const isActionConfirmable = ({ type, title }: NextUserAction) => {
      return (
        (isSalesNovice && title === 'Execute Delta Automatically') ||
        isRefSpotChangedByTrader ||
        (isReferentialWarningDisplayed && title === 'Execute Cross Manually') ||
        (!isNextDefined && type !== 'workflow/cancel')
      );
    };

    return isActionConfirmable(action)
      ? {
          isConfirmable: true,
          action,
          ...getModalInfoFromActionTitle(
            action,
            source,
            !isNextDefined,
            isRefSpotChangedByTrader,
            isReferentialWarningDisplayed,
          ),
          shouldCopyQuoteRecap: false,
          disabled: mustBeDisabled,
        }
      : {
          isConfirmable: false,
          action,
          shouldCopyQuoteRecap: !!quoteRecap && title === 'Propose Price',
          disabled: mustBeDisabled,
        };
  });

  const nextAction = nextActionModels.find(({ action: { action } }) => action === 'next');

  const nextCancelActions = nextActionModels.filter(
    ({ action }) => action.type === 'workflow/cancel',
  );
  const nextNonCancelActions = nextActionModels.filter(
    ({ action }) => action.type !== 'workflow/cancel',
  );
  const shouldDividerInserted = nextCancelActions.length > 0 && nextNonCancelActions.length > 0;

  return {
    nextUserActions: [
      ...nextNonCancelActions,
      ...(shouldDividerInserted ? [undefined] : []),
      ...nextCancelActions,
    ],
    nextAction,
    quoteRecap,
    isDisabled,
  };
}

function getModalInfoFromActionTitle(
  { title }: NextUserAction,
  source: Source,
  isAmendOrder: boolean,
  isRefSpotChangedByTrader: boolean,
  isReferentialWarningDisplayed: boolean,
): Pick<ConfirmableAction, 'modalTitle' | 'modalBody'> {
  switch (title) {
    case 'Execute Delta Manually':
      return {
        modalTitle: 'Cancel order',
        modalBody: [
          'You are about to cancel orders, change(s) on rfq will also be saved.',
          'Do you confirm?',
        ],
      };
    case 'Execute Delta Automatically':
      return isAmendOrder
        ? {
            modalTitle: 'Amend order',
            modalBody: [
              'You are about to amend orders, change(s) on rfq will also be saved.',
              'Do you confirm?',
            ],
          }
        : {
            modalTitle: 'Execute Delta Automatically',
            modalBody: ['Do you confirm that you want to send a Market Order ?'],
          };
    case 'Save':
      return {
        modalTitle: 'Save at Booked',
        modalBody: ['You are about to save modifications on a Booked RFQ.', 'Do you confirm?'],
      };
    case 'Execute Cross Manually':
      if (!isReferentialWarningDisplayed) {
        break;
      }
      return {
        modalTitle: 'Warning before executing cross manually',
        modalBody: [
          'The contract you are about to execute does not exist in the referential.',
          'Do you confirm?',
        ],
      };
  }

  if (isRefSpotChangedByTrader) {
    return {
      modalTitle: 'Ref Spot has been changed',
      modalBody: [
        'You are about to change the ref spot.',
        'Please make sure to inform the sales.',
        '',
        'Do you confirm?',
      ],
    };
  }

  if (!isVoiceLikeSource(source) && !isBiddingQisLikeSource(source)) {
    const mdp = mapMdpSourceToName(source);
    return {
      modalTitle: `Broken connection with ${mdp}`,
      modalBody: [`You are about lose the link between your RFQ and ${mdp}.`, `Do you confirm?`],
    };
  }

  return {
    modalTitle: 'Please confirm',
    modalBody: ['You are about to change the status.', 'Do you confirm?'],
  };
}
