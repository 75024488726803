import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { internalGetErrorId } from '@/neos/business/mappers/error/internal/errorIdGenerator';
import type { UnderlyingType } from '../../../../../../../business/neosOnyxModel';
import { getIsStrategyDefinitionFieldEditable } from '../../utils/fieldsEnablingConditions';

interface CommonTopSectionModel {
  displayTopSection: boolean;
  displayErrorHighlight: boolean;
}

interface TopSectionWithoutTop extends CommonTopSectionModel {
  displayTopSection: false;
}

interface TopSectionWithTop extends CommonTopSectionModel {
  displayTopSection: true;
  disabled: boolean;
  top: number | undefined;
  isCustomMode: boolean;
  underlyingId: string | undefined;
  bloombergCode: string | undefined;
  underlyingTypes: UnderlyingType[];
}

export type TopSectionModel = TopSectionWithoutTop | TopSectionWithTop;

export function getTopSectionModel(
  state: AppState,
  rfqId: string,
  strategyId: string,
  sl: Selectors,
): TopSectionModel {
  const aggregationEnabled = sl.isFeatureToggleEnabled(state, 'neos.rfq.dispersion.enabled');
  const hasACompositionLeg = aggregationEnabled && sl.hasACompositionLeg(state, strategyId, sl);

  if (!hasACompositionLeg) {
    return { displayTopSection: false, displayErrorHighlight: false };
  }

  const { top, underlyingId, strategyType } = sl.getStrategyData(state, strategyId);

  const bloombergCode = underlyingId
    ? sl.getUnderlyingInfo(state, underlyingId)?.bloombergCode
    : undefined;

  const { legs } = sl.getStrategyDefinition(state.referenceData, strategyType);
  const lastLegUnderlyingTypes = legs[legs.length - 1]?.availableProductUnderlyingTypes ?? [];

  const disabled = !getIsStrategyDefinitionFieldEditable(state, rfqId, strategyId, sl, [
    'RFQ_EDITABLE',
    'STRATEGY_EDITABLE',
    'RFQ_SCOPE',
  ]);

  const compositionLegIds = sl
    .getStrategyCompositionLegsData(state, strategyId, sl)
    .map(({ uuid }) => uuid);

  const displayErrorHighlight = compositionLegIds.some(
    legId =>
      sl.getNeosError(
        state.ui,
        internalGetErrorId('underlying')({
          rfqId,
          legId,
          strategyId,
        }),
      ).isError,
  );

  return {
    displayTopSection: true,
    disabled,
    top,
    isCustomMode: !underlyingId,
    underlyingId,
    bloombergCode,
    underlyingTypes: lastLegUnderlyingTypes,
    displayErrorHighlight,
  };
}
