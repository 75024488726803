import {
  type Product,
  type SecondaryEvent,
  isClsProduct,
  isElsProduct,
  isElsTrsProduct,
} from '@/neos/business/neosModel';

export type SecondaryField =
  | 'primaryBookingId'
  | 'remainingQuantity'
  | 'amountDue'
  | 'amountDueWay'
  | 'nettingBookingId'
  | 'novationFee'
  | 'novationFeeWay'
  | 'transferee'
  | 'transferor'
  | 'novationBookingId'
  | 'novationFeeId'
  | 'remainingParty'
  | 'confirmationToBeChecked'
  | 'partialReset'
  | 'observeNewRateFixing'
  | 'dividendCurrentFlowMode'
  | 'equityCurrentFlowMode'
  | 'rateCurrentFlowMode'
  | 'keepDividendPaymentDate'
  | 'initialStrike'
  | 'initialTradeDate'
  | 'flowMode'
  | 'mtm'
  | 'usePositionFromLastReset';

export const commonSecondaryFieldsByType: Record<SecondaryEvent, Array<SecondaryField>> = {
  PARTIAL_UNWIND: [
    'primaryBookingId',
    'remainingQuantity',
    'amountDue',
    'amountDueWay',
    'nettingBookingId',
  ],
  INCREASE: [
    'primaryBookingId',
    'remainingQuantity',
    'amountDue',
    'amountDueWay',
    'nettingBookingId',
  ],
  FULL_UNWIND: [
    'primaryBookingId',
    'remainingQuantity',
    'amountDue',
    'amountDueWay',
    'nettingBookingId',
  ],
  NOVATION_REMAINING: [
    'primaryBookingId',
    'remainingQuantity',
    'novationFee',
    'novationFeeWay',
    'transferee',
    'transferor',
    'novationBookingId',
    'novationFeeId',
  ],
  STEPIN: ['remainingParty', 'transferor', 'initialTradeDate', 'initialStrike'],
};

export const elsSecondaryFieldsByType: Record<SecondaryEvent, Array<SecondaryField>> = {
  PARTIAL_UNWIND: [
    'primaryBookingId',
    'remainingQuantity',
    'amountDue',
    'amountDueWay',
    'nettingBookingId',
    'confirmationToBeChecked',
    'partialReset',
    'observeNewRateFixing',
    'dividendCurrentFlowMode',
    'equityCurrentFlowMode',
    'rateCurrentFlowMode',
  ],
  INCREASE: [
    'primaryBookingId',
    'remainingQuantity',
    'amountDue',
    'amountDueWay',
    'nettingBookingId',
    'confirmationToBeChecked',
    'partialReset',
    'observeNewRateFixing',
    'dividendCurrentFlowMode',
    'equityCurrentFlowMode',
    'rateCurrentFlowMode',
  ],
  FULL_UNWIND: [
    'primaryBookingId',
    'remainingQuantity',
    'amountDue',
    'amountDueWay',
    'nettingBookingId',
    'confirmationToBeChecked',
    'dividendCurrentFlowMode',
    'equityCurrentFlowMode',
    'rateCurrentFlowMode',
    'keepDividendPaymentDate',
  ],
  NOVATION_REMAINING: [],
  STEPIN: [],
};

export const labelBySecondaryField: Record<SecondaryField, string> = {
  primaryBookingId: 'Primary Id',
  remainingQuantity: 'Remaining QTY (Pre-Event)',
  amountDue: 'Amount Due',
  amountDueWay: 'Amount Due Way',
  nettingBookingId: 'Netting Id',
  novationFee: 'Novation Fee',
  novationFeeWay: 'Novation Fee Way',
  transferee: 'Transferee',
  transferor: 'Transferor',
  novationBookingId: 'Novation Booking Id',
  novationFeeId: 'Novation Fee Id',
  remainingParty: 'Remaining Party',
  confirmationToBeChecked: 'Conf. To Be Checked',
  partialReset: 'Partial Reset',
  observeNewRateFixing: 'New Rate Fixing',
  dividendCurrentFlowMode: 'Dividend Current Flow Mode',
  equityCurrentFlowMode: 'Equity Current Flow Mode',
  rateCurrentFlowMode: 'Rate Current Flow Mode',
  keepDividendPaymentDate: 'Keep Dividend Payment Date',
  initialStrike: 'Initial Strike',
  initialTradeDate: 'Initial Trade Date',
  flowMode: 'Flow Mode',
  mtm: 'MTM',
  usePositionFromLastReset: 'Use Position From Last Reset',
};

export function getSecondaryFieldsToDisplay(
  secondaryEventType: SecondaryEvent,
  product: Product,
): SecondaryField[] {
  if (isElsProduct(product)) {
    if (isElsTrsProduct(product)) {
      return elsSecondaryFieldsByType[secondaryEventType];
    }
    return elsSecondaryFieldsByType[secondaryEventType].filter(
      field => field !== 'dividendCurrentFlowMode',
    );
  }

  if (isClsProduct(product)) {
    const clsSecondaryFields: SecondaryField[] = [
      'partialReset',
      'flowMode',
      'mtm',
      'usePositionFromLastReset',
    ];
    return commonSecondaryFieldsByType[secondaryEventType].concat(clsSecondaryFields);
  }
  return commonSecondaryFieldsByType[secondaryEventType];
}
