import { selectors } from '@/bootstrap/selectors';
import { NeosTooltip } from '@/neos/components/share/tooltip/NeosTooltip';

import { useState } from 'react';
import { Tooltip } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { useAppSelector } from '@/bootstrap/hooks';
import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { useFeatureToggle } from '@/neos/components/share/featureToggle/useFeatureToggle';
import { useDebounce } from '@/util/hooks/useDebounce';
import styles from './OpenRfq.module.scss';

const debounceTimeout = 2000;

function loadLatestTransaction(dispatch: ExtendedDispatch, rfqId: string) {
  dispatch(
    createLogAnalyticsAction('NEOS BLOTTER', 'Open RFQ by UUID'),
    neosActionCreators.loadTransactionAtVersion({ rfqId, version: 'LATEST' }),
  );
}

function searchByBookingId(dispatch: ExtendedDispatch, bookingId: string) {
  dispatch(
    createLogAnalyticsAction('NEOS BLOTTER', 'Search RFQ by bookingId'),
    neosActionCreators.requestSearchByBookingId(bookingId),
  );
}

export function OpenRfq() {
  const [rfqIdToLoad, setRfqIdToLoad] = useState('');
  const isBookingIdSearchEnabled = useFeatureToggle('neos.search.by.booking.id');

  const dispatch = useDispatch<ExtendedDispatch>();

  const openedRfqIds = useAppSelector(appState => selectors.getTabs(appState.ui.tabManagement)).map(
    tab => tab.id,
  );

  const onEnterClick = useDebounce(
    (event, rfqId: string) => {
      if (event.key !== 'Enter') {
        return;
      }
      const isNotGuidFormat = rfqId.length !== 36;
      if (isNotGuidFormat && rfqId.length && isBookingIdSearchEnabled) {
        searchByBookingId(dispatch, rfqId);
      } else {
        loadLatestTransaction(dispatch, rfqId);
      }
    },
    [dispatch, isBookingIdSearchEnabled],
    { wait: debounceTimeout },
  );

  const onButtonClick = useDebounce(
    (rfqId: string) => {
      const isNotGuidFormat = rfqId.length !== 36;
      (document.activeElement as any)?.blur?.();
      if (isNotGuidFormat && rfqId.length && isBookingIdSearchEnabled) {
        searchByBookingId(dispatch, rfqId);
      } else {
        loadLatestTransaction(dispatch, rfqId);
      }
    },
    [dispatch, isBookingIdSearchEnabled],
    { wait: debounceTimeout },
  );

  return (
    <div className={`${styles['rfq-simple-open']}`}>
      <input
        type="text"
        className="form-control input-sm"
        placeholder={`RFQ ID ${isBookingIdSearchEnabled ? 'OR BOOKING ID' : ''}`}
        data-e2e="neos-blotter-input-open-rfq"
        value={rfqIdToLoad}
        onChange={e => setRfqIdToLoad(e.target.value.trim())}
        onKeyDown={event => onEnterClick(event, rfqIdToLoad)}
      />
      <NeosTooltip
        placement="top"
        trigger={['focus', 'hover']}
        overlay={
          <Tooltip id="open-rfq-from-blotter">
            Open latest version
            {openedRfqIds.includes(rfqIdToLoad) ? (
              <>
                .
                <br />
                This RFQ is already opened, all unsaved changes will be lost!
              </>
            ) : null}
          </Tooltip>
        }
      >
        <button
          className="btn btn-primary"
          data-e2e="neos-blotter-btn-open-rfq"
          disabled={!rfqIdToLoad.length}
          onClick={() => onButtonClick(rfqIdToLoad)}
        >
          <i className="icon icon-sm">arrow_forward</i>
        </button>
      </NeosTooltip>
    </div>
  );
}
