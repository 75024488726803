import type { Thunk } from '@/bootstrap/thunks';
import type { PredealCheck } from '../predealCheck/predealCheckModel';

export function createPredealCheckNotificationReceivedThunk(
  rfqId: string,
  predealCheck: PredealCheck,
): Thunk {
  return function predealCheckNotificationReceivedThunk(
    dispatch,
    getState,
    {
      selectors: { isRfqSavingDone, tabExists },
      actionCreators: {
        neos: { pendingPredealChecksCrudActions },
      },
      thunks: {
        neos: { createPredealChecksReceivedThunk },
      },
    },
  ) {
    const state = getState();
    const rfqIsOpen = tabExists(state.ui.tabManagement, rfqId);
    if (!rfqIsOpen) {
      return;
    }
    const savingDone = isRfqSavingDone(state, rfqId);

    const dispatchable = savingDone
      ? createPredealChecksReceivedThunk(rfqId, [predealCheck])
      : pendingPredealChecksCrudActions.arrayAdd(rfqId, {
          property: 'pending',
          value: predealCheck,
        });

    dispatch(dispatchable);
  };
}
