import type { Tab } from './tabModel';
import type { TabManagerState } from './tabState';

export const tabSelectors = {
  getSelectedTab,
  getTabs,
  tabExists,
  getLastSelectedTabId,
};

function getSelectedTab(state: TabManagerState): 'BLOTTER' | string {
  return state.selectedTabId;
}

function getTabs(state: TabManagerState): Tab[] {
  return state.tabs;
}

function tabExists(state: TabManagerState, rfqId: string) {
  return state.tabs.some(t => t.id === rfqId);
}

function getLastSelectedTabId(state: TabManagerState): string | undefined {
  return state.previouslySelectedTabIds[state.previouslySelectedTabIds.length - 2];
}
