import { fromFeaturesMappers, toFeaturesMappers } from '../../../feature';
import { fromPartialProductMappers, toPartialProductMappers } from './partialProductMappers';
import { mapFromOnyxProduct, mapToOnyxProduct, mapToOnyxProductUnderlying } from './productMapper';
import { fromSpecificProductMappers } from './specificProductMappers';

export const toProductMappers = {
  mapToOnyxProduct,
  mapToOnyxProductUnderlying,
  ...toPartialProductMappers,
  ...toFeaturesMappers,
};

export const fromProductMappers = {
  mapFromOnyxProduct,
  ...fromSpecificProductMappers,
  ...fromPartialProductMappers,
  ...fromFeaturesMappers,
};

export type FromProductMappers = typeof fromProductMappers;
