import type { DisplayTimeZone } from '@/neos/business/neosModel';
import { NeosTooltip } from '@/neos/components/share/tooltip/NeosTooltip';
import {
  DATE_SHORT_KEBAB_FORMAT,
  DATE_SHORT_TIME_FORMAT,
  formatDateInTimeZone,
  toUtc,
} from '@/util/date/dateFormatHelper';
import { differenceInMilliseconds } from 'date-fns';
import { useState } from 'react';
import { Tooltip } from 'react-bootstrap';
import { FlowDatePicker } from '../../share/datePicker';

export interface PricingDeadlineTimerEditorProps {
  onPricingDeadlineTimeSelected(expiryDate: Date): void;
  existingDate?: string;
  displayTimeZone: DisplayTimeZone;
  isReadOnly?: boolean;
}

export const PricingDeadlineTimerEditor = ({
  onPricingDeadlineTimeSelected,
  existingDate,
  displayTimeZone,
  isReadOnly,
}: PricingDeadlineTimerEditorProps) => {
  const [date, setDate] = useState<string>(
    formatDateInTimeZone(
      displayTimeZone,
      existingDate ?? new Date().toISOString(),
      DATE_SHORT_KEBAB_FORMAT,
    ),
  );
  const [time, setTime] = useState<string>(() => {
    return formatDateInTimeZone(
      displayTimeZone,
      existingDate ?? new Date().toISOString(),
      DATE_SHORT_TIME_FORMAT,
    );
  });

  const [showError, setShowError] = useState<boolean>(false);
  const isGMTTimezone = displayTimeZone === 'GMT';
  function isDateUnderThreshold(dateToCheck: Date) {
    const timeThresholdInMs = 15 * 60000;
    return isGMTTimezone
      ? differenceInMilliseconds(dateToCheck, toUtc(new Date())) < timeThresholdInMs
      : differenceInMilliseconds(dateToCheck, new Date()) < timeThresholdInMs;
  }

  const handleFullDateTimeChange = () => {
    setShowError(false);
    const [year, month, days] = date.split('-');
    const [hours, minutes] = time.split(':');
    const newDateTime = isGMTTimezone
      ? new Date(
          Date.UTC(Number(year), Number(month) - 1, Number(days), Number(hours), Number(minutes)),
        )
      : new Date(Number(year), Number(month) - 1, Number(days), Number(hours), Number(minutes));

    if (isDateUnderThreshold(newDateTime)) {
      setShowError(true);
      onPricingDeadlineTimeSelected(new Date());
      return;
    }
    onPricingDeadlineTimeSelected(newDateTime);
  };

  const pricingDeadlineTooltip = showError ? (
    <Tooltip id={'pricing-deadline-date'} className="react-bootstrap-danger-tooltip">
      The deadline should be with a minimum of 15 minutes
    </Tooltip>
  ) : (
    <Tooltip id={'pricing-deadline-date'}>Pricing Deadline</Tooltip>
  );

  return (
    <NeosTooltip placement="top" overlay={pricingDeadlineTooltip}>
      <div className="d-flex gap-1">
        <div style={{ maxWidth: '90px', minWidth: '90px' }}>
          <FlowDatePicker
            onChange={val => setDate(val)}
            maturities={[]}
            date={date}
            hideCalendarIcon
            onCalendarClose={handleFullDateTimeChange}
            data-e2e="neos-pricing-deadline-date"
            readOnly={isReadOnly}
          />
        </div>
        <input
          className={`form-control errorable-bloc ${showError ? 'warning-bloc field-warning' : ''}`}
          type="time"
          value={time}
          onChange={event => {
            setTime(event.target.value);
          }}
          onBlur={handleFullDateTimeChange}
          data-e2e="neos-pricing-deadline-time"
          data-testid="neos-pricing-deadline-time-test-id"
          style={{ minWidth: '70px', height: '32px' }}
          readOnly={isReadOnly}
        />
      </div>
    </NeosTooltip>
  );
};
