import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { thunks } from '@/bootstrap/thunks';
import { Switch } from '@/neos/components/share/switch/Switch';
import { useDispatch, useSelector } from 'react-redux';

export function ConfirmationToBeChecked({
  allocationId,
  rfqId,
  legId,
}: {
  allocationId: string;
  rfqId: string;
  legId: string;
}) {
  const dispatch = useDispatch();

  const currentAllocation = useSelector((state: AppState) =>
    selectors.getOtcAllocationsByAllocId(state, rfqId, legId, allocationId),
  );
  const confirmationToBeChecked = currentAllocation?.secondaryInfo?.confirmationToBeChecked;

  const onConfirmationToBeChecked = (value: boolean) => {
    dispatch(
      thunks.neos.createUpdateSecondaryInfoThunk(allocationId, rfqId, legId, {
        confirmationToBeChecked: value,
      }),
    );
  };

  return (
    <Switch
      switchClassName="text-center"
      data-e2e="confirmation-to-be-checked"
      checked={!!confirmationToBeChecked}
      onChange={() => onConfirmationToBeChecked(!confirmationToBeChecked)}
    />
  );
}
