import { formatDistanceToNow } from 'date-fns';
import { useEffect, useState } from 'react';

import styles from './BlotterLastNotification.module.scss';

export interface BlotterLastNotificationPropsFromState {
  lastNotificationDate: Date | undefined;
}

export type BlotterLastNotificationProps = BlotterLastNotificationPropsFromState;

export function BlotterLastNotificationWithCss({
  lastNotificationDate,
}: BlotterLastNotificationProps) {
  const [fakeCurrentDate, setFakeCurrentDate] = useState(new Date());

  useEffect(() => {
    let c: NodeJS.Timeout;
    if (lastNotificationDate) {
      c = setTimeout(() => setFakeCurrentDate(new Date()), 4_500);
    }
    return () => clearTimeout(c);
  }, [fakeCurrentDate, lastNotificationDate]);

  if (lastNotificationDate) {
    const distance = formatDistanceToNow(lastNotificationDate, {
      addSuffix: true,
      includeSeconds: true,
    });
    return (
      <div
        className={`${styles['last-refresh']}`}
        data-e2e="last-missed-blotter-notification-warning"
      >
        Some RFQ updates are not displayed due to selected filters ({distance})
      </div>
    );
  }
  return <></>;
}
