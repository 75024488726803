import type { PreConfirmationStep } from './PreConfirmationStepModel';
import { preConfirmationStepDomain } from './preConfirmationStepDomain';

const preConfirmationStepsCrudActions = preConfirmationStepDomain.actions;

export const preConfirmationStepActionCreators = {
  preConfirmationStepsCrudActions,
  createPreConfirmationStepsReceivedAction,
  createGeneratePreconfsAction,
};

function createPreConfirmationStepsReceivedAction(
  rfqId: string,
  preConfirmationSteps: PreConfirmationStep[],
) {
  return preConfirmationSteps
    .filter(step => !!step.preConfirmationReference)
    .map(preConfirmationStep =>
      preConfirmationStepsCrudActions.upsert(
        { rfqId, uuid: preConfirmationStep.preConfirmationReference.uuid },
        preConfirmationStep,
      ),
    );
}

function createGeneratePreconfsAction(rfqId: string): GeneratePreconfsAction {
  return {
    type: 'POST_GENERATE_PRECONFIRMATION',
    rfqId,
  };
}

export interface GeneratePreconfsAction {
  type: 'POST_GENERATE_PRECONFIRMATION';
  rfqId: string;
}
