import type { Thunk } from '@/bootstrap/thunks';
import type { OnyxTransaction } from '@/neos/business/neosOnyxModel';

export function createSaveTransactionThunk(transaction: OnyxTransaction): Thunk {
  return function saveTransactionThunk(
    dispatch,
    _,
    {
      thunks: {
        neos: {
          createNeosSaveRfqThunk,
          createIntegrateNextUserActions,
          createKeepSavedGlobalDeltaThunk,
          createIntegratePreconfirmationThunk,
        },
      },
      actionCreators: {
        neos: {
          createPredealChecksReceivedAction,
          onyxBookingStepsReceived,
          createRequestSimilarActivitiesAction,
          createOrdersReceived,
          createOrdersDeleted,
        },
      },
      fromMappers,
    },
  ) {
    const {
      rfq,
      rfq: {
        uuid: rfqId,
        lifecycle: { status },
      },
      pdcs,
      bookingSteps,
      preConfirmationMail,
      orders,
      workflow,
    } = transaction;

    if (status === 'PRICE_REQUESTED') {
      dispatch(createRequestSimilarActivitiesAction(rfqId));
    }

    dispatch(
      onyxBookingStepsReceived({ rfqId, bookingSteps: bookingSteps || [] }),
      createPredealChecksReceivedAction(pdcs),
      createOrdersDeleted(rfqId),
      createOrdersReceived(rfqId, orders || [], fromMappers),
    );

    if (preConfirmationMail) {
      dispatch(createIntegratePreconfirmationThunk(rfqId, preConfirmationMail));
    }

    if (workflow) {
      dispatch(createIntegrateNextUserActions(rfqId, workflow));
    }

    dispatch(createNeosSaveRfqThunk(rfq), createKeepSavedGlobalDeltaThunk(rfqId));
  };
}
