import type { Thunk } from '@/bootstrap/thunks';
import type { OnyxRfq } from '@/neos/business/rfq/rfqOnyxModel';

export function createNeosCancelRfqThunk(rfq: OnyxRfq): Thunk {
  return function neosCancelRfqThunk(
    dispatch,
    _,
    {
      thunks: {
        createSuccessToasterThunk,
        neos: { createNeosLoadRfqThunk },
      },
      actionCreators: {
        neos: { createLoadVersionsRequestedAction },
      },
    },
  ) {
    dispatch(
      createNeosLoadRfqThunk(rfq),
      createLoadVersionsRequestedAction(rfq.uuid),
      createSuccessToasterThunk({
        message: 'RFQ cancelled successfully!',
      }),
    );
  };
}
