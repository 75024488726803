import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import {
  type PredealCheck,
  allocationOrDeltaPredealCheckWithLegsTypes,
  isAllocationOrDeltaPredealCheckWithLegs,
} from '@/neos/business/predealCheck/predealCheckModel';
import { sum } from 'lodash';
import type { PredealCheckBulletProps } from '../../../predealCheckBullet';
import { convertToBulletColor, getAggregationColor } from '../../../util';

interface HiddenPredealCheckCellModel {
  isDisplayed: false;
}

type DisplayedPredealCheckCellModel = {
  isDisplayed: true;
  bullet: DistributiveOmit<PredealCheckBulletProps, 'onSelected' | 'isElementCentered'>;
};

export type PredealCheckCellModel = HiddenPredealCheckCellModel | DisplayedPredealCheckCellModel;

interface CommonCellKey {
  type: PredealCheck['type'];
  rfqId: string;
  counterpartId: number;
  pdcLevel: 'ALLOCATION' | 'DELTA';
}
interface CounterpartLevelCellKey extends CommonCellKey {
  rowLevel: 'COUNTERPART';
}

interface StrategyLevelCellKey extends CommonCellKey {
  rowLevel: 'STRATEGY';

  strategyId: string;
}

interface LegLevelCellKey extends CommonCellKey {
  rowLevel: 'LEG';
  strategyId: string;
  legId: string;
}

export type CellKey = CounterpartLevelCellKey | StrategyLevelCellKey | LegLevelCellKey;

export function getPredealCheckCellModel(
  state: AppState,
  cellKey: CellKey,
  selectors: Selectors,
): PredealCheckCellModel {
  if (cellKey.rowLevel === 'LEG' && hasOnlyStrategyPredealChecks(state, cellKey, selectors)) {
    return { isDisplayed: false };
  }

  return cellKey.rowLevel === 'COUNTERPART' ||
    isStrategyAggregationPredealCheck(state, cellKey, selectors)
    ? getAggregationCellModel(state, cellKey, selectors)
    : getConcreteCellModel(state, cellKey, selectors);
}

function getConcreteCellModel(
  state: AppState,
  cellKey: StrategyLevelCellKey | LegLevelCellKey,
  selectors: Selectors,
): DisplayedPredealCheckCellModel {
  const selectedPredealCheckId = selectors.getUiSelectedPredealCheckId(state.ui, cellKey.rfqId);

  const predealChecks = getFiltredPredealChecks(state, cellKey, selectors);
  const predealCheck =
    cellKey.rowLevel === 'STRATEGY'
      ? predealChecks.find(pdc => pdc.strategyUuid === cellKey.strategyId)
      : predealChecks
          .filter(isAllocationOrDeltaPredealCheckWithLegs)
          .find(pdc => pdc.legUuid === cellKey.legId);

  const isLoading = predealCheck?.status === 'COMPUTING';

  return predealCheck
    ? {
        isDisplayed: true,
        bullet: {
          color: convertToBulletColor(predealCheck.uiColor ?? 'UNKNOWN'),
          isSelectable: true,
          isSelected: selectedPredealCheckId === predealCheck.uuid,
          isUnknown: false,
          isLoading,
          uuid: predealCheck.uuid,
        },
      }
    : {
        isDisplayed: true,
        bullet: {
          isSelectable: false,
          isLoading,
          color: 'unknown',
        },
      };
}

function getAggregationCellModel(
  state: AppState,
  cellKey: CellKey,
  selectors: Selectors,
): DisplayedPredealCheckCellModel {
  const predealChecks = getFiltredPredealChecks(state, cellKey, selectors);
  const relativePredealChecks =
    cellKey.rowLevel === 'STRATEGY'
      ? predealChecks.filter(pdc => pdc.strategyUuid === cellKey.strategyId)
      : predealChecks;

  const expectedPredealChecksNumber = getAggregationExpectedPredealChecksNumber(
    state,
    cellKey,
    selectors,
  );

  const pdcColors = relativePredealChecks.map(({ uiColor }) => convertToBulletColor(uiColor));
  if (relativePredealChecks.length !== expectedPredealChecksNumber) {
    pdcColors.push('grey');
  }

  const isLoading = relativePredealChecks.some(pdc => pdc.status === 'COMPUTING');

  return {
    isDisplayed: true,
    bullet: {
      color: getAggregationColor(pdcColors),
      isSelectable: false,
      isLoading,
    },
  };
}

function getFiltredPredealChecks(
  state: AppState,
  cellKey: CellKey,
  selectors: Selectors,
): PredealCheck[] {
  const { rfqId, type, counterpartId, pdcLevel } = cellKey;
  return selectors
    .getNeosPredealChecks(state, rfqId, selectors)
    .filter(
      pdc => pdc.type === type && pdc.counterpartyId === counterpartId && pdc.level === pdcLevel,
    );
}

function isStrategyAggregationPredealCheck(
  state: AppState,
  cellKey: CellKey,
  selectors: Selectors,
): boolean {
  return (
    cellKey.rowLevel === 'STRATEGY' && !hasOnlyStrategyPredealChecks(state, cellKey, selectors)
  );
}

function getAggregationExpectedPredealChecksNumber(
  state: AppState,
  cellKey: CellKey,
  selectors: Selectors,
): number {
  if (cellKey.rowLevel === 'STRATEGY') {
    return hasOnlyStrategyPredealChecks(state, cellKey, selectors)
      ? 1
      : selectors.getStrategyData(state, cellKey.strategyId).legIds.length;
  }

  return sum(
    selectors
      .getRfqLegIdsByStrategyIds(state, cellKey.rfqId, selectors)
      .map(({ strategyId, legIds }) =>
        hasOnlyStrategyPredealChecks(state, { strategyId, type: cellKey.type }, selectors)
          ? 1
          : legIds.length,
      ),
  );
}

function hasOnlyStrategyPredealChecks<T extends { strategyId: string; type: PredealCheck['type'] }>(
  state: AppState,
  { strategyId, type }: T,
  selectors: Selectors,
): boolean {
  return (
    !allocationOrDeltaPredealCheckWithLegsTypes.some(t => t === type) ||
    selectors.hasACompositionLeg(state, strategyId, selectors)
  );
}
