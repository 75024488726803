import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import {
  type OptionObservableType,
  isOptionProduct,
} from '../../../../../../../neos/business/neosModel';
import type { Field } from '../utils/Field';
import { getFieldsArray } from '../utils/getFieldsArray';

export interface ObservableTypeModel {
  observableTypes: Array<Field<OptionObservableType | undefined>>;
  availableObservableTypes: (OptionObservableType | undefined)[];
}

export function getObservableTypeModel(
  appState: AppState,
  strategyId: string,
  selectors: Selectors,
): ObservableTypeModel {
  const { strategyType } = selectors.getStrategyData(appState, strategyId);
  const { sameObservableType, readOnlyObservableType, availableObservableTypes } =
    selectors.getStrategyDefinition(appState.referenceData, strategyType);
  const products = selectors
    .getStrategyProducts(appState, strategyId, selectors)
    .filter(isOptionProduct);

  const isReadonlyAtWorkflow = selectors.isReadOnlyAtCurrentWorkflow(
    appState,
    strategyId,
    selectors,
  );

  const isReadonly = isReadonlyAtWorkflow || readOnlyObservableType;
  const observableTypes = getFieldsArray(
    products,
    'observableType',
    sameObservableType,
    isReadonly,
  );

  return {
    observableTypes,
    availableObservableTypes: [...availableObservableTypes, undefined],
  };
}
