import { useTimer } from '../../../../../share/neosTimer/useNeosTimer';
import { CommonTimer, type TimerState } from '../../commonTimer/CommonTimer';
export interface MdpQuoteTimerTimeOwnProps {
  rfqId: string;
  onTimerStateChanged: (status: TimerState) => void;
}
export interface MdpQuoteTimerTimeStateProps {
  expiry: string;
  duration: number;
}

export type MdpQuoteTimerTimeProps = MdpQuoteTimerTimeOwnProps & MdpQuoteTimerTimeStateProps;

export const MdpQuoteTimerTimeComponent = ({
  expiry,
  duration,
  onTimerStateChanged,
}: MdpQuoteTimerTimeProps) => {
  const { remainingTime } = useTimer(expiry);
  return remainingTime <= 0 ? null : (
    <CommonTimer
      remainingTime={remainingTime}
      duration={duration}
      timerName={'Price validity timer'}
      onTimerStateChanged={onTimerStateChanged}
    />
  );
};
