import type { AppConfig } from '@/util/config/config';

export type AnalyticCategory =
  | 'NEOS RFQ'
  | 'NEOS BLOTTER'
  | 'NEOS WORKSPACE'
  | 'NEOS USER PREFERENCES';

interface MyWindow extends Window {
  // global variable to communicate with piwik tracking code
  _paq: any[];
}

declare const window: MyWindow;

export class LogAnalytic implements LogAnalytic {
  private readonly _piwikSourceUrl: string;

  constructor(config: AppConfig['piwik']) {
    const currentDomain = window.location.hostname;
    window._paq = [];
    window._paq.push(['setDomains', [currentDomain]]);
    window._paq.push(['trackPageView']);
    window._paq.push(['enableLinkTracking']);
    window._paq.push(['setTrackerUrl', config.piwikRootUrl + 'piwik.php']);
    window._paq.push(['setSiteId', config.siteId]);
    this._piwikSourceUrl = config.piwikRootUrl + 'piwik.js';
  }

  public initialize(login: string): void {
    window._paq.push(['setUserId', login]);
    this.appendPiwikScript();
  }

  private appendPiwikScript(): void {
    const piwikScript = document.createElement('script');
    piwikScript.type = 'text/javascript';
    piwikScript.async = true;
    piwikScript.defer = true;
    piwikScript.src = this._piwikSourceUrl;

    const firstDocumentScriptNode = document.getElementsByTagName('script')[0];
    if (firstDocumentScriptNode === null || firstDocumentScriptNode.parentNode === null) {
      // eslint-disable-next-line no-console
      console.error('Can not inject piwik source code into document');
      return;
    }
    firstDocumentScriptNode.parentNode.insertBefore(piwikScript, firstDocumentScriptNode);
  }

  public log(category: AnalyticCategory, name: string, additionalData?: string) {
    const data = ['trackEvent', category, name];
    if (additionalData !== undefined) {
      data.push(additionalData);
    }
    window._paq.push(data);
  }
}
