import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import {
  availableBookingApplications,
  type BookingStepApplication,
} from '@/neos/business/bookingSteps/bookingStepOnyxModel';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { Portfolio } from '@/neos/components/rfq/strategyDetails/Portfolio/Portfolio';
import { If } from '@/neos/components/share/if/if';
import { NeosSelectWithAutocomplete } from '@/neos/components/share/neosSelectWithAutocomplete/NeosSelectWithAutocomplete';
import { lowerThenCamelCase, toUpperCase } from '@/util/string/stringUtils';
import type { FocusEventHandler } from 'react';
import { FormSelect } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import type { DeliveryType } from '../../../business/rfq/strategy/strategyModel';
import { NeosBlurInput } from '../../share/blurComponent/NeosBlurInput';
import { ErrorHighlight } from '../../share/errorHighlight';
import { Broker } from './Broker';
import { CrossBadge } from './CrossBadge';
import { DeterminationFields } from './DeterminationFields';
import styles from './StrategyDetails.module.scss';
import { getBrokerModel } from './getBrokerModel';
import { getDeterminationFieldsModel } from './getDeterminationFieldsModel';
import type { StrategyDetailsModel } from './getStrategyDetailsModel';

export interface StrategyDetailsOwnProps {
  rfqId: string;
  strategyId: string;
}

export interface StrategyDetailsMapStateToProps {
  model: StrategyDetailsModel;
}

export interface StrategyDetailsDispatchProps {
  onTraderGroupChanged(traderGroupName: string | undefined): void;

  onTraderChanged(traderId: string | undefined): void;

  onCommentChanged(comment: string | undefined): void;

  onStepChanged(): void;

  onBookingStepApplicationChanged(
    bookingStepApplication: BookingStepApplication | undefined,
    bookingUuid: string,
  ): void;

  onBookingIdChanged(bookingId: string, bookingUuid: string): void;

  onDeliveryTypeChanged(deliveryType: DeliveryType | undefined): void;

  onEtdDeskCityChanged(etdDeskCity: string | undefined): void;

  onExecutorsChanged(executor: string | undefined): void;
}

type StrategyDetailsProps = StrategyDetailsOwnProps &
  StrategyDetailsMapStateToProps &
  StrategyDetailsDispatchProps;

export function StrategyDetailsComponentWithStyle(props: StrategyDetailsProps) {
  const { isBrokerFieldDisplayed } = useAppSelector(state =>
    getBrokerModel(state, props.strategyId, selectors),
  );
  const { isElsDetermination, isClsDetermination } = useAppSelector(state =>
    getDeterminationFieldsModel(state, props.strategyId, selectors),
  );
  // TODO need is ELS and internal
  const isEls = useAppSelector(state =>
    selectors.isElsStrategy(state, props.strategyId, selectors),
  );
  const isInternal =
    useAppSelector(state => selectors.getRfqData(state, props.rfqId)).internal === true;

  return (
    <section
      className={`${styles['strategyDetails']}
      ${props.model.isMasterStrategy ? styles['master'] : ''}
      ${
        !props.model.etdDeskCity.isHidden || !props.model.executors.isHidden
          ? styles['withEtdDeskOrExec']
          : ''
      }
      ${isBrokerFieldDisplayed ? styles['isBrokerDisplayed'] : ''}
      ${isElsDetermination ? styles['isElsDetermination'] : ''}
      ${isClsDetermination ? styles['isClsDetermination'] : ''}`}
    >
      <label>Trader Group</label>
      <ErrorHighlight
        errorField={'traderGroup'}
        related={{ rfqId: props.rfqId, strategyId: props.strategyId }}
      >
        <NeosSelectWithAutocomplete
          data-e2e="strategy-details-trader-group"
          isReadOnly={props.model.traderGroupName.isDisabled}
          value={props.model.traderGroupName.value || ''}
          onChange={selected => {
            props.onTraderGroupChanged(selected?.value || undefined);
          }}
          addEmptyOption
          options={props.model.availableTraderGroups.map(({ value }) => ({
            value,
            label: value,
          }))}
        />
      </ErrorHighlight>
      <label>Trader</label>
      <ErrorHighlight
        errorField={'trader'}
        related={{ rfqId: props.rfqId, strategyId: props.strategyId }}
      >
        <NeosSelectWithAutocomplete
          data-e2e="strategy-details-trader"
          isReadOnly={props.model.traderId.isDisabled}
          value={props.model.traderId.value || ''}
          onChange={value => {
            props.onTraderChanged(value?.value || undefined);
          }}
          addEmptyOption
          options={props.model.availableTraders.map(({ id, lastname, firstname }) => ({
            value: id,
            label: lastname + ' ' + firstname,
          }))}
        />
      </ErrorHighlight>
      {!props.model.etdDeskCity.isHidden && (
        <>
          <label>ETD Desk</label>
          <FormSelect
            value={props.model.etdDeskCity.value || ''}
            readOnly={props.model.etdDeskCity.isDisabled}
            onChange={event => props.onEtdDeskCityChanged(event.target.value || undefined)}
            data-e2e="strategy-details-etd-desk-city"
          >
            <option value="">---</option>
            {props.model.availableEtdDeskCities.map(etdDeskCity => (
              <option value={etdDeskCity} key={etdDeskCity}>
                {etdDeskCity}
              </option>
            ))}
          </FormSelect>
        </>
      )}
      {!props.model.executors.isHidden && (
        <>
          <label>Executors</label>
          <FormSelect
            value={props.model.executors.value || ''}
            readOnly={props.model.executors.isDisabled}
            onChange={event => props.onExecutorsChanged(event.target.value || undefined)}
            data-e2e="strategy-details-executor"
          >
            <option value="">---</option>
            {props.model.availableExecutors.map(executor => (
              <option value={executor} key={executor}>
                {executor}
              </option>
            ))}
          </FormSelect>
        </>
      )}
      <CrossBadge rfqId={props.rfqId} strategyId={props.strategyId} />
      <DeterminationFields strategyId={props.strategyId} rfqId={props.rfqId} />

      <label>Delivery Type</label>
      <ErrorHighlight
        errorField={'deliveryType'}
        related={{ rfqId: props.rfqId, strategyId: props.strategyId }}
      >
        <FormSelect
          className={`${styles['delivery-type-select']}`}
          value={props.model.deliveryType.value || ''}
          readOnly={props.model.deliveryType.isDisabled}
          onChange={event =>
            props.onDeliveryTypeChanged(
              (event.target.value || undefined) as DeliveryType | undefined,
            )
          }
          data-e2e="strategy-details-delivery-type"
        >
          <option value="">---</option>
          {props.model.availableDeliveryTypes.map(deliveryType => (
            <option value={deliveryType} key={deliveryType}>
              {lowerThenCamelCase(deliveryType)}
            </option>
          ))}
        </FormSelect>
      </ErrorHighlight>
      {isElsDetermination && (
        <>
          <span></span>
          <span></span>
          <span></span>
        </>
      )}
      <label>Strategy Comment</label>
      <NeosBlurInput
        className={`${styles['strategy-comment-input']} form-control-alt`}
        readOnly={props.model.isReadOnlyRfq}
        type="text"
        value={props.model.comment}
        onBlur={event => props.onCommentChanged(event.target.value || undefined)}
        data-e2e="strategy-details-comment"
      />
      <Broker strategyId={props.strategyId} isReadOnlyRfq={props.model.isReadOnlyRfq} />
      <Portfolio strategyId={props.strategyId} rfqId={props.rfqId} />
      <label>Booking Id</label>
      <FormSelect
        data-e2e="strategy-details-booking-type"
        value={props.model.bookingApplication.value || ''}
        onChange={event =>
          props.model.bookingStep &&
          props.onBookingStepApplicationChanged(
            (event.target.value || undefined) as BookingStepApplication | undefined,
            props.model.bookingStep.parentBookingUuid,
          )
        }
        readOnly={props.model.bookingApplication.isDisabled}
      >
        <option value="">---</option>
        {availableBookingApplications.map(bookingApplication => (
          <option value={bookingApplication} key={bookingApplication}>
            {lowerThenCamelCase(bookingApplication)}
          </option>
        ))}
      </FormSelect>
      {!props.model.displayBookingIdByAlloc ? (
        <NeosBlurInput
          readOnly={props.model.bookingId.isDisabled}
          data-e2e="strategy-details-booking-id"
          type="text"
          value={props.model.bookingId.value}
          onBlur={event =>
            props.model.bookingStep &&
            props.onBookingIdChanged(event.target.value, props.model.bookingStep.parentBookingUuid)
          }
        />
      ) : (
        <div className="d-flex justify-content-center">
          <button
            data-e2e="neos-switch-nego-step"
            className="badge bg-primary p-1 align-self-center"
            onClick={() => props.onStepChanged()}
          >
            Details
          </button>
        </div>
      )}
      <If condition={isEls && isInternal}>
        <CounterpartPortfolio strategyId={props.strategyId} />
      </If>
    </section>
  );
}
interface CounterPortfolioProps {
  strategyId: string;
}
export function CounterpartPortfolio({ strategyId }: CounterPortfolioProps) {
  const dispatch = useDispatch();
  const strategy = useAppSelector(state => selectors.getStrategyData(state, strategyId));

  const onMirrorPortfolioChanged = (mirrorPortfolioId: string | undefined) => {
    dispatch(neosActionCreators.strategyDataCrudActions.update(strategyId, { mirrorPortfolioId }));
  };

  const onBlurEvent: FocusEventHandler<HTMLInputElement | undefined> = event => {
    const newValue = event.target.value !== '' ? event.target.value : undefined;
    onMirrorPortfolioChanged(newValue);
  };

  return (
    <>
      <label>Counterpart Ptf</label>
      <NeosBlurInput
        className={`${styles['strategy-comment-input']} form-control-alt`}
        type="text"
        value={strategy.mirrorPortfolioId}
        onBlur={onBlurEvent}
        transform={toUpperCase}
        data-e2e="strategy-details-mirror-portfolio"
      />
    </>
  );
}
