import { ErrorHighlight } from '@/neos/components/share/errorHighlight';
import { NumericInput } from '@/neos/components/share/numericInput';
import type { FC } from 'react';
import type { ForwardDriftModel } from './getForwardDriftModel';

export interface ForwardDriftCellsOwnProps {
  rfqId: string;
  strategyId: string;
}

export interface ForwardDriftCellsMapStateProps {
  model: ForwardDriftModel;
}

export interface ForwardDriftCellsDispatchProps {
  onForwardDriftChanged(legId: string, forwardDrift: number | undefined): void;
}

type ForwardDriftCellsProps = ForwardDriftCellsOwnProps &
  ForwardDriftCellsMapStateProps &
  ForwardDriftCellsDispatchProps;

export const ForwardDriftCellsComponent: FC<ForwardDriftCellsProps> = ({
  rfqId,
  strategyId,
  model,
  onForwardDriftChanged,
}) => {
  return (
    <section>
      {model.forwardDrifts.map(({ id: legId, value, isEditable }) => (
        <ErrorHighlight
          key={legId}
          errorField={'forwardDrift'}
          related={{ rfqId, strategyId, legId }}
        >
          <NumericInput
            readOnly={!isEditable}
            value={value}
            onBlur={val => onForwardDriftChanged(legId, val)}
            data-e2e="neos-strategy-definition-forwardDrift"
            className="errorable-bloc"
          />
        </ErrorHighlight>
      ))}
    </section>
  );
};
