import type { ActionContainer } from '@/util/actionContainer';
import type { RecomputeScope } from '../models/recomputeModel';
import { strategyActionCreators } from '../strategy/strategyActionCreators';
import { createActionWithRecompute } from './actionWithRecompute';

export function createRfqStrategyWeightChangedAction(
  rfqId: string,
  strategyId: string,
  scope: RecomputeScope,
  weight: number | undefined,
): ActionContainer {
  return createActionWithRecompute(rfqId, scope, [
    strategyActionCreators.strategyDataCrudActions.update(strategyId, { weight }),
  ]);
}
