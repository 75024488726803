import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import type { DeltaType, NegotiationMode, StrategyType } from '../../../../neos/business/neosModel';
import {
  ShortcutsComponent,
  type ShortcutsDispatchProps,
  type ShortcutsOwnProps,
  type ShortcutsStateProps,
} from './Shortcuts';
import { getShortcutsModel } from './getShortcutsModel';

function mapStateToProps(state: AppState, { rfqId }: ShortcutsOwnProps): ShortcutsStateProps {
  return getShortcutsModel(state, rfqId, selectors);
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId }: ShortcutsOwnProps,
): ShortcutsDispatchProps {
  return {
    onUnderlyingSelected: (underlyingId: string, forceApplyToAll: boolean) =>
      dispatch(
        neosThunks.createRfqSelectUnderlyingShortcutThunk(rfqId, underlyingId, forceApplyToAll),
      ),
    onStrategySelected: (strategyType: StrategyType, forceApplyToAll: boolean) => {
      if (forceApplyToAll && strategyType === 'ELS') {
        return;
      }
      dispatch(
        neosThunks.createRfqSelectStrategyTypeShortcutThunk(rfqId, strategyType, forceApplyToAll),
      );
    },
    onDeltaTypeSelected: (deltaType: DeltaType, forceApplyToAll: boolean) =>
      dispatch(neosThunks.createRfqSelectDeltaTypeShortcutThunk(rfqId, deltaType, forceApplyToAll)),
    onNegotiationModeChange: (negotiationMode: NegotiationMode, forceApplyToAll: boolean) =>
      dispatch(
        neosThunks.createRfqSelectNegotiationModeShortcutThunk(
          rfqId,
          negotiationMode,
          forceApplyToAll,
        ),
      ),
  };
}

export const Shortcuts = connect(mapStateToProps, mapDispatchToProps)(ShortcutsComponent);
