import { SgPicker } from '@sg-bootstrap/components/dist/react-library/src/components';
import { useEffect, useRef } from 'react';

type SgPickerOption = {
  key: string;
  label: string;
};

type NeosSgPickerProps = {
  value: string | undefined;
  options: SgPickerOption[];
  maxDisplayedItems?: number;
  classes?: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  addEmptyValue?: boolean;
  isLabelBold?: boolean;
  'data-e2e'?: string;
  onChange: (value: SgPickerOption['key'] | null) => void;
};

export function NeosSgPicker({
  value,
  options,
  onChange,
  classes,
  isReadOnly,
  isDisabled,
  addEmptyValue,
  isLabelBold,
  maxDisplayedItems,
  'data-e2e': dataE2e = '',
}: NeosSgPickerProps) {
  const sgPickerRef = useRef<HTMLSgPickerElement>(null);

  useEffect(() => {
    sgPickerRef.current?.setItems(options);
  }, [options]);

  useEffect(() => {
    const label = options.find(option => option.key === value)?.label;
    if (value && label) {
      sgPickerRef.current?.setValue(label);
    }
  }, [options, value]);

  return (
    <SgPicker
      ref={sgPickerRef}
      onItemsSelected={selectedItems => {
        // Get first element because single-select is used
        const selectedItem = selectedItems.detail.items.at(0);
        onChange(selectedItem ? String(selectedItem.key) : null);
      }}
      data-e2e={dataE2e}
      onReady={() => {
        sgPickerRef.current?.setItems(options);
      }}
      className={`${classes} ${isLabelBold ? 'fw-bold' : ''}`}
      noIcon
      onClear={() => {
        onChange(null);
      }}
      noClear={!addEmptyValue}
      placeholder=""
      singleSelect
      appendTo="body"
      disabled={isDisabled || isReadOnly}
      maxDisplayedItems={maxDisplayedItems}
    />
  );
}
