import { hasDiscriminator } from '@/neos/business/rfq/strategy/feature/hasDiscriminator';
import type { WithExtraProperty } from '@/neos/business/rfq/strategy/feature/withExtraProperty';
import { convertNullToUndefined } from '@/util/undefinedAndNull/convertNullToUndefined';
import {
  type ResetFrequencyFeature,
  resetFrequencies,
} from '../../../../../../neos/business/neosModel';
import type {
  OnyxProductFeaturesFields,
  OnyxResetFrequency,
} from '../../../../../../neos/business/neosOnyxModel';

export function mapFromOnyxFeatureOrExtraFeaturesToResetFrequency(
  { extraFeatures, resetFrequency: definitionResetFrequency }: OnyxProductFeaturesFields,
  strategyId: string,
): ResetFrequencyFeature | undefined {
  const mappedResetFrequency: Pick<ResetFrequencyFeature, 'strategyId' | 'type'> = {
    strategyId,
    type: 'RESET_FREQUENCY_FEATURE',
  };
  const resetFrequency = convertNullToUndefined(definitionResetFrequency);
  if (resetFrequency !== undefined) {
    return {
      ...mappedResetFrequency,
      resetFrequency: resetFrequencies.find(frequency => frequency.value === resetFrequency),
    };
  }

  if (extraFeatures) {
    const resetFrequencyFeature = extraFeatures.find(
      hasDiscriminator<OnyxResetFrequency>('RESET_FREQUENCY'),
    );
    if (resetFrequencyFeature) {
      return {
        ...mappedResetFrequency,
        resetFrequency: resetFrequencyFeature
          ? resetFrequencies.find(
              frequency => frequency.value === resetFrequencyFeature.resetFrequency,
            )
          : undefined,
      };
    }
  }

  return undefined;
}

export function mapToOnyxResetFrequencyFeature(
  feature: WithExtraProperty<ResetFrequencyFeature>,
): OnyxProductFeaturesFields {
  const { resetFrequency } = feature;

  if (!feature.isExtra) {
    return {
      resetFrequency: resetFrequency?.value,
      extraFeatures: [],
    };
  }
  return {
    extraFeatures: resetFrequency
      ? [{ discriminator: 'RESET_FREQUENCY', resetFrequency: resetFrequency.value }]
      : [],
  };
}
