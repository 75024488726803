import type {
  FeatureDefinition,
  ObservationConvention,
} from '../../../../../neos/business/neosModel';
import type {
  OnyxExtraFeatureDiscriminator,
  OnyxExtraFeaturesFields,
  OnyxStrategyDefinitionProduct,
} from '../../../../../neos/business/neosOnyxModel';

export function mapUpDownFeature(
  { up, down, extraFeatures }: OnyxStrategyDefinitionProduct,
  defaultObservationConvention?: ObservationConvention,
): FeatureDefinition[] {
  if (up === null && down === null) {
    return [
      {
        type: 'UP_DOWN',
        isExtra: false,
        defaultObservationConvention,
      },
    ];
  }

  if (!extraFeatures) {
    return [];
  }

  if (
    extraFeatures.some(hasDiscriminator('UP_CONTAINER')) &&
    extraFeatures.some(hasDiscriminator('DOWN_CONTAINER'))
  ) {
    return [
      {
        type: 'UP_DOWN',
        isExtra: true,
        defaultObservationConvention,
      },
    ];
  }
  return [];
}

export function mapCapFloorFeature({
  extraFeatures,
}: OnyxStrategyDefinitionProduct): FeatureDefinition[] {
  if (
    extraFeatures &&
    extraFeatures.some(hasDiscriminator('CAP_CONTAINER')) &&
    extraFeatures.some(hasDiscriminator('FLOOR_CONTAINER'))
  ) {
    return [
      {
        type: 'CAP_FLOOR',
        isExtra: true,
      },
    ];
  }
  return [];
}

export function mapBarrierFeature({
  barriers,
  extraFeatures,
}: OnyxStrategyDefinitionProduct): FeatureDefinition[] {
  if (extraFeatures && extraFeatures.some(hasDiscriminator('BARRIER_CONTAINER'))) {
    return [
      {
        type: 'BARRIERS',
        isExtra: true,
      },
    ];
  }
  if (barriers === null || Array.isArray(barriers)) {
    return [
      {
        type: 'BARRIERS',
        isExtra: false,
      },
    ];
  }
  return [];
}

export function mapExecFeesExtraFeature({
  extraFeatures,
}: OnyxStrategyDefinitionProduct): FeatureDefinition[] {
  if (extraFeatures && extraFeatures.some(hasDiscriminator('EXEC_FEES'))) {
    return [
      {
        type: 'EXEC_FEES',
        isExtra: true,
      },
    ];
  }

  return [];
}

export function mapRateOvernightFeature({
  extraFeatures,
  rateOvernight,
}: OnyxStrategyDefinitionProduct): FeatureDefinition[] {
  if (extraFeatures && extraFeatures.some(hasDiscriminator('RATE_OVERNIGHT'))) {
    return [
      {
        type: 'RATE_OVERNIGHT',
        isExtra: true,
      },
    ];
  }

  if (rateOvernight === null) {
    return [
      {
        type: 'RATE_OVERNIGHT',
        isExtra: false,
      },
    ];
  }

  return [];
}

export function mapRateBulletFeature({
  extraFeatures,
  rateBullet,
}: OnyxStrategyDefinitionProduct): FeatureDefinition[] {
  if (extraFeatures && extraFeatures.some(hasDiscriminator('RATE_BULLET'))) {
    return [
      {
        type: 'RATE_BULLET',
        isExtra: true,
      },
    ];
  }

  if (rateBullet === null) {
    return [
      {
        type: 'RATE_BULLET',
        isExtra: false,
      },
    ];
  }

  return [];
}

export function mapEquityBulletFeature({
  extraFeatures,
  equityBullet,
}: OnyxStrategyDefinitionProduct): FeatureDefinition[] {
  if (extraFeatures && extraFeatures.some(hasDiscriminator('EQUITY_BULLET'))) {
    return [
      {
        type: 'EQUITY_BULLET',
        isExtra: true,
      },
    ];
  }

  if (equityBullet === null) {
    return [
      {
        type: 'EQUITY_BULLET',
        isExtra: false,
      },
    ];
  }

  return [];
}

export function mapResetFrequencyFeature({
  extraFeatures,
  resetFrequency,
}: OnyxStrategyDefinitionProduct): FeatureDefinition[] {
  if (extraFeatures && extraFeatures.some(hasDiscriminator('RESET_FREQUENCY'))) {
    return [
      {
        type: 'RESET_FREQUENCY_FEATURE',
        isExtra: true,
      },
    ];
  }

  if (resetFrequency === null) {
    return [
      {
        type: 'RESET_FREQUENCY_FEATURE',
        isExtra: false,
      },
    ];
  }

  return [];
}

export function mapInterestRateFeature({
  extraFeatures,
  rateCurve,
  rateTenor,
}: OnyxStrategyDefinitionProduct): FeatureDefinition[] {
  if (extraFeatures && extraFeatures.some(hasDiscriminator('INTEREST_RATE_INDEX'))) {
    return [
      {
        type: 'INTEREST_RATE_INDEX_FEATURE',
        isExtra: true,
      },
    ];
  }

  if (rateCurve === null || rateTenor === null) {
    return [
      {
        type: 'INTEREST_RATE_INDEX_FEATURE',
        isExtra: false,
      },
    ];
  }

  return [];
}

export function mapForexTypeFeature({
  extraFeatures,
  forexType,
}: OnyxStrategyDefinitionProduct): FeatureDefinition[] {
  if (extraFeatures && extraFeatures.some(hasDiscriminator('FOREX_TYPE'))) {
    return [
      {
        type: 'FOREX_TYPE',
        isExtra: true,
      },
    ];
  }

  if (forexType === null) {
    return [
      {
        type: 'FOREX_TYPE',
        isExtra: false,
      },
    ];
  }

  return [];
}

export function mapForwardStartFeature({
  extraFeatures,
  forwardStartDate,
}: OnyxStrategyDefinitionProduct): FeatureDefinition[] {
  if (extraFeatures && extraFeatures.some(hasDiscriminator('FORWARD_START'))) {
    return [
      {
        type: 'FORWARD_START',
        isExtra: true,
      },
    ];
  }

  if (forwardStartDate === null) {
    return [
      {
        type: 'FORWARD_START',
        isExtra: false,
      },
    ];
  }

  return [];
}

export function mapDescriptionFeature({
  description,
}: OnyxStrategyDefinitionProduct): FeatureDefinition[] {
  if (description === null) {
    return [
      {
        type: 'DESCRIPTION',
        isExtra: false,
      },
    ];
  }

  return [];
}

export function mapEndOfObservationFeature({
  endOfObservation,
}: OnyxStrategyDefinitionProduct): FeatureDefinition[] {
  if (endOfObservation === null) {
    return [
      {
        type: 'END_OF_OBSERVATION',
        isExtra: false,
      },
    ];
  }

  return [];
}

function hasDiscriminator(discriminatorToMatch: OnyxExtraFeatureDiscriminator) {
  return ({ discriminator }: OnyxExtraFeaturesFields) => discriminator === discriminatorToMatch;
}
