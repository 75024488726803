import type {
  ErrorableAllUi,
  ErrorableAllUiRelated,
} from '@/neos/business/mappers/error/errorHandlerData';
import { NeosTooltip } from '@/neos/components/share/tooltip/NeosTooltip';
import {
  Children,
  Component,
  type HTMLAttributes,
  type MouseEvent,
  type ReactElement,
  cloneElement,
  isValidElement,
} from 'react';
import { Tooltip } from 'react-bootstrap';

export interface ErrorHighlightOwnProps<T extends ErrorableAllUi> {
  errorField: T;
  related: ErrorableAllUiRelated[T];
  children: any;
  disableError?: boolean;
  className?: string;
}

export interface ErrorHighlightPropsFromState {
  isError: boolean;
  errorMessages: string[];
}

export interface ErrorHighlightDispatchProps {
  onChange(): void;
  onClick?(event: MouseEvent<any, MouseEvent>): void;
}

export type ErrorHighlightProps<T extends ErrorableAllUi> = ErrorHighlightOwnProps<T> &
  ErrorHighlightDispatchProps &
  ErrorHighlightPropsFromState;

const filedErrorClassName = 'field-error';
export class ErrorHighlightUnconnected<T extends ErrorableAllUi> extends Component<
  ErrorHighlightProps<T>
> {
  constructor(props: ErrorHighlightProps<T>) {
    super(props);
  }

  public render() {
    const {
      isError,
      disableError,
      children,
      onChange,
      onClick,
      errorField,
      errorMessages,
      related,
      className,
      ...restProps
    } = this.props;
    return Children.map(children as ReactElement[], (c: ReactElement) => {
      const childProps = c?.props as HTMLAttributes<any>;
      if (!isValidElement(c)) {
        // eslint-disable-next-line no-console
        console.warn('should use a valid element, no string, undefined or null');
        return c;
      }
      const newProps: HTMLAttributes<any> = {
        ...restProps,
        className: childProps.className
          ? childProps.className + ' ' + filedErrorClassName
          : filedErrorClassName,
        onChange: (...args) => {
          onChange();
          return childProps.onChange && childProps.onChange(...args);
        },
      };

      return isError && !disableError ? (
        <NeosTooltip
          placement="top"
          overlay={
            <Tooltip className="react-bootstrap-danger-tooltip" id={errorField}>
              {errorMessages.map((errorMessage, i) => {
                return <p key={i}>{errorMessage}</p>;
              })}
            </Tooltip>
          }
        >
          <span>{cloneElement(c, newProps)}</span>
        </NeosTooltip>
      ) : (
        isValidElement(c) && cloneElement(c, restProps)
      );
    });
  }
}
