import type { ObservationConvention } from '../../../../../../business/neosModel';
import { ErrorHighlight } from '../../../../../share/errorHighlight';
import { MultiToggleButton } from '../../../../../share/multiToggleButton/MultiToggleButton';
import { NumericInput } from '../../../../../share/numericInput';
import { CloseButton } from '../closeButton';
import type { UpDownModel } from './getUpDownModel';

export interface OwnProps {
  rfqId: string;
  strategyId: string;
}

export interface StateProps {
  model: UpDownModel;
}

export interface DispatchProps {
  onUpStrikeChanged(strike: number | undefined): void;
  onDownStrikeChanged(strike: number | undefined): void;
  onObsChanged(observationConvention: ObservationConvention | undefined): void;
  onUpDownUnitChanged(unit: string | undefined): void;
}

export type Props = OwnProps & StateProps & DispatchProps;

export const UpDown = ({
  rfqId,
  strategyId,
  model,
  onUpStrikeChanged,
  onDownStrikeChanged,
  onUpDownUnitChanged,
  onObsChanged,
}: Props) => {
  if (!model.isDisplayed) {
    return <div />;
  }

  const {
    upStrikeValue,
    downStrikeValue,
    unit,
    observationConvention,
    availableObservationConventions,
  } = model;

  const unitChangeHandler = () => onUpDownUnitChanged(unit);
  return (
    <div className="d-flex ms-1 mb-1 p-1 border fit-content">
      <div
        className="d-grid gap-1"
        style={{ gridTemplateColumns: 'repeat(2, minmax(110px, 140px)) 66px' }}
      >
        <span className="fw-bold">Up</span>
        <span className="fw-bold">Down</span>
        <span className="fw-bold">Obs</span>
        <ErrorHighlight errorField={'up'} related={{ rfqId, strategyId }}>
          <NumericInput
            className="errorable-bloc"
            value={upStrikeValue}
            unit={unit}
            onBlur={onUpStrikeChanged}
            onUnitClick={unitChangeHandler}
            data-e2e="neos-extra-features-upDown-up"
          />
        </ErrorHighlight>
        <ErrorHighlight errorField={'down'} related={{ rfqId, strategyId }}>
          <NumericInput
            className="errorable-bloc"
            value={downStrikeValue}
            unit={unit}
            onBlur={onDownStrikeChanged}
            onUnitClick={unitChangeHandler}
            data-e2e="neos-extra-features-upDown-down"
          />
        </ErrorHighlight>
        <ErrorHighlight errorField={'observationConvention'} related={{ rfqId, strategyId }}>
          <MultiToggleButton<ObservationConvention | undefined>
            className="errorable-bloc form-control"
            selectedValue={observationConvention}
            availableValues={[...availableObservationConventions, undefined]}
            valueFormatter={formatObs}
            onValueChange={onObsChanged}
            data-e2e="neos-extra-features-upDown-obs-toggle"
          />
        </ErrorHighlight>
      </div>
      <CloseButton
        strategyId={strategyId}
        type={'UP_DOWN'}
        data-e2e="neos-extra-features-close-button-upDown"
      />
    </div>
  );
};

function formatObs(obs: ObservationConvention | undefined): string {
  return obs === 'T_MINUS_1' ? 'T-1' : obs === 'T_MINUS_1_AND_T' ? 'T-1 / T' : '';
}
