import { cn } from '@/util/classNames';
import type { RefDeltaTabsModel } from './getRefDeltaTabsModel';

export interface RefDeltaTabsOwnProps {
  rfqId: string;
}

export type RefDeltaTabsMapStateToProps = RefDeltaTabsModel;

export interface RefDeltaTabsDispatchProps {
  onDeltaAdjustedSelectionChanged(isDeltaAdjustedSelected: boolean): void;
}

export type RefDeltaTabsProps = RefDeltaTabsOwnProps &
  RefDeltaTabsMapStateToProps &
  RefDeltaTabsDispatchProps;

export const RefDeltaTabsComponentStyled = ({
  isDeltaAdjustedSelected,
  hasDeltaAdjusted,
  hasWarning,
  onDeltaAdjustedSelectionChanged,
}: RefDeltaTabsProps) => {
  return (
    <>
      <ul
        className="nav nav-vertical nav-underline text-nowrap bg-lvl1 shadow-sm"
        style={{ minWidth: '70px' }}
      >
        <li className="nav-item">
          <a
            className={cn('nav-link', { active: !isDeltaAdjustedSelected })}
            data-e2e="rfq-ref-tab-ref"
            onClick={() => isDeltaAdjustedSelected && onDeltaAdjustedSelectionChanged(false)}
          >
            Ref
          </a>
        </li>
        <li className="nav-item">
          <a
            className={cn('nav-link ', {
              active: isDeltaAdjustedSelected,
              disabled: !hasDeltaAdjusted,
            })}
            data-e2e="rfq-ref-tab-delta-adjusted"
            onClick={() =>
              !isDeltaAdjustedSelected && hasDeltaAdjusted && onDeltaAdjustedSelectionChanged(true)
            }
          >
            Δ Adj
            {hasWarning && (
              <i data-e2e={`adjusted-strategy-warning`} className="icon icon-sm text-warning ms-2">
                warning
              </i>
            )}
          </a>
        </li>
      </ul>
    </>
  );
};
