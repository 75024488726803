import type { Thunk } from '@/bootstrap/thunks';

export function createStrategyDeleteLegsThunk(legIds: string[], resetTop: boolean): Thunk {
  return function strategyDeleteLegsThunk(
    dispatch,
    getState,
    {
      selectors: { getLegData },
      thunks: {
        neos: { createDeleteLegsThunk, createResetTopAndDispatchThunk },
      },
      actionCreators: {
        neos: { strategyDataCrudActions },
      },
    },
  ) {
    if (!legIds.length) {
      return;
    }

    const appState = getState();

    dispatch(
      legIds.map(legId => {
        const { strategyId } = getLegData(appState, legId);

        const action = resetTop ? [createResetTopAndDispatchThunk(legId)] : [];
        return [
          action,
          strategyDataCrudActions.arrayRemove(strategyId, {
            property: 'legIds',
            value: legId,
          }),
        ];
      }),
      createDeleteLegsThunk(legIds),
    );
  };
}
