import type { Thunk } from '@/bootstrap/thunks';
import { type StrategyData, isRfqStrategyData } from '../models';
import { buildParameterWithParentIdAsQuoteId } from '../quotes/quoteActionCreator';

export interface SourceTarget {
  sourceId: string;
  targetId: string;
}

export function createCloneRfqStrategiesThunk(
  rfqTargetId: string,
  strategySourceTargets: SourceTarget[],
): Thunk {
  return function cloneRfqStrategiesThunk(
    dispatch,
    getState,
    {
      selectors: { getStrategyData, getQuote },
      actionCreators: {
        neos: { strategyDataCrudActions, createDefaultQuotesAddedAction },
      },
      thunks: {
        neos: { createCloneLegRfqThunk, createCloneStrategyFeaturesThunk },
      },
      services: { idCreator },
    },
  ) {
    const state = getState();

    function createStrategyClone(strategySourceTarget: SourceTarget): StrategyClone {
      const strategyData = getStrategyData(state, strategySourceTarget.sourceId);
      if (!isRfqStrategyData(strategyData)) {
        throw new Error(`Can't clone deltaStrategy`);
      }

      const strategyCloneId = strategySourceTarget.targetId;
      const legSourceTargets = strategyData.legIds.map(legId => ({
        sourceId: legId,
        targetId: idCreator.createId(),
      }));

      const {
        rfqId,
        uuid,
        quoteId,
        legIds,
        fairPriceId,
        valueDate,
        etdDeskCity,
        crossPartially,
        initialPriceDetermination,
        determinationMethod,
        ...strategyDataFieldsToClone
      } = strategyData;
      return {
        strategySourceTarget: {
          sourceId: strategySourceTarget.sourceId,
          targetId: strategyCloneId,
        },
        legSourceTargets,
        strategyDataClone: {
          rfqId: rfqTargetId,
          uuid: strategyCloneId,
          quoteId: strategyCloneId,
          legIds: legSourceTargets.map(sourceTarget => sourceTarget.targetId),
          etdDeskCity: undefined,
          crossPartially: undefined,
          initialPriceDetermination: undefined,
          determinationMethod: undefined,
          ...strategyDataFieldsToClone,
        },
      };
    }

    const strategyClones: StrategyClone[] = strategySourceTargets.map(strategySourceTarget =>
      createStrategyClone(strategySourceTarget),
    );

    dispatch(
      createCloneLegRfqThunk(
        strategyClones.map(strategyClone => ({
          strategyTargetId: strategyClone.strategySourceTarget.targetId,
          legSourceTargets: strategyClone.legSourceTargets,
        })),
      ),
    );

    const arrayOfParentIdsAndQuoteIds = strategySourceTargets.map(strategySourceTarget => {
      const { quoteId } = getStrategyData(state, strategySourceTarget.sourceId);
      const { unit, salesAskType } = getQuote(state, quoteId);
      return buildParameterWithParentIdAsQuoteId(
        'Strategy',
        strategySourceTarget.targetId,
        unit,
        salesAskType,
      );
    });

    dispatch(
      createDefaultQuotesAddedAction(arrayOfParentIdsAndQuoteIds),
      ...strategyClones.map(clone =>
        strategyDataCrudActions.insert(
          clone.strategySourceTarget.targetId,
          clone.strategyDataClone,
        ),
      ),
    );

    strategyClones.forEach(({ strategySourceTarget }: StrategyClone) =>
      dispatch(
        createCloneStrategyFeaturesThunk(
          rfqTargetId,
          strategySourceTarget.sourceId,
          strategySourceTarget.targetId,
        ),
      ),
    );
  };
}

interface StrategyClone {
  strategySourceTarget: SourceTarget;
  legSourceTargets: SourceTarget[];
  strategyDataClone: StrategyData;
}
