import { type Selectors, selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { neosThunks } from '@/neos/business/thunks';
import { isListedProduct } from '../../../../../business/neosModel';
import { FlowDatePickerWithOffset } from '../../../../share/datePicker/FlowDatePickerWithOffset';
import { useFeatureToggle } from '@/neos/components/share/featureToggle/useFeatureToggle';
import { useDispatch, useSelector } from 'react-redux';
import type { LegDescriptionComponentProps } from './AveragePrice';

export function getLegSettlementDate(state: AppState, legId: string, selectors: Selectors) {
  const { productId, settlement } = selectors.getLegData(state, legId);
  const product = selectors.getProduct(state, productId);
  if (isListedProduct(product)) {
    return undefined;
  }
  return settlement?.date;
}

export function SettlementDate({ rfqId, legId }: LegDescriptionComponentProps) {
  const settlementDateToggle = useFeatureToggle('neos.cls.settlement.value.date.enabled');

  const settlementDate = useSelector((state: AppState) =>
    getLegSettlementDate(state, legId, selectors),
  );
  const dispatch = useDispatch();

  function onOffsetChange(offset: number) {
    dispatch(neosThunks.createUpdateSettlementDateOffsetThunk(rfqId, legId, offset));
  }

  function onDateChange(date: string) {
    dispatch(neosThunks.createUpdateSettlementDateThunk(rfqId, legId, date));
  }

  if (!(settlementDate && settlementDateToggle)) {
    return null;
  }

  return (
    <div>
      <label className="mb-0 me-3">Settlement Date</label>
      <FlowDatePickerWithOffset
        data-e2e="settlement-date"
        date={settlementDate.value ?? ''}
        onChange={onDateChange}
        onOffsetChange={onOffsetChange}
        currentOffset={settlementDate.offset}
      />
    </div>
  );
}
