import type { BlotterUiState } from './blotterUiState';

export const blotterUiSelectors = {
  getPendingRfqIds,
  isBlotterPinned,
  getBlotterPosition,
};

function getPendingRfqIds(state: BlotterUiState) {
  return state.pendingRfqIds || [];
}

function isBlotterPinned(state: BlotterUiState) {
  return state.position !== 'CENTER';
}

function getBlotterPosition(state: BlotterUiState) {
  return state.position;
}
