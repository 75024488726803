import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { Counterpart } from '../../../../../../neos/business/neosModel';
export interface TradeRecapButtonModel {
  tradeRecap: string;
  isTradeRecapButtonEnabled: boolean;
  isShowTradeRecapModal: boolean;
  isDraftRecapMailButtonEnabled: boolean;
}

export function getTradeRecapButtonModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): TradeRecapButtonModel {
  const isLoading = selectors.isRfqLoading(state.ui, rfqId);
  const isShowTradeRecapModal = selectors.isShowTradeRecapModal(state.ui, rfqId) && !isLoading;
  const rfqData = selectors.getRfqData(state, rfqId);
  const isTradeRecapButtonEnabled = selectors.isTradeRecapEnabled(state, rfqId, selectors);

  const tradeRecap = rfqData.tradeRecap || '';
  const quoteRecap = rfqData.quoteRecap || '';
  const counterpart = getRfqCounterpart(state, rfqId, selectors)!;

  const commonPartOfTheModel = {
    tradeRecap,
    isShowTradeRecapModal,
    isTradeRecapButtonEnabled,
  };

  if (tradeRecap && counterpart && quoteRecap) {
    return {
      ...commonPartOfTheModel,
      isDraftRecapMailButtonEnabled: tradeRecap !== '',
    };
  }

  return {
    ...commonPartOfTheModel,
    isDraftRecapMailButtonEnabled: false,
  };
}

export function getRfqCounterpart(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): Counterpart | undefined {
  const counterpartId = selectors.getSelectedCounterpartId(state, rfqId);
  const counterparts = selectors.getCounterparts(state, rfqId) || [];
  return counterparts.find(({ id }: Counterpart) => id === counterpartId);
}
