import { useAppSelector } from '@/bootstrap/hooks';
import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';
import { createCreateAllocationsSecondaryEventThunk } from '@/neos/business/thunks/createAllocationsSecondaryEventThunk';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { getCreateAllocSecondaryButtonModel } from './getCreateAllocSecondaryButtonModel';

type CreateAllocSecondaryButtonProps = {
  rfqId: string;
};

export const CreateAllocSecondaryButton = ({ rfqId }: CreateAllocSecondaryButtonProps) => {
  const dispatch = useDispatch();
  const { isDisplayed, isDisabled } = useAppSelector(state =>
    getCreateAllocSecondaryButtonModel(state, rfqId, selectors),
  );

  function onCreateSecondaryEvent(rfqId: string) {
    dispatch([
      createCreateAllocationsSecondaryEventThunk(rfqId),
      createLogAnalyticsAction(
        'NEOS RFQ',
        'Secondary RFQ opened from create alloc secondary events button',
      ),
    ]);
  }

  if (!isDisplayed) {
    return null;
  }

  return (
    <Button
      data-e2e="create-otc-alloc-secondary-event"
      disabled={isDisabled}
      onClick={() => onCreateSecondaryEvent(rfqId)}
    >
      Create alloc secondary events
    </Button>
  );
};
