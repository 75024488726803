import { actionCreators } from '@/bootstrap/actions';
import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import { useDispatch, useSelector } from 'react-redux';

interface DownloadFileProps {
  rfqId: string;
  allocId: string;
  isManuallyUpload: boolean;
}

export function DownloadAllocationFile({ rfqId, allocId, isManuallyUpload }: DownloadFileProps) {
  const dispatch = useDispatch();

  const preConfStep = useSelector((state: AppState) =>
    selectors.preConfirmationStepSelectors.find(state.preConfirmationStep, {
      rfqId,
      uuid: allocId,
    }),
  );

  const canDownload = preConfStep?.status === 'GENERATED' || preConfStep?.status === 'UPLOADED';
  const canRedirect = preConfStep?.status === 'EXTERNAL_STORED';
  const isPreconfFailed =
    preConfStep?.status === 'FAILED' || preConfStep?.status === 'EXTERNAL_STORE_FAILED';
  const isLoading = preConfStep?.status === 'PENDING';

  if (!canDownload && !canRedirect && !isPreconfFailed && !isLoading) {
    return <div className="btn btn-icon" style={{ cursor: 'default' }}></div>;
  }

  if (isPreconfFailed) {
    const message = preConfStep?.message ?? 'Failed';
    return (
      <SimpleNeosTooltip type="danger" message={message} id="tooltip-download">
        <div className="btn btn-icon" style={{ cursor: 'default' }}>
          <i className="icon icon-sm text-danger align-self-center">file_download_off</i>
        </div>
      </SimpleNeosTooltip>
    );
  }

  if (isLoading) {
    return (
      <button className="btn btn-discreet-primary loading">
        <span className="loader" />
      </button>
    );
  }

  return (
    <SimpleNeosTooltip id="tooltip-download" message={canRedirect ? 'Open file' : 'Download file'}>
      <button
        className="btn btn-discreet-primary btn-icon"
        onClick={() => {
          if (canRedirect) {
            window.open(preConfStep.preConfirmationId, '_blank')?.focus();
          } else {
            dispatch(actionCreators.neos.createDownloadAllocationFileAction(rfqId, allocId));
          }
        }}
      >
        <i className={`icon ${!isManuallyUpload ? 'icon-outlined' : ''}`}>
          {isManuallyUpload ? 'save' : canRedirect ? 'file_open' : 'file_download'}
        </i>
      </button>
    </SimpleNeosTooltip>
  );
}
