import type { OtcPreAlloc } from '../../../../neos/business/neosModel';
import type { DefaultRfqPreAllocRequested, DefaultingOptions } from '../rfqActions';

export function createDefaultRfqPreAllocRequestedAction(
  rfqId: string,
  options: DefaultingOptions,
  preAllocBeforeDefaultingRequested: OtcPreAlloc[],
): DefaultRfqPreAllocRequested {
  return {
    type: 'DEFAULT_RFQ_PRE_ALLOC_REQUESTED',
    rfqId,
    options,
    preAllocBeforeDefaultingRequested,
  };
}
