import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import { useDispatch } from 'react-redux';

export interface LegExecutionHeaderProps {
  rfqId: string;
  legId: string;
  orderId: string | undefined;
  isReadOnly: boolean;
  isManual: boolean;
  isWarningDisplayed: boolean;
  isCommFlowIdDisplayed: boolean;
}

export const LegExecutionHeader = ({
  rfqId,
  orderId,
  legId,
  isManual,
  isWarningDisplayed,
  isReadOnly,
  isCommFlowIdDisplayed,
}: LegExecutionHeaderProps) => {
  const dispatch = useDispatch<ExtendedDispatch>();

  const onExecutionAdded = (orderId: string | undefined) => {
    if (orderId) {
      dispatch(
        createLogAnalyticsAction('NEOS RFQ', `Add execution`, legId),
        neosThunks.createAddExecutionThunk(rfqId, legId, orderId),
      );
    }
  };

  return (
    <>
      {!isReadOnly && (
        <button
          className="btn btn-icon btn-flat-primary"
          onClick={() => onExecutionAdded(orderId)}
          data-e2e="leg-execution-add"
        >
          <i className="icon icon-md">add</i>
        </button>
      )}
      <label>Size</label>
      <label>Price</label>
      {isManual && <label>Exec. Time</label>}
      <label>Booking ID</label>
      {isWarningDisplayed && <label>{/* Empty label for the warning icon */}</label>}
      {isCommFlowIdDisplayed && <label>Comm. Flow ID</label>}
      <label>{/* Empty label for the + icon */}</label>
    </>
  );
};
