import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { connect } from 'react-redux';
import { NeosAppComponent, type NeosAppMapStateToProps } from './NeosApp';
import { getNeosAppModel } from './getNeosAppModel';

function mapStateToProps(state: AppState): NeosAppMapStateToProps {
  return getNeosAppModel(state, selectors);
}

export const NeosApp = connect(mapStateToProps)(NeosAppComponent);
