import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { connect } from 'react-redux';
import {
  type DispatchProps,
  ExtraFeaturesModalStyled,
  type MapStateToProps,
  type OwnProps,
} from './ExtraFeaturesModal';

const { getExtraFeaturesModalState, getEligibleExtraFeatures } = selectors;
const { getStrategyData } = selectors;

function mapStateToProps(appState: AppState, { strategyId }: OwnProps): MapStateToProps {
  const uiState = appState.ui;
  const { strategyType } = getStrategyData(appState, strategyId);
  return {
    modalState: getExtraFeaturesModalState(uiState, strategyId),
    eligibleFeatureTypes: getEligibleExtraFeatures(appState.referenceData, strategyType),
  };
}

function mapDispatchToProps(dispatch: ExtendedDispatch, { strategyId }: OwnProps): DispatchProps {
  function onModalHide(): void {
    dispatch(
      neosActionCreators.strategyUiCrudActions.update(strategyId, {
        extraFeaturesModal: 'HIDE',
      }),
    );
  }
  return {
    onModalHide,
  };
}

export const ExtraFeaturesModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExtraFeaturesModalStyled);
