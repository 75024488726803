import type { Action } from '@/bootstrap/actions';
import type { Thunk } from '@/bootstrap/thunks';
import type {
  DividendLegPeriodDates,
  DividendPeriod,
} from '../../../leg/product/elsProductOnyxModel';
import type { DividendFeature } from '../../../strategyOnyxModel';
import type { DividendComponentFeatureKey } from '../../featureModel';

export function createUpdateDividendSchedulePeriodThunk(
  featureKey: DividendComponentFeatureKey,
  updatedDate: Partial<DividendLegPeriodDates>,
  uuid: string,
): Thunk {
  return function updateDividendSchedulePeriodThunk(
    dispatch,
    getState,
    {
      selectors: { getFeature },
      actionCreators: {
        neos: { featureCrudActions },
      },
    },
  ) {
    const appState = getState();
    const feature: DividendFeature | undefined = getFeature(appState.featureState, featureKey);

    if (!feature) {
      throw new Error(`This feature is not defined`);
    }
    const periods: DividendPeriod[] = [...(feature?.dividendSchedulePeriods ?? [])];
    const periodToUpdateIndex = periods.findIndex(line => line.uuid === uuid);
    const oldPeriod = periods[periodToUpdateIndex];

    if (!oldPeriod) {
      throw new Error('Index of periods is out of bound');
    }

    const newDividendPeriod = {
      ...oldPeriod,
      dates: {
        ...oldPeriod.dates,
        ...updatedDate,
      },
    };
    periods[periodToUpdateIndex] = newDividendPeriod;

    const action: Action = featureCrudActions.update(featureKey, {
      dividendSchedulePeriods: periods,
    });
    dispatch(action);
  };
}
