import type { Way } from '@/neos/business/rfq/rfqData/rfqDataModel.ts';
import type { Quote } from '@/neos/business/rfq/quotes/quoteModel.ts';

export function getQuoteToPatchByClientWay(
  clientWay: Way | undefined,
  volStrike: number | undefined,
) {
  if (clientWay === 'TWO_WAYS') {
    return { traderBid: volStrike, traderAsk: volStrike };
  }
  return clientWay === 'BUY' ? { traderAsk: volStrike } : { traderBid: volStrike };
}

export function getVolStrikeValue(clientWay: Way | undefined, quote: Quote): number | undefined {
  // NOTE: it doesn't matter where we read the volStrike value from when it's TWO_WAYS
  if (clientWay === 'TWO_WAYS' || clientWay === 'BUY') {
    return quote.traderAsk;
  }
  return quote.traderBid;
}
