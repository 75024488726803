import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { OnyxUserCategory } from '@/common/business/commonModel';
import type { BookingStepStatus } from '@/neos/business/neosOnyxModel';
import {
  isElsBasketProduct,
  isElsProduct,
} from '@/neos/business/rfq/strategy/leg/product/productModel';

export type LegBookingIdModel = {
  bookingId: string | undefined;
  bookingTimeStamp: string | undefined;
  defaultBookingId: string | undefined;
  bookingStepMessage: string | undefined;
  status: BookingStepStatus | undefined;
  isReadOnly: boolean | undefined;
} | null;

export function getLegBookingIdModel(
  state: AppState,
  { rfqId, legId }: { rfqId: string; legId: string },
  selectors: Selectors,
): LegBookingIdModel {
  const legBookingStep = selectors.getLegBookingStep(state, rfqId, legId);
  const isLegOtc = selectors.isOtcLeg(state, legId, selectors);
  const product = selectors.getLegProduct(state, legId, selectors);
  const isProductEls = isElsProduct(product);
  const isProductElsBasket = isElsBasketProduct(product);
  const { internal, initiatedByTrader } = selectors.getRfqData(state, rfqId);

  const isStatusAfterBookingRequestedIncluded = selectors.isStatusAfterBookingRequestedIncluded(
    state,
    rfqId,
    selectors,
  );

  const elsSingleStockSalesWorkflow = isProductEls && !initiatedByTrader && !isProductElsBasket;

  const isDisplayed =
    isLegOtc &&
    (!isProductEls || internal || elsSingleStockSalesWorkflow) &&
    (legBookingStep !== undefined || isStatusAfterBookingRequestedIncluded);

  if (!isDisplayed) {
    return null;
  }

  const legData = selectors.getLegData(state, legId);
  const legBookingId = legData.bookingId?.id;

  const isReadOnly = !(['ADMIN', 'TRADER'] as OnyxUserCategory[]).some(e =>
    selectors.getCategoriesForUser(state).includes(e),
  );

  return {
    bookingId: legBookingId ?? legBookingStep?.bookingId,
    defaultBookingId: legBookingStep?.bookingId,
    status: legBookingId ? undefined : legBookingStep?.status,
    bookingStepMessage: legBookingId ? undefined : legBookingStep?.message,
    bookingTimeStamp: legBookingId ? undefined : legBookingStep?.lastUpdateTime,
    isReadOnly,
  };
}
