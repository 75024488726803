import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import type { MailRequest } from '@/common/business/mail/mailRequestModel';
import { allocSecondaryEventCreationActionCreators } from '@/neos/business/allocSecondaryEventCreation/allocSecondaryEventCreationActionCreators';
import {
  onyxBookingStepsReceived,
  removeOnyxBookingSteps,
  updateTopLevelBookingApplicationStrategyOrLegBookingStep,
  updateTopLevelBookingIdStrategyOrLegBookingStep,
} from '@/neos/business/bookingSteps/BookingStepsSlice';
import { currentUserPreferencesActionCreators } from '@/neos/business/currentUserPreferences/currentUserPreferencesSlice';
import { lastTradeCompletedRfqActionCreators } from '@/neos/business/lastOnyxTradeCompletedRfq/lastOnyxTradeCompletedRfqSlice';
import { legSecondaryEventCreationActionCreators } from '@/neos/business/legSecondaryEventCreation/legSecondaryEventCreationActionCreators';
import type { ActionContainer } from '@/util/actionContainer';
import { alertActionCreators } from './alert/alertActionCreators';
import { allocationInstructionActionCreators } from './allocationInstruction/allocationInstructionActionCreators';
import { blotterActionCreators } from './blotter/blotterActionCreators';
import { electronicMediaUsersActionCreators } from './electronicMediaUsers/electronicMediaUsersActionCreators';
import type { FromOnyxMappers } from './mappers';
import type {
  BulkLoadTransactionsRequestedAction,
  CancelNegociationRequestedAction,
  CreateSecondaryEventAction,
  LoadTransactionRequestedAction,
  MailRequestedAction,
  NoSuccessToasterChainOptions,
  RequestSubscriptionAction,
  RequestUnsubscriptionAction,
  TransactionLoadRequest,
} from './neosActions';
import {
  DefaultingScope,
  type NotificationSubscriptionRequest,
  type NotificationUnsubscriptionRequest,
  type SecondaryEventTypeData,
} from './neosModel';
import type {
  OnyxUnderlyingDividendFutureInfo,
  OnyxUnderlyingFutureInfo,
  OnyxUnderlyingOptionInfo,
  OnyxUnderlyingTotalReturnFutureInfo,
} from './neosOnyxModel';
import { nestedRfqsActionCreators } from './nestedRfqs/nestedRfqsActionCreators';
import { nextUserActionsReduxActionCreators } from './nextUserActions/nextUserActionsReduxActionCreators';
import { createNotificationDriverReconnectedAction } from './notificationDriverReconnected/notificationDriverReconnectedActionCreator';
import { orderActionCreators } from './order/orderActionCreators';
import { pendingPredealChecksActionCreators } from './pendingPredealChecks/pendingPredealChecksActionCreators';
import { preConfirmationStepActionCreators } from './preConfirmationStep/preConfirmationStepActionCreators';
import { externalPreconfirmationActionCreators } from './preconfirmation/external/externalPreconfirmationActionCreators';
import { internalPreconfirmationActionCreators } from './preconfirmation/internal/internalPreconfirmationActionCreators';
import { predealCheckActionCreators } from './predealCheck/predealCheckActionCreators';
import { referenceDataActionCreators } from './referenceData/referenceDataActionCreators';
import { rfqActionCreators } from './rfq/rfqActionCreators';
import { DefaultingOptions } from './rfq/rfqActions';
import { handleAllocationFileActionCreators } from './rfq/strategy/leg/otcAllocation/otcAllocationFile/handleAllocationFileActionCreators';
import { similarActivitiesActionCreators } from './similarActivities/similarActivitiesActionCreators';
import { similarActivitiesLinkManagerActionCreators } from './similarActivitiesLinkManagement/similarActivitiesLinkManagementActionCreators';
import { triggerMatchingActionCreators } from './triggerMatching/triggerMatchingActions';
import { referenceUiActionCreators } from './ui/reference/referenceUiActionCreators';
import { uiActionCreators } from './ui/uiActionCreators';
import { underlyingInfoActionCreators } from './underlyingInfo/underlyingInfoActionCreators';
import { deltaNotificationChangesActionCreators } from './warnings/deltaNotificationChanges/deltaNotificationChangesActionCreators';
import { executionPriceNotificationChangesActionCreators } from './warnings/executionPriceNotificationChanges/executionPriceNotificationChangesActionCreators';
import { traderAskNotificationChangesActionCreators } from './warnings/traderAskNotificationChanges/traderAskNotificationChangesActionCreators';
import { traderBidNotificationChangesActionCreators } from './warnings/traderBidNotificationChanges/traderBidNotificationChangesActionCreators';

export type NeosActionCreators = typeof neosActionCreators;
export const neosActionCreators = {
  ...uiActionCreators,
  ...rfqActionCreators,
  ...referenceDataActionCreators,
  ...underlyingInfoActionCreators,
  ...blotterActionCreators,
  ...predealCheckActionCreators,
  ...pendingPredealChecksActionCreators,
  ...nestedRfqsActionCreators,
  ...nextUserActionsReduxActionCreators,
  ...alertActionCreators,
  ...orderActionCreators,
  ...allocationInstructionActionCreators,
  ...similarActivitiesActionCreators,
  ...legSecondaryEventCreationActionCreators,
  ...allocSecondaryEventCreationActionCreators,
  ...similarActivitiesLinkManagerActionCreators,
  ...externalPreconfirmationActionCreators,
  ...internalPreconfirmationActionCreators,
  ...executionPriceNotificationChangesActionCreators,
  ...deltaNotificationChangesActionCreators,
  ...traderAskNotificationChangesActionCreators,
  ...traderBidNotificationChangesActionCreators,
  ...referenceUiActionCreators,
  ...handleAllocationFileActionCreators,
  ...triggerMatchingActionCreators,
  createCancelNegociationRequestedAction,
  loadTransactionAtVersion,
  bulkLoadTransactions,
  createSecondaryEventAction,
  createLoadTransactionAfterRfqOutdatedAction,
  createTradeRecapRequestedAction,
  createUnderlyingsInfosReceivedAction,
  createNeosPredealCheckSelected,
  createNeosPredealCheckUnselected,
  createMailRequestedAction,
  createNotificationDriverReconnectedAction,
  createRequestSubscriptionAction,
  createRequestUnsubscriptionAction,
  createNeosChangeCompositionLegUnderlyingAction,
  electronicMediaUsersActionCreators,
  ...preConfirmationStepActionCreators,
  onyxBookingStepsReceived,
  removeOnyxBookingSteps,
  updateTopLevelBookingIdStrategyOrLegBookingStep,
  updateTopLevelBookingApplicationStrategyOrLegBookingStep,
  lastTradeCompletedRfqActionCreators,
  currentUserPreferencesActionCreators,
};

export function createSecondaryEventAction(
  primaryRfqId: string,
  secondaryEvents: SecondaryEventTypeData[],
): CreateSecondaryEventAction {
  return {
    type: 'CREATE_SECONDARY_EVENT_REQUESTED',
    secondaryEventCreationRequest: {
      primaryRfqId,
      secondaryEvents,
    },
  };
}

export function createCancelNegociationRequestedAction(
  rfqId: string,
): CancelNegociationRequestedAction {
  return {
    type: 'CANCEL_NEGOCIATION_REQUESTED',
    rfqId,
  };
}

export function bulkLoadTransactions(rfqIds: string[]): BulkLoadTransactionsRequestedAction {
  return {
    type: 'BULK_LOAD_TRANSACTIONS_REQUESTED',
    rfqIds,
  };
}

export function loadTransactionAtVersion(
  transactionLoadRequest: TransactionLoadRequest,
  shouldDisplayTab: boolean = true,
): LoadTransactionRequestedAction {
  return {
    type: 'LOAD_TRANSACTION_REQUESTED',
    transactionLoadRequest,
    shouldDisplayTab,
  };
}

function createLoadTransactionAfterRfqOutdatedAction(rfqId: string, version: number) {
  return [
    uiActionCreators.rfqUiCrudActions.update(rfqId, { rfqOutdatedModal: { action: 'HIDE' } }),
    loadTransactionAtVersion({ rfqId, version }),
  ];
}

function createTradeRecapRequestedAction(rfqId: string, showModal: boolean) {
  const options = new DefaultingOptions({
    enrichScopes: [
      DefaultingScope.TRADE_RECAP,
      DefaultingScope.UNDERLYING,
      DefaultingScope.SALES_COUNTERPARTY,
    ],
  });
  const chainOptions: NoSuccessToasterChainOptions = {
    success: showModal
      ? {
          dispatchables: [
            neosActionCreators.rfqUiCrudActions.patchOrInsert(rfqId, {
              showTradeRecapModal: true,
            }),
          ],
        }
      : undefined,
    error: {
      toaster: {
        type: 'OVERRIDE_MESSAGE',
        message: 'Error retrieving the Trade Recap',
      },
    },
  };
  return [
    createLogAnalyticsAction('NEOS RFQ', 'Trade recap button hit'),
    neosActionCreators.createDefaultRfqRequestedAction(rfqId, options, chainOptions),
  ];
}

function createUnderlyingsInfosReceivedAction(
  [
    udlOptionInfos,
    underlyingFutureInfos,
    underlyingFutureRefMaturities,
    underlyingDividendFutureInfos,
    underlyingTotalReturnFutureInfos = [],
  ]: [
    OnyxUnderlyingOptionInfo[],
    OnyxUnderlyingFutureInfo[],
    Record<string, string[]>,
    OnyxUnderlyingDividendFutureInfo[],
    OnyxUnderlyingTotalReturnFutureInfo[],
  ],
  mappers: FromOnyxMappers,
) {
  return udlOptionInfos.map(udlOptionInfo => {
    const underlyingInfo = mappers.mapFromUnderlyingInfo(
      udlOptionInfo,
      underlyingFutureInfos.find(u => u.underlying.id === udlOptionInfo.underlying.id)!,
      underlyingFutureRefMaturities[udlOptionInfo.underlying.id],
      underlyingDividendFutureInfos.find(u => u.underlying.id === udlOptionInfo.underlying.id)!,
      underlyingTotalReturnFutureInfos.find(u => u.underlying.id === udlOptionInfo.underlying.id)!,
    );
    return neosActionCreators.underlyingInfoCrudActions.upsert(underlyingInfo.id, underlyingInfo);
  });
}

function createNeosPredealCheckSelected(rfqId: string, predealCheckId: string): ActionContainer {
  return [
    uiActionCreators.rfqUiCrudActions.update(rfqId, {
      selectedPredealCheckId: predealCheckId,
    }),
  ];
}

function createNeosPredealCheckUnselected(rfqId: string): ActionContainer {
  return [
    uiActionCreators.rfqUiCrudActions.update(rfqId, {
      selectedPredealCheckId: undefined,
    }),
  ];
}

export function createMailRequestedAction(
  mailRequest: MailRequest,
  succesMessage: string,
  errorMessage: string,
): MailRequestedAction {
  return {
    type: 'MAIL_PRICING_REQUESTED',
    mailRequest,
    succesMessage,
    errorMessage,
  };
}

export function createRequestSubscriptionAction(
  notificationSubscriptionRequests: NotificationSubscriptionRequest[],
): RequestSubscriptionAction {
  return {
    type: 'REQUEST_SUBSCRIPTION_ACTION',
    notificationSubscriptionRequests,
  };
}

export function createRequestUnsubscriptionAction(
  notificationUnsubscriptionRequests: NotificationUnsubscriptionRequest[],
): RequestUnsubscriptionAction {
  return {
    type: 'REQUEST_UNSUBSCRIPTION_ACTION',
    notificationUnsubscriptionRequests,
  };
}

function createNeosChangeCompositionLegUnderlyingAction({
  rfqId,
  strategyId,
  productId,
  underlyingId,
}: {
  rfqId: string;
  strategyId: string;
  productId: string;
  underlyingId: string | undefined;
}) {
  const updateActions = [
    rfqActionCreators.productCrudActions.update(productId, { underlyingId }),
    rfqActionCreators.strategyDataCrudActions.update(strategyId, {
      underlyingId: undefined,
    }),
  ];
  return underlyingId
    ? [
        underlyingInfoActionCreators.createUnderlyingInfoRequestedAction([rfqId], [underlyingId], {
          success: {
            dispatchables: updateActions,
          },
        }),
      ]
    : updateActions;
}
