import { hasDiscriminator } from '@/neos/business/rfq/strategy/feature/hasDiscriminator';
import type { WithExtraProperty } from '@/neos/business/rfq/strategy/feature/withExtraProperty';
import { isDefined } from '@/util/undefinedAndNull/isDefined';
import type { CapFloorFeature } from '../../../../neosModel';
import type {
  OnyxCap,
  OnyxFeature,
  OnyxFloor,
  OnyxProductFeaturesFields,
  OnyxProductUnderlying,
} from '../../../../neosOnyxModel';

export function mapFromOnyxExtraFeaturesToCapFloor(
  extraFeatures: OnyxFeature[],
  strategyId: string,
): CapFloorFeature | undefined {
  const capFeature: OnyxCap | undefined = extraFeatures.find(
    hasDiscriminator<OnyxCap>('CAP_CONTAINER'),
  );
  const floorFeature: OnyxFloor | undefined = extraFeatures.find(
    hasDiscriminator('FLOOR_CONTAINER'),
  );
  if (capFeature || floorFeature) {
    return {
      strategyId,
      type: 'CAP_FLOOR',
      capUnit: capFeature?.cap?.price?.unit,
      capValue: capFeature?.cap?.price?.value,
      floorUnit: floorFeature?.floor?.price?.unit,
      floorValue: floorFeature?.floor?.price?.value,
    };
  }
  return undefined;
}

export function mapToOnyxCapFloor(
  feature: WithExtraProperty<CapFloorFeature>,
  underlying: OnyxProductUnderlying | undefined,
): OnyxProductFeaturesFields {
  if (!feature.isExtra) {
    throw new Error('Only extra CAP_FLOOR features are supported');
  }
  const { capUnit, capValue, floorUnit, floorValue } = feature;

  const onyxCap: OnyxCap | undefined =
    capValue !== undefined
      ? {
          discriminator: 'CAP_CONTAINER',
          cap: {
            price: {
              unit: capUnit || floorUnit,
              value: capValue,
            },
            underlying,
          },
        }
      : undefined;

  const onyxFloor: OnyxFloor | undefined =
    floorValue !== undefined
      ? {
          discriminator: 'FLOOR_CONTAINER',
          floor: {
            price: {
              unit: floorUnit || capUnit,
              value: floorValue,
            },
            underlying,
          },
        }
      : undefined;
  return {
    extraFeatures: [onyxCap, onyxFloor].filter(isDefined),
  };
}
