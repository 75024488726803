import type { Thunk } from '@/bootstrap/thunks';
import { sumBy } from 'lodash';
import type { OrderKey } from '../orderData/orderDataModel';

export function createChangeOrderUpdateExecSizeThunk(orderKey: OrderKey): Thunk {
  return function changeOrderUpdateExecSizeThunk(
    dispatch,
    getState,
    { selectors, actionCreators: { neos: as } },
  ) {
    const state = getState();

    const executions = selectors.executionSelectors.selectObjects(state.execution, {
      orderId: orderKey.orderId,
    });
    const quantity = sumBy(executions, e => e.size?.quantity ?? 0);
    const numberOfLots = sumBy(executions, e => e.size?.numberOfLots ?? 0);

    dispatch(
      as.orderCrudActionCreators.update(orderKey, {
        execSize: {
          numberOfLots,
          quantity,
          sizeType: 'FIXED_QUANTITY',
        },
      }),
    );
  };
}
