import { NumericInput } from '@/neos/components/share/numericInput';
import { CloseButton } from '../closeButton';
import type { CapFloorModel } from './getCapFloorModel';

export interface OwnProps {
  strategyId: string;
}

export interface StateProps {
  model: CapFloorModel;
}

export interface DispatchProps {
  onFloorChanged(value: number | undefined): void;
  onCapChanged(value: number | undefined): void;
}

type Props = OwnProps & StateProps & DispatchProps;

export const CapFloor = ({ strategyId, model, onCapChanged, onFloorChanged }: Props) => {
  if (!model.isDisplayed) {
    return <div />;
  }

  const { capValue, floorValue, displayUnit } = model;
  return (
    <div className="d-flex mb-1 ms-1 border p-1 fit-content">
      <div
        className="d-grid w-100 gap-1"
        style={{ gridTemplateColumns: 'minmax(113px, 146px) minmax(83px, 123px)' }}
      >
        <span className="fw-bold">Floor</span>
        <span className="fw-bold">Cap</span>

        <NumericInput
          value={floorValue}
          unit={displayUnit}
          onBlur={onFloorChanged}
          data-e2e="neos-extra-features-floorCap-floor"
        />
        <NumericInput
          value={capValue}
          onBlur={onCapChanged}
          data-e2e="neos-extra-features-floorCap-cap"
        />
      </div>
      <CloseButton strategyId={strategyId} type={'CAP_FLOOR'} />
    </div>
  );
};
