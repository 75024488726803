import type { OnyxRfq } from '@/neos/business/rfq/rfqOnyxModel';
import type { BlotterModelGetters } from '.';

export function getBlotterOptionStyles(
  { strategies }: OnyxRfq,
  { twoDimentionalArrayToString }: BlotterModelGetters,
): string {
  const optionStyles = strategies.map(strat =>
    strat.legs.map(({ product }) => product?.optionStyle),
  );

  return twoDimentionalArrayToString(optionStyles, e => e[0]);
}
