import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { useSelector } from 'react-redux';
import {
  LegLinkCellsComponent,
  type LegLinkCellsMapStateProps,
  type LegLinkCellsOwnProps,
} from './LegLinkCells';
import { getLegLinkCellsModel } from './getLegLinkCellsModel';

export const LegLinkCells = (ownProps: LegLinkCellsOwnProps) => {
  const stateProps = useSelector<AppState, LegLinkCellsMapStateProps>(state => {
    return getLegLinkCellsModel(state, ownProps.rfqId, ownProps.strategyId, selectors);
  });

  return <LegLinkCellsComponent {...ownProps} {...stateProps} />;
};
