import type { StatusSubStatusLabel } from '@/neos/business/referenceData/status/statusLabelModel';
import { getStatusAndSubStatusFromLabel } from '@/neos/business/referenceData/status/statusSelector';
import { orderOf } from '@/neos/business/services/statusOrder';
import { formatDateInTimeZone } from '@/util/date/dateFormatHelper';
import { fixPrecision, formatNum } from '@/util/number/numberUtils';
import { constantToSentence } from '@/util/string/stringUtils';
import type { ColDef } from '@ag-grid-community/core';
import { round } from 'lodash';
import type { DisplayTimeZone } from '../../../business/neosModel';
import type { Source } from '../../../business/neosOnyxModel';
import { IsQuoteCheckBox } from './IsQuoteCheckBox';
import type { BlotterRowModel } from './blotterRowModel';
import { getStatusCellClass } from './getStatusCellClass';
import { legMaturityComparator } from './legMaturityComparator';
import type { PossibleBlotterRowModel } from './possibleBlotterRowModel';
import { PreconfStatusCellRenderer } from './preconfStatusCellRender';
import { formatPriceWithUnit } from './priceFormatter';
import { sourceCellRenderer } from './sourceCellRenderer';
import { StatusFilterCellRender } from './statusFilterCellRender';

export type PossibleBlotterColumnFields = keyof PossibleBlotterRowModel;

export interface BlotterColDef extends ColDef {
  field: PossibleBlotterColumnFields;
}

export interface GridColumnDefinitionParams {
  isTrader: boolean;
  displayTimezone: DisplayTimeZone;
}

export class GridColumnDefinition {
  constructor(private defParams: GridColumnDefinitionParams) {}

  private numCellRenderer = (params: any) => formatNum(params.value as number | undefined);

  private roundedNumCellRenderer = (params: any, precision: number = 0) =>
    formatNum(fixPrecision(params.value as number | undefined, precision));

  private numWithRfqQuoteUnitCellRenderer = (params: any) => {
    const value = params.value as number | undefined;
    const unit = params.data?.rfqQuoteUnit;
    return formatPriceWithUnit({ value, unit });
  };

  private fairValueCellRenderer = (params: any) => {
    if (params.value === undefined) {
      return '';
    }
    const data: BlotterRowModel | undefined = params.data;
    const isPtmmm = data?.isPtmmm;
    const unit = data?.rfqQuoteUnit;
    const value = round(params.value as number, isPtmmm ? 4 : 2);

    return formatPriceWithUnit({ value, unit });
  };

  private constRenderer = (params: any) =>
    params.value ? constantToSentence(params.value as string) : '';

  private formatGridDate = (dateTime: any) => {
    if (!dateTime) {
      return '';
    }
    return formatDateInTimeZone(this.defParams.displayTimezone, (dateTime as Date).toISOString());
  };

  private formatGridDateForExport = (dateTime: Date | undefined) => {
    if (!dateTime) {
      return '';
    }
    return formatDateInTimeZone(
      this.defParams.displayTimezone,
      dateTime.toISOString(),
      "yyyy-MM-dd'T'HH:mm:ss",
    );
  };

  public columns: Record<BlotterColDef['field'], (param?: any) => BlotterColDef> = {
    source: () => ({
      field: 'source',
      width: 42,
      cellRenderer: (params: { value: string }) => sourceCellRenderer(params.value as Source),
    }),
    tradeDate: () => ({
      field: 'tradeDate',
      filter: 'agTextColumnFilter',
      filterParams: {
        textCustomComparator: agTextColumnFilterWithTransform(this.formatGridDate),
      },
      cellRenderer: (params: { value: any }) => this.formatGridDate(params.value),
      width: 140,
      sort: 'desc',
      valueFormatter: params => this.formatGridDateForExport(params.value as Date),
      cellClass: 'ExcelDateTime',
    }),
    preconfStatus: () => ({
      field: 'preconfStatus',
      filter: 'agTextColumnFilter',
      valueFormatter: () => '',
      cellRenderer: PreconfStatusCellRenderer,
      width: 150,
    }),
    salesValo: () => ({
      field: 'salesValo',
      width: 100,
    }),
    salesCounterpartyName: () => ({
      field: 'salesCounterpartyName',
      width: 110,
    }),
    statusLabel: (groupCell = false) => ({
      field: 'statusLabel',
      width: 220,
      cellClass: getStatusCellClass(this.defParams.isTrader),
      ...(groupCell ? { cellRenderer: 'agGroupCellRenderer' } : {}),
      filterParams: {
        cellRenderer: (params: any) => {
          return StatusFilterCellRender(params, this.defParams.isTrader);
        },
        comparator: com,
      },
      comparator: com,
    }),
    masterQuantity: () => ({
      field: 'masterQuantity',
      type: 'numericColumn',
      cellRenderer: this.numCellRenderer,
      filter: 'agNumberColumnFilter',
      width: 70,
    }),
    masterSize: () => ({
      field: 'masterSize',
      type: 'numericColumn',
      cellRenderer: this.numCellRenderer,
      filter: 'agNumberColumnFilter',
      width: 70,
    }),
    masterLotSize: () => ({
      field: 'masterLotSize',
      type: 'numericColumn',
      cellRenderer: this.numCellRenderer,
      width: 70,
    }),
    legSizes: () => ({
      field: 'legSizes',
      filter: 'agTextColumnFilter',
      width: 90,
    }),
    underlyingBloombergCodes: () => ({
      field: 'underlyingBloombergCodes',
      width: 70,
    }),
    allStrategyTypes: () => ({
      field: 'allStrategyTypes',
      width: 180,
    }),
    negotiationTypes: () => ({
      field: 'negotiationTypes',
      width: 140,
    }),
    legMaturities: () => ({
      field: 'legMaturities',
      filter: 'agTextColumnFilter',
      width: 200,
      comparator: legMaturityComparator,
    }),
    legStrikes: () => ({
      field: 'legStrikes',
      filter: 'agTextColumnFilter',
      width: 140,
    }),
    traderGroups: () => ({
      field: 'traderGroups',
      width: 140,
    }),
    traders: () => ({
      field: 'traders',
      width: 100,
    }),
    rfqBid: () => ({
      field: 'rfqBid',
      type: 'numericColumn',
      cellRenderer: this.numWithRfqQuoteUnitCellRenderer,
      filter: 'agNumberColumnFilter',
      width: 70,
    }),
    rfqMid: () => ({
      field: 'rfqMid',
      type: 'numericColumn',
      cellRenderer: this.fairValueCellRenderer,
      filter: 'agNumberColumnFilter',
      width: 70,
    }),
    rfqAsk: () => ({
      field: 'rfqAsk',
      type: 'numericColumn',
      cellRenderer: this.numWithRfqQuoteUnitCellRenderer,
      filter: 'agNumberColumnFilter',
      width: 70,
    }),
    totalSalesMarginEur: () => ({
      field: 'totalSalesMarginEur',
      type: 'numericColumn',
      cellRenderer: (param: any) => this.roundedNumCellRenderer(param, 0),
      filter: 'agNumberColumnFilter',
      width: 100,
    }),
    totalCommissionAmountEur: () => ({
      field: 'totalCommissionAmountEur',
      type: 'numericColumn',
      cellRenderer: (param: any) => this.roundedNumCellRenderer(param, 0),
      width: 100,
    }),
    crossBorder: () => ({
      field: 'crossBorder',
      cellRenderer: (params: any) => {
        return IsQuoteCheckBox(params);
      },
      width: 100,
    }),
    masterRefLevel: () => ({
      field: 'masterRefLevel',
      type: 'numericColumn',
      cellRenderer: this.numCellRenderer,
      filter: 'agNumberColumnFilter',
      width: 70,
    }),
    eurNotionalValue: () => ({
      field: 'eurNotionalValue',
      type: 'numericColumn',
      cellRenderer: (param: any) => this.roundedNumCellRenderer(param, 0),
      filter: 'agNumberColumnFilter',
      width: 71,
    }),
    usdNotionalValue: () => ({
      field: 'usdNotionalValue',
      type: 'numericColumn',
      cellRenderer: (param: any) => this.roundedNumCellRenderer(param, 0),
      filter: 'agNumberColumnFilter',
      width: 100,
    }),
    clientWay: () => ({
      field: 'clientWay',
      width: 80,
    }),
    extraFeatures: () => ({
      field: 'extraFeatures',
      filter: 'agTextColumnFilter',
      width: 200,
    }),
    comment: () => ({
      field: 'comment',
      filter: 'agTextColumnFilter',
      width: 170,
    }),
    quoteRecap: () => ({
      field: 'quoteRecap',
      filter: 'agTextColumnFilter',
      width: 460,
    }),
    workflow: () => ({
      field: 'workflow',
      width: 72,
    }),
    bookingInfo: () => ({
      field: 'bookingInfo',
      filter: 'agTextColumnFilter',
      width: 126,
    }),
    creationTime: () => ({
      field: 'creationTime',
      filter: 'agTextColumnFilter',
      cellRenderer: (params: { value: any }) => this.formatGridDate(params.value),
      width: 120,
      valueFormatter: params => this.formatGridDateForExport(params.value as Date),
      cellClass: 'ExcelDateTime',
    }),
    updateTime: () => ({
      field: 'updateTime',
      filter: 'agTextColumnFilter',
      cellRenderer: (params: { value: any }) => this.formatGridDate(params.value),
      width: 120,
      valueFormatter: params => this.formatGridDateForExport(params.value as Date),
      cellClass: 'ExcelDateTime',
    }),
    isQuotePercent: () => ({
      field: 'isQuotePercent',
      cellRenderer: IsQuoteCheckBox,

      width: 91,
    }),
    uuid: () => ({
      field: 'uuid',
      filter: 'agTextColumnFilter',
    }),
    optionStyle: () => ({
      field: 'optionStyle',
      width: 80,
    }),
    activity: () => ({
      field: 'activity',
      cellRenderer: this.constRenderer,
      width: 110,
    }),
    secondaryEventType: () => ({
      field: 'secondaryEventType',
      width: 110,
    }),
    feedbackLevel: () => ({
      field: 'feedbackLevel',
      cellRenderer: this.fairValueCellRenderer,
      width: 80,
    }),
    observableType: () => ({
      field: 'observableType',
      width: 80,
    }),
    elsType: () => ({
      field: 'elsType',
      width: 100,
    }),
  };
}

function com(a: StatusSubStatusLabel, b: StatusSubStatusLabel) {
  const { status: statusA, subStatus: subStatusA } = getStatusAndSubStatusFromLabel(a);
  const { status: statusB, subStatus: subStatusB } = getStatusAndSubStatusFromLabel(b);
  if (statusA === 'CANCELLED' && statusB === 'CANCELLED') {
    return subStatusA?.localeCompare(subStatusB);
  }

  if (statusA === statusB) {
    return 0;
  }
  try {
    if (orderOf(statusA).isAfter(statusB)) {
      return 1;
    }
  } catch {
    return -1;
  }
  return -1;
}

function agTextColumnFilterWithTransform(transformFn: (value: any) => string) {
  return (filter: string | null | undefined, value: any, filterText: string) => {
    const filterTextLowerCase = filterText.toLowerCase();
    const valTransformed = transformFn(new Date(value as string));
    const valueLowerCase = valTransformed.toString().toLowerCase();

    switch (filter) {
      case 'contains':
        return valueLowerCase.includes(filterTextLowerCase);
      case 'notContains':
        return !valueLowerCase.includes(filterTextLowerCase);
      case 'equals':
        return valueLowerCase === filterTextLowerCase;
      case 'notEqual':
        return valueLowerCase != filterTextLowerCase;
      case 'startsWith':
        return valueLowerCase.startsWith(filterTextLowerCase);
      case 'endsWith': {
        return valueLowerCase.endsWith(filterTextLowerCase);
      }
      default:
        // should never happen
        return false;
    }
  };
}
