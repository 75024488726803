import { neosActionCreators } from '@/neos/business/neosActionCreators';
import {
  DefaultingScope,
  type IATypeValuesType,
  type OtcAllocation,
  type PriceUnitType,
} from '@/neos/business/neosModel';
import { DefaultingOptions } from '@/neos/business/rfq/rfqActions';
import { neosThunks } from '@/neos/business/thunks';
import type { DisplayNegotiatedSize } from '@/neos/business/ui/strategy/strategyUiModel';
import type { FC } from 'react';
import { useDispatch } from 'react-redux';
import { IACurrency } from './IACurrency';
import { IAToBeBooked } from './IAToBeBooked';
import { IAType } from './IAType';
import { IAValue } from './IAValue';

type IndependentAmountProps = {
  showValueError?: boolean;
  showTypeError?: boolean;
  displayIAToBeBooked: boolean;
  alloc: OtcAllocation;
  uiSizeType?: DisplayNegotiatedSize;
  rfqId: string;
  legId: string;
};

export const IndependentAmount: FC<IndependentAmountProps> = ({
  showValueError,
  showTypeError,
  alloc,
  uiSizeType,
  displayIAToBeBooked,
  rfqId,
  legId,
}) => {
  const dispatch = useDispatch();

  const onIAChanged = (allocationId: string, independantAmountValue?: number) => {
    dispatch(
      neosActionCreators.otcAllocationCrudActions.update(
        { allocationId, rfqId, legId },
        { independantAmountValue },
      ),
    );
    dispatch(
      neosActionCreators.allocationUiCrudActions.patchOrInsert(allocationId, {
        errors: { IaValue: false },
      }),
    );
    const overrideScopes = [DefaultingScope.IA_CURRENCY];
    const enrichScopes = [DefaultingScope.FOREX, DefaultingScope.IA_VALUE_DATE];
    const options = new DefaultingOptions({ enrichScopes, overrideScopes });
    dispatch(neosActionCreators.createDefaultRfqRequestedAction(rfqId, options));
  };
  const onIACcyChanged = (allocationId: string, newIaUnit?: string) => {
    dispatch(
      neosThunks.createOnAllocationIACurrencyChangedThunk(legId, rfqId, allocationId, newIaUnit),
    );
  };

  const onIAToBeBookedCcyChanged = (
    allocationId: string,
    currency?: string,
    IaType?: PriceUnitType,
  ) => {
    dispatch(
      neosThunks.onAllocationIAToBeBookedCurrencyChangeThunk(
        legId,
        rfqId,
        allocationId,
        currency,
        IaType,
      ),
    );
  };
  const onIATypeChanged = (allocationId: string, newIaType?: IATypeValuesType) => {
    dispatch(
      neosThunks.createOnAllocationIATypeChangedThunk(legId, rfqId, allocationId, newIaType),
    );
  };

  return (
    <>
      <IAType
        independantAmountType={alloc.independantAmountType}
        uuid={alloc.uuid}
        onIATypeChanged={onIATypeChanged}
        dataE2e="otc-alloc-ia-type"
      />

      <div className="d-flex">
        <IACurrency
          showError={showTypeError}
          independantAmountValue={alloc.independantAmountValue}
          independantAmountUnit={alloc.independantAmountUnit}
          independantAmountType={alloc.independantAmountType}
          uuid={alloc.uuid}
          onIACcyChanged={onIACcyChanged}
          dataE2e="otc-alloc-ia-ccy"
        />

        <IAValue
          dataE2e="otc-alloc-ia-value"
          showError={showValueError}
          independantAmountValue={alloc.independantAmountValue}
          independantAmountUnit={alloc.independantAmountUnit}
          onIAChanged={onIAChanged}
          uiSizeType={uiSizeType}
          uuid={alloc.uuid}
        />
      </div>

      <IAToBeBooked
        onIAToBeBookedCcyChanged={onIAToBeBookedCcyChanged}
        uiSizeType={uiSizeType}
        alloc={alloc}
        displayIAToBeBookedColumn={displayIAToBeBooked}
      />
    </>
  );
};
