import type { OnyxRfq } from '@/neos/business/rfq/rfqOnyxModel';
import type { BlotterModelGetters } from '.';

export function getTraderIds(
  rfq: OnyxRfq,
  { getAllStrategiesExceptDeltaAdj }: BlotterModelGetters,
) {
  const allStrategiesExceptDeltaAdj = getAllStrategiesExceptDeltaAdj(rfq);
  return allStrategiesExceptDeltaAdj.map(s => s.traderDiffusion?.trader?.id);
}
