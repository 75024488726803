import { type EventType, type Product, isSecondaryEvent } from '@/neos/business/neosModel';
import { type SecondaryField, getSecondaryFieldsToDisplay } from './secondaryFieldsByType';

export const cssGridSizeBySecondaryField: Record<SecondaryField, string> = {
  primaryBookingId: '140px',
  remainingQuantity: '200px',
  amountDue: '123px',
  amountDueWay: '160px',
  nettingBookingId: '140px',
  novationFee: '123px',
  novationFeeWay: '123px',
  transferee: '250px',
  transferor: '250px',
  novationBookingId: '140px',
  novationFeeId: '140px',
  remainingParty: '123px',
  confirmationToBeChecked: '140px',
  partialReset: '90px',
  observeNewRateFixing: '110px',
  dividendCurrentFlowMode: '190px',
  equityCurrentFlowMode: '190px',
  rateCurrentFlowMode: '190px',
  keepDividendPaymentDate: '190px',
  initialStrike: '120px',
  initialTradeDate: '120px',
  mtm: '50px',
  flowMode: '270px',
  usePositionFromLastReset: '205px',
};

export function getSecondaryCssGridValues(
  eventType: EventType | undefined,
  product: Product,
): string {
  if (!eventType || !isSecondaryEvent(eventType)) {
    return '';
  }
  const fields = getSecondaryFieldsToDisplay(eventType, product);
  return fields.reduce((old, field) => old.concat(cssGridSizeBySecondaryField[field], ' '), '');
}
