import { type OnyxRfq, isOnyxBasketProduct } from '@/neos/business/rfq/rfqOnyxModel';
import { identity } from 'lodash';
import type { BlotterModelGetters } from '.';

export function getUnderlyingBloombergCodes(
  rfq: OnyxRfq,
  { getAllStrategiesExceptDeltaAdj, twoDimentionalArrayToString }: BlotterModelGetters,
) {
  const allStrategiesExceptDeltaAdj = getAllStrategiesExceptDeltaAdj(rfq);
  return twoDimentionalArrayToString(
    allStrategiesExceptDeltaAdj.map(strat =>
      strat.legs.map(({ product }) => {
        if (isOnyxBasketProduct(product)) {
          return product.underlying?.basketEliotID;
        }

        return product?.underlying?.bloombergCode ?? product?.underlying?.name;
      }),
    ),
    identity,
    { omitBrakets: true },
  );
}
