import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { GeneratePreconfApi } from './generatePreconfEpic';

export const createGeneratePreconfApi = (http: SgmeHttp): GeneratePreconfApi => ({
  generatePreconfApi(uuid, onyxTransaction) {
    return http.post({
      url: `api/workflow-neos/${uuid}/generatePreConfirmations`,
      body: onyxTransaction,
    });
  },
});
