import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { isValidEmail } from '@/util/string/stringUtils';
import type { PreconfirmationBookingOptionsStateProps } from './ExternalPreconfirmationBookingOptions';

export function getExternalPreconfirmationTabModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): PreconfirmationBookingOptionsStateProps {
  const mailOptions = selectors.getExternalMailOptions(state, rfqId);
  const otherFieldIsInvalid = !!mailOptions?.clientOther?.split(';').some(email => {
    email = email.trim();
    return email && !isValidEmail(email);
  });
  const isNovationRemaining =
    selectors.getRfqEventType(state, rfqId, selectors) === 'NOVATION_REMAINING';
  const transfereesContacts = selectors.getRfqTransfereesContacts(state, rfqId, selectors);
  const salesContact = selectors.getContacts(state, rfqId);
  const availableContacts = isNovationRemaining ? transfereesContacts : salesContact;

  return {
    bookingMailOptions: mailOptions,
    otherFieldIsInvalid,
    availableContacts: availableContacts.map(contact => ({
      value: contact.id,
      label: contact.name,
      tag: contact,
    })),
  };
}
