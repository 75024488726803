import type { AppState } from '@/bootstrap/state';
import { Provider } from 'react-redux';
import type { Store } from 'redux';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/main.scss';
import { ApplicationSelector } from '@/common/components/applicationSelector/ApplicationSelector';
import { Footer } from '@/common/components/footer/footer';
import { NavHeader } from '@/common/components/header/NavHeader';
import { HelpCenter } from '@/common/components/helpCenter/HelpCenter';
import { SessionLostModal } from '@/common/components/sessionLost/sessionLostModal';
import { createRoot } from 'react-dom/client';
import { HttpContext } from './HttpContext';
import { http } from './apis';
import { NeosNotificationsSystem } from './notifications/NeosNotificationsSystem';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
import { AppCrashModal } from '@/common/components/helpRequest/AppCrashModal';

export function renderReactApp(store: Store<AppState>) {
  const rootElement = createRoot(document.getElementById('root')!);
  rootElement.render(
    <Provider store={store}>
      <div className="app">
        <NeosNotificationsSystem />
        <NavHeader />
        <AppCrashModal />
        <HelpCenter />
        <HttpContext.Provider value={{ httpClient: http }}>
          <ApplicationSelector />
        </HttpContext.Provider>
        <SessionLostModal />
        <Footer />
      </div>
    </Provider>,
  );
}
