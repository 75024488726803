import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { useSelector } from 'react-redux';
import { McaEligibility } from './McaEligibility';

interface McaEligibilityProps {
  legId: string;
  allocUuid: string;
}

export function AllocationMcaEligibility(props: McaEligibilityProps) {
  const isMcaEligible = useSelector((state: AppState) => {
    const allocations = selectors.getOtcAllocationsByLegId(state, props.legId);
    const allocation = allocations.find(({ uuid }) => uuid === props.allocUuid);
    return allocation?.mcaEligible;
  });

  if (isMcaEligible === undefined) {
    return <div></div>;
  }
  return <McaEligibility isMcaEligible={isMcaEligible} />;
}
