import type { Thunk } from '@/bootstrap/thunks';
import { DefaultingScope, type Reference, type ReferenceKey } from '../models';
import { DefaultingOptions } from '../rfqActions';

export function createChangeReferenceMaturityThunk(
  referenceKey: ReferenceKey,
  refMaturity: string | undefined,
): Thunk {
  return function changeReferenceMaturityThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        analytics: { createLogAnalyticsAction },
        neos: {
          referenceCrudActions,
          createDefaultRfqRequestedAction,
          createStrategySelectedAction,
        },
      },
      thunks: {
        neos: { createRfqResetQuotesWithGivenUnderlyingThunk },
      },
    },
  ) {
    const state = getState();

    dispatch(
      createLogAnalyticsAction('NEOS RFQ', 'Select Ref maturity'),
      createRfqResetQuotesWithGivenUnderlyingThunk(referenceKey),
    );

    const reference = selectors.getReference(state, referenceKey);
    const refLevel = reference?.hasBeenManuallySet ? reference.refLevel : undefined;
    const patch = createUpdateMaturityAndResetOtherReferencesPatch(refMaturity!, refLevel);

    dispatch(referenceCrudActions.update(referenceKey, patch));

    const { rfqId } = referenceKey;

    const hasAlreadyFairPricesInRfq = selectors.hasRfqFairPrices(
      state,
      referenceKey.rfqId,
      selectors,
    );

    const overrideScopes = hasAlreadyFairPricesInRfq
      ? [
          DefaultingScope.FAIR_PRICES,
          DefaultingScope.AGGREGATED_FAIR_PRICES,
          DefaultingScope.NEGOTIATED_SIZE,
          DefaultingScope.NOTIONAL,
        ]
      : [DefaultingScope.NEGOTIATED_SIZE, DefaultingScope.NOTIONAL];

    const enrichScopes = [
      DefaultingScope.REFERENCE,
      DefaultingScope.SPOT_NET,
      DefaultingScope.HEDGES,
    ];

    const options = new DefaultingOptions({
      overrideScopes,
      enrichScopes,
    });

    const { uuid: masterStrategyId } = selectors.getRfqMasterStrategy(state, rfqId);
    dispatch(
      createStrategySelectedAction(rfqId, masterStrategyId),
      createDefaultRfqRequestedAction(rfqId, options),
    );
  };
}

function createUpdateMaturityAndResetOtherReferencesPatch(
  refMaturity: string,
  refLevel: number | undefined,
): Partial<Reference> {
  return {
    refMaturity,
    refLevel,
    refSpot: undefined,
    refBasis: undefined,
  };
}
