import type { BookingStep } from '@/neos/business/bookingSteps/BookingStepsSelectors';
import type {
  BookingStepReferenceType,
  BookingStepStepType,
  OnyxBookingStep,
  SkipperOnyxBookingStep,
  SplitOnyxBookingStep,
} from '@/neos/business/bookingSteps/bookingStepOnyxModel';

export interface BookingStepFilter {
  rfqId: string;
  referenceUuid?: string;
  type?: BookingStepReferenceType;
  stepType?: BookingStepStepType;
}

// TODO Optimize for one single filter call
export function filterBookingSteps(
  bookingSteps: BookingStep[],
  filter: BookingStepFilter,
): BookingStep[] {
  const { referenceUuid, stepType, type } = filter;

  if (referenceUuid !== undefined) {
    bookingSteps = bookingSteps.filter(bookingStep => bookingStep.referenceUuid === referenceUuid);
  }
  if (stepType !== undefined) {
    bookingSteps = bookingSteps.filter(bookingStep => bookingStep.stepType === stepType);
  }
  if (type !== undefined) {
    bookingSteps = bookingSteps.filter(bookingStep => bookingStep.type === type);
  }

  return bookingSteps;
}

export function transformToBookingStepsByStepType(
  onyxBookingSteps: OnyxBookingStep[],
  stepType?: BookingStepStepType,
  skipLegs: boolean = false,
) {
  if (stepType === 'SKIPPER') {
    const skipperBookingSteps = onyxBookingSteps.filter(isSkipperOnyxBookingStep);
    return mapFromSkipperOnyxBookingStep(skipperBookingSteps);
  }
  if (!skipLegs && (stepType === 'XONE_SPLIT' || stepType === 'ELIOT_STOCK_SPLIT')) {
    const splitBookingSteps = onyxBookingSteps.filter(isSplitOnyxBookingStep);
    return mapFromSplitOnyxBookingSteps(splitBookingSteps);
  }
  return mapFromOnyxBookingSteps(onyxBookingSteps);
}

export function isSkipperOnyxBookingStep(
  onyxBookingStep: OnyxBookingStep,
): onyxBookingStep is SkipperOnyxBookingStep {
  return onyxBookingStep.stepType === 'SKIPPER';
}

export function isSplitOnyxBookingStep(
  bookingStep: OnyxBookingStep,
): bookingStep is SplitOnyxBookingStep {
  return (bookingStep as SplitOnyxBookingStep).legs !== undefined;
}

/*TODO To simplify */

export function mapFromOnyxBookingSteps(onyxBookingSteps: OnyxBookingStep[]): BookingStep[] {
  return onyxBookingSteps.map(onyxBookingStep => ({
    status: onyxBookingStep.status,
    message: onyxBookingStep.message,
    stepType: onyxBookingStep.stepType,
    isReference: true,
    bookingId: onyxBookingStep.reference.bookingId?.id,
    nettingBookingId: onyxBookingStep.nettingBookingId?.reference.bookingId?.id,
    novationBookingId: onyxBookingStep.novationBookingId?.reference.bookingId?.id,
    novationFeeId: onyxBookingStep.novationFeeId?.reference.bookingId?.id,
    referenceUuid: onyxBookingStep.reference.uuid,
    parentBookingUuid: onyxBookingStep.uuid,
    application: onyxBookingStep.reference.bookingId?.application,
    type: onyxBookingStep.reference.type,
    salesCreditBooked: onyxBookingStep.salesCreditBooked,
    brokenLink: onyxBookingStep.brokenLink,
    lastUpdateTime: onyxBookingStep.reference.lastUpdateTime,
    meteorExGenId: onyxBookingStep.meteorExGenId,
    meteorTradeId: onyxBookingStep.meteorTradeId,
  }));
}

export function mapFromSkipperOnyxBookingStep(
  skipperOnyxBookingSteps: SkipperOnyxBookingStep[],
): BookingStep[] {
  return skipperOnyxBookingSteps.map(skipperOnyxBookingStep => {
    const reference = skipperOnyxBookingStep.reference;
    const xoneReference = skipperOnyxBookingStep.xoneBookingId?.reference;

    const referenceToUse =
      reference.bookingId?.application === 'SKIPPER' && xoneReference !== undefined
        ? xoneReference
        : reference;

    return {
      status: skipperOnyxBookingStep.status,
      stepType: skipperOnyxBookingStep.stepType,
      bookingId: referenceToUse.bookingId?.id,
      application: referenceToUse.bookingId?.application,
      referenceUuid: referenceToUse.uuid,
      parentBookingUuid: skipperOnyxBookingStep.uuid,
      type: skipperOnyxBookingStep.reference.type,
      lastUpdateTime: skipperOnyxBookingStep.reference.lastUpdateTime,
      isReference: true,
      meteorExGenId: skipperOnyxBookingStep.meteorExGenId,
      meteorTradeId: skipperOnyxBookingStep.meteorTradeId,
    };
  });
}

export function mapFromSplitOnyxBookingSteps(
  splitOnyxBookingSteps: SplitOnyxBookingStep[],
): BookingStep[] {
  return splitOnyxBookingSteps.flatMap(splitOnyxBookingStep => {
    return splitOnyxBookingStep.legs.map(allocationReference => {
      return {
        status: splitOnyxBookingStep.status,
        message: splitOnyxBookingStep.message,
        stepType: splitOnyxBookingStep.stepType,
        isReference: false,
        bookingId: allocationReference.bookingId?.id,
        nettingBookingId: splitOnyxBookingStep.nettingBookingId?.reference.bookingId?.id,
        novationBookingId: splitOnyxBookingStep.novationBookingId?.reference.bookingId?.id,
        novationFeeId: splitOnyxBookingStep.novationFeeId?.reference.bookingId?.id,
        referenceUuid: allocationReference.uuid,
        parentBookingUuid: splitOnyxBookingStep.uuid,
        application: allocationReference.bookingId?.application,
        type: allocationReference.type,
        brokenLink: splitOnyxBookingStep.brokenLink,
        lastUpdateTime: allocationReference.lastUpdateTime,
        meteorExGenId: splitOnyxBookingStep.meteorExGenId,
        meteorTradeId: splitOnyxBookingStep.meteorTradeId,
      };
    });
  });
}
