import { crudDomain } from '@/util/crudUtils';
import type { FieldChange } from '../changesNotificationModel';

export interface DeltaNotificationChangesKey {
  rfqId: string;
  underlyingId: string;
}

const compositeIdSeparator = ' | ';

function getDeltaNotificationChangesId({
  rfqId,
  underlyingId,
}: DeltaNotificationChangesKey): string {
  return `${rfqId}${compositeIdSeparator}${underlyingId}${compositeIdSeparator}`;
}

function getDeltaNotificationChangesKey(id: string): DeltaNotificationChangesKey {
  const [rfqId, underlyingId] = id.split(compositeIdSeparator);
  return {
    rfqId,
    underlyingId,
  };
}

export const deltaNotificationChangesDomain = crudDomain<FieldChange, DeltaNotificationChangesKey>(
  'deltaNotificationChanges',
  {
    keyToString: getDeltaNotificationChangesId,
    stringToKey: getDeltaNotificationChangesKey,
  },
);
