import { getElsType } from '@/neos/components/share/blotterGrid/blotterModelGetters/getElsType';
import { getObservableType } from '@/neos/components/share/blotterGrid/blotterModelGetters/getObservableType';
import { getSecondaryEventType } from '@/neos/components/share/blotterGrid/blotterModelGetters/getSecondaryEventType';
import { filterBlotterRows } from './filterBlotterRows';
import { getAllStrategiesExceptDeltaAdj } from './getAllStrategiesExceptDeltaAdj';
import { getAllStrategyTypes } from './getAllStrategyLabels';
import { getBlotterBookingInfoModel } from './getBlotterBookingInfoModel';
import { getBlotterClientWay } from './getBlotterClientWay';
import { getBlotterClientWayLabel } from './getBlotterClientWayLabel';
import { getBlotterDisplayStatus } from './getBlotterDisplayStatus';
import { getBlotterExtraFeatures } from './getBlotterExtraFeatures';
import { getBlotterLegSizes } from './getBlotterLegSizes';
import {
  getBlotterCreationTime,
  getBlotterTradeDate,
  getBlotterUpdateTime,
} from './getBlotterLifecycleTime';
import { getBlotterMasterLotSize } from './getBlotterMasterLotsize';
import { getBlotterMasterSize } from './getBlotterMasterSize';
import { getBlotterNegoTypes } from './getBlotterNegoTypes';
import { getBlotterOptionStyles } from './getBlotterOptionStyles';
import { getBlotterRow, getFullBlotterRow } from './getBlotterRow';
import { getBlotterRows } from './getBlotterRows';
import { getLegMaturities } from './getLegMaturities';
import { getLegStrikes } from './getLegStrikes';
import { getMasterLeg } from './getMasterLeg';
import { getMasterQuantity } from './getMasterQuantity';
import { getMasterRefLevel } from './getMasterRefLevel';
import { getMasterStrategy } from './getMasterStrategy';
import { getRfqMid } from './getRfqMid';
import { getSalesInitName, getSalesValoName } from './getSalesName';
import { getTotalSalesMarginEur } from './getTotalSalesMarginEur';
import { getTraderGroups } from './getTraderGroups';
import { getTraderIds } from './getTraderIds';
import { getTraderNames } from './getTraderNames';
import { getUnderlyingBloombergCodes } from './getUnderlyingBloombergCodes';
import { isLoadableRfq } from './isLoadableRfq';
import { isPtmmm } from './isPtmmm';
import { twoDimentionalArrayToString } from './twoDimentionalArrayToString';

export const blotterModelGetters = {
  getBlotterRow,
  getFullBlotterRow,
  isLoadableRfq,
  twoDimentionalArrayToString,
  getUnderlyingBloombergCodes,
  getBlotterBookingInfoModel,
  getSalesValoName,
  getSalesInitName,
  getLegMaturities,
  getLegStrikes,
  getAllStrategyTypes,
  getBlotterDisplayStatus,
  getTotalSalesMarginEur,
  getBlotterClientWay,
  getBlotterExtraFeatures,
  getBlotterCreationTime,
  getBlotterUpdateTime,
  getBlotterTradeDate,
  filterBlotterRows,
  getBlotterRows,
  getTraderNames,
  getTraderGroups,
  getBlotterMasterSize,
  getBlotterLegSizes,
  getBlotterNegoTypes,
  getBlotterOptionStyles,
  getAllStrategiesExceptDeltaAdj,
  getMasterStrategy,
  getMasterLeg,
  getMasterRefLevel,
  getMasterQuantity,
  getBlotterMasterLotSize,
  isPtmmm,
  getRfqMid,
  getBlotterClientWayLabel,
  getTraderIds,
  getSecondaryEventType,
  getObservableType,
  getElsType,
};

export type BlotterModelGetters = typeof blotterModelGetters;
