import type { PriceWithUnit } from '@/neos/business/neosOnyxModel';
import { NeosBlurInput } from '@/neos/components/share/blurComponent/NeosBlurInput';
import { NumericInput } from '@/neos/components/share/numericInput';
import { type FC, Fragment } from 'react';
import { FormSelect, Modal } from 'react-bootstrap';
import type { MarginRules } from '../../../../../neos/business/neosModel';
import type { SalesCreditStatusModel } from './getSalesCreditStatusModel';

export interface TotalSalesMarginOwnProps {
  rfqId: string;
}

export interface SalesMargin {
  totalAmount: number | undefined;
  includedAmount: number | undefined;
  marginAmount: number | undefined;
  unit: string | undefined;
  isReadOnly?: boolean;
}

export interface AvailableSalesMarginRuleModel {
  rule: MarginRules;
  label: string;
}

export interface TotalSalesMarginModalPropsFromState {
  isMarkupModalShown: boolean;
  availableSalesMarginRules: AvailableSalesMarginRuleModel[];
  selectedSalesCreditIncludedRule: MarginRules | undefined;
  selectedSalesMarginRule: MarginRules | undefined;
  salesMargins: SalesMargin[];
  isReadonlyRfq: boolean;
  salesCreditStatusModel: SalesCreditStatusModel;
  eliotId: {
    isDisplayed: boolean;
    isReadOnly: boolean;
    value: string;
  };
  manualSalesCredit: PriceWithUnit | undefined;
  isManuelSalesCreditReadOnly: boolean;
}

export interface TotalSalesMarginDispatchProps {
  onSalesIncludedRuleChanged(value: MarginRules | undefined): void;

  onSalesMarginRuleChanged(value: MarginRules | undefined): void;

  onSalesCreditIncludedChanged(value: number | undefined): void;

  onSalesCreditMarkUpChanged(value: number | undefined): void;

  onSalesCreditEliotIdChanged(value: string | undefined): void;

  onManualSalesCreditChange(value: number | undefined, totalScUnit: string | undefined): void;

  onHide(): void;
}

type TotalSalesMarginProps = TotalSalesMarginOwnProps &
  TotalSalesMarginModalPropsFromState &
  TotalSalesMarginDispatchProps;

export const TotalSalesMarginModalWithCss: FC<TotalSalesMarginProps> = props => (
  <Modal show={props.isMarkupModalShown} onHide={() => props.onHide()} size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Total Sales Credit</Modal.Title>
    </Modal.Header>
    <Modal.Body className="d-flex flex-column align-items-center">
      <div
        className="d-grid gap-1"
        style={{ gridTemplateColumns: 'repeat(3, minmax(150px, 220px))' }}
      >
        <label className="fw-bold">Total SC</label>
        <label className="fw-bold">Included SC</label>
        <label className="fw-bold">Markup SC</label>

        <div></div>
        <FormSelect
          data-e2e="neos-rfq-sales-included-rule"
          value={props.selectedSalesCreditIncludedRule}
          onChange={event => props.onSalesIncludedRuleChanged(event.target.value as MarginRules)}
        >
          <option value={undefined} />
          {props.availableSalesMarginRules.map(availableRule => (
            <option value={availableRule.rule} key={availableRule.rule}>
              {availableRule.label}
            </option>
          ))}
        </FormSelect>

        <FormSelect
          data-e2e="neos-rfq-sales-markup-rule"
          readOnly={props.isReadonlyRfq}
          value={props.selectedSalesMarginRule}
          onChange={event => props.onSalesMarginRuleChanged(event.target.value as MarginRules)}
        >
          <option value={undefined} />
          {props.availableSalesMarginRules.map(availableRule => (
            <option value={availableRule.rule} key={availableRule.rule}>
              {availableRule.label}
            </option>
          ))}
        </FormSelect>

        {props.salesMargins.map((margin, index) => (
          <Fragment key={index}>
            <NumericInput
              unit={margin.unit}
              value={margin.totalAmount}
              withMaximumNumberOfFloatingDigits={4}
              readOnly
            />
            <NumericInput
              value={margin.includedAmount}
              onBlur={val => props.onSalesCreditIncludedChanged(val)}
              readOnly={margin.isReadOnly}
            />
            <NumericInput
              value={margin.marginAmount}
              onBlur={val => props.onSalesCreditMarkUpChanged(val)}
              readOnly={margin.isReadOnly}
            />
          </Fragment>
        ))}
      </div>

      <div
        className="mt-5 d-grid gap-x-3 gap-y-1 w-75"
        style={{ gridTemplateColumns: 'repeat(2, minmax(150px, 220px))' }}
      >
        {(props.salesCreditStatusModel.message || props.eliotId.isDisplayed) && (
          <>
            {props.eliotId.isDisplayed && (
              <>
                <label className="fw-bold text-nowrap align-self-center text-end">Eliot Id</label>
                <div>
                  <NeosBlurInput
                    type="text"
                    value={props.eliotId.value ?? ''}
                    readOnly={props.eliotId.isReadOnly}
                    data-e2e="sales-credit-eliot-id"
                    onBlur={ev => props.onSalesCreditEliotIdChanged(ev.target.value)}
                  />
                </div>
              </>
            )}
            {props.salesCreditStatusModel.message && (
              <span
                className={`fw-bold fs-3 my-2 text-center text-${props.salesCreditStatusModel.statusColor}`}
                style={{ gridColumn: '1 / 3' }}
              >
                {props.salesCreditStatusModel.message}
              </span>
            )}
          </>
        )}

        <label className="fw-bold text-nowrap text-end align-self-center">
          Manual Sales Credit
        </label>
        <NumericInput
          value={props.manualSalesCredit?.value}
          data-e2e="manual-sales-credit"
          onBlur={value => props.onManualSalesCreditChange(value, props.salesMargins.at(0)?.unit)}
          readOnly={props.isManuelSalesCreditReadOnly}
        />
      </div>
    </Modal.Body>
  </Modal>
);
