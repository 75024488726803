import { FeaturesList } from '@/neos/components/rfq/strategies/strategy/features/featuresList/FeaturesList';
import type { ColumnGridDefinition } from '../../getDisplayedColumns';
import { ExtraFeaturesModal } from './extraFeatureModal';

export interface FeaturesOwnProps {
  rfqId: string;
  strategyId: string;
  columnGridDefinition: ColumnGridDefinition[];
  onFocus?: () => void;
}

export type FeaturesProps = FeaturesOwnProps;

export function Features({ rfqId, strategyId, columnGridDefinition, onFocus }: FeaturesProps) {
  return (
    <>
      <ExtraFeaturesModal strategyId={strategyId} />
      <FeaturesList
        rfqId={rfqId}
        strategyId={strategyId}
        columnGridDefinition={columnGridDefinition}
        onFocus={onFocus}
      />
      <div onFocus={onFocus} onClick={onFocus} />
    </>
  );
}
