import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { thunks } from '@/bootstrap/thunks';
import { Switch } from '@/neos/components/share/switch/Switch';
import { useDispatch, useSelector } from 'react-redux';

export function KeepDividendPaymentDate({
  allocationId,
  rfqId,
  legId,
}: {
  allocationId: string;
  rfqId: string;
  legId: string;
}) {
  const dispatch = useDispatch();

  const currentAllocation = useSelector((state: AppState) =>
    selectors.getOtcAllocationsByAllocId(state, rfqId, legId, allocationId),
  );
  const keepDividendPaymentDate = currentAllocation?.secondaryInfo?.keepDividendPaymentDate;

  const onKeepDividendPaymentDate = (value: boolean) => {
    dispatch(
      thunks.neos.createUpdateSecondaryInfoThunk(allocationId, rfqId, legId, {
        keepDividendPaymentDate: value,
      }),
    );
  };

  return (
    <Switch
      switchClassName="text-center"
      data-e2e="keep_dividend_payment_date"
      checked={!!keepDividendPaymentDate}
      onChange={() => onKeepDividendPaymentDate(!keepDividendPaymentDate)}
    />
  );
}
