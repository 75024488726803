import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import type { InternalMailOptions } from '@/neos/business/preconfirmation/internal/internalOnyxPreconfirmationModel';
import { NeosBlurInput } from '@/neos/components/share/blurComponent/NeosBlurInput';
import { NeosBlurTextarea } from '@/neos/components/share/blurComponent/NeosBlurTextarea';
import { useFeatureToggle } from '@/neos/components/share/featureToggle/useFeatureToggle';
import { cn } from '@/util/classNames';

export interface InternalPreconfirmationBookingOptionsOwnProps {
  rfqId: string;
}

export interface PreconfirmationBookingOptionsStateProps {
  mailOptions: InternalMailOptions | undefined;
  otherFieldIsInvalid: boolean;
}

export interface InternalPreconfirmationBookingOptionsDispatchProps {
  onOtherValueChange(email: string | undefined): void;

  onCommentSelected(comment: string | undefined): void;
}

export type InternalPreconfirmationBookingOptionsProps = PreconfirmationBookingOptionsStateProps &
  InternalPreconfirmationBookingOptionsOwnProps &
  InternalPreconfirmationBookingOptionsDispatchProps;

export const InternalPreconfirmationBookingOptionsComponent = ({
  mailOptions,
  otherFieldIsInvalid,
  rfqId,
  onOtherValueChange,
  onCommentSelected,
}: InternalPreconfirmationBookingOptionsProps) => {
  const labelClass = 'my-auto col-sm-3 col-lg-4 fw-bold';
  const inputClass = 'col-sm-9 col-lg-8';
  const isOtcPreconfEnabled = useFeatureToggle('neos.otc.preconf.enabled');
  const isMasterStrategyOtc = useAppSelector(state =>
    selectors.isMasterStrategyOtc(state, rfqId, selectors),
  );
  const isOtcAndEnabled = isMasterStrategyOtc && isOtcPreconfEnabled;
  return (
    <>
      <h5 className="mb-3">Internal Addresses</h5>
      <div className="container">
        <div className="row mb-2">
          <div className={labelClass}>Internal Other</div>
          <div className={inputClass}>
            <NeosBlurInput
              className={cn({ 'is-invalid': otherFieldIsInvalid })}
              value={mailOptions?.internalOther ?? ''}
              data-e2e="preconfirmation-other"
              onBlur={e => onOtherValueChange(e.target.value || undefined)}
            />
            {otherFieldIsInvalid && (
              <span className="invalid-feedback">
                <em className="icon">close</em> Invalid mail(s)
              </span>
            )}
          </div>
        </div>
        <div className="row mt-4">
          {!isOtcAndEnabled && (
            <div className="w-100 fst-italic text-danger fw-medium">
              Under MIFID || Article 59 and when commissions are charged to clients, all
              pre-confirmations on listed derivatives transactions must be sent to clients by the
              end of T+1 with all the relevant informations set in NEOS.
            </div>
          )}
        </div>
        <div className="row mt-4">
          <div className="w-100 fw-bold">Comment</div>
        </div>
        <div className="row">
          <div className="w-100">
            <NeosBlurTextarea
              className="form-control"
              rows={10}
              value={mailOptions?.emailComment ?? ''}
              data-e2e="preconfirmation-comment"
              onChange={e => onCommentSelected(e.target.value || undefined)}
            />
          </div>
        </div>
      </div>
    </>
  );
};
