import type { Thunk } from '@/bootstrap/thunks';
import type { Destination } from '@/neos/business/rfq/preconfirmationEmailPreview/preconfirmationEmailPreviewModel';

export function createPreconfirmationEmailPreviewRequestedThunk(
  rfqId: string,
  destination: Destination,
  ignoreFieldChanges = false,
): Thunk {
  return function preconfirmationEmailPreviewRequestedThunk(
    dispatch,
    getState,
    { actionCreators, selectors: { getInternalMailOptions, getExternalMailOptions } },
  ) {
    const state = getState();
    const internalMailOptions = getInternalMailOptions(state, rfqId);
    const externalMailOptions = getExternalMailOptions(state, rfqId);
    const mailOptions =
      destination === 'INTERNAL'
        ? { internal: internalMailOptions }
        : { external: externalMailOptions };
    dispatch([
      actionCreators.neos.preconfirmationEmailPreviewRequested(
        rfqId,
        destination,
        mailOptions,
        ignoreFieldChanges,
      ),
      destination === 'INTERNAL'
        ? actionCreators.neos.internalPreconfirmationCrudActions.patchOrInsert(rfqId, {
            ...internalMailOptions,
          })
        : actionCreators.neos.externalPreconfirmationCrudActions.patchOrInsert(rfqId, {
            ...externalMailOptions,
          }),
    ]);
  };
}
