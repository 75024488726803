import type { FC } from 'react';
import { ComboBox } from '../../../../../share/comboBox';
import { ErrorHighlight } from '../../../../../share/errorHighlight';
import { NumericInput } from '../../../../../share/numericInput';
import type { LowerStrikeCellsModel } from './getLowerStrikeCellsModel';

export interface LowerStrikeCellsOwnProps {
  rfqId: string;
  strategyId: string;
}

export interface LowerStrikeCellsMapStateProps {
  model: LowerStrikeCellsModel;
}

export interface LowerStrikeCellsDispatchProps {
  onLowerStrikeChanged(productId: string, lowerStrike: number | undefined): void;
}

type LowerStrikeCellsProps = LowerStrikeCellsOwnProps &
  LowerStrikeCellsMapStateProps &
  LowerStrikeCellsDispatchProps;

export const LowerStrikeCells: FC<LowerStrikeCellsProps> = ({
  rfqId,
  strategyId,
  model,
  onLowerStrikeChanged,
}) => {
  return (
    <section>
      {model.lowerStrikes.map(({ id, value, isEditable }, index) => (
        <ErrorHighlight
          key={id}
          errorField={'lowerStrike'}
          related={{ rfqId, strategyId, legId: id }}
        >
          {model.showAvailableStrikes ? (
            <ComboBox
              disabled={!isEditable}
              className="errorable-bloc"
              onChange={val => onLowerStrikeChanged(id, val)}
              value={value?.value}
              unit={value?.unit}
              options={model.availableStrikes[index] ? model.availableStrikes[index].strikes : []}
              data-e2e="neos-strategy-definition-lower-strike"
            />
          ) : (
            <NumericInput
              readOnly={!isEditable}
              className="errorable-bloc"
              value={value ? value.value : value}
              unit={value ? value.unit : value}
              onBlur={val => onLowerStrikeChanged(id, val)}
              data-e2e="neos-strategy-definition-lower-strike"
            />
          )}
        </ErrorHighlight>
      ))}
    </section>
  );
};
