import { ErrorBoundary } from '@/common/components/errorBoundary/ErrorBoundary';
import { cn } from '@/util/classNames';
import { AlertMenuModal } from './alert/AlertMenuModal.container';
import { BlockingContainer } from './share/blockingContainer/BlockingContainer';
import { NeosTabs } from './tabs/NeosTabs.container';
import { UserPreferencesModal } from './userPreferences/UserPreferencesModal';
import './NeosApp.scss';
import { TopLevelErrorMessage } from '@/neos/components/TopLevelErrorMessage';

export interface NeosAppMapStateToProps {
  contentIsDisabled: boolean;
  isDividedView: boolean;
}

type NeosAppProps = NeosAppMapStateToProps;

export const NeosAppComponent = ({ contentIsDisabled, isDividedView }: NeosAppProps) => {
  return (
    <ErrorBoundary location="NeosApp">
      <BlockingContainer overflowHidden blocking={contentIsDisabled} loaderEnabled>
        <div
          className={cn('neos', isDividedView ? 'blotter-pinned' : 'blotter-invisible')}
          data-e2e="neos-application"
        >
          {!contentIsDisabled && (
            <>
              <UserPreferencesModal />
              <AlertMenuModal />
              <TopLevelErrorMessage />
              <NeosTabs />
            </>
          )}
        </div>
      </BlockingContainer>
    </ErrorBoundary>
  );
};
