import type { Dispatchable, Thunk } from '@/bootstrap/thunks';
import {
  type AsianPeriod,
  type AsianPeriodDate,
  isProductWithAsianFields,
} from '@/neos/business/neosModel';
import { idCreator } from '@/util/id';

export function createAddNewAsianOptionPeriodDateThunk(productId: string): Thunk {
  return function addNewAsianOptionPeriodDateThunk(
    dispatch,
    getState,
    { actionCreators, selectors },
  ) {
    const state = getState();
    const product = selectors.getProduct(state, productId);

    if (!isProductWithAsianFields(product)) {
      return;
    }

    const newLine: AsianPeriodDate = {
      date: undefined,
      weight: undefined,
      uuid: idCreator.createId(),
    };

    const oldPeriod: AsianPeriod = product.period ?? {
      dates: [],
      endDate: '',
      frequency: undefined,
      startDate: '',
      includeEndDate: undefined,
    };

    const dispatchables: Dispatchable[] = [
      actionCreators.neos.productCrudActions.update(product.uuid, {
        period: {
          ...oldPeriod,
          dates: oldPeriod.dates.concat(newLine),
        },
      }),
    ];

    dispatch(dispatchables);
  };
}
