import { camelCase } from 'lodash';
import { isMatch } from 'date-fns';
import type { RawImportedDataSchemaType } from '@/util/excel/excel.ts';
import type { ExcelDateFormat } from '@/neos/business/ui/userPreferences/userPreferencesUiModel.ts';

const sanitizeColumnName = (column: string) => camelCase(column).toLowerCase();

export interface LegPeriodDatesColumnNames {
  equityStartDate: string;
  equityEndDate: string;
  equityPayDate: string;
  rateFirstFixingDate: string;
  rateFixingDate: string;
  rateStartDate: string;
  rateEndDate: string;
  ratePayDate: string;
  dividendStartDate: string;
  dividendEndDate: string;
  dividendTheoreticalPaymentDate: string;
  dividendPayDate: string;
}

const columnCorrespondenceMap: Map<string, keyof LegPeriodDatesColumnNames> = new Map([
  [sanitizeColumnName('Equity Start Date'), 'equityStartDate'],
  [sanitizeColumnName('Equity End Date'), 'equityEndDate'],
  [sanitizeColumnName('Equity Pay Date'), 'equityPayDate'],
  [sanitizeColumnName('Rate First Fixing Date'), 'rateFirstFixingDate'],
  [sanitizeColumnName('Rate Last Fixing Date'), 'rateFixingDate'],
  [sanitizeColumnName('Rate Fixing Date'), 'rateFixingDate'],
  [sanitizeColumnName('Rate Start Date'), 'rateStartDate'],
  [sanitizeColumnName('Rate End Date'), 'rateEndDate'],
  [sanitizeColumnName('Rate Pay Date'), 'ratePayDate'],
  [sanitizeColumnName('Dividend Start Date'), 'dividendStartDate'],
  [sanitizeColumnName('Dividend End Date'), 'dividendEndDate'],
  [sanitizeColumnName('Dividend Theoretical Payment Date'), 'dividendTheoreticalPaymentDate'],
  [sanitizeColumnName('Dividend Pay Date'), 'dividendPayDate'],
]);

export function mapFromScheduleImportedData(
  rawImportedData: RawImportedDataSchemaType,
  excelDateFormat: ExcelDateFormat,
  from: 'clipboard' | 'file',
): Partial<LegPeriodDatesColumnNames>[] {
  const schedules: Partial<LegPeriodDatesColumnNames>[] = [];

  rawImportedData.forEach(line => {
    const scheduleToAdd: Partial<LegPeriodDatesColumnNames> = {};
    Object.entries(line).forEach(([originalColumnName, value]) => {
      if (!value) {
        return;
      }
      const sanitizedColumnName = sanitizeColumnName(originalColumnName);
      const legPeriodField = columnCorrespondenceMap.get(sanitizedColumnName);
      if (legPeriodField !== undefined) {
        if (from === 'clipboard') {
          scheduleToAdd[legPeriodField] = transformToIsoDateFromClipboard(value, excelDateFormat);
        } else {
          scheduleToAdd[legPeriodField] = value;
        }
      }
    });
    schedules.push(scheduleToAdd);
  });

  return schedules;
}

/**
 * @returns a YYYY/mm/dd string date format
 */
function transformToIsoDateFromClipboard(
  dateString: string,
  excelDateFormat: ExcelDateFormat,
): string {
  const sanitizedValue = dateString.replaceAll('/', '-');

  const isIsoDate = isMatch(dateString, 'y-m-d');
  if (isIsoDate) {
    return dateString;
  }

  const dateParts = sanitizedValue.split('-');
  return excelDateFormat === 'EN-US'
    ? // mm/dd/yyyy
      `${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`
    : // dd/mm/yyyy
      `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
}
