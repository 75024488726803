import { getTransfereeContactsEpic } from '@/neos/business/rfq/transfereeContacts/transfereeContactsEpic';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { combineEpics } from 'redux-observable';
import { getActorsSetupRootEpic } from './actorsSetup/actorsSetupEpics';
import * as rfqEpics from './epics';
import { loadPreconfirmationEmailPreviewEpic } from './preconfirmationEmailPreview/preconfirmationEmailPreviewEpic';
import { getVersionsEpic } from './versions/versionsEpics';

export function getRfqRootEpic(http: SgmeHttp) {
  return combineEpics(
    getActorsSetupRootEpic(http),
    ...rfqEpics.getRfqEpics(http),
    getVersionsEpic(http),
    loadPreconfirmationEmailPreviewEpic(http),
    getTransfereeContactsEpic(http),
  );
}
