import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { thunks } from '@/bootstrap/thunks';
import type {
  ElsBasket,
  ExecFeesValue,
} from '@/neos/business/rfq/strategy/leg/product/productModel';
import { BasketCompositionDetailsGridRow } from '@/neos/components/rfq/strategies/strategy/features/elsBasket/BasketCompositionDetailsGridRow';
import { isDefined } from '@/util/undefinedAndNull/isDefined';
import { useDispatch } from 'react-redux';

interface ElsBasketDescriptionProps {
  rfqId: string;
  strategyId: string;
  product: ElsBasket;
}

export function ElsBasketComposition({ rfqId, strategyId, product }: ElsBasketDescriptionProps) {
  const dispatch = useDispatch();
  const underlyingTypes = useAppSelector(state =>
    selectors.getUnderlyingTypes(state, strategyId, selectors),
  );
  const alreadySelectedUnderlyings = product.basketUnderlying.basketComposition
    .map(u => u.underlyingId)
    .filter(isDefined);

  const onAddNewLine = () => {
    dispatch(thunks.neos.createAddNewElsBasketCompositionItemThunk(product.uuid));
  };

  const { execFees } = product.basketUnderlying;
  const numberOfColumn = getNumberOfColumn(execFees);

  return (
    <div
      className="d-grid align-items-center gap-1"
      style={{ gridTemplateColumns: `32px repeat(${6 + numberOfColumn}, 1fr)` }}
    >
      <button className="btn btn-icon btn-flat-primary" onClick={onAddNewLine}>
        <i className="icon icon-md">add</i>
      </button>
      <label className="fw-bold">Underlying</label>
      <label className="fw-bold">Weight</label>
      <label className="fw-bold">Quantity</label>
      <label className="fw-bold">Spot</label>
      <BasketExecFeesInLabel execFees={execFees} />
      <BasketExecFeesOutLabel execFees={execFees} />
      <label className="fw-bold">Spot Net</label>
      <label className="fw-bold">Nominal</label>

      {product.basketUnderlying.basketComposition.map((compositionDetails, index) => (
        <BasketCompositionDetailsGridRow
          product={product}
          rfqId={rfqId}
          underlyingTypes={underlyingTypes}
          compositionDetails={compositionDetails}
          index={index}
          key={compositionDetails.containerIndex}
          exclude={alreadySelectedUnderlyings}
        />
      ))}
    </div>
  );
}

function BasketExecFeesInLabel({ execFees }: { execFees: ExecFeesValue }) {
  if (execFees !== 'IN' && execFees !== 'IN_AND_OUT') {
    return null;
  }
  return <label className="fw-bold">Exec Fees In</label>;
}
function BasketExecFeesOutLabel({ execFees }: { execFees: ExecFeesValue }) {
  if (execFees !== 'OUT' && execFees !== 'IN_AND_OUT') {
    return null;
  }
  return <label className="fw-bold">Exec Fees Out</label>;
}

function getNumberOfColumn(execFeesKind: ExecFeesValue): number {
  if (execFeesKind === 'IN' || execFeesKind === 'OUT') {
    return 1;
  }
  if (execFeesKind === 'IN_AND_OUT') {
    return 2;
  }
  return 0;
}
