import type { Selectors } from '@/bootstrap/selectors';
import { services } from '@/bootstrap/services';
import type { AppState } from '@/bootstrap/state';
import type { FromOnyxMappers, ToOnyxMappers } from '../mappers';
import type { DeltaStrategyScope, RfqData } from '../neosModel';
import type { OnyxPreAlloc, OnyxRfq, OnyxSalesDiffusion, OnyxStrategy } from '../neosOnyxModel';
import type { ActorsSetup } from './actorsSetup/actorsSetupModel';
import { fromCounterpartMappers } from './actorsSetup/counterpart/counterpartMapper';
import { fromFairPriceMappers, toFairPriceMappers } from './fairPrice/fairPriceMapper';
import { fromHedgeMappers, toHedgeMappers } from './hedge/hedgeMapper';
import {
  type ListedPreAlloc,
  type OtcPreAlloc,
  type PreAllocConfirmationMedia,
  isDerivativeProduct,
} from './models';
import { fromQuoteMappers, toQuoteMappers } from './quotes/quoteMappers';
import { fromReferenceMappers, toReferenceMappers } from './reference/referenceMapper';
import type { PriceWithUnit, SalesDiffusionFeatureToggles } from './rfqOnyxModel';
import { fromStrategyMappers, toStrategyMappers } from './strategy/strategyMapper';

export const toRfqMappers = {
  mapToOnyxRfqWithLatestLocalVersion,
  mapToOnyxRfqWithCurrentLocalVersion,
  mapToOnyxRfqWithoutVersion,
  mapToOnyxOtcPreAllocs,
  mapToOnyxListedPreAllocs,
  ...toStrategyMappers,
  ...toFairPriceMappers,
  ...toReferenceMappers,
  ...toQuoteMappers,
  ...toHedgeMappers,
};

export const fromRfqMappers = {
  mapFromOnyxRfq,
  mapFromOnyxOtcPreAlloc,
  mapFromOnyxListedPreAlloc,
  ...fromQuoteMappers,
  ...fromFairPriceMappers,
  ...fromStrategyMappers,
  ...fromReferenceMappers,
  ...fromHedgeMappers,
  ...fromCounterpartMappers,
};

function mapToOnyxRfqWithCurrentLocalVersion(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
  mappers: ToOnyxMappers,
): OnyxRfq {
  const { version } = selectors.getRfqData(state, rfqId);
  return {
    ...mappers.mapToOnyxRfqWithoutVersion(state, rfqId, selectors, mappers),
    version,
  };
}

function mapToOnyxRfqWithLatestLocalVersion(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
  mappers: ToOnyxMappers,
): OnyxRfq {
  const { version } = selectors.getRfqData(state, rfqId);
  const latestLocalVersion = selectors.getLatestVersion(state, rfqId);
  const versionToUse = version > latestLocalVersion ? version : latestLocalVersion;

  return {
    ...mappers.mapToOnyxRfqWithoutVersion(state, rfqId, selectors, mappers),
    version: versionToUse,
  };
}

function mapToOnyxRfqWithoutVersion(
  appState: AppState,
  rfqId: string,
  selectors: Selectors,
  mappers: ToOnyxMappers,
): Omit<OnyxRfq, 'version'> {
  const salesValoId = selectors.getSelectedSalesValoId(appState, rfqId);
  const salesInitId = selectors.getSalesInitId(appState, rfqId);
  const salesDiffusionFeatureToggles = selectors.getSalesDiffusionFeatureToggles(appState, rfqId);
  const salesInitLocation = selectors.getSalesPhysicalLocation(appState, rfqId);
  const salesInitCityLocation = selectors.getSalesInitCityLocation(appState, rfqId);
  const counterpartId = selectors.getSelectedSalesCounterpartId(appState, rfqId);
  const isManualBookingSCToggleEnabled = selectors.isFeatureToggleEnabled(
    appState,
    'neos.manually.booking.sc',
  );
  const {
    internal,
    chatComment,
    source,
    status,
    subStatus,
    activity,
    creationTime,
    orderReceivedUTCTimestamp,
    workflow,
    strategyIds,
    deltaHedgingStrategyIds,
    uuid,
    comment,
    clientComment,
    notionalCurrency,
    notionalAmount,
    pivotNotionalAmount,
    pivotNotionalCurrency,
    usdNotionalAmount,
    usdNotionalCurrency,
    eurNotionalAmount,
    eurNotionalCurrency,
    crossBorder,
    fairPriceId,
    quoteRecap,
    totalSalesMargin,
    totalSalesMarginEur,
    totalPremiums,
    aggregatedDataByPriceUnit,
    dataAggregationByUnderlyingId,
    predealCheckIds,
    quoteType,
    priceRequestUTCDateAndTime,
    tradeDate,
    screenPrice,
    orderReceivedExpirationUTCTimestamp,
    rfqExpirationUTCTimestamp,
    hasToConfirmDelta,
    hasQuoteExpirationTime,
    quoteExpiryUTCTime,
    quoteValidityDuration,
    feedbackPriceValue,
    feedbackPriceUnit,
    feedbackPriceType,
    feedbackAxe,
    feedbackSpreadWidth,
    tradeRecap,
    salesCreditBookingReference,
    salesLink,
    salesMarginAmountEurByAllocation,
    quoteInBasis,
    quoteInCurrency,
    quoteInPercent,
    legQuotes,
    strategyQuotes,
    rfqPricingExpirationUTCTimestamp,
    manualSalesCredit,
    notionalInSwapCurrencyAmount,
    notionalInSwapCurrencyCurrency,
    initiatedByTrader,
    bookingValidation,
  } = selectors.getRfqData(appState, rfqId);
  const forexRates = selectors.getForexRates(appState, rfqId);

  const strategies = strategyIds.map(id =>
    mappers.mapToOnyxStrategy(appState, rfqId, id, selectors, mappers),
  );

  const deltas = deltaHedgingStrategyIds.map(id =>
    mappers.mapToOnyxStrategy(appState, rfqId, id, selectors, mappers),
  );

  const result: Omit<OnyxRfq, 'version'> = {
    internal,
    chatComment,
    uuid,
    source,
    tradeRecap,
    activity,
    workflow,
    tradeDate,
    comment,
    clientComment,
    strategies,
    totalPremiums,
    salesDiffusion: mapToSalesDiffusion(
      salesInitId,
      salesValoId,
      salesInitLocation,
      salesInitCityLocation,
      salesDiffusionFeatureToggles,
    ),
    salesCounterparty: counterpartId ? { id: counterpartId } : undefined,
    lifecycle: { status, subStatus, creationTime, orderReceivedUTCTimestamp },
    quoteType,
    crossBorder,
    notional: mapNotional(notionalAmount, notionalCurrency),
    notionalInSwapCurrency: mapNotional(
      notionalInSwapCurrencyAmount,
      notionalInSwapCurrencyCurrency,
    ),
    pivotNotional: mapNotional(pivotNotionalAmount, pivotNotionalCurrency),
    usdNotional: mapNotional(usdNotionalAmount, usdNotionalCurrency),
    eurNotional: mapNotional(eurNotionalAmount, eurNotionalCurrency),
    fairPrice: mappers.mapToOnyxFairPrice(appState, fairPriceId, selectors),
    forexRates,
    quote: mappers.mapToOnyxQuoteForRfq(appState, rfqId, selectors),
    deltas,
    quoteRecap,
    totalSalesMarginAmount: totalSalesMargin,
    totalSalesMarginAmountEur: totalSalesMarginEur,
    aggregatedDataByPriceUnit,
    pdcs: predealCheckIds,
    priceRequestUTCDate: priceRequestUTCDateAndTime,
    dataAggregationByUnderlyingId,
    hedges: mappers.mapToOnyxHedges(appState, rfqId, selectors),
    screenPrice,
    orderReceivedExpirationUTCTimestamp,
    rfqExpirationUTCTimestamp,
    hasToConfirmDelta,
    hasQuoteExpirationTime,
    quoteExpiryUTCTime,
    quoteValidityDuration,
    tradedAwayFeedback: {
      price: {
        value: feedbackPriceValue,
        unit: feedbackPriceUnit,
        type: feedbackPriceType,
      },
      axe: feedbackAxe,
      spreadWidth: feedbackSpreadWidth,
    },
    salesCreditBookingReference: isManualBookingSCToggleEnabled
      ? salesCreditBookingReference
      : undefined,
    salesLink,
    salesMarginAmountEurByAllocation,
    quoteInBasis,
    quoteInCurrency,
    quoteInPercent,
    legQuotes,
    strategyQuotes,
    rfqPricingExpirationUTCTimestamp,
    manualSalesCredit,
    initiatedByTrader,
    bookingValidation,
  };
  return result;
}

function mapNotional(
  amount: number | undefined,
  currency: string | undefined,
): PriceWithUnit | undefined {
  return amount || currency ? { unit: currency, value: amount } : undefined;
}

function mapToSalesDiffusion(
  salesInitId: string,
  salesValoId: string | undefined,
  salesInitLocation: string | undefined,
  salesInitCityLocation: string | undefined,
  featureToggles: SalesDiffusionFeatureToggles,
): OnyxSalesDiffusion {
  return {
    salesInit: { id: salesInitId, physicalLocation: salesInitLocation },
    salesValo: salesValoId ? { id: salesValoId } : undefined,
    salesInitCityLocation,
    featureToggles,
  };
}

export interface MapFromOnyxRfqResult {
  rfqData: RfqData;
  actorSetup: Omit<ActorsSetup, 'counterparts' | 'contacts'>;
}

function mapFromOnyxRfq(
  onyxRfq: OnyxRfq,
  sesameId: string,
  fromMappers: FromOnyxMappers,
): MapFromOnyxRfqResult {
  const {
    bookingValidation,
    internal,
    chatComment,
    uuid,
    activity,
    lifecycle: { status, subStatus, creationTime, orderReceivedUTCTimestamp },
    workflow,
    source,
    version,
    comment,
    clientComment,
    notional,
    quoteRecap,
    tradeRecap,
    tradeDate,
    crossBorder,
    pivotNotional,
    usdNotional,
    eurNotional,
    totalSalesMarginAmount,
    totalSalesMarginAmountEur,
    totalPremiums,
    aggregatedDataByPriceUnit,
    pdcs,
    strategies,
    deltas,
    quoteType,
    priceRequestUTCDate,
    dataAggregationByUnderlyingId,
    orderReceivedExpirationUTCTimestamp,
    rfqExpirationUTCTimestamp,
    screenPrice,
    hasToConfirmDelta,
    hasQuoteExpirationTime,
    quoteExpiryUTCTime,
    quoteValidityDuration,
    tradedAwayFeedback,
    salesCreditBookingReference,
    salesLink,
    salesMarginAmountEurByAllocation,
    quoteInBasis,
    quoteInCurrency,
    quoteInPercent,
    legQuotes,
    strategyQuotes,
    refSpotInSwapCurrency,
    rfqPricingExpirationUTCTimestamp,
    warnings,
    manualSalesCredit,
    notionalInSwapCurrency,
    initiatedByTrader,
  } = onyxRfq;
  const notionalCurrency = notional ? notional.unit : undefined;
  const notionalAmount = notional ? notional.value : undefined;

  const deltaStrategies = deltas ?? [];

  const { mapFromOnyxOtcPreAlloc, mapFromOnyxListedPreAlloc } = fromMappers;
  const otcPreAllocs: OtcPreAlloc[] = mapFromOnyxOtcPreAlloc(strategies);
  const deltaOtcPreAllocs: OtcPreAlloc[] = mapFromOnyxOtcPreAlloc(deltaStrategies);
  const listedPreAllocs: ListedPreAlloc[] = mapFromOnyxListedPreAlloc(strategies);
  const deltaListedPreAllocs: ListedPreAlloc[] = mapFromOnyxListedPreAlloc(deltaStrategies);

  const result: MapFromOnyxRfqResult = {
    rfqData: {
      internal,
      chatComment,
      source,
      workflow,
      uuid,
      activity,
      status,
      subStatus,
      savedStatus: status,
      totalPremiums,
      clientWay: services.computeClientWay(onyxRfq.strategies[0]),
      strategyIds: onyxRfq.strategies.map(strategy => strategy.uuid),
      deltaHedgingStrategyIds: onyxRfq.deltas ? onyxRfq.deltas.map(strategy => strategy.uuid) : [],
      version,
      notionalCurrency,
      notionalAmount,
      notionalInSwapCurrencyCurrency: notionalInSwapCurrency?.unit,
      notionalInSwapCurrencyAmount: notionalInSwapCurrency?.value,
      pivotNotionalAmount: pivotNotional && pivotNotional.value,
      pivotNotionalCurrency: pivotNotional && pivotNotional.unit,
      usdNotionalAmount: usdNotional && usdNotional.value,
      usdNotionalCurrency: usdNotional && usdNotional.unit,
      eurNotionalAmount: eurNotional && eurNotional.value,
      eurNotionalCurrency: eurNotional && eurNotional.unit,
      comment,
      clientComment,
      quoteRecap,
      tradeRecap,
      tradeDate,
      crossBorder,
      fairPriceId: onyxRfq.uuid,
      quoteId: uuid,
      totalSalesMargin: totalSalesMarginAmount,
      totalSalesMarginEur: totalSalesMarginAmountEur,
      aggregatedDataByPriceUnit,
      dataAggregationByUnderlyingId,
      predealCheckIds: pdcs || [],
      otcPreAllocs,
      deltaOtcPreAllocs,
      listedPreAllocs,
      deltaListedPreAllocs,
      quoteType: quoteType || 'TRADABLE',
      priceRequestUTCDateAndTime: priceRequestUTCDate,
      screenPrice,
      creationTime,
      orderReceivedUTCTimestamp,
      orderReceivedExpirationUTCTimestamp,
      rfqExpirationUTCTimestamp,
      hasToConfirmDelta,
      hasQuoteExpirationTime,
      quoteExpiryUTCTime,
      quoteValidityDuration,
      feedbackPriceValue: tradedAwayFeedback?.price?.value,
      feedbackPriceUnit: tradedAwayFeedback?.price?.unit,
      feedbackPriceType: tradedAwayFeedback?.price?.type,
      feedbackAxe: tradedAwayFeedback?.axe,
      feedbackSpreadWidth: tradedAwayFeedback?.spreadWidth,
      salesCreditBookingReference,
      salesLink,
      salesMarginAmountEurByAllocation,
      quoteInBasis,
      quoteInCurrency,
      quoteInPercent,
      legQuotes,
      strategyQuotes,
      refSpotInSwapCurrency,
      rfqPricingExpirationUTCTimestamp,
      warnings,
      manualSalesCredit,
      initiatedByTrader,
      bookingValidation,
    },
    actorSetup: {
      counterpartId: onyxRfq.salesCounterparty?.id,
      eliotCode: onyxRfq.salesCounterparty?.eliotCode,
      mnemo: onyxRfq.salesCounterparty?.mnemo,
      name: onyxRfq.salesCounterparty?.name,
      salesValoId: onyxRfq.salesDiffusion?.salesValo?.id,
      salesGroupId: onyxRfq.salesDiffusion?.salesGroup?.id,
      salesInitId: onyxRfq.salesDiffusion?.salesInit?.id ?? sesameId,
      salesPhysicalLocation: onyxRfq.salesDiffusion?.salesInit?.physicalLocation,
      salesInitCityLocation: onyxRfq.salesDiffusion?.salesInitCityLocation,
      salesDiffusionFeatureToggles: onyxRfq.salesDiffusion?.featureToggles,
    },
  };

  return result;
}

function mapToOnyxOtcPreAllocs(
  state: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): OnyxPreAlloc[] | undefined {
  const { scope } = selectors.getStrategyData(state, strategyId);

  if (!arePreAllocConsistent(scope, strategyId, state, selectors)) {
    return undefined;
  }

  const preAllocProp: keyof Pick<RfqData, 'otcPreAllocs' | 'deltaOtcPreAllocs'> =
    scope === 'RFQ' ? 'otcPreAllocs' : 'deltaOtcPreAllocs';

  const preAllocs = selectors.getRfqData(state, rfqId)[preAllocProp];

  return (
    (preAllocs.length &&
      preAllocs.map((preAlloc): OnyxPreAlloc => {
        const {
          counterpartId,
          ratio,
          independantAmountValue,
          independantAmountType,
          independantAmountUnit,
          independantAmountValueDate,
        } = preAlloc;

        return {
          discriminator: 'OTC',
          counterparty: counterpartId !== undefined ? { id: counterpartId } : undefined,
          ratio,
          markitWireEligible: preAlloc.markitWireEligible,
          mcaEligible: preAlloc.mcaEligible,
          confirmationMedia:
            preAlloc.media === 'MARKITWIRE'
              ? {
                  discriminator: 'MARKITWIRE',
                  mode: preAlloc.markitWireMode,
                  counterpartyTrader: preAlloc.electronicMediaUser,
                  eligibilityReason: preAlloc.eligibilityReason,
                  markitWireReferenceId: preAlloc.markitWireReferenceId,
                }
              : preAlloc.media
                ? {
                    discriminator: 'PAPER',
                    forcedReason: preAlloc.forcedReason,
                    form: preAlloc.media,
                    eligibilityReason: preAlloc.eligibilityReason,
                  }
                : undefined,
          independentAmount: {
            nominal: {
              value: independantAmountValue,
              type: independantAmountType,
              unit: independantAmountUnit,
            },
            valueDate: independantAmountValueDate,
          },
        };
      })) ||
    undefined
  );
}

function mapToOnyxListedPreAllocs(
  state: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): OnyxPreAlloc[] | undefined {
  const { scope } = selectors.getStrategyData(state, strategyId);

  if (!arePreAllocConsistent(scope, strategyId, state, selectors)) {
    return undefined;
  }

  const counterpartId = selectors.getSelectedCounterpartId(state, rfqId);

  const preAllocProp: keyof Pick<RfqData, 'listedPreAllocs' | 'deltaListedPreAllocs'> =
    scope === 'RFQ' ? 'listedPreAllocs' : 'deltaListedPreAllocs';

  const preAllocs = selectors.getRfqData(state, rfqId)[preAllocProp];

  return preAllocs.length
    ? preAllocs.map(
        ({ clearerAccount, broker, commission, commissionType, ratio }): OnyxPreAlloc => ({
          discriminator: 'LISTED',
          counterparty: counterpartId !== undefined ? { id: counterpartId } : undefined,
          clearingInfo: clearerAccount ? { clearerAccount } : undefined,
          commissionInfo: commission || commissionType ? { commission, commissionType } : undefined,
          broker,
          ratio,
        }),
      )
    : undefined;
}

function arePreAllocConsistent(
  scope: DeltaStrategyScope | 'RFQ',
  strategyId: string,
  state: AppState,
  selectors: Selectors,
): boolean {
  if (scope !== 'RFQ' && scope !== 'DELTA_EXCHANGE' && scope !== 'DELTA_EXCHANGE_OTC') {
    return false;
  }

  if (
    !selectors.isFeatureToggleEnabled(state, 'neos.allocs.enabled') &&
    !selectors.isFeatureToggleEnabled(state, 'neos.otc.allocs.enabled')
  ) {
    return false;
  }

  const hasDerivativeProduct = selectors
    .getStrategyProducts(state, strategyId, selectors)
    .some(product => isDerivativeProduct(product));

  return !(scope === 'DELTA_EXCHANGE' && !hasDerivativeProduct);
}

function findStrategyWithPreAllocs(
  strategies: OnyxStrategy[],
  discriminator: 'LISTED' | 'OTC',
): OnyxStrategy | undefined {
  return strategies.find(
    strategy =>
      strategy?.legs?.[0]?.product?.negotiation?.discriminator === discriminator &&
      strategy?.preAllocs?.length,
  );
}

function mapFromOnyxOtcPreAlloc(strategies: OnyxStrategy[]): OtcPreAlloc[] {
  const firstOtcStrategy = findStrategyWithPreAllocs(strategies, 'OTC');
  return (
    firstOtcStrategy?.preAllocs?.map?.((onyxPreAlloc): OtcPreAlloc => {
      if (onyxPreAlloc.discriminator === 'LISTED') {
        throw new Error('Cannot map listed preAlloc for an otc strategy!');
      }
      const { confirmationMedia: onyxConfirmationMedia } = onyxPreAlloc;

      const confirmationMedia: PreAllocConfirmationMedia =
        onyxConfirmationMedia?.discriminator === 'MARKITWIRE'
          ? {
              media: 'MARKITWIRE',
              markitWireMode: onyxConfirmationMedia.mode,
              electronicMediaUser: onyxConfirmationMedia.counterpartyTrader,
              eligibilityReason: onyxConfirmationMedia.eligibilityReason,
              markitWireReferenceId: onyxConfirmationMedia.markitWireReferenceId,
            }
          : onyxConfirmationMedia?.form === undefined
            ? {
                media: undefined,
                eligibleMedia: onyxConfirmationMedia?.form === 'UNKNOWN' ? 'LONG_FORM' : undefined,
                eligibilityReason: onyxConfirmationMedia?.eligibilityReason,
              }
            : {
                media: onyxConfirmationMedia?.form,
                forcedReason: onyxConfirmationMedia?.forcedReason,
                eligibilityReason: onyxConfirmationMedia.eligibilityReason,
              };

      return {
        counterpartId: onyxPreAlloc.counterparty?.id,
        ratio: onyxPreAlloc.ratio,
        independantAmountValue: onyxPreAlloc.independentAmount?.nominal?.value,
        independantAmountUnit: onyxPreAlloc.independentAmount?.nominal?.unit,
        independantAmountType: onyxPreAlloc.independentAmount?.nominal?.type,
        markitWireEligible: onyxPreAlloc.markitWireEligible,
        mcaEligible: onyxPreAlloc.mcaEligible,
        independantAmountValueDate: onyxPreAlloc.independentAmount?.valueDate,
        ...confirmationMedia,
      };
    }) ?? []
  );
}

function mapFromOnyxListedPreAlloc(strategies: OnyxStrategy[]): ListedPreAlloc[] {
  const firstListedStrategy = findStrategyWithPreAllocs(strategies, 'LISTED');
  return (
    firstListedStrategy?.preAllocs?.map?.((onyxPreAlloc): ListedPreAlloc => {
      if (onyxPreAlloc.discriminator === 'OTC') {
        throw new Error('Cannot map otc preAlloc for a listed strategy!');
      }
      const { clearingInfo, commissionInfo, broker, ratio } = onyxPreAlloc;
      return {
        clearerAccount: clearingInfo?.clearerAccount,
        broker,
        commission: commissionInfo?.commission,
        commissionType: commissionInfo?.commissionType,
        ratio,
      };
    }) ?? []
  );
}
