import { initial } from 'lodash';
import type {
  PreviousStrategySelectedAction,
  SelectedStrategyAction,
  StrategySelectedAction,
} from './selectedStrategyActions';
import { type SelectedStrategyState, defaultSelectedStrategyState } from './selectedStrategyState';

export function selectedStrategyReducer(
  state: SelectedStrategyState = defaultSelectedStrategyState,
  action: SelectedStrategyAction,
): SelectedStrategyState {
  switch (action.type) {
    case 'STRATEGY_SELECTED':
      return strategySelectedReducer(state, action);
    case 'PREVIOUS_STRATEGY_SELECTED':
      return previousStrategySelectedReducer(state, action);
    default:
      return state;
  }
}

function strategySelectedReducer(
  state: SelectedStrategyState,
  { rfqId, selectedStrategyId }: StrategySelectedAction,
): SelectedStrategyState {
  const selectedStrategy = state[rfqId];
  const newList = (selectedStrategy ? selectedStrategy.previouslySelectedStrategyIds || [] : [])
    .filter(sId => sId !== selectedStrategyId)
    .concat(selectedStrategyId);

  return patchSelectedList(state, rfqId, newList);
}

function previousStrategySelectedReducer(
  state: SelectedStrategyState,
  { rfqId }: PreviousStrategySelectedAction,
): SelectedStrategyState {
  const selectedStrategyPerRfq = state[rfqId];
  const newList = initial(selectedStrategyPerRfq.previouslySelectedStrategyIds);

  return patchSelectedList(state, rfqId, newList);
}

function patchSelectedList(state: SelectedStrategyState, rfqId: string, newList: string[]) {
  const selectedStrategyPerRfq = state[rfqId];
  return {
    ...state,
    [rfqId]: {
      ...selectedStrategyPerRfq,
      previouslySelectedStrategyIds: newList,
    },
  };
}
