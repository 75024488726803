import { ErrorBoundary } from '@/common/components/errorBoundary/ErrorBoundary';
import { CreateSecondaryFromXone } from '@/neos/components/blotter/createSecondaryFromXone/CreateSecondaryFromXone';
import { BlockingContainer } from '../share/blockingContainer/BlockingContainer';
import { BlotterPanelInformation } from './blotterPanelInformation/BlotterPanelInformation.container';
import { BlotterGrid } from './grid/BlotterGrid.container';
import { BlotterSearch } from './search/BlotterSearch.container';

import { OpenRfq } from '@/neos/components/blotter/openRfq/OpenRfq';
import styles from './Blotter.module.scss';

export interface BlotterPropsFromState {
  isLoading: boolean;
}

export function BlotterWithCss({ isLoading }: BlotterPropsFromState) {
  return (
    <BlockingContainer className="blotter-block w-100" blocking={isLoading}>
      <div className={`${styles['blotter']}`} data-e2e="neos-blotter">
        <section className={`${styles['search']}`}>
          <h5 className="ms-1 pb-1">Search criteria</h5>
          <BlotterSearch />
        </section>
        <section className={`${styles['open']}`}>
          <h5 className="ms-1 pb-1">Open RFQ</h5>
          <div className="d-flex">
            <OpenRfq />
            <CreateSecondaryFromXone />
          </div>
        </section>
        <section className={`${styles['grid']}`}>
          <BlotterPanelInformation />
          <ErrorBoundary location="Blotter">
            <BlotterGrid />
          </ErrorBoundary>
        </section>
      </div>
    </BlockingContainer>
  );
}
