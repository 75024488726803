import type { MouseEventHandler } from 'react';
import styles from './CircularProgressBar.module.scss';
export interface CircularProgressBarProps {
  sqSize: number;
  strokeWidth: number;
  percentage: number | undefined;
  onClick?: MouseEventHandler<unknown>;
}

export function CircularProgressBar({
  sqSize,
  strokeWidth,
  percentage,
  onClick,
}: CircularProgressBarProps) {
  // SVG centers the stroke width on the radius, subtract out so circle fits in square
  const radius = (sqSize - strokeWidth) / 2;
  // Enclose cicle in a circumscribing square
  const viewBox = `0 0 ${sqSize} ${sqSize}`;
  // Arc length at 100% coverage is the circle circumference
  const dashArray = radius * Math.PI * 2;
  // Scale 100% coverage overlay with the actual percent
  const dashOffset = dashArray - (dashArray * (percentage ?? 0)) / 100;

  return (
    <svg
      className={`${styles['progress-circle-svg']}`}
      width={sqSize}
      height={sqSize}
      viewBox={viewBox}
      onClick={onClick}
    >
      <circle
        className={`${styles['circle-background']}`}
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
      />
      <circle
        className={`${styles['circle-progress']}`}
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
        transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
        style={{
          strokeDasharray: dashArray,
          strokeDashoffset: dashOffset,
        }}
      />
      <text className={`${styles['circle-text']}`} x="50%" y="50%" dy=".3em" textAnchor="middle">
        {percentage !== undefined ? `${percentage}%` : ''}
      </text>
    </svg>
  );
}
