import type { Thunk } from '@/bootstrap/thunks';

export function createEmptyPendingBlotterRfqsThunk(): Thunk {
  return function emptyPendingBlotterRfqsThunk(
    dispatch,
    getState,
    {
      selectors: { getPendingRfqIds },
      actionCreators: {
        neos: { createEmptyPendingRfqIdsAction },
      },
    },
  ) {
    const appState = getState();
    const isThereAnyPendingRfqId: boolean = !!getPendingRfqIds(appState.ui.blotter).length;
    if (isThereAnyPendingRfqId) {
      dispatch(createEmptyPendingRfqIdsAction());
    }
  };
}
