import type { Thunk } from '@/bootstrap/thunks';
import {
  type ClsType,
  DefaultingScope,
  type InterestRateFeatureKey,
  type SwapCurrencyFeatureKey,
} from '../../../../../../neos/business/neosModel';
import { DefaultingOptions } from '../../../rfqActions';

export function createUpdateProductClsTypeThunk(
  strategyId: string,
  rfqId: string,
  productId: string,
  clsType: ClsType | undefined,
): Thunk {
  return function updateProductClsTypeThunk(
    dispatch,
    _,
    {
      thunks: {
        neos: { createUpdateProductThunk, createChangeFeatureFieldThunk },
      },
      actionCreators,
    },
  ) {
    const featureKey: InterestRateFeatureKey = { strategyId, type: 'INTEREST_RATE_INDEX_FEATURE' };
    const swapFeatureKey: SwapCurrencyFeatureKey = { strategyId, type: 'SWAP_CURRENCY' };
    dispatch(createUpdateProductThunk(strategyId, productId, 'clsType', clsType));
    if (clsType === 'ERI') {
      dispatch(
        createChangeFeatureFieldThunk(featureKey, {
          rateCurve: undefined,
          newRateTenor: undefined,
        }),
        createChangeFeatureFieldThunk(swapFeatureKey, {
          swapCurrency: undefined,
        }),
      );
    }

    if (clsType === 'TFR' || clsType === 'TRI') {
      const overrideScopes = [DefaultingScope.SWAP_CURRENCY, DefaultingScope.INTEREST_RATE_INDEX];
      const defaultingOptions = new DefaultingOptions({
        overrideScopes,
      });

      dispatch(actionCreators.neos.createDefaultRfqRequestedAction(rfqId, defaultingOptions));
    }
  };
}
