import { type ActionCreators, actionCreators } from '@/bootstrap/actions';
import type { ThunkEpic } from '@/bootstrap/epics';
import { type Thunks, thunks } from '@/bootstrap/thunks';
import type { OnyxError } from '@/neos/business/mappers/error';
import type { Broker } from '@/neos/business/referenceData/brokers/brokersModel';
import {
  type BrokersPreferencesApis,
  brokersPreferencesApis,
} from '@/neos/business/ui/userPreferences/brokers/brokersPreferencesApis';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { ofType } from 'redux-observable';
import { catchError, mergeMap } from 'rxjs/operators';

export function getSearchBrokerNotInPreferencesEpic(http: SgmeHttp): ThunkEpic {
  const api = brokersPreferencesApis(http);
  return createSearchBrokerNotInPreferencesEpic(api, actionCreators, thunks);
}

export function createSearchBrokerNotInPreferencesEpic(
  api: BrokersPreferencesApis,
  actionCreators: ActionCreators,
  { createErrorToasterThunk }: Thunks,
): ThunkEpic {
  return action$ =>
    action$.pipe(
      ofType('RFQ_SEARCH_BROKER_NOT_IN_PREFERENCES'),
      mergeMap(({ rfqId, brokerId }) => {
        return api.searchBrokers(brokerId).pipe(
          mergeMap(searchedBrokers => {
            if (searchedBrokers.length === 1) {
              const broker = searchedBrokers[0];
              const reformattedBroker: Broker = {
                name: broker.name,
                eliotCode: broker.eliotCode,
                mnemo: broker.mnemo,
                id: broker.id,
              };
              return [
                actionCreators.neos.rfqDataCrudActions.update(rfqId, {
                  brokerNotInPreferences: reformattedBroker,
                }),
              ];
            }
            return [
              createErrorToasterThunk(
                { message: 'No broker or multiple brokers found for this broker id' },
                undefined,
              ),
            ];
          }),
          catchError((error: OnyxError) => [
            createErrorToasterThunk(
              { message: 'Error when retrieving the broker associated to this RFQ' },
              error,
            ),
          ]),
        );
      }),
    );
}
