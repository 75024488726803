import type { Thunk } from '@/bootstrap/thunks';
import type { DeliveryType } from '../strategyModel';

export function createChangeStrategyDeliveryTypeThunk(
  strategyId: string,
  deliveryType: DeliveryType | undefined,
): Thunk {
  return function changeStrategyDeliveryTypeThunk(
    dispatch,
    getState,
    {
      selectors: { getStrategyData },
      actionCreators: {
        neos: { createDeliveryTypeChangedAction },
      },
    },
  ) {
    const appState = getState();

    const { legIds } = getStrategyData(appState, strategyId);
    dispatch(createDeliveryTypeChangedAction(legIds, deliveryType));
  };
}
