import type { Action } from 'redux';

export type SType = Record<keyof any, any>;
export type CrudAction<S extends SType = any, K = string> =
  | CrudInsertAction<S, K>
  | CrudInsertOrUpdate<S, K>
  | CrudUpdate<S, K>
  | CrudDelete<K>
  | CrudArrayAdd<S, K>
  | CrudArrayAddAtIndex<S, K>
  | CrudArrayRemove<S, K>
  | CrudArrayRemoveIndex<S, K>
  | CrudPatchOrInsert<S, K>
  | CrudArrayPatch<S, K>
  | CrudDeleteByPartialKey<K>;

export type ArrayPropertyValue<T> = {
  [K in keyof T]: T[K] extends Array<infer U>
    ? {
        property: K;
        value: U;
      }
    : never;
}[keyof T];

export type ArrayPropertyIndex<T> = {
  [K in keyof T]: T[K] extends any[]
    ? {
        property: K;
        index: number;
      }
    : never;
}[keyof T];

export type ArrayPropertyValueAndIndex<T> = {
  [K in keyof T]: T[K] extends Array<infer U>
    ? {
        property: K;
        value: U;
        index: number;
      }
    : never;
}[keyof T];

export type ObjectArrayPropertyPatch<T> = {
  [K in keyof T]: T[K] extends Array<infer U>
    ? U extends object
      ? {
          property: K;
          index: number;
          value: Partial<U>;
        }
      : never
    : never;
}[keyof T];

export const isCrudActionSymb: unique symbol = Symbol();

interface BaseCrudAction {
  type: string;
  domain: string;
  [isCrudActionSymb]: boolean;
}

export type Item<K, S extends SType> = {
  id: K;
  value: S;
};

export interface CrudInsertAction<S extends SType, K = string> extends BaseCrudAction {
  type: 'INSERT_ACTION';
  item: S;
  id: K;
}

export interface CrudInsertOrUpdate<S extends SType, K = string> extends BaseCrudAction {
  type: 'INSERT_OR_UPDATE';
  items: Item<K, S>[];
}

export interface CrudPatchOrInsert<S extends SType, K = string> extends BaseCrudAction {
  type: 'PATCH_OR_INSERT';
  value: S;
  id: K;
}

export interface CrudUpdate<S extends SType, K = string> extends BaseCrudAction {
  type: 'UPDATE';
  patch: Partial<S>;
  id: K;
}

export interface CrudDelete<K> extends BaseCrudAction {
  type: 'DELETE';
  id: K;
}

export interface CrudDeleteByPartialKey<K> extends BaseCrudAction {
  type: 'DELETE_BY_PARTIAL_KEY';
  id: Partial<K>;
}

export interface CrudArrayAdd<S extends SType, K = string> extends BaseCrudAction {
  type: 'ARRAY_ADD';
  changeParameters: ArrayPropertyValue<S>;
  id: K;
}

export interface CrudArrayRemove<S, K = string> extends BaseCrudAction {
  type: 'ARRAY_REMOVE';
  changeParameters: ArrayPropertyValue<S>;
  id: K;
}

export interface CrudArrayRemoveIndex<S, K = string> extends BaseCrudAction {
  type: 'ARRAY_REMOVE_INDEX';
  changeParameters: ArrayPropertyIndex<S>;
  id: K;
}

export interface CrudArrayAddAtIndex<S, K = string> extends BaseCrudAction {
  type: 'ARRAY_ADD_AT_INDEX';
  changeParameters: ArrayPropertyValueAndIndex<S>;
  id: K;
}

export interface CrudArrayPatch<S extends SType, K = string> extends BaseCrudAction {
  type: 'ARRAY_PATCH';
  changeParameters: ObjectArrayPropertyPatch<S>;
  id: K;
}

export function isCrudAction<S extends SType, K>(action: Action): action is CrudAction<S, K> {
  return (action as CrudAction<S, K>)[isCrudActionSymb];
}
