import type { DeltaType } from '../../../../../neos/business/neosModel';
import type { Preset } from '../presets';
import type { Shortcut, ShortcutButtonDispatchProps } from '../shared/ShortcutButton';
import { ShortcutButton } from '../shared/ShortcutButton.container';
import { ShortcutsTable } from '../shared/ShortcutsTable';

const deltaTypes: DeltaType[] = ['DELTA_ADJUSTED', 'RISK', 'DELTA_EXCHANGE', 'DELTA_EXCHANGE_OTC'];
export const DELTA_TYPE_SHORTCUTS: Preset<DeltaType> = [
  {
    id: deltaTypes[0],
    label: 'Δ Adjusted',
    ['data-e2e']: 'neos-rfq-shortcuts-deltaType-Adjusted',
  },
  {
    id: deltaTypes[1],
    label: 'Δ Risk',
    ['data-e2e']: 'neos-rfq-shortcuts-deltaType-Risk',
  },
  {
    id: deltaTypes[2],
    label: 'Δ Ex. Listed',
    ['data-e2e']: 'neos-rfq-shortcuts-deltaType-Exchange-Listed',
  },
  {
    id: deltaTypes[3],
    label: 'Δ Ex. OTC Synth.',
    ['data-e2e']: 'neos-rfq-shortcuts-deltaType-Exchange-Otc',
  },
];

export const DeltaTypeShortcuts = (props: ShortcutButtonDispatchProps<DeltaType>) => (
  <ShortcutsTable>
    {DELTA_TYPE_SHORTCUTS.map((shortcut: Shortcut<DeltaType>) => (
      <ShortcutButton
        key={shortcut.id}
        shortcut={shortcut}
        onShortcutSelected={props.onShortcutSelected}
        onShortcutLongPress={props.onShortcutLongPress}
      />
    ))}
  </ShortcutsTable>
);
