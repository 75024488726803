import type { Thunk } from '@/bootstrap/thunks';

export function createUpdateLegMaturityTenorThunk(
  rfqId: string,
  strategyId: string,
  legId: string,
  tenor: string | undefined,
): Thunk {
  return function updateLegMaturityTenorThunk(
    dispatch,
    getState,
    {
      selectors,
      thunks: {
        neos: {
          createRfqResetStrategyQuoteAndFairPricesThunk,
          createUpdateProductAndResetDependenciesThunk,
        },
      },
    },
  ) {
    const appState = getState();
    dispatch(createRfqResetStrategyQuoteAndFairPricesThunk(rfqId, strategyId));

    const { productId } = selectors.getLegData(appState, legId);
    dispatch(
      createUpdateProductAndResetDependenciesThunk(
        rfqId,
        strategyId,
        productId,
        'maturityTenor',
        tenor,
      ),
    );
  };
}
