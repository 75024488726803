import { crudDomain } from '@/util/crudUtils';
import type { FeatureType } from '../../../neosModel';
import type { Feature, FeatureKey } from './featureModel';

export const featureDomain = crudDomain<Feature, FeatureKey>('feature', {
  keyToString: ({ strategyId, type }) => `${strategyId} | ${type}`,
  stringToKey: id => {
    const [strategyId, type] = id.split(' | ');
    return { strategyId, type: type as FeatureType };
  },
});
