import type { BlotterModelGetters } from '.';
import type {
  BookingId,
  BookingStepApplication,
  OnyxRfq,
} from '../../../../../neos/business/neosOnyxModel';

export function getBlotterBookingInfoModel(
  rfq: OnyxRfq,
  { twoDimentionalArrayToString, getAllStrategiesExceptDeltaAdj }: BlotterModelGetters,
) {
  const getBookingValue = ({ bookingId, bookingApplication }: BlotterBookingInfo) =>
    bookingApplication || bookingId ? { bookingApplication, bookingId } : undefined;

  const bookingMessageFormatter = ({ bookingId, bookingApplication }: BlotterBookingInfo) =>
    `${bookingApplication || 'N/A'} (${bookingId || 'N/A'})`;

  const allStrategiesExceptDeltaAdj = getAllStrategiesExceptDeltaAdj(rfq);

  const val = allStrategiesExceptDeltaAdj.map(strat => {
    const bi = mapToBookingInfo(strat);
    const legs = strat.legs.map(leg => mapToBookingInfo(leg));

    return bi.bookingId || bi.bookingApplication
      ? [getBookingValue(bi)]
      : legs.map(legBookingInfo => getBookingValue(legBookingInfo));
  });

  return twoDimentionalArrayToString(val, bookingMessageFormatter);
}

function mapToBookingInfo<T extends { bookingId?: BookingId }>({ bookingId }: T) {
  return {
    bookingId: bookingId?.id,
    bookingApplication: bookingId?.application,
  };
}

interface BlotterBookingInfo {
  bookingId: string | undefined;
  bookingApplication: BookingStepApplication | undefined;
}
