import type { Thunk } from '@/bootstrap/thunks';

export function createRfqDeleteStrategyAndDependenciesThunk(strategyId: string): Thunk {
  return function rfqDeleteStrategyAndDependenciesThunk(
    dispatch,
    getState,
    {
      selectors,
      thunks: {
        neos: { createDeleteStrategyDependenciesThunk },
      },
      actionCreators: {
        neos: { quoteCrudActions, fairPriceCrudActions, strategyUiCrudActions },
      },
    },
  ) {
    const appState = getState();
    const quoteIds = selectors.getStrategyQuoteIds(appState, strategyId);
    const fairPriceIds = selectors.getStrategyFairPriceIds(appState, strategyId);

    dispatch(
      createDeleteStrategyDependenciesThunk(strategyId),
      strategyUiCrudActions.delete(strategyId),
      ...quoteIds.map(quoteId => quoteCrudActions.delete(quoteId)),
      ...fairPriceIds.map(fairPriceId => fairPriceCrudActions.delete(fairPriceId)),
    );
  };
}
