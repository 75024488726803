import type { Selectors } from '@/bootstrap/selectors';
import type { Services } from '@/bootstrap/services';
import type { AppState } from '@/bootstrap/state';
import type { StatusColor } from '@/neos/business/bookingSteps/bookingStepOnyxModel';
import type { ListedPreAlloc, RfqData } from '@/neos/business/rfq/rfqData/rfqDataModel';
import { isDefined } from '@/util/undefinedAndNull/isDefined';
import { sum } from 'lodash';

export interface TooltipInfo {
  type: StatusColor | undefined;
  message: string;
  ratioStyleName: 'green-ratios' | 'orange-ratios' | 'red-ratios';
}

interface ListedPreAllocModel extends ListedPreAlloc {
  isRatioInvalid: boolean;
  allocationIndex: number;
}

export interface ListedPreAllocationsModel {
  ratiosTooltipInfo: TooltipInfo | undefined;
  allocations: ListedPreAllocModel[];
  isApplicable: boolean;
}

export function getGenericListedPreAllocationsModel(
  state: AppState,
  rfqId: string,
  property: keyof Pick<RfqData, 'listedPreAllocs' | 'deltaListedPreAllocs'>,
  selectors: Selectors,
  services: Services,
): ListedPreAllocationsModel {
  const isApplicable = getIsApplicable();

  if (!isApplicable) {
    return { allocations: [], ratiosTooltipInfo: undefined, isApplicable };
  }

  const relatedPreAllocs = selectors.getRfqData(state, rfqId)[property];
  const allocations = mapToAllocations(relatedPreAllocs, services);
  const ratiosTooltipInfo = getTooltipInfo(allocations);

  return { allocations, ratiosTooltipInfo, isApplicable };

  function getIsApplicable(): boolean {
    if (!selectors.isFeatureToggleEnabled(state, 'neos.allocs.enabled')) {
      return false;
    }
    const selector: keyof Pick<
      Selectors,
      'getListedExecutionRfqStrategies' | 'getListedExecutionDeltaStrategies'
    > =
      property === 'listedPreAllocs'
        ? 'getListedExecutionRfqStrategies'
        : 'getListedExecutionDeltaStrategies';

    const relatedStrategies = selectors[selector](state, rfqId, selectors);
    return !!relatedStrategies.length;
  }
}

function mapToAllocations(
  preAllocations: ListedPreAlloc[],
  services: Services,
): ListedPreAllocModel[] {
  return preAllocations.map(
    (
      { clearerAccount, broker, ratio, commission, commissionType },
      index,
    ): ListedPreAllocModel => ({
      clearerAccount,
      broker,
      commission,
      commissionType,
      ratio: services.applyMultiplierWithPrecision(ratio, 100, 2),
      isRatioInvalid: ratio !== undefined && (ratio < 0 || ratio > 1),
      allocationIndex: index,
    }),
  );
}

function getTooltipInfo(allocations: ListedPreAllocModel[]): TooltipInfo | undefined {
  const allocationValidRatios = allocations
    .filter(({ isRatioInvalid }) => !isRatioInvalid)
    .map(({ ratio }) => ratio)
    .filter(isDefined);
  if (!allocationValidRatios.length) {
    return undefined;
  }

  const ratiosSum = sum(allocationValidRatios);

  if (ratiosSum === 100) {
    return {
      ratioStyleName: 'green-ratios',
      type: undefined,
      message: 'fully-pre-allocated',
    };
  }

  return ratiosSum > 100
    ? {
        ratioStyleName: 'red-ratios',
        type: 'danger',
        message: 'over-pre-allocated',
      }
    : {
        ratioStyleName: 'orange-ratios',
        type: 'warning',
        message: 'under-pre-allocated',
      };
}
