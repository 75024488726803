import { crudDomain } from '@/util/crudUtils';
import type { ListedAllocation, ListedAllocationKey } from './listedAllocationModel';

const compositeIdSeparator = ' | ';

function keyToString({ rfqId, legId, executionId, allocationId }: ListedAllocationKey): string {
  return `${rfqId}${compositeIdSeparator}${legId}${compositeIdSeparator}${executionId}${compositeIdSeparator}${allocationId}`;
}

function stringToKey(id: string): ListedAllocationKey {
  const [rfqId, legId, executionId, allocationId] = id.split(compositeIdSeparator);
  return { rfqId, legId, executionId, allocationId };
}

export const listedAllocationDomain = crudDomain<ListedAllocation, ListedAllocationKey>(
  'listedAllocation',
  {
    keyToString,
    stringToKey,
  },
);
