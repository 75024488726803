import { ClsFlowMode } from '@/neos/components/rfq/postNego/allocs/otcAllocations/secondaryAllocations/ClsFlowMode';
import { Mtm } from '@/neos/components/rfq/postNego/allocs/otcAllocations/secondaryAllocations/Mtm';
import { UsePositionFromLastReset } from '@/neos/components/rfq/postNego/allocs/otcAllocations/secondaryAllocations/UsePositionFromLastReset';
import { AmountDue } from './AmountDue';
import { AmountDueWay } from './AmountDueWay';
import { ConfirmationToBeChecked } from './ConfirmationToBeChecked';
import { DividendCurrentFlowMode } from './DividendCurrentFlowMode';
import { EquityCurrentFlowMode } from './EquityCurrentFlowMode';
import { InitialStrike } from './InitialStrike';
import { InitialTradeDate } from './InitialTradeDate';
import { KeepDividendPaymentDate } from './KeepDividendPaymentDate';
import { NettingBookingId } from './NettingBookingId';
import { ObserveNewRateFixing } from './ObserveNewRateFixing';
import { PartialReset } from './PartialReset';
import { PrimaryBookingId } from './PrimaryBookingId';
import { RateCurrentFlowMode } from './RateCurrentFlowMode';
import { RemainingQuantity } from './RemainingQuantity';
import type { SecondaryFieldProps } from './SecondaryAllocationsFields';
import { NovationFee } from './novationsFields/NovationFee';
import { NovationFeeId } from './novationsFields/NovationFeeId';
import { NovationFeeWay } from './novationsFields/NovationFeeWay';
import { NovationId } from './novationsFields/NovationId';
import { RemainingParty } from './novationsFields/RemainingParty';
import { Transferee } from './novationsFields/Transferee';
import { Transferor } from './novationsFields/Transferor';
import type { SecondaryField } from './secondaryFieldsByType';

export function SecondaryFieldComponent({
  field,
  forwardProps,
}: {
  field: SecondaryField;
  forwardProps: SecondaryFieldProps;
}) {
  switch (field) {
    case 'primaryBookingId':
      return <PrimaryBookingId {...forwardProps} />;
    case 'remainingQuantity':
      return <RemainingQuantity {...forwardProps} />;
    case 'amountDue':
      return <AmountDue {...forwardProps} />;
    case 'amountDueWay':
      return <AmountDueWay {...forwardProps} />;
    case 'nettingBookingId':
      return <NettingBookingId {...forwardProps} />;
    case 'novationFee':
      return <NovationFee {...forwardProps} />;
    case 'novationFeeWay':
      return <NovationFeeWay {...forwardProps} />;
    case 'transferee':
      return <Transferee {...forwardProps} />;
    case 'transferor':
      return <Transferor {...forwardProps} />;
    case 'novationBookingId':
      return <NovationId {...forwardProps} />;
    case 'novationFeeId':
      return <NovationFeeId {...forwardProps} />;
    case 'remainingParty':
      return <RemainingParty {...forwardProps} />;
    case 'confirmationToBeChecked':
      return <ConfirmationToBeChecked {...forwardProps} />;
    case 'partialReset':
      return <PartialReset {...forwardProps} />;
    case 'observeNewRateFixing':
      return <ObserveNewRateFixing {...forwardProps} />;
    case 'dividendCurrentFlowMode':
      return <DividendCurrentFlowMode {...forwardProps} />;
    case 'equityCurrentFlowMode':
      return <EquityCurrentFlowMode {...forwardProps} />;
    case 'rateCurrentFlowMode':
      return <RateCurrentFlowMode {...forwardProps} />;
    case 'keepDividendPaymentDate':
      return <KeepDividendPaymentDate {...forwardProps} />;
    case 'initialStrike':
      return <InitialStrike {...forwardProps} />;
    case 'initialTradeDate':
      return <InitialTradeDate {...forwardProps} />;
    case 'mtm':
      return <Mtm {...forwardProps} />;
    case 'usePositionFromLastReset':
      return <UsePositionFromLastReset {...forwardProps} />;
    case 'flowMode':
      return <ClsFlowMode {...forwardProps} />;
    default:
      return <></>;
  }
}
