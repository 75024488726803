import type { AgGridEvent, ApplyColumnStateParams, ColumnState } from '@ag-grid-community/core';

export type SortModel = Array<{
  colId: string | undefined;
  sort: string | null | undefined;
}>;

export interface GridApiExtended {
  getColumnState: () => ColumnState[];
  setColumnState: (columnState: ApplyColumnStateParams) => boolean;
}

export function createGridApiExtended({ columnApi }: AgGridEvent): GridApiExtended {
  return {
    getColumnState: columnApi.getColumnState.bind(columnApi),
    setColumnState: columnApi.applyColumnState.bind(columnApi),
  };
}
