import {
  type DefaultProductValuesForStrategy,
  type Product,
  isOptionLike,
  isProductWithUpperLowerStrike,
} from '../../../../../../../../../neos/business/neosModel';
import type { EuropeanOption } from '../../../../product/productModel';
import { getCommonPart, getDerivativePart, getNegotiationPart } from './getDefaultedPartialProduct';

export function getDefaultedEuropeanOptionProduct(
  currentProduct: Product,
  defaultValues: DefaultProductValuesForStrategy,
): EuropeanOption {
  const {
    underlying,
    negotiation,
    optionStyle,
    optionType,
    upperStrike: strategyUpperStrike,
    lowerStrike: strategyLowerStrike,
    maturity,
  } = defaultValues;

  const { type, style } = isOptionLike(currentProduct)
    ? currentProduct
    : { type: undefined, style: undefined };
  const { upperStrike, lowerStrike, strikeUnit } = isProductWithUpperLowerStrike(currentProduct)
    ? currentProduct
    : { lowerStrike: undefined, upperStrike: undefined, strikeUnit: undefined };
  return {
    subFamily: 'EUROPEAN_SPREAD_OPTION',
    type: optionType || type,
    style: optionStyle || style,
    upperStrike: strategyUpperStrike || upperStrike,
    lowerStrike: strategyLowerStrike || lowerStrike,
    strikeUnit,
    ...getCommonPart(currentProduct, defaultValues),
    ...getNegotiationPart(currentProduct, negotiation),
    ...getDerivativePart(currentProduct, maturity, underlying),
    hasCustomUnderlying: false,
  };
}
