import type { Selectors } from '@/bootstrap/selectors';
import type { OnyxRfq } from '@/neos/business/rfq/rfqOnyxModel';
import { getDisplayStatus } from '../../../share/status';

export function getBlotterDisplayStatus(
  { lifecycle: { status, subStatus } }: OnyxRfq,
  selectors: Selectors,
): string {
  return getDisplayStatus(status, subStatus, selectors);
}
