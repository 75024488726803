import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';

export function getIsAllButtonsDisabled(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): boolean {
  const { savedStatus } = selectors.getRfqData(state, rfqId);
  const { uuid: masterStrategyId } = selectors.getRfqMasterStrategy(state, rfqId);
  const isUnderlyingsSet = selectors.isStrategyUnderlyingsSet(state, masterStrategyId, selectors);
  const isReadonlyRfq = selectors.isReadOnlyRfq(state, rfqId);

  const IsAllButtonsDisabled = isReadonlyRfq || !isUnderlyingsSet || !savedStatus;

  return IsAllButtonsDisabled;
}
