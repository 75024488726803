import type { Action } from '@/bootstrap/actions.ts';
import type { Thunk } from '@/bootstrap/thunks.ts';
import type {
  EquityLegPeriodDates,
  LegPeriod,
} from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel.ts';
import { assertProductIsEls } from '../../../../../../../neosModel.ts';

export function createRemoveEquitySchedulePeriodThunk(strategyId: string, uuid: string): Thunk {
  return function removeEquitySchedulePeriodThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { productCrudActions },
      },
    },
  ) {
    const appState = getState();

    const product = selectors.getStrategyProduct(appState, strategyId, selectors);

    assertProductIsEls(product);

    const periods: LegPeriod<EquityLegPeriodDates>[] = product?.equityPeriods ?? [];

    const action: Action = productCrudActions.update(product.uuid, {
      equityPeriods: periods.filter(line => line.uuid !== uuid),
    });
    dispatch(action);
  };
}
