import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { isClsProduct, isElsProduct } from '@/neos/business/neosModel';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import { useDispatch } from 'react-redux';

export function ScheduleCell({ strategyId, rfqId }: { strategyId: string; rfqId: string }) {
  const product = useAppSelector(state =>
    selectors.getStrategyMasterProduct(state, strategyId, selectors),
  );
  const dispatch = useDispatch<ExtendedDispatch>();
  const isScheduleUiToggleEnabled = useAppSelector(state =>
    selectors.isScheduleUiToggleEnabled(state, strategyId, selectors),
  );

  const isCls = isClsProduct(product);
  const isEls = isElsProduct(product);
  const isDisplayed = isCls || isEls;

  const scheduleWarning = useAppSelector(state =>
    selectors.getElsScheduleWarning(state, rfqId, selectors),
  );

  if (!isDisplayed) {
    return null;
  }

  return (
    <SimpleNeosTooltip message="Schedule" type={scheduleWarning ? 'warning' : 'primary'}>
      <button
        onClick={() => {
          dispatch(
            neosActionCreators.strategyUiCrudActions.update(strategyId, {
              isScheduleUiToggleEnabled: !isScheduleUiToggleEnabled,
            }),
          );
        }}
        className={`btn btn-outline-primary btn-icon px-2 ${
          isScheduleUiToggleEnabled ? 'active' : ''
        } ${scheduleWarning !== undefined ? 'border border-warning' : ''}`}
        data-e2e="open-els-cls-schedule-section"
      >
        <i className="icon" aria-hidden="true">
          {isScheduleUiToggleEnabled ? 'edit_calendar' : 'calendar_month'}
        </i>
      </button>
    </SimpleNeosTooltip>
  );
}
