import type {
  DeterminationMethod,
  ExecFeesValue,
  FeatureType,
  ForexType,
  ObservationConvention,
} from '../../../../../neos/business/neosModel';
import type { PriceUnitType } from '../../../../../neos/business/referenceData/referenceDataModel';
import type { EquityFinanceType } from '../leg/product/elsProductOnyxModel';
import type { ForexConstat } from '../strategyModel';
import type {
  DividendFeature,
  ExecFees,
  NewRateTenors,
  PartialResetType,
  ValuationFrequency,
} from '../strategyOnyxModel';

interface GenericFeatureKey<T extends FeatureType> {
  strategyId: string;
  type: T;
}

export type ForwardStartDateFeatureKey = GenericFeatureKey<'FORWARD_START'>;

export type PartialResetFeatureKey = GenericFeatureKey<'PARTIAL_RESET'>;

export type BarriersFeatureKey = GenericFeatureKey<'BARRIERS'>;

export type CapFloorFeatureKey = GenericFeatureKey<'CAP_FLOOR'>;

export type UpDownFeatureKey = GenericFeatureKey<'UP_DOWN'>;

export type RateBulletKey = GenericFeatureKey<'RATE_BULLET'>;

export type RateOvernightKey = GenericFeatureKey<'RATE_OVERNIGHT'>;

export type EquityBulletKey = GenericFeatureKey<'EQUITY_BULLET'>;

export type ExecFeesKey = GenericFeatureKey<'EXEC_FEES'>;

export type ResetFrequencyFeatureKey = GenericFeatureKey<'RESET_FREQUENCY_FEATURE'>;

export type InterestRateFeatureKey = GenericFeatureKey<'INTEREST_RATE_INDEX_FEATURE'>;

export type ForexTypeFeatureKey = GenericFeatureKey<'FOREX_TYPE'>;

export type DescriptionFeatureKey = GenericFeatureKey<'DESCRIPTION'>;

export type EndOfObservationFeatureKey = GenericFeatureKey<'END_OF_OBSERVATION'>;

export type DividendComponentFeatureKey = GenericFeatureKey<'DIVIDEND_COMPONENT'>;

export type ValuationFrequencyFeatureKey = GenericFeatureKey<'VALUATION_FREQUENCY'>;

export type SwapCurrencyFeatureKey = GenericFeatureKey<'SWAP_CURRENCY'>;

export type EquityFinanceFeatureKey = GenericFeatureKey<'EQUITY_FINANCE'>;

export type FeatureKey =
  | ForwardStartDateFeatureKey
  | BarriersFeatureKey
  | CapFloorFeatureKey
  | UpDownFeatureKey
  | RateBulletKey
  | RateOvernightKey
  | EquityBulletKey
  | ExecFeesKey
  | ResetFrequencyFeatureKey
  | InterestRateFeatureKey
  | ForexTypeFeatureKey
  | DescriptionFeatureKey
  | ValuationFrequencyFeatureKey
  | DividendComponentFeatureKey
  | PartialResetFeatureKey
  | SwapCurrencyFeatureKey
  | EndOfObservationFeatureKey
  | EquityFinanceFeatureKey;

export type NeosBarrierType = 'KI Down' | 'KI Up' | 'KO Down' | 'KO Up';

export const availableBarrierTypes: NeosBarrierType[] = ['KI Down', 'KI Up', 'KO Down', 'KO Up'];

export type LimitObservationType = 'EUROPEAN' | 'CLOSE' | 'EXTREMUM';

export interface CommonBarrier {
  neosBarrierType: NeosBarrierType | undefined;
  underlyingType: 'CUSTOM' | 'NON_CUSTOM';
  maturity?: string;
  maturityTenor?: string;
  limitStrikeValue?: number;
  limitStrikeUnit?: string;
  limitStrikeUnitType: PriceUnitType | undefined;
  limitObservationType: LimitObservationType | undefined;
  allMustHit: boolean;
  strictLimit: boolean;
  gap?: number;
  rebate?: number;
  limitWindowDate?: { startDate: string; endDate: string };
}

export interface CustomBarrier extends CommonBarrier {
  underlyingType: 'CUSTOM';
  underlyingName: string | undefined;
}

export interface NonCustomBarrier extends CommonBarrier {
  underlyingType: 'NON_CUSTOM';
  underlyingId: string | undefined;
}

export type Barrier = CustomBarrier | NonCustomBarrier;

interface ValueUnit {
  value: number | undefined;
  unit: string | undefined;
}

export type Floor = ValueUnit;

export type Cap = ValueUnit;

export interface UpStrike extends ValueUnit {
  upObservationConvention: ObservationConvention;
}

export interface DownStrike extends ValueUnit {
  downObservationConvention: ObservationConvention;
}

type UnitType = string | '%';

export interface ForwardStartFeature extends ForwardStartDateFeatureKey {
  forwardStartDate?: string;
  forwardStartTenor?: string;
  determinationMethod?: DeterminationMethod;
}

export interface BarriersFeature extends BarriersFeatureKey {
  barriers: Barrier[];
}

export interface CapFloorFeature extends CapFloorFeatureKey {
  capValue?: number;
  capUnit?: UnitType;
  floorValue?: number;
  floorUnit?: UnitType;
}

export interface PartialResetFeature extends PartialResetFeatureKey {
  partialReset?: PartialResetType;
}

export interface UpDownFeature extends UpDownFeatureKey {
  upStrikeValue?: number;
  upStrikeUnit?: UnitType;
  upObservationConvention?: ObservationConvention;
  downStrikeValue?: number;
  downStrikeUnit?: UnitType;
  downObservationConvention?: ObservationConvention;
}

export interface EquityBulletFeature extends EquityBulletKey {
  equityBullet: boolean;
}

export interface RateBulletFeature extends RateBulletKey {
  rateBullet: boolean;
}

export interface RateOvernightFeature extends RateOvernightKey {
  rateOvernight: boolean;
}

export interface ExecFeesFeature extends ExecFeesKey {
  execFees: ExecFeesValue;
  execFeesIn: ExecFees | undefined;
  execFeesOut: ExecFees | undefined;
}

export const resetFrequencies = [
  { label: 'Bullet', value: 0 },
  { label: '1M', value: 1 },
  { label: '2M', value: 2 },
  { label: '3M', value: 3 },
  { label: '6M', value: 6 },
  { label: '1Y', value: 12 },
] as const;

export type ResetFrequency = (typeof resetFrequencies)[number] | undefined;

export interface ResetFrequencyFeature extends ResetFrequencyFeatureKey {
  resetFrequency: ResetFrequency | undefined;
}

export interface InterestRateFeature extends InterestRateFeatureKey {
  rateCurve: string | undefined;
  rateTenor: string | undefined;
  newRateTenor: NewRateTenors | undefined;
}

export interface ForexTypeFeature extends ForexTypeFeatureKey {
  forexType: ForexType | undefined;
  initialForexConstat?: ForexConstat | undefined;
  finalForexConstat?: ForexConstat | undefined;
}

export interface SwapCurrencyFeature extends SwapCurrencyFeatureKey {
  swapCurrency: string | undefined;
}

export interface DescriptionFeature extends DescriptionFeatureKey {
  description: string | undefined;
}

export interface EndOfObservationFeature extends EndOfObservationFeatureKey {
  endOfObservation: string | undefined;
}

export interface ValuationFrequencyFeature extends ValuationFrequencyFeatureKey {
  valuationFrequency: ValuationFrequency | undefined;
}

export interface EquityFinanceFeature extends EquityFinanceFeatureKey {
  equityFinance: EquityFinanceType | undefined;
}

export type Feature =
  | ForwardStartFeature
  | BarriersFeature
  | UpDownFeature
  | CapFloorFeature
  | EquityBulletFeature
  | RateBulletFeature
  | RateOvernightFeature
  | ExecFeesFeature
  | ResetFrequencyFeature
  | InterestRateFeature
  | ForexTypeFeature
  | DividendFeature
  | DescriptionFeature
  | ValuationFrequencyFeature
  | PartialResetFeature
  | SwapCurrencyFeature
  | EquityFinanceFeature
  | EndOfObservationFeature;

export function createDefaultFeature(
  { type, strategyId }: FeatureKey,
  currency: string | undefined,
): Feature {
  switch (type) {
    case 'FORWARD_START':
      return createDefaultForwardStartFeature(strategyId);
    case 'BARRIERS':
      return createDefaultBarriersFeature(strategyId);
    case 'CAP_FLOOR':
      return createDefaultCapFloorFeature(strategyId);
    case 'UP_DOWN':
      return createDefaultUpDownFeature(strategyId, currency);
    case 'EQUITY_BULLET':
      return createDefaultEquityBulletFeature(strategyId);
    case 'RATE_BULLET':
      return createDefaultRateBulletFeature(strategyId);
    case 'RATE_OVERNIGHT':
      return createDefaultRateOvernightFeature(strategyId);
    case 'EXEC_FEES':
      return createDefaultExecFeesFeature(strategyId);
    case 'RESET_FREQUENCY_FEATURE':
      return createDefaultResetFrequencyFeature(strategyId);
    case 'INTEREST_RATE_INDEX_FEATURE':
      return createDefaultInterestRateFeature(strategyId);
    case 'FOREX_TYPE':
      return createDefaultForexTypeFeature(strategyId);
    case 'DESCRIPTION':
      return { strategyId, type: 'DESCRIPTION', description: undefined };
    case 'END_OF_OBSERVATION':
      return createDefaultEndOfObservationFeature(strategyId);
    case 'DIVIDEND_COMPONENT':
      return createDefaultDividendFeature(strategyId);
    case 'VALUATION_FREQUENCY':
      return createDefaultValuationFrequencyFeature(strategyId);
    case 'PARTIAL_RESET':
      return createDefaultPartialResetFeature(strategyId);
    case 'SWAP_CURRENCY':
      return createDefaultSwapCurrencyFeature(strategyId);
    case 'EQUITY_FINANCE':
      return createDefaultEquityFinanceFeature(strategyId);
  }
}

export function createDefaultSwapCurrencyFeature(strategyId: string): SwapCurrencyFeature {
  return {
    type: 'SWAP_CURRENCY',
    strategyId,
    swapCurrency: undefined,
  };
}

export function createDefaultForwardStartFeature(strategyId: string): ForwardStartFeature {
  return {
    type: 'FORWARD_START',
    strategyId,
  };
}

export function createDefaultPartialResetFeature(strategyId: string): PartialResetFeature {
  return {
    type: 'PARTIAL_RESET',
    strategyId,
    partialReset: undefined,
  };
}

export function createDefaultValuationFrequencyFeature(
  strategyId: string,
): ValuationFrequencyFeature {
  return {
    type: 'VALUATION_FREQUENCY',
    strategyId,
    valuationFrequency: undefined,
  };
}

export function createDefaultDividendFeature(strategyId: string): DividendFeature {
  return {
    type: 'DIVIDEND_COMPONENT',
    strategyId,
  };
}

export function createDefaultBarriersFeature(strategyId: string): BarriersFeature {
  return {
    strategyId,
    type: 'BARRIERS',
    barriers: [],
  };
}

export function createDefaultCapFloorFeature(strategyId: string): CapFloorFeature {
  return {
    strategyId,
    type: 'CAP_FLOOR',
    capUnit: 'x',
    floorUnit: 'x',
  };
}

export function createDefaultUpDownFeature(
  strategyId: string,
  currency: string | undefined,
): UpDownFeature {
  return {
    strategyId,
    type: 'UP_DOWN',
    upStrikeUnit: currency,
    downStrikeUnit: currency,
  };
}

export function createDefaultEquityBulletFeature(strategyId: string): EquityBulletFeature {
  return {
    strategyId,
    type: 'EQUITY_BULLET',
    equityBullet: false,
  };
}

export function createDefaultRateBulletFeature(strategyId: string): RateBulletFeature {
  return {
    strategyId,
    type: 'RATE_BULLET',
    rateBullet: false,
  };
}

export function createDefaultRateOvernightFeature(strategyId: string): RateOvernightFeature {
  return {
    strategyId,
    type: 'RATE_OVERNIGHT',
    rateOvernight: false,
  };
}

export function createDefaultExecFeesFeature(strategyId: string): ExecFeesFeature {
  return {
    strategyId,
    type: 'EXEC_FEES',
    execFees: 'NONE',
    execFeesIn: undefined,
    execFeesOut: undefined,
  };
}

export function createDefaultForexTypeFeature(strategyId: string): ForexTypeFeature {
  return {
    strategyId,
    type: 'FOREX_TYPE',
    forexType: undefined,
    initialForexConstat: undefined,
    finalForexConstat: undefined,
  };
}

export function createDefaultResetFrequencyFeature(strategyId: string): ResetFrequencyFeature {
  return {
    strategyId,
    type: 'RESET_FREQUENCY_FEATURE',
    resetFrequency: undefined,
  };
}

export function createDefaultInterestRateFeature(strategyId: string): InterestRateFeature {
  return {
    strategyId,
    type: 'INTEREST_RATE_INDEX_FEATURE',
    rateCurve: undefined,
    rateTenor: undefined,
    newRateTenor: undefined,
  };
}

export function createDefaultEndOfObservationFeature(strategyId: string): EndOfObservationFeature {
  return {
    strategyId,
    type: 'END_OF_OBSERVATION',
    endOfObservation: undefined,
  };
}

export function createDefaultEquityFinanceFeature(strategyId: string): EquityFinanceFeature {
  return {
    strategyId,
    type: 'EQUITY_FINANCE',
    equityFinance: undefined,
  };
}

export function getFeatureByFeatureType<T extends Feature>(featureType: T['type']) {
  return (feature: Feature): feature is T => feature.type === featureType;
}
