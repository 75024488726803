import type { Thunk } from '@/bootstrap/thunks';

export function createRfqRemoveObsoleteHedgesThunk(rfqId: string): Thunk {
  return function rfqRemoveObsoleteHedgesThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { hedgeCrudActions },
      },
    },
  ) {
    const appState = getState();
    const rfqCurrentUnderlyingIds = selectors.getRfqAndDeltaStrategiesUnderlyingIds(
      appState,
      rfqId,
      selectors,
    );

    const rfqObsoleteHedgesKeys = selectors
      .getRfqHedgesKeys(appState, rfqId)
      .filter(
        ({ underlyingId }) =>
          !rfqCurrentUnderlyingIds.find(existingUnderlying => underlyingId === existingUnderlying),
      );

    const actions = rfqObsoleteHedgesKeys.map(key => hedgeCrudActions.delete(key));
    dispatch(actions);
  };
}
