import { crudDomain } from '@/util/crudUtils';
import type { Execution, ExecutionKey } from './executionModel';

const compositeIdSeparator = ' | ';

function getExecutionId({ rfqId, legId, orderId, executionId }: ExecutionKey): string {
  return `${rfqId}${compositeIdSeparator}${legId}${compositeIdSeparator}${orderId}${compositeIdSeparator}${executionId}`;
}

function getExecutionKey(id: string): ExecutionKey {
  const [rfqId, legId, orderId, executionId] = id.split(compositeIdSeparator);
  return { rfqId, legId, orderId, executionId };
}

export const executionDomain = crudDomain<Execution, ExecutionKey>('execution', {
  keyToString: getExecutionId,
  stringToKey: getExecutionKey,
});
