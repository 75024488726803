import type { Broker } from '@/neos/business/referenceData/brokers/brokersModel';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { Observable } from 'rxjs';

export type OnyxBrokerId = number;

export interface BrokersPreferencesApis {
  getCurrentUserBrokers: (username: string) => Observable<Broker[]>;
  searchBrokers: (entry: string) => Observable<Broker[]>;
  addBroker: (id: OnyxBrokerId) => Observable<void>;
  deleteBroker: (id: OnyxBrokerId) => Observable<void>;
}

export const brokersPreferencesApis = (http: SgmeHttp): BrokersPreferencesApis => {
  const BaseUrl: string = 'api/referential/broker';
  return {
    getCurrentUserBrokers(userSesameId: string) {
      return http.get<Broker[]>({
        url: BaseUrl + `/favorite?username=${userSesameId}`,
      });
    },
    searchBrokers(entry: string) {
      return http.get<Broker[]>({
        url: BaseUrl + `/search?query=${entry}`,
      });
    },
    addBroker(id) {
      return http.put<void>({
        url: BaseUrl + `/favorite`,
        body: [id],
      });
    },
    deleteBroker(id) {
      return http.delete<void>({
        url: BaseUrl + '/favorite',
        body: [id],
      });
    },
  };
};
