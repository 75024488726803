import type { PriceUnitType } from '../../../../neos/business/neosModel';

export enum RfqRecomputeScope {
  CHANGE_NOTIONAL_RFQ = 'CHANGE_NOTIONAL_RFQ',
  CHANGE_PTMMM = 'CHANGE_PTMMM_RFQ',
  CHANGE_PIVOT_NOTIONAL_RFQ = 'CHANGE_PIVOT_NOTIONAL_RFQ',
  CHANGE_SPOT = 'CHANGE_SPOT',
  CHANGE_EXECUTED_SPOT = 'CHANGE_EXECUTED_SPOT',
  CHANGE_FOREX = 'CHANGE_FOREX',
  CHANGE_PRICE = 'CHANGE_PRICE',
  CHANGE_PRICE_RFQ = 'CHANGE_PRICE_RFQ',
  CHANGE_SALES_PRICE_RFQ = 'CHANGE_SALES_PRICE_RFQ',
  CHANGE_FUTURE_REF = 'CHANGE_FUTURE_REF',
  CHANGE_BASIS = 'CHANGE_BASIS',
  CHANGE_REF_ADJUSTED = 'CHANGE_REF_ADJUSTED',
  CHANGE_WAY = 'CHANGE_WAY',
  CHANGE_RFQ_MARKUP = 'CHANGE_RFQ_MARKUP',
  CHANGE_DELTA_RFQ = 'CHANGE_DELTA_RFQ',
  CHANGE_RFQ_MARKUP_VALUE = 'CHANGE_RFQ_MARKUP_VALUE',
  CHANGE_QUOTE_TO_PERCENT_UNIT = 'CHANGE_QUOTE_TO_PERCENT_UNIT',
  CHANGE_QUOTE_TO_CURRENCY_UNIT = 'CHANGE_QUOTE_TO_CURRENCY_UNIT',
  CHANGE_QUOTE_TO_BASIS_UNIT = 'CHANGE_QUOTE_TO_BASIS_UNIT',
}

export enum StrategyRecomputeScope {
  CHANGE_PTMMM = 'CHANGE_PTMMM_STRATEGY_LEG',
  CHANGE_WEIGHT_STRAT_MASTER = 'CHANGE_WEIGHT_STRAT_MASTER',
  CHANGE_WEIGHT_AND_SIGN_STRAT_MASTER = 'CHANGE_WEIGHT_AND_SIGN_STRAT_MASTER',
  CHANGE_WEIGHT_STRAT_NOTMASTER = 'CHANGE_WEIGHT_STRAT_NOTMASTER',
  CHANGE_WEIGHT_AND_SIGN_STRAT_NOTMASTER = 'CHANGE_WEIGHT_AND_SIGN_STRAT_NOTMASTER',
  CHANGE_STRAT_MASTER = 'CHANGE_STRAT_MASTER ',
  CHANGE_COMPO_NEGOCIATED_SIZE_STRAT_MASTER = 'CHANGE_COMPO_NEGOCIATED_SIZE_STRAT_MASTER',
  CHANGE_COMPO_NEGOCIATED_SIZE_STRAT_NONMASTER = 'CHANGE_COMPO_NEGOCIATED_SIZE_STRAT_NONMASTER',
  CHANGE_BARRIERS_TO_CURRENCY_UNIT = 'CHANGE_BARRIERS_TO_CURRENCY_UNIT',
  CHANGE_BARRIERS_TO_PERCENT_UNIT = 'CHANGE_BARRIERS_TO_PERCENT_UNIT',
  CHANGE_UP_DOWN_TO_PERCENT_UNIT = 'CHANGE_UP_DOWN_TO_PERCENT_UNIT',
  CHANGE_UP_DOWN_TO_CURRENCY_UNIT = 'CHANGE_UP_DOWN_TO_CURRENCY_UNIT',
}

export enum LegRecomputeScope {
  CHANGE_QUANTITY_STRAT_MASTER_LEG_MASTER = 'CHANGE_QUANTITY_STRAT_MASTER_LEG_MASTER',
  CHANGE_QUANTITY_STRAT_NOTMASTER_LEG_MASTER = 'CHANGE_QUANTITY_STRAT_NOTMASTER_LEG_MASTER',
  CHANGE_QUANTITY_STRAT_MASTER_LEG_NOTMASTER = 'CHANGE_QUANTITY_STRAT_MASTER_LEG_NOTMASTER',
  CHANGE_QUANTITY_STRAT_NOTMASTER_LEG_NOTMASTER = 'CHANGE_QUANTITY_STRAT_NOTMASTER_LEG_NOTMASTER',

  CHANGE_WEIGHT_STRAT_MASTER_LEG_MASTER = 'CHANGE_WEIGHT_STRAT_MASTER_LEG_MASTER',
  CHANGE_WEIGHT_AND_SIGN_STRAT_MASTER_LEG_MASTER = 'CHANGE_WEIGHT_AND_SIGN_STRAT_MASTER_LEG_MASTER',
  CHANGE_WEIGHT_STRAT_MASTER_LEG_NOTMASTER = 'CHANGE_WEIGHT_STRAT_MASTER_LEG_NOTMASTER',
  CHANGE_WEIGHT_AND_SIGN_STRAT_MASTER_LEG_NOTMASTER = 'CHANGE_WEIGHT_AND_SIGN_STRAT_MASTER_LEG_NOTMASTER',
  CHANGE_WEIGHT_STRAT_NOTMASTER_LEG_MASTER = 'CHANGE_WEIGHT_STRAT_NOTMASTER_LEG_MASTER',
  CHANGE_WEIGHT_AND_SIGN_STRAT_NOTMASTER_LEG_MASTER = 'CHANGE_WEIGHT_AND_SIGN_STRAT_NOTMASTER_LEG_MASTER',
  CHANGE_WEIGHT_STRAT_NOTMASTER_LEG_NOTMASTER = 'CHANGE_WEIGHT_STRAT_NOTMASTER_LEG_NOTMASTER',
  CHANGE_WEIGHT_AND_SIGN_STRAT_NOTMASTER_LEG_NOTMASTER = 'CHANGE_WEIGHT_AND_SIGN_STRAT_NOTMASTER_LEG_NOTMASTER',

  CHANGE_PRICE_STRAT_MASTER_LEG_MASTER = 'CHANGE_PRICE_STRAT_MASTER_LEG_MASTER',
  CHANGE_SALES_PRICE_STRAT_MASTER_LEG_MASTER = 'CHANGE_SALES_PRICE_STRAT_MASTER_LEG_MASTER',

  CHANGE_PRICE_STRAT_MASTER_LEG_NOTMASTER = 'CHANGE_PRICE_STRAT_MASTER_LEG_NOTMASTER',
  CHANGE_SALES_PRICE_STRAT_MASTER_LEG_NOTMASTER = 'CHANGE_SALES_PRICE_STRAT_MASTER_LEG_NOTMASTER',

  CHANGE_PRICE_STRAT_NOTMASTER_LEG_MASTER = 'CHANGE_PRICE_STRAT_NOTMASTER_LEG_MASTER',
  CHANGE_SALES_PRICE_STRAT_NOTMASTER_LEG_MASTER = 'CHANGE_SALES_PRICE_STRAT_NOTMASTER_LEG_MASTER',

  CHANGE_PRICE_STRAT_NOTMASTER_LEG_NOTMASTER = 'CHANGE_PRICE_STRAT_NOTMASTER_LEG_NOTMASTER',
  CHANGE_SALES_PRICE_STRAT_NOTMASTER_LEG_NOTMASTER = 'CHANGE_SALES_PRICE_STRAT_NOTMASTER_LEG_NOTMASTER',

  CHANGE_LEG_MARKUP = 'CHANGE_LEG_MARKUP',
  CHANGE_DELTA = 'CHANGE_DELTA',

  CHANGE_EXEC_FEE = 'CHANGE_EXEC_FEE',

  CHANGE_EQUITY_HEDGE = 'CHANGE_EQUITY_HEDGE',
}

export enum StrategyDeltaRecomputeScope {
  CHANGE_LOTSIZE_DELTASTRAT = 'CHANGE_LOTSIZE_DELTASTRAT',
  CHANGE_SIZE_DELTA_STRAT_LEG_MASTER = 'CHANGE_SIZE_DELTA_STRAT_LEG_MASTER',
}

export enum ElsScheduleRecomputeScope {
  CHANGE_START_DATE_SCHEDULE_PERIOD = 'CHANGE_START_DATE_SCHEDULE_PERIOD',
  CHANGE_END_DATE_SCHEDULE_PERIOD = 'CHANGE_END_DATE_SCHEDULE_PERIOD',
}

export type RecomputeScope =
  | RfqRecomputeScope
  | StrategyRecomputeScope
  | LegRecomputeScope
  | StrategyDeltaRecomputeScope
  | ElsScheduleRecomputeScope;

type RfqRecomputeScopeParameters = {
  scope: RfqUnitRecomputeType.UNIT;
  to: PriceUnitType;
};

export enum RfqUnitRecomputeType {
  UNIT = 'UNIT',
}

type RfqUnitRecomputeScopeDico = Record<
  RfqUnitRecomputeType,
  Record<PriceUnitType, RfqRecomputeScope | undefined>
>;

const rfqUnitRecomputeScopeDico: RfqUnitRecomputeScopeDico = {
  [RfqUnitRecomputeType.UNIT]: {
    CCY: RfqRecomputeScope.CHANGE_QUOTE_TO_CURRENCY_UNIT,
    REF_PERCENT: RfqRecomputeScope.CHANGE_QUOTE_TO_PERCENT_UNIT,
    VOL_POINT: undefined,
    VAR_UNIT: undefined,
    MULTIPLIER: undefined,
    BASIS: RfqRecomputeScope.CHANGE_QUOTE_TO_BASIS_UNIT,
    CENTS: undefined,
    BIPS: undefined,
  },
};

export function getRfqRecomputeScope(
  parameters: RfqRecomputeScopeParameters,
): RfqRecomputeScope | undefined {
  return rfqUnitRecomputeScopeDico[parameters.scope][parameters.to];
}

export function getStrategyRecomputeScope(
  parameters: StrategyRecomputeScopeParameters,
): StrategyRecomputeScope {
  switch (parameters.scope) {
    case StrategyRecomputeChangedScope.MASTER:
      return StrategyRecomputeScope.CHANGE_STRAT_MASTER;
    case StrategyRecomputeChangedScope.WEIGHT:
      return parameters.isMaster
        ? parameters.hasSignChanged
          ? StrategyRecomputeScope.CHANGE_WEIGHT_AND_SIGN_STRAT_MASTER
          : StrategyRecomputeScope.CHANGE_WEIGHT_STRAT_MASTER
        : parameters.hasSignChanged
          ? StrategyRecomputeScope.CHANGE_WEIGHT_AND_SIGN_STRAT_NOTMASTER
          : StrategyRecomputeScope.CHANGE_WEIGHT_STRAT_NOTMASTER;
  }
}

type StrategyRecomputeScopeParameters =
  | {
      scope: StrategyRecomputeChangedScope.WEIGHT;
      hasSignChanged: boolean;
      isMaster: boolean;
    }
  | { scope: StrategyRecomputeChangedScope.MASTER };

export enum StrategyRecomputeChangedScope {
  WEIGHT = 'WEIGHT',
  MASTER = 'MASTER',
}

interface LegRecomputeScopeParamsBase {
  scope: LegRecomputeChangedScope;
  isMasterStrategy: boolean;
  isMasterLeg: boolean;
}

interface LegRecomputeScopeParams extends LegRecomputeScopeParamsBase {
  scope: LegRecomputeChangedScope.SALES_PRICE | LegRecomputeChangedScope.TRADER_PRICE;
}

interface LegRecomputeScopeWeightParams extends LegRecomputeScopeParamsBase {
  scope: LegRecomputeChangedScope.WEIGHT;
  hasWeightSignChanged: boolean;
}

interface LegRecomputeScopeQuantityParams extends LegRecomputeScopeParamsBase {
  scope: LegRecomputeChangedScope.QUANTITY;
  isDeltaStrategy: boolean;
}

interface LegRecomputeScopeSizeParams extends LegRecomputeScopeParamsBase {
  scope: LegRecomputeChangedScope.SIZE;
  isDeltaStrategy: boolean;
}

export type LegRecomputeParams =
  | LegRecomputeScopeParams
  | LegRecomputeScopeWeightParams
  | LegRecomputeScopeQuantityParams
  | LegRecomputeScopeSizeParams;

export function getLegRecomputeScope(
  params: LegRecomputeParams,
): LegRecomputeScope | StrategyDeltaRecomputeScope {
  if (params.scope === LegRecomputeChangedScope.QUANTITY && params.isDeltaStrategy) {
    return StrategyDeltaRecomputeScope.CHANGE_LOTSIZE_DELTASTRAT;
  }
  if (params.scope === LegRecomputeChangedScope.SIZE && params.isDeltaStrategy) {
    return StrategyDeltaRecomputeScope.CHANGE_SIZE_DELTA_STRAT_LEG_MASTER;
  }

  const stratType = params.isMasterStrategy
    ? RecomputeStratType.MASTER
    : RecomputeStratType.NOTMASTER;
  const legType = params.isMasterLeg ? RecomputeLegType.MASTER : RecomputeLegType.NOTMASTER;

  if (params.scope === LegRecomputeChangedScope.WEIGHT) {
    return legWeightRecomputeScopeDic[stratType][legType][
      params.hasWeightSignChanged ? SignChangedType.SIGNNOTCHANGED : SignChangedType.SIGNCHANGED
    ];
  }

  return legRecomputeScopeDico[params.scope][stratType][legType];
}

export enum LegRecomputeChangedScope {
  QUANTITY = 'QUANTITY',
  WEIGHT = 'WEIGHT',
  TRADER_PRICE = 'PRICE',
  SALES_PRICE = 'SALES_PRICE',
  SIZE = 'SIZE',
}

enum RecomputeStratType {
  MASTER = 'STRAT_MASTER',
  NOTMASTER = 'STRAT_NOTMASTER',
}

enum RecomputeLegType {
  MASTER = 'LEG_MASTER',
  NOTMASTER = 'LEG_NOTMASTER',
}

enum SignChangedType {
  SIGNCHANGED = 'SIGN_CHANGED',
  SIGNNOTCHANGED = 'SIGN_NOT_CHANGED',
}

type LegWeightRecomputeScopeDico = Record<
  RecomputeStratType,
  Record<RecomputeLegType, Record<SignChangedType, LegRecomputeScope>>
>;
const legWeightRecomputeScopeDic: LegWeightRecomputeScopeDico = {
  [RecomputeStratType.MASTER]: {
    [RecomputeLegType.MASTER]: {
      [SignChangedType.SIGNCHANGED]: LegRecomputeScope.CHANGE_WEIGHT_STRAT_MASTER_LEG_MASTER,
      [SignChangedType.SIGNNOTCHANGED]:
        LegRecomputeScope.CHANGE_WEIGHT_AND_SIGN_STRAT_MASTER_LEG_MASTER,
    },
    [RecomputeLegType.NOTMASTER]: {
      [SignChangedType.SIGNCHANGED]: LegRecomputeScope.CHANGE_WEIGHT_STRAT_MASTER_LEG_NOTMASTER,
      [SignChangedType.SIGNNOTCHANGED]:
        LegRecomputeScope.CHANGE_WEIGHT_AND_SIGN_STRAT_MASTER_LEG_NOTMASTER,
    },
  },
  [RecomputeStratType.NOTMASTER]: {
    [RecomputeLegType.MASTER]: {
      [SignChangedType.SIGNCHANGED]: LegRecomputeScope.CHANGE_WEIGHT_STRAT_NOTMASTER_LEG_MASTER,
      [SignChangedType.SIGNNOTCHANGED]:
        LegRecomputeScope.CHANGE_WEIGHT_AND_SIGN_STRAT_NOTMASTER_LEG_MASTER,
    },
    [RecomputeLegType.NOTMASTER]: {
      [SignChangedType.SIGNCHANGED]: LegRecomputeScope.CHANGE_WEIGHT_STRAT_NOTMASTER_LEG_NOTMASTER,
      [SignChangedType.SIGNNOTCHANGED]:
        LegRecomputeScope.CHANGE_WEIGHT_AND_SIGN_STRAT_NOTMASTER_LEG_NOTMASTER,
    },
  },
};

type LegRecomputeScopeDico = Record<
  Exclude<LegRecomputeChangedScope, LegRecomputeChangedScope.WEIGHT>,
  Record<RecomputeStratType, Record<RecomputeLegType, LegRecomputeScope>>
>;
const legRecomputeScopeDico: LegRecomputeScopeDico = {
  [LegRecomputeChangedScope.QUANTITY]: {
    [RecomputeStratType.MASTER]: {
      [RecomputeLegType.MASTER]: LegRecomputeScope.CHANGE_QUANTITY_STRAT_MASTER_LEG_MASTER,
      [RecomputeLegType.NOTMASTER]: LegRecomputeScope.CHANGE_QUANTITY_STRAT_MASTER_LEG_NOTMASTER,
    },
    [RecomputeStratType.NOTMASTER]: {
      [RecomputeLegType.MASTER]: LegRecomputeScope.CHANGE_QUANTITY_STRAT_NOTMASTER_LEG_MASTER,
      [RecomputeLegType.NOTMASTER]: LegRecomputeScope.CHANGE_QUANTITY_STRAT_NOTMASTER_LEG_NOTMASTER,
    },
  },
  [LegRecomputeChangedScope.SIZE]: {
    [RecomputeStratType.MASTER]: {
      [RecomputeLegType.MASTER]: LegRecomputeScope.CHANGE_QUANTITY_STRAT_MASTER_LEG_MASTER,
      [RecomputeLegType.NOTMASTER]: LegRecomputeScope.CHANGE_QUANTITY_STRAT_MASTER_LEG_NOTMASTER,
    },
    [RecomputeStratType.NOTMASTER]: {
      [RecomputeLegType.MASTER]: LegRecomputeScope.CHANGE_QUANTITY_STRAT_NOTMASTER_LEG_MASTER,
      [RecomputeLegType.NOTMASTER]: LegRecomputeScope.CHANGE_QUANTITY_STRAT_NOTMASTER_LEG_NOTMASTER,
    },
  },
  [LegRecomputeChangedScope.TRADER_PRICE]: {
    [RecomputeStratType.MASTER]: {
      [RecomputeLegType.MASTER]: LegRecomputeScope.CHANGE_PRICE_STRAT_MASTER_LEG_MASTER,
      [RecomputeLegType.NOTMASTER]: LegRecomputeScope.CHANGE_PRICE_STRAT_MASTER_LEG_NOTMASTER,
    },
    [RecomputeStratType.NOTMASTER]: {
      [RecomputeLegType.MASTER]: LegRecomputeScope.CHANGE_PRICE_STRAT_NOTMASTER_LEG_MASTER,
      [RecomputeLegType.NOTMASTER]: LegRecomputeScope.CHANGE_PRICE_STRAT_NOTMASTER_LEG_NOTMASTER,
    },
  },
  [LegRecomputeChangedScope.SALES_PRICE]: {
    [RecomputeStratType.MASTER]: {
      [RecomputeLegType.MASTER]: LegRecomputeScope.CHANGE_SALES_PRICE_STRAT_MASTER_LEG_MASTER,
      [RecomputeLegType.NOTMASTER]: LegRecomputeScope.CHANGE_SALES_PRICE_STRAT_MASTER_LEG_NOTMASTER,
    },
    [RecomputeStratType.NOTMASTER]: {
      [RecomputeLegType.MASTER]: LegRecomputeScope.CHANGE_SALES_PRICE_STRAT_NOTMASTER_LEG_MASTER,
      [RecomputeLegType.NOTMASTER]:
        LegRecomputeScope.CHANGE_SALES_PRICE_STRAT_NOTMASTER_LEG_NOTMASTER,
    },
  },
};
