import type { Thunk } from '@/bootstrap/thunks';
import type { DisplayNegotiatedSize } from '@/neos/business/ui/strategy/strategyUiModel.ts';
import type { SizeType } from '@/neos/business/referenceData/strategyDefinition/strategyDefinitionOnyxModel.ts';
import type { OtcAllocation } from '@/neos/business/rfq/strategy/leg/otcAllocation/otcAllocationModel.ts';

export type UpdatedSize = Pick<OtcAllocation, 'sizeType' | 'quantity' | 'notional' | 'varUnit'>;

export function createUpdateOtcAllocSizeThunk(
  strategyId: string,
  allocation: OtcAllocation,
  uiSizeType: DisplayNegotiatedSize,
  newSizeInput: number | undefined,
): Thunk {
  return function updateOtcAllocSizeThunk(
    dispatch,
    getState,
    {
      thunks: {
        neos: { createDefaultNegotiatedSizeRequestedThunk },
      },
      actionCreators: {
        neos: { otcAllocationCrudActions, allocationUiCrudActions },
      },
      selectors,
    },
  ) {
    const state = getState();

    const { rfqId, strategyType } = selectors.getStrategyData(state, strategyId);
    const { uuid: allocationId, legId } = allocation;
    const currency = selectors.getCurrencyByStrategyId(state, strategyId, selectors);

    const availableSizeTypes = selectors.getStrategyAvailableSizeTypes(
      state.referenceData,
      strategyType,
      selectors,
    );

    const onChangeSizeType = availableSizeTypes.find(sizeType =>
      sizeType.includes(uiSizeType.replace('LOCAL_', '')),
    );
    const updatedSize = getUpdatedSize(
      allocation,
      newSizeInput,
      currency,
      uiSizeType,
      onChangeSizeType,
    );

    dispatch(
      otcAllocationCrudActions.update({ allocationId, rfqId, legId }, updatedSize),
      createDefaultNegotiatedSizeRequestedThunk(rfqId),
      allocationUiCrudActions.patchOrInsert(allocationId, {
        errors: { Size: false },
      }),
    );
  };
}

export function getUpdatedSize(
  allocation: OtcAllocation,
  sizeValue: number | undefined,
  currency: string | undefined,
  uiSizeType: DisplayNegotiatedSize | undefined,
  onChangeSizeType: SizeType | undefined,
): UpdatedSize {
  const newSizeValue: UpdatedSize = {
    quantity: undefined,
    varUnit: allocation.varUnit ? { ...allocation.varUnit, value: undefined } : undefined,
    notional: allocation.notional ? { ...allocation.notional, value: undefined } : undefined,
    sizeType: onChangeSizeType,
  };

  switch (uiSizeType) {
    case 'QUANTITY':
      newSizeValue.quantity = sizeValue;
      break;
    case 'VAR_UNIT':
      if (newSizeValue.varUnit) {
        newSizeValue.varUnit.value = sizeValue;
      } else {
        newSizeValue.varUnit = {
          unit: 'VU',
          type: 'VAR_UNIT',
          value: sizeValue,
        };
      }
      break;
    default:
      if (newSizeValue.notional) {
        newSizeValue.notional.value = sizeValue;
      } else {
        newSizeValue.notional = {
          unit: currency,
          type: 'CCY',
          value: sizeValue,
        };
      }
      break;
  }
  return newSizeValue;
}
