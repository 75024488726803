import { type ResetFrequency, resetFrequencies } from '@/neos/business/neosModel';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';
import { ErrorHighlight } from '@/neos/components/share/errorHighlight';
import type { FC } from 'react';

type ResetFrequencySelectProps = {
  resetFrequency: ResetFrequency;
  rfqId: string;
  strategyId: string;
  onChange: (value: ResetFrequency) => void;
  readOnly?: boolean;
};

export const ResetFrequencySelect: FC<ResetFrequencySelectProps> = ({
  resetFrequency,
  onChange,
  rfqId,
  strategyId,
  readOnly,
}) => {
  return (
    <ErrorHighlight
      errorField="resetFrequency"
      related={{ rfqId, strategyId }}
      className="errorable-bloc"
    >
      <NeosSelect
        readOnly={readOnly}
        value={resetFrequency?.value.toString()}
        options={resetFrequencies.map(frequency => ({
          value: frequency.value.toString(),
          label: frequency.label,
        }))}
        style={{ minWidth: '110px' }}
        addNonSelectableEmptyOption
        onChange={newResetFrequency =>
          onChange(resetFrequencies.find(({ value }) => value.toString() === newResetFrequency))
        }
        className="errorable-bloc"
        data-e2e="neos-extra-features-reset-frqcy"
      />
    </ErrorHighlight>
  );
};
