import { NumericInput } from '@/neos/components/share/numericInput';
import { NeosTooltip } from '@/neos/components/share/tooltip/NeosTooltip';
import { Fragment } from 'react';

import { lowerThenCamelCase } from '@/util/string/stringUtils';
import type { DeltaSummaryModel } from './getDeltaSummaryModel';

import styles from './DeltaSummary.module.scss';

export interface DeltaSummaryOwnProps {
  rfqId: string;
}
export type DeltaSummaryStateProps = DeltaSummaryModel;

export interface DeltaSummaryDispatchProps {
  onCopyToClipboard: (text: string) => void;
}

export type DeltaSummaryProps = DeltaSummaryOwnProps &
  DeltaSummaryStateProps &
  DeltaSummaryDispatchProps;

export const DeltaSummaryComponent = ({
  hedgeViews,
  deltaSummaries,
  onCopyToClipboard,
}: DeltaSummaryProps) => {
  return (
    <div className={`${styles['delta-summaries']} `} data-e2e="delta-summary">
      <div className={`${styles['hedges-grid']} `}>
        <div className={`${styles['header']} ${styles['title']} `}>Δ Sum</div>
        <div className={`${styles['header']} `}>SG Way</div>
        <div className={`${styles['header']}`}>Size</div>
        <div className={`${styles['header']} `}>Lot Size / Mkt</div>
        {hedgeViews.map(
          ({ underlyingId, deltaType, bloombergCode, sgWay, lotSizeMarket, size }) => (
            <Fragment key={`${underlyingId}-${deltaType}`}>
              <div className={`${styles['underlying']}`}>
                <span data-e2e="underlying" key={underlyingId}>
                  {bloombergCode || <div className="spinner spinner-sm" />}
                </span>
              </div>
              <div>
                <input
                  data-e2e="sg-way"
                  className="form-control"
                  value={lowerThenCamelCase(sgWay ?? '')}
                  readOnly
                />
              </div>
              <div>
                <NumericInput data-e2e="size" readOnly value={size} unit="#" />
              </div>
              <div>
                <input
                  data-e2e="lot-size-market"
                  className="form-control"
                  value={lotSizeMarket ?? ''}
                  readOnly
                />
              </div>
            </Fragment>
          ),
        )}
      </div>
      <div className={`${styles['delta-summaries-copy']} `}>
        {deltaSummaries.length ? (
          <NeosTooltip
            placement="top"
            overlay={
              <NeosTooltip id={'copy-delta-summary'}>
                <>
                  {deltaSummaries.map((deltaSummary, index) => (
                    <div key={index}>• {deltaSummary}</div>
                  ))}
                </>
              </NeosTooltip>
            }
          >
            <button
              className="btn btn-link btn-sm fw-bold"
              data-e2e="copy-delta-summary"
              onClick={() => {
                onCopyToClipboard(deltaSummaries.join('\n') || '');
              }}
            >
              Copy Delta Summar
              {deltaSummaries.length === 1 ? 'y' : 'ies'}{' '}
              <i className="icon icon-xs ms-2">content_copy</i>
            </button>
          </NeosTooltip>
        ) : (
          'No delta summary available'
        )}
      </div>
    </div>
  );
};
