import { orderBy } from 'lodash';
import { getAskColor, getAskHighlight, getBidColor, getBidHighlight } from '../getBidAskDisplay';
import type { BidAskDisplay } from '../Totals';
import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';

export type PricesType = 'Trd' | 'Sls';
interface BidAskValues {
  unit: string | undefined;
  bid: number | undefined;
  ask: number | undefined;
  mid: MidValue;
}
interface MidValue {
  mid: number | undefined;
  ptmmm: number | undefined;
}

type AggBidAsk = Omit<BidAskValues, 'mid'> & BidAskDisplay;
export interface RfqPremiumModel {
  isReverseEnabled: boolean;
  total: BidAskValues;
  aggBidAsk: AggBidAsk[];
  pricesType: PricesType;
  hasMultipleCurrencies: boolean;
  isMdpRfq: boolean;
  areFairPricesDisplayed: boolean;
  arePtmmmDisplayed: boolean;
}

export function getRfqPremiumModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): RfqPremiumModel {
  const {
    quoteId,
    fairPriceId,
    aggregatedDataByPriceUnit = {},
    clientWay,
  } = selectors.getRfqData(state, rfqId);
  const isMdpRfq = selectors.getIsMdpRfq(state, rfqId);
  const units = Object.keys(aggregatedDataByPriceUnit);
  const quote = selectors.getQuote(state, quoteId);
  const fairPrice = fairPriceId ? selectors.getFairPrice(state, fairPriceId) : undefined;
  const arePricesUnitBasis =
    fairPrice?.midPriceType === 'BASIS' ||
    quote.traderAskType === 'BASIS' ||
    quote.traderBidType === 'BASIS';
  const isReverseEnabled =
    selectors.isReverseWeightsWarningVisible(state, rfqId, selectors) &&
    !hasOnlyOneInconsistentStrategyForReverse(state, rfqId, selectors) &&
    !arePricesUnitBasis;

  const areSalesPricesDisplayed = selectors.areSalesPricesDisplayed(state.ui, rfqId, selectors);

  const aggBidAsk = orderBy(
    units.map(unit => {
      const weight = aggregatedDataByPriceUnit?.[unit]?.weight ?? 1;
      return {
        unit,
        ask: areSalesPricesDisplayed
          ? aggregatedDataByPriceUnit?.[unit]?.quote?.salesPrice?.ask?.value
          : aggregatedDataByPriceUnit?.[unit]?.quote?.traderPrice?.ask?.value,
        bid: areSalesPricesDisplayed
          ? aggregatedDataByPriceUnit?.[unit]?.quote?.salesPrice?.bid?.value
          : aggregatedDataByPriceUnit?.[unit]?.quote?.traderPrice?.bid?.value,
        bidClassName: `${getBidColor(weight)} ${getBidHighlight(clientWay, weight)}`,
        askClassName: `${getAskColor(weight)} ${getAskHighlight(clientWay, weight)}`,
      };
    }),
    agg => agg.unit,
  );
  const pricesType: PricesType = areSalesPricesDisplayed ? 'Sls' : 'Trd';

  const areFairPricesDisplayed = selectors.areFairPricesDisplayed(state, rfqId, selectors);
  const arePtmmmDisplayed = selectors.areElsPtmmmDisplayed(state, rfqId, selectors);

  return {
    pricesType,
    isReverseEnabled,
    total: {
      unit: quote.unit,
      ask: areSalesPricesDisplayed ? quote.salesAsk : quote.traderAsk,
      bid: areSalesPricesDisplayed ? quote.salesBid : quote.traderBid,
      mid: { ptmmm: quote.preTradeMidMarketMarkPrice, mid: fairPrice?.mid },
    },
    aggBidAsk,
    hasMultipleCurrencies: aggBidAsk.length > 1,
    isMdpRfq,
    areFairPricesDisplayed,
    arePtmmmDisplayed,
  };
}

function hasOnlyOneInconsistentStrategyForReverse(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): boolean {
  const { getRfqData, isStrategyInconsistentForLegOverReverse } = selectors;
  const { strategyIds } = getRfqData(state, rfqId);
  if (strategyIds.length !== 1) {
    return false;
  }
  const strategyId = strategyIds[0];
  return isStrategyInconsistentForLegOverReverse(state, strategyId, selectors);
}
