import type {
  SimilarActivitiesLegLinkedAction,
  SimilarActivitiesLinkManagementResetedAction,
  SimilarActivitiesLinkManagerAction,
  SimilarActivitiesPrimaryLegAddedAction,
  SimilarActivitiesPrimaryRfqAddedAction,
  SimilarActivitiesPrimaryStrategyAddedAction,
  SimilarActivitiesStrategyLinkedAction,
} from './similarActivitiesLinkManagementActions';
import type { SimilarActivitiesLinkManagementModel } from './similarActivitiesLinkManagementModel';
import {
  type SimilarActivitiesLinkManagerState,
  defaultSimilarActivitiesLinkManagerState,
} from './similarActivitiesLinkManagerState';

export function similarActivitiesLinkManagementReducer(
  state: SimilarActivitiesLinkManagerState = defaultSimilarActivitiesLinkManagerState,
  action: SimilarActivitiesLinkManagerAction,
): SimilarActivitiesLinkManagerState {
  switch (action.type) {
    case 'PRIMARY_LEG_ADDED':
      return addPrimaryLeg(state, action);
    case 'LEG_LINKED':
      return linkSecondaryLeg(state, action);
    case 'LEG_UNLINKED':
      return unlinkSecondaryLeg(state);
    case 'PRIMARY_STRATEGY_ADDED':
      return addPrimaryStrategy(state, action);
    case 'STRATEGY_LINKED':
      return linkSecondaryStrategy(state, action);
    case 'STRATEGY_UNLINKED':
      return unlinkSecondaryStrategy(state);
    case 'PRIMARY_RFQ_ADDED':
      return addPrimaryRfq(state, action);
    case 'SIMILAR_ACTIVITIES_LINK_MANAGEMENT_RESETED':
      return resetSimilarActivitiesLinkManagement(action);
    default:
      return state;
  }
}

function addPrimaryLeg(
  state: Readonly<SimilarActivitiesLinkManagementModel>,
  {
    currentPrimaryLegId,
    currentPrimaryRfqId,
    secondaryEventType,
  }: SimilarActivitiesPrimaryLegAddedAction,
): SimilarActivitiesLinkManagerState {
  return { ...state, currentPrimaryLegId, currentPrimaryRfqId, secondaryEventType };
}

function linkSecondaryLeg(
  state: Readonly<SimilarActivitiesLinkManagementModel>,
  { currentSecondaryLegId }: SimilarActivitiesLegLinkedAction,
): SimilarActivitiesLinkManagerState {
  return { ...state, currentSecondaryLegId };
}

function unlinkSecondaryLeg(
  state: Readonly<SimilarActivitiesLinkManagementModel>,
): SimilarActivitiesLinkManagerState {
  return { ...state, legToUnlink: state.currentSecondaryLegId, currentSecondaryLegId: undefined };
}

function addPrimaryStrategy(
  state: Readonly<SimilarActivitiesLinkManagementModel>,
  {
    currentPrimaryStrategyId,
    currentPrimaryRfqId,
    secondaryEventType,
  }: SimilarActivitiesPrimaryStrategyAddedAction,
): SimilarActivitiesLinkManagerState {
  return { ...state, currentPrimaryStrategyId, currentPrimaryRfqId, secondaryEventType };
}

function linkSecondaryStrategy(
  state: Readonly<SimilarActivitiesLinkManagementModel>,
  { currentSecondaryStrategyId }: SimilarActivitiesStrategyLinkedAction,
): SimilarActivitiesLinkManagerState {
  return {
    ...state,
    currentSecondaryStrategyId,
  };
}

function unlinkSecondaryStrategy(
  state: Readonly<SimilarActivitiesLinkManagementModel>,
): SimilarActivitiesLinkManagerState {
  return {
    ...state,
    strategyToUnlink: state.currentSecondaryStrategyId,
    currentSecondaryStrategyId: undefined,
  };
}

function addPrimaryRfq(
  state: Readonly<SimilarActivitiesLinkManagementModel>,
  { currentPrimaryRfqId, secondaryEventType }: SimilarActivitiesPrimaryRfqAddedAction,
): SimilarActivitiesLinkManagerState {
  return { ...state, currentPrimaryRfqId, secondaryEventType };
}

function resetSimilarActivitiesLinkManagement({
  currentPrimaryRfqId,
  currentPrimaryLegId,
  secondaryEventType,
}: SimilarActivitiesLinkManagementResetedAction): SimilarActivitiesLinkManagerState {
  return {
    currentPrimaryRfqId,
    currentPrimaryStrategyId: '',
    currentPrimaryLegId,
    secondaryEventType,
  };
}
