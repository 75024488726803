import { ErrorBoundary } from '@/common/components/errorBoundary/ErrorBoundary';
import type { FC } from 'react';
import type { NegoStep } from '../../business/ui/rfq/rfqUiModel';
import { If } from '../share/if/if';
import { ElsDetails } from './elsDetails/ElsDetails';
import { Nego } from './nego/Nego.container';
import { NotificationModal } from './notification/NotificationModal.container';
import { PostNego } from './postNego/PostNego';
import { PredealChecksModal } from './predealChecks/PredealChecksModal.container';

export interface RfqOwnProps {
  rfqId: string;
}

export interface RfqPropsFromState {
  selectedStep: NegoStep;
  isRfqFullyLoaded: boolean;
  isPostNegoEnabled: boolean;
}

export type RfqProps = RfqOwnProps & RfqPropsFromState;

export const RfqComponent: FC<RfqProps> = ({
  rfqId,
  selectedStep,
  isRfqFullyLoaded,
  isPostNegoEnabled,
}) => {
  if (!isRfqFullyLoaded) {
    return null;
  }

  function renderStep(selectedStep: NegoStep, rfqId: string) {
    switch (selectedStep) {
      case 'NEGO':
        return <Nego rfqId={rfqId} />;
      case 'POST_NEGO':
        return <PostNego rfqId={rfqId} />;
      case 'ELS_DETAILS':
        return <ElsDetails rfqId={rfqId} />;
    }
  }

  return (
    <ErrorBoundary key={rfqId} location={rfqId}>
      <If condition={!isPostNegoEnabled}>
        <Nego rfqId={rfqId} />
      </If>
      <If condition={isPostNegoEnabled}>{renderStep(selectedStep, rfqId)}</If>
      <NotificationModal rfqId={rfqId} />
      <PredealChecksModal rfqId={rfqId} />
      <input data-e2e="neos-rfq-id" type="hidden" value={rfqId} />
    </ErrorBoundary>
  );
};
