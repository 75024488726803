import type { Counterpart } from '../../../../neos/business/neosModel';

export type PrimeBroker = Counterpart;

export const primeBrokerActionCreators = {
  createPrimeBrokersReceivedAction,
  createPrimeBrokersRequestedAction,
};

export interface PrimeBrokersRequestedAction {
  type: 'PRIME_BROKERS_REQUESTED';
  counterpartId: string;
}

export interface PrimeBrokersReceivedAction {
  type: 'PRIME_BROKERS_RECEIVED';
  primeBrokers: PrimeBroker[];
  counterpartId: string;
}

export type PrimeBrokersAction = PrimeBrokersRequestedAction | PrimeBrokersReceivedAction;

export function createPrimeBrokersRequestedAction(counterpartId: string): PrimeBrokersAction {
  return {
    type: 'PRIME_BROKERS_REQUESTED',
    counterpartId,
  };
}

export function createPrimeBrokersReceivedAction(
  primeBrokers: PrimeBroker[],
  counterpartId: string,
): PrimeBrokersAction {
  return {
    type: 'PRIME_BROKERS_RECEIVED',
    primeBrokers,
    counterpartId,
  };
}
