import type { Action } from '@/bootstrap/actions';
import type { Thunk } from '@/bootstrap/thunks';
import type { OnyxAlert } from '@/neos/business/alert/alertOnyxModel';
import type { AppNotificationData } from '@/appServiceWorker/notificationModel.ts';
import { getAlertAssets } from '@/neos/business/alert/alertReceivedHelper.ts';
import { serviceWorker } from '@/appServiceWorker';

export function createAlertReceivedThunk(alert: OnyxAlert): Thunk {
  return function alertReceivedThunk(
    dispatch,
    getState,
    {
      selectors: { isFeatureToggleEnabled },
      actionCreators: {
        neos: { createAlertCrudAction },
      },
      thunks: {
        neos: { createPlayMdpSoundThunk },
      },
    },
  ) {
    const state = getState();
    const isFeatureToggled = isFeatureToggleEnabled(
      state,
      'neos.browser.notification.image.enabled',
    );
    const neosImg = isFeatureToggled ? '/assets/images/neos.png' : undefined;

    if (alert.active) {
      const notificationData: AppNotificationData = {
        data: {
          type: 'NEOS_ALERT_CLICK',
          value: alert,
        },
        path: location.pathname + '#/neos/open/' + alert.rfqUuid,
      };
      const assets = getAlertAssets(alert);
      serviceWorker.showNotification(alert.message, notificationData, {
        requireInteraction: true,
        tag: alert.uuid,
        icon: assets?.icon,
        image: assets?.image ?? neosImg,
      });
    } else {
      serviceWorker.hideNotification(alert.uuid);
    }

    const action: Action = alert.active
      ? createAlertCrudAction.upsert(alert.uuid, alert)
      : createAlertCrudAction.delete(alert.uuid);

    dispatch(action, createPlayMdpSoundThunk(alert));
  };
}
