import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import type { FC } from 'react';
import { NumericInput } from '../../share/numericInput';
import { getCommissionsModel } from './allocs/listedExecutions/legExecution/executionAllocations/getCommissionsModel';

type TotalRfqLegsCommissionProps = {
  rfqId: string;
};

export const TotalRfqLegsCommission: FC<TotalRfqLegsCommissionProps> = ({ rfqId }) => {
  const areCommissionsFieldsDisplayed = useAppSelector(state =>
    getCommissionsModel(state, rfqId, selectors),
  );
  const totalCommission = useAppSelector(state =>
    selectors.getRfqTotalCommission(state, rfqId, selectors),
  );

  if (!areCommissionsFieldsDisplayed || totalCommission === undefined) {
    return null;
  }

  const { value: totalCommissionValue, unit: totalCommissionUnit } = totalCommission;

  return (
    <div className="d-flex flex-column text-nowrap border-start border-md ps-2">
      <label className="fw-bold mb-1">Total Rfq Comm.</label>

      <NumericInput
        data-e2e="total-sales-margin"
        inputClassName="fw-bold"
        unit={totalCommissionUnit}
        value={totalCommissionValue}
        withMaximumNumberOfFloatingDigits={0}
        readOnly
      />
    </div>
  );
};
