import type { Thunk } from '@/bootstrap/thunks';

export function createKeepSavedGlobalDeltaThunk(rfqId: string): Thunk {
  return function keepSavedGlobalDeltaThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { underlyingUiCrudActions },
      },
    },
  ) {
    const state = getState();
    const actions = selectors
      .getNotAggregatedReferences(state, rfqId, selectors)
      .map(({ underlyingId }) => {
        const dataAggregationByUnderlyingId = selectors.getDataAggregationByUnderlyingId(
          state,
          rfqId,
          underlyingId,
        );

        const initialDeltaSum =
          selectors.getDeltaSum(state, rfqId, underlyingId, selectors) ??
          dataAggregationByUnderlyingId?.greeks?.delta?.value;

        return underlyingUiCrudActions.patchOrInsert({ rfqId, underlyingId }, { initialDeltaSum });
      });

    dispatch(actions);
  };
}
