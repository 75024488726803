import { formatNowInTimeZone } from '@/util/date/dateFormatHelper';
import { formatNum } from '@/util/number/numberUtils';
import { lowerThenCamelCase } from '@/util/string/stringUtils';
import copy from 'copy-to-clipboard';
import { round } from 'lodash';
import { Tooltip } from 'react-bootstrap';
import type { ReferenceKey } from '../../../../business/neosModel';
import { type OrderType, isUnderlyingIndexType } from '../../../../business/neosOnyxModel';
import { NeosBlurInput } from '../../../share/blurComponent/NeosBlurInput';
import { CircularProgressBar } from '../../../share/circularProgressBar/CircularProgressBar';
import { MultiToggleButton } from '../../../share/multiToggleButton/MultiToggleButton';
import { NumericInput } from '../../../share/numericInput';
import { NeosTooltip } from '../../../share/tooltip/NeosTooltip';
import { LotSizeMarketCell } from '../../strategies/strategy/strategyDefinition/lotSizeMarketCell/LotSizeMarketCell.container';
import { SizeCells } from '../../strategies/strategy/strategyDefinition/sizeCells/SizeCells';
import { RefMaturityCell } from '../underlyingReference/refMaturityCell/RefMaturityCell.container';
import { DeltaAdjustedBooking } from './deltaAdjustedBooking/DeltaAdjustedBooking.container';
import { DeltaAdjustedAmendButton } from './deltaAdjustedButtons/deltaAdjustedAmendButton/DeltaAdjustedAmendButton.container';
import { DeltaAdjustedCancelButton } from './deltaAdjustedButtons/deltaAdjustedCancelButton/DeltaAdjustedCancelButton.container';
import type { DeltaAdjustedRefsModel } from './getDeltaAdjustedModel';

import { DeltaAdjustedBookingId } from '@/neos/components/rfq/reference/deltaAdjusted/DeltaAdjustedBookingId';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import styles from './DeltaAdjusted.module.scss';

export interface DeltaAdjustedOwnProps {
  rfqId: string;
}

export type DeltaAdjustedMapStateToProps = DeltaAdjustedRefsModel;

export interface DeltaAdjustedDispatchProps {
  onOrderTypeChanged: (strategyId: string, orderType: OrderType | undefined) => void;
  onLimitChanged: (strategyId: string, limit: number | undefined, unit: string | undefined) => void;
  onExecCommentChanged: (strategyId: string, execComment: string | undefined) => void;
  onRefLevelChanged: (
    underlyingId: string,
    refLevel: number | undefined,
    referenceKey: ReferenceKey,
  ) => void;
  onPortfolioIdChanged: (strategyId: string, portfolioId: string) => void;
  onCopyToClipboard: (text: string) => void;
}

export type DeltaAdjustedProps = DeltaAdjustedOwnProps &
  DeltaAdjustedMapStateToProps &
  DeltaAdjustedDispatchProps;

export const DeltaAdjustedComponent = ({
  rfqId,
  deltaAdjustedModels,
  isReadonlyRfq,
  displayTimeZone,
  showBookingInfo,
  isAutoExecEnabled,
  isBookingIdDisplayed,
  isBookingIdReadonly,
  isBookingMessageDisplayed,
  onOrderTypeChanged,
  onLimitChanged,
  onExecCommentChanged,
  onRefLevelChanged,
  onPortfolioIdChanged,
}: DeltaAdjustedProps) => (
  <div>
    <section className={`${styles['ref-delta-adjusted']} ${styles['booking']}  `}>
      <section className={`${styles['ref']} `}>
        <section>
          <div></div>
          <div className={`${styles['column']} pe-1`}>
            <DeltaAdjustedBooking rfqId={rfqId} />
          </div>
        </section>
        <section>
          <div>&nbsp;</div>
          <div className={`${styles['underlying-label']} readonly-values`}>
            {deltaAdjustedModels.map(({ underlyingBloombergCode, warning, underlyingId }) => (
              <div key={underlyingId}>
                {!!warning && (
                  <SimpleNeosTooltip
                    type={'warning'}
                    id={`${underlyingId}-delta-adj-warning`}
                    message={warning}
                  >
                    <i data-e2e={`delta-adj-warning`} className="icon icon-sm text-warning me-1">
                      warning
                    </i>
                  </SimpleNeosTooltip>
                )}
                <span data-e2e={`neos-rfq-deltaAdjusted-udl-${underlyingBloombergCode}`}>
                  {underlyingBloombergCode || <div className="spinner spinner-sm" />}
                </span>
              </div>
            ))}
          </div>
        </section>
        <section>
          <div>SG Way</div>
          {deltaAdjustedModels.map(({ underlyingId, sgWay }) => (
            <span key={underlyingId} data-e2e={`neos-rfq-deltaAdjusted-sgway`}>
              <input className="form-control" value={lowerThenCamelCase(sgWay || '')} readOnly />
            </span>
          ))}
        </section>
        <section>
          <div>Size</div>
          {deltaAdjustedModels.map(({ underlyingId, strategyId }) => (
            <SizeCells
              isReadOnlyRfq={isReadonlyRfq}
              key={underlyingId}
              rfqId={rfqId}
              strategyId={strategyId}
            />
          ))}
        </section>
        <section>
          <div>Lot Size / Mkt</div>
          {deltaAdjustedModels.map(({ underlyingId, strategyId }) => (
            <LotSizeMarketCell key={underlyingId} rfqId={rfqId} strategyId={strategyId} />
          ))}
        </section>
        <section>
          <div>Instr</div>
          {deltaAdjustedModels.map(({ underlyingId, instrument }) => (
            <span key={underlyingId} data-e2e={`neos-rfq-deltaAdjusted-instr`}>
              <input
                className="form-control"
                value={lowerThenCamelCase(instrument || '')}
                readOnly
              />
            </span>
          ))}
        </section>
        <section>
          <div>Ref Maturity</div>
          {deltaAdjustedModels.map(({ underlyingId }) => (
            <RefMaturityCell key={underlyingId} rfqId={rfqId} underlyingId={underlyingId} />
          ))}
        </section>
      </section>

      <section className={`${styles['delta-adjusted']} `}>
        {!showBookingInfo && (
          <section>
            <div>Validity</div>
            {deltaAdjustedModels.map(({ underlyingId, timeInForce }) => (
              <span key={underlyingId} data-e2e={`neos-rfq-deltaAdjusted-timeInForce`}>
                <input className="form-control" value={lowerThenCamelCase(timeInForce)} readOnly />
              </span>
            ))}
          </section>
        )}

        <section>
          <div>Order Type</div>
          {deltaAdjustedModels.map(({ strategyId, type }) => (
            <MultiToggleButton<OrderType | undefined>
              key={strategyId}
              className="form-control"
              data-e2e="neos-rfq-deltaAdjusted-type"
              selectedValue={type}
              availableValues={['MARKET', 'LIMIT', 'MARKET_ON_CLOSE']}
              onValueChange={val => onOrderTypeChanged(strategyId, val)}
              readOnly={isReadonlyRfq || showBookingInfo}
              valueFormatter={val =>
                val === 'MARKET_ON_CLOSE' ? 'Close' : lowerThenCamelCase(val)
              }
            />
          ))}
        </section>
        {!showBookingInfo && (
          <>
            <section>
              <div>Limit</div>
              {deltaAdjustedModels.map(
                ({ strategyId, type, limitPriceValue, limitPriceUnit, refCurrency }) => (
                  <NumericInput
                    readOnly={isReadonlyRfq || type !== 'LIMIT'}
                    key={strategyId}
                    value={limitPriceValue}
                    onBlur={val => onLimitChanged(strategyId, val, limitPriceUnit ?? refCurrency)}
                    data-e2e="neos-rfq-deltaAdjusted-limit"
                    unit={limitPriceUnit ?? refCurrency}
                  />
                ),
              )}
            </section>
            <section>
              <div>Handling Instr.</div>
              {deltaAdjustedModels.map(({ underlyingId, handlingInstruction }) => (
                <span key={underlyingId} data-e2e={`neos-rfq-deltaAdjusted-type`}>
                  <input
                    className="form-control"
                    value={lowerThenCamelCase(handlingInstruction)}
                    readOnly
                  />
                </span>
              ))}
            </section>

            <section>
              <div>Exec. Comment</div>
              {deltaAdjustedModels.map(({ strategyId, execComment }) => (
                <span key={strategyId} data-e2e={`neos-rfq-deltaAdjusted-type`}>
                  <NeosBlurInput
                    value={execComment || ''}
                    readOnly={isReadonlyRfq}
                    onBlur={ev => onExecCommentChanged(strategyId, ev.target.value || undefined)}
                  />
                </span>
              ))}
            </section>
          </>
        )}
      </section>

      <section className={`${styles['right-bloc']} `}>
        <section>
          <div>Ref</div>
          {deltaAdjustedModels.map(({ underlyingId, refLevel, refSpot, refCurrency, refType }) => (
            <SimpleNeosTooltip
              key={underlyingId}
              placement="left"
              id={`neos-rfq-deltaAdjusted-ref-${underlyingId}`}
              message={refType && isUnderlyingIndexType(refType) ? 'Level' : 'Spot'}
            >
              <span>
                <NumericInput
                  key={underlyingId}
                  data-e2e="neos-rfq-deltaAdjusted-ref"
                  value={refType && !isUnderlyingIndexType(refType) ? refSpot : refLevel}
                  readOnly={isReadonlyRfq}
                  onBlur={val => onRefLevelChanged(underlyingId, val, { rfqId, underlyingId })}
                  unit={refCurrency}
                />
              </span>
            </SimpleNeosTooltip>
          ))}
        </section>

        <section className={`${styles['refereexec-progressnce']}`}>
          <div>Average Exec. Price</div>
          {deltaAdjustedModels.map(
            ({
              underlyingId,
              averageExecPriceValue,
              averageExecPriceUnit,
              averageExecPriceWarning,
            }) => (
              <SimpleNeosTooltip
                key={underlyingId}
                id={underlyingId}
                message={averageExecPriceWarning.warningText}
                disable={!averageExecPriceWarning.isWarningDisplayed}
                type="warning"
              >
                <NumericInput
                  className={
                    averageExecPriceWarning.isWarningDisplayed
                      ? 'warning-bloc field-warning'
                      : undefined
                  }
                  key={underlyingId}
                  data-e2e="neos-rfq-deltaAdjusted-avgExecPrice"
                  value={averageExecPriceValue}
                  readOnly
                  unit={averageExecPriceUnit}
                />
              </SimpleNeosTooltip>
            ),
          )}
        </section>
        <section className={`${styles['exec-progress']}`}>
          <div></div>
          {deltaAdjustedModels.map(
            ({
              underlyingId,
              execPercentage,
              totalNumberOfLots,
              execNumberOfLots,
              averageExecPriceValue,
              averageExecPriceUnit,
              underlyingBloombergCode,
            }) => {
              const rounded = execPercentage !== undefined ? round(execPercentage) : undefined;

              return (
                <NeosTooltip
                  key={underlyingId}
                  placement="left"
                  overlay={
                    <Tooltip id={`neos-rfq-deltaAdjusted-execTooltip-${underlyingId}`}>
                      {totalNumberOfLots !== undefined && execNumberOfLots !== undefined ? (
                        <>
                          Executed {formatNum(execNumberOfLots)} of {formatNum(totalNumberOfLots)}
                          <br />
                          (click to copy to clipboard)
                        </>
                      ) : (
                        'Exec. progress'
                      )}
                    </Tooltip>
                  }
                >
                  <div>
                    {execPercentage !== undefined && (
                      <CircularProgressBar
                        sqSize={30}
                        percentage={rounded}
                        strokeWidth={2}
                        onClick={() =>
                          copy(
                            `${underlyingBloombergCode} at ${formatNowInTimeZone(
                              displayTimeZone,
                            )}, ${rounded}% executed (${formatNum(execNumberOfLots)} of ${formatNum(
                              totalNumberOfLots,
                            )}) - Avg exec price = ${averageExecPriceValue} ${averageExecPriceUnit}`,
                          )
                        }
                      />
                    )}
                  </div>
                </NeosTooltip>
              );
            },
          )}
        </section>
      </section>
      {showBookingInfo && (
        <section className={`${styles['booking-info']} `}>
          <section>
            <div>Portfolio</div>
            {deltaAdjustedModels.map(({ strategyId, portfolioId }) => (
              <span key={strategyId} data-e2e={`neos-rfq-deltaAdjusted-portfolioId`}>
                <NeosBlurInput
                  value={portfolioId || ''}
                  onBlur={e => onPortfolioIdChanged(strategyId, e.target.value)}
                />
              </span>
            ))}
          </section>
          {isBookingIdDisplayed && (
            <section>
              <div>Booking Id</div>
              {deltaAdjustedModels.map(
                ({
                  underlyingId,
                  isBookingIdManual,
                  bookingId,
                  defaultBookingId,
                  bookingApplication,
                  strategyId,
                }) => (
                  <DeltaAdjustedBookingId
                    key={underlyingId}
                    strategyId={strategyId}
                    bookingIdManual={isBookingIdManual}
                    value={bookingApplication}
                    defaultBookingId={defaultBookingId}
                    bookingId={bookingId}
                    readOnly={isBookingIdReadonly}
                  />
                ),
              )}
            </section>
          )}
          {isBookingMessageDisplayed && (
            <section>
              <div></div>
              {deltaAdjustedModels.map(({ underlyingId, bookingMessage }) => {
                const bookingMessageLower = bookingMessage?.toLocaleLowerCase();
                const isError = bookingMessageLower?.includes('error');
                const isWarning = bookingMessageLower?.includes('warning');
                return (
                  <div
                    className={`${styles['booking-message']} `}
                    key={underlyingId}
                    data-e2e={`neos-rfq-deltaAdjusted-bookingMessage`}
                  >
                    {bookingMessage && (
                      <SimpleNeosTooltip id="deltaAdj-booking-message" message={bookingMessage}>
                        <i
                          className={`icon text-${
                            isError ? 'danger' : isWarning ? 'warning' : 'info'
                          }`}
                        >
                          {isError ? 'cancel' : isWarning ? 'warning' : 'info'}
                        </i>
                      </SimpleNeosTooltip>
                    )}
                  </div>
                );
              })}
            </section>
          )}
        </section>
      )}
    </section>
    {isAutoExecEnabled && (
      <div className={`${styles['delta-buttons-container']} `}>
        <DeltaAdjustedAmendButton rfqId={rfqId} />
        <DeltaAdjustedCancelButton rfqId={rfqId} />
      </div>
    )}
  </div>
);
