import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { thunks } from '@/bootstrap/thunks';
import { isElsProduct } from '@/neos/business/rfq/strategy/leg/product/productModel';
import { ElsFeaturesContext } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures';
import { getElsRefSpotModel } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/EquityBloc/getElsRefSpotModel';
import { ElsBlocField } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/ElsBlocField';
import { NumericInput } from '@/neos/components/share/numericInput';
import { NeosTooltip } from '@/neos/components/share/tooltip/NeosTooltip';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';

export function RefSpot() {
  const { rfqId, strategyId } = useContext(ElsFeaturesContext);
  const dispatch = useDispatch();
  const product = useAppSelector(state =>
    selectors.getStrategyProduct(state, strategyId, selectors),
  );
  const shouldBeEditable = useAppSelector(state =>
    selectors.isSecondaryEditable(state, rfqId, selectors),
  );
  const refSpotModel = useAppSelector(state =>
    getElsRefSpotModel(state, rfqId, strategyId, selectors),
  );
  const isReadonlyRfq = useAppSelector(state => selectors.isReadOnlyRfq(state, rfqId));

  if (!isElsProduct(product)) {
    return null;
  }

  function onRefSpotChanged(underlyingId: string, refSpot: number | undefined): void {
    dispatch(thunks.neos.createChangeReferenceSpotThunk({ rfqId, underlyingId }, refSpot));
  }

  return (
    <ElsBlocField
      label="Ref Spot"
      renderInput={readOnly => (
        <NeosTooltip
          id={`refSpot-${refSpotModel?.underlyingId}`}
          isDisplayed={!!refSpotModel?.refSpot.warningMessage}
          renderMessage={() => <p>{refSpotModel?.refSpot.warningMessage}</p>}
          componentClassName="warning-bloc field-warning"
          tooltipClassName="react-bootstrap-warning-tooltip"
        >
          <NumericInput
            style={{ width: '100px' }}
            unit={refSpotModel?.refSpot.unit}
            value={refSpotModel?.refSpot.value}
            readOnly={(isReadonlyRfq || !refSpotModel || readOnly) && !shouldBeEditable}
            onBlur={val => refSpotModel && onRefSpotChanged(refSpotModel.underlyingId, val)}
            data-e2e="els-section-equity-ref-spot"
          />
        </NeosTooltip>
      )}
    />
  );
}
