import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { Observable } from 'rxjs';
import type { Underlying } from '../../neosModel';
import type { RetrieveUnderlyingApi } from './underlyingEpics';

export const createRetrieveUnderlyingApi = (http: SgmeHttp): RetrieveUnderlyingApi => ({
  retrieveUnderlyingByBloombergCode: (bloombergCode: string): Observable<Underlying[]> => {
    const url: string = `api/referential/underlyings?bloombergCode=${bloombergCode}`;
    return http.get<Underlying[]>({
      url,
    });
  },
  bulkRetrieveUnderlyingByBloombergCodes: (bloombergCodes: string[]): Observable<Underlying[]> => {
    const url: string = `api/referential/underlyings?bloombergCodes=${bloombergCodes.join(',')}`;
    return http.get<Underlying[]>({
      url,
    });
  },
});
