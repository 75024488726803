import { isEqual } from 'lodash';
import { ajax } from 'rxjs/ajax';
import { catchError } from 'rxjs/operators';

interface AppStatus {
  infrastructure: {
    component: string;
    version: string;
    instanceId: number;
  };
}

interface FrontVersion {
  version: string;
  buildId: string;
  buildNumber: string;
  sourceVersion: string;
  shortSha: string;
}

type Versions = Record<string, string> | FrontVersion;
const savedVersionsKey = `savedVersions (${location.pathname})`;

ajax
  .getJSON<AppStatus[] | FrontVersion>(sgmeConfiguration.http.apiInfoUrl)
  .pipe(catchError(() => []))
  .subscribe(appStatuses => {
    const currentVersions = Array.isArray(appStatuses)
      ? mapToCompositeVersion(appStatuses)
      : appStatuses;
    const savedVersions = getSavedVersions();
    if (!isEqual(currentVersions, savedVersions)) {
      storeSavedVersions(currentVersions);
      cleanLocalStorage();
      clearCookies();
      location.reload();
    }
  });

function getSavedVersions() {
  const savedVersionsStr = localStorage.getItem(savedVersionsKey) ?? '{}';
  return JSON.parse(savedVersionsStr) as Versions;
}

function storeSavedVersions(versions: Versions) {
  localStorage.setItem(savedVersionsKey, JSON.stringify(versions));
}

function mapToCompositeVersion(statuses: AppStatus[]) {
  return statuses.reduce<Versions>(
    (prev, { infrastructure: { component, version } }) => ({
      ...prev,
      [component]: version,
    }),
    {},
  );
}

const cookiePolicyCookieName = 'SGMCC';

function clearCookies() {
  document.cookie
    .split('; ')
    .map(c => c.split(';')[0].split('=')[0])
    .filter(cookieName => cookieName !== cookiePolicyCookieName)
    .forEach(cookieName => {
      const d = window.location.hostname.split('.');
      while (d.length > 0) {
        const cookieBase =
          encodeURIComponent(cookieName) +
          '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' +
          d.join('.') +
          ' ;path=';
        const p = location.pathname.split('/');
        document.cookie = cookieBase + '/';
        while (p.length > 0) {
          document.cookie = cookieBase + p.join('/');
          p.pop();
        }
        d.shift();
      }
    });
}

function cleanLocalStorage() {
  for (const key in localStorage) {
    if (key.startsWith('SGWTConnectStorage')) {
      localStorage.removeItem(key);
    }
  }
}
