import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import type { ColumnsDisplayToggle as Toggle } from '../../../../business/ui/userPreferences/userPreferencesUiModel';

export interface ColumnsDisplayToggleProps {
  name: string;
  toggle: Toggle;
  switchToggle: (toggle: Toggle) => void;
}

export const ColumnsDisplayToggle = ({ toggle, name, switchToggle }: ColumnsDisplayToggleProps) => (
  <ToggleButtonGroup
    name={`${name}-clientWay`}
    className="errorable-bloc btn-group-toggle"
    type="radio"
    onChange={switchToggle}
    value={toggle}
  >
    <ToggleButton variant="toggle-primary" value={'DISPLAYED'} id={`${name}-DISPLAYED`}>
      Displayed
    </ToggleButton>
    <ToggleButton variant="toggle-primary" value={'ON_TOGGLE'} id={`${name}-ON_TOGGLE`}>
      On leg details
    </ToggleButton>
    <ToggleButton variant="toggle-primary" value={'HIDDEN'} id={`${name}-HIDDEN`}>
      Hidden
    </ToggleButton>
  </ToggleButtonGroup>
);
