import { mapFromOnyxCurrentUserPreferences } from '@/neos/business/currentUserPreferences/currentUserPreferencesMappers';
import { fromAllocationInstructionMappers } from '../allocationInstruction/allocationInstructionMappers';
import { mapFromOnyxNextUserAction } from '../nextUserActions/nextUserActionsMapper';
import { fromOrderMappers, toOrderMappers } from '../order/orderMappers';
import { mapToOnyxPreConfirmationSteps } from '../preConfirmationStep/preConfirmationStepMapper';
import {
  mapFromExternalOnyxPreconfirmation,
  mapToExternalOnyxPreconfirmation,
} from '../preconfirmation/external/externalPreconfirmationMapper';
import {
  mapFromInternalOnyxPreconfirmation,
  mapToInternalOnyxPreconfirmation,
} from '../preconfirmation/internal/internalPreconfirmationMapper';
import { fromReferenceDataMappers } from '../referenceData/referenceDataMapper';
import { fromRfqMappers, toRfqMappers } from '../rfq/rfqMapper';
import { mapFromUnderlyingInfo } from '../underlyingInfo/underlyingInfoMapper';
import { mapFromOnyxErrors } from './error';
import { mapToOnyxTransaction } from './transactionMapper';

const toOnyxMappers = {
  mapToOnyxTransaction,
  mapToExternalOnyxPreconfirmation,
  mapToInternalOnyxPreconfirmation,
  ...toRfqMappers,
  ...toOrderMappers,
  mapToOnyxPreConfirmationSteps,
};
export type ToOnyxMappers = typeof toOnyxMappers;

const fromOnyxMappers = {
  mapFromOnyxErrors,
  mapFromUnderlyingInfo,
  mapFromOnyxNextUserAction,
  mapFromExternalOnyxPreconfirmation,
  mapFromInternalOnyxPreconfirmation,
  ...fromAllocationInstructionMappers,
  ...fromOrderMappers,
  ...fromRfqMappers,
  ...fromReferenceDataMappers,
  mapFromOnyxCurrentUserPreferences,
};
export type FromOnyxMappers = typeof fromOnyxMappers;

export interface OnyxMappers {
  toOnyxMappers: ToOnyxMappers;
  fromOnyxMappers: FromOnyxMappers;
}

export const mappers: OnyxMappers = { toOnyxMappers, fromOnyxMappers };
