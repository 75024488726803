import type { AppState } from '@/bootstrap/state';

import { extraCounterpartsDomain } from './extraCounterpartsDomain';

export const extraCounterpartsSelectors = {
  getAllocExtraCounterparts,
};

function getAllocExtraCounterparts(state: AppState, rfqId: string) {
  return extraCounterpartsDomain.selectors.find(state.extraCounterparts, rfqId)?.ALLOCATIONS;
}
