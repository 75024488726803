import type { Counterpart } from '@/neos/business/rfq/actorsSetup/counterpart/counterpartModel';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { Observable } from 'rxjs';

export type OnyxCounterpartId = string | number;

export interface CounterpartPreferencesApis {
  searchCounterpart: (entry: string) => Observable<Counterpart[]>;
  addCounterpart: (id: OnyxCounterpartId) => Observable<void>;
  deleteCounterpart: (id: OnyxCounterpartId) => Observable<void>;
}

export const counterpartPreferencesApis = (http: SgmeHttp): CounterpartPreferencesApis => ({
  deleteCounterpart(id) {
    const url: string = `api/referential/counterparts/favorite`;
    return http.delete<void>({
      url,
      body: [id],
    });
  },
  addCounterpart(id) {
    const url: string = `api/referential/counterparts/favorite`;
    return http.put<void>({
      url,
      body: [id],
    });
  },
  searchCounterpart(entry) {
    const url: string = `api/referential/counterparts/search?query=${entry}`;
    return http.get<Counterpart[]>({
      url,
    });
  },
});
