interface Params {
  areElsPtmmmDisplayed: boolean;
  areFairPricesDisplayed: boolean;
  areSalesPricesDisplayed: boolean;
  isTraderDeltaDisplayed: boolean;
  isMarkupDisplayed: boolean;
}

export function getQuotesAndHeaderGridColumnTemplate({
  areElsPtmmmDisplayed,
  areFairPricesDisplayed,
  areSalesPricesDisplayed,
  isTraderDeltaDisplayed,
  isMarkupDisplayed,
}: Params) {
  const priceColumnWithUnit = 'minmax(113px, 150px)';
  const priceColumnWithoutUnit = 'minmax(83px, 124px)';

  const ptmmmColumn = areElsPtmmmDisplayed ? priceColumnWithoutUnit : '';
  const midColumn = areFairPricesDisplayed ? priceColumnWithoutUnit : '';

  const salesBidColumn = areSalesPricesDisplayed ? priceColumnWithUnit : '';
  const traderBidColumn = areSalesPricesDisplayed ? priceColumnWithoutUnit : priceColumnWithUnit;

  const salesAskColumn = areSalesPricesDisplayed ? priceColumnWithoutUnit : '';
  const traderAskColumn = priceColumnWithoutUnit; // to keep semantic naming, in final output

  const markupColumn = areSalesPricesDisplayed && isMarkupDisplayed ? 'minmax(81px, 124px)' : '';
  const deltaColumn = isTraderDeltaDisplayed ? '80px' : '';

  return {
    gridTemplateColumns: `${salesBidColumn} ${traderBidColumn} ${ptmmmColumn} ${midColumn} ${traderAskColumn} ${salesAskColumn} ${markupColumn} ${deltaColumn}`,
  };
}
