import type { Thunk } from '@/bootstrap/thunks';
import type { UnderlyingChangedParameters } from '@/neos/business/underlyingInfo/underlyingInfoActions';
import type { NegotiationMode, StrategyType } from '../../../../neos/business/neosModel';
import type { DeltaType } from '../models';

export function createRfqSelectUnderlyingShortcutThunk(
  rfqId: string,
  underlyingId: string,
  isApplyToAll: boolean,
): Thunk {
  return function rfqSelectUnderlyingShortcutThunk(
    dispatch,
    getState,
    {
      selectors: { getSelectedLegId },
      thunks: {
        neos: { createNeosChangeRfqUnderlyingThunk },
      },
      actionCreators: {
        analytics: { createLogAnalyticsAction },
      },
    },
  ) {
    const state = getState();

    const selectedLegId = getSelectedLegId(state.ui, rfqId);

    const parameters: UnderlyingChangedParameters = {
      type: 'FROM_SHORTCUTS',
      isApplyToAll,
      selectedLegId,
      underlyingId,
      ignoreUndefined: false,
    };

    dispatch(
      createLogAnalyticsAction('NEOS RFQ', 'Add underlying'),
      createNeosChangeRfqUnderlyingThunk(rfqId, parameters),
    );
  };
}

export function createRfqSelectStrategyTypeShortcutThunk(
  rfqId: string,
  strategyType: StrategyType,
  isApplyToAll: boolean,
): Thunk {
  return function rfqSelectStrategyTypeShortcutThunk(
    dispatch,
    getState,
    {
      selectors,
      thunks: {
        neos: { createNeosChangeStrategiesTypesThunk },
      },
    },
  ) {
    const state = getState();
    const strategyIds = selectors.getStrategyIdsForShortcut(state, rfqId, isApplyToAll, selectors);
    dispatch(createNeosChangeStrategiesTypesThunk(rfqId, strategyIds, strategyType));
  };
}

export function createRfqSelectNegotiationModeShortcutThunk(
  rfqId: string,
  negotiationMode: NegotiationMode,
  isApplyToAll: boolean,
): Thunk {
  return function rfqSelectNegotiationModeShortcutThunk(
    dispatch,
    getState,
    {
      thunks: {
        neos: { createChangeStrategiesNegotiationModeThunk },
      },
      selectors,
      actionCreators: {
        analytics: { createLogAnalyticsAction },
      },
    },
  ) {
    const state = getState();
    const strategyIds = selectors.getStrategyIdsForShortcut(state, rfqId, isApplyToAll, selectors);

    dispatch(
      createLogAnalyticsAction('NEOS RFQ', 'Select Negotiation mode'),
      createChangeStrategiesNegotiationModeThunk(rfqId, strategyIds, negotiationMode),
    );
  };
}

export function createRfqSelectDeltaTypeShortcutThunk(
  rfqId: string,
  deltaType: DeltaType,
  isApplyToAll: boolean,
): Thunk {
  return function rfqSelectDeltaTypeShortcutThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        analytics: { createLogAnalyticsAction },
      },
      thunks: {
        neos: { createRfqUpdateDeltaTypeThunk },
      },
    },
  ) {
    const state = getState();
    const underlyingIds = selectors.getUnderlyingIdsForShortcut(
      state,
      rfqId,
      isApplyToAll,
      selectors,
    );

    dispatch(
      createLogAnalyticsAction('NEOS RFQ', `Select Delta type`),
      ...underlyingIds.map(underlyingId =>
        createRfqUpdateDeltaTypeThunk({ rfqId, underlyingId, deltaType }),
      ),
    );
  };
}
