import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { Observable } from 'rxjs';
import type { RecomputeRfqApi } from '../epics/recomputeRfqEpic';
import type { RecomputeScope } from '../models/recomputeModel';
import type { OnyxRfq } from '../rfqOnyxModel';

export const createRecomputeRfqApi = (http: SgmeHttp): RecomputeRfqApi => ({
  recomputeRfq(rfq: OnyxRfq, scope: RecomputeScope): Observable<OnyxRfq> {
    const { uuid } = rfq;
    const url: string = `api/rfq/recompute/${uuid}?scopes=${scope}`;
    return http.post({
      url,
      body: rfq,
    });
  },
});
