import { mappers } from '@/neos/business/mappers';
import thunkMiddlewareWithoutExtraArgument from 'redux-thunk';
import { actionCreators } from '../../actions';
import { selectors } from '../../selectors';
import { services } from '../../services';
import { thunks } from '../../thunks';

export function createThunkMiddleware() {
  return thunkMiddlewareWithoutExtraArgument.withExtraArgument({
    selectors,
    actionCreators,
    services,
    thunks,
    fromMappers: mappers.fromOnyxMappers,
    toMappers: mappers.toOnyxMappers,
  });
}
