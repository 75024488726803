import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { OnyxTransaction } from '../../neosOnyxModel';
import type { PredealChecksRefreshApi } from '../epics/refreshPredealChecksEpic';
import type { OnyxPredealCheckKey } from '../predealCheckOnyxModel';

export const createPredealChecksRefreshApi = (http: SgmeHttp): PredealChecksRefreshApi => ({
  refreshPredealChecks(rfqId: string, transaction: OnyxTransaction) {
    return http.post<OnyxPredealCheckKey[]>({
      url: `api/workflow-neos/${rfqId}/refreshPdc`,
      body: transaction,
    });
  },
});
