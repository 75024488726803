import { NeosBlurInput } from '@/neos/components/share/blurComponent/NeosBlurInput';
import { type FC, useState } from 'react';
import styles from './MdpQuoteTimerEdition.module.scss';

export interface MdpQuoteTimerEditionOwnProps {
  rfqId: string;
}
export interface MdpQuoteTimerEditionStateProps {
  isEditable: boolean;
  quoteValidityDuration: number | undefined;
}

export interface MdpQuoteTimerEditionDispatchProps {
  onEdit: (isVisble: boolean) => void;
  onQuoteTimerDurationChange: (quoteValidityDuration: number | undefined) => void;
}

export type MdpQuoteTimerEditionProps = MdpQuoteTimerEditionOwnProps &
  MdpQuoteTimerEditionStateProps &
  MdpQuoteTimerEditionDispatchProps;
export const MdpQuoteTimerEditionComponent: FC<MdpQuoteTimerEditionProps> = ({
  isEditable,
  quoteValidityDuration,
  onEdit,
  onQuoteTimerDurationChange,
}) => {
  const [seconds, setSeconds] = useState((quoteValidityDuration ?? 0) % 60);
  const [minutes, setMinutes] = useState(((quoteValidityDuration ?? 0) - seconds) / 60);
  return (
    <>
      <button
        onClick={() => {
          onEdit(!isEditable);
        }}
        type="button"
        className="btn btn-sm btn-primary btn-icon-start me-1 text-nowrap"
      >
        <i className="icon icon-sm pe-1">edit</i> Edit Timer
      </button>
      {isEditable && (
        <>
          <NeosBlurInput
            className={`${styles['quote-duration']} form-control-sm text-center fw-bold text-info`}
            value={minutes}
            type="number"
            min="0"
            max="59"
            onBlur={({ target: { value } }) => {
              const result = value ? Math.max(Math.min(Number(value), 59), 0) : 0;
              setMinutes(result);
              onQuoteTimerDurationChange(result * 60 + seconds);
            }}
          />
          <span className="fw-bold px-1">:</span>
          <NeosBlurInput
            className={`${styles['quote-duration']} form-control-sm text-center fw-bold text-info`}
            value={seconds}
            type="number"
            min="0"
            max="59"
            onBlur={({ target: { value } }) => {
              const result = value ? Math.max(Math.min(Number(value), 59), 0) : 0;
              setSeconds(result);
              onQuoteTimerDurationChange(minutes * 60 + result);
            }}
          />
        </>
      )}
    </>
  );
};
