import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { Observable } from 'rxjs';
import type { DefaultRfqApi, RequiredDefaultingOptionsParameters } from '../epics/defaultRfqEpic';
import type { DefaultingMode, DefaultingScope } from '../models';
import type { OnyxRfq } from '../rfqOnyxModel';

type ScopeModeMap = Partial<Record<DefaultingScope, DefaultingMode>>;
export interface DefaultingRequest {
  rfq: OnyxRfq;
  scopeModeMap: ScopeModeMap;
}

export const createDefaultNegotiationApi = (http: SgmeHttp): DefaultRfqApi => ({
  defaultRfq(
    rfq: OnyxRfq,
    { overrideScopes, enrichScopes, overrideIfInvalidScopes }: RequiredDefaultingOptionsParameters,
  ): Observable<OnyxRfq> {
    const { uuid } = rfq;

    const scopeModeMap: ScopeModeMap = {
      ...convertScopesArrayToRecord(overrideScopes, 'OVERRIDE'),
      ...convertScopesArrayToRecord(enrichScopes, 'ENRICH'),
      ...convertScopesArrayToRecord(overrideIfInvalidScopes, 'OVERRIDE_IF_INVALID'),
    };

    const model: DefaultingRequest = {
      rfq,
      scopeModeMap,
    };

    const url = `api/rfq/defaultRfq/${uuid}`;
    return http.post<OnyxRfq>({
      url,
      body: model,
    });
  },
});

export function convertScopesArrayToRecord(
  scopes: DefaultingScope[],
  defaultingMode: DefaultingMode,
): ScopeModeMap {
  return scopes.reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: defaultingMode,
    }),
    {},
  );
}
