import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { getCcyPremiumModel } from '@/neos/components/rfq/totals/ccyPremium/getCcyPremiumModel';
import { NumericInput } from '@/neos/components/share/numericInput';
import { Fragment } from 'react';
import type { BidAskDisplay } from '../Totals';
import styles from './CcyPremium.module.scss';

export interface CcyPremiumProps {
  rfqId: string;
}

export interface CcyPremiumModel {
  total: BidAskValues;
  aggBidAsk: Array<BidAskValues & BidAskDisplay>;
  isDisplay: boolean;
}

interface BidAskValues {
  unit: string | undefined;
  bid: number | undefined;
  ask: number | undefined;
}

export function CcyPremium(props: CcyPremiumProps) {
  const { total, aggBidAsk, isDisplay } = useAppSelector(state =>
    getCcyPremiumModel(state, props.rfqId, selectors),
  );

  const isTotalDisplayed = total.unit && aggBidAsk.length > 1;

  if (!isDisplay) {
    return null;
  }

  return (
    <div className="d-flex flex-column align-items-center px-1">
      <label className="fw-bold">CCY Premium</label>
      <div
        style={{
          gridTemplateColumns: `${isTotalDisplayed ? '40px' : ''} repeat(2, minmax(110px, 250px))`,
        }}
        className={`${styles['ccy-premium']} gap-1 align-items-center`}
      >
        {isTotalDisplayed && <div></div>}
        <label>Bid</label>
        <label>Ask</label>

        {isTotalDisplayed && <div>Total</div>}
        <NumericInput
          color="BID"
          inputClassName="fw-bold"
          unit={total.unit}
          value={total.bid}
          withMaximumNumberOfFloatingDigits={0}
          readOnly
        />
        <NumericInput
          color="ASK"
          inputClassName="fw-bold"
          value={total.ask}
          withMaximumNumberOfFloatingDigits={0}
          readOnly
        />
        {aggBidAsk.length > 1 &&
          aggBidAsk.map(aggBidAsk => (
            <Fragment key={aggBidAsk.unit}>
              <div></div>
              <NumericInput
                unit={aggBidAsk.unit}
                className={aggBidAsk.bidClassName}
                value={aggBidAsk.bid}
                withMaximumNumberOfFloatingDigits={0}
                readOnly
              />
              <NumericInput
                className={aggBidAsk.askClassName}
                value={aggBidAsk.ask}
                withMaximumNumberOfFloatingDigits={0}
                readOnly
              />
            </Fragment>
          ))}
      </div>
    </div>
  );
}
