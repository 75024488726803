import { DefaultingOptions, type StrategiesFilterer } from '../rfqActions';

import type { ChainOptions, RetryOnErrorOptions } from '@/neos/business/neosActions';
import { DefaultingScope } from '../models';
import { createDefaultRfqRequestedAction } from './defaultRfqRequestedAction';

export function createDefaultTopRequestedAction(
  rfqId: string,
  strategyIds: string[],
  chainOptions?: ChainOptions,
) {
  const strategiesFilterer: StrategiesFilterer = strategies =>
    strategies.filter(s => strategyIds.includes(s.uuid));
  const overrideScopes = [
    DefaultingScope.TRADER_DIFFUSION,
    DefaultingScope.UNDERLYING,
    DefaultingScope.NEGOTIATED_SIZE,
    DefaultingScope.NOTIONAL,
    DefaultingScope.PIVOT_CURRENCY,
    DefaultingScope.UNITS,
    DefaultingScope.EXPECTED_N,
    DefaultingScope.REFERENCE_MATURITY,
    DefaultingScope.DISPERSION_LEGS,
    DefaultingScope.CAP,
  ];

  const enrichScopes = [
    DefaultingScope.FOREX,
    DefaultingScope.REFERENCE,
    DefaultingScope.SPOT_NET,
    DefaultingScope.SALES_DIFFUSION,
    DefaultingScope.TRADE_DATE,
  ];
  const options = new DefaultingOptions({ overrideScopes, enrichScopes });
  options.strategiesFilterer = strategiesFilterer;
  const retryOptions: RetryOnErrorOptions = {
    retries: 1,
  };
  return createDefaultRfqRequestedAction(rfqId, options, chainOptions, retryOptions);
}
