import type { OnyxRfq } from '@/neos/business/rfq/rfqOnyxModel';
import { dateStringToIsoString } from '@/util/date/dateFormatHelper';

export function getBlotterCreationTime({ lifecycle: { creationTime } }: OnyxRfq) {
  return creationTime ? new Date(dateStringToIsoString(creationTime)) : undefined;
}

export function getBlotterUpdateTime({ lifecycle: { updateTime } }: OnyxRfq) {
  return updateTime ? new Date(dateStringToIsoString(updateTime)) : undefined;
}

export function getBlotterTradeDate({ tradeDate }: OnyxRfq) {
  return tradeDate ? new Date(dateStringToIsoString(tradeDate)) : undefined;
}
