import { actorsSetupDomain } from './actorsSetupDomain';
import { contactActioncreators } from './contact/contactActionCreators';
import { counterpartActioncreators } from './counterpart/counterpartActionCreators';

const actorsSetupCrudActions = actorsSetupDomain.actions;
export const actorsSetupActionCreators = {
  actorsSetupCrudActions,
  ...counterpartActioncreators,
  ...contactActioncreators,
};
