export type OnyxUserCategory =
  | SalesOrAssociatedUser
  | 'TRADER'
  | 'ADMIN'
  | 'MIDDLE_OP'
  | 'VIEW_ONLY'
  | 'MIDDLE_SA';

export type SalesOrAssociatedUser = 'SALES' | 'SALES_MANAGER' | 'ADMIN';

export const ALL_FEATURE_TOGGLES = [
  'neos.els.lend-and-borrow',
  'neos.els.basket.enabled',
  'neos.rfq.notification.diff.enabled',
  'onyx.workflow.norecompute.after.priceproposed',
  'neos.error.technical.details.enabled',
  'neos.alert.blotter.enabled',
  'neos.rfq.dispersion.enabled',
  'neos.rfq.localNotional.enabled',
  'neos.workflow.delta.stock.auto.enabled',
  'neos.rfq.basis.enabled',
  'neos.workflow.confirm.delta.execution.enabled',
  'neos.rfq.tradeRecap.groupMailing.enabled',
  'neos.rfq.giveUpEmail.enabled',
  'neos.rfq.giveUpEmailFuture.enable',
  'neos.rfq.delta.mixed.enabled',
  'neos.workflow.flex.option.enabled',
  'neos.allocs.enabled',
  'neos.otc.allocs.enabled',
  'options.futures.enabled',
  'neos.preconfirmation.blotter.enabled',
  'neos.els.enabled',
  'neos.els.strike.date',
  'neos.cls.settlement.value.date.enabled',
  'neos.els.scope.Interest.Rate.Index.enabled',
  'neos.manually.booking.sc',
  'neos.commission.enabled',
  'neos.els.effectiveDate.enabled',
  'neos.umr.pdc.enabled',
  'neos.otc.preconf.enabled',
  'neos.allocs.clearerfield.enabled',
  'neos.future.executedspot.enabled',
  'neos.matching.criteria.enabled',
  'neos.matching.futures.enabled',
  'neos.rfq.giveUpEmailDivFut.enable',
  'debug.trader.notification',
  'neos.secondary.enabled',
  'neos.rfq.total.return.future.enabled',
  'neos.search.by.booking.id',
  'neos.rfq.pricingDeadline.enabled',
  'neos.secondary.from.xone',
  'neos.secondary.product.els',
  'neos.secondary.product.cls',
  'neos.secondary.product.varvolswaps',
  'neos.secondary.product.options',
  'neos.user.preferences.counterparties',
  'neos.user.preferences.defaulting',
  'neos.user.preferences.broker',
  'neos.els.basket.secondary',
  'neos.browser.notification.image.enabled',
  'neos.buy.and.sell.add.disabled',
] as const;

export type OnyxFeatureToggleKey = (typeof ALL_FEATURE_TOGGLES)[number];

export interface OnyxCurrentUser {
  login: string;
  email: string;
  sesameId: string;
  lastname: string;
  firstname: string;
  groupId: number | undefined;
  diffusionGroups: number[];
  categories: OnyxUserCategory[];
}

export interface UserInfo {
  currentUser: OnyxCurrentUser;
  toggleFeatures: OnyxFeatureToggle[];
}

export interface OnyxFeatureToggle {
  name: OnyxFeatureToggleKey;
  enable: boolean;
}
