import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import type { DeltaType } from '../../../../../../neos/business/neosModel';
import {
  HedgeComponent,
  type HedgeDispatchProps,
  type HedgeMapStateToProps,
  type HedgeOwnProps,
} from './Hedge';
import { type UiDeltaType, getHedgeModel } from './getHedgeModel';

export const Hedge = connect(mapStateToProps, mapDispatchToProps)(HedgeComponent);

function mapStateToProps(state: AppState, { rfqId }: HedgeOwnProps): HedgeMapStateToProps {
  return getHedgeModel(state, rfqId, selectors);
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId }: HedgeOwnProps,
): HedgeDispatchProps {
  return {
    onDeltaChanged: (
      underlyingId: string,
      deltaType: DeltaType | undefined,
      delta: number | undefined,
    ) => {
      dispatch(
        neosThunks.createRfqUpdateDeltaValueThunk({ rfqId, underlyingId, deltaType }, delta),
      );
    },
    onDeltaTypeChanged: (underlyingId: string, deltaType: UiDeltaType) => {
      dispatch(
        deltaType === 'DELTA_MIXED'
          ? neosThunks.createSetHedgeMixedThunk(rfqId, underlyingId)
          : neosThunks.createRfqUpdateDeltaTypeThunk({ rfqId, underlyingId, deltaType }),
      );
    },
  };
}
