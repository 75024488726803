import { actionCreators } from '@/bootstrap/actions';
import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { NeosBlurInput } from '@/neos/components/share/blurComponent/NeosBlurInput';
import { useDispatch, useSelector } from 'react-redux';

interface SwapsWireAccountProps {
  rfqId: string;
}

export function SwapsWireAccount({ rfqId }: SwapsWireAccountProps) {
  const dispatch = useDispatch();
  const { swapsWireSgTraderAccount, uuid } = useSelector((state: AppState) =>
    selectors.getRfqMasterStrategy(state, rfqId),
  );
  const isTrader = useSelector((state: AppState) => selectors.isTrader(state));

  function onSwapsWireAccountChanged(swapsWireSgTraderAccount: string | undefined) {
    dispatch(
      actionCreators.neos.strategyDataCrudActions.update(uuid, {
        swapsWireSgTraderAccount,
      }),
    );
  }

  return (
    <div data-testid="SwapsWireAccount">
      <label>SG Trader</label>
      <NeosBlurInput
        readOnly={!isTrader}
        className="input-sm select-inline"
        value={swapsWireSgTraderAccount}
        onBlur={event => onSwapsWireAccountChanged(event.target.value || undefined)}
      />
    </div>
  );
}
