import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';

export interface ElsRefSpotNetModel {
  value: number | undefined;
  unit: string | undefined;
}

export function getElsRefSpotNetModel(
  state: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): ElsRefSpotNetModel | undefined {
  const underlyingId = selectors.getStrategyUnderlyingId(state, strategyId, selectors);
  const isElsStrategy = selectors.isElsStrategy(state, strategyId, selectors);

  if (!isElsStrategy || !underlyingId) {
    return undefined;
  }

  const reference = selectors.getReference(state, { rfqId, underlyingId });

  return {
    value: reference?.refSpotNet,
    unit: reference?.refSpotNetUnit,
  };
}
