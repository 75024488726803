import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';

export function getWorkflowHasAtLeastOneMatchedBookingId(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): boolean {
  const rfqBookingStepsInfo = selectors.selectAllBookingSteps(state, { rfqId });
  const executions = selectors.executionSelectors.selectObjects(state.execution, { rfqId });
  const rfqExecutionBookingIds = executions.map(execution =>
    selectors.getExecutionBookingStep(state, rfqId, execution.uuid),
  );

  return (
    (executions.length > 0 &&
      executions.some(execution => execution.bookingStatus === 'MATCHED')) ||
    (rfqBookingStepsInfo.length > 0 &&
      rfqBookingStepsInfo.some(bookingStepInfo => bookingStepInfo.status === 'MATCHED')) ||
    (rfqExecutionBookingIds.length > 0 &&
      rfqExecutionBookingIds.some(bookingStepInfo => bookingStepInfo?.status === 'MATCHED'))
  );
}
