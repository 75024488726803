import type { Thunk } from '@/bootstrap/thunks';
import type { OnyxRfq } from '@/neos/business/rfq/rfqOnyxModel';

export function createNeosIntegrateRfqNotificationThunk(
  rfqId: string,
  onyxNotificationRfq: OnyxRfq,
): Thunk {
  return function neosIntegrateRfqNotificationThunk(
    dispatch,
    getState,
    {
      thunks: {
        neos: {
          createNeosLoadNotificationRfqThunk,
          createNeosDisplayRefWarningThunk,
          createNeosIntegrateRfqDeltaDifferenceNotificationThunk,
          createNeosIntegrateRfqTraderQuotesDifferenceNotificationThunk,
        },
      },
      actionCreators: {
        neos: { nestedRfqsCrudActions },
      },
      selectors,
      toMappers,
    },
  ) {
    const appState = getState();
    const { getLastSavedRfqStatusComparedToCurrentOne, getLastSavedRfqVersion, tabExists } =
      selectors;

    if (!tabExists(appState.ui.tabManagement, rfqId)) {
      return;
    }
    const latestVersion = getLastSavedRfqVersion(appState, rfqId);

    if (latestVersion && latestVersion >= onyxNotificationRfq.version) {
      return;
    }

    const currentOnyxRfq = toMappers.mapToOnyxRfqWithCurrentLocalVersion(
      appState,
      rfqId,
      selectors,
      toMappers,
    );

    const lastSavedRfqStatus = getLastSavedRfqStatusComparedToCurrentOne(
      appState,
      rfqId,
      currentOnyxRfq,
    );

    dispatch(createNeosDisplayRefWarningThunk(rfqId, onyxNotificationRfq));

    dispatch(createNeosIntegrateRfqDeltaDifferenceNotificationThunk(rfqId, onyxNotificationRfq));
    dispatch(
      createNeosIntegrateRfqTraderQuotesDifferenceNotificationThunk(rfqId, onyxNotificationRfq),
    );

    switch (lastSavedRfqStatus) {
      case 'PENDING':
      case 'DIFFERENT_FROM_CURRENT':
        return dispatch(
          nestedRfqsCrudActions.update(rfqId, {
            notificationRfq: {
              isThereNotificationRfq: true,
              lastNotificationRfq: onyxNotificationRfq,
            },
          }),
        );
      case 'SAME_AS_CURRENT':
        return dispatch(createNeosLoadNotificationRfqThunk(rfqId, onyxNotificationRfq));
    }
  };
}
