import { differenceInMilliseconds } from 'date-fns';
import { toDate } from 'date-fns-tz';
import { useEffect, useMemo, useState } from 'react';

interface NeosTimer {
  remainingTime: number;
}

type NeosTimerWithDuration = NeosTimer & {
  duration: number;
};

export function useTimer(expiry: string): NeosTimer {
  const remainingTime = useTimerBase(expiry);
  return { remainingTime };
}

export function useTimerWithDuration(expiry: string, creationTime: string): NeosTimerWithDuration {
  const expiryDate = toDate(expiry, { timeZone: 'UTC' });
  const creationTimeDate = toDate(creationTime, { timeZone: 'UTC' });
  const duration = differenceInMilliseconds(expiryDate, creationTimeDate);

  const remainingTime = useTimerBase(expiry);

  return { remainingTime, duration };
}

function useTimerBase(expiry: string) {
  const expiryDate = toDate(expiry, { timeZone: 'UTC' });
  const [remainingTime, setRemainingTime] = useState<number | undefined>(
    differenceInMilliseconds(expiryDate, new Date()),
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const newRemainingTime = Math.max(getDifferenceWithNowInMms(expiryDate), 0);

      setRemainingTime(newRemainingTime);

      if (newRemainingTime === 0) {
        clearInterval(interval);
      }
    }, 250);

    return () => clearInterval(interval);
  }, [expiryDate]);

  return remainingTime ?? 0;
}

export function useTimerWithAutomaticDuration(expiry?: string): NeosTimerWithDuration {
  const expiryDate = useMemo(
    () => (expiry ? toDate(expiry, { timeZone: 'UTC' }) : new Date()),
    [expiry],
  );

  const [duration, setDuration] = useState(getDifferenceWithNowInMms(expiryDate));

  const [remainingTime, setRemainingTime] = useState<number>(duration);

  useEffect(() => {
    const interval = setInterval(() => {
      const newRemainingTime = Math.max(getDifferenceWithNowInMms(expiryDate), 0);

      setRemainingTime(newRemainingTime);

      if (newRemainingTime === 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [expiryDate]);

  useEffect(() => {
    if (expiry) {
      setDuration(getDifferenceWithNowInMms(expiryDate));
    }
  }, [expiry, expiryDate]);

  return { remainingTime, duration };
}

function getDifferenceWithNowInMms(date: Date): number {
  const diff = differenceInMilliseconds(date, new Date());
  return diff < 0 ? 0 : diff;
}
