import type { Dispatchable, Thunk } from '@/bootstrap/thunks.ts';
import { isClsProduct } from '../../../../../../neosModel.ts';
import { ElsScheduleRecomputeScope } from '../../../../../models/recomputeModel.ts';
import type { LegPeriodDates } from '../../elsProductOnyxModel.ts';

export function createChangeScheduleStartOrEndDateThunk(
  rfqId: string,
  strategyId: string,
  date: Partial<LegPeriodDates>,
  dispatchable: Dispatchable,
): Thunk {
  return function changeScheduleStartOrEndDate(
    dispatch,
    getState,
    {
      thunks: {
        neos: { createRecomputeRfqThunk },
      },
      selectors,
    },
  ) {
    const state = getState();
    const product = selectors.getStrategyMasterProduct(state, strategyId, selectors);

    if (isClsProduct(product)) {
      return dispatch(dispatchable);
    }

    const dispatchables: Dispatchable[] = [dispatchable];

    const startDateHasChanged = date.startDate !== undefined;
    const endDateHasChanged = date.endDate !== undefined;

    if (startDateHasChanged || endDateHasChanged) {
      if (startDateHasChanged) {
        dispatchables.push(
          createRecomputeRfqThunk(
            rfqId,
            ElsScheduleRecomputeScope.CHANGE_START_DATE_SCHEDULE_PERIOD,
            dispatchable,
          ),
        );
      }

      if (endDateHasChanged) {
        dispatchables.push(
          createRecomputeRfqThunk(
            rfqId,
            ElsScheduleRecomputeScope.CHANGE_END_DATE_SCHEDULE_PERIOD,
            dispatchable,
          ),
        );
      }
    }

    return dispatch(dispatchables);
  };
}
