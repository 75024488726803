import { FlowDatePicker } from '@/neos/components/share/datePicker';
import { ErrorHighlight } from '@/neos/components/share/errorHighlight';
import type { FC } from 'react';
import type { MaturityCellsModel } from './getMaturityCellsModel';

import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import styles from './MaturityCells.module.scss';

export interface MaturityCellsOwnProps {
  rfqId: string;
  strategyId: string;
  isReadOnlyRfq: boolean;
}

export type MaturityCellsMapStateProps = MaturityCellsModel;

export interface MaturityCellsDispatchProps {
  onMaturityChanged(legId: string, maturity: string | undefined): void;

  onTenorChanged(legId: string, tenor: string | undefined): void;
}

type MaturityCellsProps = MaturityCellsOwnProps &
  MaturityCellsMapStateProps &
  MaturityCellsDispatchProps;

export const MaturityCellsComponent: FC<MaturityCellsProps> = props => {
  return (
    <section>
      {props.maturities.map(({ id, value, isEditable, isValid }, index) => (
        <ErrorHighlight
          key={id}
          errorField={'maturity'}
          related={{ rfqId: props.rfqId, legId: id, strategyId: props.strategyId }}
        >
          {props.isRfqSecondaryWidgetToggleOn ? (
            isValid ? (
              <FlowDatePicker
                className={`${styles['maturity']}`}
                readOnly={!isEditable || props.isReadOnlyRfq}
                onChange={val => props.onMaturityChanged(id, val)}
                onChangeTenor={val => props.onTenorChanged(id, val)}
                maturities={props.availableMaturities[index]}
                date={value || ''}
                tenors={props.tenors}
                maturitiesCategory={
                  props.isElsOrCls || props.availableMaturities[index].length === 0
                    ? 'TENORS'
                    : 'LISTED'
                }
                data-e2e="neos-strategy-definition-maturity"
                hideCalendarIcon
              />
            ) : (
              <SimpleNeosTooltip
                key={props.strategyId}
                type="warning"
                message={props.maturityWarningText}
                id={props.strategyId}
              >
                <FlowDatePicker
                  className={`${styles['maturity']} ${isValid ? '' : 'field-warning'}`}
                  readOnly={!isEditable || props.isReadOnlyRfq}
                  onChange={val => props.onMaturityChanged(id, val)}
                  onChangeTenor={val => props.onTenorChanged(id, val)}
                  maturities={props.availableMaturities[index]}
                  date={value || ''}
                  tenors={props.tenors}
                  maturitiesCategory={
                    props.isElsOrCls || props.availableMaturities[index].length === 0
                      ? 'TENORS'
                      : 'LISTED'
                  }
                  data-e2e="neos-strategy-definition-maturity"
                  hideCalendarIcon
                />
              </SimpleNeosTooltip>
            )
          ) : (
            <FlowDatePicker
              className={`${styles['maturity']}`}
              readOnly={!isEditable || props.isReadOnlyRfq}
              onChange={val => props.onMaturityChanged(id, val)}
              onChangeTenor={val => props.onTenorChanged(id, val)}
              maturities={props.availableMaturities[index]}
              date={value || ''}
              tenors={props.tenors}
              maturitiesCategory={
                props.isElsOrCls || props.availableMaturities[index].length === 0
                  ? 'TENORS'
                  : 'LISTED'
              }
              data-e2e="neos-strategy-definition-maturity"
              hideCalendarIcon
            />
          )}
        </ErrorHighlight>
      ))}
    </section>
  );
};
