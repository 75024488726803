import type { CustomizableQuickButtonToggle } from '@/neos/business/ui/userPreferences/userPreferencesUiModel';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';

export interface Props {
  toggle: CustomizableQuickButtonToggle;
  name: string;
  switchToggle: (toggle: CustomizableQuickButtonToggle) => void;
}

export const LocationPreferenceToggle = ({ toggle, name, switchToggle }: Props) => (
  <ToggleButtonGroup
    name={`${name}-columnDisplay`}
    type="radio"
    onChange={switchToggle}
    value={toggle}
    className="btn-group-toggle"
  >
    <ToggleButton
      data-e2e="neos-user-preferences-menu-rfqtab-configButton-EUROPE"
      variant="toggle-primary"
      value={'EUROPE'}
      id={`${name}-EUROPE`}
    >
      Europe
    </ToggleButton>
    <ToggleButton
      data-e2e="neos-user-preferences-menu-rfqtab-configButton-AMER"
      variant="toggle-primary"
      value={'AMER'}
      id={`${name}-AMER`}
    >
      Amer
    </ToggleButton>
    <ToggleButton
      data-e2e="neos-user-preferences-menu-rfqtab-configButton-ASIA"
      variant="toggle-primary"
      value={'ASIA'}
      id={`${name}-ASIA`}
    >
      Asia
    </ToggleButton>
    <ToggleButton
      data-e2e="neos-user-preferences-menu-rfqtab-configButton-CUSTOM"
      variant="toggle-primary"
      value={'CUSTOM'}
      id={`${name}-CUSTOM`}
    >
      Custom
    </ToggleButton>
  </ToggleButtonGroup>
);
