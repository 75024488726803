import type { Thunk } from '@/bootstrap/thunks';
import { getDisplayedValueAndLabelBaseOnSizeType } from '@/neos/components/rfq/postNego/allocs/allocInfo/AllocationLegSize';
import { sum } from 'lodash';
import type { Execution } from '../executionModel';

export function createAddExecutionThunk(rfqId: string, legId: string, orderId: string): Thunk {
  return function addExecutionThunk(dispatch, getState, { selectors, actionCreators, services }) {
    const state = getState();
    const unit = selectors.getOrder(state.orderData, orderId)?.price.unit;

    const executionId = services.idCreator.createId();
    const strategyId = selectors.getStrategyIdByLegId(state, legId);
    const maxSize = getDisplayedValueAndLabelBaseOnSizeType(state, strategyId, legId, selectors);

    const executions = selectors.executionSelectors.selectObjects(state.execution, {
      orderId,
    });

    dispatch(
      actionCreators.neos.executionCrudActions.insert(
        {
          rfqId,
          legId,
          executionId,
          orderId,
        },
        {
          lastPrice: {
            type: 'CCY',
            unit,
            value: undefined,
          },
          size: {
            numberOfLots: maxSize?.value
              ? calculateNextExecSize(maxSize.value, executions)
              : undefined,
            quantity: undefined,
            sizeType: 'FIXED_QUANTITY',
          },
          executionTime: executions[0]?.executionTime ?? undefined,
          tradeDate: undefined,
          uuid: executionId,
          externalExecId: {
            application: 'XONE',
            id: undefined,
          },
          cashFlowId: {
            application: 'XONE',
            id: undefined,
          },
        },
      ),
    );
  };
}

export function calculateNextExecSize(
  maxSize: number,
  executions: Array<Execution>,
): number | undefined {
  const currentSum = sum(executions.map(ex => ex?.size?.numberOfLots ?? 0));
  return maxSize > currentSum ? maxSize - currentSum : undefined;
}
