import { actionCreators } from '@/bootstrap/actions';
import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { ElsFeaturesContext } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures';
import { ElsBlocField } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/ElsBlocField';
import { NumericInput } from '@/neos/components/share/numericInput';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { isElsProduct } from '../../../../../../../../neos/business/neosModel';

export function ElectionFee() {
  const { strategyId } = useContext(ElsFeaturesContext);
  const dispatch = useDispatch();
  const product = useAppSelector(state =>
    selectors.getStrategyProduct(state, strategyId, selectors),
  );
  if (!isElsProduct(product)) {
    return null;
  }
  const { uuid, electionFee } = product;
  const { update: updateProduct } = actionCreators.neos.productCrudActions;

  const onElectionFeeChange = (newElectionFee: number | undefined) => {
    dispatch(updateProduct(uuid, { electionFee: newElectionFee }));
  };

  return (
    <ElsBlocField
      label="Election Fee"
      renderInput={readOnly => (
        <NumericInput
          readOnly={readOnly}
          style={{ width: '70px' }}
          unit="%"
          value={electionFee}
          onBlur={val => onElectionFeeChange(val)}
          data-e2e="els-section-equity-election-fee"
        />
      )}
    />
  );
}
