import type { ChainOptions } from '@/neos/business/neosActions';
import { brokersActionCreators } from '@/neos/business/referenceData/brokers/brokersAction';
import { relatedExchangeActionCreator } from '@/neos/business/referenceData/relatedExchange/relatedExchangeActionCreator';
import type { RelatedExchange } from '@/neos/business/referenceData/relatedExchange/relatedExchangeModel';
import type {
  Counterpart,
  Currency,
  PredealCheckAvailableDerogation,
  SalesGroup,
  StrategyConfiguration,
  Tenor,
  TraderGroup,
  User,
} from '../../../neos/business/neosModel';
import type { OnyxStrategyDefinition } from '../../../neos/business/neosOnyxModel';
import type { PredealCheckType } from '../predealCheck/predealCheckModel';
import { counterpartActionCreators } from './counterpart/counterpartActionCreators';
import { currencyActionCreators } from './currency/currencyActionCreators';
import { curvesListActionCreators } from './curvesList/curvesListActionCreators';
import { marketsActionCreators } from './markets/marketsActionCreators';
import type { OnyxMarket, OnyxMarketEliots } from './markets/marketsModel';
import { predealChecksDerogationActionCreators } from './predealChecksDerogations/predealChecksDerogationActionCreators';
import { primeBrokerActionCreators } from './primeBroker/primeBrokerActions';
import type {
  ReferenceDataReceivedAction,
  ReferenceDataRequestedAction,
} from './referenceDataActions';
import { salesGroupsActionCreators } from './salesGroups/salesGroupsActionCreators';
import { salesLocationsActionCreators } from './salesLocation/salesLocationsActionCreators';
import type { SalesLocations } from './salesLocation/salesLocationsModel';
import { salesValoActionCreators } from './salesValo/salesValoActionCreators';
import { strategyConfigurationActionCreators } from './strategyConfiguration/strategyConfigurationActionCreators';
import { mapFromOnyxStrategyDefinitions } from './strategyDefinition/mappers/strategyDefinitionMapper';
import { strategyActionCreators } from './strategyDefinition/strategyDefinitionActionCreators';
import { tenorActionCreators } from './tenors/tenorActionCreators';
import { tradersActionCreators } from './trader/traderActionCreators';
import { traderGroupsActionCreators } from './traderGroups/traderGroupsActionCreators';
import { underlyingActionCreators } from './underlying/underlyingActionCreators';

export const referenceDataActionCreators = {
  ...salesValoActionCreators,
  ...traderGroupsActionCreators,
  ...salesGroupsActionCreators,
  ...tradersActionCreators,
  ...currencyActionCreators,
  ...strategyActionCreators,
  ...predealChecksDerogationActionCreators,
  ...strategyConfigurationActionCreators,
  ...salesLocationsActionCreators,
  ...tenorActionCreators,
  ...underlyingActionCreators,
  ...marketsActionCreators,
  ...curvesListActionCreators,
  ...primeBrokerActionCreators,
  ...relatedExchangeActionCreator,
  ...counterpartActionCreators,
  ...brokersActionCreators,
  createReferenceDataRequested,
  createReferenceDataReceivedAction,
  createReferenceDataFetchedAction,
};

function createReferenceDataRequested(
  tabId: 'BLOTTER' | string,
  sesameId: string,
  chainOptions?: ChainOptions,
): ReferenceDataRequestedAction {
  return { type: 'REFERENCE_DATA_REQUESTED_ACTION', tabId, sesameId, chainOptions };
}

function createReferenceDataReceivedAction(): ReferenceDataReceivedAction {
  return { type: 'REFERENCE_DATA_RECEIVED_ACTION' };
}

function createReferenceDataFetchedAction(
  currencies: Currency[],
  predealChecksDerogations: Record<PredealCheckType, PredealCheckAvailableDerogation[]>,
  salesGroups: SalesGroup[],
  salesValos: User[],
  strategyDefinitions: OnyxStrategyDefinition[],
  strategyConfiguration: StrategyConfiguration,
  traderGroups: TraderGroup[],
  traders: User[],
  salesLocations: SalesLocations[],
  tenors: Tenor[],
  counterparts: Counterpart[],
  markets: OnyxMarket[],
  relatedExchangesFields: RelatedExchange[],
  marketsEliot: OnyxMarketEliots,
) {
  return [
    currencyActionCreators.createCurrenciesReceivedAction(currencies),
    predealChecksDerogationActionCreators.createPredealChecksDerogationsReceivedAction(
      predealChecksDerogations,
    ),
    salesGroupsActionCreators.salesGroupsReceived(salesGroups),
    salesValoActionCreators.createSalesValosReceivedAction(salesValos),
    strategyActionCreators.createStrategiesDefinitionsReceivedAction(
      mapFromOnyxStrategyDefinitions(strategyDefinitions),
    ),
    strategyConfigurationActionCreators.createStrategyConfigurationReceivedAction(
      strategyConfiguration,
    ),
    traderGroupsActionCreators.traderGroupsReceived(traderGroups),
    tradersActionCreators.createTradersReceivedAction(traders),
    salesLocationsActionCreators.createSalesLocationReceivedAction(salesLocations),
    tenorActionCreators.createTenorReceivedAction(tenors),
    counterpartActionCreators.createCounterpartReceivedAction(counterparts),
    marketsActionCreators.createMarketsReceivedAction(markets),
    marketsActionCreators.createMarketsEliotReceivedAction(marketsEliot),
    relatedExchangeActionCreator.createRelatedExchangeFieldsAction(relatedExchangesFields),
    createReferenceDataReceivedAction(),
  ];
}
