import type { AgGridEvent, GridApi } from '@ag-grid-community/core';
import type { GridApiExtended } from './gridApiExtended';
import type { GridStateApiManager } from './gridStateApiManager';
import { GridStateSanitizer } from './gridStateSanitizer';
import type { GridStateStorageManager } from './gridStateStorageManager';

export class GridStateStorageAdapter {
  constructor(
    private storageManager: GridStateStorageManager,
    private createGridApiExtended: (event: AgGridEvent) => GridApiExtended,
    private getGridStateApiManager: (api: GridApiExtended) => GridStateApiManager,
    private gridStateSanitizer: GridStateSanitizer = new GridStateSanitizer(),
  ) {}

  public gridApi: GridApi | undefined;

  public saveGridState = (event: AgGridEvent): void => {
    const stateApiManager = this.getGridStateApiManager(this.createGridApiExtended(event));

    const state = stateApiManager.getGridState();
    this.storageManager.saveGridState(state);
  };

  public loadAndApplyGridState = (event: AgGridEvent): void => {
    this.gridApi = event.api;
    const apiEx = this.createGridApiExtended(event);
    const stateApiManager = this.getGridStateApiManager(apiEx);

    const state = this.storageManager.getGridState();

    const sanitized = this.gridStateSanitizer.sanitize(apiEx, state);

    stateApiManager.applyGridState(sanitized);
  };
}
