import type { Thunk } from '@/bootstrap/thunks';
import type { BlotterPosition } from '../blotterUiModel';

export function createToggleBlotterPinThunk(position: BlotterPosition): Thunk {
  return function toggleBlotterPinThunk(
    dispatch,
    getState,
    {
      selectors: { isBlotterPinned, getSelectedTab, getLastSelectedTabId },
      actionCreators: {
        neos: { createChangeBlotterPositionAction, createTabSelectedAction },
      },
    },
  ) {
    dispatch(createChangeBlotterPositionAction(position));
    const appState = getState();
    const blotterPinned = isBlotterPinned(appState.ui.blotter);
    const selectedTab = getSelectedTab(appState.ui.tabManagement);
    const lastSelectedTabId = getLastSelectedTabId(appState.ui.tabManagement);

    if (blotterPinned && lastSelectedTabId && selectedTab === 'BLOTTER') {
      dispatch(createTabSelectedAction(lastSelectedTabId));
    }
  };
}
