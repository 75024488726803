import type { ChangeEvent } from 'react';
import { NeosBlurTextarea } from '../../../../../share/blurComponent/NeosBlurTextarea';
import { ErrorHighlight } from '../../../../../share/errorHighlight';
import { CloseButton } from '../closeButton';

import { If } from '@/neos/components/share/if/if';
import styles from './Description.module.scss';

export interface DescriptionOwnProps {
  rfqId: string;
  strategyId: string;
}

export interface DescriptionStateProps {
  description: string | undefined;
  isDisplayed: boolean;
  isReadonly: boolean;
}

export interface DescriptionDispatchProps {
  onDescriptionChanged: (description: string) => void;
}

type DescriptionProps = DescriptionOwnProps & DescriptionStateProps & DescriptionDispatchProps;

export const DescriptionStyled = (descriptionProps: DescriptionProps) => {
  const { description, onDescriptionChanged, rfqId, strategyId, isDisplayed, isReadonly } =
    descriptionProps;
  return (
    <div className={`${styles['description-position']}`}>
      <If condition={isDisplayed}>
        <div className="d-flex mb-1 ms-1 border p-1 fit-content">
          <div className="d-grid w-100 gap-1" style={{ gridTemplateColumns: '1fr' }}>
            <span className="fw-bold">Description</span>
            <ErrorHighlight errorField={'description'} related={{ rfqId, strategyId }}>
              <NeosBlurTextarea
                readOnly={isReadonly}
                className="form-control"
                value={description || ''}
                onChange={(ev: ChangeEvent<HTMLTextAreaElement>) =>
                  onDescriptionChanged(ev.target.value)
                }
              />
            </ErrorHighlight>
          </div>
          <CloseButton
            strategyId={strategyId}
            type={'DESCRIPTION'}
            data-e2e="feature-description-close-button"
          />
        </div>
      </If>
    </div>
  );
};
