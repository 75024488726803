export function legMaturityComparator(legMaturity1: string, legMaturity2: string) {
  const regex = /\d{2}[a-zA-z]{3}\d{4}/;

  const result1 = legMaturity1?.match(regex);
  const result2 = legMaturity2?.match(regex);

  const date1 = result1 ? result1[0] : legMaturity1;
  const date2 = result2 ? result2[0] : legMaturity2;

  return dateComparator(date1, date2);
}

function dateComparator(date1: string, date2: string): number {
  if (!date1 && !date2) {
    return 0;
  }
  if (!date1) {
    return -1;
  }
  if (!date2) {
    return 1;
  }
  return new Date(date1).valueOf() - new Date(date2).valueOf();
}
