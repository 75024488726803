import type { Thunk } from '@/bootstrap/thunks';
import { DefaultingScope } from '../../../neosModel';
import { DefaultingOptions } from '../../rfqActions';

export function createUpdateSettlementDateOffsetThunk(
  rfqId: string,
  legId: string,
  offset: number,
): Thunk {
  return function updateSettlementDateOffsetThunk(
    dispatch,
    getState,
    { selectors, actionCreators: { neos: neosActionsCreators }, thunks },
  ) {
    const appState = getState();
    const legData = selectors.getLegData(appState, legId);
    const settlementData = legData?.settlement;
    if (settlementData) {
      const newSettlementData = {
        ...settlementData,
        date: { value: undefined, offset },
      };

      const isEls = selectors.isElsStrategy(appState, legData.strategyId, selectors);
      const isCls = selectors.isClsStrategy(appState, legData.strategyId, selectors);

      const overrideScopes: DefaultingScope[] = [];

      if (isEls || isCls) {
        overrideScopes.push(DefaultingScope.FIXED_DAY_TYPE);
      }
      if (isEls) {
        overrideScopes.push(DefaultingScope.DIVIDEND_PAYMENT_DATE);
      }
      const defaultingOptions = new DefaultingOptions({
        enrichScopes: [DefaultingScope.SETTLEMENT_OTC],
        overrideScopes,
      });

      const productId = legData.productId;

      dispatch(
        neosActionsCreators.legDataCrudActions.update(legId, {
          settlement: newSettlementData,
        }),
        thunks.neos.changeElsIsScheduleObsoleteThunk(rfqId, productId, true),
        thunks.neos.changeClsIsScheduleObsoleteThunk(rfqId, productId, true),
        neosActionsCreators.createDefaultRfqRequestedAction(rfqId, defaultingOptions),
      );
    }
  };
}
