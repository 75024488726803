import type { ActionCreators } from '@/bootstrap/actions';
import { isPlainObject } from 'lodash';
import type { Layout } from 'react-grid-layout';
import type { Dispatch } from 'redux';
import { type ElsSection, type ElsSectionsLayout, initialLayout } from './elsSectionsModel';

export function getInitialLayoutOrLayoutFromLS(): ElsSectionsLayout[] {
  if (!window.localStorage) {
    return initialLayout;
  }

  return initialLayout.map(initialSection => {
    const LSSection = window.localStorage.getItem(getLocalStorageSectionKey(initialSection.i));

    if (LSSection !== null && LSSection !== undefined) {
      const parsedLSSection = JSON.parse(LSSection);

      if (
        isPlainObject(parsedLSSection) &&
        // Checking if isDisplayed is in the localStorage because older versions of objects in LocalStorage didn't have it.
        Object.prototype.hasOwnProperty.call(parsedLSSection, 'isDisplayed') &&
        !Object.prototype.hasOwnProperty.call(parsedLSSection, 'minH') &&
        Object.prototype.hasOwnProperty.call(parsedLSSection, 'i') &&
        // Checking if isDisplayed is always true for dividend leg.
        ((parsedLSSection.i as ElsSection) !== 'Dividend Leg' ||
          parsedLSSection.isDisplayed === true)
      ) {
        return parsedLSSection;
      }
    }

    window.localStorage.setItem(
      getLocalStorageSectionKey(initialSection.i),
      JSON.stringify(initialSection),
    );
    return initialSection;
  });
}

export function computeAndSaveLayoutToLS(
  layout: Layout[],
  elsSections: ElsSectionsLayout[],
  dispatch: Dispatch,
  actionCreators: ActionCreators,
) {
  const newLayout = elsSections.map((elsSection): ElsSectionsLayout => {
    const section = layout.find(s => s.i === elsSection.i);

    if (!section) {
      return elsSection;
    }

    const { i, ...rest } = section;

    return {
      ...elsSection,
      ...rest,
    };
  });
  dispatch(actionCreators.neos.upsertElsSections(newLayout));
  saveLayoutToLS(newLayout);
}

function saveLayoutToLS(layout: ElsSectionsLayout[]) {
  if (window.localStorage) {
    layout.forEach(section => {
      window.localStorage.setItem(getLocalStorageSectionKey(section.i), JSON.stringify(section));
    });
  }
}

export function getLocalStorageSectionKey(sectionName: ElsSection) {
  return `ELS_Section_${sectionName.split(' ').join('_')}`;
}
