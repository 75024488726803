import { type ComponentType, forwardRef } from 'react';

interface Props {
  hasFocus?: boolean;
}

export function withReactToFocusChange<P extends Props>(CustomComponent: ComponentType<P>) {
  return forwardRef<unknown, P>((props: P, ref) => (
    <CustomComponent ref={ref} key={(props.hasFocus || false).toString()} {...props} />
  ));
}
