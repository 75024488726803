import type { FromOnyxMappers } from '@/neos/business/mappers';
import type {
  BaseCustomProduct,
  CustomProduct,
  CustomProductFamily,
  FxForward,
} from '../../../../../../../../neos/business/neosModel';
import type { OnyxSingleUnderlyingProduct } from '../../../../../../../../neos/business/neosOnyxModel';

export function mapFromOnyxCustomProduct(
  legId: string,
  productFamily: CustomProductFamily,
  onyxProduct: OnyxSingleUnderlyingProduct,
  productUuid: string,
  mappers: FromOnyxMappers,
): CustomProduct {
  return {
    subFamily: productFamily,
    ...mapFromOnyxCommonCustomProduct(legId, onyxProduct, productUuid, mappers),
  };
}

export function mapFromOnyxFxForwardProduct(
  legId: string,
  onyxProduct: OnyxSingleUnderlyingProduct,
  productUuid: string,
  mappers: FromOnyxMappers,
): FxForward {
  return {
    subFamily: 'FX_FORWARD',
    ...mapFromOnyxCommonCustomProduct(legId, onyxProduct, productUuid, mappers),
    pointValue: 1,
  };
}

function mapFromOnyxCommonCustomProduct(
  legId: string,
  onyxProduct: OnyxSingleUnderlyingProduct,
  productUuid: string,
  mappers: FromOnyxMappers,
): BaseCustomProduct {
  return {
    isDerivativeProduct: false,
    ...mappers.mapFromOnyxProductCommon(legId, onyxProduct, productUuid),
    ...mappers.mapFromOnyxOtcNegotiation(),
    ...mappers.mapFromCustomUnderlyingOnyxProduct(onyxProduct),
    description: onyxProduct.description ?? undefined,
  };
}
