import type { Thunk } from '@/bootstrap/thunks';

export function createNeosChangeStrategyTopUnderlyingThunk(
  strategyId: string,
  underlyingId: string | undefined,
): Thunk {
  return function neosChangeStrategyTopUnderlyingThunk(
    dispatch,
    getState,
    {
      selectors,
      thunks: {
        neos: { createNeosLoadUnderlyingInfoAndChangeThunk },
      },
      actionCreators: {
        neos: { createDefaultTopRequestedAction, strategyDataCrudActions, productCrudActions },
      },
    },
  ) {
    const appState = getState();
    const { rfqId, top, legIds } = selectors.getStrategyData(appState, strategyId);

    const updatedTop = underlyingId ? top : undefined;

    const actions = [strategyDataCrudActions.update(strategyId, { underlyingId, top: updatedTop })];

    const indexLegId = legIds[legIds.length - 1];
    const indexLegUnderlyingId = selectors.getUnderlyingOrRefIdOfLeg(
      appState,
      indexLegId,
      selectors,
    );

    if (!indexLegUnderlyingId) {
      const indexProductId = selectors.getProductIdOfLeg(appState, indexLegId, selectors);
      actions.push(productCrudActions.update(indexProductId, { underlyingId }));
    }

    return dispatch(
      createNeosLoadUnderlyingInfoAndChangeThunk(rfqId, underlyingId, [
        ...actions,
        createDefaultTopRequestedAction(rfqId, [strategyId]),
      ]),
    );
  };
}
