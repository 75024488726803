import type { Thunk } from '@/bootstrap/thunks';
import type { ChainOptions } from '../../../../neosActions';
import { DefaultingScope } from '../../../../neosModel';
import { DefaultingOptions } from '../../../../rfq/rfqActions';

export function createDefaultConfirmMediaRequested(
  rfqId: string,
  chainOptions?: ChainOptions,
): Thunk {
  return function defaultConfirmMediaRequested(
    dispatch,
    _,
    {
      actionCreators: {
        neos: { createDefaultRfqRequestedAction },
      },
    },
  ) {
    const defaultingOptions = new DefaultingOptions({
      overrideScopes: [
        DefaultingScope.CONFIRM_MEDIA,
        DefaultingScope.RELATED_EXCHANGE,
        DefaultingScope.SPECIAL_DIVIDENDS,
        DefaultingScope.LOCAL_TAXES,
      ],
    });

    dispatch(createDefaultRfqRequestedAction(rfqId, defaultingOptions, chainOptions));
  };
}
