import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ReferenceKey } from '@/neos/business/neosModel';
import {
  type ExecutionType,
  availableExecutionTypes,
  isUnderlyingIndexType,
} from '@/neos/business/neosOnyxModel';
import { NeosSelectWithAutocomplete } from '@/neos/components/share/neosSelectWithAutocomplete/NeosSelectWithAutocomplete';
import { NumericInput } from '@/neos/components/share/numericInput';
import { formatGridTemplateColumns } from '@/util/format/formatGridTemplateColumns';
import { useSelector } from 'react-redux';
import { DeltaHedgingButton } from '../../strategies/globalActions/deltaHedgingButton/DeltaHedgingButton.container';
import { ExecutedSpot } from './executedSpot/ExecutedSpot';
import {
  type ExecutedSpotReferenceModel,
  getExecutedSpotModel,
} from './executedSpot/getExecutedSpotModel';
import type { UnderlyingReferenceModel } from './getUnderlyingReferenceModel';
import { Hedge } from './hedge/Hedge.container';
import { RefAdjusted } from './refAdjusted/RefAdjusted.container';
import { RefMaturityCell } from './refMaturityCell/RefMaturityCell.container';

import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import styles from './UnderlyingReference.module.scss';

export interface UnderlyingReferenceOwnProps {
  rfqId: string;
  isFutureIndexRfq: boolean;
}

export type UnderlyingReferenceMapStateToProps = UnderlyingReferenceModel;

export interface UnderlyingReferenceDispatchProps {
  onRefSpotChanged: (underlyingId: string, refSpot: number | undefined) => void;
  onRefLevelChanged: (
    underlyingId: string,
    refLevel: number | undefined,
    referenceKey: ReferenceKey,
  ) => void;
  onRefBasisChanged: (underlyingId: string, refBasis: number | undefined) => void;
  onExecutionTypeChanged: (rfqId: string, executionType: ExecutionType) => void;
}

export type UnderlyingReferenceProps = UnderlyingReferenceOwnProps &
  UnderlyingReferenceMapStateToProps &
  UnderlyingReferenceDispatchProps;

export const UnderlyingReferenceComponent = (props: UnderlyingReferenceProps) => {
  const executedSpotModel = useSelector((state: AppState) =>
    getExecutedSpotModel(state, props.rfqId, selectors),
  );

  const areReferenceUiFieldsDisplayed =
    !props.isFutureIndexRfq || props.hasMasterStrategyBeenLoadedWithEmptyReferenceFields;

  const gridTemplateColumns = formatGridTemplateColumns([
    'minmax(min-content, 30px)', // BloombergCode
    [props.isFutureIndexRfq, 'minmax(65px, 110px)'], // ExecutionType
    [areReferenceUiFieldsDisplayed, 'minmax(65px, 110px) minmax(45px, 55px) minmax(65px, 100px)'], // RefLevel & Basis & RefSpot
    [executedSpotModel.isDisplayed, 'minmax(65px, 100px)'], // ExecutedSpot
    'fit-content(100%)', // RefAdjusted
    '60px 65px', //  RefCurrency & RefType
    [!props.isFutureIndexRfq, '120px fit-content(100%)'], // RefMaturity & Hedge
  ]);

  return (
    <div>
      <section className={styles['underlying']} style={{ gridTemplateColumns }}>
        {props.isFutureIndexRfq ? (
          <FutureUnderlyingReferenceFields {...props} executedSpotModel={executedSpotModel} />
        ) : (
          <UnderlyingReferenceFields {...props} executedSpotModel={executedSpotModel} />
        )}
      </section>

      {!props.isFutureIndexRfq && (
        <div
          className={`${styles['deltaHedgingButton']} ${
            props.hasRefSpotBeenChanged || props.hasBasisBeenChanged || props.hasLevelBeenChanged
              ? 'justify-content-between'
              : 'justify-content-end'
          } `}
        >
          <div className="d-flex">
            {props.hasLevelBeenChanged && (
              <div role="alert" className="alert alert-sm alert-warning mx-1">
                <i className="icon icon-sm me-2">warning</i>
                Level has been changed!
              </div>
            )}
            {props.hasBasisBeenChanged && (
              <div role="alert" className="alert alert-sm alert-warning mx-1">
                <i className="icon icon-sm me-2">warning</i>
                Basis has been changed!
              </div>
            )}
            {props.hasRefSpotBeenChanged && (
              <div role="alert" className="alert alert-sm alert-warning mx-1">
                <i className="icon icon-sm me-2">warning</i>
                Ref spot has been changed!
              </div>
            )}
          </div>
          <DeltaHedgingButton rfqId={props.rfqId} />
        </div>
      )}
    </div>
  );
};

function UnderlyingReferenceFields(
  props: UnderlyingReferenceProps & { executedSpotModel: ExecutedSpotReferenceModel },
) {
  return (
    <>
      <BloombergCode underlyingsModel={props.underlyingsModel} />
      <RefLevel
        underlyingsModel={props.underlyingsModel}
        onRefLevelChanged={props.onRefLevelChanged}
        rfqId={props.rfqId}
      />
      <Basis
        underlyingsModel={props.underlyingsModel}
        onRefBasisChanged={props.onRefBasisChanged}
      />
      <RefSpot
        underlyingsModel={props.underlyingsModel}
        onRefSpotChanged={props.onRefSpotChanged}
      />
      <ExecutedSpot
        rfqId={props.rfqId}
        isDisplayed={props.executedSpotModel.isDisplayed}
        underlyingsExecutedSpotModel={props.executedSpotModel.underlyingsExecutedSpotModel}
      />
      <RefAdjusted rfqId={props.rfqId} />
      <RefCurrency underlyingsModel={props.underlyingsModel} />
      <RefType underlyingsModel={props.underlyingsModel} />
      <RefMaturity underlyingsModel={props.underlyingsModel} rfqId={props.rfqId} />
      <Hedge rfqId={props.rfqId} />
    </>
  );
}

function FutureUnderlyingReferenceFields(
  props: UnderlyingReferenceProps & { executedSpotModel: ExecutedSpotReferenceModel },
) {
  return (
    <>
      <BloombergCode underlyingsModel={props.underlyingsModel} />
      <ReferenceExecutionType
        underlyingsModel={props.underlyingsModel}
        onExecutionTypeChanged={props.onExecutionTypeChanged}
        rfqId={props.rfqId}
      />
      {props.hasMasterStrategyBeenLoadedWithEmptyReferenceFields && (
        <>
          <RefLevel
            underlyingsModel={props.underlyingsModel}
            onRefLevelChanged={props.onRefLevelChanged}
            rfqId={props.rfqId}
          />
          <Basis
            underlyingsModel={props.underlyingsModel}
            onRefBasisChanged={props.onRefBasisChanged}
          />
          <RefSpot
            underlyingsModel={props.underlyingsModel}
            onRefSpotChanged={props.onRefSpotChanged}
          />
        </>
      )}
      <ExecutedSpot
        rfqId={props.rfqId}
        isDisplayed={props.executedSpotModel.isDisplayed}
        underlyingsExecutedSpotModel={props.executedSpotModel.underlyingsExecutedSpotModel}
      />
      <RefAdjusted rfqId={props.rfqId} />
      <RefCurrency underlyingsModel={props.underlyingsModel} />
      <RefType underlyingsModel={props.underlyingsModel} />
    </>
  );
}

function BloombergCode(props: Pick<UnderlyingReferenceProps, 'underlyingsModel'>) {
  return (
    <section>
      <div>&nbsp;</div>
      <div className={`${styles['underlying-label']} d-grid`}>
        {props.underlyingsModel.map(({ underlyingBloombergCode, underlyingId }) => (
          <span
            key={underlyingId}
            className="d-block"
            data-e2e={`neos-rfq-reference-udl-${underlyingBloombergCode}`}
          >
            {underlyingBloombergCode || <div className="spinner spinner-sm" />}
          </span>
        ))}
      </div>
    </section>
  );
}

function ReferenceExecutionType(
  props: Pick<UnderlyingReferenceProps, 'underlyingsModel' | 'onExecutionTypeChanged' | 'rfqId'>,
) {
  return (
    <section>
      <div>Execution Type</div>
      {props.underlyingsModel.map(({ underlyingId, executionType }) => (
        <NeosSelectWithAutocomplete<ExecutionType>
          key={underlyingId}
          onChange={option => {
            const newExecutionType =
              (option?.value === '' ? undefined : option?.value) ?? undefined;
            props.onExecutionTypeChanged(props.rfqId, newExecutionType as ExecutionType);
          }}
          options={availableExecutionTypes}
          addEmptyOption
          value={executionType}
          data-e2e="execution-type"
        />
      ))}
    </section>
  );
}

function RefLevel(
  props: Pick<UnderlyingReferenceProps, 'onRefLevelChanged' | 'underlyingsModel' | 'rfqId'>,
) {
  const areEveryUnderlyingIndex = props.underlyingsModel.every(
    underlyingModel => underlyingModel.refType && isUnderlyingIndexType(underlyingModel.refType),
  );

  return (
    <section>
      <div>{areEveryUnderlyingIndex ? 'Ref Fut. level' : 'Ref Level'}</div>
      {props.underlyingsModel.map(
        ({ underlyingId, refSpot, refLevel, refType, isRfqLevelLightFontEnabled, isReadonly }) => {
          const warningMessage =
            refType && !isUnderlyingIndexType(refType)
              ? refSpot.warningMessage
              : refLevel.warningMessage;

          return (
            <SimpleNeosTooltip
              id={underlyingId}
              message={warningMessage}
              key={underlyingId}
              type="warning"
            >
              <NumericInput
                className={`${warningMessage ? 'warning-bloc field-warning' : ''} ${isRfqLevelLightFontEnabled ? styles['light-font'] : ''} `}
                value={refType && !isUnderlyingIndexType(refType) ? refSpot.value : refLevel.value}
                onBlur={val =>
                  props.onRefLevelChanged(underlyingId, val, { rfqId: props.rfqId, underlyingId })
                }
                readOnly={isReadonly || (refType && !isUnderlyingIndexType(refType))}
                data-e2e="neos-rfq-reference-ref-level"
              />
            </SimpleNeosTooltip>
          );
        },
      )}
    </section>
  );
}

function Basis(props: Pick<UnderlyingReferenceProps, 'onRefBasisChanged' | 'underlyingsModel'>) {
  return (
    <section>
      <div>Basis</div>
      {props.underlyingsModel.map(({ underlyingId, basis, refType, isReadonly }) => (
        <SimpleNeosTooltip
          id={underlyingId}
          message={basis.warningMessage}
          key={underlyingId}
          type="warning"
        >
          <NumericInput
            className={basis.warningMessage ? 'warning-bloc field-warning' : undefined}
            value={refType && !isUnderlyingIndexType(refType) ? 0 : basis.value}
            onBlur={val => props.onRefBasisChanged(underlyingId, val)}
            readOnly={isReadonly || (refType && !isUnderlyingIndexType(refType))}
            data-e2e="neos-rfq-reference-basis"
          />
        </SimpleNeosTooltip>
      ))}
    </section>
  );
}

function RefSpot(props: Pick<UnderlyingReferenceProps, 'onRefSpotChanged' | 'underlyingsModel'>) {
  return (
    <section>
      <div>Ref Spot</div>
      {props.underlyingsModel.map(({ underlyingId, refSpot, isReadonly, refType }) => (
        <SimpleNeosTooltip
          id={underlyingId}
          message={refSpot.warningMessage}
          key={underlyingId}
          type="warning"
        >
          <NumericInput
            className={`${refSpot.warningMessage ? 'warning-bloc field-warning' : ''} ${refType && isUnderlyingIndexType(refType) ? styles['light-font'] : ''} `}
            value={refSpot.value}
            readOnly={isReadonly}
            onBlur={val => props.onRefSpotChanged(underlyingId, val)}
            data-e2e="neos-rfq-reference-ref-spot"
          />
        </SimpleNeosTooltip>
      ))}
    </section>
  );
}

function RefCurrency(props: Pick<UnderlyingReferenceProps, 'underlyingsModel'>) {
  return (
    <section>
      <div>Ref Ccy</div>
      {props.underlyingsModel.map(({ underlyingId, refCurrency }) => (
        <input
          key={underlyingId}
          type="text"
          value={refCurrency || ''}
          className="form-control"
          readOnly
        />
      ))}
    </section>
  );
}

function RefType(props: Pick<UnderlyingReferenceProps, 'underlyingsModel'>) {
  return (
    <section>
      <div>Ref Type</div>
      {props.underlyingsModel.map(({ refType }, index) => (
        <input key={index} type="text" value={refType || ''} className="form-control" readOnly />
      ))}
    </section>
  );
}

function RefMaturity(props: Pick<UnderlyingReferenceProps, 'underlyingsModel' | 'rfqId'>) {
  return (
    <section>
      <div>Ref Maturity</div>
      {props.underlyingsModel.map(({ underlyingId }) => (
        <RefMaturityCell key={underlyingId} underlyingId={underlyingId} rfqId={props.rfqId} />
      ))}
    </section>
  );
}
