import type { UserInfo } from './userInfoModel';

export type UserInfoState = Readonly<UserInfo>;

export const defaultUserInfoState: UserInfoState = {
  currentUser: {
    login: '',
    email: '',
    sesameId: '',
    lastname: '',
    firstname: '',
    groupId: undefined,
    diffusionGroups: [],
    categories: [],
  },
  toggleFeatures: [],
};
