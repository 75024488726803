import type { NegoStep } from '@/neos/business/ui/rfq/rfqUiModel';
import type { FC } from 'react';
import type { NegoStepSwitcherModel } from './getNegoStepSwitcherModel';

export interface NegoStepSwitcherOwnProps {
  rfqId: string;
}

export type NegoStepSwitcherStateProps = NegoStepSwitcherModel;

export interface NegoStepSwitcherDispatchProps {
  onStepChanged: (targetStep: NegoStep) => void;
}
export type NegoStepSwitcherProps = NegoStepSwitcherOwnProps &
  NegoStepSwitcherStateProps &
  NegoStepSwitcherDispatchProps;

export const NegoStepSwitcherComponent: FC<NegoStepSwitcherProps> = ({
  targetStep,
  onStepChanged,
}) => (
  <button
    data-e2e="neos-switch-nego-step"
    className="btn btn-primary text-nowrap"
    onClick={() => onStepChanged(targetStep)}
  >
    {targetStep === 'NEGO' ? 'Negotiation' : 'Alloc/Exec'}
  </button>
);
