export class InternalTitleBlinker {
  private isBlinking = false;
  private blinkingTitle = '';

  public titleBlinker = (newTitle: string) => {
    this.blinkingTitle = newTitle;
    if (this.isBlinking) {
      return;
    }
    const oldTitle = document.title;

    this.isBlinking = true;

    const blink = () => {
      document.title = document.title === oldTitle ? this.blinkingTitle : oldTitle;
    };

    blink();

    const timeoutId = setInterval(blink, 1000);

    const clear = () => {
      clearInterval(timeoutId);
      document.title = oldTitle;
      window.removeEventListener('mousemove', clear);
      window.removeEventListener('focus', clear);
      this.isBlinking = false;
    };

    window.addEventListener('mousemove', clear);
    window.addEventListener('focus', clear);
  };
}

const TitleBlinkerInstance = new InternalTitleBlinker();
export const { titleBlinker } = TitleBlinkerInstance;
