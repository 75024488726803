import type { EventType } from '@/neos/business/neosModel';
import type { FC } from 'react';
import { InitNotEligibleIcon } from '../../shared/SecondaryTypeCellsCommon';
import { SecondaryWidget } from '../../shared/SecondaryWidget';
import type { SimilarActivitiesSecondaryTypeCellsModel } from './getSimilarActivitiesSecondariesModel';

export interface SimilarActivitiesSecondaryTypeWidgetOwnProps {
  rfqId: string;
  strategyId: string;
}

export type SimilarActivitiesSecondaryTypeWidgetStateProps =
  SimilarActivitiesSecondaryTypeCellsModel;

export interface SimilarActivitiesSecondaryTypeWidgetDispatchProps {
  onEventTypeChange(eventType: EventType | undefined, legId: string, isLongClick: boolean): void;
}

type SimilarActivitiesSecondaryTypeWidgetProps = SimilarActivitiesSecondaryTypeWidgetOwnProps &
  SimilarActivitiesSecondaryTypeWidgetStateProps &
  SimilarActivitiesSecondaryTypeWidgetDispatchProps;

//similar activities button
//action button
export const SimilarActivitiesSecondaryTypeWidgetComponent: FC<
  SimilarActivitiesSecondaryTypeWidgetProps
> = props => {
  return (
    <section>
      {props.secondaryTypes.map(secondaryTypeModel => {
        return secondaryTypeModel.isEligible ? (
          <SecondaryWidget
            isDisabled={props.isReadonly}
            key={`button-group-${secondaryTypeModel.legId}`}
            eventTypes={secondaryTypeModel.eventTypes}
            isLoading={props.isLoading}
            id={secondaryTypeModel.legId}
            selectedEventType={secondaryTypeModel.secondaryType}
            handleClick={(isLongClick, legId, eventType) => {
              props.onEventTypeChange(eventType, legId, isLongClick);
            }}
          />
        ) : (
          <InitNotEligibleIcon
            legId={secondaryTypeModel.legId}
            key={`not-eligible-icon-${secondaryTypeModel.legId}`}
          />
        );
      })}
    </section>
  );
};
