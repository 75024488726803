type FairPricesColumnsKeys = 'VOL_BID' | 'VOL' | 'VOL_ASK' | 'DELTA' | 'VEGA' | 'GAMMA' | 'THETA';

const standardColumn = 'minmax(83px, 120px)';
const smallColumn = '70px';
export const fairPricesColumnsStyles: { [key in FairPricesColumnsKeys]: string } = {
  VOL_BID: standardColumn,
  VOL: standardColumn,
  VOL_ASK: standardColumn,
  DELTA: smallColumn,
  VEGA: smallColumn,
  GAMMA: smallColumn,
  THETA: smallColumn,
};

export type FairPricesColumns = Array<{ element: JSX.Element; columnKey: FairPricesColumnsKeys }>;

export function getFairPricesGridColmunTemplate(columns: FairPricesColumns) {
  return columns.map(({ columnKey }) => fairPricesColumnsStyles[columnKey]).join(' ');
}
