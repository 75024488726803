import { ErrorHighlight } from '@/neos/components/share/errorHighlight';
import { NumericInput } from '@/neos/components/share/numericInput';
import type { FC } from 'react';
import type { InterestRateModel } from './getInterestRateModel';

export interface InterestRateCellsOwnProps {
  rfqId: string;
  strategyId: string;
}

export interface InterestRateCellsMapStateProps {
  model: InterestRateModel;
}

export interface InterestRateCellsDispatchProps {
  onInterestRateChanged(legId: string, interestRate: number | undefined): void;
}

type InterestRateCellsProps = InterestRateCellsOwnProps &
  InterestRateCellsMapStateProps &
  InterestRateCellsDispatchProps;

export const InterestRateCellsComponent: FC<InterestRateCellsProps> = ({
  rfqId,
  strategyId,
  model,
  onInterestRateChanged,
}) => {
  return (
    <section>
      {model.forwardInterestRates.map(({ id, value, isEditable }) => (
        <ErrorHighlight
          key={id}
          errorField={'forwardInterestRate'}
          related={{ rfqId, strategyId, legId: id }}
        >
          <NumericInput
            readOnly={!isEditable}
            value={value}
            unit="%"
            onBlur={val => onInterestRateChanged(id, val)}
            data-e2e="neos-strategy-definition-forwardInterestRate"
            className="errorable-bloc"
          />
        </ErrorHighlight>
      ))}
    </section>
  );
};
