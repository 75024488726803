import { type ActionCreators, actionCreators } from '@/bootstrap/actions';
import type { ThunkEpic } from '@/bootstrap/epics';
import { selectors } from '@/bootstrap/selectors';
import { type Thunks, thunks } from '@/bootstrap/thunks';
import type { OnyxTransaction } from '@/neos/business/neosOnyxModel';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { ofType } from 'redux-observable';
import type { Observable } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { createApplyWorkflowActionApi } from '../apis/applyWorkflowActionApi';
import { type ToOnyxMappers, mappers } from '../mappers';
import type { OnyxError } from '../mappers/error';
import type { NextUserAction } from '../nextUserActions/nextUserActionsModel';
import { wrapInChainableLoadingObservable } from './wrapInChainableLoadingObservable';

export interface ApplyWorkflowActionApi {
  applyWorkflowAction: (
    nextUserAction: NextUserAction,
    onyxTransaction: OnyxTransaction,
  ) => Observable<OnyxTransaction>;
}

export function getApplyNextUserActionEpic(http: SgmeHttp): ThunkEpic {
  const api = createApplyWorkflowActionApi(http);
  return createApplyNextUserActionEpic(api, mappers.toOnyxMappers, actionCreators, thunks);
}

export function createApplyNextUserActionEpic(
  api: ApplyWorkflowActionApi,
  toOnyxMappers: ToOnyxMappers,
  {
    common: { createAppCrashedAction },
    neos: {
      createDeltaNotificationChangesCrudAction,
      createExecutionPriceNotificationChangesCrudAction,
      createTraderAskNotificationChangesCrudAction,
      createTraderBidNotificationChangesCrudAction,
    },
  }: ActionCreators,
  {
    neos: {
      createHandleAutoCloseTabThunk,
      createSaveTransactionThunk,
      createHandleTransactionSaveFailureThunk,
    },
  }: Thunks,
): ThunkEpic {
  return (action$, state$) =>
    action$.pipe(
      ofType('APPLY_NEXT_USER_ACTION_REQUESTED_ACTION'),
      mergeMap(({ rfqId, nextUserAction, chainOptions }) => {
        const transaction = toOnyxMappers.mapToOnyxTransaction(
          state$.value,
          rfqId,
          selectors,
          toOnyxMappers,
        );
        return wrapInChainableLoadingObservable({
          tabIds: [rfqId],
          apiObservable: api.applyWorkflowAction(nextUserAction, transaction),
          observableOptions: {
            success: {
              on: transactionFromServer => [
                createSaveTransactionThunk(transactionFromServer),
                createHandleAutoCloseTabThunk(transactionFromServer),
                createDeltaNotificationChangesCrudAction.deleteByPartialKey({ rfqId }),
                createExecutionPriceNotificationChangesCrudAction.deleteByPartialKey({ rfqId }),
                createTraderBidNotificationChangesCrudAction.deleteByPartialKey({ rfqId }),
                createTraderAskNotificationChangesCrudAction.deleteByPartialKey({ rfqId }),
              ],
            },
            error: {
              on: ({ response }: { response: OnyxError }) => [
                createHandleTransactionSaveFailureThunk(rfqId, response),
              ],
            },
          },
          chainOptions,
        });
      }),
      catchError(error => [createAppCrashedAction('apply-next-user-action', error)]),
    );
}
