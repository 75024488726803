import type { Thunk } from '@/bootstrap/thunks';

export function createHideElsClsObsoleteScheduleWarningThunk(rfqId: string): Thunk {
  return function hideElsClsObsoleteScheduleWarningThunk(
    dispatch,
    getState,
    { selectors, thunks },
  ) {
    const state = getState();
    const products = selectors.getRfqProducts(state, rfqId, selectors);

    products.forEach(product => {
      dispatch(thunks.neos.changeElsIsScheduleObsoleteThunk(rfqId, product.uuid, false));
      dispatch(thunks.neos.changeClsIsScheduleObsoleteThunk(rfqId, product.uuid, false));
    });
  };
}
