import { lowerThenCamelCase } from '@/util/string/stringUtils';
import { isDefined } from '@/util/undefinedAndNull/isDefined';
import { round } from 'lodash';
import type { UiDeltaType } from '../../rfq/reference/underlyingReference/hedge/getHedgeModel';

export function formatDeltaType(deltaType: UiDeltaType): string {
  switch (deltaType) {
    case 'DELTA_EXCHANGE':
      return 'Δ Exchange Listed';
    case 'DELTA_EXCHANGE_OTC':
      return 'Δ Exch. OTC Synth.';
    default:
      return lowerThenCamelCase(deltaType).replace('Delta', 'Δ');
  }
}

export function roundUndefined(num: number | undefined, precision: number = 0) {
  return isDefined(num) ? round(num, precision) : undefined;
}
