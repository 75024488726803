import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { isValidEmail } from '@/util/string/stringUtils';
import type { PreconfirmationBookingOptionsStateProps } from './InternalPreconfirmationBookingOptions';

export function getInternalPreconfirmationTabModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): PreconfirmationBookingOptionsStateProps {
  const mailOptions = selectors.getInternalMailOptions(state, rfqId);

  const otherFieldIsInvalid = !!mailOptions?.internalOther?.split(';').some(e => {
    e = e.trim();
    return e && !isValidEmail(e);
  });

  return {
    mailOptions,
    otherFieldIsInvalid,
  };
}
