import { useAppSelector } from '@/bootstrap/hooks';
import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import type { Shortcut } from '@/neos/components/rfq/shortcuts/shared/ShortcutButton';
import { NeosSelectWithAutocomplete } from '@/neos/components/share/neosSelectWithAutocomplete/NeosSelectWithAutocomplete';
import {
  type StrategyPickerModel,
  getStrategyPickerModel,
} from '@/neos/components/userPreferences/rfq/getStrategyPickerModel';
import { useDispatch } from 'react-redux';
import type { StrategyType } from '../../../../neos/business/neosModel';

export function StrategyPicker() {
  const { strategiesList, availableStrategyTypes }: StrategyPickerModel = useAppSelector(state =>
    getStrategyPickerModel(state, selectors),
  );

  const dispatch = useDispatch<ExtendedDispatch>();
  const updateStrategiesShortcutsList = (strategiesList: Shortcut<StrategyType>[]) => {
    dispatch(
      createLogAnalyticsAction('NEOS USER PREFERENCES', 'Update strategies Shortcuts List'),
      neosActionCreators.createUpdateStrategiesShortcutsListAction(strategiesList),
    );
  };

  return (
    <div className="row py-2">
      <div className="col-5">
        <label className="pb-2">Search by strategy type</label>
        <NeosSelectWithAutocomplete
          data-e2e="neos-user-preferences-strategy-searchbox"
          value=""
          addEmptyOption
          isDisabled={strategiesList.length >= 14}
          onChange={selected => {
            if (selected && selected.value != '') {
              updateStrategiesShortcutsList([
                ...strategiesList,
                {
                  id: selected?.value,
                  label: selected?.label,
                  'data-e2e': `rfqtab-strategy-shortcut-${selected?.value}`,
                },
              ]);
            }
          }}
          options={availableStrategyTypes.map(({ name, id, alias }) => ({
            value: id,
            label: name,
            data: [alias],
          }))}
        />
        <button
          onClick={() => {
            updateStrategiesShortcutsList([]);
          }}
          className="btn btn-link ps-0"
        >
          <u>Clear All</u>
        </button>
      </div>
      <div className="col-7 d-flex flex-wrap align-items-center">
        {strategiesList.map(strategy => (
          <span
            data-e2e={`neos-user-preferences-strategy-tag-${strategy.id}`}
            key={strategy.id}
            onClick={() => {
              updateStrategiesShortcutsList(strategiesList.filter(s => s.id !== strategy.id));
            }}
            className="badge c-pointer bg-secondary badge-dismissible mb-2 me-2"
          >
            <em className="c-pointer icon icon-xs pe-1">close</em>
            {strategy.label}
          </span>
        ))}
      </div>
    </div>
  );
}
