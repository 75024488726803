import type { StrategyConfigurationReceivedAction } from './strategyConfigurationActions';
import type { StrategyConfiguration } from './strategyConfigurationModel';

export const strategyConfigurationActionCreators = {
  createStrategyConfigurationReceivedAction,
};

export function createStrategyConfigurationReceivedAction(
  strategyConfiguration: StrategyConfiguration,
): StrategyConfigurationReceivedAction {
  return {
    type: 'STRATEGY_CONFIGURATION_RECEIVED',
    strategyConfiguration,
  };
}
