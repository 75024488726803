import type { Thunk } from '@/bootstrap/thunks';
import { isDefined } from '@/util/undefinedAndNull/isDefined';

export function createChangeSalesValoThunk(
  rfqId: string,
  salesInitId: string,
  salesValoId: string | undefined,
): Thunk {
  return function changeSalesValoThunk(
    dispatch,
    _,
    {
      actionCreators: {
        analytics: { createLogAnalyticsAction },
        neos: { actorsSetupCrudActions },
      },
      thunks: {
        neos: { createEnsureCounterpartsLoadedThunk },
      },
    },
  ) {
    dispatch(
      createLogAnalyticsAction('NEOS RFQ', 'Sales Valo', salesValoId),
      actorsSetupCrudActions.update(rfqId, { salesValoId }),
      createEnsureCounterpartsLoadedThunk([
        {
          salesIds: [salesInitId, salesValoId].filter(isDefined),
          rfqIds: [rfqId],
        },
      ]),
    );
  };
}
