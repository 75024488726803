import type { Thunk } from '@/bootstrap/thunks';

export function createNotificationGroupUpdatedThunk(
  notificationGroup: number,
  enabled: boolean,
): Thunk {
  return function notificationGroupUpdatedThunk(
    dispatch,
    _,
    {
      actionCreators: {
        neos: {
          createNotificationGroupSubscriptionChangedAction,
          createRequestUnsubscriptionAction,
          createRequestSubscriptionAction,
        },
      },
    },
  ) {
    dispatch(
      createNotificationGroupSubscriptionChangedAction(notificationGroup, enabled),
      enabled
        ? createRequestSubscriptionAction([
            {
              notificationTopic: 'TRANSACTION_GROUP',
              dataGroupNames: [notificationGroup.toString()],
            },
          ])
        : createRequestUnsubscriptionAction([
            {
              notificationTopic: 'TRANSACTION_GROUP',
              dataGroupNames: [notificationGroup.toString()],
            },
          ]),
    );
  };
}
