import type { Thunk } from '@/bootstrap/thunks';
import type { OnyxError } from '../mappers/error';

export function createHandleTransactionCancelErrorThunk(error: OnyxError | undefined): Thunk {
  return function handleTransactionCancelErrorThunk(
    dispatch,
    _,
    { thunks: { createErrorToasterThunk } },
  ) {
    dispatch(
      createErrorToasterThunk(
        {
          message: 'Error when cancelling RFQ',
        },
        error,
      ),
    );
  };
}
