import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { DisplayNegotiatedSize } from '../ui/strategy/strategyUiModel';

export function getDisplayedSizeTypeByStrategy(
  state: AppState,
  strategyId: string,
  selectors: Selectors,
): DisplayNegotiatedSize {
  const uiSizeType = selectors.getDisplayNegotiatedSize(state.ui, strategyId);
  const isListedStrategy = selectors.isListedStrategy(state, strategyId, selectors);
  if (uiSizeType === 'QUANTITY' && isListedStrategy) {
    return 'NUMBER_OF_LOTS';
  }
  return uiSizeType;
}
