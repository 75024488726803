import type {
  HelpRequestModalDisplayedAction,
  SaveStateRequestedAction,
} from './helpRequestActions';
import type { StorageRequest } from '@/common/business/helpRequest/stateStorage/storageRequestApi';

export const helpRequestActionCreators = {
  createHelpRequestModalDisplayedAction,
  createSaveStateRequestedAction,
};

function createHelpRequestModalDisplayedAction(
  isDisplayed: boolean,
  errorLocation?: string,
  error?: string,
): HelpRequestModalDisplayedAction {
  return {
    type: 'HELP_REQUEST_MODAL_DISPLAYED_ACTION',
    isDisplayed,
    errorLocation,
    error,
  };
}

function createSaveStateRequestedAction(storageRequest: StorageRequest): SaveStateRequestedAction {
  return {
    type: 'HELP_REQUEST_SAVE_STATE',
    storageRequest,
  };
}
