import { getNearestElement } from '@/neos/business/rfq/strategy/leg/thunks/getNearestStrike';
import { formatNum } from '@/util/number/numberUtils';
import { useRef, useState } from 'react';
import { NumericInput } from '../numericInput';
import styles from './ComboBox.module.scss';

export interface ComboBoxProps {
  className?: string;
  styleName?: string;
  onChange: (val: number | undefined) => void;
  onBlur?: () => void;
  disabled?: boolean;
  recommendedValue?: number;
  unit?: string;
  options: number[];
  ['data-e2e']?: string;
  value: number | undefined;
}

// there is a problem with query selectors since the .4 is considered like an error class name
// we need to convert every 8.4 to 8point4 in class names to prevent the crash
function convertDotInNumberToPointAsString(value: number): string {
  const st = value.toString();
  return st.split('.').join('point');
}

export function ComboBox(props: ComboBoxProps) {
  const ulRef = useRef<HTMLUListElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const scrollToValueOrRecommendedValueOffset = () => {
    const selectedOrRecommendedValue = props.value ?? props.recommendedValue;
    if (!selectedOrRecommendedValue) {
      return;
    }

    const valueToScrollTo = props.options.includes(selectedOrRecommendedValue)
      ? selectedOrRecommendedValue
      : getNearestElement(props.options, selectedOrRecommendedValue);

    if (!valueToScrollTo) {
      return;
    }

    const top = ulRef.current?.querySelector<HTMLElement>(
      `.element-${convertDotInNumberToPointAsString(valueToScrollTo)}`,
    )?.offsetTop;

    ulRef.current?.scrollTo({
      top,
      left: 0,
      behavior: 'auto',
    });
  };

  const hide = () => setIsOpen(false);
  const show = () => {
    setIsOpen(true);
    scrollToValueOrRecommendedValueOffset();
  };

  return (
    <div
      className={styles['strike-choose']}
      onBlur={_ => {
        if (props.onBlur) {
          props.onBlur();
        }
        hide();
      }}
      data-e2e={props['data-e2e']}
    >
      <NumericInput
        value={props.value}
        unit={props.unit}
        className={`${props.styleName} ${props.className}`}
        onBlur={value => {
          props.onChange(value);
        }}
        onClick={() => show()}
        readOnly={props.disabled}
      />
      {props.options.length > 0 && (
        <div
          className={`${styles['strike-choose-suggestions']} ${isOpen ? 'visible' : 'invisible'}`}
        >
          <ul ref={ulRef}>
            {props.options.map(value => (
              // this class is only here to be caught by the querySelector on the ulRef
              <li key={value} className={`element-${convertDotInNumberToPointAsString(value)}`}>
                <a
                  data-value={value}
                  onMouseDown={ev => {
                    if (ev.button === 0) {
                      props.onChange(value);
                      hide();
                    }
                  }}
                >
                  {formatNum(value)}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
