import type { ColumnsDisplayToggle as ColumnsDisplayToggleType } from '@/neos/business/ui/userPreferences/userPreferencesUiModel';

import { useAppSelector } from '@/bootstrap/hooks';
import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { ColumnsDisplayToggle } from '@/neos/components/userPreferences/rfq/strategyGridDisplay/ColumnsDisplayToggle';
import { getStrategyGridDisplayModel } from '@/neos/components/userPreferences/rfq/strategyGridDisplay/getStrategyGridDisplayModel';
import { useDispatch } from 'react-redux';

export interface StrategyGridDisplayDispatch {
  switchSalesPricesToggle: (toggle: ColumnsDisplayToggleType) => void;
  switchDeltaToggle: (toggle: ColumnsDisplayToggleType) => void;
  switchFairPricesToggle: (toggle: ColumnsDisplayToggleType) => void;
  switchFairGreeksToggle: (toggle: ColumnsDisplayToggleType) => void;
  switchVolBidAskToggle: (toggle: ColumnsDisplayToggleType) => void;
}

function getDispatchSwitchToggleActions(dispatch: ExtendedDispatch): StrategyGridDisplayDispatch {
  const switchSalesPricesToggle = (toggle: ColumnsDisplayToggleType) => {
    dispatch(
      createLogAnalyticsAction('NEOS USER PREFERENCES', 'Switch sales prices toggle'),
      neosActionCreators.createSalesPricesToggleAction(toggle),
    );
  };
  const switchDeltaToggle = (toggle: ColumnsDisplayToggleType) => {
    dispatch(
      createLogAnalyticsAction('NEOS USER PREFERENCES', 'Switch delta toggle'),
      neosActionCreators.createDeltaToggleAction(toggle),
    );
  };
  const switchFairPricesToggle = (toggle: ColumnsDisplayToggleType) => {
    dispatch(
      createLogAnalyticsAction('NEOS USER PREFERENCES', 'Switch fair prices toggle'),
      neosActionCreators.createFairPricesToggleAction(toggle),
    );
  };
  const switchFairGreeksToggle = (toggle: ColumnsDisplayToggleType) => {
    dispatch(
      createLogAnalyticsAction('NEOS USER PREFERENCES', 'Switch fair greeks toggle'),
      neosActionCreators.createFairGreeksToggleAction(toggle),
    );
  };
  const switchVolBidAskToggle = (toggle: ColumnsDisplayToggleType) => {
    dispatch(
      createLogAnalyticsAction('NEOS USER PREFERENCES', 'Switch vol bid ask toggle'),
      neosActionCreators.createVolBidAskToggleAction(toggle),
    );
  };

  return {
    switchSalesPricesToggle,
    switchDeltaToggle,
    switchFairPricesToggle,
    switchVolBidAskToggle,
    switchFairGreeksToggle,
  };
}

export function StrategyGridDisplay() {
  const { salesPricesToggle, deltaToggle, fairPricesToggle, fairGreeksToggle, volBidAskToggle } =
    useAppSelector(state => getStrategyGridDisplayModel(state, selectors));

  const dispatch = useDispatch<ExtendedDispatch>();
  const {
    switchSalesPricesToggle,
    switchDeltaToggle,
    switchFairPricesToggle,
    switchFairGreeksToggle,
    switchVolBidAskToggle,
  } = getDispatchSwitchToggleActions(dispatch);

  const colClasses = 'col-3 my-2';

  return (
    <div className="text-nowrap d-grid pb-4">
      <span className={colClasses}>Sales prices</span>
      <ColumnsDisplayToggle
        name="salesPrices"
        toggle={salesPricesToggle}
        switchToggle={switchSalesPricesToggle}
      />
      <div className="w-100" />
      <span className={colClasses}>Delta</span>
      <ColumnsDisplayToggle name="delta" toggle={deltaToggle} switchToggle={switchDeltaToggle} />
      <div className="w-100" />
      <span className={colClasses}>Usual fair prices</span>
      <ColumnsDisplayToggle
        name="fairPrices"
        toggle={fairPricesToggle}
        switchToggle={switchFairPricesToggle}
      />
      <div className="w-100" />
      <span className={colClasses}>Other fair greeks</span>
      <ColumnsDisplayToggle
        name="fairGreeks"
        toggle={fairGreeksToggle}
        switchToggle={switchFairGreeksToggle}
      />
      <div className="w-100" />
      <span className={colClasses}>Vol Bid/Ask</span>
      <ColumnsDisplayToggle
        name="volBidAsk"
        toggle={volBidAskToggle}
        switchToggle={switchVolBidAskToggle}
      />
    </div>
  );
}
