import { type DetailedHTMLProps, type TextareaHTMLAttributes, useEffect, useState } from 'react';
// todo improve typing

export function NeosBlurTextarea(
  props: DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>,
) {
  const [state, setState] = useState(props.value);

  useEffect(() => {
    setState(props.value);
  }, [props.value]);

  return (
    <textarea
      {...props}
      value={state}
      onChange={e => {
        setState(e?.target?.value);
      }}
      onBlur={e => props.onChange?.(e)}
    />
  );
}
