import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import type { StrategyType } from '../../../../../../../neos/business/neosModel';
import {
  type StrategyTypeCellDispatchProps,
  type StrategyTypeCellMapStateProps,
  type StrategyTypeCellOwnProps,
  StrategyTypeCellUnconnected,
} from './StrategyTypeCell';
import { getStrategyTypeCellModel } from './getStrategyTypeCellModel';

export const StrategyTypeCell = connect(
  mapStateToProps,
  mapDispatchToProps,
)(StrategyTypeCellUnconnected);

function mapStateToProps(
  state: AppState,
  { strategyId, rfqId }: StrategyTypeCellOwnProps,
): StrategyTypeCellMapStateProps {
  return getStrategyTypeCellModel(state, strategyId, rfqId, selectors);
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId, strategyId }: StrategyTypeCellOwnProps,
): StrategyTypeCellDispatchProps {
  return {
    onStrategyTypeChanged(strategyType: StrategyType) {
      dispatch(neosThunks.createNeosChangeStrategiesTypesThunk(rfqId, [strategyId], strategyType));
    },
  };
}
