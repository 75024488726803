import type { FC } from 'react';
import type { IsMasterLegCellsModel } from './getIsMasterLegCellsModel';

export interface IsMasterLegCellsOwnProps {
  rfqId: string;
  strategyId: string;
}

export type IsMasterLegCellsMapStateProps = IsMasterLegCellsModel;

export interface IsMasterLegCellsDispatchProps {
  onMasterLegChanged(legId: string): void;
}

type IsMasterLegCellsProps = IsMasterLegCellsOwnProps &
  IsMasterLegCellsMapStateProps &
  IsMasterLegCellsDispatchProps;

export const IsMasterLegCellsStyled: FC<IsMasterLegCellsProps> = props => {
  return (
    <section>
      {props.isMasterLeg.map(({ id, value }) => (
        <div className="form-check" key={id}>
          <input
            checked={value}
            className="form-check-input"
            type="radio"
            disabled={props.disabled}
            onChange={() => props.onMasterLegChanged(id)}
            data-e2e="neos-strategy-definition-is-master-leg"
            id={`masterLegRadio-${id}`}
          />
          <label className="form-check-label" htmlFor={`masterLegRadio-${id}`} />
        </div>
      ))}
    </section>
  );
};
