import type { PredealChecksDerogationsReceivedAction } from './predealChecksDerogationActions';
import type { PredealChecksDerogation } from './predealChecksDerogationModel';

export const predealChecksDerogationActionCreators = {
  createPredealChecksDerogationsReceivedAction,
};

export function createPredealChecksDerogationsReceivedAction(
  predealChecksDerogations: PredealChecksDerogation,
): PredealChecksDerogationsReceivedAction {
  return {
    type: 'PREDEAL_CHECKS_DEROGATIONS_RECEIVED',
    predealChecksDerogations,
  };
}
