import { selectors } from '@/bootstrap/selectors';
import { neosThunks } from '@/neos/business/thunks';
import { NeosTooltip } from '@/neos/components/share/tooltip/NeosTooltip';
import { createRef } from 'react';
import { Tooltip } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import type { PriceUnitType } from '../../../../../../neos/business/neosModel';

import { useAppSelector } from '@/bootstrap/hooks';
import { getIsAllButtonsDisabled } from './getIsAllButtonsDisabled';
import { getPricesUnitButtonModel } from './getPricesUnitButtonModel';

type PricesUnitButtonProps = {
  rfqId: string;
};

export function PricesUnitButton({ rfqId }: PricesUnitButtonProps) {
  const dispatch = useDispatch();
  const isRfqMasterStrategyEls = useAppSelector(state =>
    selectors.isRfqMasterStrategyEls(state, rfqId, selectors),
  );

  const isAllButtonsDisabled = useAppSelector(state =>
    getIsAllButtonsDisabled(state, rfqId, selectors),
  );

  const priceUnitButtonModels = useAppSelector(state =>
    getPricesUnitButtonModel(state, rfqId, isAllButtonsDisabled, selectors),
  );

  const { priceUnitType: masterStrategyPriceUnitType } = useAppSelector(state =>
    selectors.getRfqMasterStrategy(state, rfqId),
  );

  const isDisabled =
    useAppSelector(state =>
      selectors.isAmendWorkflowWithInvalidTradeDate(state, rfqId, selectors),
    ) || isRfqMasterStrategyEls;

  if (priceUnitButtonModels.length === 0) {
    return null;
  }

  const onPricesUnitChanged = (priceUnitType: PriceUnitType) => {
    if (!isDisabled) {
      dispatch(neosThunks.createRfqChangePricesUnitThunk(rfqId, priceUnitType));
    }
  };

  return (
    <NeosTooltip placement="top" overlay={<Tooltip id="prices-unit">Prices Unit</Tooltip>}>
      <div>
        {priceUnitButtonModels.map(({ priceUnitType, label }) => {
          const ref = createRef<HTMLButtonElement>();
          const isActive = masterStrategyPriceUnitType === priceUnitType;
          return (
            <button
              key={priceUnitType}
              data-e2e={`price-unit-${priceUnitType}`}
              ref={ref}
              className={`btn btn-${isActive ? 'info' : 'outline-info'}`}
              disabled={isDisabled}
              onClick={() => {
                if (ref.current) {
                  ref.current.blur();
                }
                onPricesUnitChanged(priceUnitType);
              }}
            >
              {label}
            </button>
          );
        })}
      </div>
    </NeosTooltip>
  );
}
