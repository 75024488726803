import { useDispatch, useSelector } from 'react-redux';

import { actionCreators } from '@/bootstrap/actions';
import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { thunks } from '@/bootstrap/thunks';
import {
  type TerminationConditionType,
  terminationConditionTypes,
} from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel';
import { ElsFeaturesContext } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures';
import { ElsBlocField } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/ElsBlocField';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';
import { useContext } from 'react';
import { isElsProduct } from '../../../../../../../../neos/business/neosModel';

export function EarlyTerminationConditions() {
  const { rfqId, strategyId } = useContext(ElsFeaturesContext);
  const dispatch = useDispatch();
  const product = useSelector((state: AppState) =>
    selectors.getStrategyProduct(state, strategyId, selectors),
  );
  if (!isElsProduct(product)) {
    return null;
  }
  const { uuid, terminationConditions } = product;
  const { update: updateProduct } = actionCreators.neos.productCrudActions;

  const onTerminationConditions = (
    newTerminationCondition: TerminationConditionType | undefined,
  ) => {
    dispatch(updateProduct(uuid, { terminationConditions: newTerminationCondition }));
    dispatch(thunks.neos.createDefaultConfirmMediaRequested(rfqId));
  };

  return (
    <ElsBlocField
      minWidth={160}
      label="Early Term. Conditions"
      renderInput={readOnly => (
        <NeosSelect
          readOnly={readOnly}
          value={terminationConditions}
          onChange={onTerminationConditions}
          data-e2e="early-termination-section-termination-condition"
          addEmptyOption
          options={terminationConditionTypes}
        />
      )}
    />
  );
}
