import type { Thunk } from '@/bootstrap/thunks';
import { createDeleteTransactionThunk } from '../../thunks/deleteTransaction';

export function createUpsertReferencedRfqThunk(
  rfqIdReferencedKey: string,
  parentId: string,
): Thunk {
  return function upsertReferencedRfqThunk(
    dispatch,
    getState,
    {
      selectors: { getReferencedRfqIds },
      actionCreators: {
        neos: { referencedRfqCrudActions },
      },
    },
  ) {
    const uiState = getState().ui;
    const referencedRfqIds = getReferencedRfqIds(uiState, rfqIdReferencedKey);
    const newIds = referencedRfqIds === undefined ? [parentId] : [...referencedRfqIds, parentId];
    dispatch(referencedRfqCrudActions.upsert(rfqIdReferencedKey, newIds));
  };
}

export function createRemoveReferencedRfqThunk(
  rfqIdReferencedKey: string,
  parentId: string,
): Thunk {
  return function removeReferencedRfqThunk(
    dispatch,
    getState,
    {
      selectors: { getReferencedRfqIds },
      actionCreators: {
        neos: { referencedRfqCrudActions },
      },
    },
  ) {
    const uiState = getState().ui;
    const referencedRfqIds = getReferencedRfqIds(uiState, rfqIdReferencedKey);

    const isLast = (referencedRfqIds?.length ?? 0) <= 1;

    if (isLast) {
      dispatch(
        createDeleteTransactionThunk(rfqIdReferencedKey),
        referencedRfqCrudActions.delete(rfqIdReferencedKey),
      );
    } else {
      dispatch(
        referencedRfqCrudActions.upsert(
          rfqIdReferencedKey,
          referencedRfqIds?.filter(id => id != parentId) ?? [],
        ),
      );
    }
  };
}
