import { If } from '@/neos/components/share/if/if';
import { NeosResizableBox } from '@/neos/components/share/neosResizableBox/NeosResizableBox';
import { type CommonResizableProps, addBorder } from '../PostNego';
import { GenerateAllocationsButton } from './generateAllocationsButton/GenerateAllocationsButton.container';
import { DeltaListedPreAllocations } from './listedPreAlloc/containers/DeltaListedPreAllocations.container';
import { RfqListedPreAllocations } from './listedPreAlloc/containers/RfqListedPreAllocations.container';
import { DeltaOtcPreAllocations } from './otcPreAlloc/containers/DeltaOtcPreAllocations.container';
import { RfqOtcPreAllocations } from './otcPreAlloc/containers/RfqOtcPreAllocations.container';
import { ReplicateInDeltaButton } from './replicateInDeltaButton/ReplicateInDeltaButton.container';
import { ResetAllocationsButton } from './resetAllocationsButton/ResetAllocationsButton.container';

type PreAllocsOwnProps = CommonResizableProps;

export const PreAllocs = ({ rfqId, width, onResize, isDeltaPresent }: PreAllocsOwnProps) => (
  <section>
    <div className="d-flex">
      <NeosResizableBox
        width={width}
        height={Infinity}
        resizeHandles={['e']}
        axis="x"
        minConstraints={[window.innerWidth * 0.25, Infinity]}
        maxConstraints={[window.innerWidth * 0.75, Infinity]}
        onResize={(_, d) => onResize(d.size.width)}
        isResizable={isDeltaPresent}
      >
        <div className="d-flex flex-column h-100">
          <div className={`${addBorder(isDeltaPresent)} flex-grow-1`}>
            <div className="d-flex justify-content-between mb-1 ps-2">
              <label>Strategies Pre Allocations</label>
              <ReplicateInDeltaButton rfqId={rfqId} />
            </div>
            <RfqListedPreAllocations rfqId={rfqId} />
            <RfqOtcPreAllocations rfqId={rfqId} />
          </div>
          <div className={`${addBorder(isDeltaPresent)} d-flex justify-content-end gap-1 pe-1`}>
            <GenerateAllocationsButton rfqId={rfqId} />
            <If condition={!isDeltaPresent}>
              <ResetAllocationsButton rfqId={rfqId} />
            </If>
          </div>
        </div>
      </NeosResizableBox>

      <div
        className="px-1 d-flex flex-column overflow-auto"
        style={{ maxWidth: `calc(100vw - ${width}px - 24px)` }}
      >
        <If condition={isDeltaPresent}>
          <label>Delta Pre Allocations</label>
          <div className="flex-grow-1">
            <DeltaListedPreAllocations rfqId={rfqId} />
            <DeltaOtcPreAllocations rfqId={rfqId} />
          </div>
          <div className="text-start">
            <ResetAllocationsButton rfqId={rfqId} />
          </div>
        </If>
      </div>
    </div>
  </section>
);
