import { hasDiscriminator } from '@/neos/business/rfq/strategy/feature/hasDiscriminator';
import type { WithExtraProperty } from '@/neos/business/rfq/strategy/feature/withExtraProperty';
import { convertNullToUndefined } from '@/util/undefinedAndNull/convertNullToUndefined';
import type { RateOvernightFeature } from '../../../../neosModel';
import type { OnyxProductFeaturesFields, OnyxRateOvernight } from '../../../../neosOnyxModel';

export function mapFromOnyxFeatureOrExtraFeaturesToRateOvernightFeature(
  { extraFeatures, rateOvernight: definitionRateOvernight }: OnyxProductFeaturesFields,
  strategyId: string,
): RateOvernightFeature | undefined {
  const mappedEls: Pick<RateOvernightFeature, 'strategyId' | 'type'> = {
    strategyId,
    type: 'RATE_OVERNIGHT',
  };
  const rateOvernight = convertNullToUndefined(definitionRateOvernight);
  if (rateOvernight !== undefined) {
    return {
      ...mappedEls,
      rateOvernight: !!rateOvernight,
    };
  }

  if (extraFeatures) {
    const rateOvernightFeature = extraFeatures.find(
      hasDiscriminator<OnyxRateOvernight>('RATE_OVERNIGHT'),
    );

    if (rateOvernightFeature) {
      return {
        ...mappedEls,
        rateOvernight: !!(rateOvernightFeature && rateOvernightFeature.rateOvernight),
      };
    }
  }

  return undefined;
}

export function mapToOnyxRateOvernightFeature(
  feature: WithExtraProperty<RateOvernightFeature>,
): OnyxProductFeaturesFields {
  const { rateOvernight } = feature;

  if (!feature.isExtra) {
    return {
      rateOvernight,
      extraFeatures: [],
    };
  }
  const onyxRateOvernight: OnyxRateOvernight = { discriminator: 'RATE_OVERNIGHT', rateOvernight };

  return {
    extraFeatures: [onyxRateOvernight],
  };
}
