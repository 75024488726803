import type { Thunk } from '@/bootstrap/thunks';
import type { OnyxExecution, OnyxOrder } from '../orderOnyxModel';

export function createIntegrateOrderExecutionPricesDifferenceNotificationThunk(
  rfqId: string,
  onyxOrder: OnyxOrder,
): Thunk {
  return function integrateOrderExecutionPricesDifferenceNotificationThunk(
    dispatch,
    getState,
    {
      selectors: { executionSelectors, tabExists, ...commonSelectors },
      actionCreators: {
        neos: { createExecutionPriceNotificationChangesCrudAction, rfqUiCrudActions },
      },
    },
  ) {
    const state = getState();
    const rfqIsOpen = tabExists(state.ui.tabManagement, rfqId);
    if (!rfqIsOpen) {
      return;
    }
    const isTrader = commonSelectors.isTrader(state);
    if (!isTrader) {
      return;
    }

    const currentExecutions = executionSelectors.selectObjects(state.execution, {
      orderId: onyxOrder.uuid,
    });

    if (!currentExecutions.length) {
      return;
    }

    const notificationExecutions = onyxOrder.executions;
    let showModal = false;

    const currentAverage = calculateAvgPrice(currentExecutions);
    const notificationAverage = calculateAvgPrice(notificationExecutions);

    if (currentAverage !== notificationAverage) {
      dispatch(
        createExecutionPriceNotificationChangesCrudAction.upsert(
          { executionId: onyxOrder.uuid, rfqId },
          {
            oldValue: currentAverage,
            newValue: notificationAverage,
          },
        ),
      );
      showModal = true;
    }

    if (showModal) {
      dispatch(rfqUiCrudActions.update(rfqId, { showWarningModal: true }));
    }
  };
}

function calculateAvgPrice(executions: Array<OnyxExecution>): number | undefined {
  let sum = 0;
  let quantity = 0;
  executions.forEach(e => {
    const q = e.size?.quantity ?? 0;
    const value = e.lastPrice?.value ?? 0;
    sum += q * value;
    quantity += q;
  });
  if (!quantity) {
    return undefined;
  }
  return sum / quantity;
}
