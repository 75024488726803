import { ErrorHighlight } from '@/neos/components/share/errorHighlight';
import type { FC } from 'react';
import { FormSelect } from 'react-bootstrap';
import type { LotSizeMarketCellModel } from './getLotSizeMarketCellModel';

export interface LotSizeMarketCellOwnProps {
  rfqId: string;
  strategyId: string;
}

export type LotSizeMarketCellMapStateProps = LotSizeMarketCellModel;

export interface LotSizeMarketCellDispatchProps {
  onLotSizeMarketChanged(legId: string, lotSizeMarket: string | undefined): void;
}

type LotSizeMarketCellProps = LotSizeMarketCellOwnProps &
  LotSizeMarketCellMapStateProps &
  LotSizeMarketCellDispatchProps;

export const LotSizeMarketCellUnconnected: FC<LotSizeMarketCellProps> = ({
  rfqId,
  strategyId,
  disabled,
  availableLotSizesMarkets,
  lotSizeMarkets,
  isListed,
  onLotSizeMarketChanged,
}) => {
  return (
    <section>
      {lotSizeMarkets.map(({ id: legId, value }) => (
        <ErrorHighlight key={legId} related={{ rfqId, legId, strategyId }} errorField={'lotSize'}>
          <FormSelect
            data-e2e="neos-strategy-definition-lot-size"
            value={value || ''}
            readOnly={disabled}
            onChange={event => onLotSizeMarketChanged(legId, event.target.value)}
          >
            {(isListed || !value) && <option value="" />}
            {availableLotSizesMarkets.map(({ id: idLotSizeMarket, value: valToDisplay }) => (
              <option value={idLotSizeMarket} key={idLotSizeMarket}>
                {valToDisplay}
              </option>
            ))}
          </FormSelect>
        </ErrorHighlight>
      ))}
    </section>
  );
};
