import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import type { OnyxElectronicMediaUser } from '@/neos/business/electronicMediaUsers/electronicMediaUserModel';
import type {
  ElectronicMediaUser,
  MarkitConfirmationMedia,
  OtcAllocation,
  OtcPreAlloc,
} from '@/neos/business/neosModel';
import { NeosSgPicker } from '@/neos/components/share/neosSelectWithAutocomplete/NeosSgPicker';
import type { StateMap } from '@/util/collectionHelper';
import type { FC } from 'react';

type ElectronicUserProps = {
  allocation: OtcAllocation | OtcPreAlloc;
  onMediaUserChanged: (user: MarkitConfirmationMedia['electronicMediaUser']) => void;
  'data-e2e': string;
};

export const ElectronicUser: FC<ElectronicUserProps> = ({
  allocation,
  onMediaUserChanged,
  'data-e2e': dataE2e,
}) => {
  const mediaUsers: StateMap<OnyxElectronicMediaUser[]> = useAppSelector(state =>
    selectors.getAllElectronicMediaUsers(state),
  );
  const options = allocation.counterpartId
    ? (mediaUsers[allocation.counterpartId?.toString()] || []).map(mediaUser => {
        return {
          key: mediaUser.id,
          label: calculateElectronicUserLabel(mediaUser),
          tag: mediaUser,
        };
      })
    : [];

  return (
    <NeosSgPicker
      addEmptyValue
      options={options}
      maxDisplayedItems={10}
      data-e2e={dataE2e}
      isDisabled={allocation.media !== 'MARKITWIRE'}
      value={allocation.media === 'MARKITWIRE' ? allocation.electronicMediaUser?.id : undefined}
      onChange={value => {
        onMediaUserChanged(options.find(item => item.key === value)?.tag);
      }}
    />
  );
};

export function calculateElectronicUserLabel(mediaUser: ElectronicMediaUser): string {
  return `${mediaUser.id.toString()}${mediaUser.type === 'GROUP' ? ' - GROUP' : ''}`;
}
