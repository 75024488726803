import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { referenceDomain } from '@/neos/business/rfq/reference/referenceDomain';
import type { Reference, ReferenceKey } from '@/neos/business/rfq/reference/referenceModel';
import type { ElsBasket } from '@/neos/business/rfq/strategy/leg/product/productModel';
import { isDefined } from '@/util/undefinedAndNull/isDefined';

export const referenceSelectors = {
  selectElsBasketReferences,
  getReference,
  getRfqReferencesKeys,
  getReferences,
};

function selectElsBasketReferences(state: AppState, rfqId: string, product: ElsBasket) {
  const underlyingIds = product.basketUnderlying.basketComposition
    .map(u => u.underlyingId)
    .filter(isDefined);

  return underlyingIds
    .map(underlyingId =>
      selectors.getReference(state, {
        underlyingId,
        rfqId,
      }),
    )
    .filter(isDefined);
}

function getReference(state: AppState, key: ReferenceKey): Reference | undefined {
  return getReferences(state, key)[0];
}

function getReferences(
  state: AppState,
  partialKey: ReferenceKey | Pick<ReferenceKey, 'rfqId'> | Pick<ReferenceKey, 'underlyingId'>,
): Reference[] {
  return referenceDomain.selectors.selectObjects(state.reference, partialKey);
}

function getRfqReferencesKeys(state: AppState, rfqId: string) {
  return referenceDomain.selectors.selectKeys(state.reference, { rfqId });
}
