import type { SubfilterType } from '@/neos/business/blotter/blotterModel';
import type { FC } from 'react';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';

export interface BlotterSubfilterStateProps {
  currentSubfilter: SubfilterType;
}

export interface BlotterSubfilterDispatchProps {
  changeBlotterSubfilter: (subfilter: SubfilterType) => void;
}

export type BlotterSubfilterProps = BlotterSubfilterStateProps & BlotterSubfilterDispatchProps;

export const BlotterSubfilterComponent: FC<BlotterSubfilterProps> = props => (
  <ToggleButtonGroup
    name="subfilter"
    className="errorable-bloc btn-group-toggle"
    value={props.currentSubfilter}
    onChange={(val: SubfilterType) => props.changeBlotterSubfilter(val)}
  >
    <ToggleButton
      value="ALL"
      variant="toggle-primary"
      data-e2e="neos-blotter-subfilter-all"
      id="neos-blotter-subfilter-all"
    >
      All
    </ToggleButton>
    <ToggleButton
      value="GROUP"
      variant="toggle-primary"
      data-e2e="neos-blotter-subfilter-group"
      id="neos-blotter-subfilter-group"
    >
      My Group
    </ToggleButton>
    <ToggleButton
      value="OWN"
      variant="toggle-primary"
      data-e2e="neos-blotter-subfilter-owner"
      id="neos-blotter-subfilter-owner"
    >
      My RFQs
    </ToggleButton>
  </ToggleButtonGroup>
);
