import type { AppState } from '@/bootstrap/state.ts';
import type { Selectors } from '@/bootstrap/selectors.ts';
import type { StockLoanComponent } from '@/neos/business/rfq/strategy/leg/product/productModel.ts';

export type PortfolioWay = keyof Pick<StockLoanComponent, 'borrowPortfolio' | 'lendPortfolio'>;

export function getElsStockLoanHedgePortfolioWay(
  state: AppState,
  legId: string,
  selectors: Selectors,
): PortfolioWay | null {
  const legWay = selectors.getLegWay(state, legId, selectors);
  return legWay === 'SELL' ? 'lendPortfolio' : legWay === 'BUY' ? 'borrowPortfolio' : null;
}
