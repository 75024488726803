import { blotterHeaderNames } from './blotterHeaderNames';
import type { BlotterColDef, PossibleBlotterColumnFields } from './gridColumnDefinition';

export function getGridColumnDefinitionWithHeaderNames(columns: BlotterColDef[]): BlotterColDef[] {
  return columns.map(
    (column): BlotterColDef => ({ ...column, headerName: blotterHeaderNames[column.field] }),
  );
}

export function getColumnHeaderName(field: PossibleBlotterColumnFields): string {
  return blotterHeaderNames[field];
}
