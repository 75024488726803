import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { connect } from 'react-redux';
import {
  type BlotterLastNotificationPropsFromState,
  BlotterLastNotificationWithCss,
} from './BlotterLastNotification';

function mapStateToProps(state: AppState): BlotterLastNotificationPropsFromState {
  return {
    lastNotificationDate: selectors.getBlotterLastNotificationDate(state.blotter),
  };
}

export const BlotterLastNotification = connect(mapStateToProps)(BlotterLastNotificationWithCss);
