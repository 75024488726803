import type { ActionContainer } from '@/util/actionContainer';
import type { OnyxRfq } from '../neosOnyxModel';
import type { RequestSimilarActivitiesAction } from './similarActivitiesActions';
import { similarActivitiesDomain } from './similarActivitiesDomain';

const similarActivitiesCrudActions = similarActivitiesDomain.actions;

export const similarActivitiesActionCreators = {
  similarActivitiesCrudActions,
  createRequestSimilarActivitiesAction,
  createSimilarActivitiesReceivedAction,
};

function createRequestSimilarActivitiesAction(rfqId: string): RequestSimilarActivitiesAction {
  return {
    type: 'REQUEST_SIMILAR_ACTIVITIES',
    rfqId,
  };
}

function createSimilarActivitiesReceivedAction(
  rfqId: string,
  similarActivities: OnyxRfq[],
): ActionContainer {
  return [similarActivitiesCrudActions.upsert(rfqId, similarActivities)];
}
