export interface SearchBrokerNotInPreferencesAction {
  type: 'RFQ_SEARCH_BROKER_NOT_IN_PREFERENCES';
  rfqId: string;
  brokerId: string;
}

export function createSearchBrokerNotInPreferencesAction(
  rfqId: string,
  brokerId: string,
): SearchBrokerNotInPreferencesAction {
  return {
    type: 'RFQ_SEARCH_BROKER_NOT_IN_PREFERENCES',
    rfqId,
    brokerId,
  };
}
