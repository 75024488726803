import type { FromOnyxMappers } from '@/neos/business/mappers';
import type { OnyxRfq } from '@/neos/business/rfq/rfqOnyxModel';
import type { ActionContainer } from '@/util/actionContainer';
import { flatMap } from 'lodash';
import type { OnyxStrategy } from '../rfqOnyxModel';
import { fairPriceDomain } from './fairPriceDomain';

export const fairPriceActionCreators = {
  fairPriceCrudActions: fairPriceDomain.actions,
  createFairPricesLoadedAction,
};

function createFairPricesLoadedAction(
  rfq: OnyxRfq,
  { mapFromOnyxFairPrice }: FromOnyxMappers,
): ActionContainer {
  const { upsertMany } = fairPriceDomain.actions;
  return upsertMany([
    {
      id: rfq.uuid,
      value: mapFromOnyxFairPrice({ type: 'Rfq', id: rfq.uuid }, rfq.fairPrice) || {},
    },
    ...getStratOrDeltaActions(rfq.strategies),
    ...getStratOrDeltaActions(rfq.deltas ?? []),
  ]);

  function getStratOrDeltaActions(strategies: OnyxStrategy[]) {
    return flatMap(strategies, strategy => [
      {
        id: strategy.uuid,
        value:
          mapFromOnyxFairPrice({ type: 'Strategy', id: strategy.uuid }, strategy.fairPrice) || {},
      },
      ...strategy.legs.map(leg => ({
        id: leg.uuid,
        value: mapFromOnyxFairPrice({ type: 'Leg', id: leg.uuid }, leg.fairPrice) || {},
      })),
    ]);
  }
}
