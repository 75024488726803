import type { PriceWithUnit } from '@/neos/business/rfq/rfqOnyxModel';
import { formatNum } from '@/util/number/numberUtils';

export function formatPriceWithUnit(priceWithUnit: PriceWithUnit | undefined) {
  if (!priceWithUnit) {
    return '';
  }
  const { value, unit } = priceWithUnit;
  return value === undefined || value === null ? '' : formatNum(value) + (unit === '%' ? '%' : '');
}
