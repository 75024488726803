import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { type ExtendedDispatch, thunks } from '@/bootstrap/thunks';
import { connect } from 'react-redux';
import {
  DeltaSummaryComponent,
  type DeltaSummaryDispatchProps,
  type DeltaSummaryOwnProps,
  type DeltaSummaryStateProps,
} from './DeltaSummary';
import { getDeltaSummaryModel } from './getDeltaSummaryModel';

export const DeltaSummary = connect(mapStateToProps, mapDispatchToProps)(DeltaSummaryComponent);

function mapStateToProps(state: AppState, { rfqId }: DeltaSummaryOwnProps): DeltaSummaryStateProps {
  return getDeltaSummaryModel(state, rfqId, selectors);
}

function mapDispatchToProps(dispatch: ExtendedDispatch): DeltaSummaryDispatchProps {
  return {
    onCopyToClipboard: (text: string) => {
      dispatch(thunks.createCopyToClipboardThunk(text));
    },
  };
}
