import type { Thunk } from '@/bootstrap/thunks.ts';
import {
  initialEquityHedge,
  isElsProduct,
  type EquityHedge,
} from '@/neos/business/rfq/strategy/leg/product/productModel.ts';

export function createUpdateElsEquityHedgeThunk<K extends keyof EquityHedge>(
  productId: string,
  key: K,
  value: EquityHedge[K],
): Thunk {
  return function updateElsEquityHedgeThunk(dispatch, getState, { actionCreators, selectors }) {
    const product = selectors.getProduct(getState(), productId);
    if (!isElsProduct(product)) {
      return;
    }

    const oldEquityHedge: EquityHedge = product.equityHedge ?? initialEquityHedge;

    dispatch(
      actionCreators.neos.productCrudActions.update(product.uuid, {
        equityHedge: {
          ...oldEquityHedge,
          [key]: value,
        },
      }),
    );
  };
}
