import { actionCreators } from '@/bootstrap/actions';
import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { thunks } from '@/bootstrap/thunks';
import { determinationMethodValues } from '@/neos/business/rfq/strategy/feature/elsSections/elsSectionsModel';
import { isElsProduct } from '@/neos/business/rfq/strategy/leg/product/productModel';
import type { DeterminationMethod } from '@/neos/business/rfq/strategy/strategyData/strategyDataModel';
import { ElsFeaturesContext } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures';
import { ElsBlocField } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/ElsBlocField';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';

export function FinalPriceDetermination() {
  const { rfqId, strategyId } = useContext(ElsFeaturesContext);
  const dispatch = useDispatch();
  const product = useAppSelector(state =>
    selectors.getStrategyProduct(state, strategyId, selectors),
  );
  const { determinationMethod } = useAppSelector(state =>
    selectors.getStrategyData(state, strategyId),
  );

  if (!isElsProduct(product)) {
    return null;
  }

  const { update: updateStrategy } = actionCreators.neos.strategyDataCrudActions;

  function onDeterminationMethodChanged(determinationMethod: DeterminationMethod | undefined) {
    dispatch(updateStrategy(strategyId, { determinationMethod }));
    dispatch(thunks.neos.createDefaultConfirmMediaRequested(rfqId));
  }

  const getDeterminationMethoValues = () => {
    if (determinationMethod === 'PER_MASTER_CONFIRM') {
      return determinationMethodValues;
    }
    return determinationMethodValues.filter(option => option.value !== 'PER_MASTER_CONFIRM');
  };

  return (
    <ElsBlocField
      label="Final Price Determination"
      renderInput={readOnly => (
        <NeosSelect
          readOnly={readOnly}
          value={determinationMethod}
          onChange={onDeterminationMethodChanged}
          data-e2e="els-section-equity-determinationMethod"
          options={getDeterminationMethoValues()}
          addEmptyOption
        />
      )}
    />
  );
}
