import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { HighlightedValue } from '@/neos/business/ui/reference/referenceUiModel';

interface ElsRefSpotWithWarning {
  warningMessage: string | undefined;
  value: number | undefined;
  unit: string | undefined;
}

export interface ElsRefSpotModel {
  underlyingId: string;
  refSpot: ElsRefSpotWithWarning;
}

export function getElsRefSpotModel(
  state: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): ElsRefSpotModel | undefined {
  const underlyingId = selectors.getStrategyUnderlyingId(state, strategyId, selectors);

  if (!underlyingId) {
    return undefined;
  }

  const reference = selectors.getReference(state, { rfqId, underlyingId });
  const referenceUi = selectors.getReferenceUiByRfqIdAndUnderlyingId(state.ui, rfqId, underlyingId);

  return {
    underlyingId,
    refSpot: {
      value: reference?.refSpot,
      unit: reference?.refSpotUnit,
      warningMessage: getRefSpotWarningMessage(referenceUi?.refSpot),
    },
  };
}

function getRefSpotWarningMessage(
  notificationRefValue: HighlightedValue | undefined,
): string | undefined {
  if (!notificationRefValue) {
    return undefined;
  }
  const { isWarningDisplayed, oldValue } = notificationRefValue;
  return isWarningDisplayed ? `Ref spot has been changed ! Old value: ${oldValue}` : undefined;
}
