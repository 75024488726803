import type { Thunk } from '@/bootstrap/thunks';

export function createRfqDeleteLegThunk(legId: string, resetTop: boolean = true): Thunk {
  return function rfqDeleteLegThunk(
    dispatch,
    getState,
    {
      selectors: { getLegData },
      thunks: {
        neos: { createStrategyDeleteLegsThunk },
      },
      actionCreators: {
        neos: { quoteCrudActions, fairPriceCrudActions },
      },
    },
  ) {
    const appState = getState();

    const { quoteId, fairPriceId } = getLegData(appState, legId);

    const actions = [quoteCrudActions.delete(quoteId)];
    if (fairPriceId) {
      actions.push(fairPriceCrudActions.delete(fairPriceId));
    }

    dispatch(actions);
    dispatch(createStrategyDeleteLegsThunk([legId], resetTop));
  };
}
