import type { MarketsEliotReceivedAction, MarketsReceivedAction } from './marketsActions';
import type { OnyxMarket, OnyxMarketEliots } from './marketsModel';

export const marketsActionCreators = {
  createMarketsReceivedAction,
  createMarketsEliotReceivedAction,
};

export function createMarketsReceivedAction(markets: OnyxMarket[]): MarketsReceivedAction {
  return {
    type: 'MARKETS_RECEIVED',
    markets,
  };
}

export function createMarketsEliotReceivedAction(
  markets: OnyxMarketEliots,
): MarketsEliotReceivedAction {
  return {
    type: 'MARKETS_ELIOT_RECEIVED',
    markets,
  };
}
