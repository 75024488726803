import type { OnyxRfq } from '@/neos/business/rfq/rfqOnyxModel';

export function getTraderGroups(rfq: OnyxRfq) {
  const traderGroups = rfq.strategies
    .map(strategy => strategy.traderDiffusion?.traderGroup?.value)
    .filter(group => group !== undefined);

  return (
    (traderGroups.length === 1
      ? traderGroups[0]
      : traderGroups.map(group => `[${group}]`).join(', ')) || ''
  );
}
