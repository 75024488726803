import { MAX_BLOTTER_RFQS_TO_OPEN } from '@/neos/components/blotter/grid/gridStaticOptions';
import type { RfqIdClipboardResult } from './getRfqIdsFromClipboard';

export interface OpenFromClipboardTooltipOwnProps {
  rfqClipboardResult: RfqIdClipboardResult | null;
}

export const OpenFromClipboardTooltip = ({
  rfqClipboardResult,
}: OpenFromClipboardTooltipOwnProps) => {
  return (
    <>
      {rfqClipboardResult?.status === 'FAILED' || rfqClipboardResult?.rfqIds.length === 0 ? (
        <>
          Open RFQ(s) with ID(s) from the clipboard.
          <br />
          {rfqClipboardResult.status === 'FAILED' ? rfqClipboardResult.message : 'No ID found.'}
        </>
      ) : rfqClipboardResult && rfqClipboardResult?.rfqIds.length > MAX_BLOTTER_RFQS_TO_OPEN ? (
        `Too many RFQ IDs are in the clipboard. Please open no more than ${MAX_BLOTTER_RFQS_TO_OPEN}.`
      ) : (
        <>
          Open
          {rfqClipboardResult?.rfqIds.length === 1
            ? ' one RFQ '
            : ` ${rfqClipboardResult?.rfqIds.length} RFQs `}
          with ID
          {rfqClipboardResult?.rfqIds.length === 1 ? '' : 's'} from the clipboard.
        </>
      )}
    </>
  );
};
