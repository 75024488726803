import { CreateSecondaryFromXoneModal } from '@/neos/components/blotter/createSecondaryFromXone/CreateSecondaryFromXoneModal';
import { useFeatureToggle } from '@/neos/components/share/featureToggle/useFeatureToggle';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import { useState } from 'react';
import styles from './CreateSecondaryFromXone.module.scss';

export function CreateSecondaryFromXone() {
  const [shouldShowModal, setShouldShowModal] = useState(false);
  const isFeatureToggleEnabled = useFeatureToggle('neos.secondary.from.xone');

  if (!isFeatureToggleEnabled) {
    return null;
  }

  return (
    <div className={`${styles['create-secondary-from-xone-id']}`}>
      <SimpleNeosTooltip
        id="create-secondary-from-xone"
        message="Create a secondary from a X-ONE ID"
      >
        <button
          className="btn btn-primary"
          data-e2e="neos-blotter-btn-create-secondary"
          onClick={() => {
            setShouldShowModal(true);
          }}
        >
          <i className="icon icon-sm">library_add</i>
        </button>
      </SimpleNeosTooltip>
      <CreateSecondaryFromXoneModal
        isModalShown={shouldShowModal}
        onHideModal={() => setShouldShowModal(false)}
      />
    </div>
  );
}
