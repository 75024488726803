import type { Thunk } from '@/bootstrap/thunks';
import type { Quote } from '../../../../neos/business/neosModel';
import { type LegRecomputeChangedScope, LegRecomputeScope, getLegRecomputeScope } from '../models';

export function createRfqUpdateStrategyQuoteThunk(
  rfqId: string,
  strategyId: string,
  quoteId: string,
  quotePatch: Partial<Quote>,
  scope:
    | LegRecomputeChangedScope.TRADER_PRICE
    | LegRecomputeChangedScope.SALES_PRICE
    | LegRecomputeScope.CHANGE_DELTA,
): Thunk {
  return function rfqUpdateStrategyQuoteThunk(
    dispatch,
    getState,
    {
      selectors: { getStrategyData, getLegData },
      actionCreators: {
        neos: { quoteCrudActions },
      },
      thunks: {
        neos: { createDispatchIfBeforeOrderAcceptedThunk, createRecomputeRfqThunk },
      },
    },
  ) {
    const state = getState();

    const { legIds, isMasterStrategy } = getStrategyData(state, strategyId);
    const strategyLegsQuotesIds = legIds.map(legId => getLegData(state, legId).quoteId);

    const legsQuotePatch: Partial<Quote> =
      legIds.length === 1 ? quotePatch : makeResetLegPricesPatch(quotePatch);

    const legsQuotesActions = strategyLegsQuotesIds.map(legQuoteId =>
      quoteCrudActions.update(legQuoteId, legsQuotePatch),
    );

    const patchAction = [quoteCrudActions.update(quoteId, quotePatch), ...legsQuotesActions];

    const recomputeScope = getRecomputeScope(scope, isMasterStrategy);

    dispatch(
      createDispatchIfBeforeOrderAcceptedThunk(
        rfqId,
        createRecomputeRfqThunk(rfqId, recomputeScope, patchAction),
        patchAction,
      ),
    );
  };
}

function getRecomputeScope(
  scope:
    | LegRecomputeChangedScope.TRADER_PRICE
    | LegRecomputeChangedScope.SALES_PRICE
    | LegRecomputeScope.CHANGE_DELTA,
  isMasterStrategy: boolean,
) {
  return scope === LegRecomputeScope.CHANGE_DELTA
    ? LegRecomputeScope.CHANGE_DELTA
    : getLegRecomputeScope({ scope, isMasterStrategy, isMasterLeg: true });
}

function makeResetLegPricesPatch({ traderBid, traderAsk, delta }: Partial<Quote>): Partial<Quote> {
  if (traderBid !== undefined || traderAsk !== undefined) {
    return {
      traderBid: undefined,
      traderAsk: undefined,
    };
  }
  if (delta !== undefined) {
    return {
      delta: undefined,
    };
  }
  return {};
}
