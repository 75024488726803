import type { EmirPredealCheck } from '@/neos/business/predealCheck/predealCheckModel';

export function getEmirDetails(emir: EmirPredealCheck) {
  return {
    flags: [{ label: 'Eligible', value: emir.eligible }],
    messages: [
      { label: 'EMIR On Boarding', message: emir.message },
      { label: 'EMIR Banking Secrecy', message: emir.bankingSecrecyMessage },
    ],
    technicalMessages: emir.redKey
      ? [
          { label: 'RED ID', message: emir.redKey.uuid },
          { label: 'VERSION ID', message: emir.redKey.version },
          { label: 'Namespace', message: emir.namespace },
          { label: 'Prior Namespace', message: emir.priorNamespace },
          { label: 'UTI', message: emir.uti },
          { label: 'Prior UTI', message: emir.priorUti },
        ]
      : [],
  };
}
