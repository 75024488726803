import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { Product } from '@/neos/business/rfq/strategy/leg/product/productModel';
import type { Field } from '../utils/Field';
import { getIsStrategyDefinitionFieldEditable } from '../utils/fieldsEnablingConditions';
import { getFieldsArray } from '../utils/getFieldsArray';

type ClientTaxRateField = Field<Product['clientTaxRate']> & {
  isDisplayed: boolean;
};

export interface ClientTaxRateCellsModel {
  clientTaxRateFields: ClientTaxRateField[];
}

export function getClientTaxRateCellsModel(
  appState: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): ClientTaxRateCellsModel {
  const products = selectors.getStrategyProducts(appState, strategyId, selectors);

  const { strategyType } = selectors.getStrategyData(appState, strategyId);
  const strategyDefinition = selectors.getStrategyDefinition(appState.referenceData, strategyType);

  const isReadonlyAtWorkflow = selectors.isReadOnlyAtCurrentWorkflow(
    appState,
    strategyId,
    selectors,
  );
  const isReadonly =
    !getIsStrategyDefinitionFieldEditable(appState, rfqId, strategyId, selectors, [
      'RFQ_SCOPE',
      'RFQ_EDITABLE',
      'STRATEGY_EDITABLE',
    ]) ||
    strategyDefinition.readOnlyProductClientTaxRate ||
    isReadonlyAtWorkflow;

  const clientTaxRateFields = getFieldsArray(
    products,
    'clientTaxRate',
    strategyDefinition.sameProductClientTaxRate,
    isReadonly,
  );

  return {
    clientTaxRateFields: clientTaxRateFields.map((field, index) => ({
      ...field,
      isDisplayed: strategyDefinition.legs.at(index)?.taxCollection ?? false,
    })),
  };
}
