import type { Selectors } from '@/bootstrap/selectors';
import { services } from '@/bootstrap/services';
import type { AppState } from '@/bootstrap/state';
import { orderOf } from '@/neos/business/services/statusOrder';
import type { FieldChange } from '@/neos/business/warnings/changesNotificationModel';
import { flatten } from 'lodash';
import {
  type DeltaType,
  type Hedge,
  isSingleUnderlyingDerivativeProduct,
} from '../../../../../business/neosModel';
import { roundUndefined } from '../../../../share/formatters';

export type MixedDeltaTypes = Exclude<DeltaType, 'RISK'>;

interface DeltaDataBase {
  isDeltaExchangeOtcAvailable: boolean;
}

interface MixedDeltaData extends DeltaDataBase {
  deltaType: 'DELTA_MIXED';
  values: Record<MixedDeltaTypes, number | undefined>;
}

interface UniqueDeltaData extends DeltaDataBase {
  deltaType: DeltaType | undefined;
  value: number | undefined;
}

type DeltaData = MixedDeltaData | UniqueDeltaData;

export type UiDeltaType = DeltaData['deltaType'];

interface HedgeByUnderlyingModel {
  underlyingId: string;
  vega: number | undefined;
  vegaUnit: string | undefined;
  deltaData: DeltaData;
  isDeltaDisabled: boolean;
  deltaSum: number | undefined;
  fairDelta: number | undefined;
  warningModel: HedgeByUnderlyingModelWarning;
}

interface HedgeByUnderlyingModelWithWarning {
  isWarningDisplayed: true;
  warningMessage: string;
}

interface HedgeByUnderlyingModelWithoutWarning {
  isWarningDisplayed: false;
}

type HedgeByUnderlyingModelWarning =
  | HedgeByUnderlyingModelWithWarning
  | HedgeByUnderlyingModelWithoutWarning;

export interface HedgeModel {
  underlyingsModel: HedgeByUnderlyingModel[];
  isDeltaTypeDisabled: boolean;
  availableDeltaTypes: UiDeltaType[];
  isDeltaMixedEnabled: boolean;
}

export function getHedgeModel(state: AppState, rfqId: string, selectors: Selectors): HedgeModel {
  const { isFeatureToggleEnabled } = selectors;

  const availableDeltaTypes: UiDeltaType[] = ['DELTA_ADJUSTED', 'DELTA_EXCHANGE', 'RISK'];

  const isDeltaMixedEnabled = isFeatureToggleEnabled(state, 'neos.rfq.delta.mixed.enabled');
  if (isDeltaMixedEnabled) {
    availableDeltaTypes.push('DELTA_MIXED');
  }

  const { status, strategyIds } = selectors.getRfqData(state, rfqId);
  const isDeltaTypeDisabled = orderOf(status).isAfter('ORDER_ACCEPTED');
  const references = selectors.getNotAggregatedReferences(state, rfqId, selectors);
  const allProducts = flatten(
    strategyIds.map(stratId => {
      return selectors.getStrategyProducts(state, stratId, selectors);
    }),
  );

  const underlyingsModel = references.map(({ underlyingId }): HedgeByUnderlyingModel => {
    const dataAggregationByUnderlyingId = selectors.getDataAggregationByUnderlyingId(
      state,
      rfqId,
      underlyingId,
    );
    const underlyingIdProducts = allProducts.filter(
      p => isSingleUnderlyingDerivativeProduct(p) && p.underlyingId === underlyingId,
    );
    const isListedUnderlyingId =
      underlyingIdProducts.length !== 0 &&
      underlyingIdProducts.every(product => product?.negotiationMode === 'LISTED');

    const hedges = selectors.getHedges(state, {
      rfqId,
      underlyingId,
    });
    const deltaSum =
      selectors.getDeltaSum(state, rfqId, underlyingId, selectors) ??
      dataAggregationByUnderlyingId?.greeks?.delta?.value;

    const fairDelta = dataAggregationByUnderlyingId?.fairGreeks?.delta?.value;

    const wasSetToMixedDelta = selectors.getWasSetToMixedDelta(state.ui, rfqId, underlyingId);
    const initialDeltaSum = selectors.getInitialDeltaSum(state.ui, rfqId, underlyingId);

    const deltaNotificationChangesState = state.warnings.deltaNotificationChanges;

    const fieldChange = selectors.deltaNotificationChangesCrudSelectors.find(
      deltaNotificationChangesState,
      {
        underlyingId,
        rfqId,
      },
    );
    const isTrader = selectors.isTrader(state);
    const warningModel = getDeltaWarning(initialDeltaSum, deltaSum, fieldChange, isTrader);
    const isDeltaDisabled =
      services.orderOf(status).isAfterOrEqual('ORDER_CROSSED') && isListedUnderlyingId;
    return {
      underlyingId,
      vega: dataAggregationByUnderlyingId?.vegaNotional?.value,
      vegaUnit: dataAggregationByUnderlyingId?.vegaNotional?.unit,
      deltaData: getDeltaData(rfqId, hedges, wasSetToMixedDelta, state, selectors, underlyingId),
      isDeltaDisabled,
      deltaSum,
      fairDelta,
      warningModel,
    };
  });

  return {
    underlyingsModel,
    isDeltaTypeDisabled,
    availableDeltaTypes,
    isDeltaMixedEnabled,
  };
}

function getDeltaWarning(
  initialDeltaSum: number | undefined,
  deltaSum: number | undefined,
  fieldChange: FieldChange | undefined,
  isTrader: boolean,
): HedgeByUnderlyingModelWarning {
  if (isTrader && fieldChange && fieldChange.oldValue) {
    return {
      isWarningDisplayed: true,
      warningMessage: `Someone else changed the delta! Old value : ${roundUndefined(
        fieldChange.oldValue,
        2,
      )}% New value : ${roundUndefined(fieldChange.newValue, 2)}%`,
    };
  }
  if (
    initialDeltaSum !== undefined &&
    (deltaSum === undefined || Math.abs(initialDeltaSum - deltaSum) > 0.001)
  ) {
    return {
      isWarningDisplayed: true,
      warningMessage: `Delta has been changed! Original value: ${roundUndefined(
        initialDeltaSum,
        2,
      )}%`,
    };
  }
  return {
    isWarningDisplayed: false,
  };
}

function getDeltaData(
  rfqId: string,
  hedges: Hedge[],
  wasSetToMixedDelta: boolean | undefined,
  state: AppState,
  selectors: Selectors,
  underlyingId: string,
): DeltaData {
  const strategies = selectors
    .getRfqData(state, rfqId)
    .strategyIds?.map(id => selectors.getStrategyData(state, id));

  const isDeltaExchangeOtcAvailable = strategies
    .filter(({ uuid }) => {
      const stratUnderlyingId = selectors.getUnderlyingOrRefIdOfStrategy(state, uuid, selectors);

      return stratUnderlyingId === underlyingId;
    })
    .some(({ uuid }) => {
      const { negotiationMode } = selectors.getStrategyProduct(state, uuid, selectors);

      return negotiationMode === 'OTC';
    });

  if (isDeltaMixed(hedges, wasSetToMixedDelta)) {
    return {
      deltaType: 'DELTA_MIXED',
      values: {
        DELTA_ADJUSTED: hedges.find(({ deltaType }) => deltaType === 'DELTA_ADJUSTED')?.delta,
        DELTA_EXCHANGE: hedges.find(({ deltaType }) => deltaType === 'DELTA_EXCHANGE')?.delta,
        DELTA_EXCHANGE_OTC: hedges.find(({ deltaType }) => deltaType === 'DELTA_EXCHANGE_OTC')
          ?.delta,
      },
      isDeltaExchangeOtcAvailable,
    };
  }

  return {
    deltaType: hedges[0]?.deltaType,
    value: hedges[0]?.delta,
    isDeltaExchangeOtcAvailable,
  };
}

function isDeltaMixed(hedges: Hedge[], wasSetToMixedDelta: boolean | undefined) {
  return (
    (hedges.length > 1 || wasSetToMixedDelta) &&
    !hedges.find(({ deltaType }) => !deltaType || deltaType === 'RISK')
  );
}
