import { uniq } from 'lodash';
import type { UnderlyingInfo } from './underlyingInfoModel';
import type {
  OnyxMarketLotSizes,
  OnyxUnderlyingDividendFutureInfo,
  OnyxUnderlyingFutureInfo,
  OnyxUnderlyingOptionInfo,
  OnyxUnderlyingTotalReturnFutureInfo,
} from './underlyingInfoOnyxModel';

export function mapFromUnderlyingInfo(
  optionInfo: OnyxUnderlyingOptionInfo,
  futureInfo: OnyxUnderlyingFutureInfo,
  futureRefMaturities: string[],
  dividendFutureInfo: OnyxUnderlyingDividendFutureInfo,
  totalReturnFutureInfos: OnyxUnderlyingTotalReturnFutureInfo | undefined,
): UnderlyingInfo {
  const {
    underlying: { id, currency, bloombergCode, type, tradingPence, metaFlagIdn },
  } = optionInfo;

  const ifloLotSizes: number[] = uniq(
    optionInfo.marketLotSizes.flatMap(({ lotSizes }) => lotSizes),
  ).sort((a, b) => a - b);

  const ifloMarketLotSize: OnyxMarketLotSizes = {
    lotSizes: ifloLotSizes,
    market: { galaxyCode: 'IFLO', micCode: undefined },
  };

  const optionsMarketLotSizesFlex: OnyxMarketLotSizes[] = [
    ...optionInfo.marketLotSizes,
    ifloMarketLotSize,
  ];

  const mapped: UnderlyingInfo = {
    id,
    type,
    currency,
    tradingPence,
    bloombergCode,
    futureRefMaturities,
    option: {
      marketLotSizes: optionInfo.marketLotSizes.reduce(addMarketInfoAndMapLotSize, {}),
      marketLotSizesFlex: optionsMarketLotSizesFlex.reduce(addMarketInfoAndMapLotSize, {}),
      optionOnFutureMarketLotSizes: optionInfo.optionOnFutureMarketLotSizes?.reduce(
        addMarketInfoAndMapLotSize,
        {},
      ),
      maturityInfo: optionInfo.maturityInfo,
      optionOnFutureMaturityInfo: optionInfo.optionOnFutureMaturityInfo,
    },
    future: {
      marketLotSizes: futureInfo.marketLotSizes.reduce(addMarketInfoAndMapLotSize, {}),
      maturityInfo: futureInfo.maturityInfo,
    },
    dividendFuture: {
      marketLotSizes: dividendFutureInfo.marketLotSizes.reduce(addMarketInfoAndMapLotSize, {}),
      maturityInfo: dividendFutureInfo.maturities,
    },
    totalReturnFuture: totalReturnFutureInfos
      ? {
          marketLotSizes: totalReturnFutureInfos.marketLotSizes.reduce(
            addMarketInfoAndMapLotSize,
            {},
          ),
          maturityInfo: totalReturnFutureInfos.maturityInfo,
        }
      : undefined,
    metaFlagIdn,
  };

  return mapped;
}

interface LotSizes<T extends Pick<OnyxMarketLotSizes, 'lotSizes'>> {
  [key: string]: Omit<T, 'lotSizes'> & {
    lotSize: number;
  };
}

const addMarketInfoAndMapLotSize = <T extends OnyxMarketLotSizes>(
  previousMarketsInfo: LotSizes<T>,
  marketInfoToAdd: T,
): LotSizes<T> => {
  const { lotSizes, ...marketInfoWithoutLotSize } = marketInfoToAdd;
  const { market } = marketInfoToAdd;
  return {
    ...previousMarketsInfo,
    ...getLotSizes<T>(lotSizes, market.galaxyCode, market.micCode, marketInfoWithoutLotSize),
  };
};

function getLotSizes<T extends OnyxMarketLotSizes>(
  lotSizes: number[],
  marketId: string,
  marketMicCode: string | undefined | null,
  marketInfoWithoutLotSize: Omit<T, 'lotSizes'>,
): LotSizes<T> {
  return uniq(lotSizes).reduce(
    (prev, lotSize) => ({
      ...prev,
      [`${lotSize} | ${marketMicCode ?? marketId}`]: { ...marketInfoWithoutLotSize, lotSize },
    }),
    {},
  );
}
