import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { Observable } from 'rxjs';
import type { OnyxTransaction } from '../neosOnyxModel';

export interface TriggerMatchingApi {
  triggerMatching: (rfqId: string, transaction: OnyxTransaction) => Observable<void>;
}

export const createTriggerMatchingApi = (http: SgmeHttp): TriggerMatchingApi => ({
  triggerMatching(rfqId: string, transaction: OnyxTransaction) {
    const url = `api/workflow-neos/${rfqId}/matchOrders`;
    return http.post<void>({
      url,
      body: transaction,
    });
  },
});
