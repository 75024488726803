import { type Selectors, selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { sum } from 'lodash';
import { isOtcProduct } from '../../../../../../neos/business/neosModel';

import { isDefined } from '@/util/undefinedAndNull/isDefined';
import { useSelector } from 'react-redux';
import { AllocationLegSize, type AllocationTooltip } from './AllocationLegSize';
import type { LegDescriptionComponentProps } from './AveragePrice';

export function getQuantityStyleNameAndTooltipMessage(
  state: AppState,
  rfqId: string,
  strategyId: string,
  legId: string,
  selectors: Selectors,
): AllocationTooltip | undefined {
  const { numberOfLots, productId } = selectors.getLegData(state, legId);

  const product = selectors.getProduct(state, productId);

  if (numberOfLots === undefined || isOtcProduct(product)) {
    return undefined;
  }

  const order = selectors.getOrderByLegIdAndCrossWay(
    state,
    { rfqId, strategyId, legId },
    selectors,
  );

  const executions = order
    ? selectors.executionSelectors.selectObjects(state.execution, {
        orderId: order.uuid,
      })
    : [];

  if (!executions.length || executions.every(({ size }) => size?.numberOfLots === undefined)) {
    return undefined;
  }

  const executionQuantitiesSum = sum(
    executions.map(({ size }) => size?.numberOfLots).filter(isDefined),
  );

  if (executionQuantitiesSum < numberOfLots) {
    return { quantityStyleName: 'not-fully-crossed', tooltipMessage: 'Not Fully Crossed' };
  }
  if (executionQuantitiesSum > numberOfLots) {
    return { quantityStyleName: 'over-crossed', tooltipMessage: 'Over Crossed' };
  }
  return undefined;
}

export function AllocationLegSizeWithExecTooltip({
  rfqId,
  strategyId,
  legId,
}: LegDescriptionComponentProps) {
  const displayedTooltip = useSelector((state: AppState) =>
    getQuantityStyleNameAndTooltipMessage(state, rfqId, strategyId, legId, selectors),
  );
  return (
    <AllocationLegSize legId={legId} strategyId={strategyId} displayedTooltip={displayedTooltip} />
  );
}
