import type { ThunkEpic } from '@/bootstrap/epics';
import { type Dispatchable, type Thunks, thunks } from '@/bootstrap/thunks';
import type { OnyxTransaction } from '@/neos/business/neosOnyxModel';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { ofType } from 'redux-observable';
import { EMPTY } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { type LoadTransactionApi, createLoadTransactionApi } from '../apis/loadTransactionApi';
import type { OnyxError } from '../mappers/error';

export function getBulkLoadTransactionsEpic(http: SgmeHttp): ThunkEpic {
  const api = createLoadTransactionApi(http);
  return createBulkLoadTransactionsEpic(api, thunks);
}

export type BulkTransactionResponseError = {
  uuid: string;
  transaction: null;
  errorMessage: string;
};
export type BulkTransactionResponseSuccess = {
  uuid: string;
  transaction: OnyxTransaction;
};
export type BulkTransactionResponse = BulkTransactionResponseSuccess | BulkTransactionResponseError;

function isBulkTransactionResponseSuccess(
  response: BulkTransactionResponse,
): response is BulkTransactionResponseSuccess {
  return !Object.hasOwn(response, 'errorMessage');
}

function isBulkTransactionResponseError(
  response: BulkTransactionResponse,
): response is BulkTransactionResponseError {
  return Object.hasOwn(response, 'errorMessage');
}

export function createBulkLoadTransactionsEpic(
  api: LoadTransactionApi,
  { neos: { createLoadTransactionsThunk }, createErrorToasterThunk }: Thunks,
): ThunkEpic {
  return action$ =>
    action$.pipe(
      ofType('BULK_LOAD_TRANSACTIONS_REQUESTED'),
      mergeMap(({ rfqIds }) => {
        if (rfqIds.length === 0) {
          return EMPTY;
        }

        return api.bulkLoadTransactions(rfqIds).pipe(
          mergeMap(responses => {
            const actions: Dispatchable[] = [];

            const errors = responses.filter(isBulkTransactionResponseError);
            if (errors.length > 0) {
              const message = `Error when loading ${
                rfqIds.length === 1
                  ? 'the RFQ'
                  : errors.length === rfqIds.length
                    ? 'all RFQs'
                    : errors.length > 1
                      ? 'some RFQs'
                      : 'one RFQ'
              }`;
              actions.push(
                createErrorToasterThunk(
                  {
                    message,
                  },
                  undefined,
                ),
              );
            }

            const transactions = responses
              .filter(isBulkTransactionResponseSuccess)
              .map(response => response.transaction);

            actions.push(createLoadTransactionsThunk(transactions, true));

            return actions;
          }),
          catchError((error: OnyxError) => {
            return [
              createErrorToasterThunk(
                {
                  message: 'Error when loading RFQs',
                },
                error,
              ),
            ];
          }),
        );
      }),
    );
}
