import { services } from '@/bootstrap/services';
import { clientWays, idbWays } from '@/neos/business/rfq/rfqData/rfqDataModel';
import type { OnyxRfq } from '@/neos/business/rfq/rfqOnyxModel';
import type { BlotterModelGetters } from '.';

export function getBlotterClientWayLabel(rfq: OnyxRfq, g: BlotterModelGetters) {
  const clientWay = g.getBlotterClientWay(rfq, services);
  if (rfq.internal) {
    return idbWays[clientWay];
  }
  return clientWays[clientWay];
}
