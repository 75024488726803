import type { AppState } from '@/bootstrap/state';

export function getUnderlyingDividendFutureMaturities(
  state: AppState,
  underlyingId: string,
): string[] {
  return state.underlyingState[underlyingId]
    ? state.underlyingState[underlyingId].dividendFuture.maturityInfo
    : [];
}
