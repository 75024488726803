import type { Dispatchable, Thunk } from '@/bootstrap/thunks';
import type { NoSuccessToasterChainOptions } from '@/neos/business/neosActions';
import type { RecomputeScope } from '../models/recomputeModel';

export function createRecomputeRfqThunk(
  rfqId: string,
  scope: RecomputeScope,
  dispatchable: Dispatchable,
  chainOptions?: NoSuccessToasterChainOptions,
): Thunk {
  return function recomputeRfqThunk(
    dispatch,
    _,
    {
      actionCreators: {
        neos: { createRecomputeRfqRequestedAction },
      },
    },
  ) {
    dispatch(dispatchable, createRecomputeRfqRequestedAction(rfqId, scope, chainOptions));
  };
}
