import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import {
  DATE_SHORT_KEBAB_FORMAT,
  DATE_SHORT_TIME_FORMAT,
  formatDateInTimeZone,
} from '@/util/date/dateFormatHelper';

export interface PriceRequestDateModel {
  date: string | undefined;
  time: string | undefined;
  isDisabled: boolean;
}

export function getPriceRequestDateModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): PriceRequestDateModel {
  const { isReadOnlyRfq, getRfqData } = selectors;
  const { getDisplayTimeZone } = selectors;
  const { priceRequestUTCDateAndTime: utc } = getRfqData(state, rfqId);
  const displayTimeZone = getDisplayTimeZone(state.ui.userPreferences);

  const dateTime = utc
    ? {
        date: formatDateInTimeZone(displayTimeZone, utc, DATE_SHORT_KEBAB_FORMAT),
        time: formatDateInTimeZone(displayTimeZone, utc, DATE_SHORT_TIME_FORMAT),
      }
    : {
        date: undefined,
        time: undefined,
      };
  return {
    ...dateTime,
    isDisabled: isReadOnlyRfq(state, rfqId),
  };
}
