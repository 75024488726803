import { PredealChecksDetails } from './predealCheckDetails';
import { PredealChecksTable } from './predealChecksTable';
import { PttPredealChecks } from './pttPredealChecks';
import { RequestPredealChecks } from './requestPredealChecks';

import { RequestPDCBrokerComponent } from '@/neos/components/rfq/predealChecks/predealChecksScreen/requestPredealChecks/RequestPDCBrokerComponent';
import styles from './PredealChecksScreen.module.scss';

export interface PredealChecksScreenOwnProps {
  rfqId: string;
}

export interface PredealChecksScreenModel {
  selectedPredealCheckId?: string;
  hasDelta: boolean;
}

export const PredealChecksWithStyle = ({
  rfqId,
  selectedPredealCheckId,
  hasDelta,
}: PredealChecksScreenModel & PredealChecksScreenOwnProps) => (
  <section className={`${styles['predeal-checks-container']} `}>
    <section className={`${styles['request-ptt-panels']}`}>
      <RequestPredealChecks rfqId={rfqId} />
      <PttPredealChecks rfqId={rfqId} />
    </section>
    <section className="card-bordered overflow-auto">
      <div className="card-body px-1 pb-0">
        <PredealChecksTable className="mt-4" rfqId={rfqId} level="ALLOCATION" />
        {hasDelta && <PredealChecksTable className="mt-5" rfqId={rfqId} level="DELTA" />}
      </div>
    </section>
    <RequestPDCBrokerComponent rfqId={rfqId} />
    {selectedPredealCheckId && <PredealChecksDetails rfqId={rfqId} />}
  </section>
);
