import { Component, type ComponentType } from 'react';

interface Props {
  value?: number;
  reset?: number;
  onChange(value: string | undefined): void;
}

interface WithAcceleratorProps {
  disableAccelerators?: boolean;
}

interface State {
  propsValue?: number;
  inputValue?: string;
  acceleratedValue?: number;
  reset?: number;
}

const acceleratorRegexp = new RegExp('k|K|m|M', 'g');

type AccelerationFunction = (value: number) => number;

type AcceleratorKey = 'k' | 'K' | 'm' | 'M';

const accelerators: Record<AcceleratorKey, AccelerationFunction> = {
  k: (value: number) => value * 1000,
  K: (value: number) => value * 1000,
  m: (value: number) => value * 1000000,
  M: (value: number) => value * 1000000,
};

const convertNumericalValueToString = (value: number | undefined): string => {
  return value?.toString() ?? '';
};
type AllProps<P extends Props> = Omit<P & WithAcceleratorProps, 'onChange'>;

export function withAccelerator<P extends Props>(
  CustomComponent: ComponentType<P>,
): ComponentType<AllProps<P>> {
  return class ComponentWithAccelerator extends Component<AllProps<P>, State> {
    public static getDerivedStateFromProps = (props: AllProps<P>, state: State): State | null => {
      if (props.value !== state.propsValue || props.reset !== state.reset) {
        return {
          propsValue: props.value,
          inputValue: convertNumericalValueToString(props.value),
          acceleratedValue: undefined,
          reset: props.reset,
        };
      }
      return null;
    };

    constructor(props: AllProps<P>) {
      super(props);
      this.state = {
        propsValue: props.value,
        inputValue: convertNumericalValueToString(props.value),
        reset: props.reset,
      };
    }

    private onWrappedComponentChange = (inputValue: string): void => {
      const acceleratorKey: AcceleratorKey | undefined = this.extractAccelerator(inputValue);
      if (acceleratorKey === undefined || this.props.disableAccelerators) {
        this.setState(
          {
            ...this.state,
            inputValue,
          },
          this.forceUpdate.bind(this),
        );
        return;
      }
      const valueWithoutAccelerator: string = this.removeAccelerator(inputValue);
      const valueAsNumber: number | undefined =
        this.convertStringValueToNumber(valueWithoutAccelerator);
      const acceleratedValue: number | undefined =
        valueAsNumber === undefined
          ? valueAsNumber
          : this.accelerate(acceleratorKey, valueAsNumber);
      this.setState(
        {
          ...this.state,
          inputValue,
          acceleratedValue,
        },
        this.forceUpdate.bind(this),
      );
    };

    private extractAccelerator = (value: string): AcceleratorKey | undefined => {
      const result: RegExpMatchArray | null = value.match(acceleratorRegexp);
      if (result === null || result.length === 0) {
        return undefined;
      }
      return result[0] as AcceleratorKey;
    };

    private removeAccelerator = (value: string): string => {
      return value.replace(acceleratorRegexp, '');
    };

    private accelerate = (acceleratorKey: AcceleratorKey, value: number): number => {
      return accelerators[acceleratorKey](value);
    };

    private convertStringValueToNumber = (value: string): number | undefined => {
      if (value === '') {
        return undefined;
      }
      const parsedValue: number = parseFloat(value);
      return isNaN(parsedValue) ? undefined : parsedValue;
    };

    private getValue = (): string | number | undefined => {
      return this.state.acceleratedValue !== undefined
        ? this.state.acceleratedValue
        : this.state.inputValue;
    };

    public render = () => {
      const { value, disableAccelerators, ...restProps } = this.props;

      return (
        <CustomComponent
          {...(restProps as P)}
          value={this.getValue()}
          onChange={this.onWrappedComponentChange}
        />
      );
    };
  };
}
