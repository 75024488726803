import { type ComponentType, forwardRef } from 'react';
import styles from './withStyle.module.scss';

interface Props {
  value?: number;
}

export type FinancialColor = 'BID' | 'ASK';

interface WithStylesProps {
  color?: FinancialColor;
  bold?: boolean;
  warning?: boolean;
  className?: string;
  styleName?: string;
}

export function withStyle<P extends Props>(CustomComponent: ComponentType<P>) {
  const ComponentWithStyle = forwardRef<HTMLDivElement, P & WithStylesProps>((props, ref) => {
    const { color, bold, warning, className, styleName, ...rest } = props;
    return (
      <div
        className={`${computeClassName({ color, className })} ${computeStyleName({
          bold,
          warning,
          styleName,
        })}`}
        ref={ref}
      >
        <CustomComponent {...(rest as P)} />
      </div>
    );
  });
  return ComponentWithStyle;
}

function computeClassName({ color, className }: WithStylesProps): string {
  const colorClassName: string =
    color !== undefined ? (color === 'BID' ? 'bid-color' : 'ask-color') : '';
  return `${colorClassName ?? ''} ${className ?? ''}`;
}

function computeStyleName({ bold, warning, styleName }: WithStylesProps): string {
  const boldStyleName: string = bold === true ? 'fw-bold' : '';
  const warningStyleName: string = warning ? 'warning' : '';
  return ` ${styles['force-color'] ?? ''} ${boldStyleName} ${styles[warningStyleName] ?? ''} ${
    styleName ? styles[styleName] : ''
  }`;
}
