import { Component } from 'react';
import { Modal } from 'react-bootstrap';
import type { TradeRecapButtonModel } from './getTradeRecapButtonModel';

import styles from './TradeRecapButton.module.scss';

export interface TradeRecapButtonOwnProps {
  rfqId: string;
}

export interface TradeRecapButtonPropsFromState {
  model: TradeRecapButtonModel;
}

export interface TradeRecapButtonDispatchProps {
  onTradeRecapModalShown(): void;
  onTradeRecapModalHidden(): void;
  sendDrafTradeRecap: (comment: string) => void;
  onCopyToClipboard: (text: string) => void;
}

export type TradeRecapButtonProps = TradeRecapButtonOwnProps &
  TradeRecapButtonPropsFromState &
  TradeRecapButtonDispatchProps;

interface TradeRecapButtonState {
  comment: string;
  initialTradeRecap: string;
}

export class TradeRecapButtonComponentWithCss extends Component<
  TradeRecapButtonProps,
  TradeRecapButtonState
> {
  public state = {
    comment: '',
    initialTradeRecap: this.props.model.tradeRecap,
  };

  public render() {
    const {
      onTradeRecapModalShown,
      onTradeRecapModalHidden,
      sendDrafTradeRecap,
      onCopyToClipboard,
      model: { isTradeRecapButtonEnabled, isShowTradeRecapModal },
    } = this.props;
    return (
      <>
        <button
          className="btn btn-primary"
          disabled={!isTradeRecapButtonEnabled}
          onClick={onTradeRecapModalShown}
          data-e2e="neos-rfq-trade-recap"
        >
          Trade Recap
        </button>
        <Modal show={isShowTradeRecapModal} onHide={onTradeRecapModalHidden}>
          <Modal.Header closeButton>
            <Modal.Title>Trade Recap</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={`${styles.title}`}>Trade recap:</div>
            <textarea
              className={`${styles['trade-recap-area']} form-control`}
              value={this.props.model.tradeRecap}
              disabled={true}
            />

            <div className={`${styles.title}`}>Comment:</div>
            <textarea
              className={`${styles['trade-recap-area']} form-control`}
              value={this.state.comment}
              onChange={e => {
                this.setState({ comment: e.target.value });
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-lg btn-flat-secondary"
              onClick={() => {
                sendDrafTradeRecap(this.state.comment);
                onTradeRecapModalHidden();
              }}
            >
              Draft recap
            </button>
            <button
              className="btn btn-lg btn-flat-secondary"
              onClick={() =>
                onCopyToClipboard(`${this.props.model.tradeRecap}\n${this.state.comment}`)
              }
            >
              Copy to clipboard
            </button>
            <button className="btn btn-lg btn-primary" onClick={onTradeRecapModalHidden}>
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
