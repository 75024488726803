import type { StrategyType } from '@/neos/business/neosModel';
import { ErrorHighlight } from '@/neos/components/share/errorHighlight';
import { NeosSelectWithAutocomplete } from '@/neos/components/share/neosSelectWithAutocomplete/NeosSelectWithAutocomplete';
import type { StrategyTypeCellModel } from './getStrategyTypeCellModel';

export interface StrategyTypeCellOwnProps {
  rfqId: string;
  strategyId: string;
  isReadOnlyRfq: boolean;
}

export type StrategyTypeCellMapStateProps = StrategyTypeCellModel;

export interface StrategyTypeCellDispatchProps {
  onStrategyTypeChanged(strategyType: StrategyType): void;
}

type StrategyTypeCellProps = StrategyTypeCellOwnProps &
  StrategyTypeCellMapStateProps &
  StrategyTypeCellDispatchProps;

export const StrategyTypeCellUnconnected = (props: StrategyTypeCellProps) => {
  return (
    <section>
      <ErrorHighlight
        errorField={'strategyType'}
        related={{ rfqId: props.rfqId, strategyId: props.strategyId }}
      >
        <NeosSelectWithAutocomplete
          isLabelBold
          data-e2e="neos-strategy-definition-strategy-type"
          isReadOnly={props.isReadOnlyRfq}
          value={props.selectedStrategyType}
          isDisabled={props.disabled}
          onChange={selected => {
            props.onStrategyTypeChanged(selected?.value as StrategyType);
          }}
          options={props.availableStrategyTypes.map(({ name, id, alias }) => ({
            value: id,
            label: name,
            data: [alias],
          }))}
        />
      </ErrorHighlight>
    </section>
  );
};
