import { FlowDatePicker } from '@/neos/components/share/datePicker';
import { ErrorHighlight } from '@/neos/components/share/errorHighlight';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import type { FC } from 'react';
import type { PriceRequestDateModel } from './getPriceRequestDateModel';

export interface PriceRequestDateOwnProps {
  rfqId: string;
}

export type PriceRequestDateMapStateToProps = PriceRequestDateModel;

export interface PriceRequestDateDispatchProps {
  onDateChanged(newDate: string): void;

  onTimeChanged(newTime: string): void;
}

export const PriceRequestDateComponent: FC<
  PriceRequestDateOwnProps & PriceRequestDateMapStateToProps & PriceRequestDateDispatchProps
> = ({ rfqId, date, time, isDisabled, onDateChanged, onTimeChanged }) => (
  <SimpleNeosTooltip id={'price-request-date'} message={'Price Request Date'}>
    <section className="d-grid" style={{ gridTemplateColumns: '90px 90px' }}>
      <ErrorHighlight errorField="rfqPriceRequestDate" related={{ rfqId }}>
        <FlowDatePicker
          readOnly={isDisabled}
          onChange={val => onDateChanged(val)}
          maturities={[]}
          date={date || ''}
          data-e2e="neos-rfq-price-request-date"
          hideCalendarIcon
          className="errorable-bloc"
          inputClassName="form-control-alt"
        />
      </ErrorHighlight>

      <ErrorHighlight errorField={'rfqPriceRequestDate'} related={{ rfqId }}>
        <input
          className="form-control errorable-bloc"
          type="time"
          value={time || ''}
          onChange={event => onTimeChanged(event.target.value)}
          disabled={isDisabled}
          data-e2e="neos-rfq-price-request-time"
        />
      </ErrorHighlight>
    </section>
  </SimpleNeosTooltip>
);
