import type { Selectors } from '@/bootstrap/selectors';
import type { Status, SubStatus } from '../../../../neos/business/neosModel';

export function getDisplayStatus(
  status: Status,
  subStatus: SubStatus | undefined,
  selectors: Selectors,
): string {
  return selectors.getStatusLabel(status, subStatus);
}
