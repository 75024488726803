import type { Thunk } from '@/bootstrap/thunks';
import type { ElsBasket } from '@/neos/business/rfq/strategy/leg/product/productModel';

export function createUpdateAllBasketSpotConfirmedThunk(
  rfqId: string,
  product: ElsBasket,
  newValue: boolean,
): Thunk {
  return function updateAllBasketSpotConfirmedThunk(
    dispatch,
    getState,
    {
      selectors: { selectElsBasketReferences },
      actionCreators: {
        neos: { referenceCrudActions },
      },
    },
  ) {
    const { update } = referenceCrudActions;
    const references = selectElsBasketReferences(getState(), rfqId, product);
    for (const reference of references) {
      dispatch(
        update({ rfqId, underlyingId: reference.underlyingId }, { isSpotConfirmed: newValue }),
      );
    }
  };
}
