import type { FC } from 'react';
import { ButtonWithConfirmModal } from '../../../../../share/buttonWithConfirm/ButtonWithConfirmModal';

export interface DeltaAdjustedAmendButtonOwnProps {
  rfqId: string;
}

export interface DeltaAdjustedAmendButtonDispatchProps {
  onSave(): void;
}

export interface DeltaAdjustedAmendButtonStateProps {
  isDisabled: boolean;
}

export type DeltaAdjustedAmendButtonProps = DeltaAdjustedAmendButtonOwnProps &
  DeltaAdjustedAmendButtonDispatchProps &
  DeltaAdjustedAmendButtonStateProps;

export const DeltaAdjustedAmendButtonComponent: FC<DeltaAdjustedAmendButtonProps> = ({
  onSave,
  isDisabled,
}) => {
  const modalBody = [
    'You are about to amend orders, change(s) on rfq will also be saved.',
    'Do you confirm?',
  ];
  return (
    <ButtonWithConfirmModal
      isDisabled={isDisabled}
      onConfirm={() => onSave()}
      buttonText="Amend"
      title="Amend order"
      data-e2e={'neos-rfq-buttonConfirm-amend'}
      modalBody={modalBody}
    />
  );
};
