import { v4 as uuidv4 } from 'uuid';

export const idCreator = (function () {
  let index = 0;

  return {
    createId(): string {
      const queryString = window.location.search;
      if (!queryString.length || window.location.origin === 'https://eqdflow.sgmarkets.com') {
        return uuidv4();
      }
      const randomLetters = !queryString.includes('&')
        ? queryString.split('random=')[1].split(',')
        : queryString.split('random=')[1].split('&')[0].split(',');
      const randomNumbers = randomLetters.map(l => parseInt(l));

      const v4options = {
        random: [...randomNumbers, index++],
      };

      const mockedId = uuidv4(v4options);
      return mockedId;
    },
  };
})();
