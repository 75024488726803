import { convertNullToUndefined } from '@/util/undefinedAndNull/convertNullToUndefined';
import type {
  OnyxEquityFinance,
  OnyxFeature,
  OnyxProductFeaturesFields,
} from '../../strategyOnyxModel';
import type { EquityFinanceFeature } from '../featureModel';

import { hasDiscriminator } from '@/neos/business/rfq/strategy/feature/hasDiscriminator';

export function mapFromOnyxFeatureOrExtraFeaturesEquityFinance(
  extraFeatures: OnyxFeature[],
  strategyId: string,
): EquityFinanceFeature | undefined {
  const equityFinanceFeature: OnyxEquityFinance | undefined = extraFeatures?.find(
    hasDiscriminator<OnyxEquityFinance>('EQUITY_FINANCE'),
  );

  if (equityFinanceFeature) {
    return {
      type: 'EQUITY_FINANCE',
      strategyId,
      equityFinance: convertNullToUndefined(equityFinanceFeature.equityFinance),
    };
  }

  return undefined;
}

export function mapToOnyxEquityFinance(feature: EquityFinanceFeature): OnyxProductFeaturesFields {
  const { equityFinance } = feature;

  return {
    extraFeatures: [
      {
        discriminator: 'EQUITY_FINANCE',
        equityFinance: equityFinance === undefined ? null : equityFinance,
      },
    ],
  };
}
