import type { FC } from 'react';

import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import styles from './FlowDatePicker.module.scss';

export type Category = 'LISTED' | 'TENORS';

interface ShortcutCategorySwitcherProps {
  displayed: boolean;
  currentCategory: Category;
  onClick: () => void;
}

export const ShortcutCategorySwitcher: FC<ShortcutCategorySwitcherProps> = ({
  displayed,
  currentCategory,
  onClick,
}) => {
  if (!displayed) {
    return null;
  }

  const [from, to] = currentCategory === 'LISTED' ? ['Listed', 'Tenors'] : ['Tenors', 'Listed'];

  return (
    <SimpleNeosTooltip id="switch-category" message={`Switch to ${to} view`}>
      <button
        data-e2e="btn-listed"
        type="button"
        className={`${styles['btn-switch-maturities']} btn btn-primary`}
        onClick={onClick}
      >
        {from}
      </button>
    </SimpleNeosTooltip>
  );
};
