import { selectors } from '@/bootstrap/selectors';
import { services } from '@/bootstrap/services';
import type { AppState } from '@/bootstrap/state';
import type { FC } from 'react';
import { useSelector } from 'react-redux';
import { MdpRfqTimerComponent, type MdpRfqTimerOwnProps } from '../MdpRfqTimer';
import { type RfqTimerModel, getMdpRfqValidityTimerModel } from './getMdpRfqValidityTimerModel';

export const MdpRfqValidityTimer: FC<MdpRfqTimerOwnProps> = ownProps => {
  const model = useSelector<AppState, RfqTimerModel>(state => {
    return getMdpRfqValidityTimerModel(state, ownProps.rfqId, selectors, services);
  });

  if (model.isMdpRfqTimerDisplay) {
    const { isMdpRfqTimerDisplay, ...rest } = model;
    return <MdpRfqTimerComponent {...rest} {...ownProps} />;
  }
  return null;
};
