import type { Thunk } from '@/bootstrap/thunks';
import { type AsianPeriod, isProductWithAsianFields } from '@/neos/business/neosModel';

export function createDeleteAsianOptionPeriodDateThunk(
  productId: string,
  dateIndex: number,
): Thunk {
  return function deleteAsianOptionPeriodDateThunk(
    dispatch,
    getState,
    { actionCreators, selectors },
  ) {
    const state = getState();
    const product = selectors.getProduct(state, productId);

    if (!isProductWithAsianFields(product)) {
      return;
    }

    const oldPeriod: AsianPeriod = product.period ?? {
      dates: [],
      endDate: '',
      frequency: undefined,
      startDate: '',
      includeEndDate: undefined,
    };

    dispatch(
      actionCreators.neos.productCrudActions.update(product.uuid, {
        period: {
          ...oldPeriod,
          dates: oldPeriod.dates.filter((_, index) => index !== dateIndex),
        },
      }),
    );
  };
}
