import type { OnyxTransaction } from '@/neos/business/neosOnyxModel';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { Observable } from 'rxjs';
import type { CancelNegotiationApi } from '../epics/cancelNegotiationEpic';

export const cancelNegotiationApi = (http: SgmeHttp): CancelNegotiationApi => ({
  cancelNegotiation(transaction: OnyxTransaction): Observable<OnyxTransaction> {
    const { uuid } = transaction.rfq;
    const url: string = `api/workflow-neos/${uuid}/cancel`;
    return http.post<OnyxTransaction>({
      url,
      body: transaction,
    });
  },
});
