import type { Thunk } from '@/bootstrap/thunks';
import type { OnyxRfq } from '@/neos/business/rfq/rfqOnyxModel';
import { sumBy } from 'lodash';
import type { OnyxHedge } from '../rfq/rfqOnyxModel';

export function createNeosIntegrateRfqDeltaDifferenceNotificationThunk(
  rfqId: string,
  onyxNotificationRfq: OnyxRfq,
): Thunk {
  return function neosIntegrateRfqDeltaDifferenceNotificationThunk(
    dispatch,
    getState,
    {
      actionCreators: {
        neos: { createDeltaNotificationChangesCrudAction, rfqUiCrudActions },
      },
      selectors,
    },
  ) {
    const appState = getState();

    const rfqIsOpen = selectors.tabExists(appState.ui.tabManagement, rfqId);
    if (!rfqIsOpen) {
      return;
    }

    const isTrader = selectors.isTrader(appState);
    if (!isTrader) {
      return;
    }

    const currentHedges = selectors.getHedges(appState, { rfqId });
    if (currentHedges.every(h => h.delta === undefined)) {
      return;
    }

    const notificationHedges = onyxNotificationRfq.hedges ?? [];

    const currentDeltaSumByUnderlyingId = currentHedges.reduce<Record<string, number | undefined>>(
      (previousDictionary, currentHedge) => {
        previousDictionary[currentHedge.underlyingId] = selectors.getDeltaSum(
          appState,
          rfqId,
          currentHedge.underlyingId,
          selectors,
        );
        return previousDictionary;
      },
      {},
    );

    const notificationDeltaSumByUnderlyingId = computeDeltaSumByUnderlying(notificationHedges);
    const currentUnderlyingIds = Object.keys(currentDeltaSumByUnderlyingId);
    const notificationUnderlyingIds = Object.keys(notificationDeltaSumByUnderlyingId);
    let showModal = false;
    notificationUnderlyingIds.forEach(underlyingId => {
      if (
        currentDeltaSumByUnderlyingId[underlyingId] !==
        notificationDeltaSumByUnderlyingId[underlyingId]
      ) {
        dispatch(
          createDeltaNotificationChangesCrudAction.upsert(
            { underlyingId, rfqId },
            {
              oldValue: currentDeltaSumByUnderlyingId[underlyingId],
              newValue: notificationDeltaSumByUnderlyingId[underlyingId],
            },
          ),
        );
        showModal = true;
      }
      if (!currentUnderlyingIds.includes(underlyingId)) {
        dispatch(createDeltaNotificationChangesCrudAction.delete({ underlyingId, rfqId }));
      }
    });
    if (showModal) {
      dispatch(rfqUiCrudActions.update(rfqId, { showWarningModal: true }));
    }
  };
}

function computeDeltaSumByUnderlying(hedges: OnyxHedge[]): Record<string, number | undefined> {
  return hedges.reduce<Record<string, number | undefined>>(
    (previousDictionary, currentNotificationHedge) => {
      const underlyingDeltas = hedges.filter(
        hedge => hedge.underlyingId === currentNotificationHedge.underlyingId,
      );
      const noDelta = underlyingDeltas?.every(({ greeks }) => greeks?.delta?.value === undefined);
      const totalDelta = sumBy(underlyingDeltas, ({ greeks }) => greeks?.delta?.value ?? 0);
      previousDictionary[currentNotificationHedge.underlyingId] = noDelta ? undefined : totalDelta;
      return previousDictionary;
    },
    {},
  );
}
