import { DATE_SHORT_KEBAB_FORMAT } from '@/util/date/dateFormatHelper';
import { format, parse } from 'date-fns';
import { type KeyboardEvent, useRef } from 'react';
import type DatePicker from 'react-datepicker';
import { getKebabFormattedDateFromInput } from './getKebabFormattedDateFromInput';

export const useFlowDatePicker = (date: string, onChange: (selectedDate: string) => void) => {
  const ref = useRef<DatePicker>(null);
  const customInputRef = useRef<HTMLInputElement>(null);

  const convertedDate = date ? parse(date, DATE_SHORT_KEBAB_FORMAT, 0) : null;

  const onInputClick = () => {
    return (ref?.current as any)?.input?.select();
  };

  const closeDatePicker = () => ref.current?.setOpen(false);

  const onDatePickerChange = (inputDate: Date) => {
    const formattedDate = format(inputDate, DATE_SHORT_KEBAB_FORMAT);
    if (date !== formattedDate) {
      onChange(formattedDate);
    }
  };

  const submitDateInput = (inputValue: string | null) => {
    const newDate = getKebabFormattedDateFromInput(inputValue, [], []);
    if (newDate.type === 'DATE' && newDate.date !== date) {
      onChange(newDate.date);
    }
  };

  const onKeyDown = ({ key }: KeyboardEvent<HTMLInputElement>) => {
    if (key === 'Tab') {
      closeDatePicker();
    }
  };

  return {
    ref,
    customInputRef,
    convertedDate,
    onInputClick,
    closeDatePicker,
    onDatePickerChange,
    submitDateInput,
    onKeyDown,
  };
};
