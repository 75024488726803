import { type ActionCreators, actionCreators } from '@/bootstrap/actions';
import type { ThunkEpic } from '@/bootstrap/epics';
import { type Selectors, selectors } from '@/bootstrap/selectors';
import { type Thunks, thunks } from '@/bootstrap/thunks';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { logger } from '@/util/logging/logger';
import { mt } from '@/util/logging/messageTemplates';
import { ofType } from 'redux-observable';
import type { Observable } from 'rxjs';
import { catchError, filter, mergeMap, takeUntil } from 'rxjs/operators';
import { type OnyxMappers, mappers } from '../../mappers';
import type { OnyxError } from '../../mappers/error';
import type { OnyxRfq } from '../../neosOnyxModel';
import { createRequestSimilarActivitiesApi } from '../apis';

export interface RequestSimilarActivitiesApi {
  requestSimilarActivities: (rfq: OnyxRfq) => Observable<OnyxRfq[]>;
}

export function getRequestSimilarActivitiesEpic(http: SgmeHttp): ThunkEpic {
  const api = createRequestSimilarActivitiesApi(http);
  return createRequestSimilarActivitiesEpic(api, selectors, actionCreators, thunks, mappers);
}

export function createRequestSimilarActivitiesEpic(
  api: RequestSimilarActivitiesApi,
  selectorsParameter: Selectors,
  {
    common: { createAppCrashedAction },
    neos: { createSimilarActivitiesReceivedAction },
  }: ActionCreators,
  { createSuccessToasterThunk, createErrorToasterThunk }: Thunks,
  { toOnyxMappers }: OnyxMappers,
): ThunkEpic {
  return (action$, state$) =>
    action$.pipe(
      ofType('REQUEST_SIMILAR_ACTIVITIES'),
      mergeMap(({ rfqId }) => {
        try {
          const onyxRfq = toOnyxMappers.mapToOnyxRfqWithLatestLocalVersion(
            state$.value,
            rfqId,
            selectorsParameter,
            toOnyxMappers,
          );
          return api.requestSimilarActivities(onyxRfq).pipe(
            mergeMap(similarActivities => [
              createSimilarActivitiesReceivedAction(rfqId, similarActivities),
              createSuccessToasterThunk({
                message: `${similarActivities.length} similar activit${
                  similarActivities.length === 1 ? 'y' : 'ies'
                } retrieved!`,
              }),
            ]),
            catchError((error: OnyxError) => [
              createErrorToasterThunk(
                {
                  message: 'An error has occured when retrieving<br /> similar activities',
                },
                error,
              ),
            ]),
            takeUntil(
              action$.pipe(
                ofType('REQUEST_SIMILAR_ACTIVITIES'),
                filter(a => a.rfqId === rfqId),
              ),
            ),
          );
        } catch (error) {
          logger.error(
            mt.unhandledException,
            'Mapping error when retrieving similar activities',
            error,
          );
          return [
            createErrorToasterThunk(
              {
                message: 'An error has occured when retrieving<br /> similar activities',
              },
              undefined,
            ),
          ];
        }
      }),
      catchError(error => [createAppCrashedAction('retrieve-similar-activities', error)]),
    );
}
