import { useAppSelector } from '@/bootstrap/hooks';
import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import type { ExcelLanguage } from '@/neos/business/ui/userPreferences/userPreferencesUiModel.ts';

export function ExcelLanguagePreferences() {
  const dispatch = useDispatch<ExtendedDispatch>();

  const changeExcelLanguage = (excelLanguage: ExcelLanguage) => {
    dispatch(
      createLogAnalyticsAction('NEOS USER PREFERENCES', 'Change excel language'),
      neosActionCreators.createExcelLanguageChangedAction(excelLanguage),
    );
  };

  const excelLanguageUserPref = useAppSelector(state => state.ui.userPreferences.excelLanguage);

  const navigatorLanguage: ExcelLanguage = navigator.language.includes('en') ? 'EN' : 'FR';

  return (
    <div className="d-grid mt-4">
      <span className="me-2 mb-2">Excel Language</span>
      <ToggleButtonGroup
        name="excelLanguage"
        className="errorable-bloc btn-group-toggle"
        type="radio"
        onChange={changeExcelLanguage}
        value={excelLanguageUserPref}
      >
        <ToggleButton variant="toggle-primary" value="auto" id="language-auto">
          Auto ({navigatorLanguage})
        </ToggleButton>
        <ToggleButton variant="toggle-primary" value="EN" id="EN">
          English
        </ToggleButton>
        <ToggleButton variant="toggle-primary" value="FR" id="FR">
          French
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}
