import { getKeys } from '@/util/getKeys/getKeys';
import { fromPairs, zip } from 'lodash';
import type { FieldMatcher, FieldMatcherResult, HandledField } from './filedMatchersModel';
import { knownFieldMatchers } from './knownFieldMatchers';

export function getKnownFieldMatch<T extends { field: string }, V extends HandledField>(
  fieldError: T,
): FieldMatcherResult<V> | undefined {
  for (const fieldName of getKeys(knownFieldMatchers)) {
    const { regex, groupIds, fieldDescriptor } = knownFieldMatchers[fieldName] as FieldMatcher<V>;
    const match = fieldError.field.match(regex);
    if (match) {
      const groupValues: Array<string | 'ALL'> = match
        .slice(1)
        .map(value => (value === '' ? 'ALL' : value));

      const groupResults = fromPairs(
        zip(groupIds, groupValues),
      ) as FieldMatcherResult<V>['groupResults'];

      const description = fieldDescriptor(groupResults);
      const result: FieldMatcherResult<V> = {
        fieldName: fieldName as V,
        groupResults,
        description,
      };
      return result;
    }
  }
}
