import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';

type NeosSelectWithUnitProps = {
  options: Array<{ label: string; value: string }>;
  onChange: (value: string | undefined) => void;
  value: string | undefined;
  style?: any;
  className?: string;
  unit: string;
  unitTooltip?: string;
  orientation?: 'left' | 'right';
  addEmptyValue?: boolean;
  readOnly?: boolean;
};

export function NormalSelectWithUnit({
  options,
  addEmptyValue,
  className,
  onChange,
  value,
  style,
  unit,
  unitTooltip,
  orientation,
  readOnly,
}: NeosSelectWithUnitProps) {
  return (
    <div className="d-flex">
      <SimpleNeosTooltip id="unit" message={unitTooltip}>
        <label
          className="btn px-1 fw-normal bg-light bg-opacity-70 text-black"
          style={{ cursor: 'default' }}
        >
          {unit}
        </label>
      </SimpleNeosTooltip>
      <NeosSelect
        readOnly={readOnly}
        orientation={orientation}
        value={value}
        onChange={onChange}
        className={className}
        style={style}
        addEmptyOption={addEmptyValue}
        options={options}
      />
    </div>
  );
}
