import { mapCounterpartsToTypeaheadValues } from '@/neos/business/blotter/searchByCounterpart/counterpartMapToTypeheadValue';
import type { MultipleTypeaheadValue } from '@/neos/components/share/multipleAsyncTypeahead/MultipleAsyncTypeahead';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import type { Counterpart } from '../../neosModel';

const URL: string = 'api/referential/counterparts/findCounterpartsByName?counterpartName';

export interface SearchCounterpartApi {
  fetchCounterpartsByName: (prefix: string) => Observable<MultipleTypeaheadValue[]>;
}

export const makeSearchCounterpartApi = (http: SgmeHttp): SearchCounterpartApi => ({
  fetchCounterpartsByName(name: string) {
    const url = `${URL}=${name}`;
    return http
      .get<Counterpart[]>({
        url,
        timeout: 10_000,
      })
      .pipe(map(mapCounterpartsToTypeaheadValues));
  },
});
