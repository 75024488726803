import type { Thunk } from '@/bootstrap/thunks';
import type { ActionContainer } from '@/util/actionContainer';
import { flatMap } from 'lodash';
import type { LegData } from '../legModel';

export function createCloneStrategyLegsThunk(
  strategySourceId: string,
  strategyTargetId: string,
  newLegIds: string[],
): Thunk {
  return function cloneStrategyLegsThunk(
    dispatch,
    getState,
    {
      selectors: { getStrategyData, getLegData, getProduct, getQuote },
      actionCreators: {
        neos: { productCrudActions, legDataCrudActions, quoteCrudActions },
      },
    },
  ) {
    const appState = getState();
    const { legIds } = getStrategyData(appState, strategySourceId);
    const legDatasToClone = legIds.map(legId => getLegData(appState, legId));

    const actions: ActionContainer[] = flatMap(legDatasToClone, (legDataToClone, index) => {
      const newUuid = newLegIds[index];

      const quoteToClone = getQuote(appState, legDataToClone.quoteId);
      const newQuote = { ...quoteToClone };

      const productToClone = getProduct(appState, legDataToClone.productId);
      const newProduct = { ...productToClone, uuid: newUuid };
      const newLegData: LegData = {
        ...legDataToClone,
        uuid: newUuid,
        strategyId: strategyTargetId,
        productId: newUuid,
        quoteId: newUuid,
      };

      return [
        quoteCrudActions.insert(newUuid, newQuote),
        productCrudActions.insert(newUuid, newProduct),
        legDataCrudActions.insert(newUuid, newLegData),
      ];
    });

    dispatch(actions);
  };
}
