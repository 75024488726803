import type { Destination } from '../../../../neos/business/neosModel';
import type { ExternalPreconfirmationRequest } from '../../preconfirmation/external/externalOnyxPreconfirmationModel';
import type { InternalPreconfirmationRequest } from '../../preconfirmation/internal/internalOnyxPreconfirmationModel';
import type { PreconfirmationEmailPreviewAction } from './preconfirmationEmailPreviewAction';
import { preconfirmationEmailPreviewDomain } from './preconfirmationEmailPreviewDomain';

export const preconfirmationEmailPreviewActionsCreators = {
  preconfirmationEmailPreviewCrudAction: preconfirmationEmailPreviewDomain.actions,
  preconfirmationEmailPreviewRequested,
};

function preconfirmationEmailPreviewRequested(
  rfqId: string,
  destination: Destination,
  mailOptions: ExternalPreconfirmationRequest | InternalPreconfirmationRequest,
  ignoreFieldChanges = false,
): PreconfirmationEmailPreviewAction {
  return {
    type: 'LOAD_GIVE_UP_EMAIL_REQUESTED',
    rfqId,
    destination,
    mailOptions,
    ignoreFieldChanges,
  };
}
