import { thunks } from '@/bootstrap/thunks';
import type { Els } from '@/neos/business/rfq/strategy/leg/product/productModel';
import { ElsLendAndBorrowGridRow } from '@/neos/components/rfq/strategies/strategy/features/elsLendAndBorrow/ElsLendAndBorrowGridRow';
import { useDispatch } from 'react-redux';

interface ElsLendAndBorrowDescriptionProps {
  rfqId: string;
  strategyId: string;
  product: Els;
}

export function ElsLendAndBorrowComponents({
  rfqId,
  strategyId,
  product,
}: ElsLendAndBorrowDescriptionProps) {
  const dispatch = useDispatch();

  const onAddClick = () => {
    dispatch(thunks.neos.createAddNewElsStockLoanComponentThunk(product.uuid));
  };

  return (
    <div
      className="d-grid align-items-center gap-1"
      style={{ gridTemplateColumns: `32px repeat(5, 1fr) repeat(2, 180px)` }}
      onMouseDown={e => e.stopPropagation()}
    >
      <button className="btn btn-icon btn-flat-primary" onClick={onAddClick}>
        <i className="icon icon-md">add</i>
      </button>
      <label className="fw-bold">Underlying</label>
      <label className="fw-bold">Quantity</label>
      <label className="fw-bold">Fees</label>
      <label className="fw-bold">DivReq</label>
      <label className="fw-bold">AllIn</label>
      <label className="fw-bold">Lend Ptf</label>
      <label className="fw-bold">Borrow Ptf</label>

      {product.stockLoanHedge?.stockLoanComponents?.map((stockLoanComponent, index) => (
        <ElsLendAndBorrowGridRow
          product={product}
          rfqId={rfqId}
          strategyId={strategyId}
          stockLoanComponent={stockLoanComponent}
          index={index}
          key={stockLoanComponent.uuid}
        />
      ))}
    </div>
  );
}
