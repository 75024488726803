import type { Thunk } from '@/bootstrap/thunks';
import { StrategyRecomputeScope } from '../models/recomputeModel';

export function createRfqChangeBarrierStrikeUnitThunk(rfqId: string, strategyId: string): Thunk {
  return function rfqChangeBarrierStrikeUnitThunk(
    dispatch,
    getState,
    {
      actionCreators: {
        neos: { createRecomputeRfqRequestedAction },
      },
      selectors,
    },
  ) {
    const state = getState().featureState;

    const feature = selectors.getFeature(state, {
      strategyId,
      type: 'BARRIERS',
    });

    const barriers = feature?.barriers ?? [];

    if (!barriers.length) {
      return;
    }

    const currentBarrierStrikeUnit = barriers[0].limitStrikeUnit;

    const recomputeScope: StrategyRecomputeScope =
      currentBarrierStrikeUnit === '%'
        ? StrategyRecomputeScope.CHANGE_BARRIERS_TO_CURRENCY_UNIT
        : StrategyRecomputeScope.CHANGE_BARRIERS_TO_PERCENT_UNIT;

    dispatch(createRecomputeRfqRequestedAction(rfqId, recomputeScope));
  };
}
