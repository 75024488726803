import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import type { UpdateJustificationLabel } from '@/neos/business/rfq/strategy/leg/otcAllocation/updateJustificationModel';
import { neosThunks } from '@/neos/business/thunks';
import { NeosSelect, type SelectOption } from '@/neos/components/share/NeosSelect/NeosSelect';
import type { FC } from 'react';
import { useDispatch } from 'react-redux';

export interface JusitifcationProps {
  rfqId: string;
}

export const UpdateJustificationType: FC<JusitifcationProps> = ({ rfqId }) => {
  const dispatch = useDispatch();

  const updateJustificationLabel = useAppSelector(state =>
    selectors.getRfqUpdateJustificationLabel(state, rfqId, selectors),
  );

  const updateJustificationLabels: UpdateJustificationLabel[] = [
    'FO error',
    'Split/Allocation',
    'Third party error/request',
  ];
  const displayedUpdateJusitifcationOptions: SelectOption<UpdateJustificationLabel>[] =
    updateJustificationLabels.map(label => ({
      value: label,
      label,
    }));

  const isRfqInAmendWorkflow = useAppSelector(state =>
    selectors.isRfqStatusInAmendWorkflow(state, rfqId, selectors),
  );
  const isRfqInCancelWorkflow = useAppSelector(state =>
    selectors.isRfqStatusInCancelWorkflow(state, rfqId, selectors),
  );
  const hasRfqAtLeastOneOtcAllocation = useAppSelector(
    state => selectors.getOtcAllocationsByRfqId(state, rfqId).length > 0,
  );

  const isDisplayed =
    hasRfqAtLeastOneOtcAllocation && (isRfqInAmendWorkflow || isRfqInCancelWorkflow);

  function onUpdateJustificationChange(
    newUpdateJustificationLabel: UpdateJustificationLabel | undefined,
  ) {
    dispatch(neosThunks.createChangeJustificationLabelThunk(rfqId, newUpdateJustificationLabel));
  }

  if (!isDisplayed) {
    return null;
  }

  return (
    <NeosSelect
      style={{ width: '180px' }}
      options={displayedUpdateJusitifcationOptions}
      value={updateJustificationLabel}
      addEmptyOption
      placeholder="Update justification"
      data-e2e="neos-update-justification-type"
      onChange={onUpdateJustificationChange}
    />
  );
};
