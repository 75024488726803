import type { Thunk } from '@/bootstrap/thunks';

export function createAddFirstStrategyThunk(rfqId: string): Thunk {
  return function addFirstStrategyThunk(
    dispatch,
    getState,
    {
      selectors: { getRfqData },
      actionCreators: {
        neos: { strategyDataCrudActions },
      },
      thunks: {
        neos: { createAddStrategyThunk },
      },
    },
  ) {
    dispatch(createAddStrategyThunk(rfqId));
    const state = getState();
    const newStrategyId = getRfqData(state, rfqId).strategyIds[0];
    dispatch(strategyDataCrudActions.update(newStrategyId, { isMasterStrategy: true }));
  };
}
