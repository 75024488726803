import type { Thunk } from '@/bootstrap/thunks';
import { isOptionLike } from '../../../../../../business/neosModel';

export function createActivateStrategyFlexThunk(rfqId: string, strategyId: string): Thunk {
  return function activateStrategyFlexThunk(
    dispatch,
    getState,
    {
      thunks: {
        neos: { createUpdateProductThunk },
      },
      selectors,
    },
  ) {
    const state = getState();
    const products = selectors
      .getStrategyProducts(state, strategyId, selectors)
      .filter(isOptionLike);

    const strategyWarnings = selectors.getStrategyWarnings(state, rfqId, strategyId, selectors);

    const dispatchable = products
      .filter(product => {
        const productWarning = strategyWarnings.find(
          strategyWarning => strategyWarning.productId === product.uuid,
        );
        const isWarningDisplayed = productWarning?.warning !== undefined;
        const isNotFlexProduct = product.flex !== 'FLEX';
        return isWarningDisplayed && isNotFlexProduct;
      })
      .map(product => createUpdateProductThunk(strategyId, product.uuid, 'flex', 'FLEX'));

    dispatch(dispatchable);
  };
}
