import type { Thunk } from '@/bootstrap/thunks';
import type { StrategyDefinition } from '@/neos/business/referenceData/strategyDefinition/strategyDefinitionModel';
import type { SizeType } from '@/neos/business/referenceData/strategyDefinition/strategyDefinitionOnyxModel';
import {
  type Product,
  isBasketDerivativeProduct,
} from '@/neos/business/rfq/strategy/leg/product/productModel';
import type { SizeUpdateParameters } from '../../rfq/strategy/thunks/updateStrategyOrLegSize';
import { type DisplayNegotiatedSize, toDisplayNegotiatedSize } from '../strategy/strategyUiModel';

export function createLegSizesUnitChangedThunk(strategyId: string, sizeId: string): Thunk {
  return function legSizesUnitChangedThunk(
    dispatch,
    getState,
    {
      selectors,
      selectors: {
        getLegData,
        getStrategyData,
        getStrategyDefinition,
        getDisplayNegotiatedSize,
        getLegSizes,
      },
      thunks: {
        neos: { createUpdateStrategyOrLegSizeThunk, createResetTopAndDispatchThunk },
      },
      actionCreators: {
        neos: { strategyUiCrudActions },
      },
    },
  ) {
    const appState = getState();
    const uiState = appState.ui;
    const strategyData = getStrategyData(appState, strategyId);
    const { legIds, strategyType, rfqId } = strategyData;
    const legData = getLegData(appState, legIds[0]);
    const { notionalUnit, localNotionalUnit } = legData;
    const product = selectors.getProduct(appState, legData.productId);

    const strategyDefinition = getStrategyDefinition(appState.referenceData, strategyType);

    const availableSizeTypes = getAvailableSizeType(product, strategyDefinition);

    const availableDisplayNegotiatedSize = availableSizeTypes.map(
      sizeType => toDisplayNegotiatedSize[sizeType],
    );
    const indexOfNotional = availableDisplayNegotiatedSize.indexOf('NOTIONAL');

    const currentDisplayNegociatedSize = getDisplayNegotiatedSize(uiState, strategyId);

    let effectiveDisplayNegociatedSize: DisplayNegotiatedSize = currentDisplayNegociatedSize;

    if (indexOfNotional !== -1 && notionalUnit !== localNotionalUnit) {
      availableDisplayNegotiatedSize.splice(indexOfNotional, 0, 'LOCAL_NOTIONAL');
    } else if (currentDisplayNegociatedSize === 'LOCAL_NOTIONAL') {
      effectiveDisplayNegociatedSize = 'NOTIONAL';
    }

    const indexOfNextDisplaySizeType =
      (availableDisplayNegotiatedSize.indexOf(effectiveDisplayNegociatedSize) + 1) %
      availableDisplayNegotiatedSize.length;

    const displayedSizeType = availableDisplayNegotiatedSize[indexOfNextDisplaySizeType];

    dispatch(strategyUiCrudActions.update(strategyId, { displayedSizeType }));

    // After the update of the displayedSizeType, we want to get the value in the correct size type
    const currentSize = getLegSizes(getState(), { rfqId, strategyId }, selectors).find(
      size => size.id === sizeId,
    );

    if (currentSize) {
      const parameters: SizeUpdateParameters = currentSize?.isStrategySize
        ? {
            strategyId,
            value: currentSize?.value,
            isStrategyUpdate: true,
          }
        : {
            strategyId,
            legId: legIds[0],
            value: currentSize?.value,
            isStrategyUpdate: false,
          };

      const dispatchable = parameters.isStrategyUpdate
        ? createUpdateStrategyOrLegSizeThunk(parameters)
        : [
            createResetTopAndDispatchThunk(parameters.legId),
            createUpdateStrategyOrLegSizeThunk(parameters),
          ];

      dispatch(dispatchable);
    }
  };
}

function getAvailableSizeType(
  product: Product,
  strategyDefinition: StrategyDefinition,
): SizeType[] {
  if (isBasketDerivativeProduct(product)) {
    return ['FIXED_QUANTITY'];
  }
  return strategyDefinition.legs[0].availableSizeTypes;
}
