import type {
  AllocationOrDeltaPredealChecksWithLegs,
  UMRPredealCheck,
} from '@/neos/business/predealCheck/predealCheckModel/predealCheckWithLegs';
import { formatNum } from '@/util/number/numberUtils';

export function getRegulationDetails(
  regulationPredealCheck: AllocationOrDeltaPredealChecksWithLegs,
) {
  return {
    flags: [{ label: 'Eligible', value: regulationPredealCheck.eligible }],
    messages: [{ label: '', message: regulationPredealCheck.message }],
    technicalMessages: regulationPredealCheck.redKey
      ? [
          { label: 'RED ID', message: regulationPredealCheck.redKey.uuid },
          { label: 'VERSION ID', message: regulationPredealCheck.redKey.version },
        ]
      : [],
  };
}

export function getUMRDetails(regulationPredealCheck: UMRPredealCheck) {
  const initialMargins = regulationPredealCheck.initialMargins;
  const margins = [
    {
      label: 'MARGINAL IM',
      message: `${formatNum(initialMargins?.marginal?.initialMargin)} ${
        regulationPredealCheck.currency
      }`,
    },
    {
      label: 'AUGMENTED IM',
      message: `${formatNum(initialMargins?.augmented?.initialMargin)} ${
        regulationPredealCheck.currency
      }`,
    },
    {
      label: 'BASELINE IM',
      message: `${formatNum(initialMargins?.baseline?.initialMargin)} ${
        regulationPredealCheck.currency
      }`,
    },
    {
      label: 'STANDALONE IM',
      message: `${formatNum(initialMargins?.standalone?.initialMargin)} ${
        regulationPredealCheck.currency
      }`,
    },
  ];
  return {
    flags: [{ label: 'Eligible', value: regulationPredealCheck.eligible }],
    messages: [
      { label: '', message: regulationPredealCheck.message },
      { label: 'Info', message: 'Results for the IM simulation computed at RFQ level.' },
    ],
    technicalMessages: regulationPredealCheck.redKey
      ? [
          { label: 'RED ID', message: regulationPredealCheck.redKey.uuid },
          { label: 'VERSION ID', message: regulationPredealCheck.redKey.version },
          ...margins,
          { label: 'SIMULATION ID', message: regulationPredealCheck.simulationId },
        ]
      : [...margins, { label: 'SIMULATION ID', message: regulationPredealCheck.simulationId }],
  };
}
