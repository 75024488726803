import type { Layout } from 'react-grid-layout';
import type {
  CalculationParty,
  DividendPriceType,
  HedgingParty,
  LinearInterpolation,
  LookthroughDR,
  ResetMode,
  ResetType,
  RightToSubstitute,
  RightToSubstituteScope,
  RoleDefinition,
  SpreadAdjustmentType,
  TerminationCondition,
} from '../../leg/product/elsProductOnyxModel';
import type { DeterminationMethod, ForexType } from '../../strategyModel';
import type { DividendFxFixingDate } from '../../strategyOnyxModel';
import type { SelectOption } from '@/neos/components/share/NeosSelect/NeosSelect.tsx';

export const elsSections = [
  'Product Details',
  'Brokers',
  'Lend And Borrow',
  'Rate Convention',
  'Forex Constat / Type',
  'Equity Leg',
  'Early Termination',
  'Dividend Leg',
  'Right to Substitute',
  'Fees',
  'Buy & Sell',
  'Other',
] as const;

export type ElsSection = (typeof elsSections)[number];

export interface ElsSectionsLayout extends Layout {
  i: ElsSection;
  isDisplayed: boolean;
}

export const availableForexTypes: [ForexType, ForexType] = ['QUANTO', 'COMPO'];

export const TerminationConditionValues: Record<TerminationCondition, string> = {
  AT_ANY_TIME: 'At any time',
  AT_RESET_DATE: 'At reset date',
};

export const RoleDefinitionValues: Record<RoleDefinition, string> = {
  AS_PER_MCA: 'As per MCA',
  NOT_AVAILABLE: 'Not available',
  SPECIFIC: 'Specific',
};

export const HedgingPartyValues: Record<HedgingParty, string> = {
  SG: 'SG',
  COUNTERPART: 'Counterpart',
  BOTH: 'Both',
};

export const CalculationPartyValues: Record<CalculationParty, string> = {
  ...HedgingPartyValues,
  SG_WITH_CLIENT_DISPUTE: 'SG with client dispute',
  SG_WITH_CLIENT_DISPUTE_EVENT_DEFAULT: 'SG with client dispute event default',
};

export const SpreadAdjustmentTypeValues: Record<SpreadAdjustmentType, string> = {
  NONE: 'None',
  ...TerminationConditionValues,
};

export const LinearInterpolationValues: Record<LinearInterpolation, string> = {
  AS_SPECIFIED_IN_MASTER_CONFIRMATION: 'As specified in master confirmation',
  NOT_APPLICABLE: 'Not applicable',
  INITIAL: 'Initial',
  INITIAL_AND_FINAL: 'Initial and final',
  FINAL: 'Final',
  ANY_PERIOD: 'Any period',
  APPLICABLE: 'Applicable',
};

export const LookthroughDRValues: Record<LookthroughDR, string> = {
  FULL: 'Full',
  PARTIAL: 'Partial',
};

export const RightToSubstituteValues: Record<RightToSubstitute, string> = {
  NONE: 'None',
  AT_ANY_TIME: 'At any time',
  AT_RESET_DATES: 'At reset dates',
  IN_CASE_OF_DIV_OR_CORP_ACTION: 'In case of div or corp action',
  IN_CASE_OF_CORP_ACTION: 'In case of corp action',
} as const;

export const RightToSubstituteScopeValues: Record<RightToSubstituteScope, string> = {
  NONE: 'None',
  CUSTOM: 'Custom',
  EQUIVALENT_ASSET_QUALITY: 'Equivalent asset quality',
  FTSE100_FINANCING: 'FTSE100 Financing',
  MUTUAL_AGREEMENT: 'Mutual Agreement',
} as const;

export const resetTypeValues: Record<ResetType, string> = {
  NONE: 'Bullet',
  DAILY: '1D',
  WEEKLY: '1W',
  TWO_WEEKS: '2W',
  MONTHLY: '1M',
  TWO_MONTHS: '2M',
  QUARTERLY: '3M',
  SEMIANNUAL: '6M',
  YEARLY: '1Y',
} as const;

export const ResetModeValues: Record<ResetMode, string> = {
  NONE: 'None',
  NOTIONAL: 'Notional',
  QUANTITY: 'Quantity',
} as const;

export const determinationMethodValues: SelectOption<DeterminationMethod>[] = [
  { value: 'CLOSING_PRICE', label: 'Closing Price' },
  { value: 'EDSP', label: 'Edsp' },
  { value: 'EXCHANGE_LOOK_ALIKE', label: 'Exchange Look Alike' },
  { value: 'HEDGE_EXECUTION', label: 'Hedge Execution' },
  { value: 'MORNING_CLOSE', label: 'Morning Close' },
  { value: 'NONE', label: 'None' },
  { value: 'OPENING_PRICE', label: 'Opening Price' },
  { value: 'OTHER', label: 'Other' },
  { value: 'PER_MASTER_CONFIRM', label: 'Per Master Confirm' },
  { value: 'VWA_PPRICE', label: 'Vwa Price' },
] as const;

export const dividendFxFixingDateValues: Record<DividendFxFixingDate, string> = {
  EX_DATE: 'Ex Date',
  PAYMENT_DATE: 'Payment Date',
  RESET_DATE: 'Reset Date',
  END_DATE: 'End Date',
} as const;

export const dividendPriceTypeValues: Record<DividendPriceType, string> = {
  EF_DIVIDEND_GUARANTEED_AND_REPO: 'EF Dividend guaranteed and repo',
  OPTION_DIVIDEND_OPEN_REPO: 'Option dividend and open repo',
};

export const initialLayout: ElsSectionsLayout[] = [
  {
    w: 25,
    h: 5,
    x: 0,
    y: 0,
    i: 'Product Details',
    minW: 5,
    moved: false,
    static: false,
    isResizable: true,
    isDisplayed: true,
  },
  {
    w: 30,
    h: 18,
    x: 0,
    y: 0,
    i: 'Brokers',
    minW: 5,
    moved: false,
    static: false,
    isResizable: true,
    isDisplayed: true,
  },
  {
    w: 30,
    h: 11,
    x: 0,
    y: 0,
    i: 'Rate Convention',
    minW: 5,
    moved: false,
    static: false,
    isResizable: true,
    isDisplayed: true,
  },
  {
    w: 24,
    h: 5,
    x: 0,
    y: 1,
    i: 'Forex Constat / Type',
    minW: 5,
    moved: false,
    static: false,
    isResizable: true,
    isDisplayed: true,
  },
  {
    w: 18,
    h: 18,
    x: 0,
    y: 0,
    i: 'Dividend Leg',
    minW: 5,
    moved: false,
    static: false,
    isResizable: true,
    isDisplayed: true,
  },
  {
    w: 22,
    h: 11,
    x: 0,
    y: 0,
    i: 'Equity Leg',
    minW: 5,
    moved: false,
    static: false,
    isResizable: true,
    isDisplayed: true,
  },
  {
    w: 13,
    h: 7,
    x: 0,
    y: 1,
    i: 'Early Termination',
    minW: 5,
    moved: false,
    static: false,
    isResizable: true,
    isDisplayed: true,
  },
  {
    w: 13,
    h: 7,
    x: 0,
    y: 0,
    i: 'Right to Substitute',
    minW: 5,
    moved: false,
    static: false,
    isResizable: true,
    isDisplayed: true,
  },
  {
    w: 20,
    h: 18,
    x: 0,
    y: 0,
    i: 'Fees',
    minW: 5,
    moved: false,
    static: false,
    isResizable: true,
    isDisplayed: true,
  },
  {
    w: 15,
    h: 7,
    x: 0,
    y: 0,
    i: 'Other',
    minW: 5,
    moved: false,
    static: false,
    isDisplayed: true,
    isResizable: true,
  },
  {
    w: 33,
    h: 18,
    x: 0,
    y: 4,
    i: 'Lend And Borrow',
    minW: 30,
    moved: false,
    static: false,
    isResizable: true,
    isDisplayed: true,
  },
  {
    w: 33,
    h: 18,
    x: 0,
    y: 5,
    i: 'Buy & Sell',
    minW: 30,
    moved: false,
    static: false,
    isResizable: true,
    isDisplayed: true,
  },
];
