import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';

interface TopLevelErrorToasterProps {
  title: string;
  message: string;
  position: number;
}

export function TopLevelErrorToaster({ title, message, position }: TopLevelErrorToasterProps) {
  return (
    <SimpleNeosTooltip placement="bottom" type="danger" message={message}>
      <div
        className="badge bg-danger badge-lg position-absolute"
        style={{ top: position, left: '50%', transform: 'translate(-50%)' }}
      >
        <em className="icon icon-sm me-2">warning</em>
        Warning: {title}
      </div>
    </SimpleNeosTooltip>
  );
}
