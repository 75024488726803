import type { Counterpart } from '@/neos/business/rfq/actorsSetup/counterpart/counterpartModel';
import type { MultipleTypeaheadValue } from '@/neos/components/share/multipleAsyncTypeahead/MultipleAsyncTypeahead';
import { sortBy } from 'lodash';

export function mapCounterpartsToTypeaheadValues(
  counterparts: Counterpart[],
): MultipleTypeaheadValue[] {
  return sortBy(counterparts.map(mapCounterpartToTypeaheadValue), u => u.label);
}

export function mapCounterpartToTypeaheadValue({
  id,
  name,
}: {
  id: number;
  name: string;
}): MultipleTypeaheadValue {
  return {
    value: id.toString(),
    label: name,
    origin: 'COUNTERPART',
  };
}
