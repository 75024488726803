import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import { getCancelledAllocationsModel } from '@/neos/components/rfq/postNego/allocs/getCancelledAllocationsModel';
import { getOtcAllocationsMeteorBookingStepsModel } from '@/neos/components/rfq/postNego/allocs/otcAllocations/getOtcAllocationMeteorBookingSteps';
import type { FC } from 'react';
import { useDispatch } from 'react-redux';
import {
  OtcAllocationsComponent,
  type OtcAllocationsDispatchProps,
  type OtcAllocationsOwnProps,
  type OtcAllocationsStateProps,
} from './OtcAllocations';
import { getOtcAllocationsModel } from './getOtcAllocationsModel';

export const OtcAllocations: FC<OtcAllocationsOwnProps> = ownProps => {
  const dispatch = useDispatch<ExtendedDispatch>();
  const otcAllocationsModel = useAppSelector(state =>
    getOtcAllocationsModel(state, ownProps.rfqId, ownProps.strategyId, ownProps.legId, selectors),
  );
  const meteorBookingStepsModel = useAppSelector(state =>
    getOtcAllocationsMeteorBookingStepsModel(state, ownProps.rfqId, ownProps.legId, selectors),
  );

  const cancelledOtcAllocationsModel = getCancelledAllocationsModel(
    otcAllocationsModel.allocations,
  );
  const stateProps: OtcAllocationsStateProps = {
    ...otcAllocationsModel,
    ...cancelledOtcAllocationsModel,
    meteorBookingSteps: meteorBookingStepsModel,
  };
  const dispatchProps = mapDispatchProps(dispatch, ownProps);
  return <OtcAllocationsComponent {...ownProps} {...stateProps} {...dispatchProps} />;
};

function mapDispatchProps(
  dispatch: ExtendedDispatch,
  { legId, rfqId }: OtcAllocationsOwnProps,
): OtcAllocationsDispatchProps {
  return {
    onAllocationAdded: (firstAllocCcy, ccy, sizeType) => {
      dispatch(neosThunks.createAddOtcAllocationThunk(legId, rfqId, firstAllocCcy, ccy, sizeType));
    },
  };
}
