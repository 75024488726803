'use strict';

import { type Notification, type Theme, bootstrapTheme } from 'reapop';

function getColorFromStatus(status: Notification['status']): string | undefined {
  switch (status) {
    case 'error':
      return 'red';
    case 'success':
      return 'green';
    case 'info':
      return 'blue';
    case 'warning':
      return 'orange';
    default:
      return undefined;
  }
}

export const neosNotificationTheme: Theme = {
  ...bootstrapTheme,
  container: (position, singleContainer) => {
    return {
      ...bootstrapTheme.container(position, singleContainer),
      top: '2px',
    };
  },
  notification: notification => {
    const color = getColorFromStatus(notification.status);
    const bootstrapNotification = bootstrapTheme.notification(notification);
    return {
      ...bootstrapNotification,
      borderRadius: '2px',
      backgroundColor: color ? `var(--bs-${color}-10)` : bootstrapNotification.backgroundColor,
      marginBottom: '5px',
      width: 'auto',
      minWidth: '350px',
    };
  },
  notificationIcon: notification => {
    const color = getColorFromStatus(notification.status);
    return {
      ...bootstrapTheme.notificationIcon(notification),
      color: `var(--bs-${color ?? 'primary'})`,
    };
  },
};
