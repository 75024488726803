import type { Thunk } from '@/bootstrap/thunks';
import { getStrategyRecomputeScope } from '../../neosModel';
import { StrategyRecomputeChangedScope } from '../models/recomputeModel';

export function createRfqChangeStrategyWeightThunk(
  rfqId: string,
  strategyId: string,
  weight: number | undefined,
): Thunk {
  return function rfqChangeStrategyWeightThunk(
    dispatch,
    getState,
    {
      selectors: { getStrategyData },
      actionCreators: {
        neos: { createRfqStrategyWeightChangedAction },
      },
    },
  ) {
    const state = getState();
    const { isMasterStrategy, initialWeight } = getStrategyData(state, strategyId);
    const hasSignChanged = weight !== undefined && Math.sign(initialWeight) !== Math.sign(weight);

    const scope = getStrategyRecomputeScope({
      scope: StrategyRecomputeChangedScope.WEIGHT,
      hasSignChanged,
      isMaster: isMasterStrategy,
    });
    dispatch(createRfqStrategyWeightChangedAction(rfqId, strategyId, scope, weight));
  };
}
