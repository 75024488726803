import type { Thunk } from '@/bootstrap/thunks';
import type { InternalMailOptions } from './internalOnyxPreconfirmationModel';

export function createInternalPreconfirmationEmailSendThunk(
  rfqId: string,
  internalMailOptions: InternalMailOptions,
  ignoreFieldChanges: boolean,
): Thunk {
  return function internalPreconfirmationEmailSendThunk(
    dispatch,
    _,
    {
      actionCreators: {
        neos: { internalPreconfirmationEmailSendAction, rfqUiCrudActions },
      },
    },
  ) {
    dispatch(
      rfqUiCrudActions.patchOrInsert(rfqId, { isPreconfirmationButtonsDisabled: true }),
      internalPreconfirmationEmailSendAction(rfqId, internalMailOptions, ignoreFieldChanges),
    );
  };
}
