import type { Thunk } from '@/bootstrap/thunks';
import type { LegSecondaryEventCreation } from '@/neos/business/legSecondaryEventCreation/legSecondaryEventCreationModel';

export function createSecondaryTypesOnLegsThunk({
  rfqId,
  strategyId,
  eventType,
}: {
  rfqId: string;
  strategyId: string;
  eventType: LegSecondaryEventCreation['eventType'];
}): Thunk {
  return function secondaryTypesThunk(
    dispatch,
    getState,
    {
      selectors: { getStrategyData },
      actionCreators: {
        neos: { legSecondaryEventCreationCrudActions },
      },
    },
  ) {
    const state = getState();
    const { legIds } = getStrategyData(state, strategyId);
    legIds.forEach(legId =>
      dispatch(
        legSecondaryEventCreationCrudActions.upsert(
          { rfqId, legId },
          { eventType, primaryLegUuid: legId },
        ),
      ),
    );
  };
}
