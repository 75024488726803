import type { ActionContainer } from '@/util/actionContainer';
import { neosThunks } from '../../thunks';
import { DefaultingScope } from '../models';
import { DefaultingOptions } from '../rfqActions';
import { createDefaultRfqRequestedAction } from './defaultRfqRequestedAction';

const { createFairPricesWarningsResetThunk } = neosThunks;

export function createRfqDeltaHedgingAndFairPricesRequestedAction(rfqId: string): ActionContainer {
  return createDefaultRfqRequestedAction(
    rfqId,
    new DefaultingOptions({
      overrideScopes: [
        DefaultingScope.FAIR_PRICES,
        DefaultingScope.AGGREGATED_FAIR_PRICES,
        DefaultingScope.DELTAS,
        DefaultingScope.UNDERLYING,
      ],
    }),
    {
      success: {
        dispatchables: [createFairPricesWarningsResetThunk(rfqId)],
      },
    },
  );
}
