import type { Thunk } from '@/bootstrap/thunks';
import type { OnyxPreconfirmationEmail } from './onyxPreconfirmationModel';

export function createIntegratePreconfirmationThunk(
  rfqId: string,
  onyxPreconfirmationEmail: OnyxPreconfirmationEmail,
): Thunk {
  return function integratePreconfirmationThunk(
    dispatch,
    _,
    {
      thunks: {
        neos: {
          createIntegrateExternalPreconfirmationThunk,
          createIntegrateInternalPreconfirmationThunk,
        },
      },
    },
  ) {
    const { external, internal } = onyxPreconfirmationEmail;

    if (external) {
      dispatch(createIntegrateExternalPreconfirmationThunk(rfqId, external));
    }

    if (internal) {
      dispatch(createIntegrateInternalPreconfirmationThunk(rfqId, internal));
    }
  };
}
