import { selectors } from '@/bootstrap/selectors';
import { services } from '@/bootstrap/services';
import type { AppState } from '@/bootstrap/state';
import type { FC } from 'react';
import { useSelector } from 'react-redux';
import { TimersComponent, type TimersOwnProps, type TimersStateProps } from './Timers';
import { getTimersModel } from './getTimersModel';

export const Timers: FC<TimersOwnProps> = ({ rfqId }) => {
  const stateProps = useSelector<AppState, TimersStateProps>(state =>
    getTimersModel(state, rfqId, selectors, services),
  );

  return <TimersComponent rfqId={rfqId} {...stateProps} />;
};
