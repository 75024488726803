import type {
  DefaultProductValuesForStrategy,
  FvaCommon,
  FvaFixedK,
  FvaFloatingK,
  Product,
} from '../../../../../../../neosModel';
import { isOptionLike, isProductWithStrike } from '../../../../../../models';
import { getCommonPart, getDerivativePart } from './getDefaultedPartialProduct';

export function getDefaultedFvaFixedK(
  currentProduct: Product,
  defaultValues: DefaultProductValuesForStrategy,
): FvaFixedK {
  return {
    subFamily: 'FIXED_STRIKE_FVA',
    forwardDrift: undefined,
    forwardInterestRate: undefined,
    ...getDefaultedFvaCommon(currentProduct, defaultValues),
  };
}

export function getDefaultedFvaFloatingK(
  currentProduct: Product,
  defaultValues: DefaultProductValuesForStrategy,
): FvaFloatingK {
  return {
    subFamily: 'FLOATING_STRIKE_FVA',
    ...getDefaultedFvaCommon(currentProduct, defaultValues),
  };
}

function getDefaultedFvaCommon(
  currentProduct: Product,
  defaultValues: DefaultProductValuesForStrategy,
): FvaCommon {
  const { underlying, optionStyle, optionType, strike: strategyStrike, maturity } = defaultValues;

  const { type, style } = isOptionLike(currentProduct)
    ? currentProduct
    : { type: undefined, style: undefined };
  const { strike, strikeUnit } = isProductWithStrike(currentProduct)
    ? currentProduct
    : { strike: undefined, strikeUnit: undefined };
  return {
    type: optionType || type,
    style: optionStyle || style,
    strike: strategyStrike || strike,
    strikeUnit,
    negotiationMode: 'OTC',
    ...getCommonPart(currentProduct, defaultValues),
    ...getDerivativePart(currentProduct, maturity, underlying),
    hasCustomUnderlying: false,
  };
}
