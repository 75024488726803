import type { Thunk } from '@/bootstrap/thunks';
import type { ActionContainer } from '@/util/actionContainer';
import type { StrategyData, StrategyType } from '../../../neosModel';
import { notionalSizeTypes } from '../../../neosOnyxModel';
import type { SizeData } from '../../models';

interface ChangeStrategyTypeParameters {
  strategyId: string;
  strategyType: StrategyType;
  legsToAddIds: string[];
  legsToRemoveIds: string[];
}

export function createChangeStrategyTypeThunk({
  strategyId,
  strategyType,
  legsToAddIds,
  legsToRemoveIds,
}: ChangeStrategyTypeParameters): Thunk {
  return function changeStrategyType(
    dispatch,
    getState,
    {
      selectors: { getStrategyDefinition, getStrategyData },
      actionCreators: {
        neos: { strategyDataCrudActions, createLegsInitializedAction, featureCrudActions },
        analytics: { createLogAnalyticsAction },
      },
      thunks: {
        neos: {
          createDefaultLegsAsPerStrategyDefinitionThunk,
          createApplyStrategyDefinitionSameValueToLegsThunk,
          createStrategyDeleteLegsThunk,
        },
      },
    },
  ) {
    const state = getState();

    const addLegsActions: ActionContainer[] = legsToAddIds.length
      ? [
          createLegsInitializedAction(strategyId, legsToAddIds),
          ...legsToAddIds.map(legToAddId =>
            strategyDataCrudActions.arrayAdd(strategyId, { property: 'legIds', value: legToAddId }),
          ),
        ]
      : [];

    const deleteFeatureAction: ActionContainer = featureCrudActions.deleteByPartialKey({
      strategyId,
    });

    const { hasACompositionLeg, legs, priceUnitType } = getStrategyDefinition(
      state.referenceData,
      strategyType,
    );

    const sizePatch = getSizePatch();
    const strategyPatch: Partial<StrategyData> = hasACompositionLeg
      ? { strategyType, priceUnitType, ...sizePatch }
      : {
          strategyType,
          priceUnitType,
          top: undefined,
          underlyingId: undefined,
          ...sizePatch,
        };

    return dispatch(
      createLogAnalyticsAction('NEOS RFQ', `Select strategy`),
      strategyDataCrudActions.update(strategyId, strategyPatch),
      ...addLegsActions,
      deleteFeatureAction,
      createStrategyDeleteLegsThunk(legsToRemoveIds, true),
      createDefaultLegsAsPerStrategyDefinitionThunk(strategyId),
      createApplyStrategyDefinitionSameValueToLegsThunk(strategyId),
    );

    function getSizePatch(): SizeData {
      if (!hasACompositionLeg) {
        return {
          sizeType: undefined,
          localNotional: undefined,
          localNotionalUnit: undefined,
          notional: undefined,
          notionalUnit: undefined,
          numberOfLots: undefined,
          quantity: undefined,
          varUnit: undefined,
        };
      }

      const strategyData = getStrategyData(state, strategyId);

      const {
        sizeType: strategySizeType,
        numberOfLots,
        notional,
        localNotional,
        varUnit,
      } = strategyData.scope === 'RFQ'
        ? strategyData
        : {
            sizeType: undefined,
            numberOfLots: undefined,
            notional: undefined,
            localNotional: undefined,
            varUnit: undefined,
          };
      const firstLegDefinition = legs[0];
      const newSizeType =
        strategySizeType && firstLegDefinition.availableSizeTypes.includes(strategySizeType)
          ? strategySizeType
          : firstLegDefinition.sizeType;

      return {
        sizeType: newSizeType,
        numberOfLots: newSizeType === 'FIXED_QUANTITY' ? numberOfLots : undefined,
        quantity: undefined,
        varUnit: newSizeType === 'FIXED_VAR_UNIT' ? varUnit : undefined,
        notional: notionalSizeTypes.includes(newSizeType) ? notional : undefined,
        localNotional: notionalSizeTypes.includes(newSizeType) ? localNotional : undefined,
      };
    }
  };
}
