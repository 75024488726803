import { NumericInput } from '@/neos/components/share/numericInput';
import type { FC } from 'react';
import type { MidPrices } from './getMidPricesModel';

import styles from './MidPrices.module.scss';

export interface MidPricesOwnProps {
  rfqId: string;
  strategyId: string;
}

export type MidPricesStateProps = MidPrices;

export type MidPricesProps = MidPricesOwnProps & MidPricesStateProps;
export const MidPricesStyled: FC<MidPricesProps> = ({
  midOrPtmmLegsPrices,
  strategyMidOrPtmmmPrice,
  displayEmptyFirstLeg,
}) => {
  return (
    <section>
      {displayEmptyFirstLeg && <NumericInput readOnly />}
      {midOrPtmmLegsPrices.map(midPrice => (
        <NumericInput
          key={midPrice.key}
          value={midPrice.price}
          readOnly={true}
          withMaximumNumberOfFloatingDigits={2}
          data-e2e="neos-leg-fair-price"
        />
      ))}
      <NumericInput
        value={strategyMidOrPtmmmPrice.price}
        readOnly={true}
        className={`${styles['strategy-price']}`}
        data-e2e="neos-strategy-fair-price"
        withMaximumNumberOfFloatingDigits={2}
      />
    </section>
  );
};
