import { type ActionCreators, actionCreators } from '@/bootstrap/actions';
import type { ThunkEpic } from '@/bootstrap/epics';
import { type Selectors, selectors } from '@/bootstrap/selectors';
import { type Thunks, thunks } from '@/bootstrap/thunks';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { type ToOnyxMappers, mappers } from '../../mappers';
import type { OnyxError } from '../../mappers/error';
import { uiActionCreators } from '../../ui/uiActionCreators';
import { createInternalPreconfirmationApi } from './internalPreconfirmationApi';
import type { InternalPreconfirmationApi } from './internalPreconfirmationApiType';

export function getSendInternalPreconfirmationSendEpic(http: SgmeHttp) {
  const api = createInternalPreconfirmationApi(http);
  return createSendInternalPreconfirmationSendEpic(
    api,
    thunks,
    selectors,
    actionCreators,
    mappers.toOnyxMappers,
  );
}

export function createSendInternalPreconfirmationSendEpic(
  api: InternalPreconfirmationApi,
  {
    createErrorToasterThunk,
    neos: { createPreconfModalDisplayChangeThunk, createIntegrateInternalPreconfirmationThunk },
  }: Thunks,
  { getPreconfIgnoreChangesModalInfo }: Selectors,
  { neos: { rfqUiCrudActions } }: ActionCreators,
  toOnyxMappers: ToOnyxMappers,
): ThunkEpic {
  return (action$, state$) =>
    action$.pipe(
      ofType('INTERNAL_PRECONFIRMATION_EMAIL_SEND_REQUESTED'),
      mergeMap(({ rfqId, internalMailOptions, ignoreFieldChanges }) => {
        const enabledSendButtonAction = rfqUiCrudActions.patchOrInsert(rfqId, {
          isPreconfirmationButtonsDisabled: false,
        });

        const transaction = toOnyxMappers.mapToOnyxTransaction(
          state$.value,
          rfqId,
          selectors,
          toOnyxMappers,
        );

        return api
          .sendInternalPreconfirmationEmail(
            rfqId,
            { internal: internalMailOptions },
            transaction,
            ignoreFieldChanges,
          )
          .pipe(
            mergeMap(internalOnyxPreconfirmation =>
              of(
                enabledSendButtonAction,
                createPreconfModalDisplayChangeThunk(rfqId, false),
                createIntegrateInternalPreconfirmationThunk(
                  rfqId,
                  internalOnyxPreconfirmation.internal,
                ),
              ),
            ),
            catchError((error: OnyxError) => {
              const appState = state$.value;

              if (!ignoreFieldChanges) {
                const modalInfo = getPreconfIgnoreChangesModalInfo(appState.ui, rfqId);
                return [
                  uiActionCreators.rfqUiCrudActions.update(rfqId, {
                    preconfIgnoreChangesModalInfo: {
                      isModalDisplayed: true,
                      error: error.response ?? error,
                      type:
                        modalInfo?.isModalDisplayed && modalInfo?.type === 'externalSend'
                          ? 'externalInternalSend'
                          : 'internalSend',
                    },
                  }),
                  enabledSendButtonAction,
                ];
              }

              return [
                createErrorToasterThunk(
                  {
                    message: 'Error sending preconfirmation email',
                  },
                  error,
                ),
                enabledSendButtonAction,
              ];
            }),
          );
      }),
    );
}
