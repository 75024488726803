import { DATE_SHORT_KEBAB_FORMAT } from '@/util/date/dateFormatHelper';
import { format, parse } from 'date-fns';
import type { Tenor } from '../../../../neos/business/neosModel';

export type FromattedDateResult =
  | { type: 'DATE'; date: string }
  | { type: 'TENOR'; tenorCode: string }
  | { type: 'NO_CHANGE' };
export function getKebabFormattedDateFromInput(
  inputValue: string | null,
  maturities: string[],
  tenors: Tenor[],
): FromattedDateResult {
  if (inputValue === null) {
    return { type: 'NO_CHANGE' };
  }

  if (inputValue === '') {
    return { type: 'DATE', date: '' };
  }

  const tenor = tenors?.find(
    ({ label }) => label === inputValue || label === inputValue.toUpperCase(),
  );

  if (tenor) {
    return { type: 'TENOR', tenorCode: tenor.code };
  }

  try {
    //06Apr2021
    //6Apr2021
    if (inputValue.length > 7) {
      return { type: 'DATE', date: convertToKebabDateFormat(inputValue, 'ddMMMyyyy') };
    }

    //6Apr21
    //07Dec21
    if (inputValue.length === 6 || (inputValue.length === 7 && inputValue[0].match(/\d/))) {
      return { type: 'DATE', date: convertToKebabDateFormat(inputValue, 'ddMMMyy') };
    }

    //Apr2021
    if (inputValue.length === 7) {
      const d = convertToKebabDateFormat(inputValue, 'MMMyyyy');
      const applicableDates = maturities.filter(mat => mat.startsWith(d.substr(0, 7)));
      if (applicableDates.length === 1) {
        return { type: 'DATE', date: applicableDates[0] };
      }
      return { type: 'NO_CHANGE' };
    }

    //Apr21
    if (inputValue.length === 5) {
      const d = convertToKebabDateFormat(inputValue, 'MMMyy');
      const applicableDates = maturities.filter(mat => mat.startsWith(d.substr(0, 7)));
      if (applicableDates.length === 1) {
        return { type: 'DATE', date: applicableDates[0] };
      }
      return { type: 'NO_CHANGE' };
    }
  } catch (e) {
    if (e instanceof RangeError) {
      return { type: 'NO_CHANGE' };
    }
    throw e;
  }

  return { type: 'NO_CHANGE' };
}

function convertToKebabDateFormat(input: string, fromFormat: string) {
  const date = parse(input, fromFormat, new Date('2000-01-01'));
  return format(date, DATE_SHORT_KEBAB_FORMAT);
}
