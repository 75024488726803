import type { ServiceWorkerEvent, WindowClient } from './appServiceWorkerEventModel';
import type { AppNotificationData } from './notificationModel';
const icon = '/assets/images/sg-logo.png';

const serviceWorkerFilename =
  import.meta.env.MODE === 'production' ? '/sw.js' : '/dev-sw.js?dev-sw';

export interface BaseServiceWorkerRegistration {
  showNotification: (
    message: string,
    notificationData?: AppNotificationData,
    options?: Omit<NotificationOptions, 'data'> | undefined,
  ) => Promise<void>;
  hideNotification: (id: string) => Promise<void>;
  hideAllNotifications: () => Promise<void>;
  addEventListener: (type: 'message', handler: (ev: ServiceWorkerEvent) => any) => void;
  postMessage: (notificationData: AppNotificationData) => void;
  getClients: () => Promise<WindowClient[]>;
}

export class AppServiceWorkerRegistration implements BaseServiceWorkerRegistration {
  private readonly registrationPromise: Promise<ServiceWorkerRegistration>;

  constructor(private readonly serviceWorker: ServiceWorkerContainer) {
    this.registrationPromise = serviceWorker.register(serviceWorkerFilename, {
      type: import.meta.env.MODE === 'production' ? 'classic' : 'module',
    });
  }

  public showNotification = async (
    message: string,
    notificationData?: AppNotificationData,
    options?: Omit<NotificationOptions, 'data'> | undefined,
  ) => {
    const [first, ...rest] = message.split('\n').map(s => s.trim());
    const title = rest.length > 0 ? first : '';
    const body = rest.length > 0 ? rest.join('\n') : first;
    const reg = await this.registrationPromise;
    reg.showNotification(title, {
      body,
      ...(options ?? {}),
      icon: options?.icon ?? icon,
      data: notificationData,
    });
  };

  public hideNotification = async (tag: string) => {
    const reg = await this.registrationPromise;
    const notifications = await reg.getNotifications({ tag });
    notifications.forEach(notification => notification.close());
  };

  public hideAllNotifications = async () => {
    const reg = await this.registrationPromise;
    const notifications = await reg.getNotifications();
    notifications.forEach(notification => notification.close());
  };

  public addEventListener = (type: 'message', handler: (event: ServiceWorkerEvent) => any) => {
    this.serviceWorker.addEventListener(type, handler);
  };

  public postMessage = (data: AppNotificationData) => {
    return this.serviceWorker.controller?.postMessage(data);
  };

  public getClients = async () => {
    return new Promise<WindowClient[]>((resolve, reject) => {
      const listener = (event: ServiceWorkerEvent) => {
        if (event.data.type !== 'CLIENT_COUNT') {
          return;
        }
        this.serviceWorker.removeEventListener('message', listener);
        resolve(event.data.clients);
      };
      try {
        this.serviceWorker.addEventListener('message', listener);
        if (this.serviceWorker.controller) {
          this.serviceWorker.controller.postMessage('GET_CLIENT_COUNT');
        }
      } catch (error) {
        reject(error);
      }
    });
  };
}
