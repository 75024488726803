import type { Thunk } from '@/bootstrap/thunks';
import type { StrategyData } from '../strategyModel';

export function createCloneStrategyThunk(
  rfqTargetId: string,
  strategySourceId: string,
  strategyTargetId: string,
): Thunk {
  return function cloneStrategyThunk(
    dispatch,
    getState,
    {
      selectors: { getStrategyData },
      thunks: {
        neos: { createCloneStrategyLegsThunk, createCloneStrategyFeaturesThunk },
      },
      actionCreators: {
        neos: { strategyDataCrudActions },
      },
      services: { idCreator },
    },
  ) {
    const appState = getState();
    const strategyDataToClone: StrategyData = getStrategyData(appState, strategySourceId);
    const newLegIds = strategyDataToClone.legIds.map(_ => idCreator.createId());

    const newStrategyData: StrategyData = {
      ...strategyDataToClone,
      isMasterStrategy: false,
      uuid: strategyTargetId,
      legIds: newLegIds,
    };

    dispatch(
      createCloneStrategyLegsThunk(strategySourceId, strategyTargetId, newLegIds),
      strategyDataCrudActions.insert(strategyTargetId, newStrategyData),
      createCloneStrategyFeaturesThunk(rfqTargetId, strategySourceId, strategyTargetId),
    );
  };
}
