import { EventEmitter } from 'events';
import type { TypedEventEmitter } from '@/util/typedEventEmitter/typedEventEmitter';

export const filterChangedBus: TypedEventEmitter<{
  blotterFilterChanged: { filterModel: any };
}> = new EventEmitter();

export const filterDeletedBus: TypedEventEmitter<{
  filterDeleted: string;
}> = new EventEmitter();
