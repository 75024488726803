import type { MifidPredealCheck } from '@/neos/business/predealCheck/predealCheckModel';

export function getMifidDetails(mifid: MifidPredealCheck) {
  return {
    flags: [],
    messages: [{ label: '', message: mifid.message }],
    technicalMessages: mifid.redKey
      ? [
          { label: 'RED ID', message: mifid.redKey.uuid },
          { label: 'VERSION ID', message: mifid.redKey.version },
        ]
      : [],
  };
}
