import type { Thunk } from '@/bootstrap/thunks';
import type { ExternalOnyxPreconfirmationData } from './externalOnyxPreconfirmationModel';

export function createIntegrateExternalPreconfirmationThunk(
  rfqId: string,
  externalOnyxPreconfirmation: ExternalOnyxPreconfirmationData,
): Thunk {
  return function integrateExternalPreconfirmationThunk(
    dispatch,
    _,
    {
      actionCreators: {
        neos: { externalPreconfirmationCrudActions },
      },
      fromMappers: { mapFromExternalOnyxPreconfirmation },
    },
  ) {
    const preconfirmation = mapFromExternalOnyxPreconfirmation(externalOnyxPreconfirmation);
    dispatch(externalPreconfirmationCrudActions.upsert(rfqId, preconfirmation));
  };
}
