import { NeosBlurInput } from '@/neos/components/share/blurComponent/NeosBlurInput';
import { MultiToggleButton } from '@/neos/components/share/multiToggleButton/MultiToggleButton';
import { formatFromCamelToStartCase } from '@/util/format';
import type { NegotiationMode } from '../../../../neos/business/neosModel';
import type { IbChatModel } from './getIbChatModel';

import styles from './IbChat.module.scss';

export interface IbChatOwnProps {
  rfqId: string;
}

export type IbChatPropsFromState = IbChatModel;

export interface IbChatDispatchProps {
  onIbChatChanged: (ibChat: string) => void;
  onIbChatNegotiationModeChanged: (mode: NegotiationMode) => void;
  onIbChatRequested: () => void;
}

type IbChatProps = IbChatOwnProps & IbChatPropsFromState & IbChatDispatchProps;

export const IbChatStyled = (props: IbChatProps) => (
  <section className={`${styles['ib-chat']}`}>
    <div className={`${styles['ib-chat-input-container']} input-group`}>
      <MultiToggleButton<NegotiationMode>
        className="fw-bold"
        selectedValue={props.ibChatNegotiationMode}
        valueFormatter={mode => formatNegotiationMode(mode)}
        availableValues={props.availableNegotiationModes}
        onValueChange={newMode => props.onIbChatNegotiationModeChanged(newMode)}
      />
      <NeosBlurInput
        className="w-100"
        placeholder="IB Chat"
        value={props.ibChat}
        onBlur={e => props.onIbChatChanged(e.target.value)}
        onKeyUp={e =>
          e.key === 'Enter' &&
          (props.onIbChatChanged(e.currentTarget.value), props.onIbChatRequested())
        }
      />
      <span className="input-group-btn">
        <button className="btn btn-primary btn-icon" onClick={props.onIbChatRequested}>
          <i className="icon">search</i>
        </button>
      </span>
    </div>
    {props.ibChatTrustLevel !== undefined && (
      <span
        className={` ${styles['trust-level']} ${
          props.ibChatTrustLevelStyleName ? styles[props.ibChatTrustLevelStyleName] : ''
        } `}
      >{`IB Chat trust level: ${props.ibChatTrustLevel}/5`}</span>
    )}
  </section>
);

function formatNegotiationMode(mode: NegotiationMode): string {
  return mode === 'LISTED' ? formatFromCamelToStartCase(mode) : mode;
}
