import type { EventType } from '../../../neos/business/neosModel';
import type {
  SimilarActivitiesLegLinkedAction,
  SimilarActivitiesLegUnlinkedAction,
  SimilarActivitiesLinkManagementResetedAction,
  SimilarActivitiesPrimaryLegAddedAction,
  SimilarActivitiesPrimaryRfqAddedAction,
  SimilarActivitiesPrimaryStrategyAddedAction,
  SimilarActivitiesStrategyLinkedAction,
  SimilarActivitiesStrategyUnlinkedAction,
} from './similarActivitiesLinkManagementActions';

export const similarActivitiesLinkManagerActionCreators = {
  createLinkSecondaryLeg,
  createUnlinkSecondaryLeg,
  createAddPrimaryRfq,
  createAddPrimaryStrategy,
  createAddPrimaryLeg,
  createLinkSecondaryStrategy,
  createUnlinkSecondaryStrategy,
  createResetSALinkManagement,
};

export function createLinkSecondaryLeg(
  currentSecondaryLegId: string,
): SimilarActivitiesLegLinkedAction {
  return {
    type: 'LEG_LINKED',
    currentSecondaryLegId,
  };
}

export function createUnlinkSecondaryLeg(
  previousSecondaryLegId: string,
): SimilarActivitiesLegUnlinkedAction {
  return {
    type: 'LEG_UNLINKED',
    legToUnlink: previousSecondaryLegId,
  };
}

export function createAddPrimaryRfq(
  currentPrimaryRfqId: string,
  secondaryEventType: EventType,
): SimilarActivitiesPrimaryRfqAddedAction {
  return {
    type: 'PRIMARY_RFQ_ADDED',
    currentPrimaryRfqId,
    secondaryEventType,
  };
}

export function createAddPrimaryStrategy(
  currentPrimaryStrategyId: string,
  currentPrimaryRfqId: string,
  secondaryEventType: EventType,
): SimilarActivitiesPrimaryStrategyAddedAction {
  return {
    type: 'PRIMARY_STRATEGY_ADDED',
    currentPrimaryStrategyId,
    currentPrimaryRfqId,
    secondaryEventType,
  };
}

export function createAddPrimaryLeg({
  currentPrimaryLegId,
  currentPrimaryRfqId,
  secondaryEventType,
}: {
  currentPrimaryLegId: string;
  currentPrimaryRfqId: string;
  secondaryEventType: EventType;
}): SimilarActivitiesPrimaryLegAddedAction {
  return {
    type: 'PRIMARY_LEG_ADDED',
    currentPrimaryLegId,
    currentPrimaryRfqId,
    secondaryEventType,
  };
}

export function createLinkSecondaryStrategy(
  currentSecondaryStrategyId: string,
): SimilarActivitiesStrategyLinkedAction {
  return {
    type: 'STRATEGY_LINKED',
    currentSecondaryStrategyId,
  };
}

export function createUnlinkSecondaryStrategy(
  previousSecondaryStrategyId: string,
): SimilarActivitiesStrategyUnlinkedAction {
  return {
    type: 'STRATEGY_UNLINKED',
    strategyToUnlink: previousSecondaryStrategyId,
  };
}

export function createResetSALinkManagement(): SimilarActivitiesLinkManagementResetedAction {
  return {
    type: 'SIMILAR_ACTIVITIES_LINK_MANAGEMENT_RESETED',
    currentPrimaryLegId: '',
    currentPrimaryRfqId: '',
    secondaryEventType: 'CREATE',
  };
}
