import { type SchedulerLike, iif, of, throwError } from 'rxjs';
import { concatMap, delay, retryWhen } from 'rxjs/operators';

/**
 *
 * @param retrialTimes number of retrials ( >= 1 )
 * @param delayBeforeRetrying delay before nxt retrial
 * @param scheduler
 */
export const retryWithDelay = <T>(
  retrialTimes: number,
  delayBeforeRetrying: number,
  scheduler: SchedulerLike | undefined,
) =>
  retryWhen<T>(errors =>
    errors.pipe(
      // Use concat map to keep the errors in order and make sure they
      // aren't executed in parallel
      concatMap((e, i) =>
        // Executes a conditional Observable depending on the result
        // of the first argument
        iif(
          () => i > retrialTimes - 1,
          // If the condition is true we throw the error (the last error)
          throwError(e),
          // Otherwise we pipe this back into our stream and delay the retry
          of(e).pipe(delay(delayBeforeRetrying, scheduler)),
        ),
      ),
    ),
  );
