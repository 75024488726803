import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { useSelector } from 'react-redux';
import {
  type ShortcutButtonDispatchProps,
  type ShortcutButtonOwnProps,
  ShortcutButtonUnconnected,
} from './ShortcutButton';

export function ShortcutButton<T>(
  props: ShortcutButtonOwnProps<T> & ShortcutButtonDispatchProps<T>,
) {
  const { getSelectedTab, isRfqLoading } = selectors;

  const rfqId = useSelector<AppState>(state => getSelectedTab(state.ui.tabManagement)) as string;
  const isLoading = useSelector<AppState>(state => isRfqLoading(state.ui, rfqId)) as boolean;

  return <ShortcutButtonUnconnected {...props} isLoading={isLoading} />;
}
