import type {
  DefaultProductValuesForStrategy,
  Product,
} from '../../../../../../../../../neos/business/neosModel';
import { type DividendFuture, isDividendFutureProduct } from '../../../../product/productModel';
import {
  getCommonPart,
  getDerivativePart,
  getFutureNegotiationPart,
  getFutureStrikeDatePart,
} from './getDefaultedPartialProduct';

export function getDefaultedDividendFutureProduct(
  currentProduct: Product,
  defaultValues: DefaultProductValuesForStrategy,
): DividendFuture {
  const {
    pointValue: strategyPointValue,
    maturity,
    underlying,
    negotiation: strategyNegotiation,
  } = defaultValues;
  const { pointValue } = isDividendFutureProduct(currentProduct)
    ? currentProduct
    : {
        pointValue: undefined,
      };
  return {
    subFamily: 'DIVIDEND_FUTURE',
    pointValue: strategyPointValue || pointValue,
    ...getCommonPart(currentProduct, defaultValues),
    ...getFutureNegotiationPart(currentProduct, strategyNegotiation),
    ...getDerivativePart(currentProduct, maturity, underlying),
    ...getFutureStrikeDatePart(currentProduct, defaultValues),
    hasCustomUnderlying: false,
  };
}
