import type {
  AllocationsModel,
  StrategyAllocationsModel,
} from '@/neos/components/rfq/postNego/allocs/getAllocationsModel';

export function getLegsToChange(
  areButtonsInSync: boolean,
  legs: Partial<AllocationsModel>,
  isDelta: boolean | undefined,
) {
  const legsToChange: StrategyAllocationsModel[] = [];
  if (areButtonsInSync) {
    if (legs.strategyLegs) {
      legsToChange.push(...legs.strategyLegs);
    }
    if (legs.deltaLegs) {
      legsToChange.push(...legs.deltaLegs);
    }
  } else {
    if (isDelta) {
      if (legs.deltaLegs) {
        legsToChange.push(...legs.deltaLegs);
      }
    } else {
      if (legs.strategyLegs) {
        legsToChange.push(...legs.strategyLegs);
      }
    }
  }
  return legsToChange;
}
