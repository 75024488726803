import type { LegSecondaryEventCreation } from '@/neos/business/legSecondaryEventCreation/legSecondaryEventCreationModel';
import {
  type EventType,
  type Product,
  type SecondaryEvent,
  isElsProduct,
} from '@/neos/business/neosModel';
import { NeosTooltip } from '@/neos/components/share/tooltip/NeosTooltip';
import { Tooltip } from 'react-bootstrap';

import type { NotApplicableField } from '../secondaryTypeModel';

export type AvailableEventType = Exclude<Extract<EventType, SecondaryEvent>, 'STEPIN'> | undefined;

export const availableEventTypes: AvailableEventType[] = [
  'INCREASE',
  'PARTIAL_UNWIND',
  'FULL_UNWIND',
  'NOVATION_REMAINING',
  undefined,
];

export function getEventTypesByProduct(product: Product): AvailableEventType[] {
  if (isElsProduct(product)) {
    return availableEventTypes.filter(eventType => eventType !== 'NOVATION_REMAINING');
  }
  return availableEventTypes;
}

export type OutlineColor = 'success' | 'warning' | 'danger' | 'info' | 'primary';

interface ButtonData {
  color: OutlineColor;
  label: string;
  iconName: string;
  dataE2e: string;
}

export function getButtonData(eventType: EventType | undefined): ButtonData {
  switch (eventType) {
    case 'INCREASE':
      return {
        color: 'success',
        label: 'Increase',
        iconName: 'arrow_upward',
        dataE2e: 'unwind-increase-button',
      };

    case 'PARTIAL_UNWIND':
      return {
        color: 'warning',
        label: 'Decrease',
        iconName: 'arrow_downward',
        dataE2e: 'unwind-decrease-button',
      };

    case 'FULL_UNWIND':
      return {
        color: 'danger',
        label: 'Close',
        iconName: 'sync_alt',
        dataE2e: 'unwind-close-button',
      };

    case 'NOVATION_REMAINING':
      return {
        color: 'info',
        label: 'Novation Remaining',
        iconName: 'R',
        dataE2e: 'unwind-novation-button',
      };

    case undefined:
    default:
      return {
        color: 'primary',
        label: 'Not applicable',
        iconName: 'N/A',
        dataE2e: 'unwind-not-applicable-button',
      };
  }
}

export function EventTypeIcon({
  dataE2e,
  eventType,
  iconName,
}: {
  eventType: LegSecondaryEventCreation['eventType'];
  iconName: string;
  dataE2e: string;
}) {
  const isNotMaterialIcon = eventType === undefined || eventType === 'NOVATION_REMAINING';
  return (
    <i
      className={`${isNotMaterialIcon ? '' : 'icon icon-sm'} ${
        eventType === 'NOVATION_REMAINING' ? 'px-1' : ''
      }`}
      data-e2e={dataE2e}
    >
      {iconName}
    </i>
  );
}

export function InitNotEligibleIcon({ legId }: { legId: NotApplicableField['legId'] }) {
  return (
    <div
      style={{ height: '32px' }}
      className="flex-center"
      key={`div-legEvent-not-eligible-${legId}`}
    >
      <NeosTooltip
        key={`legEvent-not-eligible-${legId}`}
        placement="top"
        overlay={<Tooltip id="unwind-leg-warning">Not Eligible</Tooltip>}
      >
        <i className="icon text-warning">warning</i>
      </NeosTooltip>
    </div>
  );
}
