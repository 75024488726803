import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import {
  InterestRateCellsComponent,
  type InterestRateCellsDispatchProps,
  type InterestRateCellsMapStateProps,
  type InterestRateCellsOwnProps,
} from './InterestRateCells';
import { getInterestRateModel } from './getInterestRateModel';

export const InterestRateCells = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InterestRateCellsComponent);

function mapStateToProps(
  state: AppState,
  { rfqId, strategyId }: InterestRateCellsOwnProps,
): InterestRateCellsMapStateProps {
  return getInterestRateModel(state, rfqId, strategyId, selectors);
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId, strategyId }: InterestRateCellsOwnProps,
): InterestRateCellsDispatchProps {
  return {
    onInterestRateChanged(legId: string, interestRate: number | undefined) {
      dispatch(
        neosThunks.createUpdateProductAndResetDependenciesThunk(
          rfqId,
          strategyId,
          legId,
          'forwardInterestRate',
          interestRate,
        ),
      );
    },
  };
}
