export interface TriggerMatchingAction {
  type: 'TRIGGER_MATCHING';
  rfqId: string;
}

export const triggerMatchingActionCreators = {
  createTriggerMatchingAction,
};

function createTriggerMatchingAction(rfqId: string): TriggerMatchingAction {
  return { type: 'TRIGGER_MATCHING', rfqId };
}
