import type { SimilarActivitiesLinkManagementModel } from './similarActivitiesLinkManagementModel';

export type SimilarActivitiesLinkManagerState = Readonly<SimilarActivitiesLinkManagementModel>;

export const defaultSimilarActivitiesLinkManagerState: SimilarActivitiesLinkManagerState = {
  currentPrimaryRfqId: '',
  currentPrimaryLegId: '',
  currentPrimaryStrategyId: '',
  secondaryEventType: 'CREATE',
};
