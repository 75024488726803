import type { Dispatchable, Thunk } from '@/bootstrap/thunks';
import { DefaultingScope } from '@/neos/business/neosModel';
import { DefaultingOptions } from '@/neos/business/rfq/rfqActions';

export function createUpdateProductEffectiveDateThunk(
  rfqId: string,
  strategyId: string,
  effectiveDate: string | undefined,
): Thunk {
  return function updateProductEffectiveDateThunk(
    dispatch,
    getState,
    {
      actionCreators,
      selectors,
      thunks: {
        neos: { createUpdateProductThunk, changeElsIsScheduleObsoleteThunk },
      },
    },
  ) {
    const state = getState();
    const { uuid } = selectors.getStrategyMasterProduct(state, strategyId, selectors);

    const isEls = selectors.isElsStrategy(state, strategyId, selectors);

    const dispatchables: Dispatchable[] = [
      createUpdateProductThunk(strategyId, uuid, 'effectiveDateOffset', undefined),
      createUpdateProductThunk(strategyId, uuid, 'effectiveDate', effectiveDate),
      changeElsIsScheduleObsoleteThunk(rfqId, uuid, true),
    ];

    if (isEls) {
      const defaultingOptions = new DefaultingOptions({
        enrichScopes: [DefaultingScope.EFFECTIVE_DATE],
      });

      dispatchables.push(
        actionCreators.neos.createDefaultRfqRequestedAction(rfqId, defaultingOptions),
      );
    }
    dispatch(dispatchables);
  };
}
