import type { Thunk } from '@/bootstrap/thunks';
import { getKeys } from '@/util/getKeys/getKeys';

export function createIntegrateNotificationSubscriptionResultsThunk(
  subscriptionResults: Record<string, boolean>,
): Thunk {
  return function integrateNotificationSubscriptionResultsThunk(
    dispatch,
    getState,
    {
      selectors: { tabExists },
      actionCreators: {
        neos: { rfqUiCrudActions },
      },
    },
  ) {
    const state = getState().ui.tabManagement;
    const actions = getKeys(subscriptionResults)
      .filter(rfqId => tabExists(state, rfqId))
      .map(rfqId =>
        rfqUiCrudActions.patchOrInsert(rfqId, {
          isSubscribedToNotification: subscriptionResults[rfqId],
        }),
      );
    if (!actions.length) {
      return;
    }
    dispatch(actions);
  };
}
