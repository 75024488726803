import { Fragment } from 'react';
import { type FairPricesColumns, getFairPricesGridColmunTemplate } from './fairPricesCommon';

import styles from './FairPricesHeader.module.scss';

export interface FairPricesHeaderOwnProps {
  rfqId: string;
}

export interface FairPricesHeaderPropsFromState {
  isOtherFairGreeksDisplayed: boolean;
  isUsualFairPricesDisplayed: boolean;
  isVolBidAskPricesDisplayed: boolean;
  areAllStrategiesFutureLike: boolean;
}

type FairPricesHeader = FairPricesHeaderOwnProps & FairPricesHeaderPropsFromState;

export const FairPricesHeaderWithCss = ({
  isOtherFairGreeksDisplayed,
  areAllStrategiesFutureLike,
  isUsualFairPricesDisplayed,
  isVolBidAskPricesDisplayed,
}: FairPricesHeader) => {
  const headers: FairPricesColumns = [];

  if (isVolBidAskPricesDisplayed) {
    headers.push({ columnKey: 'VOL_BID', element: <label>Vol Bid</label> });
  }

  if (isUsualFairPricesDisplayed && !areAllStrategiesFutureLike) {
    headers.push({ columnKey: 'VOL', element: <label>Vol</label> });
  }

  if (isVolBidAskPricesDisplayed) {
    headers.push({ columnKey: 'VOL_ASK', element: <label>Vol Ask</label> });
  }

  if (isUsualFairPricesDisplayed) {
    headers.push({ columnKey: 'DELTA', element: <label>Delta</label> });
    if (!areAllStrategiesFutureLike) {
      headers.push({ columnKey: 'VEGA', element: <label>Vega</label> });
    }
  }

  if (isOtherFairGreeksDisplayed) {
    headers.push(
      { columnKey: 'GAMMA', element: <label>Gamma</label> },
      { columnKey: 'THETA', element: <label>Theta</label> },
    );
  }
  const gridTemplateColumns = getFairPricesGridColmunTemplate(headers);
  return (
    <section
      style={{
        gridTemplateColumns,
      }}
      className={`${styles['fair-prices-grid']}`}
    >
      {headers.map(({ element, columnKey }) => (
        <Fragment key={columnKey}>{element}</Fragment>
      ))}
    </section>
  );
};
