import { createAddExtraCounterPartThunk } from './addExtraCounterPartThunk';
import { createChangeSalesCounterpartyThunk } from './changeSalesCounterparty';
import { createChangeSalesLocationThunk } from './changeSalesLocations';
import { createChangeSalesValoThunk } from './changeSalesValo';
import { createDefaultSalesCounterpartyThunk } from './defaultSalesCounterpartyThunk';
import { createEnsureCounterpartsLoadedThunk } from './ensureCounterpartsLoaded';
import { createEnsureValidPreAllocCounterpartsThunk } from './ensureValidPreAllocCounterparts';
import { createIntegrateReceivedCounterPartThunk } from './integrateReceivedCounterparts';
import { createTrySelectCounterpart } from './trySelectCounterpart';

export const actorsSetupThunks = {
  createChangeSalesCounterpartyThunk,
  createIntegrateReceivedCounterPartThunk,
  createChangeSalesLocationThunk,
  createEnsureValidPreAllocCounterpartsThunk,
  createTrySelectCounterpart,
  createEnsureCounterpartsLoadedThunk,
  createChangeSalesValoThunk,
  createAddExtraCounterPartThunk,
  createDefaultSalesCounterpartyThunk,
};
