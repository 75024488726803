import type { ThunkEpic } from '@/bootstrap/epics';
import { type Thunks, thunks } from '@/bootstrap/thunks';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { ofType } from 'redux-observable';
import { catchError, mergeMap } from 'rxjs/operators';
import { type LoadTransactionApi, createLoadTransactionApi } from '../apis/loadTransactionApi';
import type { OnyxError } from '../mappers/error';

export function getLoadTransactionEpic(http: SgmeHttp): ThunkEpic {
  const api = createLoadTransactionApi(http);
  return createLoadTransactionEpic(api, thunks);
}

export function createLoadTransactionEpic(
  api: LoadTransactionApi,
  { neos: { createLoadTransactionsThunk }, createErrorToasterThunk }: Thunks,
): ThunkEpic {
  return action$ =>
    action$.pipe(
      ofType('LOAD_TRANSACTION_REQUESTED'),
      mergeMap(({ transactionLoadRequest: { rfqId, version }, shouldDisplayTab }) => {
        return api.loadTransaction(rfqId, version).pipe(
          mergeMap(transaction => [createLoadTransactionsThunk([transaction], shouldDisplayTab)]),
          catchError((error: OnyxError) => {
            return [
              createErrorToasterThunk(
                {
                  message: 'Error when loading the RFQ',
                },
                error,
              ),
            ];
          }),
        );
      }),
    );
}
