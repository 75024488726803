import { crudDomain } from '@/util/crudUtils';
import type { FieldChange } from '../changesNotificationModel';

interface ExecutionPriceNotificationChangesKey {
  rfqId: string;
  executionId: string;
}

const compositeIdSeparator = ' | ';

function getExecutionPriceNotificationChangesId({
  rfqId,
  executionId,
}: ExecutionPriceNotificationChangesKey): string {
  return `${rfqId}${compositeIdSeparator}${executionId}${compositeIdSeparator}`;
}

function getExecutionPriceNotificationChangesKey(id: string): ExecutionPriceNotificationChangesKey {
  const [rfqId, executionId] = id.split(compositeIdSeparator);
  return {
    rfqId,
    executionId,
  };
}
export const executionPriceNotificationChangesDomain = crudDomain<
  FieldChange,
  ExecutionPriceNotificationChangesKey
>('executionPriceNotificationChanges', {
  keyToString: getExecutionPriceNotificationChangesId,
  stringToKey: getExecutionPriceNotificationChangesKey,
});
