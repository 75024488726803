import type { Thunk } from '@/bootstrap/thunks';
import type { OnyxRfq } from '@/neos/business/rfq/rfqOnyxModel';

export function createNeosLoadRfqThunk(rfq: OnyxRfq): Thunk {
  return function neosLoadRfqThunk(
    dispatch,
    getState,
    {
      selectors: { getRfqData },
      thunks: {
        neos: {
          createLoadRfqThunk,
          createObsoletePredealChecksRemovedThunk,
          createAutoSelectDeltaAdjOrRefTabThunk,
          createFinalizeRfqSavingDoneThunk,
          createSetNegoOrPostNegoScreenThunk,
        },
      },
    },
  ) {
    const state = getState();
    const rfqId = rfq.uuid;
    const { predealCheckIds } = getRfqData(state, rfqId);
    dispatch(
      createLoadRfqThunk(rfq),
      createFinalizeRfqSavingDoneThunk(rfq),
      createObsoletePredealChecksRemovedThunk(rfqId, predealCheckIds),
      createAutoSelectDeltaAdjOrRefTabThunk(rfqId),
      createSetNegoOrPostNegoScreenThunk(rfqId),
    );
  };
}
