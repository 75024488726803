import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { createUpdateProductThunk } from '@/neos/business/rfq/strategy/leg/thunks/updateProduct';
import { connect } from 'react-redux';
import type { AccrualType } from '../../../../../../business/neosModel';
import {
  type AccrualCellsDispatchProps,
  type AccrualCellsMapStateProps,
  type AccrualCellsOwnProps,
  AccrualCells as Component,
} from './AccrualCells';
import { getAccrualCellsModel } from './getAccrualCellsModel';

export const AccrualCells = connect(mapStateToProps, mapDispatchToProps)(Component);

function mapStateToProps(
  state: AppState,
  { strategyId, rfqId }: AccrualCellsOwnProps,
): AccrualCellsMapStateProps {
  return getAccrualCellsModel(state, rfqId, strategyId, selectors);
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { strategyId }: AccrualCellsOwnProps,
): AccrualCellsDispatchProps {
  return {
    onAccrualChanged(productId: string, accrual: AccrualType | undefined) {
      dispatch(createUpdateProductThunk(strategyId, productId, 'accrual', accrual));
    },
  };
}
