import { Component, type ComponentType } from 'react';

interface Props {
  value?: number;
  hasFocus?: boolean;
  reset?: number;
  onFocus: () => void;
  onBlur?(value: number | undefined): void;
}

interface State {
  propsValue?: number;
  value?: number;
  mode: 'DISPLAY' | 'INPUT';
  maximumFractionDigits: number;
  reset?: number;
}

interface WithFormatProps {
  maximumFractionDigits?: number;
  onFocus?: () => void;
}

export function withFormat<P extends Props>(
  CustomComponent: ComponentType<P>,
): ComponentType<Omit<P, 'onFocus'> & WithFormatProps> {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return class ComponentWithFormat extends Component<Omit<P, 'onFocus'> & WithFormatProps, State> {
    public static getDerivedStateFromProps = (
      props: Omit<P, 'onFocus'> & WithFormatProps,
      state: State,
    ): State | null => {
      if (props.value !== state.propsValue || props.reset !== state.reset) {
        return {
          ...state,
          propsValue: props.value,
          value: props.value,
          reset: props.reset,
        };
      }
      return null;
    };

    constructor(props: Omit<P, 'onFocus'> & WithFormatProps) {
      super(props);
      this.state = {
        propsValue: props.value,
        value: props.value,
        mode: this.props.hasFocus ? 'INPUT' : 'DISPLAY',
        maximumFractionDigits: props.maximumFractionDigits || 20,
        reset: props.reset,
      };
    }

    private onWrappedComponentFocus = (): void => {
      this.setState(
        {
          ...this.state,
          mode: 'INPUT',
        },
        () => {
          if (this.props.onFocus) {
            this.props.onFocus();
          }
        },
      );
    };

    private onWrappedComponentBlur = (value: number): void => {
      this.setState(
        {
          ...this.state,
          value,
          mode: 'DISPLAY',
        },
        () => {
          if (this.props.onBlur && this.state.value !== this.state.propsValue) {
            this.props.onBlur(value);
          }
        },
      );
    };

    private getValue = (): string | number | undefined => {
      return this.state.mode === 'DISPLAY' ? this.formatValue(this.state.value) : this.state.value;
    };

    private formatValue = (value?: number): string => {
      const englishFormat = new Intl.NumberFormat('en-EN', {
        maximumFractionDigits: this.state.maximumFractionDigits,
      });
      return value === 0 ? '0' : value && !isNaN(value) ? englishFormat.format(value) : '';
    };

    private hasFocus = (): boolean => {
      return this.state.mode === 'INPUT';
    };

    public render() {
      const { value, onFocus, onBlur, maximumFractionDigits, ...restProps } = this.props;
      return (
        <CustomComponent
          {...(restProps as P)}
          value={this.getValue()}
          hasFocus={this.hasFocus()}
          onFocus={this.onWrappedComponentFocus}
          onBlur={this.onWrappedComponentBlur}
        />
      );
    }
  };
}
