import type { Action } from '@/bootstrap/actions';
import type { Thunk } from '@/bootstrap/thunks';
import { isCustomUnderlyingProduct, isSingleUnderlyingDerivativeProduct } from '../../leg/legModel';
import type { Barrier, BarriersFeature, BarriersFeatureKey } from '../featureModel';

export function createAddBarrierToFeatureThunk(featureKey: BarriersFeatureKey): Thunk {
  return function addBarrierToFeature(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { featureCrudActions },
      },
    },
  ) {
    const appState = getState();
    const feature: BarriersFeature | undefined = selectors.getFeature(
      appState.featureState,
      featureKey,
    );

    const product = selectors.getStrategyMasterProduct(appState, featureKey.strategyId, selectors);

    const commonBarrier: Omit<Barrier, 'underlyingType'> = {
      neosBarrierType: undefined,
      allMustHit: true,
      strictLimit: false,
      limitObservationType: 'EUROPEAN',
      limitStrikeUnit: undefined,
      limitStrikeUnitType: undefined,
    };

    const defaultBarrier: Barrier = isCustomUnderlyingProduct(product)
      ? {
          underlyingType: 'CUSTOM',
          underlyingName: product.underlyingName,
          ...commonBarrier,
        }
      : {
          underlyingType: 'NON_CUSTOM',
          underlyingId: isSingleUnderlyingDerivativeProduct(product)
            ? product.underlyingId
            : undefined,
          ...commonBarrier,
        };
    const action: Action = featureCrudActions.upsert(featureKey, {
      type: 'BARRIERS',
      strategyId: featureKey.strategyId,
      barriers: [...(feature?.barriers ?? []), defaultBarrier],
    });
    dispatch(action);
  };
}
