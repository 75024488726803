import type { Action } from '@/bootstrap/actions';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import type { Middleware } from 'redux';

export function createErrorHandlingMiddleware(): Middleware {
  return _ => (next: ExtendedDispatch) => (action: Action) => {
    try {
      return next(action);
    } catch (err) {
      setTimeout(() => {
        throw err;
      });
    }
  };
}
