import type {
  DefaultProductValuesForStrategy,
  DigitalOptionProduct,
  Product,
} from '../../../../../../../../../neos/business/neosModel';
import {
  getCommonPart,
  getDerivativePart,
  getOtcNegotiationPart,
} from './getDefaultedPartialProduct';

export function getDefaultedDigitalOptionProduct(
  currentProduct: Product,
  defaultValues: DefaultProductValuesForStrategy,
): DigitalOptionProduct {
  return {
    subFamily: 'DIGITAL_OPTION',
    hasCustomUnderlying: false,
    ...getCommonPart(currentProduct, defaultValues),
    ...getOtcNegotiationPart(),
    ...getDerivativePart(currentProduct, defaultValues.maturity, defaultValues.underlying),
  };
}
