import type {
  ExecFeesUnit,
  ExecFeesUnitType,
} from '@/neos/business/rfq/strategy/leg/product/productOnyxModel';

const feesUnitByUnitType: Record<ExecFeesUnitType, ExecFeesUnit> = {
  BIPS: 'bp',
  REF_PERCENT: '%',
  CENTS: 'Cts',
} as const;

export function switchUnit(currentUnitType: ExecFeesUnitType) {
  const feeUnitTypes: Array<ExecFeesUnitType> = ['BIPS', 'REF_PERCENT', 'CENTS'];
  const currentPosition = feeUnitTypes.findIndex(v => v === currentUnitType);
  const newFeeUnitType = feeUnitTypes[(currentPosition + 1) % 3];

  return {
    type: newFeeUnitType,
    unit: feesUnitByUnitType[newFeeUnitType],
  };
}
