import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { isDefined } from '@/util/undefinedAndNull/isDefined';

export function getStrategyIdsForShortcut(
  state: AppState,
  rfqId: string,
  isApplyToAll: boolean,
  selectors: Selectors,
): string[] {
  const { strategyIds, deltaHedgingStrategyIds } = selectors.getRfqData(state, rfqId);
  const strategyIdsToUpdate = isApplyToAll
    ? [...strategyIds, ...deltaHedgingStrategyIds]
    : [selectors.getSelectedStrategyId(state.ui.selectedStrategy, rfqId)];
  return strategyIdsToUpdate;
}

export function getUnderlyingIdsForShortcut(
  state: AppState,
  rfqId: string,
  isApplyToAll: boolean,
  selectors: Selectors,
): string[] {
  const strategyIds = getStrategyIdsForShortcut(state, rfqId, isApplyToAll, selectors);
  return strategyIds
    .map(strategyId =>
      selectors.getUnderlyingId(selectors.getStrategyMasterProduct(state, strategyId, selectors)),
    )
    .filter(isDefined);
}
