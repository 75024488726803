import { DATE_SHORT_KEBAB_FORMAT } from '@/util/date/dateFormatHelper';
import type { FC } from 'react';
import DatePicker from 'react-datepicker';
import { CustomDateInput } from './CustomDateInput';
import type { PopperPlacementType } from './FlowDatePicker';
import { useFlowDatePicker } from './useFlowDatePicker';

import styles from './FlowDatePicker.module.scss';

export interface FlowDatePickerWithOffsetProps {
  className?: string;
  inputClassName?: string;
  styleName?: string;
  hideCalendarIcon?: boolean;
  filterDate?: (date: Date) => boolean;
  date: string;
  disabled?: boolean;
  readOnly?: boolean;
  onChange: (selectedDate: string) => void;
  ['data-e2e']?: string;
  popperPlacement?: PopperPlacementType;
  onOffsetChange: (selectedOffset: number) => void;
  currentOffset: number | undefined;
  offsetList?: number[];
  children?: any;
}

export const FlowDatePickerWithOffset: FC<FlowDatePickerWithOffsetProps> = ({
  date,
  filterDate,
  disabled = false,
  readOnly = false,
  inputClassName = '',
  onChange,
  children,
  styleName,
  hideCalendarIcon,
  popperPlacement = undefined,
  currentOffset,
  offsetList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
  onOffsetChange,
  ...restProps
}) => {
  const {
    ref,
    customInputRef,
    convertedDate,
    onInputClick,
    closeDatePicker,
    onDatePickerChange,
    submitDateInput,
    onKeyDown,
  } = useFlowDatePicker(date, onChange);

  return (
    <div {...restProps}>
      <DatePicker
        ref={ref}
        customInput={
          <CustomDateInput
            ref={customInputRef}
            convertedDate={convertedDate}
            onCustomBlur={submitDateInput}
            closeDatePicker={closeDatePicker}
            disabled={disabled}
            hideCalendarIcon={hideCalendarIcon}
            className={inputClassName}
            readOnly={readOnly}
          />
        }
        readOnly={readOnly}
        onInputClick={onInputClick}
        peekNextMonth
        dropdownMode="select"
        selected={convertedDate}
        locale={'en'}
        dateFormat={DATE_SHORT_KEBAB_FORMAT}
        filterDate={filterDate}
        fixedHeight
        disabledKeyboardNavigation
        onChange={onDatePickerChange}
        onKeyDown={onKeyDown}
        disabled={disabled}
        calendarClassName={`${styles.calendar} panel panel-default`}
        popperClassName={styles.popper}
        popperPlacement={popperPlacement}
      >
        <div className={`${styles['container-maturities']} `}>
          <div
            data-e2e="date-picker-offset-title"
            className={`${styles['date-picker-with-offset-offset-title']} d-flex justify-content-center align-items-center`}
          >
            Offset
          </div>
          <div className={`${styles['offsets-container']} `}>
            {offsetList.map(offset => {
              return (
                <span
                  className={`${currentOffset === offset ? styles['tenor-selected'] : ''}`}
                  key={offset}
                  onClick={() => {
                    onOffsetChange(offset);
                    closeDatePicker();
                  }}
                >
                  {offset}
                </span>
              );
            })}
          </div>
        </div>
      </DatePicker>
    </div>
  );
};
