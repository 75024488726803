import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { NeosBookingId } from '@/neos/components/share/NeosBookingId/NeosBookingId';
import { useSelector } from 'react-redux';

export function NettingBookingId({
  allocationId,
  rfqId,
}: {
  allocationId: string;
  rfqId: string;
  legId: string;
}) {
  const bookingStep = useSelector((state: AppState) =>
    selectors.getAllocationBookingStep(state, rfqId, allocationId),
  );
  const nettingId = bookingStep?.nettingBookingId;
  return (
    <NeosBookingId
      onBookingIdChanged={null}
      bookingId={nettingId}
      dataE2e="otc-allocation-netting-booking-id"
      isReadOnly
      overlayTriggerPlacement="top"
    />
  );
}
