import type { Dispatchable } from '@/bootstrap/thunks';
import type { Observable } from 'rxjs';
import type { OnyxError } from '../mappers/error';
import type { ChainOptions, SingleChainOptionsWithToaster } from '../neosActions';
import { wrapInLoadingObservable } from './wrapInLoadingObservable';

interface ObservableToasterOptions {
  creator: (message: string, error: OnyxError | undefined) => Dispatchable;
  message: string;
}

export interface ResultParameters<T> {
  toaster?: ObservableToasterOptions;
  on?: (param: T) => Dispatchable[];
}

export interface WrapInLoadingChainableOptions<T> {
  tabIds: string[];
  apiObservable: Observable<T>;
  observableOptions: {
    success: ResultParameters<T>;
    error?: ResultParameters<any>;
    retryOnError?: ResultParameters<any>;
  };
  chainOptions: ChainOptions | undefined;
  withRetryOnError?: boolean;
}

export function wrapInChainableLoadingObservable<T>(
  {
    apiObservable,
    tabIds,
    observableOptions,
    chainOptions,
    withRetryOnError: withRetryOptions,
  }: WrapInLoadingChainableOptions<T>,
  wrapInLoadingObservableImpl: typeof wrapInLoadingObservable = wrapInLoadingObservable,
) {
  const onSuccess = (result: T) => {
    return getDispatchables(
      observableOptions.success,
      chainOptions && chainOptions.success,
      result,
      undefined,
    );
  };

  const onError = (error: OnyxError) => {
    if (withRetryOptions && observableOptions.retryOnError?.on) {
      return observableOptions.retryOnError?.on(error);
    }
    return getDispatchables(
      observableOptions.error,
      chainOptions && chainOptions.error,
      error,
      error,
    );
  };

  return wrapInLoadingObservableImpl({
    tabIds,
    apiObservable,
    onSuccess,
    onError,
  });
}

function getDispatchables<I>(
  observableOptions: ResultParameters<I> | undefined,
  chainOptions: SingleChainOptionsWithToaster | undefined,
  result: I,
  error: OnyxError | undefined,
) {
  const dispatchables: Dispatchable[] = [];

  if (observableOptions && observableOptions.on) {
    dispatchables.push(...observableOptions.on(result));
  }

  dispatchables.push(
    ...getToasterDispatchable(chainOptions, observableOptions && observableOptions.toaster, error),
  );

  if (chainOptions && chainOptions.dispatchables) {
    dispatchables.push(...(chainOptions.dispatchables || []));
  }

  return dispatchables;
}

function getToasterDispatchable(
  chainOptions: SingleChainOptionsWithToaster | undefined,
  toasterOptions: ObservableToasterOptions | undefined,
  error: OnyxError | undefined,
) {
  const dispatchables: Dispatchable[] = [];
  if (chainOptions) {
    const { toaster: chainToasterOptions } = chainOptions;
    if (chainToasterOptions && !toasterOptions) {
      const { type } = chainToasterOptions;
      throw new Error(`Can't ${type} toaster if it is not specified`);
    }
    if (chainToasterOptions && chainToasterOptions.type === 'OVERRIDE_MESSAGE' && toasterOptions) {
      dispatchables.push(toasterOptions.creator(chainToasterOptions.message, error));
    }
  }

  if ((!chainOptions || !chainOptions?.toaster) && toasterOptions) {
    dispatchables.push(toasterOptions.creator(toasterOptions.message, error));
  }
  return dispatchables;
}
