import type { Thunk } from '@/bootstrap/thunks.ts';
import type { Reference } from '@/neos/business/rfq/reference/referenceModel.ts';
import {
  type BasketCompositionDetails,
  isElsBasketProduct,
  type Underlying,
} from '@/neos/business/rfq/strategy/leg/product/productModel.ts';
import type { ValidImportedBasketData } from '@/neos/business/rfq/strategy/leg/product/els/thunks/importBasketCompositionThunk.ts';

export function createUpdateProductAndReferenceWithImportedBasketDetailsThunk(
  rfqId: string,
  productId: string,
  underlyings: Underlying[],
  importedBasketDetails: ValidImportedBasketData,
): Thunk {
  return function updateProductAndReferenceWithImportedBasketDetailsThunk(
    dispatch,
    getState,
    { actionCreators, thunks, selectors },
  ) {
    const state = getState();
    const product = selectors.getProduct(state, productId);

    if (!isElsBasketProduct(product)) {
      return;
    }

    const newBasketCompositionDetails: BasketCompositionDetails[] = importedBasketDetails.map(
      (line, index) => {
        const underlyingId = underlyings.find(
          underlying => underlying.bloombergCode === line.bloombergCode,
        )?.id;

        return {
          containerIndex: index,
          underlyingId,
          weight: undefined,
          quantity: line.quantity,
          nominal: undefined,
          execFeesIn: {
            value: line.execFeesIn,
            unit: line.execFeesInUnit,
            type: undefined,
          },
          execFeesOut: {
            value: line.execFeesOut,
            unit: line.execFeesOutUnit,
            type: undefined,
          },
        };
      },
    );

    dispatch(
      actionCreators.neos.productCrudActions.update(product.uuid, {
        basketUnderlying: {
          ...product.basketUnderlying,
          basketComposition: newBasketCompositionDetails,
        },
      }),
    );

    importedBasketDetails.forEach(line => {
      const underlying = underlyings.find(
        underlying => underlying.bloombergCode === line.bloombergCode,
      );

      if (underlying) {
        const spotCurrency = getSpotCurrency(line.spotUnit, line.spot, underlying.currency);
        const spotNetCurrency = getSpotCurrency(
          line.spotNetUnit,
          line.spotNet,
          underlying.currency,
        );

        const reference: Reference = {
          isSpotConfirmed: undefined,
          underlyingId: underlying.id,
          refSpot: line.spot,
          refSpotUnit: spotCurrency,
          refSpotNet: line.spotNet,
          refSpotNetUnit: spotNetCurrency,
        };

        dispatch(
          actionCreators.neos.referenceCrudActions.upsert(
            {
              rfqId,
              underlyingId: underlying.id,
            },
            reference,
          ),
        );
      }
    });

    dispatch(thunks.neos.createDefaultBasketProductThunk(rfqId));
  };
}

function getSpotCurrency(
  spotUnit: string | undefined,
  spot: number | undefined,
  underlyingCurrency: string | undefined,
) {
  if (spot === undefined) {
    return undefined;
  }

  return spotUnit ?? underlyingCurrency;
}
