import { combineReducers } from 'redux';
import { deltaNotificationChangesReducer } from './deltaNotificationChanges/deltaNotificationChangesReducer';
import { executionPriceNotificationChangesReducer } from './executionPriceNotificationChanges/executionPriceNotificationChangesReducer';
import { traderAskNotificationChangesReducer } from './traderAskNotificationChanges/traderAskNotificationChangesReducer';
import { traderBidNotificationChangesReducer } from './traderBidNotificationChanges/traderBidNotificationChangesReducer';
import type { NeosWarningsState } from './warningModel';

export const warningsReducer = combineReducers<NeosWarningsState>({
  deltaNotificationChanges: deltaNotificationChangesReducer,
  executionPriceNotificationChanges: executionPriceNotificationChangesReducer,
  traderAskNotificationChanges: traderAskNotificationChangesReducer,
  traderBidNotificationChanges: traderBidNotificationChangesReducer,
});
