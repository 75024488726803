import type { Thunk } from '@/bootstrap/thunks';

export function createDerogatePredealChecksOnModalCloseThunk(rfqId: string): Thunk {
  return function derogatePredealChecksOnModalCloseThunk(
    dispatch,
    getState,
    {
      actionCreators: {
        neos: { createNeosPredealCheckDerogationRequestedAction, predealChecksCrudActions },
      },
      selectors,
    },
  ) {
    const state = getState();
    const riskAskPdc = selectors.getCreditCheckAskPdc(state, rfqId, selectors);
    if (!!riskAskPdc && riskAskPdc.hasRiskFieldsChanged && riskAskPdc.pdcDerogation) {
      dispatch(
        createNeosPredealCheckDerogationRequestedAction(rfqId, {
          pdcDerogation: riskAskPdc.pdcDerogation,
          pdcUuid: riskAskPdc.uuid,
          derogationComment: riskAskPdc.derogationComment,
          riskId: riskAskPdc.riskId,
        }),
      );
      dispatch(
        predealChecksCrudActions.update(riskAskPdc.uuid, {
          hasRiskFieldsChanged: false,
        }),
      );
    }

    const riskBidPdc = selectors.getCreditCheckBidPdc(state, rfqId, selectors);
    if (!!riskBidPdc && riskBidPdc.hasRiskFieldsChanged && riskBidPdc.pdcDerogation) {
      dispatch(
        createNeosPredealCheckDerogationRequestedAction(rfqId, {
          pdcDerogation: riskBidPdc.pdcDerogation,
          pdcUuid: riskBidPdc.uuid,
          derogationComment: riskBidPdc.derogationComment,
          riskId: riskBidPdc.riskId,
        }),
      );
      dispatch(
        predealChecksCrudActions.update(riskBidPdc.uuid, {
          hasRiskFieldsChanged: false,
        }),
      );
    }
  };
}
