import { addMilliseconds, format } from 'date-fns';
import { ceil } from 'lodash';

export function formatMSToHumanTime(durationMilis: number) {
  if (durationMilis <= 0) {
    return '00:00';
  }
  const ONE_HOUR = 3_600_000;
  const isThereDay = durationMilis >= ONE_HOUR * 24;
  if (isThereDay) {
    const hours = ceil(durationMilis / ONE_HOUR);
    return `${hours} hours`;
  }

  const roundedDurationMs = ceil(durationMilis, -3);
  const isThereHour = roundedDurationMs >= ONE_HOUR;

  return format(
    addMilliseconds(new Date(2021, 0, 0, 0, 0, 0), roundedDurationMs),
    `${isThereHour ? 'HH:' : ''}mm:ss`,
  );
}
