import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import {
  type LotSizeMarketCellDispatchProps,
  type LotSizeMarketCellMapStateProps,
  type LotSizeMarketCellOwnProps,
  LotSizeMarketCellUnconnected,
} from './LotSizeMarketCell';
import { getLotSizeMarketCellModel } from './getLotSizeMarketCellModel';

export const LotSizeMarketCell = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LotSizeMarketCellUnconnected);

function mapStateToProps(
  state: AppState,
  { rfqId, strategyId }: LotSizeMarketCellOwnProps,
): LotSizeMarketCellMapStateProps {
  return getLotSizeMarketCellModel(state, rfqId, strategyId, selectors);
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId, strategyId }: LotSizeMarketCellOwnProps,
): LotSizeMarketCellDispatchProps {
  return {
    onLotSizeMarketChanged(legId: string, lotSizeMarket: string | undefined) {
      dispatch(
        neosThunks.createRfqChangeMarketLotSizeThunk(rfqId, strategyId, legId, lotSizeMarket),
        createLogAnalyticsAction('NEOS RFQ', 'Select lot size', lotSizeMarket),
      );
    },
  };
}
