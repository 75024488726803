import { isDefined } from '@/util/undefinedAndNull/isDefined';
import { flatMap, isEqual, uniqWith } from 'lodash';

interface TwoDimentionalArrayToStringParams {
  ignoreStrategiesWithEmptyValues: boolean;
  omitBrakets: boolean;
}

const defaultTwoDimentionalArrayToStringParams: TwoDimentionalArrayToStringParams = {
  ignoreStrategiesWithEmptyValues: true,
  omitBrakets: false,
};

export function twoDimentionalArrayToString<T>(
  arr: Array<Array<T | null | undefined>>,
  formatter: (x: T) => string,
  opt?: Partial<TwoDimentionalArrayToStringParams>,
): string {
  const options: TwoDimentionalArrayToStringParams = {
    ...defaultTwoDimentionalArrayToStringParams,
    ...(opt ?? {}),
  };
  const filteredArray = arr
    .filter(
      a => !options.ignoreStrategiesWithEmptyValues || a.some(a => isDefinedOrNonEmptyString(a)),
    )
    .map(a => {
      const un = uniqWith(a, isEqual);
      return un.length === 1 ? un : a;
    });

  const flattened = flatMap(filteredArray, a => a);
  const uniqueFlattened = uniqWith(flattened, isEqual);
  if (uniqueFlattened.length < 2) {
    const e = uniqueFlattened[0];
    return isDefined(e) ? formatter(e) : '';
  }
  const shouldUseBrackets = filteredArray.length > 1 && !options.omitBrakets;
  return filteredArray
    .map(
      a =>
        (shouldUseBrackets ? '[' : '') +
        `${innerArrayToString<T>(a, formatter)}` +
        (shouldUseBrackets ? ']' : ''),
    )
    .join(', ');
}

function innerArrayToString<T>(a: (T | null | undefined)[], formatter: (x: T) => string) {
  return a.map(e => (isDefined(e) ? formatter(e) : 'N/A')).join('; ');
}

function isDefinedOrNonEmptyString<T>(v: T | undefined) {
  return isDefined(v) && (v as unknown as string) !== '';
}
