import type { ChangeEvent } from 'react';
import * as XLSX from 'xlsx';
import { getDataFromClipboard } from '@/util/clipboard/clipboard.ts';
import { z } from 'zod';
import type { ExcelDateFormat } from '@/neos/business/ui/userPreferences/userPreferencesUiModel.ts';
import { DATE_SHORT_KEBAB_FORMAT } from '@/util/date/dateFormatHelper.ts';

function parseExcelDateUsFormat<T>(
  fileData: string | ArrayBuffer | null,
  header: XLSX.Sheet2JSONOpts['header'] = undefined,
) {
  const workbook = XLSX.read(fileData, {
    type: 'binary',
    cellText: true,
    cellDates: false,
    dateNF: 'dd/mm/yyyy',
    /*
      NOTE: counterintuitively, that allows to get the dates in Excel and convert them to US format for dev purpose
      e.g. input in US format "03/04/2024" in Excel gives a Date "3rd April" (ISO) instead of "4th March"
      But input "11/18/2024" in Excel is not read as a Date (because 18 exceeds the available number of months)
      So we get it as it is "11/18/2024" (mm/dd/yyyy)
      Here we harmonize all the months to be on the first 2 digits
      So, "03/04/2024" => "03/04/2024" so that we can have the month first (mm/dd/yyyy)
    */
  });

  const sheet = workbook.Sheets[workbook.SheetNames[0]];
  const resultSheet = XLSX.utils.sheet_to_json<T>(sheet, {
    raw: false,
    header,
  });
  const datesInUsFormatSheet: T[] = [];

  for (let index = 0; index < resultSheet.length; index++) {
    const row = new Object(resultSheet[index]);

    const newRow = Object.fromEntries(
      Object.entries(row).map(([label, value]) => {
        const parsedValue = value.split('/');

        if (parsedValue.length != 3) {
          return [label, value];
        }
        return [label, `${parsedValue[2]}-${parsedValue[0]}-${parsedValue[1]}`];
      }),
    );
    datesInUsFormatSheet.push(newRow as T);
  }
  return datesInUsFormatSheet;
}

export function parseExcelFile<T>(
  fileData: string | ArrayBuffer | null,
  header: XLSX.Sheet2JSONOpts['header'] = undefined,
  dateFormat: ExcelDateFormat | undefined,
): T[] {
  if (dateFormat === 'EN-US') {
    return parseExcelDateUsFormat(fileData, header);
  }

  const workbook = XLSX.read(fileData, { type: 'binary', cellText: false, cellDates: true });

  const sheet = workbook.Sheets[workbook.SheetNames[0]];
  return XLSX.utils.sheet_to_json<T>(sheet, {
    raw: false,
    header,
    dateNF: DATE_SHORT_KEBAB_FORMAT,
  });
}

export async function importFromExcelClipboardData<T>(
  handleClipboardData: (data: T[]) => void,
  handleError: (error: string) => void,
) {
  const result = await getDataFromClipboard();
  if (result.status === 'FAILED') {
    handleError(result.message);
    return;
  }

  handleClipboardData(tsvToJson(result.data) as T[]);
}

export function exportDataToExcel<T extends Object>(data: T[], title: string): void {
  const headers = [Object.keys(data[0])];
  const ws = XLSX.utils.aoa_to_sheet(headers);
  XLSX.utils.sheet_add_json(ws, data, {
    header: Object.keys(data[0]),
    skipHeader: true,
    origin: -1,
  });

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  XLSX.writeFile(wb, `${title}.xlsx`, { bookType: 'xlsx', type: 'binary', cellDates: true });
}

export function importExcelFileContent<T>(
  event: ChangeEvent<HTMLInputElement>,
  onImportDataCallback: (data: T[]) => void,
  config?: { withHeader?: boolean; dateFormat?: ExcelDateFormat },
): void {
  const getContent = () => {
    event.persist();
    const reader = new FileReader();
    reader.onload = () => {
      const fileData = reader.result;
      const parsedData = parseExcelFile<T>(
        fileData,
        config?.withHeader ? 1 : undefined,
        config?.dateFormat,
      );
      return onImportDataCallback(parsedData);
    };

    const selectedFile = event.target.files?.item(0);
    if (selectedFile) {
      reader.readAsBinaryString(selectedFile);
    }
    event.target.value = '';
  };

  getContent();
}

/*
  Transform tab separated values (tsv) with headers into json object
 */
function tsvToJson(tsvText: string): unknown[] {
  // Split all the text into seperate lines on new lines and carriage return feeds
  const allTextLines = tsvText.split(/\r\n|\n/);
  // Split on tabs
  const headers = allTextLines[0].split(/\t/);
  const lines = [];

  for (let i = 1; i < allTextLines.length; i++) {
    const data = allTextLines[i].split(/\t/);

    if (data.length == headers.length) {
      const row: Record<string, string> = {};
      for (let j = 0; j < headers.length; j++) {
        row[headers[j]] = data[j];
      }
      lines.push(row);
    }
  }

  return lines;
}

export const RawImportedDataSchema = z.array(z.record(z.string(), z.string()));
export type RawImportedDataSchemaType = z.infer<typeof RawImportedDataSchema>;
