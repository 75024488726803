import { logger } from '@/util/logging/logger';
import { mt } from '@/util/logging/messageTemplates';
import type { ConnectableObservable, Observable, Subscription } from 'rxjs';
import { filter, map, share, take, tap } from 'rxjs/operators';
import {
  type DataStreamData,
  type SessionStreamConfiguration,
  type SessionStreamEvent,
  createSessionStream,
  isData,
  toDataStreamEvent,
} from './signalrConnectionHelper';

export interface SignalRService {
  datastreamId: string;
  connected: boolean;
  datastream$: Observable<DataStreamData>;
  reconnect$: Observable<any>;
  connect(hubUrl: string, handler: (bootstrapOptions?: { isSignalRError: boolean }) => void): void;
  disconnect(): void;
}

const defaultSignalRConfig: SessionStreamConfiguration = {
  hubUrl: '',
};

let session$: ConnectableObservable<SessionStreamEvent>;
let dataStream$: Observable<DataStreamData>;
let reconnect$: Observable<any>;

let dataStreamId: string;
let subscription: Subscription;

const service: SignalRService = {
  connect(hubUrl, handler) {
    if (this.connected) {
      this.disconnect();
    }
    session$ = createSessionStream({
      ...defaultSignalRConfig,
      hubUrl,
    });

    dataStream$ = session$.pipe(
      filter(event => event.type === 'DATA'),
      map(event => toDataStreamEvent(event)),
      filter(isData),
      share(),
    );

    reconnect$ = session$.pipe(
      filter(event => event.type === 'RECONNECT'),
      tap(e => {
        dataStreamId = e.data;
      }),
      share(),
    );

    session$
      .pipe(
        filter(e => e.type !== 'DATA'),
        tap(e => logger.info(mt.signalrLog, e)),
        filter(e => e.type === 'START'),
        take(1),
      )
      .subscribe(e => {
        dataStreamId = e.data;
        handler();
      });

    session$
      .pipe(
        filter(e => e.type === 'STARTERROR'),
        take(1),
      )
      .subscribe(() => {
        handler({ isSignalRError: true });
      });

    subscription = session$.connect();

    const loggerSubscription = session$.subscribe(event => signalRLogFunction(event));

    subscription.add(loggerSubscription);
  },

  disconnect() {
    if (this.connected) {
      subscription.unsubscribe();
    }
  },
  get datastreamId() {
    return dataStreamId;
  },
  get datastream$() {
    return dataStream$;
  },
  get reconnect$() {
    return reconnect$;
  },
  get connected() {
    return subscription && !subscription.closed;
  },
};

function signalRLogFunction(content: SessionStreamEvent) {
  const { type, data } = content;

  if (type === 'ERROR' || type === 'SESSIONERROR') {
    logger.error(mt.signalrError, content);
  } else {
    const message = type === 'DATA' ? { type: 'DATA', data } : content;
    logger.info(mt.signalrLog, message);
  }
}

export default service;
