import type { Thunk } from '@/bootstrap/thunks';
import { DefaultingScope, isElsProduct } from '../../models';
import { DefaultingOptions } from '../../rfqActions';

export function createDefaultSalesCounterpartyThunk(rfqId: string): Thunk {
  return function defaultSalesCounterpartyThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { createDefaultRfqRequestedAction },
      },
    },
  ) {
    const state = getState();
    const product = selectors.getRfqMasterProduct(state, rfqId, selectors);
    const overrideScopes = [];
    const enrichScopes = [];

    if (isElsProduct(product)) {
      overrideScopes.push(
        DefaultingScope.ROLE_DEFINITION,
        DefaultingScope.BREAK_FEE_ELECTION,
        DefaultingScope.COMPONENT_SECURITY_INDEX_ANNEX,
        DefaultingScope.DIVIDEND_PAYMENT_DATE,
        DefaultingScope.SETTLEMENT_METHOD_ELECTION,
        DefaultingScope.UNDERLYING,
        DefaultingScope.RIGHT_TO_SUBSTITUTE,
      );
    }

    const isMasterStrategyOtc = selectors.isMasterStrategyOtc(state, rfqId, selectors);
    if (isMasterStrategyOtc) {
      overrideScopes.push(
        DefaultingScope.CONFIRM_MEDIA,
        DefaultingScope.MCA,
        DefaultingScope.RELATED_EXCHANGE,
        DefaultingScope.SPECIAL_DIVIDENDS,
        DefaultingScope.LOCAL_TAXES,
      );
      enrichScopes.push(DefaultingScope.PRE_ALLOCATIONS);
    }

    if (overrideScopes.length > 0 || enrichScopes.length > 0) {
      const options = new DefaultingOptions({ overrideScopes, enrichScopes });
      return dispatch(createDefaultRfqRequestedAction(rfqId, options));
    }
  };
}
