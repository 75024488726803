import { ErrorHighlight } from '@/neos/components/share/errorHighlight';
import { MultiToggleButton } from '@/neos/components/share/multiToggleButton/MultiToggleButton';
import type { AccrualType } from '../../../../../../../neos/business/neosModel';
import type { AccrualCellsModel } from './getAccrualCellsModel';

export interface AccrualCellsOwnProps {
  rfqId: string;
  strategyId: string;
}

export type AccrualCellsMapStateProps = AccrualCellsModel;

export interface AccrualCellsDispatchProps {
  onAccrualChanged(productId: string, clsType: AccrualType | undefined): void;
}

type AccrualCellsProps = AccrualCellsOwnProps &
  AccrualCellsMapStateProps &
  AccrualCellsDispatchProps;

export function AccrualCells(props: AccrualCellsProps) {
  return (
    <section>
      {props.accruals.map(({ id, value, isEditable }) => (
        <ErrorHighlight
          key={id}
          errorField={'accrual'}
          related={{ rfqId: props.rfqId, legId: id, strategyId: props.strategyId }}
        >
          <MultiToggleButton<AccrualType | undefined>
            className="form-control"
            readOnly={!isEditable}
            selectedValue={value}
            valueFormatter={v => (v ? v : '')}
            availableValues={props.availableAccruals}
            onValueChange={newAccrual => props.onAccrualChanged(id, newAccrual)}
            data-e2e="neos-strategy-accrual-cells"
          />
        </ErrorHighlight>
      ))}
    </section>
  );
}
