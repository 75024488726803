import type { DetailedHTMLProps, HTMLAttributes } from 'react';
import styles from './miniAlert.module.scss';
interface MiniAlertProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  lines: JSX.Element;
  fn?: {
    onYes: () => void;
    onNo: () => void;
  };
}

export const MiniAlert = (props: MiniAlertProps) => {
  const { fn, lines, ...htmlProps } = props;
  return (
    <div {...htmlProps} className={`${styles['alert-container']} `} data-e2e="neos-alert-leg-over">
      <div role="alert" className="alert alert-warning">
        <div>{lines}</div>
        {fn?.onYes && fn?.onNo && (
          <div className={`${styles['button-container']} `}>
            <button
              className="btn btn-flat-primary-alt"
              data-e2e="neos-alert-leg-over-yes"
              onClick={fn.onYes}
            >
              Yes
            </button>
            <button
              className="btn btn-flat-primary-alt"
              data-e2e="neos-alert-leg-over-no"
              onClick={fn.onNo}
            >
              No
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
