import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import {
  type Tenor,
  isClsProduct,
  isElsProduct,
} from '../../../../../../../neos/business/neosModel';
import type { Field } from '../utils/Field';
import type { MaturityCellsModelGetters } from './modelGetters';

export interface MaturityCellsModel {
  displayCompositionEmptyCell: boolean;
  maturities: Array<Field<string | undefined> & { isValid: boolean }>;
  maturityWarningText?: string;
  isRfqSecondaryWidgetToggleOn: boolean;
  availableMaturities: string[][];
  tenors: Tenor[];
  isElsOrCls: boolean;
}

export function getMaturityCellsModel(
  appState: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
  { getMaturitiesFieldsArray }: MaturityCellsModelGetters,
): MaturityCellsModel {
  const { getStrategyData, getLegMaturities, getStrategyProduct } = selectors;
  const { hasACompositionLeg, getTenors, getStrategyDefinition } = selectors;

  const { legIds } = getStrategyData(appState, strategyId);

  const maturities = getMaturitiesFieldsArray(appState, rfqId, strategyId, selectors);
  const maturityWarningText = 'Maturity must be today or later';
  const isRfqSecondaryWidgetToggleOn = selectors.isRfqSecondaryWidgetToggleOn(appState.ui, rfqId);
  const hasACompoLeg = hasACompositionLeg(appState, strategyId, selectors);

  const availableMaturities = hasACompoLeg
    ? [getLegMaturities(appState, legIds[legIds.length - 1], selectors)]
    : legIds.map(legId => getLegMaturities(appState, legId, selectors));

  const product = getStrategyProduct(appState, strategyId, selectors);
  const tenors = getTenors(appState.referenceData);
  const isElsOrCls = isElsProduct(product) || isClsProduct(product);

  const { strategyType } = getStrategyData(appState, strategyId);
  const { sameProductMaturity } = getStrategyDefinition(appState.referenceData, strategyType);

  const displayCompositionEmptyCell = hasACompoLeg && !sameProductMaturity && !!maturities.length;

  return isRfqSecondaryWidgetToggleOn
    ? {
        displayCompositionEmptyCell,
        maturities,
        maturityWarningText,
        availableMaturities,
        isRfqSecondaryWidgetToggleOn,
        isElsOrCls,
        tenors,
      }
    : {
        displayCompositionEmptyCell,
        maturities,
        availableMaturities,
        isRfqSecondaryWidgetToggleOn,
        isElsOrCls,
        tenors,
      };
}
