import type { Dispatchable, Thunk } from '@/bootstrap/thunks';
import type { NegoStep } from '../../../neos/business/neosModel';

export function createNeosSwitchNegoStepThunk(rfqId: string, targetStep: NegoStep): Thunk {
  return function neosSwitchNegoStepThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { rfqUiCrudActions, createTradeRecapRequestedAction },
      },
    },
  ) {
    const actions: Dispatchable[] = [
      rfqUiCrudActions.patchOrInsert(rfqId, {
        selectedNegoStep: targetStep,
      }),
    ];

    if (isRequestTradeRecapNeeded()) {
      actions.push(createTradeRecapRequestedAction(rfqId, false));
    }
    return dispatch(actions);

    function isRequestTradeRecapNeeded(): boolean {
      const isPostNegoTargetStep = targetStep === 'POST_NEGO';
      if (!isPostNegoTargetStep) {
        return false;
      }

      const state = getState();
      const { tradeRecap } = selectors.getRfqData(state, rfqId);
      const isRecapEnabled = selectors.isTradeRecapEnabled(state, rfqId, selectors);
      const isTradeRecapNotDefined = !tradeRecap;

      return isRecapEnabled && isTradeRecapNotDefined;
    }
  };
}
