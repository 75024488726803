import type { BookingStepStatus } from '../../../../neos/business/neosOnyxModel';

export interface UiBookingStatus {
  name: string;
  className: string;
  tooltip?: string;
}

export function getUiBookingStatus(
  status: BookingStepStatus | undefined,
  tooltip?: string,
): UiBookingStatus {
  switch (status) {
    case 'BOOKED':
      return { name: 'Booked', className: 'bg-success' };
    case 'POSITION_BOOKED':
      return { name: 'Position Booked', className: 'bg-success' };
    case 'PENDING':
      return { name: 'Pending', className: 'bg-warning' };
    case 'INITIALIZED':
      return {
        name: 'Initialized',
        className: 'bg-danger',
      };
    case 'SKIPPED':
      return { name: 'Skipped', className: 'bg-danger' };
    case 'FAILED':
      return { name: 'Failed', className: 'bg-danger', tooltip };
    case 'POSITION_FAILED':
      return { name: 'Position Failed', className: 'bg-danger', tooltip };
    case 'UPDATED':
      return { name: 'Updated', className: 'bg-warning' };
    default:
      return { name: '', className: '' };
  }
}
