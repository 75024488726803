import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { Observable } from 'rxjs';
import type { OnyxCounterparty } from '../../../neosOnyxModel';
import type { CounterpartApi } from './counterpartEpics';

const URL = 'api/referential/counterparts';

export const makeCounterpartApi = (http: SgmeHttp): CounterpartApi => ({
  getCounterparts(users: string[]): Observable<OnyxCounterparty[]> {
    const url: string = `${URL}?${users.map(user => 'username=' + user).join('&')}&showfunds=true`;
    return http.get<OnyxCounterparty[]>({ url });
  },
  getCounterpart(bdrId: number): Observable<OnyxCounterparty> {
    const url: string = `${URL}/${bdrId}`;
    return http.get<OnyxCounterparty>({ url });
  },
});
