import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { LoadVersionsApi } from './versionsEpics';
import type { Version } from './versionsModel';

export const createLoadVersionsApi = (http: SgmeHttp): LoadVersionsApi => ({
  loadVersions(uuid: string) {
    const url: string = `api/rfq/${uuid}/history/view`;
    return http.get<Version[]>({
      url,
    });
  },
});
