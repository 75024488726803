import { type ActionCreators, actionCreators } from '@/bootstrap/actions';
import type { ThunkEpic } from '@/bootstrap/epics';
import { type Selectors, selectors } from '@/bootstrap/selectors';
import { type Thunks, thunks } from '@/bootstrap/thunks';
import type { OnyxError } from '@/neos/business/mappers/error';
import { makeReferenceDataApi } from '@/neos/business/referenceData/referenceDataApi';
import type { ReferenceDataApi } from '@/neos/business/referenceData/referenceDataEpics';
import {
  type CounterpartPreferencesApis,
  counterpartPreferencesApis,
} from '@/neos/business/ui/userPreferences/counterparties/counterpartPreferencesApis';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { ofType } from 'redux-observable';
import { catchError, mergeMap } from 'rxjs/operators';

export function getSearchCounterpartiesEpic(http: SgmeHttp): ThunkEpic {
  const api = counterpartPreferencesApis(http);
  return createSearchCounterpartiesEpic(api, actionCreators, thunks);
}

export function getGetCurrentUserCounterpartsEpic(http: SgmeHttp): ThunkEpic {
  const api = makeReferenceDataApi(http);
  return createGetCurrentUserCounterpartsEpic(api, actionCreators, thunks, selectors);
}
export function getAddCounterpartiesEpic(http: SgmeHttp): ThunkEpic {
  const api = counterpartPreferencesApis(http);
  return createAddCounterpartPreferencesEpic(api, actionCreators, thunks);
}
export function getDeleteCounterpartiesEpic(http: SgmeHttp): ThunkEpic {
  const api = counterpartPreferencesApis(http);
  return createDeleteCounterpartPreferencesEpic(api, actionCreators, thunks);
}

export function createSearchCounterpartiesEpic(
  api: CounterpartPreferencesApis,
  actionCreators: ActionCreators,
  { createErrorToasterThunk }: Thunks,
): ThunkEpic {
  return action$ =>
    action$.pipe(
      ofType('USER_PREFERENCES_SEARCH_COUNTERPART'),
      mergeMap(({ entry }) => {
        return api.searchCounterpart(entry).pipe(
          mergeMap(searchedCounterparts => {
            return [
              actionCreators.neos.createInsertSearchedCounterpartsAction(searchedCounterparts),
              actionCreators.neos.createChangeUserPreferenceSearchCounterpartsLoadingAction(false),
            ];
          }),
          //TODO: check if error is OnyxError
          catchError((error: OnyxError) => [
            actionCreators.neos.createChangeUserPreferenceSearchCounterpartsLoadingAction(false),
            createErrorToasterThunk(
              { message: 'Error when search for counterparts in user preferences!' },
              error,
            ),
          ]),
        );
      }),
    );
}

export function createAddCounterpartPreferencesEpic(
  api: CounterpartPreferencesApis,
  actionCreators: ActionCreators,
  { createErrorToasterThunk }: Thunks,
): ThunkEpic {
  return action$ =>
    action$.pipe(
      ofType('USER_PREFERENCES_ADD_COUNTERPART'),
      mergeMap(({ counterpartId }) => {
        return api.addCounterpart(counterpartId).pipe(
          mergeMap(() => [
            actionCreators.neos.createGetCurrentUserCounterpartsAction(),
            actionCreators.neos.createChangeUserPreferenceAddCounterpartsLoadingAction(false),
          ]),
          //TODO: check if error is OnyxError
          catchError((error: OnyxError) => [
            actionCreators.neos.createChangeUserPreferenceAddCounterpartsLoadingAction(false),
            createErrorToasterThunk(
              { message: 'Error when adding a counterpart in user preferences!' },
              error,
            ),
          ]),
        );
      }),
    );
}

export function createDeleteCounterpartPreferencesEpic(
  api: CounterpartPreferencesApis,
  actionCreators: ActionCreators,
  { createErrorToasterThunk }: Thunks,
): ThunkEpic {
  return action$ =>
    action$.pipe(
      ofType('USER_PREFERENCES_DELETE_COUNTERPART'),
      mergeMap(({ counterpartId }) => {
        return api.deleteCounterpart(counterpartId).pipe(
          mergeMap(() => [
            actionCreators.neos.createGetCurrentUserCounterpartsAction(),
            actionCreators.neos.createChangeUserPreferenceDeleteCounterpartsLoadingAction(false),
          ]),
          //TODO: check if error is OnyxError
          catchError((error: OnyxError) => [
            actionCreators.neos.createChangeUserPreferenceDeleteCounterpartsLoadingAction(false),
            createErrorToasterThunk(
              { message: 'Error when deleting a counterpart in user preferences!' },
              error,
            ),
          ]),
        );
      }),
    );
}

export function createGetCurrentUserCounterpartsEpic(
  api: ReferenceDataApi,
  actionCreators: ActionCreators,
  { createErrorToasterThunk }: Thunks,
  { getSesameId }: Selectors,
): ThunkEpic {
  return (action$, state$) =>
    action$.pipe(
      ofType('GET_CURRENT_USER_COUNTERPARTS'),
      mergeMap(() => {
        const sesameId = getSesameId(state$.value);
        return api.getCounterparts(sesameId).pipe(
          mergeMap(counterparts => [
            actionCreators.neos.createCounterpartReceivedAction(counterparts),
          ]),
          catchError((error: OnyxError) => [
            createErrorToasterThunk(
              { message: 'Error when getting current user counterparts for user preferences!' },
              error,
            ),
          ]),
        );
      }),
    );
}
