import type { Thunk } from '@/bootstrap/thunks';

export function createCopyToClipboardThunk(text: string, toasterMessage = 'Copied!'): Thunk {
  return function copyToClipboardThunk(
    dispatch,
    _,
    { thunks: { createInfoToasterThunk }, services: { copyToClipboard } },
  ) {
    copyToClipboard(text, {
      onSuccess: () =>
        dispatch(createInfoToasterThunk({ message: toasterMessage, dismissAfter: 1500 })),
    });
  };
}
