import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { Broker } from '@/neos/business/referenceData/brokers/brokersModel';
import type { RelatedExchange } from '@/neos/business/referenceData/relatedExchange/relatedExchangeModel';
import type { UnderlyingType } from '@/neos/business/rfq/strategy/leg/legOnyxModel';
import { flatten, sortBy, uniq } from 'lodash';
import type {
  FeatureDefinition,
  PredealCheckAvailableDerogation,
  PriceUnitType,
  ReferenceData,
  SwitcherFeatureType,
  Tenor,
} from '../../../neos/business/neosModel';
import type { PredealCheckType } from '../predealCheck/predealCheckModel';
import type { Curve } from './curvesList/curveModel';
import type { MarginRules } from './marginRule/marginRuleModel';
import type { Market } from './markets/marketsModel';
import type { PrimeBroker } from './primeBroker/primeBrokerActions';
import type {
  Currency,
  StrategyDefinition,
  StrategyScope,
  StrategyType,
  TraderGroup,
  User,
} from './referenceDataModel';
import type { SizeType } from './referenceDataOnyxModel';
import type { ReferenceDataState } from './referenceDataState';
import type { SalesGroup } from './salesGroups/salesGroupsModel';
import type { SalesLocations } from './salesLocation/salesLocationsModel';
import { statusSelectors } from './status/statusSelector';
import type { StrategyConfiguration } from './strategyConfiguration/strategyConfigurationModel';
import type { FeatureType } from './strategyDefinition/strategyDefinitionModel';

export const referenceDataSelectors = {
  getCurrencyList,
  getBrokersList,
  getPreferenceBrokerById,
  getSalesValos,
  getSalesMarginRules,
  getTraders,
  getTraderById,
  getTraderGroups,
  getSalesGroups,
  getUserGroups,
  getStrategyDefinitions,
  getStrategyDefinition,
  getPredealCheckDerogations,
  getAllReferenceDataAreFetched,
  getSingleStrategyDefinitionFeature,
  getEligibleExtraFeatures,
  isAnyExtraFeatureEligible,
  isStrategyDefinitionListed,
  isStrategyDefinitionOtc,
  getTraderGroup,
  getSalesLocations,
  getStrategyConfiguration,
  getStratgyDefinitionMandatoryFeatures,
  isStrategyDefinitionFeatureMandatory,
  getSalesValo,
  isQuoteUnitEditable,
  getStrategyAvailablePriceUnitTypes,
  getStrategyAvailableSizeTypes,
  getTenors,
  isStrategyTypeCustomUnderlying,
  isReadOnlyStrategy,
  getUsMarkets,
  getMarkets,
  getAvailableStrategies,
  getCurrencyCurvesList,
  getPrimeBrokersByCounterpartId,
  getPrimeBrokersByRfqId,
  getRelatedExchangeFields,
  selectHaveOneLegWithTaxCollectionInStrategyDefinition,
  getUnderlyingTypes,
  getEliotMarkets,
  ...statusSelectors,
};

function getCurrencyList(state: ReferenceDataState): Currency[] {
  return state.currencies.sort((a, b) => (a.refId > b.refId ? 1 : a.refId < b.refId ? -1 : 0));
}

function getBrokersList(state: ReferenceDataState): Broker[] {
  return state.brokers.toSorted((brokerA, brokerB) => (brokerA.name > brokerB.name ? 1 : -1));
}

function getPreferenceBrokerById(state: ReferenceDataState, id: string): Broker | undefined {
  return getBrokersList(state).find(broker => broker.id.toString() === id);
}

function getSalesValo(state: ReferenceDataState, salesValoId: string): User | undefined {
  return getSalesValos(state)[salesValoId];
}

function getSalesValos(state: ReferenceDataState): Record<string, User> {
  return state.salesValos;
}

function getSalesMarginRules({ marginRules: salesMarginRules }: ReferenceDataState): MarginRules[] {
  return salesMarginRules;
}

function getTraderById(state: ReferenceDataState, traderId: string): User | undefined {
  return getTraders(state)[traderId];
}

function getTraders(state: ReferenceDataState): Record<string, User> {
  return state.traders;
}

function getTraderGroups(state: ReferenceDataState): TraderGroup[] {
  return state.traderGroups;
}

function getSalesGroups(state: ReferenceDataState): SalesGroup[] {
  return state.salesGroups;
}

export type UserGroup = Pick<TraderGroup, 'id' | 'value'> | Pick<SalesGroup, 'id' | 'value'>;

function getUserGroups(state: ReferenceDataState): UserGroup[] {
  const traderGroups = getTraderGroups(state);
  const salesGroups = getSalesGroups(state);
  return [
    ...traderGroups.map(({ id, value }): UserGroup => ({ id, value })),
    ...salesGroups.map(({ id, value }): UserGroup => ({ id, value })),
  ];
}

function getStrategyConfiguration(state: ReferenceDataState): StrategyConfiguration {
  return state.strategyConfiguration;
}

export interface StrategyTypeWithLabel {
  name: string;
  alias: string;
  id: StrategyType;
}

function getAvailableStrategies(
  state: ReferenceDataState,
  scope: StrategyScope,
  selectors: Selectors,
): StrategyTypeWithLabel[] {
  const { readOnlyStrategies, editableStrategies } = selectors.getStrategyConfiguration(state);
  const strategiesDefinitions = selectors.getStrategyDefinitions(state, scope);
  const strategyDefinitionIds: StrategyTypeWithLabel[] = strategiesDefinitions
    .filter(strategy => [...editableStrategies, ...readOnlyStrategies].includes(strategy.id))
    .map(({ id, name, alias }) => ({ id, name, alias }));
  return sortBy(strategyDefinitionIds, sd => sd.name);
}

function isReadOnlyStrategy(state: ReferenceDataState, strategyType: StrategyType): boolean {
  return (state.strategyConfiguration.readOnlyStrategies ?? []).includes(strategyType);
}

function getTraderGroup(state: ReferenceDataState, traderGroupName: string) {
  return state.traderGroups.find(traderGroup => traderGroup.value === traderGroupName);
}

function getPrimeBrokersByCounterpartId(
  state: ReferenceDataState,
  counterpartId: string,
): PrimeBroker[] | undefined {
  return state.primeBrokers[counterpartId];
}

function getPrimeBrokersByRfqId(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): PrimeBroker[] | undefined {
  const counterpartId = selectors.getSelectedCounterpartId(state, rfqId);
  if (counterpartId) {
    return state.referenceData.primeBrokers[counterpartId];
  }
  return undefined;
}

function getSalesLocations(state: ReferenceDataState): SalesLocations[] {
  return state.salesLocations;
}

function getTenors(state: ReferenceDataState): Tenor[] {
  return state.tenors;
}

function getMarkets(state: ReferenceDataState): Market[] {
  return state.markets;
}

function getEliotMarkets(state: ReferenceDataState): ReferenceData['marketsEliot'] {
  return state.marketsEliot;
}

function getUsMarkets(state: ReferenceDataState): Market[] {
  return state.usMarkets;
}

function getStrategyDefinitions(
  state: ReferenceDataState,
  requestedScope: StrategyScope,
): StrategyDefinition[] {
  const strategyDefinitions = Object.values(state.strategyDefinitions);
  return requestedScope === 'RFQ'
    ? strategyDefinitions.filter(({ availableStrategyScopes }) =>
        availableStrategyScopes.find(scope => scope === 'RFQ'),
      )
    : strategyDefinitions.filter(({ availableStrategyScopes }) =>
        availableStrategyScopes.find(
          scope => scope === 'DELTA_EXCHANGE' || scope === 'DELTA_EXCHANGE_OTC',
        ),
      );
}

function getStrategyDefinition(
  state: ReferenceDataState,
  strategyType: StrategyType,
): StrategyDefinition {
  const strategyDefinition = state.strategyDefinitions[strategyType];
  if (!strategyDefinition) {
    throw new Error(`Cannot find strategy type ${strategyType}`);
  }
  return strategyDefinition;
}

function getUnderlyingTypes(
  state: AppState,
  strategyId: string,
  selectors: Selectors,
): UnderlyingType[] {
  const strategyData = selectors.getStrategyData(state, strategyId);
  return (
    selectors
      .getStrategyDefinition(state.referenceData, strategyData.strategyType)
      .legs.find(({ master }) => master)?.availableProductUnderlyingTypes ?? []
  );
}

function selectHaveOneLegWithTaxCollectionInStrategyDefinition(
  state: AppState,
  strategyIds: string[],
  selectors: Selectors,
): boolean {
  return strategyIds.some(strategyId => {
    const strategyData = selectors.getStrategyData(state, strategyId);
    const strategyDefinition = selectors.getStrategyDefinition(
      state.referenceData,
      strategyData.strategyType,
    );
    return strategyDefinition.legs.some(legDefinition => legDefinition.taxCollection);
  });
}

function getStratgyDefinitionMandatoryFeatures(
  state: ReferenceDataState,
  strategyType: StrategyType,
  neosSelectors: Selectors,
): FeatureDefinition[] {
  const { legs } = neosSelectors.getStrategyDefinition(state, strategyType);
  return legs[0].features.filter(({ isExtra }) => !isExtra);
}

function isStrategyDefinitionFeatureMandatory(
  state: ReferenceDataState,
  strategyType: StrategyType,
  featureType: FeatureType,
  neosSelectors: Selectors,
): boolean {
  return !!neosSelectors
    .getStratgyDefinitionMandatoryFeatures(state, strategyType, neosSelectors)
    .find(({ type }) => featureType === type);
}

function getPredealCheckDerogations(
  state: ReferenceDataState,
  predealCheckType: PredealCheckType,
): PredealCheckAvailableDerogation[] {
  return state.predealChecksDerogations ? state.predealChecksDerogations[predealCheckType] : [];
}

function getAllReferenceDataAreFetched(state: ReferenceDataState): boolean {
  return state.areReferenceDataReceived;
}

function getStrategyDefinitionFeatures(
  state: ReferenceDataState,
  strategyType: StrategyType,
): FeatureDefinition[] {
  const strategyDefinition = getStrategyDefinition(state, strategyType);
  return strategyDefinition.legs[0].features;
}

function getSingleStrategyDefinitionFeature(
  state: ReferenceDataState,
  strategyType: StrategyType,
  featureType: FeatureType,
): FeatureDefinition | undefined {
  const features: FeatureDefinition[] = getStrategyDefinitionFeatures(state, strategyType);
  return features.find(({ type }: FeatureDefinition) => type === featureType);
}

function getEligibleExtraFeatures(
  state: ReferenceDataState,
  strategyType: StrategyType,
): SwitcherFeatureType[] {
  const definitions: FeatureDefinition[] = getStrategyDefinitionFeatures(state, strategyType);
  return definitions
    .filter(({ isExtra }: FeatureDefinition) => isExtra)
    .map(({ type }: FeatureDefinition) => type)
    .filter(
      (type): type is SwitcherFeatureType =>
        type !== 'RESET_FREQUENCY_FEATURE' &&
        type !== 'EQUITY_BULLET' &&
        type !== 'RATE_BULLET' &&
        type !== 'RATE_OVERNIGHT' &&
        type !== 'EXEC_FEES' &&
        type !== 'INTEREST_RATE_INDEX_FEATURE' &&
        type !== 'FOREX_TYPE',
    );
}

function isAnyExtraFeatureEligible(state: ReferenceDataState, strategyType: StrategyType): boolean {
  return !!getEligibleExtraFeatures(state, strategyType).length;
}

function isStrategyDefinitionListed(
  state: ReferenceDataState,
  strategyType: StrategyType,
  selectors: Selectors,
): boolean {
  const strategydefinition = selectors.getStrategyDefinition(state, strategyType);
  return strategydefinition.legs.every(({ product }) => product.negotiation === 'LISTED');
}

function isStrategyDefinitionOtc(
  state: ReferenceDataState,
  strategyType: StrategyType,
  selectors: Selectors,
): boolean {
  const strategydefinition = selectors.getStrategyDefinition(state, strategyType);
  return strategydefinition.legs.every(({ product }) => product.negotiation === 'OTC');
}

function isQuoteUnitEditable(
  state: ReferenceDataState,
  strategyType: StrategyType,
  selectors: Selectors,
): boolean {
  const priceUnitTypes = selectors.getStrategyAvailablePriceUnitTypes(
    state,
    strategyType,
    selectors,
  );
  return (
    selectors.isStrategyTypeCustomUnderlying(state, strategyType, selectors) &&
    priceUnitTypes?.includes('CCY')
  );
}

function isStrategyTypeCustomUnderlying(
  state: ReferenceDataState,
  strategyType: StrategyType,
  selectors: Selectors,
): boolean {
  const strategydefinition = selectors.getStrategyDefinition(state, strategyType);
  return strategydefinition.legs[0].availableProductUnderlyingTypes?.[0] === 'CUSTOM';
}

function getStrategyAvailablePriceUnitTypes(
  state: ReferenceDataState,
  strategyType: StrategyType,
  selectors: Selectors,
): PriceUnitType[] {
  const strategydefinition = selectors.getStrategyDefinition(state, strategyType);
  return strategydefinition.availablePriceUnitTypes || [];
}

function getStrategyAvailableSizeTypes(
  state: ReferenceDataState,
  strategyType: StrategyType,
  selectors: Selectors,
): SizeType[] {
  const strategydefinition = selectors.getStrategyDefinition(state, strategyType);
  return uniq(flatten(strategydefinition.legs.map(leg => leg.availableSizeTypes)));
}

function getCurrencyCurvesList(state: ReferenceDataState, currency: string): Curve[] {
  return state.currencyCurvesList[currency] ?? [];
}

function getRelatedExchangeFields(state: ReferenceDataState): RelatedExchange[] {
  return state.relatedExchangeFields;
}
