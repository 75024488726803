import { formatGridTemplateColumns } from '@/util/format/formatGridTemplateColumns';
import { Fragment } from 'react';
import { LegDescription } from '../genericLegDescription/LegDescription';
import styles from './ListedExecutions.module.scss';
import type { ListedExecutionsModel } from './getListedExecutionsModel';
import { LegExecution } from './legExecution/LegExecution.container';
import { LegExecutionHeader } from './legExecution/LegExecutionHeader';
import { ExecutionAllocations } from './legExecution/executionAllocations/ExecutionAllocations.container';
import { ExecutionAllocationsHeader } from './legExecution/executionAllocations/ExecutionAllocationsHeader';

export interface ListedExecutionsOwnProps {
  rfqId: string;
  strategyId: string;
  legId: string;
}

export interface ListedExecutionsStateProps {
  model: ListedExecutionsModel;
  isWarningDisplayed: boolean;
  isCommFlowIdDisplayed: boolean;
}

type ListedExecutionsProps = ListedExecutionsOwnProps & ListedExecutionsStateProps;

export const ListedExecutionsComponent = ({
  rfqId,
  strategyId,
  legId,
  model: { executionKeys, isManual, isReadOnly, orderId },
  isWarningDisplayed,
  isCommFlowIdDisplayed,
}: ListedExecutionsProps) => {
  const gridTemplateColumns = formatGridTemplateColumns([
    [!isReadOnly, '32px'], // add or delete icons
    'minmax(70px, 130px) minmax(85px, 130px)', // size, price
    [isManual, '210px'], // Execution date and time
    '140px', // Booking id
    [isWarningDisplayed, '18px'],
    [isCommFlowIdDisplayed, '140px'], // Commission flow id
    `32px 32px minmax(95px, 130px) minmax(85px, 130px)
    minmax(68px, 130px) 140px minmax(72px, 132px) minmax(82px, 132px) minmax(72px, 132px)`,
  ]);

  return (
    <div className={`${styles['leg-description']}`} data-e2e="neos-postNego-listed-execution">
      <LegDescription rfqId={rfqId} strategyId={strategyId} legId={legId} />
      <div
        className={`${styles['common-grid']} d-grid align-items-start`}
        style={{ gridTemplateColumns }}
      >
        <LegExecutionHeader
          rfqId={rfqId}
          legId={legId}
          orderId={orderId}
          isReadOnly={isReadOnly}
          isManual={isManual}
          isWarningDisplayed={isWarningDisplayed}
          isCommFlowIdDisplayed={isCommFlowIdDisplayed}
        />
        <ExecutionAllocationsHeader rfqId={rfqId} />
        {executionKeys.length ? (
          <>
            {executionKeys.map(executionKey => (
              <Fragment key={executionKey.executionId}>
                <LegExecution
                  executionKey={executionKey}
                  isReadOnly={isReadOnly}
                  isManual={isManual}
                  isWarningDisplayed={isWarningDisplayed}
                  isCommFlowIdDisplayed={isCommFlowIdDisplayed}
                />
                <ExecutionAllocations executionKey={executionKey} rfqId={rfqId} />
              </Fragment>
            ))}
          </>
        ) : null}
      </div>
    </div>
  );
};
