import type { BulkTransactionResponse } from '@/neos/business/epics/bulkLoadTransactionsEpic';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { Observable } from 'rxjs';
import type { RequestedVersion } from '../neosActions';
import type { OnyxTransaction } from '../neosOnyxModel';

export interface LoadTransactionApi {
  bulkLoadTransactions: (rfqIds: string[]) => Observable<BulkTransactionResponse[]>;
  loadTransaction: (
    rfqId: string,
    version: RequestedVersion,
  ) => Observable<OnyxTransaction | undefined>;
}

export const createLoadTransactionApi = (http: SgmeHttp): LoadTransactionApi => ({
  bulkLoadTransactions(rfqIds) {
    const url: string = `api/workflow-neos/transactions?uuid=${rfqIds.join(',')}`;
    return http.get({ url });
  },
  loadTransaction(
    rfqId: string,
    version: RequestedVersion,
  ): Observable<OnyxTransaction | undefined> {
    const url: string = `api/workflow-neos/transaction/${rfqId}${
      version !== 'LATEST' ? `/${version}` : ''
    }`;
    return http.get<OnyxTransaction | undefined>({ url });
  },
});
