import type { ExtractStrict } from '@/util/typings/ExtractStrict';

export type BookingStepStatus =
  | 'CANCELLED'
  | 'BOOKED'
  | 'POSITION_BOOKED'
  | 'PENDING'
  | 'SKIPPED'
  | 'FAILED'
  | 'POSITION_FAILED'
  | 'INITIALIZED'
  | 'UPDATED'
  | 'ABORTED'
  | 'MATCHED'
  | 'MANUALLY_BOOKED'
  | 'MANUALLY_UPDATED';

export const BookingStatusTooltip: Record<BookingStepStatus, string> = {
  UPDATED: 'Booked',
  BOOKED: 'Booked',
  POSITION_BOOKED: 'Position Booked',
  MATCHED: 'Matched',
  MANUALLY_BOOKED: 'Manually Booked',
  MANUALLY_UPDATED: 'Manually Updated',
  FAILED: 'Failed',
  POSITION_FAILED: 'Position Failed',
  SKIPPED: 'Skipped',
  ABORTED: 'Aborted',
  INITIALIZED: 'Initialized',
  PENDING: 'Pending',
  CANCELLED: 'Cancelled',
};

export type StatusColor = 'success' | 'danger' | 'warning' | 'info';

export const BookingStatusBorderColor: Record<BookingStepStatus, StatusColor> = {
  UPDATED: 'success',
  BOOKED: 'success',
  POSITION_BOOKED: 'success',
  MANUALLY_BOOKED: 'info',
  MANUALLY_UPDATED: 'info',
  FAILED: 'danger',
  POSITION_FAILED: 'danger',
  SKIPPED: 'warning',
  ABORTED: 'danger',
  MATCHED: 'success',
  INITIALIZED: 'warning',
  PENDING: 'warning',
  CANCELLED: 'danger',
};

export function getBookingStatusTooltipClassName(
  bookingStatus: BookingStepStatus | undefined,
): string {
  if (!bookingStatus) {
    return 'd-none';
  }
  return `react-bootstrap-${BookingStatusBorderColor[bookingStatus]}-tooltip`;
}

const bookedClasses = 'bg-success text-white text-center fw-bold';
const errorClasses = 'bg-danger text-white fw-bold';
const neutralClasses = 'text-dark text-center fw-bold';

export const bookingStepStatusClassName: Record<BookingStepStatus, string> = {
  BOOKED: bookedClasses,
  POSITION_BOOKED: bookedClasses,
  PENDING: neutralClasses,
  SKIPPED: errorClasses,
  FAILED: errorClasses,
  POSITION_FAILED: errorClasses,
  INITIALIZED: neutralClasses,
  UPDATED: bookedClasses,
  MATCHED: 'border border-success border-md text-center fw-bold',
  ABORTED: errorClasses,
  CANCELLED: errorClasses,
  MANUALLY_BOOKED: 'text-dark text-center bg-info text-white fw-bold',
  MANUALLY_UPDATED: 'text-dark text-center bg-info text-white fw-bold',
};

export const availableBookingApplications = [
  'CASCADE',
  'NEC',
  'ONYX',
  'SPARK',
  'XONE',
  'SKIPPER',
  'METEOR',
] as const;
export type BookingStepApplication = (typeof availableBookingApplications)[number];

export type BookingStepReferenceType =
  | 'RFQ'
  | 'STRATEGY'
  | 'LEG'
  | 'DELTA'
  | 'SALES_CREDIT'
  | 'ALLOCATION'
  | 'HEDGE'
  | 'EXECUTION';
export type BookingStepStepType =
  | 'CASCADE_DELTA_SYNTHETIC'
  | 'CASCADE_DELTA_SYNTHETIC_LINK'
  | 'CASCADE_REQUEST'
  | 'CASCADE_STRATEGY'
  | 'ELIOT_ALLOCATION'
  | 'ELIOT_STRATEGY'
  | 'MANUAL_BOOKING_STEP'
  | 'MANUAL_STRATEGY'
  | 'NEC_STRATEGY'
  | 'XONE_CANCEL'
  | 'XONE_EXECUTION'
  | 'XONE_INCREASE'
  | 'XONE_LEG'
  | 'XONE_NOVATION'
  | 'XONE_SPLIT'
  | 'XONE_STEPIN'
  | 'XONE_STRATEGY'
  | 'XONE_UNWIND'
  | 'XONE_UPDATE'
  | 'XONE_ALLOCATION'
  | 'MARKITWIRE'
  | 'ELIOT_CASH_FLOW'
  | 'ELIOT_STOCK_SPLIT'
  | 'XONE_HEDGE'
  | 'SKIPPER';

interface SecondaryAllocationIdType extends OnyxBookingReference {
  type: 'ALLOCATION';
  uuid: string;
  bookingId: {
    application: 'XONE';
    id: string;
  };
}

type SecondaryReferenceAllocationId = { reference: SecondaryAllocationIdType; uuid: string };
export interface OnyxBookingStep {
  delta?: OnyxBookingReference;
  owner?: OnyxBookingReference;
  legs: OnyxBookingReference[] | undefined;
  message: string | undefined;
  nettingBookingId?: SecondaryReferenceAllocationId;
  novationBookingId?: SecondaryReferenceAllocationId;
  novationFeeId?: SecondaryReferenceAllocationId;
  salesCreditBooked?: boolean;
  brokenLink?: boolean;
  uuid: string;
  mode: string;
  status: BookingStepStatus;
  stepType: BookingStepStepType;
  reference: OnyxBookingReference;
  meteorTradeId?: BookingId;
  meteorExGenId?: BookingId;
}
export interface SkipperOnyxBookingStep extends OnyxBookingStep {
  stepType: ExtractStrict<BookingStepStepType, 'SKIPPER'>;
  executionMode: string;
  skipperProductId?: { application: BookingStepApplication; id: string };
  skipperTradeID?: { application: BookingStepApplication; id: string };
  xoneBookingId?: { uuid: string; reference: OnyxBookingReference };
}
//
export interface SplitOnyxBookingStep extends OnyxBookingStep {
  stepType: ExtractStrict<BookingStepStepType, 'ELIOT_STOCK_SPLIT' | 'XONE_SPLIT'>;
  legs: OnyxBookingReference[];
}

export interface OnyxBookingReference {
  lastUpdateTime: string | undefined;
  bookingId: BookingId | undefined;
  type: BookingStepReferenceType;
  uuid: string;
}

export interface BookingId {
  id?: string;
  application?: BookingStepApplication;
}
