import type { ExternalMailOptions } from './externalOnyxPreconfirmationModel';
import type {
  ExternalPreconfirmationPreviewAction,
  ExternalPreconfirmationSendAction,
} from './externalPreconfirmationAction';
import { externalPreconfirmationDomain } from './externalPreconfirmationDomain';

const externalPreconfirmationCrudActions = externalPreconfirmationDomain.actions;

export const externalPreconfirmationActionCreators = {
  externalPreconfirmationCrudActions,
  externalPreconfirmationEmailSendAction,
  externalPreconfirmationPreviewAction,
};

function externalPreconfirmationEmailSendAction(
  rfqId: string,
  externalMailOptions: ExternalMailOptions,
  ignoreFieldChanges = false,
): ExternalPreconfirmationSendAction {
  return {
    type: 'EXTERNAL_PRECONFIRMATION_EMAIL_SEND_REQUESTED',
    rfqId,
    externalMailOptions,
    ignoreFieldChanges,
  };
}

function externalPreconfirmationPreviewAction(rfqId: string): ExternalPreconfirmationPreviewAction {
  return {
    type: 'EXTERNAL_PRECONFIRMATION_EMAIL_PREVIEW_REQUESTED',
    rfqId,
  };
}
