import { NeosBlurTextarea } from '@/neos/components/share/blurComponent/NeosBlurTextarea';

import styles from '../Comment.module.scss';

export interface ClientCommentOwnPropsProps {
  rfqId: string;
}

export interface ClientCommentStateProps {
  clientComment: string | undefined;
}

export interface ClientCommentDispatchProps {
  onCommentChanged(comment: string): void;
}

export type ClientCommentProps = ClientCommentOwnPropsProps &
  ClientCommentStateProps &
  ClientCommentDispatchProps;

export const ClientCommentComponent = ({ clientComment, onCommentChanged }: ClientCommentProps) => (
  <div className={`${styles['comment']}`}>
    <label className="fw-bold m-1">Client Comment</label>
    <NeosBlurTextarea
      className={`${styles['rfq-comment-textarea']} form-control`}
      data-e2e="neos-rfq-comment"
      value={clientComment || ''}
      rows={4}
      onChange={event => onCommentChanged(event.target.value)}
      disabled={true}
    />
  </div>
);
