import type { Selectors } from '@/bootstrap/selectors';
import { services } from '@/bootstrap/services';
import type { AppState } from '@/bootstrap/state';
import type { Status } from '../../../../../../neos/business/neosModel';
import { AreValidAllocExecSizes } from '../../nextUserActions/AreValidExecutionsSizesSelectors';

interface HiddenSaveButtonModel {
  canSaveStatus: false;
}
interface NonConfirmableSaveButtonModel {
  canSaveStatus: true;
  isConfirmable: false;
  isDisabled: boolean;
  shouldCopyQuoteRecap: boolean;
  quoteRecap: string | undefined;
}

interface ConfirmableSaveButtonModel {
  canSaveStatus: true;
  isConfirmable: true;
  isDisabled: boolean;
  modalTitle: string;
  modalBody: string[];
  shouldCopyQuoteRecap: false;
}

export type SaveButtonModel =
  | HiddenSaveButtonModel
  | NonConfirmableSaveButtonModel
  | ConfirmableSaveButtonModel;

export function getSaveButtonModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): SaveButtonModel {
  const { status, quoteRecap } = selectors.getRfqData(state, rfqId);
  const isDealInAmendMode = ['AMEND_REQUESTED', 'DEAL_AMENDED', 'AMEND_COMPLETED'].includes(status);
  const canSaveStatus = services.orderOf(status).isBeforeOrEqual('BOOKED') || isDealInAmendMode;
  const isSavePossible = selectors.isSavePossible(state, rfqId);

  const { strategyIds } = selectors.getRfqData(state, rfqId);

  const areValidStrategySizes = strategyIds
    .map(stratId => selectors.isValidStrategySize(state, stratId, selectors))
    .every(isValidStrategySize => isValidStrategySize);

  const areValidExecStrategySizes = strategyIds
    .filter(stratId => selectors.isListedStrategy(state, stratId, selectors))
    .map(stratId => selectors.isValidListedExecStrategySize(state, rfqId, stratId, selectors))
    .every(isValidExecStrategySize => isValidExecStrategySize);

  const areValidAllocExecSizes = AreValidAllocExecSizes(strategyIds, rfqId, state, selectors);
  const isRfqContainingInvalidListedStrategySize =
    !areValidStrategySizes || !(areValidAllocExecSizes && areValidExecStrategySizes);

  const isDisabled =
    !isSavePossible ||
    isRfqContainingInvalidListedStrategySize ||
    selectors.isUserForbiddenToSaveRfq(state);

  const isRefSpotChangedByTrader = selectors.isRefSpotChangedByTrader(state.ui, rfqId);

  if (isRefSpotChangedByTrader && canSaveStatus) {
    return {
      canSaveStatus,
      isConfirmable: true,
      shouldCopyQuoteRecap: false,
      isDisabled,
      modalTitle: 'Ref Spot has been changed',
      modalBody: [
        'You are about to change the ref spot.',
        'Please make sure to inform the sales.',
        '',
        'Do you confirm?',
      ],
    };
  }

  return canSaveStatus
    ? getIsConfirmable(status)
      ? {
          canSaveStatus,
          isConfirmable: true,
          isDisabled,
          ...getModalInfo(status),
          shouldCopyQuoteRecap: false,
        }
      : {
          canSaveStatus,
          isConfirmable: false,
          isDisabled,
          quoteRecap,
          shouldCopyQuoteRecap: status === 'PRICE_PROPOSED' && !!quoteRecap,
        }
    : {
        canSaveStatus,
      };
}

function getIsConfirmable(status: Status): status is 'BOOKED' | 'DELTA_BEING_EXECUTED' {
  return status === 'BOOKED' || status === 'DELTA_BEING_EXECUTED';
}

function getModalInfo(status: Extract<Status, 'BOOKED' | 'DELTA_BEING_EXECUTED'>) {
  switch (status) {
    case 'BOOKED':
      return {
        modalTitle: 'Save at Booked',
        modalBody: ['You are about to save modifications on a Booked RFQ.', 'Do you confirm?'],
      };
    case 'DELTA_BEING_EXECUTED':
      return {
        modalTitle: 'Amend order',
        modalBody: [
          'You are about to amend orders, change(s) on rfq will also be saved.',
          'Do you confirm?',
        ],
      };
  }
}
