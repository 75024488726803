import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';
import { type ExtendedDispatch, thunks } from '@/bootstrap/thunks';
import { FlowDatePicker } from '@/neos/components/share/datePicker';
import { ErrorHighlight } from '@/neos/components/share/errorHighlight';
import { useDispatch } from 'react-redux';
import { getStrikeDateModel } from './getStrikeDateModel';

import { useAppSelector } from '@/bootstrap/hooks';
import styles from './StrikeDateCells.module.scss';

type StrikeDateCellsProps = {
  rfqId: string;
  strategyId: string;
};

export const StrikeDateCells = ({ rfqId, strategyId }: StrikeDateCellsProps) => {
  const dispatch = useDispatch<ExtendedDispatch>();
  const { availableMaturities, isProductWithStrikeDate, strikeDates, tenors } = useAppSelector(
    state => getStrikeDateModel(state, rfqId, strategyId, selectors),
  );

  function onStrikeDateChanged(productId: string, strikeDate: string | undefined): void {
    dispatch(thunks.neos.createChangeStrikeDateThunk(rfqId, strategyId, productId, strikeDate));
  }

  function onTenorChanged(legId: string, tenor: string | undefined) {
    dispatch(
      thunks.neos.createRfqDefaultTenorMaturityThunk(
        rfqId,
        strategyId,
        legId,
        tenor,
        'STRIKE_DATE',
      ),
      createLogAnalyticsAction('NEOS RFQ', 'Select Tenor', tenor),
    );
  }

  return (
    <section>
      {strikeDates.map(({ id, value, isEditable }, index) => (
        <ErrorHighlight
          key={id}
          errorField={'strikeDate'}
          related={{ rfqId, legId: id, strategyId }}
        >
          <FlowDatePicker
            className={`${styles['strike-date']} errorable-bloc`}
            readOnly={!isEditable}
            onChange={val => onStrikeDateChanged(id, val)}
            maturities={availableMaturities[index]}
            date={value || ''}
            tenors={tenors}
            maturitiesCategory={
              isProductWithStrikeDate || availableMaturities[index].length === 0
                ? 'TENORS'
                : 'LISTED'
            }
            onChangeTenor={val => onTenorChanged(id, val)}
            data-e2e="neos-strategy-definition-strikeDate"
            hideCalendarIcon
          />
        </ErrorHighlight>
      ))}
    </section>
  );
};
