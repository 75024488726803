import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { getSizeUnit } from '@/neos/components/rfq/strategies/strategy/strategyDefinition/sizeCells/getSizeUnit';
import type { Field } from '@/neos/components/rfq/strategies/strategy/strategyDefinition/utils/Field';
import { getIsStrategyDefinitionFieldEditable } from '@/neos/components/rfq/strategies/strategy/strategyDefinition/utils/fieldsEnablingConditions';
import type { RfqStrategyData } from '../strategyModel';
import type { LegData } from './legModel';

export interface SizeInfo extends Field<number | undefined> {
  isStrategySize: boolean;
  isValidStrategySize: boolean;
  isListedStrategy: boolean;
  unit: string;
}

interface LegParameter {
  data: LegData;
  isStrategySize: false;
}

interface StrategyParameter {
  data: RfqStrategyData;
  isStrategySize: true;
}

type Parameters = LegParameter | StrategyParameter;

export function getLegSizes(
  state: AppState,
  {
    rfqId,
    strategyId,
  }: {
    rfqId: string;
    strategyId: string;
  },
  selectors: Selectors,
): SizeInfo[] {
  const { strategyType: selectedStrategyType } = selectors.getStrategyData(state, strategyId);
  const { sameNegotiatedSize, ratio } = selectors.getStrategyDefinition(
    state.referenceData,
    selectedStrategyType,
  );
  const hasACompositionLeg = selectors.hasACompositionLeg(state, strategyId, selectors);
  const sizeField = selectors.getLegSizeField(state, strategyId, selectors);
  const uiSizeType = selectors.getDisplayNegotiatedSize(state.ui, strategyId);

  return getSizesFieldsArray();

  function getSizesFieldsArray(): SizeInfo[] {
    if (sameNegotiatedSize) {
      const masterLegData = selectors.getStrategyMasterLeg(state, strategyId, selectors);
      return [
        getSizeField({
          data: masterLegData,
          isStrategySize: false,
        }),
      ];
    }

    const compositionSize = getCompositionSize();

    const legsData = selectors.getStrategyLegsData(state, strategyId, selectors);
    const consistentLegsData = hasACompositionLeg ? [legsData[legsData.length - 1]] : legsData;

    const consistentLegsDataSizesField = consistentLegsData.map(legData =>
      getSizeField({ data: legData, isStrategySize: false }),
    );
    return [...compositionSize, ...consistentLegsDataSizesField];
  }

  function getCompositionSize(): SizeInfo[] {
    const strategyData = selectors.getStrategyData(state, strategyId);
    if (!hasACompositionLeg || strategyData.scope !== 'RFQ') {
      return [];
    }
    return [
      getSizeField({
        data: strategyData,
        isStrategySize: true,
      }),
    ];
  }

  function getSizeField(parameter: Parameters): SizeInfo {
    const { data, isStrategySize } = parameter;
    const { uuid, notionalUnit = '', localNotionalUnit = '' } = data;

    const isEditableOnSecondary = selectors.isSecondaryOrMixedRfq(state, rfqId);
    const sizesFieldAreEnabled = getIsStrategyDefinitionFieldEditable(
      state,
      rfqId,
      strategyId,
      selectors,
      ['RFQ_EDITABLE', 'STRATEGY_EDITABLE', 'UNDERLYING_SET'],
    );
    const isElsBasket = selectors.isStrategyElsBasket(state, rfqId, selectors);

    const isCompatibleStrategy = ratio || (!parameter.isStrategySize && parameter.data.isMaster);
    const isEditable =
      !isElsBasket && (isEditableOnSecondary || (sizesFieldAreEnabled && isCompatibleStrategy));
    const isListedStrategy = selectors.isListedStrategy(state, strategyId, selectors);
    const isValidStrategySize = isEditable
      ? selectors.isValidStrategySize(state, strategyId, selectors)
      : true;
    return {
      id: uuid,
      value: data[sizeField],
      isEditable,
      isStrategySize,
      unit: getSizeUnit(uiSizeType, { notionalUnit, localNotionalUnit }),
      isListedStrategy,
      isValidStrategySize,
    };
  }
}
