import { type StatusLabel, allStatusLabels } from './statusModel';
import { type SubStatusLabel, allSubStatusLabel } from './subStatus/subStatusModel';

export const cancelledWithoutReasonLabel = 'Cancelled Without Reason' as const;

export type StatusSubStatusLabel =
  | StatusLabel
  | SubStatusLabel
  | typeof cancelledWithoutReasonLabel;

export const allStatusSubStatusLabels: StatusSubStatusLabel[] = [
  ...allStatusLabels,
  ...allSubStatusLabel,
  cancelledWithoutReasonLabel,
];
