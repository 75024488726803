import type { RelatedExchange } from '@/neos/business/referenceData/relatedExchange/relatedExchangeModel';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { type Observable, of } from 'rxjs';
import type {
  Counterpart,
  Currency,
  PredealChecksDerogation,
  SalesGroup,
  StrategyConfiguration,
  Tenor,
  TraderGroup,
  User,
} from '../neosModel';
import type { OnyxStrategyDefinition } from '../neosOnyxModel';
import type { OnyxMarket, OnyxMarketEliots } from './markets/marketsModel';
import type { ReferenceDataApi } from './referenceDataEpics';
import type { SalesLocations } from './salesLocation/salesLocationsModel';

export const makeReferenceDataApi = (http: SgmeHttp): ReferenceDataApi => ({
  getSalesValos(): Observable<User[]> {
    return http.get<User[]>({
      url: 'api/users?category=SALES',
    });
  },
  getTraderGroups(): Observable<TraderGroup[]> {
    return http.get<TraderGroup[]>({
      url: 'api/groups/trader',
    });
  },
  getTraders(): Observable<User[]> {
    return http.get<User[]>({
      url: 'api/users?category=TRADER',
    });
  },
  getStrategiesDefinitions(): Observable<OnyxStrategyDefinition[]> {
    return http.get<OnyxStrategyDefinition[]>({
      url: 'api/rfq/strategyDescription/',
    });
  },
  getStrategyConfiguration(): Observable<StrategyConfiguration> {
    return of(sgmeConfiguration.strategyConfiguration);
  },
  getSalesGroups(): Observable<SalesGroup[]> {
    return http.get<SalesGroup[]>({
      url: 'api/groups/sales',
    });
  },
  getPredealChecksDerogations(): Observable<PredealChecksDerogation> {
    return http.get<PredealChecksDerogation>({
      url: 'api/pdc/derogations',
    });
  },
  getCurrencies(): Observable<Currency[]> {
    return http.get<Currency[]>({
      url: 'api/referential/currencies',
    });
  },
  getSalesLocations(): Observable<SalesLocations[]> {
    return http.get<SalesLocations[]>({
      url: 'api/referential/cities',
    });
  },
  getTenors(): Observable<Tenor[]> {
    return http.get<Tenor[]>({
      url: 'api/referential/tenors',
    });
  },
  getMarkets(): Observable<OnyxMarket[]> {
    return http.get<OnyxMarket[]>({
      url: 'api/referential/markets',
    });
  },
  getMarketsEliot(): Observable<OnyxMarketEliots> {
    return http.get<OnyxMarketEliots>({
      url: 'api/referential/markets/eliot',
    });
  },
  getCounterparts(sesameId: string): Observable<Counterpart[]> {
    return http.get<Counterpart[]>({
      url: `api/referential/counterparts?username=${sesameId}&showfunds=true`,
    });
  },
  getRelatedExchangeFields(): Observable<RelatedExchange[]> {
    return http.get<RelatedExchange[]>({
      url: 'api/referential/exchanges',
    });
  },
});
