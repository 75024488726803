import { If } from '@/neos/components/share/if/if';
import type { FC } from 'react';
import type { FeatureKey } from '../../../../../../../neos/business/neosModel';

export type OwnProps = FeatureKey & {
  classes?: string;
};

export interface StateProps {
  isClosable: boolean;
}

export interface DispatchProps {
  onClick(): void;
}

type Props = OwnProps & StateProps & DispatchProps;

export const CloseButton: FC<Props> = ({ isClosable, onClick, type, classes }: Props) => (
  <button
    className={`btn btn-icon btn-sm btn-flat-primary ${classes}`}
    data-e2e={`neos-extra-features-close-button-${type.toLowerCase()}`}
  >
    <If condition={isClosable}>
      <i className="icon" onClick={onClick}>
        clear
      </i>
    </If>
  </button>
);
