import type { ColumnState } from '@ag-grid-community/core';
import { keyBy } from 'lodash';
import type { GridApiExtended } from './gridApiExtended';
import type { GridState } from './gridStateApiManager';

export class GridStateSanitizer {
  public sanitize = (apiEx: GridApiExtended, savedState: GridState): GridState => {
    const savedColumnState = savedState.columnState;

    const newColState = savedColumnState
      ? this.sanitizeColumnState(apiEx, savedColumnState)
      : savedState.columnState;

    return {
      columnState: newColState,
    };
  };

  private sanitizeColumnState(
    { getColumnState }: GridApiExtended,
    savedColumnState: ColumnState[],
  ): ColumnState[] {
    const noColumnId = 'NO_COL_ID';
    const newColState: ColumnState[] = [];
    const currColumnState = getColumnState();

    const savedColStateTransformed = savedColumnState.map(col => ({
      ...col,
      colId: col.colId ?? noColumnId,
    }));

    const currColumnStateByColId = keyBy(currColumnState, c => c.colId ?? noColumnId);
    const savedColumnStateByColId = keyBy(savedColStateTransformed, c => c.colId);
    //in both
    for (let i = 0; i < savedColumnState.length; i++) {
      const savedCol = savedColStateTransformed[i];
      const currCol = currColumnStateByColId[savedCol.colId];
      if (currCol) {
        currCol.hide = savedCol.hide;
        currCol.pinned = savedCol.pinned;
        currCol.width = savedCol.width;
        currCol.rowGroupIndex = savedCol.rowGroupIndex;
        currCol.sort = savedCol.sort;
        currCol.sortIndex = savedCol.sortIndex;
        newColState.push(currCol);
      }
    }

    //in current but not in saved
    for (let i = 0; i < currColumnState.length; i++) {
      const currCol = currColumnState[i];
      if (!savedColumnStateByColId[currCol.colId ?? noColumnId]) {
        newColState.splice(i, 0, currCol);
      }
    }

    return newColState;
  }
}
