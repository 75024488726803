import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';

import { NeosBookingId } from '@/neos/components/share/NeosBookingId/NeosBookingId';
import { useSelector } from 'react-redux';

export function PrimaryBookingId({
  allocationId,
  rfqId,
  legId,
}: {
  allocationId: string;
  rfqId: string;
  legId: string;
}) {
  const currentAllocation = useSelector((state: AppState) =>
    selectors.getOtcAllocationsByAllocId(state, rfqId, legId, allocationId),
  );
  const primaryBookingId = currentAllocation?.secondaryInfo?.primaryBookingId;

  return (
    <NeosBookingId
      onBookingIdChanged={null}
      bookingId={primaryBookingId?.id}
      dataE2e="primary-booking-id"
      isReadOnly
    />
  );
}
