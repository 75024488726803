import type { BasketCompositionDetails } from '@/neos/business/rfq/strategy/leg/product/productModel.ts';

export function getBiggestIndexOfBasketComposition(
  basketComposition: BasketCompositionDetails[],
): number {
  if (basketComposition.length === 0) {
    return 0;
  }

  const sortedBasketCompositionDetails = [...basketComposition].sort((a, b) =>
    a.containerIndex < b.containerIndex ? 1 : -1,
  );
  return sortedBasketCompositionDetails[0].containerIndex;
}
