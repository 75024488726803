import type { Thunk } from '@/bootstrap/thunks';

export function createNeosHideCompositionLegsModalThunk(rfqId: string, strategyId: string): Thunk {
  return function neosHideCompositionLegsModalThunk(
    dispatch,
    _,
    {
      thunks: {
        neos: { createRfqHideCompositionLegsModalThunk },
      },
      actionCreators: {
        neos: { strategyUiCrudActions },
      },
    },
  ) {
    dispatch(
      createRfqHideCompositionLegsModalThunk(rfqId, strategyId),
      strategyUiCrudActions.update(strategyId, {
        displayCompositionLegsModal: false,
      }),
    );
  };
}
