import type { GridOptions } from '@ag-grid-community/core';
import { filterChangedBus } from '../eventBus/filterChanged';
import type { BlotterGridProps } from './BlotterGrid';
import type { GridStateStorageAdapter } from './gridStateStorageAdapter';

export interface ExtraArgumentsBlotterState {
  handleResetFilter: () => void;
}
export const getBlotterStateProps: (
  stateStorageAdapter: GridStateStorageAdapter,
  props: BlotterGridProps,
  params: ExtraArgumentsBlotterState,
) => Partial<GridOptions> = (
  { loadAndApplyGridState, saveGridState },
  {
    requestAnalyticLogging: onAnalyticLogRequested,
    resetBlotterRfqFilteredOutNotificationLastDate,
  },
  { handleResetFilter },
) => ({
  onGridReady: ev => {
    loadAndApplyGridState(ev);
    handleResetFilter();
  },
  onColumnResized: saveGridState,
  onColumnMoved: saveGridState,
  onColumnPinned: saveGridState,
  onColumnVisible: saveGridState,
  onModelUpdated: event => {
    filterChangedBus.emit('blotterFilterChanged', {
      filterModel: event.api.getFilterModel(),
    });
  },
  onFilterChanged: event => {
    saveGridState(event);
    resetBlotterRfqFilteredOutNotificationLastDate();
    filterChangedBus.emit('blotterFilterChanged', {
      filterModel: event.api.getFilterModel(),
    });
  },
  onSortChanged: event => {
    onAnalyticLogRequested('Grid sort requested');
    saveGridState(event);
  },
  onColumnRowGroupChanged: event => {
    onAnalyticLogRequested('Grid group requested');
    saveGridState(event);
  },
  onRowGroupOpened: saveGridState,
  onColumnEverythingChanged: e => e.source === 'contextMenu' && saveGridState(e),
});
