import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { type NeosActionCreators, neosActionCreators } from '@/neos/business/neosActionCreators';
import { useDispatch, useSelector } from 'react-redux';

import { selectors } from '@/bootstrap/selectors';
import {
  InternalPreconfirmationBookingOptionsComponent,
  type InternalPreconfirmationBookingOptionsDispatchProps,
  type InternalPreconfirmationBookingOptionsOwnProps,
  type PreconfirmationBookingOptionsStateProps,
} from './InternalPreconfirmationBookingOptions';
import { getInternalPreconfirmationTabModel } from './getPreconfirmationBookingOptionsModel';

export const InternalPreconfirmationBookingOptions = (
  ownProps: InternalPreconfirmationBookingOptionsOwnProps,
) => {
  const stateProps = useSelector<AppState, PreconfirmationBookingOptionsStateProps>(state => {
    return getInternalPreconfirmationTabModel(state, ownProps.rfqId, selectors);
  });

  const dispatch = useDispatch<ExtendedDispatch>();
  const dispatchProps = getDispatchProps(dispatch, ownProps, neosActionCreators);

  return (
    <InternalPreconfirmationBookingOptionsComponent
      {...ownProps}
      {...stateProps}
      {...dispatchProps}
    />
  );
};

export function getDispatchProps(
  dispatch: ExtendedDispatch,
  { rfqId }: InternalPreconfirmationBookingOptionsOwnProps,
  neosActionCreators: NeosActionCreators,
): InternalPreconfirmationBookingOptionsDispatchProps {
  return {
    onOtherValueChange: (internalOther: string | undefined) => {
      dispatch(
        neosActionCreators.internalPreconfirmationCrudActions.patchOrInsert(rfqId, {
          internalOther,
        }),
      );
    },
    onCommentSelected: (emailComment: string | undefined) => {
      dispatch(
        neosActionCreators.internalPreconfirmationCrudActions.patchOrInsert(rfqId, {
          emailComment,
        }),
      );
    },
  };
}
