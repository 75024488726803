import type { OnyxRfq } from '@/neos/business/rfq/rfqOnyxModel';
import type { BlotterModelGetters } from '.';
import type { ProductSubFamily } from '../../../../../neos/business/neosModel';

const elsProductFamilies: ProductSubFamily[] = ['ELS', 'TRS', 'PRS'];

export function isPtmmm(rfq: OnyxRfq, g: BlotterModelGetters) {
  const masterLeg = g.getMasterLeg(rfq, g);
  const ptmmm = rfq.quote?.preTradeMidMarketMarkPrice?.value;
  const productFamily = masterLeg?.product?.discriminator;

  return ptmmm !== undefined && !!productFamily && elsProductFamilies.includes(productFamily);
}
