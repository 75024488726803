import { type ActionCreators, actionCreators } from '@/bootstrap/actions';
import type { ThunkEpic } from '@/bootstrap/epics';
import { type Thunks, thunks } from '@/bootstrap/thunks';
import type { OnyxError } from '@/neos/business/mappers/error';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import type { PreconfirmationPrefsApi } from '../apiModel';
import { createPreconfirmationPreferencesApi } from '../preconfirmationPreferencesApi';

export function getPreconfirmationPreferenceAddGroupEpic(http: SgmeHttp) {
  const api = createPreconfirmationPreferencesApi(http);
  return createPreconfirmationPreferenceLoadGroupsEpic(api, thunks, actionCreators);
}

export function createPreconfirmationPreferenceLoadGroupsEpic(
  api: PreconfirmationPrefsApi,
  { createErrorToasterThunk }: Thunks,
  {
    neos: { addUserPrefPreconfGroupsActionCreator, removePreconfPrefGroupFromLoadingGroupAction },
  }: ActionCreators,
): ThunkEpic {
  return action$ =>
    action$.pipe(
      ofType('ADD_USER_PREF_GROUP_REQUESTED'),
      mergeMap(({ groupKey }) =>
        api.addUserSavedGroup(groupKey).pipe(
          mergeMap(() =>
            of(
              addUserPrefPreconfGroupsActionCreator(groupKey),
              removePreconfPrefGroupFromLoadingGroupAction(groupKey),
            ),
          ),
          catchError((error: OnyxError) => [
            removePreconfPrefGroupFromLoadingGroupAction(groupKey),
            createErrorToasterThunk(
              {
                message: 'Error adding preconfirmation preference group',
              },
              error,
            ),
          ]),
        ),
      ),
    );
}
