import { selectors } from '@/bootstrap/selectors';
import {
  defaultBasketUnderlying,
  DefaultingScope,
  type Els,
  isElsProduct,
} from '@/neos/business/neosModel';
import { useAppSelector } from '@/bootstrap/hooks';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { useDispatch } from 'react-redux';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { Switch } from '@/neos/components/share/switch/Switch';
import { useFeatureToggle } from '@/neos/components/share/featureToggle/useFeatureToggle';
import { DefaultingOptions } from '@/neos/business/rfq/rfqActions';

export function BasketToggleCell({ rfqId, strategyId }: { rfqId: string; strategyId: string }) {
  const dispatch = useDispatch<ExtendedDispatch>();

  const product = useAppSelector(state =>
    selectors.getStrategyMasterProduct(state, strategyId, selectors),
  );
  const isElsBasketEnabled = useFeatureToggle('neos.els.basket.enabled');

  const isDisplayed = isElsProduct(product) && isElsBasketEnabled;
  if (!isDisplayed) {
    return null;
  }

  const onBasketToggle = () => {
    if (product.underlyingKind === 'BASKET') {
      const { basketUnderlying: _, ...restProductProp } = product;

      const newProduct: Els = {
        ...restProductProp,
        underlyingKind: 'SINGLE',
        underlyingId: undefined,
      };

      dispatch(neosActionCreators.productCrudActions.upsert(product.uuid, newProduct));
    } else {
      dispatch(
        neosActionCreators.productCrudActions.update(product.uuid, {
          underlyingKind: 'BASKET',
          basketUnderlying: defaultBasketUnderlying,
        }),
        neosActionCreators.createDefaultRfqRequestedAction(
          rfqId,
          new DefaultingOptions({
            overrideScopes: [DefaultingScope.UNDERLYING],
          }),
        ),
      );
    }
  };

  return (
    <Switch
      checked={product.underlyingKind === 'BASKET'}
      onChange={onBasketToggle}
      e2eId="els-basket-toggle"
    />
  );
}
