import type { Dispatchable, Thunk } from '@/bootstrap/thunks';
import { isDefined } from '@/util/undefinedAndNull/isDefined';
import type { OnyxTransaction } from '../neosOnyxModel';

export function createLoadTransactionsThunk(
  transactions: (OnyxTransaction | undefined)[],
  shouldDisplayTab: boolean = true,
): Thunk {
  return function loadTransactionsThunk(
    dispatch,
    getState,
    {
      thunks: { neos, createErrorToasterThunk },
      selectors: { isFeatureToggleEnabled },
      actionCreators: {
        neos: {
          createPredealChecksReceivedAction,
          onyxBookingStepsReceived,
          createOrdersReceived,
          createAllocationInstructionsReceivedAction,
          createPreConfirmationStepsReceivedAction,
          allocationInstructionCrudActions,
        },
      },
      fromMappers,
    },
  ) {
    for (const transaction of transactions) {
      if (!transaction) {
        dispatch(
          createErrorToasterThunk(
            {
              message: 'Error loading RFQ!',
            },
            undefined,
            false,
          ),
        );
        continue;
      }

      const isOtcPreConfEnabled = isFeatureToggleEnabled(getState(), 'neos.otc.preconf.enabled');
      const { rfq, preConfirmationMail } = transaction;
      const rfqId = rfq.uuid;
      const dispatchables: Dispatchable[] = [
        neos.createLoadTransactionFirstStepThunk(transaction, shouldDisplayTab),
        neos.createDeleteTransactionThunk(rfqId),
        createPredealChecksReceivedAction(transaction.pdcs),
        onyxBookingStepsReceived({ rfqId, bookingSteps: transaction.bookingSteps || [] }),
        createOrdersReceived(rfqId, transaction.orders || [], fromMappers),
        allocationInstructionCrudActions.deleteByPartialKey({ rfqId }),
        createAllocationInstructionsReceivedAction(
          transaction.allocationInstructions ?? [],
          fromMappers,
        ),
      ];
      if (isOtcPreConfEnabled) {
        dispatchables.push(
          createPreConfirmationStepsReceivedAction(rfqId, transaction.preConfirmationSteps || []),
        );
      }

      dispatch(dispatchables);

      if (transaction.workflow) {
        dispatch(neos.createIntegrateNextUserActions(rfqId, transaction.workflow));
      }

      dispatch(
        neos.createLoadRfqThunk(rfq),
        neos.createFinalizeRfqSavingDoneThunk(rfq),
        neos.createSetNegoOrPostNegoScreenThunk(rfq.uuid),
      );

      if (preConfirmationMail && preConfirmationMail.external) {
        dispatch(
          neos.createIntegrateExternalPreconfirmationThunk(rfqId, preConfirmationMail.external),
        );
      }
      if (preConfirmationMail && preConfirmationMail.internal) {
        dispatch(
          neos.createIntegrateInternalPreconfirmationThunk(rfqId, preConfirmationMail.internal),
        );
      }
    }

    const rfqIds = transactions.filter(isDefined).map(({ rfq: { uuid } }) => uuid);
    if (rfqIds.length > 0) {
      dispatch(neos.createFinalizeTransactionsLoadThunk(rfqIds, shouldDisplayTab));
    }
  };
}
