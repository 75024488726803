import type { Thunk } from '@/bootstrap/thunks';
import { DefaultingScope } from '../../models';
import { DefaultingOptions } from '../../rfqActions';

export function createChangeStrikeDateThunk(
  rfqId: string,
  strategyId: string,
  productId: string,
  strikeDate: string | undefined,
): Thunk {
  return function changeStrikeDateThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { createDefaultRfqRequestedAction },
      },
      thunks: {
        neos: {
          createUpdateProductAndResetDependenciesThunk,
          changeElsIsScheduleObsoleteThunk,
          changeClsIsScheduleObsoleteThunk,
        },
      },
    },
  ) {
    const state = getState();
    const isStrategyEls = selectors.isElsStrategy(state, strategyId, selectors);
    const isStrategyCls = selectors.isClsStrategy(state, strategyId, selectors);
    const isStrikeDateFeatureEnabled = selectors.isFeatureToggleEnabled(
      state,
      'neos.els.strike.date',
    );
    const isElsStrategyAndStrikeDateFeatureEnabled = isStrategyEls && isStrikeDateFeatureEnabled;

    dispatch(
      createUpdateProductAndResetDependenciesThunk(
        rfqId,
        strategyId,
        productId,
        'strikeDate',
        strikeDate,
      ),
    );
    dispatch(changeElsIsScheduleObsoleteThunk(rfqId, productId, true));
    dispatch(changeClsIsScheduleObsoleteThunk(rfqId, productId, true));

    if (isStrategyCls) {
      const defaultingOptions = new DefaultingOptions({
        overrideScopes: [
          DefaultingScope.DETERMINATION_METHOD,
          DefaultingScope.END_OF_OBSERVATION,
          DefaultingScope.EXPECTED_N,
          DefaultingScope.MATURITY_DATE,
          DefaultingScope.TENOR_DATE,
          DefaultingScope.UNDERLYING,
          DefaultingScope.FIXED_DAY_TYPE,
        ],
        enrichScopes: [DefaultingScope.SALES_DIFFUSION, DefaultingScope.TRADE_DATE],
      });
      dispatch(createDefaultRfqRequestedAction(rfqId, defaultingOptions));
    }

    if (isElsStrategyAndStrikeDateFeatureEnabled) {
      const overrideScopes = [
        DefaultingScope.FIXED_DAY_TYPE,
        DefaultingScope.EFFECTIVE_DATE,
        DefaultingScope.MATURITY_DATE,
        DefaultingScope.BREAK_FEE,
      ];

      const defaultingOptions = new DefaultingOptions({
        overrideScopes,
        isSettlementOtcOverride: true,
      });
      dispatch(createDefaultRfqRequestedAction(rfqId, defaultingOptions));
    }
  };
}
