import { ajax } from 'rxjs/ajax';
import { getAuthHeader } from '../api/connect/connect';
import type { SgmeHttp } from '../http/sgmeHttpBase';
import { createSgmeHttpWithoutLogger } from '../http/sgmeHttpWithoutLogger';
import { commonTimeout } from '../timeout';
import { AsyncLogger, type AsyncLoggerConfig } from './asyncLogger';

const loggerConfig: AsyncLoggerConfig = {
  logUrl: 'api/log',
  pushTimeout: commonTimeout,
  maxLogEntrySize: 10_000,
  bufferTimeSpan: 5_000,
  maxLogBufferSize: 10,
  maxRetryCount: 100,
  retryDelay: 20_000,
  logDelay: 2_000,
};

function createLogger(_sgmeHttp: SgmeHttp, _loggerConfig: AsyncLoggerConfig): AsyncLogger {
  return new AsyncLogger(_sgmeHttp, _loggerConfig);
}

const sgmeHttp = createSgmeHttpWithoutLogger(getAuthHeader, ajax);
export const logger = createLogger(sgmeHttp, loggerConfig);
