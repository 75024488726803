import { useAppSelector } from '@/bootstrap/hooks';
import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import { getPreconfGroupsModel } from '@/neos/components/userPreferences/preconfirmationsGroups/getPreconfirmationModel';
import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Switch } from '../../share/switch/Switch';

export function PreconfirmationGroups() {
  const { preconfGroups } = useAppSelector(state => getPreconfGroupsModel(state, selectors));

  const dispatch = useDispatch<ExtendedDispatch>();
  const onPreconfGroupChanged = (groupKey: string, enabled: boolean) => {
    dispatch(
      createLogAnalyticsAction('NEOS USER PREFERENCES', 'Change preconfirmation group'),
      neosThunks.createPreconfirmationPreferencesGroupUpdatedThunk(groupKey, enabled),
    );
  };

  return (
    <>
      <div className="mb-2">Select the groups on which you want to receive preconf emails</div>
      {preconfGroups.map(({ groupKey, enabled, isLoading }) => (
        <Fragment key={groupKey}>
          {isLoading ? (
            <div className="spinner" />
          ) : (
            <Switch
              checked={enabled}
              onChange={() => onPreconfGroupChanged(groupKey, !enabled)}
              labelName={groupKey}
              switchClassName="ms-2"
            />
          )}
        </Fragment>
      ))}
    </>
  );
}
