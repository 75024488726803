import type { Action } from '@/bootstrap/actions';
import type { Thunk } from '@/bootstrap/thunks';
import type { DividendComponentFeatureKey } from '../../featureModel';

export function createRemoveAllDividendSchedulePeriodThunk(
  featureKey: DividendComponentFeatureKey,
): Thunk {
  return function removeAllDividendSchedulePeriodThunk(
    dispatch,
    _,
    {
      actionCreators: {
        neos: { featureCrudActions },
      },
    },
  ) {
    const action: Action = featureCrudActions.update(featureKey, {
      dividendSchedulePeriods: [],
    });
    dispatch(action);
  };
}
