import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { OnyxRfq } from '@/neos/business/rfq/rfqOnyxModel';
import { tryGetUserFullName } from '../../../share/userUtils/userUtils';

export function getSalesValoName(
  state: AppState,
  { salesDiffusion }: OnyxRfq,
  selectors: Selectors,
) {
  const salesValoId = salesDiffusion?.salesValo?.id;
  return getSalesName(state, salesValoId, selectors);
}

export function getSalesInitName(
  state: AppState,
  { salesDiffusion }: OnyxRfq,
  selectors: Selectors,
) {
  const salesInitId = salesDiffusion?.salesInit?.id;
  return getSalesName(state, salesInitId, selectors);
}

function getSalesName(state: AppState, salesId: string | undefined, selectors: Selectors) {
  const salesValos = selectors.getSalesValos(state.referenceData);
  return tryGetUserFullName(salesValos, salesId, '');
}
