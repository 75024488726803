import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import type { NegotiationMode, StrategyType } from '../../../../neos/business/neosModel';
import styles from './Shortcuts.module.scss';
import { DeltaTypeShortcuts } from './deltaType/DeltaType';
import { NegotiationModeShortcuts } from './negotiationMode/NegotiationMode.container';
import { CustomShortcutsTable } from './shared/CustomShortcutsTable';
import type { Shortcut } from './shared/ShortcutButton';

export interface ShortcutsOwnProps {
  rfqId: string;
}

export interface ShortcutsStateProps {
  strategyTypeShortcuts: Shortcut<StrategyType>[];
  underlyingShortcuts: Shortcut<string>[];
}

export interface ShortcutsDispatchProps {
  onNegotiationModeChange(negotiationMode: NegotiationMode, forceApplyToAll: boolean): void;

  onStrategySelected(strategyType: StrategyType, forceApplyToAll: boolean): void;

  onDeltaTypeSelected(deltaTypeId: string, forceApplyToAll: boolean): void;

  onUnderlyingSelected(underlyingId: string, forceApplyToAll: boolean): void;
}

type Props = ShortcutsOwnProps & ShortcutsStateProps & ShortcutsDispatchProps;

export const ShortcutsComponent = ({
  strategyTypeShortcuts,
  underlyingShortcuts,
  onUnderlyingSelected,
  onStrategySelected,
  onDeltaTypeSelected,
  onNegotiationModeChange,
  rfqId,
}: Props) => (
  <section className={`${styles['shortcuts-bloc']} `}>
    <div className={`${styles['shortcuts']} `}>
      <CustomShortcutsTable<string>
        shortcuts={underlyingShortcuts}
        onShortcutSelected={(val: string) => onUnderlyingSelected(val, false)}
        onShortcutLongPress={(val: string) => onUnderlyingSelected(val, true)}
      />
      <CustomShortcutsTable<StrategyType>
        shortcuts={strategyTypeShortcuts}
        data-e2e="strategyType-shortcuts"
        onShortcutSelected={(val: StrategyType) => onStrategySelected(val, false)}
        onShortcutLongPress={(val: StrategyType) => onStrategySelected(val, true)}
      />

      <DeltaTypeShortcuts
        onShortcutSelected={val => onDeltaTypeSelected(val, false)}
        onShortcutLongPress={val => onDeltaTypeSelected(val, true)}
      />
      <NegotiationModeShortcuts
        rfqId={rfqId}
        onShortcutSelected={(val: NegotiationMode) => onNegotiationModeChange(val, false)}
        onShortcutLongPress={(val: NegotiationMode) => onNegotiationModeChange(val, true)}
      />
    </div>
    <div className={`${styles['parameters']} `}>
      <SimpleNeosTooltip
        placement="right"
        id="apply-to-all-info"
        message={'Long click to apply to all'}
      >
        <i className="icon">info</i>
      </SimpleNeosTooltip>
    </div>
  </section>
);
