import type { Thunk } from '@/bootstrap/thunks';
import type { NotificationSubscriptionRequest } from '../neosModel';

export function createRequestResubscribeThunk(): Thunk {
  return function requestResubscribeThunk(
    dispatch,
    getState,
    {
      actionCreators: {
        neos: { createRequestSubscriptionAction },
      },
      selectors,
    },
  ) {
    const state = getState();

    const openedRfqIds = selectors.getOpenedRfqIds(state, selectors);

    const dataGroupNames = selectors
      .getCurrentUser(state)
      .diffusionGroups.map(diffusionGroup => diffusionGroup.toString());

    const filteredDataGroupNames = selectors
      .getFilteredNotificationGroupsByPreferences(state, selectors)
      .map(group => group.toString());

    const resubscribeRequests: NotificationSubscriptionRequest[] = [
      { notificationTopic: 'BLOTTER', dataGroupNames },
      { notificationTopic: 'TRANSACTION', dataGroupNames: openedRfqIds },
      { notificationTopic: 'TRANSACTION_GROUP', dataGroupNames: filteredDataGroupNames },
    ];

    const isPreconfBlotterEnabled = selectors.isFeatureToggleEnabled(
      state,
      'neos.preconfirmation.blotter.enabled',
    );

    if (isPreconfBlotterEnabled) {
      resubscribeRequests.push({
        notificationTopic: 'PRECONF_BLOTTER',
        dataGroupNames,
      });
    }

    dispatch(createRequestSubscriptionAction(resubscribeRequests));
  };
}
