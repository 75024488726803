import { NumericInput } from '@/neos/components/share/numericInput';
import type { WeightCellModel } from './getWeightCellModel';

export interface WeightCellOwnProps {
  rfqId: string;
  strategyId: string;
  isReadOnlyRfq: boolean;
}

export type WeightCellMapStateProps = WeightCellModel;

export interface WeightCellDispatchProps {
  onWeightChanged(weight: number | undefined): void;
}

type WeightCellProps = WeightCellOwnProps & WeightCellMapStateProps & WeightCellDispatchProps;

export function WeightCell(props: WeightCellProps) {
  return (
    <section>
      <NumericInput
        value={props.weight}
        warning={props.warning}
        readOnly={props.disabled || props.isReadOnlyRfq}
        onBlur={value => props.onWeightChanged(value)}
        data-e2e="neos-strategy-definition-weight"
      />
    </section>
  );
}
