import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { Currency } from '@/neos/business/referenceData/currency/currencyModel';
import type { LegData } from '@/neos/business/rfq/strategy/leg/legData/legDataModel';
import type { DisplayTimeZone } from '@/neos/business/ui/userPreferences/userPreferencesUiModel';
import type { SelectOption } from '@/neos/components/share/NeosSelect/NeosSelect';
import {
  DATE_LONG_TIME_FORMAT,
  DATE_SHORT_KEBAB_FORMAT,
  dateStringToIsoString,
  formatDateInTimeZone,
} from '@/util/date/dateFormatHelper';
import {
  getKycTextColor,
  type KycTextColor,
} from '@/neos/components/rfq/actorsSetup/getActorSetupModel.ts';

export interface GetBrokerBlocModel {
  brokerFields: Partial<LegData>;
  legId: string;
  brokerId: string | undefined;
  availableBrokers: SelectOption[];
  currencies: Currency[];
  tradingDate:
    | {
        date: string;
        time: string;
      }
    | undefined;
  timezone: DisplayTimeZone;
  brokerTextColor: KycTextColor;
}

export function getBrokerBlocModel(
  state: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): GetBrokerBlocModel {
  const {
    uuid: legId,
    brokerId,
    brokerCommission,
    brokerCommissionCcy,
    brokerInfoVenue,
    brokerInfoExecutionVenue,
    brokerInfoTradingDateTime,
    brokerInfoTradingVenueTransactionId,
  } = selectors.getStrategyLeg(state, strategyId);

  const availableBrokers = selectors.getBrokersList(state.referenceData).map(broker => {
    return {
      label: broker.name,
      value: broker.id.toString(),
    };
  });
  const { brokerNotInPreferences } = selectors.getRfqData(state, rfqId);

  if (brokerNotInPreferences) {
    availableBrokers.push({
      label: brokerNotInPreferences.name,
      value: brokerNotInPreferences.id.toString(),
    });
  }
  const timezone = selectors.getDisplayTimeZone(state.ui.userPreferences);

  const tradingDate = brokerInfoTradingDateTime
    ? {
        date: formatDateInTimeZone(
          timezone,
          dateStringToIsoString(brokerInfoTradingDateTime),
          DATE_SHORT_KEBAB_FORMAT,
        ),
        time: formatDateInTimeZone(
          timezone,
          dateStringToIsoString(brokerInfoTradingDateTime),
          DATE_LONG_TIME_FORMAT,
        ),
      }
    : undefined;

  const selectedBrokerKycColor =
    selectors
      .getRequestExceptPttPredealChecksByBdrId(state, rfqId, Number(brokerId), selectors)
      .find(pdc => pdc.type === 'KYC')?.uiColor || 'UNKNOWN';

  return {
    brokerFields: {
      brokerCommission,
      brokerCommissionCcy,
      brokerInfoVenue,
      brokerInfoExecutionVenue,
      brokerInfoTradingDateTime,
      brokerInfoTradingVenueTransactionId,
    },
    legId,
    brokerId,
    availableBrokers,
    currencies: selectors.getCurrencyList(state.referenceData),
    tradingDate,
    timezone,
    brokerTextColor: getKycTextColor(selectedBrokerKycColor),
  };
}
