import type { ExecutionKey } from '@/neos/business/order/orderModel';
import { NeosBookingId } from '@/neos/components/share/NeosBookingId/NeosBookingId';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import { ErrorHighlightWithTooltip } from '../../../../../share/errorHighlightWithTooltip/ErrorHighlightWithTooltip.container';
import { NumericInput } from '../../../../../share/numericInput';
import styles from './LegExecution.module.scss';
import { CashFlowId } from './cashFlowId/CashFlowId';
import type { LegExecutionModel } from './getLegExecutionModel';
import { BookingStatusWarningIcon } from './legExecutionComponents/BookingStatus';
import { ExecutionDateAndTime } from './legExecutionComponents/ExecutionDateAndTime';

export interface LegExecutionOwnProps {
  executionKey: ExecutionKey;
  isReadOnly: boolean;
  isManual: boolean;
  isWarningDisplayed: boolean;
  isCommFlowIdDisplayed: boolean;
}

export interface LegExecutionStateProps {
  model: LegExecutionModel;
}

export interface LegExecutionDispatchProps {
  onExecutionRemoved: () => void;
  onExecutionTimeChanged: (newTime: string | undefined) => void;
  onExecutionDateChanged: (newDate: string | undefined) => void;
  onExecutionEliotIdChanged: (eliotId: string | undefined) => void;
  onExecutionNumberOfLotsChanged: (newLotSize: number | undefined) => void;
  onExecutionPriceChanged: (newPrice: number | undefined, unit: string | undefined) => void;
  onAllocationAdded: () => void;
}

type LegExecutionProps = LegExecutionOwnProps & LegExecutionStateProps & LegExecutionDispatchProps;

export const LegExecutionComponent = ({
  executionKey,
  isReadOnly,
  isManual,
  model,
  onAllocationAdded,
  onExecutionRemoved,
  onExecutionTimeChanged,
  onExecutionDateChanged,
  onExecutionPriceChanged,
  onExecutionEliotIdChanged,
  onExecutionNumberOfLotsChanged,
  isWarningDisplayed,
  isCommFlowIdDisplayed,
}: LegExecutionProps) => {
  if (!model.exists) {
    return null;
  }

  const {
    price,
    priceUnit,
    executionDateTime,
    eliotId,
    defaultEliotId,
    traderWarning,
    isTrader,
    bookingMessage,
    status,
  } = model;

  return (
    <>
      {!isReadOnly && (
        <button
          className={`${styles['first-column']} btn btn-icon btn-flat-primary`}
          onClick={() => onExecutionRemoved()}
          data-e2e="leg-execution-remove"
        >
          <i className="icon icon-md">delete_forever</i>
        </button>
      )}
      <ErrorHighlightWithTooltip
        errorField={'numberOfLots'}
        related={{ rfqId: executionKey.rfqId, legId: executionKey.legId, strategyId: '' }}
        id={executionKey.executionId}
        isDisplayed={!model.isValidExecStrategySize}
        renderMessage={() => <p>Must be integer</p>}
        componentClassName="warning-bloc field-error"
        tooltipClassName="react-bootstrap-danger-tooltip"
      >
        <NumericInput
          value={model.numberOfLots}
          data-e2e="leg-execution-number-of-lots"
          className={isReadOnly ? styles['first-column'] : ''}
          readOnly={isReadOnly}
          onBlur={val => onExecutionNumberOfLotsChanged(val)}
        />
      </ErrorHighlightWithTooltip>

      <SimpleNeosTooltip
        disable={!isTrader}
        type="warning"
        id="trader-warning"
        message={traderWarning}
      >
        <NumericInput
          readOnly={isReadOnly}
          value={price}
          unit={priceUnit}
          data-e2e="leg-execution-price"
          className={isTrader && traderWarning ? 'field-warning' : ''}
          onBlur={val => {
            onExecutionPriceChanged(val, priceUnit);
          }}
        />
      </SimpleNeosTooltip>
      <ExecutionDateAndTime
        executionDateTime={executionDateTime}
        isReadOnly={isReadOnly}
        isManual={isManual}
        onExecutionDateChanged={onExecutionDateChanged}
        onExecutionTimeChanged={onExecutionTimeChanged}
      />
      <NeosBookingId
        defaultBookingId={defaultEliotId}
        bookingId={eliotId}
        bookingStatus={status}
        dataE2e="leg-execution-eliot-id"
        onBookingIdChanged={onExecutionEliotIdChanged}
      />
      {isWarningDisplayed && <BookingStatusWarningIcon status={status} message={bookingMessage} />}
      {isCommFlowIdDisplayed && <CashFlowId executionKey={executionKey} />}
      <button
        className="btn btn-icon btn-flat-primary"
        onClick={() => onAllocationAdded()}
        data-e2e="leg-execution-add-allocation"
        disabled={isTrader}
      >
        <i className="icon icon-md">add</i>
      </button>
    </>
  );
};
