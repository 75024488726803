import type { AppState } from '@/bootstrap/state';
import type { EventType } from '../../../neos/business/neosModel';

export const similarActivitiesLinkManagementSelectors = {
  getSecondaryEventType,
  getSimilarActivitiesLinkManagement,
};

function getSecondaryEventType(state: AppState, legId: string): EventType | undefined {
  return legId === state.similarActivitiesLinkManagement.currentPrimaryLegId
    ? state.similarActivitiesLinkManagement.secondaryEventType
    : undefined;
}

function getSimilarActivitiesLinkManagement(state: AppState) {
  return state.similarActivitiesLinkManagement;
}
