import { getAuthHeader } from '@/util/api/connect/connect';
import type { BlobWithMetadata } from '@/util/http/sgmeAjaxHttpRequest';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { idCreator } from '@/util/id';
import signalRService from '@/util/notifications/signalRService';
import { type Observable, from, of, throwError } from 'rxjs';

export interface HandleAllocationFileApi {
  uploadFile: (rfqId: string, allocId: string, formData: FormData) => Observable<any>;
  getPreconfAllocationDocument: (rfqId: string, allocId: string) => Observable<BlobWithMetadata>;
  downloadFile: (rfqId: string, allocId: string) => Observable<BlobWithMetadata>;
}

export const makeHandleAllocationFileApi = (http: SgmeHttp): HandleAllocationFileApi => ({
  uploadFile(rfqId: string, allocId: string, formData: FormData) {
    const url = `${sgmeConfiguration.http.webApiHost}api/preConfirmations/${rfqId}/upload/alloc/${allocId}`;

    return from(
      fetch(url, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: getAuthHeader() ?? '',
          'x-correlation-id': idCreator.createId(),
          'sgme-user-datastream-id': signalRService.datastreamId,
        },
      }).then(response => {
        if (!response.ok) {
          throw throwError(response);
        }
        return of('success');
      }),
    );
  },

  downloadFile(rfqId: string, allocId: string) {
    const URL: string = `${sgmeConfiguration.http.webApiHost}api/preConfirmations/`;
    const url = `${URL}${rfqId}/download/${allocId}`;
    let fileName = `pre-confirmation-${rfqId}-${allocId}.pdf`;
    return from(
      fetch(url, {
        method: 'GET',
        headers: {
          Authorization: getAuthHeader() ?? '',
          'x-correlation-id': idCreator.createId(),
          'sgme-user-datastream-id': signalRService.datastreamId,
        },
      })
        .then(response => {
          if (!response.ok) {
            throw throwError(response);
          }
          const contentDispositionHeader = response.headers.get('Content-Disposition');
          if (contentDispositionHeader) {
            const [_, extracted] = contentDispositionHeader.split('"');
            fileName = extracted;
          }
          return response.blob();
        })
        .then(blob => {
          return { blob, metadata: { fileName } } as BlobWithMetadata;
        }),
    );
  },

  getPreconfAllocationDocument(rfqId: string, allocId: string) {
    const url = `api/preConfirmations/${rfqId}/download/${allocId}`;
    return http.get<BlobWithMetadata>({
      url,
      responseContentType: { contentType: 'BLOB' },
    });
  },
});
