import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { mappers } from '@/neos/business/mappers';
import type { RfqDifference } from '@/neos/business/neosModel';
import { classifyDifferences, filterHiddenDifferences } from '@/util/comparison/getDifferences';

export interface RfqDifferencesModalModel {
  highlightedDifferences: RfqDifference[];
  otherDifferences: RfqDifference[];
}

export function getRfqDifferencesModalModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): RfqDifferencesModalModel {
  const differencesBetweenCurrentAndNotificationRfq =
    selectors.getDifferencesBetweenCurrentAndLastTradeCompletedRfq(
      state,
      rfqId,
      selectors,
      mappers.toOnyxMappers,
    );

  const visibleDifferences = filterHiddenDifferences(differencesBetweenCurrentAndNotificationRfq);

  return classifyDifferences(visibleDifferences);
}
