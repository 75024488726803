import type { AppState } from '@/bootstrap/state';
import type { Dispatchable, ExtendedDispatch } from '@/bootstrap/thunks';
import { flattenDeep } from 'lodash';
import type { Middleware, MiddlewareAPI } from 'redux';

export function createActionContainerMiddleware(): Middleware {
  return (_: MiddlewareAPI<ExtendedDispatch, AppState>) =>
    (next: ExtendedDispatch) =>
    (...actions: Dispatchable[]) => {
      const subActions = flattenDeep(actions);
      subActions.forEach(a => {
        next(a);
      });
    };
}
