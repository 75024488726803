import './common/business/appInfo';
import '/assets/images/favicon.ico';
import type { SGWTConnectCore } from '@sgwt/connect-core';
import { bootstrap } from './bootstrap/bootstrap';
import type { SgwtConnectHTMLElement } from './types/sgwt-widgets';
import { displayDomError } from './util/jsErrorHandler/domError';
import { setupAgGridLicence } from './util/libs/ag-grid';
import { logger } from './util/logging/logger';
import { mt } from './util/logging/messageTemplates';
import signalRService from './util/notifications/signalRService';
import { sgwtConnect } from './util/widgets/sgwtConnect';

setupSgwtConnectWidget(sgwtConnect);

if (sgwtConnect.isAuthorized()) {
  renderApp();
} else {
  const authorizationError = sgwtConnect.getAuthorizationError();
  if (authorizationError !== null) {
    displayDomError({ message: 'Authorization error', error: authorizationError });
  } else {
    sgwtConnect.requestAuthorization();
  }
}

function renderApp() {
  setupAgGridLicence();
  connectStreaming();
  logUserAgent();
}

function logUserAgent() {
  const userAgent = navigator.userAgent;
  const chromeVersion = userAgent.match(/Chrome\/(\S+)/)?.[1];
  logger.info(mt.session, chromeVersion);
}

function connectStreaming() {
  try {
    signalRService.connect(sgmeConfiguration.signalr.hubUrl, bootstrap);
  } catch {
    bootstrap({ isSignalRError: true });
  }
}

function setupSgwtConnectWidget(sgwtConnect: SGWTConnectCore) {
  const widget = document.querySelector<SgwtConnectHTMLElement>('sgwt-connect');
  if (widget) {
    // When the code is executed, the swgtConnectWidget may not have been initialized. So, we need to check that, otherwise calling
    // `swgtConnectWidget.setSgwtConnectInstance()` will throw an error.
    if (typeof widget.setSgwtConnectInstance === 'undefined') {
      // Widget is not initialized yet, so we will wait the event that indicates the swgtConnectWidget is ready...
      const handleSgwtConnectReady = () => {
        widget.setSgwtConnectInstance(sgwtConnect);
        widget.removeEventListener('sgwt-connect--ready', handleSgwtConnectReady);
      };

      widget.addEventListener('sgwt-connect--ready', handleSgwtConnectReady);
    } else {
      // Widget is initialized...
      widget.setSgwtConnectInstance(sgwtConnect);
    }
  }
}
