import {
  type Future,
  type ListedNegotiation,
  type Negotiation,
  type Otc,
  type OtcNegotiation,
  type Product,
  isListedProduct,
} from '../../productModel';
import type { OnyxNegotiation } from '../../productOnyxModel';

export const toNegotiationMappers = {
  mapToOnyxNegotiation,
};

export const fromNegotiationMappers = {
  mapFromOnyxNegotiation,
  mapFromOnyxOtcNegotiation,
  mapFromOnyxListedNegotiation,
  mapFromOnyxFutureNegotiation,
};

export type OtcNegotiationFutureProductPart = Pick<
  Otc<Future>,
  'lotSize' | 'negotiationMode' | 'pointValue'
>;

function mapFromOnyxNegotiation(negotiation: OnyxNegotiation | undefined): Negotiation {
  if (!negotiation?.discriminator) {
    throw new Error(`No negotiation or discriminator found.`);
  }

  return negotiation.discriminator === 'LISTED'
    ? mapFromOnyxListedNegotiation(negotiation)
    : mapFromOnyxOtcNegotiation();
}

function mapFromOnyxFutureNegotiation(
  negotiation: OnyxNegotiation | undefined,
): ListedNegotiation | OtcNegotiationFutureProductPart {
  if (!negotiation?.discriminator) {
    throw new Error(`No negotiation or discriminator found.`);
  }

  return negotiation.discriminator === 'LISTED'
    ? mapFromOnyxListedNegotiation(negotiation)
    : { ...mapFromOnyxOtcNegotiation(), pointValue: 1 };
}

function mapFromOnyxOtcNegotiation(): OtcNegotiation {
  return { negotiationMode: 'OTC' };
}

function mapFromOnyxListedNegotiation(negotiation: OnyxNegotiation | undefined): ListedNegotiation {
  if (!negotiation) {
    return {
      negotiationMode: 'LISTED',
      marketExchangeId: undefined,
      refId: undefined,
      marketMicCode: undefined,
    };
  }

  if (negotiation.discriminator !== 'LISTED') {
    throw Error(`Expected Listed Product Negotiation. Found ${negotiation.discriminator}`);
  }

  return {
    negotiationMode: 'LISTED',
    marketExchangeId: negotiation.market?.galaxyCode,
    refId: negotiation.productRef?.id,
    sameProductIds: negotiation.productRef?.sameProductIds,
    marketMicCode: negotiation.market?.micCode,
    clientPosition: negotiation.clientPosition,
  };
}

function mapToOnyxNegotiation(product: Product): OnyxNegotiation {
  const isListed = isListedProduct(product);

  if (isListed) {
    const shouldSendProductRef: string | undefined = product.refId || product.sameProductIds;

    return {
      discriminator: 'LISTED',
      market: product.marketExchangeId
        ? { galaxyCode: product.marketExchangeId, micCode: product.marketMicCode }
        : undefined,
      productRef: shouldSendProductRef
        ? {
            backend: 'GALAXY',
            id: product.refId,
            sameProductIds: product.sameProductIds,
          }
        : undefined,
      clientPosition: product.clientPosition,
    };
  }
  return {
    discriminator: 'OTC',
  };
}
