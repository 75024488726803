import type { Thunk } from '@/bootstrap/thunks';
import { RfqRecomputeScope } from '../../../neosModel';

export function createChangeForexRatesThunk(
  rfqId: string,
  forexRateValue: number | undefined,
  fromCurrency: string,
  toCurrency: string,
): Thunk {
  return function changeForexRatesThunk(
    dispatch,
    getState,
    {
      selectors: { getForexRates },
      actionCreators: {
        neos: { forexRatesCrudActions, createRecomputeRfqRequestedAction },
      },
    },
  ) {
    const state = getState();

    const forexRates = getForexRates(state, rfqId);

    const modifiedForexRateIndex = forexRates.findIndex(
      forexRate => forexRate.from === fromCurrency && forexRate.to === toCurrency,
    );
    dispatch(
      forexRatesCrudActions.arrayPatch(rfqId, {
        index: modifiedForexRateIndex,
        value: { from: fromCurrency, to: toCurrency, rate: forexRateValue },
        property: 'forexRates',
      }),
    );

    if (forexRateValue !== undefined) {
      const oppositeForexRateIndex = forexRates.findIndex(
        forexRate => forexRate.from === toCurrency && forexRate.to === fromCurrency,
      );

      dispatch(
        forexRatesCrudActions.arrayRemoveIndex(rfqId, {
          index: oppositeForexRateIndex,
          property: 'forexRates',
        }),
        createRecomputeRfqRequestedAction(rfqId, RfqRecomputeScope.CHANGE_FOREX),
      );
    }
  };
}
