import { NeosTooltip } from '@/neos/components/share/tooltip/NeosTooltip';
import { useState } from 'react';
import { FormSelect, Tooltip } from 'react-bootstrap';

import styles from './AddStrategyButton.module.scss';

interface AddStrategyButtonProps {
  tooltipText: string;
  availableValues: number[];
  isDisabled?: boolean;
  onClick(): void;
  onSelect(n: number): void;
}

export function AddStrategyButton({
  availableValues,
  onClick,
  onSelect,
  tooltipText,
  isDisabled,
}: AddStrategyButtonProps) {
  const [acceleratorIsVisible, setAcceleratorIsVisible] = useState(false);

  return (
    <div
      onMouseOver={() => !isDisabled && setAcceleratorIsVisible(true)}
      onMouseOut={() => setAcceleratorIsVisible(false)}
      className={`${styles['button-with-accelerator']} `}
    >
      <NeosTooltip
        placement="top"
        overlay={<Tooltip id={'add-strategies-button'}>{tooltipText}</Tooltip>}
      >
        <button
          className="btn btn-outline-primary btn-icon"
          disabled={isDisabled}
          onClick={() => onClick()}
          data-e2e="neos-strategy-add"
        >
          <i className="icon">add</i>
        </button>
      </NeosTooltip>
      <div
        className={`${styles['accelerator']} `}
        style={{ display: acceleratorIsVisible ? 'block' : 'none' }}
      >
        <NeosTooltip
          placement="top"
          overlay={<Tooltip id={'add-strategies-button'}>Add many</Tooltip>}
        >
          <FormSelect
            value={0}
            onChange={event => onSelect(parseInt(event.target.value, 10))}
            data-e2e="neos-strategy-add-many"
          >
            <option key="void" />
            {availableValues.map((value: number) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </FormSelect>
        </NeosTooltip>
      </div>
    </div>
  );
}
