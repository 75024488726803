import type { DeltaType } from '../neosModel';

export function getDomainId({ rfqId, underlyingId, deltaType }: DomainKey): string {
  return `${rfqId}${compositeIdSeparator}${underlyingId}${compositeIdSeparator}${
    deltaType ?? 'NONE'
  }`;
}

export function getDomainKey(id: string): DomainKey {
  const [rfqId, underlyingId, deltaType] = id.split(compositeIdSeparator);
  return {
    rfqId,
    underlyingId,
    deltaType: deltaType === 'NONE' ? undefined : (deltaType as DomainKey['deltaType']),
  };
}

const compositeIdSeparator = ' | ';

export interface DomainKey {
  rfqId: string;
  underlyingId: string;
  deltaType: DeltaType | undefined;
}
