import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { debounce } from 'lodash';
import {
  type KeyboardEvent,
  type Ref,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { type BlotterRowModel, updateAndReturnSelectedRows } from '../../../share/blotterGrid';
import type { SimilarActivitiesRef } from '../SimilarActivities';
import { getBlotterColDefs } from './getGridColDef';
import type { SimilarActivitiesGridModel } from './getSimilarActivitiesGridModel';

import { RfqDetailsCellRenderer } from './RfqDetails.container';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import type {
  GetContextMenuItemsParams,
  GridApi,
  GridReadyEvent,
  MenuItemDef,
  RowDoubleClickedEvent,
} from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { SideBarModule } from '@ag-grid-enterprise/side-bar';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import styles from './SimilarActivitiesGrid.module.scss';

export interface SimilarActivitiesGridOwnProps {
  rfqId: string;
}

export interface SimilarActivitiesGridStateProps {
  model: SimilarActivitiesGridModel;
}

export interface SimilarActivitiesGridDispatchProps {
  loadLatestTransactions(rfqIds: string[]): void;

  displayNotManagedRfqError(): void;
}

type GridProps = SimilarActivitiesGridOwnProps &
  SimilarActivitiesGridStateProps &
  SimilarActivitiesGridDispatchProps;

export const SimilarActivitiesGridWithStyle = (
  {
    rfqId,
    model: { displayTimezone, isTrader, rows },
    loadLatestTransactions,
    displayNotManagedRfqError,
  }: GridProps,
  ref: Ref<SimilarActivitiesRef>,
) => {
  const columnDefs = getBlotterColDefs({ displayTimezone, isTrader });
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  useImperativeHandle(ref, () => ({
    handleSimilarActivitiesResize: () => {
      sizeColumnsToFit();
    },
  }));

  const sizeColumnsToFit = debounce(() => {
    return gridApi?.sizeColumnsToFit();
  }, 300);

  useEffect(() => {
    window.addEventListener('resize', sizeColumnsToFit);
    return function cleanup() {
      window.removeEventListener('resize', sizeColumnsToFit);
    };
  });

  function onGridReady(params: GridReadyEvent) {
    setGridApi(params.api);
    return params.api.sizeColumnsToFit();
  }

  function handleMultipleOpenRfq({ charCode }: KeyboardEvent<HTMLDivElement>) {
    if (charCode === 13) {
      const selectedRows = gridApi?.getSelectedRows() as BlotterRowModel[];
      const selectedIds = selectedRows.map(node => node.uuid);
      loadLatestTransactions(selectedIds);
    }
  }

  const rfqDetailsCellRenderer = useMemo(() => {
    return RfqDetailsCellRenderer;
  }, []);

  return (
    <div
      className={`${styles['blotter-grid']} ag-theme-sg-bootstrap ag-theme-sg-bootstrap-condensed`}
      onKeyPress={handleMultipleOpenRfq}
    >
      <AgGridReact
        gridOptions={{
          onGridReady,
          defaultColDef: {
            enableRowGroup: true,
            enablePivot: true,
            resizable: true,
            sortable: true,
            filter: true,
            floatingFilter: true,
          },
          statusBar: {
            statusPanels: [
              {
                statusPanel: 'agTotalAndFilteredRowCountComponent',
                align: 'left',
              },
              {
                statusPanel: 'agAggregationComponent',
                statusPanelParams: {
                  aggFuncs: ['sum', 'min', 'max', 'avg'],
                },
              },
            ],
          },
          sideBar: false,
          animateRows: true,
          suppressRowClickSelection: false,
          rowSelection: 'multiple',
          enableRangeSelection: true,
          columnDefs,
          excelStyles: [
            {
              id: 'ExcelDateTime',
              dataType: 'DateTime',
              numberFormat: { format: 'yyyy-mm-dd hh:mm:ss;;;' },
            },
          ],
          getRowId: (params: any) => params.data.uuid,
          getContextMenuItems: getContextMenuItems(loadLatestTransactions),
          onRowDoubleClicked: (e: RowDoubleClickedEvent) => {
            const data: BlotterRowModel | undefined = e.data;
            if (data) {
              return data.isLoadableRfq
                ? loadLatestTransactions([data.uuid])
                : displayNotManagedRfqError();
            }
          },
        }}
        rowData={rows}
        masterDetail={true}
        detailCellRenderer={rfqDetailsCellRenderer}
        detailCellRendererParams={{ parentRfqId: rfqId }}
        detailRowAutoHeight={true}
        modules={[
          ExcelExportModule,
          ClientSideRowModelModule,
          RangeSelectionModule,
          SideBarModule,
          StatusBarModule,
          MenuModule,
          RowGroupingModule,
          MasterDetailModule,
          ClipboardModule,
        ]}
      />
    </div>
  );
};

function getContextMenuItems(loadLatestTransactions: (ids: string[]) => void) {
  return (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
    const selectedRows = updateAndReturnSelectedRows(params);
    const selectedIds = selectedRows.map(node => node.uuid);

    const loadMultipleRfq = () => {
      if (selectedIds.length > 0) {
        loadLatestTransactions(selectedIds);
      }
    };

    return [
      {
        name: 'Open RFQ',
        action: loadMultipleRfq,
        disabled: selectedIds.length === 0,
      },
      'separator',
      'copy',
      'copyWithHeaders',
      'paste',
    ];
  };
}
