import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import type { LegDescriptionComponentProps } from './AveragePrice';

export function Underlying({ legId }: LegDescriptionComponentProps) {
  const underlyingBloombergCode = useAppSelector(state =>
    selectors.getLegUnderlyingLabel(state, legId, selectors),
  );

  return (
    <div>
      <label className="mb-0 me-3">Underlying</label>
      <input
        className="form-control"
        data-e2e="underlying"
        value={underlyingBloombergCode ?? ''}
        readOnly
      />
    </div>
  );
}
