import { formatFromCamelToStartCase } from '@/util/format';
import type { ForexType } from '../../../../../../business/neosModel';
import { ErrorHighlight } from '../../../../../share/errorHighlight';
import type { ForexTypeModel } from './getForexTypeModel';

import { FormSelect } from 'react-bootstrap';

export interface OwnProps {
  rfqId: string;
  strategyId: string;
}

export interface StateProps {
  model: ForexTypeModel;
}

export interface DispatchProps {
  onForexTypeChanged(forexType: ForexType | undefined): void;
}

type Props = OwnProps & StateProps & DispatchProps;

export const ForexTypeComponent = ({ rfqId, strategyId, model, onForexTypeChanged }: Props) => {
  if (!model.isDisplayed) {
    return <div />;
  }

  const { forexType, availableForexTypes } = model;
  return (
    <div
      className="d-grid gap-1 mb-1 ms-1 border p-1 fit-content"
      style={{ gridTemplateColumns: '1fr' }}
    >
      <label className="fw-bold">Forex Type</label>
      <ErrorHighlight errorField={'forexType'} related={{ rfqId, strategyId }}>
        <FormSelect
          value={forexType || ''}
          style={{ minWidth: '80px' }}
          onChange={event => onForexTypeChanged((event.target.value as ForexType) || undefined)}
          data-e2e="feature-forex-type"
        >
          {availableForexTypes.map(forexType => (
            <option key={forexType ?? ''} value={forexType ?? ''}>
              {forexType ? formatFromCamelToStartCase(forexType) : ''}
            </option>
          ))}
        </FormSelect>
      </ErrorHighlight>
    </div>
  );
};
