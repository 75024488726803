import { Switch } from '@/neos/components/share/switch/Switch';
import { debounce } from 'lodash';
import { useState } from 'react';
import { Modal, type ModalProps } from 'react-bootstrap';
import type { Destination } from '../../../../../neos/business/neosModel';
import type { PreconfirmationModalModel } from './getPreconfirmationModalModel';
import { ExternalPreconfirmationBookingOptions } from './tabs/externalPreconfirmationBookingOptions/ExternalPreconfirmationBookingOptions.container';
import { ExternalPreconfTab } from './tabs/externalPreconfirmationBookingOptions/PreconfTabContainer';
import { InternalPreconfirmationBookingOptions } from './tabs/internalPreconfirmationBookingOptions/InternalPreconfirmationBookingOptions.container';
import { InternalPreconfTab } from './tabs/internalPreconfirmationBookingOptions/PreconfTabContainer';
import { PreconfirmationPreview } from './tabs/shared/preconfirmationPreview/PreconfirmationPreviewContainer';

export interface PreconfirmationModalOwnProps {
  rfqId: string;
  isDisplayed: boolean;
  onHide: () => void;
}

export interface PreconfirmationModalStateProps {
  model: PreconfirmationModalModel;
}

export type PreconfirmationModalProps = PreconfirmationModalOwnProps &
  PreconfirmationModalStateProps &
  PreconfirmationModalDispatchProps;

export interface PreconfirmationModalDispatchProps {
  onSendClicked(rfqId: string, isExternalSelected: boolean, isInternalSelected: boolean): void;
}

export const PreconfirmationModalComponent = ({
  rfqId,
  isDisplayed,
  onHide,
  model: {
    isExternalPreconfirmationPreviewVisible,
    isInternalPreconfirmationPreviewVisible,
    externalColor,
    internalColor,
    isPreconfirmationButtonsDisabled,
  },
  onSendClicked,
}: PreconfirmationModalProps) => {
  const onSendClickedDebounced = debounce(onSendClicked, 20000, {
    leading: true,
    trailing: false,
  });

  const externalCol = isExternalPreconfirmationPreviewVisible ? 'col-3' : 'col-12';
  const internalCol = isInternalPreconfirmationPreviewVisible ? 'col-3' : 'col-12';

  const [activeTab, setActiveTab] = useState<Destination>('EXTERNAL');
  const [isExternalSendSelected, setIsExternalSelected] = useState<boolean>(true);
  const [isInternalSendSelected, setIsInternalSelected] = useState<boolean>(true);

  const size: ModalProps['size'] =
    (isExternalPreconfirmationPreviewVisible && activeTab === 'EXTERNAL') ||
    (isInternalPreconfirmationPreviewVisible && activeTab === 'INTERNAL')
      ? 'xl'
      : 'lg';

  return (
    <Modal
      show={isDisplayed}
      onHide={() => {
        onHide();
        // mandatory setTimeout here, otherwise we see the modal switching tab before it close.
        setTimeout(() => setActiveTab('EXTERNAL'), 200);
      }}
      size={size}
    >
      <Modal.Header closeButton>
        <Modal.Title>Preconf Email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <nav>
            <div className="nav nav-tabs" role="tablist">
              <a
                onClick={() => setActiveTab('EXTERNAL')}
                className={`nav-link ${activeTab === 'EXTERNAL' ? 'active' : ''}`}
              >
                External <i className={`icon icon-sm ms-2 text-${externalColor}`}>circle</i>
              </a>
              <a
                onClick={() => setActiveTab('INTERNAL')}
                className={`nav-link ${activeTab === 'INTERNAL' ? 'active' : ''}`}
              >
                Internal <i className={`icon icon-sm ms-2 text-${internalColor}`}>circle</i>
              </a>
            </div>
          </nav>
          {activeTab === 'EXTERNAL' && (
            <ExternalPreconfTab rfqId={rfqId}>
              <div className="row mb-2">
                <div className={externalCol}>
                  {<ExternalPreconfirmationBookingOptions rfqId={rfqId} />}
                </div>
                {isExternalPreconfirmationPreviewVisible && (
                  <div className="col-9">
                    {<PreconfirmationPreview rfqId={rfqId} destination={'EXTERNAL'} />}
                  </div>
                )}
              </div>
            </ExternalPreconfTab>
          )}
          {activeTab === 'INTERNAL' && (
            <InternalPreconfTab rfqId={rfqId}>
              <div className="row mb-2">
                <div className={internalCol}>
                  {<InternalPreconfirmationBookingOptions rfqId={rfqId} />}
                </div>
                {isInternalPreconfirmationPreviewVisible && (
                  <div className="col-9">
                    {<PreconfirmationPreview rfqId={rfqId} destination={'INTERNAL'} />}
                  </div>
                )}
              </div>
            </InternalPreconfTab>
          )}
        </>
      </Modal.Body>
      <Modal.Footer>
        <>
          <div>
            <div className="d-flex justify-content-between mb-1">
              <span className="me-1 fw-bold">External</span>
              <Switch
                checked={isExternalSendSelected}
                onChange={() => setIsExternalSelected(!isExternalSendSelected)}
              />
            </div>
            <div className="d-flex justify-content-between">
              <span className="me-1 fw-bold">Internal</span>

              <Switch
                checked={isInternalSendSelected}
                onChange={() => setIsInternalSelected(!isInternalSendSelected)}
              />
            </div>
          </div>
          <button
            className="btn btn-lg btn-primary"
            disabled={
              isPreconfirmationButtonsDisabled ||
              !(isExternalSendSelected || isInternalSendSelected)
            }
            onClick={() => {
              onSendClickedDebounced(rfqId, isExternalSendSelected, isInternalSendSelected);
            }}
            data-e2e="preconfirmation-send"
          >
            Send
            <i className="icon ms-2">mail</i>
          </button>
        </>
      </Modal.Footer>
    </Modal>
  );
};
