import type { Thunk } from '@/bootstrap/thunks';
import type { StrategyType } from '../../../neos/business/neosModel';
import { internalGetErrorId } from '../mappers/error/internal/errorIdGenerator';
import type { OnyxFieldError } from '../mappers/error/onyxErrorModel';
import type { NeosActionCreators } from '../neosActionCreators';
import { toDisplayNegotiatedSize } from '../ui/strategy/strategyUiModel';

export function createNeosChangeStrategiesTypesThunk(
  rfqId: string,
  strategyIds: string[],
  strategyType: StrategyType,
): Thunk {
  return function neosChangeStrategiesTypesThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: { neos },
      thunks: {
        neos: {
          createDefaultStrategyTypeRequestThunk,
          createFetchCurvesListBasedOnCurrencyThunk,
          createRfqChangeStrategyTypeThunk,
          createHandleErrorsThunk,
        },
      },
    },
  ) {
    const appState = getState();
    const { strategyUiCrudActions, rfqDataCrudActions } = neos;
    const defaultSizeType = selectors.getStrategyTypeDefaultSizeType(
      appState,
      strategyType,
      selectors,
    );

    const displayedSizeType = toDisplayNegotiatedSize[defaultSizeType];
    const defaultDisplayedSizeType =
      displayedSizeType === 'NOTIONAL' ? 'LOCAL_NOTIONAL' : displayedSizeType;

    const isElsStrategy = strategyType === 'ELS';

    if (!isElsStrategy) {
      dispatch(rfqDataCrudActions.update(rfqId, { internal: undefined }));
    }

    dispatch(
      strategyIds.map(strategyId => [
        createRfqChangeStrategyTypeThunk(rfqId, strategyId, strategyType),
        strategyUiCrudActions.update(strategyId, {
          displayedSizeType: defaultDisplayedSizeType,
        }),
      ]),
      createDefaultStrategyTypeRequestThunk(rfqId, strategyIds, strategyType, {
        success: {
          dispatchables: [
            createFetchCurvesListBasedOnCurrencyThunk(rfqId, strategyIds),
            getErrorReset(strategyIds, neos),
          ],
        },
        error: {
          dispatchables: [createHandleErrorsThunk(rfqId, getStrategyTypeFieldErrors(strategyIds))],
        },
      }),
    );
  };
}

function getErrorReset(strategyIds: string[], { errorCrudActions }: NeosActionCreators) {
  return strategyIds.map(strategyId => {
    const errorId = internalGetErrorId('strategyType')({
      strategyId,
    });
    return errorCrudActions.delete(errorId);
  });
}

function getStrategyTypeFieldErrors(strategyIds: string[]): OnyxFieldError[] {
  return strategyIds.map((_, stratIndex) => ({
    field: `rfq.strategies[${stratIndex}].strategyType`,
    message: 'Due to defaulting service error, strategy type has not been correctly set',
  }));
}
