import { RfqPremium } from './rfqPremium/RfqPremium.container';
import { CcyPremium } from './ccyPremium/CcyPremium';
import { TotalSalesMargin } from './totalSalesMargin/TotalSalesMargin';
import styles from './Totals.module.scss';
import { selectors } from '@/bootstrap/selectors';
import { useAppSelector } from '@/bootstrap/hooks';
import { If } from '@/neos/components/share/if/if';

export interface BidAskDisplay {
  bidClassName: string;
  askClassName: string;
}

export interface TotalsOwnProps {
  rfqId: string;
}

export function Totals({ rfqId }: TotalsOwnProps) {
  const { clientWay } = useAppSelector(state => selectors.getRfqData(state, rfqId));
  const clientWayClassName =
    clientWay === 'BUY'
      ? 'rfq-total-transparent-bids'
      : clientWay === 'SELL'
        ? 'rfq-total-transparent-asks'
        : '';
  const isElsIdb = useAppSelector(state => selectors.isRfqInitiatedByTrader(state, rfqId));

  return (
    <section className={`${styles['totals-recap']} ${clientWayClassName}`}>
      <RfqPremium rfqId={rfqId} />
      <CcyPremium rfqId={rfqId} />
      <If condition={!isElsIdb}>
        <TotalSalesMargin rfqId={rfqId} />
      </If>
    </section>
  );
}
