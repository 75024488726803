import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { WithExtraProperty } from '@/neos/business/rfq/strategy/feature/withExtraProperty';
import { isDefined } from '@/util/undefinedAndNull/isDefined';
import type { ToOnyxMappers } from '../../../mappers';
import {
  type OnyxBasketUnderlying,
  type OnyxProductFeaturesFields,
  type OnyxProductUnderlying,
  assertIsOnyxProductUnderlying,
} from '../../../neosOnyxModel';
import { mapFromOnyxExtraFeaturesToBarriers, mapToOnyxBarriers } from './barriers/barrierMapper';
import { mapFromOnyxExtraFeaturesToCapFloor, mapToOnyxCapFloor } from './capFloor/capFloorMapper';
import {
  mapFromOnyxFeatureOrExtraFeaturesCurveTenor,
  mapToOnyxInterestRateFeature,
} from './curveTenor/curveTenorMapper';
import { mapFromOnyxFeatureOrExtraFeaturesToDescription } from './description/descriptionMapper';
import {
  mapFromOnyxFeatureOrExtraFeaturesDividend,
  mapToOnyxDividendFeature,
} from './dividend/dividendMapper';
import {
  mapFromOnyxFeatureOrExtraFeaturesToEndOfObservation,
  mapToOnyxEndOfObservation,
} from './endOfObservation/endOfObservationMapper';
import {
  mapFromOnyxFeatureOrExtraFeaturesToEquityBullet,
  mapToOnyxEquityBulletFeature,
} from './equityBullet/equityBulletMapper';
import {
  mapFromOnyxFeatureOrExtraFeaturesEquityFinance,
  mapToOnyxEquityFinance,
} from './equityFinance/equityFinanceMapper';
import {
  mapFromOnyxExtraFeaturesToExecFeesFeature,
  mapToOnyxExecFeesFeature,
} from './execFees/execFeesMapper';
import type { Feature } from './featureModel';
import {
  mapFromOnyxFeatureOrExtraFeaturesToForexType,
  mapToOnyxForexType,
} from './forexType/forexTypeMapper';
import {
  mapFromOnyxFeatureOrExtraFeaturesToForwardStart,
  mapToOnyxForwardStart,
} from './forwardStart/forwardStartMapper';
import {
  mapFromOnyxFeatureOrExtraFeaturesPartialReset,
  mapToOnyxPartialReset,
} from './partialReset/partialResetMapper';
import {
  mapFromOnyxFeatureOrExtraFeaturesToRateBulletFeature,
  mapToOnyxRateBulletFeature,
} from './rateBullet/rateBulletMapper';
import {
  mapFromOnyxFeatureOrExtraFeaturesToRateOvernightFeature,
  mapToOnyxRateOvernightFeature,
} from './rateOvernight/rateOvernightMapper';
import {
  mapFromOnyxFeatureOrExtraFeaturesToResetFrequency,
  mapToOnyxResetFrequencyFeature,
} from './resetFrequency/resetFrequencyMapper';
import {
  mapFromOnyxFeatureOrExtraFeaturesSwapCurrency,
  mapToOnyxSwapCurrency,
} from './swapCurrency/swapCurrencyMapper';
import { mapFromOnyxFeatureOrExtraFeatureToUpDown, mapToOnyxUpDown } from './upDown/upDownMapper';
import {
  mapFromOnyxFeatureOrExtraFeaturesValuationFrequency,
  mapToOnyxValuationFrequency,
} from './valuationFrequency/valuationFrequencyMapper';

export function mapFromOnyxFeatures(
  productFields: OnyxProductFeaturesFields,
  strategyId: string,
): Feature[] {
  const { extraFeatures } = productFields;
  return [
    mapFromOnyxFeatureOrExtraFeaturesToForwardStart(productFields, strategyId),
    mapFromOnyxFeatureOrExtraFeaturesToEquityBullet(productFields, strategyId),
    mapFromOnyxFeatureOrExtraFeaturesToRateBulletFeature(productFields, strategyId),
    mapFromOnyxFeatureOrExtraFeaturesToRateOvernightFeature(productFields, strategyId),
    mapFromOnyxExtraFeaturesToExecFeesFeature(productFields, strategyId),
    mapFromOnyxFeatureOrExtraFeaturesToResetFrequency(productFields, strategyId),
    mapFromOnyxExtraFeaturesToBarriers(extraFeatures || [], strategyId),
    mapFromOnyxExtraFeaturesToCapFloor(extraFeatures || [], strategyId),
    mapFromOnyxFeatureOrExtraFeatureToUpDown(productFields, strategyId),
    mapFromOnyxFeatureOrExtraFeaturesToForexType(productFields, strategyId),
    mapFromOnyxFeatureOrExtraFeaturesToDescription(productFields, strategyId),
    mapFromOnyxFeatureOrExtraFeaturesToEndOfObservation(productFields, strategyId),
    mapFromOnyxFeatureOrExtraFeaturesCurveTenor(productFields, strategyId),
    mapFromOnyxFeatureOrExtraFeaturesDividend(productFields, strategyId),
    mapFromOnyxFeatureOrExtraFeaturesPartialReset(productFields, strategyId),
    mapFromOnyxFeatureOrExtraFeaturesValuationFrequency(productFields, strategyId),
    mapFromOnyxFeatureOrExtraFeaturesSwapCurrency(productFields, strategyId),
    mapFromOnyxFeatureOrExtraFeaturesEquityFinance(extraFeatures || [], strategyId),
  ].filter(isDefined);
}

function mapForFeatureType(
  state: AppState,
  feature: WithExtraProperty<Feature>,
  selectors: Selectors,
  mappers: ToOnyxMappers,
  underlying: OnyxProductUnderlying | OnyxBasketUnderlying | undefined,
): OnyxProductFeaturesFields {
  switch (feature.type) {
    case 'FORWARD_START':
      return mapToOnyxForwardStart(feature);
    case 'BARRIERS':
      return mapToOnyxBarriers(state, feature, selectors, mappers);
    case 'CAP_FLOOR':
      assertIsOnyxProductUnderlying(underlying);
      return mapToOnyxCapFloor(feature, underlying);
    case 'UP_DOWN':
      assertIsOnyxProductUnderlying(underlying);
      return mapToOnyxUpDown(feature, underlying);
    case 'EQUITY_BULLET':
      return mapToOnyxEquityBulletFeature(feature);
    case 'RATE_BULLET':
      return mapToOnyxRateBulletFeature(feature);
    case 'RATE_OVERNIGHT': {
      return mapToOnyxRateOvernightFeature(feature);
    }
    case 'EXEC_FEES':
      return mapToOnyxExecFeesFeature(feature);
    case 'RESET_FREQUENCY_FEATURE':
      return mapToOnyxResetFrequencyFeature(feature);
    case 'INTEREST_RATE_INDEX_FEATURE':
      return mapToOnyxInterestRateFeature(feature);
    case 'FOREX_TYPE':
      return mapToOnyxForexType(feature);
    case 'DESCRIPTION':
      return { description: feature.description };
    case 'END_OF_OBSERVATION':
      return mapToOnyxEndOfObservation(feature);
    case 'DIVIDEND_COMPONENT':
      return mapToOnyxDividendFeature(feature);
    case 'VALUATION_FREQUENCY':
      return mapToOnyxValuationFrequency(feature);
    case 'PARTIAL_RESET':
      return mapToOnyxPartialReset(feature);
    case 'SWAP_CURRENCY':
      return mapToOnyxSwapCurrency(feature);
    case 'EQUITY_FINANCE':
      return mapToOnyxEquityFinance(feature);
  }
}

function combineProductFields(
  result: OnyxProductFeaturesFields,
  current: OnyxProductFeaturesFields,
): OnyxProductFeaturesFields {
  return {
    ...result,
    ...current,
    extraFeatures: [...(result.extraFeatures || []), ...(current.extraFeatures || [])],
  };
}

export function mapToOnyxProductFeatures(
  state: AppState,
  strategyId: string,
  selectors: Selectors,
  mappers: ToOnyxMappers,
): OnyxProductFeaturesFields {
  const { productId: masterLegProductId } = selectors.getStrategyMasterLeg(
    state,
    strategyId,
    selectors,
  );
  const underlying = mappers.mapToOnyxProductUnderlying(
    state,
    masterLegProductId,
    selectors,
    mappers,
  );

  const features: WithExtraProperty<Feature>[] = selectors
    .getStrategyFeatures(state.featureState, strategyId)
    .map(feature => {
      const isExtra = !selectors.isStrategyFeatureMandatory(state, feature, selectors);
      return {
        ...feature,
        isExtra,
      };
    });

  const onyxProductFeaturesFieldsArray: OnyxProductFeaturesFields[] = features
    .map(feature => {
      return mapForFeatureType(state, feature, selectors, mappers, underlying);
    })
    .filter(isDefined);

  return onyxProductFeaturesFieldsArray.reduce(combineProductFields, {});
}
