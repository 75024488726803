import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import type {
  ForexType as FT,
  ForexTypeFeatureKey,
} from '../../../../../../../neos/business/neosModel';
import {
  ForexTypeComponent as Component,
  type DispatchProps,
  type OwnProps,
  type StateProps,
} from './ForexType';
import { getForexTypeModel } from './getForexTypeModel';

function mapStateToProps(appState: AppState, { strategyId }: OwnProps): StateProps {
  return { model: getForexTypeModel(appState, strategyId, selectors) };
}

function mapDispatchToProps(dispatch: ExtendedDispatch, { strategyId }: OwnProps): DispatchProps {
  const featureKey: ForexTypeFeatureKey = { strategyId, type: 'FOREX_TYPE' };

  return {
    onForexTypeChanged: (forexType: FT | undefined) =>
      dispatch(neosThunks.createChangeFeatureFieldThunk(featureKey, { forexType })),
  };
}

export const ForexType = connect(mapStateToProps, mapDispatchToProps)(Component);
