import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ErrorableAllUi } from '@/neos/business/mappers/error/errorHandlerData';
import { pick } from 'lodash';
import type { ErrorHighlightOwnProps } from '../errorHighlight/ErrorHighlight';
import { getErrorIds } from '../errorHighlight/ErrorHighlight.container';
import type { NeosTooltipProps as TooltipComponentProps } from '../tooltip/NeosTooltip';
import type { ErrorHighlightProps, TooltipProps } from './ErrorHighlightWithTooltip';
import type { ErrorHighlightWithTooltipOwnProps } from './ErrorHighlightWithTooltip.container';

export function getErrorHighlightWithTooltipModel<T extends ErrorableAllUi>(
  appState: AppState,
  props: ErrorHighlightWithTooltipOwnProps<T>,
  selectors: Selectors,
): ErrorHighlightProps<T> | TooltipProps {
  const isError = getErrorIds<T>(props).some(
    errorId => selectors.getNeosError(appState.ui, errorId).isError,
  );
  return pickProps(props, isError);
}

function pickProps<T extends ErrorableAllUi>(
  props: ErrorHighlightWithTooltipOwnProps<T>,
  isError: boolean,
): ErrorHighlightProps<T> | TooltipProps {
  if (isError) {
    const errorOwnProps: ErrorHighlightOwnProps<T> = pick<
      ErrorHighlightWithTooltipOwnProps<T>,
      keyof ErrorHighlightOwnProps<T>
    >(props, 'errorField', 'related', 'disableError', 'className', 'children');
    const errorProps: ErrorHighlightProps<T> = { ...errorOwnProps, discriminator: 'ERROR' };
    return errorProps;
  }
  const tooltipOwnProps: TooltipComponentProps = pick<
    ErrorHighlightWithTooltipOwnProps<T>,
    keyof TooltipComponentProps
  >(
    props,
    'id',
    'isDisplayed',
    'renderMessage',
    'placement',
    'tooltipClassName',
    'componentClassName',
    'children',
  );
  const tooltipProps: TooltipProps = { ...tooltipOwnProps, discriminator: 'TOOLTIP' };
  return tooltipProps;
}
