import type { Destination } from '../../../../../../../../neos/business/neosModel';
import styles from './PreconfirmationPreview.module.scss';

export interface PreconfirmationPreviewOwnProps {
  rfqId: string;
  destination: Destination;
}

export interface PreconfirmationPreviewStateProps {
  from: string;
  to: string;
  cc: string;
  bcc: string;
  emailBody: string;
}

export interface PreconfirmationPreviewDispatchProps {
  onCleanPreviewData: () => void;
}

export type PreconfirmationEmailPreviewProps = PreconfirmationPreviewStateProps &
  PreconfirmationPreviewOwnProps &
  PreconfirmationPreviewDispatchProps;

export const PreconfirmationPreviewComponent = ({
  from,
  to,
  cc,
  bcc,
  emailBody,
  onCleanPreviewData,
}: PreconfirmationEmailPreviewProps) => {
  return (
    <>
      <h5 className="mb-3 d-flex align-items-center">
        Preview
        <button onClick={() => onCleanPreviewData()} className="btn btn-icon btn-flat-primary ms-2">
          <i className="icon">clear</i>
        </button>
      </h5>
      <div className="container">
        <div className="row mb-2">
          <div className="my-auto col-2 fw-bold">From</div>
          <div className="col-10">
            <input
              readOnly
              className="form-control"
              value={from}
              data-e2e="preconfirmation-preview-from"
            />
          </div>
        </div>

        <div className="row mb-2">
          <div className="my-auto col-2 fw-bold">To</div>
          <div className="col-10">
            <input
              readOnly
              className="form-control"
              value={to}
              data-e2e="preconfirmation-preview-to"
            />
          </div>
        </div>

        <div className="row mb-2">
          <div className="my-auto col-2 fw-bold">Cc</div>
          <div className="col-10">
            <input
              readOnly
              className="form-control"
              value={cc}
              data-e2e="preconfirmation-preview-cc"
            />
          </div>
        </div>

        <div className="row mb-2">
          <div className="my-auto col-2 fw-bold">Bcc</div>
          <div className="col-10">
            <input
              readOnly
              className="form-control"
              value={bcc}
              data-e2e="preconfirmation-preview-bcc"
            />
          </div>
        </div>

        <div className="row mt-4">
          <div className="w-100 fw-bold">Email Body</div>
          <div className={`col-12 ${styles['email-body-container']}`}>
            <iframe sandbox="" srcDoc={emailBody} data-e2e="preconfirmation-preview-body"></iframe>
          </div>
        </div>
      </div>
    </>
  );
};
