import { NeosTooltip } from '@/neos/components/share/tooltip/NeosTooltip';
import { cn } from '@/util/classNames';
import { Popover } from 'react-bootstrap';
import type { TabId } from '../NeosTabs';
import { type InfoBadgeValue, TabTitleBadge } from '../TabTitleBadge';
import { BlotterPinButtons } from './BlotterPinButtons.container';

import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import styles from './TabTitle.module.scss';

export interface TabTitleOwnProps {
  tabId: TabId;
}

interface LabelLine {
  text: string;
  className: string;
}

export interface TabTitleStateProps {
  infoBadgeValue: InfoBadgeValue | undefined;
  isBadgeDisplayed: boolean;
  label: LabelLine[];
  active: boolean;
  isLoading: boolean;
  isAlertIconDisplayed: boolean;
  isPinned: boolean;
  isFeatureToggleEnabled?: boolean;
  isSecondaryRfq?: boolean;
  tabsCount: number;
}

export interface TabTitleDispatchProps {
  onTabSelectionChanged: (tabId: TabId) => void;
  onTabClosed: (tabId: string) => void;
  onCopied: (rfqId: string) => void;
}

type TabTitleProps = TabTitleOwnProps & TabTitleStateProps & TabTitleDispatchProps;

export const TabTitleStyled = (props: TabTitleProps) => {
  const {
    tabId,
    active,
    infoBadgeValue,
    isBadgeDisplayed,
    isFeatureToggleEnabled,
    isSecondaryRfq,
    tabsCount,
  } = props;
  const isDisplayedWithToolTip = !!(isBadgeDisplayed && infoBadgeValue?.badgeToolTipText);
  return (
    <li
      className={`${styles['neos-nav-item']} `}
      onMouseUp={ev =>
        ev.button === 1 && !props.isLoading && tabId !== 'BLOTTER' && props.onTabClosed(tabId.rfqId)
      }
      onClick={() => (!props.isPinned || tabId !== 'BLOTTER') && props.onTabSelectionChanged(tabId)}
      data-e2e="tab-title"
    >
      <a className={`${styles['neos-nav-link']} ${cn({ active })} `}>
        <div className={`${styles['top-container']} `}>
          <div className={`${styles['tab-left-column']} `}>
            <i
              className={cn(
                { invisible: !props.isAlertIconDisplayed },
                'icon icon-sm ms-1 text-info hidden-icon-not-hovered',
              )}
            >
              notifications
            </i>
            {tabId !== 'BLOTTER' && isFeatureToggleEnabled && isSecondaryRfq && (
              <TabTitleBadge
                infoBadgeValue={{
                  badgeText: '2nd',
                  badgeColorClassName: 'bg-info',
                  badgeToolTipText: 'Secondary RFQ',
                }}
                isToolTipPresent={true}
              />
            )}
            {isBadgeDisplayed && infoBadgeValue && (
              <TabTitleBadge
                infoBadgeValue={infoBadgeValue}
                isToolTipPresent={isDisplayedWithToolTip}
              />
            )}
          </div>
          <div data-e2e="neos-tab-title" className="m-auto">
            {parseLabel(props.label)}
          </div>
          <div className={`${styles['tab-right-column']} `}>
            {props.tabId === 'BLOTTER' && (
              <div
                style={tabsCount === 1 ? { cursor: 'not-allowed' } : undefined}
                title={tabsCount === 1 ? 'Need at least 2 tabs' : undefined}
              >
                <NeosTooltip
                  trigger="click"
                  placement="right"
                  overlay={
                    <Popover id="popover-basic" style={{ minWidth: 'unset' }}>
                      <BlotterPinButtons />
                    </Popover>
                  }
                  rootClose
                >
                  <button
                    className="btn btn-icon btn-flat-primary btn-sm"
                    onClick={e => e.stopPropagation()}
                    data-e2e="neos-tab-pin-button"
                    disabled={tabsCount === 1}
                  >
                    <em
                      className="icon icon-sm"
                      style={{
                        transform: 'rotate(45deg) translateY(2px)',
                        opacity: props.isPinned ? 1 : 0.5,
                      }}
                    >
                      push_pin
                    </em>
                  </button>
                </NeosTooltip>
              </div>
            )}
            {tabId !== 'BLOTTER' && !props.isLoading && (
              <>
                <SimpleNeosTooltip placement="right" id={'close-rfq'} message={'Close RFQ'}>
                  <button
                    className="btn btn-sm btn-flat-primary btn-icon"
                    onClick={e => {
                      props.onTabClosed(tabId.rfqId);
                      e.stopPropagation();
                    }}
                    data-e2e="neos-tab-close-button"
                  >
                    <i className="icon hidden-icon-not-hovered">clear</i>
                  </button>
                </SimpleNeosTooltip>
                <SimpleNeosTooltip placement="right" id={'copyUuid'} message={'Copy RFQ ID'}>
                  <button
                    className="btn btn-sm btn-flat-primary btn-icon mb-1"
                    onClick={e => {
                      props.onCopied(tabId.rfqId);
                      e.stopPropagation();
                    }}
                    data-e2e="tab-copy-button"
                  >
                    <i className="icon hidden-icon-not-hovered icon-xs icon-outlined">
                      content_copy
                    </i>
                  </button>
                </SimpleNeosTooltip>
              </>
            )}
          </div>
        </div>
        {props.isLoading && props.active && (
          <div className={`${styles['loading']} `} data-e2e="neos-rfq-loading-progress-bar" />
        )}
      </a>
    </li>
  );
};

function parseLabel(label: LabelLine[]) {
  return (
    <div className="d-flex flex-column flex-center">
      {label.map(({ text, className }, i) => {
        return (
          <span key={i} className={className}>
            {text}
          </span>
        );
      })}
    </div>
  );
}
