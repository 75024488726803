import type { Thunk } from '@/bootstrap/thunks';
import type { ChainOptions } from '@/neos/business/neosActions';
import { flatMap } from 'lodash';
import type { ReferenceKey } from '../models';

export function createChangeReferenceLevelThunk(
  referenceKey: ReferenceKey,
  refLevel: number | undefined,
): Thunk {
  return function changeReferenceLevelThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { createLevelChangedAction },
      },
      thunks: {
        neos: { createRfqResetQuotesWithGivenUnderlyingThunk, createRequestRfqFairPricesThunk },
      },
    },
  ) {
    const state = getState();

    const { strategyIds } = selectors.getRfqData(state, referenceKey.rfqId);
    const rfqStrategies = strategyIds.map(strategyId =>
      selectors.getStrategyData(state, strategyId),
    );
    const priceUnitTypes = flatMap(
      rfqStrategies.map(({ quoteId }) => {
        const quote = selectors.getQuote(state, quoteId);
        return quote.traderAskType || quote.traderBidType;
      }),
    );

    if (priceUnitTypes.includes('CCY')) {
      dispatch(createRfqResetQuotesWithGivenUnderlyingThunk(referenceKey));
    }
    const chainOptions: ChainOptions = {
      success: {
        dispatchables: [createRequestRfqFairPricesThunk(referenceKey.rfqId)],
      },
    };

    const hasAlreadyFairPricesInRfq = selectors.hasRfqFairPrices(
      state,
      referenceKey.rfqId,
      selectors,
    );

    dispatch(
      createLevelChangedAction(
        referenceKey,
        refLevel,
        hasAlreadyFairPricesInRfq ? chainOptions : undefined,
      ),
    );
  };
}
