import type { ThunkEpic } from '@/bootstrap/epics';
import { type Thunks, thunks } from '@/bootstrap/thunks';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { ofType } from 'redux-observable';
import type { Observable } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { createSearchByBookingIdApi } from '../blotter/blotterApi';
import type { OnyxError } from '../mappers/error';
import type { OnyxTransaction } from '../neosOnyxModel';

export interface SearchByBookingIdApi {
  loadRfqs: (bookingId: string) => Observable<OnyxTransaction[]>;
}

export function getSearchByBookingIdEpic(http: SgmeHttp): ThunkEpic {
  const api = createSearchByBookingIdApi(http);
  return createSearchByBookingIdEpic(api, thunks);
}

export function createSearchByBookingIdEpic(
  api: SearchByBookingIdApi,
  {
    createErrorToasterThunk,
    createInfoToasterThunk,
    neos: { createLoadTransactionsThunk },
  }: Thunks,
): ThunkEpic {
  return action$ =>
    action$.pipe(
      ofType('REQUEST_SEARCH_BY_BOOKING_ID'),
      switchMap(({ bookingId }) => {
        const apiObservable$ = api.loadRfqs(bookingId);

        return apiObservable$.pipe(
          mergeMap(transactions => {
            if (!transactions.length) {
              return [
                createInfoToasterThunk({
                  message: 'No Rfq with this booking id found',
                }),
              ];
            }
            return [createLoadTransactionsThunk(transactions, true)];
          }),
          catchError((error: { response: OnyxError | undefined }) => {
            return [
              createErrorToasterThunk(
                {
                  message:
                    error?.response?.content ??
                    'An error occured while searching for the rfq with booking id',
                },
                error?.response,
              ),
            ];
          }),
        );
      }),
    );
}
