import { convertNullToUndefined } from '@/util/undefinedAndNull/convertNullToUndefined';
import type { OnyxProductFeaturesFields, OnyxSwapCurrency } from '../../strategyOnyxModel';
import type { SwapCurrencyFeature } from '../featureModel';

import { hasDiscriminator } from '@/neos/business/rfq/strategy/feature/hasDiscriminator';

export function mapFromOnyxFeatureOrExtraFeaturesSwapCurrency(
  { extraFeatures }: OnyxProductFeaturesFields,
  strategyId: string,
): SwapCurrencyFeature | undefined {
  const swapCurrencyFeature: OnyxSwapCurrency | undefined = extraFeatures?.find(
    hasDiscriminator<OnyxSwapCurrency>('SWAP_CURRENCY'),
  );

  if (swapCurrencyFeature) {
    return {
      type: 'SWAP_CURRENCY',
      strategyId,
      swapCurrency: convertNullToUndefined(swapCurrencyFeature.swapCurrency),
    };
  }

  return undefined;
}

export function mapToOnyxSwapCurrency(feature: SwapCurrencyFeature): OnyxProductFeaturesFields {
  const { swapCurrency } = feature;

  return {
    extraFeatures: [
      {
        discriminator: 'SWAP_CURRENCY',
        swapCurrency: swapCurrency === undefined ? null : swapCurrency,
      },
    ],
  };
}
