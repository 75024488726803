import type { Thunk } from '@/bootstrap/thunks';
import type { PredealCheck, PredealCheckUiColor } from '../predealCheckModel';

export function createShowPredealChecksModalThunks(rfqId: string): Thunk {
  return function requestNeosPredealCheckRefreshThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { rfqUiCrudActions },
      },
    },
  ) {
    const state = getState();

    const predealChecks = selectors.getNeosPredealChecks(state, rfqId, selectors);

    // failing pdc predicate
    const isThereFailingPdc = ({ uiColor }: PredealCheck): boolean => {
      const failingColor: PredealCheckUiColor[] = [
        'AMBER',
        'AMBER_BLUR',
        'RED',
        'RED_DEROGATED',
        'RED_BLUR',
      ];
      return failingColor.includes(uiColor);
    };

    const isThereFailingMarketPdc = !!predealChecks
      .filter(({ level }) => level !== 'DELTA')
      .find(isThereFailingPdc);

    const isThereFailingDeltaPdc = !!predealChecks
      .filter(({ level }) => level === 'DELTA')
      .find(isThereFailingPdc);

    dispatch(
      rfqUiCrudActions.update(rfqId, {
        areMarketPredealChecksCollapsed: !isThereFailingMarketPdc,
        areDeltaPredealChecksCollapsed: !isThereFailingDeltaPdc,
        showPredealChecksModal: true,
      }),
    );
  };
}
