import type { LegSecondaryEventCreation } from '@/neos/business/legSecondaryEventCreation/legSecondaryEventCreationModel';
import { type FC, Fragment } from 'react';

import { ElsBasketSecondaryWidget } from '@/neos/components/rfq/strategies/globalActions/secondaryEventWidget/widget/ElsBasketSecondaryWidget';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import { InitNotEligibleIcon } from '../../shared/SecondaryTypeCellsCommon';
import { LegSecondaryToggleButtons } from './LegSecondaryToggleButtons';
import type { LegSecondaryTypeWidgetModel } from './getLegSecondaryTypeWidgetModel';

export interface LegSecondaryTypeWidgetOwnProps {
  rfqId: string;
  strategyId: string;
}

export type LegSecondaryTypeWidgetStateProps = LegSecondaryTypeWidgetModel;

export interface LegSecondaryTypeWidgetDispatchProps {
  onLegSecondaryTypeChanged(legId: string, eventType: LegSecondaryEventCreation['eventType']): void;

  onLegSecondaryTypeLongClicked(eventType: LegSecondaryEventCreation['eventType']): void;
}

type LegSecondaryTypeWidgetProps = LegSecondaryTypeWidgetOwnProps &
  LegSecondaryTypeWidgetStateProps &
  LegSecondaryTypeWidgetDispatchProps;

//leg level secondary
//action button
export const LegSecondaryTypeWidgetComponent: FC<LegSecondaryTypeWidgetProps> = props => {
  function handleClick({
    isLongClick,
    legId,
    eventType,
  }: {
    isLongClick: boolean;
    legId: string;
    eventType: LegSecondaryEventCreation['eventType'];
  }) {
    if (isLongClick) {
      props.onLegSecondaryTypeLongClicked(eventType);
    } else {
      props.onLegSecondaryTypeChanged(legId, eventType);
    }
  }

  return (
    <section>
      {props.legsSecondaryTypes.map(st => {
        if (!st.isEligible) {
          return <InitNotEligibleIcon legId={st.legId} key={`ini-not-eligible-icon-${st.legId}`} />;
        }

        return (
          <Fragment key={`button-group-${st.legId}`}>
            {st.isElsBasket ? (
              <ElsBasketSecondaryWidget key={`button-group-${st.legId}`} />
            ) : (
              <SimpleNeosTooltip
                disable={st.isLegMaturityValid}
                id="secondary-type-button-group"
                message={'Impossible to unwind a leg with Maturity in the past'}
              >
                <LegSecondaryToggleButtons
                  isLoading={props.isLoading}
                  handleClick={handleClick}
                  secondaryTypeField={st}
                />
              </SimpleNeosTooltip>
            )}
          </Fragment>
        );
      })}
    </section>
  );
};
