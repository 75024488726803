import type { Dispatchable, Thunk } from '@/bootstrap/thunks';

export function createApplyToSelectedStrategyThunk(
  rfqId: string,
  dispatchableCreator: (selectedStrategyId: string) => Dispatchable,
): Thunk {
  return function applyToSelectedStrategyThunk(dispatch, getState, { selectors }) {
    const state = getState();
    const selectedStrategyId = selectors.getSelectedStrategyId(state.ui.selectedStrategy, rfqId);
    const dispatchable = dispatchableCreator(selectedStrategyId);
    dispatch(dispatchable);
  };
}
