import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { Observable } from 'rxjs';
import type { NotificationSubscriptionApi } from '../epics/notificationSubscriptionEpic';
import type {
  NotificationSubscriptionRequest,
  NotificationSubscriptionResponse,
} from '../neosModel';

export const createNotificationSubscriptionApi = (http: SgmeHttp): NotificationSubscriptionApi => ({
  subscribeToNotification(
    notificationSubscriptionRequests: NotificationSubscriptionRequest[],
  ): Observable<NotificationSubscriptionResponse[]> {
    return http.post<NotificationSubscriptionResponse[]>({
      url: 'api/neos/notification/subscribe',
      body: notificationSubscriptionRequests,
    });
  },
});
