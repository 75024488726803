import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { FairPrice } from '../../../../../../../neos/business/neosModel';

export interface VolBidAsk {
  volBid?: number;
  volBidUnit?: string;
  volAsk?: number;
  volAskUnit?: string;
}

export interface FairPricesModel {
  isMasterStrategy: boolean;
  legsFairPrices: FairPrice[];
  strategyFairPrice: FairPrice;
  isOtherFairGreeksDisplayed: boolean;
  isUsualFairPricesDisplayed: boolean;
  isVolBidAskPricesDisplayed: boolean;
  displayEmptyFirstLeg: boolean;
  strategyVolBidAsk: VolBidAsk;
  legsVolBidAsk: VolBidAsk[];
  areAllStrategiesFutureLike: boolean;
}

export function getFairPricesModel(
  appState: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): FairPricesModel {
  const {
    fairPriceId: strategyFairPriceId,
    isMasterStrategy,
    quoteId: strategyQuoteId,
  } = selectors.getStrategyData(appState, strategyId);

  const { displayReadonlyCellsForCompositionLeg, displayedPricesLegIds: legIds } =
    selectors.getIdsOfLegsWithDisplayedPrices(appState, rfqId, strategyId, selectors);

  const strategyFairPrice: FairPrice =
    (strategyFairPriceId && selectors.getFairPrice(appState, strategyFairPriceId)) ||
    ({} as FairPrice);

  const legsFairPrices: FairPrice[] = legIds.map(legId => {
    const { fairPriceId } = selectors.getLegData(appState, legId);
    return (fairPriceId && selectors.getFairPrice(appState, fairPriceId)) || ({} as FairPrice);
  });

  const {
    traderBidVolatility,
    traderBidVolatilityUnit,
    traderAskVolatility,
    traderAskVolatilityUnit,
  } = selectors.getQuote(appState, strategyQuoteId);
  const strategyVolBidAsk: VolBidAsk = {
    volAsk: traderAskVolatility,
    volAskUnit: traderAskVolatilityUnit,
    volBid: traderBidVolatility,
    volBidUnit: traderBidVolatilityUnit,
  };

  const legsVolBidAsk: VolBidAsk[] = legIds.map(legId => {
    const { quoteId } = selectors.getLegData(appState, legId);
    const {
      traderBidVolatility,
      traderBidVolatilityUnit,
      traderAskVolatility,
      traderAskVolatilityUnit,
    } = selectors.getQuote(appState, quoteId);
    return {
      volAsk: traderAskVolatility,
      volAskUnit: traderAskVolatilityUnit,
      volBid: traderBidVolatility,
      volBidUnit: traderBidVolatilityUnit,
    };
  });

  const { fairGreeksToggle, fairPricesToggle, volBidAskToggle } = appState.ui.userPreferences;

  return {
    isMasterStrategy,
    legsFairPrices,
    strategyFairPrice,
    isOtherFairGreeksDisplayed: selectors.isOtherFairGreeksDisplayed(
      fairGreeksToggle,
      appState.ui,
      rfqId,
      selectors,
    ),
    displayEmptyFirstLeg: displayReadonlyCellsForCompositionLeg,
    isUsualFairPricesDisplayed: selectors.isUsualFairPricesDisplayed(
      fairPricesToggle,
      appState.ui,
      rfqId,
      selectors,
    ),
    isVolBidAskPricesDisplayed: selectors.isVolBidAskDisplayed(
      volBidAskToggle,
      appState.ui,
      rfqId,
      selectors,
    ),
    areAllStrategiesFutureLike: selectors.areRfqStrategiesFutureLike(appState, rfqId, selectors),
    strategyVolBidAsk,
    legsVolBidAsk,
  };
}
