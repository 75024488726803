import type { PriceUnitType } from '@/neos/business/neosModel';
import type {
  BookingId,
  OnyxCounterparty,
  PriceWithUnit,
  SecondaryInfo,
  SizeType,
  StepInInfo,
} from '@/neos/business/neosOnyxModel';
import type { UpdateJustificationLabel } from '@/neos/business/rfq/strategy/leg/otcAllocation/updateJustificationModel';

export const otcAllocationsIaCurrencies = ['%', 'EUR', 'USD', 'GBP'];

export interface OtcAllocationKey {
  rfqId: string;
  legId: string;
  allocationId: string;
}

export interface AbstractConfirmationMedia {
  eligibilityReason: string | undefined;
}

export interface MarkitConfirmationMedia extends AbstractConfirmationMedia {
  media: 'MARKITWIRE';
  markitWireMode: 'AUTO' | 'MANUAL';
  electronicMediaUser: ElectronicMediaUser | undefined;
  markitWireReferenceId?: BookingId;
}

export interface ElectronicMediaUser {
  id: string;
  type: 'USER' | 'GROUP';
}

export interface PaperConfirmationMedia extends AbstractConfirmationMedia {
  media: 'LONG_FORM' | 'SHORT_FORM' | 'UNKNOWN';
  forcedReason?:
    | 'SG_BOOKING_SPECIFICS'
    | 'SG_OTHER_REASON'
    | 'CTP_NOT_READY_ON_MW_ELS_FORWARD'
    | 'CTP_NOT_READY_ON_MW_EMERGENCY_MCA'
    | 'CTP_OTHER_REASON';
}

export type IATypeValuesType = Exclude<
  PriceUnitType,
  'BASIS' | 'VOL_POINT' | 'VAR_UNIT' | 'CENTS' | 'BIPS'
>;
export const IATypeValues: Array<IATypeValuesType> = ['MULTIPLIER', 'REF_PERCENT', 'CCY'];

export const IATypeLabels: Record<IATypeValuesType, string> = {
  CCY: 'CCY',
  REF_PERCENT: '%',
  MULTIPLIER: 'Vega Multi.',
};

export type ConfirmationMedia = {
  eligibleMedia?: 'MARKITWIRE' | 'LONG_FORM' | 'SHORT_FORM' | 'UNKNOWN';
  eligibilityReason?: string;
} & ({ media: undefined } | MarkitConfirmationMedia | PaperConfirmationMedia);

export type OtcAllocation = {
  uuid: string;
  legId: string;
  isCancelled: boolean;
  counterpartId: number | undefined;
  quantity?: number;
  notional?: PriceWithUnit;
  varUnit?: PriceWithUnit;
  sizeType?: SizeType;
  independantAmountValue: number | undefined;
  independantAmountType: IATypeValuesType | undefined;
  independantAmountUnit: string | undefined;
  independantAmountCurrencyValue: number | undefined;
  independantAmountCurrencyUnit?: string;
  independantAmountValueDate?: string;
  broker: OnyxCounterparty | undefined;
  mcaEligible?: boolean;
  secondaryInfo?: SecondaryInfo;
  stepInInfo?: StepInInfo;
  externalReferenceId?: {
    application: 'XONE';
    id: string | undefined;
  };
  salesCreditReferenceId?: {
    application: string | undefined;
    id: string | undefined;
  };
  markitWireEligible: boolean | undefined;
  updateJustificationLabel: UpdateJustificationLabel | undefined;
} & ConfirmationMedia;

export const otcAllocMediaOptions: Record<NonNullable<OtcAllocation['media']>, string> = {
  MARKITWIRE: 'Markitwire',
  SHORT_FORM: 'Short form',
  LONG_FORM: 'Long form',
  UNKNOWN: '',
};

export const otcAllocForcedReasonOptions: Record<
  NonNullable<PaperConfirmationMedia['forcedReason']>,
  string
> = {
  SG_BOOKING_SPECIFICS: 'SG Booking specifics',
  SG_OTHER_REASON: 'SG other reason',
  CTP_NOT_READY_ON_MW_ELS_FORWARD: 'CTP not ready on MW (ELS Forward)',
  CTP_NOT_READY_ON_MW_EMERGENCY_MCA: 'CTP not ready on MW (Emerging MCA)',
  CTP_OTHER_REASON: 'CTP other reason',
};

export function shouldShowForcedReason(
  alloc: Pick<OtcAllocation, 'markitWireEligible' | 'media'>,
): boolean {
  return alloc.markitWireEligible === true && alloc.media !== 'MARKITWIRE';
}
