import { MultiToggleButton } from '@/neos/components/share/multiToggleButton/MultiToggleButton';
import { formatFromCamelToStartCase } from '@/util/format';
import type { FC } from 'react';
import type { OptionType } from '../../../../../../../neos/business/neosModel';
import type { OptionTypeCellsModel } from './getOptionTypeCellsModel';

export interface OptionTypeCellsOwnProps {
  rfqId: string;
  strategyId: string;
}

export type OptionTypeCellsMapStateProps = OptionTypeCellsModel;

export interface OptionTypeCellsDispatchProps {
  onOptionTypeChanged(productId: string, optionType: OptionType | undefined): void;
}

type OptionTypeCellsProps = OptionTypeCellsOwnProps &
  OptionTypeCellsMapStateProps &
  OptionTypeCellsDispatchProps;

export const OptionTypeCells: FC<OptionTypeCellsProps> = props => {
  return (
    <section className="d-flex flex-column">
      {props.optionTypes.map(({ id, value, isEditable }) => (
        <MultiToggleButton<OptionType | undefined>
          className="form-control"
          key={id}
          readOnly={!isEditable}
          selectedValue={value}
          valueFormatter={type => (type ? formatFromCamelToStartCase(type) : '')}
          availableValues={props.availableTypes}
          onValueChange={newType => props.onOptionTypeChanged(id, newType)}
        />
      ))}
    </section>
  );
};
