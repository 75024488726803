import { thunks } from '@/bootstrap/thunks';
import type { ElsBasket } from '@/neos/business/rfq/strategy/leg/product/productModel';
import { useDispatch } from 'react-redux';

type ElsBasketExportProps = {
  rfqId: string;
  product: ElsBasket;
};

export const ElsBasketExport = ({ rfqId, product }: ElsBasketExportProps) => {
  const dispatch = useDispatch();
  const isButtonDisabled = product.basketUnderlying.basketComposition.length === 0;

  return (
    <button
      data-e2e="els-basket-export"
      className={`btn btn-discreet-primary sgbs-btn-primary ${isButtonDisabled && 'disabled'}`}
      onClick={() => {
        if (!isButtonDisabled) {
          dispatch(thunks.neos.createExportElsBasketCompositionThunk(rfqId, product.uuid));
        }
      }}
      disabled={isButtonDisabled}
    >
      Export <i className="icon icon-sm ms-2">file_download</i>
    </button>
  );
};
