import { exportDataToExcel } from '@/util/excel/excel';
import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type {
  LegPeriod,
  LegPeriodDates,
} from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel';
import { useSelector } from 'react-redux';

type ScheduleExportProps = {
  strategyId: string;
};

export const CLSScheduleExport = ({ strategyId }: ScheduleExportProps) => {
  const rateData = useSelector((state: AppState) =>
    selectors.selectScheduleRateLegDates(state, strategyId, selectors),
  );

  function transformDataForExport() {
    const maxLength = rateData?.length ?? 0;

    const exportedData = [];
    for (let index = 0; index < maxLength; index++) {
      const newLine = {
        'Rate Fixing Date': getCellValue(index, rateData, 'fixingDate'),
        'Rate Start Date': getCellValue(index, rateData, 'startDate'),
        'Rate End Date': getCellValue(index, rateData, 'endDate'),
        'Rate Pay Date': getCellValue(index, rateData, 'paymentDate'),
      };
      exportedData.push(newLine);
    }
    return exportedData;

    function getCellValue<T extends LegPeriodDates>(
      index: number,
      data: LegPeriod<T>[] | undefined,
      key: keyof T,
    ) {
      return data && data.length > index ? data[index].dates[key] : '';
    }
  }

  const isButtonDisabled = !rateData?.length;

  return (
    <button
      data-e2e="cls-schedule-export"
      data-testid="cls-schedule-export"
      className={`btn btn-discreet-primary ${isButtonDisabled && 'disabled'}`}
      onClick={() =>
        !isButtonDisabled && exportDataToExcel(transformDataForExport(), 'Cls Schedule Period')
      }
      disabled={isButtonDisabled}
      aria-label="schedule_export"
    >
      Export <i className="icon icon-sm ms-2">file_download</i>
    </button>
  );
};
