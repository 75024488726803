import { sentenceToKebabCase } from '@/util/string/stringUtils';
import type { FC } from 'react';
import type { SwitcherFeatureType } from '../../../../../../../business/neosModel';
import { Switch } from '../../../../../../share/switch/Switch';

export type Toggle = 'ON' | 'OFF';

export interface OwnProps {
  strategyId: string;
  type: SwitcherFeatureType;
}

export interface StateProps {
  type: SwitcherFeatureType;
  label: string;
  visible: boolean;
}

export interface DispatchProps {
  handler: (toggle: Toggle) => void;
}

type Props = OwnProps & StateProps & DispatchProps;

export const FeatureSwitcher: FC<Props> = ({ label, visible, handler }: Props) => {
  function toggleVisibility(): void {
    handler(visible ? 'OFF' : 'ON');
  }

  return (
    <div className="row py-2" key={label}>
      <div className="col-5">
        <label>{label}</label>
      </div>
      <div className="col-4">
        <Switch
          checked={visible}
          onChange={toggleVisibility}
          e2eId={`extra-features-switcher-${sentenceToKebabCase(label)}`}
        />
      </div>
    </div>
  );
};
