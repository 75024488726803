import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { NeosBookingId } from '@/neos/components/share/NeosBookingId/NeosBookingId';

export function NovationFeeId({ allocationId, rfqId }: { allocationId: string; rfqId: string }) {
  const bookingStep = useAppSelector(state =>
    selectors.getAllocationBookingStep(state, rfqId, allocationId),
  );
  const bookingId = bookingStep?.novationFeeId;
  return (
    <NeosBookingId
      onBookingIdChanged={null}
      bookingId={bookingId}
      dataE2e="otc-allocation-novation-fee-booking-id"
      isReadOnly
      overlayTriggerPlacement="top"
    />
  );
}
