const aladinTwoWays = '/assets/images/mdp/aladin-2ways-logo.png';
const aladinBuyBlack = '/assets/images/mdp/aladin-buy-black-logo.png';
const aladinBuy = '/assets/images/mdp/aladin-buy-logo.png';
const aladinSellBlack = '/assets/images/mdp/aladin-sell-black-logo.png';
const aladinSell = '/assets/images/mdp/aladin-sell-logo.png';
const bfamTwoWays = '/assets/images/mdp/bfam-2ways-logo.png';
const bfamBuyBlack = '/assets/images/mdp/bfam-buy-black-logo.png';
const bfamBuy = '/assets/images/mdp/bfam-buy-logo.png';
const bfamSellBlack = '/assets/images/mdp/bfam-sell-black-logo.png';
const bfamSell = '/assets/images/mdp/bfam-sell-logo.png';
const bloombergTwoWays = '/assets/images/mdp/bloomberg-2ways-logo.png';
const bloombergBuyBlack = '/assets/images/mdp/bloomberg-buy-black-logo.png';
const bloombergBuy = '/assets/images/mdp/bloomberg-buy-logo.png';
const bloombergSellBlack = '/assets/images/mdp/bloomberg-sell-black-logo.png';
const bloombergSell = '/assets/images/mdp/bloomberg-sell-logo.png';
const aladinImgTwoWays = '/assets/images/mdp/imgs/aladin-2ways-img.png';
const aladinImgBuyBlack = '/assets/images/mdp/imgs/aladin-buy-black-img.png';
const aladinImgBuy = '/assets/images/mdp/imgs/aladin-buy-img.png';
const aladinImgSellBlack = '/assets/images/mdp/imgs/aladin-sell-black-img.png';
const aladinImgSell = '/assets/images/mdp/imgs/aladin-sell-img.png';
const bfamImgTwoWays = '/assets/images/mdp/imgs/bfam-2ways-img.png';
const bfamImgBuyBlack = '/assets/images/mdp/imgs/bfam-buy-black-img.png';
const bfamImgBuy = '/assets/images/mdp/imgs/bfam-buy-img.png';
const bfamImgSellBlack = '/assets/images/mdp/imgs/bfam-sell-black-img.png';
const bfamImgSell = '/assets/images/mdp/imgs/bfam-sell-img.png';
const bloombergImgTwoWays = '/assets/images/mdp/imgs/bloomberg-2ways-img.png';
const bloombergImgBuyBlack = '/assets/images/mdp/imgs/bloomberg-buy-black-img.png';
const bloombergImgBuy = '/assets/images/mdp/imgs/bloomberg-buy-img.png';
const bloombergImgSellBlack = '/assets/images/mdp/imgs/bloomberg-sell-black-img.png';
const bloombergImgSell = '/assets/images/mdp/imgs/bloomberg-sell-img.png';
const rfqHubImgTwoWays = '/assets/images/mdp/imgs/rfqHub-2ways-img.png';
const rfqHubImgBuyBlack = '/assets/images/mdp/imgs/rfqHub-buy-black-img.png';
const rfqHubImgBuy = '/assets/images/mdp/imgs/rfqHub-buy-img.png';
const rfqHubImgSellBlack = '/assets/images/mdp/imgs/rfqHub-sell-black-img.png';
const rfqHubImgSell = '/assets/images/mdp/imgs/rfqHub-sell-img.png';
const tradeWebImgTwoWays = '/assets/images/mdp/imgs/tradeweb-2ways-img.png';
const tradeWebImgBuyBlack = '/assets/images/mdp/imgs/tradeweb-buy-black-img.png';
const tradeWebImgBuy = '/assets/images/mdp/imgs/tradeweb-buy-img.png';
const tradeWebImgSellBlack = '/assets/images/mdp/imgs/tradeweb-sell-black-img.png';
const tradeWebImgSell = '/assets/images/mdp/imgs/tradeweb-sell-img.png';
const rfqHubTwoWays = '/assets/images/mdp/rfqHub-2ways-logo.png';
const rfqHubBuyBlack = '/assets/images/mdp/rfqHub-buy-black-logo.png';
const rfqHubBuy = '/assets/images/mdp/rfqHub-buy-logo.png';
const rfqHubSellBlack = '/assets/images/mdp/rfqHub-sell-black-logo.png';
const rfqHubSell = '/assets/images/mdp/rfqHub-sell-logo.png';
const tradeWebTwoWays = '/assets/images/mdp/tradeweb-2ways-logo.png';
const tradeWebBuyBlack = '/assets/images/mdp/tradeweb-buy-black-logo.png';
const tradeWebBuy = '/assets/images/mdp/tradeweb-buy-logo.png';
const tradeWebSellBlack = '/assets/images/mdp/tradeweb-sell-black-logo.png';
const tradeWebSell = '/assets/images/mdp/tradeweb-sell-logo.png';
import type { Way } from '../neosModel';
import { type Mdp, isBiddingQisLikeSource, isVoiceLikeSource } from '../neosOnyxModel';
import { orderOf } from '../services/statusOrder';
import type { OnyxAlert } from './alertOnyxModel';

export function getAlertAssets({
  source,
  clientWay,
  rfqStatus,
}: OnyxAlert): NotificationAsset | undefined {
  const isBeforeOrderAccepted = rfqStatus ? orderOf(rfqStatus).isBefore('PRICE_PROPOSED') : false;

  const clientWayClean = clientWay ?? 'TWO_WAYS';

  const way: assetWay =
    clientWayClean !== 'TWO_WAYS' && isBeforeOrderAccepted
      ? `${clientWayClean}_BLACK`
      : clientWayClean;

  return Object.keys(mdpMapper).includes(source) &&
    !isVoiceLikeSource(source) &&
    !isBiddingQisLikeSource(source)
    ? mdpMapper[source][way]
    : undefined;
}

type BlackAssetWayObj = { [K in Exclude<Way, 'TWO_WAYS'>]: `${K}_BLACK` };
type BlackAssetWay = BlackAssetWayObj[keyof BlackAssetWayObj];

type assetWay = Way | BlackAssetWay;

export interface NotificationAsset {
  image: string;
  icon: string;
}

type mapResult = { [P in assetWay]: NotificationAsset };

const mdpMapper: Record<Mdp, mapResult> = {
  FIX_ALADDIN: {
    BUY: { image: aladinImgBuy, icon: aladinBuy },
    SELL: { image: aladinImgSell, icon: aladinSell },
    TWO_WAYS: { image: aladinImgTwoWays, icon: aladinTwoWays },
    BUY_BLACK: { image: aladinImgBuyBlack, icon: aladinBuyBlack },
    SELL_BLACK: { image: aladinImgSellBlack, icon: aladinSellBlack },
  },
  FIX_TRADEWEB: {
    BUY: { image: tradeWebImgBuy, icon: tradeWebBuy },
    SELL: { image: tradeWebImgSell, icon: tradeWebSell },
    TWO_WAYS: { image: tradeWebImgTwoWays, icon: tradeWebTwoWays },
    BUY_BLACK: { image: tradeWebImgBuyBlack, icon: tradeWebBuyBlack },
    SELL_BLACK: { image: tradeWebImgSellBlack, icon: tradeWebSellBlack },
  },
  FIX_RFQHUB: {
    BUY: { image: rfqHubImgBuy, icon: rfqHubBuy },
    SELL: { image: rfqHubImgSell, icon: rfqHubSell },
    TWO_WAYS: { image: rfqHubImgTwoWays, icon: rfqHubTwoWays },
    BUY_BLACK: { image: rfqHubImgBuyBlack, icon: rfqHubBuyBlack },
    SELL_BLACK: { image: rfqHubImgSellBlack, icon: rfqHubSellBlack },
  },
  FIX_BLOOMBERG: {
    BUY: { image: bloombergImgBuy, icon: bloombergBuy },
    SELL: { image: bloombergImgSell, icon: bloombergSell },
    TWO_WAYS: { image: bloombergImgTwoWays, icon: bloombergTwoWays },
    BUY_BLACK: { image: bloombergImgBuyBlack, icon: bloombergBuyBlack },
    SELL_BLACK: { image: bloombergImgSellBlack, icon: bloombergSellBlack },
  },
  BOT_SYMPHONY_BFAM: {
    BUY: { image: bfamImgBuy, icon: bfamBuy },
    SELL: { image: bfamImgSell, icon: bfamSell },
    TWO_WAYS: { image: bfamImgTwoWays, icon: bfamTwoWays },
    BUY_BLACK: { image: bfamImgBuyBlack, icon: bfamBuyBlack },
    SELL_BLACK: { image: bfamImgSellBlack, icon: bfamSellBlack },
  },
};
