import type { Thunk } from '@/bootstrap/thunks';
import type { NegotiationMode } from '../strategyModel';

export function createChangeStrategyNegotiationModeThunk(
  strategyId: string,
  negotiationMode: NegotiationMode,
): Thunk {
  return function changeStrategyNegotiationMode(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { createLegProductsNegotiationModeUpdatedAction },
      },
      thunks: {
        neos: { createDefaultProductLotSizeMarketThunk, createRemoveStrategyFeaturesThunk },
      },
    },
  ) {
    const state = getState();
    const { strategyType } = selectors.getStrategyData(state, strategyId);
    const { readOnlyProductNegotiationMode } = selectors.getStrategyDefinition(
      state.referenceData,
      strategyType,
    );
    if (readOnlyProductNegotiationMode) {
      return;
    }

    const productIds = selectors.getStrategyProductIds(state, strategyId, selectors);
    dispatch(
      createLegProductsNegotiationModeUpdatedAction(productIds, negotiationMode),
      createDefaultProductLotSizeMarketThunk(productIds),
    );
    if (negotiationMode === 'LISTED') {
      dispatch(createRemoveStrategyFeaturesThunk(strategyId));
    }
  };
}
