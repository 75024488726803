import { round } from 'lodash';

export function applyMultiplierWithPrecision(
  value: number | undefined,
  multiplier: number = 1,
  precision: number = 2,
): number | undefined {
  if (value === undefined) {
    return value;
  }

  return round(value * multiplier * 10 ** precision) / 10 ** precision;
}
export function applyPrecision(value: number, precision: number): number;

export function applyPrecision(value: undefined, precision: number): undefined;

export function applyPrecision(
  value: number | undefined,
  precision: number = 2,
): number | undefined {
  return applyMultiplierWithPrecision(value, 1, precision);
}
