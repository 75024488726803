import { importExcelFileContent } from '@/util/excel/excel';
import type { DisplayNegotiatedSize } from '@/neos/business/ui/strategy/strategyUiModel';
import { type FC, useRef } from 'react';
import type { SizeType } from '../../../../../../../business/neosOnyxModel';

export interface ImportOtcAllocationsOwnProps {
  rfqId: string;
}

export interface OtcLegModel {
  legId: string;
  uiSizeType: DisplayNegotiatedSize;
  onChangeSizeType?: SizeType;
  ccy?: string;
  allocIds: string[];
}

export interface ImportOtcAllocationsStateProps {
  legs: OtcLegModel[];
  clients: number[];
}

export interface ImportOtcAllocationsDispatchProps {
  onImport: (rows: AllocRow[]) => void;
}

export type AllocRow = [string?, string?, string?, string?];

export type ImportOtcAllocationsProps = ImportOtcAllocationsOwnProps &
  ImportOtcAllocationsStateProps &
  ImportOtcAllocationsDispatchProps;

export const ImportOtcAllocationsComponent: FC<ImportOtcAllocationsProps> = props => {
  const uploadInputRef = useRef<HTMLInputElement>(null);

  return (
    <button
      data-e2e="import-otc-allocation"
      className="btn btn-flat-primary"
      onClick={() => uploadInputRef?.current?.click()}
    >
      Import
      <i className="icon icon-sm ms-2">file_upload</i>
      <input
        ref={uploadInputRef}
        type="file"
        id="allocs_import"
        name="allocs_import"
        accept=".xlsx"
        className="d-none"
        onChange={e => importExcelFileContent(e, props.onImport, { withHeader: true })}
      />
    </button>
  );
};
