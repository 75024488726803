import type { Thunk } from '@/bootstrap/thunks';
import { getInitialLayoutOrLayoutFromLS } from '../feature/elsSections/elsSectionsUtils';

export function createInitializeElsSectionsThunk(): Thunk {
  return function initializeElsSectionsThunk(
    dispatch,
    _,
    {
      actionCreators: {
        neos: { upsertElsSections },
      },
    },
  ) {
    const layout = getInitialLayoutOrLayoutFromLS();

    dispatch(upsertElsSections(layout));
  };
}
