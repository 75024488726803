import { mdpSound } from '@/bootstrap/audios/mdpSound';
import { thunks } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { usePricingDeadline } from '@/neos/components/rfq/pricingDeadline/usePricingDeadline';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import { useDispatch } from 'react-redux';
import { CommonTimer, type TimerState } from '../workflow/timers/commonTimer/CommonTimer';
import { PricingDeadlineTimerEditor } from './PricingDeadlineTimerEditor';

export interface PricingDeadlineProps {
  rfqId: string;
}

export const PricingDeadline = ({ rfqId }: PricingDeadlineProps) => {
  const dispatch = useDispatch();
  const {
    remainingTime,
    duration,
    displayTimeZone,
    isDisplayed,
    isTrader,
    isEditable,
    canPlaySound,
    pricingDeadline,
    toggleIsEditable,
  } = usePricingDeadline(rfqId);

  function onPricingDeadlineTimeSelected(expiryDate: Date) {
    const expiry = expiryDate.toISOString();
    dispatch(
      neosActionCreators.rfqDataCrudActions.update(rfqId, {
        rfqPricingExpirationUTCTimestamp: expiry,
      }),
    );
  }

  function onTimerStateChanged(status: TimerState) {
    if (status === 'ENDED' && canPlaySound) {
      mdpSound.play();
    }
    if (status === 'BEFORE_ENDED') {
      dispatch(
        thunks.createInfoToasterThunk({
          message: `Rfq: ${rfqId}  Your pricing time ended in 10 minutes `,
          dismissAfter: undefined,
        }),
      );
    }
  }

  if (!isDisplayed) {
    return null;
  }

  return (
    <div className="d-flex align-items-center">
      <label>Pricing Deadline</label>
      <CommonTimer
        remainingTime={remainingTime}
        duration={duration}
        timerName={''}
        onTimerStateChanged={onTimerStateChanged}
        beforeEndedThresholdInMinutes={10}
      />
      <SimpleNeosTooltip id="pricing-deadline-edit-tooltip" message={'Edit Pricing Deadline timer'}>
        <button
          className="btn btn-primary btn-icon"
          data-e2e="pricing-deadline-edit-toggle"
          data-testid="pricing-deadline-edit-toggle-test-id"
          onClick={toggleIsEditable}
        >
          <i className="icon">{isEditable ? 'chevron_left' : 'chevron_right'}</i>
        </button>
      </SimpleNeosTooltip>
      {isEditable && (
        <PricingDeadlineTimerEditor
          onPricingDeadlineTimeSelected={onPricingDeadlineTimeSelected}
          existingDate={pricingDeadline}
          displayTimeZone={displayTimeZone}
          isReadOnly={isTrader}
        />
      )}
    </div>
  );
};
