import type { Thunk } from '@/bootstrap/thunks';
import type { ActionContainer } from '@/util/actionContainer';
import { range } from 'lodash';
import { batch } from 'react-redux';

export function createRfqChangeStrategyTopThunk(
  rfqId: string,
  strategyId: string,
  top: number | undefined,
): Thunk {
  return function rfqChangeStrategyTopThunk(
    dispatch,
    getState,
    {
      selectors: { getStrategyData },
      actionCreators: {
        neos: { strategyDataCrudActions, createDefaultTopRequestedAction },
      },
      thunks: {
        neos: { createRfqAddLegsThunk, createRfqDeleteLegThunk },
      },
    },
  ) {
    const state = getState();
    const { underlyingId, legIds } = getStrategyData(state, strategyId);

    const targetTop = top ?? 1;
    const actions: ActionContainer[] = [
      strategyDataCrudActions.update(strategyId, { top: targetTop }),
    ];

    batch(() => {
      if (underlyingId) {
        addDefaultingRequest();
      } else {
        adjustCompositionLegsNumber();
      }
      dispatch(actions);
    });

    function addDefaultingRequest() {
      actions.push(createDefaultTopRequestedAction(rfqId, [strategyId]));
    }

    function adjustCompositionLegsNumber() {
      const currentCompositionLegsNumber = legIds.length - 1;
      const legsToAddOrRemoveNumber = targetTop - currentCompositionLegsNumber;

      return legsToAddOrRemoveNumber > 0 ? addMissingLegs() : removeExtraLegs();

      function addMissingLegs() {
        dispatch(createRfqAddLegsThunk(strategyId, legsToAddOrRemoveNumber, 'BEFORE_LAST'));
      }

      function removeExtraLegs() {
        range(-legsToAddOrRemoveNumber).forEach(index => {
          const legToDeleteId = legIds[currentCompositionLegsNumber - 1 - index];
          dispatch(createRfqDeleteLegThunk(legToDeleteId));
        });
      }
    }
  };
}
