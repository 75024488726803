import type { ActionContainer } from '@/util/actionContainer';
import { DefaultingScope } from '../models';
import { DefaultingOptions } from '../rfqActions';
import { createDefaultRfqRequestedAction } from './defaultRfqRequestedAction';

export function createRfqDeltaHedgingRequestedAction(rfqId: string): ActionContainer {
  return createDefaultRfqRequestedAction(
    rfqId,
    new DefaultingOptions({
      overrideScopes: [DefaultingScope.DELTAS, DefaultingScope.UNDERLYING],
    }),
  );
}
