import type { Thunk } from '@/bootstrap/thunks';
import {
  type BasketCompositionDetails,
  type ExecFeesValue,
  isElsBasketProduct,
} from '@/neos/business/rfq/strategy/leg/product/productModel';

export function createCleanBasketCompositionExecFees(
  productId: string,
  execFeeKind: ExecFeesValue,
): Thunk {
  return function deleteKeyInBasketComposition(dispatch, getState, { actionCreators, selectors }) {
    const product = selectors.getProduct(getState(), productId);
    if (!isElsBasketProduct(product)) {
      return;
    }
    const keyToDelete: (keyof BasketCompositionDetails)[] = [];

    switch (execFeeKind) {
      case 'IN_AND_OUT':
        return;
      case 'NONE':
        keyToDelete.push('execFeesOut');
        keyToDelete.push('execFeesIn');
        break;
      case 'OUT':
        keyToDelete.push('execFeesIn');
        break;
      case 'IN':
        keyToDelete.push('execFeesOut');
        break;
    }

    const { basketComposition } = product.basketUnderlying;

    dispatch(
      actionCreators.neos.productCrudActions.update(productId, {
        basketUnderlying: {
          ...product.basketUnderlying,
          basketComposition: getUpdatedBasketComposition(basketComposition, keyToDelete),
        },
      }),
    );
  };
}

function getUpdatedBasketComposition<K extends keyof BasketCompositionDetails>(
  basketComposition: BasketCompositionDetails[],
  keyToDelete: K[],
): BasketCompositionDetails[] {
  return basketComposition.map(basketCompositionDetail => {
    const newBasketCompositionDetail = structuredClone(basketCompositionDetail);

    keyToDelete.forEach(k => {
      delete newBasketCompositionDetail[k];
    });
    return newBasketCompositionDetail;
  });
}
