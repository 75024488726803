import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { isSingleUnderlyingDerivativeProduct } from '../../../../../../neos/business/neosModel';

export function getCapModel(appState: AppState, rfqId: string, selectors: Selectors) {
  const { strategyIds } = selectors.getRfqData(appState, rfqId);
  return strategyIds.filter(strategyId => {
    const product = selectors.getStrategyProduct(appState, strategyId, selectors);

    return (
      isSingleUnderlyingDerivativeProduct(product) &&
      product.underlyingId &&
      selectors.getUnderlyingInfo(appState, product.underlyingId)?.type !== 'STOCK' &&
      (product.subFamily === 'VOL_SWAP' || product.subFamily === 'VAR_SWAP')
    );
  });
}
