import type { OnyxCounterpartId } from '@/neos/business/ui/userPreferences/counterparties/counterpartPreferencesApis';

export interface SearchUserCounterpartAction {
  type: 'USER_PREFERENCES_SEARCH_COUNTERPART';
  entry: string;
}

export function createSearchCounterpartPreferencesAction(
  entry: string,
): SearchUserCounterpartAction {
  return {
    type: 'USER_PREFERENCES_SEARCH_COUNTERPART',
    entry,
  };
}

export interface AddCounterpartPreferencesAction {
  type: 'USER_PREFERENCES_ADD_COUNTERPART';
  counterpartId: OnyxCounterpartId;
}

export function createAddCounterpartPreferencesAction(
  counterpartId: OnyxCounterpartId,
): AddCounterpartPreferencesAction {
  return {
    type: 'USER_PREFERENCES_ADD_COUNTERPART',
    counterpartId,
  };
}

export interface DeleteCounterpartPreferencesAction {
  type: 'USER_PREFERENCES_DELETE_COUNTERPART';
  counterpartId: OnyxCounterpartId;
}

export function createDeleteCounterpartPreferencesAction(
  counterpartId: OnyxCounterpartId,
): DeleteCounterpartPreferencesAction {
  return {
    type: 'USER_PREFERENCES_DELETE_COUNTERPART',
    counterpartId,
  };
}

export interface GetCurrentUserCounterpartsAction {
  type: 'GET_CURRENT_USER_COUNTERPARTS';
}

export function createGetCurrentUserCounterpartsAction(): GetCurrentUserCounterpartsAction {
  return {
    type: 'GET_CURRENT_USER_COUNTERPARTS',
  };
}
