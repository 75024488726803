import { getDataFromClipboard } from '@/util/clipboard/clipboard.ts';

export type RfqIdClipboardResult =
  | {
      status: 'SUCCESS';
      rfqIds: string[];
    }
  | {
      status: 'FAILED';
      message: string;
    };

export async function getRfqIdsFromClipboard(): Promise<RfqIdClipboardResult> {
  const result = await getDataFromClipboard();
  if (result.status === 'FAILED') {
    return result;
  }

  const rfqIds = result.data.match(
    /[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}/gi,
  );
  return { status: 'SUCCESS', rfqIds: rfqIds ?? [] };
}
