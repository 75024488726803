import { DATE_SHORT_KEBAB_FORMAT, formatNow } from '@/util/date/dateFormatHelper';
import {
  type DefaultProductValuesForStrategy,
  type Els,
  type ElsProductFamily,
  type Product,
  isElsProduct,
} from '../../../../../../../../../neos/business/neosModel';
import {
  getCommonPart,
  getDerivativePart,
  getOtcNegotiationPart,
} from './getDefaultedPartialProduct';

export function getDefaultedElsProduct(
  currentProduct: Product,
  defaultValues: DefaultProductValuesForStrategy,
  discriminator: ElsProductFamily,
): Els {
  const {
    maturity,
    underlying,
    swapCurrency: strategySwapCurrency,
    strikeDate: strategyStrikeDate,
    elsType: strategyElsType,
  } = defaultValues;
  const {
    swapCurrency: currentSwapCurrency,
    strikeDate: currentStrikeDate,
    elsType: currentElsType,
  } = isElsProduct(currentProduct)
    ? currentProduct
    : {
        swapCurrency: undefined,
        strikeDate: undefined,
        elsType: undefined,
      };

  const strikeDate =
    !window.location.search?.length ||
    !window.location.search?.includes('random=') ||
    window.location.origin === 'https://eqdflow.sgmarkets.com'
      ? formatNow(DATE_SHORT_KEBAB_FORMAT)
      : undefined;

  return {
    subFamily: discriminator,
    swapCurrency: strategySwapCurrency ?? currentSwapCurrency,
    strikeDate: strategyStrikeDate ?? currentStrikeDate ?? strikeDate,
    strikeTenor: undefined,
    elsType: strategyElsType ?? currentElsType,
    ...getCommonPart(currentProduct, defaultValues),
    ...getOtcNegotiationPart(),
    ...getDerivativePart(currentProduct, maturity, underlying),
    hasCustomUnderlying: false,
  };
}
