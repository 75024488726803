import type { Thunk } from '@/bootstrap/thunks';

export function createRfqChangePriceRequestDateThunk(
  rfqId: string,
  newDate: string | undefined,
): Thunk {
  return function rfqChangePriceRequestDate(
    dispatch,
    getState,
    {
      selectors: { getDisplayTimeZone, getRfqData },
      actionCreators: {
        neos: { rfqDataCrudActions },
      },
    },
  ) {
    if (!newDate) {
      return dispatch(rfqDataCrudActions.update(rfqId, { priceRequestUTCDateAndTime: undefined }));
    }
    const appState = getState();
    const {
      ui: { userPreferences },
    } = appState;
    const displayTimeZone = getDisplayTimeZone(userPreferences);
    const { priceRequestUTCDateAndTime } = getRfqData(appState, rfqId);
    const [year, month, days] = newDate.split('-');
    const priceRequestDate = cleanPriceRequestDate(priceRequestUTCDateAndTime);

    if (displayTimeZone === 'LOCAL') {
      priceRequestDate.setFullYear(Number(year));
      priceRequestDate.setMonth(Number(month) - 1);
      priceRequestDate.setDate(Number(days));
    } else {
      priceRequestDate.setUTCFullYear(Number(year));
      priceRequestDate.setUTCMonth(Number(month) - 1);
      priceRequestDate.setUTCDate(Number(days));
    }

    return dispatch(
      rfqDataCrudActions.update(rfqId, {
        priceRequestUTCDateAndTime: priceRequestDate.toISOString(),
      }),
    );
  };
}

function cleanPriceRequestDate(priceRequestUTCDateAndTime: string | undefined) {
  if (priceRequestUTCDateAndTime) {
    return new Date(priceRequestUTCDateAndTime);
  }
  const prd = new Date();
  prd.setSeconds(0);
  prd.setMilliseconds(0);
  return prd;
}
