import type { FC } from 'react';
import type { OptionStyle } from '../../../../../../../neos/business/neosModel';
import { ErrorHighlight } from '../../../../../share/errorHighlight';
import { MultiToggleButton } from '../../../../../share/multiToggleButton/MultiToggleButton';
import type { OptionStyleCellsModel } from './getOptionStyleCellsModel';

export interface OptionStyleCellsOwnProps {
  rfqId: string;
  strategyId: string;
}

export type OptionStyleCellsMapStateProps = OptionStyleCellsModel;

export interface OptionStyleCellsDispatchProps {
  onOptionStyleChanged(productId: string, optionStyle: OptionStyle | undefined): void;
}

type OptionStyleCellsProps = OptionStyleCellsOwnProps &
  OptionStyleCellsMapStateProps &
  OptionStyleCellsDispatchProps;

export const OptionStyleCells: FC<OptionStyleCellsProps> = props => {
  return (
    <section>
      {props.optionStyles.map(({ id, value, isEditable }) => (
        <ErrorHighlight
          key={id}
          errorField={'optionStyle'}
          related={{ rfqId: props.rfqId, legId: id, strategyId: props.strategyId }}
        >
          <MultiToggleButton<OptionStyle | undefined>
            readOnly={!isEditable}
            selectedValue={value}
            valueFormatter={v => (v ? v.charAt(0) : '')}
            availableValues={props.availableStyles}
            onValueChange={newStyle => props.onOptionStyleChanged(id, newStyle)}
            data-e2e="neos-strategy-definition-style"
            className="fw-bold form-control"
          />
        </ErrorHighlight>
      ))}
    </section>
  );
};
