const hiddenFieldPathsFromDifference: RegExp[] = [
  /^rfq\.pdcs\[(\d*)\]/,
  /^rfq.*\.fairPrice\.greeks.*\.type/,
  /^rfq\.strategies\[(\d*)\]\.fairPrice\.spot\.type/,
  /^rfq\.strategies\[(\d*)\]\.legs\[(\d*)\]\.fairPrice\.spot\.type/,
  /^rfq\.strategies\[(\d*)\]\.legs\[(\d*)\]\.product\.isScheduleObsolete/,
  /^rfq\.strategies\[(\d*)\]\.legs\[(\d*)\]\.legAssociations.*/,
  /^rfq\.fairPrice\.spot\.type/,
  /^rfq\.strategyQuotes.*/,
  /^rfq\.legQuotes.*/,
  /^rfq\.tradeDate.*/,
  /^rfq\.aggregatedDataByPriceUnit/,
  /^rfq\.lifecycle\.orderReceivedUTCTimestamp/,
];

export function isPathHiddenFromDifference(pathToMatch: string): boolean {
  return (
    hiddenFieldPathsFromDifference.length > 0 &&
    hiddenFieldPathsFromDifference.some(regex => regex.test(pathToMatch))
  );
}
