import { actionCreators } from '@/bootstrap/actions';
import { useAppSelector } from '@/bootstrap/hooks';
import type { Counterpart } from '@/neos/business/rfq/actorsSetup/counterpart/counterpartModel';
import { SearchInput } from '@/neos/components/userPreferences/counterparties/SearchInput';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

export function CounterpartiesPreferences() {
  const dispatch = useDispatch();

  const isAddButtonLoading = useAppSelector(
    state => state.ui.userPreferences.isAddCounterpartPreferenceButtonLoading,
  );
  const isDeleteButtonLoading = useAppSelector(
    state => state.ui.userPreferences.isDeleteCounterpartPreferenceButtonLoading,
  );
  const isLoading = isAddButtonLoading || isDeleteButtonLoading;

  const currentUserCounterparts = useAppSelector(
    state => state.referenceData.counterparts,
  ).toSorted((counterpartA, counterpartB) => (counterpartA.name > counterpartB.name ? 1 : -1));

  const searchedCounterparts = useAppSelector(
    state => state.ui.userPreferences.searchedCounterparts,
  ).toSorted((counterpartA, counterpartB) => (counterpartA.name > counterpartB.name ? 1 : -1));

  const onDeleteCounterpart = (counterpart: Counterpart) => {
    dispatch(actionCreators.neos.createChangeUserPreferenceDeleteCounterpartsLoadingAction(true));
    dispatch(actionCreators.neos.createDeleteCounterpartPreferencesAction(counterpart.id));
  };

  const onAddCounterparty = (counterpart: Counterpart) => {
    const isDisabled =
      currentUserCounterparts.find(userCounterpart => userCounterpart.id === counterpart.id) !==
      undefined;

    if (isDisabled) {
      return;
    }

    dispatch(actionCreators.neos.createChangeUserPreferenceAddCounterpartsLoadingAction(true));
    dispatch(actionCreators.neos.createAddCounterpartPreferencesAction(counterpart.id));
  };

  return (
    <div className="d-grid gap-3 h-100" style={{ gridTemplateColumns: '1fr 1fr' }}>
      <div className="card bg-lvl3 overflow-hidden">
        <div className="card-header">
          <h5 className="card-title d-inline">Search </h5> by name, mnemo or BDR id
          {isLoading && <div className="spinner spinner-sm ms-2"></div>}
          <SearchInput searchedCounterparts={searchedCounterparts} />
        </div>

        <div className="card-body px-1 overflow-auto">
          <table className="table table-sm table-striped table-borderless">
            <tbody>
              {searchedCounterparts.map(searchedCounterpart => {
                const isDisabled =
                  currentUserCounterparts.find(
                    userCounterpart => userCounterpart.id === searchedCounterpart.id,
                  ) !== undefined;

                return (
                  <tr key={searchedCounterpart.id}>
                    <th>{searchedCounterpart.name}</th>
                    <td>{searchedCounterpart.mnemo}</td>
                    <td>{searchedCounterpart.id}</td>
                    <td className="align-middle">
                      <Button
                        className="btn-icon flex-shrink-0"
                        variant="flat-primary"
                        style={{ pointerEvents: isDisabled ? 'none' : undefined }}
                        onClick={() => onAddCounterparty(searchedCounterpart)}
                        data-e2e={`user-preferences-counterparts-add-${searchedCounterpart.id}`}
                      >
                        {isDisabled ? <i className="icon">star</i> : <i className="icon">add</i>}
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div className="card bg-lvl3 overflow-hidden">
        <div className="card-header">
          <h5 className="card-title">Selected counterparties</h5>
        </div>
        <div className="card-body px-1 overflow-auto">
          <table className="table table-sm table-striped table-borderless">
            <tbody>
              {currentUserCounterparts.map(counterpart => (
                <tr key={counterpart.id}>
                  <td className="align-middle">
                    <Button
                      className="btn-icon"
                      variant="flat-primary"
                      onClick={() => onDeleteCounterpart(counterpart)}
                      data-e2e={`user-preferences-counterparts-delete-${counterpart.id}`}
                    >
                      <i className="icon">delete_forever</i>
                    </Button>
                  </td>
                  <th>{counterpart.name}</th>
                  <td>{counterpart.mnemo}</td>
                  <td>{counterpart.id}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
