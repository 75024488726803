import type { FinancialColor } from '@/neos/components/share/numericInput/withStyle/withStyle';
import type { Way } from '../../../../../neos/business/neosModel';

export function getEffectiveWay(
  clientWay: Way | undefined,
  BidAskValue: number | undefined,
): FinancialColor {
  return clientWay === 'SELL'
    ? 'BID'
    : clientWay === 'BUY'
      ? 'ASK'
      : BidAskValue !== undefined
        ? 'ASK'
        : 'BID';
}
