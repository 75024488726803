import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { type ExtendedDispatch, thunks } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import {
  TopSectionComponent,
  type TopSectionDispatchProps,
  type TopSectionMapStateProps,
  type TopSectionOwnProps,
} from './TopSection';
import { getTopSectionModel } from './getTopSectionModel';

export const TopSection = connect(mapStateToProps, mapDispatchToProps)(TopSectionComponent);

function mapStateToProps(
  state: AppState,
  { strategyId, rfqId }: TopSectionOwnProps,
): TopSectionMapStateProps {
  return { model: getTopSectionModel(state, rfqId, strategyId, selectors) };
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId, strategyId }: TopSectionOwnProps,
): TopSectionDispatchProps {
  return {
    onTopChanged(top: number | undefined) {
      dispatch(neosThunks.createRfqChangeStrategyTopThunk(rfqId, strategyId, top));
    },
    onUnderlyingTopChanged(strategyId: string, underlyingId: string | undefined) {
      dispatch(
        neosThunks.createNeosLoadUnderlyingInfoAndChangeThunk(
          rfqId,
          underlyingId,
          thunks.neos.createNeosChangeStrategyTopUnderlyingThunk(strategyId, underlyingId),
        ),
      );
    },
    onCompositionOpened() {
      dispatch(
        neosActionCreators.strategyUiCrudActions.update(strategyId, {
          displayCompositionLegsModal: true,
        }),
      );
    },
  };
}
