import {
  type DefaultProductValuesForStrategy,
  type Product,
  isOptionLike,
} from '../../../../../../../../../neos/business/neosModel';
import { type OptionOnFuture, isProductWithStrike } from '../../../../product/productModel';
import { getCommonPart, getDerivativePart, getNegotiationPart } from './getDefaultedPartialProduct';

export function getDefaultedOptionOnFutureProduct(
  currentProduct: Product,
  defaultValues: DefaultProductValuesForStrategy,
): OptionOnFuture {
  const {
    underlying,
    negotiation,
    optionStyle,
    optionType,
    strike: strategyStrike,
    maturity,
  } = defaultValues;

  const { type, style } = isOptionLike(currentProduct)
    ? currentProduct
    : { type: undefined, style: undefined };
  const { strike, strikeUnit } = isProductWithStrike(currentProduct)
    ? currentProduct
    : { strike: undefined, strikeUnit: undefined };
  return {
    subFamily: 'OPTION_ON_FUTURE',
    type: optionType || type,
    style: optionStyle || style,
    strike: strategyStrike || strike,
    strikeUnit,
    ...getCommonPart(currentProduct, defaultValues),
    ...getNegotiationPart(currentProduct, negotiation),
    ...getDerivativePart(currentProduct, maturity, underlying),
    hasCustomUnderlying: false,
  };
}
