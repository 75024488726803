import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { type ExtendedDispatch, thunks } from '@/bootstrap/thunks';
import { connect } from 'react-redux';
import {
  ManualPricingButtonComponent,
  type ManualPricingButtonDispatchProps,
  type ManualPricingButtonMapStateToProps,
  type ManualPricingButtonOwnProps,
} from './ManualPricingButton';

export const ManualPricingButton = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManualPricingButtonComponent);

function mapStateToProps(
  state: AppState,
  { rfqId }: ManualPricingButtonOwnProps,
): ManualPricingButtonMapStateToProps {
  const { quoteRecap } = selectors.getRfqData(state, rfqId);
  return { isButtonEnabled: quoteRecap !== undefined && quoteRecap !== '' };
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId }: ManualPricingButtonOwnProps,
): ManualPricingButtonDispatchProps {
  return {
    onManualPricingRequested: () => {
      dispatch(
        thunks.neos.createRequestManualPricingFromRfqThunk(rfqId),
        createLogAnalyticsAction('NEOS RFQ', 'Manual pricing button hit'),
      );
    },
  };
}
