import { changeClsIsScheduleObsoleteThunk } from '@/neos/business/rfq/thunks/changeClsIsScheduleObsoleteThunk';
import { changeElsIsScheduleObsoleteThunk } from '@/neos/business/rfq/thunks/changeElsIsScheduleObsoleteThunk';
import { createChangeIsRfqInternalThunk } from '@/neos/business/rfq/thunks/changeIsRfqInternalThunk';
import { createHideElsClsObsoleteScheduleWarningThunk } from '@/neos/business/rfq/thunks/hideElsClsObsoleteScheduleWarningThunk';
import { createRequestTransfereesContactsThunk } from '@/neos/business/rfq/transfereeContacts/transfereeContactsThunk';
import { createRfqChangeIsRfqContainingInvalidListedExecStrategyThunk } from '../../ui/thunks/rfqChangeIsRfqContainingInvalidListedExecStrategyThunk';
import { createRfqChangeIsRfqContainingInvalidListedStrategyThunk } from '../../ui/thunks/rfqChangeIsRfqContainingInvalidListedStrategyThunk';
import { actorsSetupThunks } from '../actorsSetup/thunks';
import { forexRatesThunks } from '../forexRates/thunks';
import { hedgeThunks } from '../hedge/thunks';
import { createOnAllocationIACurrencyChangedThunk } from '../postNego/otcAllocation/onAllocationIACurrencyChangedThunk';
import { onAllocationIAToBeBookedCurrencyChangeThunk } from '../postNego/otcAllocation/onAllocationIAToBeBookedCurrencyChangeThunk';
import { createOnAllocationIATypeChangedThunk } from '../postNego/otcAllocation/onAllocationIATypeChangedThunk';
import { createOnIACurrencyChangedThunk } from '../postNego/otcPreAlloc/onIACurrencyChangedThunk';
import { createOnIATypeChangedThunk } from '../postNego/otcPreAlloc/onIATypeChangedThunk';
import { createChangeLegSecondaryTypeThunk } from '../secondaryEvent/changeLegSecondaryTypeThunk';
import { type LegAndQuoteIds as LocalLegAndQuoteIds, strategyThunks } from '../strategy/thunks';
import { createAddFirstStrategyThunk } from './addFirstStrategy';
import { createAddStrategyThunk, createManyStrategiesAddedThunk } from './addStrategy';
import { createApplyRfqListedPreallocationsToDeltaThunk } from './applyRfqListedPreallocationsToDelta';
import { createApplyToSelectedStrategyThunk } from './applyToSelectedStrategyThunk';
import { createChangeMasterStrategyExecutionTypeThunk } from './changeExecutionTypeThunk';
import { createChangeLegQuoteMarkupRuleThunk } from './changeLegMarkupRuleThunk';
import { createChangeLegMidThunk } from './changeLegMidThunk';
import { createRfqChangeLegWeightThunk } from './changeLegWeight';
import { createChangeReferenceBasisThunk } from './changeReferenceBasis';
import { createChangeReferenceLevelThunk } from './changeReferenceLevel';
import { createChangeReferenceMaturityThunk } from './changeReferenceMaturity';
import { createChangeReferenceSpotThunk } from './changeReferenceSpot';
import { createChangeRfqMidThunk } from './changeRfqMidThunk';
import { createChangeStrategyMarkupRuleThunk } from './changeStrategyMarkupRuleThunk';
import { createChangeStrategyMidThunk } from './changeStrategyMidThunk';
import { createRfqChangeStrategyWeightThunk } from './changeStrategyWeight';
import { createCloneRfqThunk } from './cloneRfq';
import { createCloneRfqStrategiesThunk } from './cloneRfqStrategies';
import { createDefaultNegotiatedSizeRequestedThunk } from './defaultNegotiatedSizeRequestedThunk';
import { createDefaultRfqThunk } from './defaultRfq';
import { createDeleteRfqThunk } from './deleteRfq';
import { createInitializeRfqThunk } from './initializeRfq';
import { createIntegrateReceivedIbChatThunk } from './integrateReceivedIbChat';
import { createLoadPartialRfqThunk, createLoadRfqThunk } from './loadRfq';
import { createRecomputeRfqThunk } from './recomputeRfq';
import {
  createRequestRfqDeltaHedgingAndFairPricesThunk,
  createRequestRfqDeltaHedgingThunk,
} from './requestRfqDeltaHedging';
import { createRequestRfqFairPricesThunk } from './requestRfqFairPrices';
import { createRequestTradeRecapMailThunk } from './requestTradeRecapMailThunk';
import { createRfqAddBarrierThunk } from './rfqAddBarrier';
import { createRfqAddLegsThunk } from './rfqAddLegsThunk';
import { createRfqAddLegWithUnderlyingThunk } from './rfqAddLegsWithUnderlyingThunk';
import { createRfqAddStrategyWhenOnlyOneExistThunk } from './rfqAddStrategyWhenOnlyOneExistThunk';
import { createRfqApplyImportedCompositionLegsThunk } from './rfqApplyImportedCompositionLegsThunk';
import { createChangeRfqActivity } from './rfqChangeActivityThunk';
import { createRfqChangeBarrierStrikeUnitThunk } from './rfqChangeBarrierStrikeUnit';
import { createRfqChangeMarketLotSizeThunk } from './rfqChangeMarketLotSize';
import { createRfqChangePriceRequestDateThunk } from './rfqChangePriceRequestDate';
import { createRfqChangePriceRequestTimeThunk } from './rfqChangePriceRequestTime';
import { createRfqChangePricesUnitThunk } from './rfqChangePricesUnit';
import { createRfqChangeQuoteTypeThunk } from './rfqChangeQuoteType';
import { createRfqChangeSalesCreditAmountThunk } from './rfqChangeSalesCreditAmount';
import { createRfqChangeStrategyDeltaTypeThunk } from './rfqChangeStrategyDeltaType';
import { createRfqChangeStrategyOrLegSizeThunk } from './rfqChangeStrategyOrLegSize';
import { createRfqChangeStrategyTopThunk } from './rfqChangeStrategyTop';
import { createRfqChangeStrategyTypeThunk } from './rfqChangeStrategyType';
import { createRfqCloneStrategyThunk } from './rfqCloneStrategy';
import { createRfqCustomOptionsAddLegsThunk } from './rfqCustomOptionsAddLegsThunk';
import { createRfqDefaultTenorMaturityThunk } from './rfqDefaultTenorMaturity';
import { createRfqDeleteLegThunk } from './rfqDeleteLegThunk';
import { createRfqDeleteObsoleteLegsThunk } from './rfqDeleteObsoleteLegsThunk';
import { createRfqDeleteObsoleteUnderlyingDependenciesThunk } from './rfqDeleteObsoleteUnderlyingDependencies';
import { createRfqDeleteStrategyThunk } from './rfqDeleteStrategy';
import { createRfqHideCompositionLegsModalThunk } from './rfqHideCompositionLegsModalThunk';
import { createRfqProceedToLegsToStrategiesThunk } from './rfqProceedToLegsToStrategies';
import { createRfqRemoveObsoleteForexThunk } from './rfqRemoveObsoleteForex';
import { createRfqRemoveObsoleteHedgesThunk } from './rfqRemoveObsoleteHedges';
import { createRfqRemoveObsoleteReferencesThunk } from './rfqRemoveObsoleteReferences';
import { createRfqDeleteStrategyAndDependenciesThunk } from './rfqRemoveStrategyAndDependencies';
import { createRfqResetQuotesWithGivenUnderlyingThunk } from './rfqResetQuotesWithGivenUnderlying';
import { createRfqResetStrategyFairPriceThunk } from './rfqResetStrategyFairPrice';
import { createRfqResetStrategyQuoteThunk } from './rfqResetStrategyQuote';
import { createRfqResetStrategyQuoteAndFairPricesThunk } from './rfqResetStrategyQuoteAndFairPrices';
import { createRfqReverseStrategyWeightThunk } from './rfqReverseStrategyWeight';
import { createRfqSetStrategyAsMasterThunk } from './rfqSetStrategyAsMaster';
import { rfqUnderlyingChangedThunks } from './rfqUnderlyingChangedThunk';
import { createRfqUpdateDeltaTypeThunk } from './rfqUpdateDeltaType';
import { createRfqUpdateDeltaValueThunk } from './rfqUpdateDeltaValue';
import { createRfqUpdateLegMaturityThunk } from './rfqUpdateLegMaturity';
import { createRfqUpdateLegQuoteThunk } from './rfqUpdateLegQuote';
import { createRfqUpdateLegQuoteAccordingToAveragePriceThunk } from './rfqUpdateLegQuoteAccordingToAveragePriceThunk';
import { createRfqUpdateStrategyQuoteThunk } from './rfqUpdateStrategyQuote';
import { createRfqUpdateStrategyQuoteUnitThunk } from './rfqUpdateStrategyQuoteUnit';
import {
  createRfqSelectDeltaTypeShortcutThunk,
  createRfqSelectNegotiationModeShortcutThunk,
  createRfqSelectStrategyTypeShortcutThunk,
  createRfqSelectUnderlyingShortcutThunk,
} from './shortcutsThunks';
import { createUpdateTotalRfqQuoteThunk } from './updateTotalRfqQuote';

export type LegAndQuoteIds = LocalLegAndQuoteIds;
export const rfqThunks = {
  ...forexRatesThunks,
  ...strategyThunks,
  ...actorsSetupThunks,
  ...rfqUnderlyingChangedThunks,
  ...hedgeThunks,
  createChangeRfqActivity,
  createChangeLegSecondaryTypeThunk,
  createRfqCustomOptionsAddLegsThunk,
  createRfqSelectDeltaTypeShortcutThunk,
  createRfqSelectNegotiationModeShortcutThunk,
  createRfqSelectStrategyTypeShortcutThunk,
  createRfqSelectUnderlyingShortcutThunk,
  createLoadRfqThunk,
  createDefaultRfqThunk,
  createLoadPartialRfqThunk,
  createDeleteRfqThunk,
  createAddStrategyThunk,
  createManyStrategiesAddedThunk,
  createRfqDeleteStrategyThunk,
  createRfqCloneStrategyThunk,
  createRfqSetStrategyAsMasterThunk,
  createAddFirstStrategyThunk,
  createInitializeRfqThunk,
  createRfqProceedToLegsToStrategiesThunk,
  createRfqChangeStrategyTypeThunk,
  createRfqUpdateStrategyQuoteThunk,
  createUpdateTotalRfqQuoteThunk,
  createRfqReverseStrategyWeightThunk,
  createRecomputeRfqThunk,
  createRfqRemoveObsoleteReferencesThunk,
  createRfqResetStrategyQuoteAndFairPricesThunk,
  createRfqResetStrategyFairPriceThunk,
  createRfqResetStrategyQuoteThunk,
  createRfqResetQuotesWithGivenUnderlyingThunk,
  createChangeReferenceSpotThunk,
  createChangeReferenceMaturityThunk,
  createRfqDeleteStrategyAndDependenciesThunk,
  createRequestRfqDeltaHedgingThunk,
  createRfqRemoveObsoleteForexThunk,
  createRfqChangePricesUnitThunk,
  createRequestRfqDeltaHedgingAndFairPricesThunk,
  createApplyToSelectedStrategyThunk,
  createRfqAddStrategyWhenOnlyOneExistThunk,
  createIntegrateReceivedIbChatThunk,
  createChangeReferenceBasisThunk,
  createChangeReferenceLevelThunk,
  createCloneRfqThunk,
  createCloneRfqStrategiesThunk,
  createRfqChangeMarketLotSizeThunk,
  createRfqChangeStrategyDeltaTypeThunk,
  createRfqUpdateLegQuoteThunk,
  createRfqUpdateLegQuoteAccordingToAveragePriceThunk,
  createRfqUpdateLegMaturityThunk,
  createRequestRfqFairPricesThunk,
  createRequestTradeRecapMailThunk,
  createRfqChangeQuoteTypeThunk,
  createRfqChangePriceRequestDateThunk,
  createRfqChangePriceRequestTimeThunk,
  createRfqChangeStrategyWeightThunk,
  createRfqChangeLegWeightThunk,
  createRfqChangeStrategyOrLegSizeThunk,
  createRfqChangeIsRfqContainingInvalidListedExecStrategyThunk,
  createRfqChangeIsRfqContainingInvalidListedStrategyThunk,
  createRfqRemoveObsoleteHedgesThunk,
  createRfqDeleteObsoleteUnderlyingDependenciesThunk,
  createRfqUpdateDeltaValueThunk,
  createRfqUpdateDeltaTypeThunk,
  createRfqDeleteLegThunk,
  createRfqAddLegsThunk,
  createChangeRfqMidThunk,
  createChangeStrategyMidThunk,
  createChangeLegMidThunk,
  createRfqUpdateStrategyQuoteUnitThunk,
  createRfqDefaultTenorMaturityThunk,
  createRfqChangeSalesCreditAmountThunk,
  createRfqChangeStrategyTopThunk,
  createRfqAddLegWithUnderlyingThunk,
  createRfqApplyImportedCompositionLegsThunk,
  createRfqDeleteObsoleteLegsThunk,
  createRfqHideCompositionLegsModalThunk,
  createChangeStrategyMarkupRuleThunk,
  createChangeLegQuoteMarkupRuleThunk,
  createRfqChangeBarrierStrikeUnitThunk,
  createRfqAddBarrierThunk,
  createApplyRfqListedPreallocationsToDeltaThunk,
  createChangeMasterStrategyExecutionTypeThunk,
  createOnIACurrencyChangedThunk,
  createOnIATypeChangedThunk,
  createOnAllocationIACurrencyChangedThunk,
  createOnAllocationIATypeChangedThunk,
  onAllocationIAToBeBookedCurrencyChangeThunk,
  createDefaultNegotiatedSizeRequestedThunk,
  changeElsIsScheduleObsoleteThunk,
  changeClsIsScheduleObsoleteThunk,
  createRequestTransfereesContactsThunk,
  createHideElsClsObsoleteScheduleWarningThunk,
  createChangeIsRfqInternalThunk,
};
