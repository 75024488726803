import { mapFromOnyxProductAsianOption } from './asianOptionMapper';
import { mapFromOnyxProductAsianSpreadOption } from './asianSpreadOptionMapper';
import { mapFromOnyxProductCls } from './clsMapper';
import { mapFromOnyxCustomProduct, mapFromOnyxFxForwardProduct } from './customProductMapper';
import { mapFromOnyxDigitalOptionProduct } from './digitalOptionMapper';
import { mapFromOnyxProductDivSwapSwaption } from './divSwapSwaptionMapper';
import { mapFromOnyxProductDividendFuture } from './dividendFutureMapper';
import { mapFromOnyxProductEls } from './elsMapper';
import { mapFromOnyxProductEuropeanOption } from './europeanOptionMapper';
import { mapFromOnyxFutureStrikeDate, mapFromOnyxProductFuture } from './futureMapper';
import { mapFromOnyxFvaFixedK, mapFromOnyxFvaFloatingK } from './fvaMapper';
import { mapFromOnyxFxDigitalOptionProduct } from './fxDigitalOptionProductMapper';
import { mapFromOnyxProductTotalReturnFuture } from './mapFromOnyxProductTotalReturnFuture';
import { mapFromOnyxProductOption } from './optionMapper';
import { mapFromOnyxProductOptionOnFuture } from './optionOnFutureMapper';
import { mapFromOnyxProductStock } from './stockMapper';
import { mapFromOnyxProductVSwap } from './vSwapMapper';

export const fromSpecificProductMappers = {
  mapFromOnyxFutureStrikeDate,
  mapFromOnyxProductFuture,
  mapFromOnyxProductTotalReturnFuture,
  mapFromOnyxProductDividendFuture,
  mapFromOnyxProductOption,
  mapFromOnyxProductOptionOnFuture,
  mapFromOnyxProductEuropeanOption,
  mapFromOnyxProductAsianOption,
  mapFromOnyxProductStock,
  mapFromOnyxProductVSwap,
  mapFromOnyxFvaFixedK,
  mapFromOnyxFvaFloatingK,
  mapFromOnyxProductEls,
  mapFromOnyxProductCls,
  mapFromOnyxProductDivSwapSwaption,
  mapFromOnyxCustomProduct,
  mapFromOnyxFxForwardProduct,
  mapFromOnyxFxDigitalOptionProduct,
  mapFromOnyxDigitalOptionProduct,
  mapFromOnyxProductAsianSpreadOption,
};

export type FromSpecificProductMappers = typeof fromSpecificProductMappers;
