import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { BookingStepStatus } from '@/neos/business/bookingSteps/bookingStepOnyxModel';
import type { Execution, ExecutionKey } from '@/neos/business/order/orderModel';
import {
  DATE_LONG_TIME_FORMAT,
  DATE_SHORT_KEBAB_FORMAT,
  formatDateInTimeZone,
} from '@/util/date/dateFormatHelper';
import { getBookingStatusOnly } from './getBookingStatus';

export interface ExistingLegExecutionModel {
  exists: true;
  quantity: number | undefined;
  price: number | undefined;
  priceUnit: string | undefined;
  executionDateTime: ExecutionDateTime;
  eliotId: string | undefined;
  defaultEliotId: string | undefined;
  numberOfLots: number | undefined;
  isValidExecStrategySize: boolean;
  traderWarning: string | undefined;
  isTrader: boolean;
  status: BookingStepStatus | undefined;
  bookingMessage: string | undefined;
}

interface NonExistingLegExecutionModel {
  exists: false;
}

export type LegExecutionModel = ExistingLegExecutionModel | NonExistingLegExecutionModel;

export function getLegExecutionModel(
  state: AppState,
  executionKey: ExecutionKey,
  selectors: Selectors,
): LegExecutionModel {
  const execution = selectors.executionSelectors.find(state.execution, executionKey);
  const executionPriceChange = selectors.executionPriceNotificationChangesCrudSelectors.find(
    state.warnings.executionPriceNotificationChanges,
    {
      rfqId: executionKey.rfqId,
      executionId: executionKey.executionId,
    },
  );
  let traderWarning = executionPriceChange
    ? 'The execution price value has been changed'
    : undefined;

  if (traderWarning && executionPriceChange?.oldValue) {
    traderWarning = `${traderWarning}, Old value: ${executionPriceChange?.oldValue}`;
  }
  const isTrader = selectors.isTrader(state);

  if (!execution) {
    return { exists: false };
  }

  const { lastPrice, size, externalExecId } = execution;
  const executionDateTime = getExecutionDateTime(state, execution, selectors);

  const bookingStep = selectors.getExecutionBookingStep(
    state,
    executionKey.rfqId,
    executionKey.executionId,
  );

  const extractedStatusAndMessage = externalExecId?.id
    ? undefined
    : getBookingStatusOnly(bookingStep);

  const eliotId = externalExecId?.id ?? bookingStep?.bookingId;

  const strategyId = selectors.getStrategyIdByLegId(state, executionKey.legId);

  const isValidExecStrategySize = selectors.isValidListedExecStrategySize(
    state,
    executionKey.rfqId,
    strategyId,
    selectors,
  );

  return {
    exists: true,
    quantity: size?.quantity,
    numberOfLots: size?.numberOfLots,
    price: lastPrice?.value,
    priceUnit: lastPrice?.unit,
    executionDateTime,
    eliotId,
    defaultEliotId: bookingStep?.bookingId,
    status: extractedStatusAndMessage?.status,
    isValidExecStrategySize,
    traderWarning,
    isTrader,
    bookingMessage: extractedStatusAndMessage?.message,
  };
}

export type ExecutionDateTime =
  | {
      date: string;
      time: string;
    }
  | {
      date: undefined;
      time: undefined;
    };

export function getExecutionDateTime(
  state: AppState,
  execution: Execution,
  selectors: Selectors,
): ExecutionDateTime {
  const { getDisplayTimeZone } = selectors;
  const executionTime = execution.executionTime;
  const displayTimeZone = getDisplayTimeZone(state.ui.userPreferences);

  const executionTimeSanitized = executionTime?.substring(
    0,
    executionTime?.indexOf('[') > 0 ? executionTime?.indexOf('[') : executionTime.length,
  );
  return executionTimeSanitized
    ? {
        date: formatDateInTimeZone(
          displayTimeZone,
          executionTimeSanitized,
          DATE_SHORT_KEBAB_FORMAT,
        ),
        time: formatDateInTimeZone(displayTimeZone, executionTimeSanitized, DATE_LONG_TIME_FORMAT),
      }
    : {
        date: undefined,
        time: undefined,
      };
}
