import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';

interface BrokenLinkProps {
  hasBrokenLink: boolean | undefined;
  isAllocBookingId?: boolean;
}

export function BrokenLink({ hasBrokenLink, isAllocBookingId = false }: BrokenLinkProps) {
  if (!hasBrokenLink) {
    return <div></div>;
  }

  const errorMessage = `Link is broken. No booking can be done on this ${isAllocBookingId ? 'allocation' : 'leg'}. An email has been sent to your MO, please contact them via Teams`;

  return (
    <div className="d-flex flex-center align-items-center">
      <SimpleNeosTooltip type={'danger'} id="broken-link-message" message={errorMessage}>
        <i className="icon text-danger">link_off</i>
      </SimpleNeosTooltip>
    </div>
  );
}
