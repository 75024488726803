import type { ElsSectionsLayout } from './elsSectionsModel';

export type ElsSectionsActions = ElsSectionsUpsertAction;

type ElsSectionsUpsertAction = {
  type: 'UPSERT_ELS_SECTIONS';
  payload: ElsSectionsLayout[];
};

export const elsSectionsActionCreators = {
  upsertElsSections: (elsSections: ElsSectionsLayout[]): ElsSectionsUpsertAction => {
    return {
      type: 'UPSERT_ELS_SECTIONS',
      payload: elsSections,
    };
  },
};
