import type { Thunk } from '@/bootstrap/thunks';
import type { ActionContainer } from '@/util/actionContainer';
import { DefaultingScope, LegRecomputeScope } from '../models';
import { DefaultingOptions } from '../rfqActions';

export function createRfqUpdateStrategyQuoteUnitThunk(
  rfqId: string,
  strategyId: string,
  unit: string | undefined,
): Thunk {
  return function rfqUpdateStrategyQuoteUnitThunk(
    dispatch,
    getState,
    {
      actionCreators: {
        neos: {
          quoteCrudActions,
          createRecomputeRfqRequestedAction,
          createDefaultRfqRequestedAction,
        },
      },
      selectors: { getStrategyQuoteIds, getRfqData, getStrategyData },
    },
  ) {
    const state = getState();
    const { quoteId: rfqQuoteId } = getRfqData(state, rfqId);
    const { isMasterStrategy } = getStrategyData(state, strategyId);
    const quoteIds = getStrategyQuoteIds(state, strategyId);

    const actions: ActionContainer[] = quoteIds.map(quoteId =>
      quoteCrudActions.update(quoteId, {
        unit,
        extraSalesMarginAskUnit: unit,
        extraSalesMarginBidUnit: unit,
        extraSalesMarginSpreadUnit: unit,
        includedSalesMarginAskUnit: unit,
        includedSalesMarginBidUnit: unit,
        includedSalesMarginSpreadUnit: unit,
        thetaUnit: unit,
        vegaUnit: unit,
        preTradeMidMarketMarkPriceUnit: unit,
      }),
    );
    actions.push(
      quoteCrudActions.update(rfqQuoteId, {
        unit: undefined,
        extraSalesMarginAskUnit: undefined,
        extraSalesMarginBidUnit: undefined,
        extraSalesMarginSpreadUnit: undefined,
        includedSalesMarginAskUnit: undefined,
        includedSalesMarginBidUnit: undefined,
        includedSalesMarginSpreadUnit: undefined,
        thetaUnit: undefined,
        vegaUnit: undefined,
        preTradeMidMarketMarkPriceUnit: undefined,
      }),
    );
    actions.push(
      createRecomputeRfqRequestedAction(
        rfqId,
        isMasterStrategy
          ? LegRecomputeScope.CHANGE_PRICE_STRAT_MASTER_LEG_MASTER
          : LegRecomputeScope.CHANGE_PRICE_STRAT_NOTMASTER_LEG_MASTER,
        {
          success: {
            dispatchables: [
              createDefaultRfqRequestedAction(
                rfqId,
                new DefaultingOptions({
                  overrideScopes: [DefaultingScope.TRADED_AWAY_FEEDBACK],
                }),
              ),
            ],
          },
        },
      ),
    );

    dispatch(actions);
  };
}
