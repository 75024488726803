import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { hasFutureMaturity } from '@/neos/business/rfq/strategy/leg/product/productModel';
import type { Field } from '../utils/Field';
import { getIsStrategyDefinitionFieldEditable } from '../utils/fieldsEnablingConditions';
import { getFieldRelevantProducts } from '../utils/getFieldRelevantProducts';

export interface FutureMaturityCellsModel {
  futureMaturities: Array<Field<string | undefined>>;
}

export function getFutureMaturityCellsModel(
  appState: AppState,
  rfqId: string,
  strategyId: string,
  sl: Selectors,
): FutureMaturityCellsModel {
  const { getStrategyData, getLegDataByProductId } = sl;
  const { getStrategyDefinition } = sl;

  const { strategyType } = getStrategyData(appState, strategyId);
  const { sameProductMaturity, legs } = getStrategyDefinition(appState.referenceData, strategyType);

  const products = getFieldRelevantProducts(appState, strategyId, sameProductMaturity, sl);

  const derivativeProducts = products.filter(hasFutureMaturity);

  const readOnlyField = legs.every(leg => leg.readOnlyFutureMaturity);

  const isEditable =
    getIsStrategyDefinitionFieldEditable(appState, rfqId, strategyId, sl, [
      'RFQ_SCOPE',
      'RFQ_EDITABLE',
      'UNDERLYING_SET',
      'STRATEGY_EDITABLE',
    ]) && !readOnlyField;

  return {
    futureMaturities: derivativeProducts.map((product): Field<string | undefined> => {
      const legData = getLegDataByProductId(appState, product.uuid);
      if (!legData) {
        throw Error(`No LegData found for product id ${product.uuid}`);
      }
      return {
        id: legData.uuid,
        value: product.futureMaturity,
        isEditable,
      };
    }),
  };
}
