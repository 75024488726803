import type { ActionContainer } from '@/util/actionContainer';
import type {
  NeosPredealCheckDerogationRequestedAction,
  NeosPredealCheckRefreshRequestedAction,
} from './predealCheckActions';
import { predealCheckDomain } from './predealCheckDomain';
import type { Derogation, PredealCheck } from './predealCheckModel';

const predealChecksCrudActions = predealCheckDomain.actions;

export const predealCheckActionCreators = {
  createPredealChecksReceivedAction,
  createPredealCheckDerogationDoneAction,
  predealChecksCrudActions,
  createNeosPredealCheckDerogationRequestedAction,
  createNeosPredealCheckRefreshRequestedAction,
  createPredealChecksRemovedAction,
};

function createPredealChecksReceivedAction(predealChecks: PredealCheck[]) {
  return predealChecks.map(predealCheck =>
    predealChecksCrudActions.upsert(predealCheck.uuid, predealCheck),
  );
}

function createPredealCheckDerogationDoneAction(derogations: Derogation[]) {
  return derogations.map(({ pdcUuid, pdcDerogation }) =>
    predealChecksCrudActions.update(pdcUuid, {
      pdcDerogation,
      uiColor: 'RED_DEROGATED',
      status: 'DEROGATED',
    }),
  );
}

function createNeosPredealCheckDerogationRequestedAction(
  rfqId: string,
  derogation: Derogation,
): NeosPredealCheckDerogationRequestedAction {
  return {
    type: 'NEOS_PREDEAL_CHECK_DEROGATION_REQUESTED',
    rfqId,
    derogation,
  };
}

function createNeosPredealCheckRefreshRequestedAction(
  rfqId: string,
): NeosPredealCheckRefreshRequestedAction {
  return {
    type: 'NEOS_PREDEAL_CHECK_REFRESH_REQUESTED',
    rfqId,
  };
}

function createPredealChecksRemovedAction(predealChecksToRemoveIds: string[]): ActionContainer {
  return predealChecksToRemoveIds.map(predealCheckToRemoveId =>
    predealChecksCrudActions.delete(predealCheckToRemoveId),
  );
}
