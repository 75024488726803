import { useState } from 'react';
import type { LegLinkCellModel } from './getLegLinkCellModel';

export interface LegLinkCellOwnProps {
  rfqId: string;
  strategyId: string;
  legId: string;
}

export type LegLinkCellMapStateProps = LegLinkCellModel;

export interface LegLinkCellDispatchProps {
  onLink: () => void;
}

type LegLinkCellProps = LegLinkCellOwnProps & LegLinkCellMapStateProps & LegLinkCellDispatchProps;

export function LegLinkCellComponent(props: LegLinkCellProps) {
  const [toggleLink, setToggleLink] = useState(false);

  function handleClick() {
    setToggleLink(!toggleLink);
    props.onLink();
  }

  return (
    <button
      key={props.legId}
      className={`d-block text-center btn text-large p-0 w-100 h-100 p-1 btn-primary`}
      disabled={props.disabled}
      onClick={() => handleClick()}
    >
      <span className="icon icon-sm">link</span>
    </button>
  );
}
