import { type FC, useState } from 'react';
import type { TimerState } from './commonTimer/CommonTimer';
import { MdpQuoteTimerEdition } from './mdpQuoteTimer/mdpQuoteTimerEdition/MdpQuoteTimerEdition.container';
import { MdpQuoteTimerTime } from './mdpQuoteTimer/mdpQuoteTimerTime/MdpQuoteTimerTime.container';
import { MdpRfqOrderReceivedTimer } from './mdpRfqTimer/mdpRfqOrderReceivedTimer/MdpRfqOrderReceivedTimer.container';
import { MdpRfqValidityTimer } from './mdpRfqTimer/mdpRfqValidityTimer/MdpRfqTimer.container';

export interface TimersStateProps {
  isMdpQuoteTimerDisplay: boolean;
}

export interface TimersOwnProps {
  rfqId: string;
}

export type TimersProps = TimersOwnProps & TimersStateProps;

export const TimersComponent: FC<TimersProps> = ({ rfqId, isMdpQuoteTimerDisplay }) => {
  const [quoteTimerState, setQuoteTimerState] = useState<TimerState | undefined>(undefined);
  return (
    <>
      {quoteTimerState !== 'STARTED' && (
        <>
          <MdpRfqValidityTimer rfqId={rfqId} />
          <MdpRfqOrderReceivedTimer rfqId={rfqId} />
        </>
      )}
      {isMdpQuoteTimerDisplay && (
        <div className="d-flex">
          <MdpQuoteTimerTime rfqId={rfqId} onTimerStateChanged={setQuoteTimerState} />
          <MdpQuoteTimerEdition rfqId={rfqId} />
        </div>
      )}
    </>
  );
};
