import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { flatMap } from 'lodash';
import type { Reference } from '../../../../neos/business/neosModel';
import { isCustomUnderlyingProduct } from '../models';

export function getNotAggregatedReferences(
  appState: AppState,
  rfqId: string,
  selectors: Selectors,
): Reference[] {
  const rfqReferences = selectors.getReferences(appState, { rfqId });
  const { strategyIds } = selectors.getRfqData(appState, rfqId);
  const notAggregatedUnderlyingIds = flatMap(strategyIds, strategyId => {
    const hasACompositionLeg = selectors.hasACompositionLeg(appState, strategyId, selectors);
    const StrategyLegIds = selectors.getStrategyData(appState, strategyId).legIds;
    const legIds = hasACompositionLeg
      ? [StrategyLegIds[StrategyLegIds.length - 1]]
      : StrategyLegIds;

    const nonCustomLegIds = legIds.filter(legId => {
      const productId = selectors.getLegData(appState, legId).productId;
      const product = selectors.getProduct(appState, productId);
      return !isCustomUnderlyingProduct(product);
    });

    return nonCustomLegIds.map(legId =>
      selectors.getUnderlyingOrRefIdOfLeg(appState, legId, selectors),
    );
  });

  return rfqReferences.filter(({ underlyingId }) =>
    notAggregatedUnderlyingIds.includes(underlyingId),
  );
}
