import type { Thunk } from '@/bootstrap/thunks';
import type { OnyxRfq } from '@/neos/business/rfq/rfqOnyxModel';

export function createNeosLoadNotificationRfqThunk(rfqId: string, notificationRfq: OnyxRfq): Thunk {
  return function neosLoadNotificationRfqThunk(
    dispatch,
    _,
    {
      thunks: {
        neos: {
          createNeosLoadRfqThunk,
          createFinalizeTransactionsLoadThunk,
          createSetNegoOrPostNegoScreenThunk,
        },
      },
    },
  ) {
    dispatch(
      createNeosLoadRfqThunk(notificationRfq),
      createSetNegoOrPostNegoScreenThunk(rfqId),
      createFinalizeTransactionsLoadThunk([rfqId], true),
    );
  };
}
