import type { Thunk } from '@/bootstrap/thunks';
import { valueBy } from '@/util/array/arrayUtils';
import { flatMap, uniq, values } from 'lodash';
import type { ChainOptions } from '../neosActions';

export function createRequestMissingUnderlyingsThunk(
  rfqIds: string[],
  chainOptions?: ChainOptions,
  otherUnderlyingIdsToRetrieve: string[] = [],
): Thunk {
  return function requestMissingUnderlyingsThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { createUnderlyingInfoRequestedAction },
      },
    },
  ) {
    const appState = getState();
    const missingUdlsByRfqId = valueBy(rfqIds, rfqId =>
      selectors.getUnderlyingsIdsWithoutInfo(appState, rfqId, selectors),
    );

    const rfqMissingUnderlyingIds = flatMap(missingUdlsByRfqId, underlyingIds => {
      return values(underlyingIds);
    });
    const allMissingUnderlyingIds = rfqMissingUnderlyingIds.concat(otherUnderlyingIdsToRetrieve);

    const missingUnderlyingIds = uniq(allMissingUnderlyingIds);

    if (missingUnderlyingIds.length > 0) {
      const affectedRfqIds =
        otherUnderlyingIdsToRetrieve.length > 0
          ? rfqIds
          : rfqIds.filter(rfqId => missingUdlsByRfqId[rfqId].length > 0);

      dispatch(
        createUnderlyingInfoRequestedAction(affectedRfqIds, missingUnderlyingIds, chainOptions),
      );
    } else {
      const dispatchables = chainOptions?.success?.dispatchables ?? [];
      dispatchables.forEach(toBeDispatched => dispatch(toBeDispatched));
    }
  };
}
