import type { ElsSectionsActions } from './elsSectionsActionCreators';
import type { ElsSectionsLayout } from './elsSectionsModel';

export function elsSectionsReducer(
  state: ElsSectionsLayout[] = [],
  action: ElsSectionsActions,
): ElsSectionsLayout[] {
  if (action.type === 'UPSERT_ELS_SECTIONS') {
    return action.payload;
  }
  return state;
}
