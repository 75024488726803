import type { Counterpart } from '../../../../neos/business/neosModel';
import type { CounterpartAction } from './counterpartActions';

export const counterpartActionCreators = {
  createCounterpartReceivedAction,
};

export function createCounterpartReceivedAction(counterparts: Counterpart[]): CounterpartAction {
  return {
    type: 'COUNTERPART_RECEIVED',
    counterparts,
  };
}
