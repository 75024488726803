import type { FC } from 'react';

import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import styles from './TabTitleBadge.module.scss';

interface TabTitleBadgeProps {
  isToolTipPresent: boolean;
  infoBadgeValue: InfoBadgeValue;
}

export interface InfoBadgeValue {
  badgeText: string;
  badgeColorClassName: BadgeColorClassName;
  badgeToolTipText?: string;
}

export type BadgeColorClassName = 'bg-info' | 'bg-success';

export const TabTitleBadge: FC<TabTitleBadgeProps> = ({
  infoBadgeValue: { badgeToolTipText, badgeColorClassName, badgeText },
  isToolTipPresent,
}) => (
  <span className={`${styles['badge']} badge ${badgeColorClassName}`} data-e2e="neos-tab-badge">
    <SimpleNeosTooltip disable={!isToolTipPresent} id={'info-badge'} message={badgeToolTipText}>
      <span>{badgeText}</span>
    </SimpleNeosTooltip>
  </span>
);
