import styles from './PredealCheckBullet.module.scss';

export type BulletColor =
  | 'green'
  | 'green-blur'
  | 'amber'
  | 'amber-blur'
  | 'red'
  | 'red-derogated'
  | 'red-blur'
  | 'grey'
  | 'grey-blur'
  | 'unknown';

interface CommonPredealCheckBullet {
  color: BulletColor;
  isLoading: boolean;
  isElementCentered: boolean;
  isSmaller?: boolean;
}

interface SelectablePredealCheckBullet extends CommonPredealCheckBullet {
  uuid: string;
  isSelectable: true;
  isSelected: boolean;
  isUnknown: boolean;
  onSelected(predealCheckId: string): void;
}

interface UnselectablePredealCheckBullet extends CommonPredealCheckBullet {
  isSelectable: false;
}

export type PredealCheckBulletProps = UnselectablePredealCheckBullet | SelectablePredealCheckBullet;

export const PredealCheckBullet = (props: PredealCheckBulletProps) => {
  const { isElementCentered, isLoading, isSmaller, color } = props;
  return isElementCentered ? (
    <div
      className={calculateClassNames(styles, props)}
      onClick={() => {
        if (isSelectablePredealCheckProps(props) && !props.isSelected && !props.isUnknown) {
          props.onSelected(props.uuid);
        }
      }}
      data-testid="predeal-check-bullet-container"
    >
      <div
        className={`bullet ${isSmaller ? 'bullet-sm' : ''} ${styles[color]} ${
          isLoading ? styles.spinningLoader : ''
        } position-relative`}
        data-testid="predeal-check-bullet"
      >
        {color === 'red-derogated' && (
          <i className="icon icon-sm text-white position-absolute-centered">priority_high</i>
        )}
      </div>
    </div>
  ) : (
    <div
      className={`bullet ${isSmaller ? 'bullet-sm' : ''} ${styles[color]} ${
        isLoading ? styles.spinningLoader : ''
      }`}
      data-testid="predeal-check-bullet"
    />
  );
};

function isSelectablePredealCheckProps(
  props: PredealCheckBulletProps,
): props is SelectablePredealCheckBullet {
  return props.isSelectable;
}

function calculateClassNames(styles: CSSModuleClasses, props: PredealCheckBulletProps): string {
  const classes = `${styles['predeal-check-bullet']} mx-1`;

  if (!isSelectablePredealCheckProps(props) || props.isUnknown || props.isLoading) {
    return classes;
  }
  if (props.isSelected) {
    return `${classes} ${styles['predeal-check-selected']}`;
  }
  return `${classes} ${styles['predeal-check-selectable']} ${styles['predeal-check-unselected']}`;
}
