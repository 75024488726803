import { NeosTooltip } from '@/neos/components/share/tooltip/NeosTooltip';
import { useState } from 'react';
import { Tooltip } from 'react-bootstrap';
import type { TabId } from '../NeosTabs';
import { TabTitle } from '../tabTitle/TabTitle.container';
import { OpenFromClipboardTooltip } from './OpenFromClipboardTooltip';
import { type RfqIdClipboardResult, getRfqIdsFromClipboard } from './getRfqIdsFromClipboard';

export interface NavTabsOwnProps {
  kind: 'MAIN' | 'SECONDARY';
}

export interface NavTabsStateProps {
  tabIds: TabId[];
  selectedTabId: string;
  canAdd: boolean;
}
export interface NavTabsDispatchProps {
  onTabAdded: () => void;
  onTabCloned: (rfqId: string) => void;
  onTabOpened: (rfqIds: string[]) => void;
}

type NavTabsProps = NavTabsOwnProps & NavTabsStateProps & NavTabsDispatchProps;

export const NavTabsUnconnected = (props: NavTabsProps) => {
  const [rfqClipboardResult, setRfqClipboardResult] = useState<RfqIdClipboardResult | null>(null);

  async function pasteAction() {
    const result = await getRfqIdsFromClipboard();
    if (result.status === 'SUCCESS') {
      props.onTabOpened(result.rfqIds);
    }
  }

  return (
    <ul
      className="nav nav-pills nav-hover align-items-stretch border-bottom border-1 border-opacity-70"
      role="tablist"
    >
      {props.tabIds.map(tabId => (
        <TabTitle key={tabId === 'BLOTTER' ? tabId : tabId.rfqId} tabId={tabId} />
      ))}
      {props.canAdd && (
        <>
          <div className="d-flex ms-auto">
            {
              <NeosTooltip
                placement="left"
                onEntering={async () => {
                  const result = await getRfqIdsFromClipboard();
                  setRfqClipboardResult(result);
                }}
                overlay={
                  <Tooltip id="open-from-clipboard" key={JSON.stringify(rfqClipboardResult ?? {})}>
                    <OpenFromClipboardTooltip rfqClipboardResult={rfqClipboardResult} />
                  </Tooltip>
                }
              >
                <button
                  className="h-100 nav-item btn btn-xl btn-flat-primary btn-icon"
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onClick={pasteAction}
                  data-e2e="neos-tab-paste-button"
                >
                  <i className="icon icon-md icon-outlined">content_paste_search</i>
                </button>
              </NeosTooltip>
            }
            {props.selectedTabId !== 'BLOTTER' && (
              <button
                className="h-100 nav-item btn btn-xl btn-flat-primary btn-icon"
                title="Clone tab"
                onClick={() => props.onTabCloned(props.selectedTabId)}
                data-e2e="neos-tab-clone-button"
              >
                <i className="icon">content_copy</i>
              </button>
            )}
            <button
              className="h-100 nav-item btn btn-xl btn-flat-primary btn-icon"
              title="Add a new tab"
              onClick={props.onTabAdded}
              data-e2e="neos-tab-add-button"
            >
              <i className="icon">add</i>
            </button>
          </div>
        </>
      )}
    </ul>
  );
};
