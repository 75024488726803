import { Fragment, useState } from 'react';
import { ConfirmModal } from '../confirmModal';

interface ButtonWithConfirmModalProps {
  isDisabled?: boolean;
  onConfirm: () => void;
  buttonText: string;
  title: string;
  modalBody: string[];
  ['data-e2e']: string;
  children?: any;
}

export const ButtonWithConfirmModal = (props: ButtonWithConfirmModalProps) => {
  const { isDisabled, onConfirm, buttonText, title, modalBody, 'data-e2e': dataE2e } = props;
  const [isModalShown, setIsModalShown] = useState<boolean>(false);

  return (
    <>
      <button
        disabled={isDisabled}
        data-e2e={dataE2e}
        className="btn btn-secondary"
        onClick={() => setIsModalShown(true)}
      >
        {buttonText}
      </button>
      <ConfirmModal
        title={title}
        isModalShown={isModalShown}
        onHide={() => setIsModalShown(false)}
        onConfirm={() => {
          onConfirm();
        }}
      >
        <p>
          {modalBody.map(b => (
            <Fragment key={b}>
              {b}
              <br />
            </Fragment>
          ))}
        </p>
      </ConfirmModal>
    </>
  );
};
