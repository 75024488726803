import type { Thunk } from '@/bootstrap/thunks';

export function createDeleteOtcAllocationThunk(
  rfqId: string,
  legId: string,
  allocationId: string,
  allocationBookingId: string | undefined,
): Thunk {
  return function deleteOtcAllocationThunk(dispatch, _, { actionCreators }) {
    const hasAllocationBookingId = allocationBookingId !== undefined;

    if (hasAllocationBookingId) {
      dispatch(
        actionCreators.neos.otcAllocationCrudActions.update(
          { allocationId, rfqId, legId },
          { isCancelled: true },
        ),
      );
    } else {
      dispatch(
        actionCreators.neos.otcAllocationCrudActions.delete({ allocationId, rfqId, legId }),
        actionCreators.neos.allocationUiCrudActions.delete(allocationId),
      );
    }
  };
}
