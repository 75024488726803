import type { ThunkEpic } from '@/bootstrap/epics';
import { type Thunks, thunks } from '@/bootstrap/thunks';
import { type MailRequestApi, createMailRequestApi } from '@/common/business/mail/mailRequestApi';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { ofType } from 'redux-observable';
import { catchError, mergeMap } from 'rxjs/operators';
import type { OnyxError } from '../mappers/error';

export function getMailRequestEpic(http: SgmeHttp): ThunkEpic {
  const api = createMailRequestApi(http);
  return createMailRequestEpic(api, thunks);
}

export function createMailRequestEpic(
  api: MailRequestApi,
  { createErrorToasterThunk, createSuccessToasterThunk }: Thunks,
): ThunkEpic {
  return action$ =>
    action$.pipe(
      ofType('MAIL_PRICING_REQUESTED'),
      mergeMap(({ mailRequest, succesMessage, errorMessage }) => {
        return api.sendEmail(mailRequest).pipe(
          mergeMap(_ => [createSuccessToasterThunk({ message: succesMessage })]),
          catchError((error: OnyxError) => [
            createErrorToasterThunk(
              {
                message: errorMessage,
              },
              error,
            ),
          ]),
        );
      }),
    );
}
