import { idCreator } from '@/util/id';
import type { Observable } from 'rxjs';
import type { AjaxRequest, AjaxResponse } from 'rxjs/ajax';
import type { AsyncLogger } from '../logging/asyncLogger';
import signalRService from '../notifications/signalRService';
import { type AjaxCallParams, SgmeAjaxHttpClient } from './sgmeAjaxHttpRequest';

export interface HttpCall<T> {
  payload: T;
  timeTaken: number;
}

type AjaxCallParamsWithoutMethod = Omit<AjaxCallParams, 'method'>;

export interface SgmeHttp {
  ajax<T>(params: AjaxCallParams): Observable<T>;

  get<T>(params: AjaxCallParamsWithoutMethod): Observable<T>;
  head<T>(params: AjaxCallParamsWithoutMethod): Observable<T>;
  post<T>(params: AjaxCallParamsWithoutMethod): Observable<T>;
  put<T>(params: AjaxCallParamsWithoutMethod): Observable<T>;
  delete<T>(params: AjaxCallParamsWithoutMethod): Observable<T>;
}

export interface AjaxAlike {
  (urlOrRequest: string | AjaxRequest): Observable<AjaxResponse>;
}

function buildCorrelationId(headers?: object) {
  return Object.keys(headers || {}).some(header => header === 'x-correlation-id')
    ? undefined
    : { 'x-correlation-id': idCreator.createId() };
}

export function createSgmeHttpUsingInjectedLogger(
  getAuthorizationHeader: () => string | null,
  ajaxApi: AjaxAlike,
  injectedLogger: AsyncLogger | undefined,
): SgmeHttp {
  function buildHeaders(headers?: object) {
    return {
      'Content-Type': 'application/json',
      ...headers,
      Authorization: getAuthorizationHeader(),
      ...buildCorrelationId(headers),
      'sgme-user-datastream-id': signalRService.datastreamId,
    };
  }

  const httpClient = new SgmeAjaxHttpClient(ajaxApi, buildHeaders, injectedLogger);
  return {
    ajax<T>(params: AjaxCallParams): Observable<T> {
      return httpClient.request(params);
    },

    get<T>(params: AjaxCallParamsWithoutMethod): Observable<T> {
      return httpClient.request({ ...params, method: 'GET' });
    },
    head<T>(params: AjaxCallParamsWithoutMethod): Observable<T> {
      return httpClient.request({ ...params, method: 'HEAD' });
    },
    post<T>(params: AjaxCallParamsWithoutMethod): Observable<T> {
      return httpClient.request({ ...params, method: 'POST' });
    },
    put<T>(params: AjaxCallParamsWithoutMethod): Observable<T> {
      return httpClient.request({ ...params, method: 'PUT' });
    },
    delete<T>(params: AjaxCallParamsWithoutMethod): Observable<T> {
      return httpClient.request({ ...params, method: 'DELETE' });
    },
  };
}
