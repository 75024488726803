import { createRemoveRateSchedulePeriodThunk } from '@/neos/business/rfq/strategy/leg/product/els/thunks/rateSchedulePeriod/removeRateSchedulePeriodThunk.ts';
import { createAddRateSchedulePeriodThunk } from '@/neos/business/rfq/strategy/leg/product/els/thunks/rateSchedulePeriod/addRateSchedulePeriodThunk.ts';
import { createUpdateRateSchedulePeriodThunk } from '@/neos/business/rfq/strategy/leg/product/els/thunks/rateSchedulePeriod/updateRateSchedulePeriodThunk.ts';
import { createRemoveEquitySchedulePeriodThunk } from '@/neos/business/rfq/strategy/leg/product/els/thunks/equitySchedulePeriod/removeEquitySchedulePeriodThunk.ts';
import { createAddEquitySchedulePeriodThunk } from '@/neos/business/rfq/strategy/leg/product/els/thunks/equitySchedulePeriod/addEquitySchedulePeriodThunk.ts';
import { createUpdateEquitySchedulePeriodThunk } from '@/neos/business/rfq/strategy/leg/product/els/thunks/equitySchedulePeriod/updateEquitySchedulePeriodThunk.ts';
import { createRemoveAllEquitySchedulePeriodThunk } from '@/neos/business/rfq/strategy/leg/product/els/thunks/equitySchedulePeriod/removeAllEquitySchedulePeriodThunk.ts';
import { createAddBreakFeePeriodThunk } from '@/neos/business/rfq/strategy/leg/product/els/thunks/breakFeesPeriod/addBreakFeePeriodThunk.ts';
import { createUpdateBreakFeePeriodThunk } from '@/neos/business/rfq/strategy/leg/product/els/thunks/breakFeesPeriod/updateBreakFeePeriodThunk.ts';
import { createRemoveBreakFeePeriodThunk } from '@/neos/business/rfq/strategy/leg/product/els/thunks/breakFeesPeriod/removeBreakFeePeriodThunk.ts';
import { createRemoveAllRateSchedulePeriodThunk } from '@/neos/business/rfq/strategy/leg/product/els/thunks/rateSchedulePeriod/removeAllRateSchedulePeriodThunk.ts';
import { createImportScheduleLegsDataThunk } from '@/neos/business/rfq/strategy/leg/product/els/thunks/importScheduleLegsDataThunk.ts';
import { createEnableElsSectionThunk } from '@/neos/business/rfq/strategy/leg/product/els/thunks/enableElsSectionThunk.ts';
import { createDisableElsSectionThunk } from '@/neos/business/rfq/strategy/leg/product/els/thunks/disableElsSectionThunk.ts';
import { createToggleElsSectionThunk } from '@/neos/business/rfq/strategy/leg/product/els/thunks/toggleElsSectionThunk.ts';
import { createChangeScheduleStartOrEndDateThunk } from '@/neos/business/rfq/strategy/leg/product/els/thunks/changeScheduleStartOrEndDateThunk.ts';
import { createUpdateBreakFeeElectionThunk } from '@/neos/business/rfq/strategy/leg/product/els/thunks/breakFeeElection/updateBreakFeeElectionThunk.ts';
import { createUpdateProductAndReferenceWithImportedBasketDetailsThunk } from '@/neos/business/rfq/strategy/leg/product/els/thunks/updateProductAndReferenceWithImportedBasketDetailsThunk.ts';
import { createImportBasketCompositionThunk } from '@/neos/business/rfq/strategy/leg/product/els/thunks/importBasketCompositionThunk.ts';
import { createHandleBasketUnderlyingsReceivedThunk } from '@/neos/business/rfq/strategy/leg/product/els/thunks/handleBasketUnderlyingsReceivedThunk.ts';
import { createDefaultOnChangeBasketCurrencyThunk } from '@/neos/business/rfq/strategy/leg/thunks/defaultOnChangeBasketCurrencyThunk.ts';
import { createDefaultBasketProductThunk } from '@/neos/business/rfq/strategy/leg/thunks/defaultBasketProductThunk.ts';
import { createToggleElsLendAndBorrowFeatureThunk } from '@/neos/business/rfq/strategy/leg/product/els/thunks/createToggleElsLendAndBorrowFeatureThunk.ts';
import { createUpdateElsStockLoanHedgeThunk } from '@/neos/business/rfq/strategy/leg/product/els/thunks/createUpdateElsStockLoanHedgeThunk.ts';
import { createUpdateElsStockLoanComponentThunk } from '@/neos/business/rfq/strategy/leg/product/els/thunks/createUpdateElsStockLoanComponentThunk.ts';
import { createAddNewElsStockLoanComponentThunk } from '@/neos/business/rfq/strategy/leg/product/els/thunks/createAddNewElsStockLoanComponentThunk.ts';
import { createExportElsBasketCompositionThunk } from '@/neos/business/rfq/strategy/leg/product/els/thunks/createExportElsBasketCompositionThunk.ts';
import { createAddNewElsBasketCompositionItemThunk } from '@/neos/business/rfq/strategy/leg/product/els/thunks/createAddNewElsBasketCompositionItemThunk.ts';
import { createUpdateElsBasketCompositionThunk } from '@/neos/business/rfq/strategy/leg/product/els/thunks/createUpdateElsBasketCompositionThunk.ts';
import { createChangeElsBasketExecFeeKindThunk } from '@/neos/business/rfq/strategy/leg/product/els/thunks/createChangeElsBasketExecFeeKindThunk.ts';
import { createCleanBasketCompositionExecFees } from '@/neos/business/rfq/strategy/leg/product/thunks/createDeleteKeyInBasketCompositionThunk.ts';
import { createUpdateBasketCompositionFeesUnit } from '@/neos/business/rfq/strategy/leg/product/els/thunks/createUpdateBasketCompositionFeesUnitThunk.ts';
import { createUpdateElsBasketThunk } from '@/neos/business/rfq/strategy/leg/product/els/thunks/createUpdateElsBasketThunk.ts';
import { createUpdateElsStockLoanHedgePortfolioThunk } from '@/neos/business/rfq/strategy/leg/product/els/thunks/createUpdateElsStockLoanHedgePortfolioThunk.ts';
import { createUpdateElsStockLoanHedgeComponentPortfolioThunk } from '@/neos/business/rfq/strategy/leg/product/els/thunks/createUpdateElsStockLoanHedgeComponentPortfolioThunk.ts';
import { createToggleElsBuyAndSellFeatureThunk } from '@/neos/business/rfq/strategy/leg/product/els/thunks/createToggleElsBuyAndSellFeatureThunk.ts';
import { createUpdateElsEquityHedgeThunk } from '@/neos/business/rfq/strategy/leg/product/els/thunks/createUpdateElsEquityHedgeThunk.ts';
import { createAddNewElsEquityHedgeComponentThunk } from '@/neos/business/rfq/strategy/leg/product/els/thunks/createAddNewElsEquityHedgeComponentThunk.ts';
import { createUpdateElsEquityComponentThunk } from '@/neos/business/rfq/strategy/leg/product/els/thunks/createUpdateElsEquityHedgeComponentThunk.ts';
import { createUpdateElsEquityHedgeBrokerThunk } from '@/neos/business/rfq/strategy/leg/product/els/thunks/createUpdateElsEquityHedgeBrokerThunk.ts';
import { createChangeScheduleRateLegStartOrEndDateThunk } from '@/neos/business/rfq/strategy/leg/product/els/thunks/changeScheduleRateLegStartOrEndDateThunk.ts';
import { createUpdateElsBasketCompositionUnderlyingThunk } from '@/neos/business/rfq/strategy/leg/product/els/thunks/createUpdateElsBasketCompositionUnderlyingThunk.ts';
import { createUpdateElsEquityHedgeComponentUnderlyingThunk } from '@/neos/business/rfq/strategy/leg/product/els/thunks/createUpdateElsEquityHedgeComponentUnderlyingThunk.ts';
import { createUpdateElsStockLoanHedgeComponentUnderlyingThunk } from '@/neos/business/rfq/strategy/leg/product/els/thunks/createUpdateElsStockLoanHedgeComponentUnderlyingThunk.ts';

export const elsProductThunks = {
  createUpdateElsStockLoanHedgeComponentPortfolioThunk,
  createUpdateElsStockLoanHedgePortfolioThunk,
  createRemoveRateSchedulePeriodThunk,
  createAddRateSchedulePeriodThunk,
  createUpdateRateSchedulePeriodThunk,
  createRemoveEquitySchedulePeriodThunk,
  createAddEquitySchedulePeriodThunk,
  createUpdateEquitySchedulePeriodThunk,
  createRemoveAllEquitySchedulePeriodThunk,
  createAddBreakFeePeriodThunk,
  createUpdateBreakFeePeriodThunk,
  createRemoveBreakFeePeriodThunk,
  createRemoveAllRateSchedulePeriodThunk,
  createImportScheduleLegsDataThunk,
  createEnableElsSectionThunk,
  createDisableElsSectionThunk,
  createToggleElsSectionThunk,
  createChangeScheduleStartOrEndDateThunk,
  createChangeScheduleRateLegStartOrEndDateThunk,
  createUpdateElsBasketCompositionUnderlyingThunk,
  createUpdateElsEquityHedgeComponentUnderlyingThunk,
  createUpdateElsStockLoanHedgeComponentUnderlyingThunk,
  createUpdateBreakFeeElectionThunk,
  createUpdateProductAndReferenceWithImportedBasketDetailsThunk,
  createImportBasketCompositionThunk,
  createHandleBasketUnderlyingsReceivedThunk,
  createDefaultOnChangeBasketCurrencyThunk,
  createDefaultBasketProductThunk,
  createToggleElsLendAndBorrowFeatureThunk,
  createUpdateElsStockLoanHedgeThunk,
  createUpdateElsStockLoanComponentThunk,
  createAddNewElsStockLoanComponentThunk,
  createExportElsBasketCompositionThunk,
  createAddNewElsBasketCompositionItemThunk,
  createUpdateElsBasketCompositionThunk,
  createChangeElsBasketExecFeeKindThunk,
  createCleanBasketCompositionExecFees,
  createUpdateBasketCompositionFeesUnit,
  createUpdateElsBasketThunk,
  createToggleElsBuyAndSellFeatureThunk,
  createUpdateElsEquityHedgeThunk,
  createAddNewElsEquityHedgeComponentThunk,
  createUpdateElsEquityComponentThunk,
  createUpdateElsEquityHedgeBrokerThunk,
};
