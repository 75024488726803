import type { Status } from '../neosModel';

export function orderOf(status: Status) {
  return new StatusOrder(status);
}

const orderedStatuses: Status[] = [
  'NEW',
  'CAPTURED',
  'TO_BE_REQUESTED',
  'PRICE_REQUESTED',
  'BEING_PRICED',
  'PRICED',
  'PRICE_PROPOSED',
  'ORDER_RECEIVED',
  'PENDING_DELTA_INSTRUCTIONS',
  'ORDER_CONFIRMED',
  'PRE_TRADE_BOOKING_REQUESTED',
  'PRE_TRADE_BEING_BOOKED',
  'PRE_TRADE_POSITION_BOOKED',
  'ORDER_ACCEPTED',
  'PLACED',
  'DELTA_BEING_EXECUTED',
  'DELTA_EXECUTED',
  'DELTA_MANUALLY_EXECUTED',
  'ORDER_BEING_CROSSED',
  'ORDER_MANUALLY_CROSSED',
  'ORDER_CROSSED',
  'BOOKING_REQUESTED',
  'POSITION_BEING_BOOKED',
  'POSITION_BOOKED',
  'TRADE_BEING_COMPLETED',
  'TRADE_TO_BE_COMPLETED',
  'TRADED',
  'TRADED_EXTERNALLY',
  'ORDER_BEING_EXECUTED',
  'ORDER_EXECUTED',
  'BEING_BOOKED',
  'BOOKED',
  'TRADE_COMPLETED',
  'TRADE_BEING_CANCELLED',
  'TRADE_TO_BE_CANCELLED',
  'TRADE_CANCELLED',
  'AMEND_REQUESTED',
  'BEING_AMENDED',
  'DEAL_AMENDED',
  'AMEND_BEING_COMPLETED',
  'AMEND_COMPLETED',
  'EXPIRED',
  'INDICATIVE_COMPLETED',
  'CANCELLED',
];
const ordered = new Map<Status, number>();
orderedStatuses.forEach((v, i) => ordered.set(v, i));

export class StatusOrder {
  private _index: number;

  constructor(status: Status) {
    const i = ordered.get(status);
    if (i === undefined) {
      throw new Error(`Can't find status ${status}`);
    }
    this._index = i;
  }

  public isAfter = (...statuses: Status[]) => {
    return statuses.every(s => {
      const indexOfOther = ordered.get(s);
      if (indexOfOther === undefined) {
        throw new Error(`Can't find status ${s}`);
      }

      return this._index > indexOfOther;
    });
  };

  public isBefore = (...statuses: Status[]) => {
    return statuses.every(s => {
      const indexOfOther = ordered.get(s);
      if (indexOfOther === undefined) {
        throw new Error(`Can't find status ${s}`);
      }

      return this._index < indexOfOther;
    });
  };

  public isAfterOrEqual = (...statuses: Status[]) => {
    return statuses.every(s => {
      const indexOfOther = ordered.get(s);
      if (indexOfOther === undefined) {
        throw new Error(`Can't find status ${s}`);
      }

      return this._index >= indexOfOther;
    });
  };

  public isBeforeOrEqual = (...statuses: Status[]) => {
    return statuses.every(s => {
      const indexOfOther = ordered.get(s);
      if (indexOfOther === undefined) {
        throw new Error(`Can't find status ${s}`);
      }

      return this._index <= indexOfOther;
    });
  };

  public isBetween = (from: Status, to: Status) => {
    return this.isAfterOrEqual(from) && this.isBeforeOrEqual(to);
  };

  public isBetweenRightExclude = (from: Status, to: Status) => {
    return this.isAfterOrEqual(from) && this.isBefore(to);
  };

  public isBetweenLeftExclude = (from: Status, to: Status) => {
    return this.isAfter(from) && this.isBeforeOrEqual(to);
  };
}
