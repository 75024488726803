import { SimpleDatePicker } from '@/neos/components/share/datePicker/SimpleDatePicker';
import { isAfter, isBefore, parse } from 'date-fns';
import { forwardRef } from 'react';
import { DATE_SHORT_KEBAB_FORMAT } from '@/util/date/dateFormatHelper.ts';

export type DoubleDate = [Date | null, Date | null];

export interface DoubleDatePickerProps {
  dateFrom: string;
  dateTo: string;
  onDateRangeChange: (dates: DoubleDate) => void;
  ['data-e2e']?: string;
  className?: string;
  clearable?: boolean;
  readOnly?: boolean;
}

export const DoubleDatePicker = forwardRef<HTMLDivElement, DoubleDatePickerProps>(
  (props, parentRef) => {
    const { dateFrom, dateTo, onDateRangeChange, clearable, ...restProps } = props;

    const startDate = dateFrom ? parse(dateFrom, DATE_SHORT_KEBAB_FORMAT, 0) : null;
    const endDate = dateTo ? parse(dateTo, DATE_SHORT_KEBAB_FORMAT, 0) : null;

    const onStartDateChange = (startDateUpdated: Date | null) => {
      if (startDateUpdated === null) {
        onDateRangeChange([startDateUpdated, endDate]);
        return;
      }

      const today = new Date();
      const endDateOrToday = endDate ?? today;
      const endDateUpdated = isAfter(startDateUpdated, endDateOrToday)
        ? new Date(startDateUpdated)
        : endDateOrToday;

      onDateRangeChange([startDateUpdated, endDateUpdated]);
    };

    const onEndDateChange = (endDateUpdated: Date | null) => {
      if (endDateUpdated === null) {
        onDateRangeChange([startDate, endDateUpdated]);
        return;
      }

      const today = new Date();
      const startDateOrToday = startDate ?? today;
      const startDateUpdated = isBefore(endDateUpdated, startDateOrToday)
        ? new Date(endDateUpdated)
        : startDateOrToday;

      onDateRangeChange([startDateUpdated, endDateUpdated]);
    };

    return (
      <div ref={parentRef} className="d-flex" style={{ width: clearable ? '23em' : '21em' }}>
        <SimpleDatePicker
          readOnly={props.readOnly}
          data-e2e={restProps['data-e2e'] + '-start'}
          date={startDate}
          onChange={onStartDateChange}
          clearable={clearable}
        />
        <div className="d-flex align-self-center mx-1">to</div>
        <SimpleDatePicker
          readOnly={props.readOnly}
          data-e2e={restProps['data-e2e'] + '-end'}
          date={endDate}
          onChange={onEndDateChange}
          clearable={clearable}
        />
      </div>
    );
  },
);
