import { getElsStockLoanHedgePortfolioWay } from '@/neos/business/rfq/strategy/leg/product/els/selectors/getElsStockLoanHedgePortfolioWay.ts';
import type { AppState } from '@/bootstrap/state.ts';
import {
  type EquityHedgeType,
  type InternalEquityHedgeType,
  internalEquityHedgeTypes,
  type OtherEquityHedgeType,
  otherEquityHedgeTypes,
} from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel.ts';
import type { Selectors } from '@/bootstrap/selectors.ts';
import type { SelectOptions } from '@/neos/components/share/NeosSelect/NeosSelect.tsx';

export const elsSelectors = {
  getElsStockLoanHedgePortfolioWay,
  isElsEquityHedgeTypeInternal,
  getEquityHedgeTypeAndOptions,
};

export function isElsEquityHedgeTypeInternal(
  state: AppState,
  rfqId: string,
  equityType: EquityHedgeType | undefined,
  selectors: Selectors,
): equityType is InternalEquityHedgeType {
  return selectors.isRfqInternalEls(state, rfqId, selectors);
}

type EquityHedgeTypeAndOptions<T extends EquityHedgeType> = {
  equityType: T | undefined;
  equityTypeOptions: SelectOptions<T>;
  isElsEquityHedgeTypeInternal: T extends InternalEquityHedgeType ? true : false;
};

export function getEquityHedgeTypeAndOptions(
  state: AppState,
  rfqId: string,
  equityType: EquityHedgeType | undefined,
  selectors: Selectors,
):
  | EquityHedgeTypeAndOptions<OtherEquityHedgeType>
  | EquityHedgeTypeAndOptions<InternalEquityHedgeType> {
  const isElsEquityHedgeTypeInternal = selectors.isElsEquityHedgeTypeInternal(
    state,
    rfqId,
    equityType,
    selectors,
  );

  if (isElsEquityHedgeTypeInternal) {
    return {
      equityType,
      equityTypeOptions: internalEquityHedgeTypes,
      isElsEquityHedgeTypeInternal: true,
    };
  }

  return {
    equityType,
    equityTypeOptions: otherEquityHedgeTypes,
    isElsEquityHedgeTypeInternal: false,
  };
}
