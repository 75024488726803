import type { Thunk } from '@/bootstrap/thunks';
import type { ActionContainer } from '@/util/actionContainer';
import { RfqRecomputeScope } from '../../neosModel';

export function createChangeRfqMidThunk(
  rfqId: string,
  mid: number | undefined,
  isPtmmm: boolean,
): Thunk {
  return function changeRfqMidThunk(
    dispatch,
    getState,
    {
      selectors: { getRfqData },
      actionCreators: {
        neos: { quoteCrudActions, fairPriceCrudActions, createActionWithRecompute },
      },
    },
  ) {
    const appState = getState();
    const { fairPriceId, quoteId } = getRfqData(appState, rfqId);

    const action: ActionContainer[] = [];

    if (isPtmmm) {
      action.push(quoteCrudActions.update(quoteId, { preTradeMidMarketMarkPrice: mid }));
    }

    if (fairPriceId) {
      action.push(fairPriceCrudActions.update(fairPriceId, { mid }));
    }

    if (action.length > 0) {
      dispatch(createActionWithRecompute(rfqId, RfqRecomputeScope.CHANGE_PTMMM, action));
    }
  };
}
