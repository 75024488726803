import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { NegotiationMode } from '../../../../../../../neos/business/neosModel';
import type { Field } from '../utils/Field';
import { getFieldsArray } from '../utils/getFieldsArray';

export interface NegoTypeCellModel {
  negoTypes: Array<Field<NegotiationMode>>;
}

export function getNegoTypeCellModel(
  appState: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): NegoTypeCellModel {
  const { getStrategyData, getStrategyProducts, isReadOnlyRfq, getStrategyDefinition } = selectors;

  const { strategyType } = getStrategyData(appState, strategyId);
  const { sameProductNegotiationMode, readOnlyProductNegotiationMode } = getStrategyDefinition(
    appState.referenceData,
    strategyType,
  );

  const products = getStrategyProducts(appState, strategyId, selectors);
  const readonlyRfq = isReadOnlyRfq(appState, rfqId);

  const shouldBeReadOnlyOnSecondaryRfq =
    selectors.isSecondaryOrMixedRfq(appState, rfqId) &&
    !selectors.isPrimaryStrategy(appState, strategyId, selectors);

  const isOtcAndAmendWorkflowStatus = selectors.isOtcStrategyAndAmendWorkflowStatus(
    appState,
    rfqId,
    strategyId,
    selectors,
  );
  const isReadonlyAtWorkflow = selectors.isReadOnlyAtCurrentWorkflow(
    appState,
    strategyId,
    selectors,
  );
  const isReadonly =
    readOnlyProductNegotiationMode ||
    readonlyRfq ||
    shouldBeReadOnlyOnSecondaryRfq ||
    isOtcAndAmendWorkflowStatus ||
    isReadonlyAtWorkflow;
  const negotiationModes = getFieldsArray(
    products,
    'negotiationMode',
    sameProductNegotiationMode,
    isReadonly,
  );

  return {
    negoTypes: negotiationModes,
  };
}
