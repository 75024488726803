import { crudDomain } from '@/util/crudUtils';
import type { FieldChange } from '../changesNotificationModel';
import {
  type QuoteNotificationChangesKey,
  getQuoteNotificationChangesId,
  getQuoteNotificationChangesKey,
} from '../quoteNotificationChanges/quoteNotificationChangesDomain';

export type TraderBidNotificationChangesKey = QuoteNotificationChangesKey;

const getTraderBidNotificationChangesId = getQuoteNotificationChangesId;
const getTraderBidNotificationChangesKey = getQuoteNotificationChangesKey;

export const traderBidNotificationChangesDomain = crudDomain<
  FieldChange,
  TraderBidNotificationChangesKey
>('traderBidNotificationChanges', {
  keyToString: getTraderBidNotificationChangesId,
  stringToKey: getTraderBidNotificationChangesKey,
});
