import type { Dispatchable } from '@/bootstrap/thunks';
import type { CrudActionCreators } from '@/util/crudUtils';
import type { AlertsRequestedAction } from './alertActions';
import { alertDomain } from './alertDomain';
import type { OnyxAlert } from './alertOnyxModel';

export const alertActionCreators = {
  createAlertCrudAction: alertDomain.actions,
  createAlertRequestedAction,
  createAlertLoadedBatchAction,
};

function createAlertRequestedAction(): AlertsRequestedAction {
  return {
    type: 'ALERTS_REQUESTED_ACTION',
  };
}

function createAlertLoadedBatchAction(
  { upsertMany }: CrudActionCreators<OnyxAlert, string>,
  onyxAlerts: OnyxAlert[],
): Dispatchable {
  const items = onyxAlerts.map(alert => ({ id: alert.uuid, value: alert }));
  return upsertMany(items);
}
