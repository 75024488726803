import type { Thunk } from '@/bootstrap/thunks';
import { isSingleUnderlyingDerivativeProduct } from '../rfq/models';
import type { FromFieldUnderlyingChangedParameters } from '../underlyingInfo/underlyingInfoActions';

export function createNeosBlurProductUnderlyingThunk(
  rfqId: string,
  underlyingChangedParameter: FromFieldUnderlyingChangedParameters,
): Thunk {
  return function neosBlurProductUnderlyingThunk(
    dispatch,
    getState,
    {
      thunks: {
        neos: { createNeosChangeRfqUnderlyingThunk, createLegBlurredThunk },
      },
      selectors: { getLegData, getProduct },
    },
  ) {
    const appState = getState();
    const { legId } = underlyingChangedParameter;
    const { productId } = getLegData(appState, legId);
    const product = getProduct(appState, productId);
    if (
      isSingleUnderlyingDerivativeProduct(product) &&
      underlyingChangedParameter.underlyingId !== product.underlyingId
    ) {
      dispatch(createNeosChangeRfqUnderlyingThunk(rfqId, underlyingChangedParameter));
    }

    dispatch(createLegBlurredThunk(rfqId, legId));
  };
}
