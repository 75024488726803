import type {
  DefaultProductValuesForStrategy,
  Product,
  Stock,
} from '../../../../../../../../../neos/business/neosModel';
import { getCommonPart, getListedNegotiationPart } from './getDefaultedPartialProduct';

export function getDefaultedStockProduct(
  currentProduct: Product,
  defaultValues: DefaultProductValuesForStrategy,
): Stock {
  return {
    subFamily: 'STOCK',
    isDerivativeProduct: false,
    ...getCommonPart(currentProduct, defaultValues),
    ...getListedNegotiationPart(currentProduct),
    hasCustomUnderlying: false,
  };
}
