import type { FC } from 'react';
import type { ReplicateInDeltaButtonModel } from './getReplicateInDeltaButtonModel';

import styles from './ReplicateInDeltaButton.module.scss';

export interface ReplicateInDeltaButtonOwnProps {
  rfqId: string;
}

export interface ReplicateInDeltaButtonStateProps {
  model: ReplicateInDeltaButtonModel;
}

export interface ReplicateInDeltaButtonDispatchProps {
  onButtonClicked: () => void;
}

type ReplicateInDeltaButtonProps = ReplicateInDeltaButtonOwnProps &
  ReplicateInDeltaButtonStateProps &
  ReplicateInDeltaButtonDispatchProps;

export const ReplicateInDeltaButtonComponent: FC<ReplicateInDeltaButtonProps> = ({
  model: { isDisabled },
  onButtonClicked,
}) => {
  return (
    <button
      className={`${styles['apply-to-delta-button']} btn btn-discreet-secondary`}
      onClick={() => onButtonClicked()}
      disabled={isDisabled}
      data-e2e="apply-listed-prealloc-to-delta"
    >
      Replicate in Delta Pre Alloc
    </button>
  );
};
