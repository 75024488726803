import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { FromOnyxMappers, ToOnyxMappers } from '@/neos/business/mappers';
import { flatMap } from 'lodash';
import type {
  LegData,
  ListedAllocation,
  OtcAllocation,
  Product,
  Reference,
} from '../../../../neos/business/neosModel';
import type {
  OnyxLeg,
  OnyxProductUnderlying,
  OnyxStrategy,
  OnyxStrategyReference,
} from '../../../../neos/business/neosOnyxModel';
import type { Feature } from './feature/featureModel';
import type { DeltaStockListedAllocation } from './leg/deltaStockListedAllocation/deltaStockListedAllocationModel';
import { fromLegMappers, toLegMappers } from './leg/legMapper';
import type { OnyxNegotiatedSize } from './leg/legOnyxModel';
import {
  type CommonStrategyData,
  type StrategyData,
  isRfqStrategyData,
} from './strategyData/strategyDataModel';

export const fromStrategyMappers = {
  mapFromOnyxStrategy,
  mapFromOnyxUnderlying,
  ...fromLegMappers,
};

export interface MapFromOnyxStrategyResult {
  strategyData: StrategyData;
  referencesState: Reference[];
  legsData: LegData[];
  products: Product[];
  features: Feature[];
  listedAllocations: ListedAllocation[];
  otcAllocations: OtcAllocation[];
  deltaStockListedAllocations: DeltaStockListedAllocation[];
}

export function mapFromOnyxStrategy(
  rfqId: string,
  onyxStrategy: OnyxStrategy,
  mappers: FromOnyxMappers,
): MapFromOnyxStrategyResult {
  const {
    uuid,
    activityType,
    comment,
    strategyType,
    weight,
    traderDiffusion,
    determinationMethod,
    initialPriceDetermination,
    master,
    references: onyxReferences,
    valueDate,
    deltaType,
    discriminator: scope,
    strategyTypeInfo,
    type,
    handlingInstruction,
    timeInForce,
    limitPrice,
    compoNegotiatedSize,
    screenPrice,
    bookingInfo,
    priceUnitType,
    crossPartially,
    broker,
    executionType,
    swapsWireSgTrader,
    bookingId,
  } = onyxStrategy;
  const legIds = onyxStrategy.legs.map(l => l.uuid);

  const referencesState: Reference[] = mappers.mapFromOnyxReferences(onyxReferences);

  const legMapper = (leg: OnyxLeg) => mappers.mapFromOnyxLeg(leg, uuid, mappers, scope);
  const legs = onyxStrategy.legs.map(legMapper);
  const resultOfLegsMapping = legs.map(leg => {
    const { features, ...restLeg } = leg;
    return restLeg;
  });

  const hasBeenLoadedWithEmptyReferenceFields = referencesState.some(
    reference =>
      reference.refLevel === undefined ||
      reference.refSpot === undefined ||
      reference.refBasis === undefined,
  );

  const commonStrategyData: CommonStrategyData = {
    uuid,
    rfqId,
    activityType,
    comment,
    strategyType,
    weight: weight || 1,
    initialWeight: weight || 1,
    priceUnitType,
    legIds,
    valueDate,
    determinationMethod,
    initialPriceDetermination,
    traderGroupName: traderDiffusion?.traderGroup?.value,
    traderGroupId: traderDiffusion?.traderGroup?.id,
    traderId: traderDiffusion?.trader?.id,
    tradingBusiness: traderDiffusion?.tradingBusiness,
    isMasterStrategy: master,
    quoteId: uuid,
    fairPriceId: uuid,
    top: strategyTypeInfo?.top,
    underlyingId: strategyTypeInfo?.underlyingTop?.id,
    screenPrice,
    portfolioId: bookingInfo?.portfolioId,
    operatingCenterId: bookingInfo?.operatingCenterId,
    etdDeskCity: traderDiffusion?.etdDeskCity,
    executor: traderDiffusion?.executor,
    crossPartially,
    broker,
    executionType,
    hasBeenLoadedWithEmptyReferenceFields,
    swapsWireSgTraderAccount: swapsWireSgTrader?.swapsWireAccount,
    swapsWireSgTraderAccountType: swapsWireSgTrader?.swapsWireAccountType,
    mirrorPortfolioId: bookingInfo?.mirrorPortfolioId,
  };
  const { features } = legs[0];

  const localNotional = compoNegotiatedSize?.localNotional ?? compoNegotiatedSize?.notional;
  const strategyData: StrategyData =
    scope === 'RFQ'
      ? {
          ...commonStrategyData,
          scope,
          localNotional: localNotional?.value,
          localNotionalUnit: localNotional?.unit,
          notional: compoNegotiatedSize?.notional?.value,
          notionalUnit: compoNegotiatedSize?.notional?.unit,
          numberOfLots: compoNegotiatedSize?.numberOfLots,
          quantity: compoNegotiatedSize?.quantity,
          sizeType: compoNegotiatedSize?.sizeType,
          varUnit: compoNegotiatedSize?.varUnit?.value,
        }
      : {
          ...commonStrategyData,
          scope,
          type,
          handlingInstruction,
          timeInForce,
          limitPriceValue: limitPrice?.value,
          limitPriceUnit: limitPrice?.unit,
          bookingApplication: bookingId?.application,
          bookingId: bookingId?.id,
        };

  if (isRfqStrategyData(strategyData) && deltaType) {
    strategyData.deltaType = deltaType;
  }

  return {
    strategyData,
    referencesState,
    legsData: resultOfLegsMapping.map(({ legData }) => legData),
    products: resultOfLegsMapping.map(({ product }) => product),
    features,
    listedAllocations: flatMap(resultOfLegsMapping, ({ listedAllocations }) => listedAllocations),
    otcAllocations: flatMap(resultOfLegsMapping, ({ otcAllocations }) => otcAllocations),
    deltaStockListedAllocations: flatMap(
      resultOfLegsMapping,
      ({ deltaStockListedAllocations }) => deltaStockListedAllocations,
    ),
  };
}

function mapFromOnyxUnderlying(
  onyxProductUnderlying: Nullable<OnyxProductUnderlying> | undefined,
): string | undefined {
  return onyxProductUnderlying?.id;
}

export const toStrategyMappers = {
  mapToOnyxStrategy,
  mapToOnyxUnderlying,
  ...toLegMappers,
};

function mapToOnyxStrategy(
  state: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
  mappers: ToOnyxMappers,
): OnyxStrategy {
  const strategyData = selectors.getStrategyData(state, strategyId);
  const {
    comment,
    activityType,
    strategyType,
    uuid,
    legIds,
    determinationMethod,
    initialPriceDetermination,
    traderId,
    traderGroupName,
    traderGroupId,
    etdDeskCity,
    executor,
    tradingBusiness,
    isMasterStrategy,
    weight,
    fairPriceId,
    valueDate,
    top,
    underlyingId,
    screenPrice,
    portfolioId,
    operatingCenterId,
    priceUnitType,
    crossPartially,
    broker,
    executionType,
    swapsWireSgTraderAccount,
    swapsWireSgTraderAccountType,
    mirrorPortfolioId,
  } = strategyData;

  const isOtc = selectors.isOtcStrategy(state, strategyId, selectors);

  const references: OnyxStrategyReference[] = mappers.mapToOnyxReferences(
    state,
    rfqId,
    strategyId,
    selectors,
    mappers,
  );

  const deltaType = isRfqStrategyData(strategyData) ? strategyData.deltaType : undefined;

  const {
    type,
    handlingInstruction,
    timeInForce,
    limitPriceValue,
    limitPriceUnit,
    bookingId,
    bookingApplication,
  } =
    strategyData.scope !== 'RFQ'
      ? strategyData
      : {
          type: undefined,
          handlingInstruction: undefined,
          timeInForce: undefined,
          limitPriceValue: undefined,
          limitPriceUnit: undefined,
          bookingApplication: undefined,
          bookingId: undefined,
        };

  const deltaStrategyOnyxBookingId: OnyxStrategy['bookingId'] =
    strategyData.scope !== 'RFQ'
      ? {
          application: bookingApplication,
          id: bookingId,
        }
      : undefined;

  return {
    discriminator: strategyData.scope,
    weight,
    uuid,
    activityType,
    deltaType,
    valueDate,
    strategyType,
    determinationMethod,
    initialPriceDetermination,
    priceUnitType,
    comment,
    strategyTypeInfo: {
      top,
      underlyingTop: mappers.mapToOnyxUnderlying(state, underlyingId, selectors),
      strategyType,
    },
    compoNegotiatedSize: mapToOnyxCompositionSize(),
    traderDiffusion: {
      trader: traderId ? { id: traderId } : undefined,
      traderGroup: traderGroupName ? { value: traderGroupName, id: traderGroupId } : undefined,
      etdDeskCity,
      executor,
      tradingBusiness,
    },
    legs: legIds.map(legId =>
      mappers.mapToOnyxLeg({
        state,
        rfqId,
        strategyId,
        legId,
        selectors,
        mappers,
      }),
    ),
    master: isMasterStrategy,
    quote: mappers.mapToOnyxQuoteForStrategy(state, strategyId, selectors),
    references,
    fairPrice: mappers.mapToOnyxFairPrice(state, fairPriceId, selectors),
    preAllocs: isOtc
      ? mappers.mapToOnyxOtcPreAllocs(state, rfqId, strategyId, selectors)
      : mappers.mapToOnyxListedPreAllocs(state, rfqId, strategyId, selectors),
    type,
    handlingInstruction,
    timeInForce,
    limitPrice:
      limitPriceValue !== undefined
        ? {
            value: limitPriceValue,
            unit: limitPriceUnit,
          }
        : undefined,
    screenPrice,
    bookingInfo: {
      operatingCenterId,
      portfolioId,
      mirrorPortfolioId,
    },
    crossPartially,
    broker: isOtc ? undefined : broker,
    executionType,
    swapsWireSgTrader:
      swapsWireSgTraderAccount !== undefined || swapsWireSgTraderAccountType !== undefined
        ? {
            swapsWireAccount: swapsWireSgTraderAccount,
            swapsWireAccountType: swapsWireSgTraderAccountType,
          }
        : undefined,
    bookingId: deltaStrategyOnyxBookingId,
  };

  function mapToOnyxCompositionSize(): OnyxNegotiatedSize | undefined {
    const hasACompositionLeg = selectors.hasACompositionLeg(state, strategyId, selectors);
    if (!hasACompositionLeg || strategyData.scope !== 'RFQ') {
      return undefined;
    }
    const { numberOfLots, notional, notionalUnit, sizeType, quantity, varUnit } = strategyData;
    return {
      numberOfLots,
      notional: notionalUnit || notional ? { unit: notionalUnit, value: notional } : undefined,
      sizeType,
      quantity,
      varUnit: varUnit ? { value: varUnit, unit: 'VU' } : undefined,
    };
  }
}

function mapToOnyxUnderlying(
  state: AppState,
  underlyingId: string | undefined,
  selectors: Selectors,
): OnyxProductUnderlying | undefined {
  if (!underlyingId) {
    return undefined;
  }
  const underlyingInfo = selectors.getUnderlyingInfo(state, underlyingId);

  if (!underlyingInfo) {
    throw Error(`Underlying Info not found for ${underlyingId}`);
  }
  const { type, currency, bloombergCode } = underlyingInfo;
  return {
    id: underlyingId,
    type,
    currency,
    bloombergCode,
  };
}
