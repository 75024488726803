import {
  type Mdp,
  type Source,
  mapMdpSourceToBadgeText,
  mapSourceToBadgeText,
} from '@/neos/business/neosOnyxModel';

export const sourceCellRenderer = (source: Source) => {
  if (!source || source === 'VOICE') {
    return <span></span>;
  }
  const badgeText = mapSourceToBadgeText(source);

  return (
    <span
      className="
      badge bg-success"
      style={{ fontSize: '10px', fontWeight: 'normal', marginRight: '10px' }}
    >
      {badgeText ?? mapMdpSourceToBadgeText(source as Mdp)}
    </span>
  );
};
