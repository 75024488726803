import type { Thunk } from '@/bootstrap/thunks.ts';
import { DefaultingOptions } from '@/neos/business/rfq/rfqActions.ts';
import type { EBreakFeeElectionType } from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel.ts';
import { assertProductIsEls, DefaultingScope } from '../../../../../../../neosModel.ts';

export function createUpdateBreakFeeElectionThunk(
  strategyId: string,
  rfqId: string,
  newBreakFeeElection: EBreakFeeElectionType | undefined,
): Thunk {
  return function updateBreakFeeElectionThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: {
          productCrudActions: { update: updateProduct },
          createDefaultRfqRequestedAction,
        },
      },
    },
  ) {
    const appState = getState();

    const product = selectors.getStrategyProduct(appState, strategyId, selectors);

    assertProductIsEls(product);

    dispatch([
      updateProduct(product.uuid, { breakFeeElection: newBreakFeeElection }),
      createDefaultRfqRequestedAction(
        rfqId,
        new DefaultingOptions({
          overrideScopes: [
            DefaultingScope.BREAK_FEE,
            DefaultingScope.CONFIRM_MEDIA,
            DefaultingScope.RELATED_EXCHANGE,
            DefaultingScope.SPECIAL_DIVIDENDS,
            DefaultingScope.LOCAL_TAXES,
          ],
        }),
      ),
    ]);
  };
}
