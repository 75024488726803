import {
  Children,
  type ReactElement,
  type ReactNode,
  cloneElement,
  isValidElement,
  useEffect,
  useState,
} from 'react';

interface PeriodicOwnProps {
  delay: number;
  onHide: () => void;
  children: any;
}

export const HideAfter = (props: PeriodicOwnProps) => {
  const { delay, children, onHide, ...rest } = props;
  const [visible, setVisible] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setVisible(false);
      onHide();
    }, delay);
  });

  return visible
    ? Children.map(children, c =>
        isValidElement(c as ReactNode) ? cloneElement(c as ReactElement, rest) : (c ?? null),
      )
    : null;
};
