import type { Thunk } from '@/bootstrap/thunks';
import { uniq, escape } from 'lodash';
import type { OnyxIbChatResponse, OnyxRfq } from '../rfqOnyxModel';

export function createIntegrateReceivedIbChatThunk(
  rfqId: string,
  { rfq, trustLevel, messages, chat }: OnyxIbChatResponse,
): Thunk {
  return function integrateReceivedIbChatThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { rfqUiCrudActions, createNextUserActionsRequestedAction },
      },
      thunks: { neos: thunks, createErrorToasterThunk, createWarningToasterThunk },
    },
  ) {
    const state = getState();
    if (
      !rfq ||
      !rfq.strategies ||
      !rfq.strategies.length ||
      rfq.strategies.some(strategy => !strategy.legs.length)
    ) {
      return dispatch(
        createErrorToasterThunk(
          {
            message:
              messages && messages.length ? formatMessages(messages) : 'Error requesting IB Chat!',
            allowHTML: true,
          },
          undefined,
          false,
        ),
      );
    }
    const { editableStrategies } = selectors.getStrategyConfiguration(state.referenceData);
    const uneditableStrategyTypes = uniq(
      rfq.strategies.map(s => s.strategyType).filter(st => !editableStrategies.includes(st)),
    );

    if (uneditableStrategyTypes.length) {
      const plural =
        uneditableStrategyTypes.length === 1 ? { s: '', are: 'is' } : { s: 's', are: 'are' };

      return dispatch(
        createWarningToasterThunk({
          message: `Strategy Type${plural.s}: ${uneditableStrategyTypes} ${plural.are} not<br> supported`,
          allowHTML: true,
        }),
      );
    }

    const { status, subStatus, version } = selectors.getRfqData(state, rfqId);
    const updatedOnyxRfq: OnyxRfq = {
      ...rfq!,
      lifecycle: {
        status,
        subStatus,
      },
      version,
      uuid: rfqId,
    };

    dispatch(
      thunks.createLoadTransactionsThunk([
        {
          rfq: updatedOnyxRfq,
          bookingSteps: [],
          preConfirmationSteps: [],
          pdcs: [],
          workflow: undefined,
          orders: undefined,
          allocationInstructions: undefined,
          preConfirmationMail: undefined,
        },
      ]),
      createNextUserActionsRequestedAction(rfqId),
      rfqUiCrudActions.patchOrInsert(rfqId, { ibChatTrustLevel: trustLevel, ibChat: chat }),
    );

    if (messages && messages.length) {
      dispatch(
        createWarningToasterThunk({
          message: formatMessages(messages),
          allowHTML: true,
        }),
      );
    }
  };
}

function formatMessages(messages: string[]): string {
  return messages.map(escape).join('<br />');
}
