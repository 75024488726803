import type { NextUserAction } from '@/neos/business/nextUserActions/nextUserActionsModel';
import type { FC } from 'react';
import { ButtonWithConfirmModal } from '../../../../../share/buttonWithConfirm/ButtonWithConfirmModal';

export interface DeltaAdjustedCancelButtonOwnProps {
  rfqId: string;
}

export type DeltaAdjustedCancelButtonModel =
  | {
      isDisabled: false;
      executeDeltaManualyNextAction: NextUserAction;
    }
  | {
      isDisabled: true;
    };

export interface DeltaAdjustedCancelButtonStateProps {
  model: DeltaAdjustedCancelButtonModel;
}
export interface DeltaAdjustedCancelButtonDispatchProps {
  cancel: (nextAction: NextUserAction) => void;
}

export type DeltaAdjustedCancelButtonProps = DeltaAdjustedCancelButtonOwnProps &
  DeltaAdjustedCancelButtonStateProps &
  DeltaAdjustedCancelButtonDispatchProps;

export const DeltaAdjustedCancelButtonComponent: FC<DeltaAdjustedCancelButtonProps> = ({
  model,
  cancel,
}) => {
  const modalBody = [
    'You are about to cancel orders, change(s) on rfq will also be saved',
    'Do you confirm?',
  ];
  return (
    <ButtonWithConfirmModal
      isDisabled={model.isDisabled}
      onConfirm={() => !model.isDisabled && cancel(model.executeDeltaManualyNextAction)}
      buttonText="Cancel"
      title="Cancel order"
      data-e2e={'neos-rfq-buttonConfirm-amend'}
      modalBody={modalBody}
    />
  );
};
