import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { FeatureComponentModel } from '../sharedFeatureComponentModel';

export type CapFloorModel = FeatureComponentModel<{
  displayUnit: string | undefined;
  floorValue: number | undefined;
  capValue: number | undefined;
}>;

export function getCapFloorModel(
  state: AppState,
  strategyId: string,
  selectors: Selectors,
): CapFloorModel {
  const isDisplayed = selectors.isStrategyFeatureMandatoryOrDefined(
    state,
    {
      strategyId,
      type: 'CAP_FLOOR',
    },
    selectors,
  );

  if (!isDisplayed) {
    return { isDisplayed };
  }

  const feature = selectors.getFeature(state.featureState, {
    strategyId,
    type: 'CAP_FLOOR',
  });

  const { capValue, capUnit, floorValue, floorUnit } = feature
    ? feature
    : { capValue: undefined, capUnit: undefined, floorValue: undefined, floorUnit: undefined };

  const unit = capUnit || floorUnit;
  return {
    isDisplayed,
    displayUnit: unit === 'x' ? '✕' : unit,
    floorValue,
    capValue,
  };
}
