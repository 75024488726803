import type { Reference, UnderlyingInfo } from '../../../../../neos/business/neosModel';
import { isUnderlyingIndexType } from '../../../../../neos/business/neosOnyxModel';

export interface RefThresholdWarning {
  isWarningDisplayed: boolean;
  warningText: string | undefined;
}

export interface RefComparedValue {
  value: number | undefined;
  type: 'Ref Adjusted' | 'Average Exec. Price';
}

export function getRefThresholdWarning(
  threshold: number,
  { value: comparedValue, type: comparedValueType }: RefComparedValue,
  { refSpot, refLevel }: Pick<Reference, 'refSpot' | 'refLevel'>,
  underlyingInfo: UnderlyingInfo | undefined,
): RefThresholdWarning {
  const refToCompare = underlyingInfo
    ? isUnderlyingIndexType(underlyingInfo.type)
      ? refLevel
      : refSpot
    : undefined;

  if (!underlyingInfo || !comparedValue || !refToCompare) {
    return { isWarningDisplayed: false, warningText: undefined };
  }

  const isOverThreshold =
    comparedValue < refToCompare - (refToCompare * threshold) / 100 ||
    comparedValue > refToCompare + (refToCompare * threshold) / 100;

  return {
    isWarningDisplayed: isOverThreshold,
    warningText: isOverThreshold
      ? `The difference between the ${comparedValueType} and the ${
          isUnderlyingIndexType(underlyingInfo.type) ? 'Ref level' : 'Ref spot'
        } is greater than ${threshold}%`
      : undefined,
  };
}
