import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { getIsStrategyDefinitionFieldEditable } from '@/neos/components/rfq/strategies/strategy/strategyDefinition/utils/fieldsEnablingConditions';
import { type Tenor, isProductWithStrikeDateTenor } from '../../../../../../business/neosModel';
import type { Field } from '../utils/Field';
import { getFieldsArray } from '../utils/getFieldsArray';

export interface StrikeDateModel {
  availableMaturities: string[][];
  strikeDates: Array<Field<string | undefined>>;
  isProductWithStrikeDate: boolean;
  tenors: Tenor[];
}

export function getStrikeDateModel(
  appState: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): StrikeDateModel {
  const { strategyType: selectedStrategyType, legIds } = selectors.getStrategyData(
    appState,
    strategyId,
  );
  const products = selectors.getStrategyProducts(appState, strategyId, selectors);
  const {
    sameProductStrikeDate,
    readOnlyProductStrikeDate,
    legs: strategyDefinitionLegs,
  } = selectors.getStrategyDefinition(appState.referenceData, selectedStrategyType);

  const strikeDateProducts = products
    .filter((_, index) => strategyDefinitionLegs.at(index)?.strikeDate === true)
    .filter(isProductWithStrikeDateTenor);

  const isReadonlyAtWorkflow = selectors.isReadOnlyAtCurrentWorkflow(
    appState,
    strategyId,
    selectors,
  );
  const isStrategyDefinitionFieldEditable = getIsStrategyDefinitionFieldEditable(
    appState,
    rfqId,
    strategyId,
    selectors,
    ['RFQ_SCOPE', 'RFQ_EDITABLE', 'UNDERLYING_SET', 'STRATEGY_EDITABLE'],
  );

  const disabled =
    isReadonlyAtWorkflow || readOnlyProductStrikeDate || !isStrategyDefinitionFieldEditable;

  const strikeDates = getFieldsArray(
    strikeDateProducts,
    'strikeDate',
    sameProductStrikeDate,
    disabled,
  );

  const availableMaturities = legIds.map(legId =>
    selectors.getLegMaturities(appState, legId, selectors),
  );

  const product = selectors.getStrategyProduct(appState, strategyId, selectors);

  const tenors = selectors.getTenors(appState.referenceData);
  const isProductWithStrikeDate = isProductWithStrikeDateTenor(product);

  return {
    strikeDates,
    availableMaturities,
    isProductWithStrikeDate,
    tenors,
  };
}
