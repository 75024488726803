import type { ThunkEpic } from '@/bootstrap/epics';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { combineEpics } from 'redux-observable';
import { getSearchByBookingIdEpic } from '../../epics/searchByBookingIdEpic';
import { getLoadBlotterPreconEpic } from '../blotterPreconf/getLoadBlotterPreconfEpic';
import { getCancelRfqsEpic } from './getCancelRfqsEpic';
import { getLoadRfqsEpic } from './getLoadRfqsEpic';

export function getBlotterRootEpic(http: SgmeHttp): ThunkEpic {
  return combineEpics(
    getLoadRfqsEpic(http),
    getSearchByBookingIdEpic(http),
    getCancelRfqsEpic(http),
    getLoadBlotterPreconEpic(http),
  );
}
