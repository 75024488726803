import type { Thunk } from '@/bootstrap/thunks';

export function createChangeLegRatioThunk(
  rfqId: string,
  strategyId: string,
  legId: string,
  weight: number,
): Thunk {
  return function changeLegRatioThunk(
    dispatch,
    _,
    {
      thunks: {
        neos: { createUpdateLegThunk, createRfqResetStrategyQuoteAndFairPricesThunk },
      },
    },
  ) {
    dispatch(
      createUpdateLegThunk(strategyId, legId, 'weight', weight),
      createRfqResetStrategyQuoteAndFairPricesThunk(rfqId, strategyId, 'STRATEGY'),
    );
  };
}
