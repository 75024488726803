import type { BookingStep } from '@/neos/business/bookingSteps/BookingStepsSelectors';
import type { BookingStepStatus } from '@/neos/business/bookingSteps/bookingStepOnyxModel';

export interface BookingStatusMessage {
  className: 'error' | 'warning' | undefined;
  message: string | undefined;
}

export function getBookingStatus(bookingStep: BookingStep | undefined): {
  status: BookingStepStatus | undefined;
  bookingStatus: BookingStatusMessage;
} {
  if (bookingStep?.isReference) {
    const { status, message } = bookingStep;
    let className: 'error' | 'warning' | undefined = undefined;

    if (status === 'FAILED' || status === 'SKIPPED' || status === 'ABORTED') {
      className = 'error';
    } else if (message) {
      className = 'warning';
    }

    return {
      status,
      bookingStatus: { className, message },
    };
  }

  return {
    status: undefined,
    bookingStatus: { className: undefined, message: undefined },
  };
}

export function getBookingStatusOnly(bookingStep: BookingStep | undefined): {
  status: BookingStepStatus | undefined;
  message: string | undefined;
} {
  return {
    status: bookingStep?.isReference ? bookingStep.status : undefined,
    message: bookingStep?.isReference ? bookingStep.message : undefined,
  };
}

export function getBookingStatusAsIs(bookingStep: BookingStep | undefined): {
  status: BookingStepStatus | undefined;
  bookingStatus: BookingStatusMessage;
} {
  if (!bookingStep) {
    return {
      status: undefined,
      bookingStatus: { className: undefined, message: undefined },
    };
  }

  const { status, message } = bookingStep;
  let className: 'error' | 'warning' | undefined = undefined;

  if (status === 'FAILED' || status === 'SKIPPED' || status === 'ABORTED') {
    className = 'error';
  } else if (message) {
    className = 'warning';
  }

  return {
    status,
    bookingStatus: { className, message },
  };
}
