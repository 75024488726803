import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { thunks } from '@/bootstrap/thunks';
import { CalculationAgent } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/OtherBloc/CalculationAgent';
import { ComponentSecurityIndexAnnex } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/OtherBloc/ComponentSecurityIndexAnnex';
import { DeclareCashDiv } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/OtherBloc/DeclareCashDiv';
import { DeterminingParty } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/OtherBloc/DeterminingParty';
import { DividendType } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/OtherBloc/DividendType';
import { HedgingPartyComponent } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/OtherBloc/HedgingParty';
import { LinearInterpolationComponent } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/OtherBloc/LinearInterpolation';
import { LocalTaxes } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/OtherBloc/LocalTaxes';
import { LookthroughDRComponent } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/OtherBloc/LookthroughDR';
import { RelatedExchange } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/OtherBloc/RelatedExchange';
import { RoleDefinitionComponent } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/OtherBloc/RoleDefinition';
import { useUpdateSectionHeight } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/useUpdateSectionHeight';
import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isElsProduct } from '../../../../../../../business/neosModel';
import { ElsFeaturesContext, commonElsBlocClasses } from '../ElsFeatures';

interface OtherBlocProps {
  canBeHidden: boolean;
}

export function OtherBloc({ canBeHidden }: OtherBlocProps) {
  const { strategyId } = useContext(ElsFeaturesContext);
  const dispatch = useDispatch();
  const product = useSelector((state: AppState) =>
    selectors.getStrategyProduct(state, strategyId, selectors),
  );
  const { ref } = useUpdateSectionHeight(strategyId, 'Other');

  if (!isElsProduct(product)) {
    return null;
  }

  function hideSection() {
    dispatch(thunks.neos.createToggleElsSectionThunk('Other', false));
  }

  return (
    <div className={commonElsBlocClasses} style={{ cursor: 'grab' }}>
      <div ref={ref}>
        <div className="d-flex justify-content-between">
          <h5 className="d-inline-block p-1 m-0 mb-1">Other</h5>
          {canBeHidden && (
            <button className="btn btn-icon btn-flat-primary" onClick={hideSection}>
              <i className="icon">clear</i>
            </button>
          )}
        </div>

        <div className="d-flex flex-wrap">
          <RoleDefinitionComponent />
          <CalculationAgent />
          <DeterminingParty />
          <HedgingPartyComponent />
          <LinearInterpolationComponent />
          <LookthroughDRComponent />
          <RelatedExchange />
          <ComponentSecurityIndexAnnex />
          <LocalTaxes />
          <DeclareCashDiv />
          <DividendType />
        </div>
      </div>
    </div>
  );
}
