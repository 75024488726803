import { appReadyActionCreators } from './appReady/appReadyActionCreators';
import { globalErrorActionCreators } from './globalError/globalErrorActionCreators';
import { helpRequestActionCreators } from './helpRequest/helpRequestActionCreators';
import { logActionCreators } from './log/logActionCreators';
import { userInfoActionCreators } from './userInfo/userInfoActionCreators';

export const commonActionCreators = {
  ...appReadyActionCreators,
  ...globalErrorActionCreators,
  ...logActionCreators,
  ...userInfoActionCreators,
  ...helpRequestActionCreators,
};
