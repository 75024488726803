import type { AppState } from '@/bootstrap/state';
import type { OnyxAlert } from './alertOnyxModel';

export const alertSelectors = {
  getAlertRecordLength,
  getAlertsAsArray,
};

function getAlertRecordLength({ alerts }: AppState): number {
  return Object.keys(alerts).length;
}

function getAlertsAsArray({ alerts }: AppState): OnyxAlert[] {
  return Object.values(alerts);
}
