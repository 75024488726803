import type { Dispatchable } from '@/bootstrap/thunks';
import { type Observable, merge } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { rfqUiActionCreators } from '../ui/rfq/rfqUiActionCreators';

function toToArray(dispatchable: any): any[] {
  if (dispatchable instanceof Array) {
    return dispatchable;
  }
  return [dispatchable];
}

export interface WrapInLoadingOptions<T> {
  tabIds: string[];
  apiObservable: Observable<T>;
  onSuccess: (result: T) => Dispatchable[] | Dispatchable;
  onError: (error: any) => Dispatchable[] | Dispatchable;
}

export function wrapInLoadingObservable<T>({
  tabIds,
  apiObservable,
  onSuccess,
  onError,
}: WrapInLoadingOptions<T>): Observable<Dispatchable> {
  const loadingAction = (isLoading: boolean) =>
    tabIds.map(tabId => rfqUiActionCreators.rfqUiCrudActions.patchOrInsert(tabId, { isLoading }));
  return merge(
    loadingAction(true),
    apiObservable.pipe(
      mergeMap(result => [...loadingAction(false), ...toToArray(onSuccess(result))]),
      catchError(err => [...loadingAction(false), ...toToArray(onError(err))]),
    ),
  );
}
