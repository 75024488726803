import type { FC } from 'react';
import type { ResetAllocationsButtonModel } from './getResetAllocationsButtonModel';

export interface ResetAllocationsButtonOwnProps {
  rfqId: string;
}

export interface ResetAllocationsButtonStateProps {
  model: ResetAllocationsButtonModel;
}

export interface ResetAllocationsButtonDispatchProps {
  onButtonClicked: () => void;
}

type ResetAllocationsButtonProps = ResetAllocationsButtonOwnProps &
  ResetAllocationsButtonStateProps &
  ResetAllocationsButtonDispatchProps;

export const ResetAllocationsButtonComponent: FC<ResetAllocationsButtonProps> = ({
  model: { isDisabled },
  onButtonClicked,
}) => {
  return (
    <button
      className="btn btn-primary"
      data-e2e="reset-allocations-button"
      onClick={onButtonClicked}
      disabled={isDisabled}
    >
      Reset Allocations
    </button>
  );
};
