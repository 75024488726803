import type { Thunk } from '@/bootstrap/thunks';
import type { UnderlyingChangeData } from '@/neos/business/neosSelectors/underlyingChangeData';

export function createChangeStrategyUnderlyingThunk(
  rfqId: string,
  changeData: UnderlyingChangeData,
  underlyingId: string | undefined,
): Thunk {
  return function changeStrategyUnderlyingThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { strategyDataCrudActions },
      },
      thunks: {
        neos: { createChangeProductUnderlyingThunk },
      },
    },
  ) {
    const state = getState();
    const { affectedStrategyId, affectedLegIds, isStrategyUnderlyingAffected } = changeData;
    const {
      strategyType,
      legIds,
      underlyingId: strategyUnderlyingId,
    } = selectors.getStrategyData(state, affectedStrategyId);
    const { hasACompositionLeg } = selectors.getStrategyDefinition(
      state.referenceData,
      strategyType,
    );

    if (hasACompositionLeg) {
      dispatch(
        strategyDataCrudActions.update(affectedStrategyId, {
          underlyingId: getTargetStrategyUnderlying(),
        }),
      );
    }
    return dispatch(createChangeProductUnderlyingThunk(rfqId, changeData, underlyingId));

    function getTargetStrategyUnderlying() {
      if (isStrategyUnderlyingAffected) {
        return underlyingId;
      }

      const affectedLegIsIndexLeg =
        affectedLegIds.length === 1 && affectedLegIds[0] === legIds[legIds.length - 1];

      if (!affectedLegIsIndexLeg) {
        return undefined;
      }

      return strategyUnderlyingId;
    }
  };
}
