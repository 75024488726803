import { hasDiscriminator } from '@/neos/business/rfq/strategy/feature/hasDiscriminator';
import type { WithExtraProperty } from '@/neos/business/rfq/strategy/feature/withExtraProperty';
import { convertNullToUndefined } from '@/util/undefinedAndNull/convertNullToUndefined';
import type { EndOfObservationFeature } from '../../../../neosModel';
import type { OnyxEndOfObservation, OnyxProductFeaturesFields } from '../../../../neosOnyxModel';

export function mapFromOnyxFeatureOrExtraFeaturesToEndOfObservation(
  { extraFeatures, endOfObservation: definitionEndOfObservation }: OnyxProductFeaturesFields,
  strategyId: string,
): EndOfObservationFeature | undefined {
  const mappedEndOfObservation: Pick<EndOfObservationFeature, 'strategyId' | 'type'> = {
    strategyId,
    type: 'END_OF_OBSERVATION',
  };
  const endOfObservation = convertNullToUndefined(definitionEndOfObservation);
  if (endOfObservation !== undefined) {
    return { ...mappedEndOfObservation, endOfObservation };
  }

  if (extraFeatures) {
    const endOfObservationFeature: OnyxEndOfObservation | undefined = extraFeatures.find(
      hasDiscriminator<OnyxEndOfObservation>('END_OF_OBSERVATION'),
    );
    if (endOfObservationFeature) {
      return {
        ...mappedEndOfObservation,
        endOfObservation: convertNullToUndefined(endOfObservationFeature.endOfObservation),
      };
    }
  }

  return undefined;
}

export function mapToOnyxEndOfObservation(
  feature: WithExtraProperty<EndOfObservationFeature>,
): OnyxProductFeaturesFields {
  return feature.isExtra
    ? {
        extraFeatures: [
          {
            discriminator: 'END_OF_OBSERVATION',
            endOfObservation: feature.endOfObservation,
          },
        ],
      }
    : {
        endOfObservation: feature.endOfObservation,
      };
}
