import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { isDefined } from '@/util/undefinedAndNull/isDefined';
import { type DeltaPredealCheck, isDeltaPredealCheck } from '../predealCheck/predealCheckModel';

export function getNeosDeltaPredealChecks(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): DeltaPredealCheck[] {
  const salesCounterpartyId = selectors.getSelectedCounterpartId(state, rfqId);
  const { predealCheckIds } = selectors.getRfqData(state, rfqId);
  return predealCheckIds
    .map(id => selectors.getPredealCheck(state, id))
    .filter(isDefined)
    .filter(({ counterpartyId }) => counterpartyId === salesCounterpartyId)
    .filter(isDeltaPredealCheck);
}
