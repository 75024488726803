import type { DerogationKey } from '@/neos/business/predealCheck/predealCheckModel';
import { NeosBlurInput } from '@/neos/components/share/blurComponent/NeosBlurInput';
import { NeosBlurTextarea } from '@/neos/components/share/blurComponent/NeosBlurTextarea';
import type { FC } from 'react';
import type { PredealCheckDetailsModel } from './getPredealCheckDetailsModel';

import { FormSelect } from 'react-bootstrap';
import styles from './PredealCheckDetails.module.scss';

export interface PredealChecksDetailsOwnProps {
  rfqId: string;
}

export interface PredealChecksDetailsDispatchProps {
  onDerogate(derogateAll: boolean, derogationKey: DerogationKey): void;

  onDerogationRiskIdChange(preadealCheckId: string, value: string, oldValue: string): void;

  onDerogationCommentChange(preadealCheckId: string, value: string, oldValue: string): void;

  onClose(): void;
}

export type PredealChecksDetailsPropsFromState = PredealCheckDetailsModel;

export type PredealChecksDetailsProps = PredealChecksDetailsOwnProps &
  PredealChecksDetailsDispatchProps &
  PredealChecksDetailsPropsFromState;

export const PredealChecksDetailsWithStyle: FC<PredealChecksDetailsProps> = props => {
  const { detail, predealCheckTitle, bdrId } = props;
  const messages = detail.messages.filter(({ message }) => message);
  const technicalMessages = detail.technicalMessages.filter(({ message }) => message);

  return (
    <div>
      <div className={`${styles['pdc-details']} card card-bordered`}>
        <div className={`${styles['heading']} `}>
          <div className="fw-bold" data-e2e="predeal-check-title">
            <span>
              {predealCheckTitle} - {bdrId}
            </span>
          </div>
          <div className="d-flex align-items-center gap-4">
            {detail.flags.map(({ label, value }) => (
              <span key={label} data-e2e="predeal-check-flag">
                <i className={`icon ${getIconStyle(value)}`} aria-hidden="true">
                  {getEligibilityIcon(value)}
                </i>
                <span className="ps-2">{label}</span>
              </span>
            ))}
            <button onClick={() => props.onClose()} className="btn btn-flat-primary btn-icon">
              <i className="icon icon-xs">clear</i>
            </button>
          </div>
        </div>
        <div className="card-body">
          {messages.length === 0 && technicalMessages.length === 0 ? (
            <div className={`${styles['main-messages']} `} data-e2e="predeal-check-no-message">
              No message
            </div>
          ) : (
            <div className={`${styles['main-messages']} `}>
              {messages.map(({ label, message }, index) => {
                if (label === 'Url') {
                  return (
                    <div key={`${label}${index}`} data-e2e={`predeal-check-url-message-${index}`}>
                      <a href={message} target="_blank" rel="noopener noreferrer">
                        {message}
                      </a>
                    </div>
                  );
                }
                return (
                  <div key={label} data-e2e={`predeal-check-message-${index}`}>
                    {label ? <span className="fw-bold">{label}:</span> : ''} {message}
                  </div>
                );
              })}
            </div>
          )}

          <div className={`${styles['secondary-details']} `}>
            {technicalMessages.length > 0 && (
              <div className={`${styles['technical-messages']} `}>
                {technicalMessages.map(({ label, message }) => (
                  <div key={label} data-e2e={`predeal-check-technical-message`}>
                    <span>{label}:</span> {message}
                  </div>
                ))}
              </div>
            )}
            {detail.isDerogatable && props.canDerogate && props.commentInfo.noDerogationComment && (
              <div className="form-check ms-3">
                <input
                  type="checkbox"
                  id="derogate"
                  className={`form-check-input`}
                  checked={props.detail.selectedIsDerogated}
                  onChange={_ => {
                    props.onDerogate(false, 'DEFAULT');
                  }}
                  data-e2e="derogation-check-box"
                />
                <label htmlFor="derogate" className="form-check-label">
                  Derogate
                </label>
              </div>
            )}

            {detail.isDerogatable && !props.commentInfo.noDerogationComment && (
              <>
                <div className="ms-2">
                  <div className="fw-bold mb-1">Derogation reason</div>
                  <FormSelect
                    data-e2e="derogation-reason"
                    value={props.detail.selectedDerogationComment || 'DEFAULT'}
                    onChange={event => {
                      props.onDerogate(false, event.target.value as DerogationKey);
                    }}
                  >
                    {props.commentInfo.derogationComments.map(comment => (
                      <option value={comment.type} key={comment.type}>
                        {comment.message}
                      </option>
                    ))}
                  </FormSelect>
                </div>

                {props.detail.riskDetails && (
                  <>
                    <div className="ms-2">
                      <div className="fw-bold mb-1">IFlow ID</div>
                      <NeosBlurInput
                        data-e2e="derogation-IFlowId"
                        value={props.detail.riskDetails.riskId}
                        onBlur={event => {
                          if (props.detail.riskDetails) {
                            props.onDerogationRiskIdChange(
                              props.detail.riskDetails.uuid,
                              event.target.value,
                              props.detail.riskDetails.riskId,
                            );
                          }
                        }}
                      />
                    </div>

                    <div className="ms-2 w-100">
                      <div className="fw-bold mb-1">Comment</div>
                      <NeosBlurTextarea
                        data-e2e="derogation-comment"
                        className="form-control"
                        rows={3}
                        value={props.detail.riskDetails.derogationComment}
                        onChange={event => {
                          if (props.detail.riskDetails) {
                            props.onDerogationCommentChange(
                              props.detail.riskDetails.uuid,
                              event.target.value,
                              props.detail.riskDetails.derogationComment,
                            );
                          }
                        }}
                      />
                    </div>
                  </>
                )}
              </>
            )}

            {detail.isDerogatable && props.canDerogate && props.canApplyAll && (
              <button
                className={`${styles['apply-to-all']} btn btn-discreet-secondary`}
                onClick={() => {
                  if (props.detail.selectedDerogationComment) {
                    props.onDerogate(true, props.detail.selectedDerogationComment);
                  }
                }}
                data-e2e="derogation-apply-all-button"
              >
                Apply to all
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

function getEligibilityIcon(value: boolean | undefined): string {
  return value === undefined ? 'priority_high' : value ? 'done' : 'clear';
}

function getIconStyle(value: boolean | undefined): string {
  return value === undefined ? '' : value ? 'text-success' : 'text-danger';
}
