export enum MarginRules {
  CUSTOM = 'CUSTOM',
  SPREAD_10 = 'SPREAD_10',
  SPREAD_16 = 'SPREAD_16',
  SPREAD_20 = 'SPREAD_20',
  SPREAD_25 = 'SPREAD_25',
  SPREAD_30 = 'SPREAD_30',
  SPREAD_50 = 'SPREAD_50',
  NO_SPREAD = 'NO_SPREAD',
}
