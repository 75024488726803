import { DefaultingOptions } from '../rfqActions';

import type { Thunk } from '@/bootstrap/thunks';
import type { ChainOptions, RetryOnErrorOptions } from '../../neosActions';
import { DefaultingScope } from '../models';

export function createDefaultNegotiatedSizeRequestedThunk(
  rfqId: string,
  chainOptions?: ChainOptions,
): Thunk {
  return function defaultNegotiatedSizeRequestedThunk(
    dispatch,
    getState,
    { selectors, actionCreators },
  ) {
    const isSecondaryRfq = selectors.isSecondaryRfq(getState(), rfqId);

    const options = new DefaultingOptions({
      enrichScopes: [DefaultingScope.NEGOTIATED_SIZE, DefaultingScope.UNITS, DefaultingScope.FOREX],
      overrideScopes: [DefaultingScope.IA_CURRENCY],
    });
    const retryOptions: RetryOnErrorOptions = {
      retries: 1,
    };

    if (isSecondaryRfq) {
      options.overrideScopes.push(DefaultingScope.SECONDARY_IA);
    }

    dispatch(
      actionCreators.neos.createDefaultRfqRequestedAction(
        rfqId,
        options,
        chainOptions,
        retryOptions,
      ),
    );
  };
}
