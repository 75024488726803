import type { TradeRecapMailCCRecipientsAction } from '@/neos/business/rfq/tradeRecapMail/TradeRecapMailCCRecipientsAction';

export function createTradeRecapMailCCRecipientsAction(
  rfqId: string,
  comment: string,
): TradeRecapMailCCRecipientsAction {
  return {
    type: 'TRADE_RECAP_MAIL_CC_RECIPIENTS_REQUESTED',
    rfqId,
    comment,
  };
}
