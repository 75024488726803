import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { services } from '@/bootstrap/services';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import type { PrimeBroker } from '@/neos/business/referenceData/primeBroker/primeBrokerActions';
import type { CommissionType, RfqData } from '../../../../../../../neos/business/neosModel';
import type { ScopeType } from '../../scopeModel';
import type {
  CommonListedPreAllocationsDispatchProps,
  CommonListedPreAllocationsOwnProps,
} from '../CommonListedPreAllocations';

export function genericMapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId }: CommonListedPreAllocationsOwnProps,
  scope: ScopeType,
): CommonListedPreAllocationsDispatchProps {
  const property: keyof Pick<RfqData, 'listedPreAllocs' | 'deltaListedPreAllocs'> =
    scope === 'RFQ' ? 'listedPreAllocs' : 'deltaListedPreAllocs';
  return {
    onClearerAccountChanged: (index: number, clearerAccount: string | undefined) => {
      dispatch(
        neosActionCreators.rfqDataCrudActions.arrayPatch(rfqId, {
          index,
          property,
          value: { clearerAccount },
        }),
      );
    },
    onPreAllocationAdded: () => {
      dispatch(
        createLogAnalyticsAction(
          'NEOS RFQ',
          `Add ${scope === 'DELTA' ? 'delta' : ''} strategies listed pre alloc`,
        ),
      );
      dispatch(
        neosActionCreators.rfqDataCrudActions.arrayAdd(rfqId, {
          property,
          value: {
            clearerAccount: undefined,
            broker: undefined,
            commission: undefined,
            commissionType: 'FIXED_PER_LOTS',
            ratio: undefined,
          },
        }),
      );
    },
    onPreAllocationRemoved: (index: number) => {
      dispatch(
        createLogAnalyticsAction(
          'NEOS RFQ',
          `Remove ${scope === 'DELTA' ? 'delta' : ''} strategies listed pre alloc`,
        ),
      );
      dispatch(
        neosActionCreators.rfqDataCrudActions.arrayRemoveIndex(rfqId, {
          index,
          property,
        }),
      );
    },
    onRatioChanged: (index: number, ratio: number | undefined) => {
      dispatch(
        neosActionCreators.rfqDataCrudActions.arrayPatch(rfqId, {
          index,
          property,
          value: { ratio: services.applyMultiplierWithPrecision(ratio, 0.01, 4) },
        }),
      );
    },
    onBrokerChanged: (index: number, broker: PrimeBroker | undefined) => {
      dispatch(
        neosActionCreators.rfqDataCrudActions.arrayPatch(rfqId, {
          index,
          property,
          value: { broker },
        }),
      );
    },
    onCommissionChanged: (index: number, commission: number | undefined) => {
      dispatch(
        neosActionCreators.rfqDataCrudActions.arrayPatch(rfqId, {
          index,
          property,
          value: { commission },
        }),
      );
    },
    onCommissionTypeChanged: (index: number, commissionType: CommissionType | undefined) => {
      dispatch(
        neosActionCreators.rfqDataCrudActions.arrayPatch(rfqId, {
          index,
          property,
          value: { commissionType },
        }),
      );
    },
  };
}
