import type { FC } from 'react';
import type { LegLinkCellsModel } from './getLegLinkCellsModel';
import { LegLinkCell } from './legLinkCell/LegLinkCell.container';

export interface LegLinkCellsOwnProps {
  rfqId: string;
  legIds: string[];
  strategyId: string;
}

export type LegLinkCellsMapStateProps = LegLinkCellsModel;

export type LegLinkCellsProps = LegLinkCellsOwnProps & LegLinkCellsMapStateProps;

export const LegLinkCellsComponent: FC<LegLinkCellsProps> = props => {
  return (
    <section>
      <div role="group" className="d-block btn-group btn-group-toggle">
        {props.legIds.map(legId => (
          <LegLinkCell
            key={legId}
            rfqId={props.rfqId}
            strategyId={props.strategyId}
            legId={legId}
          />
        ))}
      </div>
    </section>
  );
};
