import type { PredealCheckAllocationOrDeltaLevel } from '../common';
import type { CommonPredealCheckWithLegs } from './common';

export const onlyListedAllocationOrDeltaPredealCheckWithLegsTypes = [
  'COST_AND_CHARGES',
  'EMBARGO_CTPY',
  'EMBARGO',
] as const;
export const allocationOrDeltaPredealCheckWithLegsTypes = [
  ...onlyListedAllocationOrDeltaPredealCheckWithLegsTypes,
  'DFA',
  'DFA_SEC',
  'EMIR',
  'FATCA',
  'HIRE_ACT',
  'CROSS_BORDER',
  'HKMA',
  'IM_VM',
  'IM_SIMULATION_BID',
  'IM_SIMULATION_ASK',
  'CASS',
] as const;
interface CommonAllocationOrDeltaPredealCheckWithLegs extends CommonPredealCheckWithLegs {
  type: AllocationOrDeltaPredealCheckWithLegsType;
  level: PredealCheckAllocationOrDeltaLevel;
}

export type AllocationOrDeltaPredealCheckWithLegsType =
  (typeof allocationOrDeltaPredealCheckWithLegsTypes)[number];

export type AllocationOrDeltaPredealChecksWithLegs =
  | CostAndChargesPredealCheck
  | DfaPredealCheck
  | DfaSecPredealCheck
  | EmirPredealCheck
  | FatcaPredealCheck
  | CassPredealCheck
  | EmbargoPredealCheck
  | EmbargoCtpPredealCheck
  | HireActPredealCheck
  | HkmaPredealCheck
  | CrossBorderPredealCheck
  | UMRBidPredealCheck
  | UMRAskPredealCheck
  | ImVmPredealCheck;

interface CommonCostAndChargesPredealCheck extends CommonAllocationOrDeltaPredealCheckWithLegs {
  type: 'COST_AND_CHARGES';
}

export interface AllocationCostAndChargesPredealCheck extends CommonCostAndChargesPredealCheck {
  level: 'ALLOCATION';
}

export interface DeltaCostAndChargesPredealCheck extends CommonCostAndChargesPredealCheck {
  level: 'DELTA';
}

export type CostAndChargesPredealCheck =
  | AllocationCostAndChargesPredealCheck
  | DeltaCostAndChargesPredealCheck;

interface CommonDfaPredealCheck extends CommonAllocationOrDeltaPredealCheckWithLegs {
  type: 'DFA';
  preTradeMidMarketMarkRequired: boolean;
  sgReportingParty: boolean;
  usBusiness: boolean;
  namespace: string;
  usi: string;
  priorNamespace: string;
  priorUsi: string;
  regulator: string;
}

export interface AllocationDfaPredealCheck extends CommonDfaPredealCheck {
  level: 'ALLOCATION';
}

export interface DeltaDfaPredealCheck extends CommonDfaPredealCheck {
  level: 'DELTA';
}

export type DfaPredealCheck = AllocationDfaPredealCheck | DeltaDfaPredealCheck;

interface CommonDfaSecPredealCheck extends CommonAllocationOrDeltaPredealCheckWithLegs {
  type: 'DFA_SEC';
  sgReportingParty: boolean;
  usBusiness: boolean;
  namespace: string;
  usi: string;
  priorNamespace: string;
  priorUsi: string;
  regulator: string;
}

export interface AllocationDfaSecPredealCheck extends CommonDfaSecPredealCheck {
  level: 'ALLOCATION';
}

export interface DeltaDfaSecPredealCheck extends CommonDfaSecPredealCheck {
  level: 'DELTA';
}

export type DfaSecPredealCheck = AllocationDfaSecPredealCheck | DeltaDfaSecPredealCheck;

interface CommonEmirPredealCheck extends CommonAllocationOrDeltaPredealCheckWithLegs {
  type: 'EMIR';
  uti: string;
  priorNamespace: string;
  priorUti: string;
  bankingSecrecyMessage: string;
  namespace: string;
}

export interface AllocationEmirPredealCheck extends CommonEmirPredealCheck {
  level: 'ALLOCATION';
}

export interface DeltaEmirPredealCheck extends CommonEmirPredealCheck {
  level: 'DELTA';
}

export type EmirPredealCheck = DeltaEmirPredealCheck | AllocationEmirPredealCheck;

interface CommonFatcaPredealCheck extends CommonAllocationOrDeltaPredealCheckWithLegs {
  type: 'FATCA';
}

export interface AllocationFatcaPredealCheck extends CommonFatcaPredealCheck {
  level: 'ALLOCATION';
}

export interface DeltaFatcaPredealCheck extends CommonFatcaPredealCheck {
  level: 'DELTA';
}

type FatcaPredealCheck = AllocationFatcaPredealCheck | DeltaFatcaPredealCheck;

interface CommonCassPredealCheck extends CommonAllocationOrDeltaPredealCheckWithLegs {
  type: 'CASS';
}

export interface AllocationCassPredealCheck extends CommonCassPredealCheck {
  level: 'ALLOCATION';
}

export interface DeltaCassPredealCheck extends CommonCassPredealCheck {
  level: 'DELTA';
}

type CassPredealCheck = AllocationCassPredealCheck | DeltaCassPredealCheck;

interface CommonEmbargoPredealCheck extends CommonAllocationOrDeltaPredealCheckWithLegs {
  type: 'EMBARGO';
}

export interface AllocationEmbargoPredealCheck extends CommonEmbargoPredealCheck {
  level: 'ALLOCATION';
}

export interface DeltaEmbargoPredealCheck extends CommonEmbargoPredealCheck {
  level: 'DELTA';
}

type EmbargoPredealCheck = AllocationEmbargoPredealCheck | DeltaEmbargoPredealCheck;

interface CommonEmbargoCtpPredealCheck extends CommonAllocationOrDeltaPredealCheckWithLegs {
  type: 'EMBARGO_CTPY';
}

export interface AllocationEmbargoCtpPredealCheck extends CommonEmbargoCtpPredealCheck {
  level: 'ALLOCATION';
}

export interface DeltaEmbargoCtpPredealCheck extends CommonEmbargoCtpPredealCheck {
  level: 'DELTA';
}

type EmbargoCtpPredealCheck = AllocationEmbargoCtpPredealCheck | DeltaEmbargoCtpPredealCheck;

interface CommonHireActPredealCheck extends CommonAllocationOrDeltaPredealCheckWithLegs {
  type: 'HIRE_ACT';
}

export interface AllocationHireActPredealCheck extends CommonHireActPredealCheck {
  level: 'ALLOCATION';
}

export interface DeltaHireActPredealCheck extends CommonHireActPredealCheck {
  level: 'DELTA';
}

type HireActPredealCheck = AllocationHireActPredealCheck | DeltaHireActPredealCheck;

interface CommonHkmaPredealCheck extends CommonAllocationOrDeltaPredealCheckWithLegs {
  type: 'HKMA';
  uti: string;
}

export interface AllocationHkmaPredealCheck extends CommonHkmaPredealCheck {
  level: 'ALLOCATION';
}

export interface DeltaHkmaPredealCheck extends CommonHkmaPredealCheck {
  level: 'DELTA';
}

type HkmaPredealCheck = AllocationHkmaPredealCheck | DeltaHkmaPredealCheck;

interface CommonCrossBorderPredealCheck extends CommonAllocationOrDeltaPredealCheckWithLegs {
  type: 'CROSS_BORDER';
}

export interface AllocationCrossBorderPredealCheck extends CommonCrossBorderPredealCheck {
  level: 'ALLOCATION';
}

export interface DeltaCrossBorderPredealCheck extends CommonCrossBorderPredealCheck {
  level: 'DELTA';
}

type CrossBorderPredealCheck = AllocationCrossBorderPredealCheck | DeltaCrossBorderPredealCheck;
interface CommonImVmPredealCheck extends CommonAllocationOrDeltaPredealCheckWithLegs {
  type: 'IM_VM';
}

interface MarginType {
  addOn: number;
  initialMargin: number;
}
export interface UMRPredealCheck extends CommonAllocationOrDeltaPredealCheckWithLegs {
  simulationId: string;
  currency: string;
  initialMargins?: {
    augmented: MarginType;
    baseline: MarginType;
    marginal: MarginType;
    standalone: MarginType;
  };
}
interface UMRBidPredealCheck extends UMRPredealCheck {
  type: 'IM_SIMULATION_BID';
}
interface UMRAskPredealCheck extends UMRPredealCheck {
  type: 'IM_SIMULATION_ASK';
}

export interface AllocationImVmPredealCheck extends CommonImVmPredealCheck {
  level: 'ALLOCATION';
}

export interface DeltaImVmPredealCheck extends CommonImVmPredealCheck {
  level: 'DELTA';
}

export type ImVmPredealCheck = AllocationImVmPredealCheck | DeltaImVmPredealCheck;
