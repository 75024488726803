import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { OnyxRfq } from '@/neos/business/rfq/rfqOnyxModel';
import { tryGetUserFullName } from '@/neos/components/share/userUtils/userUtils';
import type { BlotterModelGetters } from '.';

export function getTraderNames(
  state: AppState,
  rfq: OnyxRfq,
  selectors: Selectors,
  blotterGetters: BlotterModelGetters,
) {
  const traderIds = blotterGetters.getTraderIds(rfq, blotterGetters);
  const traders = selectors.getTraders(state.referenceData);

  return blotterGetters.twoDimentionalArrayToString(
    traderIds.map(traderId => [traderId]),
    traderId => tryGetUserFullName(traders, traderId, 'N/A'),
    { ignoreStrategiesWithEmptyValues: false },
  );
}
