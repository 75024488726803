import { crudDomain } from '@/util/crudUtils';
import type {
  LegSecondaryEventCreation,
  LegSecondaryEventCreationKey,
} from './legSecondaryEventCreationModel';

const compositeIdSeparator = ' | ';

function getLegSecondaryEventCreationId({ rfqId, legId }: LegSecondaryEventCreationKey): string {
  return `${rfqId}${compositeIdSeparator}${legId}`;
}

function getLegSecondaryEventCreationKey(id: string): LegSecondaryEventCreationKey {
  const [rfqId, legId] = id.split(compositeIdSeparator);
  return { rfqId, legId };
}

export const legSecondaryEventCreationDomain = crudDomain<
  LegSecondaryEventCreation,
  LegSecondaryEventCreationKey
>('leg-secondary-event-creation', {
  keyToString: getLegSecondaryEventCreationId,
  stringToKey: getLegSecondaryEventCreationKey,
});
