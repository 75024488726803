import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';

export interface RefDeltaTabsModel {
  isDeltaAdjustedSelected: boolean;
  hasDeltaAdjusted: boolean;
  hasWarning: boolean;
}

export function getRefDeltaTabsModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): RefDeltaTabsModel {
  const { deltaHedgingStrategyIds } = selectors.getRfqData(state, rfqId);

  const hasDeltaAdjusted = deltaHedgingStrategyIds.some(strategyId =>
    selectors.isDeltaHedgingStrategyAdjusted(state, strategyId, selectors),
  );

  const isDeltaAdjustedSelected = selectors.isDeltaAdjustedSelected(state.ui, rfqId);

  const hasWarning = deltaHedgingStrategyIds.some(strategyId => {
    const isDeltaAdjusted = selectors.isDeltaHedgingStrategyAdjusted(state, strategyId, selectors);
    const warnings = selectors.getStrategyWarnings(state, rfqId, strategyId, selectors);
    return isDeltaAdjusted && warnings[0]?.warning;
  });

  return {
    isDeltaAdjustedSelected,
    hasDeltaAdjusted,
    hasWarning,
  };
}
