export type UpdateJustification =
  | 'FA1'
  | 'FA2'
  | 'FA3'
  | 'FB1'
  | 'FB2'
  | 'FB3'
  | 'GA1'
  | 'GA2'
  | 'GA3'
  | 'GB1'
  | 'GB2'
  | 'GB3';

export type UpdateJustificationLabel =
  | 'FO error'
  | 'Third party error/request'
  | 'Split/Allocation';

export const allUpdateJustificationOptions: {
  value: UpdateJustification;
  label: UpdateJustificationLabel;
}[] = [
  { value: 'FA1', label: 'FO error' },
  { value: 'FA2', label: 'Third party error/request' },
  { value: 'FA3', label: 'Split/Allocation' },
  { value: 'FB1', label: 'FO error' },
  { value: 'FB2', label: 'Third party error/request' },
  { value: 'FB3', label: 'Split/Allocation' },
  { value: 'GA1', label: 'FO error' },
  { value: 'GA2', label: 'Third party error/request' },
  { value: 'GA3', label: 'Split/Allocation' },
  { value: 'GB1', label: 'FO error' },
  { value: 'GB2', label: 'Third party error/request' },
  { value: 'GB3', label: 'Split/Allocation' },
];
