import { sortBy, sortedIndex } from 'lodash';

export function getNearestElement(elements: number[], base: number): number | undefined {
  const length: number = elements.length;

  if (length === 0) {
    return undefined;
  }

  const sortedElements: number[] = sortBy(elements);
  const indexOfBaseThatPreservesElementsOrder: number = sortedIndex(sortedElements, base);
  switch (indexOfBaseThatPreservesElementsOrder) {
    case 0:
      return sortedElements[0];
    case length:
      return sortedElements[length - 1];
    default:
      return getNearestOfTwoElementsWithAbsoluteComparison(
        sortedElements,
        indexOfBaseThatPreservesElementsOrder,
        base,
      );
  }
}

function getNearestOfTwoElementsWithAbsoluteComparison(
  sortedElements: number[],
  indexOfBaseThatPreservesElementsOrder: number,
  base: number,
): number {
  const closestLowerElement: number = sortedElements[indexOfBaseThatPreservesElementsOrder - 1];
  const closestHigherElement: number = sortedElements[indexOfBaseThatPreservesElementsOrder];
  const closestElements: number[] = [closestLowerElement, closestHigherElement];
  const absoluteDifferencesWithBase: number[] = closestElements.map(absoluteMin(base));
  return absoluteDifferencesWithBase[0] < absoluteDifferencesWithBase[1]
    ? closestElements[0]
    : closestElements[1];
}

function absoluteMin(base: number) {
  return (element: number) => Math.abs(element - base);
}
