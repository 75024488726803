import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { createRfqUpdateStrategyQuoteUnitThunk } from '@/neos/business/rfq/thunks/rfqUpdateStrategyQuoteUnit';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import {
  LegRecomputeChangedScope,
  LegRecomputeScope,
  type MarginRules,
} from '../../../../../../business/neosModel';
import {
  QuotesComponent,
  type QuotesMapStateProps,
  type QuotesOwnProps,
  type WrapedQuotesDispatchProps,
} from './Quotes';
import { getQuotesModel } from './getQuotesModel';

export const Quotes = connect(mapStateToProps, mapDispatchToProps)(QuotesComponent);

function mapStateToProps(
  state: AppState,
  { strategyId, rfqId }: QuotesOwnProps,
): QuotesMapStateProps {
  return { model: getQuotesModel(state, rfqId, strategyId, selectors) };
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { strategyId, rfqId }: QuotesOwnProps,
): WrapedQuotesDispatchProps {
  return {
    dispatchProps: {
      onLegTraderBidChanged(quoteId: string, legId: string, value?: number) {
        dispatch(
          neosThunks.createRfqUpdateLegQuoteThunk({
            rfqId,
            strategyId,
            quoteId,
            legId,
            quotePatch: { traderBid: value },
            scope: LegRecomputeChangedScope.TRADER_PRICE,
          }),
        );
      },
      onLegTraderAskChanged(quoteId: string, legId: string, value?: number) {
        dispatch(
          neosThunks.createRfqUpdateLegQuoteThunk({
            rfqId,
            strategyId,
            quoteId,
            legId,
            quotePatch: { traderAsk: value },
            scope: LegRecomputeChangedScope.TRADER_PRICE,
          }),
        );
      },
      onLegSalesBidChanged(quoteId: string, legId: string, value?: number) {
        dispatch(
          neosThunks.createRfqUpdateLegQuoteThunk({
            rfqId,
            strategyId,
            quoteId,
            legId,
            quotePatch: { salesBid: value },
            scope: LegRecomputeChangedScope.SALES_PRICE,
          }),
        );
      },
      onLegSalesAskChanged(quoteId: string, legId: string, value?: number) {
        dispatch(
          neosThunks.createRfqUpdateLegQuoteThunk({
            rfqId,
            strategyId,
            quoteId,
            legId,
            quotePatch: { salesAsk: value },
            scope: LegRecomputeChangedScope.SALES_PRICE,
          }),
        );
      },
      onLegTraderDeltaChanged(quoteId: string, legId: string, value?: number) {
        dispatch(
          neosThunks.createRfqUpdateLegQuoteThunk({
            rfqId,
            strategyId,
            quoteId,
            legId,
            quotePatch: {
              delta: value,
            },
            scope: LegRecomputeScope.CHANGE_DELTA,
          }),
        );
      },
      onLegMarkupRuleChange(quoteId: string, rule: MarginRules | undefined) {
        dispatch(neosThunks.createChangeLegQuoteMarkupRuleThunk(rfqId, quoteId, rule));
      },

      onStrategyTraderBidChanged(quoteId: string, value?: number) {
        dispatch(
          neosThunks.createRfqUpdateStrategyQuoteThunk(
            rfqId,
            strategyId,
            quoteId,
            { traderBid: value },
            LegRecomputeChangedScope.TRADER_PRICE,
          ),
        );
      },
      onStrategyTraderAskChanged(quoteId: string, value?: number) {
        dispatch(
          neosThunks.createRfqUpdateStrategyQuoteThunk(
            rfqId,
            strategyId,
            quoteId,
            { traderAsk: value },
            LegRecomputeChangedScope.TRADER_PRICE,
          ),
        );
      },
      onStrategySalesBidChanged(quoteId: string, value?: number) {
        dispatch(
          neosThunks.createRfqUpdateStrategyQuoteThunk(
            rfqId,
            strategyId,
            quoteId,
            { salesBid: value },
            LegRecomputeChangedScope.SALES_PRICE,
          ),
        );
      },
      onStrategySalesAskChanged(quoteId: string, value?: number) {
        dispatch(
          neosThunks.createRfqUpdateStrategyQuoteThunk(
            rfqId,
            strategyId,
            quoteId,
            { salesAsk: value },
            LegRecomputeChangedScope.SALES_PRICE,
          ),
        );
      },

      onStrategyTraderDeltaChanged(quoteId: string, value?: number) {
        dispatch(
          neosThunks.createRfqUpdateStrategyQuoteThunk(
            rfqId,
            strategyId,
            quoteId,
            { delta: value },
            LegRecomputeScope.CHANGE_DELTA,
          ),
        );
      },

      onStrategyMarkupRuleChange(rule: MarginRules | undefined) {
        dispatch(neosThunks.createChangeStrategyMarkupRuleThunk(rfqId, strategyId, rule));
      },
      onStrategyQuoteUnitChanged(unit: string | undefined) {
        dispatch(createRfqUpdateStrategyQuoteUnitThunk(rfqId, strategyId, unit));
      },
    },
  };
}
