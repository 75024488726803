import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { type NeosActionCreators, neosActionCreators } from '@/neos/business/neosActionCreators';
import { useDispatch, useSelector } from 'react-redux';
import type { Contact } from '../../../../../../../neos/business/neosModel';
import {
  ExternalPreconfirmationBookingOptionsComponent,
  type ExternalPreconfirmationBookingOptionsDispatchProps,
  type ExternalPreconfirmationBookingOptionsOwnProps,
  type PreconfirmationBookingOptionsStateProps,
} from './ExternalPreconfirmationBookingOptions';
import { getExternalPreconfirmationTabModel } from './getPreconfirmationBookingOptionsModel';

export const ExternalPreconfirmationBookingOptions = (
  ownProps: ExternalPreconfirmationBookingOptionsOwnProps,
) => {
  const stateProps = useSelector<AppState, PreconfirmationBookingOptionsStateProps>(state => {
    return getExternalPreconfirmationTabModel(state, ownProps.rfqId, selectors);
  });

  const dispatch = useDispatch<ExtendedDispatch>();
  const dispatchProps = getDispatchProps(dispatch, ownProps, neosActionCreators);

  return (
    <ExternalPreconfirmationBookingOptionsComponent
      {...ownProps}
      {...stateProps}
      {...dispatchProps}
    />
  );
};

export function getDispatchProps(
  dispatch: ExtendedDispatch,
  { rfqId }: ExternalPreconfirmationBookingOptionsOwnProps,
  neosActionCreators: NeosActionCreators,
): ExternalPreconfirmationBookingOptionsDispatchProps {
  return {
    onContactSelectOption: (clientContact: Contact | undefined) => {
      dispatch(
        neosActionCreators.externalPreconfirmationCrudActions.patchOrInsert(rfqId, {
          clientContact,
        }),
      );
    },
    onMiddleSelectOption: (clientMiddle: Contact | undefined) => {
      dispatch(
        neosActionCreators.externalPreconfirmationCrudActions.patchOrInsert(rfqId, {
          clientMiddle,
        }),
      );
    },
    onBackSelectOption: (clientBack: Contact | undefined) => {
      dispatch(
        neosActionCreators.externalPreconfirmationCrudActions.patchOrInsert(rfqId, {
          clientBack,
        }),
      );
    },
    onOtherValueChange: (clientOther: string | undefined) => {
      dispatch(
        neosActionCreators.externalPreconfirmationCrudActions.patchOrInsert(rfqId, {
          clientOther,
        }),
      );
    },
    onCommentSelected: (emailComment: string | undefined) => {
      dispatch(
        neosActionCreators.externalPreconfirmationCrudActions.patchOrInsert(rfqId, {
          emailComment,
        }),
      );
    },
  };
}
