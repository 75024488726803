import type { RfqDifference } from '@/neos/business/neosModel';
import { type FC, Fragment, useState } from 'react';
import { Modal } from 'react-bootstrap';
import type { NotificationModalModel } from './getNotificationModalModel';

import styles from './NotificationModal.module.scss';

export interface NotificationModalOwnProps {
  rfqId: string;
}

export type NotificationModalPropsFromState = NotificationModalModel;

export interface NotificationModalDispatchProps {
  onConfirmNotificationModal(): void;
}

export const NotificationModalWithStyle: FC<
  NotificationModalOwnProps & NotificationModalPropsFromState & NotificationModalDispatchProps
> = ({
  isNotificationModalDisplayed,
  otherDifferences,
  highlightedDifferences,
  areDifferencesDisplayed,
  traderNotifications,
  isTraderWarningDisplayed,
  onConfirmNotificationModal,
  lastUpdater,
}) => {
  const [showDetails, setShowDetails] = useState(false);
  return !isNotificationModalDisplayed ? null : (
    <Modal
      show={true}
      onKeyPress={(event: any) => event.charCode === 13 && onConfirmNotificationModal()}
      size="lg"
      className={`${styles['notification-modal']}`}
      data-e2e="rfq-notification-modal"
    >
      <Modal.Header>
        <Modal.Title>
          {lastUpdater
            ? `Version has been updated by ${lastUpdater}`
            : 'You received an updated version'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={`${styles['trader-notifications-modal-body']}`}>
        {isTraderWarningDisplayed &&
          traderNotifications.map(traderNotification => (
            <div
              key={traderNotification.message}
              className={`${styles['trader-notification-warning-message']} py-3`}
            >
              <p className="text-danger m-0 fw-bold">{traderNotification.message}</p>
              <div>
                {traderNotification.fieldChanges.map(({ oldValue, newValue }, index) => {
                  return (
                    <p
                      key={traderNotification.message + index.toString()}
                      className="text-danger m-0 fw-bold"
                    >
                      {newValue
                        ? `From ${oldValue} to ${newValue}`
                        : `Deleted used to be ${oldValue}`}
                    </p>
                  );
                })}
              </div>
            </div>
          ))}
        A new version is available
        {areDifferencesDisplayed && (
          <>
            {displayDifferences(highlightedDifferences)}
            {showDetails ? (
              <section>
                <br />
                {displayDifferences(otherDifferences)}
                <a onClick={() => setShowDetails(false)}>Hide details</a>
              </section>
            ) : (
              <p>
                <a onClick={() => setShowDetails(true)}>Show details...</a>
              </p>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          data-e2e="neos-modal-load-update-btn"
          className="btn btn-lg btn-primary"
          onClick={onConfirmNotificationModal}
        >
          Load update
        </button>
      </Modal.Footer>
    </Modal>
  );
};

const displayDifferences = (differences: RfqDifference[]) => (
  <section className={`${styles['notification-diff']}`}>
    <label>Field</label>
    <label>Notification Value</label>
    <label>Current Value</label>
    {differences.map(diff => (
      <Fragment key={diff.path}>
        <span>{diff.path}</span>
        <span>{diff.newValue}</span>
        <span>{diff.currentValue}</span>
      </Fragment>
    ))}
  </section>
);
