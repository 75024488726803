import type { Thunk } from '@/bootstrap/thunks';
import type { ExecutionType } from '../rfqOnyxModel';

export function createChangeMasterStrategyExecutionTypeThunk(
  rfqId: string,
  executionType: ExecutionType,
): Thunk {
  return function changeMasterStrategyExecutionTypeThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { strategyDataCrudActions },
      },
    },
  ) {
    const state = getState();

    const { uuid: strategyId } = selectors.getRfqMasterStrategy(state, rfqId);
    dispatch(
      strategyDataCrudActions.update(strategyId, {
        executionType,
      }),
    );
  };
}
