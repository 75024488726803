import { services } from '@/bootstrap/services';
import type { FC } from 'react';
import { BrowserNotificationCloseIconComponent } from './BrowserNotificationCloseIcon';

export const BrowserNotificationCloseIcon: FC = () => (
  <BrowserNotificationCloseIconComponent
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    closeAllBrowserNotifications={() => services.serviceWorker.hideAllNotifications()}
  />
);
