import type { Thunk } from '@/bootstrap/thunks';

export function createCloseTabThunk(rfqId: string): Thunk {
  return function closeTabThunk(
    dispatch,
    getState,
    {
      thunks: {
        neos: { createDeleteTransactionThunk },
        createRemoveNotificationsThunk,
      },
      actionCreators: {
        neos: {
          rfqUiCrudActions,
          referencedRfqCrudActions,
          createTabClosedAction,
          nestedRfqsCrudActions,
          pendingPredealChecksCrudActions,
          createDeltaNotificationChangesCrudAction,
          createExecutionPriceNotificationChangesCrudAction,
          createTraderAskNotificationChangesCrudAction,
          createTraderBidNotificationChangesCrudAction,
          lastTradeCompletedRfqActionCreators: { deleteRfq },
        },
      },
      selectors: { getReferencedRfqIds },
    },
  ) {
    const referencedRfqIds = getReferencedRfqIds(getState().ui, rfqId);
    const isLast = (referencedRfqIds?.length ?? 0) <= 1;

    dispatch(
      createTabClosedAction(rfqId),
      rfqUiCrudActions.delete(rfqId),
      deleteRfq(rfqId),
      isLast
        ? referencedRfqCrudActions.delete(rfqId)
        : referencedRfqCrudActions.upsert(rfqId, referencedRfqIds?.filter(id => id != rfqId) ?? []),
      nestedRfqsCrudActions.delete(rfqId),
      pendingPredealChecksCrudActions.delete(rfqId),
    );
    if (isLast) {
      dispatch(createDeleteTransactionThunk(rfqId));
    }

    dispatch(
      createRemoveNotificationsThunk(),
      createDeltaNotificationChangesCrudAction.deleteByPartialKey({ rfqId }),
      createExecutionPriceNotificationChangesCrudAction.deleteByPartialKey({ rfqId }),
      createTraderAskNotificationChangesCrudAction.deleteByPartialKey({ rfqId }),
      createTraderBidNotificationChangesCrudAction.deleteByPartialKey({ rfqId }),
    );
  };
}
